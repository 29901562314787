<template>
<div>
  <div v-if="showLoading">
    <LoadingSimple
        clazz="big-load p-t-50 p-b-50"
        label="Loading..."
    ></LoadingSimple>
  </div>
  <div v-else>
    <TitlePrincipal
        :title="$t(titleText)"
        :subtitle="$t(subTitleText)"
        clazz="text-align-c">
    </TitlePrincipal>
    <img
        class="img-fluid max-h-300px d-flex m-0-auto"
        src="@/assets/images/Illustrations/illustration-correo-verificado.svg"
        alt="Correo verificado correctamente"/>
    <div class="footer-buttons center">
      <button type="button" class="button button-filled button-primary" name="Continuar" :disabled="!validEmail"
              @click.prevent="goToSelectAccount"> {{ $t('register.verificationEmail.continueRegis') }} </button>
    </div>
    <p class="f-14 c-plain_text poppins-font-light text-align-c m-t-30">
      <span v-html="$t('register.verificationEmail.attendance')"></span>
      <strong class="poppins-font-bold">chat de soporte</strong> o contáctanos a los teléfonos:
      <strong class="poppins-font-bold">800 461 0119</strong> para México o
      <strong class="poppins-font-bold">800 392 6948</strong> para Estados Unidos.
    </p>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import LoadingSimple from "@/components/LoadingSimple";
import UserApi from "@/api/users/users.api";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {
  SET_TOKEN,
  SET_TOKEN_EXPIRE_DATE,
  SET_USER,
  STORE as SESSION_STORE
} from "@/store/modules/session/session.types";
import {
  STORE as OnboardingStepsStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import axios from "axios";
import {catchError} from "@/utils/utils";


export default {
  name: "VerificationEmail",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    LoadingSimple
  },
  methods: {
    goToSelectAccount() {
      // this.$store.dispatch(`${OnboardingStepsStore}/checkCurrentStep`, {route: 'selectAccount', doRedirect: true} );
      this.$router.push({name: "selectAccount"})
    }
  },
  data() {
    return {
      validEmail: false,
      showLoading: true,
      titleText: "register.verificationEmail.title",
      subTitleText: "register.verificationEmail.subtitle"
    }
  },
  async created () {
    await this.$store.dispatch(`${OnboardingStepsStore}/checkCurrentStep`, this.$route);
    const token = this.$route.params.token;
    const response = await UserApi.confirmEmail({id: token, retrieveToken: true}).catch(catchError)
    if (response.data) {
      if (response.data.error) {
        this.titleText = "register.verificationEmail.titleError";
        this.subTitleText = "register.verificationEmail.subtitleError";
        this.displayNotificationError( '', this.$i18n.t('register.verificationEmail.emailError'))
      } else {
        this.validEmail = true;
        const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
        const user = response.data.data.user;
        // localStorage.setItem('currentUser', JSON.stringify(user));
        this.$store.commit(`${SESSION_STORE}/${SET_USER}`, user);

        localStorage.setItem('currentToken', JSON.stringify(response.data.data.token));
        const validToken = response.data.data.token.replace(/"/g, "");
        this.$store.commit(`${SESSION_STORE}/${SET_TOKEN}`, validToken);

        axios.defaults.headers.common.Authorization = `Bearer ${validToken}`;
        this.$store.commit(`${SESSION_STORE}/${SET_TOKEN_EXPIRE_DATE}`, expireDate);
        // localStorage.setItem('currentPermissions', JSON.stringify(response.data.data.permissions));
        // localStorage.setItem('profile', JSON.stringify(response.data.data.profile));
        // localStorage.setItem('expireDate', JSON.stringify(expireDate));
      }
    }
    this.showLoading = false;
  },
}
</script>

<style scoped>

</style>
