<template>
  <div>
    <div class="register-container">
      <div class="left-side-content">
        <div class="container-left">
          <img
            class="img-fluid logo"
            src="@/assets/images/Logos/logo-color.svg"
            alt="Logo Pagando"
          />

          <div class="progress-percent-title only-title">
            <h2 class="title m-l-0">
              {{ $t('register.shareholders.notification.sideTitle') }}
              <br />
              <strong>{{ $t('register.shareholders.notification.sideSubtitle') }}</strong>
            </h2>
          </div>
        </div>
      </div>
      <div class="right-side-content">
        <AccountType
          text="Cuenta seleccionada"
          clazzAccount="account-business"
          business
          :typeAccount="typeAccount"
        >
        </AccountType>
        <div class="container-right max-w-100">
          <TitlePrincipal
            :title="$t('register.shareholders.notification.title')"
            :subtitle="$t('register.shareholders.notification.subtitle')"
          >
          </TitlePrincipal>
          <ShareholdersTree
            :isMulti="$route.name === 'shareholdersModificationMultiple'"
            @onLoadChildrenDone="onTreeChildrenLoaded"
            @onLoadChildrenError="onTreeLoadError"
            @onDeleteError="onTreeDeleteError"
            @onLoadRootDone="onLoadRootDone"
            showWhenEmpty
          />
          <div class="footer-buttons right">
            <button
              type="button"
              class="button button-ghost button-plain_text"
              name="Regresar"
              @click="back"
            >
              Regresar
            </button>
            <button
              type="button"
              class="button button-filled button-primary"
              name="Continuar"
              :disabled="!hasLegal"
              @click="goToReview"
            >
              Continuar
            </button>
          </div>
        </div>
      </div>
    </div>
    <FooterPage></FooterPage>
  </div>
</template>
<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import ShareholdersTree from "@/components/ShareholdersTree";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import AccountType from "@/components/AccountType.vue";
import FooterPage from "@/components/FooterPage.vue";
import { mapGetters } from "vuex";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import {
  STORE as OnboardingStore,
  GET_CARD_REQUEST,
} from '@/store/modules/onboardingSteps/onboardingSteps.types';

export default {
  name: "ShareholdersModification",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    ShareholdersTree,
    AccountType,
    FooterPage,
  },
  data() {
    return {
      loading: true,
      childrenExist: false,
      hasLegal: false
    };
  },
  computed: {
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO,
    }),
    ...mapGetters(OnboardingStore, {
      cardRequest: GET_CARD_REQUEST
    }),
    typeAccount() {
      if(this.cardRequest && this.cardRequest.cardTemplate) {
        return this.cardRequest.cardTemplate.name
      }
      return null
    },
  },
  methods: {
    async back() {
      if(this.$route.name === 'shareholdersModificationMultiple') {
        await this.$store.dispatch(`${OnboardingStore}/getProvisionalCards`, false);

        this.$router.push({ name: "businessShareholdersMultiple" });
      } else {
        this.$router.push({ name: "businessShareholders" });
      }
    },
    addPhysical() {
      if(this.$route.name === 'shareholdersModificationMultiple') {
        this.$router.push({ name: "newPhysicalShareholderMultiple" });
      } else {
        this.$router.push({ name: "newPhysicalShareholder" });
      }
    },
    addMoral() {
      if(this.$route.name === 'shareholdersModificationMultiple') {
        this.$router.push({ name: "newMoralShareholderMultiple" });
      } else {
        this.$router.push({ name: "newMoralShareholder" });
      }
    },
    onLoadRootDone(rootData) {
      const legal = rootData.find((d) => d.legal);
      this.hasLegal = !!legal;
    },
    onTreeChildrenLoaded(exists) {
      this.childrenExist = exists;
      this.loading = false;
    },
    onTreeLoadError() {
      this.displayNotificationError(
        "No se pudieron cargar los accionistas. Intenta de nuevo."
      );
    },
    onTreeDeleteError() {
      this.displayNotificationError(
        "No se pudo borrar el accionista. Intenta de nuevo."
      );
    },
    async goToReview() {
      await this.back();
    }
  },
};
</script>
