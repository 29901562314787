<template>
  <div>
    <div class="register-container">
      <LeftSide :showBack="showBack"></LeftSide>
      <RightSide></RightSide>
      <Footer></Footer>
    </div>
    <FooterPage></FooterPage>
    <vue-snotify></vue-snotify>
  </div>

</template>

<script>

import LeftSide from "@/pages/onboarding/LeftSide";
import RightSide from "@/pages/onboarding/RightSide";
import Footer from "@/pages/onboarding/Footer";
import FooterPage from "@/components/FooterPage.vue";
import {
  GET_CARD_REQUEST,
  STORE as onboardingStepsStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {mapGetters} from "vuex";

export default {
  name: "OnboardingPersonal",
  components: {
    LeftSide,
    RightSide,
    Footer,
    FooterPage
  },
  computed: {
    ...mapGetters(onboardingStepsStore, {
      cardRequest: GET_CARD_REQUEST,
    }),
  },
  data() {
    return {
      showBack: false
    }
  },
  mounted() {
    this.showBack = this.$route.matched.some(r => r.name === 'selectAccountMultiple' || r.name === 'selectDocumentsMultiple'
      || r.name === 'uploadDocumentsMultiple' || r.name === 'addInfoMultiple');

    if(!this.showBack) {
      if (this.cardRequest) {
        this.$store.dispatch(`${onboardingStepsStore}/putLevel`, this.cardRequest.information_level);
      } else {
        this.$store.dispatch(`${onboardingStepsStore}/putLevel`, 'LEVEL_ONE');
      }
    }
  }
}
</script>

<style scoped>

</style>
