import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'firebase';

export default {
    getAll: ( params) => {
        return axios.get(`${base.baseUrl}/${namespace}/notifications`, {
            params: params
        });
    },
    changeStatus: (id, params) => {
        return axios.put(`${base.baseUrl}/${namespace}/notifications/${id}`, params);
    },

    subscribeDevice: (params) => {
        return axios.put(`${base.baseUrl}/${namespace}/notifications/device/registry/`, params);
    }
}
