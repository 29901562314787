<template>
    <div id="" class="movements-list-container" :class="clazz">
        <span class="movement-icon">
            <i aria-hidden="true" :class="icon"></i>
        </span>
        <span class="movement-info">
            <label>{{movConcept}}</label>
            <p v-if="movText">{{movText}}</p>
            <slot name="strongText"></slot>
            <small v-if="showTimeNotification">{{movTime}}</small>
        </span>
        <span class="movement-extra">
            <slot></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'MovementsList',
        props: {
            clazz: String,
            icon: String,
            movConcept: String,
            movText: String,
            movTime: String,
            showTimeNotification: {type: Boolean, default: false},
        },
        methods: {},
    }
</script>
