import pagandoExplore from "@/assets/images/Cards/pagando-explore.svg"
import pagandoExplorePlus from "@/assets/images/Cards/pagando-explore-plus.svg";
import pagandoNight from "@/assets/images/Cards/pagando-night.svg";
import pagandoBusiness from "@/assets/images/Cards/pagando-business.svg";
import pagandoBusinessPro from "@/assets/images/Cards/Accounts/businesspro-virtual-front.svg";
import pagandoExploreFisicaFront from "@/assets/images/Cards/Accounts/explore-fisica-front.svg";
import pagandoExploreFisicaBack from "@/assets/images/Cards/Accounts/explore-fisica-back.svg";
import pagandoExploreVirtualFront from "@/assets/images/Cards/Accounts/explore-virtual-front.svg";
import pagandoExploreVirtualBack from "@/assets/images/Cards/Accounts/explore-fisica-back.svg";
import pagandoExplorePlusFisicaFront from "@/assets/images/Cards/Accounts/exploreplus-fisica-front.svg";
import pagandoExplorePlusFisicaBack from "@/assets/images/Cards/Accounts/exploreplus-fisica-back.svg";
import pagandoExplorePlusVirtualFront from "@/assets/images/Cards/Accounts/exploreplus-virtual-front.svg";
import pagandoExplorePlusVirtualBack from "@/assets/images/Cards/Accounts/exploreplus-fisica-back.svg";
import pagandoNightFisicaFront from "@/assets/images/Cards/Accounts/night-fisica-front.svg";
import pagandoNightFisicaBack from "@/assets/images/Cards/Accounts/night-fisica-back.svg";
import pagandoNightVirtualFront from "@/assets/images/Cards/Accounts/night-virtual-front.svg";
import pagandoNightVirtualBack from "@/assets/images/Cards/Accounts/night-fisica-back.svg";
import pagandoBusinessFisicaFront from "@/assets/images/Cards/Accounts/business-fisica-front.svg";
import pagandoBusinessFisicaBack from "@/assets/images/Cards/Accounts/business-fisica-back.svg";
import pagandoBusinessVirtualFront from "@/assets/images/Cards/Accounts/business-virtual-front.svg";
import pagandoBusinessVirtualBack from "@/assets/images/Cards/Accounts/business-fisica-back.svg";
import pagandoBusinessProVirtualFront from "@/assets/images/Cards/Accounts/businesspro-virtual-front.svg";
import pagandoBusinessProVirtualBack from "@/assets/images/Cards/Accounts/businesspro-virtual-back.svg";

const CARD_DEFAULT_IMG = {
    "pagandoExplore": pagandoExplore,
    "pagandoExplorePlus": pagandoExplorePlus,
    "pagandoNight": pagandoNight,
    "pagandoBusiness": pagandoBusiness,
    "pagandoBusinessPro": pagandoBusinessPro,
    "pagandoExploreFisicaFront": pagandoExploreFisicaFront,
    "pagandoExploreFisicaBack": pagandoExploreFisicaBack,
    "pagandoExploreVirtualFront": pagandoExploreVirtualFront,
    "pagandoExploreVirtualBack": pagandoExploreVirtualBack,
    "pagandoExplorePlusFisicaFront": pagandoExplorePlusFisicaFront,
    "pagandoExplorePlusFisicaBack": pagandoExplorePlusFisicaBack,
    "pagandoExplorePlusVirtualFront": pagandoExplorePlusVirtualFront,
    "pagandoExplorePlusVirtualBack": pagandoExplorePlusVirtualBack,
    "pagandoNightFisicaFront": pagandoNightFisicaFront,
    "pagandoNightFisicaBack": pagandoNightFisicaBack,
    "pagandoNightVirtualFront": pagandoNightVirtualFront,
    "pagandoNightVirtualBack": pagandoNightVirtualBack,
    "pagandoBusinessFisicaFront": pagandoBusinessFisicaFront,
    "pagandoBusinessFisicaBack": pagandoBusinessFisicaBack,
    "pagandoBusinessVirtualFront": pagandoBusinessVirtualFront,
    "pagandoBusinessVirtualBack": pagandoBusinessVirtualBack,
    "pagandoBusinessProVirtualFront": pagandoBusinessProVirtualFront,
    "pagandoBusinessProVirtualBack": pagandoBusinessProVirtualBack,
}

export {
    CARD_DEFAULT_IMG
}
