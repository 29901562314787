<template>
<div>
  <TitlePrincipal :title="$t('register.processingRequest.title')" clazz="text-align-c">
    <p v-html="$t('register.processingRequest.subtitle')"></p>
  </TitlePrincipal>
  <img class="img-fluid max-h-300px d-flex m-0-auto"
       src="@/assets/images/Illustrations/illustration-solicitud-proceso.svg"
       alt="Procesando tu solicitud"/>
  <span class="f-14 c-title poppins-font-medium text-align-c d-block m-t-20 m-b-20">
    {{ $t('register.processingRequest.currentStatus') }}
    <br/>
    <strong class="f-24 c-info poppins-font-bold">{{ $t(messageStatus) }}</strong>
  </span>
  <div class="vertical-center justify-content-center" v-if="level !== 0">
    <button v-if="showVerify" type="button" class="button button-filled button-primary" name="Corregir" @click="reSendCard()">
      Verificar
    </button>
    <button v-else :id="'Loading button'" class="button button-ghost button-plain_text">
      <LoadingSimple clazz="small-load no-text" />
    </button>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import {
  GET_LEVEL,
  LOAD_CARD_REQUEST,
  GET_ERROR_CARD_REQUEST,
  GET_ACCOUNT_TYPE_BUSINESS,
  STORE as onbngStore, GET_LOCATION, SET_LOCATION
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {EventBus} from "@/main";
import { mapGetters } from 'vuex';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import LoadingSimple from "@/components/LoadingSimple";
import UserApi from "@/api/users/users.api";
import {
  SET_USER,
  STORE as SESSION_STORE,
} from '@/store/modules/session/session.types';

let interval;

export default {
  name: "ProcessingRequest",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    LoadingSimple,
  },
  data() {
    return {
      messageStatus: "register.processingRequest.inProcess",
      showVerify: true,
      isMoral: null
    }
  },
  computed: {
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      hasError: GET_ERROR_CARD_REQUEST,
      location: GET_LOCATION,
      isMoral2: GET_ACCOUNT_TYPE_BUSINESS

    }),
  },
  methods: {
    startCheck() {
      interval = setInterval(async () => {
        await this.$store.dispatch(`${onbngStore}/getProvisionalCards`, false);
      }, (1000 * 60 * 2));
      this.$store.dispatch(`${onbngStore}/getProvisionalCards`, false);
    },
    stopInterval(cardRequest) {
      if (cardRequest) {
        const status = cardRequest.status;
        if (status === "ACCEPTED" || status === "DELIVERED" || status === 'CANCELLED') {
            clearInterval(interval);
            this.pushMethod(cardRequest);
        }
      } else {
        clearInterval(interval);
      }
    },
    pushMethod(cardRequest) {
      let goTo = "successRequest";

          if(this.$route.name === 'processingRequestMultiple'){
            goTo = "successRequestMultiple"
          }
          if (cardRequest.accountType === 'BUSINESS') {
            if(this.$route.name === 'processingRequestMultiple'){
              goTo = "businessSuccessRequestMultiple"
            } else {
              if(this.$route.name === 'businessProcessingRequestMultiple') {
                goTo = "businessSuccessRequestMultiple";
              } else {
                goTo = "businessSuccessRequest";
              }
            }
          }
          this.$router.push({name: goTo});
    },
    async reSendCard() {
      // await this.$store.dispatch(`${onbngStore}/requestCard`, {
      //   provider: "PAGANDITO",
      //   longitud: this.location.longitud,
      //   latitud: this.location.latitud
      // });
      this.showVerify = false;
      this.displayNotificationInfo(this.$i18n.t("register.processingRequest.subtitle"));
      await this.$store.dispatch(`${onbngStore}/getProvisionalCards`, false);
      this.showVerify = true;
    },
    async finishCollaboratorOnboarding() {
      try {
        const response = await UserApi.finishCollaboratorOnboarding();
        const user = response.data.data.user;
        this.$store.commit(`${SESSION_STORE}/${SET_USER}`, user);
        if(user.errorOnboardingCollaborator) {
          this.$router.push({name: "successRequestCollaborator"});
        } else {
          this.$router.push({name: "onbngWelcomeCollaborator"});
        }
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    checkErrorRouteMounted() {
      if(this.hasError) {
        if(this.$route.name === 'processingRequestMultiple'){
          this.$router.push({name: "successRequestMultiple"});
        } else {
          if(this.$route.name === 'businessProcessingRequestMultiple') {
            this.$router.push({name: "businessSuccessRequestMultiple"});
          } else {
            this.$router.push({name: "successRequest"});
          }
        }
        // this.messageStatus = "Ocurrió un error al procesar tu solicitud.";
      } else {
        this.startCheck();
      }
    }
  },
  async mounted() {
    if(this.isMoral2) {
      this.isMoral = this.isMoral2 === 'MORAL';
    }

    if(this.level === 0) {
      this.finishCollaboratorOnboarding();
    } else {
      if(this.$route.name === 'processingRequestMultiple'){
        EventBus.$emit("hide-cancel-request", false);
        this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_PERSONAL');
      } else if(this.$route.name === 'businessProcessingRequestMultiple') {
        EventBus.$emit("hide-cancel-request", false);
        await this.$store.dispatch(`${onbngStore}/putLevel`, this.isMoral ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC');
      }
      this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
      this.checkErrorRouteMounted();
      EventBus.$on(`${onbngStore}.${LOAD_CARD_REQUEST}`, this.stopInterval);
    }
  },
  beforeMount() {
    if((!this.location.latitud || !this.location.longitud) && ("geolocation" in navigator)) {
      navigator.geolocation.getCurrentPosition((coords) => {
        this.$store.commit(`${onbngStore}/${SET_LOCATION}`, {latitud: coords.coords.latitude, longitud: coords.coords.longitude})
      });
    }
  },
  beforeDestroy() {
    EventBus.$off(`${onbngStore}.${LOAD_CARD_REQUEST}`, this.stopInterval);
  },
}
</script>

<style scoped>

</style>
