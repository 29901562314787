<template>
  <div class="row">
    <div class="col-12">
      <FloatingTitleLayout :titleStrong="$t('myPoints.title')"></FloatingTitleLayout>
    </div>
    <div class="col-12">
      <PanelPoints v-if="!loading"
          clazzIcon="icon-warning"
          icon="iconsax-like-medal-star"
          :textPoints="$t('rewardPoints.availablePoints')"
          :pointsNumber="$tc('rewardPoints.quantityPoints', availablePoints, {points: availablePoints})"
          :textEquiv="$t('rewardPoints.equivalence')"
          :pointsEquiv="$t('rewardPoints.equivalentPoints', {amount: currency(equivalentAmount)})">
      </PanelPoints>
      <LoadingBar v-else loadingText="Cargando..."></LoadingBar>
    </div>

    <div class="col-12 col-md-6 cards-same-height">
      <CardSimple
        :title="$t('myPoints.calculator.title')"
        :subtitle="$t('myPoints.calculator.subtitle')">
        <div class="row w-100" v-if="!loading">
          <div class="col-10 col-lg-5 d-flex flex-wrap justify-content-center">
            <div class="icon-bgm icon-warning m-b-15 d-none d-lg-flex">
              <i aria-hidden="true" class="iconsax-like-medal-star"></i>
            </div>
            <BasicInput
              clazz="w-100 mb-lg-0"
              v-model="pointsToCalculate"
              required
              inputId=""
              :label="$t('myPoints.calculator.points')"
              inputPlaceholder="0"></BasicInput>
          </div>
          <div class="col-2 col-lg-2 p-l-0 p-r-0 pb-lg-3 pb-5 d-flex justify-content-center align-items-end">
            <p class="f-12 c-text_title poppins-font-bold f-700 text-center w-100 m-b-0">=</p>
          </div>
          <div class="col-12 col-lg-5 d-flex flex-wrap justify-content-center">
            <div class="icon-bgm icon-success m-b-15 d-none d-lg-flex">
              <i aria-hidden="true" class="iconsax-money-currency-circle"></i>
            </div>
            <BasicInput
              clazz="w-100 mb-lg-0 m-b-0"
              required
              inputId=""
              :label="$t('myPoints.calculator.balance')"
              v-model="amount"
              inputPlaceholder="$0.00"></BasicInput>
          </div>
        </div>
        <LoadingBar v-else loadingText="Cargando..."></LoadingBar>
      </CardSimple>
    </div>
    <div class="col-12 col-md-6 cards-same-height">
      <CardSimple
        :title="$t('myPoints.section3.title')"
        :subtitle="$t('myPoints.section3.subtitle')">
        <div class="vertical-center flex-wrap-no column-gap-15px m-b-20">
          <div class="icon-bgm icon-warning xs">
            <i aria-hidden="true" class="iconsax-weather-lightning"></i>
          </div>
          <span>
            <label class="f-14 c-text_title poppins-font-semibold f-600 m-b-0">
              {{$t('myPoints.section3.servicesPay')}}
            </label>
            <p class="f-12 c-text_info poppins-font-medium f-500 m-b-0">
              {{$t('myPoints.section3.servicesPayDescription')}}
            </p>
          </span>
        </div>
        <div class="vertical-center flex-wrap-no column-gap-15px m-b-20">
          <div class="icon-bgm icon-info xs">
            <i aria-hidden="true" class="iconsax-shop-shop"></i>
          </div>
          <span>
            <label class="f-14 c-text_title poppins-font-semibold f-600 m-b-0">
              {{$t('myPoints.section3.selectedEstablishments')}}
            </label>
            <p class="f-12 c-text_info poppins-font-medium f-500 m-b-0">
              {{$t('myPoints.section3.selectedEstablishmentsDescription')}}
            </p>
          </span>
        </div>
        <div class="vertical-center flex-wrap-no column-gap-15px">
          <div class="icon-bgm icon-pink xs">
            <i aria-hidden="true" class="iconsax-shop-bag-timer"></i>
          </div>
          <span>
            <label class="f-14 c-text_title poppins-font-semibold f-600 m-b-0">
              {{$t('myPoints.section3.pastPurchases')}}
            </label>
            <p class="f-12 c-text_info poppins-font-medium f-500 m-b-0">
              {{$t('myPoints.section3.pastPurchasesDescription')}}
            </p>
          </span>
        </div>
      </CardSimple>
    </div>

    <div class="col-12">
      <div class="collapse-beneficiary">
          <div class="head flex-wrap-no">
              <span>
                  <p class="text-only">
                    {{$t('myPoints.section4.title')}}
                  </p>
              </span>
              <span>
                  <button
                      id=""
                      class="button-square xs button-stroke button-accent"
                      type="button"
                      name="Colapsar beneficiario"
                      aria-label="Colapsar beneficiario"
                      v-b-toggle.collapse-como-funciona>
                      <i aria-hidden="true" class="iconsax-arrow-direction-down"></i>
                      <i aria-hidden="true" class="iconsax-arrow-direction-up"></i>
                  </button>
              </span>
          </div>
          <b-collapse id="collapse-como-funciona" class="body">
            <ul class="checked-list m-b-30">
                <li v-html="$t('myPoints.section4.part1')">
                </li>
                <li v-html="$t('myPoints.section4.part2', {points: this.$tc('rewardPoints.quantityPoints', pointsGeneratedByPurchase, {points: pointsGeneratedByPurchase})})">
                </li>
                <li v-html="$t('myPoints.section4.part3')">
                </li>
                <li v-html="$t('myPoints.section4.part4')">
                </li>
            </ul>
            <p class="f-12 c-text_content poppins-font-regular f-400 m-b-0" v-html="$t('myPoints.section4.part5')">
            </p>
          </b-collapse>
      </div>
    </div>
    <div class="col-12">
      <div class="collapse-beneficiary">
          <div class="head flex-wrap-no">
              <span>
                  <p class="text-only">
                    {{$t('myPoints.section5.title')}}
                  </p>
              </span>
              <span>
                  <button
                      id=""
                      class="button-square xs button-stroke button-accent"
                      type="button"
                      name="Colapsar beneficiario"
                      aria-label="Colapsar beneficiario"
                      v-b-toggle.collapse-como-canjear>
                      <i aria-hidden="true" class="iconsax-arrow-direction-down"></i>
                      <i aria-hidden="true" class="iconsax-arrow-direction-up"></i>
                  </button>
              </span>
          </div>
          <b-collapse id="collapse-como-canjear" class="body">
            <ul class="checked-list">
                <li v-html="$t('myPoints.section5.part1')">
                </li>
                <li v-html="$t('myPoints.section5.part2')">
                </li>
                <li v-html="$t('myPoints.section5.part3')">
                </li>
                <li v-html="$t('myPoints.section5.part4')">
                </li>
            </ul>
          </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import PanelPoints from "@/components/PanelPoints.vue";
import CardSimple from "@/components/CardSimple.vue";
import BasicInput from "@/components/BasicInput.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import RewardPointsApi from '@/api/wallet/rewardPoints.api';
import { catchError } from "@/utils/utils";
import {STORE as sessionStore, GET_ACCOUNT} from "@/store/modules/session/session.types";
import {mapGetters} from "vuex";
import ToastSnotify from "@/mixins/toastSnotify.mixin";

export default {
  name: "MyPoints",
  mixins: [ToastSnotify],
  components: {
    FloatingTitleLayout,
    PanelPoints,
    CardSimple,
    BasicInput,
    LoadingBar
  },
  data() {
    return {
      availablePoints: 0,
      equivalentAmount: 0,
      pointsToCalculate: 0,
      amount: 0,
      timeoutAmount: null,
      loading: false,
      pointsGeneratedByPurchase: 0
    }
  },
  computed: {
    ...mapGetters(sessionStore, {
      account: GET_ACCOUNT
    }),
    currency() {
        return this.$options.filters.currency;
    }
  },
  methods: {
    async getDetails() {
      const res = await RewardPointsApi.getDetails(this.account.account._id, this.account.orgInfo?._id).catch(catchError);
      if(res && res.data && !res.data.error) {
          this.availablePoints = res.data.object.totalPoints.toLocaleString();
          this.pointsToCalculate = res.data.object.totalPoints.toLocaleString();
          this.equivalentAmount = res.data.object.valuePerPoint;
      } else {
          this.toastErrorDefault(this.$t('rewardPoints.error.getDetail'));
          this.$router.push({name: 'Dashboard'});
      }
    },
    calculateAmount(value) {
      this.amount = this.currency((value * this.equivalentAmount));
    },
    calculatePoints(value) {
      this.pointsToCalculate = this.equivalentAmount > 0 ? (value / this.equivalentAmount).toLocaleString() : '0';
    }
  },
  async created() {
    this.pointsGeneratedByPurchase = this.account.account.accountType.firstPoints;
    this.loading = true;
    await this.getDetails();
    this.loading = false;
  },
  watch: {
    'pointsToCalculate': function(val) {
      const valueToNumber = Number(val.replace(/,/g, ''));
        this.calculateAmount(valueToNumber);
    },
    'amount': function(val) {
        clearTimeout(this.timeoutAmount);
        this.timeoutAmount = setTimeout(() => {
          const valueToNumber = Number(val.replace(/[$,]/g, ''));
          this.calculatePoints(valueToNumber);
        }, 1000);
    }
  }
};
</script>
