<template>
<div>
  <TitlePrincipal
      :title="$t('register.occupation.title')"
      :subtitle="$t('register.occupation.subtitle')">
  </TitlePrincipal>
  <BasicSelect
      required
      :label="$t('register.occupation.selectName')">
<!--    economiActivityKey-->
    <multiselect
        v-model="valueOccupation"
        :options="optionsOccupation"
        :close-on-select="true"
        :preserve-search="true"
        :placeholder="loadingOptions ? $t('register.occupation.loadingOptions') : $t('register.occupation.selectNamePh')"
        label="description"
        track-by="_id"
        :preselect-first="false"
        :disabled="loadingOptions"
    >
      <template
          slot="selection"
          slot-scope="{ values, search, isOpen }"
      >
                                <span
                                    class="multiselect__single"
                                    v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} options selected</span>
      </template>
    </multiselect>
  </BasicSelect>
  <BasicTextarea
    required
    textareaId="occupationDescription"
    :label="$t('register.occupation.textArea')"
    v-model="description"
    :textareaPlaceholder="$t('register.occupation.textAreaPh')"
    :maxCount="0"
  />
  <div class="footer-buttons right">
    <button @click="back()" type="button" class="button button-ghost button-plain_text" name="Regresar">
      {{ $t('register.occupation.back') }}
    </button>
    <button v-if="!loadingButton" type="button" class="button button-filled button-primary" name="Continuar" :disabled="disableButton" @click="submitData">
      {{ $t('register.occupation.continue') }}
    </button>
    <button v-else :id="'Loading button'" class="button button-filled button-primary">
      <LoadingSimple clazz="small-load no-text"/>
    </button>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import BasicSelect from "@/components/BasicSelect";
import Multiselect from "vue-multiselect";
import LoadingSimple from "@/components/LoadingSimple";
import BasicTextarea from "@/components/BasicTextarea.vue";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import UserApi from "@/api/users/users.api";
import {catchError} from "@/utils/utils";
import {
  GET_CARD_REQUEST,
  GET_LEVEL,
  GO_TO_ROUTE,
  STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import {
  GET_FULL_DATA_USER,
  LOAD_FULL_DATA_USER,
  STORE as sessionStore
} from "@/store/modules/session/session.types";
import { mapGetters } from 'vuex';
import {EventBus} from "@/main";

export default {
  name: "Ocupation",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicSelect,
    Multiselect,
    LoadingSimple,
    BasicTextarea
  },
  data() {
    return {
      loadingButton: false,
      loadingOptions: false,
      valueOccupation: "",
      optionsOccupation: [
        {name: 'Arquitecto(a)' },
        {name: 'Doctor(a)' }
      ],
      description: '',
    }
  },
  computed: {
    ...mapGetters(onbngStore, {
      cardRequest: GET_CARD_REQUEST,
      cardLevel: GET_LEVEL
    }),
    ...mapGetters(sessionStore, {
      fullDataUser: GET_FULL_DATA_USER
    }),
    disableButton() {
      if(!this.description || this.description.length === 0) {
        return true;
      }
      return !this.valueOccupation;
    }
  },
  methods: {
    back() {
      if(this.cardLevel === 0) {
        this.$store.dispatch(`${onbngStore}/backCollaborator`)
      } else if(this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${onbngStore}/backBusiness`)
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`)
      }
    },
    async getOccupations() {
      this.loadingOptions = true;
      const response = await UserApi.getOccupation().catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
              this.displayNotificationError(this.$i18n.t("register.occupation.errorLoad"))
          } else {
            this.optionsOccupation = response.data.object;
          }
      }
      this.loadingOptions = false;
    },
    async submitData() {
      this.loadingButton = true;
      const response = await UserApi.saveOccupation({
        occupation: this.valueOccupation._id,
        descriptionOcupation: this.description
      }).catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(this.$i18n.t("register.occupation.errorSave"))
        } else {
          if(this.$route.name === 'occupationMultiple') {
            this._checkMultipleSuccess();
          } else if(this.$route.name === 'businessOccupationMultiple') {
            this.$router.push({name: 'businessSelectDocumentsMultiple'});
          } else {
            this._checkNormalSuccess();
          }
        }
      }
      this.loadingButton = false;
    },
    _checkMultipleSuccess() {
      this.$router.push({name: 'selectDocumentsMultiple'});
      // this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, 'selectDocumentsMultiple');
    },
    _checkNormalSuccess() {
      if (this.cardLevel >= 3) {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.ADD_DATA,
        });
        let goTo = "addInfo";
        if (this.cardRequest.accountType === "BUSINESS") {
            goTo = "businessAddInfo";
        }
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, goTo);
      } else if (this.cardLevel === 0) {
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "addInfoCollaborator");
      } else {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.PHONE_VERIFICATION,
        });
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "phoneVerification");
      }
    },
    assignData() {
      if(this.fullDataUser) {
        this.valueOccupation = this.fullDataUser.ocupacion;
        this.description = this.fullDataUser.descriptionOcupation
      }
    }
  },
  async beforeMount() {
    if(this.$route.name === 'occupationMultiple') {
      this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_PERSONAL');
    } else if(this.$route.name === 'businessOccupationMultiple') {
      await this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_BUSINESS_PHYSIC');
    }
    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);

    this.getOccupations();
    this.assignData();
    EventBus.$on(`${sessionStore}.${LOAD_FULL_DATA_USER}`, () => {
      this.assignData();
    });
  }
}
</script>

<style scoped>

</style>
