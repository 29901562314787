<template>
    <div class="form-group read-only" v-bind:class="clazz">
        <label :class="titleClazz"> {{title}} <span v-if="extraTitle" :class="extraTitleClass">{{extraTitle}}</span></label>
        <p v-if="currency" :class="textClazz"> {{text | currency}} </p>
        <p v-else :class="textClazz"> {{text}} </p>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'ReadOnlyInfo',
        props: {
            clazz: String,
            title: String,
            text: [String, Number],
            titleClazz: String,
            textClazz: String,
            currency: Boolean,
            extraTitle: String,
            extraTitleClass: String,
        }
    }
</script>
