<template>

  <!--------------------------------------------------------------------------------------------------------------->
  <!--                                       ___        _____     ___                                            -->
  <!--                                     .'   `.     |_   _|  .'   `.   _                                      -->
  <!--                                    /  .-.  \      | |   /  .-.  \ (_)                                     -->
  <!--                                    | |   | |  _   | |   | |   | |  _                                      -->
  <!--                                    \  `-'  / | |__' |   \  `-'  / (_)                                     -->
  <!--                                     `.___.'  `.____.'    `.___.'                                          -->
  <!--                                                                                                           -->
  <!--      ____  _____               _                                               _                          -->
  <!--     |_   \|_   _|             (_)                                             / |_                        -->
  <!--       |   \ | |     .--.      __    _ .--..--.    _ .--.     .--.    _ .--.  `| |-'  ,--.    _ .--.       -->
  <!--       | |\ \| |   / .'`\ \   [  |  [ `.-. .-. |  [ '/'`\ \ / /'`\ \ [ `/'`\]  | |   `'_\ :  [ `/'`\]      -->
  <!--      _| |_\   |_  | \__. |    | |   | | | | | |   | \__/ | | \__/ |  | |      | |,  // | |,  | |          -->
  <!--     |_____|\____|  '.__.'    [___] [___||__||__]  | ;.__/   '.__.'  [___]     \__/  \'-;__/ [___]         -->
  <!--                                                  [__|                                                     -->
  <!--                                                                                                           -->
  <!--      No importar nuevamente este componente en el proyecto. Ya esta importado globalmente en App.vue      -->
  <!--------------------------------------------------------------------------------------------------------------->


  <ModalAlert
    closeCross
    closeEsc
    modalId="modal-alertDefault"
    buttonType="button-primary"
    :title="$t('general.modalIdleWarning.title')"
    :text="$t('general.modalIdleWarning.text')"
    :buttonCancel="$t('general.modalIdleWarning.buttonCancel')"
    :buttonText="$t('general.modalIdleWarning.buttonText')"
    @cancel="logout"
    @submit="preserveSession"
  />
</template>
<script>
import ModalAlert from "@/components/ModalAlert.vue";

export default {
  name: 'ModalIdleWarning',
  components: {
    ModalAlert,
  },
  data() {
    return {
      isModalShown: false,
      /** @type {() => void} */
      onClickLogout: null,
      /** @type {() => void} */
      onClickPreserveSession: null,
    };
  },
  mounted() {
    this.$root.$on("bv::modal::shown", (_, modalId) => {
      if (modalId === 'modal-alertDefault') {
        this.isModalShown = true;
      }
    });
    this.$root.$on("bv::modal::hidden", (_, modalId) => {
      if (modalId === 'modal-alertDefault') {
        this.isModalShown = false;
      }
    });
  },
  methods: {
    // Opens the modal
    openModal({ onClickLogout, onClickPreserveSession }) {
      this.$bvModal.show('modal-alertDefault');
      this.onClickLogout = onClickLogout;
      this.onClickPreserveSession = onClickPreserveSession;
    },

    // Closes the modal
    closeModal() {
      this.onClickLogout = null;
      this.onClickPreserveSession = null;
      this.$bvModal.hide('modal-alertDefault');
    },

    // Called when the user clicks the logout button
    logout() {
      this.onClickLogout?.();
      this.closeModal();
    },

    // Called when the user clicks the preserve session button
    preserveSession() {
      this.onClickPreserveSession?.();
      this.closeModal();
    },
  },
}
</script>
