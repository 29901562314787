<template>
  <div>
    <TitlePrincipal
        :title="$t('register.signature.title')"
        :subtitle="$t('register.signature.subtitle')">
    </TitlePrincipal>
    <span class="vertical-center m-b-10">
      <p class="f-18 c-primary_1 poppins-font-semibold d-block m-b-5">
        {{ $t('register.signature.instruction') }}
      </p>
      <button type="button"
              class="button xs button-stroke button-primary m-0-0-0-auto"
              @click="undoSignature">
        {{ $t('register.signature.deleteSign') }}
      </button>
    </span>
    <div>
      <VueSignaturePad
          id="signaturePad"
          width="100%"
          height="300px"
          ref="signaturePad"
          :options="optionsPad"
      />
    </div>
    <div class="footer-buttons right">
      <button @click="back()" type="button" class="button button-ghost button-plain_text" name="Regresar">
        {{ $t('register.signature.back') }}
      </button>
      <button v-if="showButton" type="button" class="button button-filled button-primary" name="Continuar"
              @click="save">
        {{ $t('register.signature.next') }}
      </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text"/>
      </button>
    </div>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import VueSignaturePad from "vue-signature-pad";
import LoadingSimple from "@/components/LoadingSimple";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import UserApi from "@/api/users/users.api";
import DocumentApi from "@/api/core/documents.api";
import CouponsApi from "@/api/core/coupons.api";
import {catchError} from "@/utils/utils";
import {
  STORE as SESSION_STORE,
  GET_EMAIL_USER, GET_USER, SET_TEMP_WALLET_USER
} from '@/store/modules/session/session.types';
import {mapGetters} from 'vuex';
import {
  GET_ACCOUNT_TYPE,
  GET_CARD_REQUEST,
  GET_ACCOUNT_TYPE_BUSINESS,
  STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO
} from '@/store/modules/organizationInfo/organizationInfo.types';
import CardsApi from "@/api/wallet/card.api";
import OrganizationInfoApi from "@/api/core/organizationInfo.api";

export default {
  name: "Sign",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    VueSignaturePad,
    LoadingSimple
  },
  computed: {
    ...mapGetters(SESSION_STORE, {
      userEmail: GET_EMAIL_USER,
      user: GET_USER
    }),
    ...mapGetters(onbngStore, {
      accountType: GET_ACCOUNT_TYPE,
      cardRequest: GET_CARD_REQUEST,
      isMoral2: GET_ACCOUNT_TYPE_BUSINESS

    }),
    ...mapGetters(OrganizationInfoStore, {
      orgInfo: GET_ORGANIZATION_INFO
    }),
  },
  data() {
    return {
      showButton: true,
      optionsPad: {
        penColor: "#000",
      },
    }
  },
  methods: {
    back() {
      if (this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${onbngStore}/backBusiness`)
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`)
      }
    },
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
    },
    async save() {
      this.showButton = false;
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
      if (isEmpty) {
        this.displayNotificationError(this.$i18n.t('register.signature.notSign'))
      } else {
        const img = this.processImg(data);
        const response = await UserApi.saveDigitalSign({
          digitalSign: {
              name: `sign-${this.cardRequest.pagandoAccount}-${this.user._id}`,
            "type": "png",
            "size": img.length,
            "data": img
          }
        }).catch(catchError);
        if (response && response.data) {
          if (response.data.error) {
            this.displayNotificationError(this.$i18n.t('register.signature.errorSave'))
          } else {
            await this.acceptContract()
          }
        }
      }
      this.showButton = true;
    },
    async acceptContract() {
      const response = await DocumentApi.acceptContract({
        documentType: "CONTRACT",
        appliesTo: "WALLET",
        cardRequestId: this.cardRequest._id,
        discriminators: [this.accountType._id || this.accountType],
        accountId: this.cardRequest.pagandoAccount,
        profileType: "USER"
      }).catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(this.$i18n.t('register.signature.errorContract'))
        } else {
          await this.updateInfoBusiness();
          await this.updateUserWallet();
        }
      }
    },
    async updateInfoBusiness() {
      if(this.cardRequest.accountType === 'BUSINESS' && !this.cardRequest.isMoral && this.orgInfo) {
        await OrganizationInfoApi.copyInfo({
          organizationId: this.orgInfo._id
        }).catch(catchError);
      }
    },
    async updateUserWallet() {
      const responseUpdate = await CardsApi.updateUserWallet({
        requestCard: this.cardRequest._id
      });
      if (responseUpdate && responseUpdate.data) {
        if (responseUpdate.data.error) {
          this.displayNotificationError(this.$i18n.t('register.signature.errorContract'))
        } else {
          if(this.$route.name === 'signMultiple') {
            this.$store.commit(
              `${onbngStore}/SET_CARD_REQUEST`,
              ''
            );
            this.$router.push({name: 'onbngWelcomeMultiple'});
          } else {
            if(this.$route.name === 'businessSignMultiple') {
              this.$store.commit(
                `${onbngStore}/SET_CARD_REQUEST`,
                ''
              );
              this.$router.push({name: 'businessOnbngWelcomeMultiple'});
            } else {
              this.$store.commit(`${SESSION_STORE}/${SET_TEMP_WALLET_USER}`, true);
              await this.checkCampaigns();
            }
          }
        }
      } else {
        this.displayNotificationError('Ocurrió un error al firmar contrato')
      }
    },
    async checkCampaigns() {
      const responseCampaigns = await CouponsApi.getPublicCampaigns({type: 'REFERRED'});
      let goTo = "onbngWelcome";
      if (this.cardRequest.accountType === 'BUSINESS') {
        goTo = "businessOnbngWelcome"
      }
      if (responseCampaigns && responseCampaigns.data) {
        if (responseCampaigns.data.error || responseCampaigns.data.object.length === 0) {
          if (this.cardRequest.accountType === 'BUSINESS') {
            goTo = "businessOnbngWelcome"
          } else {
            goTo = "onbngWelcome";
          }
        } else {
          if (this.cardRequest.accountType === 'BUSINESS') {
            goTo = "businessPromotionalCode"
          } else {
            goTo = "promotionalCode"
          }
        }
      }
      this.$router.push({name: goTo});
    },
    processImg(img) {
      if (/^data:image\/png;base64,/.test(img)) {
        return img.replace(/^data:image\/png;base64,/, '');
      }
      return img
    },
  },
  async beforeMount() {
    if(this.isMoral2) {
      this.isMoral = this.isMoral2 === 'MORAL';
    }

    const routeName = this.$route.name;
    if(routeName === 'signMultiple'){
      this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_PERSONAL');
    } else if(routeName === 'businessSignMultiple') {
      await this.$store.dispatch(`${onbngStore}/putLevel`, this.isMoral ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC');
    }

    this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
  }
}
</script>

<style scoped>

</style>
