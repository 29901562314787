<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/settings"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('settings.goBack')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('settings.notifications.title')"></FloatingTitleLayout>
                        </div>
                        <div class="col-12">
                            <CardSimple :title="$t('settings.notifications.typesTitle')" :subtitle="$t('settings.notifications.typesText')">
                                <div class="d-flex justify-content-between align-items-center border-bottom-1px p-t-10 p-b-10">
                                    <div class="c-text_content f-12 poppins-font-semibold d-flex align-items-center">
                                        <i aria-hidden="true" class="iconsax-notifications-bell m-r-20 f-18"></i>
                                        {{$t('settings.notifications.type1')}}
                                    </div>
                                    <BasicSwitch @onBasicSwitchChange="changeEmergentNotificationEvent"
                                        :switchValue="emergentNotification" :returnOriginValue="returnOriginValueEmergent" idInput="1"></BasicSwitch>
                                </div>
                                <div class="d-flex justify-content-between align-items-center border-bottom-1px p-t-10 p-b-10">
                                    <div class="c-text_content f-12 poppins-font-semibold d-flex align-items-center">
                                        <i aria-hidden="true" class="iconsax-emails-sms m-r-20 f-18"></i>
                                        {{$t('settings.notifications.type2')}}
                                    </div>
                                    <BasicSwitch @onBasicSwitchChange="changeEmailNotificationEvent"
                                        :switchValue="user ? user.emailNotification : false" :returnOriginValue="returnOriginValue" idInput="2"></BasicSwitch>
                                </div>
                            </CardSimple>
                        </div>
                        <div class="col-12">
                            <div class="control-buttons">
                                <button
                                    @click="goBack()"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('settings.notifications.back')}}
                                </button>
                                <div class="f-13 c-text_content poppins-font-medium text-center text-md-right">
                                    {{$t('settings.notifications.needChange')}}
                                    <a
                                            href="https://www.pagando.mx/contacto-pagando/"
                                            class="c-info poppins-font-bold text-underline hover"
                                            tabindex="">
                                            {{$t('settings.notifications.contactUs')}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Modal Desactivar -->
            <ModalAlert
                closeCross
                modalId="modal-desactivar-notificaciones"
                buttonType="button-primary"
                :imgUrl="imageModalTrash"
                :title="$t('settings.notifications.deactivateModal.title')"
                :text="$t('settings.notifications.deactivateModal.text')"
                :buttonCancel="$t('settings.notifications.deactivateModal.cancel')"
                :buttonText="$t('settings.notifications.deactivateModal.button')"
                @cancel="cancelChangeNotification()"
                @submit="deactiveNotification()"
            ></ModalAlert>
        </section>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>

    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import BasicSwitch from "@/components/BasicSwitch.vue";
    import ModalAlert from "@/components/ModalAlert.vue";
    import imgStatus from '@/assets/images/Illustrations/illustration-gift.svg';
    import imageModalTrash from '@/assets/images/Icons/icon-alert.svg';
        import {
        STORE as sessionStore,
        GET_ACCOUNT,
        GET_USER,
        SET_NOTIFICATION_EMAIL
    } from "@/store/modules/session/session.types";
    import {mapGetters} from "vuex";
    import userApi from "@/api/users/users.api";
    import { catchError } from "@/utils/utils";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";

    const modalNotification = 'modal-desactivar-notificaciones';

    export default {
        name: "Notifications",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            CardSimple,
            BasicSwitch,
            ModalAlert,
        },
        computed: {
            ...mapGetters(sessionStore, {
                account: GET_ACCOUNT,
                user: GET_USER
            }),
        },
        data() {
            return {
                // Images
                imgStatus: imgStatus,
                imageModalTrash: imageModalTrash,
                window: {
                    width: 0
                },
                isMenuOpen: false,
                isEmailNotification: false,
                isEmergentNotification: false,
                returnOriginValue: false,
                returnOriginValueEmergent: false,
                emergentNotification: false
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            async deactiveNotification() {
                this.$bvModal.hide(modalNotification);
                const auth = await this.$requestAuth();

                if(auth) {
                    if(this.isEmailNotification) {
                        await this.changeEmailNotification(false);
                    }
                    if(this.isEmergentNotification) {
                        this.emergentNotification = false;
                        localStorage.setItem('emergentNotification', this.emergentNotification);
                    }
                } else {
                    if(this.isEmailNotification) {
                    this.returnOriginValue = true;
                    }

                    if(this.isEmergentNotification) {
                        this.returnOriginValueEmergent = true;
                    }
                }
            },
            async changeEmailNotification(value) {
                const res = await userApi.changeEmailNotification({emailNotification: value}).catch(catchError);

                if(res && res.data && !res.data.error) {
                    this.$store.commit(`${sessionStore}/${SET_NOTIFICATION_EMAIL}`, value);
                } else {
                    if(value === true) {
                        this.toastErrorDefault(this.$t('settings.notifications.errorActive'));
                    } else {
                        this.toastErrorDefault(this.$t('settings.notifications.errorDeactive'));
                    }
                }
            },
            async changeEmailNotificationEvent(event) {
                this.returnOriginValue = false;
                if(event === false) {
                    this.isEmailNotification = true;
                    this.isEmergentNotification = false;
                    this.$bvModal.show(modalNotification);
                } else {
                    await this.changeEmailNotification(event);
                }
            },
            async changeEmergentNotificationEvent(event) {
                this.returnOriginValueEmergent = false;

                if(event === false) {
                    this.isEmailNotification = false;
                    this.isEmergentNotification = true;
                    this.$bvModal.show(modalNotification);
                } else {
                    this.emergentNotification = event;
                    localStorage.setItem('emergentNotification', this.emergentNotification);
                }
            },
            cancelChangeNotification() {
                if(this.isEmailNotification) {
                    this.isEmailNotification = false;
                    this.returnOriginValue = true;
                    this.$store.commit(`${sessionStore}/${SET_NOTIFICATION_EMAIL}`, this.user.emailNotification);
                }

                if(this.isEmergentNotification) {
                    this.isEmergentNotification = false;
                    this.returnOriginValueEmergent = true;
                }

            },
            goBack() {
                this.$router.push({name: 'Settings'});
            }
        },
        created() {
            this.emergentNotification = localStorage.getItem('emergentNotification') === 'true' ? true : false;
        },
        mounted () {
            this.loading = true;
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        },
    };
</script>
