import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'users';

export default {
    getUserByEmail: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-user-by-email`, params)
    },
    save: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/register`, params)
    },
    resendVerificationEmail: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/resend-verification-email`, params)
    },
    confirmEmail: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirmEmail`, params)
    },
    login: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/login-v2`, params)
    },
    getCurrentUserSession: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-current-user-session`, params)
    },
    saveNationality: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-two/save-step-nationality`, params)
    },
    savePersonalData: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-two/save-personal-information`, params)
    },
    saveResidence: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-two/save-residence-place`, params)
    },
    getOccupation: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/occupations`, params)
    },
    saveOccupation: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-two/save-occupation`, params)
    },
    sendCode: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/send-code`, params)
    },
    verifyCode: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/verify-code`, params)
    },
    reSendCode: (params) => {
        return axios.post(`${base.baseUrl}/payment-process/send-sms`, params)
    },
    updateSecurityMeasure: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/update-security-measure`, params);
    },
    savePin: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-two/save-pin`, params);
    },
    validatePin: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/validate-pin`, params);
    },
    saveDigitalSign: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-three/save-digital-sign`, params);
    },
    finishLevelOne: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-one/finish-user-wizard`, params);
    },
    finishLevelTwo: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-two/finish-user-wizard`, params);
    },
    finishLevelThree: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-three/finish-user-wizard`, params);
    },
    saveDocuments: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-file-documents`, params);
    },
    finishCollaboratorDocuments: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/finish-collaborator-documents`, params);
    },
    getFullDataLevelTwo: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/get-full-user-level-two`, params);
    },
    getFullDataLevelThree: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/get-full-user-level-three`, params);
    },
    generateUrlQR: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/url-for-qr`, params)
    },
    getInfoQR: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-info-qr`, params)
    },
    verifyDocument: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/verify-document`, params)
    },
    updateOnbLocation: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/update-onb-web-location`, params)
    },
    savePersonalDataLevel3: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-three/save-additional-information`, params)
    },
    recoverPhone: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/recovery-user-phone`, params)
    },
    recoverPass: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/recoveryPassword`, params)
    },
    confirmPasswordRestore: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/confirmRestorePassword`, params)
    },
    saveBeneficiaryAsList: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-three/save-beneficiaries-information`, params)
    },
    beneficiaryList: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/level-three/beneficiaries-list`, params)
    },
    changeEmailNotification: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/change-email-notification`, params)
    },
    getReferralCode: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/referral-code/`, params)
    },
    changePass: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/restore-password-manual`, params)
    },
    recoverPassOnlyEmail: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/recoveryPassword-mobile`, params)
    },
    changeSMSAsTwoFactor: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/sms-as-two-factor`, params)
    },
    changeFingerPrintAuth: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/finger-print-auth`, params)
    },
    changeFacialRecognitionAuth: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/facial-recognition-auth`, params)
    },
    checkActiveSessions: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/check-access`, params)
    },
    disableAccess: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/disable-access`, params)
    },
    logout: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/logout-v2`, params)
    },
    getCollaboratorByToken: (token) => {
        return axios.get(`${base.baseUrl}/${namespace}/find-collaborator-email/${token}`)
    },
    registerCollaborator: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/register-collaborator`, params)
    },
    setCollaboratorSecurity: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/collaborator-security`, params)
    },
    finishCollaboratorOnboarding: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/finish-collaborator-onboarding`, params)
    },
    updateStepOnbCollab: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/update-step-onb-collab`, params)
    },
    registerDevice: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/update-thrustedDevice`, params)
    },
    deleteAllInfoForUser: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/delete-all-for-user`, params)
    },
    updateOnbTypeUser: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/update-onb-type-user`, params)
    },
    preserveSession: () => {
        return axios.post(`${base.baseUrl}/${namespace}/preserve-session`)
    },
}
