<template>
  <div>
    <div class="register-container">
      <LeftSide></LeftSide>
      <RightSide></RightSide>
      <Footer></Footer>
    </div>
    <FooterPage></FooterPage>
    <vue-snotify></vue-snotify>
  </div>

</template>

<script>

import LeftSide from "@/pages/onboarding/LeftSide";
import RightSide from "@/pages/onboarding/RightSide";
import Footer from "@/pages/onboarding/Footer";
import FooterPage from "@/components/FooterPage.vue";
import {
  STORE as onboardingStepsStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";

export default {
  name: "OnboardingCollaborator",
  components: {
    LeftSide,
    RightSide,
    Footer,
    FooterPage
  },
  created() {
    this.$store.dispatch(`${onboardingStepsStore}/putLevel`, 'LEVEL_ZERO');
  }
}
</script>

<style scoped>

</style>
