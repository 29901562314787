<template>
  <div>
    <div v-if="loadingInfo">
      <LoadingBar
        clazz="p-t-50 p-b-50"
        :loadingText="$t('register.addInfo.loadingInfo')"
      ></LoadingBar>
    </div>
    <div v-if="!loadingInfo && dynamicOnbng.steps.length">
      <div v-if="isNormal">
        <TitlePrincipal
          :title="currentStep.title"
          :subtitle="currentStep.description"
        >
        </TitlePrincipal>
        <DynamicOnboarding
          :current-step="currentStep"
          :onbngData="onbngData"
          :temp-model="tempModel"
          @update-parent="updateParent"
        ></DynamicOnboarding>
      </div>
      <div v-if="!isNormal">
        <div v-for="(step, index) in dynamicOnbng.steps" :key="index">
          <TitlePrincipal :title="step.title" :subtitle="step.description">
          </TitlePrincipal>
          <DynamicOnboarding
            :current-step="step"
            :onbngData="onbngData"
            :temp-model="tempModel"
            @update-parent="updateParent"
            :id="index"
          ></DynamicOnboarding>
        </div>
      </div>
      <div class="footer-buttons right" v-if="isNormal">
        <button
          type="button"
          class="button button-ghost button-plain_text"
          name="Regresar"
          @click="back"
        >
          {{ $t("register.addInfo.back") }}
        </button>
        <button
          v-if="showNext"
          type="button"
          class="button button-filled button-primary"
          name="Continuar"
          @click="submit"
        >
          {{ $t("register.addInfo.next") }}
        </button>
        <button
          v-else
          :id="'Loading button'"
          class="button button-ghost button-plain_text"
        >
          <LoadingSimple clazz="small-load no-text" />
        </button>
      </div>
    </div>
    <div v-if="!loadingInfo && dynamicOnbng.steps.length === 0">
      <EmptyState clazz="m-t-70" :title="emptyStateTitle" text=""></EmptyState>
    </div>
  </div>
</template>

<script>
import RandExp from "randexp";
import TitlePrincipal from "@/components/TitlePrincipal";
import LoadingSimple from "@/components/LoadingSimple";
import LoadingBar from "@/components/LoadingBar";
import EmptyState from "@/components/EmptyState";

import DynamicOnboarding from "@/components/extraInfo/DynamicOnboarding";

import ToastSnotify from "@/mixins/toastSnotify.mixin";
import OnboardingData from "@/api/core/onboardingData.api";
import {
  GET_LEVEL,
  GET_CARD_REQUEST,
  GO_TO_ROUTE,
  GET_LOCATION,
  GET_ACCOUNT_TYPE_BUSINESS,
  GET_SKIPPED_DYNAMIC,
  STORE as onbngStore,
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import { TYPE_DATA } from "@/enums/onbngDynamicData.enum";
import {
  catchError,
  isBoolean,
  stringToBoolean,
  isDefinedNonEmptyStr,
} from "@/utils/utils";
import {
  STORE as sessionStore,
  GET_USER,
  GET_COLLAB_ACCOUNT,
} from "@/store/modules/session/session.types";
import { mapGetters } from "vuex";
import { EventBus } from "@/main";
import { ONBOARDING_STEPS } from "@/utils/onbngSteps";

export default {
  name: "AddInfo",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    LoadingSimple,
    LoadingBar,
    EmptyState,
    DynamicOnboarding,
  },
  props: {
    isNormal: { type: Boolean, default: true },
    isLegalRepresentative: { type: Boolean, default: false },
    shareHolderId: { type: String },
  },
  data() {
    return {
      loadingInfo: true,
      showNext: true,
      currentStep: {
        questions: [],
      },
      currentStepIndex: 0,
      maxStep: 0,
      dynamicOnbng: {
        steps: [],
      },
      onbngData: {},
      tempModel: {},
      emptyStateTitle: this.$i18n.t("register.addInfo.emptyState.title"),
      isMoral: null,
    };
  },
  computed: {
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      cardRequest: GET_CARD_REQUEST,
      location: GET_LOCATION,
      skippedDynamic: GET_SKIPPED_DYNAMIC,
      isMoral2: GET_ACCOUNT_TYPE_BUSINESS,
    }),
    ...mapGetters(sessionStore, {
      user: GET_USER,
      accountCollab: GET_COLLAB_ACCOUNT,
    }),
  },
  methods: {
    /**
     * Navega hacia atrás entre los pasos del onboarding dynamic
     * o hacia el paso anterior del flujo principal de registro
     */
    back() {
      if (this.currentStepIndex === 0) {
        if (this.isNormal) {
          if (this.level === 0) {
            this.$store.dispatch(`${onbngStore}/backCollaborator`);
          } else if (
            this.$route.name === "businessAddInfoMultiple" ||
            this.$route.name === "businessAddInfo"
          ) {
            this.$store.dispatch(`${onbngStore}/backBusiness`);
          } else {
            this.$store.dispatch(`${onbngStore}/backPersonal`);
          }
        } else {
          this.displayNotificationWarning(
            this.$i18n.t("register.addInfo.warningBack")
          );
        }
      } else {
        this.setCurrentStep(this.currentStepIndex - 1);
      }
    },
    /**
     * Function para actualizar el DOM del componente
     * Padre
     */
    updateParent() {
      this.$forceUpdate();
    },
    _pushDependentQuestions(c) {
      if (c.hasDependentQuestions === true) {
        for (const cd of c.dependentQuestions) {
          if (!this.onbngData[cd._id]) {
            this.onbngData[cd._id] = {
              idQuestion: cd._id,
              data: null,
            };
          }
        }
      }
    },
    /**
     * Cambia el paso que actualmente se muestra
     * @param step
     */
    setCurrentStep(step) {
      if (
        this.dynamicOnbng.steps.length &&
        step < this.dynamicOnbng.steps.length
      ) {
        this.currentStep = this.dynamicOnbng.steps[step];
        for (const c of this.currentStep.questions) {
          if (!this.onbngData[c._id]) {
            this.onbngData[c._id] = {
              idQuestion: c._id,
              data: null,
            };

            this._pushDependentQuestions(c);
          }
        }
        this.currentStepIndex = step;
      }
    },
    /**
     * Válida que toda la información recopilada
     * en el objeto onbngData sea correcta
     * @return {*}
     */
    validateData() {
      const required = [];
      const notRequired = [];
      for (const steps of this.dynamicOnbng.steps) {
        const requiredQ = this.filterRequiredQuestions(steps.questions);
        required.push(...requiredQ.required);
        notRequired.push(...requiredQ.notRequired);
      }
      const validRequired = this.validateRequireData(required);
      const validNotRequired = this.validateNotRequiredData(notRequired);
      return validRequired && validNotRequired;
    },
    /**
     * Filtra aquellas preguntas que sean requeridas
     * @param questions
     * @return {{notRequired: *[], required: *[]}}
     */
    filterRequiredQuestions(questions) {
      const required = [];
      const notRequired = [];
      const embebedRequired = [];
      const embebedNotRequired = [];
      for (const element of questions) {
        if (element.isRequired) {
          required.push(element);
        } else {
          notRequired.push(element);
        }
        if (
          element.type === TYPE_DATA.BOOLEAN &&
          this.onbngData[element._id]?.data ===
            stringToBoolean(element.dependentQuestionsAppearsWhenAnswerIs) &&
          element.dependentQuestions.length
        ) {
          embebedRequired.push(
            ...element.dependentQuestions.filter((q) => q.isRequired)
          );
          embebedNotRequired.push(
            ...element.dependentQuestions.filter((q) => !q.isRequired)
          );
        }
      }
      return {
        required: [...required, ...embebedRequired],
        notRequired: [...notRequired, ...embebedNotRequired],
      };
    },
    /**
     * Válida que las respuestas de las preguntas
     * requeridas sean correctas
     * @param questions
     * @return {boolean|*}
     */
    validateRequireData(questions) {
      const requiredOnData = questions.filter((q) =>
        isDefinedNonEmptyStr(this.onbngData[q._id]?.data)
      );
      if (questions.length === requiredOnData.length) {
        return this.checkValidationData(requiredOnData);
      }
      const idsRequired = requiredOnData.map((d) => d._id);
      const need = questions.find((q) => !idsRequired.includes(q._id));
      this.displayNotificationWarning(
        this.$i18n.t("register.addInfo.error.requiresQuestion", {
          title: need.title,
        })
      );
      return false;
    },
    /**
     Válida que las respuestas de las preguntas
     no requeridas sean correctas
     * @param questions
     * @return {boolean|*}
     */
    validateNotRequiredData(questions) {
      const notRequiredOnData = questions.filter((q) =>
        isDefinedNonEmptyStr(this.onbngData[q._id]?.data)
      );
      if (notRequiredOnData.length) {
        return this.checkValidationData(notRequiredOnData);
      }
      return true;
    },
    /**
     * Método para evaluar la respuesta de una pregunta
     * @param questions
     * @return {boolean}
     */
    checkValidationData(questions) {
      let valid = false;
      let lastQuestion;
      let lastValue;
      for (const question of questions) {
        const value = this.onbngData[question._id].data;
        if (question.type === TYPE_DATA.BOOLEAN) {
          valid = isBoolean(value);
        }

        if (question.type === TYPE_DATA.TEXT) {
          valid = this.validTextByType(question, value);
        }

        if (question.type === TYPE_DATA.ENUM) {
          valid = this.validEnumType(question, value);
        }

        if (
          TYPE_DATA.DOCUMENT === question.type ||
          TYPE_DATA.IMAGES === question.type
        ) {
          valid = this.validDocument(value);
        }

        if (!valid) {
          lastQuestion = question;
          lastValue = value;
          break;
        }
      }
      if (!valid && lastQuestion) {
        this.printWarnLastQuestion(lastQuestion, lastValue);
      }
      return valid;
    },
    /**
     * Método para evaluar la respuesta de una pregunta tipo texto
     * @param question
     * @param value
     * @return {boolean}
     */
    validTextByType(question, value) {
      if (!value) {
        return false;
      }
      if (question.hasCustomValidation) {
        const regex = new RegExp(question.regularExpression);
        return regex.test(value);
      }
      return value.length <= Number(question.maxCharacters);
    },
    /**
     * Método para evaluar la respuesta de una pregunta tipo enum
     * @param question
     * @param value
     * @return {boolean|*}
     */
    validEnumType(question, value) {
      if (!value) {
        return false;
      }
      if (question.enumOptions.length) {
        return question.enumOptions.includes(value);
      }
      return false;
    },
    /**
     * Método para evaluar la respuesta de una pregunta tipo documento o imagen
     * @param value
     * @return {boolean}
     */
    validDocument(value) {
      return !!value;
    },
    /**
     * Muestra un mensaje si alguna respuesta no cumple con el formato
     * @param lastQuestion
     * @param value
     */
    printWarnLastQuestion(lastQuestion, value) {
      if (!value) {
        this.displayNotificationWarning(
          this.$i18n.t("register.addInfo.error.formatResponseEmpty", {
            title: lastQuestion.title,
          })
        );
        return;
      }
      if (lastQuestion.hasCustomValidation) {
        this.displayNotificationWarning(
          this.$i18n.t("register.addInfo.error.formatResponseCustom", {
            title: lastQuestion.title,
            hint: this.getHintWarn(lastQuestion),
          })
        );
      } else {
        this.displayNotificationWarning(
          this.$i18n.t("register.addInfo.error.formatResponseMax", {
            title: lastQuestion.title,
            max: lastQuestion.maxCharacters,
          })
        );
      }
    },
    /**
     * Método que genera un string con base en un regex
     * @param question
     * @return {string}
     */
    getHintWarn(question) {
      let stringReg = new RandExp(question.regularExpression).gen();
      if (question.regularExpression.includes("\\s")) {
        stringReg = stringReg.replace(/\s/g, "");
      }
      return stringReg;
    },
    /**
     *
     */
    async submit() {
      if (this.currentStepIndex < this.dynamicOnbng.steps.length - 1) {
        this.setCurrentStep(this.currentStepIndex + 1);
      } else {
        if (this.isNormal) {
          await this.submitNormal();
        } else {
          this.submitShareHolder();
        }
      }
      this.showNext = true;
    },
    submitShareHolder() {
      const valid = this.validateData();
      if (valid) {
        const values = Object.values(this.onbngData);
        return {
          valid: valid,
          values: values,
          dynamicOnboarding: this.dynamicOnbng._id,
        };
      }
      return { valid: valid };
    },
    sendRequestCard() {
      let isMoral = false;
      if (this.cardRequest.isMoral) {
        isMoral = true;
      }

      this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
        stepOnbng: ONBOARDING_STEPS.WAIT_REQUEST,
      });

      this.$store
        .dispatch(`${onbngStore}/requestCard`, {
          provider: "PAGANDITO",
          longitud: this.location.longitud,
          latitud: this.location.latitud,
          isMoral: isMoral,
          isMultipleAccount:
            this.$route.name === "addInfoMultiple" ||
            this.$route.name === "businessAddInfoMultiple",
        })
        .then(() => {});

      if (this.$route.name === "businessAddInfoMultiple") {
        this.$router.push({ name: "businessProcessingRequestMultiple" });
      } else {
        this.$router.push({ name: "processingRequestMultiple" });
      }
    },
    async submitNormal() {
      this.showNext = false;
      const valid = this.validateData();
      if (valid) {
        const values = Object.values(this.onbngData);
        const response = await OnboardingData.createData({
          onboarding: this.dynamicOnbng._id,
          questions: values,
        }).catch(catchError);
        if (response && response.data.error) {
          const message =
            response.data.message || this.$i18n.t("register.addInfo.errorSave");
          this.displayNotificationError(message);
          return;
        }
        if (this.level === 0) {
          this.$store.dispatch(
            `${onbngStore}/${GO_TO_ROUTE}`,
            "phoneVerificationCollaborator"
          );
          return;
        }
        await this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.PHONE_VERIFICATION,
        });
        let goTo = "phoneVerification";
        if (this.cardRequest.accountType === "BUSINESS") {
          goTo = "businessPhoneVerification";
        }

        if (
          this.$route.name === "addInfoMultiple" ||
          this.$route.name === "businessAddInfoMultiple"
        ) {
          this.sendRequestCard();
        } else {
          this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, goTo);
        }
      }
    },
    /**
     * Busca el último DynamicOnbording activo
     * @return {Promise<void>}
     */
    async getDynamicOnboarding() {
      const product = this.isNormal
        ? "WALLET"
        : this.isLegalRepresentative
        ? "LEGAL_REPRESENTATIVE"
        : "SHAREHOLDERS";
      let timeout = false;
      const accountType =
        this.$route.name === "addInfoCollaborator"
          ? this.accountCollab
          : this.cardRequest.cardTemplate.accountType._id;
      const response = await OnboardingData.getActive(
        product,
        accountType
      ).catch((err) => {
        // Check for timeout
        if (err.code === "ECONNABORTED") {
          timeout = true;
        }
        return catchError(err);
      });

      if (timeout) {
        this.loadingInfo = false;
        this.displayNotificationWarning(
          this.$i18n.t("register.addInfo.error.dynamicOnbng")
        );
        return;
      }
      const dynamicOnbng = response?.data?.object;
      if (response?.data?.error || !dynamicOnbng) {
        this._skipStep();
        return;
      }
      this.dynamicOnbng = dynamicOnbng;
      this.$store.commit(
        `${onbngStore}/SET_ONB_DYNAMIC_COLLAB`,
        this.dynamicOnbng?._id
      );
      this.maxStep = this.dynamicOnbng?.steps?.length;
      this.setCurrentStep(this.currentStepIndex);
      this.onbngData = {};
      this.tempModel = {};
      this.$forceUpdate();
      EventBus.$emit("radio-forceSearchValue");
      if (this.isNormal) {
        await this.getUserData();
      } else {
        await this.getUserData(this.shareHolderId);
      }
      this.loadingInfo = false;
    },
    /**
     * Se saltea este paso sin subir nada y redirecciona a la siguiente pantalla
     *
     * ****************** NO SE PUEDE SALTEAR NINGUN PASO DEL ONBOARDING. *****************
     */
    async _skipStep() {
      this.displayNotificationError(
        "Ocurrió un error, por el momento no se puede continuar con el proceso de registro. Intentalo más tarde"
      );
      // this.$store.commit(`${onbngStore}/SET_SKIPPED_DYNAMIC`, true);
      if (this.level === 0) {
        this.$store.dispatch(
          `${onbngStore}/${GO_TO_ROUTE}`,
          "occupationCollaborator"
        );
        return;
      }
      await this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
        stepOnbng: ONBOARDING_STEPS.JOB,
      });
      let goTo = "occupation";
      if (this.cardRequest.accountType === "BUSINESS") {
        goTo = "businessOccupation";
      }

      if (this.$route.name === "addInfoMultiple") {
        goTo = "occupationMultiple";
      }

      if (this.$route.name === "businessAddInfoMultiple") {
        goTo = "businessOccupationMultiple";
      }
      // this.sendRequestCard();
      // } else {
      this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, goTo);
      // }
    },
    async getUserData(id) {
      const userId = id || this.user._id;
      const response = await OnboardingData.getData(userId, {
        onb: this.dynamicOnbng._id,
        fromWeb: true,
        isNormal: this.isNormal,
        isLegalRepresentative: this.isLegalRepresentative,
      }).catch(catchError);
      if (response && response.data && response.data.error) {
        this.displayNotificationWarning(
          this.$i18n.t("register.addInfo.error.getData")
        );
      } else {
        const userData = response.data.object || [];
        if (userData.length) {
          for (const userDatum of userData) {
            this.onbngData[userDatum.idQuestion] = {
              idQuestion: userDatum.idQuestion,
              data: userDatum.answer,
            };
            if (userDatum.isFile) {
              this.tempModel[userDatum.idQuestion] = {
                value: userDatum.answer,
                progress: 100,
                clazz: "icon-bgm-success",
              };
            } else {
              this.tempModel[userDatum.idQuestion] = userDatum.answer;
            }
          }
          this.$forceUpdate();
          EventBus.$emit("radio-forceSearchValue");
        }
      }
    },
    getUserDataShareHolder() {
      this.getUserData(this.shareHolderId);
    },
  },
  watch: {
    shareHolderId: function (newVal) {
      this.getUserDataShareHolder(newVal);
    },
    isLegalRepresentative: async function () {
      await this.getDynamicOnboarding();
    },
  },
  created() {
    // if(this.skippedDynamic) {
    //   this.$store.commit(`${onbngStore}/SET_SKIPPED_DYNAMIC`, false);
    //   this.back();
    //   return;
    // }
    this.getDynamicOnboarding();
  },
  async beforeMount() {
    if (this.isMoral2) {
      this.isMoral = this.isMoral2 === "MORAL";
    }
    if (this.isNormal) {
      if (this.$route.name === "addInfoMultiple") {
        this.$store.dispatch(`${onbngStore}/putLevel`, "MULTIPLE_PERSONAL");
      } else if (this.$route.name === "businessAddInfoMultiple") {
        await this.$store.dispatch(
          `${onbngStore}/putLevel`,
          this.isMoral ? "MULTIPLE_BUSINESS_MORAL" : "MULTIPLE_BUSINESS_PHYSIC"
        );
      }
      this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
    }
  },
};
</script>

<style scoped>
</style>
