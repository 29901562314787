<template>
<div>
  <TitleBadge clazzContainer="p-0" :titleText="$t('myAccount.bankStatements.noDocs')" :badgeText="totalDocs.toString()">
    <div class="daterange-filter">
      <label class="f-12 c-text_info poppins-font-medium f-500 m-b-0">{{ $t('myAccount.bankStatements.filterBy') }}</label>
      <date-range-picker
          ref="picker"
          opens="right"
          :showDropdowns="false"
          :autoApply="true"
          v-model="dateRange"
          :linked-calendars="false"
          :ranges="false"
          singleDatePicker="range"
          @select="onSelectDate"
          :locale-data="timeOptions">
        <!-- eslint-disable -->
        <template v-slot:input="picker">
          <span class="daterange-btn">{{ $t('myAccount.bankStatements.date') }} <i aria-hidden="true" class="iconsax-arrow-direction-square-down"></i></span>
        </template>
        <!-- eslint-enable -->
      </date-range-picker>
    </div>
  </TitleBadge>
  <CardSimple
      clazz="p-0"
      hideTitle>
    <div
        id="accStateTable"
        class="basic-table">
      <b-table-simple
          responsive
          table-variant="xl">
        <b-thead>
          <b-tr class="">
            <b-th> {{$t('myAccount.bankStatements.headers.period')}}</b-th>
            <b-th class="text-center"> {{$t('myAccount.bankStatements.headers.dueDate')}}</b-th>
            <b-th class="text-center"> {{$t('myAccount.bankStatements.headers.daysPeriod')}}</b-th>
            <b-th class="text-center"> {{$t('myAccount.bankStatements.headers.year')}}</b-th>
            <b-th class="text-center"> {{$t('myAccount.bankStatements.headers.actions')}}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <LoadingBar clazz="p-t-50 fullcontent-loading" v-if="loading"/>
          <b-tr class="noHover" v-else-if="!bankStatements.length">
            <b-td colspan="5">
              <EmptyState
                  :emptyStateDefault="!loading"
                  :title="$t('myAccount.bankStatements.emptyState.title')"
                  :text="$t('myAccount.bankStatements.emptyState.text')"
                  :imgWidth="520"
                  :imgHeight="320"
                  :imgAlt="$t('myAccount.bankStatements.emptyState.imgAlt')"
                  :emptyStateSearching="loading"
              >
              </EmptyState>
            </b-td>
          </b-tr>
          <b-tr class="" v-for="doc in bankStatements" :key="doc._id">
            <b-td>
              <div class="vertical-center flex-wrap-no">
                <img class="img-fluid" src="@/assets/images/Icons/icon-doc-pdf.svg" width="24" height="26"
                     alt="Archivo PDF"/>
                <strong class="c-text_title poppins-font-bold m-l-15 min-w-100px">{{getFormatDate(doc.from)}} - {{getFormatDate(doc.to)}}</strong>
              </div>
            </b-td>
            <b-td class="text-center">
              <strong class="c-text_title poppins-font-bold">{{getFormatDate(doc.to)}}</strong>
            </b-td>
            <b-td class="text-center">
              <strong class="c-text_title poppins-font-bold">{{ doc.daysOfMonth }}</strong>
            </b-td>
            <b-td class="text-right">
              <strong class="c-text_title poppins-font-bold">{{ doc.year }}</strong>
            </b-td>
            <b-td class="text-center">
              <div class="vertical-center flex-wrap-no column-gap-10px w-fit m-0-auto">
                <button
                    id="seeDoc"
                    type="button"
                    class="button-square xs button-stroke button-primary"
                    name="Ver documento"
                    aria-label="Ver documento"
                    @click="watchDoc(doc)"
                >
                  <i aria-hidden="true" class="iconsax-security-eye"></i>
                </button>
                <button
                    id="downloadDoc"
                    type="button"
                    class="button-square xs button-stroke button-primary"
                    name="Descargar documento"
                    aria-label="Descargar documento"
                    @click="downloadDoc(doc, {requireAuth: true})"
                >
                  <i aria-hidden="true" class="iconsax-content-document-download"></i>
                </button>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </CardSimple>

  <div class="pagination-custom" v-if="totalDocs">
    <span class="pagination-count">{{ $tc('myAccount.bankStatements.pagination', totalDocs, {total: totalDocs}) }} </span>
    <b-pagination
        v-model="currentPage"
        :total-rows="totalDocs"
        :per-page="perPage"
        aria-controls="my-table"
        next-text=""
        prev-text=""
        hide-goto-end-buttons
        @change="pageChange"
    ></b-pagination>
  </div>

  <!-- MODAL ESTADO DE CUENTA -->
  <b-modal
      id="modal-edo-cuenta"
      size="lg"
      hide-header
      hide-footer>
    <button class="close-modal"
            @click="$bvModal.hide('modal-edo-cuenta')"
            aria-label="Boton cerrar ventana"
            name="Boton cerrar ventana">
      <i aria-hidden="true" class="banana banana-cross-normal"></i>
    </button>
    <div class="modal-terminos-privacidad">
      <h2 class="title">
        {{ $t('myAccount.bankStatements.modalEdoAccount.title') }}
      </h2>
      <span class="date-update">
        <small>{{$t('myAccount.bankStatements.modalEdoAccount.period')}}</small>
        {{getFormatDate(currentDoc.from)}} - {{getFormatDate(currentDoc.to)}}
      </span>
      <div v-if="!loadingModal" class="text-container">
        <vuePdf :src="pathPdf"></vuePdf>
      </div>
      <div v-else class="text-container">
        <loading-bar :loadingText="$t('myAccount.bankStatements.modalEdoAccount.loadDoc')"></loading-bar>
      </div>
      <button v-if="!downloading" id="" type="button" class="button button-filled button-primary"
              @click="downloadDoc(currentDoc, {requireAuth: false})">
        <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
        {{ $t('myAccount.bankStatements.modalEdoAccount.download') }}
      </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
        <LoadingSimple clazz="small-load no-text"/>
      </button>
    </div>
  </b-modal>
</div>
</template>

<script>
import vuePdf from 'pdfvuer';
import CardSimple from "@/components/CardSimple";
import TitleBadge from "@/components/TitleBadge";
import EmptyState from "@/components/EmptyState";
import LoadingBar from "@/components/LoadingBar";
import DateRangePicker from "vue2-daterange-picker";
import LoadingSimple from "@/components/LoadingSimple";

import moment from 'moment';
import utils from "@/utils/utils";
import FileApi from "@/api/core/files.api";

import CorporativeAccountsApi from "@/api/wallet/corporativeAccounts.api";

export default {
  name: "CorporativeActivityBankStatements",
  components: {
    vuePdf,
    CardSimple,
    TitleBadge,
    EmptyState,
    LoadingBar,
    LoadingSimple,
    DateRangePicker,
  },
  props: {
    info: Object,
  },
  data() {
    return {
      perPage: 8,
      pathPdf: '',
      totalDocs: 0,
      dateRange: {},
      loading: true,
      currentPage: 1,
      currentDoc: {},
      bankStatements: [],
      downloading: false,
      loadingModal: false,
    }
  },
  computed: {
    timeOptions() {
      return {
        weekLabel: "#",
        daysOfWeek: [...Array(7).keys()].map(n => this.$t(utils.getWeekDayByNoAbbr(n))),
        monthNames: [...Array(12).keys()].map(n => this.$t(utils.getMonthByNo(n))),
      };
    },
    search() {
      const {startDate, endDate} = this.dateRange;
      if(startDate && endDate) {
        return {
          from: moment(startDate).startOf('day').valueOf(),
          to: moment(endDate).endOf('day').valueOf(),
        };
      }
      return {};
    },
  },
  methods: {
    async getBankStatements() {
      this.loading = true;
      try {
        const params = {
          id: this.info.general.accountId,
          page: this.currentPage,
          perPage: this.perPage,
          ...this.search,
        }
        const res = await CorporativeAccountsApi.getBankStatements(params);
        this.bankStatements = res.data.object.docs;
        this.totalDocs = res.data.object.totalDocs;
      } catch(error) {
        this.toastErrorDefault(error);
      }
      this.loading = false;
    },
    getFormatDate(timestamp) {
      const date = utils.timestampToDate(timestamp);
      const month = this.$i18n.t(utils.getMonthByNoAbbr(date.getMonth()));
      return `${date.getDate()} ${month}. ${date.getFullYear()}`
    },
    pageChange(page) {
      this.currentPage = page;
      this.getBankStatements();
    },
    onSelectDate({startDate, endDate}) {
      if(startDate && endDate && moment(startDate).isSame(endDate)) {
        // Deselecciona las fechas cuando el rango no abarca mas de un dia
        this.$refs.picker.clickRange([])
      } else {
        this.$nextTick(this.getBankStatements);
      }
    },
    async watchDoc(doc) {
      if(await this.$requestAuth()) {
        this.currentDoc = doc;
        this.$bvModal.show('modal-edo-cuenta');
        this.getPdfBase64(doc.file);
      }
    },
    async downloadDoc(doc, {requireAuth}) {
      if(!requireAuth) {
        this.getSignedUrl(doc.file);
      } else if(await this.$requestAuth()) {
        this.displayNotificationInfo(this.$t('myAccount.bankStatements.downloading'));
        this.getSignedUrl(doc.file);
      }
    },
    async getPdfBase64(id) {
      this.loadingModal = true;
      const response = await FileApi.downloadFile(id, {
        base64: true
      }).catch(utils.catchError);
      if (response && response.data) {
        this.pathPdf = response.data.object;
      } else {
        this.displayNotificationWarning(this.$i18n.t('myAccount.bankStatements.modalEdoAccount.errorFind'))
      }
      this.loadingModal = false;
    },
    async getSignedUrl(id) {
      this.downloading = true;
      const response = await FileApi.downloadSignedUrl({id: id}).catch(utils.catchError);
      if (response && response.data) {
        this.downloadFile(response.data.object)
      } else {
        this.displayNotificationWarning(this.$i18n.t('myAccount.bankStatements.modalEdoAccount.errorFind'))
      }
      this.downloading = false;
    },
    downloadFile(url) {
      if (url) {
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.click();
      }
    },
  },
  mounted() {
    this.getBankStatements();
  },
}
</script>
