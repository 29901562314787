<template>
<div>
  <TitlePrincipal :title="$t('register.confirmNip.title')"
                  clazz="text-align-c"
                  :subtitle="$t('register.confirmNip.subtitle')">
  </TitlePrincipal>
  <CodeInput  typeInput="password"
              :clazz="errorNip ? 'has-error' : ''"
              :four-codes="true"
              :deleteName="'delete-code-input'"
              v-on:verificationCode="onChangeCode"
              :warningText="$t(warningText)" />
  <div class="footer-buttons center">
    <button @click="back()" type="button" class="button button-ghost button-plain_text" name="Regresar"> {{ $t('register.confirmNip.back') }} </button>
    <button v-if="showConfirm" type="button" class="button button-filled button-primary" name="Continuar" :disabled="!isValidPin" @click="submitPin">
      {{ $t('register.confirmNip.continue') }}
    </button>
    <button v-else :id="'Loading button'" class="button button-filled button-primary">
      <LoadingSimple clazz="small-load no-text"/>
    </button>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import CodeInput from "@/components/CodeInput";
import LoadingSimple from "@/components/LoadingSimple";
import {
  GET_LEVEL,
  GET_LOCATION,
  GET_CARD_REQUEST,
  STORE as onbngStore,
  GO_TO_ROUTE
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import UserApi from "@/api/users/users.api";
import {catchError} from "@/utils/utils";
import { mapGetters } from 'vuex';
import {EventBus} from "@/main";
import {
  SET_USER,
  STORE as SESSION_STORE,
} from '@/store/modules/session/session.types';


export default {
  name: "confirmPin",
  components: {
    TitlePrincipal,
    CodeInput,
    LoadingSimple
  },
  computed: {
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      location: GET_LOCATION,
      cardRequest: GET_CARD_REQUEST
    }),
    isValidPin() {
      return this.pin && this.pin.length === 4;
    }
  },
  data() {
    return {
      showConfirm: true,
      warningText: "register.confirmNip.warningText",
      errorNip: false,
      pin: ""
    }
  },
  methods: {
    back() {
      if(this.level === 0) {
        this.$store.dispatch(`${onbngStore}/backCollaborator`)
      } else if(this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${onbngStore}/backBusiness`)
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`)
      }
    },
    onChangeCode(value) {
      this.pin = value;
    },
    async submitPin() {
      this.showConfirm = false;
      const params = { pin: this.pin };
      if(this.level === 0) {
        params.returnUser = true;
      }
      const response = await UserApi.validatePin(params).catch(catchError);
      if (response && response.data) {
          if (response.data.error) {
              this.warningText = "register.confirmNip.errorSave";
              this.errorNip = true;
              this.pin = "";
              EventBus.$emit("delete-code-input");
          } else {
            this.warningText = "register.confirmNip.warningText";
            this.errorNip = false;
            if(this.level === 0) {
              const user = response.data.data.user;
              this.$store.commit(`${SESSION_STORE}/${SET_USER}`, user);
            }
            this.nextStep();
          }
      }
      this.showConfirm = true;
    },
    async nextStep() {
      if (this.level === 0) {
        this.$router.push({name: 'processingRequestCollaborator'});
      } else if (this.cardRequest.isMoral) {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.NATIONALITY,
        });
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "businessNationality");
      } else {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.WAIT_REQUEST,
        });
        let goTo = "processingRequest";
        if (this.cardRequest.accountType === 'BUSINESS') {
          goTo = "businessProcessingRequest"
        }
        // NO USAR función STORE GO_TO_ROUTE
        this.$router.push({name: goTo});
      }
    }
  },
  created() {
    this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
  }
}
</script>

<style scoped>

</style>
