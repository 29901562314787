<template>
  <div class="collapse-beneficiary">
    <div class="head">
      <span>
        <BeneficiaryInfo
          :text="value.email"
          :textBold="value.name"
        >
        </BeneficiaryInfo>
      </span>
      <span>
        <span class="badge badge-error">
          <i aria-hidden="true" class="badge-icon iconbullet"></i>
          {{$t('collaborators.badge.REJECTED')}}
        </span>
      </span>
      <span>
        <button
          id=""
          class="button-square xs button-stroke button-accent"
          type="button"
          :name="$t('collaborators.actions.collapse')"
          :aria-label="$t('collaborators.actions.collapse')"
          v-b-toggle="`collapse-collaborator-${key}`"
        >
          <i aria-hidden="true" class="iconsax-arrow-direction-down"></i>
          <i aria-hidden="true" class="iconsax-arrow-direction-up"></i>
        </button>
      </span>
    </div>
    <b-collapse :id="`collapse-collaborator-${key}`" class="body" v-if="!amending">
      <div class="row">
        <div class="col-12">
          <StatusNote
            status="error"
            :title="$t('collaborators.rejected.invite.title')"
            :text="$t('collaborators.rejected.invite.text')"
            :imgWidth="124"
            :imgHeight="124"
            :mgAlt="$t('collaborators.rejected.invite.title')"
            :imgUrl="imgErrorStatus"
          >
          </StatusNote>
          <p class="f-18 c-title poppins-font-semibold f-600 text-center m-b-0">
            {{collaborator.rejectReason}}
          </p>
        </div>
        <div class="control-buttons buttons-full-w buttons-full-w-xs">
          <button type="button" class="button button-ghost button-error w-100" @click="cancelRequest">
            {{$t('collaborators.actions.deleteRequest')}}
          </button>
          <button
            type="button"
            class="button button-filled button-primary w-100"
            @click="amending = true"
          >
            {{$t('collaborators.actions.amendRequest')}}
          </button>
        </div>
      </div>
    </b-collapse>
    <b-collapse :id="`collapse-collaborator-${key}`" class="body" v-else>
      <div class="row">
        <div class="col-12 col-md-6">
          <BasicInput
            required
            inputId=""
            v-model="collaborator.name"
            :label="$t('collaborators.fields.name.label')"
            :inputPlaceholder="$t('collaborators.fields.name.placeholder')"
          >
          </BasicInput>
        </div>
        <div class="col-12 col-md-6">
          <BasicInput
            required
            inputId=""
            v-model="collaborator.email"
            :label="$t('collaborators.fields.email.label')"
            :inputPlaceholder="$t('collaborators.fields.email.placeholder')"
          >
          </BasicInput>
        </div>
        <div class="col-12">
          <div id="" class="basic-table">
            <b-table-simple responsive table-variant="xl">
              <b-thead>
                <b-tr class="">
                  <b-th class="w-60"> {{$t('collaborators.headers.permissions')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.read')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.request')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.authorize')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(type, index) in permissionTypes" :key="index">
                  <b-td class="w-60">
                    {{ $t(`collaborators.permissionTypes.${type}`) }}
                  </b-td>
                  <b-td
                    v-for="(action, index) in permissionActions"
                    :key="index"
                  >
                    <BasicCheckbox
                      clazz="w-fit m-0-auto"
                      inputClass="m-0"
                      :value="checkPermission(collaborator, action, type)"
                      @input="checked => onPermissionInput(checked, action, type)"
                      :index="index"
                    ></BasicCheckbox>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
        <div class="control-buttons buttons-full-w buttons-full-w-xs">
          <button
            type="button"
            class="button button-stroke button-primary w-100"
            @click="amending = false"
          >
            {{$t('collaborators.actions.cancel')}}
          </button>
          <button
            type="button"
            class="button button-filled button-primary w-100"
            :disabled="$v.$invalid || areEquals(value, collaborator)"
            @click="save"
            v-if="!loading"
          >
            {{$t('collaborators.actions.resendRequest')}}
          </button>
          <button
            v-else
            type="button"
            class="button button-filled button-primary w-100"
          >
            <LoadingSimple clazz="small-load no-text" />
          </button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
// @ is an alias to /src
import BeneficiaryInfo from "@/components/BeneficiaryInfo.vue";
import StatusNote from "@/components/StatusNote.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";
import BasicCheckbox from "@/components/BasicCheckbox.vue";
import BasicInput from "@/components/BasicInput.vue";

import ToastSnotify from "@/mixins/toastSnotify.mixin";
import CollaboratorsMixin from "@/mixins/collaboratorPermissions.mixin";

import { required } from "vuelidate/lib/validators";
import { regexEmail } from "@/utils/regexUtils";

import imgErrorStatus from '@/assets/images/Illustrations/illustration-status-error.svg';

import CollaboratorsApi from "@/api/wallet/collaborators.api";

const clone = (obj) => JSON.parse(JSON.stringify(obj));

export default {
  name: "CollaboratorRejected",
  mixins: [ToastSnotify, CollaboratorsMixin],
  components: {
    BeneficiaryInfo,
    StatusNote,
    LoadingSimple,
    BasicCheckbox,
    BasicInput,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgErrorStatus,
      collaborator: null,
      amending: false,
      loading: false,
    };
  },
  computed: {
    key() {
      return this.$vnode?.key?.toString() || "";
    },
  },
  created() {
    this.collaborator = clone(this.value);
  },
  methods: {
    areEquals: (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2),
    async cancelRequest() {
      if(this.loading) {
        return;
      }
      try {
        this.cancelling = true;
        await CollaboratorsApi.cancelRequest({id: this.collaborator._id});
        this.$emit('input', {status: 'HIDDEN'});
        this.collaborator.status = 'HIDDEN';
        this.cancelling = false;
        this.toastSuccessDefault();
      } catch(error) {
        this.toastErrorDefault(error);
        this.cancelling = false;
      }
    },
    onPermissionInput(add, action, type) {
      if(add) {
        this.addPermission(action, type);
      } else {
        this.removePermission(action, type);
      }
    },
    addPermission(action, type) {
      if(action !== 'READ' && !this.collaborator.permissions.some(p => p.action === 'READ' && p.type === type)) {
        this.collaborator.permissions.push({action: 'READ', type});
      }
      this.collaborator.permissions.push({action, type});
    },
    removePermission(action, type) {
      if(action === 'READ') {
        this.$set(this.collaborator, 'permissions', this.collaborator.permissions.filter(p => p.type !== type));
      } else {
        this.$set(this.collaborator, 'permissions', this.collaborator.permissions.filter(p => p.action !== action || p.type !== type));
      }
    },
    async save() {
      try {
        this.loading = true;
        const response = await CollaboratorsApi.amendRequest({
          ...this.collaborator,
          accountId: this.accountId,
        });

        this.$emit('input', {...response.data.object, beginVisible: true});
        this.loading = false;
        this.toastSuccessDefault();
      } catch (error) {
        this.toastErrorDefault(error);
        this.loading = false;
      }
    }
  },
  validations: {
    collaborator: {
      name: { required },
      email: { required, validEmail: (val) => regexEmail.test(val) }
    },
  },
  watch: {
    amending() {
      this.collaborator = clone(this.value);
    },
  },
};
</script>
