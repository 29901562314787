import {
    GET_USER,
    STORE as sessionStore
  } from "@/store/modules/session/session.types";
import store from '@/store/index'


export default function isNotWalletUser({ next, router }) {

    const user = store.getters[`${sessionStore}/${GET_USER}`];

    if(user && !user.walletUser) {
        return next();
    }

    return router.push({name: 'Dashboard'});
    // return next('/wallet');
}
