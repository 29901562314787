<template>
  <div>
    <div v-for="doc in filterDocs" :key="doc._id">
      <CardSelection
        :title="doc.title"
        :subtitle="`${doc.fileSpecs} / ${doc.allowedFormats.join(', ')}`"
        :clazz="`border-card
        ${
          filesUploadPercentage[doc.key] === '0'
            ? 'icon-bgm-error'
            : filesUploadPercentage[doc.key] === 100
            ? 'icon-bgm-success'
            : 'icon-bgm-info_text'
        }`"
        v-bind:key="doc._id"
      >
        <template v-slot:icon>
          <i aria-hidden="true" :class="doc.icon"></i>
        </template>
        <template v-slot:extraMiddleContent>
          <b-progress
            :max="100"
            class="bar-progress percent-color"
            :class="{ success: filesUploadPercentage[doc.key] === 100 }"
          >
            <b-progress-bar :value="filesUploadPercentage[doc.key] || 0">
              <div class="label-progress">
                <span>{{ filesUploadPercentage[doc.key] || 0 }}%</span>
              </div>
            </b-progress-bar>
          </b-progress>
        </template>
        <template v-slot:extraFinalContent>
          <button
            v-if="doc.fileDocumentType !== 'IDENTIFICATION_LIVENESS'"
            type="button"
            class="button xs button-filled button-primary p-relative"
          >
            {{ $t("components.dynamicDoc.upload") }}
            <input
              type="file"
              class="input-file"
              :ref="doc.key"
              @change="uploadDoc(doc)"
            />
          </button>
          <button
            v-else
            type="button"
            class="button xs button-filled button-primary p-relative"
            @click="openModal(doc)"
          >
            Tomar video
          </button>
        </template>
      </CardSelection>
    </div>
    <b-modal
      id="modal-como-tomar-video-test"
      size=""
      hide-header
      hide-footer
      modal-class="modal-default"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-como-tomar-video-test')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head"></div>
      <div class="body">
        <!--        <img v-if="isVideo"-->
        <!--             class="img-fluid d-flex m-0-auto m-b-40"-->
        <!--             src="@/assets/images/Default/img-ejemplo-como-tomar-video.png"-->
        <!--             alt="Imágen como tomar video"-->
        <!--        />-->

        <!--        <lottie-animation-->
        <!--            v-show="isVideo"-->
        <!--            path="lf30_pi6uawro.json"-->
        <!--            :width="400"-->
        <!--            :height="400"-->
        <!--        />-->

        <div class="w-100 max-w-200px m-0-auto m-b-40">
          <LottieAnimation
            :animationData="require('@/assets/lotties/lf30_pi6uawro.json')"
            :auto-play="true"
            :loop="true"
            :speed="1"
          />
        </div>

        <h2
          class="f-18 c-title poppins-font-semibold text-align-c w-100 m-t-20 m-b-25"
        >
          {{ $t("components.dynamicDoc.modalHowTake.title") }}
        </h2>
        <ul class="number-list">
          <li v-if="isVideo">
            <span
              v-html="$t('components.dynamicDoc.modalHowTake.vIns1')"
            ></span>
          </li>
          <li v-else>
            <span
              v-html="$t('components.dynamicDoc.modalHowTake.cIns1')"
            ></span>
          </li>
          <li v-if="!isVideo">
            <span
              v-html="$t('components.dynamicDoc.modalHowTake.cIns2')"
            ></span>
          </li>
          <li v-if="isVideo">
            <span
              v-html="$t('components.dynamicDoc.modalHowTake.vIns2')"
            ></span>
          </li>
          <li v-else>
            <span
              v-html="$t('components.dynamicDoc.modalHowTake.cIns3')"
            ></span>
          </li>
          <li v-if="isVideo">
            <span
              v-html="$t('components.dynamicDoc.modalHowTake.vIns3')"
            ></span>
          </li>
        </ul>
      </div>
      <div class="footer">
        <button
          type="button"
          class="button button-filled button-primary w-100"
          @click="openWebcam"
        >
          {{ $t("components.dynamicDoc.modalHowTake.button") }}
        </button>
        <!--        <button-->
        <!--            v-else-->
        <!--            type="button"-->
        <!--            class="button button-filled button-primary w-100"-->
        <!--            @click="openWebcam()"-->
        <!--        >-->
        <!--          Tomar Foto-->
        <!--        </button>-->
      </div>
    </b-modal>
    <b-modal
      id="modal-video-permiso-test"
      size=""
      hide-header
      hide-footer
      modal-class="modal-default"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-video-permiso-test')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <img
          class="img-fluid d-flex m-0-auto m-b-30"
          src="@/assets/images/Icons/icon-webcam.svg"
          alt="Permitir el acceso a la cámara"
        />
        <h2 class="modal-title">
          {{ $t("components.dynamicDoc.modalVideoPermit.title") }}
        </h2>
        <p class="modal-subtitle">
          {{ $t("components.dynamicDoc.modalVideoPermit.subtitle") }}
        </p>
      </div>
      <div class="body"></div>
      <div class="footer">
        <button
          type="button"
          class="button button-filled button-primary w-100"
          @click="acceptPermission"
        >
          {{ $t("components.dynamicDoc.modalVideoPermit.acceptPermission") }}
        </button>
      </div>
    </b-modal>
    <b-modal
      id="modal-take-video-test"
      size="xl"
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      modal-class="modal-default modal-video"
    >
      <button
        class="close-modal"
        @click="cancelVideo"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="body">
         <div>
          <CameraVue
            ref="upload-video-test"
            verified
            camId="upload-video-test"
            imageClassification="users/test-delete-in-anyCase"
            imageNamePrefix="junk-"
            @stopVideo="stopRecCamera($event)"
            @startingRecord="showStarting = $event"
            @videoEnded="videoPlaying = false"
          >
            <template v-slot:cameraButtons>
              <div
                class="play-button-container"
                v-if="replay && !videoPlaying && isVideo"
              >
                <button
                  type="button"
                  @click="emitPlay()"
                  class="btn-play-video"
                >
                  <i aria-hidden="true" class="banana banana-player-play"></i>
                </button>
                <label>Reproducir video</label>
              </div>
            </template>
          </CameraVue>
        </div>
       <div v-if="showStart && !showStarting && isVideo">
          <button
            type="button"
            @click="startVideoCapture(startRecCamera)"
            class="btn-recording"
            aria-label="Iniciar grabación"
            name="Iniciar grabación"
          >
            <svg height="60" width="60">
              <circle
                cx="30"
                cy="30"
                r="30"
                stroke="#FFFFFF"
                stroke-width="3"
                fill-opacity="0"
              />
              <circle
                class="circle"
                cx="30"
                cy="30"
                r="30"
                stroke="#C23E37"
                stroke-width="3"
                fill-opacity="0"
                stroke-linecap="round"
              />
            </svg>
            <div class="center-icon">
              <i
                aria-hidden="true"
                class="banana banana-shape-circle bgm-error brd-50prc f-20"
              ></i>
            </div>
          </button>
          <label
            class="f-14 c-title poppins-font-bold line-height-1 d-block text-align-c w-100 m-b-0"
          >
            {{ $t("components.dynamicDoc.modalTakeVideo.initRecord") }}
          </label>
        </div>
        <div v-if="showStarting && isVideo">
          <button
            type="button"
            class="btn-recording"
            aria-label="Iniciar grabación"
            name="Iniciar grabación"
          >
            <svg height="60" width="60">
              <circle
                cx="30"
                cy="30"
                r="30"
                stroke="#FFFFFF"
                stroke-width="3"
                fill-opacity="0"
              />
              <circle
                class="circle"
                cx="30"
                cy="30"
                r="30"
                stroke="#C23E37"
                stroke-width="3"
                fill-opacity="0"
                stroke-linecap="round"
              />
            </svg>
            <div class="center-icon">
              <i
                aria-hidden="true"
                class="banana banana-shape-circle bgm-error brd-50prc f-20"
              ></i>
            </div>
          </button>
          <label
            class="f-14 c-title poppins-font-bold line-height-1 d-block text-align-c w-100 m-b-0"
          >
            Iniciando grabación...
          </label>
        </div>
        <div v-if="showStop && isVideo">
          <button
            type="button"
            class="btn-recording start"
            aria-label="Grabando video"
            @click="emitStop()"
            name="Grabando video"
          >
            <svg height="60" width="60">
              <circle
                cx="30"
                cy="30"
                r="30"
                stroke="#FFFFFF"
                stroke-width="3"
                fill-opacity="0"
              />
              <circle
                class="circle"
                cx="30"
                cy="30"
                r="30"
                stroke="#C23E37"
                stroke-width="3"
                fill-opacity="0"
                stroke-linecap="round"
              />
            </svg>
            <div class="center-icon">
              <i aria-hidden="true" class="banana banana-player-stop"></i>
            </div>
          </button>
          <label
            class="f-14 c-title poppins-font-bold line-height-1 d-block text-align-c w-100 m-b-0"
          >
            {{ $t("components.dynamicDoc.modalTakeVideo.recording") }}
          </label>
        </div>
        <label
          v-if="replay && isVideo"
          class="f-14 c-title poppins-font-bold line-height-1 d-block text-align-c w-100 m-b-0"
        >
          {{ $t("components.dynamicDoc.modalTakeVideo.dummy") }}
        </label>
      </div>
      <div class="footer m-t-10" v-if="replay && isVideo">
        <button
          type="button"
          class="button sm button-stroke button-primary white-space-pre-wrap"
          @click="recordAgain()"
        >
          <i aria-hidden="true" class="banana banana-refresh"></i>
          {{ $t("components.dynamicDoc.modalTakeVideo.noTryAgain") }}
        </button>
        <button
          @click="confirmVideo()"
          type="button"
          class="button sm button-filled button-primary white-space-pre-wrap"
        >
          <i aria-hidden="true" class="banana banana-check-normal"></i>
          {{ $t("components.dynamicDoc.modalTakeVideo.yesConfirm") }}
        </button>
      </div>
    </b-modal>
    <b-modal
      id="modal-video-salir"
      size=""
      hide-header
      hide-footer
      modal-class="modal-default"
    >
      <button
        class="close-modal"
        @click="continueProccessCamera()"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <img
          class="img-fluid d-flex m-0-auto m-b-30"
          src="@/assets/images/Icons/icono-modal-salir.svg"
          alt="Permitir el acceso a la cámara"
        />
        <h2 class="modal-title">
          {{ $t("components.dynamicDoc.modalExit.areYouSure") }}
        </h2>
        <p class="modal-subtitle">
          {{ $t("components.dynamicDoc.modalExit.p") }}
        </p>
      </div>
      <div class="body"></div>
      <div class="footer">
        <button
          type="button"
          class="button button-stroke button-primary"
          @click="handleOut()"
        >
          {{ $t("components.dynamicDoc.modalExit.exit") }}
        </button>
        <button
          type="button"
          class="button button-filled button-primary"
          @click="continueProccessCamera()"
        >
          {{ $t("components.dynamicDoc.modalExit.next") }}
        </button>
      </div>
    </b-modal>
    <b-modal
      id="modal-video-analisis"
      size=""
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      modal-class="modal-default"
    >
      <div class="head m-b-0">
        <div class="p-relative d-block min-h-100px">
          <LoadingBar loadingText="Analizando video…"></LoadingBar>
        </div>
        <h2 class="modal-title">Analizando video grabado</h2>
        <p class="modal-subtitle m-b-0">
          {{ $t("register.uploadDocuments.modalVideo.processingFaceVideo") }}
        </p>
      </div>
    </b-modal>

    <b-modal
      id="modal-upload-video"
      size=""
      hide-header
      hide-footer
      modal-class="modal-default"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-upload-video')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">¿Cómo tomar mi video?</h2>
        <p class="modal-subtitle">
          Para realizar la prueba de vida, tienes que grabar un video de tu
          cara, para realizarlo puedes usar cualquiera de las siguientes dos
          opciones que te presentamos aquí:
        </p>
      </div>
      <div class="body">
        <div class="video-cards-container">
          <button
            type="button"
            :class="`video-card ${optionSelected === 1 ? 'selected' : ''}`"
            aria-label="Cámara Web"
            name="Cámara Web"
            @click="optionSelected = 1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="114"
              height="105"
              viewBox="0 0 114 105"
              fill="none"
            >
              <path
                d="M109.401 6.6877H80.7678C79.992 2.8766 76.6185 0 72.5862 0H41.4142C37.3819 0 34.0084
                          2.8766 33.2327 6.6877H4.59943C2.06336 6.6877 0 8.75331 0 11.292V83.4355C0 85.9744 2.06336
                          88.0398 4.59943 88.0398H44.6808L42.428 94.9696H37.75C34.5526 94.9696 31.9511 97.5738 31.9511
                          100.775C31.9511 103.104 33.8446 105 36.1718 105H77.8282C80.1555 105 82.0489 103.105 82.0489
                          100.775C82.0489 97.5738 79.4474 94.9696 76.25 94.9696H71.572L69.3192 88.0398H109.401C111.937
                          88.0398 114 85.9742 114 83.4355V11.292C114 8.75331 111.937 6.6877 109.401 6.6877ZM63.6755
                          3.34385H72.5862C75.3485 3.34385 77.596 5.59381 77.596 8.35962C77.596 11.1254 75.3485 13.3754
                          72.5862 13.3754H63.6755C64.7262 11.9772 65.3499 10.2402 65.3499 8.35962C65.3499 6.47904 64.7262
                          4.74202 63.6755 3.34385ZM57.0002 3.34385C59.7625 3.34385 62.01 5.59381 62.01 8.35962C62.01 11.1254
                          59.7625 13.3754 57.0002 13.3754C54.2379 13.3754 51.9904 11.1254 51.9904 8.35962C51.9904 5.59381
                          54.2379 3.34385 57.0002 3.34385ZM41.4142 3.34385H50.325C49.2742 4.74202 48.6506 6.47904 48.6506
                          8.35962C48.6506 10.2402 49.2742 11.9772 50.325 13.3754H41.4142C38.6519 13.3754 36.4044 11.1254
                          36.4044 8.35962C36.4044 5.59381 38.6519 3.34385 41.4142 3.34385ZM76.2503 98.3136C77.6062 98.3136
                          78.7093 99.4178 78.7093 100.775C78.7093 101.261 78.3143 101.656 77.8285 101.656H36.172C35.6861
                          101.656 35.2912 101.261 35.2912 100.775C35.2912 99.4178 36.3942 98.3136 37.7502 98.3136H49.4978C50.4203
                          98.3136 51.1677 97.5651 51.1677 96.6417C51.1677 95.7184 50.4203 94.9698 49.4978 94.9698H45.9406L48.1935
                          88.04H65.8072L68.0601 94.9698H57.204C56.2815 94.9698 55.534 95.7184 55.534 96.6417C55.534 97.5651
                          56.2815 98.3136 57.204 98.3136H76.2503ZM110.661 20.0606H33.6212C32.6988 20.0606 31.9513 20.8092
                          31.9513 21.7326C31.9513 22.6559 32.6988 23.4045 33.6212 23.4045H110.661V71.3232H98.0248C97.1023
                          71.3232 96.3549 72.0718 96.3549 72.9951C96.3549 73.9185 97.1023 74.667 98.0248
                          74.667H110.661V83.4355C110.661 84.1306 110.095 84.6959 109.401 84.6959H4.59943C3.90496 84.6959
                          3.33986 84.1304 3.33986 83.4355V74.667H90.3416C91.264 74.667 92.0115 73.9185 92.0115 72.9951C92.0115
                          72.0718 91.264 71.3232 90.3416 71.3232H83.9598C82.7401 67.7994 79.7665 65.0916 76.0717 64.2548C76.0597
                          64.2519 76.0477 64.2494 76.0354 64.2469L63.3756 61.6758C63.2404 60.6414 63.2141 59.6128 63.2987
                          58.5267C67.7361 54.8115 69.8972 51.5198 69.8972 48.4785C69.8972 48.1488 69.8838 47.824 69.8553
                          47.492C69.7841 46.7644 69.7438 43.4096 69.7578 39.3335C69.7692 36.0124 68.4851 32.887 66.1428
                          30.5331C63.7982 28.1773 60.6774 26.8794 57.3556 26.879C57.3549 26.879 57.3543 26.879 57.3536
                          26.879C54.0315 26.879 50.9103 28.1764 48.5653 30.532C46.2221 32.8859 44.9378 36.0117 44.9491
                          39.3337C44.9632 43.4121 44.9229 46.7698 44.85 47.5157C44.8233 47.8186 44.8097 48.1425 44.8097
                          48.4782C44.8097 51.5156 46.9642 54.8017 51.3844 58.5062C51.4665 59.5687 51.4369 60.5741 51.2951
                          61.6214L38.4079 64.2461C38.3959 64.2485 38.3841 64.251 38.3721 64.2539C34.6777 65.0914 31.7048
                          67.7997 30.4858 71.3232H3.33986V23.4045H25.9781C26.9005 23.4045 27.648 22.6559 27.648 21.7326C27.648
                          20.8092 26.9005 20.0606 25.9781 20.0606H3.33986V11.292C3.33986 10.5969 3.90496 10.0315 4.59943
                          10.0315H33.2325C34.0082 13.8426 37.3817 16.7192 41.414 16.7192H72.586C76.6183 16.7192 79.9918
                          13.8426 80.7675 10.0315H109.401C110.095 10.0315 110.66 10.5971 110.66 11.292V20.0606H110.661ZM48.289
                          39.3221C48.2808 36.8956 49.219 34.6122 50.9308 32.8926C52.6448 31.1709 54.9255 30.2228 57.3536
                          30.2228H57.3551C59.7825 30.2233 62.0634 31.1716 63.777 32.8935C65.4884 34.6129 66.4262 36.896 66.418
                          39.3219C66.4062 42.7507 66.43 46.7834 66.5297 47.7972C66.548 48.0137 66.5573 48.2428 66.5573
                          48.4785C66.5573 50.4879 64.4494 53.3019 60.6189 56.4052C59.7031 57.1506 58.5492 57.561 57.3618
                          57.561C57.3583 57.561 57.3547 57.561 57.3511 57.561C56.155 57.5615 55.0052 57.1517 54.0819
                          56.3996C50.2562 53.301 48.1494 50.4877 48.1494 48.4785C48.1494 48.2402 48.1585 48.0148 48.175
                          47.8257C48.277 46.7883 48.3008 42.7529 48.289 39.3221ZM60.1274 62.5708C60.0448 62.66 59.9613
                          62.7496 59.8772 62.839C59.1469 63.6181 58.2571 64.0468 57.3618 64.0468C57.3571 64.0468 57.3522
                          64.0468 57.3473 64.0468C56.4594 64.0468 55.5676 63.617 54.8329 62.8325C54.7345 62.7279 54.6369
                          62.6232 54.5405 62.5186C54.6487 61.8369 54.7162 61.1666 54.7476 60.496C55.5803 60.7638 56.4536
                          60.9047 57.3351 60.9047H57.3685C58.2404 60.9047 59.1032 60.7678 59.9259 60.5076C59.9538 61.1974
                          60.0192 61.8811 60.1274 62.5708ZM52.1545 64.8586C52.2363 64.9469 52.3171 65.0354 52.3999
                          65.123C53.7697 66.5856 55.526 67.3904 57.3384 67.3904H57.371C59.1898 67.3904 60.9444 66.587
                          62.3099 65.1299C62.3781 65.0577 62.4446 64.9848 62.5121 64.9121L75.3525 67.5201C77.5096 68.0141
                          79.3107 69.4277 80.3265 71.3232H34.1191C35.1346 69.4272 36.9355 68.0132 39.0928 67.519L52.1545 64.8586Z"
                fill="#7F95A4"
              />
            </svg>
            <label>{{ $t("register.uploadDocuments.modal.webcam") }}</label>
          </button>
          <button
            type="button"
            :class="`video-card ${optionSelected === 2 ? 'selected' : ''}`"
            aria-label="Mi Teléfono"
            name="Mi Teléfono"
            @click="optionSelected = 2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="105"
              height="105"
              viewBox="0 0 105 105"
              fill="none"
            >
              <path
                d="M99.75 0H47.25C45.8589 0.00415466 44.5259 0.558612 43.5423 1.54228C42.5586
                          2.52595 42.0042 3.85889 42 5.25V35H36.0325C34.8439 34.9969 33.6899 35.3993 32.7608
                          36.1406C31.8318 36.8819 31.1833 37.9179 30.9225 39.0775L26.6 57.75H22.75V56C22.75
                          55.5359 22.5656 55.0908 22.2374 54.7626C21.9092 54.4344 21.4641 54.25 21 54.25H8.75C8.28587
                          54.25 7.84075 54.4344 7.51256 54.7626C7.18437 55.0908 7 55.5359 7 56H1.75C1.28587
                          56 0.840752 56.1844 0.512563 56.5126C0.184374 56.8408 0 57.2859 0 57.75V96.25C0
                          96.7141 0.184374 97.1592 0.512563 97.4874C0.840752 97.8156 1.28587 98 1.75 98H7C7
                          98.4641 7.18437 98.9092 7.51256 99.2374C7.84075 99.5656 8.28587 99.75 8.75 99.75H21C21.4641
                          99.75 21.9092 99.5656 22.2374 99.2374C22.5656 98.9092 22.75 98.4641 22.75
                          98V96.25H29.225L34.0375 99.4525C34.3207 99.6471 34.6564 99.7508 35 99.75H42C42.0042
                          101.141 42.5586 102.474 43.5423 103.458C44.5259 104.441 45.8589 104.996 47.25 105H99.75C101.141
                          104.996 102.474 104.441 103.458 103.458C104.441 102.474 104.996 101.141 105 99.75V5.25C104.996
                          3.85889 104.441 2.52595 103.458 1.54228C102.474 0.558612 101.141 0.00415466 99.75 0ZM7
                          94.5H3.5V59.5H7V94.5ZM19.25 96.25H10.5V57.75H19.25V96.25ZM42 49V96.25H35.525L30.7125
                          93.0475C30.4293 92.8529 30.0936 92.7492 29.75 92.75H22.75V61.25H28C28.3968 61.2526
                          28.7824 61.1183 29.0917 60.8696C29.4009 60.6209 29.6149 60.2732 29.6975 59.885L34.335
                          39.865C34.4176 39.4768 34.6316 39.1291 34.9408 38.8804C35.2501 38.6317 35.6357 38.4974
                          36.0325 38.5H53.375C54.5353 38.5 55.6481 38.9609 56.4686 39.7814C57.2891 40.6019
                          57.75 41.7147 57.75 42.875C57.75 46.69 52.815 47.25 49.875 47.25H43.75C43.2859
                          47.25 42.8408 47.4344 42.5126 47.7626C42.1844 48.0908 42 48.5359 42 49ZM57.75 52.5H66.5C66.9641
                          52.5 67.4092 52.3156 67.7374 51.9874C68.0656 51.6592 68.25 51.2141 68.25 50.75V33.25C68.25 32.7859
                          68.0656 32.3408 67.7374 32.0126C67.4092 31.6844 66.9641 31.5 66.5 31.5H57.75C57.2859 31.5
                          56.8408 31.6844 56.5126 32.0126C56.1844 32.3408 56 32.7859 56 33.25V35.49C55.4355
                          35.2841 54.8477 35.1489 54.25 35.0875V19.25H91V56H54.25V50.3125C54.8459 50.19 55.431
                          50.0203 56 49.805V50.75C56 51.2141 56.1844 51.6592 56.5126 51.9874C56.8408 52.3156
                          57.2859 52.5 57.75 52.5ZM59.5 37.9925V35H64.75V49H59.5V47.53C60.6248 46.2397 61.2462
                          44.5868 61.25 42.875C61.2455 41.0947 60.6275 39.3703 59.5 37.9925ZM101.5 99.75C101.5
                          100.214 101.316 100.659 100.987 100.987C100.659 101.316 100.214 101.5 99.75 101.5H47.25C46.7859
                          101.5 46.3408 101.316 46.0126 100.987C45.6844 100.659 45.5 100.214 45.5 99.75V91H101.5V99.75ZM101.5
                          87.5H45.5V50.75H49.875C50.19 50.75 50.4525 50.7325 50.75 50.715V57.75C50.75 58.2141 50.9344 58.6592
                          51.2626 58.9874C51.5908 59.3156 52.0359 59.5 52.5 59.5H92.75C93.2141 59.5 93.6592 59.3156
                          93.9874 58.9874C94.3156 58.6592 94.5 58.2141 94.5 57.75V17.5C94.5 17.0359 94.3156 16.5908
                          93.9874 16.2626C93.6592 15.9344 93.2141 15.75 92.75 15.75H52.5C52.0359 15.75 51.5908 15.9344
                          51.2626 16.2626C50.9344 16.5908 50.75 17.0359 50.75 17.5V35H45.5V10.5H101.5V87.5ZM101.5
                          7H45.5V5.25C45.5 4.78587 45.6844 4.34075 46.0126 4.01256C46.3408 3.68437 46.7859 3.5 47.25
                          3.5H99.75C100.214 3.5 100.659 3.68437 100.987 4.01256C101.316 4.34075 101.5 4.78587 101.5 5.25V7Z"
                fill="#7F95A4"
              />
              <path d="M70 94.5H77V98H70V94.5Z" fill="#7F95A4" />
              <path
                d="M68.25 21H57.75C57.2859 21 56.8408 21.1844 56.5126 21.5126C56.1844 21.8408 56 22.2859
                          56 22.75V29.75H59.5V24.5H68.25V21Z"
                fill="#7F95A4"
              />
              <path
                d="M77 35H87.5C87.9641 35 88.4092 34.8156 88.7374 34.4874C89.0656 34.1592 89.25 33.7141
                          89.25 33.25V22.75C89.25 22.2859 89.0656 21.8408 88.7374 21.5126C88.4092 21.1844 87.9641 21 87.5
                          21H77C76.5359 21 76.0908 21.1844 75.7626 21.5126C75.4344 21.8408 75.25 22.2859 75.25 22.75V33.25C75.25
                          33.7141 75.4344 34.1592 75.7626 34.4874C76.0908 34.8156 76.5359 35 77 35ZM78.75 24.5H85.75V31.5H78.75V24.5Z"
                fill="#7F95A4"
              />
              <path
                d="M77 52.5H87.5C87.9641 52.5 88.4092 52.3156 88.7374 51.9874C89.0656 51.6592 89.25
                          51.2141 89.25 50.75V43.75C89.25 43.2859 89.0656 42.8408 88.7374 42.5126C88.4092 42.1844
                          87.9641 42 87.5 42H77C76.5359 42 76.0908 42.1844 75.7626 42.5126C75.4344 42.8408 75.25
                          43.2859 75.25 43.75V50.75C75.25 51.2141 75.4344 51.6592 75.7626 51.9874C76.0908 52.3156
                          76.5359 52.5 77 52.5ZM78.75 45.5H85.75V49H78.75V45.5Z"
                fill="#7F95A4"
              />
              <path d="M70 21H73.5V35H70V21Z" fill="#7F95A4" />
              <path d="M75.25 36.75H89.25V40.25H75.25V36.75Z" fill="#7F95A4" />
              <path d="M70 36.75H73.5V45.5H70V36.75Z" fill="#7F95A4" />
              <path d="M70 49H73.5V52.5H70V49Z" fill="#7F95A4" />
              <path d="M63 26.25H68.25V29.75H63V26.25Z" fill="#7F95A4" />
              <path
                d="M50.7499 64.75H59.4999V68.25H50.7499V64.75Z"
                fill="#7F95A4"
              />
              <path d="M63 64.75H94.5V68.25H63V64.75Z" fill="#7F95A4" />
              <path d="M50.7499 73.5H94.4999V77H50.7499V73.5Z" fill="#7F95A4" />
            </svg>
            <label>{{ $t("register.uploadDocuments.modal.phone") }}</label>
          </button>
        </div>
      </div>
      <div class="footer">
        <button
          type="button"
          class="button button-filled button-primary w-100"
          :disabled="!optionSelected"
          @click="continueProccess()"
        >
          Continuar
        </button>
      </div>
    </b-modal>
    <b-modal
      id="modal-qr"
      size=""
      hide-header
      hide-footer
      modal-class="modal-default"
    >
      <button
        class="close-modal"
        @click="handleClose('modal-qr')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head m-b-0">
        <div class="qr-container">
          <qr-code v-if="urlQR" :text="urlQR" :size="250" error-level="L">
          </qr-code>
          <div v-else>Ocurrio un error al generar QR</div>
        </div>
        <h2 class="modal-title">Escanea el código QR</h2>
        <p class="modal-subtitle m-b-0">
          Para realizar la grabación del video de prueba de vida, puedes hacerlo
          desde tu teléfono
          <strong class="poppins-font-bold"
            >escaneando el código QR usando la camara de tu teléfono.</strong
          >
          Una vez que en tu teléfono se indique que la verificación finalizo,
          haz click en el botón Verificar archivo.
        </p>
      </div>
      <div class="body"></div>
      <div class="footer">
        <button
          type="button"
          class="button button-filled button-primary w-100"
          @click="verifyDocument()"
        >
          Verificar archivo
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CardSelection from "@/components/CardSelection";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import CameraVue from "@/components/CameraVue";
import LoadingBar from "@/components/LoadingBar";
import CameraMixin from "@/mixins/CameraMixin";
import { EventBus } from "@/main";
import UserApi from "@/api/users/users.api";
import { catchError } from "@/utils/utils";

const nameModalVideoPermit = "modal-video-permiso-test";
const nameModalHowTake = "modal-como-tomar-video-test";
const nameModalTakeVideo = "modal-take-video-test";
const nameModalVideoSalir = "modal-video-salir";
import LottieAnimation from "lottie-web-vue";
const nameModalUpload = "modal-upload-video";

export default {
  name: "DynamicDocs",
  mixins: [ToastSnotify, CameraMixin("upload-video-test")],
  components: {
    CardSelection,
    CameraVue,
    LoadingBar,
    LottieAnimation,
  },
  props: {
    docs: [],
    blockLiveness: { type: Boolean, default: false },
    idShareholder: String
  },
  computed: {
    filterDocs() {
      if (this.blockLiveness) {
        return this.docs.filter(
          (d) =>
            d.fileDocumentType !== "IDENTIFICATION_LIVENESS" &&
            d.fileDocumentType !== "RESIDENCY"
        );
      }
      return this.docs;
    },
  },
  data() {
    return {
      filesUploadPercentage: {},
      files: {},
      docModal: {},
      // Videos
      isVideo: true,
      videoPlaying: false,
      resultCamera: {},
      blobVideo: {},
      showLoadingCam: true,
      showStart: false,
      showStop: false,
      showPlay: false,
      showPause: false,
      showStarting: false,
      replay: false,
      optionSelected: null,
      urlQR: null,
    };
  },
  methods: {
    _isValidFormat(doc, type) {
      const formats = [...doc.allowedFormats];
      if (formats.includes("jpg")) {
        formats.push("jpeg");
      }
      for (const format of formats) {
        if (type.match(format)) {
          return true;
        }
      }
      return false;
    },
    async uploadDoc(doc) {
      this.$set(this.filesUploadPercentage, doc.key, 25);
      const file = this.$refs[doc.key][0].files[0];

      if (this._isValidFormat(doc, file.type)) {
        this.$set(this.filesUploadPercentage, doc.key, 50);
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.$set(this.filesUploadPercentage, doc.key, 100);
          this.$refs[doc.key][0].value = null;
          this.$emit("add-file", {
            key: doc.key,
            file: {
              title: `${doc.title}`,
              key: doc.key,
              fileDocument: doc._id,
              isRequired: doc.isRequired,
              file: {
                name: file.name || doc.title,
                type: file.type,
                size: file.size,
                data: fileReader.result.split(",")[1],
              },
            },
          });
        });
        fileReader.addEventListener("error", () => {
          this.displayNotificationWarning(
            this.$i18n.t("register.shareholders.error.doc")
          );
          this.$set(this.filesUploadPercentage, doc.key, -1);
          this.$refs[doc.key][0].value = null;
        });
        fileReader.readAsDataURL(file);
      } else {
        this.displayNotificationWarning(
          this.$i18n.t("components.dynamicDoc.error.formatInvalid")
        );
        this.$set(this.filesUploadPercentage, doc.key, "0");
        this.$refs[doc.key][0].value = null;
      }
    },
    async verifyDocument() {
      const res = await UserApi.verifyDocument({
        fileDocument: this.docModal._id,
        shareholder: this.idShareholder,
      }).catch(catchError);
      if (res && res.data) {
        if (res.data.error) {
          this.displayNotificationError(
            this.$i18n.t("register.uploadDocuments.error")
          );
        } else {
          // const file = this.selectionFiles.findIndex(s => s.file === document._id);
          // this.selectionFiles[file].verified = true;
          // this._setProgress(this.indexModal, 100);
          // this._setProgressClazz(this.indexModal, clazzSuccess);
          this.$set(this.filesUploadPercentage, this.docModal.key, 100);

          this.$bvModal.hide("modal-qr");
        }
      } else {
        this.displayNotificationError(
          this.$i18n.t("Error al verificar documentación")
        );
      }
    },
    continueProccess() {
      // const fileInside = this.selectionFiles.findIndex(f => f.file === this.docModal._id);

      // if (fileInside === -1) {
      //   this.selectionFiles.push({file: this.docModal._id, optionSelected: this.optionSelected});
      // } else {
      //   this.selectionFiles[fileInside] = {file: this.docModal._id, optionSelected: this.optionSelected};
      // }

      if (this.optionSelected === 1) {
        this.askPermits();
      } else {
        this.takeVideoByQR();
      }

      this.optionSelected = null;
    },
    async takeVideoByQR() {
      this.showLoadingCam = true;
      this.$bvModal.hide(nameModalUpload);

      this.$bvModal.show("modal-qr");
      const res = await UserApi.generateUrlQR({
        fileDocument: this.docModal._id,
        shareholder: this.idShareholder,
      }).catch(catchError);

      if (res && res.data) {
        if (res.data.error) {
          this.displayNotificationError(
            "Ocurrio un error con la generacion de qr"
          );
        } else {
          this.urlQR = res.data.object;
        }
      } else {
        this.displayNotificationError(
          "Ocurrio un error con la generacion de qr"
        );
      }
    },
    openModal(doc) {
      this.docModal = doc;
      this.$bvModal.show(nameModalUpload);
      // this.askPermits();
      // this.$bvModal.show(nameModalVideoPermit);
    },
    async openInstructions() {
      this.$bvModal.show(nameModalHowTake);
      this.$forceUpdate();
      this.$bvModal.hide(nameModalVideoPermit);
    },
    askPermits() {
      if (navigator.permissions) {
        navigator.permissions.query({ name: "camera" }).then((res) => {
          if (res.state === "granted") {
            this.openInstructions();

            this.$bvModal.hide(nameModalUpload);
          } else {
            if (res.state === "denied") {
              this.displayNotificationError(
                "El permiso de camara esta bloqueado. Si deseas usar la camara necesitas quitar el bloque de camara!"
              );
            } else {
              this.$bvModal.show("modal-video-permiso-test");
              this.$bvModal.hide(nameModalUpload);
            }
          }
        });
      } else {
        if (navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia({ video: true }).then(() => {
            this.openInstructions();
            this.$bvModal.hide(nameModalUpload);
          });
        } else {
          this.displayNotificationError("browser not support");
        }
      }
    },
    acceptPermission() {
      navigator.getUserMedia(
        // constraints
        {
          video: true,
        },

        // successCallback
        // this.openWebcam(),
        () => {
          this.openInstructions();
          // this.openWebcam();
        },

        // errorCallback
        () => {
          this.displayNotificationError(
            this.$i18n.t("components.dynamicDoc.modalVideoPermit.errorPermit")
          );
          navigator.mediaDevices.getUserMedia({ video: true });
        }
      );
    },
    openWebcam() {
      this.showLoadingCam = true;
      this.replay = false;
      this.$bvModal.hide(nameModalHowTake);
      this.$bvModal.show(nameModalTakeVideo);
      const x = setInterval(() => {
        if (Object.prototype.hasOwnProperty.call(this.$refs, "upload-video-test")) {
          clearInterval(x);
          this.initCamera(this._initCamera);
        }
      }, 1000);
    },
    // Videos
    _initCamera() {
      if (this.isVideo) {
        this.showStart = true;
      } else {
        this.showTakePhoto = true;
        this.proccessPhoto = false;
        this.takeOtherPhoto = false;
        this.finalizePhoto = false;
      }
      this.showLoadingCam = false;
    },
    startRecCamera() {
      this.showStop = true;
      this.showStart = false;
      this.showStarting = false;
      this.showPlay = false;
      this.showPause = true;
      this.replay = false;
    },
    stopRecCamera(data) {
      this.blobVideo = data;
      this.showLoadingCam = true;
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.resultCamera = reader.result;
      });
      reader.readAsDataURL(data);
      this.showStop = false;
      this.showStarting = false;
      this.showStart = false;
      this.showPlay = false;
      this.showPause = false;
      this.stopCamera();
      this.playCamera();
      this.replay = true;
      this.showLoadingCam = false;
    },
    emitStop() {
      EventBus.$emit("global.cameraVue.turn-off");
    },
    emitPlay() {
      EventBus.$emit("global.cameraVue.play-video");
      this.videoPlaying = true;
    },
    async confirmVideo() {
      this.$bvModal.hide(nameModalTakeVideo);
      this.$bvModal.show("modal-video-analisis");
      this.$set(this.filesUploadPercentage, this.docModal.key, 100);

      // this.$set(this.files, this.docModal.key, {
      //   title: `${this.docModal.title}`,
      //   key: this.docModal.key,
      //   fileDocument: this.docModal._id,
      //   isRequired: this.docModal.isRequired,
      //   file: {
      //     name: this.blobVideo.name || this.docModal.title,
      //     type: this.blobVideo.type,
      //     size: this.blobVideo.size,
      //     data: this.resultCamera.split(',')[1],
      //   },
      // });

      this.$emit("add-file", {
        key: this.docModal.key,
        file: {
          title: `${this.docModal.title}`,
          key: this.docModal.key,
          fileDocument: this.docModal._id || this.docModal.id,
          isRequired: this.docModal.isRequired,
          file: {
            name: this.blobVideo.name || this.docModal.title,
            type: this.blobVideo.type,
            size: this.blobVideo.size,
            data: this.resultCamera.split(",")[1],
          },
        },
      });

      setTimeout(() => {
        this.$bvModal.hide("modal-video-analisis");
      }, 3000);
    },
    recordAgain() {
      this.showLoadingCam = true;
      this.replay = false;
      this.showStart = true;
      this.initCamera(this._initCamera);
    },
    handleOut() {
      if (this.modalToClose === nameModalTakeVideo) {
        this.resultCamera = null;
        this.blobVideo = null;
        this.showStart = false;
        this.showStop = false;
        this.showPlay = false;
        this.showPause = false;
        this.replay = false;
      }
      this.$bvModal.hide(nameModalVideoSalir);
    },
    continueProccessCamera() {
      this.$bvModal.hide(nameModalVideoSalir);
      this.$bvModal.show(this.modalToClose);

      if (this.modalToClose === nameModalTakeVideo) {
        this.openWebcam();
      }
    },
    handleClose(modal) {
      this.$bvModal.show(nameModalVideoSalir);
      this.modalToClose = modal;
      this.$bvModal.hide(modal);
    },
    cancelVideo() {
      // this.$bvModal.hide(nameModalTakevideo);
      this.handleClose(nameModalTakeVideo);
    },
    reloadDocs(files) {
      for (const { key } of this.docs) {
        if (files.includes(key)) {
          this.$set(this.filesUploadPercentage, key, 100);
        }
      }
    },
  },
  created() {
    this.filesUploadPercentage = {};
    EventBus.$on("LOAD-DYNAMIC-DOCS-COM", this.reloadDocs);
  },
  beforeMount() {
    this.$emit("beforeMount");
  },
  beforeDestroy() {
    EventBus.$off("LOAD-DYNAMIC-DOCS-COM", this.reloadDocs);
  },
};
</script>

<style scoped>
</style>
