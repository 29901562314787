<template>
  <div>
    <TitlePrincipal :title="$t('register.newNip.title')" clazz="text-align-c">
      <p class="subtitle" v-html="$t('register.newNip.subtitle')"></p>
    </TitlePrincipal>
<!--    <img class="img-fluid max-h-300px d-flex m-0-auto"-->
<!--         src="@/assets/images/Illustrations/illustration-nip-seguridad.svg"-->
<!--         alt="NIP de seguridad"/>-->
    <CodeInput clazz="m-t-40"
               typeInput="password"
               :warningText="$t('register.newNip.warningText')"
               :fourCodes="true"
               v-on:verificationCode="onChangeCode"/>
    <div class="footer-buttons center">
      <button @click="back()" type="button" class="button button-ghost button-plain_text" name="Regresar"> {{
          $t('register.newNip.back')
        }}
      </button>
      <button v-if="showConfirm" type="button" class="button button-filled button-primary" name="Continuar"
              :disabled="!isValidPin" @click="submitPin">
        {{ $t('register.newNip.continue') }}
      </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text"/>
      </button>
    </div>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import CodeInput from "@/components/CodeInput";
import LoadingSimple from "@/components/LoadingSimple";
import {GET_LEVEL, GET_CARD_REQUEST, STORE as onbngStore} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import UserApi from "@/api/users/users.api";
import {catchError} from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import { mapGetters } from 'vuex';

export default {
  name: "NewNip",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    CodeInput,
    LoadingSimple
  },
  computed: {
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      cardRequest: GET_CARD_REQUEST
    }),
    isValidPin() {
      return this.pin && this.pin.length === 4;
    }
  },
  data() {
    return {
      showConfirm: true,
      pin: ""
    }
  },
  methods: {
    back() {
      if(this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${onbngStore}/backBusiness`)
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`)
      }
    },
    onChangeCode(value) {
      this.pin = value;
    },
    async submitPin() {
      this.showConfirm = false;
      if (this.isValidPin) {
        const response = await UserApi.savePin({
          newPin: this.pin
        }).catch(catchError);
        if (response && response.data) {
          if (response.data.error) {
            const message = response.data.message || this.$i18n.t('register.newNip.errorSave');
            this.displayNotificationError(message)
          } else {
            this.nextStep();
          }
        }
      }
      this.showConfirm = true;
    },
    nextStep() {
      if(this.level === 0) {
        // NO USAR función STORE GO_TO_ROUTE
        this.$router.push({name: 'confirmPinCollaborator'});
      } else {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.CONFIRM_NIP,
        });
        let goTo = "confirmPin";
        if (this.cardRequest.accountType === 'BUSINESS') {
          goTo = "businessConfirmPin"
        }
        // NO USAR función STORE GO_TO_ROUTE
        this.$router.push({name: goTo});
      }
    },
  },
  beforeMount() {
    this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
  },
}
</script>

<style scoped>

</style>
