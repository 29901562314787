<template>
    <div
        class="checkbox"
        :class="clazz"
    >
        <input
            :class="inputClass"
            :checked="checkboxValue"
            :id="checkId"
            type="checkbox"
            v-model="checkboxValue"
            :true-value="true"
            :false-value="false"
            :value="checkboxValue"
            @change="onCheckboxChange"
            :disabled="disabled"
            placeholder="Checkbox"
        >
        <i
            aria-hidden="true"
            class="input-helper"
        ></i>
        <span :class="[textClass, 'c-pointer']" v-if="clickableText" @click="toggle">{{text}}</span>
        <span :class="textClass" v-else>{{text}}</span>
        <span>
            <slot></slot>
        </span>
    </div>
</template>

<script>
export default {
    name: 'BasicCheckbox',
    data () {
        return {
            checkboxValue: false
        }
    },
    props: {
        checkId: String,
        text: String,
        clazz: String,
        textClass: String,
        inputClass: String,
        isDisabled: { type: Boolean, default: false },
        index: { type: Number, default: null },
        value: { type: Boolean, default: false },
        selectAll: { type: Boolean, default: false },
        parentIndex: {type: Number, default: null},
        clickableText: {type: Boolean, default: false},
        disabled: {type: Boolean, default: false}
    },
    watch: {
      value(){
          this.checkboxValue = this.value;
      }
    },
    methods: {
        toggle() {
            this.checkboxValue = !this.checkboxValue;
            this.onCheckboxChange();
        },
        onCheckboxChange () {
            // if (this.index == null) {
            //     this.$emit('change', this.checkboxValue)
            //     this.$emit('input', this.checkboxValue)
            // }else if(this.parentIndex !== null && this.parentIndex !== undefined){
            //         this.$emit('change', {checkboxValue: this.checkboxValue, index: this.index, parentIndex: this.parentIndex}) ;
            // } else {
            //     const index = this.index;
            //     const checkValue = this.checkboxValue;
            //     this.$emit('change', { index, checkValue })
            // }
            this.$emit('input', this.checkboxValue);
        },
        // checkStoreValue (value) {
        //     this.checkboxValue = value;
        // },
        // setDisabled (value) {
        //     this.isDisabled = value;
        // },
    },
    mounted () {
        this.checkboxValue = this.value;
    }
}
</script>
