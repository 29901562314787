import LoginBase from "@/pages/Login/LoginBase";
import LoginSecurity from "@/pages/Login/LoginSecurity";
import RecoveEmail from "@/pages/Login/RecoveEmail";
import RecoverPass from "@/pages/Login/RecoverPass";
import ChangePass from "@/pages/Login/ChangePass";

export default [
    {
        path: '',
        name: "loginBase",
        component: LoginBase
    },
    {
        path: 'security',
        name: "loginSecurity",
        component: LoginSecurity
    },
    {
        path: 'recover-email',
        name: "recoverEmail",
        component: RecoveEmail
    },
    {
        path: 'recover-pass',
        name: "recoverPass",
        component: RecoverPass
    },
    {
        path: 'recover-security/:token',
        name: "recoverSecurity",
        component: ChangePass
    }
];
