<template>
  <div class="row">
    <div class="col-12">
      <router-link
        :to="{name: 'CorporativeAccountList'}"
        class="button xs button-ghost button-text_info p-l-0 p-r-0"
        tabindex=""
      >
        <i
          aria-hidden="true"
          class="iconsax-arrow-arrow-left f-18 c-info m-r-10"
        ></i>
        {{$t('corporativeAccounts.new.back')}}
      </router-link>
      <FloatingTitleLayout
        :titleStrong="$t('corporativeAccounts.new.header')"
      ></FloatingTitleLayout>
    </div>
    <CorporativeAccountForm
      v-show="status === 'NEW'"
      class="col-12"
      :value="requestData"
      :showLoadingButton="processing"
      @submit="submit"
      @cancel="$router.push({name: 'CorporativeAccountList'})"
    />
    <div v-if="status === 'PROCESSING'">
      <CardSimple hideTitle>
        <StatusNote
          status="default"
          :title="$t('register.processingRequest.title')"
          :text="$t('register.processingRequest.subtitle')"
          :imgNote="false"
        >
          <template>
            <img class="img-fluid max-h-300px d-flex m-0-auto"
                src="@/assets/images/Illustrations/illustration-solicitud-proceso.svg"
                :alt="$t('corporativeAccounts.new.processingAlt')"/>
            <span class="f-14 c-title poppins-font-medium text-align-c d-block m-t-20 m-b-20">
              <p>{{ $t('register.processingRequest.currentStatus') }}</p>
              <strong class="f-24 c-info poppins-font-bold">{{$t('corporativeAccounts.new.processing')}}</strong>
            </span>
          </template>
        </StatusNote>
      </CardSimple>
      <div class="control-buttons">
        <button type="button" class="button-icon" @click="returnToList">
          <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
          {{$t('corporativeAccounts.new.return')}}
        </button>
      </div>
    </div>
    <div v-else-if="status === 'SUCCESS'">
      <CardSimple hideTitle>
        <StatusNote
          status="successs"
          :title="$t('corporativeAccounts.new.success.title')"
          :text="$t('corporativeAccounts.new.success.text', {email})"
          :imgWidth="284"
          :imgHeight="280"
          :imgAlt="$t('corporativeAccounts.new.success.title')"
        >
        </StatusNote>
      </CardSimple>
      <div class="control-buttons">
        <button type="button" class="button-icon" @click="returnToList">
          <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
          {{$t('corporativeAccounts.new.return')}}
        </button>
        <div class="vertical-center">
          <button type="button" class="button button-filled button-primary" @click="returnToList">
            {{$t('corporativeAccounts.new.finish')}}
          </button>
        </div>
      </div>
    </div>
    <div v-else-if="status === 'ERROR'">
      <CardSimple hideTitle>
        <StatusNote
          status="error"
          :title="$t('corporativeAccounts.new.error.title')"
          :text="$t('corporativeAccounts.new.error.text')"
          :imgWidth="124"
          :imgHeight="124"
          :imgAlt="$t('corporativeAccounts.new.error.title')"
          :imgUrl="imgErrorStatus"
        >
          <p class="text-center f-18 poppins-font-bold m-t-30 c-text_title">
            {{rejectReason || $t('corporativeAccounts.new.genericError')}}
          </p>
        </StatusNote>
      </CardSimple>
      <div class="control-buttons">
        <button type="button" class="button button-stroke button-primary" @click="openZendesk">
          <i aria-hidden="true" class="iconsax-emails-messages-4 m-r-10"></i>
          {{$t('corporativeAccounts.new.contactSupport')}}
        </button>
        <div class="vertical-center">
          <button type="button" class="button button-filled button-primary" @click="status = 'NEW'">
            {{$t('corporativeAccounts.new.retry')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import CardSimple from "@/components/CardSimple.vue";
import StatusNote from "@/components/StatusNote.vue";
import CorporativeAccountForm from "@/pages/CorporativeAccounts/CorporativeAccountForm";

import imgErrorStatus from "@/assets/images/Illustrations/illustration-status-error.svg";

import CorporativeAccountsApi from "@/api/wallet/corporativeAccounts.api";

export default {
  name: "NewCorporativeAccount",
  components: {
    FloatingTitleLayout,
    CardSimple,
    StatusNote,
    CorporativeAccountForm,
  },
  props: {
    requestData: Object,
  },
  data() {
    return {
      // Images
      imgErrorStatus,

      processing: false,
      status: 'NEW', // NEW | PROCESSING | SUCCESS | ERROR
      requestId: null,
      interval: null,
      rejectReason: null,
      email: null,
      retryAccountId: null,
    };
  },
  created() {
    if(this.requestData?.status === 'ERROR') {
      this.status = 'ERROR';
      this.rejectReason = this.requestData.rejectReason;
    } else if(this.requestData?.status === 'PROCESSING') {
      this.status = 'PROCESSING';
      this.requestId = this.requestData.requestId;
      this.retryAccountId = this.requestData._id;
      this.email = this.requestData.userEmail;
      this.startInterval();
    }
  },
  methods: {
    returnToList() {
      this.$router.push({name: 'CorporativeAccountList'});
    },
    async submit(data) {
      try {
        if(!this.processing && await this.$requestAuth()) {
          this.processing = true;
          if(this.retryAccountId) {
            data.pagandoAccount = this.retryAccountId;
          }
          const response = await CorporativeAccountsApi.save(data);
          const { requestId, pagandoAccount } = response.data.object;
          this.requestId = requestId;
          this.retryAccountId = pagandoAccount;
          this.email = data.email;
          if(response.status === 202) {
            // Si el colaborador no tiene cuenta en pagando, no es necesario mostrar pantalla de Procesando
            this.status = 'SUCCESS';
          } else {
            // Si el colaborador ya tiene cuenta en pagando, esperar a que se termine de crear la cuenta
            this.status = 'PROCESSING';
            this.startInterval();
          }
        }
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.processing = false;
    },
    openZendesk() {
      if(window.zE) {
        window.zE('messenger', 'open');
      }
    },
    startInterval() {
      // Every 10 seconds
      this.interval = setInterval(this.checkStatus, 10*1000);
      this.checkStatus();
    },
    async checkStatus() {
      try {
        const response = await CorporativeAccountsApi.checkStatus({requestId: this.requestId});
        const {status, rejectReason} = response.data.object;
        if(['SUCCESS', 'ERROR'].includes(status)) {
          this.status = status;
          this.rejectReason = rejectReason;
          if(this.interval) {
            clearInterval(this.interval);
          }
        }
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
  },
  beforeDestroy() {
      if(this.interval) {
        clearInterval(this.interval);
      }
  },
};
</script>
