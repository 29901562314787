const base = require('@/api/base.api').default;
const moment = require("moment");
const momentTZ = require('moment-timezone');
const dateFormat = "DD/MM/YYYY HH:mm";

const sequential = function(password) {
    const passwordLowercase = password.toLowerCase();
    let groups = passwordLowercase.match(/[A-Z]+|[a-z]+|[0-9]+/g);
    let score = 0;
    if (groups) {
        groups = groups.filter(function (element) {
            return element.length >= 3;
        });

        for (const group of groups) {
            let splitGroup = [];
            for (let i = 0; i < group.split("").length - 2; i++) {
                splitGroup.push(group.substring(i, i + 3));
            }

            splitGroup = [...new Set(splitGroup)];

            score = _sequentialLoop1(splitGroup, score);
            score = _sequentialLoop2(splitGroup, score);
        }
    }
    return score * 3;
};

const sequentialSymbols = function (password) {
    const groups = password.match(/[^a-zA-z0-9]+/g);
    let score = 0;
    if (groups) {
        let triGroups = [];

        for (const group of groups) {
            triGroups = triGroups.concat(group.match(/.{1,3}/g));
        }

        triGroups = [...new Set(triGroups)];
        triGroups = triGroups.filter(function (element) {
            return element.length === 3;
        });

        score = triGroups.length;
    }

    return score * 3;
}

const _sequentialLoop1 = function (splitGroup, score) {
    for (const splitGroupElement of splitGroup) {
        let point = 0;
        for (
            let i = 0;
            i < splitGroupElement.split("").length - 1;
            i++
        ) {
            if (splitGroupElement[i] < splitGroupElement[i + 1]) {
                if (
                    _nextChar(splitGroupElement[i]) ===
                    splitGroupElement[i + 1]
                ) {
                    point += 0.5;
                } else {
                    break;
                }
            } else {
                break;
            }
        }

        if (point === 1) {
            score += 1;
        }
    }
    return score;
};

const _sequentialLoop2 = function(splitGroup, score) {
    for (const splitGroupElement of splitGroup) {
        let point = 0;
        for (
            let i = 0;
            i < splitGroupElement.split("").length - 1;
            i++
        ) {
            if (splitGroupElement[i] > splitGroupElement[i + 1]) {
                if (
                    _prevChar(splitGroupElement[i]) === splitGroupElement[i + 1]
                ) {
                    point += 0.5;
                } else {
                    break;
                }
            } else {
                break;
            }
        }

        if (point === 1) {
            score += 1;
        }
    }
    return score;
};

const _nextChar = function (c) {
    return String.fromCharCode(c.charCodeAt(0) + 1);
};

const _prevChar = function (c) {
    return String.fromCharCode(c.charCodeAt(0) - 1);
};

const sortByLength = (arr, limit) => {
    arr.sort(function (a, b) {
        return b.length - a.length;
    });
    const list = [];
    for (let index = 0; index < arr.length; index++) {
        if (limit) {
            if (arr[index].length >= limit) {
                list.push(arr[index]);
            }
        } else {
            list.push(arr[index]);
        }
    }
    return list;
}

const catchError = function (err) {
    if (isDefined(err.response) && hasProperty(err, "response") && hasProperty(err.response, "data")) {
        return err.response;
    }
    return {
        data: {
            error: true,
            err: err
        }
    }
}

const formatMoney = function(value) {
    if (!value) {
        return "$0.00"
    }
    return `${new Intl.NumberFormat("es-MX", {style: "currency", currency: "MXN"}).format(Number(value))}`
}

const getWeekDayByNoAbbr = (number) => {
    switch (number) {
        default:
        case 0: return "general.weekDaysAbbr.Sunday";
        case 1: return "general.weekDaysAbbr.Monday";
        case 2: return "general.weekDaysAbbr.Tuesday";
        case 3: return "general.weekDaysAbbr.Wednesday";
        case 4: return "general.weekDaysAbbr.Thursday";
        case 5: return "general.weekDaysAbbr.Friday";
        case 6: return "general.weekDaysAbbr.Saturday";
    }
}

const getMonthByNo = (number) => {
    switch (number) {
        case 0:
            return "general.months.January";
        case 1:
            return "general.months.February";
        case 2:
            return "general.months.March";
        case 3:
            return "general.months.April";
        case 4:
            return "general.months.May";
        case 5:
            return "general.months.June";
        case 6:
            return "general.months.July";
        case 7:
            return "general.months.August";
        case 8:
            return "general.months.September";
        case 9:
            return "general.months.October";
        case 10:
            return "general.months.November";
        default:
            return "general.months.December";
    }
}

const getMonthByNoAbbr = (number) => {
    switch (number) {
        case 0:
            return "general.monthsAbbr.January";
        case 1:
            return "general.monthsAbbr.February";
        case 2:
            return "general.monthsAbbr.March";
        case 3:
            return "general.monthsAbbr.April";
        case 4:
            return "general.monthsAbbr.May";
        case 5:
            return "general.monthsAbbr.June";
        case 6:
            return "general.monthsAbbr.July";
        case 7:
            return "general.monthsAbbr.August";
        case 8:
            return "general.monthsAbbr.September";
        case 9:
            return "general.monthsAbbr.October";
        case 10:
            return "general.monthsAbbr.November";
        default:
            return "general.monthsAbbr.December";
    }
}

const checkPositiveNumber = (value = "") => {
    return /^\d+$/.test(value);
}

const randomIntFromInterval = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}

const isDefined = (obj) => {
    return obj !== undefined && obj !== null;
};

const isDefinedNonEmptyStr = (obj) => {
    return obj !== undefined && obj !== null && obj !== '';
};

const isDefinedNonEmptyObj = (obj) => {
    return obj !== undefined && obj !== null && !isObjectEmpty(obj);
};

const isObjectEmpty = (obj) => {
    if (isDefined(obj)) {
        return Object.keys(obj).length === 0 && obj.constructor === Object;
    }
    return false;
};

const hasProperty = (obj, tag) => {
    return Object.prototype.hasOwnProperty.call(obj, tag)
};

const capitalizeFirst = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
}

const stringIsUUID = function (string) {
    return /^[A-Za-z0-9]{24}$/.test(string);
}

const imageDownloadLink = function (file, render) {
    if (file) {
        if (file._id || stringIsUUID(file.toString())) {
            return `${base.baseUrl}/image/download/${file._id || file}?render=${!!render}`;
        }
        // File might already be a link
        return file.toString();
    }
    return null;
}
const index = {};
const debounce = function(func, options = {}, ...params) {
    const functionCallId = func.toString() + JSON.stringify(params || []);
    const isWaitingToRun = index[functionCallId];
    if (isWaitingToRun) {
        clearTimeout(index[functionCallId]);
    }
    index[functionCallId] = setTimeout(() => {
        if (options.context) {
            func.apply(options.context, params);
        } else {
            func.apply(params);
        }
    }, options.time || 1000);
}

/**
 * función que transforma un string a un booleano
 * Nota: Esta función prioriza el valor verdadero cualquier cosa
 *  diferente se considera falso
 * @param val
 * @return {boolean|boolean}
 */
const stringToBoolean = (val) => {
    if (isDefined(val)) {
        return typeof val === 'boolean' ? val : val.toLowerCase() === 'true';
    }
    return false;
}

const isBoolean = (val) => {
  return typeof val === 'boolean';
}

/**
 * return the last day of a month
 * @param month
 * @param year
 * @return {Date}
 */
const lastDateMonth = (month, year) => {
    return new Date(year, month + 1, 0);
}

/**
 * Transforma un timestamp a fecha
 * @param timestamp
 * @return {Date}
 */
const timestampToDate = (timestamp) => {
  try {
      return new Date(parseFloat(timestamp));
  } catch(err) {
      return new Date();
  }
}

/**
 * Redondea un número para evitar numeros raros de jaascript. Esto se hace debido a que en javascript es muy común
 * terminar con números de la forma 115.999999999
 *
 * @param num {number} a redondear
 * @returns {number} número redondeado
 */
 function jsNumFix(num) {
    return Math.round(num * 100 + Number.EPSILON) / 100;
}

/**
 * Busca una propiedad en el refs hasta que éste en el Dom
 * @param context
 * @param ref
 * @param attempts
 * @return {Promise<unknown>}
 */
const findRef = (context, ref, attempts = 10) => {
    return new Promise((res) => {
        let attempt = 0
        const x = setInterval(() => {
            if (Object.prototype.hasOwnProperty.call(context.$refs, ref) || attempt >= attempts) {
                clearInterval(x);
                res(context.$refs[ref]);
            }
            attempt +=1
        }, 1000);
    });
}

/**
 * comprueba si un string es una fecha válida
 * @param {string} date
 * @return {boolean}
 */
const stringDateIsValid = (date) => {
    try {
        return moment(date, dateFormat, true).isValid();
    } catch {
        return false
    }
}

/**
 * Check if a date is in the future
 * @param {string} date
 * @return {boolean}
 */
const checkIfDateIsInFuture = (date) => {
    const dateFuture = moment(date, dateFormat);
    const datePresent = moment(new Date(), dateFormat);
    const diff = datePresent.diff(dateFuture);
    return diff < 0;
}

const getCurrentTimeZone = () => {
    let timezone = "America/Monterrey";
    try {
        const tempTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const valid = !!moment.tz.zone(tempTimezone);
        if (valid) {
            timezone = tempTimezone;
        }
    } catch(err) {
        console.error(err)
    }
    return timezone;
}


const dateToGreenwich = (timestamp, timezone) => {
    const currentZone = momentTZ.tz(timestamp, timezone);
    const greenwichTime = currentZone.clone().tz("Greenwich");
    return greenwichTime.format();
}

const formatPhone = (phone) => {
    const cleaned = (`${phone}`).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    const match1 = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);
    if(match1) {
        return `(${match1[2]}) ${match1[3]}-${match1[4]}`;
    }
    return null;
};

/**
 * Retorna el número de segundos que han pasado desde la fecha dada
 * @param {Date} date
 * @returns {number}
 */
const secondsSince = (date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    return Math.floor(diff / 1000);
}

module.exports = {
    findRef,
    debounce,
    jsNumFix,
    isDefined,
    isBoolean,
    sequential,
    catchError,
    formatMoney,
    hasProperty,
    formatPhone,
    getMonthByNo,
    stringIsUUID,
    sortByLength,
    secondsSince,
    lastDateMonth,
    isObjectEmpty,
    dateToGreenwich,
    capitalizeFirst,
    timestampToDate,
    stringToBoolean,
    getMonthByNoAbbr,
    sequentialSymbols,
    stringDateIsValid,
    imageDownloadLink,
    getWeekDayByNoAbbr,
    getCurrentTimeZone,
    checkPositiveNumber,
    isDefinedNonEmptyStr,
    isDefinedNonEmptyObj,
    randomIntFromInterval,
    checkIfDateIsInFuture,
};
