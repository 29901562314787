<template>
  <div>
    <div class="row">
      <div class="col-12">
        <FloatingTitleLayout
          :titleStrong="$t('corporativeAccounts.list.title')"
          :subtitle="$t('corporativeAccounts.list.subtitle')"
        >
          <template v-slot:buttons v-if="canCreateCorporativeAccounts">
            <button
              id=""
              type="button"
              class="button sm button-filled button-primary"
              @click="$router.push({name: 'NewCorporativeAccount'})"
            >
              <i aria-hidden="true" class="iconsax-essential-add-square m-r-10"></i>
              {{$t('corporativeAccounts.list.new')}}
            </button>
          </template>
        </FloatingTitleLayout>
      </div>
      <div class="col-12">
        <TitleBadge
          clazzContainer="p-0"
          :titleText="$t('corporativeAccounts.list.myAccounts')"
          :badgeText="(pagination.totalDocs || 0).toString()"
        ></TitleBadge>

        <BasicInput
          clazz="m-b-15 noLabel panel-search"
          inputType="text"
          :inputPlaceholder="$t('corporativeAccounts.list.search')"
          inputClazz="icon-left"
          iconInput="banana-search-normal"
          @basicInput="onSearch"
        ></BasicInput>

        <CardSimple clazz="p-0" hideTitle>
          <div id="menuOverflowFix" class="basic-table">
            <b-table-simple
              responsive
              selectable
              selected-variant="active"
              table-variant="xl"
            >
              <b-thead>
                <b-tr class="">
                  <b-th>{{$t('corporativeAccounts.list.headers.key')}}</b-th>
                  <b-th>{{$t('corporativeAccounts.list.headers.alias')}}</b-th>
                  <b-th class="text-center">{{$t('corporativeAccounts.list.headers.collaborator')}}</b-th>
                  <b-th class="text-center">{{$t('corporativeAccounts.list.headers.status')}}</b-th>
                  <b-th>{{$t('corporativeAccounts.list.headers.monthUsage')}}</b-th>
                  <b-th></b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr class="noHover" v-if="loading">
                  <b-td colspan="6">
                    <LoadingBar
                      clazz="p-t-50 p-b-50"
                      :loadingText="$t('corporativeAccounts.list.loading')"
                    ></LoadingBar>
                  </b-td>
                </b-tr>
                <b-tr class="" v-else-if="accounts.length" v-for="account in accounts" :key="account._id">
                  <b-td>
                    <router-link
                      to=""
                      class="hover poppins-font-bold c-info text-underline"
                      tabindex=""
                      @click.native="goToDetails(account)"
                    >
                      {{account.fullKey}}
                    </router-link>
                  </b-td>
                  <b-td>{{account.alias}}</b-td>
                  <b-td class="text-center c-text_content">
                    <strong v-if="account.userName" class="f-14">{{account.userName}}</strong><br v-if="account.userName"/>
                    {{account.userEmail}}
                  </b-td>
                  <b-td class="text-center">
                    <Badge v-if="account.status === 'CREATED'" type="badge-info" :text="$t('corporativeAccounts.list.badges.CREATED')"></Badge>
                    <Badge v-if="account.status === 'ERROR'" type="badge-error" :text="$t('corporativeAccounts.list.badges.ERROR')"></Badge>
                    <Badge v-if="account.status === 'ACTIVE'" type="badge-success" :text="$t('corporativeAccounts.list.badges.ACTIVE')"></Badge>
                    <Badge v-if="account.status === 'INACTIVE'" type="badge-text_content" :text="$t('corporativeAccounts.list.badges.INACTIVE')"></Badge>
                    <Badge v-if="account.status === 'PROCESSING'" type="badge-warning" :text="$t('corporativeAccounts.list.badges.PROCESSING')"></Badge>
                  </b-td>
                  <b-td class="text-center min-w-150px">
                    <div>
                      <b-progress
                        max="15000"
                        class="bar-progress percent-color"
                      >
                        <b-progress-bar :value="account.balance.current">
                          <div class="label-progress">
                            <span>{{account.balance.current | currency}}</span>
                            <span>{{account.balance.limit | currency}}</span>
                          </div>
                        </b-progress-bar>
                      </b-progress>
                    </div>
                  </b-td>
                  <b-td class="text-center">
                    <b-dropdown
                      @show="toggleDropdown"
                      @hide="toggleDropdown"
                      id="dropdown-table"
                      right
                      class="dropdown-table"
                    >
                      <template v-slot:button-content>
                        <button type="button" class="button-square button-filled button-primary xs" aria-label="">
                          <i aria-hidden="true" class="iconsax-essential-more-vertical-dots"></i>
                        </button>
                      </template>
                      <b-dropdown-group :header="$t('corporativeAccounts.list.actions.header')">
                        <b-dropdown-item href="#" @click="goToDetails(account)">
                          <i aria-hidden="true" class="iconsax-security-eye"></i>
                          {{$t('corporativeAccounts.list.actions.details')}}
                        </b-dropdown-item>
                        <b-dropdown-item href="#" @click="goToActivity(account)" v-if="['ACTIVE', 'INACTIVE'].includes(account.status)">
                          <i aria-hidden="true" class="iconsax-business-chart-2"></i>
                          {{$t('corporativeAccounts.list.actions.activity')}}
                        </b-dropdown-item>
                        <b-dropdown-item href="#" @click="goToEdit(account)" v-if="['ACTIVE', 'INACTIVE', 'CREATED'].includes(account.status)">
                          <i aria-hidden="true" class="iconsax-content-edit-2"></i>
                          {{$t('corporativeAccounts.list.actions.edit')}}
                        </b-dropdown-item>
                        <b-dropdown-item-button @click="openUnblockModal(account)" v-if="account.status === 'INACTIVE'">
                          <i aria-hidden="true" class="iconsax-security-unlock"></i>
                          {{$t('corporativeAccounts.list.actions.unblock')}}
                        </b-dropdown-item-button>
                        <b-dropdown-item-button @click="openBlockModal(account)" v-if="account.status === 'ACTIVE'">
                          <i aria-hidden="true" class="iconsax-security-lock"></i>
                          {{$t('corporativeAccounts.list.actions.block')}}
                        </b-dropdown-item-button>
                        <b-dropdown-item-button @click="resendEmail(account)" v-if="account.status === 'CREATED'">
                          <i aria-hidden="true" class="iconsax-emails-sms"></i>
                          {{$t('corporativeAccounts.list.actions.resendEmail')}}
                        </b-dropdown-item-button>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-item-button variant="c-error" @click="openDeleteModal(account)">
                          <i aria-hidden="true" class="iconsax-essential-trash"></i>
                          {{$t('corporativeAccounts.list.actions.delete')}}
                        </b-dropdown-item-button>
                      </b-dropdown-group>
                    </b-dropdown>
                  </b-td>
                </b-tr>
                <b-tr class="noHover" v-else>
                  <b-td colspan="6">
                    <EmptyState
                      clazz="p-0"
                      emptyStateDefault
                      :title="$t('corporativeAccounts.list.empty.title')"
                      :text="$t('corporativeAccounts.list.empty.text')"
                      :imgWidth="520"
                      :imgHeight="320"
                      :imgAlt="$t('corporativeAccounts.list.empty.title')"
                    >
                      <button
                        id=""
                        type="button"
                        class="button sm button-filled button-primary m-auto m-t-30"
                        v-if="canCreateCorporativeAccounts"
                        @click="$router.push({name: 'NewCorporativeAccount'})"
                      >
                        <i aria-hidden="true" class="iconsax-essential-add-square m-r-10"></i>
                        {{$t('corporativeAccounts.list.new')}}
                      </button>
                    </EmptyState>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </CardSimple>

        <div class="pagination-custom">
          <span class="pagination-count">
            {{$tc('corporativeAccounts.list.paginationCount', pagination.totalDocs, {total: pagination.totalDocs || 0})}}
          </span>
          <b-pagination
            v-model="currentPage"
            :total-rows="pagination.totalDocs"
            :per-page="perPage"
            aria-controls="my-table"
            next-text=""
            prev-text=""
            hide-goto-end-buttons
            @change="pageChange"
          ></b-pagination>
        </div>
      </div>
      <ModalAlert
        closeCross
        :modalId="modalDelete"
        buttonType="button-primary"
        :loading="loading"
        :imgUrl="imageModalTrash"
        :title="$t('corporativeAccounts.modals.delete.title')"
        :text="$t('corporativeAccounts.modals.delete.text')"
        :buttonCancel="$t('corporativeAccounts.modals.delete.buttonCancel')"
        :buttonText="$t('corporativeAccounts.modals.delete.buttonText')"
        @submit="deleteAccount"
      ></ModalAlert>
      <ModalAlert
        closeCross
        :modalId="modalBlock"
        buttonType="button-primary"
        :loading="loading"
        :imgUrl="imageLock"
        :title="$t('corporativeAccounts.modals.block.title')"
        :text="$t('corporativeAccounts.modals.block.text')"
        :buttonCancel="$t('corporativeAccounts.modals.block.buttonCancel')"
        :buttonText="$t('corporativeAccounts.modals.block.buttonText')"
        @submit="blockAccount"
      ></ModalAlert>
      <ModalAlert
        closeCross
        :modalId="modalUnblock"
        buttonType="button-primary"
        :loading="loading"
        :imgUrl="imageLock"
        :title="$t('corporativeAccounts.modals.unblock.title')"
        :text="$t('corporativeAccounts.modals.unblock.text')"
        :buttonCancel="$t('corporativeAccounts.modals.unblock.buttonCancel')"
        :buttonText="$t('corporativeAccounts.modals.unblock.buttonText')"
        @submit="unblockAccount"
      ></ModalAlert>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import TitleBadge from "@/components/TitleBadge.vue";
import BasicInput from "@/components/BasicInput.vue";
import CardSimple from "@/components/CardSimple.vue";
import EmptyState from "@/components/EmptyState.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import ModalAlert from "@/components/ModalAlert.vue";
import Badge from "@/components/Badge.vue";

import imageModalTrash from "@/assets/images/Icons/icon-trash-pink.svg";
import imageModalCard from "@/assets/images/Icons/icon-card-receive.svg";
import imageModalCard2 from "@/assets/images/Icons/icon-card-send.svg";
import imageLock from "@/assets/images/Icons/icon-lock.svg";

import MenuHandler from "@/mixins/menuHandler.mixin";

import CorporativeAccountsApi from "@/api/wallet/corporativeAccounts.api";

import { debounce } from '@/utils/utils';

import {
  STORE as sessionStore,
  CHECK_PERMISSION,
} from "@/store/modules/session/session.types";

export default {
  name: "CorporativeAccountList",
  mixins: [MenuHandler],
  components: {
    FloatingTitleLayout,
    TitleBadge,
    BasicInput,
    CardSimple,
    EmptyState,
    LoadingBar,
    ModalAlert,
    Badge,
  },
  data() {
    return {
      // Images
      imageModalTrash: imageModalTrash,
      imageModalCard: imageModalCard,
      imageModalCard2: imageModalCard2,
      imageLock,

      // Modal ids
      modalDelete: 'modal-delete',
      modalBlock: 'modal-block',
      modalUnblock: 'modal-unblock',

      accounts: [],
      pagination: {},
      loading: false,
      currentPage: 1,
      perPage: 5,
      search: null,

      selectedAccount: null,
    };
  },
  computed: {
    canCreateCorporativeAccounts() {
      const checkPermission = this.$store.getters[`${sessionStore}/${CHECK_PERMISSION}`];
      return checkPermission('AUTHORIZE', 'CORPORATIVE_ACCOUNTS');
    },
  },
  created() {
    this.loadList();
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      if([this.modalBlock, this.modalUnblock].includes(modalId)) {
        this.selectedAccount = null;
      }
    });
  },
  methods: {
    toggleDropdown() {
      document
        .getElementById("menuOverflowFix")
        .classList.toggle("dropdown-table-overflow");
    },
    async loadList() {
      try {
        this.loading = true;
        const response = await CorporativeAccountsApi.listAccounts({
          limit: this.perPage,
          page: this.currentPage,
          search: this.search,
        });
        const {docs, ...pagination} = response.data.object;
        this.accounts = docs;
        this.pagination = pagination;
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.loading = false;
    },
    pageChange(page) {
      this.currentPage = page;
      this.loadList();
    },
    onSearch(value) {
      this.loading = true;
      this.search = value;
      debounce(() => {
        this.currentPage = 1;
        this.loadList();
      }, {time: 500});
    },
    goToDetails(account) {
      this.$router.push({name: 'CorporativeAccountDetail', params: {id: account._id}});
    },
    goToEdit(account) {
      this.$router.push({name: 'EditCorporativeAccount', params: {id: account._id}});
    },
    goToActivity(account) {
      this.$router.push({name: 'CorporativeActivity', params: {id: account._id}});
    },
    onError(message) {
      this.displayNotificationError(message);
    },

    /** Block */
    openBlockModal(account) {
      this.selectedAccount = account;
      this.$bvModal.show(this.modalBlock);
    },
    async blockAccount() {
      try {
        this.loading = true;
        await CorporativeAccountsApi.block({id: this.selectedAccount._id});

        this.selectedAccount.status = 'INACTIVE';
        this.displayNotificationSuccess(this.$t('corporativeAccounts.modals.block.toastSuccess'));
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.$bvModal.hide(this.modalBlock);
      this.loading = false;
    },

    /** Unblock */
    openUnblockModal(account) {
      this.selectedAccount = account;
      this.$bvModal.show(this.modalUnblock);
    },
    async unblockAccount() {
      try {
        this.loading = true;
        await CorporativeAccountsApi.unblock({id: this.selectedAccount._id});

        this.selectedAccount.status = 'ACTIVE';
        this.displayNotificationSuccess(this.$t('corporativeAccounts.modals.unblock.toastSuccess'));
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.$bvModal.hide(this.modalUnblock);
      this.loading = false;
    },

    /** Delete */
    openDeleteModal(account) {
      this.selectedAccount = account;
      this.$bvModal.show(this.modalDelete);
    },
    async deleteAccount() {
      try {
        this.$bvModal.hide(this.modalDelete);
        if(await this.$requestAuth()) {
          this.loading = true;
          await CorporativeAccountsApi.delete({id: this.selectedAccount._id});

          this.displayNotificationSuccess(this.$t('corporativeAccounts.modals.delete.toastSuccess'));
          this.pageChange(1);
        }
      } catch (error) {
        this.toastErrorDefault(error);
        this.loading = false;
      }
    },

    /** Resend email */
    async resendEmail(account) {
      try {
        await CorporativeAccountsApi.resendEmail({id: account._id});
        this.toastSuccessDefault()
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
  },
};
</script>
