export const STORE = 'SHAREHOLDERS';
export const GET_SHAREHOLDER = 'GET_SHAREHOLDER';
export const SET_SHAREHOLDER = 'SET_SHAREHOLDER';
export const LOAD_SHAREHOLDER = 'LOAD_SHAREHOLDER';
export const SAVE_SHAREHOLDER = 'SAVE_SHAREHOLDER';
export const LOAD_PHYSICAL_DOCS = 'LOAD_PHYSICAL_DOCS';
export const GET_PHYSICAL_DOCS = 'GET_PHYSICAL_DOCS';
export const SET_PHYSICAL_DOCS = 'SET_PHYSICAL_DOCS';
export const LOAD_MORAL_DOCS = 'LOAD_MORAL_DOCS';
export const GET_MORAL_DOCS = 'GET_MORAL_DOCS';
export const SET_MORAL_DOCS = 'SET_MORAL_DOCS';
