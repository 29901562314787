// PAGES
// import newAccount from "@/pages/onboarding/rightSideSteps/newAccount";
const newAccount = () => import('@/pages/onboarding/rightSideSteps/newAccount')
import VerifyEmail from "@/pages/onboarding/rightSideSteps/VerifyEmail";
import VerificationEmail from "@/pages/onboarding/rightSideSteps/VerificationEmail";
import SelectAccountCard from "@/pages/onboarding/rightSideSteps/SelectAccountCard";
import UsagePolicies from "@/pages/onboarding/rightSideSteps/UsagePolicies";
import Nationality from "@/pages/onboarding/rightSideSteps/Nationality";
import PersonalData from "@/pages/onboarding/rightSideSteps/PersonalData";
import Residence from "@/pages/onboarding/rightSideSteps/Residence";
import Occupation from "@/pages/onboarding/rightSideSteps/Occupation";
import PhoneVerification from "@/pages/onboarding/rightSideSteps/PhoneVerification";
import CodeVerification from "@/pages/onboarding/rightSideSteps/CodeVerification";
import SuccessPhone from "@/pages/onboarding/rightSideSteps/SuccessPhone";
import SecurityMeasures from "@/pages/onboarding/rightSideSteps/SecurityMeasures";
import Beneficiaries from "@/pages/onboarding/rightSideSteps/Beneficiaries";
import NewNip from "@/pages/onboarding/rightSideSteps/NewNip";
import ConfirmPin from "@/pages/onboarding/rightSideSteps/ConfirmPin";
import ProcessingRequest from "@/pages/onboarding/rightSideSteps/ProcessingRequest";
import SuccessRequest from "@/pages/onboarding/rightSideSteps/SuccessRequest";
import Contract from "@/pages/onboarding/rightSideSteps/Contract";
import Sign from "@/pages/onboarding/rightSideSteps/Sign";
import PromotionalCode from "@/pages/onboarding/rightSideSteps/PromotionalCode";
import OnbngWelcome from "@/pages/onboarding/rightSideSteps/OnbngWelcome";
import SelectDocument from "@/pages/onboarding/rightSideSteps/SelectDocument";
import UploadDocument from "@/pages/onboarding/rightSideSteps/UploadDocument";
const addInfo = () => import('@/pages/onboarding/rightSideSteps/AddInfo');

// MIDDLEWARE
import hasNotUserSession from "@/middleware/hasNotUserSession";
import hasUserSession from "@/middleware/hasUserSession";
import isNotWalletUser from "@/middleware/isNotWalletUser";
import isWalletUser from "@/middleware/isWalletUser";

export default [
    {
        path: '',
        name: "newAccount",
        component: newAccount,
        meta: {
            middleware: [hasNotUserSession]
        },
    },
    {
        path: 'verify-email/:email',
        name: "verifyEmail",
        component: VerifyEmail,
        meta: {
            middleware: hasNotUserSession
        },
    },
    {
        path: 'verification/:token',
        name: "verificationEmail",
        component: VerificationEmail,
        meta: {
            middleware: hasNotUserSession
        },
    },
    {
        path: 'select-account',
        name: "selectAccount",
        component: SelectAccountCard,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'use-policy',
        name: "usagePolicies",
        component: UsagePolicies,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'nationality',
        name: "nationality",
        component: Nationality,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'personal-info',
        name: "personalInfo",
        component: PersonalData,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'residence',
        name: "residence",
        component: Residence,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'occupation',
        name: "occupation",
        component: Occupation,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'phone',
        name: "phoneVerification",
        component: PhoneVerification,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'phone-code',
        name: "codeVerification",
        component: CodeVerification,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'success-code',
        name: "successCode",
        component: SuccessPhone,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'security-measures',
        name: "securityMeasures",
        component: SecurityMeasures,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'new-pin',
        name: "newPin",
        component: NewNip,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'confirm-pin',
        name: "confirmPin",
        component: ConfirmPin,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'processing-request',
        name: "processingRequest",
        component: ProcessingRequest,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'response-request',
        name: "successRequest",
        component: SuccessRequest,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'beneficiaries',
        name: "beneficiariesRegister",
        component: Beneficiaries,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'contract',
        name: "contract",
        component: Contract,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'sign',
        name: "sign",
        component: Sign,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'promotional-code',
        name: "promotionalCode",
        component: PromotionalCode,
        meta: {
            middleware: [hasUserSession, isWalletUser]
        },
    },
    {
        path: 'welcome',
        name: "onbngWelcome",
        component: OnbngWelcome,
        meta: {
            middleware: [hasUserSession, isWalletUser]
        },
    },

    // LEVEL TWO
    {
        path: 'select-documents',
        name: "selectDocuments",
        component: SelectDocument,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'upload-documents/:id',
        name: "uploadDocuments",
        component: UploadDocument,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    // LEVEL THREE
    {
        path: 'add-info',
        name: "addInfo",
        component: addInfo,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
];
