<template>
    <div class="change-beneficiary">
        <div class="user-info">
            <UserAccount
                :clazz="clazzColor"
                :noAccount="noAccount"
                :userImg="userImg"
                :userName="userNameInitials">
            </UserAccount>
            <div>
                <div class="name">
                    {{beneficiaryName}}
                </div>
                <div class="full-name">
                    {{beneficiaryFullName}}
                </div>
                <div class="number">
                    {{beneficiaryNumber}}
                </div>
            </div>
        </div>
        <button id="" type="button" class="button xs button-stroke button-primary" @click="$emit('click')">
            {{$t('transfers.new.changeBeneficiary')}}
        </button>
    </div>
</template>

<script>
    import imgUserAccount from '@/assets/images/Default/profile-photo.svg';
    import UserAccount from "@/components/UserAccount.vue";

    export default {
        name: 'TransferInfo',
        components: {
            UserAccount,
        },
        props: {
            beneficiaryName: String,
            beneficiaryFullName: String,
            beneficiaryNumber: String,
            clazzColor: String,
            userNameInitials: String,
            noAccount: {type: Boolean, default: true},
            userImg: String,
        },
        data() {
            return {
                imgUserAccount: imgUserAccount,
            }
        }
    }
</script>
