<template>
    <div class="operational-limits-container" :class="clazz">
        <label class="title-bar">
            {{titleBar}}
        </label>
        <b-progress
            :value="valueProgressBar"
            :max="maxProgressBar"
            class="bar-progress percent-color"
            :class="{'warning' : valueProgressBar >= warningProgressBar, 'error' : valueProgressBar >= dangerProgressBar }"
        ></b-progress>
        <p class="text-bar">
            {{textBar}} <strong> {{textBarStrong}} </strong>
        </p>
        <p class="text-bar text-right">
            {{textBarRight}}
        </p>
    </div>
</template>

<script>
    export default {
        name: 'OperationalLimitsBar',
        props: {
            clazz: String,
            titleBar: String,
            textBar: String,
            textBarStrong: String,
            textBarRight: String,
            valueProgressBar: Number,
            maxProgressBar: Number,
            warningProgressBar: Number,
            dangerProgressBar: Number
        },
        data () {
            return {}
        },
        methods: {},
    }
</script>
