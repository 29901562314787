<template>
  <div>
    <TitlePrincipal
        title="Información acerca de tu negocio"
        subtitle="Ahora necesitamos que nos proporciones algunos datos sobre tu negocio.">
    </TitlePrincipal>
    <div class="row">
        <div class="col-12">
            <h2 class="f-18 c-primary_1 poppins-font-semibold m-b-15">Fecha de constitución</h2>
        </div>
        <div class="col-12 col-md-4">
            <BasicInput
                required
                v-model="day"
                inputType="text"
                inputId="user_day"
                label="Día"
                inputPlaceholder="DD"/>
        </div>
        <div class="col-12 col-md-4">
            <BasicInput
                    required
                    v-model="month"
                    inputType="text"
                    inputId="user_month"
                    label="Mes"
                    inputPlaceholder="MM"/>
        </div>
        <div class="col-12 col-md-4">
            <BasicInput
                required
                v-model="year"
                inputType="text"
                inputId="user_year"
                label="Año"
                inputPlaceholder="AAAA"/>
        </div>
        <div class="col-12">
            <BasicSelect
                required
                label="Giro del negocio">
                <multiselect
                    v-model="giro"
                    :options="giroOptions"
                    :close-on-select="true"
                    :preserve-search="true"
                    placeholder="Selecciona el giro del negocio"
                    :custom-label="customLabel"
                    track-by="_id"
                    :preselect-first="false"
                >
                    <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                    >
                        <span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                        >{{ values.length }} options selected</span>
                    </template>
                </multiselect>
            </BasicSelect>
        </div>
        <div class="col-12">
            <TelInput
                required
                v-model="phone"
                idInput="user_phone"
                label="Número de teléfono del negocio"
                inputPlaceholder="Escribe los 10 dígitos del teléfono del negocio"
                @validate="onValidatePhone"
            />
        </div>
    </div>
    <div class="footer-buttons right">
        <button type="button" class="button button-ghost button-plain_text" name="Regresar" @click="back"> Regresar </button>
        <button
            v-if="showNext"
            type="button"
            class="button button-filled button-primary"
            name="Continuar"
            :disabled='!isValidData'
            @click="submit"
        > Continuar </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text" />
      </button>
    </div>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import BasicSelect from "@/components/BasicSelect.vue";
import BasicInput from "@/components/BasicInput.vue";
import Multiselect from "vue-multiselect";
import TelInput from "@/components/TelInput.vue";
import LoadingSimple from "@/components/LoadingSimple"
import { mapGetters } from 'vuex';
import {
  STORE as OnboardingStore,
  GET_CARD_REQUEST,
  GO_TO_ROUTE
} from '@/store/modules/onboardingSteps/onboardingSteps.types';
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO
} from '@/store/modules/organizationInfo/organizationInfo.types';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {
  required,
  numeric,
  minLength,
  maxLength,
  between
} from "vuelidate/lib/validators";
import GiroFamilyApi from '@/api/core/giroFamily.api';
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";

export default {
  name: "BusinessInfo",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicSelect,
    BasicInput,
    Multiselect,
    TelInput,
    LoadingSimple
  },
  computed: {
    ...mapGetters(OnboardingStore, {
      cardRequest: GET_CARD_REQUEST
    }),
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO
    }),
    isValidData() {
      return !this.$v.$invalid && this.phoneValid;
    },
    phoneValid() {
      return (this.phoneData && this.phoneData.valid === true);
    }
  },
  data() {
    return {
      day: null,
      month: null,
      year: null,
      giro: null,
      phone: null,
      phoneData: {},
      showNext: true,
      giroOptions: [],
    }
  },
  validations: {
    day: {
      required,
      numeric,
      minLength: minLength(1),
      maxLength: maxLength(2),
    },
    month: {
      required,
      numeric,
      minLength: minLength(1),
      maxLength: maxLength(2),
      between: between(1, 12),
    },
    year: {
      required,
      numeric,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    giro: {
      required,
    },
    phone: {
      required,
      minLength: minLength(10),
    },
  },
  methods: {
    back () {
      this.$store.dispatch(`${OnboardingStore}/backBusiness`)
    },
    customLabel({familyName}) {
      return this.$i18n.t(`register.businessData.enum.${familyName}`);
    },
    async getGiroFamilies() {
      try {
        const response = await GiroFamilyApi.all();
        this.giroOptions = response.data.object;

        if(this.organization?.giro) {
          this.giro = this.giroOptions.find(giro => this.organization.giro === giro._id);
        }
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    async submit() {
      this.showNext = false;
      const constitutionDate = new Date(
        parseInt(this.year, 10),
        parseInt(this.month, 10) - 1, // Indexed from 0
        parseInt(this.day, 10)
      );
      const params = {
        id: this.organization._id,
        constitutionDate,
        giro: this.giro._id,
        phone: this.phone
      };
      const success = await this.$store.dispatch(`${OrganizationInfoStore}/saveBusinessInfo`, params);
      if(success) {
          // this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
          //   stepOnbng: ONBOARDING_STEPS.BUSINESS_FISCAL_DATA,
          // });
          this.nextStep();
      } else {
        this.displayNotificationError('No se pudo guardar la informacion del negocio')
      }
      this.showNext = true;
    },
    nextStep() {

      this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
        stepOnbng: ONBOARDING_STEPS.BUSINESS_FISCAL_DATA,
      });

      if(this.$route.name === 'businessDataMultiple') {
        this.$store.dispatch(`${OnboardingStore}/${GO_TO_ROUTE}`, "businessFiscalDataMultiple");
      } else {
        this.$store.dispatch(`${OnboardingStore}/${GO_TO_ROUTE}`, "businessFiscalData");
      }
    },
    onValidatePhone(phoneData) {
      this.phoneData = phoneData;
    }
  },
  async mounted() {
    if(this.$route.name === 'businessDataMultiple') {
      this.$store.commit(`${OnboardingStore}/SET_CREATE_NEW_ORG`, true);
      await this.$store.dispatch(`${OnboardingStore}/putLevel`, 'MULTIPLE_BUSINESS_MORAL');
    }
    await this.$store.dispatch(`${OnboardingStore}/checkCurrentStep`, this.$route);

    await this.getGiroFamilies();
    if(this.organization) {
      if(this.organization.phone) {
        this.phone = this.organization.phone;
        this.validPhone = true;
      }
      if(this.organization.invoiceData && this.organization.invoiceData.constitutionDate) {
        const date = new Date(this.organization.invoiceData.constitutionDate);
        this.day = date.getDate();
        this.month = date.getMonth()+1;
        this.year = date.getFullYear();
      }
    }
  },
}
</script>

<style scoped>

</style>
