<template>
  <div>
    <TitlePrincipal
      title="Datos Fiscales"
      subtitle="Ingresa los siguientes datos fiscales de tu negocio."
    >
    </TitlePrincipal>
    <BasicInput
      required
      v-model="legalName"
      inputType="text"
      inputId="company_razonSocial"
      label="Razón Social"
      inputPlaceholder="Escribe la razón social"
    />
    <BasicInput
      required
      v-model="rfc"
      inputType="text"
      inputId="company_rfc"
      label="RFC"
      inputPlaceholder="Escribe el RFC"
    />
    <BasicInput
      required
      v-model="electronicSignatureSerialNumber"
      inputType="text"
      inputId="company_noSerie"
      label="No. de serie de firma electrónica avanzada"
      inputPlaceholder="Escribe el no. de serie de firma electrónica avanzada"
    />
    <div class="footer-buttons right">
      <button
          @click="back"
        type="button"
        class="button button-ghost button-plain_text"
        name="Regresar"
      >
        Regresar
      </button>
      <button
        v-if="showNext"
        type="button"
        class="button button-filled button-primary"
        name="Continuar"
        :disabled='this.$v.$invalid'
        @click="submit"
      >
        Continuar
      </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text" />
      </button>
    </div>
  </div>
</template>
<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import BasicInput from "@/components/BasicInput.vue";
import LoadingSimple from "@/components/LoadingSimple";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {regexRFCMoral} from "@/utils/regexUtils";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import { mapGetters } from 'vuex';
import {
  STORE as OnboardingStore,
  GO_TO_ROUTE, STORE as onbngStore
} from '@/store/modules/onboardingSteps/onboardingSteps.types';
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO
} from '@/store/modules/organizationInfo/organizationInfo.types';
import {
  required,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

const validRfc = (val) => regexRFCMoral.test(val);

export default {
  name: "FiscalData",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicInput,
    LoadingSimple
  },
  data() {
    return {
      legalName: null,
      rfc: null,
      electronicSignatureSerialNumber: null,
      showNext: true
    }
  },
  computed: {
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO
    }),
  },
  validations: {
    legalName: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(100),
    },
    rfc: {
      required,
      validRfc
    },
    electronicSignatureSerialNumber: {
      required
    },
  },
  methods: {
    back () {
        this.$store.dispatch(`${OnboardingStore}/backBusiness`);
    },
    assignData() {
      this.legalName = this.organization.legalName;
      this.rfc = this.organization.RFC;
      this.electronicSignatureSerialNumber = this.organization.invoiceData.electronicSignatureSerialNumber;
    },
    async submit() {
      this.showNext = false;
      const params = {
        id: this.organization.id,
        legalName: this.legalName,
        rfc: this.rfc,
        electronicSignatureSerialNumber: this.electronicSignatureSerialNumber
      };
      const success = await this.$store.dispatch(`${OrganizationInfoStore}/saveFiscalData`, params);
      if(success) {
          this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.BUSINESS_FISCAL_ADDRESS,
          });

          if(this.$route.name === 'businessFiscalDataMultiple') {
            this.$store.dispatch(`${OnboardingStore}/${GO_TO_ROUTE}`, "businessFiscalAddressMultiple");
          } else {
            this.$store.dispatch(`${OnboardingStore}/${GO_TO_ROUTE}`, "businessFiscalAddress");
          }
      } else {
        this.displayNotificationError('No se pudo guardar la informacion del negocio')
      }
      this.showNext = true;
    }
  },
  async beforeMount() {
    if(this.$route.name === 'businessFiscalDataMultiple') {
      this.$store.commit(`${OnboardingStore}/SET_CREATE_NEW_ORG`, true);
      await this.$store.dispatch(`${OnboardingStore}/putLevel`, 'MULTIPLE_BUSINESS_MORAL');
    }

    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
    if (this.organization) {
        this.assignData();
    }
  }
};
</script>
