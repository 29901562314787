<template>
  <div class="row">
    <div class="col-12">
      <router-link
        :to="{name: 'CorporativeAccountList'}"
        class="button xs button-ghost button-text_info p-l-0 p-r-0"
        tabindex=""
      >
        <i
          aria-hidden="true"
          class="iconsax-arrow-arrow-left f-18 c-info m-r-10"
        ></i>
        Regresar a Cuentas Corporativas
      </router-link>
      <FloatingTitleLayout :titleStrong="$t('corporativeAccounts.edit.header')"/>
    </div>
      <CardSimple class="horizontal-center" v-if="loading">
        <LoadingBar clazz="p-t-50 p-b-50" loadingText="Buscando..."/>
      </CardSimple>
      <CorporativeAccountForm
        v-else
        editMode
        :value="details"
        :showLoadingButton="processing"
        @submit="submit"
        @cancel="back"
      ></CorporativeAccountForm>
  </div>
</template>
<script>
// @ is an alias to /src
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import CardSimple from "@/components/CardSimple.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import CorporativeAccountForm from "@/pages/CorporativeAccounts/CorporativeAccountForm";

import CorporativeAccountsApi from "@/api/wallet/corporativeAccounts.api";

export default {
  name: "EditCorporativeAccount",
  components: {
    FloatingTitleLayout,
    CardSimple,
    CorporativeAccountForm,
    LoadingBar,
  },
  props: {
    data: Object,
    comesFromDetails: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      processing: false,
      loading: false,
      details: null,
    };
  },
  created() {
    if(this.data) {
      this.details = this.data;
    } else {
      this.loadDetails();
    }
  },
  methods: {
    back() {
      if(this.comesFromDetails) {
        const id = this.$route.params.id;
        this.$router.push({name: 'CorporativeAccountDetail', params: {id}});
      } else {
        this.$router.push({name: 'CorporativeAccountList'});
      }
    },
    async loadDetails() {
      try {
        this.loading = true;
        const id = this.$route.params.id;
        const response = await CorporativeAccountsApi.getDetails(id);
        this.details = response.data.object;
        this.loading = false;
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    async submit(data) {
      try {
        if(!this.processing && await this.$requestAuth()) {
          this.processing = true;
          await CorporativeAccountsApi.edit(data);
          this.back();
        }
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.processing = false;
    },
  },
};
</script>
