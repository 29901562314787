import {
    CLEAR_STORE,
    STORE as sessionStore
  } from "@/store/modules/session/session.types";
import store from '@/store/index'
import {Buffer} from 'buffer'

function _clearSession() {
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentToken');
    localStorage.removeItem('currentPermissions');
    localStorage.removeItem('profile');
    localStorage.removeItem('expireDate');
    localStorage.removeItem('s1');
    store.commit(`${sessionStore}/${CLEAR_STORE}`);
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(Buffer.from(base64, "base64").toString("ascii").split("").map(function (c) {
        // return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        return `%${(getChartCode(c)).slice(-2)}`;
    }).join(''));

    return JSON.parse(jsonPayload);
}

function getChartCode(c) {
    return `00${c.charCodeAt(0).toString(16)}`;
}


export default function hasUserSession({ next }) {
   const token = localStorage.getItem('currentToken');
    if (token) {
        const jwtPayload = parseJwt(token);

        if (jwtPayload.exp < Date.now() / 1000) {
            // token expired
            _clearSession();
            return next("/login");
        }

        return next();
    }

    return next('/login');
}
