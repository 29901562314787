<template>
  <div>
    <CardSimple
      :title="editMode ? $t('corporativeAccounts.edit.title') : $t('corporativeAccounts.new.title')"
      :subtitle="editMode ? $t('corporativeAccounts.edit.subtitle') : $t('corporativeAccounts.new.subtitle')"
    >
      <div class="row">
        <div class="col-12 col-md-6">
          <!-- Alias -->
          <BasicInput
            required
            inputId="alias"
            v-model="alias"
            :label="$t('corporativeAccounts.form.alias.label')"
            :inputPlaceholder="$t('corporativeAccounts.form.alias.placeholder')"
            :warningText="$t('corporativeAccounts.form.alias.warningText')"
            :maxCount="20"
            inputClazz="p-r-50"
            :hasError="alias && $v.alias.$invalid"
          >
          </BasicInput>
        </div>
        <!-- MonthlyAmount -->
        <div class="col-12 col-md-6">
          <BasicInput
            required
            money
            inputId="monthlyAmount"
            v-model="monthlyAmount.limit"
            :inputValue="monthlyAmount.limit.toString()"
            :label="$t('corporativeAccounts.form.monthlyAmount.label')"
            :inputPlaceholder="$t('corporativeAccounts.form.monthlyAmount.placeholder')"
            :hasError="hasError(monthlyAmount)"
            :warningText="getLimitError(monthlyAmount)"
          >
          </BasicInput>
        </div>
        <!-- Account Type -->
        <div class="col-12 col-md-6">
          <BasicSelect required :label="$t('corporativeAccounts.form.accountType.label')">
            <multiselect
              v-model="accountType"
              :options="accountTypes"
              :close-on-select="true"
              :preserve-search="true"
              :placeholder="$t('corporativeAccounts.form.accountType.placeholder')"
              label="description"
              track-by="_id"
              :disabled="editMode"
            >
              <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
              >
                <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} options selected</span
                >
              </template>
            </multiselect>
          </BasicSelect>
        </div>
        <div class="col-12 col-md-6 d-flex align-items-c justify-space-between column-gap-10px m-b-30 p-t-15">
          <AccountType clazz="p-relative imgLeft m-0-auto-0-0" v-if="accountType">
            <img
              class="img-fluid"
              :src="accountImage"
              width="80"
              height="50"
              :alt="accountType.description"
            />
            <template v-slot:extraText>
              <label class="f-12 c-info poppins-font-semibold m-b-0">
                {{alias && alias.length >= 3 ? alias : $t('corporativeAccounts.form.noAlias')}}
              </label>
              <p class="f-12 c-text_title poppins-font-regular m-b-0">{{accountType.description}}</p>
            </template>
          </AccountType>
          <AccountType clazz="p-relative imgLeft m-0-auto-0-0" v-else>
            <img
              class="img-fluid"
              src="@/assets/images/Cards/empty-card.svg"
              width="80"
              height="50"
              :alt="$t('corporativeAccounts.form.noAccountType')"
            />
            <template v-slot:extraText>
              <label class="f-12 c-info poppins-font-semibold m-b-0">
                {{alias && alias.length >= 3 ? alias : $t('corporativeAccounts.form.noAlias')}}
              </label>
              <p class="f-12 c-text_title poppins-font-regular m-b-0">{{$t('corporativeAccounts.form.noAccountType')}}</p>
            </template>
          </AccountType>
        </div>
        <div class="col-12">
          <div class="title-card m-t-15">
            <label>
              {{editMode ? $t('corporativeAccounts.edit.title2') : $t('corporativeAccounts.new.title2')}}
              <p>
                {{editMode ? $t('corporativeAccounts.edit.subtitle2') : $t('corporativeAccounts.new.subtitle2')}}
              </p>
            </label>
          </div>
        </div>
        <!-- Email -->
        <div class="col-12 col-md-6">
          <BasicInput
            required
            v-model="email"
            class="search-type"
            inputType="text"
            :label="$t('corporativeAccounts.form.email.label')"
            :inputPlaceholder="$t('corporativeAccounts.form.email.placeholder')"
            @onKeyUpEnter="findUserByEmail"
            @basicInput="userFound = null"
            :disabled="editMode"
          >
            <button
              v-if="!editMode"
              :disabled="$v.email.$invalid"
              type="button"
              class="button xs button-filled button-primary"
              @click="findUserByEmail"
            >
              {{$t('corporativeAccounts.form.email.button')}}
            </button>
          </BasicInput>
        </div>
        <div class="col-12 col-md-6 p-t-10">
          <LoadingBar v-if="loadingUserByEmail" loadingText="Buscando colaborador..."/>
          <ReadOnlyInfo
            v-else-if="userFound === true"
            :title="$t('corporativeAccounts.form.user.label')"
            :text="user.name"
          ></ReadOnlyInfo>
          <ReadOnlyInfo
            v-else-if="userFound === false"
            :title="$t('corporativeAccounts.form.user.labelNoAccount')"
            :text="$t('corporativeAccounts.form.user.textNoAccount')"
          ></ReadOnlyInfo>
          <ReadOnlyInfo
            v-else
            :title="$t('corporativeAccounts.form.user.label')"
            :text="$t('corporativeAccounts.form.user.placeholder')"
          ></ReadOnlyInfo>
        </div>
        <div class="col-12">
          <div class="title-card m-t-15">
            <label>
              {{$t('corporativeAccounts.form.limitsTitle')}}
              <p>{{$t('corporativeAccounts.form.limitsText')}}</p>
            </label>
          </div>
        </div>
        <div class="col-12">
          <div id="menuOverflowFix" class="basic-table inside-card">
            <b-table-simple
              responsive
              selectable
              selected-variant="active"
              table-variant="xl"
            >
              <b-thead>
                <b-tr class="">
                  <b-th></b-th>
                  <b-th>{{$t('corporativeAccounts.limits.headers.type')}}</b-th>
                  <b-th>{{$t('corporativeAccounts.limits.headers.amount')}}</b-th>
                  <b-th>{{$t('corporativeAccounts.limits.headers.periodicity')}}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr class="noHover" v-for="limit in limitList" :key="limit.type">
                  <b-td class="">
                    <BasicSwitch
                      v-model="limit.enabled"
                      :idInput="`switch_${limit.type}`"
                      :ref="`switch_${limit.type}`"
                    />
                  </b-td>
                  <b-td class="w-100">{{$t(`corporativeAccounts.limits.type.${limit.type}`)}}</b-td>
                  <b-td class="">
                    <BasicInput
                      money
                      clazz="m-0 w-200px"
                      v-model="limit.limit"
                      :inputValue="limit.limit.toString()"
                      :disabled="!limit.enabled"
                      :hasError="hasError(limit)"
                      :warningText="getLimitError(limit)"
                    >
                    </BasicInput>
                  </b-td>
                  <b-td class="">
                    <BasicSelect clazz="m-0 w-200px">
                      <multiselect
                        @open="toggleDropdown"
                        @close="toggleDropdown"
                        v-model="limit.periodicity"
                        :options="periodicityOptions"
                        :custom-label="val => $tc(`corporativeAccounts.limits.periodicity.${val}`)"
                        :close-on-select="true"
                        :preserve-search="true"
                        :placeholder="$t('corporativeAccounts.form.limit.placeholder')"
                        :disabled="!limit.enabled"
                      >
                        <template
                          slot="selection"
                          slot-scope="{ values, search, isOpen }"
                        >
                          <span
                            class="multiselect__single"
                            v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} options selected</span
                          >
                        </template>
                      </multiselect>
                    </BasicSelect>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
      </div>
    </CardSimple>
    <div class="control-buttons">
      <button type="button" class="button-icon" @click="$emit('cancel')">
        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
        {{$t('corporativeAccounts.form.back')}}
      </button>
      <div class="vertical-center">
        <button type="button" class="button button-stroke button-primary" @click="$emit('cancel')">
          {{$t('corporativeAccounts.form.cancel')}}
        </button>
        <button
          v-if="!showLoadingButton"
          type="button"
          class="button button-filled button-primary"
          :disabled="isFormInvalid"
          @click="editMode ? submitEdit() : $bvModal.show('modal-new-account')"
        >
          {{editMode ? $t('corporativeAccounts.edit.submit') : $t('corporativeAccounts.new.submit')}}
        </button>
        <button
          v-else
          class="button button-filled button-primary"
        >
          <LoadingSimple clazz="small-load no-text"/>
        </button>
      </div>
    </div>
    <ModalAlert
      closeCross
      modalId="modal-new-account"
      buttonType="button-primary"
      :imgUrl="imageModal"
      :title="$t('corporativeAccounts.new.modal.title')"
      :buttonText="$t('corporativeAccounts.new.modal.confirm')"
      @submit="submitSave"
    >
      <p>
        {{$t('corporativeAccounts.new.modal.text1')}}
        <strong class="c-info poppins-font-bold" v-if="accountType">
          {{accountType.description}} {{$t('corporativeAccounts.new.modal.text2')}}
          {{(accountType.cost ? accountType.cost.amount : 0) | currency}}
          {{$t(`corporativeAccounts.modalNewAccount.periodicity.${accountType.cost ? accountType.cost.periodicity : 'MONTHLY'}`)}}
        </strong>
        {{$t('corporativeAccounts.new.modal.text3')}}
      </p>
    </ModalAlert>
  </div>
</template>
<script>
// @ is an alias to /src
import BasicInput from "@/components/BasicInput.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import CardSimple from "@/components/CardSimple.vue";
import BasicSelect from "@/components/BasicSelect.vue";
import AccountType from "@/components/AccountType.vue";
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
import BasicSwitch from "@/components/BasicSwitch.vue";
import LoadingSimple from "@/components/LoadingSimple";
import ModalAlert from "@/components/ModalAlert.vue";

import Multiselect from "vue-multiselect";
import { required, minLength, maxLength, decimal } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";

import {CARD_DEFAULT_IMG} from "@/enums/defalutCardImg.enum";

import imageModal from "@/assets/images/Icons/icon-card.svg";

import { imageDownloadLink } from '@/utils/utils';
import { regexEmail } from "@/utils/regexUtils";

import CorporativeAccountsApi from "@/api/wallet/corporativeAccounts.api";

import { STORE as sessionStore, GET_ORG_INFO } from "@/store/modules/session/session.types";

export default {
  name: "CorporativeAccountForm",
  components: {
    BasicInput,
    LoadingBar,
    CardSimple,
    BasicSelect,
    Multiselect,
    AccountType,
    ReadOnlyInfo,
    BasicSwitch,
    LoadingSimple,
    ModalAlert,
  },
  props: {
    value: Object,
    editMode: {
      type: Boolean,
      default: false,
    },
    showLoadingButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imageModal,
      alias: null,
      accountType: null,
      accountTypes: [],
      email: null,
      loadingUserByEmail: false,
      userFound: null,// True si es usuario existente, false si es nuevo usuario, null si no se ha buscado el correo
      user: null,
      limits: [
        { limit: 0, periodicity: 'MONTHLY', type: 'ACCUMULATED_BALANCE' },
        { enabled: false, limit: 0, periodicity: null, type: 'CARD_PURCHASES' },
        { enabled: false, limit: 0, periodicity: null, type: 'SPEI_IN' },
        { enabled: false, limit: 0, periodicity: null, type: 'SPEI_OUT' },
        { enabled: false, limit: 0, periodicity: null, type: 'TRANSFER_IN_PAGANDO' },
        { enabled: false, limit: 0, periodicity: null, type: 'TRANSFER_OUT_PAGANDO' },
        { enabled: false, limit: 0, periodicity: null, type: 'ATM_CASH_WITHDRAWAL' },
        { enabled: false, limit: 0, periodicity: null, type: 'CASH_WITHDRAWAL_STORES' },
      ],
      periodicityOptions: [
        'DAILY',
        'MONTHLY',
        'QUARTERLY',
        'BIANNUAL',
        'ANNUAL',
      ],
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
        orgInfo: GET_ORG_INFO,
    }),
    accountImage() {
      if(!this.accountType) {
        return null;
      }

      const {image, product} = this.accountType.template;

      if(image) {
        return imageDownloadLink(image);
      }

      const images = {
        EXPLORE: CARD_DEFAULT_IMG.pagandoExplore,
        EXPLORE_PLUS: CARD_DEFAULT_IMG.pagandoExplorePlus,
        NIGHT: CARD_DEFAULT_IMG.pagandoNight,
        BUSINESS: CARD_DEFAULT_IMG.pagandoBusiness,
        BUSINESS_PRO: CARD_DEFAULT_IMG.pagandoBusinessPro,
      };

      return images[product];
    },
    monthlyAmount() {
      return this.limits.find(({type}) => type === 'ACCUMULATED_BALANCE');
    },
    limitList() {
      return this.limits.filter(({type}) => type !== 'ACCUMULATED_BALANCE');
    },
    config() {
      return this.limits.filter(l => l.enabled || l.type === 'ACCUMULATED_BALANCE').map(limit => ({
        type: limit.type,
        limit: limit.limit,
        periodicity: limit.periodicity,
      }));
    },
    isFormInvalid() {
      return this.$v.$invalid || this.limitList.some(this.hasError);
    }
  },
  created() {
    this.loadAccountTypes();
  },
  mounted() {
    if(this.value) {
      this.fillForm();
    }
  },
  methods: {
    toggleDropdown() {
      document.getElementById("menuOverflowFix").classList.toggle("dropdown-table-overflow");
    },
    async loadAccountTypes() {
      try {
        const response = await CorporativeAccountsApi.getAccountTypes();
        this.accountTypes = response.data.object;
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    async findUserByEmail() {
      try {
        if(this.$v.email.$invalid) {
          return;
        }
        this.loadingUserByEmail = true;
        const response = await CorporativeAccountsApi.findUserByEmail({email: this.email});
        this.user = response.data.object;
        this.userFound = !!this.user;
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.loadingUserByEmail = false;
    },
    async submitSave() {
      this.$bvModal.hide('modal-new-account');
      if(this.$v.$invalid) {
        return;
      }
      const params = {
        email: this.email,
        accountTypeId: this.accountType._id,
        accountUserId: this.user?._id,
        newUser: this.userFound === false,
        latitud: this.location.lat.toString(),
        longitud: this.location.lon.toString(),
        orgInfId: this.orgInfo._id,
        alias: this.alias,
        config: this.config,
      };
      this.$emit('submit', params);
    },
    async submitEdit() {
      if(this.$v.$invalid) {
        return;
      }
      const params = {
        id: this.value._id,
        alias: this.alias,
        config: this.config,
      };
      this.$emit('submit', params);
    },
    fillForm() {
      this.alias = this.value.alias;
      this.accountType = this.value.accountType;
      this.email = this.value.userEmail;
      this.limits.forEach(lim => {
        const dataLimit = this.value.limits.find(({type}) => type === lim.type);
        if(dataLimit) {
          Object.assign(lim, dataLimit);
          lim.enabled = true;
          if(lim.type !== 'ACCUMULATED_BALANCE') {
            this.$refs[`switch_${lim.type}`][0].forceValue(true);
          }
        }
      });

      this.findUserByEmail();
    },
    hasError(limit) {
      return limit.enabled !== false && ((!!limit.limit && limit.limit < 1) || !!this.getLimitError(limit));
    },
    getLimitError({type, enabled, periodicity, limit}) {
      if(enabled === false) {
        return null;
      }
      if(!!limit && limit < 1) {
        return this.$t('corporativeAccounts.limits.errorMin');
      }
      if(!periodicity || ['ACCUMULATED_BALANCE', 'SPEI_IN', 'TRANSFER_IN_PAGANDO'].includes(type)) {
        return null;
      }

      const multipliers = {
        DAILY: 1,
        MONTHLY: 1,
        QUARTERLY: 3,
        BIANNUAL: 6,
        ANNUAL: 12,
      };
      const monthlyAmount = this.monthlyAmount?.limit || 0;
      const max = monthlyAmount * multipliers[periodicity];
      if(limit <= max) {
        return null;
      }
      return this.$t('corporativeAccounts.limits.errorMax', {
        amount: this.$options.filters.currency(max),
        periodicity: this.$tc(`corporativeAccounts.limits.periodicity.${periodicity}`, max).toLowerCase(),
      });
    },
  },
  validations: {
    alias: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(20),
    },
    accountType: {required},
    userFound: {required},
    email: {
      required,
      validEmail: (val) => regexEmail.test(val)
    },
    config: {
      required,
      $each: {
        limit: {
          required,
          decimal,
          greaterThanZero: (val) => val > 0,
        },
        periodicity: {
          required,
        },
      }
    },
  },
};
</script>
