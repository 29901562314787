<template>
    <div class="swiper-panel-link" :class="clazz">
        <span>
            <img class="img-fluid" src="@/assets/images/Illustrations/illustration-notificacion.svg" width="38" height="50" alt="" />
        </span>
        <span class="info">
            <label>{{panelTitle}}</label>
            <p>
                {{panelText}}
            </p>
        </span>
        <span>
            <router-link
                :to="{ name: 'Announcement', params: { id: panelId } }"
                class="button-circle button-ghost button-primary"
                aria-label="Ver anuncio">
                <i aria-hidden="true" class="iconsax-arrow-circle-right f-30"></i>
            </router-link>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'PanelSwiperLink',
        props: {
            clazz: String,
            panelId: String,
            panelTitle: String,
            panelText: String,
        },
    }
</script>
