<template>
  <div class="collapse-beneficiary" :id="`draft-collaborator-${key}`">
    <div class="head">
      <span>
        <BeneficiaryInfo
          :text="value.email || $t('collaborators.fields.email.label')"
          :textBold="value.name || $t('collaborators.fields.name.label')"
        />
      </span>
      <span>
        <span class="badge badge-text_content">
          <i aria-hidden="true" class="badge-icon iconbullet"></i>
          {{$t('collaborators.badge.DRAFT')}}
        </span>
      </span>
      <span>
        <button
          id=""
          class="button-square xs button-stroke button-accent"
          type="button"
          :name="$t('collaborators.actions.collapse')"
          :aria-label="$t('collaborators.actions.collapse')"
          v-b-toggle="`collapse-collaborator-${key}`"
        >
          <i aria-hidden="true" class="iconsax-arrow-direction-down"></i>
          <i aria-hidden="true" class="iconsax-arrow-direction-up"></i>
        </button>
      </span>
    </div>
    <b-collapse :id="`collapse-collaborator-${key}`" class="body" visible>
      <div class="row">
        <div class="col-12 col-md-6">
          <BasicInput
            required
            inputId=""
            v-model="value.name"
            :label="$t('collaborators.fields.name.label')"
            :inputPlaceholder="$t('collaborators.fields.name.placeholder')"
          >
          </BasicInput>
        </div>
        <div class="col-12 col-md-6">
          <BasicInput
            required
            inputId=""
            v-model="value.email"
            :label="$t('collaborators.fields.email.label')"
            :inputPlaceholder="$t('collaborators.fields.email.placeholder')"
          >
          </BasicInput>
        </div>
        <div class="col-12">
          <div id="" class="basic-table">
            <b-table-simple responsive table-variant="xl">
              <b-thead>
                <b-tr class="">
                  <b-th class="w-60"> {{$t('collaborators.headers.permissions')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.read')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.request')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.authorize')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(type, index) in permissionTypes" :key="index">
                  <b-td class="w-60">
                    {{ $t(`collaborators.permissionTypes.${type}`) }}
                  </b-td>
                  <b-td
                    v-for="(action, index) in permissionActions"
                    :key="index"
                  >
                    <BasicCheckbox
                      clazz="w-fit m-0-auto"
                      inputClass="m-0"
                      :value="checkPermission(value, action, type)"
                      @input="checked => onPermissionInput(checked, action, type)"
                      :index="index"
                    ></BasicCheckbox>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
        <div class="control-buttons buttons-full-w buttons-full-w-xs">
          <button
            type="button"
            class="button button-stroke button-primary w-100"
            @click="cancel"
          >
            {{ $t(`collaborators.actions.cancel`) }}
          </button>
          <button
            type="button"
            class="button button-filled button-primary w-100"
            :disabled="this.$v.$invalid"
            @click="save"
            v-if="!loading"
          >
            {{ $t(`collaborators.actions.sendInvite`) }}
          </button>
          <button
            v-else
            type="button"
            class="button button-filled button-primary w-100"
          >
            <LoadingSimple clazz="small-load no-text" />
          </button>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
// @ is an alias to /src
import BeneficiaryInfo from "@/components/BeneficiaryInfo.vue";
import BasicCheckbox from "@/components/BasicCheckbox.vue";
import BasicInput from "@/components/BasicInput.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";

import ToastSnotify from "@/mixins/toastSnotify.mixin";
import CollaboratorsMixin from "@/mixins/collaboratorPermissions.mixin";

import { required } from "vuelidate/lib/validators";
import { regexEmail } from "@/utils/regexUtils";

import CollaboratorsApi from "@/api/wallet/collaborators.api";



export default {
  name: "CollaboratorDraft",
  mixins: [ToastSnotify, CollaboratorsMixin],
  components: {
    BeneficiaryInfo,
    BasicCheckbox,
    BasicInput,
    LoadingSimple,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    key() {
      return this.$vnode?.key?.toString() || "";
    },
    permissions() {
      return this.value.permissions;
    },
  },
  mounted () {
    this.scrollToView();
  },
  methods: {
    onPermissionInput(add, action, type) {
      if(add) {
        this.addPermission(action, type);
      } else {
        this.removePermission(action, type);
      }
    },
    addPermission(action, type) {
      if(action !== 'READ' && !this.permissions.some(p => p.action === 'READ' && p.type === type)) {
        this.value.permissions.push({action: 'READ', type});
      }
      this.value.permissions.push({action, type});
    },
    removePermission(action, type) {
      if(action === 'READ') {
        this.$set(this.value, 'permissions', this.value.permissions.filter(p => p.type !== type));
      } else {
        this.$set(this.value, 'permissions', this.value.permissions.filter(p => p.action !== action || p.type !== type));
      }
    },
    async scrollToView() {
      // eslint-disable-next-line
      document.querySelector(`#draft-collaborator-${this.key}`)?.scrollIntoView({block: 'center'});
    },
    cancel() {
      this.$emit('input', {...this.value, status: 'HIDDEN'});
    },
    async save() {
      try {
        this.loading = true;
        const response = await CollaboratorsApi.save({
          accountId: this.accountId,
          ...this.value,
        });

        this.$emit('input', response.data.object);
        this.loading = false;
        this.toastSuccessDefault();
      } catch (error) {
        this.toastErrorDefault(error);
        this.loading = false;
      }
    }
  },
  validations: {
    value: {
      name: { required },
      email: { required, validEmail: (val) => regexEmail.test(val) }
    },
  },
};
</script>
