import axios from 'axios';
import base from '@/api/base.api';

const namespace = 'users';

export default {
    occupationsList: () => {
        return axios
            .get(`${base.baseUrl}/${namespace}/occupations`);
    }
}
