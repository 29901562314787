import { CHECK_PERMISSION, GET_ACCOUNT, STORE as sessionStore } from "@/store/modules/session/session.types";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            currentPermissionTypes: "",
            permissionTypes: {
                TRANSFERS: "TRANSFERS",
                BENEFICIARIES: "BENEFICIARIES",
                COLLABORATORS: "COLLABORATORS",
                REQUEST_VIRTUAL_ADDITIONAL_CARD: "REQUEST_VIRTUAL_ADDITIONAL_CARD",
                DISPERSIONS: "DISPERSIONS",
                CORPORATIVE_ACCOUNTS: "CORPORATIVE_ACCOUNTS",
            },
            permissionActions: {
                READ: "READ",
                REQUEST: "REQUEST",
                AUTHORIZE: "AUTHORIZE",
            },
        };
    },
    computed: {
        ...mapGetters(sessionStore, {
            infoAccount: GET_ACCOUNT,
            checkPermissionStore: CHECK_PERMISSION
        }),
        canAuthorized() {
            return this.checkPermissionStore(this.permissionActions.AUTHORIZE, this.currentPermissionTypes);
        },
        canRequest() {
            return this.checkPermissionStore(this.permissionActions.REQUEST, this.currentPermissionTypes);
        },
        canRead() {
            return this.checkPermissionStore(this.permissionActions.READ, this.currentPermissionTypes);
        },
    },
    methods: {
        checkPermission(action, type) {
            return this.checkPermissionStore(action, type);
        },
        canAuthorizedByType(type) {
            return this.checkPermissionStore(this.permissionActions.AUTHORIZE, type);
        },
        canRequestByType(type) {
            return this.checkPermissionStore(this.permissionActions.REQUEST, type);
        },
        canReadByType(type) {
            return this.checkPermissionStore(this.permissionActions.READ, type);
        },
        setCurrentPermissionTypes(type) {
            this.currentPermissionTypes = type
        }
    },
}
