<template>
  <div id="" class="panel-my-account" :class="[clazz, {'c-pointer': pointer}]">
    <span>
        <slot></slot>
    </span>
    <span v-if="additionalCards">
        <ReadOnlyInfo
            clazz="m-b-0"
            :titleClazz="pointer ? 'c-pointer' : ''"
            id="ReadOnlyInfoCards"
            :title="addCardTitle"
            :text="addCardText"
        ></ReadOnlyInfo>
        <i aria-hidden="true" class="iconsax-money-cards f-22 c-primary"></i>
    </span>
    <span>
        <ReadOnlyInfo
            clazz="m-b-0"
            :titleClazz="pointer ? 'c-pointer' : ''"
            id="ReadOnlyInfo1"
            :title="primaryTitle"
            :text="primaryText"
        ></ReadOnlyInfo>
        <button
            v-if="!hideButtons"
            id="copyClabe"
            type="button"
            class="button-square sm button-ghost button-primary"
            name="Copiar Clabe"
            aria-label="Copiar Clabe"
            @click="copyData(1)"
        >
            <i aria-hidden="true" class="iconsax-design-copy f-22"></i>
        </button>
    </span>
    <span v-if="secondTitle">
      <ReadOnlyInfo
          clazz="m-b-0"
          :titleClazz="pointer ? 'c-pointer' : ''"
          id="ReadOnlyInfo2"
          :title="secondTitle"
          :text="secondText"
      ></ReadOnlyInfo>
      <button
          v-if="!hideButtons"
          id="copyNoCuenta"
          type="button"
          class="button-square sm button-ghost button-primary"
          name="Copiar número de cuenta"
          aria-label="Copiar número de cuenta"
          @click="copyData(2)"
      >
          <i aria-hidden="true" class="iconsax-design-copy f-22"></i>
      </button>
    </span>
    <span v-if="selectable">
        <slot name="selectable"></slot>
    </span>
  </div>
</template>

<script>
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
import ToastSnotify from "@/mixins/toastSnotify.mixin";


export default {
  name: 'PanelMyAccount',
  mixins: [ToastSnotify],
  props: {
    clazz: String,
    primaryTitle: String,
    primaryText: String,
    secondTitle: String,
    secondText: String,
    selectable: {type: Boolean, default: false},
    hideButtons: {type: Boolean, default: false},
    additionalCards: {type: Boolean, default: false},
    addCardTitle: String,
    addCardText: String,
    pointer: {type: Boolean, default: false},
  },
  components: {
    ReadOnlyInfo,
  },
  methods: {
    copyData(no) {
      let text;
      let type;
      if (no === 1) {
        text = this.primaryText;
        type = this.primaryTitle;
      } else {
        text = this.secondText;
        type = this.secondTitle;
      }
      navigator.clipboard.writeText(text);
      this.displayNotificationInfo(this.$i18n.t('myAccount.generalInfo.modals.copyText', {type: type}));
    }
  }
}
</script>
