<template>
    <div>
        <div class="row">
            <div class="col-12">
                <router-link
                    to="/wallet/dashboard"
                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                    tabindex=""
                >
                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                    {{$t('general.returnHome')}}
                </router-link>
                <FloatingTitleLayout :titleStrong="$t('cardRequest.activation.title')"></FloatingTitleLayout>
            </div>

            <div
                class="col-12"
                v-if="!activated || loading"
            >
                <CardSimple
                    :title="$t('cardRequest.activation.card-title')"
                    :subtitle="$t('cardRequest.activation.card-subtitle')">
                    <div class="row">
                        <div class="col-12 col-md-4 vertical-center">
                            <img
                                class="img-fluid d-flex m-0-auto m-b-30"
                                src="@/assets/images/Default/img-card-activation-number.svg"
                                width="250"
                                height="120"
                                :alt="$t('cardRequest.activation.step-one.label')" />
                        </div>
                        <div class="col-12 col-md-8">
                            <ReadOnlyInfo
                                clazz="m-b-15"
                                :title="$t('cardRequest.activation.step-one.title')"
                                :text="$t('cardRequest.activation.step-one.text')"
                                titleClazz="f-14 c-title poppins-font-semibold f-600 m-b-0"
                                textClazz="f-11 c-text_content poppins-font-medium f-500"
                            ></ReadOnlyInfo>
                            <BasicInput
                                required
                                inputId=""
                                :label="$t('cardRequest.activation.step-one.label')"
                                :inputPlaceholder="$t('cardRequest.activation.step-one.placeholder')"
                                v-model="pan"
                                :mask="['#### #### #### ####']"
                            ></BasicInput>
                        </div>
                        <div class="col-12 col-md-4 vertical-center">
                            <img
                                class="img-fluid d-flex m-0-auto m-b-30"
                                src="@/assets/images/Default/img-card-activation-date.svg"
                                width="250"
                                height="120"
                                :alt="$t('cardRequest.activation.step-two.label')" />
                        </div>
                        <div class="col-12 col-md-8">
                            <ReadOnlyInfo
                                clazz="m-b-15"
                                :title="$t('cardRequest.activation.step-two.title')"
                                :text="$t('cardRequest.activation.step-two.text')"
                                titleClazz="f-14 c-title poppins-font-semibold f-600 m-b-0"
                                textClazz="f-11 c-text_content poppins-font-medium f-500"
                            ></ReadOnlyInfo>
                            <BasicInput
                                required
                                clazz="m-b-0"
                                inputId=""
                                :label="$t('cardRequest.activation.step-two.label')"
                                :inputPlaceholder="$t('cardRequest.activation.step-two.label')"
                                v-model="date"
                                :mask="['##/##']"
                            ></BasicInput>
                        </div>
                    </div>
                </CardSimple>
                <div class="control-buttons">
                    <button
                        type="button"
                        class="button-icon"
                        @click="() => $router.push({name: 'Dashboard'})"
                    >
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                        {{$t('general.return')}}
                    </button>
                    <button
                        type="button"
                        class="button button-filled button-primary"
                        v-text="$t('cardRequest.activation.title')"
                        @click="activate"
                        :disabled="$v.$invalid"
                    ></button>
                </div>
            </div>

            <LoadingBar
                clazz="fullcontent-loading"
                :loadingText="$t('cardRequest.activation.loading')"
                v-if="loading"
            />

            <!-- NUEVA ACTIVACION EXITOSA -->
            <div class="col-12" v-if="activated && !loading">
                <CardSimple hideTitle>
                    <div class="row">
                        <div class="col-12">
                            <img
                                class="img-fluid d-flex m-0-auto m-b-30"
                                src="@/assets/images/Illustrations/illustration-cajero.svg"
                                width="400"
                                height="410"
                                :alt="$t('cardRequest.activation.success.imgAlt')" />
                            <label class="f-20 poppins-font-bold c-text_title text-center d-block w-100 m-b-5">
                                {{ $t('cardRequest.activation.success.title') }}
                            </label>
                            <i18n tag="p" path="cardRequest.activation.success.subtitle" class="f-16 poppins-font-regular c-text_content text-center d-block w-100">
                                <strong class="poppins-font-bold c-info">{{$t('cardRequest.activation.success.atm')}}</strong>
                                <strong class="poppins-font-bold c-text_title"><br/><br/>{{$t('cardRequest.activation.success.remember')}}</strong>
                            </i18n>
                            <a class="f-12 c-info poppins-font-bold text-center m-t-30 d-block w-100"  tabindex="" v-b-modal.modal-cajerosRed>
                                <u>{{ $t('cardRequest.activation.success.openModal') }}</u>
                            </a>
                            <b-alert class="alert-note m-t-30" show variant="info">
                                <p class="text-container">
                                    <i
                                        aria-hidden="true"
                                        class="icon banana banana-comment"
                                        ></i>
                                        <i18n :path="`cardRequest.activation.success.commission.${inquiryCommission ? 'exists' : 'none'}`">
                                        <template #important>
                                            <strong class="text-upper" v-t="'cardRequest.activation.success.commission.important'"/>
                                        </template>
                                        <template #amount>
                                            <strong v-text="$tc('cardRequest.activation.success.commission.amount', currency(inquiryCommission))"/>
                                        </template>
                                    </i18n>
                                </p>
                            </b-alert>
                        </div>
                    </div>
                </CardSimple>
                <div class="control-buttons">
                    <button
                        type="button"
                        class="button-icon"
                        @click="() => $router.push({name: 'Dashboard'})"
                    >
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                        {{$t('general.return')}}
                    </button>
                    <button
                        type="button"
                        class="button button-filled button-primary"
                        v-text="$t('cardRequest.activation.finish')"
                        @click="() => $router.push({name: 'Dashboard'})"
                    ></button>
                </div>
            </div>
        </div>
        <!-- MODAL BANCOS EN RED -->
        <b-modal
            id="modal-cajerosRed"
            size="lg"
            hide-header
            hide-footer
            modal-class="modal-default left-oriented">
            <button class="close-modal"
                    @click="$bvModal.hide('modal-cajerosRed')"
                    :aria-label="$t('general.closeWindowBtn')"
                    :name="$t('general.closeWindowBtn')">
                <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <div class="head">
                <h2 class="modal-title">{{ $t('cardRequest.activation.modalAtm.title') }}</h2>
                <p class="modal-subtitle">{{ $t('cardRequest.activation.modalAtm.subtitle') }}</p>
            </div>
            <div class="body">
                <div class="row">
                    <div class="col-12 col-sm-4" v-for="(column, i) in atmColumns" :key="i">
                        <ul class="p-0 m-0 list-style-none">
                            <li class="f-16 poppins-font-semibold c-text_title m-b-20" v-for="atm in column" :key="atm">{{atm}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </b-modal>
    </div>
</template>
<script>
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import { required } from "vuelidate/lib/validators";
    import {mapGetters} from "vuex";
    import { GET_ACCOUNT, GET_ACCOUNT_DES, STORE as sessionStore} from "@/store/modules/session/session.types";
    import LoadingBar from "@/components/LoadingBar.vue";
    import ExploreFront from "@/assets/images/Cards/Activation/img-success-explore.svg";
    import ExplorePlusFront from "@/assets/images/Cards/Activation/img-success-explore-plus.svg";
    import NightFront from "@/assets/images/Cards/Activation/img-success-night.svg";
    import BussinessFront from "@/assets/images/Cards/Activation/img-success-business.svg";
    import ExploreBack from "@/assets/images/Cards/Activation/img-reverso-explore.svg";
    import ExplorePlusBack from "@/assets/images/Cards/Activation/img-reverso-explore-plus.svg";
    import NightBack from "@/assets/images/Cards/Activation/img-reverso-night.svg";
    import BussinessBack from "@/assets/images/Cards/Activation/img-reverso-business.svg";
    import CardsApi from "@/api/wallet/card.api.js";
    import store from "@/store/index";

    export default {
        name: "CardActivation",
        components: {
            FloatingTitleLayout,
            CardSimple,
            ReadOnlyInfo,
            BasicInput,
            LoadingBar,
        },
        data() {
            const atms = [
                'ABC Capital',
                'Afirme',
                'Banco Autofín',
                'Banco Azteca',
                'Banco Compartamos',
                'Banco del Bajio',
                'Banco del Bienestar',
                'Banco Famsa',
                'Bancoppel',
                'Banjercito',
                'Bankaool',
                'Banorte',
                'Banregio',
                'Bansi',
                'BBVA',
                'CIBanco',
                'Citibanamex',
                'HSBC',
                'Inbursa',
                'Mifel',
                'Multiva',
                'Santander',
                'Scotiabank',
                'Ve Por Más',
            ];
            return {
                loading: false,
                activated: false,
                pan: '',
                date: '',
                atms: atms.sort((a, b) => a.localeCompare(b)),
                inquiryCommission: 0,
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                account: GET_ACCOUNT,
                accountDescription: GET_ACCOUNT_DES,
            }),
            virtualCardWrapper() {
                return this.account.cards.find(({card: {manufacturingType}}) => manufacturingType === 'VIRTUAL');
            },
            frontImage() {
                switch(this.virtualCardWrapper?.cardTemplate?.product) {
                    case 'EXPLORE': return ExploreFront;
                    case 'EXPLORE_PLUS': return ExplorePlusFront;
                    case 'NIGHT': return NightFront;
                    case 'BUSINESS': return BussinessFront;
                    default: return ExploreFront;
                }
            },
            backImage() {
                switch(this.virtualCardWrapper?.cardTemplate?.product) {
                    case 'EXPLORE': return ExploreBack;
                    case 'EXPLORE_PLUS': return ExplorePlusBack;
                    case 'NIGHT': return NightBack;
                    case 'BUSINESS': return BussinessBack;
                    default: return ExploreBack;
                }
            },
            /**
             * Retorna los cajeros que serán mostrados en el modal, agrupados en 3 columnas
             * @returns {Array<Array<string>>}
             */
            atmColumns() {
                const perColumn = Math.ceil(this.atms.length / 3);
                return [0, 1, 2].map(i => this.atms.slice(i*perColumn, (i+1)*perColumn));
            },
            currency() {
                return this.$options.filters.currency;
            },
        },
        methods: {
            async activate() {
                try {
                    this.loading = true;

                    const virtualCard = this.account?.cards?.find(({card: {manufacturingType}}) => manufacturingType === 'VIRTUAL');
                    const physicCard = this.account?.cards?.find(({card: {manufacturingType}}) => manufacturingType === 'PHYSIC');
                    if(physicCard?.requestCard?.isReposition) {
                        await CardsApi.confirmReposition({
                            requestCardInfo: physicCard.requestCard._id,
                            cookie: this.pan,
                            expMonth: this.date.substring(0,2),
                            expYear: this.date.substring(2),
                        });
                    } else {
                        await CardsApi.addPhysicalCard({
                            requestCardInfo: virtualCard.requestCard._id,
                            cookie: this.pan,
                            exp_month: this.date.substring(0,2),
                            exp_year: this.date.substring(2),
                        });
                    }

                    this.activated = true;
                    await store.dispatch(`${sessionStore}/loadAccount`)
                } catch (error) {
                    this.toastErrorDefault(error);
                } finally {
                    this.loading = false;
                }
            },
            async getInquiryCommission() {
                this.inquiryCommission = await CardsApi.getCommissionForEvent({
                    pagandoAccount: this.account?.account?._id,
                    commission: 'BALANCE_INQUIRY',
                }).then(res => res.data.object).catch(() => 0);
            }
        },
        created() {
            this.getInquiryCommission();
        },
        validations: {
            pan: {
                required,
                valid: (val) => new RegExp('^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$').test(val)
            },
            date: {
                required,
                valid: (val) => new RegExp('^([0-9]{4})$').test(val)
            },
        }
    };
</script>
