<template>
    <div :id="id" class="card-simple" :class="clazz">
        <div class="title-card" :class="clazzTitle" v-if="!hideTitle">
            <slot name="titleBefore"></slot>
            <label :class="clazzLabel">
                {{title}}
                <p :class="clazzSubtitle">
                    {{subtitle}}
                    <slot name="subtitleSlot"></slot>
                </p>
            </label>
            <span v-if="showButtons" class="buttons"><slot name="titleButtons"></slot></span>
        </div>
        <div id="dct-docCont" class="body-card" :class="clazzBody">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CardSimple',
        props: {
            id: String,
            clazz: String,
            clazzTitle: String,
            hideTitle: {type: Boolean, default: false},
            clazzLabel: String,
            title: String,
            clazzSubtitle: String,
            subtitle: String,
            clazzBody: String,
            showButtons: {type: Boolean, default: false},
        }
    }
</script>
