<template>
<div>
  <TitlePrincipal :title="$t('register.securityMeasure.title')">
    <p class="subtitle" v-html="$t('register.securityMeasure.subtitle')"></p>
  </TitlePrincipal>
  <div class="security-measures-img m-b-30">
    <div class="security-img-card c-pointer"
         v-for="(image, index) in images"
         :key="image.id"
         :class="image.selected ? 'selected': currentImage ? 'gray' : ''"
         @click="selectImage(image.id, index)"
    >
      <i
          aria-hidden="true"
          class="banana banana-check-normal security-icon-selected"
      ></i>
      <img
          class="img-fluid security-img-cover"
          :src="image.src"
          alt="Imagen seguridad"/>
    </div>
  </div>
  <BasicInput
      v-model="securityPhrase"
      required
      inputType="text"
      inputId="user_frase_seguridad"
      :label="$t('register.securityMeasure.securityLabel')"
      :inputPlaceholder="$t('register.securityMeasure.securityLabelPh')"/>
  <div class="footer-buttons right">
    <button @click="back()" type="button" class="button button-ghost button-plain_text" name="Regresar"> {{ $t('register.securityMeasure.back') }} </button>
    <button v-if="showConfirm" type="button" class="button button-filled button-primary" name="Continuar" :disabled="!disabledButton" @click="submitMeasures">
      {{ $t('register.securityMeasure.continue') }} </button>
    <button v-else :id="'Loading button'" class="button button-filled button-primary">
      <LoadingSimple clazz="small-load no-text"/>
    </button>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import BasicInput from "@/components/BasicInput";
import LoadingSimple from "@/components/LoadingSimple";
import {securityImages} from "@/enums/securityImg";
import UserApi from "@/api/users/users.api";
import {catchError} from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {
  GET_LEVEL,
  GET_CARD_REQUEST,
  GO_TO_ROUTE,
  STORE as onbngStore,
  GET_LOCATION
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import {
  GET_USER,
  SET_USER,
  STORE as sessionStore
} from "@/store/modules/session/session.types";
import { mapGetters } from 'vuex';

export default {
  name: "SecurityMeasures",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicInput,
    LoadingSimple
  },
  computed: {
    ...mapGetters(sessionStore, {
      user: GET_USER
    }),
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      location: GET_LOCATION,
      cardRequest: GET_CARD_REQUEST
    }),
    disabledButton() {
      return this.currentImage && this.securityPhrase;
    }
  },
  data() {
    return {
      showConfirm: true,
      images: securityImages,
      oldSelect: -1,
      currentImage: "",
      securityPhrase: ""
    }
  },
  methods: {
    back() {
      if(this.level === 0) {
        this.$store.dispatch(`${onbngStore}/backCollaborator`)
      } else if(this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${onbngStore}/backBusiness`)
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`)
      }
    },
    selectImage(id, index){
      if (this.oldSelect >= 0 && this.oldSelect !== index) {
          this.images[this.oldSelect].selected = false;
      }
      this.oldSelect = index;
      this.images[index].selected = true;
      this.currentImage = id;
    },
    async submitMeasures() {
      this.showConfirm = false;

      let response;

      if(this.level === 0) {
          response = await UserApi.setCollaboratorSecurity({
            securityPhrase: this.securityPhrase,
            securityImageId: this.currentImage
          }).catch(catchError);
      } else if(this.level === 1 || this.cardRequest.isMoral) {
          response = await UserApi.finishLevelOne({
            securityPhrase: this.securityPhrase,
            securityImageId: this.currentImage,
            setFinishRegister: false,
            cardRequestInfoId: this.cardRequest._id
          }).catch(catchError);
      } else if(this.level === 2) {
        response = await UserApi.finishLevelTwo({
            securityPhrase: this.securityPhrase,
            securityImageId: this.currentImage,
            setFinishRegister: false
          }).catch(catchError);
      } else {
        response = await UserApi.finishLevelThree({
            securityPhrase: this.securityPhrase,
            securityImageId: this.currentImage,
            setFinishRegister: false
          }).catch(catchError);
      }

      // const response = await UserApi.updateSecurityMeasure({
      //   securityPhrase: this.securityPhrase,
      //   securityImageId: this.currentImage,
      // }).catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
            this.displayNotificationError(this.$i18n.t('register.securityMeasure.errorUpdate'));
        } else {
          const user = response.data.data.user;
          this.$store.commit(`${sessionStore}/${SET_USER}`, user);
          this.nextStep()
        }
      }
      this.showConfirm = true;
    },
    nextStep() {
      if(this.level === 0) {
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "newPinCollaborator");
      } else if (this.cardRequest.isMoral) {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.WAIT_REQUEST,
        });
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "businessProcessingRequest");
      } else {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.CREATE_NIP,
        });
        let goTo = "newPin";
        if (this.cardRequest.accountType === 'BUSINESS') {
          goTo = "businessNewPin"
        }
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, goTo);
      }
    },
    assignData() {
      if(this.user) {
        this.securityPhrase = this.user.securityPhrase;
        this.currentImage = this.user.securityImageId;

        const imageIndex = this.images.findIndex(c => c.id === this.currentImage);
        if (imageIndex >= 0) {
          this.images[imageIndex].selected = true;
          this.oldSelect = imageIndex;
        }
      }
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
    this.assignData();
  },
}
</script>

<style scoped>

</style>
