<template>
  <div>
    <TitlePrincipal
        :title="$t('register.businessStart.title')"
        :subtitle="$t('register.businessStart.subtitle')"
    >
    </TitlePrincipal>
    <BasicInput
        required
        inputId="company_name"
        :label="$t('register.businessStart.company')"
        v-model="name"
        :inputPlaceholder="$t('register.businessStart.companyPh')"
    >
    </BasicInput>
    <BasicTextarea
        required
        textareaId="examplet1"
        :label="$t('register.businessStart.whatDoesYourBusinessDo')"
        v-model="description"
        :textareaPlaceholder="$t('register.businessStart.whatDoesYourBusinessDoPh')"
        :maxCount="0"
    >
    </BasicTextarea>
    <CardSelection
        :title="$t('register.businessStart.logo')"
        subtitle="Tamaño: 400px por 400px (jpg, png)"
        :clazz="`border-card ${
        image ? 'icon-bgm-success' : 'icon-bgm-info_text'
      }`"
    >
      <template v-slot:icon>
        <i aria-hidden="true" class="banana banana-camera"></i>
      </template>
      <template v-slot:extraMiddleContent>
        <b-progress
            :max="maxProgressBar"
            class="bar-progress percent-color"
            :class="{ success: valueProgressBar0 == 100 }"
        >
          <b-progress-bar :value="valueProgressBar0">
            <div class="label-progress">
              <span>{{ valueProgressBar0 }}%</span>
            </div>
          </b-progress-bar>
        </b-progress>
      </template>
      <template v-slot:extraFinalContent>
        <button
            type="button"
            class="button xs button-filled button-primary p-relative"
            v-if="!image && !loadingLogo"
        >
          {{ $t('register.businessStart.upload') }}
          <input
              type="file"
              class="input-file"
              ref="file-business"
              id="file-business"
              value=""
              name="file-business"
              @change="fileUpload"
          />
        </button>
        <button
            type="button"
            class="button xs button-filled button-primary p-relative"
            v-if="!image && loadingLogo"
        >
          <LoadingSimple clazz="small-load no-text" />
        </button>
        <i
            v-if="image"
            aria-hidden="true"
            class="banana banana-check-normal f-30 c-success"
        ></i>
      </template>
    </CardSelection>
    <div class="footer-buttons right">
      <button
          type="button"
          class="button button-ghost button-plain_text"
          name="Regresar"
          @click="back()"
      >
        {{ $t('register.businessStart.back') }}
      </button>
      <button
          v-if="showNext"
          type="button"
          class="button button-filled button-primary"
          name="Continuar"
          :disabled="!isValidData"
          @click="submit()"
      >
        {{ $t('register.businessStart.next') }}
      </button>
      <button
          v-else
          type="button"
          class="button button-filled button-primary"
          name="Continuar"
          :disabled="!isValidData"
      >
        <LoadingSimple clazz="small-load no-text"/>
      </button>
    </div>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import BasicTextarea from "@/components/BasicTextarea.vue";
import BasicInput from "@/components/BasicInput.vue";
import CardSelection from "@/components/CardSelection.vue";
import LoadingSimple from "@/components/LoadingSimple";
import {mapGetters} from "vuex";
import {
  STORE as OnboardingStore,
  GET_CARD_REQUEST,
  GET_LOCATION,
  GET_ACCOUNT_TYPE_BUSINESS, STORE as onboardingStepsStore, GO_TO_ROUTE
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO, EMIT_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import {
  STORE as SessionStore,
  GET_USER
} from "@/store/modules/session/session.types";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {required} from "vuelidate/lib/validators";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import {EventBus} from "@/main";

const idFile = "file-business";

export default {
  name: "BusinessStart",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicTextarea,
    BasicInput,
    LoadingSimple,
    CardSelection,
  },
  computed: {
    ...mapGetters(OnboardingStore, {
      cardRequest: GET_CARD_REQUEST,
      isMoral2: GET_ACCOUNT_TYPE_BUSINESS,
      location: GET_LOCATION
    }),
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO,
    }),
    ...mapGetters(SessionStore, {
      user: GET_USER,
    }),
    isValidData() {
      return !this.$v.$invalid;
    },
  },
  data() {
    return {
      showNext: true,
      isMoral: null,
      valueProgressBar0: 0,
      maxProgressBar: 100,
      name: "",
      description: "",
      image: null,
      width: 400,
      height: 400,
      reloadImg: true,
      organizationId: null,
      loadingLogo: false,
    };
  },
  validations: {
    name: {
      required,
    },
    description: {
      required,
    },
  },
  methods: {
    back() {
      this.$store.dispatch(`${onboardingStepsStore}/backBusiness`);
    },
    fileUpload() {
      this.loadingLogo = true;
      this.reloadImg = true;
      this.valueProgressBar0 = 20;
      const files = this.$refs[idFile].files;

      this._validateImage(files[0]);
    },
    _validateImage(file) {
      this.valueProgressBar0 = 50;
      const _URL = window.URL || window.webkitURL;
      const img = new Image();
      const objectUrl = _URL.createObjectURL(file);
      img.onload = () => {
        _URL.revokeObjectURL(objectUrl);
        if (this.imageSizeIsValid(img.width, img.height)) {
          this.valueProgressBar0 = 100;
          this.image = file;
        } else {
          this.valueProgressBar0 = 0;
          const messageKey = "general.image.sizeError";
          this.displayNotificationError(
              this.$t(messageKey, {width: this.width, height: this.height}),
          );
        }
        this.$refs[idFile].value = null;
      };
      img.onerror = (error) => this.toastErrorDefault(error);
      img.src = objectUrl;
      this.loadingLogo = false;
    },
    imageSizeIsValid(width, height) {
      this.valueProgressBar0 = 80;
      if (this.width <= width && this.height <= height) {
        const requiredProportion = (this.width / this.height).toFixed(2);
        const actualProportion = (width / height).toFixed(2);
        return requiredProportion === actualProportion;
      }
      return this.width === width && this.height === height;
    },
    async submit() {
      this.showNext = false;
      const created = await this.$store.dispatch(
          `${OrganizationInfoStore}/saveBasicInfo`,
          {
            name: this.name,
            description: this.description,
            image: this.image,
            isMoral: this.cardRequest.isMoral,
            reloadImg: this.reloadImg,
            organizationId: this.organizationId
          }
      );
      if (created) {
        const editedCard = await this.$store.dispatch(
            `${OrganizationInfoStore}/saveOrgToCardRequest`,
            {
              id: this.cardRequest._id,
              organization: this.organization._id,
            }
        );

        if (editedCard) {
          if (this.cardRequest.isMoral) {
            this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
              stepOnbng: ONBOARDING_STEPS.BUSINESS_START,
            });
            await this.checkRedirectMoral();
          } else {
            await this.checkRedirectPhysic();
          }
        } else {
          // TODO MODIFICAR MENSAJES
          this.displayNotificationError(
              this.$i18n.t("register.selectAccountCard.errorSaveRequestCard"),
              this.$i18n.t("register.selectAccountCard.errorRequestCardTitle")
          );
        }
      } else {
        // TODO MODIFICAR MENSAJES
        this.displayNotificationError(
            this.$i18n.t("register.selectAccountCard.errorSaveRequestCard"),
            this.$i18n.t("register.selectAccountCard.errorRequestCardTitle")
        );
      }
      this.showNext = true;
    },
    async checkRedirectMoral() {
       if(this.$route.name === 'businessStartMultiple') {
        this.$router.push({name: "usagePoliciesBusinessMultiple"})

      } else {
        await this.$store.dispatch(`${onboardingStepsStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.BUSINESS_OPERATES,
          });
        // operates tambien tiene el stepOnbng como bussiness_start por eso no poner go to route
        this.$router.push({name: "businessOperates"})
      }
    },
    async checkRedirectPhysic() {
      if(this.$route.name === 'businessStartMultiple') {
        const policiesAccepted = await this.$store.dispatch(`${onboardingStepsStore}/checkAcceptedPolicies`);
        if(!policiesAccepted) {
          await this.$store.dispatch(`${onboardingStepsStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.POLICIES_OF_USE,
          });
          this.$store.dispatch(`${onboardingStepsStore}/${GO_TO_ROUTE}`, "usagePoliciesBusinessMultiple");
          return;
        }

        if(!this.user.hasFinishLevelTwo) {
          this.$store.dispatch(`${onboardingStepsStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.PLACE_RESIDENCE,
          });
          this.$store.dispatch(`${onboardingStepsStore}/${GO_TO_ROUTE}`, "businessResidenceMultiple");
        } else {
          if(!this.user.hasFinishLevelThree) {
            this.$store.dispatch(`${onboardingStepsStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.ADD_DATA,
          });
            this.$store.dispatch(`${onboardingStepsStore}/${GO_TO_ROUTE}`, "businessAddInfoMultiple");
          } else {
            this.sendRequestCard();
          }
        }
      } else {
        await this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.NATIONALITY,
        });
        this.$store.dispatch(`${onboardingStepsStore}/${GO_TO_ROUTE}`, "businessNationality");
      }
    },
    sendRequestCard() {
      let isMoral = false;
      if (this.cardRequest.isMoral) {
        isMoral = true;
      }

      this.$store.dispatch(`${onboardingStepsStore}/updateOnboardingStep`, {
        stepOnbng: ONBOARDING_STEPS.WAIT_REQUEST,
      });

      this.$store.dispatch(`${onboardingStepsStore}/requestCard`, {
        provider: "PAGANDITO",
        longitud: this.location.longitud,
        latitud: this.location.latitud,
        isMoral: isMoral,
        organizationInfo: this.organization._id,
        isMultipleAccount: this.$route.name === 'businessStartMultiple'
      }).then(() => {});

      this.$router.push({name: "businessProcessingRequestMultiple"});
    },
    assignData() {
      if (this.organization) {
        this.organizationId = this.organization._id;
        this.name = this.organization.name;
        this.description = this.organization.description;
        this.image = this.organization.image;
        if (this.image) {
          this.reloadImg = false;
        }
      }
    }
  },
  async beforeMount() {
    if(this.$route.name === 'businessStartMultiple') {
        this.$store.commit(`${onboardingStepsStore}/SET_CREATE_NEW_ORG`, true);
        await this.$store.dispatch(`${onboardingStepsStore}/putLevel`, this.isMoral2 === 'MORAL' ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC');
    }
    await this.$store.dispatch(`${onboardingStepsStore}/checkCurrentStep`, this.$route);

  },
  mounted() {
    this.assignData();
    EventBus.$on(EMIT_ORGANIZATION_INFO, this.assignData);
  },
  beforeDestroy() {
    EventBus.$off(EMIT_ORGANIZATION_INFO, this.assignData);
  },
};
</script>

<style scoped>
</style>
