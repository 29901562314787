import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'documents';

export default {
    getDocumentWallet: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/actual-terms-platform/WALLET/${params.key}`, params)
    },
    acceptDocumentWallet: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/accept-preterms-platform`, params)
    },
    getPreContract: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/actual-contract-wallet`, params)
    },
    getPreContractFromPagandoAccount: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/news-contract-wallet-preview`, params)
    },
    acceptContract: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/sign-contract`, params)
    },
    findDocumentAccepted: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/find-document-accepted-user`, params);
    },
    findDocumentAcceptedCorporative: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/find-document-accepted-corporative`, params);
    },
    checkIfPoliciesAlreadyAccepted: (id) => {
        return axios.get(`${base.baseUrl}/${namespace}/check-usage-policies/${id}`);
    },
}
