<template>
    <div class="recent-contacts-container" :class="clazz">
        <span>
            <slot name="user"></slot>
        </span>
        <span class="info">
            <label>{{textPrincipal}}</label>
            <p v-html="text"/>
        </span>
        <span>
            <slot name="action"></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'RecentContacts',
        props: {
            clazz: String,
            textPrincipal: String,
            text: String,
        },
    }
</script>
