import {mapGetters} from "vuex";
import {GET_LOCATION, SET_LOCATION, STORE as sessionStore} from "@/store/modules/session/session.types";

export default {
    computed: {
        ...mapGetters(sessionStore, {
            location: GET_LOCATION,
        }),
    },
    created() {
        try {
            if((!this.location.lat || !this.location.lon) && ("geolocation" in navigator)) {
                navigator.geolocation.getCurrentPosition(
                    ({coords: {latitude, longitude}}) => {
                        if (latitude !== this.location.lat || longitude !== this.location.lon) {
                            this.$store.commit(`${sessionStore}/${SET_LOCATION}`, {lat: latitude, lon: longitude});
                        }
                    },
                    () => {
                        if (!this.location.error) {
                            this.$store.commit(`${sessionStore}/${SET_LOCATION}`, {error: true});
                        }
                    }
                );
            }
        } catch (error) {
            // unmanaged
        }
    }
};
