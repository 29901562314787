import {
    GET_USER,
    STORE as sessionStore
  } from "@/store/modules/session/session.types";
import store from '@/store/index'
import {
    // GET_CARD_REQUEST,
    STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";

export default function isWalletUser({ next }) {
    const user = store.getters[`${sessionStore}/${GET_USER}`];

    if(user) {
        if(user.walletUser) {
            if(user.contract !== 2 && user.contract !== 4) {
                return next();
            }
                return next('/update-contract');

        }
        return store.dispatch(`${onbngStore}/getRouteByStep`);
    }
    // TODO algo le duele aquí, no interfiere pere revisar cosas de vue
    return next('/login');
}
