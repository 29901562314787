<template>
    <span class="title-badge-container" :class="clazzContainer">
        <label class="title-badge" :class="clazz">
            {{titleText}}
            <b-badge>{{badgeText}}</b-badge>
        </label>
        <div class="extra-content">
            <slot></slot>
        </div>
    </span>
</template>

<script>
    export default {
        name: 'TitleBadge',
        props: {
            clazzContainer: String,
            clazz: String,
            titleText: String,
            badgeText: String
        },
        methods: {},
    }
</script>
