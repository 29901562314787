var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"modal-detail-purchase","size":"lg","hide-header":"","hide-footer":"","modal-class":"modal-default left-oriented"}},[_c('button',{staticClass:"close-modal",attrs:{"aria-label":"Boton cerrar ventana","name":"Boton cerrar ventana"},on:{"click":function($event){return _vm.$bvModal.hide('modal-detail-purchase')}}},[_c('i',{staticClass:"banana banana-cross-normal",attrs:{"aria-hidden":"true"}})]),_c('div',{staticClass:"head"},[_c('h2',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.$t("movements.details.title")))]),_c('div',{staticClass:"w-100 d-none d-md-flex align-items-c justify-space-between"},[_c('Badge',{attrs:{"type":"badge-info","text":_vm.$t('movements.details.purchase')}}),_c('ReadOnlyInfo',{attrs:{"clazz":"text-center m-b-0 w-fit","title":_vm.$t('movements.details.amount'),"titleClazz":"f-14","text":_vm.getAmount(),"textClazz":_vm.movementToShow.sign === '+'
            ? 'f-28 c-success poppins-font-bold f-700'
            : 'f-28 c-primary poppins-font-bold f-700'}})],1),_c('div',{staticClass:"w-100 d-flex d-md-none flex-wrap justify-content-center row-gap-10px"},[_c('Badge',{attrs:{"clazz":"m-0-auto","type":"badge-info","text":_vm.$t('movements.details.purchase')}}),_c('ReadOnlyInfo',{attrs:{"clazz":"text-center m-b-0","title":_vm.$t('movements.details.amount'),"titleClazz":"f-14","text":_vm.getAmount(),"textClazz":_vm.movementToShow.sign === '+'
            ? 'f-28 c-success poppins-font-bold f-700'
            : 'f-28 c-primary poppins-font-bold f-700'}})],1)]),_c('div',{staticClass:"body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('ReadOnlyInfo',{attrs:{"title":_vm.$t('movements.details.date'),"text":_vm.getDateDetail()}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('ReadOnlyInfo',{attrs:{"title":_vm.$t('movements.details.type'),"text":_vm.movementToShow.contaMovementType
              ? _vm.getTypeMovement(_vm.movementToShow.contaMovementType)
              : '-'}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('ReadOnlyInfo',{attrs:{"title":_vm.$t('movements.details.concept'),"text":_vm.movementToShow.payConcept
              ? _vm.movementToShow.payConcept
              : _vm.movementToShow.movementTitle}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('ReadOnlyInfo',{attrs:{"title":_vm.$t('movements.details.folio'),"text":_vm.movementToShow.fullKey ? _vm.movementToShow.fullKey : '-'}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('ReadOnlyInfo',{attrs:{"title":_vm.$t('movements.details.card'),"text":_vm.getCard()}})],1),(_vm.showCommission())?_c('div',{staticClass:"col-12 col-md-6"},[_c('ReadOnlyInfo',{attrs:{"title":_vm.$t('movements.details.commission'),"text":_vm.getCommission()}})],1):_vm._e()])]),_c('div',{staticClass:"footer"},[_c('p',{staticClass:"f-13 c-text_content poppins-font-medium f-500 text-center m-b-0"},[_vm._v(" "+_vm._s(_vm.$t("movements.details.unrecognizeMovement"))+" "),_c('a',{staticClass:"f-13 c-info poppins-font-bold f-700 din-block hover",attrs:{"href":"","tabindex":""}},[_c('strong',[_c('u',[_vm._v(_vm._s(_vm.$t("movements.details.contactUs")))])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }