<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/promotions-list"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('promotions.detail.goBack')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('promotions.detail.title')"></FloatingTitleLayout>
                        </div>

                        <div class="col-12" v-if="promotion">
                            <CardSimple hideTitle>
                                <div class="row">
                                    <div class="col-12">
                                        <h2 class="f-18 c-primary poppins-font-bold f-700 text-center text-md-left m-b-10">
                                            {{promotion.sponsor}}
                                        </h2>
                                    </div>
                                    <div class="col-12 col-md-3">
                                        <span class="promo-img-preview">
                                            <img
                                                class="img-fluid"
                                                :src="getImage()"
                                                width="150"
                                                height="150"
                                                :alt="promotion.sponsor" />
                                        </span>
                                    </div>
                                    <div class="col-12 col-md-9">
                                        <span class="promo-info-preview">
                                            <label class="f-18 c-text_title poppins-font-bold f-700 text-center text-md-left w-100 m-b-10">
                                                {{promotion.alliance.title}}
                                            </label>
                                            <p class="f-12 c-text_content poppins-font-regular f-400 text-center text-md-left w-100 m-b-20">
                                               {{getAvailabilityDescription()}}
                                            </p>
                                            <p class="f-14 poppins-font-bold f-700 text-center text-md-left w-100 m-b-20" :class="getExtraClass()">
                                                {{getDate()}}
                                            </p>
                                            <label class="f-16 c-text_title poppins-font-bold f-700 text-center text-md-left w-100 m-b-5"
                                                v-if="promotion.alliance.availableIn === 'CUSTOM'">
                                                {{$t('promotions.detail.appliedPromo')}}
                                            </label>
                                            <p class="f-12 c-text_content poppins-font-regular f-400 text-center text-md-left w-100 m-b-0"
                                                v-if="promotion.alliance.availableIn === 'CUSTOM'">
                                                {{promotion.alliance.customAvailableIn}}
                                            </p>
                                        </span>
                                    </div>
                                    <div class="col-12">
                                        <p class="f-12 c-text_info poppins-font-regular f-400 text-center text-md-left m-b-0 m-t-30">
                                            {{promotion.alliance.description}}
                                        </p>
                                    </div>
                                    <div class="col-12">
                                        <div class="control-buttons buttons-full-w buttons-full-w-xs">
                                            <button v-if="promotion.alliance.hasTermsAndConditions"
                                                @click="openTerms()"
                                                type="button"
                                                class="button button-stroke button-primary w-100">
                                                {{$t('promotions.detail.consultTerms')}}
                                            </button>
                                            <a  v-if="promotion.alliance.hasCallToAction"
                                                :href="promotion.alliance.callToActionLink"
                                                class="button button-filled button-primary w-100"
                                                tabindex=""
                                                rel="noopener nofollow"
                                                target="_blank">
                                                {{promotion.alliance.callToActionText}}
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </CardSimple>

                            <div class="control-buttons">
                                <button
                                    @click="goBack()"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('promotions.back')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <b-modal
            id="modal-terms"
            size="lg"
            hide-header
            hide-footer
            modal-class="modal-default left-oriented"
        >
            <button
            class="close-modal"
            @click="$bvModal.hide('modal-terms')"
            aria-label="Boton cerrar ventana"
            name="Boton cerrar ventana"
            >
            <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <div class="head">
            <h2 class="modal-title">{{$t('promotions.detail.termsConditions')}}</h2>
            </div>
            <div class="body">
            <div class="row" v-html="promotion ? promotion.alliance.termsAndConditions : null">
            </div>
            </div>
        </b-modal>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>
    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import { imageDownloadLink } from "@/utils/utils";
    import moment from 'moment';
    import Imagen from '@/assets/images/Backgrounds/Background-login.png';

    export default {
        name: "PromotionDetails",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            CardSimple,
        },
        data() {
            return {
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
                // Multiselect
                valuecolonia: [],
                optionsColonia: [],
                imagePlaceholder: Imagen
            }
        },
        props: {
            promotion: null
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            getImage() {
                if(this.promotion.alliance.thirdPartyLogo) {
                    return imageDownloadLink(this.promotion.alliance.thirdPartyLogo);
                }

                return this.imagePlaceholder;
            },
            getAvailabilityDescription() {
                return this.$t(`promotions.availability.${this.promotion.alliance.availableIn}`);
            },
            getExtraClass() {
                if(this.promotion.endDate) {

                    const days = this.getDifferenceDays(this.promotion.endDate);

                    if(days <= 5) {
                        return 'c-warning';
                    }
                }

                return 'c-text_content';
            },
            getDifferenceDays(endDate) {
                const date = moment(endDate);
                const now = moment();

                return date.diff(now, 'days');
            },
            getDate() {
                if(this.promotion.endDate) {
                    const days = this.getDifferenceDays(this.promotion.endDate);

                    if(days <= 5 && days > 0) {
                        return this.$tc(`promotions.detail.expireInDays`, days, {days: days});
                    } else if(days === 0) {
                        return this.$t('promotions.detail.expireToday');
                    }
                    return this.$t(`promotions.detail.expireIn`, {date: moment(this.promotion.endDate).format('DD/MM/YYYY')});
                }

                return null;
            },
            openTerms() {
                this.$bvModal.show('modal-terms');
            },
            goBack() {
                this.$router.push({name: 'PromotionList'});
            }
        },
        async mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);

            this.id = this.$route.params.id;

            if(!this.promotion) {
                this.toastErrorDefault(this.$t('promotions.detail.error'));
                this.$router.push({name: 'PromotionList'});
            }
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
