<template>
    <div class="transfer-account-info">
        <div class="item">
            <div class="item-title">{{$t('transfers.new.summary.myAccount')}}</div>
            <div class="item-container">
                <slot name="cuentaOrigen"></slot>
                <div class="name">
                    {{transmitterName}}
                </div>
                <div class="account">
                    {{transmitterAccount}}
                </div>
                <div class="number">
                    {{transmitterNumber}}
                </div>
            </div>
        </div>
        <div class="icon">
            <i aria-hidden="true" class="iconsax-arrow-arrow-right"></i>
        </div>
        <div class="item">
            <div class="item-title">{{$t('transfers.new.summary.beneficiary')}}</div>
            <div class="item-container">
                <slot name="cuentaBeneficiario"></slot>
                <div class="name">
                    {{beneficiaryName}}
                </div>
                <div class="account">
                    {{beneficiaryAccount}}
                </div>
                <div class="number">
                    {{beneficiaryNumber}}
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'ChangeBeneficiary',
        components: {},
        props: {
            transmitterName: String,
            transmitterAccount: String,
            transmitterNumber: String,
            beneficiaryName: String,
            beneficiaryAccount: String,
            beneficiaryNumber: String,
        },
        data() {
            return {}
        }
    }
</script>
