<template>
  <div>
    <CardSimple hideTitle>
      <StatusNote
        status=""
        :title="$t('transfers.new.pendingDetails.title')"
        :text="$t('transfers.new.pendingDetails.text')"
        :imgWidth="440"
        :imgHeight="280"
        :imgUrl="imgStatusFirma"
        :imgAlt="$t('transfers.new.pendingDetails.title')"
      >
      </StatusNote>
      <TransferInfo
        :transmitterName="fullName"
        :transmitterAccount="accountDescription"
        :transmitterNumber="accountNumber"
        :beneficiaryName="contact.contactName"
        :beneficiaryAccount="contact.bank"
        :beneficiaryNumber="contact.number"
      >
        <template v-slot:cuentaOrigen>
          <UserAccount
            clazz="xs m-0-auto"
            :userImg="imageDownloadLink(selfPortrait) || imgUserAccount"
            :widthImg="42"
            :heightImg="42"
            :altImgUser="fullName"
          >
          </UserAccount>
        </template>
        <template v-slot:cuentaBeneficiario>
          <UserAccount
            :clazz="`xs m-0-auto ${getContactColorClass(contact)}`"
            :noAccount="!contact.isPagandoAccount"
            :userImg="imageDownloadLink(contact.image) || imgUserAccount"
            :userName="getContactInitials(contact)"
            :widthImg="42"
            :heightImg="42"
            :altImgUser="contact.contactName"
          >
          </UserAccount>
        </template>
      </TransferInfo>
      <div class="row align-items-c m-t-30">
        <div class="col-12 col-sm-6">
          <ReadOnlyInfo
            :title="$t('transfers.new.pendingDetails.amount')"
            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
            :text="amount | currency"
            textClazz="f-26 c-text_title poppins-font-bold f-700 m-b-5 text-center"
          >
            <p class="f-12 c-text_content poppins-font-medium f-500 m-b-0 text-center" v-if="transferDetails.commission">
              {{$t('transfers.new.pendingDetails.commission1')}}
              <strong class="c-text_title poppins-font-bold f-700">
                {{$t('transfers.new.pendingDetails.commission2', {amount: currency(transferDetails.commission)})}}
              </strong>
            </p>
            <p class="f-12 c-text_content poppins-font-medium f-500 m-b-0 text-center" v-else>
              {{$t('transfers.new.pendingDetails.noCommission')}}
            </p>
          </ReadOnlyInfo>
        </div>
        <div class="col-12 col-sm-6">
          <ReadOnlyInfo
            title="Concepto"
            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
            :text="$t('transfers.new.pendingDetails.concept')"
            textClazz="f-16 c-text_title poppins-font-bold f-700 m-b-5 text-center"
          >
          </ReadOnlyInfo>
        </div>
      </div>
    </CardSimple>

    <div class="control-buttons">
      <button
        type="button"
        class="button button-filled button-primary m-0-0-0-auto"
        @click="$emit('finish')"
      >
        {{$t('transfers.new.pendingDetails.finish')}}
      </button>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import TransferInfo from "@/components/TransferInfo.vue";
import StatusNote from "@/components/StatusNote.vue";
import CardSimple from "@/components/CardSimple.vue";
import imgErrorStatus from "@/assets/images/Illustrations/illustration-status-error.svg";
import UserAccount from "@/components/UserAccount.vue";
import imgUserAccount from "@/assets/images/Default/profile-photo.svg";
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
import imgStatusFirma from "@/assets/images/Illustrations/illustration-firma.svg";
import { imageDownloadLink } from "@/utils/utils";
import { getContactColorClass, getContactInitials } from "@/utils/contactUtils";
import { mapGetters } from "vuex";
import moment from "moment";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {
  STORE as sessionStore,
  GET_ACCOUNT_DESCRIPTION,
  GET_ACCOUNT,
  GET_ACCOUNT_NUMBER,
  GET_USER_FULL_NAME,
  GET_USER_SELF_PORTRAIT,
} from "@/store/modules/session/session.types";

export default {
  name: "NewTransferPendingDetails",
  mixins: [ToastSnotify],
  components: {
    TransferInfo,
    StatusNote,
    CardSimple,
    UserAccount,
    ReadOnlyInfo,
  },
  props: {
    contact: Object,
    amount: Number,
    concept: String,
    transferDetails: Object,
  },
  data() {
    return {
      // Images
      imgErrorStatus: imgErrorStatus,
      imgUserAccount: imgUserAccount,
      imgStatusFirma: imgStatusFirma,

      // SidebarRight / MenuPrincipal
      window: {
        width: 0,
      },
      isMenuOpen: false,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
      account: GET_ACCOUNT,
      accountDescription: GET_ACCOUNT_DESCRIPTION,
      accountNumber: GET_ACCOUNT_NUMBER,
      fullName: GET_USER_FULL_NAME,
      selfPortrait: GET_USER_SELF_PORTRAIT,
    }),
    currency() {
      return this.$options.filters.currency;
    },
  },
  methods: {
    moment,
    imageDownloadLink,
    getContactColorClass,
    getContactInitials,
    // Resize Sidebar Menu Principal
    handleResizeMenu() {
      this.window.width = window.innerWidth;
      if (window.innerWidth > 767) {
        this.isMenuOpen = true;
      } else {
        this.isMenuOpen = false;
      }
    },
    // Show Sidebar Menu Principal
    showMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    // Hide Sidebar Menu Principal
    hideMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  mounted() {
    this.handleResizeMenu();
    window.addEventListener("resize", this.handleResizeMenu);
  },
  destroyed() {
    window.addEventListener("resize", this.handleResizeMenu);
  },
};
</script>
