<template>
  <div>
    <TitlePrincipal
      title="Domicilio comercial"
      subtitle="Para continuar con el registro responde la siguiente pregunta."
    />
    <div class="col-12 m-b-30">
      <label class="f-16 c-title poppins-font-semibold d-block m-b-5">
        ¿Tu domicilio comercial es igual a tu domicilio fiscal?
      </label>
      <div class="vertical-center column-gap-50px">
        <RadioInput
          ref="domComercial_yes"
          idInput="domComercial_yes"
          text="Si"
          @selected="onRadioInput(true)"
        ></RadioInput>
        <RadioInput
          ref="domComercial_no"
          idInput="domComercial_no"
          text="No"
          @selected="onRadioInput(false)"
        ></RadioInput>
      </div>
    </div>
    <AddressForm v-if="addressesEqual === false" v-model="address" />
    <div class="footer-buttons right">
      <button
        type="button"
        class="button button-ghost button-plain_text"
        name="Regresar"
        @click="back"
      >
        Regresar
      </button>
      <button
        v-if="!processing"
        type="button"
        class="button button-filled button-primary"
        name="Continuar"
        :disabled="!validForm"
        @click="submit"
      >
        Continuar
      </button>
      <button
        v-else
        :id="'Loading button'"
        class="button button-filled button-primary"
      >
        <LoadingSimple clazz="small-load no-text" />
      </button>
    </div>
  </div>
</template>
<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import RadioInput from "@/components/RadioInput.vue";
import AddressForm from "@/components/AddressForm.vue";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import LoadingSimple from "@/components/LoadingSimple";
import { ONBOARDING_STEPS } from "@/utils/onbngSteps";
import { mapGetters } from "vuex";
import { GO_TO_ROUTE, STORE as OnboardingStore } from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import { required, minLength } from "vuelidate/lib/validators";
import { isDefined } from "@/utils/utils";

export default {
  name: "CommercialAddress",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    LoadingSimple,
    RadioInput,
    AddressForm,
  },
  data() {
    return {
      addressesEqual: null,
      processing: false,
      address: {},
    };
  },
  computed: {
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO,
    }),
    validForm() {
      if (this.addressesEqual) {
        return true;
      }
      return !this.$v.$invalid;
    },
  },
  validations: {
    address: {
      zipCode: { required },
      city: { required },
      state: { required },
      district: { required, minLength: minLength(3) },
      streetAddress: {
        required,
        minLength: minLength(3),
      },
      exteriorNumber: { required },
    },
  },
  async mounted() {

    if(this.$route.name === 'businessFiscalComAddressMultiple') {
      this.$store.commit(`${OnboardingStore}/SET_CREATE_NEW_ORG`, true);
      await this.$store.dispatch(`${OnboardingStore}/putLevel`, 'MULTIPLE_BUSINESS_MORAL');
    }
    this.$store.dispatch(`${OnboardingStore}/checkCurrentStep`, this.$route);

    if(isDefined(this.organization?.businessAddressEqualsToLegal)) {
      this.addressesEqual = this.organization.businessAddressEqualsToLegal;
      if(this.addressesEqual) {
        this.$refs.domComercial_yes.reloadInternalValue(true);
      } else {
        this.$refs.domComercial_no.reloadInternalValue(true);
        this.address = this.organization.businessAddress;
      }
    }
  },
  methods: {
    back() {
      this.$store.dispatch(`${OnboardingStore}/backBusiness`);
    },
    async submit() {
      this.processing = true;
      let params;
      if (this.addressesEqual) {
        params = {
          id: this.organization.id,
          businessAddressEqualsToLegal: true,
        };
      } else {
        params = {
          ...this.address,
          id: this.organization.id,
        };
      }
      const success = await this.$store.dispatch(
        `${OrganizationInfoStore}/saveCommercialAddress`,
        params
      );
      if (success) {
        this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.BUSINESS_SHAREHOLDERS,
        });

        if(this.$route.name === 'businessFiscalComAddressMultiple') {
          this.$store.dispatch(`${OnboardingStore}/${GO_TO_ROUTE}`, "businessShareholdersMultiple");
        } else {
          this.$store.dispatch(`${OnboardingStore}/${GO_TO_ROUTE}`, "businessShareholders");
        }
      } else {
        this.displayNotificationError(
          "No se pudo guardar la informacion del negocio"
        );
      }
      this.processing = false;
    },
    onRadioInput(event) {
      this.addressesEqual = event;
    },
  },
};
</script>
