<template>
  <div>
    <TitlePrincipal :title="$t('register.phoneCode.title')" clazz="text-align-c">
      <p class="subtitle" v-html="$t('register.phoneCode.subtitle', {phone: getPhone()})"></p>
    </TitlePrincipal>
    <CodeInput v-show="showCodeInput" :warningText="$t(warningMessage)" :clazz="errorCode ? 'has-error' : ''"
               v-on:verificationCode="onChangeCode"/>

    <span v-if="!showCodeInput" class="d-block p-relative bgm-white min-h-200px">
                        <LoadingBar>
                            <p v-html="$t('register.phoneCode.checkingCode')"></p>
                        </LoadingBar>
                    </span>

    <span v-if="showTimer" class="f-12 c-plain_text poppins-font-medium text-align-c d-block">
                        {{ $t('register.phoneCode.reSendCodeIn') }}
                        <p class="f-24 poppins-font-semibold" id="countdownTimer"></p>
                    </span>

    <a v-else href="#" class="f-14 c-info poppins-font-bold text-underline text-align-c w-100 d-block" tabindex=""
       @click="reSendCode">
      {{ $t('register.phoneCode.reSendCode') }}
    </a>
    <!-- <a href="#" class="f-14 c-info poppins-font-bold text-underline text-align-c w-100 d-block m-t-30" tabindex="">
        <u>Quiero recibir el código por llamada</u>
    </a>-->
    <div class="footer-buttons center">
      <button @click="back()" type="button" class="button button-ghost button-plain_text" name="Regresar">
        {{ $t('register.phoneCode.back') }}
      </button>
<!--      <button type="button" class="button button-filled button-primary" name="Confirmar" disabled>-->
<!--        {{ $t('register.phoneCode.confirm') }}-->
<!--      </button>-->
    </div>
  </div>
</template>

<script>
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import TitlePrincipal from "@/components/TitlePrincipal";
import CodeInput from "@/components/CodeInput";
import LoadingBar from "@/components/LoadingBar";
import UserApi from "@/api/users/users.api";
import {catchError} from "@/utils/utils";
import {SET_USER,
GET_FULL_DATA_USER,
 STORE as SESSION_STORE} from "@/store/modules/session/session.types";
import { mapGetters } from 'vuex';
import {
  GET_LEVEL, GET_CARD_REQUEST, GET_LOCATION, SET_LOCATION, STORE as onbngStore,
  STORE as OnboardingStepsStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
let intervalCodeTime;

export default {
  name: "CodeVerification",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    CodeInput,
    LoadingBar
  },
  data() {
    return {
      warningMessage: "register.phoneCode.warningCodeNormal",
      errorCode: false,
      showTimer: false,
      showCodeInput: true
    }
  },
  computed: {
    ...mapGetters(SESSION_STORE, {
      fullDataUser: GET_FULL_DATA_USER
    }),
    ...mapGetters(OnboardingStepsStore, {
      level: GET_LEVEL,
      location: GET_LOCATION,
      cardRequest: GET_CARD_REQUEST
    }),
  },
  methods: {
    back() {
      if(this.level === 0) {
        this.$store.dispatch(`${OnboardingStepsStore}/backCollaborator`)
      } else if(this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${OnboardingStepsStore}/backBusiness`)
      } else {
        this.$store.dispatch(`${OnboardingStepsStore}/backPersonal`)
      }
    },
    countDown(start = 0) {
      this.showTimer = true;
      let countDownDate;
      if (start) {
          countDownDate = start;
      } else {
        countDownDate = new Date();
        countDownDate = countDownDate.setMinutes(countDownDate.getMinutes() + 2, countDownDate.getSeconds() + 30);
        localStorage.setItem('onbngCountDownDate', countDownDate);
      }
      intervalCodeTime = setInterval( () => {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        document.getElementById("countdownTimer").innerHTML = `${minutes} m ${seconds} s`;
        if (distance < 0) {
          clearInterval(intervalCodeTime);
          localStorage.removeItem('onbngCountDownDate');
          this.showTimer = false;
          this.showCodeInput = true;
        }
      }, 1000);
    },
    async onChangeCode(value) {
      if (value.length === 5) {
        this.showCodeInput = false;
        const response = await UserApi.verifyCode({
          code: value
        }).catch(catchError);
        if (response && response.data) {
          if (response.data.error) {
            this.errorCode = true;
            this.warningMessage = "register.phoneCode.warningCodeError"
          } else if(this.level === 0) {
            this.$store.commit(`${SESSION_STORE}/${SET_USER}`, response.data.data);
            // NO USAR función STORE GO_TO_ROUTE
            this.$router.push({name: 'successCodeCollaborator'});
          } else {
            this.warningMessage = "register.phoneCode.warningCodeNormal"
            this.errorCode = false;
            this.sendRequestCard();
            // localStorage.setItem('currentUser', JSON.stringify(response.data.data));
            this.$store.commit(`${SESSION_STORE}/${SET_USER}`, response.data.data);
            let goTo = "successCode";
            if (this.cardRequest.accountType === 'BUSINESS') {
              goTo = "businessSuccessCode"
            }
            // NO USAR función STORE GO_TO_ROUTE
            this.$router.push({name: goTo});
          }
        }
        this.showCodeInput = true;
      }
    },
    sendRequestCard() {
      let isMoral = false;
      if (this.cardRequest.isMoral) {
        isMoral = true;
      }
      this.$store.dispatch(`${onbngStore}/requestCard`, {
        provider: "PAGANDITO",
        longitud: this.location.longitud,
        latitud: this.location.latitud,
        isMoral: isMoral
      }).then(() => {});
    },
    async reSendCode() {
      this.showCodeInput = false;
      const response = await UserApi.reSendCode().catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(this.$i18n.t('register.phoneCode.errorCode'));
        }
        this.countDown();
      }
      this.showCodeInput = true;
    },
    getPhone() {
      const phone = this.fullDataUser.phone || '';
      const match = phone.match(/^(\S{3})(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        return `(${match[2]}) ${match[3]} ${match[4]}`;
      }
      return this.fullDataUser.phone;
    }
  },
  created() {
    this.$store.dispatch(`${OnboardingStepsStore}/checkCurrentStep`, this.$route);
    const times = localStorage.getItem('onbngCountDownDate');
    if (times) {
      this.countDown(times);
    } else {
      this.countDown();
    }
    if((!this.location.latitud || !this.location.longitud) && ("geolocation" in navigator)) {
      navigator.geolocation.getCurrentPosition((coords) => {
        this.$store.commit(`${onbngStore}/${SET_LOCATION}`, {latitud: coords.coords.latitude, longitud: coords.coords.longitude})
      });
    }
    // localStorage.removeItem('expireDate');
    // localStorage.setItem('currentToken', JSON.stringify(response.data.data.token));
  },
  beforeDestroy() {
    clearInterval(intervalCodeTime);
  }
}
</script>

<style scoped>

</style>
