<template>
  <div>
    <!-- Modal editar contacto otro banco -->
    <b-modal
      :id="modalId"
      size="lg"
      hide-header
      hide-footer
      modal-class="modal-default left-oriented"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-edit-contact-other')"
        :aria-label="$t('transfers.beneficiaries.edit.close')"
        :name="$t('transfers.beneficiaries.edit.close')"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{$t('transfers.beneficiaries.edit.titleExternal')}}</h2>
        <p class="modal-subtitle">{{$t('transfers.beneficiaries.edit.subtitle')}}</p>
      </div>
      <div class="body">
        <BasicInput
          required
          inputId="usuario_clabe"
          :label="$t('transfers.beneficiaries.fields.clabeLabel')"
          :inputPlaceholder="$t('transfers.beneficiaries.fields.clabePlaceholder')"
          :inputValue="clabe"
          disabled
        >
        </BasicInput>
        <BasicInput
          required
          inputId="usuario_banco"
          :label="$t('transfers.beneficiaries.fields.bankLabel')"
          :inputPlaceholder="$t('transfers.beneficiaries.fields.bankPlaceholder')"
          :inputValue="bank ? bank.shortName : ''"
          disabled
        >
        </BasicInput>
        <BasicInput
          required
          inputId="usuario_nombre_beneficiario"
          :label="$t('transfers.beneficiaries.fields.nameLabel')"
          :inputPlaceholder="$t('transfers.beneficiaries.fields.namePlaceholder')"
          :inputValue="contactName"
          disabled
        >
        </BasicInput>
        <BasicInput
          required
          inputId="usuario_alias"
          :label="$t('transfers.beneficiaries.fields.aliasLabel')"
          :inputPlaceholder="$t('transfers.beneficiaries.fields.aliasPlaceholder')"
          v-model="alias"
        >
        </BasicInput>
      </div>
      <div class="footer">
        <button type="button" class="button button-ghost button-error" @click="onClickDelete">
          {{$t('transfers.beneficiaries.edit.delete')}}
        </button>
        <button
          v-if="!loading"
          type="button"
          class="button button-filled button-primary"
          :disabled="!validForm"
          @click="save"
        >
          {{$t('transfers.beneficiaries.edit.save')}}
        </button>
        <button
          v-else
          type="button"
          class="button button-filled button-primary"
        >
          <LoadingSimple clazz="small-load no-text" />
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BasicInput from "@/components/BasicInput.vue";
import ContactsApi from "@/api/wallet/contacts.api";
import LoadingSimple from "@/components/LoadingSimple.vue";

export default {
  name: "ModalEditContactExternal",
  components: {
    BasicInput,
    LoadingSimple,
  },
  data() {
    return {
      modalId: "modal-edit-contact-other",
      contactId: null,
      clabe: null,
      contactName: null,
      alias: null,
      loading: false,
      bank: null,
    };
  },
  computed: {
    validForm() {
      return this.alias;
    },
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      if (modalId === this.modalId) {
        this.clearModal();
      }
    });
  },
  methods: {
    open(contact) {
      this.contactId = contact._id;
      this.clabe = contact.clabe;
      this.contactName = contact.contactName;
      this.alias = contact.alias;
      this.bank = contact.bank;

      this.$bvModal.show(this.modalId);
    },
    clearModal() {
      this.contactId = null;
      this.clabe = null;
      this.contactName = null;
      this.alias = null;
      this.loading = false;
    },
    async save() {
      this.loading = true;
      try {
        await ContactsApi.edit({
          contactId: this.contactId,
          alias: this.alias,
        });
        this.$emit("onSave");
        this.$bvModal.hide(this.modalId);
      } catch (error) {
        this.toastErrorDefault(error);
        this.loading = false;
      }
    },
    async onClickDelete() {
      this.$bvModal.hide(this.modalId);
      this.$emit('delete');
    },
  },
};
</script>
