<template>
    <CardSimple
        clazzTitle="m-b-0"
        :title="$t('cardRequest.address-change-page.title')"
        :subtitle="$t('cardRequest.address-change-page.subtitle')"
        showButtons
    >
        <template v-slot:titleBefore>
            <div class="icon-bgm icon-warning">
                <i aria-hidden="true" class="iconsax-location-location"></i>
            </div>
        </template>

        <template v-slot:titleButtons>
            <BasicInput
                required
                class="search-type m-b-0"
                inputType="text"
                :label="$t('cardRequest.address-change-page.postal-code-label')"
                :inputPlaceholder="$t('cardRequest.address-change-page.postal-code-placeholder')"
                v-model="zipCode"
                :validations="$v.zipCode"
            >
                <button
                    type="button"
                    class="button xs button-filled button-primary"
                    v-text="$t('cardRequest.address-change-page.search')"
                    @click="searchZipCode"
                    :disabled="$v.zipCode.$invalid"
                ></button>
            </BasicInput>
        </template>

        <div
            class="row"
            v-if="!zipCodeData"
        >
            <div class="col-12">
                <EmptyState
                    clazz="p-relative"
                    emptyStateDefault
                    :title="$t('cardRequest.address-change-page.postal-code-unkown')"
                    :imgUrl="imgEmptyCP"
                    :imgWidth="300"
                    :imgHeight="285"
                    :imgAlt="$t('cardRequest.address-change-page.postal-code-unkown')"
                >
                    <a class="f-12 c-info poppins-font-bold f-700 text-center d-block w-100 hover" tabindex="" @click="openSarchZipCodeTab">
                        <u v-text="$t('cardRequest.address-change-page.postal-code-know')"></u>
                    </a>
                    <template
                        v-slot:extraContent
                        v-if="zipCodeDataLoading"
                    >
                        <LoadingBar
                            clazz="fullcontent-loading"
                            :loadingText="$t('cardRequest.address-change-page.postal-code-loading')"
                        ></LoadingBar>
                    </template>
                </EmptyState>
            </div>
        </div>

        <div
            class="row p-t-35"
            v-else
        >
            <div class="col-12 col-md-6">
                <BasicSelect
                    required
                    :label="$t('cardRequest.address-change-page.district-label')"
                >
                    <multiselect
                        v-model="district"
                        :options="zipCodeData.apiDetail.map((name) =>({name}))"
                        :close-on-select="true"
                        :preserve-search="true"
                        :placeholder="$t('cardRequest.address-change-page.district-placeholder')"
                        label="name"
                        track-by="name"
                        :preselect-first="false"
                    >
                        <template
                            slot="selection"
                            slot-scope="{ values, isOpen }">
                            <span
                                class="multiselect__single"
                                v-if="values.length &amp;&amp; !isOpen"
                            >{{ values.length }} options selected</span>
                        </template>
                    </multiselect>
                </BasicSelect>
            </div>
            <div class="col-12 col-md-3">
                <BasicInput
                    required
                    inputId=""
                    :label="$t('cardRequest.address-change-page.state')"
                    :inputPlaceholder="$t('cardRequest.address-change-page.state')"
                    :value="state.name"
                    disabled
                ></BasicInput>
            </div>
            <div class="col-12 col-md-3">
                <BasicInput
                    required
                    inputId=""
                    :label="$t('cardRequest.address-change-page.delegation')"
                    :inputPlaceholder="$t('cardRequest.address-change-page.delegation')"
                    :value="delegation.name"
                    disabled
                ></BasicInput>
            </div>
            <div class="col-12 col-md-6">
                <BasicInput
                    required
                    inputId=""
                    :label="$t('cardRequest.address-change-page.street-label')"
                    :inputPlaceholder="$t('cardRequest.address-change-page.street-placeholder')"
                    v-model="street"
                ></BasicInput>
            </div>
            <div class="col-12 col-md-3">
                <BasicInput
                    required
                    inputId=""
                    :label="$t('cardRequest.address-change-page.noExt-label')"
                    :inputPlaceholder="$t('cardRequest.address-change-page.noExt-placeholder')"
                    v-model="noExt"
                ></BasicInput>
            </div>
            <div class="col-12 col-md-3">
                <BasicInput
                    inputId=""
                    :label="$t('cardRequest.address-change-page.noInt-label')"
                    :inputPlaceholder="$t('cardRequest.address-change-page.noInt-placeholder')"
                    v-model="noInt"
                ></BasicInput>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <div class="control-buttons buttons-full-w buttons-full-w-xs">
                    <button
                        type="button"
                        class="button sm button-stroke button-primary w-100"
                        v-text="$t('cardRequest.address-change-page.cancel')"
                        @click="cancel"
                    ></button>
                    <button
                        type="button"
                        class="button sm button-filled button-primary w-100"
                        v-text="$t('cardRequest.address-change-page.save')"
                        :disabled="$v.$invalid"
                        @click="save"
                    ></button>
                </div>
            </div>
        </div>
    </CardSimple>
</template>
<script>
import CardSimple from "@/components/CardSimple.vue";
import BasicInput from "@/components/BasicInput.vue";
import EmptyState from "@/components/EmptyState.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import BasicSelect from "@/components/BasicSelect.vue";
import Multiselect from "vue-multiselect";
import imgEmptyCP from "@/assets/images/Emptystates/emptystate-no-cp.svg";
import ZipCodeApi from "@/api/core/zipCode.api.js";
import {minLength, maxLength, required} from "vuelidate/lib/validators";

export default {
    name: "CardRequestAddressChange",
    components: {
        CardSimple,
        BasicInput,
        EmptyState,
        LoadingBar,
        BasicSelect,
        Multiselect,
    },
    data() {
        return {
            imgEmptyCP,
            zipCode: null,
            zipCodeData: null,
            zipCodeDataLoading: false,
            district: null,
            delegation: null,
            state: null,
            street: null,
            noExt: null,
            noInt: null,
            country: null,
        };
    },
    methods: {
        cancel() {
            this.$emit('cancel');
        },
        openSarchZipCodeTab() {
            window.open('http://www.correosdemexico.gob.mx/SSLServicios/ConsultaCP/Descarga.aspx', '_blank');
        },
        async searchZipCode() {
            this.zipCodeDataLoading = true;
            this.zipCodeData = await ZipCodeApi
                .search({zipCode: this.zipCode})
                .then(({data: {object}}) => object);
            this.zipCodeDataLoading = false;
            this.state = this.zipCodeData.state
            this.delegation = this.zipCodeData.delegation
            this.country = this.zipCodeData.country
        },
        save() {
            const addres = {
                zipCode: this.zipCode,
                district: this.district.name,
                delegation: this.delegation,
                state: this.state,
                street: this.street,
                noExt: this.noExt,
                noInt: this.noInt,
                country: this.country,
            };
            this.$emit('save', addres);
        }
    },
    validations: {
        zipCode: {
            required,
            minLength: minLength(5),
            maxLength: maxLength(5),
        },
        district: { required },
        delegation: { required },
        state: { required },
        street: { required },
        noExt: { required },
        country: { required },
    }
};
</script>
