import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import UserApi from "@/api/users/users.api";
import config from '@/config';
const { stringToBoolean, secondsSince } = require("./utils/utils");

import base from '@/api/base.api';
import axios from 'axios';
import i18n from '@/plugins/i18n';
// Plugins
import '@/plugins/bootstrap';
import '@/plugins/vueapexcharts';
import '@/plugins/vue-snotify';
import '@/plugins/vue-tel-input';
import '@/plugins/vue-multiselect';
import '@/plugins/vue-clockpicker';
import '@/plugins/vuejs-daterangepicker';
import '@/plugins/vue-signature-pad';
import '@/plugins/vuelidate';
import '@/plugins/qrCode';
import '@/plugins/vueView';
import '@/plugins/currencyFilter';
import '@/plugins/v-money';
import '@/plugins/vue-the-mask';
import '@/plugins/vue-barcode';


// Mixins
import LocationMixin from '@/mixins/location.mixin.js';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import PermissionsMixin from "@/mixins/PermissionsMixin";

import {
  CLEAR_STORE,
  GET_TOKEN,
  STORE as sessionStore
} from "@/store/modules/session/session.types";

import {beforeMount} from './utils/initVue'

axios.defaults.baseURL = base.baseUrl;

document.getElementById('firstLoading').style.display = "block"
// Only for vendors css
import '@/plugins/vendors-css';

export const EventBus = new Vue();

const globalMethods = {
  install(app) {
    app.mixin({
      methods: {
        $requestAuth() {
          return new Promise(resolve => EventBus.$emit('twoFactorAuth', resolve));
        },
        /**
         * Ejecuta un callback cada vez que existe una mutacion en un store.
         * @param {String} store El nombre del store que contiene el mutation
         * @param {String} mutation El nombre del mutation a observar
         * @param {Function} callback El callback que se ejecuta despues del mutation
         * @param {Boolean} immediate True por default. Determina si el callback se ejecuta una vez antes de esperar la mutacion.
         * @returns {Function} Una funcion que al ser ejecutada hace que deje de observarse la mutacion
         */
        $watchMutation(store, mutation, callback, immediate = true) {
          if(immediate) {
            callback();
          }
          return this.$store.subscribe(({type}) => {
            if(type === `${store}/${mutation}`) {
              callback();
            }
          })
        },
      }
    });
  }
}

Vue.mixin(LocationMixin);
Vue.mixin(ToastSnotify);
Vue.mixin(PermissionsMixin);
Vue.config.productionTip = false;
Vue.use(globalMethods);

beforeMount().then(() => {

  new Vue({
    router,
    mounted: async function (){
      // Agrega el fingerprint como header a todas las peticiones
      const fingerprint = await window.PagandoW.getAFT();
      axios.defaults.headers.common['X-Device-Fingerprint'] = fingerprint;
    },
    i18n,
    store,
    render: h => h(App),
  }).$mount('#app');
});

let interval;

function _clearSession() {
  localStorage.removeItem('currentUser');
  localStorage.removeItem('currentToken');
  localStorage.removeItem('currentPermissions');
  localStorage.removeItem('profile');
  localStorage.removeItem('expireDate');
  localStorage.removeItem('s1');
  UserApi.logout();
  store.commit(`${sessionStore}/${CLEAR_STORE}`);
  if (router.currentRoute.name !== 'login') {
    router.push({ name: 'login' });
  }
}

/** Tiempo de inactividad (en segundos) para que se muestre el modal de inactividad */
const timeForIdleWarn = 150; // 2 min 30 seg

/** Tiempo de inactividad (en segundos) para que se haga logout automatico */
const timeForIdleLogout = 180; // 3 min

/** Tiempo mínimo (en segundos) entre llamadas al preserveSession */
const preserveMinInterval = 20;

if (stringToBoolean(config.automaticLogout)) {
  let lastFocus = new Date(); // Último momento en que el usuario interactuó con la página
  let lastPreserve = new Date(); // Último momento en que se llamó a preserveSession

  const isGuest = () => !store.getters[`${sessionStore}/${GET_TOKEN}`];

  const checkIfModalIdleIsShown = async () => {
    return new Promise(resolve => EventBus.$emit('checkIfModalIdleIsShown', resolve));
  };

  const onMovement = () => {
    if(!document.hasFocus()) {
      return;
    }
    lastFocus = new Date();

    if (isGuest()) {
      // No preservar sesión si el usuario es guest
      return;
    }

    checkIfModalIdleIsShown().then(isShown => {
      // Si el modal de inactividad está abierto, no preservar sesión (para eso está el botón de "Mantener sesión")
      if (!isShown && secondsSince(lastPreserve) > preserveMinInterval) {
        lastPreserve = new Date();
        UserApi.preserveSession();
      }
    });
  }
  document.onmousemove = onMovement;
  document.onclick = onMovement;

  setInterval(() => {
    if (isGuest()) {
      // No checar inactividad si el usuario es guest
      return;
    }

    if (secondsSince(lastFocus) > timeForIdleLogout) {
      EventBus.$emit('closeIdleWarning');
      _clearSession();
    } else if (secondsSince(lastFocus) > timeForIdleWarn) {
      EventBus.$emit('openIdleWarning', {
        onClickLogout() {
          _clearSession();
        },
        onClickPreserveSession() {
          lastFocus = new Date();
          lastPreserve = new Date();
          UserApi.preserveSession();
        },
      });
    }
  }, 1000);
}

