// VIEWS UX
const UxComponents = () => import("@/pages/UxComponents.vue");
const RegistrationAccountUX = () => import("@/pages/RegistrationAccountUX.vue");
const RegistrationStepsUX = () => import("@/pages/RegistrationStepsUX.vue");
const AddPersonFisicaMoralUX = () => import("@/pages/AddPersonFisicaMoralUX.vue");
const ShareholdersIdentificationUX = () => import("@/pages/ShareholdersIdentificationUX.vue");
const LoginUser = () => import("@/pages/LoginUser.vue");
const ContactsUx = () => import("@/pages/ContactsUX.vue");

import showUX from "@/middleware/showUX";

export default [
    {
        path: "",
        name: "uxcomponents",
        component: UxComponents,
        meta: {
            middleware: [showUX]
        },
    },
    {
        path: "registration-account-type",
        name: "RegistrationUX",
        component: RegistrationAccountUX,
        meta: {
            middleware: [showUX]
        },
    },
    {
        path: "registration-steps",
        name: "RegistrationUXSteps",
        component: RegistrationStepsUX,
        meta: {
            middleware: [showUX]
        },
    },
    {
        path: "addperson-fisica-moral",
        name: "AddPersonFisicaMoralUX",
        component: AddPersonFisicaMoralUX,
        meta: {
            middleware: [showUX]
        },
    },
    {
        path: "shareholders-identification",
        name: "ShareholdersIdentificationUX",
        component: ShareholdersIdentificationUX,
        meta: {
            middleware: [showUX]
        },
    },
    {
        path: "loginUx",
        name: "LoginUserUx",
        component: LoginUser,
        meta: {
            middleware: [showUX]
        },
    },
    {
        path: "contactsUX",
        name: "ContactsUx",
        component: ContactsUx,
        meta: {
            middleware: [showUX]
        },
    },
]
