<template>
  <div>
    <b-modal
      :id="modalId"
      size="xs"
      hide-header
      hide-footer
      modal-class="modal-default"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide(modalId)"
        :aria-label="$t('transfers.authorize.close')"
        :name="$t('transfers.authorize.close')"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{$t('transfers.authorize.title')}}</h2>
      </div>
      <div class="body">
        <div class="vertical-center justify-content-center column-gap-50px row-gap-10px m-b-30">
          <RadioInput
            clazz="m-b-0"
            idInput="accept"
            :text="$t('transfers.authorize.accept')"
            @selected="select('accept')"
          ></RadioInput>
          <RadioInput
            clazz="m-b-0"
            idInput="deny"
            :text="$t('transfers.authorize.reject')"
            @selected="select('reject')"
          ></RadioInput>
        </div>
        <BasicInput
          required
          inputId=""
          v-model="rejectReason"
          v-if="action === 'reject'"
          :label="$t('transfers.authorize.rejectReason')"
          :inputPlaceholder="$t('transfers.authorize.rejectReasonPlaceholder')"
        >
        </BasicInput>
        <div class="transfer-account-info">
          <div class="item">
            <div class="item-container">
              <div class="name f-24 m-t-0 m-b-10 poppins-font-bold f-700">
                {{transfer.amount | currency}}
              </div>
              <div class="account">{{$t('transfers.general.folio')}}: {{transfer.folio}}</div>
              <div class="number">{{contact.contactName}}</div>
              <div class="account">{{contact.bank}}</div>
              <div class="number">{{contact.number}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button type="button" class="button button-stroke button-primary" @click="$bvModal.hide(modalId)">
          {{$t('transfers.authorize.cancel')}}
        </button>
        <button
          type="button"
          class="button button-filled button-primary"
          :disabled="!validForm"
          @click="confirm"
        >
          {{$t('transfers.authorize.confirm')}}
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import RadioInput from "@/components/RadioInput.vue";
import BasicInput from "@/components/BasicInput.vue";
import CardApi from '@/api/wallet/card.api';
import {EventBus} from "@/main";

export default {
  name: 'ModalAuthorizeTransfer',
  components: {
    BasicInput,
    RadioInput,
  },
  data() {
    return {
      modalId: 'modal-transfer',
      transfer: {},
      contact: {},
      action: null, // 'accept', 'reject'
      rejectReason: null,
    };
  },
  computed: {
    validForm() {
      return this.action === 'accept' || (this.action === 'reject' && this.rejectReason);
    },
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      if (this.modalId === modalId) {
        this.clearModal();
      }
    });
  },
  methods: {
    open(transfer) {
      this.transfer = transfer;
      this.contact = transfer.contact;
      this.$bvModal.show(this.modalId);
    },
    select(action) {
      this.action = action;
      if(action === 'accept') {
        this.rejectReason = null;
      }
    },
    clearModal() {
      this.transfer = {};
      this.contact = {};
      this.action = null;
      this.rejectReason = null;
    },
    async confirm() {
      const folio = this.transfer.folio;
      const rejectReason = this.rejectReason;
      const action = this.action;
      this.$bvModal.hide(this.modalId);
      try {
        if(await this.$requestAuth()) {
          this.setLoading();
          if(action === 'accept') {
            const response = await CardApi.authorizeTransfer({folio});
            this.stopLoading();
            this.$router.push({
              name: 'NewTransfer',
              params: {
                showSuccess: true,
                transfer: response.data.object,
              }
            })
          } else {
            await CardApi.rejectTransfer({folio, rejectReason});
            this.stopLoading();
            this.$emit('rejected');
          }
        }
      } catch (error) {
        this.stopLoading();
        this.onError(error);
      }
    },
    setLoading() {
      EventBus.$emit('TransfersList.onAuthorize', {
        loading: true,
        loadingMessage: 'Autorización en proceso...'
      });
    },
    stopLoading() {
      EventBus.$emit('TransfersList.onAuthorize', {
        loading: false,
      });
    },
    onError(error) {
      this.$emit('error', error);
    },
  }
}
</script>
