// PAGES
const newAccount = () => import('@/pages/onboarding/collaboratorRightSideSteps/newAccount')
import Nationality from "@/pages/onboarding/rightSideSteps/Nationality";
import PersonalData from "@/pages/onboarding/rightSideSteps/PersonalData";
import Occupation from "@/pages/onboarding/rightSideSteps/Occupation";
import PhoneVerification from "@/pages/onboarding/rightSideSteps/PhoneVerification";
import CodeVerification from "@/pages/onboarding/rightSideSteps/CodeVerification";
import SuccessPhone from "@/pages/onboarding/rightSideSteps/SuccessPhone";
import SecurityMeasures from "@/pages/onboarding/rightSideSteps/SecurityMeasures";
import NewNip from "@/pages/onboarding/rightSideSteps/NewNip";
import ConfirmPin from "@/pages/onboarding/rightSideSteps/ConfirmPin";
import ProcessingRequest from "@/pages/onboarding/rightSideSteps/ProcessingRequest";
import SuccessRequest from "@/pages/onboarding/rightSideSteps/SuccessRequest";
import OnbngWelcome from "@/pages/onboarding/rightSideSteps/OnbngWelcome";
import SelectDocument from "@/pages/onboarding/rightSideSteps/SelectDocument";
import UploadDocument from "@/pages/onboarding/rightSideSteps/UploadDocument";
import Residence from "@/pages/onboarding/rightSideSteps/Residence";
const addInfo = () => import('@/pages/onboarding/rightSideSteps/AddInfo');

// MIDDLEWARE
import hasNotUserSession from "@/middleware/hasNotUserSession";
import hasUserSession from "@/middleware/hasUserSession";
import isNotWalletUser from "@/middleware/isNotWalletUser";
import isWalletUser from "@/middleware/isWalletUser";

export default [
    {
        path: 'register/:token',
        name: "registerCollaborator",
        component: newAccount,
        meta: {
            middleware: [hasNotUserSession]
        },
    },
    {
        path: 'nationality',
        name: "nationalityCollaborator",
        component: Nationality,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'select-documents',
        name: "selectDocumentsCollaborator",
        component: SelectDocument,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'upload-documents/:id',
        name: "uploadDocumentsCollaborator",
        component: UploadDocument,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'personal-info',
        name: "personalInfoCollaborator",
        component: PersonalData,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'residence',
        name: "residenceCollaborator",
        component: Residence,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'occupation',
        name: "occupationCollaborator",
        component: Occupation,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'add-info',
        name: "addInfoCollaborator",
        component: addInfo,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'phone',
        name: "phoneVerificationCollaborator",
        component: PhoneVerification,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'phone-code',
        name: "codeVerificationCollaborator",
        component: CodeVerification,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'success-code',
        name: "successCodeCollaborator",
        component: SuccessPhone,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'security-measures',
        name: "securityMeasuresCollaborator",
        component: SecurityMeasures,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'new-pin',
        name: "newPinCollaborator",
        component: NewNip,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'confirm-pin',
        name: "confirmPinCollaborator",
        component: ConfirmPin,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'processing-request',
        name: "processingRequestCollaborator",
        component: ProcessingRequest,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'response-request',
        name: "successRequestCollaborator",
        component: SuccessRequest,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'welcome',
        name: "onbngWelcomeCollaborator",
        component: OnbngWelcome,
        meta: {
            middleware: [hasUserSession, isWalletUser]
        },
    },
];
