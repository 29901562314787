/*
    A ref Parameter must be passed
    Although this doesn't offer dynamism to have 2 cameras/videos at the same time (which can be implemented later),
    It is not hardcoded just to make the developer aware that they must set it on the component call as well
 */
export default function (ref) {
    return {
        created: function () {
        },
        methods: {
            initCamera(callback) {
                this.$refs[ref].initCamera(callback);
            },
            stopCamera(callback) {
                this.$refs[ref].stopCamera(callback);
            },
            playCamera(callback) {
                this.$refs[ref].playCamera(callback);
            },
            replayCamera() {
                this.$refs[ref].replay();
            },
            startVideoCapture(callback) {
                this.$refs[ref].startVideoCapture(callback);
            },
            takePicture(callback) {
                this.$refs[ref].takePicture(callback);
            },
            processPicture(callback) {
                this.$refs[ref].processPicture(callback);
            },
            playVideoCapture(callback) {
                this.$refs[ref].playVideoCapture(callback);
            },
            pauseVideoCapture(callback){
                return this.$refs[ref].pauseVideoCapture(callback)
            },
            stopVideoCapture(callback) {
                this.$refs[ref].stopVideoCapture(callback);
            },
            nextVideoSource(callback, index) {
                this.$refs[ref].nextVideoSource(callback, index);
            },
            getVideoSources() {
                return this.$refs[ref].getVideoSources();
            }
        }
    }
}
