<template>
  <div class="row">
    <div class="col-12 m-b-20" v-for="question in currentStep.questions" :key="question._id">
      <label class="f-16 c-title poppins-font-semibold d-block m-b-5"
             v-if="!(TYPE_DATA.DOCUMENT === question.type || TYPE_DATA.IMAGES === question.type)">
        {{ question.title }} <span v-if="question.isRequired" class="c-error">*</span>
      </label>
      <div class="vertical-center column-gap-50px">
        <!-- BOOLEAN  -->
        <RadioInput
            v-if="TYPE_DATA.BOOLEAN === question.type"
            :name="`group${question._id}`"
            :refInput="`${question._id}_rid_yes`"
            :idInput="`${question._id}_rid_yes`"
            :text="$t('register.addInfo.yes')"
            findCurrentValue
            @search-current-value="searchCurrent"
            @selected="setPropertyValue(question._id, question._id, true)"
        ></RadioInput>
        <RadioInput
            v-if="TYPE_DATA.BOOLEAN === question.type"
            :name="`group${question._id}`"
            :refInput="`${question._id}_rid_no`"
            :idInput="`${question._id}_rid_no`"
            :text="$t('register.addInfo.no')"
            findCurrentValue
            @search-current-value="searchCurrent"
            @selected="setPropertyValue(question._id, question._id, false)"
        ></RadioInput>
        <!-- BOOLEAN  -->
        <!-- BOOLEAN EMBEBED -->
        <div class="m-t-15 col-12 row"
             v-if="question.hasDependentQuestions && showDependenQuestions(question._id, question.dependentQuestionsAppearsWhenAnswerIs)">
          <DynamicEmbebed :current-step="question" :onbngData="onbngData" :temp-model="tempModel"></DynamicEmbebed>
        </div>
        <!-- BOOLEAN EMBEBED -->
        <!-- TEXT  -->
        <BasicInput
            v-if="TYPE_DATA.TEXT === question.type"
            v-model="tempModel[question._id]"
            :idInput="`${question._id}_text`"
            inputType="text"
            :inputPlaceholder="question.title"
            clazz="noLabel"
            @input="setPropertyValue(question._id, question._id, $event)"
        />
        <!-- TEXT  -->
        <!-- ENUM  -->
        <BasicSelect v-if="TYPE_DATA.ENUM === question.type">
          <multiselect
              v-model="tempModel[question._id]"
              :options="question.enumOptions"
              :close-on-select="true"
              :preserve-search="true"
              :placeholder="question.title"
              :preselect-first="false"
              @input="setPropertyValue(question._id, question._id, $event)"
          >
            <template slot="selection" slot-scope="{ values, isOpen }">
              <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} options selected</span
              >
            </template>
          </multiselect>
        </BasicSelect>
        <!-- ENUM  -->
        <!--DOCUMENT IMAGES -->
        <div class="col-12" v-if="TYPE_DATA.DOCUMENT === question.type || TYPE_DATA.IMAGES === question.type">
          <CardSelection :title="question.title"
                         :subtitle="question.title"
                         :clazz="`border-card ${tempModel[question._id] ? tempModel[question._id].clazz : 'icon-bgm-info_text' }`">
            <template v-slot:icon>
              <i aria-hidden="true" class="banana banana-document-normal"></i>
            </template>
            <template v-slot:extraMiddleContent>
              <b-progress
                  :max="maxProgressBar"
                  class="bar-progress percent-color"
                  :class="{'success' :  getCurrentProgress(question._id) === 100 }"
              >
                <b-progress-bar :value="getCurrentProgress(question._id)">
                  <div class="label-progress">
                    <span>{{ getCurrentProgress(question._id) }}%</span>
                  </div>
                </b-progress-bar>
              </b-progress>
            </template>
            <template v-slot:extraFinalContent>
              <button type="button" class="button xs button-filled button-accent1">
                Subir
                <input type="file" class="input-file" value="" @change="onFilePicked($event ,question)" name="" />
              </button>
            </template>
          </CardSelection>
        </div>
        <!--DOCUMENT IMAGES -->
      </div>
    </div>
  </div>
</template>

<script>
import RadioInput from "@/components/RadioInput";
import BasicInput from "@/components/BasicInput";
import CardSelection from "@/components/CardSelection";
import {isDefined, stringToBoolean, isObjectEmpty} from "@/utils/utils";
import DynamicEmbebed from "@/components/extraInfo/DynamicEmbebed";
import {TYPE_DATA} from "@/enums/onbngDynamicData.enum";
import BasicSelect from "@/components/BasicSelect";
import Multiselect from "vue-multiselect";
import {EventBus} from "@/main";
import ToastSnotify from "@/mixins/toastSnotify.mixin";


const NORMAL_DOC = "icon-bgm-info_text";
const UPLOAD_DOC = "icon-bgm-accent2";
const ERROR_DOC = "icon-bgm-error";
const SUCCESS_DOC = "icon-bgm-success";

export default {
  name: "DynamicOnboarding",
  mixins: [ToastSnotify],
  data() {
    return {
      TYPE_DATA: TYPE_DATA,
      maxProgressBar: 100,
    }
  },
  computed: {},
  components: {
    DynamicEmbebed,
    RadioInput,
    BasicInput,
    BasicSelect,
    Multiselect,
    CardSelection
  },
  props: {
    currentStep: Object,
    onbngData: Object,
    tempModel: Object
  },
  methods: {
    setPropertyValue(property, id, value) {
      if (this.onbngData[property]) {
        this.onbngData[property].data = value;
      } else {
        this.onbngData[property] = {
          idQuestion: id,
          data: value
        }
      }
      this.tempModel[property] = value;
      this.addToParent();
      this.$forceUpdate();
    },
    showDependenQuestions(property, value) {
      const booleanValue = stringToBoolean(value);
      if (this.onbngData[property]) {
        return this.onbngData[property].data === booleanValue;
      }
      return false;
    },
    getCurrentProgress(id) {
      if (this.tempModel[id]) {
          return this.tempModel[id].progress || 0;
      }
      return 0;
    },
    setCurrentProgress(id, value, clazz = UPLOAD_DOC) {
      if (this.tempModel[id]) {
        this.tempModel[id].progress = value;
        this.tempModel[id].clazz = clazz;
          this.$forceUpdate();
      }
    },
    onFilePicked(event, question) {
      if (event.target.files.length) {
        this.tempModel[question._id] = {
          clazz: NORMAL_DOC,
          progress: 0
        };
        const file = event.target.files[0];
        if (question.documentToUpload) {
          this.setCurrentProgress(question._id, 20);
          if (this._isValidFormat(question.documentToUpload, file.type)) {
            this.setCurrentProgress(question._id, 40);
            const fileReader = new FileReader();
            fileReader.addEventListener("load", () => {
              this.setCurrentProgress(question._id, 60);
              this._addFile(file, fileReader.result, question);
            });
            fileReader.readAsDataURL(file);
          } else {
            this.setCurrentProgress(question._id, 0, ERROR_DOC);
            this.displayNotificationWarning(this.$i18n.t("register.addInfo.error.formatDocumentType", {
              title: question.title,
              formats: question.documentToUpload.allowedFormats.join(",")
            }))
          }
        } else {
          this.setCurrentProgress(question._id, 0, ERROR_DOC);
          this.displayNotificationWarning(this.$i18n.t("register.addInfo.error.formatDocumentConfig"))
        }
      } else {
        this.setCurrentProgress(question._id, 0);
      }
    },
    _addFile(file, raw, doc) {
      this.setCurrentProgress(doc._id, 80);
      const newFile = {
        title: `${doc.documentToUpload.title}`,
        key: `${doc.documentToUpload.title}`,
        fileDocument: doc.documentToUpload._id,
        isRequired: doc.documentToUpload.isRequired,
        name: file.name || doc.documentToUpload.title,
        type: file.type,
        size: file.size,
        data: raw.split(',')[1],
      };
      this.setCurrentProgress(doc._id, 100, SUCCESS_DOC);
      this.tempModel[doc._id].value = newFile;
      this.onbngData[doc._id] = {
        idQuestion: doc._id,
        data: newFile
      }
    },
    _isValidFormat(doc, type) {
      const formats = [...doc.allowedFormats];
      if (formats.includes("jpg")) {
        formats.push("jpeg");
      }
      for (const format of formats) {
        if (type.match(format)) {
          return true;
        }
      }
      return false;
    },
    searchCurrent(id) {
      let realId = id.split("_rid")
      if (realId.length) {
        realId = realId[0];
        if (isDefined(this.tempModel[realId])) {
          if (this.tempModel[realId]) {
            EventBus.$emit(`radio-${realId}_rid_yes`, true);
          } else {
            EventBus.$emit(`radio-${realId}_rid_no`, true);
          }
          this.$forceUpdate();
        }
      }
    },
    addToParent() {
      // TEMP function just to see changes on parent
      this.$emit('update-parent')
    }
  },
  mounted() {
    if (isObjectEmpty(this.tempModel)) {
        setTimeout(() => {
          this.$forceUpdate();
        }, 5000)
    }
  }
}
</script>

<style scoped>

</style>
