import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'shareholders';

export default {
    find: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/find/${params.id}`)
    },
    save: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/save`, params)
    },
    loadTree: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-organization-tree`, params)
    },
    delete: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/delete`, params)
    },
    setDigitalSign: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/set-digital-sign`, params)
    },
    saveLegal: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-legal-data`, params)
    }
}
