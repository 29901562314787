<template>
<div>
  <div v-if="showEmail">
    <div class="p-relative d-block w-100 m-b-10">
      <router-link to="/login"
                   class="button xs button-ghost button-info p-l-5 p-r-5 text-capit"
                   tabindex="">
        <i
            aria-hidden="true"
            class="banana banana-arrow-left m-r-10">
        </i>
        {{ $t('login.recoverPass.back') }}
      </router-link>
    </div>
    <TitlePrincipal
        :title="$t('login.recoverPass.title')"
        :subtitle="$t('login.recoverPass.subtitle')">
    </TitlePrincipal>
    <BasicInput
        v-model="email"
        :validations="$v.email"
        clazz="m-b-0"
        required
        inputId="user_email"
        label="Correo electrónico"
        inputPlaceholder="Ej: pablo@mail.com">
    </BasicInput>
    <div class="horizontal-center m-t-15 m-b-15">
      <vue-recaptcha
          :sitekey="siteKey"
          type="checkbox"
          ref="recaptcha"
          @verify="onVerify"
          @expired="onExpired"
      ></vue-recaptcha>
    </div>
    <button v-if="showRestore" type="button" class="button button-filled button-primary w-100" :disabled="disableButton" @click="restore">
      {{ $t('login.recoverPass.restore') }}
    </button>
    <button v-else type="button" class="button button-filled button-primary w-100">
      <LoadingSimple clazz="small-load no-text" />
    </button>
    <span class="notification-action">
      <label>
          {{ $t('login.recoverPass.iRemember') }}
          <router-link to="/login" class="hover" tabindex="">{{ $t('login.recoverPass.login') }}</router-link>
      </label>
    </span>
  </div>
  <div v-else>
    <div class="p-relative d-block w-100 m-b-10">
      <router-link to="/login"
                   class="button xs button-ghost button-info p-l-5 p-r-5 text-capit"
                   tabindex="">
        <i
            aria-hidden="true"
            class="banana banana-arrow-left m-r-10">
        </i>
        {{ $t('login.recoverPass.back') }}
      </router-link>
    </div>
    <TitlePrincipal
        :title="$t('login.recoverPass.title2')">
      <p v-html="$t('login.recoverPass.subtitle2', {email: email})"></p>
    </TitlePrincipal>
    <button v-if="showRestore" type="button" class="button button-stroke button-primary w-100" @click="restore">
      {{$t('login.recoverPass.resend')}}
    </button>
    <button v-else type="button" class="button button-stroke button-primary w-100">
      <LoadingSimple clazz="small-load no-text" />
    </button>
    <p class="f-12 c-info_text poppins-font-semibold f-600 text-align-c m-t-20 m-b-20" v-html="$t('login.recoverPass.resendFooter')"></p>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import BasicInput from "@/components/BasicInput";
import LoadingSimple from "@/components/LoadingSimple";
import {helpers, required} from "vuelidate/lib/validators";
import {regexEmail} from "@/utils/regexUtils";
const emailValidator = helpers.regex("rfcValidator", regexEmail);
import VueRecaptcha from "vue-recaptcha";
import config from "@/config";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import UserApi from "@/api/users/users.api";
import {catchError} from "@/utils/utils";
const publicKey = config.googleRecapcha.publicKey;

export default {
  name: "RecoverPass",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicInput,
    LoadingSimple,
    VueRecaptcha
  },
  computed: {
    disableButton() {
      return this.$v.$invalid || !this.token;
    }
  },
  data() {
    return {
      showEmail: true,
      showRestore: true,
      email: "",
      token: false,
      expired: false,
      siteKey: publicKey,
      loaded: false,
    }
  },
  methods: {
    async restore() {
      this.showRestore = false;
      const res = await UserApi.recoverPass({
        token: this.token,
        email: this.email,
        platform: "WALLET",
        fromWeb: true,
      }).catch(catchError);
      if (res && res.data) {
          if (res.data.error) {
              const message = res.data.message || this.$i18n.t('login.recoverPass.error.email');
              this.displayNotificationError(message);
          } else {
            this.showEmail = false;
          }
      } else {
        this.displayNotificationError(this.$i18n.t('login.recoverPass.error.email'));
      }
      this.showRestore = true;
    },
    onVerify(response) {
      this.token = response;
      this.expired = false;
    },
    onExpired() {
      this.showEmail = true;
      this.expired = true;
      this.token = false;
      this.displayNotificationError(this.$i18n.t('login.recoverPass.error.recaptcha'));
    },
  },
  created() {
    const s = document.createElement('script');
    s.setAttribute( 'src', "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" );
    s.onload = () => {
      this.loaded = true;
    }
    document.head.appendChild(s);
  },
  validations: {
    email: {
      required,
      emailValidator
    }
  }
}
</script>

<style scoped>

</style>
