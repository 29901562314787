export const STORE = 'ONBOARDING_STEPS';
export const SET_TYPE_ACCOUNT = 'SET_TYPE_ACCOUNT';
export const SET_TYPE_ACCOUNT_BUSINESS = 'SET_TYPE_ACCOUNT_BUSINESS';
export const GET_TYPE_ACCOUNT = 'GET_TYPE_ACCOUNT';
export const SET_CURRENT_PCT = 'SET_CURRENT_PCT';
export const GET_CURRENT_PCT = 'GET_CURRENT_PCT';
export const SET_CURRENT_STEPS = 'SET_CURRENT_STEPS';
export const SET_LEVEL = 'SET_LEVEL';
export const SET_AUX_LEVEL = 'SET_AUX_LEVEL';
export const SET_LOCATION = 'SET_LOCATION';
export const GET_LOCATION = 'GET_LOCATION';
export const GET_CURRENT_STEPS = 'GET_CURRENT_STEPS';
// export const GET_PROVISIONAL = 'GET_PROVISIONAL';
export const SET_SELECT_ACCOUNT = 'SET_SELECT_ACCOUNT';
export const GET_SELECT_ACCOUNT = 'GET_SELECT_ACCOUNT';
export const SET_CARD_REQUEST = 'SET_CARD_REQUEST';
export const SET_ERROR_REQUEST_CARD = 'SET_ERROR_REQUEST_CARD';
export const GET_ERROR_CARD_REQUEST = 'GET_ERROR_CARD_REQUEST';
export const SET_OBJECT_ERROR_REQUEST_CARD = 'SET_OBJECT_ERROR_REQUEST_CARD';
export const GET_OBJECT_ERROR_REQUEST_CARD = 'GET_OBJECT_ERROR_REQUEST_CARD';
export const GET_CARD_REQUEST = 'GET_CARD_REQUEST';
export const LOAD_CARD_REQUEST = 'LOAD_CARD_REQUEST';
export const GET_ACCOUNT_TYPE = 'GET_ACCOUNT_TYPE';
export const GET_ACCOUNT_TYPE_BUSINESS = 'GET_ACCOUNT_TYPE_BUSINESS';
export const GET_LEVEL = 'GET_LEVEL';
export const GET_AUX_LEVEL = 'GET_AUX_LEVEL';
export const SET_GROUP_FILES = 'SET_GROUP_FILES';
export const GET_GROUP_FILES = 'GET_GROUP_FILES';
export const LOAD_GROUP_FILES = 'LOAD_GROUP_FILES';
export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const GET_DOCUMENTS = 'GET_DOCUMENTS';
export const SET_GIROS = 'SET_GIROS';
export const GET_GIROS = 'GET_GIROS';
export const LOAD_GIROS = 'LOAD_GIROS';
export const SET_COUNTRIES = 'SET_COUNTRIES';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const GO_TO_ROUTE = 'GO_TO_ROUTE';
export const SET_GO_TO_WAIT = 'SET_GO_TO_WAIT';
export const GET_GO_TO_WAIT = 'GET_GO_TO_WAIT';
export const SET_CONTRACT_INDEX = 'SET_CONTRACT_INDEX';
export const GET_CONTRACT_INDEX = 'GET_CONTRACT_INDEX';
export const SET_CREATE_NEW_ORG = 'SET_CREATE_NEW_ORG';
export const GET_CREATE_NEW_ORG = 'GET_CREATE_NEW_ORG';
export const SET_POLICIES_ACCEPTED = 'SET_POLICIES_ACCEPTED';
export const GET_POLICIES_ACCEPTED = 'GET_POLICIES_ACCEPTED';
export const SET_SKIPPED_DYNAMIC = 'SET_SKIPPED_DYNAMIC';
export const GET_SKIPPED_DYNAMIC = 'GET_SKIPPED_DYNAMIC';
export const SET_ONB_DYNAMIC_COLLAB = 'SET_ONB_DYNAMIC_COLLAB';
export const GET_ONB_DYNAMIC_COLLAB = 'GET_ONB_DYNAMIC_COLLAB';
