<template>
  <div>
    <TitlePrincipal :title="$t('register.phoneVerification.title')">
      <p class="subtitle" v-html="$t('register.phoneVerification.subtitle', {phone: phone})"></p>
    </TitlePrincipal>
    <TelInput
        v-model="phone"
        allCountries
        required
        :value="phone"
        idInput="user_phone"
        :label="$t('register.phoneVerification.selectPhone')"
        :inputPlaceholder="$t('register.phoneVerification.selectPhonePh')"
    />
    <div class="footer-buttons right">
      <button @click="back()" type="button" class="button button-ghost button-plain_text" name="Regresar">
        {{ $t('register.phoneVerification.back') }}
      </button>
      <button v-if="!loadingButton" type="button" class="button button-filled button-primary" name="Continuar"
              :disabled="disableButton" @click="submitPhone">
        {{ $t('register.phoneVerification.sendCode') }}
      </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text"/>
      </button>
    </div>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import TelInput from "@/components/TelInput";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import LoadingSimple from "@/components/LoadingSimple";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import UserApi from "@/api/users/users.api";
import {catchError} from "@/utils/utils";
import {GET_LEVEL, GET_CARD_REQUEST, STORE as onbngStore} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import {
  GET_FULL_DATA_USER,
  LOAD_FULL_DATA_USER,
  STORE as sessionStore
} from "@/store/modules/session/session.types";
import {mapGetters} from 'vuex';
import {EventBus} from "@/main";

export default {
  name: "PhoneVerification",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    TelInput,
    LoadingSimple
  },
  computed: {
    ...mapGetters(sessionStore, {
      fullDataUser: GET_FULL_DATA_USER
    }),
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      cardRequest: GET_CARD_REQUEST
    }),
    disableButton() {
      return this.$v.$invalid;
    }
  },
  data() {
    return {
      loadingButton: false,
      phone: ""
    }
  },
  methods: {
    back() {
      if(this.level === 0) {
        this.$store.dispatch(`${onbngStore}/backCollaborator`)
      } else if (this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${onbngStore}/backBusiness`)
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`)
      }
    },
    async submitPhone() {
      this.loadingButton = true;
      // ${this.phone.replace(/\s/g,'')}
      const response = await UserApi.sendCode({
        contactPhone: this.phone,
        phoneVerified: false,
      }).catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          let message = 'register.phoneVerification.errorSave';
          if (response.data.err && response.data.err.customError) {
            message = response.data.err.userMessage;
          }
          this.displayNotificationError(this.$i18n.t(message))
        } else if(this.level === 0) {
          // NO USAR función STORE GO_TO_ROUTE
          this.$router.push({name: 'codeVerificationCollaborator'});
        } else {
          this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.CODE_VERIFICATION,
          });
          let goTo = "codeVerification"
          if (this.cardRequest.accountType === 'BUSINESS') {
            goTo = "businessCodeVerification"
          }
          // NO USAR función STORE GO_TO_ROUTE
          this.$router.push({name: goTo});
        }
      }
      this.loadingButton = false;
    },
    assignData() {
      if (this.fullDataUser) {
        // ADD FORMAT WITH SPACE
        this.phone = this.fullDataUser.phone;
      }
    }
  },
  validations: {
    phone: {
      required,
      minLength: minLength(13),
      maxLength: maxLength(16),
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
    this.assignData();
    EventBus.$on(`${sessionStore}.${LOAD_FULL_DATA_USER}`, () => {
      this.assignData();
    });
  }
}
</script>

<style scoped>

</style>
