// PAGES
const DispersionsList = () => import("@/pages/Dispersions/DispersionsList");
const DispersionsNew = () => import("@/pages/Dispersions/DispersionsNew");
const DispersionsDetail = () => import("@/pages/Dispersions/DispersionsDetail");
const DispersionsConflicts = () => import("@/pages/Dispersions/DispersionsConflicts");

import isWalletUser from "@/middleware/isWalletUser";
import hasPermissionTo from "@/middleware/hasPermission";

export default [
    {
        path: '',
        redirect: 'DispersionsList'
    },
    {
        path: 'list',
        name: 'DispersionsList',
        meta: {
                middleware: [
                    isWalletUser,
                    hasPermissionTo('READ', 'DISPERSIONS', {only: ['CLIENTS_ACCOUNT']})
                ]
            },
        component: DispersionsList
    },
    {
        path: "new",
        name: "DispersionsNew",
        meta: {
                middleware: [
                    isWalletUser,
                    hasPermissionTo('REQUEST', 'DISPERSIONS', {only: ['CLIENTS_ACCOUNT']})
                ]
            },
        component: DispersionsNew
    },
    {
        path: "detail/:folio",
        name: "DispersionsDetail",
        meta: {
                middleware: [
                    isWalletUser,
                    hasPermissionTo('READ', 'DISPERSIONS', {only: ['CLIENTS_ACCOUNT']})
                ]
            },
        component: DispersionsDetail
    },
    {
        path: "conflicts/:folio",
        name: "DispersionsConflicts",
        meta: {
                middleware: [
                    isWalletUser,
                    hasPermissionTo('READ', 'DISPERSIONS', {only: ['CLIENTS_ACCOUNT']})
                ]
            },
        component: DispersionsConflicts
    },
];
