<template>
  <div class="col-12 col-md-12">
    <div v-for="question in currentStep.dependentQuestions" :key="question._id">
      <label class="f-14 c-title body-font-regular d-block m-b-5"
             v-if="!(TYPE_DATA.DOCUMENT === question.type || TYPE_DATA.IMAGES === question.type)">
        {{question.title}}<span v-if="question.isRequired" class="c-error">*</span>
      </label>
      <!-- BOOLEAN  -->
      <RadioInput
          v-if="TYPE_DATA.BOOLEAN === question.type"
          :name="`group${question.fieldLinkedToUser}`"
          :refInput="`${question.fieldLinkedToUser}_rid_yes`"
          :idInput="`${question.fieldLinkedToUser}_rid_yes`"
          :text="$t('register.addInfo.yes')"
          findCurrentValue
          @search-current-value="searchCurrent"
          @selected="setPropertyValue(question.fieldLinkedToUser, question._id, true)"
      ></RadioInput>
      <RadioInput
          v-if="TYPE_DATA.BOOLEAN === question.type"
          :name="`group${question.fieldLinkedToUser}`"
          :refInput="`${question.fieldLinkedToUser}_rid_no`"
          :idInput="`${question.fieldLinkedToUser}_rid_no`"
          :text="$t('register.addInfo.no')"
          findCurrentValue
          @search-current-value="searchCurrent"
          @selected="setPropertyValue(question.fieldLinkedToUser, question._id, false)"
      ></RadioInput>
      <!-- BOOLEAN  -->
      <!-- TEXT  -->
      <BasicInput
          v-if="TYPE_DATA.TEXT === question.type"
          v-model="tempModel[question.fieldLinkedToUser]"
          :idInput="`${question.fieldLinkedToUser}_text`"
          inputType="text"
          :inputPlaceholder="question.title"
          clazz="noLabel"
          @input="setPropertyValue(question.fieldLinkedToUser, question._id, $event)"
      />
      <!-- TEXT  -->
      <!-- ENUM  -->
      <BasicSelect v-if="TYPE_DATA.ENUM === question.type">
        <multiselect
            v-model="tempModel[question.fieldLinkedToUser]"
            :options="question.enumOptions"
            :close-on-select="true"
            :preserve-search="true"
            :placeholder="question.title"
            :preselect-first="false"
            @input="setPropertyValue(question.fieldLinkedToUser, question._id, $event)"
        >
          <template slot="selection" slot-scope="{ values, isOpen }">
              <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} options selected</span
              >
          </template>
        </multiselect>
      </BasicSelect>
      <!-- ENUM  -->
    </div>
  </div>
</template>

<script>
import BasicInput from "@/components/BasicInput";
import RadioInput from "@/components/RadioInput";
import {TYPE_DATA} from "@/enums/onbngDynamicData.enum";
import BasicSelect from "@/components/BasicSelect";
import Multiselect from "vue-multiselect";
import {isDefined} from "@/utils/utils";
import {EventBus} from "@/main";

export default {
  name: "DynamicEmbebed",
  components: {
    BasicInput,
    RadioInput,
    BasicSelect,
    Multiselect
  },
  props: {
    currentStep: Object,
    onbngData: Object,
    tempModel: Object
  },
  data() {
    return {
      TYPE_DATA: TYPE_DATA,
      maxProgressBar: 100,
    }
  },
  methods: {
    setPropertyValue(property, id, value) {
      if (this.onbngData[property]) {
        this.onbngData[property].data = value;
      } else {
        this.onbngData[property] = {
          idQuestion: id,
          data: value
        }
      }
      this.$forceUpdate();
    },
    searchCurrent(id) {
      let realId = id.split("_rid")
      if (realId.length) {
        realId = realId[0];
        if (isDefined(this.tempModel[realId])) {
          if (this.tempModel[realId]) {
            EventBus.$emit(`radio-${realId}_rid_yes`, true);
          } else {
            EventBus.$emit(`radio-${realId}_rid_no`, true);
          }
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
