<template>
    <div :id="idForm" class="form-group" :class="`${clazz} ${errorClass}`">
        <component
            :is="tag"
            :id="inputId"
            :min="min"
            :maxlength="maxLength"
            :max="max"
            :type="inputType"
            v-if="!readOnly"
            class="form-control"
            :class="inputClazz"
            :placeholder="inputPlaceholder"
            :value="value || inputValue"
            aria-label=""
            @input="onBasicInput"
            @change="onChangeInput"
            v-on:keyup.enter="onKeyUpEnter"
            :disabled="disabled"
            :name="name"
            :mask="mask"
            :prefix="money ? '$' : ''"
        />

        <component
            :is="tag"
            :id="inputId"
            v-else
            readonly
            :type="inputType"
            class="form-control"
            :class="inputClazz"
            :placeholder="inputPlaceholder"
            :value="value || inputValue"
            aria-label=""
            @input="onBasicInput"
            :disabled="disabled"
            :name="name"
            :mask="mask"
            :prefix="money ? '$' : ''"
        />

        <button :id="extraInputId" class="extra-input-button" v-if="inputClazz" type="button" @click="onIconClick" aria-label="Search">
            <i aria-hidden="true" class="input-icon banana" :class="iconInput"></i>
        </button>

        <div class="word-counter" v-if="maxCount > 0"><small :class="{'c-error': countError }">{{remainingCount}}</small>
            / <small>{{maxCount}}</small>
        </div>

        <slot></slot>

        <div class="input-label">
            <label class="" :for="inputId">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i v-if="warningIcon" aria-hidden="true" :class="warningIcon"></i>
            {{warningText}}
        </small>
    </div>
</template>

<script>
    // TODO meter en utils
    const index = [];
    const debounceTime = 800;
    const debounce = function (func, ...params) {
        const id = func.toString() + JSON.stringify(params || []);
        if (!index[id]) {
            index[id] = true;
            setTimeout(function () {
                    index[id] = false
                }
                , debounceTime
            );
            return func(...params);
        }
            return null;
    };

    export default {
        name: 'BasicInput',
        props: {
            min: Number,
            max: Number,
            maxLength: Number,
            help: {type: Boolean, default: false},
            idHelpTooltip: String,
            required: {type: Boolean, default: false},
            label: String,
            helpText: String,
            warningText: String,
            inputPlaceholder: String,
            helpIcon: String,
            warningIcon: String,
            inputId: String,
            extraInputId: String,
            clazz: {type: String, default: ''},
            inputType: String,
            disabled: {type: Boolean, default: false},
            readOnly: {type: Boolean, default: false},
            inputValue: {type: String, default: null},
            value: {type: [String, Number] , default: null},
            hasBlur: {type: Boolean, default: false},
            inputClazz: String,
            iconInput: String,
            name: {
                type: String,
                default: ""
            },
            customRef: {
                type: String,
                default: "basicInput"
            },
            validations: {},
            validationsNamespace: {
                type: String,
                default: "general.validations"
            },
            idForm: String,
            hasError: {
                type: Boolean,
                default: false
            },
            mask: {
                type: [String, Array],
                default: null
            },
            money: {
                type: Boolean,
                default: false
            },
            maxCount: {type: Number, default: 0},
        },
        data() {
            return {
                internalValue: '',
            }
        },
        methods: {
            onBasicInput(event) {
                const eventValue = typeof event === 'string' || this.money ? event : event.target.value;
                if(this.maxCount > 0) {
                    this.internalValue = eventValue;
                }
                this.$emit('basicInput', eventValue);
                this.$emit('input', eventValue);
                if (this.validations) {
                    debounce(() => {
                        this.validations.$touch();
                    });
                }
            },
            onChangeInput(event) {
                const eventValue = typeof event === 'string' || this.money ? event : event.target.value;
                this.$emit('onChangeInput', eventValue);
            },
            onKeyUpEnter(event) {
                const eventValue = typeof event === 'string' || this.money ? event : event.target.value;
                this.$emit('onKeyUpEnter', eventValue);
            },
            onIconClick() {
                this.$emit('onIconClick')
            }
        },
        computed: {
            errorClass() {
                return this.hasError || (this.validations && this.validations.$error) ? 'has-error' : '';
            },
            tag() {
                if (this.mask) {
                    return 'the-mask';
                }
                if(this.money) {
                    return 'money';
                }
                return 'input';
            },
            remainingCount() {
                return this.maxCount - this.internalValue.length;
            },
            countError() {
                return this.remainingCount < 0;
            }
        }
    }
</script>

<style scoped>
/* Todo borrar*/
.has-error input{
  border-width: 2px !important;
}
</style>
