import LAKE_HOME from '@/assets/images/Backgrounds/Security/Casa.png';
import TOUCAN from '@/assets/images/Backgrounds/Security/Tucan.png';
import BLACK_CAR from '@/assets/images/Backgrounds/Security/Carro.png';
import COUP_GLASS from '@/assets/images/Backgrounds/Security/Margarita.png';
import SHOW_HOME from '@/assets/images/Backgrounds/Security/Edificio.png';
import NATURE_WATCH from '@/assets/images/Backgrounds/Security/Brujula.png';
import CASSET_GUARDIANS from '@/assets/images/Backgrounds/Security/Disco.png';
import RUBIK_CUBE from '@/assets/images/Backgrounds/Security/Cubo.png';
import BEAUTIFUL_DOG from '@/assets/images/Backgrounds/Security/Perro.png';


const securityImages = [
    {
        "id": "SHOW_HOME",
        "src": SHOW_HOME,
        "selected": false
    },
    {
        "id": "TOUCAN",
        "src": TOUCAN,
        "selected": false
    },
    {
        "id": "BLACK_CAR",
        "src": BLACK_CAR,
        "selected": false
    },
    {
        "id": "NATURE_WATCH",
        "src": NATURE_WATCH,
        "selected": false
    },
    {
        "id": "CASSET_GUARDIANS",
        "src": CASSET_GUARDIANS,
        "selected": false
    },
    {
        "id": "LAKE_HOME",
        "src": LAKE_HOME,
        "selected": false
    },
    {
        "id": "RUBIK_CUBE",
        "src": RUBIK_CUBE,
        "selected": false
    },
    {
        "id": "BEAUTIFUL_DOG",
        "src": BEAUTIFUL_DOG,
        "selected": false
    },
    {
        "id": "COUP_GLASS",
        "src": COUP_GLASS,
        "selected": false
    }
]

export {
    securityImages
}
