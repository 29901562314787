<template>
  <div>
     <div class="col-12" v-if="smsActive">
          <h5 class="f-18 c-title body-font-semibold m-b-5"> {{ $t('login.smsAuth.title') }}</h5>
          <p class="m-b-15 c-plain_text body-font-regular f-16">
            {{ $t('login.smsAuth.subtitle') }}
              <strong> {{userAux.phone}} </strong>.
          </p>
          <div class="m-b-50">
              <CodeInput containerClass="text-left" @verificationCode="onChangeCode"/>
          </div>
          <p class="m-b-20 c-info_text body-font-regular f-14">
            {{ $t('login.smsAuth.warning') }}
              <a href="#" class="c-accent_2 text-underline" @click="resendCode()">
            {{ $t('login.smsAuth.resend') }}
              </a>
          </p>
          <div class="d-flex justify-content-between m-b-40">
            <button
                id="btn-loginDefault"
                v-if="!isLoading"
                :class="{'button-disable':disabledVerify}"
                class="button button-filled button-primary w-100"
                :disabled="disabledVerify"
                @click.prevent="verifyCode"
            >{{ $t('login.smsAuth.verify') }}</button>
            <button v-else :id="'loading-sms-verification'" class="button button-filled button-primary w-100">
              <LoadingSimple clazz="small-load no-text" />
            </button>
          </div>
      </div>
      <div v-if="!smsActive">
        <div class="p-relative d-block w-100 m-b-10">
          <router-link to="/login" class="button xs button-ghost button-info p-l-5 p-r-5 text-capit"
                       tabindex="">
            <i aria-hidden="true" class="banana banana-arrow-left m-r-10"></i>
            {{ $t('login.security.back') }}
          </router-link>
        </div>
        <TitlePrincipal
            :subtitle="$t('login.security.subtitle')">
          <template v-slot:specialTitle>
            {{ $t('login.security.hi') }}
            <strong class="c-primary">{{user.name}}</strong>
          </template>
        </TitlePrincipal>
        <b-alert class="alert-note" :show="hasError" variant="danger">
            <p class="text-container">
                <i
                    aria-hidden="true"
                    class="icon banana banana-cross-circle2"
                    ></i>
                <span v-html="$t('login.security.textDanger')"></span>
            </p>
        </b-alert>
        <UploadProfileImage
            imgUploaded
            :imgUser="ImgProfileUser"
            showInfoUser
            clazz="horizontal-info sm user-verification-login"
            :userName="`“${user.securityPhrase || ''}”`"
        ></UploadProfileImage>

        <form class="w-100" v-on:submit.prevent>
          <PasswordInput
              required
              :clazz="`m-b-10 ${passError ? 'has-error' : ''}`"
              :warning="passError"
              :warningText="'La contraseña que has ingresado es incorrecta. Vuelve a intentarlo.'"
              :inputType="passInputType"
              inputId="user_psw"
              :label="$t('login.security.passLabel')"
              :inputPlaceholder="$t('login.security.passPh')"
              :hasLevelBar="false"
              v-on:passwordInput="onPasswordChangeRegister"
              v-on:passwordInputType="changeTypePasswordRegister"
              v-on:enterKey="doLogin"
          />
        </form>
        <span class="info-forgot">
          <label>{{$t('login.security.forgotPass')}}</label>
          <router-link to="/login/recover-pass" class="hover" tabindex="">{{$t('login.security.recoverHere')}}</router-link>
        </span>
        <button v-if="showLogin" type="button" class="button button-filled button-primary w-100"
                :disabled="disableButton" @click="doLogin">
          {{$t('login.security.login')}}
        </button>
        <button v-else :id="'Loading button'" class="button button-filled button-primary w-100">
          <LoadingSimple clazz="small-load no-text" />
        </button>
      </div>

    <ModalAlert
        modalId="modal-sessions"
        :title="$t('login.modalSessions.title', {qty: activeSessions})"
        :text="$t('login.modalSessions.text')"
        :buttonText="$t('general.accept')"
        buttonType="button-primary"
        closeCross
        closeEsc
        closeBackdrop
        noCancelButton
        withAutomaticLoad
        @submit="agreeCloseSessions"
    ></ModalAlert>
  </div>
</template>

<script>
import axios from "axios";
import TitlePrincipal from "@/components/TitlePrincipal";
import PasswordInput from "@/components/PasswordInput";
import ModalAlert from "@/components/ModalAlert";
import CodeInput from '@/components/CodeInput.vue';
import UploadProfileImage from "@/components/UploadProfileImage";
import LoadingSimple from "@/components/LoadingSimple";
import UserApi from "@/api/users/users.api";
import CardApi from "@/api/wallet/card.api";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'
import config from '@/config';
// import {stringToBoolean} from "@/utils/utils";

import {
  STORE as sessionStore,
  GET_USER, SET_USER,
  SET_TOKEN, SET_TOKEN_EXPIRE_DATE,
  SET_USER_HAS_UNIFIED_PIN,
  SET_USER_CVV_MIGRATION_STATUS,
} from "@/store/modules/session/session.types";
import {mapGetters} from "vuex";
import {securityImages} from "@/enums/securityImg";
import {required} from "vuelidate/lib/validators";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {catchError, isObjectEmpty} from "@/utils/utils";
import {getData} from "@/utils/initVue";
import {
  STORE as onbngStore,
  GET_CARD_REQUEST
} from "@/store/modules/onboardingSteps/onboardingSteps.types";

const maxAttempts = 6;
const errorLogin = 'login.security.error';

export default {
  name: "LoginSecurity",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    PasswordInput,
    UploadProfileImage,
    LoadingSimple,
    CodeInput,
    ModalAlert
  },
  computed: {
    ...mapGetters(sessionStore, {
      user: GET_USER
    }),
    ...mapGetters(onbngStore, {
      cardRequest: GET_CARD_REQUEST
    }),
    disableButton() {
      return this.$v.$invalid;
    }
  },
  data() {
    return {
      ImgProfileUser: "",
      password: "",
      hasError: false,
      passError: false,
      showLogin: true,
      passInputType: 'password',
      activeSessions: 0,
      userAux: null,
      token: null,
      visitorId: "",
      smsActive: false,
      disabledVerify: true,
      pin: "",
      isLoading: false
    }
  },
  methods: {
    assignData() {
      const image = securityImages.find((i) => i.id === this.user.securityImageId);
      if (image) {
          this.ImgProfileUser = image.src;
      }
    },
    changeTypePasswordRegister (isVisibleOn) {
      if (isVisibleOn) {
        this.passInputType = 'password';
      } else {
        this.passInputType = 'text';
      }
    },
    onPasswordChangeRegister(value) {
      this.passError = false;
      this.password = value;
    },
    processErrorLogin(response) {
      const user = response.data.data ? response.data.data.user : {};
      if (!isObjectEmpty(user) && user.validPass && !user.hasConfirmEmail) {
        this.$router.push({name: 'verifyEmail', params: {email: this.user.email}});
      }
      if (response.data.attempts >= maxAttempts) {
        this.hasError = true;
      }
      this.passError = true;
      this.displayNotificationError(this.$i18n.t(errorLogin))
    },
    async processSuccessLogin(response) {
      const user = response.data.data ? response.data.data.user : {};
      this.hasError = false;
      if(user.DifferentFingerPrint){
        this.userAux = user;
        this.token = response.data.data.token;
        this.$store.commit(`${sessionStore}/${SET_USER}`, this.userAux);
        const validToken = this.token.replace(/"/g, "");
        axios.defaults.headers.common.Authorization = `Bearer ${validToken}`;
        this.smsActive = true;
        return;
      }

      if (isObjectEmpty(user)) {
        this.displayNotificationError(this.$i18n.t(errorLogin));
        return;
      }

      this.userAux = user;
      this.token = response.data.data.token;
      this.$store.commit(`${sessionStore}/${SET_USER}`, this.userAux);
      const validToken = this.token.replace(/"/g, "");
      axios.defaults.headers.common.Authorization = `Bearer ${validToken}`;

      if (!user.hasNewRegisterFinish) {
        this.handleSession();
        const forBusiness = user.onbForBusiness;
        await getData(this.userAux);
        await this.getRouteByStep(forBusiness);
        return;
      }

      const resSession = await UserApi.checkActiveSessions().catch(catchError);
      if(resSession.data.object > 0) {
        this.activeSessions = resSession.data.object;
        this.$bvModal.show('modal-sessions');
        return;
      }

      this.handleSession();
      if(user.contract === 2 || user.contract === 4) {
        this.$router.push({name: "UpdateContract"});
      } else {
        await this.$store.dispatch(`${sessionStore}/loadAccount`);
        this.goToNextPage();
      }
    },

    /**
     * Lleva a la siguiente pantalla cuando el login fue exitoso.
     */
    async goToNextPage() {
      try {
        // Checa si el usuario ya hizo la unificación del pin. 📌
        const hasUnifiedPin = await CardApi.checkPinUnification().then((response) => response.data.object);
        this.$store.commit(`${sessionStore}/${SET_USER_HAS_UNIFIED_PIN}`, hasUnifiedPin);
        if (!hasUnifiedPin) {
          this.$router.push({name: "PinUnification"});
          return;
        }

        // Checa si el usuario ya hizo la migración a cvv dinámico. 🕑🔑
        const statusMigrationCVV = await CardApi.checkMigrationDynamicCVV().then((response) => response.data.object);
        this.$store.commit(`${sessionStore}/${SET_USER_CVV_MIGRATION_STATUS}`, statusMigrationCVV);
        if (['PENDING', 'IN_PROCESS'].includes(statusMigrationCVV)) {
          this.$router.push({name: "MigrationDynamicCVV"});
          return;
        }

        this.$router.push({name: "Dashboard"});
      } catch {
        // Si el request falla, no mostrar la pantalla de la unificación 😱
        this.$router.push({name: "Dashboard"});
      }
    },
    handleSession() {
        // this.$store.commit(`${sessionStore}/${SET_USER}`, this.user);
        localStorage.setItem('currentToken', JSON.stringify(this.token));
        this.$store.commit(`${sessionStore}/${SET_TOKEN}`, this.token.replace(/"/g, ""));

        const expireDate = Math.floor(Date.now() / 1000) + (60 * 60 * 24);
        this.$store.commit(`${sessionStore}/${SET_TOKEN_EXPIRE_DATE}`, expireDate);
    },
    agreeCloseSessions(){
      UserApi.disableAccess().then(async () => {
          this.handleSession();
          if(this.userAux.contract === 2 || this.userAux.contract === 4) {
            this.$router.push({name: "UpdateContract"});
          } else {
            await this.$store.dispatch(`${sessionStore}/loadAccount`);
            this.goToNextPage();
          }
      }).catch(error => this.toastErrorDefault(error));
    },
    async getRouteByStep(forBusiness) {
      if(this.cardRequest) {
        if(this.cardRequest.status !== 'DELIVERED' && this.cardRequest.cardType !== 'PHYSIC') {
          if(this.cardRequest.accountType === 'BUSINESS') {
            this.routesForBusiness();
          } else {
            this.routesForPersonal();
          }
        } else {
          const resSession = await UserApi.checkActiveSessions().catch(catchError);
          if(resSession.data.object > 0) {
              this.activeSessions = resSession.data.object;
              this.$bvModal.show('modal-sessions');
              return;
          }
          await this.$store.dispatch(`${sessionStore}/loadAccount`);
          this.goToNextPage();
        }
      } else if(this.userAux?.stepOnboardingCollaborator) {
        this.$store.dispatch(`${onbngStore}/getRouteByStep`);
      } else {
        if (forBusiness) {
          this.$router.push({name: "selectSchemaBusiness"});
        } else {
          this.$router.push({name: "selectAccount"});
        }
      }
    },
    routesForBusiness() {
      if(this.cardRequest.status === 'CANCELLED') {
        this.$router.push({name: "businessSuccessRequest"});
      } else {
        this.$store.dispatch(`${onbngStore}/getRouteByStep`);
      }
    },
    routesForPersonal() {
      if(this.cardRequest.status === 'CANCELLED') {
        this.$router.push({name: "successRequest"});
      } else {
        this.$store.dispatch(`${onbngStore}/getRouteByStep`);
      }
    },
    async getSeonId(){
        return window.PagandoW.getAFT()
    },
    async getVisitorId() {
      const fpPromise = FingerprintJS.load({ apiKey: config.fingerprintJs.key })
        // Get the visitor identifier when you need it.
      fpPromise
        .then(fp => fp.get())
        .then(result => {
          this.visitorId = result.visitorId
          return result.visitorId
      })
    },
    async verifyCode(){
      this.isLoading = true;
      const response = await UserApi.verifyCode({
        code: this.pin,
        user: this.user,
        idUser: this.user._id
      }).catch(catchError);
      if(response && response.data && !response.data.error) {
        // Aqui tiramos el verificado del device
        UserApi.registerDevice({
          fingerprint: this.user.idDevice,
          user: this.user._id,
        });
        if (this.user.hasNewRegisterFinish) {
            const resSession = await UserApi.checkActiveSessions().catch(catchError);
            if(resSession.data.object > 0) {
              this.activeSessions = resSession.data.object;
              this.$bvModal.show('modal-sessions');

            } else {
              this.isLoading = false;
              this.handleSession();
              await this.$store.dispatch(`${sessionStore}/loadAccount`);
              this.$router.push({name: "Dashboard"})
            }
          } else {
            this.handleSession();
            const forBusiness = this.user.onbForBusiness;
            await getData(this.user);
            await this.getRouteByStep(forBusiness);
            this.isLoading = false;
          }
      }else{
        this.toastErrorDefault(response.data);
        this.isLoading = false;
      }
    },
    onChangeCode(value) {
      if(value.length === 5){
        this.disabledVerify = false
      }else{
        this.disabledVerify = true
      }
      this.pin = value;
    },
    async resendCode() {
      const response = await UserApi.reSendCode().catch(catchError);
      if (response && response.data && response.data.error) {
        this.displayNotificationError(this.$i18n.t('register.phoneCode.errorCode'));
      }
    },
    async doLogin() {
      let seonID = "";
      this.showLogin = false;

      if (!this.disableButton) {
        // if (stringToBoolean(config.loginAntiFraud.active)) {
          seonID = await this.getSeonId()
        // }

        const response = await UserApi.login({
          email: this.user.email,
          webInfo: seonID,
          visitorId: this.visitorId,
          password: this.password,
          userType: "USER",
          platform: "WALLET"
        }).catch(catchError);
        if (response && response.data) {
            if (response.data.error) {
              this.processErrorLogin(response);
            } else {
              await this.processSuccessLogin(response);
            }
        } else {
          this.displayNotificationError(this.$i18n.t(errorLogin))
        }
      }
      this.showLogin = true;
    }
  },
  async beforeMount() {
    // if (stringToBoolean(config.fingerprintJs.active)) {
      await this.getVisitorId();
    // }

    if (this.user) {
        this.assignData();
    } else {
      this.$router.push({name: "loginBase"})
    }
  },
  validations: {
    password: {
      required
    }
  }
}
</script>

<style scoped>

</style>
