<template>
    <div
        id="dctSearchFilterBar"
        class="search-filter-bar"
        v-bind:class="clazz">
        <div class="search-container">
            <input
                id="txtSearchTable"
                class=""
                @input="searchTextChange"
                type="text"
                name=""
                v-model="searchText"
                value=""
                v-if="showInput"
                :placeholder="$t(placeholder)"/>

                 <button id="extraInputId" class="extra-input-button" type="button" @click="onIconClick" aria-label="Search">
                    <i aria-hidden="true" class="input-icon banana banana-search-normal" ></i>
                </button>
        </div>

        <div class="filter-container">

            <button
                class="button sm button-filled button-primary m-l-10" @click="onClickButton"
                v-if="canCreate">
                <i aria-hidden="true" :class="iconButton"></i> {{buttonText}}
            </button>

            <slot name="extraContent"></slot>
        </div>
    </div>
</template>

<script>
import { debounce } from '@/utils/utils';

export default {
    name: 'SearchBar',
    components: {
    },
    props: {
        showInput: {
            type: Boolean,
            default: true
        },
        clazz: String,
        buttonText: {
            type: String,
            default: 'Nuevo elemento'
        },
        canCreate: {
            type: Boolean,
            default: true
        },
        noOptions: {
            type: Boolean,
            default: false
        },
        iconButton: {
            type: String,
            default: 'banana banana-symbol-plus'
        },
        placeholder: {
            type: String,
            default: 'general.search-bar.search-text-placeholder',
        }
    },
    data () {
        return {
            searchText: ''
        }
    },
    methods: {
        onIconClick() {
            this.$emit('onIconClick')
        },
        onClickButton() {
            this.$emit('onClickButton')
        },
        resetInput(e) {
            if (e !== undefined) {
                e.preventDefault()
            }
            if (this.searchText.length > 0) {
                this.searchText = "";
                this.$emit('searchTextChange', "");
            }
        },
        searchTextChange (event) {
            debounce(() => {
                this.$emit('searchTextChange', event.target.value);
            });
        }
    },
    mounted () {
    }
}
</script>

<style lang="scss">
.search-filter-bar {
    box-shadow: 0 2px 10px 0 rgba(20, 34, 102, 0.15);
    padding: 8px 14px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    background-color: #FFFFFF !important;
    border-radius: 15px;
    border: none;
    min-height: 54px;

    &.full-search-container {
        .search-container {
            width: 100%;
            margin-bottom: 0px !important;
        }
    }

    .search-container {
        display: flex;
        align-items: center;
        margin: 0 auto 0 0;
        min-width: 40%;
        // width: calc(100% - 370px);

        input {
            border: none;
            padding: 6px 6px 6px 30px;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.43;
            width: 100%;
            transition: 0.5s;
        }

        .extra-input-button {
            position: absolute;
            float: left;
            top: 18px;
            width: 24px;
            height: 24px;
            font-size: 22px;
            transition: 0.5s;
            display: initial;

        }

        input:focus ~ i {
            &.banana-search-normal {
                opacity: 0;
                z-index: -1;
            }

            &.banana-cross-normal {
                opacity: 1;
                z-index: 1;
            }
        }
    }

    .filter-container {
        margin: 0 0 0 auto;
        // min-width: 370px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        > .form-group.noLabel {
            width: 185px;
        }

        button {
            border-radius: 8px;
            padding: 6px 12px;
            font-size: 12px;

            i {
                margin-right: 5px;
                font-size: 16px;
            }
        }
    }

    .b-dropdown-form {
        padding: 0 !important;

        .form-group {
            margin-bottom: 0 !important;

            > .form-text-info {
                margin: 0 !important;
            }
        }
    }

    .filter-container > .dropdown-image-list .dropdown-toggle {
        padding: 0;
    }
}
</style>
