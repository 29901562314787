<template>
    <div id="" class="title-steps" :class="clazz">
        <slot name="progress"></slot>
        <span class="info">
            <label :class="titleClass">{{ title }}</label>
            <p v-if="subtitle" :class="subtitleClass">{{ subtitle }}</p>
        </span>

        <div class="buttons">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TitleSteps',
        props: {
            clazz: String,
            title: String,
            subtitle: String,
            titleClass: String,
            subtitleClass: String,
        }
    }
</script>
