<template>
  <div class="left-side-content">
    <div class="container-left">
      <h2 class="welcome-text">
        {{ $t("login.leftSide.title") }}
        <br/>
        <strong>{{$t('login.leftSide.subtitleStrong')}}</strong>
        {{$t('login.leftSide.subtitle')}}
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "LeftSideLogin"
}
</script>

<style scoped>

</style>
