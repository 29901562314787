import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'contacts/api-v1';

export default {
    findAccount: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/search-account`, params)
    },
    listAll: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/list-access`, params)
    },
    listRecent: ({accountId}) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/recent-contacts-access/${accountId}`)
    },
    save: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/save-web`, params)
    },
    edit: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/edit-web`, params)
    },
    delete: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/delete-web`, params)
    },
    authorizeContact: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/authorize`, params)
    },
    rejectContact: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/reject`, params)
    },
    uploadBulkFile: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/import-bulk`, params)
    },
    checkBulkProgress: (id) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/check-bulk-progress/${id}`)
    },
    listBulks: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/list-bulks`, {params})
    },
    authorizeBulk: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/authorize-bulk`, params)
    },
    downloadError: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/download-error`, {params: params})
    },
    downloadBulk: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/download-bulk`, {params: params})
    },
    downloadTemplate: () => {
        // Esto solo regresa ruta no una promesa
        return `${base.baseUrlWallet}/${namespace}/download/template`
    },
}
