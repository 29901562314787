<template>
  <div>
    <div class="row" v-if="!useDropdowns">
      <BasicInput
        required
        class="col-12"
        v-model="zipCode"
        :label="$t('register.residence.zipCode')"
        :inputPlaceholder="$t('register.residence.zipCode')"
        @input="onInputChange"
      />
      <BasicInput
        required
        class="col-12 col-md-6"
        v-model="stateName"
        inputType="text"
        :label="$t('register.residence.state')"
        :inputPlaceholder="$t('register.residence.state')"
        @input="onInputChange"
      />
      <BasicInput
        required
        class="col-12 col-md-6"
        v-model="city"
        inputType="text"
        :label="$t('register.residence.delegation')"
        :inputPlaceholder="$t('register.residence.delegation')"
        @input="onInputChange"
      />
      <BasicInput
        required
        class="col-12"
        v-model="typedDistrict"
        inputType="text"
        :label="$t('register.residence.suburb')"
        :inputPlaceholder="$t('register.residence.suburb')"
        @input="onInputChange"
      />
      <BasicInput
        required
        class="col-12"
        v-model="streetAddress"
        inputType="text"
        :label="$t('register.residence.street')"
        :inputPlaceholder="$t('register.residence.streetPh')"
        @input="onInputChange"
      />
      <BasicInput
        required
        class="col-12 col-md-6"
        v-model="exteriorNumber"
        v-bind:maxLength="exteriorNoLength"
        inputType="text"
        :label="$t('register.residence.externalNo')"
        :inputPlaceholder="$t('register.residence.externalNoPh')"
        @input="onInputChange"
      />
      <BasicInput
        class="col-12 col-md-6"
        v-model="interiorNumber"
        inputType="text"
        v-bind:maxLength="interiorNoLength"
        :label="$t('register.residence.insideNo')"
        :inputPlaceholder="$t('register.residence.insideNoPh')"
        @input="onInputChange"
      />
    </div>
    <LoadingBar v-else-if="loadingZipCode" :loadingText="$t('register.residence.loading')"/>
    <div v-else>
      <BasicInput
        class="noLabel search-type"
        v-model="zipCode"
        inputType="text"
        :inputPlaceholder="$t('register.residence.zipCode')"
        inputClazz="icon-left"
        iconInput="banana-search-normal"
        @input="onInputChange"
        @onKeyUpEnter="findZipCode"
      >
        <button
          type="button"
          class="button xs button-filled button-primary b-shadow-none"
          @click="findZipCode"
        >
          {{$t('register.residence.search')}}
        </button>
      </BasicInput>
      <div class="p-relative min-h-100px m-b-40" v-if="!validZipCode">
        <img
          class="img-fluid max-h-300px d-flex m-0-auto"
          src="@/assets/images/Illustrations/illustration-lugar-recidencia.svg"
          :alt="$t('register.residence.title')"
        />
        <p class="f-14 c-title poppins-font-bold d-block m-b-5 text-align-c">
          {{$t('register.residence.notZipCode')}}
        </p>
        <a
          href="https://www.correosdemexico.gob.mx/SSLServicios/ConsultaCP/Descarga.aspx"
          class="
            f-13
            c-title
            poppins-font-regular
            text-align-c
            d-block
            w-100
            hover
          "
          tabindex=""
          target="_blank"
        >
          {{$t('register.residence.searchHere')}}
          <strong class="poppins-font-bold c-info">{{$t('register.residence.here')}}</strong>
        </a>
      </div>
      <div class="row" v-if="validZipCode">
        <div class="col-12 col-md-6">
          <BasicInput
            required
            inputType="text"
            inputId="company_edo"
            :label="$t('register.residence.state')"
            :value="state.name"
            disabled
            @input="onInputChange"
          />
        </div>
        <div class="col-12 col-md-6">
          <BasicInput
            required
            inputType="text"
            inputId="company_municipio"
            :label="$t('register.residence.delegation')"
            :value="city"
            disabled
            @input="onInputChange"
          />
        </div>
        <div class="col-12">
          <BasicSelect required :label="$t('register.residence.suburb')">
            <multiselect
              v-model="selectedDistrict"
              :options="districtOptions"
              :close-on-select="true"
              :preserve-search="true"
              :placeholder="$t('register.residence.suburbPh')"
              :preselect-first="false"
              :disabled="manualDistrict"
              @input="onInputChange"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} options selected</span
                >
              </template>
            </multiselect>
          </BasicSelect>
          <label
            class="f-14 c-title poppins-font-semibold d-block m-b-50"
            v-if="!manualDistrict"
          >
            {{$t('register.residence.notFound1')}}
            <a
              class="c-info text-underline hover"
              tabindex=""
              @click="toggleManualDistrict"
              >{{$t('register.residence.notFound2')}}</a
            >
          </label>
          <label class="f-14 c-title poppins-font-semibold d-block m-b-50" v-else>
            {{$t('register.residence.found1')}}
            <a
              class="c-info text-underline hover"
              tabindex=""
              @click="toggleManualDistrict"
              >{{$t('register.residence.found2')}}</a
            >
          </label>
        </div>
        <div class="col-12" v-if="manualDistrict">
          <BasicInput
            required
            v-model="typedDistrict"
            inputType="text"
            inputId="company_colonia"
            :label="$t('register.residence.suburb')"
            :inputPlaceholder="$t('register.residence.suburbPhNotFound')"
            @input="onInputChange"
          />
        </div>
        <div class="col-12">
          <BasicInput
            required
            v-model="streetAddress"
            inputType="text"
            inputId="company_calle"
            :label="$t('register.residence.street')"
            :inputPlaceholder="$t('register.residence.streetPh')"
            @input="onInputChange"
          />
        </div>
        <div class="col-12 col-md-6">
          <BasicInput
            required
            v-model="exteriorNumber"
            inputType="text"
            v-bind:maxLength="exteriorNoLength"
            inputId="company_no_ext"
            :label="$t('register.residence.externalNo')"
            :inputPlaceholder="$t('register.residence.externalNoPh')"
            @input="onInputChange"
          />
        </div>
        <div class="col-12 col-md-6">
          <BasicInput
            v-model="interiorNumber"
            inputType="text"
            inputId="company_no_int"
            v-bind:maxLength="interiorNoLength"
            :label="$t('register.residence.insideNo')"
            :inputPlaceholder="$t('register.residence.insideNoPh')"
            @input="onInputChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BasicSelect from "@/components/BasicSelect.vue";
import BasicInput from "@/components/BasicInput.vue";
import Multiselect from "vue-multiselect";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import LoadingBar from "@/components/LoadingBar.vue";
import ZipCodeApi from "@/api/core/zipCode.api";
import { catchError, isDefined } from "@/utils/utils";

export default {
  name: "AddressForm",
  mixins: [ToastSnotify],
  components: {
    BasicInput,
    LoadingBar,
    BasicSelect,
    Multiselect,
  },
  props: {
    value: Object,
    forShareholder: {
      type: Boolean,
      default: false,
    },
    forPersonal: {
      type: Boolean,
      default: false,
    },
    nationality: {
      type: Object,
    },
  },
  data() {
    return {
      loadingZipCode: false,
      validZipCode: false,
      zipCode: null,
      city: null,
      state: null,
      stateName: null,
      country: null,
      typedDistrict: null,
      selectedDistrict: null,
      streetAddress: null,
      exteriorNumber: null,
      interiorNumber: null,
      manualDistrict: false,
      districtOptions: [],
      delegation: null,
      exteriorNoLength: 10,
      interiorNoLength: 5,
    };
  },
  computed: {
    district() {
      if (this.typedDistrict || !this.useDropdowns) {
        return this.typedDistrict;
      }
      return this.selectedDistrict;
    },
    useDropdowns() {
      // Usa dropdowns cuando la nacionalidad es mexicana.
      // Para otros paises deja los campos de texto abiertos.
      return !this.nationality || this.nationality.isoCode === 'MX';
    },
  },
  mounted() {
    if(isDefined(this.value?.zipCode)) {
      this.setAddress(this.value);
    }
  },
  methods: {
    async findZipCode() {
      this.loadingZipCode = true;
      const response = await ZipCodeApi.search({
        zipCode: this.zipCode,
      }).catch(catchError);
      if (response && response.data && !response.data.error) {
        this.loadingZipCode = false;
        this.validZipCode = true;

        const zipCode = response.data.object;
        this.city = zipCode.delegation.name;
        this.delegation = zipCode.delegation;
        if(this.forShareholder) {
          this.country = zipCode.country._id;
        }
        this.state = zipCode.state;
        this.districtOptions = zipCode.apiDetail;
        this.onInputChange();
      } else {
        this.loadingZipCode = false;
        this.displayNotificationError(
          "No se pudo conseguir la informacion del codigo postal"
        );
      }
    },
    toggleManualDistrict() {
      this.selectedDistrict = null;
      this.typedDistrict = null;
      this.manualDistrict = !this.manualDistrict;
      this.onInputChange();
    },
    resetValues() {
      this.loadingZipCode = false;
      this.validZipCode = false;
      this.city = null;
      this.state = null;
      this.stateName = null;
      this.country = null;
      this.selectedDistrict = null;
      this.typedDistrict = null;
      this.streetAddress = null;
      this.exteriorNumber = null;
      this.interiorNumber = null;
      this.manualDistrict = false;
      this.districtOptions = [];
      this.onInputChange();
    },
    onInputChange() {
      if (this.forShareholder) {
        this.$emit("input", {
          street: this.streetAddress,
          noExt: this.exteriorNumber,
          noInt: this.interiorNumber,
          district: this.district,
          city: this.city,
          state: this.useDropdowns ? this.state : null,
          stateName: this.useDropdowns ? null : this.stateName,
          // this.country viene del codigo postal, this.nationality viene del prop (del formulario de afuera)
          country: this.useDropdowns ? this.country : this.nationality._id,
          zipCode: this.zipCode,
        });
      } else if(this.forPersonal) {
        this.$emit("input", {
          state: this.useDropdowns ? this.state?._id : this.stateName,
          delegation: this.delegation?._id,
          street: this.streetAddress,
          noExt: this.exteriorNumber,
          noInt: this.interiorNumber,
          district: this.district,
          zipCode: this.zipCode,
        });
      } else {
        this.$emit("input", {
          streetAddress: this.streetAddress,
          exteriorNumber: this.exteriorNumber,
          interiorNumber: this.interiorNumber,
          district: this.district,
          city: this.city,
          state: this.useDropdowns ? this.state : this.stateName,
          zipCode: this.zipCode,
        });
      }
    },
    async setAddress(address) {
      if(this.useDropdowns) {
        this.zipCode = address.zipCode;
        // Este nextTick es importante para la animacion de loading, para esperar a que ocurra todo lo del watch del zipcode antes de proceder
        await this.$nextTick();
        await this.findZipCode();

        const district = this.districtOptions.find(district => district === address.district);
        if(district) {
          this.selectedDistrict = district;
        } else {
          this.manualDistrict = true;
          this.typedDistrict = district;
        }
      } else {
        this.manualDistrict = true;
        this.typedDistrict = address.district;
        this.zipCode = address.zipCode;
        this.state = null;
        this.stateName = address.stateName;
        this.city = address.city;
      }

      this.exteriorNumber = address.noExt || address.exteriorNumber;
      this.interiorNumber = address.noInt || address.interiorNumber;
      this.streetAddress = address.street || address.streetAddress;

      this.onInputChange();
    }
  },
  watch: {
    nationality: function () {
      this.onInputChange();
    },
    useDropdowns: function () {
      this.zipCode = null;
      this.resetValues();
    },
    zipCode: function () {
      if(this.useDropdowns) {
        this.resetValues();
      }
    },
  },
};
</script>
