<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/settings"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('settings.goBack')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('settings.contact.title')"></FloatingTitleLayout>
                        </div>
                        <div class="col-12">
                            <CardSimple :title="$t('settings.contact.title2')" :subtitle="$t('settings.contact.text')">
                                <div class="row">
                                    <div class="col-12 col-sm-6 m-b-20">
                                        <div class="d-flex">
                                            <i aria-hidden="true" class="iconsax-location-global-search f-30 c-primary"></i>
                                            <div class="m-l-15">
                                                <span class="c-text_info f-13">{{$t('settings.contact.webPage')}}</span><br>
                                                <a href="https://www.pagando.mx" target="_blank"
                                                class="f-16 poppins-font-semibold c-info text-underline hover">
                                                    www.pagando.mx
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 m-b-20">
                                        <div class="d-flex">
                                            <i aria-hidden="true" class="iconsax-call-calling f-30 c-primary"></i>
                                            <div class="m-l-15">
                                                <span class="c-text_info f-13">{{$t('settings.contact.phoneFromMexico')}}</span><br>
                                                <span
                                                class="f-16 poppins-font-semibold c-info text-underline">
                                                    800-461-0119
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 m-b-20">
                                        <div class="d-flex">
                                            <i aria-hidden="true" class="iconsax-brand-facebook f-30 c-primary"></i>
                                            <div class="m-l-15">
                                                <span class="c-text_info f-13">Facebook</span><br>
                                                <a href="https://www.facebook.com/pagandomx" target="_blank"
                                                class="f-16 poppins-font-semibold c-info text-underline hover">
                                                    @pagandomx
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 m-b-20">
                                        <div class="d-flex">
                                            <i aria-hidden="true" class="iconsax-call-calling f-30 c-primary"></i>
                                            <div class="m-l-15">
                                                <span class="c-text_info f-13">{{$t('settings.contact.phoneFromEUA')}}</span><br>
                                                <span
                                                class="f-16 poppins-font-semibold c-info text-underline">
                                                    +1 800-392-6948
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 m-b-20">
                                        <div class="d-flex">
                                            <i aria-hidden="true" class="iconsax-location-global f-30 c-primary"></i>
                                            <div class="m-l-15">
                                                <span class="c-text_info f-13">Twitter</span><br>
                                                <a href="https://twitter.com/pagandomx" target="_blank"
                                                class="f-16 poppins-font-semibold c-info text-underline hover">
                                                    @pagandomx
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 m-b-20">
                                        <div class="d-flex">
                                            <i aria-hidden="true" class="iconsax-emails-sms-tracking f-30 c-primary"></i>
                                            <div class="m-l-15">
                                                <span class="c-text_info f-13">{{$t('settings.contact.email')}}</span><br>
                                                <a href="mailto:soporte@pagando.mx"
                                                class="f-16 poppins-font-semibold c-info text-underline hover">
                                                    soporte@pagando.mx
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6 m-b-20">
                                        <div class="d-flex">
                                            <i aria-hidden="true" class="iconsax-essential-instagram f-30 c-primary"></i>
                                            <div class="m-l-15">
                                                <span class="c-text_info f-13">Instagram</span><br>
                                                <a href="https://www.instagram.com/pagandomexico/" target="_blank"
                                                class="f-16 poppins-font-semibold c-info text-underline hover">
                                                    @pagandomexico
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardSimple>
                        </div>
                        <div class="col-12">
                            <div class="control-buttons">
                                <button
                                    @click="goBack()"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('settings.contact.back')}}
                                </button>
                                <div class="f-13 c-text_content poppins-font-medium text-center text-md-right">
                                    {{$t('settings.contact.needChange')}}
                                    <a
                                            href="https://www.pagando.mx/contacto-pagando/" target="_blank"
                                            class="c-info poppins-font-bold text-underline hover"
                                            tabindex="">
                                            {{$t('settings.contact.contactUs')}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>

    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import CardSimple from "@/components/CardSimple.vue";

    export default {
        name: "Contact",
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            CardSimple,
        },
        data() {
            return {
                window: {
                    width: 0
                },
                isMenuOpen: false,
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            goBack() {
                this.$router.push({name: 'Settings'});
            }
        },
        mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        },
    };
</script>
