<template>
  <div>
    <vue-snotify></vue-snotify>
    <div id="" class="responsive-top-bar">
      <span>
        <button
          type="button"
          id="showLeftMenu"
          class="button-square xs button-filled button-primary"
          aria-label="Mostrar menú principal"
          name="Mostrar menú principal"
          @click="showMenu"
        >
          <i aria-hidden="true" class="iconsax-essential-menu"></i>
        </button>
      </span>
      <span>
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          width="114"
          height="25"
          alt="Pagando Logo"
        />
      </span>
      <span></span>
    </div>

    <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show' : '']">
      <template v-slot:buttonClose>
        <button
          type="button"
          class="button-close-menu"
          aria-label="Esconder menú principal"
          name="Esconder menú principal"
          @click="hideMenu"
        >
          <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
        </button>
      </template>
    </SidebarMenuPrincipal>
    <div class="sidebar-menu-backdrop menu-principal"></div>

    <section id="main-view" class="p-r-0">
      <div class="page-container content-centered">
        <router-view/>
      </div>
    </section>

    <FooterPage></FooterPage>
  </div>
</template>
<script>
// @ is an alias to /src
import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
import imgEmptystateTable from "@/assets/images/Emptystates/emptystate-table.svg";
import FooterPage from "@/components/FooterPage.vue";

export default {
  name: "Dispersions",
  components: {
    SidebarMenuPrincipal,
    FooterPage,
  },
  data() {
    return {
      // Images
      imgEmptystateTable: imgEmptystateTable,
      // SidebarRight / MenuPrincipal
      window: {
        width: 0,
      },
      isMenuOpen: false,
      // Date
      dateRangeComponentBtn: {},
    };
  },
  methods: {
    // Resize Sidebar Menu Principal
    handleResizeMenu() {
      this.window.width = window.innerWidth;
      if (window.innerWidth > 767) {
        this.isMenuOpen = true;
      } else {
        this.isMenuOpen = false;
      }
    },
    // Show Sidebar Menu Principal
    showMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    // Hide Sidebar Menu Principal
    hideMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
  mounted() {
    this.handleResizeMenu();
    window.addEventListener("resize", this.handleResizeMenu);
  },
  destroyed() {
    window.addEventListener("resize", this.handleResizeMenu);
  },
};
</script>
