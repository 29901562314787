<template>
  <div class="progress-circle" :class="clazz">
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProgressCircle',
    props: {
      clazz: String,
    }
  }
</script>
