<template>
<div>
  <div v-show="showLoading">
    <LoadingSimple
        clazz="big-load p-t-50 p-b-50"
        label="Loading..."
    ></LoadingSimple>
  </div>
  <div v-show="!showLoading">
    <TitlePrincipal :title="$t('register.usePolicy.title')">
      <p class="subtitle" v-html="$t('register.usePolicy.subtitle')">
      </p>
    </TitlePrincipal>
    <CardSelection :title="$t('register.usePolicy.noticePrivacy')"
                   :subtitle="$t('register.usePolicy.aboutPrivacyData')"
                   clazz="icon-bgm-pink">
      <template v-slot:icon>
        <i aria-hidden="true" class="banana banana-locker-locked"></i>
      </template>
      <template v-slot:extraMiddleContent>
        <a @click="$bvModal.show('modal-aviso-privacidad')"
           class="f-14 c-info body-font-bold text-underline d-block m-t-5 c-pointer"
           tabindex>{{ $t('register.usePolicy.readNotice') }}</a>
      </template>
      <template v-slot:extraFinalContent>
        <BasicSwitch ref="refBsAvisoPrivacidad" :disabled="!hasDocuments" idInput="bs-avisoPrivacidad" @onBasicSwitchChange="onChangeUsePolicy"></BasicSwitch>
      </template>
    </CardSelection>

    <b-modal
        id="modal-aviso-privacidad"
        size="lg"
        hide-header
        hide-footer>
      <button class="close-modal" @click="$bvModal.hide('modal-aviso-privacidad')" aria-label="Boton cerrar ventana" name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="modal-terminos-privacidad">
        <h2 class="title">
          {{ $t('register.usePolicy.modalUsePrivacy') }}
        </h2>
        <span class="date-update">
          <small>{{$t('register.usePolicy.lastUpdated')}}</small> {{getDateUpdate(usePolicy.updatedAt)}}
        </span>
        <div class="text-container" v-html="usePolicy.content">
        </div>
        <button id="acceptNotice" class="button button-filled button-primary" type="button" @click="acceptUsePolicy">
          <i aria-hidden="true" class="banana banana-check-normal"></i>
          {{ $t('register.usePolicy.modalAcceptUsePrivacy') }}
        </button>
      </div>
    </b-modal>

    <CardSelection :title="$t('register.usePolicy.termsCond')"
                   :subtitle="$t('register.usePolicy.termsCondDesc')"
                   clazz="icon-bgm-warning">
      <template v-slot:icon>
        <i aria-hidden="true" class="banana banana-document-normal"></i>
      </template>
      <template v-slot:extraMiddleContent>
        <a @click="$bvModal.show('modal-terminos-condiciones')"
           class="f-14 c-info body-font-bold text-underline d-block m-t-5 c-pointer"
           tabindex>{{ $t('register.usePolicy.readTerms') }}</a>
      </template>
      <template v-slot:extraFinalContent>
        <BasicSwitch ref="refBsTerminosCondiciones" :disabled="!hasDocuments" idInput="bs-terminosCondiciones" @onBasicSwitchChange="onChangeTerms"></BasicSwitch>
      </template>
    </CardSelection>

    <b-modal
        id="modal-terminos-condiciones"
        size="lg"
        hide-header
        hide-footer>
      <button class="close-modal" @click="$bvModal.hide('modal-terminos-condiciones')" aria-label="Boton cerrar ventana" name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="modal-terminos-privacidad">
        <h2 class="title">
          {{ $t('register.usePolicy.modalTermsCond') }}
        </h2>
        <span class="date-update">
          <small>{{$t('register.usePolicy.lastUpdated')}}</small> {{getDateUpdate(termsCond.updatedAt)}}
        </span>
        <div class="text-container" v-html="termsCond.content">
        </div>
        <button id="acceptTerms" class="button button-filled button-primary" type="button" @click="acceptTerms">
          <i aria-hidden="true" class="banana banana-check-normal"></i>
          {{ $t('register.usePolicy.modalAcceptTermsCond') }}
        </button>
      </div>
    </b-modal>

    <CardSelection :title="$t('register.usePolicy.locationTitle')"
                   :subtitle="$t('register.usePolicy.locationSubTitle')"
                   clazz="icon-bgm-success">
      <template v-slot:icon>
        <i aria-hidden="true" class="banana banana-location"></i>
      </template>
      <template v-slot:extraFinalContent>
        <BasicSwitch ref="refBsUbicacion" idInput="bs-ubicacion" @onBasicSwitchChange="onChangeLocation"></BasicSwitch>
      </template>
    </CardSelection>
    <div class="footer-buttons right">
      <button type="button" class="button button-ghost button-plain_text" name="Regresar" @click="back()"> {{ $t('register.usePolicy.back') }} </button>
      <button v-if="!loadingDocuments" type="button" class="button button-filled button-primary" name="Continuar" :disabled="!acceptPolitics" @click="acceptDocuments">
        {{ $t('register.usePolicy.continue')  }} </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text" />
      </button>
    </div>
  </div>

  <ModalAlert
      closeCross
      modalId="alert-return"
      buttonType="button-primary"
      :imgUrl="imageModalGeneric"
      title="¿Estás seguro de que quieres regresar a la selección de cuenta?"
      text="Si regresas se borrará toda la información que llevas registrada al momento y se reiniciará todo el proceso de nuevo"
      buttonCancel="No"
      buttonText="Sí"
      @submit="returnToSelectAccount()"
  ></ModalAlert>

</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import CardSelection from "@/components/CardSelection";
import BasicSwitch from "@/components/BasicSwitch";
import DocumentsApi from "@/api/core/documents.api";
import UsersApi from "@/api/users/users.api";
import {catchError, getMonthByNo} from "@/utils/utils";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {EventBus} from "@/main";
import imageModalGeneric from "@/assets/images/Illustrations/illustration-modal-generic.svg";
import ModalAlert from "@/components/ModalAlert.vue";

import {
  STORE as onbngStore,
  GET_ACCOUNT_TYPE, LOAD_CARD_REQUEST, GET_CARD_REQUEST, SET_LOCATION,
  GO_TO_ROUTE, GET_LEVEL, GET_LOCATION, GET_TYPE_ACCOUNT
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
  STORE as sessionStore,
  GET_USER
} from "@/store/modules/session/session.types";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import {mapGetters} from "vuex";
import LoadingSimple from "@/components/LoadingSimple";
const errorTittle = "register.usePolicy.errorDocumentsTitle";

// https://staging-api.pagando.mx/api-v2/documents/accept-preterms-platform
// {"appliesTo":"WALLET","cardRequestId":"615dd1cd29c84b00078341db","discriminators":["5f067eb1fda2a40006078160"],"documentType":"PRIVACY_AGREEMENT","profileType":"USER"}
// {"appliesTo":"WALLET","cardRequestId":"615dd1cd29c84b00078341db","discriminators":["5f067eb1fda2a40006078160"],"documentType":"TERMS_CONDITIONS","profileType":"USER"}
export default {
  name: "UsagePolicies",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    CardSelection,
    BasicSwitch,
    LoadingSimple,
    ModalAlert
  },
  computed: {
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      location: GET_LOCATION,
      cardRequest: GET_CARD_REQUEST,
      accountType: GET_ACCOUNT_TYPE,
      isPersonal: GET_TYPE_ACCOUNT
    }),
    ...mapGetters(sessionStore, {
      user: GET_USER,
    }),
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO,
    }),
    acceptPolitics() {
      return (this.usePolicySwitch && this.termsCondSwitch && this.locationSwitch)
    }
  },
  methods: {
    async returnToSelectAccount() {
      const response = await UsersApi.deleteAllInfoForUser({user: this.user._id,
        isMultiple: ['usagePoliciesMultiple', 'usagePoliciesBusinessMultiple'].includes(this.$route.name)});

      if(response.data.error) {
        this.displayNotificationError('Ocurrió un error al regresar.')
      } else {
        // TODO CHECK USER ONB FOR BUSINESS PARA MOSTRAR YA QUE CARD REQUEST SE ELIMINARA

          this.$store.commit(`${onbngStore}/SET_CARD_REQUEST`, '');
          this.$store.commit(`${onbngStore}/SET_SELECT_ACCOUNT`, null);
          this.$store.commit(`${onbngStore}/SET_LEVEL`, 1);
          this.$store.commit(`${onbngStore}/SET_LOCATION`, {});
          this.$store.commit(`${onbngStore}/SET_AUX_LEVEL`, 1);

          if(this.cardRequest.accountType === 'BUSINESS') {
            this.$store.dispatch(`${onbngStore}/backBusiness`)
          } else {
            this.$store.dispatch(`${onbngStore}/backPersonal`)
          }
      }
    },
    back() {

      // TODO GENERAR MODAL PARA ALERTA Y BORRAR CARD REQUEST Y TEMP DOCUMENTS

      if(this.isPersonal) {
        this.$bvModal.show('alert-return');
      } else {
        this.$store.dispatch(`${onbngStore}/backBusiness`)
      }

      // if(this.cardRequest.accountType === 'BUSINESS') {
      // } else {
      //   this.$store.dispatch(`${onbngStore}/backPersonal`)
      // }
    },
    onChangeUsePolicy(value) {
      this.usePolicySwitch = value;
    },
    onChangeTerms(value) {
      this.termsCondSwitch = value;
    },
    onChangeLocation(value) {
      this.displayNotificationInfo("", this.$i18n.t('register.usePolicy.checkLocation'))
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition((coords) => {
          this.locationSwitch = value;
          this.$store.commit(`${onbngStore}/${SET_LOCATION}`, {latitud: coords.coords.latitude, longitud: coords.coords.longitude})
        }, () => {
          this.displayNotificationError("", this.$i18n.t('register.usePolicy.locationErrorTittle'))
          this.$refs.refBsUbicacion.forceValue(false);
        });
      } else {
        this.locationSwitch = value;
      }
    },
    getDateUpdate(date){
      if (date) {
          const nDate = new Date(date);
          return `${nDate.getDate()} ${this.$i18n.t(getMonthByNo(nDate.getMonth()))} ${nDate.getFullYear()}`;
      }
      return "";
    },
    acceptUsePolicy(value = null) {
      if(value === null) {
        this.usePolicySwitch = true;
        this.$refs.refBsAvisoPrivacidad.forceValue(true);
        this.$bvModal.hide('modal-aviso-privacidad');
      } else {
        this.usePolicySwitch = value;
        this.$refs.refBsAvisoPrivacidad.forceValue(value);
        this.$bvModal.hide('modal-aviso-privacidad');
      }
    },
    acceptTerms(value = null) {
      if(value === null) {
        this.termsCondSwitch = true;
        this.$refs.refBsTerminosCondiciones.forceValue(true);
        this.$bvModal.hide('modal-terminos-condiciones');
      } else {
        this.termsCondSwitch = value;
        this.$refs.refBsTerminosCondiciones.forceValue(value);
        this.$bvModal.hide('modal-terminos-condiciones');
      }
    },
     async getDocuments() {
      if (this.cardRequest) {
        const responseUsePolicy = await DocumentsApi.getDocumentWallet({
          key: "PRIVACY_AGREEMENT",
          cardRequestId: this.cardRequest._id,
          discriminators: [this.accountType._id || this.accountType]
        });

        if(responseUsePolicy.data.error) {
            this.hasDocuments = false;

          this.displayNotificationError(this.$i18n.t("register.usePolicy.errorDocuments"),
                this.$i18n.t(errorTittle))
        } else {
          const responseTerms = await DocumentsApi.getDocumentWallet({
            key: "TERMS_CONDITIONS",
            cardRequestId: this.cardRequest._id,
            discriminators: [this.accountType]
          });

          if(responseTerms.data.error) {
            this.hasDocuments = false;

            this.displayNotificationError(this.$i18n.t("register.usePolicy.errorDocuments"),
                this.$i18n.t(errorTittle))
          } else {
          this.usePolicy = responseUsePolicy.data.object;
            this.acceptUsePolicy(this.usePolicy.accepted);

            this.termsCond = responseTerms.data.object;
            this.acceptTerms(this.termsCond.accepted);
            this.hasDocuments = true;

          }
        }
      }
      this.showLoading = false;
    },
    async getDocumentsOld() {
      if (this.cardRequest) {
        const promisePrivacy = DocumentsApi.getDocumentWallet({
          key: "PRIVACY_AGREEMENT",
          cardRequestId: this.cardRequest._id,
          discriminators: [this.accountType._id || this.accountType]
        }).catch(catchError);
        const promiseTerms = DocumentsApi.getDocumentWallet({
          key: "TERMS_CONDITIONS",
          cardRequestId: this.cardRequest._id,
          discriminators: [this.accountType]
        }).catch(catchError);
        const documents = await Promise.all([promisePrivacy, promiseTerms]);
        if (documents.length) {
          const areValid = !documents.every((d) => d.data.error);
          if (areValid) {
            this.usePolicy = documents[0].data.object;
            this.acceptUsePolicy(this.usePolicy.accepted);

            this.termsCond = documents[1].data.object;
            this.acceptTerms(this.termsCond.accepted);
            this.hasDocuments = true;
          } else {
            this.hasDocuments = false;
            this.displayNotificationError(this.$i18n.t("register.usePolicy.errorDocuments"),
                this.$i18n.t(errorTittle))
          }
        } else {
          this.hasDocuments = false;
          this.displayNotificationError(this.$i18n.t("register.usePolicy.errorDocuments"),
              this.$i18n.t(errorTittle))
        }
      }
      this.showLoading = false;
    },
    async acceptDocuments() {
      this.loadingDocuments = true;
      const promisePrivacy = DocumentsApi.acceptDocumentWallet({
        documentType: "PRIVACY_AGREEMENT",
        appliesTo: "WALLET",
        cardRequestId: this.cardRequest._id,
        discriminators: [this.accountType],
        profileType: "USER"
      }).catch(catchError);
      const promiseTerms = DocumentsApi.acceptDocumentWallet({
        documentType: "TERMS_CONDITIONS",
        appliesTo: "WALLET",
        cardRequestId: this.cardRequest._id,
        discriminators: [this.accountType],
        profileType: "USER"
      }).catch(catchError);
      const locationPromise = UsersApi.updateOnbLocation({
        allowWebLocation: this.locationSwitch,
      }).catch(catchError);
      const documents = await Promise.all([promisePrivacy, promiseTerms, locationPromise]);
      if (documents.length) {
        const areValid = !documents.every((d) => d.data.error);
        if (areValid) {
          if(['usagePoliciesMultiple', 'usagePoliciesBusinessMultiple'].includes(this.$route.name)) {
            this._handleSuccessMultiple();
            return;
          }
          this.$store.dispatch(`${sessionStore}/getFullDataByLevel`, {level: this.cardRequest.information_level});
          if(this.cardRequest && this.cardRequest.accountType === 'BUSINESS') {
            this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
              stepOnbng: ONBOARDING_STEPS.BUSINESS_START,
            });
            // this.$store.dispatch(`${onbngStore}/checkCurrentStep`, {route: 'businessStart', doRedirect: true});
            this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "businessStart");
          } else {
            this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
              stepOnbng: ONBOARDING_STEPS.NATIONALITY,
            });
            this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "nationality");
          }
        } else {
          this.displayNotificationError(this.$i18n.t("register.usePolicy.errorAcceptDocument"),
              this.$i18n.t(errorTittle))
        }
      } else {
        this.displayNotificationError(this.$i18n.t("register.usePolicy.errorAcceptDocument"),
            this.$i18n.t(errorTittle))
      }
      this.loadingDocuments = false;
    },

    /** Methods for Multiple */
    _handleSuccessMultiple() {
      if(this.$route.name === 'usagePoliciesMultiple') {
        this._handleSuccessPersonal();
      } else {
        this._handleSuccessBusiness();
      }
    },
    _handleSuccessPersonal() {
      if(this.level === 1) {
        // level 1
        this.sendRequestCard();
      } else if(this.level === 2) {
        // level 2
        // if(!this.user.hasFinishLevelTwo) {
          this.goToRoute('residenceMultiple');
        // } else {
        //   this.sendRequestCard();
        // }
      } else {
        // level 3
        if(!this.user.hasFinishLevelTwo) {
          this.goToRoute('residenceMultiple');
        } else {
          this.goToRoute('addInfoMultiple');
        }
        // } else {
        //   this.sendRequestCard();
        // }
      }
    },
    _handleSuccessBusiness() {
      if(this.cardRequest.isMoral) {
        this.$router.push({name: "businessOperatesMultiple"});
      } else if(!this.user.hasFinishLevelTwo) {
        this.goToRoute('businessResidenceMultiple');
      } else if(!this.user.hasFinishLevelThree) {
        this.goToRoute('businessAddInfoMultiple');
      } else {
        this.sendRequestCard(this.organization._id);
      }
    },
    async sendRequestCard(organizationInfo) {
      const params = {
        organizationInfo,
        isMultipleAccount: this.$route.name === 'usagePoliciesMultiple'
      };

      if(this.$route.query.org) {
        params.organizationInfo = this.$route.query.org;
        params.isNewOrg = false;
      }

      await this.$store.dispatch(`${onbngStore}/sendRequestCard`, params);

      if(this.$route.name === 'usagePoliciesBusinessMultiple') {
        this.goToRoute('businessProcessingRequestMultiple');
      } else {
        this.goToRoute('processingRequestMultiple');
      }
    },
    goToRoute(route) {
      if(['residenceMultiple', 'businessResidenceMultiple'].includes(route)) {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {stepOnbng: ONBOARDING_STEPS.PLACE_RESIDENCE});
      }
      if(['addInfoMultiple', 'businessAddInfoMultiple'].includes(route)) {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {stepOnbng: ONBOARDING_STEPS.ADD_DATA});
      }
      this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, route);
    }
  },
  data() {
    return {
      usePolicySwitch: false,
      termsCondSwitch: false,
      locationSwitch: false,
      usePolicy: {},
      termsCond: {},
      loadingDocuments: false,
      showLoading: true,
      hasDocuments: false,
      imageModalGeneric
    }
  },
  async mounted() {
    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);

    await this.getDocuments();
    EventBus.$on(`${onbngStore}.${LOAD_CARD_REQUEST}`, ()=>{
      this.getDocuments()
    });
  },
  beforeDestroy() {
    EventBus.$off(`${onbngStore}.${LOAD_CARD_REQUEST}`);
  },
}
</script>

<style scoped>

</style>
