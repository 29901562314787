// PAGES
import SelectSchemaBusiness from "@/pages/onboarding/businessRightSideSteps/SelectSchemaBusiness";
import SelectAccountCard from "@/pages/onboarding/rightSideSteps/SelectAccountCard";
import UsagePolicies from "@/pages/onboarding/rightSideSteps/UsagePolicies";
import BusinessStart from "@/pages/onboarding/businessRightSideSteps/BusinessStart";
import BusinessOperates from "@/pages/onboarding/businessRightSideSteps/OperateBusiness";
import FiscalData from "@/pages/onboarding/businessRightSideSteps/FiscalData";
import FiscalAddress from "@/pages/onboarding/businessRightSideSteps/FiscalAddress";
import CommercialAddress from "@/pages/onboarding/businessRightSideSteps/CommercialAddress";
import SelectCreateOrgBusiness from "@/pages/onboarding/businessRightSideSteps/SelectCreateOrgBusiness";
import ShareholdersIdentification from "@/pages/onboarding/businessRightSideSteps/ShareholdersIdentification";
// import Nationality from "@/pages/onboarding/rightSideSteps/Nationality";
// import SelectDocument from "@/pages/onboarding/rightSideSteps/SelectDocument";
// import UploadDocument from "@/pages/onboarding/rightSideSteps/UploadDocument";
// import PersonalData from "@/pages/onboarding/rightSideSteps/PersonalData";
// import Residence from "@/pages/onboarding/rightSideSteps/Residence";
// import Occupation from "@/pages/onboarding/rightSideSteps/Occupation";
import AddInfo from "@/pages/onboarding/rightSideSteps/AddInfo";
import ProcessingRequest from "@/pages/onboarding/rightSideSteps/ProcessingRequest";
import SuccessRequest from "@/pages/onboarding/rightSideSteps/SuccessRequest";
import Contract from "@/pages/onboarding/rightSideSteps/Contract";
import Sign from "@/pages/onboarding/rightSideSteps/Sign";
import OnbngWelcome from "@/pages/onboarding/rightSideSteps/OnbngWelcome";
import BusinessInfo from "@/pages/onboarding/businessRightSideSteps/BusinessInfo";
// import NewNip from "@/pages/onboarding/rightSideSteps/NewNip";
// import ConfirmPin from "@/pages/onboarding/rightSideSteps/ConfirmPin";
// MIDDLEWARE
// import hasNotUserSession from "@/middleware/hasNotUserSession";
// import hasUserSession from "@/middleware/hasUserSession";
// import isNotWalletUser from "@/middleware/isNotWalletUser";
import isWalletUser from "@/middleware/isWalletUser";
import hasCardRequest from "@/middleware/hasCardRequest";
import isLevelTwoUser from "@/middleware/isLevelTwoUser";
import SelectDocument from "@/pages/onboarding/rightSideSteps/SelectDocument";
import UploadDocument from "@/pages/onboarding/rightSideSteps/UploadDocument";
import Residence from "@/pages/onboarding/rightSideSteps/Residence";
import Occupation from "@/pages/onboarding/rightSideSteps/Occupation";
import Documents from "@/pages/onboarding/businessRightSideSteps/Documents";

export default [
    {
        path: 'select-schema',
        name: "selectSchemaBusinessMultiple",
        component: SelectSchemaBusiness,
        meta: {
            middleware: [isWalletUser]
        },
    },
    {
        path: 'select-account',
        name: "selectAccountBusinessMultiple",
        component: SelectAccountCard,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'use-policy',
        name: "usagePoliciesBusinessMultiple",
        component: UsagePolicies,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'business-start',
        name: "businessStartMultiple",
        component: BusinessStart,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'add-info',
        name: "businessAddInfoMultiple",
        component: AddInfo,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'processing-request',
        name: "businessProcessingRequestMultiple",
        component: ProcessingRequest,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'response-request',
        name: "businessSuccessRequestMultiple",
        component: SuccessRequest,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'contract',
        name: "businessContractMultiple",
        component: Contract,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'sign',
        name: "businessSignMultiple",
        component: Sign,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'welcome',
        name: "businessOnbngWelcomeMultiple",
        component: OnbngWelcome,
        meta: {
            middleware: [isWalletUser]
        },
    },
    {
        path: 'residence',
        name: "businessResidenceMultiple",
        component: Residence,
        meta: {
            middleware: [isWalletUser, isLevelTwoUser, hasCardRequest]
        },
    },
    {
        path: 'occupation',
        name: "businessOccupationMultiple",
        component: Occupation,
        meta: {
            middleware: [isWalletUser, isLevelTwoUser, hasCardRequest]
        },
    },
    {
        path: 'select-documents',
        name: "businessSelectDocumentsMultiple",
        component: SelectDocument,
        meta: {
            middleware: [isWalletUser, isLevelTwoUser, hasCardRequest]
        },
    },
    {
        path: 'upload-documents/:id',
        name: "businessUploadDocumentsMultiple",
        component: UploadDocument,
        meta: {
            middleware: [isWalletUser, isLevelTwoUser, hasCardRequest]
        },
    },

    // Moral
    {
        path: 'operates',
        name: "businessOperatesMultiple",
        component: BusinessOperates,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'documents',
        name: "businessDocumentsMultiple",
        component: Documents,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'business-info',
        name: "businessDataMultiple",
        component: BusinessInfo,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'business-fiscal-data',
        name: "businessFiscalDataMultiple",
        component: FiscalData,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'business-fiscal-address',
        name: "businessFiscalAddressMultiple",
        component: FiscalAddress,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'business-fiscal-c-address',
        name: "businessFiscalComAddressMultiple",
        component: CommercialAddress,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'business-shareholders',
        name: "businessShareholdersMultiple",
        component: ShareholdersIdentification,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'organization',
        name: "selectCreateOrgBusinessMultiple",
        component: SelectCreateOrgBusiness,
        meta: {
            middleware: [isWalletUser, hasCardRequest]
        },
    }
];
