<template>
  <div class="footer-help responsive">
    <a href class="help-chat" tabindex="">
      <img class="img-fluid" src="@/assets/images/Illustrations/illustration-chat-help.svg" alt="Chat de soporte" />
    </a>
    <p class="help-num">
      También puedes contactarnos a los teléfonos:
      <br/>
      <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>

</style>
