<template>
  <div>
    <vue-snotify></vue-snotify>
    <div class="register-container">
      <div class="left-side-content">
        <div class="container-left">
          <img
            class="img-fluid logo"
            src="@/assets/images/Logos/logo-color.svg"
            alt="Logo Pagando"
          />

          <div class="progress-percent-title only-title">
            <h2 class="title m-l-0">
              Agregar accionista
              <br />
              <strong>Persona Fisica</strong>
            </h2>
          </div>

          <div class="footer-help">
            <a href class="help-chat" tabindex="" aria-label="Chat de soporte">
              <img
                class="img-fluid"
                src="@/assets/images/Illustrations/illustration-chat-help.svg"
                alt="Chat de soporte"
              />
            </a>
            <p class="help-num">
              También puedes contactarnos a los teléfonos:
              <br />
              <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="right-side-content">
        <LoadingBar clazz="fullcontent-loading" v-show="loading" loadingText="Cargando..."></LoadingBar>
        <AccountType
          text="Cuenta seleccionada"
          clazzAccount="account-business"
          business
          :typeAccount="typeAccount"
        >
        </AccountType>
        <div class="container-right" v-show="!loading">
          <TitlePrincipal
            title="Datos personales del accionista"
            subtitle="Completa los siguientes datos."
          >
          </TitlePrincipal>
          <div class="row" v-show="!secondStep">
            <div class="col-12">
              <BasicInput
                required
                v-model="firstPart.name"
                inputType="text"
                inputId="accionista_name"
                label="Nombre(s)"
                inputPlaceholder="Escribe su(s) nombre(s)"
              >
              </BasicInput>
            </div>
            <div class="col-12 col-md-6">
              <BasicInput
                required
                v-model="firstPart.lastName"
                inputType="text"
                inputId="accionista_apellido_paterno"
                label="Primer apellido"
                inputPlaceholder="Escribe su primer apellido"
              >
              </BasicInput>
            </div>
            <div class="col-12 col-md-6">
              <BasicInput
                required
                v-model="firstPart.secondLastName"
                inputType="text"
                inputId="accionista_apellido_materno"
                label="Segundo apellido"
                inputPlaceholder="Escribe su segundo apellido"
              >
              </BasicInput>
            </div>
            <div class="col-12">
              <BasicSelect required label="Género">
                <multiselect
                  v-model="firstPart.gender"
                  :options="genderOptions"
                  :close-on-select="true"
                  :preserve-search="true"
                  placeholder="Selecciona tu género"
                  label="name"
                  track-by="name"
                  :preselect-first="false"
                >
                  <template
                    slot="selection"
                    slot-scope="{ values, search, isOpen }"
                  >
                    <span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} options selected</span
                    >
                  </template>
                </multiselect>
              </BasicSelect>
            </div>
            <div class="col-12">
              <BasicSelect required label="Ocupación">
                <multiselect
                  v-model="firstPart.occupation"
                  :options="occupationOptions"
                  :close-on-select="true"
                  :preserve-search="true"
                  placeholder="Selecciona la ocupación"
                  label="name"
                  track-by="_id"
                  :preselect-first="false"
                >
                  <template
                    slot="selection"
                    slot-scope="{ values, search, isOpen }"
                  >
                    <span
                      class="multiselect__single"
                      v-if="values.length &amp;&amp; !isOpen"
                      >{{ values.length }} options selected</span
                    >
                  </template>
                </multiselect>
              </BasicSelect>
            </div>
            <div class="col-12">
              <h2 class="f-18 c-primary_1 poppins-font-semibold m-b-15">
                Fecha de nacimiento
              </h2>
            </div>
            <div class="col-12 col-md-4">
              <BasicInput
                required
                v-model="firstPart.birthdayDay"
                inputType="text"
                inputId="accionista_day"
                label="Día"
                inputPlaceholder="DD"
              >
              </BasicInput>
            </div>
            <div class="col-12 col-md-4">
              <BasicInput
                required
                v-model="firstPart.birthdayMonth"
                inputType="text"
                inputId="accionista_month"
                label="Mes"
                inputPlaceholder="MM"
              >
              </BasicInput>
            </div>
            <div class="col-12 col-md-4">
              <BasicInput
                required
                v-model="firstPart.birthdayYear"
                inputType="text"
                inputId="accionista_year"
                label="Año"
                inputPlaceholder="AAAA"
              >
              </BasicInput>
            </div>
            <div class="col-12 col-md-6">
              <BasicInput
                required
                v-model="firstPart.percentShare"
                inputType="text"
                inputId="accionista_acciones"
                label="Porcentaje de acciones"
                inputPlaceholder="Escribe el porcentaje"
              >
              </BasicInput>
            </div>
            <div class="col-12 col-md-6">
              <BasicCheckbox
                v-model="firstPart.islegalRepresentative"
                text="¿Es el representante legal?"
                :index="1"
                clazz="m-t-30"
              ></BasicCheckbox>
            </div>
          </div>

          <div v-if="(needsExtraInfo || !canvasMounted) && !secondStep">
            <TitlePrincipal
              clazz="m-t-100"
              title="Documentos"
              subtitle="Carga los siguientes documentos."
            >
            </TitlePrincipal>

            <BasicSwitch class="m-b-20" :useVModel="true" :value="showPassportDocs" label="Pasaporte Mexicano" ref="typeDocument"
              idInput="bs-typeDocument" @onBasicSwitchChange="onChangeTypeDocument"></BasicSwitch>
            <BasicSwitch class="m-b-20" :useVModel="true"  :value="showExtranjeroDocs" label="Extranjero" ref="typeDocumentExtranjero"
              idInput="bs-typeDocumentExtranjero" @onBasicSwitchChange="onChangeTypeDocumentExtranjero"></BasicSwitch>
            <LoadingBar v-if="loadingRequiredDocuments" loadingText="Cargando..."></LoadingBar>

            <DynamicDocs v-if="!loadingRequiredDocuments"
              :docs="documentsFirstPage" @add-file="addFile" :blockLiveness="!(firstPart.islegalRepresentative && !childShareHolder)" @beforeMount="reloadDocs"></DynamicDocs>

            <TitlePrincipal
              clazz="m-t-100"
              title="Lugar de residencia"
              subtitle="Completa los siguientes datos."
            >
            </TitlePrincipal>
            <AddressForm
              v-model="extraPart.address"
              ref="addressForm"
              forShareholder
            />

<!--            <TitlePrincipal-->
<!--              clazz="m-t-100"-->
<!--              title="Información adicional"-->
<!--              subtitle="Debido al tipo de beneficios ofrecidos-->
<!--                            en la cuenta que seleccionaste, es necesario que-->
<!--                            nos ayudes respondiendo las siguientes preguntas."-->
<!--            >-->
<!--            </TitlePrincipal>-->
            <div>
              <AddInfo :is-normal="false" ref="addInfoComponent" :is-legal-representative="firstPart.islegalRepresentative" :shareHolderId="shareHolderId"></AddInfo>
            </div>

            <!-- <div v-show="firstPart.islegalRepresentative && !childShareHolder">
              <TitlePrincipal
                  clazz="m-t-100"
                  title="Firma autógrafa"
                  subtitle="De acuerdo a disposiciones oficiales
                                es necesario que nos proporciones tu firma
                                autógrafa para continuar con tu registro."
              >
              </TitlePrincipal>
              <span class="vertical-center m-b-10">
              <p class="f-18 c-primary_1 poppins-font-semibold d-block m-b-5">
                Realiza tu firma en esta sección
              </p>
              <button
                  type="button"
                  class="button xs button-stroke button-primary m-0-0-0-auto"
                  @click="undoSignature"
              >
                Borrar firma
              </button>
            </span>
              <div>
                <VueSignaturePad
                    id="signaturePad"
                    width="100%"
                    height="300px"
                    ref="signaturePad"
                    :options="optionsPad"
                />
              </div>
            </div> -->

          </div>

          <div v-if="firstPart.islegalRepresentative && !childShareHolder && secondStep">
            <TitlePrincipal
              clazz="m-t-100"
              title="Documentos"
              subtitle="Carga los siguientes documentos."
            >
            </TitlePrincipal>

            <DynamicDocs v-if="!loadingRequiredDocuments" :idShareholder="firstPart._id"
              :docs="livenessDoc" @add-file="addFileLiveness" :blockLiveness="false" @beforeMount="reloadDocsLiveness"></DynamicDocs>

            <div>
              <TitlePrincipal
                  clazz="m-t-100"
                  title="Firma autógrafa"
                  subtitle="De acuerdo a disposiciones oficiales
                                es necesario que nos proporciones tu firma
                                autógrafa para continuar con tu registro."
              >
              </TitlePrincipal>
              <span class="vertical-center m-b-10">
              <p class="f-18 c-primary_1 poppins-font-semibold d-block m-b-5">
                Realiza tu firma en esta sección
              </p>
              <button
                  type="button"
                  class="button xs button-stroke button-primary m-0-0-0-auto"
                  @click="undoSignature"
              >
                Borrar firma
              </button>
            </span>
              <div>
                <VueSignaturePad
                    id="signaturePad"
                    width="100%"
                    height="300px"
                    ref="signaturePad"
                    :options="optionsPad"
                />
              </div>
            </div>

          </div>

          <div class="footer-buttons right">
            <button
              type="button"
              class="button button-ghost button-plain_text"
              name="Regresar"
              @click="back"
            >
              Regresar
            </button>

            <button v-if="(!firstPart.islegalRepresentative || childShareHolder) && !processing"
              type="button"
              class="button button-filled button-primary"
              name="Continuar"
              :disabled="!validForm"
              @click="submit(false)"
            >
              Agregar accionista
            </button>
            <button v-if="firstPart.islegalRepresentative && !processing && !secondStep && !childShareHolder"
              type="button"
              class="button button-filled button-primary"
              name="Continuar"
              :disabled="!validForm"
              @click="submit(true)"
            >
              Siguiente
            </button>
            <button v-if="firstPart.islegalRepresentative && !processing && secondStep"
              type="button"
              class="button button-filled button-primary"
              name="Continuar"
              @click="submitLegal()"
            >
              Guardar
            </button>
            <button
              v-if="processing"
              :id="'Loading button'"
              class="button button-filled button-primary"
            >
              <LoadingSimple clazz="small-load no-text" />
            </button>
          </div>
        </div>
      </div>
      <div class="footer-help responsive">
        <a href class="help-chat" tabindex="" aria-label="Chat de soporte">
          <img
            class="img-fluid"
            src="@/assets/images/Illustrations/illustration-chat-help.svg"
            alt="Chat de soporte"
          />
        </a>
        <p class="help-num">
          También puedes contactarnos a los teléfonos:
          <br />
          <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
        </p>
      </div>
    </div>
    <FooterPage></FooterPage>
    <vue-snotify></vue-snotify>
  </div>
</template>
<script>
import TitlePrincipal from "@/components/TitlePrincipal.vue";
import BasicInput from "@/components/BasicInput.vue";
import BasicSelect from "@/components/BasicSelect.vue";
import Multiselect from "vue-multiselect";
import VueSignaturePad from "vue-signature-pad";
import AccountType from "@/components/AccountType.vue";
import BasicCheckbox from "@/components/BasicCheckbox.vue";
// import CardSelection from "@/components/CardSelection.vue";
import AddressForm from "@/components/AddressForm.vue";
import LoadingSimple from "@/components/LoadingSimple";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import DashboardApi from "@/api/core/dashboard.api";
import LoadingBar from "@/components/LoadingBar.vue";
import FooterPage from "@/components/FooterPage.vue";
import BasicSwitch from "@/components/BasicSwitch.vue";
import AddInfo from "@/pages/onboarding/rightSideSteps/AddInfo";
import { catchError } from "@/utils/utils";

import { mapGetters } from "vuex";
import {
  STORE as ShareholderStore,
  LOAD_PHYSICAL_DOCS,
  GET_PHYSICAL_DOCS,
  GET_SHAREHOLDER,
  LOAD_SHAREHOLDER,
  SET_SHAREHOLDER,
  SET_PHYSICAL_DOCS
} from "@/store/modules/shareholders/shareholders.types";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import {
  STORE as OnboardingStore,
  GET_CARD_REQUEST,
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import DynamicDocs from "@/components/DynamicDocs";
// import { regexRFC } from "@/utils/regexUtils";
import {
  required,
  numeric,
  decimal,
  minValue,
  maxValue,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";
import {EventBus} from "@/main";
import ShareholdersApi from "@/api/core/shareholders.api";

export default {
  name: "NewPhysicalShareholder",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicInput,
    BasicSelect,
    Multiselect,
    VueSignaturePad,
    AccountType,
    BasicCheckbox,
    // CardSelection,
    AddressForm,
    LoadingSimple,
    LoadingBar,
    FooterPage,
    AddInfo,
    DynamicDocs,
    BasicSwitch
  },
  props: {},
  data() {
    const multiselectOptions = {
      genderOptions: [
        { name: "Femenino", value: "FEMALE" },
        { name: "Masculino", value: "MALE" },
      ],
      occupationOptions: [],
    };
    return {
      ...multiselectOptions,
      loadingRequiredDocuments: false,
      firstPart: {
        personType: "PHYSIC",
        name: null,
        lastName: null,
        secondLastName: null,
        gender: null,
        islegalRepresentative: false,
        occupation: null,
        birthdayDay: null,
        birthdayMonth: null,
        birthdayYear: null,
        percentShare: null,
      },
      extraPart: {
        address: {},
      },
      shareHolderId: "",
      files: {},
      filesUploadPercentage: {},
      loading: false,
      processing: false,
      // Vue signature pad
      modifiedSignature: false,
      optionsPad: {
        penColor: "#000",
        onBegin: () => {
          this.modifiedSignature = true;
        },
      },
      canvasMounted: false,
      editMode: false,
      filesAlreadySaved: false,
      childShareHolder: false,
      showPassportDocs: false,
      showExtranjeroDocs: false,
      secondStep: false,
      livenessVideo: {},
      idShareholder: null
    };
  },
  created() {
      this.$store.commit(`${ShareholderStore}/${SET_SHAREHOLDER}`, {});
    if (this.$route.query.parent) {
      this.childShareHolder = true;
    }
  },
  async mounted() {
    this.secondStep = false;

    this.firstPart = {
        personType: "PHYSIC",
        name: null,
        lastName: null,
        secondLastName: null,
        gender: null,
        islegalRepresentative: false,
        occupation: null,
        birthdayDay: null,
        birthdayMonth: null,
        birthdayYear: null,
        percentShare: null,
        _id: null
      };
    this.canvasMounted = true;
    this.loading = true;
    await this.loadOccupationsAndDocs();
    if (this.$route.query.id) {
      this.editMode = true;
      await this.loadShareholder();
    }
    this.loading = false;
  },
  destroyed() {},
  computed: {
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO,
    }),
    ...mapGetters(ShareholderStore, {
      requiredDocs: GET_PHYSICAL_DOCS,
      shareholder: GET_SHAREHOLDER,
    }),
    ...mapGetters(OnboardingStore, {
      cardRequest: GET_CARD_REQUEST,
    }),
    documentsFirstPage() {
      return this.requiredDocs.filter((d) => d.fileDocumentType !== 'IDENTIFICATION_LIVENESS');
    },
    livenessDoc() {
      return this.requiredDocs.filter((d) => d.fileDocumentType === 'IDENTIFICATION_LIVENESS');
    },
    typeAccount() {
      if (this.cardRequest && this.cardRequest.cardTemplate) {
        return this.cardRequest.cardTemplate.name;
      }
      return null;
    },
    needsExtraInfo() {
      const percentNumber = parseFloat(this.firstPart.percentShare);
      return percentNumber >= 25 || this.firstPart.islegalRepresentative;
    },
    validForm() {
      if (!this.needsExtraInfo) {
        return !this.$v.firstPart.$invalid;
      }
      return !this.$v.$invalid && this.hasAllRequiredDocuments;
    },
    validFormLegal() {
      return this.livenessVideo && this.digitalSign
    },
    birthday() {
      const { birthdayDay, birthdayMonth, birthdayYear } = this.firstPart;
      if (birthdayDay && birthdayMonth && birthdayYear) {
        return new Date(
          parseInt(birthdayYear, 10),
          parseInt(birthdayMonth, 10) - 1, // Indexed from 0
          parseInt(birthdayDay, 10)
        );
      }
      return null;
    },
    hasAllRequiredDocuments() {
      if (this.editMode && this.filesAlreadySaved) {
        return true;
      }
      for (const { key, fileDocumentType} of this.documentsFirstPage) {

        if ((fileDocumentType === "IDENTIFICATION_LIVENESS" || fileDocumentType === 'RESIDENCY') && (!this.firstPart.islegalRepresentative || this.childShareHolder)) {
          continue;
        }
        if (!Object.prototype.hasOwnProperty.call(this.files, key)) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    async onChangeTypeDocument(value) {
      this.showPassportDocs = value;
      if(this.showExtranjeroDocs && this.showPassportDocs) {
        this.showExtranjeroDocs = false;
      }
      this.$store.commit(`${ShareholderStore}/${SET_PHYSICAL_DOCS}`, []);
      await this.getRequiredDocuments();
    },
    async onChangeTypeDocumentExtranjero(value) {
      this.showExtranjeroDocs = value;
      if(this.showPassportDocs && this.showExtranjeroDocs) {
        this.showPassportDocs = false;
      }
      this.$store.commit(`${ShareholderStore}/${SET_PHYSICAL_DOCS}`, []);
      await this.getRequiredDocuments();
    },
    onBeginSignature() {
      this.modifiedSignature = true;
    },
    async loadOccupationsAndDocs() {
      await Promise.all([this.getOccupations(), this.getRequiredDocuments()]);
    },
    async loadShareholder() {
      const success = await this.$store.dispatch(
        `${ShareholderStore}/${LOAD_SHAREHOLDER}`,
        { id: this.$route.query.id }
      );
      if (success) {
        // this.loading =false;
        await this.fillForm();
      } else {
        this.displayNotificationWarning(
          "No se pudo cargar el accionista a editar"
        );
      }
    },
    reloadDocs() {
      if (this.shareholder?.fileDocuments?.length) {
        const savedDocs = this.shareholder.fileDocuments.map(
            (doc) => doc.key
        );
        let allSaved = true;
        for (const { key } of this.documentsFirstPage) {
          if (savedDocs.includes(key)) {
            this.$set(this.filesUploadPercentage, key, 100);
            this.$set(this.files, key, this.shareholder.fileDocuments.find((d) => d.key === key))
          } else {
            allSaved = false;
          }
        }
        EventBus.$emit("LOAD-DYNAMIC-DOCS-COM", savedDocs);
        this.filesAlreadySaved = allSaved;
      }
    },
    reloadDocsLiveness() {
      if (this.shareholder?.fileDocuments?.length) {
        const savedDocs = this.shareholder.fileDocuments.map(
            (doc) => doc.key
        );
        let allSaved = true;
        for (const { key } of this.livenessDoc) {
          if (savedDocs.includes(key)) {
            this.$set(this.filesUploadPercentage, key, 100);
            this.$set(this.livenessVideo, key, this.shareholder.fileDocuments.find((d) => d.key === key))
          } else {
            allSaved = false;
          }
        }
        EventBus.$emit("LOAD-DYNAMIC-DOCS-COM", savedDocs);
        this.filesAlreadySaved = allSaved;
      }
    },
    async fillForm() {
      const birthday = new Date(this.shareholder.birthDay);
      this.$set(this.firstPart, "_id", this.shareholder._id);
      this.$set(this.firstPart, "name", this.shareholder.name);
      this.$set(this.firstPart, "lastName", this.shareholder.lastName);
      this.$set(
        this.firstPart,
        "secondLastName",
        this.shareholder.secondLastName
      );
      this.$set(
        this.firstPart,
        "gender",
        this.genderOptions.find(
          (gender) => gender.value === this.shareholder.gender
        )
      );
      this.$set(
        this.firstPart,
        "islegalRepresentative",
        this.shareholder.islegalRepresentative
      );

      this.$set(
        this.firstPart,
        "occupation",
        this.shareholder.occupation
      );
      this.$set(this.firstPart, "birthdayDay", birthday.getDate());
      this.$set(this.firstPart, "birthdayMonth", birthday.getMonth() + 1);
      this.$set(this.firstPart, "birthdayYear", birthday.getFullYear());
      this.$set(this.firstPart, "percentShare", this.shareholder.percentShare || 0);

      this.reloadDocs();

      if (this.shareholder.additionalInfo) {
        this.shareHolderId = this.$route.query.id;
      }

      // this.$set(this.extraPart, "RFC", this.shareholder.RFC);

      // if (this.shareholder.islegalRepresentative && this.shareholder.signBase64 ) {
      //   this.drawSign(0);
      // }

      // if (this.shareholder.signBase64) {
      //   // console.log('Line 601 this.$refs.signaturePad: ', this.$refs.signaturePad);
      //   // this.$refs.signaturePad.clearSignature();
      //   this.$refs.signaturePad.fromDataURL(
      //     `data:image/png;base64,${this.shareholder.signBase64}`
      //   );
      // }

      if (this.shareholder.address) {
        this.extraPart.address = this.shareholder.address;
        // await this.$refs.addressForm.setAddress(this.shareholder.address);
      }
    },
    drawSign(n) {
      setTimeout(() => {
        const sig = this.$refs.signaturePad.$el
        const width = sig.querySelector('canvas').width;
        if (width === 0 || n === 30) {
          this.$refs.signaturePad.resizeCanvas();
          this.$forceUpdate();
          this.drawSign(n + 1);
        } else {
          this.$refs.signaturePad.fromDataURL(
              `data:image/png;base64,${this.shareholder.signBase64}`
          );
        }
      }, 500);
    },
    back() {
      if(this.secondStep) {
        this.secondStep = false;
      } else {
        if(this.$route.name === 'newPhysicalShareholderMultiple') {
          this.$router.push({ name: "shareholdersModificationMultiple", query: {org: this.organization._id || this.shareholder.organizationInfo} });
        } else {
          this.$router.push({ name: "shareholdersModification" });
        }
      }
    },
    // Vue signature pad
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
      this.modifiedSignature = true;
    },
    async getOccupations() {
      const response = await DashboardApi.occupationsList();
      if (response && response.data && !response.data.error) {
        this.occupationOptions = response.data.object;
      } else {
        this.displayNotificationWarning(
          "No se pudo cargar la lista de ocupaciones"
        );
      }
    },
    async getRequiredDocuments() {
      this.loadingRequiredDocuments = true;
      const success = await this.$store.dispatch(
        `${ShareholderStore}/${LOAD_PHYSICAL_DOCS}`
      , {showPassportDocs: this.showPassportDocs, showExtranjeroDocs: this.showExtranjeroDocs});
      this.loadingRequiredDocuments = false;
      if (!success) {
        this.displayNotificationWarning(
          "No se pudo cargar la lista de documentos"
        );
      }
    },
    resizePad() {
      this.$nextTick(() => {
        this.$refs.signaturePad.resizeCanvas();
      })

      if(this.editMode && this.shareholder.islegalRepresentative && this.shareholder.signBase64 ) {
        this.drawSign(0);
      }
      // if (this.firstPart.islegalRepresentative) {
      //   const sig = this.$refs.signaturePad.$el
      //   sig.querySelector('canvas').setAttribute('width', 520)
      //   sig.querySelector('canvas').setAttribute('height', 300)
      // }
    },

    getExtraInfo(params) {
      const data = this.$refs.addInfoComponent.submitShareHolder();
      if (data?.valid) {
          params.extraInfo = {
            values: data.values,
            dynamicOnboarding: data.dynamicOnboarding
          }
          return true;
      }
      return false;
    },
    async submitLegal() {
      const signature = this.checkSignature();
      const params = {
        _id: this.idShareholder,
        organizationInfo: this.organization._id
      }
        if (signature) {
          params.digitalSign = signature;
        } else {
          this.displayNotificationError(
            this.$i18n.t("register.signature.notSign")
          );
        }
        params.livenessVideo = this.livenessVideo;

        const response = await ShareholdersApi.saveLegal(params);

        if(response.data.error) {
          this.toastErrorDefault('Ocurrió un error al guardar información');
        } else {
          this.$store.commit(`${ShareholderStore}/${SET_SHAREHOLDER}`, {});
          this.toastSuccessDefault('Información agregada correctamente');
          this.secondStep = false;
          if(this.$route.name === 'newPhysicalShareholderMultiple') {
            this.$router.push({ name: "shareholdersModificationMultiple", query: {org: this.organization._id} });
          } else {
            this.$router.push({ name: "shareholdersModification" });
          }
        }
    },
    async submit(nextStep = false) {
      this.processing = true;
      // const formData = new FormData();

      const params = {
        ...this.firstPart,
        birthDay: this.birthday,
        percentShare: parseFloat(this.firstPart.percentShare),
        gender: this.firstPart.gender.value,
        occupation: this.firstPart.occupation._id,
        organizationInfo: this.organization._id,
      };
      if (this.needsExtraInfo) {
        const proceed = this.processExtraInfo(params);
        const getInfoData = this.getExtraInfo(params);
        if (!proceed || !getInfoData) {
          this.processing = false;
          this.displayNotificationWarning(this.$i18n.t("register.shareholders.error.addInfo"))
          return;
        }
      }
      if (this.$route.query.parent) {
        params.moralPersonFather = this.$route.query.parent;
      }
      if (this.$route.query.id) {
        params.id = this.$route.query.id;
      }
      // formData.append("json", JSON.stringify(params));
      try {
        const response = await ShareholdersApi.save(params);

        if(response.data.error) {
          this.toastErrorDefault('Ocurrió un error al guardar información');
        } else {
          if(nextStep) {
            this.idShareholder = response.data.object._id;
            this.secondStep = true;
            await this.getRequiredDocuments();
            this.resizePad();
        } else {
          this.$store.commit(`${ShareholderStore}/${SET_SHAREHOLDER}`, {});
          this.toastSuccessDefault('Accionista agregado correctamente');
          if(this.$route.name === 'newPhysicalShareholderMultiple') {
            this.$router.push({ name: "shareholdersModificationMultiple", query: {org: this.organization._id} });
          } else {
            this.$router.push({ name: "shareholdersModification" });
          }

        }

        }
      } catch (error) {
        const errorMsg = error?.response?.data?.message;
        if(errorMsg) {
          this.toastErrorDefault(errorMsg);
        } else {
          this.displayNotificationError(this.$i18n.t('register.shareholders.error.save'));
        }
      }
      this.processing = false;
    },
    processExtraInfo(params) {
      Object.assign(params, this.extraPart);
      params.fileDocuments = this.files;

      // for (const docName in this.files) {
      //   if (Object.prototype.hasOwnProperty.call(this.files, docName)) {
      //     const file = this.files[docName];
      //     formData.append(docName, file);
      //   }
      // }
      return true;
    },
    checkSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        const img = this.processImg(data);
        return {
          name: `sign-legalRep-${this.organization._id}-${new Date().getTime()}`,
          mimetype: "png",
          size: img.length,
          data: img,
        };
      }
      return null;
    },
    processImg(img) {
      if (/^data:image\/png;base64,/.test(img)) {
        return img.replace(/^data:image\/png;base64,/, "");
      }
      return img;
    },
    addFile(data) {
      this.$set(this.files, data.key, data.file);
    },
    addFileLiveness(data) {
      this.$set(this.livenessVideo, data.key, data.file);
    }
  },
  validations: {
    firstPart: {
      name: { required },
      lastName: { required },
      secondLastName: { required },
      gender: { required },
      occupation: { required },
      birthdayDay: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(31),
      },
      birthdayMonth: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(12),
      },
      birthdayYear: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(4),
      },
      percentShare: {
        required,
        decimal,
        minValue: minValue(0),
      },
    },
    extraPart: {
      address: {
        zipCode: { required },
        city: { required },
        state: { required },
        country: { required },
        district: { required, minLength: minLength(3) },
        street: {
          required,
          minLength: minLength(3),
        },
        noExt: { required },
      },
    },
  },
};
</script>
