<template>
  <div>
    <vue-snotify></vue-snotify>
    <div class="register-container">
      <div class="left-side-content">
        <div class="container-left">
          <img
              class="img-fluid logo"
              src="@/assets/images/Logos/logo-color.svg"
              alt="Logo Pagando"
          />

          <div class="progress-percent-title only-title">
            <h2 class="title m-l-0">
              Agregar accionista
              <br/>
              <strong>Persona Moral</strong>
            </h2>
          </div>

          <div class="footer-help">
            <a href class="help-chat" tabindex="" aria-label="Chat de soporte">
              <img
                  class="img-fluid"
                  src="@/assets/images/Illustrations/illustration-chat-help.svg"
                  alt="Chat de soporte"
              />
            </a>
            <p class="help-num">
              También puedes contactarnos a los teléfonos:
              <br/>
              <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
            </p>
          </div>
        </div>
      </div>
      <div class="right-side-content">
        <LoadingBar clazz="fullcontent-loading" v-if="loading" loadingText="Cargando..."></LoadingBar>
        <AccountType
            text="Cuenta seleccionada"
            clazzAccount="account-business"
            business
            :typeAccount="typeAccount"
        >
        </AccountType>
        <div class="container-right" v-show="!loading">
          <!-- PERSONA MORAL -->
          <TitlePrincipal
              title="Información acerca del negocio"
              subtitle="Necesitamos que nos proporciones algunos datos sobre el negocio."
          >
          </TitlePrincipal>
          <div class="row">
            <div class="col-12">
              <BasicInput
                  required
                  v-model="legalName"
                  inputType="text"
                  inputId="company_razonSocial"
                  label="Razón Social"
                  inputPlaceholder="Escribe la razón social"
              >
              </BasicInput>
            </div>
            <div class="col-12">
              <BasicSelect clazz="flag-select " required label="Nacionalidad">
                <multiselect
                    v-model="nationality"
                    :options="countryOptions"
                    placeholder="Selecciona tu nacionalidad"
                    label="nationalitie"
                    track-by="_id"
                    :close-on-select="true"
                    :preserve-search="true"
                    :option-height="104"
                    :custom-label="customLabel"
                    :show-labels="false"
                >
                  <template slot="singleLabel" slot-scope="props">
                    <div class="d-flex align-items-center">
                      <img
                          class="flag-img"
                          :src="getFlagUrl(props.option)"
                          alt="image"
                      />
                      <div class="text">
                        {{ props.option.nationalitie }}
                      </div>
                    </div>
                  </template>
                  <template slot="option" slot-scope="props">
                    <div class="d-flex align-items-center">
                      <img
                          class="flag-img"
                          :src="getFlagUrl(props.option)"
                          alt="image2"
                      />
                      <div class="text">
                        {{ props.option.nationalitie }}
                      </div>
                    </div>
                  </template>
                </multiselect>
              </BasicSelect>
            </div>
            <div class="col-12">
              <BasicInput
                  required
                  v-model="RFC"
                  inputType="text"
                  inputId="company_rfc"
                  label="RFC / TAX ID"
                  inputPlaceholder="Escribe el RFC / TAX ID"
              >
              </BasicInput>
            </div>
            <div class="col-12">
              <BasicSelect required label="Giro del negocio">
                <multiselect
                    v-model="giro"
                    :options="giroOptions"
                    :close-on-select="true"
                    :preserve-search="true"
                    placeholder="Selecciona el giro del negocio"
                    label="description"
                    track-by="_id"
                    :preselect-first="false"
                >
                  <template
                      slot="selection"
                      slot-scope="{ values, search, isOpen }"
                  >
                    <span
                        class="multiselect__single"
                        v-if="values.length &amp;&amp; !isOpen"
                    >{{ values.length }} options selected</span
                    >
                  </template>
                </multiselect>
              </BasicSelect>
            </div>
            <div class="col-12">
              <h2 class="f-18 c-primary_1 poppins-font-semibold m-b-15">
                Fecha de constitución
              </h2>
            </div>
            <div class="col-12 col-sm-4">
              <BasicInput
                  required
                  v-model="day"
                  inputType="text"
                  inputId="constitution_day"
                  label="Día"
                  inputPlaceholder="DD"
              >
              </BasicInput>
            </div>
            <div class="col-12 col-sm-4">
              <BasicInput
                  required
                  v-model="month"
                  inputType="text"
                  inputId="constitution_month"
                  label="Mes"
                  inputPlaceholder="MM"
              >
              </BasicInput>
            </div>
            <div class="col-12 col-sm-4">
              <BasicInput
                  required
                  v-model="year"
                  inputType="text"
                  inputId="constitution_year"
                  label="Año"
                  inputPlaceholder="AAAA"
              >
              </BasicInput>
            </div>
            <div class="col-12 col-md-6">
              <BasicInput
                  required
                  v-model="percentShare"
                  inputType="text"
                  inputId="accionista_acciones"
                  label="Porcentaje de acciones"
                  inputPlaceholder="Escribe el porcentaje"
              >
              </BasicInput>
            </div>
          </div>

          <TitlePrincipal
              title="Domicilio fiscal"
              subtitle="Ingresa los siguientes datos del domicilio del negocio."
              clazz="m-t-60"
          >
          </TitlePrincipal>
          <AddressForm
            v-model="address"
            ref="addressForm"
            forShareholder
            :nationality="nationality"
            />
            <!-- :useDropdowns="!nationality || nationality.isoCode === 'MX'" -->

          <TitlePrincipal
              v-if="percentShare >= 25 && !childShareHolder"
              clazz="m-t-100"
              title="Documentos"
              subtitle="Carga los siguientes documentos."
          ></TitlePrincipal>
          <DynamicDocs v-if="percentShare >= 25 && !childShareHolder" :docs="requiredDocs" @add-file="uploadDoc" @beforeMount="reloadDocs"></DynamicDocs>

          <div class="footer-buttons right">
            <button
                type="button"
                class="button button-ghost button-plain_text"
                name="Regresar"
                @click="back"
            >
              Regresar
            </button>
            <button
                v-if="!processing"
                type="button"
                class="button button-filled button-primary"
                name="Continuar"
                :disabled="!validForm"
                @click="submit"
            >
              {{$t('register.shareholders.addShareholder')}}
            </button>
            <button
                v-else
                :id="'Loading button'"
                class="button button-filled button-primary"
            >
              <LoadingSimple clazz="small-load no-text"/>
            </button>
          </div>
        </div>
      </div>
      <div class="footer-help responsive">
        <a href class="help-chat" tabindex="" aria-label="Chat de soporte">
          <img
              class="img-fluid"
              src="@/assets/images/Illustrations/illustration-chat-help.svg"
              alt="Chat de soporte"
          />
        </a>
        <p class="help-num">
          {{ $t('register.shareholders.notification.footer.support') }}
          <br/>
          <strong>{{ $t('register.shareholders.notification.footer.supportMex') }}</strong>
        </p>
      </div>
    </div>
    <FooterPage></FooterPage>
  </div>
</template>
<script>
// @ is an alias to /src
import TitlePrincipal from "@/components/TitlePrincipal.vue";
import BasicInput from "@/components/BasicInput.vue";
import BasicSelect from "@/components/BasicSelect.vue";
import Multiselect from "vue-multiselect";
import AccountType from "@/components/AccountType.vue";
import AddressForm from "@/components/AddressForm.vue";
import LoadingSimple from "@/components/LoadingSimple";
import LoadingBar from "@/components/LoadingBar.vue";
import FooterPage from "@/components/FooterPage.vue";
import {mapGetters} from "vuex";
import {
  STORE as OnboardingStore,
  GET_CARD_REQUEST,
  LOAD_GIROS,
  GET_GIROS,
  LOAD_COUNTRIES,
  GET_COUNTRIES,
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import {
  STORE as ShareholderStore,
  SAVE_SHAREHOLDER,
  GET_SHAREHOLDER,
  LOAD_SHAREHOLDER, GET_MORAL_DOCS, LOAD_MORAL_DOCS,
} from "@/store/modules/shareholders/shareholders.types";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {regexRFC} from "@/utils/regexUtils";
import {
  required,
  numeric,
  minValue,
  maxValue,
  minLength,
  maxLength,
  requiredIf,
  requiredUnless,
} from "vuelidate/lib/validators";
import DynamicDocs from "@/components/DynamicDocs";
import {EventBus} from "@/main";

const validRfc = (val) => regexRFC.test(val);

export default {
  name: "NewMoralShareholder",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicInput,
    BasicSelect,
    Multiselect,
    AccountType,
    AddressForm,
    LoadingSimple,
    LoadingBar,
    FooterPage,
    DynamicDocs
  },
  props: {},
  data() {
    return {
      loading: false,
      percentShare: null,
      processing: false,
      legalName: null,
      RFC: null,
      giro: null,
      nationality: null,
      day: null,
      month: null,
      year: null,
      address: null,
      files: {},
      filesUploadPercentage: {},
      filesAlreadySaved: false,
      editMode: false,
      childShareHolder: false
    };
  },
  destroyed() {
  },
  computed: {
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO,
    }),
    ...mapGetters(OnboardingStore, {
      cardRequest: GET_CARD_REQUEST,
      giroOptions: GET_GIROS,
      countryOptions: GET_COUNTRIES,
    }),
    ...mapGetters(ShareholderStore, {
      shareholder: GET_SHAREHOLDER,
      requiredDocs: GET_MORAL_DOCS,
    }),
    typeAccount() {
      if (this.cardRequest && this.cardRequest.cardTemplate) {
        return this.cardRequest.cardTemplate.name;
      }
      return null;
    },
    constitutionDay() {
      if (
          this.$v.day.$invalid ||
          this.$v.month.$invalid ||
          this.$v.year.$invalid
      ) {
        return null;
      }
      return new Date(
          parseInt(this.year, 10),
          parseInt(this.month, 10) - 1, // Indexed from 0
          parseInt(this.day, 10)
      );
    },
    validForm() {
      if(this.nationality?.isoCode === 'MX' && !validRfc(this.RFC)) {
        return false;
      }
      if (this.percentShare >= 25 && !this.childShareHolder) {
        return !this.$v.$invalid && this.hasAllRequiredDocuments;
      }
      return !this.$v.$invalid
    },
    hasAllRequiredDocuments() {
      if (this.editMode && this.filesAlreadySaved) {
        return true;
      }
      for (const { key } of this.requiredDocs) {
        if (!Object.prototype.hasOwnProperty.call(this.files, key)) {
          return false;
        }
      }
      return true;
    },
  },
  methods: {
    back() {
      if(this.$route.name === 'newMoralShareholderMultiple') {
        this.$router.push({ name: "shareholdersModificationMultiple", query: {org: this.organization._id} });
      } else {
        this.$router.push({ name: "shareholdersModification" });
      }
    },
    customLabel() {
    },
    getFlagUrl(country) {
      return require(`@/assets/images/Icons/flags/${country.flagName}`);
    },
    async uploadDoc(data) {
      this.$set(this.files, data.key, data.file);
    },
    async getRequiredDocuments() {
      const success = await this.$store.dispatch(
          `${ShareholderStore}/${LOAD_MORAL_DOCS}`
      );
      if (!success) {
        this.displayNotificationWarning(
            "No se pudo cargar la lista de documentos"
        );
      }
    },
    async loadGiros() {
      const success = await this.$store.dispatch(
          `${OnboardingStore}/${LOAD_GIROS}`
      );
      if (!success) {
        this.displayNotificationWarning("No se pudo cargar la lista de giros");
      }
    },
    async loadCountries() {
      const success = await this.$store.dispatch(
          `${OnboardingStore}/${LOAD_COUNTRIES}`
      );
      if (!success) {
        this.displayNotificationWarning(
            "No se pudo cargar la lista de nacionalidades"
        );
      } else {
        const mexico = this.countryOptions.find(country => country.isoCode === 'MX');
        if(mexico) {
          this.nationality = mexico;
        }
      }
    },
    async loadShareholder() {
      const success = await this.$store.dispatch(
          `${ShareholderStore}/${LOAD_SHAREHOLDER}`,
          {id: this.$route.query.id}
      );
      if (success) {
        await this.fillForm();
      } else {
        this.displayNotificationWarning(
            "No se pudo cargar el accionista a editar"
        );
      }
    },
    reloadDocs() {
      if (this.shareholder?.fileDocuments) {
        const savedDocs = this.shareholder.fileDocuments.map(
            (doc) => doc.key
        );
        let allSaved = true;
        for (const { key } of this.requiredDocs) {
          if (savedDocs.includes(key)) {
            this.$set(this.filesUploadPercentage, key, 100);
            this.$set(this.files, key, this.shareholder.fileDocuments.find((d) => d.key === key))
          } else {
            allSaved = false;
          }
        }
        EventBus.$emit("LOAD-DYNAMIC-DOCS-COM", savedDocs);
        this.filesAlreadySaved = allSaved;
      }
    },
    async fillForm() {
      const date = new Date(this.shareholder.constitutionDay);
      this.percentShare = this.shareholder.percentShare;

      this.legalName = this.shareholder.legalName;
      this.RFC = this.shareholder.RFC;
      this.day = date.getDate();
      this.month = date.getMonth() + 1;
      this.year = date.getFullYear();

      this.reloadDocs();

      this.giro = this.giroOptions.find(
          (giro) => giro._id === this.shareholder.giro._id
      );
      this.nationality = this.countryOptions.find(
          (country) => country._id === this.shareholder.nationality
      );
      if (this.$refs.addressForm) {
        await this.$nextTick();
        this.$refs.addressForm.setAddress(this.shareholder.address);
      }
    },
    async submit() {
      this.processing = true;
      const params = {
        personType: "MORAL",
        organizationInfo: this.organization._id,
        legalName: this.legalName,
        RFC: this.RFC,
        giro: this.giro,
        nationality: this.nationality,
        constitutionDay: this.constitutionDay,
        address: this.address,
        percentShare: this.percentShare
      };
      if (this.$route.query.parent) {
        params.moralPersonFather = this.$route.query.parent;
      }
      if (this.$route.query.id) {
        params.id = this.$route.query.id;
      }
      // const formData = new FormData();
      // for (const docName in this.files) {
      //   if (Object.prototype.hasOwnProperty.call(this.files, docName)) {
      //     const file = this.files[docName];
      //     formData.append(docName, file);
      //   }
      // }
      // formData.append("json", JSON.stringify(params));
      if (this.percentShare >= 25) {
        params.fileDocuments = this.files;
      }
      const success = await this.$store.dispatch(
          `${ShareholderStore}/${SAVE_SHAREHOLDER}`,
          params
      );
      this.processing = false;
      if (success) {
        if(this.$route.name === 'newMoralShareholderMultiple') {
          this.$router.push({ name: "shareholdersModificationMultiple", query: {org: this.organization._id} });
        } else {
          this.$router.push({ name: "shareholdersModification" });
        }
      } else {
        this.displayNotificationError(
            "No se pudo guardar la informacion del accionista"
        );
      }
    },
    async initDom() {
      await Promise.all([
        this.loadGiros(),
        this.loadCountries(),
        this.getRequiredDocuments()
      ]);
    }
  },
  created() {
    if (this.$route.query.parent) {
      this.childShareHolder = true;
    }
  },
  async mounted() {
    this.loading = true;
    await this.initDom();
    if (this.$route.query.id) {
      this.editMode = true;
      await this.loadShareholder();
    }
    this.loading = false;
  },
  validations: {
    legalName: {required},
    RFC: {required},
    giro: {required},
    percentShare: {required},
    nationality: {required},
    day: {
      required,
      numeric,
      minValue: minValue(1),
      maxValue: maxValue(31),
    },
    month: {
      required,
      numeric,
      minValue: minValue(1),
      maxValue: maxValue(12),
    },
    year: {
      required,
      numeric,
      minLength: minLength(4),
      maxLength: maxLength(4),
    },
    constitutionDay: {required},
    address: {
      zipCode: {required},
      city: {required},
      state: {
        required: requiredIf(function () {
          return this.nationality?.isoCode === 'MX';
        }),
      },
      stateName: {
        required: requiredUnless(function () {
          return this.nationality?.isoCode === 'MX';
        }),
      },
      country: {required},
      district: {required, minLength: minLength(3)},
      street: {
        required,
        minLength: minLength(3),
      },
      noExt: {required},
    },
  },
};
</script>
