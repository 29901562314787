const TYPE_DATA = {
    IMAGES: "IMAGES",
    TEXT: "TEXT",
    BOOLEAN: "BOOLEAN",
    ENUM: "ENUM",
    UNDEFINED: "UNDEFINED",
    DOCUMENT: "DOCUMENTS"
}

export {
    TYPE_DATA
};
