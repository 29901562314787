<template>
    <div class="row">
        <!-- Pánel de solicitud/activación de tarjeta -->
        <CardRequestStatus></CardRequestStatus>

        <!-- Pánel cuenta autorizada -->
        <div class="col-12" v-if="showBanner()">
            <CardSimple
                clazzTitle="m-b-0"
                title="¡Nueva cuenta autorizada!"
                subtitle="Tu nueva cuenta está lista, firma su contrato para utilizarla."
                showButtons>
                <template v-slot:titleBefore>
                    <img
                        class="img-fluid"
                        :src="accountImageRequest()"
                        width="82"
                        height="50"
                        alt="Pagando" />
                </template>
                <template v-slot:titleButtons>
                    <button
                        @click="getRequestCardPending()"
                        class="button sm button-filled button-primary w-100">
                        <i aria-hidden="true" class="iconsax-content-edit m-r-10"></i>
                        Firmar contrato
                    </button>
                </template>
            </CardSimple>
        </div>

        <div class="col-12" v-if="showSignContract">
            <CardSimple
                clazzTitle="m-b-0"
                :title="user.docsContract.length > 1 ? 'Tienes contratos pendientes de firmar' : 'Tienes un contrato pendiente de firmar'"
                :subtitle="user.docsContract.length > 1 ?
                    `Hemos actualizado los contratos de tus cuentas. Recuerda que tienes 30 dias a partir de que te
                    notificamos para seguir disfrutando de los beneficios de tus cuentas.`
                    : `Hemos actualizado el contrato de tu cuenta. Recuerda que tienes 30 dias a partir de que
                    te notificamos para seguir disfrutando de los beneficios de tus cuentas.`"
                showButtons>
                <!-- <template v-slot:titleBefore>
                    <img
                        class="img-fluid"
                        :src="accountImageRequest()"
                        width="82"
                        height="50"
                        alt="Pagando" />
                </template> -->
                <template v-slot:titleButtons>
                    <button
                        @click="goToSignContract()"
                        class="button sm button-filled button-primary w-100">
                        <i aria-hidden="true" class="iconsax-content-edit m-r-10"></i>
                        {{user.docsContract.length > 1 ? 'Firmar Contratos' : 'Firmar Contrato'}}
                    </button>
                </template>
            </CardSimple>
        </div>

        <div class="col-12 m-b-20">
            <PanelSwiperLink
                v-if="announcements && announcements.length === 1"
                :clazz="announcements[0].relevance === 'IMPORTANT' ? 'important' : ''"
                class="m-b-30"
                :panelId="announcements[0]._id"
                :panelTitle="announcements[0].title[locale]"
                :panelText="announcements[0].message[locale]"
            />

          <div
              class="swiper-panel-link-container"
              v-if="announcements && announcements.length > 1"
          >
            <div class="swiper-container">
              <carousel
                  :perPage="1"
                  :navigationEnabled="false"
                  :paginationEnabled="true"
                  paginationActiveColor="#E40096"
                  paginationColor="#FDF3FA"
              >
                <slide v-for="announcement in announcements" :key="announcement._id">
                  <div class="swiper-slide">
                    <PanelSwiperLink
                        :clazz="announcement.relevance === 'IMPORTANT' ? 'important' : ''"
                        :panelId="announcement._id"
                        :panelTitle="announcement.title[locale]"
                        :panelText="announcement.message[locale]"
                    />
                  </div>
                </slide>
              </carousel>
            </div>
          </div>

        </div>

        <div class="col-12 col-lg-7 col-xl-6 cards-same-height" v-if="loadingMovements">
            <CardSimple hideTitle>
                <EmptyState
                    clazz="horizontal-center"
                    emptyStateSearching
                    :emptyStateDefault="false"
                    text="Buscando"
                ></EmptyState>
            </CardSimple>
        </div>
        <div class="col-12 col-lg-7 col-xl-6 cards-same-height" v-else-if="movementGroups.length === 0">
            <CardSimple hideTitle>
                <EmptyState
                    emptyStateDefault
                    title="Sin historial de movimientos"
                    text="Comienza a utilizar tu cuenta y aquí podrás
                    consultar tu historial del movimientos."
                    :imgUrl="imgEmptystateHistorialMov"
                    :imgWidth="310"
                    :imgHeight="300"
                    imgAlt="Sin historial de movimientos"
                ></EmptyState>
            </CardSimple>
        </div>
        <div class="col-12 col-lg-6 cards-same-height" v-else>
            <CardSimple
                title="Movimientos recientes"
                subtitle="Consulta los movimientos que se han realizado en tu cuenta."
                clazzBody="card-body-no-padding">
                <div v-for="group in movementGroups" v-bind:key="group[0]">
                    <TitleBadge
                        clazzContainer="w-100 m-b-10"
                        clazz="xs text-upper m-b-0"
                        :titleText="parseMovementGroupTitle(group[0])"
                        :badgeText="`${group[1].length}`">
                    </TitleBadge>
                    <router-link to="" class="w-100" tabindex="" v-for="(movement, index) in group[1]" v-bind:key="movement._id">
                        <MovementsList
                            :clazz="`${movement.color} ${index < group[1].length - 1 ? 'mb-0' : ''}`"
                            :icon="movement.icon"
                            :movConcept="movement.movementTitle"
                            :movText="movement.movementDescription">
                            <p v-if="((movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
                                && movement.contaMovementType !== 'CARGO') ||
                                ((movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
                                && movement.contaMovementType !== 'ABONO' && (movement.spentRewardPoints !== 0 || availablePoints < 100))  ||
                                (movement.type !== 'COMPRA_DIGITAL' && movement.type !== 'Compra' && movement.type !== 'COMPRA_CONTACTLESS')"
                            :class="movement.amountClass">{{movement.sign}} {{movement.amount | currency}}</p>
                            <router-link
                                v-if="(movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
                                    && movement.contaMovementType === 'CARGO' && movement.spentRewardPoints === 0 && availablePoints > 100"
                                :to="`/points-pagando/${movement._id}`"
                                :id="`tooltip-pagarPuntos-${movement._id}`"
                                tabindex=""
                                class="button xs button-stroke button-primary m-0-0-0-auto p-l-10 p-r-10">
                                <i aria-hidden="true" class="f-18 iconsax-like-medal-star m-r-5"></i>
                                {{movement.sign}} {{movement.amount | currency}}
                            </router-link>
                            <b-tooltip
                                v-if="(movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
                                    && movement.contaMovementType === 'CARGO' && movement.spentRewardPoints === 0 && availablePoints > 100"
                                :target="`tooltip-pagarPuntos-${movement._id}`"
                                placement="top">
                                <p class="m-b-0">
                                    {{$t('movements.paidWithPoints')}}
                                </p>
                            </b-tooltip>
                        </MovementsList>
                    </router-link>
                </div>
                <router-link to="/movements" class="button xs button-ghost button-text_title m-auto-auto-0" tabindex="">
                    Ver todos mis movimientos
                    <i aria-hidden="true" class="iconsax-arrow-circle-right f-22 c-info m-l-10"></i>
                </router-link>
            </CardSimple>
        </div>
        <div class="col-12 col-lg-5 col-xl-6 cards-same-height" v-if="loadingContacts">
            <CardSimple hideTitle>
                <EmptyState
                    clazz="horizontal-center"
                    emptyStateSearching
                    :emptyStateDefault="false"
                    text="Buscando"
                ></EmptyState>
            </CardSimple>
        </div>
        <div class="col-12 col-lg-5 col-xl-6 cards-same-height" v-else-if="recentContacts.length === 0">
            <CardSimple hideTitle>
                <EmptyState
                    emptyStateDefault
                    :title="$t('contacts.dashboardEmptyStateTitle')"
                    :text="$t('contacts.dashboardEmptyStateDes')"
                    :imgUrl="imgEmptystateContactosRec"
                    :imgWidth="308"
                    :imgHeight="250"
                    imgAlt="Sin contactos recientes"
                ></EmptyState>
                <router-link :to="{ name: 'Contacts'}" class="button xs button-ghost button-text_title m-auto-auto-0" tabindex="">
                    {{$t('contacts.dashboardAdd')}}
                    <i aria-hidden="true" class="iconsax-arrow-circle-right f-22 c-info m-l-10"></i>
                </router-link>
            </CardSimple>
        </div>
        <div class="col-12 col-lg-6 cards-same-height" v-else>
            <CardSimple
                title="Contactos recientes"
                subtitle="Haz transferencias rápidas
                a tus contactos recientes."
                clazzBody="card-body-no-padding">
                <RecentContacts v-for="contact in recentContacts" v-bind:key="contact._id" :textPrincipal="contact.alias" :text="contact.text">
                    <template v-slot:user v-if="contact.isPagandoAccount">
                        <UserAccount
                            clazz="xs"
                            :userImg="imageDownloadLink(contact.image) || imgUserAccount"
                            :widthImg="42"
                            :heightImg="42"
                            altImgUser="(614) 298 2629">
                        </UserAccount>
                    </template>
                    <template v-slot:user v-else>
                        <UserAccount
                            :clazz="`xs ${getContactColorClass(contact)}`"
                            noAccount
                            :userName="getContactInitials(contact)">
                        </UserAccount>
                    </template>
                    <template v-slot:action>
                        <div class="show-on-hover d-md-flex d-none" @click="transferToContact(contact)">
                            <router-link to="" class="button xs button-ghost button-primary p-l-5 p-r-5">
                                Enviar fondos
                            </router-link>
                        </div>
                        <button id="" class="button-circle xs button-ghost button-primary d-md-none" type="button" name="Enviar fondos" aria-label="Enviar fondos">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right f-22"></i>
                        </button>
                    </template>
                </RecentContacts>
                <router-link :to="{ name: 'Contacts'}" class="button xs button-ghost button-text_title m-auto-auto-0 m-t-30" tabindex="">
                    {{$t('contacts.dashboardAll')}}
                    <i aria-hidden="true" class="iconsax-arrow-circle-right f-22 c-info m-l-10"></i>
                </router-link>
            </CardSimple>
        </div>
        <div class="col-12" v-if="false">
            <CardSimple hideTitle>
                <EmptyState
                    emptyStateDefault
                    title="Sin reporte de movimientos semanales"
                    text="Aquí podrás consultar las estadísticas
                    de tus abonosy cargos semanales cuando realices
                    tu primer transacción o depósito a tu cuenta."
                    :imgUrl="imgEmptystateMovSem"
                    :imgWidth="430"
                    :imgHeight="320"
                    imgAlt="Sin reporte de movimientos semanales"
                ></EmptyState>
            </CardSimple>
        </div>
        <div class="col-12">
            <CardSimple
                title=" Movimientos semanales"
                showButtons>
                <template v-slot:titleButtons>
                    <div class="daterange-table d-none d-md-flex">
                        <button @click="subtractWeek"><i aria-hidden="true" class="iconsax-arrow-direction-left"></i></button>
                        <date-range-picker
                            ref="picker"
                            opens="right"
                            :showDropdowns="false"
                            :autoApply="true"
                            :showWeekNumbers="true"
                            :linked-calendars="false"
                            :ranges="false"
                            singleDatePicker="range"
                            v-model="week"
                            @hoverDate="onHover"
                            @startSelection="onClickDate"
                            :locale-data="{
                                weekLabel: '#',
                                daysOfWeek: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
                                monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
                                'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
                            }"
                            >
                            <!-- eslint-disable -->
                            <template v-slot:input="picker">
                                <span>{{moment(week.startDate).format('ll')}} - {{moment(week.endDate).format('ll')}}</span>
                            </template>
                            <!-- eslint-enable -->
                        </date-range-picker>
                        <button @click="addWeek"><i aria-hidden="true" class="iconsax-arrow-direction-right"></i></button>
                    </div>
                </template>
                <div class="graph-container">
                    <div class="money-legend">
                        <div class="abonos">
                            <span>Abonos de la semana</span>
                            {{totalAbonos | currency}}
                        </div>
                        <div class="cargos">
                            <span>Cargos de la semana</span>
                            {{totalCargos | currency}}
                        </div>
                    </div>
                    <apexchart
                        width="100%"
                        height="330px"
                        type="bar"
                        :options="chartMovimientos"
                        :series="seriesMovimientos"
                        class=""
                    ></apexchart>
                </div>
            </CardSimple>
        </div>
    </div>
</template>

<script>
import CardSimple from "@/components/CardSimple.vue";
import EmptyState from "@/components/EmptyState.vue";
import imgEmptystateHistorialMov from '@/assets/images/Emptystates/emptystate-movimientos.svg';
import imgEmptystateContactosRec from '@/assets/images/Emptystates/emptystate-contactos-recientes.svg';
import imgEmptystateMovSem from '@/assets/images/Emptystates/emptystate-mov-semanales.svg';
import TitleBadge from "@/components/TitleBadge.vue";
import MovementsList from "@/components/MovementsList.vue";
import DateRangePicker from "vue2-daterange-picker";
import RecentContacts from "@/components/RecentContacts.vue";
import UserAccount from "@/components/UserAccount.vue";
import PanelSwiperLink from "@/components/PanelSwiperLink.vue";
import imgUserAccount from '@/assets/images/Default/profile-photo.svg';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import RewardPointsApi from '@/api/wallet/rewardPoints.api';
import moment from 'moment';
import {mapGetters} from "vuex";
import {EventBus} from "@/main";
import { jsNumFix, formatMoney, catchError, imageDownloadLink } from "@/utils/utils";
import { getContactColorClass, getContactInitials } from "@/utils/contactUtils";
import CardRequestStatus from "@/pages/MyAccount/CardRequestStatus";
import CardApi from '@/api/wallet/card.api';
import ContactsApi from '@/api/wallet/contacts.api';
import NoveltiesApi from '@/api/core/novelties.api';
import {CARD_DEFAULT_IMG} from "@/enums/defalutCardImg.enum";
import { GET_CARD_REQUEST, STORE as onbngStore} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
    STORE as sessionStore,
    GET_ACCOUNT,
    SET_ACCOUNT,
    GET_USER,
} from "@/store/modules/session/session.types";
import { Carousel, Slide } from 'vue-carousel';

const IconMoneyReceive = 'iconsax-money-receive';

export default {
    name: "Dashboard",
    mixins: [ToastSnotify],
    components: {
      Carousel,
      Slide,
        CardSimple,
        EmptyState,
        TitleBadge,
        MovementsList,
        DateRangePicker,
        RecentContacts,
        UserAccount,
        CardRequestStatus,
        PanelSwiperLink,
    },
    data () {
        return {
            availablePoints: 0,
            // Images
            imgEmptystateHistorialMov: imgEmptystateHistorialMov,
            imgEmptystateContactosRec: imgEmptystateContactosRec,
            imgEmptystateMovSem: imgEmptystateMovSem,
            // Chart Bar
            chartMovimientos: {
                chart: {
                    id: 'vuechart-movimientos',
                    toolbar: {
                        show: false
                    }
                },
                legend: {
                    position: 'top',
                    offsetY: 40,
                    markers: {
                        width: '18px',
                        height: '18px',
                        strokeWidth: 0,
                        fillColors: ['#BFF7FF', '#FF3D72'],
                        radius: 3,
                        customHTML: undefined,
                        onClick: undefined,
                        offsetX: 0,
                        offsetY: 0
                    },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 3,
                        horizontal: false,
                        columnWidth: 40,
                        endingShape: 'rounded',
                        labels: {
                            show: false,
                        }
                    }
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 10,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: [],
                    labels: {
                        style: {
                            colors: ['#5F6185', '#5F6185', '#5F6185', '#5F6185', '#5F6185', '#5F6185', '#5F6185']
                        }
                    }
                },
                fill: {
                    opacity: 1,
                    colors: ['#BFF7FF', '#FF3D72']
                },
                tooltip: {
                    followCursor: false,
                    fillSeriesColor: true,
                    y: {
                        formatter: function(value){
                            return formatMoney(value);
                        }
                    }
                },
                states: {
                    normal: {
                        filter: {
                            type: 'none',
                        }
                    },
                    hover: {
                        filter: {
                            type: 'none',
                        }
                    },
                    active: {
                        allowMultipleDataPointsSelection: false,
                        filter: {
                            type: 'none',
                        }
                    },
                },
                responsive: [{
                    breakpoint: 768,
                    options: {
                        xaxis: {
                            labels: {
                                rotate: -90
                            },
                            categories: [],
                        },
                        stroke: {
                            width: 3,
                        },
                    },
                }]
            },
            seriesMovimientos: [
                {
                    name: 'Abonos',
                    data: ['0', '0', '0', '0', '0', '0', '0']
                }, {
                    name: 'Cargos',
                    data: ['0', '0', '0', '0', '0', '0', '0']
                }
            ],
            imgUserAccount: imgUserAccount,
            // totalAbonos: 0,
            // totalCargos: 0,
            movementGroups: [],
            recentContacts: [],
            week: {},
            loadingMovements: false,
            loadingContacts: false,
            showBanners: false,
            banners: [],
            announcements: [],
        }
    },
    computed: {
        ...mapGetters(sessionStore, {
            account: GET_ACCOUNT,
            user: GET_USER
        }),
        ...mapGetters(onbngStore, {
            cardRequest: GET_CARD_REQUEST,
        }),
        totalAbonos() {
            return this.seriesMovimientos[0].data.reduce((sum, a) => sum+jsNumFix(a), 0);
        },
        totalCargos() {
            return this.seriesMovimientos[1].data.reduce((sum, a) => sum+jsNumFix(a), 0);
        },
        locale() {
            return this.$i18n.locale;
        },
        showSignContract() {
            return this.user.contract === 1 || this.user.contract === 3;
        },
    },
    methods: {
        imageDownloadLink,
        getContactColorClass,
        getContactInitials,
        moment,
        goToSignContract() {
            this.$router.push({ name: 'UpdateContract'});
        },
        accountImageRequest() {
            const defaultImage = CARD_DEFAULT_IMG.pagandoExplore;
            if(!this.account || !this.account.requestFound) {
                return defaultImage;
            }

            switch (this.account.requestFound.product) {
                case "EXPLORE":
                    return CARD_DEFAULT_IMG.pagandoExplore;
                case "EXPLORE_PLUS":
                    return CARD_DEFAULT_IMG.pagandoExplorePlus;
                case "NIGHT":
                    return CARD_DEFAULT_IMG.pagandoNight;
                case "BUSINESS":
                    return CARD_DEFAULT_IMG.pagandoBusiness;
                case "BUSINESS_PRO":
                    return CARD_DEFAULT_IMG.pagandoBusinessPro;
                default:
                    return defaultImage;
            }
        },
        async getPoints(){
            const res = await RewardPointsApi.getDetails(this.account.account._id).catch(catchError);
            if(res && res.data && !res.data.error) {
                this.availablePoints = res.data.object.totalPoints.toLocaleString();
            } else {
                this.availablePoints = 0;
            }
        },
        async getCardMovements() {
            try {
                this.loadingMovements = true;
                const response = await CardApi.getMovements({
                    accountId: this.account.account._id,
                    perPage: 3
                });
                const movements = response.data.object.docs;
                movements.forEach(movement => {
                    if(!movement.date) {
                        movement.date = movement.createdAt;
                    }
                })

                // Agrupa los movimientos en días
                const groups = {};
                movements.forEach(movement => {
                    const date = moment(movement.date).startOf('day').toString();
                    if(!Object.prototype.hasOwnProperty.call(groups, date)) {
                        groups[date] = [];
                    }
                    groups[date].push(this.parseMovement(movement));
                })

                // Ordena los grupos de movimientos (más reciente primero)
                this.movementGroups = Object.entries(groups).sort((a, b) => {
                    const groupA = moment(a[0]);
                    const groupB = moment(b[0]);
                    return groupB.diff(groupA)
                });

                // Ordena los movimientos de cada grupo (más reciente primero)
                this.movementGroups.forEach(group => {
                    group[1].sort((a, b) => {
                        const movementA = moment(a.date);
                        const movementB = moment(b.date);
                        return movementB.diff(movementA)
                    })
                })
                this.loadingMovements = false;
            } catch (error) {
                this.toastErrorDefault(error);
                this.loadingMovements = false;
            }
        },
        parseMovement(movement) {
            let sign = '';
            let amountClass = [];
            if(movement.contaMovementType === 'ABONO') {
                sign = '+';
                amountClass = ['c-success'];
            } else if(movement.contaMovementType === 'CARGO') {
                sign = '-';
            }

            if(movement.type === 'LOST_CHARGEBACK') {
                amountClass = ['c-error'];
            }

            const time = moment(movement.date).format('hh:mm a');
            const movementDescription = `${time} - ${movement.movementDescription}`;

            let icon = 'iconsax-money-card';
            let color = 'mov-purchase';
            switch (movement.type) {
                case "Compra":
                case "COMPRA_DIGITAL":
                case "COMPRA_CASHBACK":
                case "COMPRA_CONTACTLESS":
                    icon = 'iconsax-money-card';
                    color = 'mov-purchase';
                    break;
                case "Devolución":
                    icon = 'iconsax-arrow-undo';
                    color = 'mov-refund';
                    break;
                case "Retiro":
                case "SPEIOUT":
                    icon = 'iconsax-money-send';
                    color = 'mov-sent';
                    break;
                case "Deposito":
                case "SPEIIN":
                case "CASHIN":
                    icon = IconMoneyReceive;
                    color = 'mov-received';
                    break;
                case "Transferencia":
                    icon = movement.movementKey === 'PT' ? IconMoneyReceive : 'iconsax-money-send';
                    color = movement.movementKey === 'PT' ? 'mov-received' : 'mov-sent';
                    break;
                case "Dispersion":
                    icon = IconMoneyReceive;
                    break;
                case "Transacción":
                case"LOST_CHARGEBACK":
                case "COMMISSION":
                case "Consulta":
                default:
                    break;
            }

            const timeAgo = moment(movement.date).fromNow();

            return {
                ...movement,
                sign,
                amountClass,
                movementDescription,
                icon,
                color,
                timeAgo,
            };
        },
        parseMovementGroupTitle(date) {
          let newDate;
          try {
              newDate = new Date(date);
          } catch(err) {
              newDate = new Date();
          }
          const today = moment();
          if(today.isSame(newDate, 'day')) {
              return this.$i18n.t('general.datesCommon.today');
          }
          return moment(newDate).format('dddd ll').toString();
        },
        async getContacts() {
            try {
                this.loadingContacts = true;
                const response = await ContactsApi.listRecent({accountId: this.account.account._id});
                if(response.data.object) {
                    this.recentContacts = response.data.object.map(contact => {
                        if(contact.isPagandoAccount) {
                            contact.text = contact.phone;
                        } else {
                            contact.text = contact.clabe ? `${contact.bank.shortName} - ${contact.clabe}` : contact.bank.shortName;
                        }
                        return contact;
                    });
                }
                this.loadingContacts = false;
            } catch (error) {
                this.toastErrorDefault(error);
                this.loadingContacts = false;
            }
        },
        async getInfoForChart() {
            try {
                const response = await CardApi.getMovementsByDateRange({
                    ...this.week,
                    accountId: this.account.account._id,
                });

                if(response.data.object) {
                    const days = response.data.object;
                    const abonos = [];
                    const cargos = [];

                    const curr = moment(this.week.startDate).startOf('day');
                    const end = moment(this.week.endDate).startOf('day');
                    const categories = [];
                    do {
                        categories.push([
                            curr.clone().format('ddd'),
                            curr.clone().format('DD MMM'),
                        ]);
                        const day = days.find(day => moment(day.date).startOf('day').isSame(curr));
                        abonos.push(day ? jsNumFix(day.abonos) : 0);
                        cargos.push(day ? jsNumFix(day.cargos) : 0);
                    } while(curr.add(1, 'd').diff(end) <= 0);
                    this.$set(this.chartMovimientos.xaxis, 'categories', categories);
                    this.$set(this.chartMovimientos.responsive[0].options.xaxis, 'categories', categories);
                    this.chartMovimientos = {...this.chartMovimientos};
                    this.$set(this.seriesMovimientos[0], 'data', abonos);
                    this.$set(this.seriesMovimientos[1], 'data', cargos);
                }
            } catch (error) {
                this.toastErrorDefault(error);
            }
        },
        onHover(date) {
            this.$refs.picker.start = moment(date).day(0).toDate();
            this.$refs.picker.end = moment(date).day(6).toDate();
        },
        onClickDate(date) {
            const startDate = moment(date).day(0).toDate();
            const endDate = moment(date).day(6).toDate();
            this.$refs.picker.start = startDate;
            this.$refs.picker.end = endDate;
            this.$refs.picker.in_selection = false;
            this.$refs.picker.togglePicker(false, true);
            this.week = {startDate, endDate};
        },
        subtractWeek() {
            this.onClickDate(moment(this.week.startDate).subtract(7, 'days'))
        },
        addWeek() {
            this.onClickDate(moment(this.week.startDate).add(7, 'days'))
        },
        transferToContact(contact) {
            this.$router.push({
                name: 'NewTransfer',
                params: {
                    contact: {
                        _id: contact._id,
                        alias: contact.alias,
                        contactName: contact.contactName,
                        isPagandoAccount: contact.isPagandoAccount,
                        bank: contact.bank?.shortName || 'Pagando',
                        number: contact.number,
                        image: contact.image,
                    }
                }
            });
        },
        async getBanners() {
            const res = await NoveltiesApi.getBanners().catch(catchError);

            if(res && res.data && !res.data.error) {
                this.banners = res.data.object;
            } else {
                this.toastErrorDefault('Ocurrió un error al obtener banners');
            }
        },
        /**
         * Carga los avisos que se muestran en el dashboard
         */
        async getAnnouncements() {
            this.announcements = [];
            try {
                const response = await NoveltiesApi.getAnnouncements();
                this.announcements = response.data.object;
            } catch (error) {
                this.toastErrorDefault(error);
            }
        },
        /**
         * Carga los datos del dashboard. Se ejecuta al abrir el dashboard y cada vez que se cambia de cuenta.
         */
        loadDashboard() {
            this.onClickDate(new Date())
            this.getCardMovements();
            this.getContacts();
            this.getPoints();
            this.getAnnouncements();
        },
        showBanner() {
            return !!(this.account.requestFound &&
                (this.account.requestFound.requestCase === 'VIRTUAL_CONTRACT' || this.account.requestFound.requestCase === 'CORPORATIVE_REQUEST'));
        },
        async getRequestCardPending() {

            await this.$store.dispatch(`${onbngStore}/getProvisionalCards`, false);

            switch(this.cardRequest.stepOnboarding){
                case 'SELECT_DOCUMENT':
                case 'UPLOAD_DOCUMENT':
                this.$router.push({name: 'selectDocumentsMultiple'});
                break;
                case 'ADD_DATA':
                this.$router.push({name: 'addInfoMultiple'});
                break;
                case 'CONTRACT':
                    if(this.cardRequest.accountType === "BUSINESS"){
                        this.$router.push({name: 'businessContractMultiple'});
                    }else{
                        this.$router.push({name: 'contractMultiple'});
                    }
                break;
                case 'WAIT_REQUEST':
                case 'PENDING_WEBHOOK':
                this.$router.push({name: 'processingRequestMultiple'});
                break;
                default:
                this.$router.push({name: 'selectAccountMultiple'});
                break;
            }
        },
    },
    async mounted() {
        moment.locale(this.$i18n.locale);
        if(this.account.account._id) {
            this.loadDashboard();
        }
        EventBus.$on(`${sessionStore}.${SET_ACCOUNT}`, this.loadDashboard);

        this.showBanners = localStorage.getItem('emergentNotification');

        if(this.showBanners === 'true') {
            await this.getBanners();
        }

    },
    beforeDestroy () {
        EventBus.$off(`${sessionStore}.${SET_ACCOUNT}`);
    },
    watch: {
        week: function () {
            this.getInfoForChart();
        },
    },
};
</script>
