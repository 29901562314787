<template>
  <div class="right-side-content">
    <div class="container-right p-t-20" id="container-right">
      <img class="img-fluid logo floating m-t-30" src="@/assets/images/Logos/logo-color.svg" alt="Logo Pagando"/>
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "RightSideLogin"
}
</script>

<style scoped>

</style>
