<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/services-payment"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                   {{$t('services.summary.return')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('services.summary.title')"></FloatingTitleLayout>
                        </div>

                        <LoadingBar v-if="loading" :loadingText="$t('services.loading')"></LoadingBar>


                        <div class="col-12 col-md-6" v-if="!loading && !hideProccess && !showNoDebt">
                            <PanelService
                                :imgServiceUrl="getImage()"
                                :serviceName="service ? service.organization.name : '-'"
                                :serviceDescr="service ? service.name : '-'">
                            </PanelService>

                            <CardSimple
                                :title="$t('services.summary.paymentMethod')"
                                clazzTitle="m-b-15">
                                <AccountType
                                    :typeAccount="typeAccount"
                                    clazz="p-relative imgLeft">
                                    <img class="img-fluid" :src="accountImage()" width="64" height="40" :alt="account.account.accountType.description"/>
                                    <template v-slot:extraText>
                                        <p class="f-11 c-text_content poppins-font-regular f-400 m-b-0">
                                            {{$t('services.summary.available')}}
                                            <strong class="c-primary poppins-font-bold f-700">{{card.balance | currency}}</strong>
                                        </p>
                                    </template>
                                </AccountType>

                                <router-link :to="{name: 'Deposit'}" class="btn-link-goto warning m-t-15" tabindex="" v-if="checkTotal()">
                                    <p>
                                        {{$t('services.summary.insufficientFunds')}}
                                        <br/>
                                        <strong>
                                           {{$t('services.summary.depositAccount')}}
                                        </strong>
                                    </p>
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-right"></i>
                                </router-link>
                            </CardSimple>

                            <div class="collapse-beneficiary">
                                <div class="head flex-wrap-no">
                                    <span>
                                        <p class="text-only">
                                            {{$t('services.summary.infoPayment')}}
                                        </p>
                                    </span>
                                    <span>
                                        <button
                                            id=""
                                            class="button-square xs button-stroke button-accent"
                                            type="button"
                                            name="Información del pago"
                                            aria-label="Información del pago"
                                            v-b-toggle.collapse-info-pago>
                                            <i aria-hidden="true" class="iconsax-arrow-direction-down"></i>
                                            <i aria-hidden="true" class="iconsax-arrow-direction-up"></i>
                                        </button>
                                    </span>
                                </div>
                                <b-collapse id="collapse-info-pago" class="body">
                                    <ReadOnlyInfo v-for="(param, index) in params" :key="index"
                                        :title="param.description"
                                        :text="param.value"
                                    ></ReadOnlyInfo>
                                    <ReadOnlyInfo v-for="(reference, index) in balance.references" :key="index+'w'"
                                        :title="reference.name"
                                        :text="reference.value"
                                    ></ReadOnlyInfo>
                                </b-collapse>
                            </div>

                            <b-alert class="alert-note" show variant="info">
                                <p class="text-container">
                                    <i
                                        aria-hidden="true"
                                        class="icon iconsax-emails-message-bold"
                                        ></i>
                                    <span v-html="$t('services.summary.warning')"></span>
                                </p>
                            </b-alert>
                        </div>

                        <div class="col-12 col-md-6" v-if="!loading && !hideProccess && !showNoDebt">
                            <CardSimple clazz="border-top-primary" title="Detalle de tu pago" v-if="!showCoupons">
                                <div class="vertical-center w-100 justify-space-between m-b-10">
                                    <label class="f-12 c-text_info poppins-font-semibold f-600 text-left m-b-0">
                                        {{$t('services.summary.subtotal')}}
                                    </label>
                                    <p class="f-12 c-text_title poppins-font-semibold f-600 text-right m-b-0">
                                        {{balance.subTotal | currency}}
                                    </p>
                                </div>
                                <div class="vertical-center w-100 justify-space-between m-b-10">
                                    <label class="f-12 c-text_info poppins-font-semibold f-600 text-left m-b-0">
                                        {{$t('services.summary.commission')}}
                                    </label>
                                    <p class="f-12 c-text_title poppins-font-semibold f-600 text-right m-b-0">
                                        {{balance.commission | currency}}
                                    </p>
                                </div>
                                <div class="vertical-center w-100 justify-space-between m-b-10" v-if="codeApplied">
                                    <label class="f-12 c-text_info poppins-font-semibold f-600 text-left m-b-0">
                                        {{$t('services.summary.codeApplied')}}
                                        <br/>
                                        (<a class="c-info hover" tabindex="" @click="openDeleteCode()">{{$t('services.summary.delete')}}</a>)
                                    </label>
                                    <p class="f-12 c-text_title poppins-font-semibold f-600 text-right m-b-0">
                                        -{{codeApplied.amount | currency}}
                                    </p>
                                </div>
                                <div class="border-dashed-divider"></div>
                                <div class="vertical-center w-100 justify-space-between">
                                    <label class="f-16 c-text_title poppins-font-semibold f-600 text-left m-b-0">
                                        {{$t('services.summary.total')}}
                                    </label>
                                    <p class="f-26 c-primary poppins-font-bold f-700 text-right m-b-0">
                                        {{getTotal() | currency}}
                                    </p>
                                </div>
                                <div class="border-dashed-divider"></div>
                                <button type="button" class="btn-link-goto info" v-if="!codeApplied" @click="showCodeInput()">
                                    <p>
                                        {{$t('services.summary.promotionalCode')}}
                                        <br/>
                                        <strong>
                                            {{$t('services.summary.haveCode')}}
                                        </strong>
                                    </p>
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-right"></i>
                                </button>
                                <button type="button" class="button sm button-filled button-primary w-100 m-t-30" disabled v-if="checkTotal()">
                                    {{$t('services.summary.insufficientFunds')}}
                                </button>
                                <button type="button" class="button sm button-filled button-primary w-100 m-t-30" v-if="!checkTotal()" @click="pay()">
                                    {{$t('services.summary.doPayment')}}
                                </button>
                                <p class="f-10 c-text_content poppins-font-medium f-500 m-b-0 m-t-20 w-100 d-flex align-items-c justify-content-center">
                                    <i aria-hidden="true" class="iconsax-money-card-security f-22 c-success m-r-10"></i>
                                    {{$t('services.summary.messageInfo')}}
                                </p>
                            </CardSimple>

                            <CardSimple hideTitle v-if="showCoupons">
                                <button type="button" class="button xs button-ghost button-text_content p-l-5 p-r-5" @click="goBackCodeValidation()">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left m-r-10"></i>
                                    {{$t('services.summary.back')}}
                                </button>

                                <img v-if="!codeIsValid"
                                    class="img-fluid d-flex m-0-auto"
                                    src="@/assets/images/Illustrations/illustration-canjear-codigo.svg"
                                    width="290"
                                    height="170"
                                    alt="Canjea tu código promocional" />
                                <img v-else
                                    class="img-fluid d-flex m-0-auto"
                                    src="@/assets/images/Illustrations/illustration-codigo-canjeado.svg"
                                    width="308"
                                    height="170"
                                    alt="Canjea tu código promocional" />

                                <h2 class="f-16 c-text_title poppins-font-bold f-700 text-center m-t-10 m-b-5" v-html="$t('services.summary.exchangeCode')">
                                </h2>
                                <p class="f-12 c-text_title poppins-font-regular f-400 text-center">
                                    {{$t('services.summary.exchangeCodeMessage')}}
                                </p>

                                <span class="input-codigo-promo" :class="codeWasCheck ? codeIsValid ? 'success' : 'error' : ''" v-if="!loadingCode">
                                    <small v-if="codeIsValid && codeWasCheck">{{$t('services.summary.validateCodeSuccess')}}</small>
                                    <small v-if="!codeIsValid && codeWasCheck">{{$t('services.summary.validateCodeError')}}</small>
                                    <input type="text" class="" :placeholder="$t('services.summary.enterCode')" v-model="code" name="" value=""/>
                                </span>

                                <LoadingBar v-if="loadingCode" :loadingText="$t('services.loading')"></LoadingBar>

                                <button type="button" class="button sm button-filled button-primary w-100 m-t-20"
                                    :disabled="!code || code.length === 0" @click="validateCode()" v-if="!codeIsValid && !loadingCode">
                                    {{$t('services.summary.exchangeCodeButton')}}
                                </button>

                                <button type="button" class="button sm button-filled button-primary w-100 m-t-20"
                                    v-if="codeIsValid && !loadingCode" @click="goBackCodeValidation()">
                                    {{$t('services.summary.back')}}
                                </button>
                            </CardSimple>
                        </div>

                        <!-- ESTATUS INFO -->
                        <div class="col-12" v-if="showNoDebt">
                            <CardSimple
                                hideTitle>
                                <StatusNote
                                    status="info"
                                    :title="$t('services.summary.payNoDebt.title')"
                                    :text="$t('services.summary.payNoDebt.text')"
                                    :imgWidth="124"
                                    :imgHeight="124"
                                    imgAlt="NoDebt"
                                    :imgUrl="imgInfoStatus">
                                </StatusNote>
                            </CardSimple>
                            <div class="control-buttons buttons-full-w-xs">
                                <button
                                    type="button"
                                    class="button button-stroke button-primary">
                                    <i aria-hidden="true" class="iconsax-emails-messages-2 m-r-10"></i>
                                    {{$t('services.summary.payNoDebt.support')}}
                                </button>
                                <button @click="finalize()"
                                    type="button"
                                    class="button button-filled button-primary">
                                    {{$t('services.summary.payNoDebt.goBack')}}
                                </button>
                            </div>
                        </div>

                        <!-- ESTATUS SUCCESS -->
                        <div class="col-12" v-if="!loading && showPaymentSuccess && !showNoDebt">
                            <CardSimple
                                hideTitle>
                                <StatusNote
                                    status="successs"
                                    :title="$t('services.summary.paySuccess.title')"
                                    :text="$t('services.summary.paySuccess.text')"
                                    :imgWidth="284"
                                    :imgHeight="280"
                                    imgAlt="Pago exitoso">
                                    <p class="f-16 c-info poppins-font-bold f-700 text-center m-b-0">
                                        <u>{{email}}</u>
                                    </p>
                                </StatusNote>
                            </CardSimple>
                            <div class="control-buttons buttons-full-w-xs">
                                <button
                                    type="button"
                                    @click="downloadReceipt()"
                                    class="button button-stroke button-primary">
                                    <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
                                    {{$t('services.summary.paySuccess.download')}}
                                </button>
                                <button @click="finalize()"
                                    type="button"
                                    class="button button-filled button-primary">
                                    {{$t('services.summary.paySuccess.finalize')}}
                                </button>
                            </div>
                        </div>

                        <!-- ESTATUS ERROR -->
                        <div class="col-12" v-if="!loading && showPaymentError && !showNoDebt">
                            <CardSimple
                                hideTitle>
                                <StatusNote
                                    status="error"
                                    :title="$t('services.summary.payError.title')"
                                    :text="$t('services.summary.payError.text')"
                                    :imgWidth="124"
                                    :imgHeight="124"
                                    imgAlt="Pago fallido"
                                    :imgUrl="imgErrorStatus">
                                    <p class="f-18 c-text_title poppins-font-semibold f-600 text-center m-b-0 m-t-30">
                                        {{errorMessage}}
                                    </p>
                                </StatusNote>
                            </CardSimple>
                            <div class="control-buttons buttons-full-w-xs">
                                <button
                                    type="button"
                                    class="button button-stroke button-primary">
                                    <i aria-hidden="true" class="iconsax-emails-messages-4 m-r-10"></i>
                                    {{$t('services.summary.payError.support')}}
                                </button>
                                <button
                                    type="button"
                                    class="button button-filled button-primary" @click="pay()">
                                    {{$t('services.summary.payError.retry')}}
                                </button>
                            </div>
                        </div>

                        <!-- ESTATUS INFO -->
                        <div class="col-12 d-none">
                            <CardSimple
                                hideTitle>
                                <StatusNote
                                    status="info"
                                    title="Servicio sin adeudo"
                                    text="El servicio que estás consultando no tiene un adeudo en este momento.
                                    Si esto no es correcto, intenta consultar el servicio más tarde."
                                    :imgWidth="124"
                                    :imgHeight="124"
                                    imgAlt="Servicio sin adeudo"
                                    :imgUrl="imgInfoStatus">
                                </StatusNote>
                            </CardSimple>
                            <div class="control-buttons buttons-full-w-xs">
                                <button
                                    type="button"
                                    class="button button-stroke button-primary">
                                    <i aria-hidden="true" class="iconsax-emails-messages-4 m-r-10"></i>
                                    {{$t('services.summary.payError.support')}}
                                </button>
                                <button
                                    type="button"
                                    class="button button-filled button-primary">
                                    Volver al listado de servicios
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <ModalAlert
            customRef="modal-delete-code"
            closeCross
            modalId="modal-delete-code"
            buttonType="button-primary"
            :imgUrl="imageModalTrash"
            :title="$t('services.summary.deleteCode.title')"
            :text="$t('services.summary.deleteCode.text')"
            :buttonText="$t('services.summary.deleteCode.button')"
            @submit="deleteCode()"
        ></ModalAlert>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>
    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import PanelService from "@/components/PanelService.vue";
    import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
    import AccountType from "@/components/AccountType.vue";
    import LoadingBar from "@/components/LoadingBar.vue";
    import StatusNote from "@/components/StatusNote.vue";
    import ModalAlert from "@/components/ModalAlert.vue";
    import imgErrorStatus from '@/assets/images/Illustrations/illustration-status-error.svg';
    import imgInfoStatus from "@/assets/images/Illustrations/illustration-status-info.svg";
    import CardApi from '@/api/wallet/card.api';
    import CouponsApi from '@/api/core/coupons.api';
    import { imageDownloadLink, catchError } from "@/utils/utils";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import {GET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";
    import {mapGetters} from "vuex";

    import pagandoExplore from "@/assets/images/Cards/pagando-explore.svg"
    import pagandoExplorePlus from "@/assets/images/Cards/pagando-explore-plus.svg";
    import pagandoNight from "@/assets/images/Cards/pagando-night.svg";
    import pagandoBusiness from "@/assets/images/Cards/pagando-business.svg";
    import pagandoBusinessPro from "@/assets/images/Cards/Accounts/businesspro-virtual-front.svg";
    import WSApi from '@/api/wallet/ws.api';
    import TransactionHistoryApi from '@/api/core/transactionHistory.api';
    import imageModalTrash from "@/assets/images/Icons/icon-trash-pink.svg";

    export default {
        name: "ServicesPaymentSummary",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            // BasicInput,
            CardSimple,
            PanelService,
            ReadOnlyInfo,
            AccountType,
            // CodeInput,
            LoadingBar,
            StatusNote,
            ModalAlert
        },
        computed: {
            ...mapGetters(sessionStore, {
                account: GET_ACCOUNT,
            }),
            typeAccount() {
                return this.account.account.accountType.description;
            }
        },
        props: {
            balance: {},
            service: {},
            serviceId: null,
            showNoDebt: {type: Boolean, default: false}
        },
        data() {
            return {
                // Images
                imgErrorStatus: imgErrorStatus,
                imgInfoStatus: imgInfoStatus,
                imageModalTrash,
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
                cards: [],
                card: {
                    balance: 0,
                    product: null,
                    image: null
                },
                codeApplied: null,
                showCoupons: false,
                code: null,
                codeIsValid: false,
                codeWasCheck: false,
                loading: false,
                loadingCode: false,
                showPaymentSuccess: false,
                showPaymentError: false,
                hideProccess: false,
                errorMessage: null,
                email: null,
                movementId: null,
                params: []
            }
        },
        methods: {
            imageDownloadLink,
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            openDeleteCode() {
                this.$bvModal.show('modal-delete-code');
            },
            getImage() {
                if(this.service) {
                    if(this.service.images.length > 0) {
                       const img = this.service.images[0]
                       if(img) {
                           return img;
                       }
                    }
                    return this.service.organization.image;
                }

                return null;

            },
            async getCards() {
                for(const card of this.account.cards) {
                    if(card.card.manufacturingType === 'VIRTUAL') {
                        await this.getCardData(card.card);
                        this.card = card.card;
                        this.card.product = card.cardTemplate.product;
                        this.card.image = card.cardTemplate.image;
                    }
                }
            },
            async getCardData(card) {
                const response = await CardApi.getAccountInfo({
                    paymentSourceId: card._id,
                    provider: this.account.account.externalProvider,
                }).catch(catchError);

                if(response && response.data && !response.data.error) {
                    card.balance = response.data.object.balance
                } else {
                    this.toastErrorDefault(this.$t('services.summary.errors.getBalance'));
                }
            },
            accountImage() {
                const defaultImage = pagandoExplore;


                switch (this.card.product) {
                    case "EXPLORE":
                        return pagandoExplore;
                    case "EXPLORE_PLUS":
                        return pagandoExplorePlus;
                    case "NIGHT":
                        return pagandoNight;
                    case "BUSINESS":
                        return pagandoBusiness;
                    case "BUSINESS_PRO":
                        return pagandoBusinessPro;
                    default:
                        if(this.card.image) {
                            return imageDownloadLink(this.card.image);
                        }
                        return defaultImage;
                }
            },
            checkTotal() {
                if(this.card.balance >= this.getTotal()) {
                    return false;
                }

                return true;
            },
            showCodeInput() {
                this.showCoupons = true;
            },
            async validateCode() {
                this.loadingCode = true;
                const obj = {code: this.code, paymentId: this.serviceId, type: this.service.type, amount: this.balance.total};

                const res = await CouponsApi.validateCode(obj).catch(catchError);

                this.codeWasCheck = true;

                if(res && res.data && !res.data.error) {
                    this.codeIsValid = true;
                    this.codeApplied = res.data.object;
                } else {
                    this.codeIsValid = false;
                }

                this.loadingCode = false;

            },
            getTotal() {
                if(this.codeApplied) {
                    return this.balance.total - this.codeApplied.amount;
                }
                return this.balance ? this.balance.total : 0;
            },
            goBackCodeValidation() {
                this.showCoupons = false;
                this.codeIsValid = false;
                this.codeWasCheck = false;
                if(!this.codeApplied) {
                    this.code = null;
                }
            },
            deleteCode() {
                this.codeApplied = null;
                this.$bvModal.hide('modal-delete-code');
            },
            async pay() {
                const auth = await this.$requestAuth();

                if(auth) {
                    this.hideProccess = true;
                    this.loading = true;
                    const fields = [];
                    for(const field of this.service.params) {
                        const obj = {
                            name: field.name,
                            value: field.value
                        }
                        fields.push(obj);
                    }
                    const obj = {cardId: this.card._id, paymentId: this.serviceId, fields: fields};
                    if(this.codeApplied) {
                        obj.couponCode = this.code;
                    }
                    const res = await WSApi.payment(obj).catch(catchError);

                    if(res && res.data && !res.data.error) {
                        this.showPaymentSuccess = true;
                        this.showPaymentError = false;
                        this.email = res.data.object.email;
                        this.movementId = res.data.object.processCode;
                    } else {
                        this.showPaymentError = true;
                        this.showPaymentSuccess = false;
                        this.errorMessage = res.data ? res.data.message : 'Error';
                    }

                    this.loading = false;

                }
            },
            finalize() {
                this.$router.push({name: 'ServicesPayment'});
            },
            async downloadReceipt() {
                TransactionHistoryApi.receipts(this.movementId).then(async (response) => {
                    const wnd = window.open("about:blank", "", "_blank");
                    wnd.document.write(response.data);
                }).catch(error => {
                    this.toastErrorDefault(error);
                });
            }
        },
        async mounted () {
            this.loading = true;
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);

            if(this.balance && this.service) {
                const params = [...this.service.params];

                for(const field of this.balance.references) {
                    const find = params.findIndex(p => p.name === field.name);

                    if(find !== -1) {
                        params.splice(find, 1);
                    }
                }
                this.params = params;
                await this.getCards();
            } else {
                if(!this.showNoDebt) {
                    this.toastErrorDefault(this.$t('services.summary.errors.getDetails'));
                    this.$router.push({name: 'ServicesPayment'});
                }
            }

            this.loading = false;

        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
