<template>
    <div>
        <vue-snotify/>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    :aria-label="$t('transfers.new.showMenu')"
                    :name="$t('transfers.new.showMenu')"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        :aria-label="$t('transfers.new.hideMenu')"
                        :name="$t('transfers.new.hideMenu')"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/wallet/transfer-list"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('transfers.new.returnToList')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('transfers.new.title')"></FloatingTitleLayout>
                        </div>
                        <div class="col-12">
                            <NewTransferBeneficiary
                                v-if="currentStep >= 1"
                                :visible="currentStep === 1"
                                @nextStep="nextStep"
                                @stepBack="returnToTransfersList"
                                :step="currentStep" />
                            <NewTransferAmount
                                v-if="currentStep >= 2"
                                :visible="currentStep === 2"
                                :contact="selectedContact"
                                :preamount="amount"
                                :preconcept="concept"
                                @nextStep="nextStep"
                                @stepBack="currentStep--"/>
                            <NewTransferSummary
                                v-if="currentStep >= 3"
                                :visible="currentStep === 3"
                                :contact="selectedContact"
                                :amount="amount"
                                :concept="concept"
                                @nextStep="nextStep"
                                @stepBack="currentStep--"/>
                            <NewTransferSuccessDetails
                                v-if="currentStep === 4 && transferStatus == 'success'"
                                :contact="selectedContact"
                                :amount="amount"
                                :concept="concept"
                                :transferDetails="transferDetails"
                                @finish="finishTransfer"/>
                            <NewTransferPendingDetails
                                v-if="currentStep === 4 && transferStatus == 'pendingAuthorization'"
                                :contact="selectedContact"
                                :amount="amount"
                                :concept="concept"
                                :transferDetails="transferDetails"
                                @finish="finishTransfer"/>
                            <NewTransferErrorDetails
                                v-if="currentStep === 4 && transferStatus == 'error'"
                                :contact="selectedContact"
                                :amount="amount"
                                :concept="concept"
                                :transferError="transferError"
                                @retry="retryTransfer"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>
    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import NewTransferBeneficiary from "@/pages/Transfers/NewTransferBeneficiary.vue";
    import NewTransferAmount from "@/pages/Transfers/NewTransferAmount.vue";
    import NewTransferSummary from "@/pages/Transfers/NewTransferSummary.vue";
    import NewTransferSuccessDetails from "@/pages/Transfers/NewTransferSuccessDetails.vue";
    import NewTransferPendingDetails from "@/pages/Transfers/NewTransferPendingDetails.vue";
    import NewTransferErrorDetails from "@/pages/Transfers/NewTransferErrorDetails.vue";

    export default {
        name: "NewTransfer",
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            NewTransferBeneficiary,
            NewTransferAmount,
            NewTransferSummary,
            NewTransferSuccessDetails,
            NewTransferPendingDetails,
            NewTransferErrorDetails,
        },
        data() {
            return {
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
                currentStep: 1,
                selectedContact: null,
                amount: null,
                concept: null,
                transferStatus: null,
                transferDetails: null,
                transferError: null,
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            nextStep(event) {
                switch(this.currentStep) {
                    case 1: // From beneficiary to amount
                        this.selectedContact = event;
                        break;
                    case 2: // From amount to summary
                        this.amount = event.amount;
                        this.concept = event.concept;
                        break;
                    case 3: // From summary
                        if(event.transferError) {
                            this.transferError = event.transferError.message || event.transferError;
                            this.transferStatus = 'error';
                        } else {
                            this.transferDetails = event.transferDetails;
                            this.transferStatus = event.status === 202 ? 'pendingAuthorization' : 'success';
                        }
                        break;
                    default:
                        break;
                }
                this.currentStep++;
            },
            returnToTransfersList() {
                this.$router.push({name: 'TransfersList'});
            },
            retryTransfer() {
                this.currentStep = 2;
            },
            finishTransfer() {
                this.$router.push({name: 'TransfersList'});
            },
        },
        created () {
            if(this.$attrs) {
                if(this.$attrs.resend) {
                    this.amount = this.$attrs.transfer.amount;
                    this.concept = this.$attrs.transfer.concept;
                    this.selectedContact = this.$attrs.transfer.contact;

                    if(this.selectedContact) {
                        this.currentStep = 2;
                    }
                } else if(this.$attrs.showSuccess) {
                    this.amount = this.$attrs.transfer.amount;
                    this.concept = this.$attrs.transfer.concept;
                    this.selectedContact = this.$attrs.transfer.contact;
                    this.transferDetails = this.$attrs.transfer.transferDetails;
                    this.transferStatus = 'success';
                    this.currentStep = 4;
                } else if(this.$attrs.contact) {
                    this.selectedContact = this.$attrs.contact;
                    this.currentStep = 2;
                }
            }
        },
        mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        },
    };
</script>
