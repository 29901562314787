<template>
    <div class="form-group" :class="`${clazz} ${errorClass}`">
    <textarea class="form-control textarea-counter"
              :class="textAreaClazz"
              rows="5"
              :id="textareaId"
              :placeholder="textareaPlaceholder"
              @input="textInput"
              @blur="onBlur"
              v-model="internalValue"
              :disabled="disabled"
              ref="textArea"
            ></textarea>
        <div class="word-counter" v-if="maxCount > 0"><small :class="{'c-error': countError }">{{remainingCount}}</small>
            / <small>{{maxCount}}</small>
        </div>

        <div class="input-label">
            <label class="" :class="labelClass" :for="textareaId">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i v-if="warningIcon" aria-hidden="true" :class="warningIcon"></i>
            {{warningText}}
        </small>
    </div>
</template>

<script>
    export default {
        name: 'BasicTextarea',
        props: {
            help: {type: Boolean, default: false},
            idHelpTooltip: String,
            required: {type: Boolean, default: false},
            label: String,
            labelClass: String,
            helpText: String,
            warningText: String,
            textareaPlaceholder: String,
            helpIcon: String,
            warningIcon: String,
            disabled: {type: Boolean, default: false},
            textareaId: String,
            clazz: String,
            textAreaClazz: String,
            value: String,
            inputValue: {type: String, default: null},
            initialCount: {type: Number, default: 0},
            maxCount: {type: Number, default: 200},
            validations: {},
            validationsNamespace: {
                type: String,
                default: "general.validations"
            },
        },
        data() {
            return {
                message: '',
                internalValue: '',
            }
        },
        watch: {
            value(newVal){
                this.internalValue = newVal;
            }
        },
        methods: {
            textInput(event) {
                this.internalValue = event.target.value;
                this.$emit('input', this.internalValue);
                // if (this.validations) {
                //     debounce(() => {
                //         this.validations.$touch();
                //     });
                // }
            },
            onBlur() {
                this.$emit('blur')
            },
            reloadInternalValue(value){
                this.internalValue = value;
                this.$refs.textArea.value = value;
                this.$forceUpdate();
            }
        },
        computed: {
            errorClass () {
                return this.hasError || (this.validations && this.validations.$error) ? 'has-error' : '';
            },
            // warningTextComputed () {
            //     if (this.validations) {
            //         const errorKey = Object
            //             .keys(this.validations)
            //             .find(k => k && this.validations.hasOwnProperty(k) && k.indexOf('$') === -1 && !this.validations[k]);
            //         if (errorKey) {
            //             const params = this.validations.$params[errorKey];
            //             return this.$t(`${this.validationsNamespace}${this.validationsNamespace ? '.' : ''}${errorKey}`, params);
            //         }
            //         return null;
            //     }
            //     return null;
            // },
            // warningIconComputed () {
            //     if (this.validations) {
            //         const errorKey = Object
            //             .keys(this.validations)
            //             .find(k => k && this.validations.hasOwnProperty(k) && k.indexOf('$') === -1 && !this.validations[k]);
            //         return errorKey ? 'banana banana-warning-circle1' : null;
            //     }
            //     return null;
            // },
            remainingCount() {
                return this.maxCount - this.internalValue.length;
            },
            countError() {
                return this.remainingCount < 0;
            }
        },
        beforeMount() {
            this.internalValue = this.value || '';
        },
    }
</script>
