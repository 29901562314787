import Vue from "vue";
import Router from "vue-router";

// ROUTES CHILD
import onbPersonalRoutes from "@/routes/onbWeb/onbPersonal.routes";
import onbPersonalMultipleRoutes from "@/routes/onbWeb/onbPersonalMultiple.routes";
import onbMoralRoutes from "@/routes/onbWeb/onbBusiness.routes";
import onbMoralMultipleRoutes from "@/routes/onbWeb/onbBusinessMultiple.routes";
import onbCollaboratorRoutes from "@/routes/onbWeb/onbCollaborator.routes.js";
import loginRoutes from "@/routes/Login/login.routes";
import DispersionsRoutes from "@/routes/dispersions/dispersions.routes";
import UxRoutes from "@/routes/ux.routes";
import PinUnification from "@/pages/Login/PinUnification";
import MigrationDynamicCVV from "@/pages/Login/MigrationDynamicCVV";

import WalletLayout from "@/pages/WalletLayout.vue";
import LivenessMovil from "@/pages/LivenessMovil.vue";
import NotFound from "@/pages/NotFound.vue";
import SelectAccount from '@/views/Register/SelectAccount.vue';
import OnboardingPersonal from '@/views/Register/OnboardingPersonal';
import OnboardingMoral from '@/views/Register/OnboardingMoral';
import OnboardingCollaborator from '@/views/Register/OnboardingCollaborator';
import Login from "@/views/Login/Login";
// import temporalLogin from "@/views/temporalLogin";
import ShareholdersModification from "@/pages/onboarding/businessRightSideSteps/ShareholdersModification";
import NewMoralShareholder from "@/pages/onboarding/businessRightSideSteps/NewMoralShareholder";
import NewPhysicalShareholder from "@/pages/onboarding/businessRightSideSteps/NewPhysicalShareholder";
import Dashboard from "@/views/Dashboard.vue";
import TransfersList from "@/views/TransfersList.vue";
import MyAccount from "@/views/MyAccount.vue";
import AccOperationalLimits from "@/views/AccOperationalLimits.vue";
import MyPoints from "@/views/MyPoints.vue";
import Deposit from "@/views/Deposit.vue";
import CashDeposit from "@/views/CashDeposit.vue";

// PAGES
import PhonePageQr from "@/pages/onboarding/rightSideSteps/phonePageQr";
// import beforeMount from "@/utils/initVue";
import NewTransfer from "@/pages/Transfers/NewTransfer.vue";
import NewTransferDetails from "@/pages/Transfers/NewTransferDetails.vue";
import AddBeneficiaries from "@/pages/MyAccountTabs/AddBeneficiaries.vue";
import Dispersions from "@/pages/Dispersions/Dispersions";
import Movements from "@/pages/Movements.vue";
import UpdateContract from "@/pages/UpdateContract.vue";
import CollaboratorsList from "@/pages/Collaborators/CollaboratorsList.vue";
import Contacts from "@/pages/Contacts";
import BulkContactsList from "@/pages/BulkContactsList";
import BulkContactsNew from "@/pages/BulkContactsNew";
import BulkContactsDetails from "@/pages/BulkContactsDetails";
import BaseLayout from "@/pages/BaseLayout";
import PagandoPoints from "@/pages/PagandoPoints.vue";
import CardActivation from "@/pages/Cards/CardActivation.vue";
import CardRequest from "@/pages/Cards/CardRequest.vue";
import CardShippingStatus from "@/pages/Cards/CardShippingStatus.vue";
import Settings from "@/pages/Settings/Settings.vue";
import PersonalInformation from "@/pages/Settings/PersonalInformation.vue";
import InviteFriends from "@/pages/Settings/InviteFriends.vue";
import Notifications from "@/pages/Settings/Notifications.vue";
import Security from "@/pages/Settings/Security.vue";
import SecurityChangePass from "@/pages/Settings/SecurityChangePass.vue";
import Contact from "@/pages/Settings/Contact.vue";
import ServicesPayment from "@/pages/Services/ServicesPayment.vue";
import ServicesPaymentSummary from "@/pages/Services/ServicesPaymentSummary.vue";
import PromotionList from "@/pages/Promotions/PromotionList.vue";
import PromotionDetails from "@/pages/Promotions/PromotionDetails.vue";
import ReplaceCard from "@/pages/Cards/ReplaceCard.vue";
import AdditionalCards from "@/pages/AdditionalCards/AdditionalCards.vue";
import AdditionalCardNew from "@/pages/AdditionalCards/AdditionalCardNew.vue";
import NewCorporativeAccount from "@/pages/CorporativeAccounts/NewCorporativeAccount.vue";
import EditCorporativeAccount from "@/pages/CorporativeAccounts/EditCorporativeAccount.vue";
import CorporativeAccountList from "@/pages/CorporativeAccounts/CorporativeAccountList.vue";
import CorporativeAccountDetail from "@/pages/CorporativeAccounts/CorporativeAccountDetail.vue";
import CorporativeActivity from "@/pages/CorporativeAccounts/CorporativeActivity.vue";
import CorporativeActivityMovements from "@/pages/CorporativeAccounts/CorporativeActivityMovements.vue";
import Announcement from "@/pages/Announcement.vue";

// Middlewares
import accountPersonal from "./middleware/accountPersonal";
import isWalletUser from "./middleware/isWalletUser";
import accountBusiness from "./middleware/accountBusiness";
import hasNotUserSession from "./middleware/hasNotUserSession";
import hasUserSession from "./middleware/hasUserSession";
import hasPermissionTo from "./middleware/hasPermission";
import { needsPinUnification, needsMigrationCVV } from "./middleware/missingMigration.js";
import UxBase from "@/pages/uxBase";
import needUpdateContract from "./middleware/needUpdateContract";

// import { GET_IS_AUTH } from "./store/modules/session/session.types";

Vue.use(Router);


const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [
        // UX/UI Views/Pages
        {
            path: "/uxcomponents",
            component: UxBase,
            children: UxRoutes,
        },
        // Login
        {
            path: "/login",
            meta: {
                middleware: [hasNotUserSession]
            },
            component: Login,
            children: loginRoutes
        },
        // Pin Unification
        {
            path: "/unify-pin",
            meta: {
                middleware: [isWalletUser, needsPinUnification]
            },
            component: Login,
            children: [
                {
                    path: '',
                    name: "PinUnification",
                    component: PinUnification
                },
            ],
        },
        // Pin Unification
        {
            path: "/migrate-cvv",
            meta: {
                middleware: [isWalletUser, needsMigrationCVV]
            },
            component: Login,
            children: [
                {
                    path: '',
                    name: "MigrationDynamicCVV",
                    component: MigrationDynamicCVV,
                },
            ],
        },
        {
            path: '/create-new-account',
            name: 'createNewAccount',
            component: SelectAccount,
            meta: {
                middleware: [isWalletUser]
            },
            props: true
        },
        {
            path: '/update-contract',
            name: 'UpdateContract',
            component: UpdateContract,
            meta: {
                middleware: [needUpdateContract]
            },
            props: true
        },
        // Register
        {
            path: '/register-account',
            name: 'registerAccount',
            component: SelectAccount,
            meta: {
                middleware: [hasNotUserSession]
            }
        },
        {
            path: '/account-type',
            name: 'selectAccountType',
            component: SelectAccount,
            meta: {
                middleware: [hasUserSession]
            }
        },
        {
            path: '/personal',
            name: 'multiplePersonal',
            meta: {
                middleware: [accountPersonal]
            },
            component: OnboardingPersonal,
            children: onbPersonalMultipleRoutes
        },
        {
            path: '/negocios',
            name: 'multipleBusiness',
            meta: {
                middleware: [accountBusiness]
            },
            component: OnboardingMoral,
            children: onbMoralMultipleRoutes
        },
        // Onboarding
        {
            path: '/onboarding-personal',
            meta: {
                middleware: [accountPersonal]
            },
            component: OnboardingPersonal,
            children: onbPersonalRoutes
        },
        // Onboarding business
        {
            path: '/onboarding-business',
            component: OnboardingMoral,
            meta: {
                middleware: [accountBusiness]
            },
            children: onbMoralRoutes
        },
        // Onboarding Colaborador
        {
            path: '/onboarding-collaborator',
            meta: {
                middleware: [accountPersonal]
            },
            component: OnboardingCollaborator,
            children: onbCollaboratorRoutes
        },
        // Shareholders
        {
            path: '/shareholders-modification',
            name: 'shareholdersModification',
            component: ShareholdersModification,
            meta: {
                middleware: [hasUserSession, accountBusiness]
            },
        },
        {
            path: '/physical-shareholder',
            name: 'newPhysicalShareholder',
            component: NewPhysicalShareholder,
            meta: {
                middleware: [hasUserSession, accountBusiness]
            },
        },
        {
            path: '/moral-shareholder',
            name: 'newMoralShareholder',
            component: NewMoralShareholder,
            meta: {
                middleware: [hasUserSession, accountBusiness]
            },
        },
        {
            path: '/shareholders-modification-multi',
            name: 'shareholdersModificationMultiple',
            component: ShareholdersModification,
            meta: {
                middleware: [isWalletUser, accountBusiness]
            },
        },
        {
            path: '/physical-shareholder-multi',
            name: 'newPhysicalShareholderMultiple',
            component: NewPhysicalShareholder,
            meta: {
                middleware: [isWalletUser, accountBusiness]
            },
        },
        {
            path: '/moral-shareholder-multi',
            name: 'newMoralShareholderMultiple',
            component: NewMoralShareholder,
            meta: {
                middleware: [isWalletUser, accountBusiness]
            },
        },
        {
            path: "/liveness-movil",
            name: "LivenessMovil",
            component: LivenessMovil
        },
        // Transfers
        {
            path: "/transfer-new",
            name: "NewTransfer",
            component: NewTransfer,
            props: (route) => route.params,
            meta: {
                middleware: [isWalletUser, hasPermissionTo('REQUEST', 'TRANSFERS')]
            },
        },
        {
            path: "/transfer-new-details",
            name: "NewTransferDetails",
            component: NewTransferDetails,
            meta: {
                middleware: [isWalletUser, hasPermissionTo('READ', 'TRANSFERS')]
            },
        },
        //
        {
            path: "/wallet",
            component: WalletLayout,
            meta: {
                middleware: [isWalletUser]
            },
            children: [
                {
                    path: '',
                    redirect: 'dashboard'
                },
                {
                    path: 'dashboard',
                    name: 'Dashboard',
                    component: Dashboard,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
                {
                    path: 'transfer-list',
                    name: 'TransfersList',
                    component: TransfersList,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
                {
                    path: 'my-account',
                    name: 'MyAccount',
                    component: MyAccount,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
                {
                    path: 'my-points',
                    name: 'MyPoints',
                    component: MyPoints,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
                // Beneficiaries
                {
                    path: "add-beneficiaries/:mode",
                    name: "AddBeneficiaries",
                    component: AddBeneficiaries,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
                // Depositar a cuenta
                {
                    path: "depositar-a-cuenta",
                    name: "Deposit",
                    component: Deposit,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
            ]
        },
        // Rutas que solo requieren el sideBar
        {
            path: "/pages",
            component: BaseLayout,
            meta: {
                middleware: [isWalletUser]
            },
            children: [
                {
                    path: 'contacts',
                    name: 'Contacts',
                    component: Contacts,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
                {
                    path: 'bulk-contacts',
                    name: 'BulkContactsList',
                    component: BulkContactsList,
                    meta: {
                        middleware: [isWalletUser, hasPermissionTo('READ', 'BENEFICIARIES', {only: ['CLIENTS_ACCOUNT']})]
                    },
                },
                {
                    path: 'bulk-contacts/new',
                    name: 'BulkContactsNew',
                    component: BulkContactsNew,
                    meta: {
                        middleware: [isWalletUser, hasPermissionTo('REQUEST', 'BENEFICIARIES', {only: ['CLIENTS_ACCOUNT']})]
                    },
                },
                {
                    path: 'bulk-contacts/details/:id',
                    name: 'BulkContactsDetails',
                    component: BulkContactsDetails,
                    meta: {
                        middleware: [isWalletUser, hasPermissionTo('READ', 'BENEFICIARIES', {only: ['CLIENTS_ACCOUNT']})]
                    },
                },
                // Solicitar tarjeta física
                {
                    path: "card-request",
                    name: "CardRequest",
                    component: CardRequest,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
                // Estatus de envío de tarjeta física
                {
                    path: "card-shipping-status",
                    name: "CardShippingStatus",
                    component: CardShippingStatus,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
                // Activación de tarjeta
                {
                    path: "card-activation",
                    name: "CardActivation",
                    component: CardActivation,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
                // Replace card
                {
                    path: "/replace-card",
                    name: "ReplaceCard",
                    component: ReplaceCard,
                    meta: {
                        middleware: [isWalletUser]
                    }
                },
                {
                    path: 'my-account-limits',
                    name: 'MyAccountLimits',
                    component: AccOperationalLimits,
                    meta: {
                        middleware: [isWalletUser]
                    },
                },
            ]
        },

        {
            path: "/phone-liveness/:token",
            name: "phonePageQr",
            component: PhonePageQr
        },
        {
            path: "/not-found",
            name: "NotFound",
            component: NotFound
        },
        { path: "*", component: NotFound },
        // Dispersions
        {
            path: "/dispersions",
            component: Dispersions,
            meta: {
                middleware: [
                    isWalletUser,
                    hasPermissionTo('READ', 'DISPERSIONS', {only: ['CLIENTS_ACCOUNT']})
                ]
            },
            children: DispersionsRoutes,
        },
        {
            path: "/movements",
            name: "Movements",
            component: Movements,
            meta: {
                middleware: [isWalletUser]
            }
        },
        // Collaborators list
        {
            path: "/collaborators",
            name: "CollaboratorsList",
            component: CollaboratorsList,
            meta: {
                middleware: [
                    isWalletUser,
                    hasPermissionTo('READ', 'COLLABORATORS', {only: ['CLIENTS_ACCOUNT'], prevent: ['USERS_ACCOUNT']})
                ]
            },
        },
        // Pagando Puntos
        {
            path: "/points-pagando/:id",
            name: "PagandoPoints",
            component: PagandoPoints,
            meta: {
                middleware: [isWalletUser]
            }
        },
        // Depositar a cuenta efectivo
        {
            path: "/deposito-efectivo/:id",
            name: "CashDeposit",
            component: CashDeposit,
            meta: {
                middleware: [isWalletUser]
            }
        },
        // Settings
        {
            path: "/settings",
            name: "Settings",
            component: Settings,
            meta: {
                middleware: [isWalletUser]
            }
        },
        {
            path: "/settings/personal-information",
            name: "PersonalInformation",
            component: PersonalInformation,
            meta: {
                middleware: [isWalletUser]
            }
        },
        {
            path: "/settings/invite-friends",
            name: "InviteFriends",
            component: InviteFriends,
            meta: {
                middleware: [isWalletUser]
            }
        },
        {
            path: "/settings/notifications",
            name: "Notifications",
            component: Notifications,
            meta: {
                middleware: [isWalletUser]
            }
        },
        {
            path: "/settings/security",
            name: "Security",
            component: Security,
            meta: {
                middleware: [isWalletUser]
            }
        },
        {
            path: "/settings/security/change-pass",
            name: "SecurityChangePass",
            component: SecurityChangePass,
            meta: {
                middleware: [isWalletUser]
            }
        },
        {
            path: "/settings/contact",
            name: "Contact",
            component: Contact,
            meta: {
                middleware: [isWalletUser]
            }
        },
        // Services
        {
            path: "/services-payment",
            name: "ServicesPayment",
            component: ServicesPayment,
            meta: {
                middleware: [isWalletUser]
            }
        },
        {
            path: "/services-payment-summary",
            name: "ServicesPaymentSummary",
            component: ServicesPaymentSummary,
            props: true,
            meta: {
                middleware: [isWalletUser]
            }
        },
        // Promos
        {
            path: "/promotions-list",
            name: "PromotionList",
            component: PromotionList,
            meta: {
                middleware: [isWalletUser]
            }
        },
        {
            path: "/promotion-details",
            name: "PromotionDetails",
            component: PromotionDetails,
            props: true,
            meta: {
                middleware: [isWalletUser]
            }
        },
        // Additional New Card
        {
            path: "/additional-cards",
            name: "AdditionalCards",
            component: AdditionalCards,
            meta: {
                middleware: [isWalletUser]
            }
        },
        {
            path: "/additional-card-new",
            name: "AdditionalCardNew",
            component: AdditionalCardNew,
            meta: {
                middleware: [isWalletUser]
            }
        },
        {
            path: "/additional-card-edit",
            name: "AdditionalCardEdit",
            props: true,
            component: AdditionalCardNew,
            meta: {
                middleware: [isWalletUser]
            }
        },
        // Corporative Accounts
        {
            path: "/" +
                "corporative-account",
            name: 'CorporativeAccounts',
            component: BaseLayout,
            meta: {
                middleware: [isWalletUser, hasPermissionTo('READ', 'CORPORATIVE_ACCOUNTS', {only: ['CLIENTS_ACCOUNT']})],
            },
            children: [
                {
                    path: "new",
                    name: 'NewCorporativeAccount',
                    props: true,
                    component: NewCorporativeAccount
                },
                {
                    path: "edit/:id",
                    name: 'EditCorporativeAccount',
                    props: true,
                    component: EditCorporativeAccount
                },
                {
                    path: "list",
                    name: 'CorporativeAccountList',
                    component: CorporativeAccountList
                },
                {
                    path: "details/:id",
                    name: 'CorporativeAccountDetail',
                    component: CorporativeAccountDetail
                },
            ],
        },
        {
            path: "/corporative-activity/:id",
            name: "CorporativeActivity",
            component: CorporativeActivity,
            props: true,
            meta: {
                middleware: [isWalletUser, hasPermissionTo('READ', 'CORPORATIVE_ACCOUNTS', {only: ['CLIENTS_ACCOUNT']})],
            },
        },
        {
            path: "/corporative-activity/movements/:id",
            name: "CorporativeActivityMovements",
            component: CorporativeActivityMovements,
            props: true,
            meta: {
                middleware: [isWalletUser, hasPermissionTo('READ', 'CORPORATIVE_ACCOUNTS', {only: ['CLIENTS_ACCOUNT']})],
            },
        },
        // Announcements
        {
            path: "/announcement/:id",
            name: "Announcement",
            component: Announcement
        }
    ]
});

// function _clearSession() {
//     localStorage.removeItem('currentUser');
//     localStorage.removeItem('currentToken');
//     localStorage.removeItem('currentPermissions');
//     localStorage.removeItem('profile');
//     localStorage.removeItem('expireDate');
//     store.commit(`${SESSION_STORE}/${CLEAR_STORE}`);
// }




router.beforeEach(async (to, from, next) => {

    if (to.path === "/") {
        return next('/login');
    }

    // if (to.path.indexOf("registration-steps") !== -1 || to.path.indexOf("login") !== -1 || to.path.indexOf("liveness-movil") !== -1) {
    //     return next()
    // }

    // let validSession = false;
    // const token = localStorage.getItem('currentToken');

    // if (token) {
    //     const jwtPayload = parseJwt(token);

    //     if (jwtPayload.exp < Date.now() / 1000) {
    //         // token expired
    //         _clearSession();
    //         return next("/");
    //     }

    //     if (to.path.indexOf("login") === 1) {
    //         next('/wallet');
    //     }

        // Esta logica es para implementar middlewares No funciono, ya que cargan primero los middleware que logica para obtener token reciente.
        if (to.matched.some(record => record.meta.middleware)) {

            const middlewareFound = checkMiddlewares(to.matched);

            const context = {
                from,
                next,
                router,
                to,
            };

            const nextMiddleware = nextFactory(context, middlewareFound, 1);

            return middlewareFound[0]({ ...context, next: nextMiddleware });
        }

        return next();
    // }

    // return next('/');
});



function nextFactory(context, middleware, index) {
    const subsequentMiddleware = middleware[index];
    // If no subsequent Middleware exists,
    // the default `next()` callback is returned.
    if (!subsequentMiddleware) return context.next;

    return (...parameters) => {
        // Run the default Vue Router `next()` callback first.
        context.next(...parameters);
        // Then run the subsequent Middleware with a new
        // `nextMiddleware()` callback.
        const nextMiddleware = nextFactory(context, middleware, index + 1);
        subsequentMiddleware({ ...context, next: nextMiddleware });
    };
}

function checkMiddlewares(matched) {
    const middlewareFound = [];
    for (const i of matched) {
        if (i.meta.middleware) {
            if (Array.isArray(i.meta.middleware)) {
                for (const c of i.meta.middleware) {
                    middlewareFound.push(c);
                }
            } else {
                middlewareFound.push(i.meta.middleware)
            }
        }
    }

    return middlewareFound;
}

export default router;
