<template>
    <div class="empty-state" :class="clazz">
        <div v-if="!emptyStateDefault && emptyStateSearching" class="content-vertical">
            <img class="img-fluid" :class="imgClass" src="@/assets/images/Emptystates/emptystate-searching.svg" width="200" height="200" alt="Buscando..." />
            <span>
                <p class="loading-note no-cpu"> “{{text}}” </p>
                <slot></slot>
            </span>
        </div>

        <div v-if="emptyStateDefault && !emptyStateSearching" class="content-vertical">
            <img class="img-fluid" :class="imgClass" :src="imgUrl" :width="imgWidth" :height="imgHeight" :alt="imgAlt" />
            <slot name="extraLoadingBar"></slot>
            <span>
                <label> {{title}} </label>
                <p>{{text}}</p>
                <slot></slot>
            </span>
        </div>

        <slot name="extraContent"></slot>
    </div>
</template>

<script>
    import Imagen from '@/assets/images/Emptystates/emptystate-table.svg';

    export default {
        name: 'EmptyState',
        props: {
            clazz: String,
            title: String,
            text: String,
            imgClass: String,
            emptyStateDefault: {type: Boolean, default: true},
            emptyStateSearching: {type: Boolean, default: false},
            imgUrl: {type: String, default: Imagen},
            imgWidth: Number,
            imgHeight: Number,
            imgAlt: {type: String, default: "Estado vacío"},
        },
        data() {
            return {
                defaultUrl: Imagen,
            }
        }
    }
</script>
