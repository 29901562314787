<template>
    <div class="row">
        <div class="col-12">
            <router-link
                to="/wallet/dashboard"
                class="button xs button-ghost button-text_info p-l-0 p-r-0"
                tabindex=""
            >
                <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                {{$t('general.returnHome')}}
            </router-link>
            <FloatingTitleLayout :titleStrong="$t('cardRequest.tracking.status')"></FloatingTitleLayout>
        </div>

        <div :class="cardClass">
            <CardSimple
                :title="$t('cardRequest.tracking.address-card-title')"
                :subtitle="addressDescription"
                clazzTitle="m-b-0">
                <template v-slot:titleBefore>
                    <div class="icon-bgm icon-pink">
                        <i aria-hidden="true" class="iconsax-money-card"></i>
                    </div>
                </template>
            </CardSimple>
        </div>

        <div class="col-12 col-lg-6" v-if="loading || trackingInfo">
            <CardSimple
                :title="trackingInfo ? $t('cardRequest.tracking.card-title') : ''"
                clazzTitle="m-b-0">
                <template v-slot:titleBefore>
                    <div class="icon-bgm icon-accent">
                        <i aria-hidden="true" class="iconsax-delivery-truck-fast"></i>
                    </div>
                </template>
                <template v-slot:subtitleSlot v-if="trackingInfo">
                    {{$t('cardRequest.tracking.carrier')}}:
                    <strong class="poppins-font-bold f-700">
                        {{carrier}}
                    </strong>
                    <br/>
                    {{$t('cardRequest.tracking.number')}}:
                    <strong class="poppins-font-bold f-700">
                        <a
                            :href="trackingLink"
                            target="_blank"
                        >
                            {{trackingInfo.trackingNumber}}
                        </a>
                    </strong>
                </template>
                <template v-slot:subtitleSlot v-else>
                    <LoadingSimple clazz="no-text" v-if="loading"></LoadingSimple>
                </template>
            </CardSimple>
        </div>

        <div class="col-12">
            <CardSimple hideTitle>
                <div class="row">
                    <div class="col-12">
                        <ul class="simple-status-timeline">

                            <li :class="{'status-success': stepOne}">
                                <label v-text="$t('cardRequest.tracking.steps.one.title')"></label>
                                <p v-text="$t('cardRequest.tracking.steps.one.subtitle')"></p>
                            </li>

                            <li :class="{'status-success': stepTwo}">
                                <label v-text="$t('cardRequest.tracking.steps.two.title')"></label>
                                <p v-text="$t('cardRequest.tracking.steps.two.subtitle')"></p>
                            </li>

                            <li :class="{'status-success': stepThree}">
                                <label v-text="$t('cardRequest.tracking.steps.three.title')"></label>
                                <p v-text="$t('cardRequest.tracking.steps.three.subtitle')"></p>
                                <button
                                    type="button"
                                    class="button-shipping-status"
                                    v-b-toggle.collapse-shipping-status
                                    v-if="groupedTrackingEvents.length > 0"
                                >
                                    {{$t('cardRequest.tracking.see-tracking')}}
                                    <i aria-hidden="true" class="iconsax-arrow-direction-square-down m-l-10"></i>
                                    <i aria-hidden="true" class="iconsax-arrow-direction-square-up m-l-10"></i>
                                </button>
                                <b-collapse
                                    id="collapse-shipping-status"
                                    class="shipping-status"
                                    v-if="trackingInfo"
                                >
                                    <template v-for="(g) of groupedTrackingEvents">
                                        <label v-bind:key="g.day" v-text="dateDescription(g.day, 'dddd DD MMMM, YYYY')"></label>
                                        <span v-for="(e) of g.events" v-bind:key="e.date">
                                            <p>
                                                <strong v-text="dateDescription(e.date, 'hh:mm a')"></strong>
                                                <br/>
                                                {{e.description}}
                                                <br/>
                                                <em v-text="e.location"></em>
                                            </p>
                                        </span>
                                    </template>
                                </b-collapse>
                            </li>

                            <li :class="{'status-success': stepFour}">
                                <label v-text="$t('cardRequest.tracking.steps.four.title')"></label>
                                <p v-text="$t('cardRequest.tracking.steps.four.subtitle')"></p>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12">
                        <button
                            type="button"
                            class="button sm button-filled button-primary w-100"
                            :disabled="!currentRequest"
                            @click="activate"
                            v-text="$t('cardRequest.tracking.activate')"
                        ></button>
                    </div>
                </div>
            </CardSimple>
        </div>

        <div class="col-12">
            <b-alert class="alert-note" show variant="info">
                <p class="text-container">
                    <i
                        aria-hidden="true"
                        class="icon iconsax-emails-message-bold"
                        ></i>
                    <span>
                        {{$t('cardRequest.tracking.support-1')}}
                        <a href="" class="c-info poppins-font-bold hover" tabindex="">
                            <u v-text="$t('cardRequest.tracking.support-1')"></u>
                        </a>
                    </span>
                </p>
            </b-alert>
        </div>
    </div>
</template>
<script>
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import {mapGetters} from "vuex";
    import { GET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";
    import ShippingOrdersApi from "@/api/core/shippingOrders.api.js";
    import moment from 'moment';
    import LoadingSimple from "@/components/LoadingSimple.vue";
    // const tz = 'America/Mexico_city';

    export default {
        name: "CardShippingStatus",
        components: {
            FloatingTitleLayout,
            CardSimple,
            LoadingSimple,
        },
        data() {
            return {
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
                trackingInfo: null,
                loading: false
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            async getTrackingInfo() {
                try {
                    this.loading = true;
                    this.trackingInfo = (await ShippingOrdersApi.trackingInfo(this.currentRequest?._id))[0];
                } catch(error) {
                    // unmanaged error
                } finally {
                    this.loading = false;
                }
                return this.trackingInfo;
            },
            dateDescription(dateString, format) {
                return moment(dateString).format(format);
            },
            activate() {
                this.$router.push({name: 'CardActivation'});
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                account: GET_ACCOUNT,
            }),
            currentRequest() {
                const virtualCard = this.account?.cards?.find(({card: {manufacturingType}}) => manufacturingType === 'VIRTUAL');
                const physicCard = this.account?.cards?.find(({card: {manufacturingType}}) => manufacturingType === 'PHYSIC');
                return physicCard?.requestCard?.isReposition ? physicCard?.requestCard : virtualCard?.requestCard;
            },
            addressDescription() {
                if (this.currentRequest) {
                    const address = this.currentRequest?.shippingAddress;
                    const city = address?.city;
                    const state = address?.state;
                    const country = address?.country;
                    const line1 = `${address?.street}, ${address?.number} ${address?.reference || ''}, ${address?.district}`;
                    const line2 = `${this.$t('register.residence.zipCode')} ${address?.postalCode}, ${city}, ${state}, ${country}`;
                    return `${line1} ${line2}`;
                }
                return this.$t('cardRequest.tracking-norequest');
            },
            carrier() {
                return this.trackingInfo?.carrier?.toUpperCase() || 'N/A';
            },
            trackingNumber() {
                return this.trackingInfo?.trackingNumber || 'N/A';
            },
            trackingLink() {
                return `${this.trackingInfo?.trackUrlSite}${this.trackingInfo?.trackingNumber}`;
            },
            stepOne() {
                return !!this.currentRequest;
            },
            stepTwo() {
                return !!this.trackingInfo;
            },
            stepThree() {
                const handledStatus = [
                    'Recogido',
                    'Enviado',
                    'Out for Delivery',
                    'Delivered',
                    'Information'
                ];
                return handledStatus.includes(this.trackingInfo?.status);
            },
            stepFour() {
                const handledStatus = [
                    'Delivered',
                    'Information'
                ];
                return handledStatus.includes(this.trackingInfo?.status);
            },
            groupedTrackingEvents() {
                const events = [];
                const eventHistory = this.trackingInfo?.eventHistory;
                if (eventHistory) {
                    for (const e of eventHistory) {
                        const day = e.date.substring(0, 10);
                        let dayGroup = events.find((g) => g.day === day);
                        if (!dayGroup) {
                            dayGroup = {day, events: []};
                            events.push(dayGroup);
                        }
                        dayGroup.events.push(e);
                    }
                }
                return events;
            },
            cardClass() {
                return this.loading || this.trackingInfo ? 'col-12 col-lg-6' : 'col-12';
            }
        },
        async mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
            await this.getTrackingInfo();
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
