<template>
  <div>
    <!-- Modal Nuevo contacto Pagando -->
    <b-modal
      :id="modalId"
      size="lg"
      hide-header
      hide-footer
      modal-class="modal-default left-oriented"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-contacto-pagando')"
        :aria-label="$t('transfers.beneficiaries.new.close')"
        :name="$t('transfers.beneficiaries.new.close')"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{$t('transfers.beneficiaries.new.titlePagando')}}</h2>
        <p class="modal-subtitle">{{$t('transfers.beneficiaries.new.subtitlePagando')}}</p>
      </div>
      <div class="body">
        <BasicInput
          required
          v-model="search"
          class="search-type"
          inputType="text"
          :label="$t('transfers.beneficiaries.fields.phoneLabel')"
          :inputPlaceholder="$t('transfers.beneficiaries.fields.phonePlaceholder')"
          @onKeyUpEnter="findAccount"
        >
          <button
            type="button"
            class="button xs button-filled button-primary"
            :disabled="searching"
            @click="findAccount"
          >
            {{$t('transfers.beneficiaries.new.search')}}
          </button>
        </BasicInput>
        <div v-if="searching">
          <LoadingBar loadingText="Buscando..."/>
        </div>
        <div v-else-if="modalStatus === 'error'">
          <span class="title-divider error">
            <label>{{$t('transfers.beneficiaries.new.userNotFound')}}</label>
          </span>
          <b-alert class="alert-note" show dismissible variant="danger">
            <p class="text-container">
              <i
                aria-hidden="true"
                class="icon banana banana-cross-circle2"
              ></i>
              {{$t('transfers.beneficiaries.new.userNotFoundSubtitle')}}
            </p>
          </b-alert>
        </div>
        <div v-else-if="modalStatus === 'success'">
          <span class="title-divider success">
            <label>{{$t('transfers.beneficiaries.new.userFound')}}</label>
          </span>
          <BasicInput
            required
            disabled
            inputId=""
            :label="$t('transfers.beneficiaries.fields.accountLabel')"
            :value="accountNumber"
          >
          </BasicInput>
          <BasicInput
            required
            disabled
            inputId=""
            :label="$t('transfers.beneficiaries.fields.nameLabel')"
            :value="contactName"
          >
          </BasicInput>
          <BasicInput
            required
            inputId=""
            v-model="contactAlias"
            :label="$t('transfers.beneficiaries.fields.aliasLabel')"
            :inputPlaceholder="$t('transfers.beneficiaries.fields.aliasPlaceholderNew')"
          >
          </BasicInput>
          <div class="row m-b-30">
            <div class="col-12 col-md-6">
              <BasicSwitch
                  @onBasicSwitchChange="onSwitchEditChange"
                  idInput="switchEditPagando"
                  ref="switchEditPagando"
                  :label="$t('contacts.modals.editPagando.switchSpeiOut')"
                  :switchValue="limitSpeiOut"
              />
            </div>
            <div class="col-12 col-md-6" v-if="limitSpeiOut">
              <BasicInput
                  money
                  v-model="speiOut"
                  :inputValue="speiOut.toString()"
                  :label="$t('contacts.modals.editPagando.speiOut')"
              >
              </BasicInput>
            </div>
          </div>
          <BasicCheckbox
            v-if="forTransfer"
            v-model="shouldSave"
            clickableText
            :text="$t('transfers.beneficiaries.new.persist')"
          ></BasicCheckbox>
        </div>
      </div>
      <div class="footer">
        <button
          v-if="!loading"
          type="button"
          class="button button-filled button-primary w-100"
          :disabled="modalStatus !== 'success' || !contactAlias"
          @click="save"
        >
          {{forTransfer ? $t('transfers.beneficiaries.new.continue') : $t('contacts.modals.newPagando.save')}}
        </button>
        <button
          v-else
          type="button"
          class="button button-filled button-primary w-100"
        >
          <LoadingSimple clazz="small-load no-text" />
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BasicCheckbox from "@/components/BasicCheckbox.vue";
import BasicInput from "@/components/BasicInput.vue";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import ContactsApi from "@/api/wallet/contacts.api";
import LoadingSimple from '@/components/LoadingSimple.vue';
import LoadingBar from "@/components/LoadingBar.vue";
import {mapGetters} from "vuex";
import {
  STORE as sessionStore,
  GET_ACCOUNT,
} from "@/store/modules/session/session.types";
import BasicSwitch from "@/components/BasicSwitch.vue";

export default {
  name: "ModalNewContactPagando",
  mixins: [ToastSnotify],
  components: {
    BasicSwitch,
    BasicCheckbox,
    BasicInput,
    LoadingSimple,
    LoadingBar,
  },
  props: {
    forTransfer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalId: 'modal-contacto-pagando',
      search: '',
      accountNumber: null,
      contactName: null,
      contactAlias: null,
      shouldSave: false,
      idCard: null,
      modalStatus: 'empty',
      loading: false,
      searching: false,
      limitSpeiOut: false,
      speiOut: 0,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
      account: GET_ACCOUNT
    }),
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      if (modalId === this.modalId) {
        this.clearModal();
      }
    });
  },
  methods: {
    open() {
      this.$bvModal.show(this.modalId);
    },
    clearModal(clearSearch = true) {
      if (clearSearch) {
        this.search = "";
      }
      this.accountNumber = null;
      this.contactName = null;
      this.contactAlias = null;
      this.shouldSave = false;
      this.idCard = null;
      this.modalStatus = 'empty';
      this.loading = false;
    },
    async findAccount() {
      let timeout;
      try {
        if(this.searching) {
          return;
        }
        if(!this.search) {
          this.accountNumber = null;
          this.contactName = null;
          this.contactAlias = null;
          this.idCard = null;
          this.modalStatus = 'empty';
          return;
        }
        const that = this;
        timeout = setTimeout(function() {
          that.searching = true;
        }, 100);
        const response = await ContactsApi.findAccount({ search: this.search });
        const account = response.data.object;
        if(account) {
          this.accountNumber = account.accountNumber;
          this.contactName = account.name;
          this.idCard = account.idCard;
          this.modalStatus = "success";
        } else {
          this.clearModal(false);
          this.modalStatus = "error";
        }
      } catch (error) {
        this.toastErrorDefault(error);
        this.modalStatus = "error";
      }
      clearTimeout(timeout);
      this.searching = false;
    },
    async save() {
      this.loading = true;
      try {
        const response = await ContactsApi.save({
          accountId: this.account.account._id,
          idCard: this.idCard,
          contactName: this.contactName,
          alias: this.contactAlias,
          isPagandoAccount: true,
          save: this.forTransfer ? this.shouldSave : true,
          speiOutLimit: {
            limitSpeiOut: this.limitSpeiOut,
            speiOut: this.speiOut,
          }
        });
        this.$bvModal.hide(this.modalId);
        this.$emit('continue', response.data.object, this.shouldSave);
      } catch (error) {
        this.toastErrorDefault(error);
        this.loading = false;
      }
    },
    onSwitchEditChange(value) {
      this.limitSpeiOut = value;
    }
  },
};
</script>
