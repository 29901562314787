<template>
  <div>
    <TitlePrincipal
        :title="$t('register.personalData.title')"
        :subtitle="$t('register.personalData.subtitle')">
    </TitlePrincipal>
    <div class="row">
      <div class="col-12">
        <BasicInput
            v-model="userData.name"
            required
            inputType="text"
            inputId="user_nombre"
            :has-error="userData.name ? $v.userData.name.$invalid : false"
            :label="$t('register.personalData.nameLabel')"
            :inputPlaceholder="$t('register.personalData.namePH')"/>
      </div>
      <div class="col-12 col-md-6">
        <BasicInput
            v-model="userData.firstLastName"
            required
            inputType="text"
            inputId="user_apellido_paterno"
            :has-error="userData.firstLastName ? $v.userData.firstLastName.$invalid : false"
            :label="$t('register.personalData.firstLastName')"
            :inputPlaceholder="$t('register.personalData.firstLastNamePH')"/>
      </div>
      <div class="col-12 col-md-6">
        <BasicInput
            v-model="userData.secondLastName"
            required
            inputType="text"
            :has-error="userData.secondLastName ? $v.userData.secondLastName.$invalid : false"
            inputId="user_apellido_materno"
            :label="$t('register.personalData.secondLastName')"
            :inputPlaceholder="$t('register.personalData.secondLastNamePH')"/>
      </div>
      <div class="col-12">
        <BasicSelect
            required
            :label="$t('register.personalData.gender')">
          <multiselect
              v-model="userData.gender"
              :options="optionsGenero"
              :close-on-select="true"
              :preserve-search="true"
              :placeholder="$t('register.personalData.genderPH')"
              label="name"
              track-by="name"
              :preselect-first="false"
          >
            <template
                slot="selection"
                slot-scope="{ values, search, isOpen }"
            >
                                        <span
                                            class="multiselect__single"
                                            v-if="values.length &amp;&amp; !isOpen"
                                        >{{ values.length }} options selected</span>
            </template>
          </multiselect>
        </BasicSelect>
      </div>
      <div class="col-12">
        <h2 class="f-18 c-primary_1 poppins-font-semibold m-b-15">{{ $t('register.personalData.birthday') }}</h2>
      </div>
      <div class="col-12 col-md-4">
        <BasicInput
            v-model="userData.day"
            required
            inputType="text"
            inputId="user_day"
            :has-error="userData.day ? $v.userData.day.$invalid : false"
            :label="$t('register.personalData.day')"
            inputPlaceholder="DD"/>
      </div>
      <div class="col-12 col-md-4">
        <BasicInput
            v-model="userData.month"
            required
            inputType="text"
            inputId="user_month"
            :has-error="userData.month ? $v.userData.month.$invalid : false"
            :label="$t('register.personalData.month')"
            inputPlaceholder="MM"/>
      </div>
      <div class="col-12 col-md-4">
        <BasicInput
            v-model="userData.year"
            required
            inputType="text"
            inputId="user_year"
            :has-error="userData.year ? $v.userData.year.$invalid : false"
            :label="$t('register.personalData.year')"
            inputPlaceholder="AAAA"/>
      </div>
    </div>
    <div class="footer-buttons right">
      <button @click="back()" type="button" class="button button-ghost button-plain_text" name="Regresar">
        {{ $t('register.personalData.back') }}
      </button>
      <button v-if="!loadingButton" type="button" class="button button-filled button-primary" @click="submitData"
              name="Continuar" :disabled="!isValidData"> {{ $t('register.personalData.continue') }}
      </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text"/>
      </button>
    </div>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import BasicInput from "@/components/BasicInput";
import BasicSelect from "@/components/BasicSelect";
import Multiselect from "vue-multiselect";
import LoadingSimple from "@/components/LoadingSimple";
import {required, minLength, maxLength} from "vuelidate/lib/validators";
import {catchError, checkPositiveNumber} from "@/utils/utils";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import UserApi from "@/api/users/users.api";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {
  GET_FULL_DATA_USER,
  LOAD_FULL_DATA_USER,
  STORE as sessionStore
} from "@/store/modules/session/session.types";
import {
  GET_LEVEL,
  GET_CARD_REQUEST,
  GO_TO_ROUTE,
  STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import { mapGetters } from 'vuex';
import moment from "moment";
import {EventBus} from "@/main";

const formatDate = "DD/MM/YYYY";

export default {
  name: "PersonalData",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicInput,
    BasicSelect,
    Multiselect,
    LoadingSimple
  },
  data() {
    return {
      loadingButton: false,
      userData: {
        name: "",
        firstLastName: "",
        secondLastName: "",
        gender: "",
        day: "",
        month: "",
        year: ""
      },
      optionsGenero: [
        {name: 'Femenino', value: "FEMALE"},
        {name: 'Masculino', value: "MALE"},
      ],
    }
  },
  computed: {
    ...mapGetters(sessionStore, {
      fullDataUser: GET_FULL_DATA_USER
    }),
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      cardRequest: GET_CARD_REQUEST
    }),
    isValidData() {
      return !this.$v.$invalid;
    }
  },
  async beforeMount() {
    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
    this.assignData();
    EventBus.$on(`${sessionStore}.${LOAD_FULL_DATA_USER}`, () => {
      this.assignData();
    });
  },
  beforeDestroy() {
    EventBus.$off(`${onbngStore}.${LOAD_FULL_DATA_USER}`);
  },
  methods: {
    back() {
      if(this.level === 0) {
        this.$store.dispatch(`${onbngStore}/backCollaborator`)
      } else if(this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${onbngStore}/backBusiness`)
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`)
      }
    },
    assignData() {
      if(this.fullDataUser) {
        const dateFormat = moment(this.fullDataUser.birthDay).format(formatDate);
        this.userData.name = this.fullDataUser.name;
        this.userData.firstLastName = this.fullDataUser.lastName;
        this.userData.secondLastName = this.fullDataUser.secondLastName;
        this.userData.month = moment(dateFormat, formatDate).format('MM');
        this.userData.day = moment(dateFormat, formatDate).format('DD');
        this.userData.year = moment(dateFormat, formatDate).format('YYYY');
        this.userData.gender = this.optionsGenero.filter(o => o.value === this.fullDataUser.gender)[0];
      }
    },
    async submitData() {
      this.loadingButton = true;
      // formato de fecha mm/dd/yyyy si no core lo rechaza
      const data = {
        name: this.userData.name,
        lastName: this.userData.firstLastName,
        secondLastName: this.userData.secondLastName,
        birthDay: `${this.userData.month}/${this.userData.day}/${this.userData.year}`,
        gender: this.userData.gender.value,
        timeOffset: - new Date().getTimezoneOffset() / 60,
      }
      const response = await UserApi.savePersonalData(data).catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          const message = response.data.message || this.$i18n.t('register.personalData.erroSaveUser');
          this.displayNotificationError(message);
        } else {
          if(this.level === 1) {
            this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
              stepOnbng: ONBOARDING_STEPS.JOB,
            });
            this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "occupation");
          } else if(this.level === 0) {
            this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "residenceCollaborator");
          } else {
            this.nextStep();
          }
        }
      }
      this.loadingButton = false;
    },
    nextStep() {
      if (this.cardRequest.isMoral) {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.PHONE_VERIFICATION,
        });
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "businessPhoneVerification");
      } else {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.PLACE_RESIDENCE,
        });
        let goTo = "residence";
        if (this.cardRequest.accountType === "BUSINESS") {
          goTo = "businessResidence";
        }
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, goTo);
      }
    }

  },
  validations: {
    userData: {
      name: {
        required,
      },
      firstLastName: {
        required,
      },
      secondLastName: {
        required,
      },
      gender: {
        required
      },
      day: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2),
        checkPositiveNumber
      },
      month: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(2),
        checkPositiveNumber

      },
      year: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4),
        checkPositiveNumber
      },
    },
  }
}
</script>

<style scoped>

</style>
