<template>
    <div
        class="col-12"
        v-if="hasPermit && hasPendingRequest"
    >
        <CardSimple
            clazzTitle="m-b-0"
            :title="title"
            :subtitle="currentRequestInProgress ? '' : $t('cardRequest.request-sub')"
            showButtons
        >
            <template v-slot:titleBefore>
                <img
                    class="img-fluid"
                    :src="accountImage"
                    width="82"
                    height="50"
                    :alt="accountDescription" />
            </template>
            <template v-slot:titleButtons>
                <router-link
                    :to="buttonLink"
                    class="button sm button-filled button-primary w-100"
                >
                    <i aria-hidden="true" class="iconsax-money-card m-r-10"></i>
                    {{button}}
                </router-link>
            </template>
            <template
                v-slot:subtitleSlot
                v-if="currentRequestInProgress"
            >
                {{$t('cardRequest.activate-sub-a')}}
                <router-link
                    to="/pages/card-shipping-status"
                    class="c-info poppins-font-bold f-700 hover"
                    tabindex=""
                >
                    <u>{{$t('cardRequest.activate-sub-b')}}</u>
                </router-link>.
            </template>
        </CardSimple>
    </div>
</template>
<script>
import {mapGetters} from "vuex";
import CardSimple from "@/components/CardSimple.vue";
import {GET_ACCOUNT_IMG, GET_ACCOUNT_DES, GET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";

export default {
    name: "CardRequestStatus",
    components: {
        CardSimple
    },
    computed: {
        ...mapGetters(sessionStore, {
            accountImage: GET_ACCOUNT_IMG,
            accountDescription: GET_ACCOUNT_DES,
            account: GET_ACCOUNT
        }),
        title() {
            return this.$t(this.currentRequestInProgress ? 'cardRequest.activate' : 'cardRequest.request');
        },
        button() {
            return this.$t(this.currentRequestInProgress ? 'cardRequest.activate-button' : 'cardRequest.request-button');
        },
        buttonLink() {
            return this.currentRequestInProgress ? '/pages/card-activation' : '/pages/card-request';
        },
        icon() {
            return this.currentRequestInProgress ? 'iconsax-money-card-tick' : 'iconsax-money-card';
        },
        hasPermit() {
            return !!this.account?.account?.accountType?.permits?.find(({permitKey, status}) => status && permitKey === 'REQUEST_PHYSICAL_CARD');
        },
        currentRequest() {
            const wrapper = this.account?.cards?.find(({card: {manufacturingType}}) => manufacturingType === 'VIRTUAL');
            return wrapper?.requestCard;
        },
        currentRequestInProgress() {
            const statusPending = ['IN_PROCCESS', 'PENDING_WEBHOOK', 'ACCEPTED', 'SENT'];
            return this.currentRequest !== undefined ? statusPending.includes(this.currentRequest.status) : false;
        },
        hasPendingRequest() {
            const card = this.account?.cards?.find(({card: {manufacturingType}}) => manufacturingType === 'PHYSIC');
            if(card){
                return card.requestCard?.isReposition;
            }
            return !this.currentRequest || this.currentRequestInProgress;
        }
    },
}
</script>
