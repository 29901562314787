<template>
    <div class="panel-promo" :class="clazz">
        <label class="panel-promo-title">{{promoTitle}}</label>
        <img class="img-fluid" :src="imgPromoUrl" width="200" height="100" :alt="imgAlt" />
        <p class="panel-promo-descr">{{promoDescr}}</p>
        <p class="panel-promo-availability">{{promoAv}}</p>
        <p class="panel-promo-date" :class="dateClazz">{{promoDate}}</p>
        <router-link :to="{name: 'PromotionDetails', params: {promotion: promo}}" class="panel-promo-link" tabindex="">
            <u>Ver promoción</u>
        </router-link>
    </div>
</template>

<script>
    import Imagen from '@/assets/images/Logos/logo-color.svg';

    export default {
        name: 'PanelPromo',
        props: {
            clazz: String,
            promoTitle: String,
            promoDescr: String,
            promoAv: String,
            dateClazz: String,
            promoDate: String,
            imgAlt: String,
            promo: {},
            imgPromoUrl: {type: String, default: Imagen},
        },
        data() {
            return {
                defaultUrl: Imagen,
            }
        }
    }
</script>
