import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'novelties';

export default {
    getAnnouncements: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/current-announcements-access`, params)
    },
    getBanners: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/current-banner-alerts`, params)
    }
}
