<template>
    <span class="user-account-img" :class="clazz">
        <img v-if="!noAccount" class="img-fluid user-img" :src="userImg" :width="widthImg" :height="heightImg" :alt="altImgUser" />
        <div v-if="!noAccount" class="account-type">
            <img class="img-fluid" :src="accountImg" width="14" height="12" :alt="altImgAccount" />
        </div>
        <label v-if="noAccount" class="user-name">{{userName}}</label>
    </span>
</template>

<script>
    import ImgAccount from '@/assets/images/Logos/Pagando-logo-xs-white.svg';

    export default {
        name: 'UserAccount',
        props: {
            clazz: String,
            userImg: String,
            widthImg: Number,
            heightImg: Number,
            altImgUser: String,
            accountImg: {type: String, default: ImgAccount},
            altImgAccount: {type: String, default: "Pagando"},
            userName: String,
            noAccount: {type: Boolean, default: false},
        },
        data() {
            return {
                defaultUrl: ImgAccount,
            }
        },
        methods: {},
    }
</script>
