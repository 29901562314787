<template>
  <b-modal
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
    id="modal-add-extra-info"
    size="lg"
    hide-header
    hide-footer
    @change="change"
  >
    <div class="modal-terminos-privacidad">
      <div>
        <div>
          <TitlePrincipal
            :title="currentStep.title"
            :subtitle="currentStep.description"
          >
          </TitlePrincipal>
          <DynamicOnboarding
            :current-step="currentStep"
            :onbngData="onbngData"
            :temp-model="tempModel"
            @update-parent="updateParent"
          ></DynamicOnboarding>
        </div>
        <div class="footer-buttons right">
          <button
            v-if="currentStepIndex > 0"
            type="button"
            class="button button-ghost button-plain_text"
            name="Regresar"
            @click="back"
          >
            {{ $t("register.addInfo.back") }}
          </button>
          <button
            v-if="showNext"
            type="button"
            class="button button-filled button-primary"
            name="Continuar"
            @click="submit"
          >
            {{ $t("register.addInfo.next") }}
          </button>
          <button
            v-else
            :id="'Loading button'"
            class="button button-ghost button-plain_text"
          >
            <LoadingSimple clazz="small-load no-text" />
          </button>
        </div>
      </div>
    </div>
    <LoadingBar clazz="fullcontent-loading" v-if="loading" />
  </b-modal>
</template>
<script>
import OnboardingData from "@/api/core/onboardingData.api";
import LoadingBar from "@/components/LoadingBar.vue";
import {
  catchError,
  isBoolean,
  stringToBoolean,
  isDefinedNonEmptyStr,
} from "@/utils/utils";
import DynamicOnboarding from "@/components/extraInfo/DynamicOnboarding";
import TitlePrincipal from "@/components/TitlePrincipal";
import { STORE as onbngStore } from "@/store/modules/onboardingSteps/onboardingSteps.types";
import { TYPE_DATA } from "@/enums/onbngDynamicData.enum";
import {
  STORE as sessionStore,
  GET_USER,
  GET_ACCOUNT,
} from "@/store/modules/session/session.types";
import { mapGetters } from "vuex";
import LoadingSimple from "@/components/LoadingSimple";

export default {
  name: "ModalAddExtraInfo",
  components: {
    LoadingBar,
    DynamicOnboarding,
    TitlePrincipal,
    LoadingSimple,
  },
  computed: {
    ...mapGetters(sessionStore, {
      user: GET_USER,
      account: GET_ACCOUNT,
    }),
  },
  data() {
    return {
      loading: false,
      showNext: true,

      currentStep: {
        questions: [],
      },
      currentStepIndex: 0,
      maxStep: 0,
      dynamicOnbng: {
        steps: [],
      },
      onbngData: {},
      tempModel: {},
    };
  },
  methods: {
    /**
     * Válida que las respuestas de las preguntas
     * requeridas sean correctas
     * @param questions
     * @return {boolean|*}
     */
    validateRequireData(questions) {
      const requiredOnData = questions.filter((q) =>
        isDefinedNonEmptyStr(this.onbngData[q._id]?.data)
      );
      if (questions.length === requiredOnData.length) {
        return this.checkValidationData(requiredOnData);
      }
      const idsRequired = requiredOnData.map((d) => d._id);
      const need = questions.find((q) => !idsRequired.includes(q._id));
      this.displayNotificationWarning(
        this.$i18n.t("register.addInfo.error.requiresQuestion", {
          title: need.title,
        })
      );
      return false;
    },
    /**
     * Método para evaluar la respuesta de una pregunta tipo documento o imagen
     * @param value
     * @return {boolean}
     */
    validDocument(value) {
      return !!value;
    },
    /**
     Válida que las respuestas de las preguntas
     no requeridas sean correctas
     * @param questions
     * @return {boolean|*}
     */
    validateNotRequiredData(questions) {
      const notRequiredOnData = questions.filter((q) =>
        isDefinedNonEmptyStr(this.onbngData[q._id]?.data)
      );
      if (notRequiredOnData.length) {
        return this.checkValidationData(notRequiredOnData);
      }
      return true;
    },
    /**
     * Método para evaluar la respuesta de una pregunta
     * @param questions
     * @return {boolean}
     */
    checkValidationData(questions) {
      let valid = false;
      let lastQuestion;
      let lastValue;
      for (const question of questions) {
        const value = this.onbngData[question._id].data;
        if (question.type === TYPE_DATA.BOOLEAN) {
          valid = isBoolean(value);
        }

        if (question.type === TYPE_DATA.TEXT) {
          valid = this.validTextByType(question, value);
        }

        if (question.type === TYPE_DATA.ENUM) {
          valid = this.validEnumType(question, value);
        }

        if (
          TYPE_DATA.DOCUMENT === question.type ||
          TYPE_DATA.IMAGES === question.type
        ) {
          valid = this.validDocument(value);
        }

        if (!valid) {
          lastQuestion = question;
          lastValue = value;
          break;
        }
      }
      if (!valid && lastQuestion) {
        this.printWarnLastQuestion(lastQuestion, lastValue);
      }
      return valid;
    },
    /**
     * Método para evaluar la respuesta de una pregunta tipo texto
     * @param question
     * @param value
     * @return {boolean}
     */
    validTextByType(question, value) {
      if (!value) {
        return false;
      }
      if (question.hasCustomValidation) {
        const regex = new RegExp(question.regularExpression);
        return regex.test(value);
      }
      return value.length <= Number(question.maxCharacters);
    },
    /**
     * Método para evaluar la respuesta de una pregunta tipo enum
     * @param question
     * @param value
     * @return {boolean|*}
     */
    validEnumType(question, value) {
      if (!value) {
        return false;
      }
      if (question.enumOptions.length) {
        return question.enumOptions.includes(value);
      }
      return false;
    },
    /**
     * Filtra aquellas preguntas que sean requeridas
     * @param questions
     * @return {{notRequired: *[], required: *[]}}
     */
    filterRequiredQuestions(questions) {
      const required = [];
      const notRequired = [];
      const embebedRequired = [];
      const embebedNotRequired = [];
      for (const element of questions) {
        if (element.isRequired) {
          required.push(element);
        } else {
          notRequired.push(element);
        }
        if (
          element.type === TYPE_DATA.BOOLEAN &&
          this.onbngData[element._id]?.data ===
            stringToBoolean(element.dependentQuestionsAppearsWhenAnswerIs) &&
          element.dependentQuestions.length
        ) {
          embebedRequired.push(
            ...element.dependentQuestions.filter((q) => q.isRequired)
          );
          embebedNotRequired.push(
            ...element.dependentQuestions.filter((q) => !q.isRequired)
          );
        }
      }
      return {
        required: [...required, ...embebedRequired],
        notRequired: [...notRequired, ...embebedNotRequired],
      };
    },
    /**
     * Válida que toda la información recopilada
     * en el objeto onbngData sea correcta
     * @return {*}
     */
    validateData() {
      const required = [];
      const notRequired = [];
      for (const steps of this.dynamicOnbng.steps) {
        const requiredQ = this.filterRequiredQuestions(steps.questions);
        required.push(...requiredQ.required);
        notRequired.push(...requiredQ.notRequired);
      }
      const validRequired = this.validateRequireData(required);
      const validNotRequired = this.validateNotRequiredData(notRequired);
      return validRequired && validNotRequired;
    },
    async submit() {
      if (this.currentStepIndex < this.dynamicOnbng.steps.length - 1) {
        this.setCurrentStep(this.currentStepIndex + 1);
      } else {
        await this.submitNormal();
      }
      this.showNext = true;
    },
    async submitNormal() {
      this.showNext = false;
      const valid = this.validateData();
      if (valid) {
        const values = Object.values(this.onbngData);
        const response = await OnboardingData.createData({
          onboarding: this.dynamicOnbng._id,
          questions: values,
        }).catch(catchError);
        if (response) {
          if (response.data.error) {
            const message =
              response.data.message ||
              this.$i18n.t("register.addInfo.errorSave");
            this.displayNotificationError(message);
          } else {
            this.displayNotificationSuccess("Datos actualizados correctamente");
            this.$bvModal.hide("modal-add-extra-info");
          }
        }
      } else {
        this.displayNotificationError("Información proporcionada no válida");
      }
    },
    /**
     * Navega hacia atrás entre los pasos del onboarding dynamic
     * o hacia el paso anterior del flujo principal de registro
     */
    back() {
      if (this.currentStepIndex === 0) {
        this.$store.dispatch(`${onbngStore}/backPersonal`);
      } else {
        this.setCurrentStep(this.currentStepIndex - 1);
      }
    },
    /**
     * Function para actualizar el DOM del componente
     * Padre
     */
    updateParent() {
      this.$forceUpdate();
    },
    async getOnboardingActive() {
      this.loading = true;
      let timeout = false;
      const response = await OnboardingData.getActive(
        "WALLET",
        null,
        false
      ).catch((err) => {
        // Check for timeout
        if (err.code === "ECONNABORTED") {
          timeout = true;
        }
        return catchError(err);
      });
      if (timeout) {
        this.loadingInfo = false;
        this.displayNotificationWarning(
          this.$i18n.t("register.addInfo.error.dynamicOnbng")
        );
        return;
      }
      this.loading = false;

      const dynamicOnbng = response?.data?.object;
      if (response?.data?.error || !dynamicOnbng) {
        this.displayNotificationWarning(
          "Ocurrió un error al obtener onboarding"
        );
        this.$bvModal.hide("modal-add-extra-info");
        this.$bvModal.show("update-data");
        return;
      }
      this.dynamicOnbng = dynamicOnbng;
      this.maxStep = this.dynamicOnbng?.steps?.length;
      this.setCurrentStep(this.currentStepIndex);
      //   await this.getUserData();
    },
    async change(evt) {
      if (evt) {
        await this.getOnboardingActive();
      }
    },
    async getUserData() {
      const userId = this.user._id;
      const response = await OnboardingData.getData(userId, {
        fromWeb: true,
        isNormal: true,
      }).catch(catchError);
      if (response && response.data && response.data.error) {
        this.displayNotificationWarning(
          this.$i18n.t("register.addInfo.error.getData")
        );
      } else {
        const userData = response.data.object || [];
        if (userData.length) {
          for (const userDatum of userData) {
            this.onbngData[userDatum._id] = {
              idQuestion: userDatum.idQuestion,
              data: userDatum.answer,
            };
            if (userDatum.isFile) {
              this.tempModel[userDatum._id] = {
                value: userDatum.answer,
                progress: 100,
                clazz: "icon-bgm-success",
              };
            } else {
              this.tempModel[userDatum._id] = userDatum.answer;
            }
          }
          this.$forceUpdate();
        }
      }
    },
    _pushDependentQuestions(c) {
      if (c.hasDependentQuestions === true) {
        for (const cd of c.dependentQuestions) {
          if (!this.onbngData[cd._id]) {
            this.onbngData[cd._id] = {
              idQuestion: cd._id,
              data: null,
            };
          }
        }
      }
    },
    /**
     * Cambia el paso que actualmente se muestra
     * @param step
     */
    setCurrentStep(step) {
      if (
        this.dynamicOnbng.steps.length &&
        step < this.dynamicOnbng.steps.length
      ) {
        this.currentStep = this.dynamicOnbng.steps[step];
        for (const c of this.currentStep.questions) {
          if (!this.onbngData[c._id]) {
            this.onbngData[c._id] = {
              idQuestion: c._id,
              data: null,
            };

            this._pushDependentQuestions(c);
          }
        }
        this.currentStepIndex = step;
      }
    },
  },
};
</script>
