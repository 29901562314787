<template>
  <div v-show="visible">
    <TitleSteps
      :title="$t('transfers.new.amount.title')"
      :subtitle="$t('transfers.new.amount.subtitle')"
    >
      <template v-slot:progress>
        <radial-progress-bar
          class="stepper-circle"
          :diameter="diamStep"
          :totalSteps="3"
          :completedSteps="radialStep"
          startColor="#E40096"
          stopColor="#E40096"
          innerStrokeColor="#D8D8E1"
          :strokeWidth="strokeWStep"
          :innerStrokeWidth="innerStrokeWStep"
          strokeLinecap="square"
          timingFunc="ease-in-out"
        >
          <p class="percentage">2 {{$t('transfers.new.of')}} 3</p>
        </radial-progress-bar>
      </template>
      <AccountType
        :text="$t('transfers.new.amount.account')"
        :typeAccount="accountDescription"
        clazz="p-relative"
      >
        <img
          class="img-fluid"
          :src="accountImage"
          width="64"
          height="40"
          :alt="accountDescription"
        />
      </AccountType>
    </TitleSteps>

    <CardSimple hideTitle>
      <div class="row">
        <div class="col-12 send-money">
          <InputEdit
            clazz="send-money-input"
            inputType="text"
            inputPlaceholder="$0.00"
            v-model="amount"
            money
          >
          </InputEdit>
          <p :class="{ 'send-money-text': true, 'c-error': $v.amount.$invalid && amount !== 0 }">
            {{ $t('transfers.new.amount.limitMessage', {low: currency(1), high: currency(maxLimit)}) }}
          </p>
          <p class="send-money-text" v-if="remainingBalance >= 0">
            {{$t('transfers.new.amount.remaining1')}}
            <strong>{{ $t('transfers.new.amount.remaining2', {amount: currency(remainingBalance)}) }}</strong>
            <br/>
            <small v-if="commission">
              {{ $t('transfers.new.amount.commission', {amount: currency(commission)}) }}
            </small>
          </p>
          <p class="send-money-text c-error" v-else>
            {{ $t('transfers.new.amount.notEnoughFunds1') }}
            <strong class="c-error">{{ $t('transfers.new.amount.notEnoughFunds2', {amount: currency(balance)}) }}</strong>
            {{ $t('transfers.new.amount.notEnoughFunds3') }}
          </p>
        </div>
        <div class="col-12">
          <BasicInput
            required
            v-model="concept"
            clazz="m-b-10"
            inputId="example2"
            :label="$t('transfers.new.amount.conceptLabel')"
            :inputPlaceholder="$t('transfers.new.amount.conceptPlaceholder')"
            :hasError="!!conceptErrorMessage"
            :warningText="conceptErrorMessage"
          >
          </BasicInput>
          <p class="f-12 c-text_info poppins-font-regular line-height-1 m-b-10">
            {{$t('transfers.new.amount.predefinedConcepts.title')}}
          </p>
          <div class="btn-text-container">
            <button
              id=""
              class="btn-text"
              :class="{ active: concept === predefinedConcept }"
              type="button"
              v-for="predefinedConcept in predefinedConcepts"
              :key="predefinedConcept"
              @click="concept = predefinedConcept"
            >
              {{ predefinedConcept }}
            </button>
          </div>
        </div>
        <div class="col-12">
          <TitleBadge
            clazzContainer="p-0 m-b-10"
            clazz="f-15"
            :titleText="$t('transfers.new.amount.beneficiary')"
          ></TitleBadge>
          <ChangeBeneficiary
            :clazzColor="getContactColorClass(contact)"
            :userNameInitials="getContactInitials(contact)"
            :beneficiaryName="contact.alias"
            :beneficiaryFullName="contact.contactName"
            :beneficiaryNumber="`${contact.bank} - ${contact.number}`"
            :noAccount="!contact.isPagandoAccount"
            :userImg="imageDownloadLink(contact.image) || imgUserAccount"
            @click="$emit('stepBack')"
          />
        </div>
      </div>
    </CardSimple>

    <div class="control-buttons">
      <button type="button" class="button-icon" @click="$emit('stepBack')">
        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
        {{$t('transfers.new.amount.stepBack')}}
      </button>
      <button
        type="button"
        class="button button-filled button-primary"
        :disabled="$v.$invalid"
        @click="onContinue"
      >
        {{$t('transfers.new.amount.continue')}}
      </button>
    </div>
  </div>
</template>

<script>
import TitleSteps from "@/components/TitleSteps.vue";
import TitleBadge from "@/components/TitleBadge.vue";
import BasicInput from "@/components/BasicInput.vue";
import CardSimple from "@/components/CardSimple.vue";
import AccountType from "@/components/AccountType.vue";
import InputEdit from "@/components/InputEdit.vue";
import ChangeBeneficiary from "@/components/ChangeBeneficiary.vue";
import RadialProgressBar from "vue-radial-progress";
import { mapGetters } from "vuex";
import imgUserAccount from "@/assets/images/Default/profile-photo.svg";
import { getContactColorClass, getContactInitials } from "@/utils/contactUtils";
import {
  STORE as sessionStore,
  GET_ACCOUNT,
  GET_USER,
} from "@/store/modules/session/session.types";
import pagandoExplore from "@/assets/images/Cards/pagando-explore.svg";
import pagandoExplorePlus from "@/assets/images/Cards/pagando-explore-plus.svg";
import pagandoNight from "@/assets/images/Cards/pagando-night.svg";
import pagandoBusiness from "@/assets/images/Cards/pagando-business.svg";
import pagandoBusinessPro from "@/assets/images/Cards/Accounts/businesspro-virtual-front.svg";
import { imageDownloadLink } from "@/utils/utils";
import CardApi from "@/api/wallet/card.api";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import { minLength, maxLength, minValue, required } from "vuelidate/lib/validators";

export default {
  name: "NewTransferAmount",
  mixins: [ToastSnotify],
  components: {
    TitleSteps,
    TitleBadge,
    BasicInput,
    CardSimple,
    AccountType,
    InputEdit,
    ChangeBeneficiary,
    RadialProgressBar,
  },
  props: {
    contact: Object,
    visible: { type: Boolean, default: false },

    // Starting values. Used when resending transfers.
    preamount: Number,
    preconcept: String,
  },
  data() {
    return {
      // Images
      imgUserAccount,

      // ProgressCircle
      diamStep: 64,
      currentStep: 0,
      strokeWStep: 5,
      innerStrokeWStep: 5,
      radialStep: 1,

      // completedSteps: this.lastStep,
      amount: null,
      concept: null,
      operationalLimit: {},
      balance: 0,
      commission: 0,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
      account: GET_ACCOUNT,
      user: GET_USER,
    }),
    currency() {
      return this.$options.filters.currency;
    },
    accountDescription() {
      if (
        this.account &&
        this.account.account &&
        this.account.account.accountType
      ) {
        return this.account.account.accountType.description;
      }
      return null;
    },
    accountImage() {
      const defaultImage = pagandoExplore;
      if (!this.account || !this.account.template) {
        return defaultImage;
      }

      if (this.account.template.image) {
        return imageDownloadLink(this.account.template.image);
      }

      switch (this.account.template.product) {
        case "EXPLORE":
          return pagandoExplore;
        case "EXPLORE_PLUS":
          return pagandoExplorePlus;
        case "NIGHT":
          return pagandoNight;
        case "BUSINESS":
          return pagandoBusiness;
        case "BUSINESS_PRO":
          return pagandoBusinessPro;
        default:
          return defaultImage;
      }
    },
    remainingBalance() {
      return this.balance - this.amount - this.commission;
    },
    predefinedConcepts() {
      return [
        this.$t('transfers.new.amount.predefinedConcepts.hello'),
        this.$t('transfers.new.amount.predefinedConcepts.sentFromPagando'),
        this.$t('transfers.new.amount.predefinedConcepts.transferBeetween'),
        this.$t('transfers.new.amount.predefinedConcepts.from', this.user),
      ];
    },
    maxLimit() {
      if (this.operationalLimit && this.operationalLimit.limit) {
        const { limit, current } = this.operationalLimit;
        return Math.min(limit - current, this.balance - this.commission);
      }
      return this.balance - this.commission;
    },
    conceptErrorMessage() {
      if(!this.$v.concept.noSpecialCharacters) {
        return this.$t('transfers.new.amount.validationErrors.noSpecialCharacters');
      }
      if(!this.$v.concept.minLength) {
        return this.$t('transfers.new.amount.validationErrors.minLength');
      }
      if(!this.$v.concept.maxLength) {
        return this.$t('transfers.new.amount.validationErrors.maxLength');
      }
      return null;
    }
  },
  mounted() {
    this.getOperationalLimit();
    this.getCurrentBalance();
    this.getCommission();

    if(this.preamount) {
      this.amount = this.preamount;
    }
    if(this.preconcept) {
      this.concept = this.preconcept;
    }
  },
  methods: {
    getContactColorClass,
    getContactInitials,
    imageDownloadLink,
    onInputAmount(event) {
      const value = event.target.value;
      this.amount = value;
    },
    async getOperationalLimit() {
      try {
        const response = await CardApi.getOperationLimitsByAccount(this.account.account._id);
        if (response.data.error) {
          throw new Error(response.data.message);
        }
        const limits = response.data.object;
        this.operationalLimit = limits.find(
          (limit) => limit.id === "AMOUNT_DEPOSITS_TRANSFERS"
        );
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    async getCurrentBalance() {
      try {
        const response = await CardApi.getAccountInfo({
          paymentSourceId: this.account.cards[0].card._id,
          provider: this.account.account.externalProvider,
        });
        if (response.data.error) {
          throw new Error(response.data.message);
        }
        this.balance = parseFloat(response.data.object.balance);
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    async getCommission() {
      try {
        const response = await CardApi.getCommissionForEvent({
          pagandoAccount: this.account.account._id,
          commission: this.contact.isPagandoAccount
            ? "PAGANDO_TRANSFER"
            : "SPEI_TRANSFER",
        });
        if (response.data.error) {
          throw new Error(response.data.message);
        }
        this.commission = parseFloat(response.data.object);
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    onContinue() {
      this.$emit("nextStep", {
        amount: this.amount,
        concept: this.concept,
      });
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue, oldValue) {
        this.radialStep = oldValue === false ? 3 : 1;
        setTimeout(() => {
          this.radialStep = 2;
        }, 0);
      },
    },
  },
  validations() {
    return {
      amount: {
        minValue: minValue(1),
        maxValue: (value) => value <= this.maxLimit,
      },
      concept: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40),
        noSpecialCharacters: (value) => /^[A-Za-z0-9\s]*$/.test(value)
      },
    };
  }
};
</script>
