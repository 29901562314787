<template>
  <div>
    <vue-snotify></vue-snotify>
    <div id="" class="responsive-top-bar">
      <span>
        <button
          type="button"
          id="showLeftMenu"
          class="button-square xs button-filled button-primary"
          :aria-label="$t('collaborators.actions.showMenu')"
          :name="$t('collaborators.actions.showMenu')"
          @click="showMenu"
        >
          <i aria-hidden="true" class="iconsax-essential-menu"></i>
        </button>
      </span>
      <span>
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          width="114"
          height="25"
          alt="Pagando Logo"
        />
      </span>
      <span></span>
    </div>

    <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show' : '']">
      <template v-slot:buttonClose>
        <button
          type="button"
          class="button-close-menu"
          :aria-label="$t('collaborators.actions.hideMenu')"
          :name="$t('collaborators.actions.hideMenu')"
          @click="hideMenu"
        >
          <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
        </button>
      </template>
    </SidebarMenuPrincipal>
    <div class="sidebar-menu-backdrop menu-principal"></div>

    <section id="main-view" class="p-r-0">
      <div class="page-container content-centered">
        <div>
          <div class="row">
            <div class="col-12">
              <router-link
                to="/wallet/my-account"
                class="button xs button-ghost button-text_info p-l-0 p-r-0"
                tabindex=""
              >
                <i
                  aria-hidden="true"
                  class="iconsax-arrow-arrow-left f-18 c-info m-r-10"
                ></i>
                {{$t('collaborators.actions.back')}}
              </router-link>
              <FloatingTitleLayout
                :titleStrong="$t('collaborators.list.title')"
              ></FloatingTitleLayout>
              <FloatingTitleLayout
                :titleStrong="$t('collaborators.list.subtitle')"
                :subtitle="$t('collaborators.list.description')"
              >
              </FloatingTitleLayout>
            </div>
            <div class="col-12">
              <div v-if="loading">
                <LoadingBar clazz="p-t-50 p-b-50" loadingText="Buscando..."></LoadingBar>
              </div>
              <div v-else v-for="(collaborator, index) in collaborators" :key="index">
                <ListItemCollaborator
                  v-if="['ACCOUNT_HOLDER', 'COLLABORATOR'].includes(collaborator.status)"
                  v-model="collaborators[index]" :key="index"
                />
                <ListItemDraft
                  v-if="['DRAFT'].includes(collaborator.status)"
                  v-model="collaborators[index]" :key="index"
                />
                <ListItemPendingAuth
                  v-if="['PENDING_AUTHORIZATION', 'PENDING_REMOVAL', 'PENDING_EDIT'].includes(collaborator.status)"
                  v-model="collaborators[index]" :key="index"
                  @reloadCollaborator="reloadCollaborator"
                />
                <ListItemRejected
                  v-if="['REJECTED'].includes(collaborator.status)"
                  v-model="collaborators[index]" :key="index"
                />
                <ListItemInvited
                  v-if="['PENDING_INVITE'].includes(collaborator.status)"
                  v-model="collaborators[index]" :key="index"
                />
              </div>

              <button
                type="button"
                class="button-square-add m-b-40"
                v-if="canRequestCollaborator"
                @click="addDraft"
              >
                <i aria-hidden="true" class="iconsax-essential-add m-r-10"></i>
                {{$t('collaborators.actions.addDraft')}}
              </button>

              <b-alert class="alert-note" show variant="info">
                <p class="text-container">
                  <i
                    aria-hidden="true"
                    class="icon banana banana-info-circle2"
                  ></i>
                  <span v-html="$t('collaborators.footers.note')"/>
                </p>
              </b-alert>
            </div>
            <div class="col-12">
              <div class="control-buttons">
                <button type="button" class="button-icon">
                  <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                  {{$t('collaborators.actions.return')}}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <FooterPage></FooterPage>
  </div>
</template>
<script>
// @ is an alias to /src
import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import FooterPage from "@/components/FooterPage.vue";
import LoadingBar from "@/components/LoadingBar.vue";

import ListItemCollaborator from "@/components/collaborators/Collaborator.vue";
import ListItemDraft from "@/components/collaborators/CollaboratorDraft.vue";
import ListItemPendingAuth from "@/components/collaborators/CollaboratorPendingAuth.vue";
import ListItemRejected from "@/components/collaborators/CollaboratorRejected.vue";
import ListItemInvited from "@/components/collaborators/CollaboratorInvited.vue";

import imgEmptystateInvitacion from "@/assets/images/Emptystates/emptystate-invitation.svg";
import imgErrorStatus from "@/assets/images/Illustrations/illustration-status-error.svg";
import imgEmptystateInvitacionEnviada from "@/assets/images/Emptystates/emptystate-invitation-sent.svg";
import imageModalInvitationCancel from "@/assets/images/Icons/icon-notification.svg";
import imageModalDeleteCollaborator from "@/assets/images/Icons/icon-close.svg";

import ToastSnotify from "@/mixins/toastSnotify.mixin";
import MenuHandler from "@/mixins/menuHandler.mixin";
import CollaboratorsMixin from "@/mixins/collaboratorPermissions.mixin";

import CollaboratorsApi from "@/api/wallet/collaborators.api";

export default {
  name: "CollaboratorsList",
  mixins: [ToastSnotify, MenuHandler, CollaboratorsMixin],
  components: {
    SidebarMenuPrincipal,
    FloatingTitleLayout,
    FooterPage,
    ListItemCollaborator,
    ListItemDraft,
    ListItemPendingAuth,
    ListItemRejected,
    ListItemInvited,
    LoadingBar,
  },
  data() {
    return {
      show: true,
      // Images
      imgEmptystateInvitacion: imgEmptystateInvitacion,
      imgErrorStatus: imgErrorStatus,
      imgEmptystateInvitacionEnviada: imgEmptystateInvitacionEnviada,
      imageModalInvitationCancel: imageModalInvitationCancel,
      imageModalDeleteCollaborator: imageModalDeleteCollaborator,

      collaborators: [],
      loading: false,
    };
  },
  mounted() {
    this.loadCollaborators();
  },
  methods: {
    async loadCollaborators() {
      try {
        this.loading = true;
        const response = await CollaboratorsApi.list(
          this.infoAccount?.account?._id
        );
        this.collaborators = response.data.object;
        this.loading = false;
      } catch (error) {
        this.toastErrorDefault(error);
        this.loading = false;
      }
    },
    addDraft() {
      this.collaborators.push({ status: "DRAFT", permissions: [] });
    },
    reloadCollaborator(collaborator) {
      const index = this.collaborators.findIndex(c => c._id === collaborator._id);
      if(index >= 0) {
        this.$set(this.collaborators, index, collaborator);
      }
    },
  },
};
</script>
