import {
    STORE as SessionStore,
    SET_TOKEN, GET_TOKEN,
    SET_USER, GET_USER,
    SET_USER_EMAIL,
    SET_FULL_DATA_USER,
    SET_PERMISSIONS, GET_PERMISSIONS,
    SET_TOKEN_EXPIRE_DATE, GET_TOKEN_EXPIRE_DATE,
    CLEAR_STORE, GET_EMAIL_USER, GET_FULL_DATA_USER,
    LOAD_FULL_DATA_USER,
    GET_LOADING, SET_LOADING,
    SET_TEMP_WALLET_USER,
    GET_ACCOUNT, SET_ACCOUNT, GET_ACCOUNT_DES,
    SET_CARD_STATUS, GET_SMS_TWO_FACTOR,
    GET_ACCOUNT_DESCRIPTION, GET_ACCOUNT_NUMBER,
    GET_USER_NAME,
    GET_USER_FULL_NAME, GET_USER_SELF_PORTRAIT,
    GET_USER_HAS_UNIFIED_PIN, SET_USER_HAS_UNIFIED_PIN,
    GET_USER_CVV_MIGRATION_STATUS, SET_USER_CVV_MIGRATION_STATUS,
    CHECK_PERMISSION, GET_ACCOUNT_ID,
    GET_ACCOUNT_IMG,
    SET_NOTIFICATION_EMAIL,
    SET_SMS_TWO_FACTOR,
    SET_FACIAL_RECOGNITION_AUTH,
    SET_FINGER_PRINT_AUTH,
    GET_LOCATION, SET_LOCATION,
    GET_ORG_INFO,
    GET_MAIN_ACCOUNT, SET_MAIN_ACCOUNT,
    GET_PRODUCT_TYPE,
    GET_COLLAB_ACCOUNT,
    SET_COLLAB_ACCOUNT,
} from "@/store/modules/session/session.types";
import UsersApi from "@/api/users/users.api";
import AccountsApi from '@/api/wallet/accounts.api';
import {catchError} from "@/utils/utils";
import {EventBus} from "@/main";
import {CARD_DEFAULT_IMG} from "@/enums/defalutCardImg.enum";
import { imageDownloadLink } from '@/utils/utils';
import axios from "axios";
import {
STORE as organizationInfoStore
} from "@/store/modules/organizationInfo/organizationInfo.types";

const state = {
    token: '',
    tokenExpireDate: new Date(),
    permissions: {},
    user: {},
    fullDataUser: {},
    loading: true,
    currentAccount: {
        account: {},
        cards: [],
        template: {}
    },
    location: {
        lat: 0,
        lon: 0,
        error: false
    },
    accountCollab: null
};

const getters = {
    [GET_TOKEN]: (state) => state.token,
    [GET_LOADING]: (state) => state.loading,
    [GET_USER]: (state) => state.user,
    [GET_FULL_DATA_USER]: (state) => state.fullDataUser,
    [GET_EMAIL_USER]: (state) => state.user.email,
    [GET_PERMISSIONS]: (state) => state.permissions,
    [GET_TOKEN_EXPIRE_DATE]: (state) => state.tokenExpireDate,
    [GET_ACCOUNT]: (state) => state.currentAccount,
    [GET_MAIN_ACCOUNT]: (state) => !!state.currentAccount?.isMainAccount,
    [GET_ACCOUNT_DESCRIPTION]: (state) => state.currentAccount.account.accountType.description,
    [GET_ACCOUNT_NUMBER]: (state) => state.currentAccount.account.fullKey,
    [GET_USER_NAME]: (state) => state.user.name,
    [GET_USER_FULL_NAME]: (state) => state.user.fullName || `${state.user.name} ${state.user.lastName} ${state.user.secondLastName}`,
    [GET_USER_SELF_PORTRAIT]: (state) => state.user.selfPortrait,
    [GET_USER_HAS_UNIFIED_PIN]: (state) => !!state.user.hasUnifiedPin,
    [GET_USER_CVV_MIGRATION_STATUS]: (state) => state.user.statusMigrationCVV,
    [GET_COLLAB_ACCOUNT]: (state) => state.accountCollab,
    [GET_ACCOUNT_DES]: (state) => {
        if(state.currentAccount.account && state.currentAccount.account.accountType) {
            return state.currentAccount.account.accountType.description;
        }
        return "";
    },
    [GET_SMS_TWO_FACTOR]: (state) => state.user.userSmsAsTwoFactor,
    [CHECK_PERMISSION]: (state) => (action, type) => {
        const account = state.currentAccount;
        if(account && account.isAccountHolder) {
            return true;
        }
        if(account && account.permissions) {
            return account.permissions.some(p => p.action === action && p.type === type);
        }
        return false;
    },
    [GET_ACCOUNT_ID]: (state) => state.currentAccount?.account?._id,
    [GET_ACCOUNT_IMG]: (state) => {
        const defaultImage = CARD_DEFAULT_IMG.pagandoExplore;

        if(state.currentAccount?.template?.image) {
            return imageDownloadLink(state.currentAccount.template.image);
        }

        switch (state.currentAccount?.template?.product) {
            case "EXPLORE":
                return CARD_DEFAULT_IMG.pagandoExplore;
            case "EXPLORE_PLUS":
                return CARD_DEFAULT_IMG.pagandoExplorePlus;
            case "NIGHT":
                return CARD_DEFAULT_IMG.pagandoNight;
            case "BUSINESS":
                return CARD_DEFAULT_IMG.pagandoBusiness;
            case "BUSINESS_PRO":
                return CARD_DEFAULT_IMG.pagandoBusinessPro;
            default:
                return defaultImage;
        }
    },
    [GET_LOCATION]: (state) => state.location,
    [GET_ORG_INFO]: (state) => {
        if (state.currentAccount && state.currentAccount.orgInfo) {
            return state.currentAccount.orgInfo;
        }
        return {}
    },
    [GET_PRODUCT_TYPE]: (state) => state.currentAccount?.account?.accountType?.productType,
};

const mutations = {
    [SET_COLLAB_ACCOUNT]: (state, payload) => {state.accountCollab = payload},
    [SET_TOKEN]: (state, payload) => {state.token = payload},
    [SET_LOADING]: (state, payload) => {
        state.loading = payload},
    [SET_USER]: (state, payload) => {
        state.user = payload;
    },
    [SET_USER_EMAIL]: (state, payload) => {
        state.user.email = payload;
    },
    [SET_NOTIFICATION_EMAIL]: (state, payload) => {
        state.user.emailNotification = payload;
    },
    [SET_FINGER_PRINT_AUTH]: (state, payload) => {
        state.user.userFingerPrintAuth = payload;
    },
    [SET_FACIAL_RECOGNITION_AUTH]: (state, payload) => {
        state.user.userFacialRecognitionAuth = payload;
    },
    [SET_SMS_TWO_FACTOR]: (state, payload) => {
        state.user.userSmsAsTwoFactor = payload;
    },
    [SET_FULL_DATA_USER]: (state, user) => {
        state.fullDataUser = user;
    },
    [SET_USER_HAS_UNIFIED_PIN]: (state, value) => {
        state.user.hasUnifiedPin = value;
    },
    [SET_USER_CVV_MIGRATION_STATUS]: (state, value) => {
        state.user = Object.assign({}, state.user, { statusMigrationCVV: value });
    },
    [SET_TEMP_WALLET_USER]: (state, value) => {
      state.user.walletUser = value;
    },
    [SET_PERMISSIONS]: (state, payload) => {state.permissions = payload},
    [SET_TOKEN_EXPIRE_DATE]: (state, payload) => {state.permissions = payload},
    [SET_ACCOUNT]: (state, account) => {
        state.currentAccount = account;
        if (account) {
            localStorage.setItem('lastAccount', account.account._id);
            axios.defaults.headers.common["x-account-id"] = account?.account?._id;
        }
        EventBus.$emit(`${SessionStore}.${SET_ACCOUNT}`);
    },
    [SET_MAIN_ACCOUNT]: (state, value) => {
        if(state.currentAccount) {
            state.currentAccount.isMainAccount = value;
        }
    },
    [SET_CARD_STATUS]: (state, {id, status}) => {
        if(state.currentAccount && state.currentAccount.cards) {
            const card = state.currentAccount.cards.find(card => card.card._id === id).card;
            card.status = status;
        }
    },
    [CLEAR_STORE]: (state) => {
        state.token = '';
        state.tokenExpireDate= new Date();
        state.permissions= {};
        state.user= {};
        state.profile= {};
        state.currentAccount = null;
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentToken');
        localStorage.removeItem('currentPermissions');
        localStorage.removeItem('profile');
        localStorage.removeItem('expireDate');
        localStorage.removeItem('s1');
    },
    [SET_LOCATION]: (state, {lat, lon, error}) => {state.location = {lat, lon, error}},
};


const actions = {
    async getFullDataLevelTwo({commit}, params) {
        const response = await UsersApi.getFullDataLevelTwo(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_FULL_DATA_USER, response.data.object);
            EventBus.$emit(`${SessionStore}.${LOAD_FULL_DATA_USER}`);

            return true
        }
        return false;
    },
    async getFullDataLevelThree({commit}, params) {
        const response = await UsersApi.getFullDataLevelThree(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_FULL_DATA_USER, response.data.object);
            EventBus.$emit(`${SessionStore}.${LOAD_FULL_DATA_USER}`);

            return true
        }
        return false;
    },

    async getFullDataByLevel({dispatch}, params) {
        const level = params.level || "LEVEL_TWO"
        if (level === "LEVEL_THREE") {
            return dispatch(`getFullDataLevelThree`);
        }
        return dispatch(`getFullDataLevelTwo`);
    },

    /**
     * Selecciona una cuenta del usuario para ser usada. Carga la informacion de la cuenta y la guarda en el state del store.
     *
     * @param {*} context El contexto. Vuex manda automaticamente este parametro, no se necesita mandarlo en el dispatch.
     * @param {String} accountId (opcional) El id de la cuenta. Si no se especifica, se usa la primer cuenta que se encuentre del usuario.
     * @returns {Boolean} Verdadero si la ejecucion fue exitosa, falso si hubo algun error.
     */
    async loadAccount({commit}, accountId) {
        const response = await AccountsApi.loadAccount({accountId: accountId || localStorage.getItem('lastAccount')}).catch(catchError);
        if (response.data && !response.data.error && response.data.object) {
            commit(SET_ACCOUNT, response.data.object);
            await this.dispatch(`${organizationInfoStore}/getOrganizations`);
            return true;
        }
        return false;
    },

    async loadAccountCollab({commit}, userId) {
        const response = await AccountsApi.loadAccountCollab({userId: userId}).catch(catchError);
        if (response.data && !response.data.error && response.data.object) {
            commit(SET_COLLAB_ACCOUNT, response.data.object);
            return true;
        }
        return false;
    },
};

export default {
    namespaced: true,
    state: {
        ...state
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    }
};
