<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('additionalCards.goBack')}}
                            </router-link>
                            <FloatingTitleLayout
                                :titleStrong="$t('additionalCards.title')"
                                :subtitle="$t('additionalCards.subtitle')">
                            </FloatingTitleLayout>
                        </div>

                        <div class="col-12">
                            <PanelMyAccount
                                :primaryTitle="$t('additionalCards.CLABE')"
                                :primaryText="account.account.providerCLABE"
                                :secondTitle="$t('additionalCards.noAccount')"
                                :secondText="account.account.fullKey"
                                additionalCards
                                :addCardTitle="$t('additionalCards.numAdditional')"
                                :addCardText="getTotalCardsAdditionals()">
                                <AccountType :text="$t('additionalCards.myAccount')"
                                            :typeAccount="accountDescription"
                                            clazz="p-relative imgLeft">
                                            <template v-slot:extraText>
                                                <button v-if="accounts.length > 1"
                                                    @click="showModal()"
                                                    type="button"
                                                    class="button xs button-stroke button-primary m-t-5">
                                                    {{$t('additionalCards.changeAccount')}}
                                                </button>
                                            </template>
                                </AccountType>
                            </PanelMyAccount>
                        </div>

                        <div class="col-12">
                            <CardSimple v-if="cards.length > 0"
                                :title="$t('additionalCards.myCards')"
                                :subtitle="$t('additionalCards.consultCards')">
                                <div class="vertical-center justify-space-between column-gap-30px w-100 p-15" :class="getLastItem(index)"
                                    v-for="(card, index) of cards" :key="card.card._id">
                                    <AccountType
                                        clazz="p-relative imgLeft m-0-auto-0-0">
                                        <img class="img-fluid" :src="accountImage(card)" width="80" height="50" :alt="accountDescription"/>
                                        <template v-slot:extraText>
                                            <label class="f-12 c-info poppins-font-semibold m-b-0">{{getAlias(card.card, index)}}</label>
                                            <p class="f-12 c-text_title poppins-font-regular m-b-0">{{getProductName(card.card)}}</p>
                                            <strong class="f-14 c-text_title poppins-font-bold m-b-0">{{getLastFour(card.card)}}</strong>
                                        </template>
                                    </AccountType>
                                    <span class="vertical-center w-xs-100">
                                        <span class="badge badge-primary m-r-20" v-if="card.card.default">
                                            <i aria-hidden="true" class="badge-icon iconbullet"></i>
                                            {{$t('additionalCards.principal')}}
                                        </span>
                                        <button
                                            @click="editCard(card)"
                                            :id="`tooltip-button-edit-${index}`"
                                            type="button"
                                            class="button-circle button-ghost button-primary m-0-0-0-auto"
                                            name="Editar alias"
                                            aria-label="Editar alias">
                                            <i aria-hidden="true" class="iconsax-content-edit"></i>
                                        </button>
                                        <b-tooltip
                                            :target="`tooltip-button-edit-${index}`"
                                            placement="top">
                                            <p class="m-b-0">
                                                {{$t('additionalCards.editAlias')}}
                                            </p>
                                        </b-tooltip>
                                    </span>
                                </div>
                                <button
                                    v-if="checkAdditionalCards()"
                                    @click="addCard()"
                                    type="button"
                                    class="button-square-add m-t-40">
                                    <i aria-hidden="true" class="iconsax-essential-add"></i>
                                    {{$t('additionalCards.addNewCard')}}
                                </button>
                            </CardSimple>
                        </div>

                        <div class="col-12">
                            <div class="control-buttons">
                                <button
                                    @click="goBack()"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('additionalCards.back')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <b-modal
            id="modal-cambiar-cuenta"
            size="lg"
            hide-header
            hide-footer
            modal-class="modal-default left-oriented">
            <button class="close-modal"
                    @click="$bvModal.hide('modal-cambiar-cuenta')"
                    aria-label="Boton cerrar ventana"
                    name="Boton cerrar ventana">
                <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <div class="head">
                <h2 class="modal-title">Cambiar de cuenta</h2>
                <p class="modal-subtitle">
                    Selecciona una de tus cuentas para
                    ver y administrar tus movimientos.
                </p>
            </div>
            <div class="body">
                <!-- Add clazz "selected" when radioInput selected -->
                <LoadingBar v-if="loadingAccounts" clazz="fullcontent-loading"/>
                <PanelMyAccount
                    v-for="account in accounts"
                    v-bind:key="account._id"
                    :primaryTitle="$t('changeAccount.balance')"
                    :primaryText="account.balance | currency"
                    :secondTitle="$t('changeAccount.noAccount')"
                    :secondText="account.fullKey"
                    :selectable="true"
                    :hideButtons="true"
                    :clazz="`b-shadow-none centered-xs ${modalSelection === account._id ? 'selected' : ''}`">
                    <AccountType
                        :text="$t('changeAccount.myAccount')"
                        :typeAccount="account.description"
                        clazz="p-relative imgLeft">
                        <img class="img-fluid" :src="accountImageFromAccount(account)" width="64" height="40" :alt="account.description"/>
                    </AccountType>
                    <template v-slot:selectable>
                        <RadioInput
                            clazz="m-b-0"
                            :idInput="account._id"
                            :currentValue="modalSelection === account._id"
                            @selected="modalAccountSelected(account._id)"
                        ></RadioInput>
                    </template>
                </PanelMyAccount>
            </div>
            <div class="footer">
                <button
                    v-if="!accountModalLoading"
                    type="button"
                    class="button button-filled button-primary w-100"
                    :disabled="!modalSelection"
                    @click="changeAccount"
                >
                    {{$t('changeAccount.button')}}
                </button>
                <button
                    v-else
                    type="button"
                    class="button button-filled button-primary w-100"
                >
                    <LoadingSimple clazz="small-load no-text" />
                </button>
            </div>
        </b-modal>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>
    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import PanelMyAccount from "@/components/PanelMyAccount.vue";
    import AccountType from "@/components/AccountType.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import RadioInput from "@/components/RadioInput.vue";
    import LoadingSimple from "@/components/LoadingSimple.vue";
    import {mapGetters} from "vuex";
    import {
        STORE as sessionStore,
        GET_ACCOUNT
    } from "@/store/modules/session/session.types";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import {CARD_DEFAULT_IMG} from "@/enums/defalutCardImg.enum";
    import { imageDownloadLink } from '@/utils/utils';
    import CardApi from '@/api/wallet/card.api';
    // import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";

    export default {
        name: "AdditionalCards",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            PanelMyAccount,
            AccountType,
            CardSimple,
            RadioInput,
            LoadingSimple,
            // ReadOnlyInfo,
        },
        data() {
            return {
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
                accounts: [],
                modalSelection: null,
                accountModalLoading: false,
                loadingAccounts: false,
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                account: GET_ACCOUNT
            }),
            accountDescription() {
                if(this.account && this.account.account && this.account.account.accountType) {
                    return this.account.account.accountType.description;
                }
                return null;
            },
            commission() {
                if(this.account && this.account.account && this.account.account.accountType) {
                    const commissions = this.account.account.accountType.commissions;

                    return commissions.find(c => c.events === "VIRTUAL_ADDITIONAL_COST");
                }

                return null;
            },
            cards() {
                if(this.account && this.account.cards) {
                    return this.account.cards.filter(c => c.card.manufacturingType === 'VIRTUAL');
                }
                return null;
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            getTotalCardsAdditionals() {
                if(this.cards && this.commission) {
                    const cardsAdditionals = this.cards.filter(c => !c.card.default);
                    const totalCards = this.account.account.accountType.limitAdditionalCards;

                    return this.$t('additionalCards.numCards', {cards: cardsAdditionals.length, total: totalCards});
                }

                return this.$t('additionalCards.numCards', {cards: 0, total: 0});
            },
            getAlias(card, index) {
                if(card.alias) {
                    return card.alias
                }

                return this.$t('additionalCards.aliasDefault', {num: index+1});
            },
            getProductName(card) {
                const type = this.$t(`typeCards.${card.manufacturingType}`);
                return `${this.accountDescription} ${type}`;
            },
            getLastFour(card) {
                return `•••••••• ${card.last4}`;
            },
            accountImage(card) {
                const defaultImage = CARD_DEFAULT_IMG.pagandoExplore;
                if(!card || !card.cardTemplate) {
                    return defaultImage;
                }

                switch (card.cardTemplate.product) {
                    case "EXPLORE":
                        return CARD_DEFAULT_IMG.pagandoExplore;
                    case "EXPLORE_PLUS":
                        return CARD_DEFAULT_IMG.pagandoExplorePlus;
                    case "NIGHT":
                        return CARD_DEFAULT_IMG.pagandoNight;
                    case "BUSINESS":
                        return CARD_DEFAULT_IMG.pagandoBusiness;
                    case "BUSINESS_PRO":
                        return CARD_DEFAULT_IMG.pagandoBusinessPro;
                    default:
                        if(card.cardTemplate.image) {
                            return imageDownloadLink(card.cardTemplate.image);
                        }

                        return defaultImage;
                }
            },
            accountImageFromAccount(account) {
                const defaultImage = CARD_DEFAULT_IMG.pagandoExplore;

                switch (account.product) {
                    case "EXPLORE":
                        return CARD_DEFAULT_IMG.pagandoExplore;
                    case "EXPLORE_PLUS":
                        return CARD_DEFAULT_IMG.pagandoExplorePlus;
                    case "NIGHT":
                        return CARD_DEFAULT_IMG.pagandoNight;
                    case "BUSINESS":
                        return CARD_DEFAULT_IMG.pagandoBusiness;
                    case "BUSINESS_PRO":
                        return CARD_DEFAULT_IMG.pagandoBusinessPro;
                    default:
                        if(account.image) {
                            return imageDownloadLink(account.image);
                        }
                        return defaultImage;
                }
            },
            modalAccountSelected(id) {
                this.modalSelection = id;
            },
            addCard() {
                this.$router.push({name: 'AdditionalCardNew'});
            },
            editCard(card) {
                this.$router.push({name: 'AdditionalCardEdit', params: {card: card}});
            },
            checkAdditionalCards() {
                const cardsAdditionals = this.cards.filter(c => !c.card.default);

                return cardsAdditionals.length < this.account.account.accountType.limitAdditionalCards;
            },
            getLastItem(index) {
                const length = this.cards.length;

                if((length - 1) !== index) {
                    return 'brd-divider';
                }

                return '';
            },
            goBack() {
                this.$router.push({name: 'Dashboard'});
            },
            async showModal() {
                this.$bvModal.show('modal-cambiar-cuenta')
            },
            async loadAccounts() {
                try {
                    this.loadingAccounts = true;
                    const response = await CardApi.getAccountsRequestAdditionalWithBalance();
                    this.accounts = response.data.object;
                    const currentAccountId = this.account && this.account.account ? this.account.account._id : null;
                    if(this.accounts.find(acc => acc._id === currentAccountId)) {
                        this.modalSelection = currentAccountId;
                    }
                    this.loadingAccounts = false;
                } catch (error) {
                    this.toastErrorDefault(error);
                    this.loadingAccounts = false;
                }
            },
            changeAccount() {
                this.accountModalLoading = true;
                this.$store.dispatch(`${sessionStore}/loadAccount`, this.modalSelection).then(success => {
                    if(success) {
                        this.accountModalLoading = false;
                        this.$bvModal.hide('modal-cambiar-cuenta');
                    } else {
                        this.accountModalLoading = false;
                        this.toastErrorDefault();
                    }
                });
            },
        },
        created() {
          this.setCurrentPermissionTypes(this.permissionTypes.REQUEST_VIRTUAL_ADDITIONAL_CARD);
        },
        async mounted () {
            if(this.canRequest) {
                this.handleResizeMenu();
                window.addEventListener('resize', this.handleResizeMenu);

                await this.loadAccounts();
            } else {
                this.$router.push({name: 'Dashboard'});
            }

        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
