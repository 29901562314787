/* eslint-disable */
/* --------------Guía de contribución al i18n---------------
La estructura general es simple:
{{clasificación}}.{{sub-clasificación}}[.{{llave-del-mensaje}}][.{{llave-del-mensaje}}]

Reglas generales:
    - No se permiten mayúsculas.
    - Todos los segmentos de la llave se separan con punto (.)
    - No se permiten mensajes sin clasificación:
        ej.
            x incorrecto:
                "nombre": "Nombre"
                "email": "Email"
            ✓ correcto:
                "usuarios.nombre": "Nombre"
                "usuarios.email": "Email"

    - Si un segmento tiene más de una palabra, deben separarse con guión (-).
    - No duplicar mensajes para plurales. Se debe usar la pluralización de vue-i18n (http://kazupon.github.io/vue-i18n/guide/pluralization.html)
        ej.
            x incorrecto:
                "usuarios.usuario": "Usuario"
                "usuarios.usuarios": "Usuarios"

            ✓ correcto:
                "usuarios.usuario": "Usuario | Usuarios"

    - No se permiten acentos, así que de preferencia utiliza "hacks" para palabras que no suenen bien:
        ej. año => anio

    - Si se usa inglés, por favor verifica que lo que escribas es correcto
        ej.
            x incorrecto:
                clasification
            ✓ correcto:
                classification (doble 's')

            x incorrecto:
                wizzard
            ✓ correcto:
                wizard (una sola 'z')

    - Si ya existe una internacionalización que corresponde a lo que deseas usar, verifica que tenga sentido reutilizarla.
        ej.
            Si quieres colocar "Email" para usuario.email y ya existe la siguiente internacionalización:
                "proveedor.email": "Email"

            Es tentador usarla, ¿pero que pasa si en un futuro se cambia el valor de la siguiente manera?
                "proveedor.email": "Email del Proveedor"

            Este uso es incorrecto, por reutilizar el i18n de una llave existente, pero que no corresponde a la misma propiedad,

            Aunque tenga el mismo valor, conceptualmente la i18n puede ser distinta

    - Mantén las clasificaciones juntas y ordenadas. Es tentador agregar tus i18ns al final, pero ayuda a mantener el orden de la aplicación.
        ej.
            x incorrecto:
                usuarios.foo.1
                proveedores.foo
                usuarios.bar
                usuarios.foo.2
                comparar.baz
                usuarios.baz
                proveedores.bar
                usuarios.foo.3
                comparar.foo
                comparar.bar


            ✓ correcto:
                usuarios.foo.1
                usuarios.foo.2
                usuarios.foo.3
                usuarios.bar
                usuarios.baz
                proveedores.foo
                proveedores.bar
                comparar.foo
                comparar.bar
                comparar.baz

¿Inglés o español?
    Se prefiere español para términos de los procesos y los datos de la plataforma.
        Ej. proveedor, calculo, tipo-rfc

    Se prefiere inglés para términos de sistemas.
        Ej. error, info, validations, warning, required

    Si tienes dudas, o ambos lenguajes son viables, puedes basarte en un uso similar que ya exista, o confiar en tu corazón.


--------Clasificaciones (procesos)-------
general
usuarios
proveedores
unidades-administrativas
recursos
contratos
calculos
comparar

--------Subclasificaciones (partes de los procesos anteriores)-------
error
success
info
warning
[nombre-de-propiedad]
[nombre-de-accion] (ejemplos: "save", "delete")

--------- Sub-sub-clasificaciones de [nombre-de-propiedad] ---------
validations
status
[sub-propiedades-de-la-propiedad]

--------Ejemplos-------
"general.app-name": "Monitor Karewa"
"general.copyright": "Black Labs 2019"
"general.error.cargar-registros": "No fue posible cargar los registros. Por favor recarga la página e intenta nuevamente."
"general.info.loading": "Cargando..."

"usuarios.usuario": "Usuario"
"usuarios.email": "Email"
"usuarios.email.description": "El email que utilizará el usuario para acceder a la plataforma."
"usuarios.email.placeholder": "Introduce el email del usuario."
"usuarios.email.validations.required": "El email del Usuario es requerido."
"usuarios.email.validations.invalid": "Por favor introduce un email válido para el Usuario."
"usuarios.email.status": "Estatus del email"
"usuarios.email.status.pendiente-validacion": "Pendiente de validar"
"usuarios.email.status.validado": "Validado"

"proveedores.error.duplicado": "El Proveedor ya se encuentra registrado."
"proveedores.warning.tipo-rfc-inconsistente": "El RFC registrado para este Proveedor corresponde a una persona moral, pero el Proveedor es persona física."
"proveedores.rfc": "RFC del Proveedor"
"proveedores.rfc.validations.required": "El RFC del Proveedor es requerido."
 */

import cardRequest from './es/card-request.json';
import pagandoCheck from './es/pagando-check.json';

export default {
    general: {
        pagando: "Pagando",
        back: "Regresar",
        accept: "Aceptar",
        account: "Cuenta",
        CLABE: "CLABE",
        de: "de",
        datesCommon: {
            day: "Día",
            month: "Mes",
            year: "Año",
            dayPh: "DD",
            monthPh: "MM",
            yearPh: "AAAA",
            today: "Hoy"
        },
        months: {
            January: "Enero",
            February: "Febrero",
            March: "Marzo",
            April: "Abril",
            May: "Mayo",
            June: "Junio",
            July: "Julio",
            August: "Agosto",
            September: "Septiembre",
            October: "Octubre",
            November: "Noviembre",
            December: "Diciembre",
        },
        monthsAbbr: {
            January: "Ene",
            February: "Feb",
            March: "Mar",
            April: "Abr",
            May: "May",
            June: "Jun",
            July: "Jul",
            August: "Ago",
            September: "Sep",
            October: "Oct",
            November: "Nov",
            December: "Dic",
        },
        weekDaysAbbr: {
            Sunday: 'D',
            Monday: 'L',
            Tuesday: 'M',
            Wednesday: 'M',
            Thursday: 'J',
            Friday: 'V',
            Saturday: 'S',
        },
        image: {
            sizeError: "La image debe ser de tamaño {width} X {height}"
        },
        actions: {
            error: {
                title: "Ups!",
                text: "Hubo un problema al ejecutar la acción"
            },
            success: {
                title: "Éxito",
                text: "La acción se ha ejecutado exitosamente.",
            },
        },
        modalTwoFactor: {
            titleNip: "Ingresa tu NIP de seguridad",
            subtitleNip: "Esto nos ayuda a verificar tu identidad y confirmar la seguridad de tu cuenta.",
            titleCode: "Ingresa código de confirmación",
            subtitleCode: "Hemos enviado un mensaje SMS con tu código al número celular ",
            warningNip: "El NIP que has ingresado es incorrecto. Inténtalo de nuevo.",
            warningCode: "El código que has ingresado no es válido. Inténtalo de nuevo.",
            infoText: "El SMS puede tardar unos minutos en llegar, si no lo has recibido puedes presionar el siguiente link:",
            loadingNip: "Validando NIP...",
            loadingCode: "Validando código...",
            resendingCode: "Reenviando código...",
            cancel: "Cancelar",
            confirm: "Confirmar",
            reSendIn: "REENVIAR CÓDIGO EN:",
            reSend: "REENVIAR CÓDIGO",
        },
        modalIdleWarning: {
            title: "¡Atención! Tiempo de inactividad superado",
            text: "Para garantizar la seguridad de tus datos y proteger tu privacidad, notamos que has estado inactivo por más de 2 minutos, si deseas continuar, por favor, presiona 'Mantener sesión'",
            buttonCancel: "Salir",
            buttonText: "Mantener sesión",
        },
        returnHome: "Regresar a Inicio",
        return: "Regresar",
        lastAccess: "Último acceso:",
        newAccount: "Solicitar nueva cuenta",
        searching: "Buscando...",
        closeWindowBtn: "Boton cerrar ventana",
    },
    walletLayout: {
        balance: "Saldo disponible"
    },
    register: {
        stepsNames: {
            stepNewAccount: "Crear cuenta",
            stepBusiness: "Información del negocio",
            stepInfo: "Información personal",
            stepInfoAdd: "Información adicional",
            stepSecurity: "Seguridad",
            stepValAcc: "Validación de cuenta",
            stepContract: "Contrato",
            stepBeneficiaries: "Beneficiarios",
            stepPromotion: "Código promocional",
            stepFiscal: "Datos fiscales",
            stepShareholders: "Accionistas",
        },
        selectAccount: {
            title: "¡Empecemos!",
            subtitle: "Crear tu cuenta te tomará solo unos minutos. Elige tu opción preferida:",
            account: "Cuenta",
            newAccount: "Crear cuenta",
            selectedAccount: "Cuenta seleccionada",
            card_personal: {
                title: "Personal",
                info1: "Obtén tu propio wallet",
                info2: "Abona fondo electrónico a tu cuenta",
                info3: "Haz retiros de efectivo",
                info4: "Envía y recibe transferencias vía SPEI",
                info5: "Obtén tu tarjeta física y/o virtual",
                info6: "Realiza tus pagos de servicios",
            },
            card_business: {
                title: "Negocios",
                info1: "Ideal para PyMEs y emprendedores",
                info2: "Vende y recibe pagos en línea",
                info3: "Obtén una terminal para cobrar a tus clientes",
                info4: "Acepta tarjetas de débito y crédito",
                info5: "Ofrece Meses sin Intereses",
                info6: "Analiza las estadísticas de tus ventas",
            },
        },
        onbngSteps: {
            account: "Cuenta",
            personal: "Personal",
            business: "Negocios"
        },
        newAccountBusiness: {
            title: "Crea tu Cuenta de negocios"
        },
        newAccount: {
            title: "Crea tu cuenta Personal",
            subtitle: "¡Hola! Para empezar, necesitamos que ingreses un correo y una contraseña, los cuales usarás para <strong>iniciar sesión y recibir notificaciones</strong> de Pagando.",
            emailLabel: "Correo electrónico",
            emailPlace: "Ej: pablo@mail.com",
            passLabel: "Contraseña",
            passPlace: "Mínimo 6 caracteres con mayúsculas, minúsculas, números y símbolos",
            repeatPassword: "Confirmar contraseña",
            passStatusWeak: "Muy débil",
            passStatusRegular: "Regular",
            passStatusGood: "Buena",
            passStatusExc: "Excelente",
            passTip: "Si deseas una contraseña segura sigue estas recomendaciones:",
            passTip6min: "Usar 6 o más caracteres",
            passTipNo: "Usar al menos un número",
            passTipCap: "Usar al menos una letra mayúscula",
            passTipSymbol: "Usar al menos un caractér especial",
            next: "continuar",
            errorRegisterTitle: "Problema de registro",
            errorRegister: "Hubo un problema tratando de registrar al usuario"
        },
        verifyEmail: {
            title: "Verifica tu correo electrónico",
            subtitle: "Hemos enviado un correo a <strong class=\"c-accent_2\"> {email} </strong> con el vínculo para verificar tu correo electrónico y continuar tu proceso de registro.",
            text: "El correo electrónico puede tardar unos minutos en llegar, si no ha llegado",
            resendEmail: "haz clic aquí para reenviarlo",
            resendEmailSuccess: "se ha vuelto enviar el correo",
            resendEmailSuccessTitle: "Correo enviado",
            resendEmailError: "hubo un problema tratando de enviar el correo",
            resendEmailErrorTitle: "Correo no enviado",

        },
        verificationEmail: {
            title: "Correo verificado correctamente",
            subtitle: "Tu correo se ha validado correctamente, ya puedes continuar con tu proceso de registro.",
            titleError: "Correo no verificado",
            subtitleError: "Tu correo no se ha podido validar",
            continueRegis: "Continuar con el registro",
            attendance: "Si requieres <strong class=\"poppins-font-bold\">asistencia técnica</strong> durante el proceso de registro, por favor utiliza nuestro ",
            emailSuccess: "Correo verificado exitosamente",
            emailError: "Hubo un problema verificando el correo",
        },
        selectAccountCard: {
            title: "¿Cuál es tu cuenta ideal?",
            subtitle: "Elige el tipo de cuenta que deseas crear en Pagando y que mejor se adapte a tus necesidades.",
            errorRequestCardTitle: "Problema en cuentas",
            errorRequestCard: "Hubo un problema tratando de consultar las cuentas",
            errorSaveRequestCard: "Hubo un problema tratando de guardar la cuenta",
            continue: "Continuar",
            back: "Regresar",
            emptyStateTitle: "No se encontraron resultados con la búsqueda",
            emptyStateText: "Buscando documentos",
        },
        usePolicy: {
            title: "Políticas de uso",
            subtitle: "Para crear tu cuenta es necesario que <strong>confirmes que estás de acuerdo</strong> con el aviso de privacidad y los términos y condiciones:",
            noticePrivacy: "Aviso de Privacidad",
            aboutPrivacyData: "Acerca de la privacidad de tus datos.",
            readNotice: "Leer Aviso",
            termsCond: "Términos y Condiciones",
            termsCondDesc: "Sobre las políticas de uso de Pagando.",
            readTerms: "Leer Términos",
            locationTitle: "Permiso de acceso a tu ubicación",
            locationSubTitle: "Por disposiciones oficiales, conocer tu ubicación cuando Pagando esté en uso nos permite garantizar la seguridad de tu cuenta.",
            errorDocumentsTitle: "Problema con políticas",
            errorDocuments: "Hubo un problema tratando de cargar las políticas de uso",
            modalUsePrivacy: "Aviso de Privacidad de tus datos",
            modalAcceptUsePrivacy: "Acepto el Aviso de Privacidad",
            lastUpdated: "Última actualización: ",
            modalTermsCond: "Términos y Condiciones de uso de Pagando",
            modalAcceptTermsCond: " Acepto el Aviso de Privacidad",
            continue: "Continuar",
            back: "Regresar",
            locationErrorTittle: "La localización se encuentra deshabilitada",
            errorAcceptDocument: "Ha ocurrido un problema tratando de aceptar los documentos",
            checkLocation: "Comprobando localización"
        },
        nationality: {
            title: "¿En qué país naciste?",
            titleBusiness: "¿Donde opera tu negocio?",
            subtitle: " Ahora necesitamos algunos datos sobre tu <strong>nacionalidad y lugar de residencia.</strong>",
            subtitleBusiness: "Por favor especifica en que país opera tu negocio.",
            nationality: "Nacionalidad",
            naPlaceHolder: "Selecciona tu nacionalidad",
            errorCountries: "Hubo un problema tratando de cargar los países",
            errorStates: "No existen estados capturados para el país. Por favor contacta a soporte.",
            titleState: "Lugar de nacimiento (Estado)",
            statePH: "Selecciona un estado",
            homeTitle: "¿En qué país vives?",
            homePH: "Selecciona tu país de residencia",
            continue: "Continuar",
            back: "Regresar",
            saveInfo: "Hubo un problema tratando de guardar la nacionalidad",
            loadingStates: "Cargando Estados..."
        },
        selectDocuments: {
            title: "Identificación oficial",
            subtitle: " A continuación sera necesario que <strong>verifiques tus datos personales</strong> seleccionando el tipo de documento con el que deseas identificarte.",
            error: "Hubo un problema tratando de obtener los documentos"
        },
        uploadDocuments: {
            title: "Subir documentos",
            subtitle: "Reúne cada uno de los elementos solicitados para subir el archivo correspondiente.",
            error: "No se encontró ningún documento",
            errorUpload: "Hubo un problema tratando de procesar su documento, por favor intente de nuevo",
            errorFormat: "El formato no concuerda con los permitidos para el documento seleccionado",
            upload: "subir",
            modal: {
                title: "¿Cómo tomar mi video?",
                subtitle: "Para realizar la prueba de vida, tienes que grabar un video de tu cara, para realizarlo puedes usar cualquiera de las siguientes dos opciones que te presentamos aquí:",
                webcam: "Camara web",
                phone: "Mi teléfono",
                next: "Continuar"
            },
            modalVideo: {
                processingFaceVideo: "En este momento se esta realizando el análisis biométrico de tu rostro. Este proceso puede durar varios minutos."
            }
        },
        personalData: {
            title: "Datos personales",
            subtitle: "Para continuar con el registro de tu cuenta, necesitamos que ingreses algunos datos sobre ti, con ellos obtendremos tu CURP.",
            nameLabel: "Nombre(s)",
            namePH: "Escribe tu(s) nombre(s)",
            firstLastName: "Primer apellido",
            firstLastNamePH: "Escribe tu primer apellido",
            secondLastName: "Segundo apellido",
            secondLastNamePH: "Escribe tu segundo apellido",
            gender: "Genero",
            genderPH: "Escribe tu primer apellido",
            birthday: "Fecha de nacimiento",
            day: "Día",
            month: "Mes",
            year: "Año",
            continue: "Continuar",
            back: "Regresar",
            erroSaveUser: "Hubo un problema tratando de guardar la información personal"
        },
        occupation: {
            title: "¿A qué te dedicas?",
            subtitle: "Completa la información general sobre tu ocupación.",
            continue: "Continuar",
            back: "Regresar",
            errorLoad: "Habido un problema tratando de cargar las ocupaciones",
            errorSave: "Habido un problema tratando de actualizar la ocupación",
            selectName: "Ocupación/Profesión",
            selectNamePh: "Selecciona una opción",
            loadingOptions: "Cargando opciones...",
            textArea: "Platicanos a qué te dedicas",
            textAreaPh: "Escribe una breve descripción de tu ocupación"
        },
        residence: {
            title: "Lugar de residencia",
            subtitle: "Ingresa los siguientes datos de tu domicilio.",
            zipCode: "Código Postal",
            search: "Buscar",
            notZipCode: "¿No sabes cuál es tu código postal?",
            searchHere: "Puedes consultarlo",
            here: "aquí",
            back: "Regresar",
            next: "Continuar",
            state: "Estado",
            delegation: "Municipio",
            suburb: "Colonia",
            suburbPh: "Selecciona tu colonia",
            suburbPhNotFound: "Si no encuentras tu colonia, ingresala manualmente aquí",
            notFound1:"Si no has encontrado tu colonia en el listado, da",
            notFound2:"clic aquí para agregarla",
            found1: "¿Quieres volver a buscar tu colonia en el listado?",
            found2: "clic aquí",
            street :"Calle",
            streetPh :"Nombre de la calle de tu domicilio",
            externalNo :"No. Exterior",
            externalNoPh :"No. exterior",
            insideNo :"No. Interior",
            insideNoPh :"No. interior",
            loading: "Cargando información...",
        },
        phoneVerification: {
            title: "¿Cuál es tu número celular?",
            subtitle: "Para la seguridad de tu cuenta, ingresa tu número de celular. A este número <strong>enviaremos un mensaje de texto con el código</strong> de confirmación para vincular tu celular y tu cuenta.",
            continue: "Continuar",
            sendCode: "Enviar código",
            back: "Regresar",
            errorSave: "Ocurrió un problema en el envío del SMS",
            selectPhone: "Número de celular",
            selectPhonePh: "Escribe los 10 dígitos de tu celular",
            successTitle: "Celular validado correctamente",
            successSubtitle: "¡Excelente! Tu número de celular con terminación: <strong class=\"c-accent_2\">****{last4}</strong> ha sido validado exitosamente.",

        },
        phoneCode: {
            title: "Ingresa código de confirmación",
            subtitle: "Hemos enviado un mensaje SMS con tu código al número celular <strong class=\"c-accent_2\">{phone}.</strong>",
            warningCodeNormal: "El SMS puede tardar unos minutos en llegar, si no lo has recibido puedes presionar el siguiente link:",
            warningCodeError: "El código que has ingresado no es válido. Inténtalo de nuevo.",
            confirm: "Confirmar",
            back: "Regresar",
            checkingCode: "Validando <strong>número de celular</strong>",
            reSendCode: "REENVIAR CÓDIGO",
            reSendCodeIn: "REENVIAR CÓDIGO EN:",
            errorCode: "No se pudo reenviar el código, Intento de re-envío antes de tiempo "
        },
        securityMeasure: {
            title: "Medidas de seguridad",
            subtitle: "Selecciona una imagen e ingresa una frase personalizada por ti para que <strong>identifiques tu perfil cada vez que inicies sesión</strong> en Pagando.",
            securityLabel: "Frase de seguridad",
            securityLabelPh: "Escribe tu frase",
            continue: "Continuar",
            back: "Regresar",
            errorUpdate: "Existe un problema tratando de actualizar la información, por intente de nuevo"
        },
        newNip: {
            title: "Crea el NIP de tu cuenta",
            subtitle: "Con este NIP podrás <strong>realizar pagos y transferencias</strong> de manera más segura, e iniciar sesión en tu cuenta",
            warningText: "Para mayor seguridad te recomendamos que tu NIP no sea tu fecha de nacimiento y no lo compartas con nadie.",
            continue: "Continuar",
            back: "Regresar",
            errorSave: "Hubo un problema configurando su nuevo pin"
        },
        confirmNip: {
            title: "Confirma tu NIP",
            subtitle: "Ingresa nuevamente tu NIP de cuenta.",
            warningText: "Confirma tu NIP de nuevo",
            continue: "Continuar",
            back: "Regresar",
            errorSave: "El NIP que escribiste no coincide con el NIP que creaste en el paso anterior, intenta nuevamente."
        },
        processingRequest: {
            title: "Procesando tu solicitud",
            subtitle: "Toda tu información está siendo validada, <strong> espera unos minutos o vuelve a iniciar sesión más tarde</strong> para consultar el estatus del alta de tu cuenta.",
            currentStatus: "Estatus actual: ",
            inProcess: "Solicitud en proceso...",
            successTitle: "Solicitud aprobada",
            successSubtitle: "¡Todo está en orden! <br/> Puedes <strong>continuar con los últimos pasos</strong> de tu registro.",
            continue: "Continuar",
            resend: "Reenviar solicitud",
            errors: "Corregir detalles",
            error: "Error:"
        },
        contract: {
            title: "Contrato de alta de cuenta",
            subtitle: " A continuación puedes <strong>leer el contrato</strong> para dar de alta tu cuenta.",
            accept: "Aceptar Contrato",
            footer: "Una copia de este contrato será enviada a tu correo electrónico <strong>{email}</strong> al finalizar la creación de tu cuenta.",
            error: "habido un problema tratando de cargar el contrato"
        },
        signature: {
            title: "Firma autógrafa",
            subtitle: "De acuerdo a disposiciones oficiales es necesario que nos proporciones tu firma  autógrafa para continuar con tu registro",
            next: "Continuar",
            back: "Regresar",
            instruction: "Realiza tu firma en esta sección",
            deleteSign: "Borrar firma",
            notSign: "No se encontró ninguna firma",
            errorSave: "Hubo un problema tratando de guardar su firma, por favor intente de nuevo",
            errorContract: "Hubo un problema tratando de firmar su contrato, por favor intente de nuevo"
        },
        promoCode: {
            title: "Si tienes un código promocional, ingrésalo y gana fondo electrónico",
            titleWithReward: "Si tienes un código promocional, ingrésalo y gana ${n} de fondo electrónico",
            subtitle: "Si cuentas con un código promocional que te hayan compartido tus amigos, puedes aplicarlo aquí y ganarte fondo electrónico de bienvenida.",
            subtitleWithReward: "Si cuentas con un código promocional que te hayan compartido tus amigos, puedes aplicarlo aquí y ganarte ${n} de bienvenida.",
            omit: "Omitir",
            apply: "Aplicar",
            label: "Código promocional",
            labelPH: "Ingresa tu código promocional"

        },
        welcome: {
            title: "¡Bienvenido a Pagando!",
            subtitle: "Bienvenido(a) {name}, a partir de ahora cuentas con una tarjeta virtual, podrás enviar y recibir fondos electrónicos, fondear tu cuenta así como solicitar una tarjeta física.",
            next: "Ir a mi cuenta"
        },
        addInfo: {
            title: "Información adicional",
            subtitle: "Debido al tipo de beneficios ofrecidos en la cuenta que seleccionaste, es necesario que nos ayudes respondiendo las siguientes preguntas.",
            publicCharge: "¿Has desempeñado un cargo público?",
            relationPoliticallyExposed: "¿Tienes una relación con una persona políticamente expuesta?",
            relationPoliticallyExposedData: {
                labelName: "Nombre de la persona políticamente expuesta",
                labelNamePh: "Nombre de la persona expuesta",
                labelPosition: "Puesto que ocupo la persona",
                labelPositionPh: "Diputado distrito 16",
                labelPeriod: "Periodo donde desempeño el puesto",
                labelPeriodPh: "2013-2016",
            },
            politicallyExposedAssociate: "¿Estás asociado con una persona políticamente expuesta?",
            yes: "si",
            no: "no",
            rfc: "RFC",
            rfcPh: "Ingresa tu RFC",
            next: "Continuar",
            nextNoNormal: "Siguiente",
            back: "Regresar",
            errorSave: "Hubo un problema tratando de actualizar la información",
            loadingInfo: "Cargando información Adicional",
            error: {
                getDynamic: "No se pudo cargar la información adicional",
                dynamicOnbng: "No existe una configuración para este tipo de producto",
                requiresQuestion: "La pregunta {title} es requerida",
                formatResponseCustom: "La respuesta a este pregunta {title} no es correcta, ej. {hint}",
                formatResponseMax: "La respuesta a este pregunta {title} no puede ser de mayor a {max} caracteres",
                formatResponseEmpty: "No se obtuvo respuesta de la pregunta {title}",
                formatDocumentType: "Solo se aceptan los formatos {formats} para la pregunta {title}",
                formatDocumentConfig: "No se encuentra configuración de documento",
                getData: "Algo salio mal, tratando de cargar la información previa"
            },
            emptyState: {
                title: "No se encontró configuración para este producto"
            },
            warningBack: "Se encuentra actualmente en el primer paso",
            step: "paso {step}",
        },
        businessData: {
            title: "Información acerca de tu negocio",
            subtitle: "Ahora necesitamos que nos proporciones algunos datos sobre tu negocio.",
            constitutionDate: "Fecha de constitución",
            errorDate: "La fecha ingresada no es validad",
            enum : {
                BENEFICENCIA: "beneficencia",
                EDUCACION_BASICA: "educación básica",
                MEDICOS_Y_DENTISTAS: "medicos y dentistas",
                GUARDERIAS: "guarderías",
                REFACCIONES_Y_FERRETERIAS: "refacciones y ferreterías",
                SALONES_DE_BELLEZA: "salones de belleza",
                MISCELANEA: "miscelánea",
                GASOLINERAS: "gasolineras",
                COLEGIOS_Y_UNIVERSIDADES: "colegios y universidades",
                GOBIERNO: "gobierno",
                GRANDES_SUPERFICIES: "grandes superficies",
                COMIDA_RAPIDA: "comida rápida",
                FARMACIAS: "farmacias",
                PEAJE: "peaje",
                ESTACIONAMIENTOS: "estacionamientos",
                SUPERMERCADOS: "supermercados",
                TRANSPORTE_TERRESTRE_DE_PASAJEROS: "transporte terrestre de pasajeros",
                RENTA_DE_AUTOS: "renta de autos",
                AGENCIAS_DE_VIAJES: "agencias de viajes",
                HOTELES: "hoteles",
                ENTRETENIMIENTO: "entretenimiento",
                TRANSPORTE_AEREO: "transporte aéreo",
                TELECOMUNICACIONES: "telecomunicaciones",
                ASEGURADORAS: "aseguradoras",
                HOSPITALES: "hospitales",
                RESTAURANTES: "restaurantes",
                VENTAS_AL_DETALLE_RETAIL: "ventas al detalle",
                OTROS: "otros",
                AGREGADORES: "agregadores",
            }
        },
        documentsBus: {
            title: "Subir documentos",
            subtitle: "Para continuar con el registro, debemos verificar los datos de la existencia de tu negocio, por favor sube los siguientes documentos:",
            upload: "Subir",
            back: "Regresar",
            next: "Continuar",
            errors: {
                loadGroup: "No fue posible encontrar los documentos, por favor intente de nuevo más tarde",
                loadDocuments: "No fue posible cargar los documents, por favor intente de nuevo más tarde",
                uploadDocuments: "Hubo un problema tratando de procesar los documentos"
            }
        },
        livenessMobil: {
            next: "Continuar",
            exit: "Salir",
            cancel: "Cancelar",
            permits: {
                title: "Permite el acceso a la cámara",
                subtitle: "Para continuar, necesitamos acceder a la camara de tu teléfono para permitir grabar un video corto."
            },
            logout: {
                title: "Genera un código QR y leelo para comenzar con el proceso!"
            },
            instructions: {
                titleVideo: "¿Cómo tomar mi video?",
                titlePhoto: "¿Cómo tomar la foto?",
                videoFace1: "Mira directamente a la cámara ",
                videoFace2: "y haz clic en el botón de iniciar video.",
                photoDoc1: "Coloca la identificación en el centro de la pantalla ",
                photoDoc2: "y haz clic en el botón de Tomar foto.",
                videoCircule1: "Haz despacio 2 círculos con tu cabeza ",
                videoCircule2: "sin dejar de mirar a la cámara.",
                photoDocR1: "Recorta la imagen seleccionando solamente la identificación ",
                photoDocR2: "y haz click en el botón de Procesar.",
                videoTime1: "Termina antes de que pasen 10 segundos y haz clic en el botón de ",
                videoTime2: "finalizar video.",
                videoDocF1: "Si la identificación quedo correctamente seleccionada haz clic en el botón de ",
                videoDocF2: "Subir Foto",
                nextVideo: "Tomar mi video",
                nextPhoto: "Tomar Foto"
            },
            showLogout: {
                title: "¿Estás seguro de que quieres salir del proceso?",
                subtitle: "Si decides finalizar ahora, deberás reiniciar el proceso desde el principio."
            },
            takeVideo: {
                ins1: "Haz despacio 2 círculos con tu cabeza",
                ins2: "Es muy importante que mires directo a la camara y que tu rostro este descubierto y sin accesorios.",
                takeOther: "tomar otro",
                startVideo: "Iniciar grabación",
                recordVideo: "Grabando video",
                useVideo: "Usar video"
            },
            takePhoto: {
                title: "Toma la foto de la identificación",
                cropTitle: "Recorta la imagen",
                subtitle: "Es muy importante que recortes exactamente la identificación.",
                cropSubtitle: 'Puedes <strong class="c-info">recortar la foto</strong> dibujando un rectángulo de izquierda a derecha con el cursor',
                takeOther: "Tomar otra",
                processPhoto: "Procesar foto",
                uploadPhoto: "Subir Foto",
                camerasTip: 'Recuerda que puedes cambiar tus cámaras',
            },
            analize: {
                title: "¿Hiciste 2 circulos con tu cabeza?",
                subtitle: "Asegúrate de haber tomado un video claro y completo de tu rostro haciendo <strong>2 círculos con tu cabeza.</strong>",
                tryAgain: "No, intentar de nuevo",
                twoCircles: "Si, hice 2 circulos",
                recordVideo: "Analizando video grabado",
                loading: "Analizando video...",
                titlePhoto: "Analizando foto",
                subtitlePhoto: "En este momento se esta realizando el análisis de la foto."
            },
            error: {
                title: "Ocurrió un problema",
                subtitle: "Ocurrió un problema al intentar analizar y subir el archivo, por favor, vuelve a intentar."
            },
            verify: {
                title: "Verificación realizada",
                subtitle: "Listo, la verificación se realizo con éxito, ya puedes continuar con el registro de tu cuenta desde el navegador web."
            },
            modal: {
                title: "Selecciona que camara quieres activar.",
                cameras: "Cámaras disponibles"
            }
        },
        shareholders: {
            notification: {
                sideTitle: "Accionistas",
                sideSubtitle: "Físicos y Morales",
                title: "Identificación de accionistas",
                subtitle: "En este apartado es esencial que identifiques y añadas a cada uno de los accionistas que representen a tu negocio.",
                loadingTable: "Cargando información...",
                modal: {
                    titlePhysic: "Persona Física",
                    textPhysic: "Persona que tiene una actividad profesional o comercial, con la que obtiene una remuneración económica y, por tanto, tendrá que emitir facturas y hacer declaración de impuestos.",
                    titleMoral: "Persona Moral",
                    textMoral: "Conjunto de personas físicas que se unen para realizar alguna actividad en común, comercial, profesional, de servicios o productos, incluso sin fines de lucro.",
                    kind: "Selecciona el tipo de persona",
                    kindText: "A continuación elige el tipo de persona física/moral con el que se identifica el accionista que estas agregando.",
                    next: "Continuar"

                },
                footer: {
                    support: "También puedes contactarnos a los teléfonos:",
                    supportMex: "México: 800 461 0119 y EU: 800 392 6948"
                }
            },
            error: {
                addInfo: "La información ingresada se encuentra incompleta o no es validad",
                doc: "hubo un problema tratando de cargar el documento",
                save: "No se pudo guardar la información del accionista, por favor revise la información ingresada o el porcentaje asignado"
            },
            addShareholder: "Agregar accionista"
        },
        businessStart: {
            title: "Empecemos por tu negocio",
            subtitle: "Cuéntanos un poco sobre tu negocio y llena los siguientes campos.",
            company: "¿Cómo se llama tu negocio?",
            companyPh: "Nombre de tu negocio",
            whatDoesYourBusinessDo: "¿Que hace tu negocio? ",
            whatDoesYourBusinessDoPh: "Escribe una breve descripción de tu negocio",
            logo: "Logotipo de tu negocio",
            upload: "Subir",
            back: "Regresar",
            next: "Continuar"

        }
    },
    login: {
        modalSessions: {
            title: "Sesiones activas: {qty}",
            text: "Para una mejor experiencia de rendimiento y seguridad, es necesario cerrar las sesiones abiertas que no se estén usando."
        },
        leftSide: {
            title: "¡Tu tiempo es valioso!",
            subtitleStrong: "Dile bye",
            subtitle: " a los trámites molestos"
        },
        base: {
            title: "Iniciar Sesión",
            subtitle: "Bienvenido a Pagando, para administrar tus fondos debes iniciar sesión con tu cuenta o también puedes abrir una cuenta nueva.",
            emailLabel: "Correo electrónico",
            emailPh: "Ej: pablo@mail.com",
            forgotEmail: "¿Olvidaste tu correo?",
            recoverHere: "Recuperalo aquí",
            next: "Continuar",
            newPagando: "¿Eres nuevo en Pagando?",
            registerNow: "Registrate ahora",
            error: "El correo no coincide con alguna cuenta de pagando"
        },
        security: {
            back: "Regresar",
            subtitle: "Si este no es tu nombre o no reconoces la siguiente imagen y frase, haz clic en el botón de “Regresar” y comienza de nuevo.",
            hi: "hola",
            textDanger: "Has <strong>excedido el número de intentos</strong> para iniciar sesión. Puedes intentarlo de nuevo en 30 minutos.",
            passLabel: "Contraseña",
            passPh: "Ingresa tu contraseña",
            forgotPass: "¿Olvidaste tu contraseña?",
            recoverHere: "Recuperalo aquí",
            login: "Iniciar sesión",
            error: "No se ha podido identificar correctamente, por favor vuelva a intentar mas tarde"
        },
        recoverAccount: {
            back: "Regresar a iniciar sesión",
            title: "Recuperar cuenta de Pagando",
            subtitle: "Ingresa el número telefónico con el cual creaste tu cuenta. Te enviaremos un mensaje SMS con las instrucciones para recuperar tu cuenta.",
            title2: "¡La ayuda va en camino!",
            subtitle2: "Hemos enviado un mensaje SMS al número telefónico que ingresaste con las instrucciones para recuperar tu cuenta.",
            phone: "Número de celular",
            phonePh: "Escribe los 10 dígitos de tu celular",
            recoverAccount: "Recuperar cuenta",
            iRemember: "¿Ya recordaste tu correo?",
            login: "Inicia sesión",
            resend: "Reenviar SMS",
            footer: "El SMS puede tardar unos minutos en llegar, si no lo has recibido puedes presionar el botón <strong>“Reenviar SMS”</strong>",
            error: "Se produjo un problema buscando la cuenta"
        },
        recoverPass: {
            back: "Regresar a iniciar sesión",
            title: "¿Has olvidado tu contraseña?",
            subtitle: "¡No te preocupes! Restablecer tu contraseña es fácil, solo vamos a enviarte un email con instrucciones para restablecer tu contraseña.",
            title2: "Revisa tu correo electrónico",
            subtitle2: "Hemos enviado un correo electrónico a <strong>{email}</strong> con las instrucciones para que puedas recuperar tu contraseña.",
            title3: "Restablecer contraseña",
            subtitle3: "Por favor introduce y confirma tu nueva contraseña para poder ingresar a tu cuenta.",
            title4: "¡Listo! Contraseña restablecida",
            subtitle4: "Tu contraseña ha sido restablecida correctamente, puedes continuar administrando tus fondos en Pagando.",
            iRemember: "¿Ya recordaste tu contraseña?",
            login: "Inicia sesión",
            restore: "Restablecer",
            resend: "Reenviar correo",
            next: "Continuar",
            resendFooter: "El correo electrónico puede tardar unos minutos en llegar, si no lo has recibido puedes presionar el botón <strong>“Reenviar correo”</strong>",
            error: {
                recaptcha: "El reCaptcha ha expirado, favor de volverlo a ingresar",
                email: "Hubo un problema tratando de procesar el email, por intente mas tarde",
                pass: "Hubo un problema tratando de actualizar la contraseña"
            }
        },
        smsAuth: {
            title: "Hemos detectado que estás intentando iniciar sesión de otro dispositivo",
            subtitle: "Te enviamos tu código por mensaje de texto al número celular",
            warning: "Tu código de seguridad puede tardar unos minutos en llegar.",
            resend: "¿No recibiste ningún mensaje? Haz clic aquí para reenviar el código de seguridad.",
            verify: "Verificar"
        },
        pinUnification: {
            announcement: {
                title: '¡Actualización importante!',
                imageAlt: 'Aviso',
                paragraphs: [
                    'Hola {0} A partir de ahora puedes unir tu NIP de cuenta y el de tus tarjetas en uno mismo.',
                    'Esto te permitirá {0} para realizar operaciones y acceder a tu cuenta, así como para pagar con tu tarjeta física en un establecimiento.',
                    'Para llevar a cabo este cambio, {0} con tu tarjeta física usando el NIP de tu cuenta.',
                    'Ten en cuenta que una vez que empieces el proceso y hasta que lo finalices NO podrás realizar compras en establecimientos con tu tarjeta física. Así mismo, por medidas de seguridad ya no podrás consultar el NIP de la tarjeta física dentro de la aplicación.',
                    'Si tienes dudas sobre cómo realizar este proceso, contacta a nuestro {0}',
                ],
                useOnePin: 'usar un solo NIP',
                instructionsATM: 'primero debes de “Confirmar la unión de NIP”, después acudir a un cajero automático y realizar una consulta de saldo o un retiro de efectivo',
                supportTeam: 'Equipo de Soporte.',
                skipBtn: 'Lo haré después',
                confirmBtn: 'Confirmar la unión de NIP',
            },
            input: {
                title: 'Ingresa tu NIP de seguridad',
                subtitle: 'Esto nos ayuda a verificar tu identidad y confirmar la seguridad de esta actualización.',
                backBtn: 'Regresar',
                confirmBtn: 'Confirmar',
            },
            success: {
                title: '¡Unificación de NIP exitosa!',
                paragraphs: [
                    'Recuerda que para concluir el proceso {0} a realizar una consulta de saldo o un retiro en efectivo, así mismo si tienes múltiples cuentas, deberás acudir con cada una de tus tarjetas físicas a un cajero automático.',
                    'Una vez concluido el proceso podrás utilizar un solo NIP para acceder a tus cuentas, realizar operaciones y pagar con tu tarjeta física en tus establecimientos favoritos.',
                    'Por último, te recordamos que a partir de este momento ya no podrás consultar el NIP de la tarjeta física dentro de la aplicación.',
                ],
                goToATM: 'debes de acudir a un cajero automático',
                continueBtn: 'Continuar',
            },
            error: {
                title: '¡Ups! Lo sentimos mucho',
                paragraphs: [
                    'Ocurrió un error al intentar unificar el NIP de tu cuenta y el NIP de tus tarjetas.',
                    'Puedes volver a intentarlo de nuevo, si sigues teniendo problemas no dudes en contactar a nuestro {0}',
                ],
                supportTeam: 'Equipo de Soporte.',
                closeBtn: 'Cerrar',
                retryBtn: 'Volver a intentar',
            },
        },
        migrationCVV: {
            announcement: {
                title: '¡Nuevo método de seguridad!',
                imageAlt: 'Aviso',
                paragraphs: [
                    'Hola {0}, ahora puedes cambiar a nuestra tarjeta virtual con {1}, brindándote una mayor seguridad en tus transacciones en línea.',
                    'El CVV dinámico es un código de seguridad que {0} para proteger tus datos.',
                    'Para hacer el cambio solo necesitas seleccionar la opción {0} e Ingresar el NIP de tu cuenta para confirmar la operación.',
                    'Recuerda transferir pagos recurrentes a la nueva tarjeta virtual, ya que {0}',
                    'Si tienes más de una cuenta Pagando, se aplicará el cambio {0}',
                    'Si tienes dudas sobre cómo realizar este proceso, contacta a nuestro {0}',
                ],
                labels: [
                    'CVV dinámico',
                    'se actualiza periódicamente',
                    '“Cambiar a CVV dinámico”',
                    'la tarjeta virtual anterior será cancelada.',
                    'a todas tus cuentas.',
                    'Equipo de Soporte.',
                ],
                skipBtn: 'Lo haré después',
                confirmBtn: 'Cambiar a CVV dinámico',
            },
            input: {
                title: 'Ingresa tu NIP de seguridad',
                subtitle: 'Esto nos ayuda a verificar tu identidad y confirmar la seguridad de esta actualización.',
                backBtn: 'Regresar',
                confirmBtn: 'Confirmar',
            },
            success: {
                title: 'Cambio a CVV dinámico exitoso',
                paragraphs: [
                    'Se ha hecho el cambio de tus tarjetas virtuales para incluir CVV dinámico.',
                    'Recuerda que los números de tus tarjetas virtuales viejas ya no funcionarán y cualquier pago recurrente deberá enlazarse nuevamente a tu nueva tarjeta virtual.',
                    'Tus tarjetas Pagando físicas continuarán trabajando de manera normal.',
                ],
                continueBtn: 'Continuar',
            },
            error: {
                title: '¡Ups! Lo sentimos mucho',
                paragraphs: [
                    'Ocurrió un error al intentar crear tus nuevas tarjetas virtuales. Puedes intentarlo de nuevo, si sigues teniendo problemas no dudes en contactar a nuestro {0}',
                ],
                labels: [
                    'Equipo de Soporte.',
                ],
                closeBtn: 'Cerrar',
                retryBtn: 'Volver a intentar',
            },
            support: {
                title: 'Hubo un error con la migración de tus tarjetas virtuales',
                paragraphs: [
                    'Algo salió mal al migrar una o varias de tus tarjetas virtuales. ¡No te preocupes! lo resolveremos. Por favor ponte en contacto con nuestro {0} haciendo {1} o al número {2}',
                ],
                labels: [
                    'Equipo de Soporte Técnico',
                    'clic aquí',
                    '(800) 461 0119'
                ],
                closeBtn: 'Cerrar sesión',
            },
        },
    },
    myAccount: {
        title: "Mi Cuenta",
        noCuenta: "No. de cuenta",
        generalInfo: {
            titleTap: "Información general",
            title: "Información de la cuenta",
            subtitle: "Consulta la información general sobre tu cuenta Pagando.",
            noCuenta: "No. de cuenta",
            accountType: "Tipo de cuenta",
            accountTypeDes: "Fondos de Pago Electrónico",
            cutoffDate: "Fecha de corte",
            virtualCard: "Tarjeta virtual",
            physicCard: "Tarjeta física",
            billingDate: {
                entMonth: "Al final de cada mes",
                everyMonth: "Día {day} de cada mes"
            },
            modals: {
                loadInfo: "Buscando información ...",
                errorFind: "No se encontró el documento",
                download: "Descargar",
                lastUpdate: "Última actualización:",
                copyText: "Información copiada correctamente! ({type})",
                contrato: {
                    title_card: "Contrato",
                    subtitle_card: "Revisa las cláusulas de tu contrato",
                    title: "Contrato de la cuenta"
                },
                termsCond: {
                    title_card: "Términos y condiciones",
                    subtitle_card: "Leer Términos y condiciones de Pagando",
                    title: "Términos y Condiciones de uso de Pagando"
                },
                privacy: {
                    title_card: "Aviso de privacidad",
                    subtitle_card: "Acerca de la privacidad de tus datos",
                    title: "Aviso de Privacidad de tus datos"
                },
                commissions: {
                    title_card: "Comisiones",
                    subtitle_card: "Montos cobrados por operación",
                    title: "Comisiones",
                    subtitle: "Compartimos contigo las comisiones que se cobran según el tipo de operación que realices.",
                    events: {
                        card_request: "Solicitud de tarjeta física",
                        balance_inquiry: "Consulta de saldo",
                        spei_transfer: "Transferencia de spei (salida)",
                        spei_income: "Transferencia de spei (entrada)",
                        pagando_transfer: "Transferencias entre cuentas Pagando",
                        cash_commerce: "Retiro en tiendas",
                        cash_atm: "Retiro en ATM",
                        cash_inflow: "Depósito en tiendas",
                        resourcers_abroad: "Recepción de Remesas",
                        card_replacement: "Reposición de tarjeta",
                        cancel_account: "Cancelación de cuenta",
                        monthly_subscription: "Subscripción mensual",
                        account_opening: "Apertura de Cuenta",
                        dispersion_layout: "Uso de plantilla de dispersión",
                    },
                    freeEvents: {
                        UNIQUE: "{quantity} evento gratuito | {quantity} eventos gratuitos",
                        BY_EVENT: "{quantity} evento gratuito por evento | {quantity} eventos gratuitos por evento",
                        DAILY: "{quantity} evento gratuito diario | {quantity} eventos gratuitos diarios",
                        MONTHLY: "{quantity} evento gratuito mensual | {quantity} eventos gratuitos mensuales",
                        QUARTERLY: "{quantity} evento gratuito trimestral | {quantity} eventos gratuitos trimestrales",
                        BIANNUAL: "{quantity} evento gratuito semestral | {quantity} eventos gratuitos semestrales",
                        ANNUAL: "{quantity} evento gratuito anual | {quantity} eventos gratuitos anuales",
                    },
                    error: "No se encontraron las comisiones en estos momentos, por favor intente mas tarde"
                },
                addInfo: {
                    title_card: "Información adicional",
                    subtitle_card: "Importante a considerar como cliente",
                    title: "Información adicional",
                    subtitle: "Como usuario de Pagando es importante que estés al tanto de la siguiente información:",
                    responsibilities: "Responsabilidades",
                    risks: "Riesgos",
                    error: "No se encontraron los riesgos y responsabilidades en estos momentos, por favor intente mas tarde"
                },
                cancelAccount: {
                    title: "¿Estás seguro de cancelar tu cuenta {account}?",
                    subtitle: "Recuerda que esta acción es definitiva y ya no podrás disfrutar de los beneficios que te da Pagando:",
                    li1: "Un método de pago seguro para tus compras en línea",
                    li2: "Hacer transferencias y recibir depósitos sin comisiones excesivas",
                    li3: "Disponer de una tarjeta virtual para compras seguras",
                    li4: "Estadísticas en tiempo real de tus ingresos y gastos",
                    li5: "Tener una tarjeta física respaldada por Mastercard",
                    remember: "Recuerda",
                    messageDelete: "que solo cancelarás tu cuenta “{account}”. Si tienes más cuentas y deseas cancelarlas tendrás que ir directamente a las opciones de cada cuenta.",
                    pagandoContact: "Para realizar la cancelación de tu cuenta es <strong class=\"c-primary\">necesario que llames al 800 461 0119.</strong> Se cobra una <strong class=\"c-primary\">comisión de ${commission} por la cancelación</strong> de tu cuenta.",
                    keepAccount: "Conservar cuenta"
                }
            },
            cards: {
                limits: {
                    title: 'Configurar limites operacionales',
                    subtitle: 'Configura los limites de operación'
                }
            }
        },
        cashWithdrawal: {
            titleTap: "Retiro de efectivo",
            title: "Consulta y retiro de efectivo",
            subtitle: "Conoce los establecimientos donde puedes realizar disposiciones de efectivo y los cajeros en RED donde puedes consultar tu saldo o realizar retiros de efectivo.",
            byEvent: "por evento",
            label1: "El monto <strong>máximo de retiros</strong> en efectivo ",
            label2: "es de",
            label3_1: "en Cajeros en RED",
            label3_2: "en los establecimientos participantes.",
            DAILY: "por día",
            MONTHLY: "por mes",
            QUARTERLY: "por trimestre",
            BIANNUAL: "por semestre",
            ANNUAL: "por año",
            sideLink: {
                labelAtmRed: "Consulta de saldo en cajeros",
                labelAtmWithdrawal: "Retiro en cajeros en RED",
                labelAtmStores: "Retiro en establecimientos",
                atmRed: "Acude a cualquier <strong>“Cajero en RED”</strong>",
                atmWithdrawal: "Retiros de efectivo hasta por ",
                atmStores: "Retiros de efectivo hasta por "
            },
            modals: {
                loading: "Buscando información ...",
                warning1: "<strong class=\"text-upper\">Recuerda:</strong> La comisión cobrada por la consulta de saldo dependerá del establecimiento o la entidad financiera y la cantidad puede variar de uno a otro.",
                warning2: "<strong class=\"text-upper\">Recuerda:</strong> Pagando cobra una <strong>comisión de ${cost}</strong> por cada consulta de saldo. La comisión cobrada por el establecimiento o la entidad financiera puede variar de uno a otro.",
                warning3: "<strong class=\"text-upper\">Recuerda:</strong> La comisión cobrada por el retiro de efectivo dependerá del establecimiento o la entidad financiera y la cantidad puede variar de uno a otro.",
                warning4: "<strong class=\"text-upper\">Recuerda:</strong> Pagando cobra una <strong>comisión de ${cost}</strong> por cada retiro en cajero. La comisión cobrada por el establecimiento o la entidad financiera puede variar de uno a otro.",
                balance_inquiry: {
                    title: "Consulta de saldo: Cajeros en RED",
                    subtitle: "Estos son algunos de los cajeros en los que podrás realizar una <strong class=\"c-primary poppins-font-bold f-700\">consulta de saldo.</strong>",
                },
                withdrawalModal: {
                    title: "Retiro de efectivo: Cajeros en RED",
                    subtitle: "Estos son algunos de los cajeros en los que podrás realizar retiros de efectivo hasta por "
                },
                store: {
                    title: "Retiro de efectivo: Establecimientos",
                    subtitle1: "Podrás realizar retiros de hasta",
                    subtitle2: "en más de 3,500 tiendas a nivel nacional de los siguientes establecimientos:",
                    warning1: "<strong class=\"text-upper\">Recuerda:</strong>Si realizas una <strong>compra de al menos $15.00,</strong> el establecimiento <strong>no te cobrará</strong> comisión por hacer el retiro de efectivo. Sin embargo, <strong>si no realizas una compra</strong> y solo quieres retirar efectivo, el establecimiento te cobrará una <strong>comisión de $5.00</strong> por retiro.",
                    warning2: "<strong class=\"text-upper\">Recuerda:</strong> Pagando cobra una comisión de <strong>${commission}</strong> por cada retiro en establecimiento. Si realizas una <strong>compra de al menos $15.00,</strong> el establecimiento <strong>no te cobrara su comisión</strong> por hacer el retiro de efectivo. Sin embargo, <strong>si no realizas una compra</strong> y solo quieres retirar efectivo, el establecimiento te cobrará una comisión de <strong>$5.00.</strong>",
                    error: "Algo salio mal consultando los establecimientos"
                }
            }
        },
        bankStatements: {
            title: "Estados de cuenta",
            titleResponsive: "Edos. cuenta",
            tittleTap: "Estados de cuenta",
            noDocs: "Estados de cuenta",
            filterBy: "Filtrar por:",
            date: "Fecha",
            downloading: "Descargando Estado de cuenta",
            emptyState: {
                title: "Por el momento no tienes estados de cuenta",
                text: "Cuando se generen tus estados de cuenta mensuales podrás visualizarlos y descargarlos desde aquí.",
                imgAlt: "Por el momento no tienes estados de cuenta",
                loading: "Cargando",
            },
            pagination: "{total} Estado de cuenta | {total} Estados de cuenta",
            headers: {
                period: "Periodo",
                dueDate: "Fecha de corte",
                daysPeriod: "Días en el periodo",
                year: "Año",
                actions: "Acciones",
            },
            modalNip: {
                title: "Ingresa tu NIP de seguridad",
                subtitle: "Esto nos ayuda a verificar tu identidad y confirmar la seguridad de tu cuenta.",
                cancel: "Cancelar",
                next: "Continuar",
                validating: "Validando NIP...",
                error: "El NIP que has ingresado es incorrecto. Inténtalo de nuevo."
            },
            modalCode: {
                title: "Ingresa código de confirmación",
                subtitle: "Hemos enviado un mensaje SMS con tu código al número celular",
                cancel: "Cancelar",
                next: "Confirmar",
                validating: "Validando código...",
                error: "El código que has ingresado no es válido. Inténtalo de nuevo.",
                warningText: "El SMS puede tardar unos minutos en llegar, sino lo has recibido puedes presionar el siguiente link:",
                reSendIn: "REENVIAR CÓDIGO EN:",
                reSend: "REENVIAR CÓDIGO",
                errorSms: "No se pudo enviar el código sms"
            },
            modalEdoAccount: {
                title: "Estado de cuenta",
                period: "Periodo:",
                download: "Descargar",
                errorFind: "No se encontró el documento",
                loadDoc: "Cargando estado de cuenta"
            }
        },
        limits: {
            titleTap: "Límites operacionales",
            title: "Límites operacionales",
            subtitle: "Lleva un control de cómo utilizas tu cuenta según los límites de uso establecidos.",
            accumulated: "Acumulado:",
            transactions: "{count} transacciones",
            intents: "{count} intentos",
            DAILY_M: "diario",
            DAILY_F: "diaria",
            MONTHLY: "mensual",
            QUARTERLY: "trimestral",
            BIANNUAL: "semestral",
            ANNUAL: "anual",
            BY_EVENT: "por evento",
            amountTransactions: "Monto máximo por transacción",
            qtyTransactions: "Cantidad máxima {time} de transacciones",
            qtyCashWithdrawals: "Cantidad máxima {time} de retiros",
            amountDeposits: "Monto máximo {time} depositado",
            limitCashWithdrawals: "Monto máximo de retiro en efectivo {time}",
            accumulatedBalance: "Saldo máximo acumulado en la cuenta",
            amountCashWithdrawals: "Monto máximo de retiro en efectivo por compra {time}",
            amountDepositsTransfers: "Monto máximo {time} depositado por transferencias", // TODO date
            amountDepositsCash: "Monto máximo {time} depositado en efectivo",
            limitPin: "Cantidad máxima de intentos de PIN erróneo",
            warning: "Los acumulados son calculados en tiempo real y comprenden el mes o el día en curso.",
            enum: {
                AMOUNT_DEPOSITS: "Monto máximo {time} depositado",
                AMOUNT_DEPOSITS_TRANSFERS: "Monto máximo {time} depositado por transferencias",
                AMOUNT_DEPOSITS_CASH: "Monto máximo {time} depositado en efectivo",
                ACCUMULATED_BALACE: "Saldo máximo acumulado en la cuenta",
                AMOUNT_TRANSACTIONS: "Monto máximo por transacción",
                AMOUNT_CASH_WITHDRAWALS: "Monto máximo de retiro en efectivo por compra {time}",
                QTY_CASH_WITHDRAWALS: "Cantidad máxima {time} de retiros",
                QTY_TRANSACTIONS: "Cantidad máxima {time} de transacciones",
                LIMIT_CASH_WITHDRAWALS: "Monto máximo de retiro en efectivo {time}",
                LIMIT_PIN: "Cantidad máxima de intentos de PIN erróneo",
                SPEI_OUT: "Monto máximo {time} a depositar por transferencias SPEI",
                TRANSFER_OUT_PAGANDO: "Monto máximo {time} a depositar a cuentas Pagando",
            },
        },
        beneficiaries: {
            titleTap: "Beneficiarios",
            allocatedFunds: "Fondos asignados",
            infoNote: "Tus beneficiarios pueden ser personas físicas mayores de edad que tengan algún parentesco contigo. Por ejemplo: madre, padre, hermanos, abuelos, hijos o cónyuges.",
            title: "Beneficiarios",
            subtitle: "Tus beneficiarios son quienes recibirán el total o una parte de los fondos que se encuentren en tu cuenta en caso de que llegues a fallecer.",
            addBeneficiaries: "Agregar beneficiarios",
            editBeneficiaries: "Editar beneficiarios",
            addBeneficiary: "Agregar beneficiario",
            backAccount: "Regresar a Mi cuenta",
            titleAdd: "Agregar beneficiarios",
            titleEdit: "Editar beneficiarios",
            titleCard: "Mis beneficiarios",
            subtitleCard: "Agrega tus beneficiarios y asígnales un porcentaje de fondos hasta completar el 100%.",
            back: "Regresar",
            save: "Guardar mis beneficiarios",
            loadingInfo: "buscando beneficiarios ...",
            errors: {
                percentageExceeded: "El porcentaje excede el 100%",
                noMore: "Ya no se puede agregar más beneficiarios, ya se asigno el 100%",
                saving: "algo salio mal tratando de guardar sus beneficiarios, por favor verifique la información que proporciono"
            },
            relationship: {
                son: "Hijo",
                daughter: "Hija",
                father: "Padre",
                mother: "Madre",
                brother: "Hermano",
                sister: "Hermana",
                spouse: "Cónyuge",
                grandfather: "Abuelo",
                grandmother: "Abuela",
            },
            modalBack: {
                title: "¿Estás seguro de que quieres regresar antes de terminar el proceso?",
                subtitle: "Si regresas a la pantalla anterior antes de terminar la asignación de tus beneficiarios deberás agregar de nuevo todos sus datos la próxima vez que ingreses.",
                buttonConfirm: "Sí"
            },
            modalDelete: {
                title: "¿Estás seguro de que quieres eliminar este beneficiario?",
                subtitle: "Al eliminar a este beneficiario los fondos de tu cuenta no podrán ser entregados a esta persona en caso de que llegues a fallecer.",
                buttonConfirm: "Eliminar"
            },
            list: {
                percentage: "Porcentaje",
                selectAvatar: "Selecciona un avatar para tu beneficiario:",
                name: "Nombre completo",
                namePh: "Nombre completo del beneficiario",
                relationship: "Parentesco",
                relationshipPh: "Elige el parentesco",
                phone: "Teléfono",
                phonePh: "Teléfono (10 dígitos)",
                email: "Correo electrónico",
                emailPh: "Correo de contacto",
                saveBeneficiary: "Guardar beneficiario",
                deleteBeneficiary: "Eliminar beneficiario",
                editBeneficiary: "Editar beneficiario"
            }
        },
        mainAccount: {
            badge: {
                label: 'Cuenta principal',
                action: 'Convertir esta cuenta en principal',
            },
            modal: {
                title: '¿Deseas activar esta cuenta como principal?',
                text1: 'Esta será la cuenta que se mostrará cuando tus amigos te busquen por número de teléfono en Pagando y quieran enviarte una transferencia.',
                text2: 'IMPORTANTE: Si tus amigos guardaron tu contacto con número de teléfono y luego cambias otra de tus cuentas por default, tus amigos tendrán que actualizar tu contacto para enviarte transferencias, de lo contrario, los envíos te seguirán llegando a la cuenta que no tienes como default.',
                buttonCancel: 'Por ahora no',
                buttonText: 'Activar como cuenta principal',
            },
        },
    },
    sideBar: {
        myAccount: "Mi Cuenta",
        seeReverse: "Ver reverso",
        seeFront: "Ver vista frontal",
        virtualCard: "Tarjeta Virtual",
        physicalCard: "Tarjeta Física",
        copyNo: "Copiar No. de tarjeta",
        cardFront: 'Tarjeta {card} Frontal',
        cardBack: 'Tarjeta {card} Reverso',
        expDate: "EXP. DATE",
        changeAccount: "Cambiar de cuenta",
        asignAlias: "Asignar alias",
        myCards: "Mis tarjetas",
        noCopy: "¡Se ha copiado el número de la tarjeta exitosamente!",
        nip: {
            label: 'NIP DE TARJETA',
            action: 'Ver NIP de tarjeta',
        },
        cvc: {
            label: 'CVC',
            action: 'Ver CVC',
            available: 'DISPONIBLE'
        },
    },
    transfers: {
        general: {
            folio: 'Folio',
        },
        list: {
            title: 'Transferencias',
            subtitle: 'Transferencias recientes',
            buttonNew: 'Nueva transferencia',
            filterBy: 'Filtrar por:',
            date: 'Fecha',
            fields: {
                beneficiaryName: 'Beneficiario',
                beneficiaryAccount: 'Cuenta destino',
                timestamp: 'Fecha y hora',
                folio: 'Folio',
                status: 'Estatus',
                amount: 'Monto',
            },
            loading: 'Buscando...',
            empty: {
                title: 'Por el momento no tienes historial de transferencias',
                text: 'Aquí estará el historial con los detalles de las transferencias que hayas realizado.',
            },
            resend: 'Reenviar',
            authorize: 'Autorizar',
            pagination: "{total} transferencia | {total} transferencias",
            tooltip: {
                success: 'Enviada',
                pendingAuth: 'Esperando firma',
                pending: 'Procesando',
                rejected: 'Motivo rechazo',
                error: 'Problema en transacción',
            },
        },
        new: {
            changeBeneficiary: 'Cambiar Beneficiario',
            showMenu: 'Mostrar menú principal',
            hideMenu: 'Esconder menú principal',
            returnToList: 'Regresar a Transferencias',
            title: 'Nueva transferencia',
            of: 'de',
            beneficiary: {
                title: 'Beneficiario',
                subtitle: 'Elige a quién quieres realizar la transferencia.',
                add: {
                    button: 'Agregar contacto',
                    pagandoTitle: 'Usuario Pagando',
                    pagandoText: 'Agrega un contacto Pagando con su CLABE o número de celular.',
                    externalTitle: 'Otros bancos',
                    externalText: 'Agrega un contacto de otro banco con su CLABE o número de tarjeta.',
                },
                recentContacts: 'Contactos recientes',
                contactsTitle: 'Mis contactos',
                filter: {
                    both: 'Todos',
                    pagando: 'Amigos en Pagando',
                    others: 'Otros bancos',
                },
                searchPlaceholder: 'Buscar un contacto...',
                alias: 'Nombre / Alias',
                bank: 'Institución',
                account: 'Cuenta',
                select: 'Seleccionar',
                loading: 'Buscando...',
                emptyTitle: 'Sin contactos',
                emptyText: 'Agrega las cuentas de tus familiares o amigos y selecciona su contacto más fácil y rápido al momento de realizar próximas transferencias.',
                notFoundTitle: 'Sin resultados',
                notFoundText: 'El contacto que buscaste no se encuentra en tu lista. ¡Agrégalo ahora!',
                bankPagando: 'Pagando',
                editButton: 'Editar contacto',
                stepBack: 'Regresar',
                continue: 'Continuar',
                delete: {
                    title: '¿Estás seguro de que quieres eliminar este contacto?',
                    text: 'Si borras este contacto tendrás que volver a darlo de alta para poder hacerle una transferencia.',
                    action: 'Eliminar',
                },
            },
            amount: {
                title: 'Monto a transferir',
                subtitle: 'Ingresa un monto y un concepto para tu transferencia.',
                account: 'Cuenta origen',
                limitMessage: 'Puedes enviar entre {low} y {high}',
                remaining1: 'Después de esta transferencia quedarán:',
                remaining2: '{amount} en tu cuenta',
                commission: 'Esta transferencia genera {amount} de comisión',
                notEnoughFunds1: 'Solo tienes',
                notEnoughFunds2: 'disponible {amount}',
                notEnoughFunds3: 'en tu cuenta, ingresa una cantidad igual o menor a tu saldo',
                conceptLabel: 'Concepto de pago',
                conceptPlaceholder: 'Ingresa un concepto de máximo 40 caracteres (Sin caracteres especiales)',
                beneficiary: 'Beneficiario',
                stepBack: 'Regresar',
                continue: 'Continuar',
                predefinedConcepts: {
                    title: 'Puedes usar estos conceptos de pago predefinidos',
                    hello: 'Hola',
                    sentFromPagando: 'Enviado desde Pagando',
                    transferBeetween: 'Traspaso entre cuentas',
                    from: 'De {name}'
                },
                validationErrors: {
                    noSpecialCharacters: 'El concepto de pago no debe de contener caracteres especiales',
                    minLength: 'El concepto de pago debe de tener al menos 3 caracteres',
                    maxLength: 'El concepto de pago no debe de tener más de 40 caracteres',
                },
            },
            summary: {
                title: 'Resumen de la transferencia',
                subtitle: 'Verifica que toda la información sea correcta antes de realizar la transferencia.',
                loading: 'Transacción en proceso...',
                myAccount: 'Cuenta origen',
                beneficiary: 'Beneficiario',
                amount: 'Monto',
                commission1: 'Esta transferencia genera',
                commission2: '{amount} de comisión',
                noCommission: 'Esta transferencia no genera comisión',
                concept: 'Concepto',
                stepBack: 'Regresar',
                send: 'Enviar',
                locationUnavailable: 'La localización se encuentra deshabilitada',
            },
            errorDetails: {
                title: 'Transferencia fallida',
                text: '¡Lo sentimos mucho!<br>Tu transferencia no pudo realizarse exitosamente por el siguiente motivo:',
                contactSupport: 'Contactar a soporte',
                retry: 'Reintentar',
            },
            pendingDetails: {
                title: 'Transferencia en espera de firma',
                text: 'Hemos notificado a la persona encargada de autorizar esta transferencia. La transferencia será realizada una vez que esa persona introduzca su firma.',
                amount: 'Monto',
                commission1: 'Esta transferencia generó',
                commission2: '{amount} de comisión',
                noCommission: 'Esta transferencia no genera comisión',
                concept: 'Concepto',
                finish: 'Finalizar',
            },
            successDetails: {
                title: 'Transferencia exitosa',
                amount: 'Monto',
                commission1: 'Esta transferencia generó',
                commission2: '{amount} de comisión',
                noCommission: 'Esta transferencia no genera comisión',
                concept: 'Concepto',
                folio: 'Folio',
                trackingKey: 'Clave de rastreo',
                verifyStatusText: 'Verifica el estatus de tu operación en:',
                downloadReceipt: 'Descargar comprobante',
                receiptFilename: 'Comprobante_Transferencia_{folio}.pdf',
                finish: 'Finalizar',
            },
        },
        authorize: {
            title: '¿Qué deseas hacer con esta transferencia?',
            accept: 'Aceptarla',
            reject: 'Rechazarla',
            rejectReason: 'Motivo de rechazo',
            rejectReasonPlaceholder: '¿Por qué se rechazó?',
            close: 'Boton cerrar ventana',
            cancel: 'Cancelar',
            confirm: 'Confirmar'
        },
        beneficiaries: {
            new: {
                titlePagando: 'Nuevo contacto Pagando',
                titleExternal: 'Nuevo contacto',
                subtitlePagando: 'Introduce un número de teléfono o número de cuenta Pagando para ayudarte a buscar los datos del beneficiario.',
                subtitleExternal: 'Introduce los datos del beneficiario del otro banco para agregarlo a tus contactos.',
                loadingBanks: 'Cargando...',
                noBank: 'No se encontró el banco correspondiente a la CLABE. Verifica que los datos sean correctos.',
                selectBank: 'Selecciona el banco',
                close: 'Boton cerrar ventana',
                search: 'Buscar',
                userNotFound: 'Usuario no encontrado',
                userNotFoundSubtitle: 'El beneficiario que buscas no fue encontrado en nuestra base de datos. Verifica que los datos que ingresaste sean correctos.',
                userFound: 'Usuario encontrado',
                persist: 'Guardar en mi lista de contactos',
                continue: 'Continuar',
            },
            edit: {
                titlePagando: 'Editar contacto Pagando',
                titleExternal: 'Editar contacto',
                close: 'Boton cerrar ventana',
                subtitle: 'Introduce los nuevos datos del beneficiario para actualizar tu contacto.',
                delete: 'Eliminar contacto',
                save: 'Guardar cambios',
            },
            fields: {
                clabeLabel: 'CLABE interbancaria o número de tarjeta',
                clabePlaceholder: 'Ingresa la CLABE o el número de tarjeta',
                bankLabel: 'Banco de destino',
                bankPlaceholder: 'Ingresa la CLABE o el número de tarjeta para identificar el banco',
                phoneLabel: 'Teléfono, número de cuenta o cuenta CLABE Pagando',
                phonePlaceholder: 'Ingresa el número telefónico, número de cuenta o cuenta CLABE',
                accountLabel: 'Cuenta',
                accountPlaceholder: 'Ingresa el número de cuenta',
                nameLabel: 'Nombre del beneficiario de la cuenta',
                namePlaceholder: 'Ingresa el nombre del beneficiario de la cuenta',
                namePlaceholderCharacters: 'Ingresa el nombre del beneficiario sin caracteres especiales ni acentos',
                aliasLabel: 'Alias del contacto',
                aliasPlaceholder: 'Ingresa el alias del contacto',
                aliasPlaceholderNew: 'Ingresa un alias para identificar esta cuenta',
                noSpecialCharacters: "El nombre del contacto no debe de contener caracteres especiales"
            },
        },
        modalDetails: {
            close: 'Boton cerrar ventana',
            title: 'Detalle de transferencia',
            amount: 'Monto',
            concept: 'Concepto',
            beneficiaryName: 'Beneficiario',
            bank: 'Institución destino',
            beneficiaryAccoun: 'Cuenta de destino',
            folio: 'Folio',
            rejectReason: 'Motivo de rechazo',
            trackingKey: 'Clave de rastreo',
            operationStatus: 'Estatus de operación',
            badge: {
                success: 'Enviada',
                pendingAuth: 'Esperando firma',
                pending: 'Procesando',
                rejected: 'Rechazada',
                error: 'Error',
            },
            date: {
                SUCCESS: 'Fecha y hora de transferencia',
                PENDING_AUTH: 'Fecha y hora de solicitud',
                PENDING: 'Fecha y hora de solicitud',
                REJECTED: 'Fecha y hora de rechazo',
                ERROR: 'Fecha y hora de transferencia',
            },
            commission: {
                SUCCESS: 'Comisión cobrada',
                PENDING_AUTH: 'Comisión a cobrar',
                PENDING: 'Comisión a cobrar',
                REJECTED: 'Comisión cobrada',
                ERROR: 'Comisión a cobrar',
            },
        },
        twoFactor: {
            close: 'Boton cerrar ventana',
            nip: {
                title: 'Ingresa tu NIP de seguridad',
                subtitle: 'Esto nos ayuda a verificar tu identidad y confirmar la seguridad de tu cuenta.',
                wrongNip: 'El NIP que has ingresado es incorrecto. Inténtalo de nuevo.',
                cancel: 'Cancelar',
                continue: 'Continuar',
                loading: 'Validando NIP...',
            },
            sms: {
                title: 'Ingresa código de confirmación',
                subtitle: 'Hemos enviado un mensaje SMS con tu código al número celular',
                standBy: 'El SMS puede tardar unos minutos en llegar, sino lo has recibido puedes presionar el siguiente link:',
                wrongCode: 'El código que has ingresado no es válido. Inténtalo de nuevo.',
                loading: 'Validando código...',
                resend: {
                    countdownTitle: 'REENVIAR CÓDIGO EN:',
                    seconds: 'seg',
                    action: 'REENVIAR CÓDIGO',
                    loading: 'Reenviando código...',
                },
                cancel: 'Cancelar',
                confirm: 'Confirmar',
            },
        },
    },
    collaborators: {
        list: {
            title: 'Administrar acceso de colaboradores',
            subtitle: 'Acceso de Colaboradores y permisos',
            description: 'Agrega acceso de colaboradores y decide cuáles actividades pueden realizar por su cuenta y cuáles necesitarán autorización del titular o de otro colaborador.',
        },
        badge: {
            ACCOUNT_HOLDER: 'Titular',
            COLLABORATOR: 'Colaborador',
            PENDING_AUTHORIZATION: 'Esperando autorización',
            REJECTED: 'Rechazado',
            PENDING_INVITE: 'Invitación enviada',
            DRAFT: 'Borrador',
            PENDING_EDIT: 'Edición por autorizar',
            PENDING_REMOVAL: 'Eliminación por autorizar',
        },
        permissionTypes: {
            TRANSFERS: 'Transferencias',
            BENEFICIARIES: 'Contactos / beneficiarios',
            COLLABORATORS: 'Colaboradores',
            REQUEST_VIRTUAL_ADDITIONAL_CARD: "Solicitud de tarjetas virtuales adicionales",
            DISPERSIONS: 'Dispersiones',
            CORPORATIVE_ACCOUNTS: "Cuentas para colaboradores (corporativas)"
        },
        actions: {
            back: 'Regresar a Mi cuenta',
            return: 'Regresar',
            collapse: 'Colapsar colaborador',
            delete: 'Eliminar acceso de colaborador',
            edit: 'Editar permisos',
            cancel: 'Cancelar',
            saveChanges: 'Guardar cambios',
            sendInvite: 'Enviar invitación',
            cancelInvite: 'Cancelar invitación',
            resendInvite: 'Reenviar invitación',
            editRequest: 'Editar acceso de colaborador',
            updateRequest: 'Actualizar información',
            rejectCollaborator: 'Rechazar acceso de Colaborador',
            rejectRemoval: 'Rechazar eliminación',
            rejectEdit: 'Rechazar cambios',
            authorizeCollaborator: 'Autorizar acceso de Colaborador',
            authorizeRemoval: 'Autorizar eliminación',
            authorizeEdit: 'Autorizar cambios',
            deleteRequest: 'Eliminar acceso de colaborador',
            amendRequest: 'Corregir acceso de colaborador',
            resendRequest: 'Solicitar reconsideración',
            showMenu: 'Mostrar menú principal',
            hideMenu: 'Esconder menú principal',
            addDraft: 'Agregar acceso de Colaborador',
        },
        fields: {
            email: {
                label: 'Correo electrónico',
                placeholder: 'Correo que usa en el negocio',
            },
            name: {
                label: 'Nombre completo',
                placeholder: 'Nombre del colaborador',
            },
        },
        headers: {
            permissions: 'Permisos del titular',
            read: 'Ver detalle',
            request: 'Crear y editar',
            authorize: 'Autorizar y rechazar',
        },
        footers: {
            rejectedRemoval: 'Se ha rechazado la eliminación de este colaborador por el siguiente motivo:',
            rejectedEdit: 'Se han rechazado los cambios de permisos solicitados por el siguiente motivo:',
            note: `<strong>RECUERDA:</strong> Los accesos de colaboradores con permisos de <strong>“VER DETALLE”</strong> solo
                pueden ver datos sin modificar nada. Quienes tienen permisos de <strong>“CREAR Y EDITAR”</strong>
                únicamente podrán llenar o editar datos y deberán esperar por la autorización de otro colaborador o del
                titular. Los colaboradores con permisos de <strong>“AUTORIZAR Y RECHAZAR”</strong> podrán validar los
                datos que llenaron otros colaboradores para permitir o rechazar la acción, pero no estarán autorizados
                para llenar datos. Los colaboradores con <strong>TODOS LOS PERMISOS</strong> podrán llenar datos y
                concretar la acción sin necesidad de pedir autorización.`,
        },
        confirmModal: {
            actions: {
                yes: 'Sí',
                no: 'No',
                closeWindow: 'Boton cerrar ventana',
                cancel: 'Cancelar',
                reject: 'Rechazar',
                authorize: 'Autorizar',
            },
            cancelInvite: {
                title: '¿Estás seguro de que quieres cancelar la invitación de este colaborador?',
                text: 'Si cancelas la invitación, este colaborador será descartado y tendrás que configurar nuevamente sus permisos en caso de que quieras volver a incluirlo como colaborador de la cuenta.',
            },
            removeCollaborator: {
                title: '¿Estás seguro de que quieres eliminar este colaborador?',
                text: 'Si lo eliminas, este colaborador ya no podrá acceder a la cuenta ni podrá realizar ninguna acción. En caso de que quieras volver a incluirlo como colaborador de la cuenta tendrás que configurar sus permisos e invitarlo nuevamente.',
            },
            rejectCollaborator: {
                title: 'Rechazar colaborador',
                label: 'Motivo de rechazo',
                placeholder: '¿Por qué se rechazó?',
            },
            authorizeCollaborator: {
                title: 'Autorizar colaborador',
            },
        },
        sentInvite: {
            title: 'Invitación enviada',
            text: 'Tu colaborador podrá acceder a esta cuenta siguiendo las instrucciones que hemos enviado a su correo',
        },
        pendingAuthorization: {
            invite: {
                title: 'Invitación en espera de autorización',
                text: 'Hemos notificado a la persona encargada de autorizar colaboradores. Las invitación será enviada una vez que esa persona introduzca su firma.',
            },
        },
        rejected: {
            invite: {
                title: 'Colaborador rechazado',
                text: 'Se ha rechazado este colaborador por el siguiente motivo:',
            },
        },
    },
    movements: {
        pagination: "{total} Movimiento | {total} Movimientos",
        title: 'Mis Movimientos',
        subtitle: 'Consulta tu historial de compras, transferencias, depósitos y retiros de efectivo.',
        recentMovements: 'Movimientos recientes',
        downloadReport: 'Descargar reporte',
        filterBy: 'Filtrar por:',
        date: 'Fecha',
        searchMovement: 'Buscar movimiento...',
        searching: 'Buscando...',
        table: {
            description: 'Descripción',
            folio: 'Folio',
            date: 'Fecha y hora',
            card: 'Tarjeta',
            amount: 'Monto'
        },
        emptyState: {
            title: 'Por el momento no tienes historial de movimientos',
            subtitle: 'Aquí estará el historial con los detalles de los movimientos que hayas realizado.'
        },
        paidWithPoints: 'Pagar con puntos',
        errorGetMovements: 'Ocurrió un problema al obtener el historial de movimientos',
        details: {
            title: 'Detalle de movimientos',
            amount: 'Monto',
            balanceInquiry: 'Consulta de saldo',
            atmWithdrawal: 'Retiro en ATM',
            speiTransfer: 'Transferencia SPEI',
            transfer: 'Transferencia',
            deposit: 'Depósito',
            refund: 'Devolución',
            missingClarification: 'Aclaración perdida',
            unrecognizeMovement: '¿No reconoces este movimiento?',
            contactUs: 'Contáctanos',
            purchase: 'Compra',
            commission: 'Comisión',
            date: 'Fecha y hora de transferencia',
            type: 'Tipo de movimiento',
            concept: 'Concepto',
            folio: 'Folio',
            card: 'Tarjeta utilizada',
            reference: 'Referencia',
            beneficiary: 'Beneficiario',
            bank: 'Institución destino',
            trackingKey: 'Clave de rastreo',
            destinationAccount: 'Cuenta de destino',
            payer: 'Ordenante',
            pointsByPurchase: 'Puntos ganados con la compra',
            amountPaidWithPoints: 'Monto pagado con puntos',
            paidWithPoints: 'Paga con puntos'
        },
        types: {
            ABONO: 'Abono',
            CARGO: 'Cargo'
        },
    },
    footerPage: {
        supportInfo: {
            title: "Si tienes dudas, contáctanos",
            description: "Contáctanos al correo <strong>soporte@pagando.mx</strong> o al teléfono <strong>(800) 461 0119</strong> desde el interior de la república.",
        },
        principalContent: {
            slogan: "La Fintech del Norte",
            registeredMark: "© 2024 PAGANDO Una marca registrada de XC Networks S.A. de C.V. I.F.P.E. Todos los derechos reservados."
        },
        account: {
            title: "CUENTA",
            info1: "Información general",
            info2: "Depositar a cuenta",
            info3: "Realizar transferencia",
            info4: "Estados de cuenta",
            info5: "Pago de servicios",
            info6: "Promociones",
        },
        profile: {
            title: "PERFIL",
            info1: "Mi perfil",
            info2: "Ajustes",
            info3: "Contáctanos",
            info4: "Contrato de adhesión",
            info5: "Términos y condiciones",
            info6: "Aviso de Privacidad",
        },
        support: {
            title: "SOPORTE",
            info1: "Preguntas frecuentes",
            info2: "UNE",
            info3: "Consulta los Costos y las Comisiones de nuestros productos",
        },
        extraInfo: "XC Networks, S.A. de C.V. Institución de Fondos de Pago Electrónico (Pagando), es una entidad financiera autorizada, regulada y supervisada por las autoridades financieras en México para operar en términos de lo previsto por la Ley para Regular las Instituciones de Tecnología Financiera. <br><br> Ni el Gobierno Federal ni las entidades de la administración pública paraestatal podrán responsabilizarse o garantizar los recursos de los Clientes que sean utilizados en las Operaciones que celebren con las ITF o frente a otros, así como tampoco asumir alguna responsabilidad por las obligaciones contraídas por las ITF o por algún Cliente frente a otro, en virtud de las Operaciones que celebren.",
        condusef: {
            phone: "Tel. 55 53 400 999"
        }
    },
    deposits: {
        title: "Depositar a mi cuenta",
        transfer: "Transferencia",
        cash: "Efectivo",
        CLABE: "CLABE",
        originAccount: "Cuenta origen",
        byTransfers: {
            title: "Depósitos por transferencia",
            subtitle: "Realiza depósitos a tu cuenta por transferencias vía SPEI siguiendo las siguientes instrucciones:",
            step1: "Ingresa al portal de tu banco preferido y selecciona la opción: <strong class='poppins-font-bold'>Transferir a otros bancos</strong>",
            step2: "Elige como banco destino a: <strong class='poppins-font-bold'>{bankName}</strong>",
            step3: "Ingresa el nombre del titular de la cuenta Pagando: <strong class='poppins-font-bold'>{userFullName}</strong>",
            step4: "<strong class='poppins-font-bold'>Ingresa la CLABE interbancaria</strong> de {clabeLength} dígitos que te proporcionamos en la parte superior.",
            step5: "Captura un concepto, el monto de la transferencia y confirma.",
            step6: "¡Listo! El <strong class='poppins-font-bold'>depósito se verá reflejado en tu cuenta</strong> en un par de minutos.",
            noCommission: "<strong>RECUERDA: </strong> Pagando no te cobrará ninguna comisión por realizar un depósito SPEI a tu cuenta.",
            withCommission: "<strong>RECUERDA: </strong> Pagando cobra una <strong>comisión de {commission}</strong> por realizar un depósito SPEI a tu cuenta."
        },
        byCash: {
            title: "Depósitos en efectivo",
            subtitle: "Puedes realizar tu depósito en efectivo en cualquiera de los siguientes establecimientos, selecciona el de tu preferencia y sigue las instrucciones.",
            loading: "Cargando establecimientos...",
            returnToDeposit: "Regresar a Depositar a mi cuenta",
            referenceDeposit: "Referencia de depósito",
            referenceDepositSubtitle: "Te compartimos tu número de referencia y los pasos a seguir para realizar el depósito.",
            noReferenceDeposit: "No. de referencia de depósito",
            sendEmail: "Enviar por correo",
            withCommission: "<strong>RECUERDA: </strong> Pagando no te cobrará ninguna comisión por realizar un depósito de efectivo a tu cuenta.",
            howToDo: "¿Cómo depositar en efectivo?",
            step1: "Acude a la <strong class='poppins-font-bold'>sucursal {name}</strong> más cercana o de tu preferencia.",
            step2: "Dile al cajero que quieres hacer un <strong class='poppins-font-bold'>depósito en Pagando.</strong>",
            step3: "El cajero deberá <strong class='poppins-font-bold'>escanear el código</strong> de barras que acabamos de generarte, si no funciona el código, díctale el <strong class='poppins-font-bold'>número de referencia de {noReferenceLength} dígitos.</strong>",
            step4: "Realiza el pago correspondiente más la comisión que cobrará el establecimiento.",
            step5: "Una vez realizado el pago, <strong class='poppins-font-bold'>guarda el comprobante impreso</strong> y tómale una foto para cualquier aclaración.",
            step6: "<strong class='poppins-font-bold'>¿Cuándo llega el dinero a mi cuenta Pagando?</strong> En el momento que se nos notifique el pago, te depositaremos el dinero a tu cuenta. Esta notificación puede tardar un par de horas en llegar a nosotros. Una vez realizado el depósito en tu cuenta te mandaremos una notificación.",
            modal: {
                title: "¿Qué deseas hacer con esta transferencia?",
                toMyMail: "A mi correo",
                toOtherMail: "A otro correo",
                sendReferenceToMail: "Enviaremos la referencia a tu correo:",
                email: "Correo electrónico",
                successMessage: "Hemos enviado tu referencia al siguiente correo: <br><strong class='c-info poppins-font-bold'>{email}</strong>",
                successSend: "Correo enviado",
                cancel: "Cancelar",
                finalize: "Finalizar",
                send: "Enviar"
            }
        },
        errors: {
            getCommissions: "Ocurrió un problema al obtener comisión",
            getEstablishments: "Ocurrió un problema al obtener establecimientos",
            generateBarcode: "Ocurrió un problema al generar código de barras",
            getEstablishment: "Ocurrió un problema al obtener establecimiento",
            sendMail: "Ocurrió un problema al enviar correo"
        }
    },
    rewardPoints: {
        quantityPoints: "{points} punto | {points} puntos",
        equivalentPoints: "1 punto = {amount}",
        generatedPoints: "{points} pt. | {points} pts.",
        generatedPointsNegative: "-{points} pt. | -{points} pts.",
        returnToMovements: "Regresar a Movimientos",
        title: "Pago con puntos",
        availablePoints: "Puntos disponibles",
        equivalence: "Equivalencia",
        breakdown: "Desglose",
        consultDetails: "Consulta el detalle de tu pago con puntos:",
        purchaseWithPoints: "Compra a pagar con punto",
        establishment: "Establecimiento",
        totalPurchase: "Total de la compra",
        pointsGenerated: "Puntos generados",
        amountToPaidWithPoints: "Monto a pagar con puntos",
        amountToPaidWithCard: "Monto a pagar con tarjeta",
        detailPoints: "Detalle de los Puntos",
        initialPoints: "Puntos iniciales",
        pointsToDiscount: "Puntos a descontar",
        pointsToExchange: "Puntos a canjear",
        finalPoints: "Puntos finales",
        exchangePoints: "Canjear puntos",
        regards: {
            title: "Consideraciones",
            subtitle: "Al momento de canjear tus puntos debes tener en cuenta lo siguiente:",
            part1: "Una vez realizada la transacción, devolveremos a tu saldo el monto pagado con puntos.",
            part2: "Descontaremos de tus puntos disponibles tus puntos generados con esta compra.",
            part3: "$5.00 es el monto mínimo de saldo a canjear con puntos.",
            part4: "Si tus puntos no cubren el total del monto a pagar o existe una diferencia en centavos por la conversión de puntos, se usará el saldo que ya habías utilizado de tu tarjeta para cubrir la diferencia..",
            part5: "Tus puntos tienen vigencia de 1 año a partir del día en que se genera tu compra."
        },
        success: {
            title: "¡Pago con puntos exitoso!",
            amountPaidWithPoints: "Monto pagado con puntos",
            amountPaidWithCard: "Monto pagado con tarjeta",
            pointsRedeemed: "Puntos canjeados",
            account: "Cuenta {account}",
            paidPurchase: "Compra pagada",
            folio: "Folio",
            downloadTicket: "Descargar comprobante",
            finalize: "Finalizar"
        },
        error: {
            title: "Pago fallido",
            subtitle: "¡Lo sentimos mucho! Tu pago con puntos no pudo realizarse exitosamente",
            contactToSupport: "Contactar a soporte",
            retry: "Reintentar",
            getDetail: "Ocurrió un problema al obtener el detalle de puntos",
            getMovement: "Ocurrió un problema al obtener información de el movimiento"
        }
    },
    myPoints: {
        title: "Mis puntos Pagando",
        title2: "Mis puntos",
        calculator: {
            title: "Calculadora",
            subtitle: "Calcula cuánto equivalen tus puntos en saldo:",
            points: "Puntos",
            balance: "Saldo"
        },
        section3: {
            title: "¡Usa tus puntos a tu manera!",
            subtitle: "Te compartimos algunas formas para canjearlos:",
            servicesPay: "Pago de servicios",
            servicesPayDescription: "Pagos de agua, luz, cable, etc. desde nuestra app o plataforma web",
            selectedEstablishments: "Establecimientos seleccionados",
            selectedEstablishmentsDescription: "Al pagar en tienda o en línea",
            pastPurchases: "Compras pasadas",
            pastPurchasesDescription: "Te devolvemos el saldo equivalente a tus puntos"
        },
        section4: {
            title: "¿Cómo funciona el programa de puntos?",
            part1: "Realiza <strong class='poppins-font-bold f-700'>compras con tu tarjeta</strong> física o virtual.",
            part2: "Por <strong class='poppins-font-bold f-700'>cada compra</strong> generas <strong class='poppins-font-bold f-700'>{points} Pagando*</strong>",
            part3: "<strong class='poppins-font-bold f-700'>Acumula puntos y canjéalos</strong> en tus compras",
            part4: "Te descontamos el <strong class='poppins-font-bold f-700'>equivalente en puntos</strong> del monto total a pagar",
            part5: "*Aplica para un máximo de <strong class='poppins-font-bold f-700'>100 compras por mes.</strong>"
        },
        section5: {
            title: "¿Cómo canjear mis puntos en mis compras pasadas?",
            part1: "Desde el historial de movimientos entra al <strong class='poppins-font-bold f-700'>detalle de la compra</strong> a pagar con puntos.",
            part2: "Haz clic en <strong class='poppins-font-bold f-700'>“Pagar con puntos”</strong> y consulta el detalle de puntos disponibles y puntos a usar.",
            part3: "Confirma el monto a pagar con tus puntos, haz clic en <strong class='poppins-font-bold f-700'>“Canjear puntos”</strong> e ingresa tu NIP.",
            part4: "¡Listo! Obtendrás tu comprobante de pago y te <strong class='poppins-font-bold f-700'>devolveremos el saldo</strong> equivalente a tus puntos."
        }
    },
    contacts: {
        dashboardAdd: "Agregar contactos",
        dashboardAll: "Ver todos mis contactos",
        dashboardEmptyStateTitle: "Sin contactos recientes",
        dashboardEmptyStateDes: "Aquí encontrarás tus contactos recientes para que puedas realizarles transferencias rápidas.",
        backDash: "Regresar al inicio",
        title: "Administrar contactos",
        titleStrong: "Contactos",
        pagination: "Contacto | Contactos",
        subtitle: "Agrega las cuentas de tus familiares o amigos para poder realizar transferencias de manera más fácil y rápida.",
        addContact: "Agregar contacto",
        pagandoUser: "Usuario Pagando",
        pagandoUserDes: "Agrega un contacto Pagando con su CLABE o número de celular. ",
        otherBank: "Otros bancos",
        otherBankDes: "Agrega un contacto de otro banco con su CLABE o número de tarjeta.",
        MyContacts: {
            title: "Mis Contactos",
            all: "Todos",
            friends: "Amigos en Pagando",
            banks: "Otros bancos",
        },
        search: "Buscar un contacto...",
        table: {
            headers: {
                name: " Nombre / Alias",
                bank: " Institución",
                account: " Cuenta",
                status: "Estatus",
                validate: "Validar",
                actions: " Seleccionar",
            },
            emptyState: {
                title: "Sin contactos",
                subtitle: "Agrega las cuentas de tus familiares o amigos y selecciona tu contacto más fácil y rápido al momento de realizar próximas transferencias."
            },
            loading: "Buscando...",
            pagando: "Pagando",
            externalBank: "Banco externo",
            waitingSign: "Esperando Firma",
            waitingSignDelete: "Esperando Eliminación",
            authorized: "Autorizado",
            rejected: "Motivo de rechazo",
        },
        error: {
            loading: "Habido un problema consultando los contactos",
            delete: "No fue posible eliminar el contacto, por favor intente de nuevo mas tarde"
        },
        deleteSuccess: "El contacto ha sido eliminado correctamente",
        pagandoCLABE: "La CLABE ingresada pertenece a una cuenta Pagando",
        modals: {
            delete: {
                title: "¿Estás seguro de que quieres eliminar este contacto?",
                description: "Si borras este contacto tendrás que volver a darlo de alta para poder hacerle una transferencia.",
                delete: "Eliminar"
            },
            newPagando: {
                title: "Nuevo contacto Pagando",
                subtitle: "Introduce un número de teléfono o número de cuenta Pagando para ayudarte a buscar los datos del beneficiario.",
                phoneAccount: "Teléfono o cuenta Pagando",
                phoneAccountPh: "Ingresa el número telefónico o número de cuenta",
                find: "Buscar",
                error: {
                    title: "Usuario no encontrado",
                    subtitle: "El beneficiario que buscas no fue encontrado en nuestra base de datos. Verifica que los datos que ingresaste sean correctos.",
                    message: "Hubo un problema tratando de guardar el contacto"
                },
                success: {
                    title: "Usuario encontrado",
                    account: "Cuenta",
                    name: "Nombre del beneficiario de la cuenta",
                    alias: "Alias del contacto",
                    aliasPh: "Ingresa un alias para identificar esta cuenta",
                },
                save: "Guardar",
            },
            newOther: {
                title: "Nuevo contacto",
                subtitle: "Introduce los datos del beneficiario del otro banco para agregarlo a tus contactos.",
                error: {
                    message: "Hubo un problema tratando de guardar el contacto"
                },
                success: {
                    clabe: "CLABE interbancaria o número de tarjeta",
                    clabePh: "Ingresa la CLABE o el número de tarjeta",
                    bank: "Banco de destino",
                    name: "Nombre del beneficiario de la cuenta",
                    namePh: "Ingresa el nombre del beneficiario sin caracteres especiales ni acentos",
                    alias: "Alias del contacto",
                    aliasPh: "Ingresa un alias para identificar esta cuenta",
                },
                warning: "Antes de guardar, asegurate de que los datos ingresados sean los correctos.",
                save: "Guardar",
            },
            editPagando: {
                title: "Editar contacto Pagando",
                subtitle: "Introduce los nuevos datos del beneficiario para actualizar tu contacto.",
                phone: "Teléfono o cuenta Pagando",
                account: "Cuenta",
                name: "Nombre del beneficiario de la cuenta",
                alias: "Alias del contacto",
                save: "Guardar",
                switchSpeiOut: 'Limitar transferencias',
                speiOut: "Monto Máximo por transferencia",
            },
            editOther: {
                title: "Editar contacto",
                subtitle: "Introduce los nuevos datos del beneficiario para actualizar tu contacto.",
                error: {
                    message: "Hubo un problema tratando de guardar el contacto"
                },
                success: {
                    clabe: "CLABE interbancaria o número de tarjeta",
                    bank: "Banco de destino",
                    name: "Nombre del beneficiario de la cuenta",
                    alias: "Alias del contacto",
                },
                warning: "Antes de guardar, asegurate de que los datos ingresados sean los correctos.",
                save: "Guardar",
            },
            acceptContact: {
                title: "¿Deseas aceptar o rechazar este contacto?",
                acceptIt: "Aceptarlo",
                rejectIt: "Rechazarlo",
                rejectedReason: "Motivo de rechazo",
                rejectedReasonPh: "¿Por qué se rechazó?",
                name: "Nombre / Alias",
                account: "Cuenta",
                bank: "Institución",
                cancel: "Cancelar",
                confirm: "Confirmar",
                error: "Hubo un problema tratando actualizar la solicitud del contacto"
            }
        }
    },
    services: {
        title: "Servicios",
        backToHome: "Regresar al inicio",
        title2: "Pago de servicios",
        loading: "Cargando...",
        search: "Buscar un servicio...",
        empty: {
            title: "Sin resultados",
            text: "El servicio que buscaste no se encuentra disponible."
        },
        modalParams: {
            title: "Llenar datos",
            text1: "Antes de proceder a tu pago, te pedimos ingreses la siguiente información:",
            text2: "Antes de proceder a tu pago, has clic en el boton de Continuar al pago.",
            button: "Continuar al pago"
        },
        errors: {
            getSections: "Ocurrió un problema al obtener secciones",
            getDetails: "Ocurrió un problema al obtener detalle de sección",
            continueWithPay: "Ocurrió un problema al continuar con el pago",
            fillData: "Llene correctamente los datos solicitados"
        },
        summary: {
            return: "Regresar al Pago de servicios",
            title: "Resumen del pago",
            paymentMethod: "Método de pago",
            available: "Disponible:",
            insufficientFunds: "Fondos insuficientes",
            depositAccount: "Realiza un depósito a tu cuenta",
            infoPayment: "Información del pago",
            warning: "El pago del servicio será realizado por <strong>Black Labs S.A. de C.V.</strong>, XC Networks solo será responsable de transferir los fondos de pago electrónico y no de la entrega del bien o servicio.",
            subtotal: "Subtotal:",
            commission: "Comisión:",
            codeApplied: "Código promocional aplicado:",
            delete: "ELIMINAR",
            total: "Total",
            promotionalCode: "Código promocional",
            haveCode: "Tengo un código promocional",
            doPayment: "Realizar Pago",
            messageInfo: "La información de tu pago estará encriptada y segura",
            back: "Regresar",
            exchangeCode: "Canjea tu <strong class='c-primary'>código promocional</strong>",
            exchangeCodeMessage: "Si cuentas con un código promocional, puedes ingresarlo aquí y obtener promociones o descuentos.",
            validateCodeSuccess: "El código promocional se validó correctamente",
            validateCodeError: "El código promocional que ingresaste no es válido",
            enterCode: "Ingresa tu código",
            exchangeCodeButton: "Canjear código",
            paySuccess: {
                title: "Pago exitoso",
                text: "Hemos enviado tu recibo de pago al correo:",
                download: "Descargar comprobante",
                finalize: "Finalizar"
            },
            payError: {
                title: "Pago fallido",
                text: "¡Lo sentimos mucho! Tu pago no pudo realizarse exitosamente por el siguiente motivo:",
                support: "Contactar a soporte",
                retry: "Reintentar"
            },
            payNoDebt: {
                title: "Servicio sin adeudo",
                text: "El servicio que estás consultando no tiene un adeudo en este momento. Si esto no es correcto, intenta consultar el servicio más tarde.",
                support: "Contactar a soporte",
                goBack: "Volver al listado de servicios"
            },
            errors: {
                getBalance: "Ocurrió un problema al obtener balance de tarjeta",
                getDetails: "Ocurrió un problema al obtener detalles del pago"
            },
            deleteCode: {
                title: "¿Estás seguro de que quieres eliminar este cupón?",
                text: "Si lo eliminas se descartará el descuento o promoción aplicados.",
                button: "Si"
            }
        }
    },
    bulkContacts: {
        new: {
            templateDispersion: 'Plantilla de Contactos',
            templateDispersionSub: 'Archivo de máximo 25MB en formato .xlsx',
            bCard: {
                upload: 'Subir',
                uploading: 'Subiendo',
                retry: 'Reintenta',
            },
            error: {
                uploadFile: "Hubo un problema tratando de cargar el documento",
                fileFormat: "El archivo no cumple el formato requerido",
                unknownError: "Hubo un problema tratando de procesar el xlsx"
            },
        },
    },
    dispersions: {
        list: {
            titleStrong: "Dispersiones",
            subtitleStrong: "Realiza transferencias múltiples, fácil y rápido con solo cargar una plantilla. Ideal para realizar pagos a un gran número de beneficiarios.",
            new: "Nueva dispersión",
            validate: "Validadas",
            filterBy: "Filtrar por:",
            paginate: "dispersión | dispersiones",
            table: {
                headers: {
                    folio: "Folio",
                    description: "Descripción",
                    transfers: "Transferencias",
                    date: "Fecha y hora",
                    status: "Estatus",
                    amount: "Monto",
                },
                emptyState: {
                    title: "Por el momento no tienes historial de dispersiones",
                    subtitle: "Aquí estará el historial con los detalles de las dispersiones que hayas realizado."
                },
                tooltip: {
                    CAPTURED: "Capturada",
                    VALIDATING: "Validando",
                    VALIDATED_WITH_EXCEPTIONS: "Validada con excepciones",
                    VALIDATED: "Validada",
                    WAITING_AUTHORIZATION: "Esperando autorización",
                    REJECTED: "Rechazada",
                    SCHEDULED: "Programada",
                    SCATTERED: "Dispersando",
                    TERMINATED_WITH_EXCEPTIONS: "Finalizada con excepciones",
                    COMPLETED: "Finalizada",
                    CANCELLED: "Cancelada"
                }
            },
            error: {
                list: "Hubo un problema consultando las dispersiones"
            }
        },
        new: {
            backList: "Regresar a Dispersiones",
            titleStrong: "Nueva dispersión",
            back: "Regresar",
            downloadTemplate: "Descargar plantilla",
            processTemplate: "Procesar plantilla",
            title: "Configuración general",
            subtitle: "Ingresa la descripción de la dispersión a realizar y carga la plantilla de movimientos.",
            description: "Descripción",
            descriptionPh: "Descripción de la dispersión, max. 40 caracteres",
            warning: {
                important: "IMPORTANTE:",
                part1: "Para que la dispersión pueda ser procesada verifica que el archivo de Excel esté lleno correctamente.",
                part2: "Recuerda que",
                part3: "esta cuenta Pagando Business solicita una doble validación de beneficiarios",
                part4: "para evitar transferencias fraudulentas",
                part5: "Si un beneficiario incluido en tu plantilla no se encuentra en tu",
                part6: "lista de contactos",
                part7: "porque no ha sido validado por la persona encargada de autorizar nuevos beneficiarios, no podrá realizarse su transferencia."
            },
            templateDispersion: "Plantilla de dispersión",
            templateDispersionSub: "Archivo de máximo 25MB en formato .xlsx",
            bCard: {
                upload: "Subir",
                uploading: "Subiendo",
                retry: "Reintentar"

            },
            error: {
                uploadFile: "Hubo un problema tratando de cargar el documento",
                fileFormat: "El archivo no cumple el formato requerido",
                unknownError: "Hubo un problema tratando de procesar el xlsx"
            },
            titleError: "Dispersión fallida",
            subtitleError: "¡Lo sentimos mucho! Tu dispersión no pudo procesarse por el siguiente motivo:",
            processAgain: "Procesar otra plantilla",
        },
        details: {
            loading: "Cargando ...",
            routerLink: "Regresar a Dispersiones",
            title: "Detalle de dispersión",
            accOrigin: "Cuenta origen",
            back: "Regresar",
            cancel: "Cancelar dispersión",
            reject: "Rechazar dispersión",
            requestAuth: "Solicitar autorización",
            goDispersion: "Confirmar",
            authDispersion: "Autorizar dispersión",
            resolveConflicts: "Resolver Conflictos",
            smallTitle: "Dispersiones recientes",
            atHour: "a las",
            folio: "Folio",
            infoDis: {
                openingBalance: "Saldo inicial",
                amountScatter: "Monto a dispersar",
                scatteredAmount: "Monto dispersado",
                balanceAfter: "Saldo tras dispersión",
                totalMovements: "Total movimientos",
                validated: "Validados",
                conflictive: "Conflictivos",
                scattered: "Dispersados"
            },
            enum: {
                CAPTURED: "Capturada",
                VALIDATING: "Validando",
                VALIDATED_WITH_EXCEPTIONS: "Validada con excepciones",
                VALIDATED: "Validada",
                WAITING_AUTHORIZATION: "Esperando autorización",
                REJECTED: "Rechazada",
                SCHEDULED: "Programada",
                SCATTERED: "Dispersando",
                TERMINATED_WITH_EXCEPTIONS: "Finalizada con excepciones",
                COMPLETED: "Finalizada",
                CANCELLED: "Cancelada"
            },
            date: {
                CAPTURED: "fecha de captura:",
                VALIDATING: "fecha de captura:",
                VALIDATED_WITH_EXCEPTIONS: "Fecha de validación:",
                VALIDATED: "Fecha de validación:",
                REJECTED: "Fecha de rechazo:",
                WAITING_AUTHORIZATION: "Fecha de validación:",
                SCATTERED: "Fecha de autorización:",
                SCHEDULED: "Programada para:",
                TERMINATED_WITH_EXCEPTIONS: "Fecha de inicio de dispersion:",
                COMPLETED: "Fecha de inicio de dispersion:",
                CANCELLED: "Fecha de cancelación"
            },
            bAlerts: {
                info: "<strong>RECUERDA:</strong> Se cobra un <strong>{commission}% + IVA de comisión sobre el monto total de dispersión.</strong> Se requerirá la firma de la persona designada para autorizar dispersiones para poder realizar las transferencias de esta dispersión.",
                infoScattered: "La dispersión se encuentra en proceso.",
                infoScheduled: "La dispersión ha sido autorizada y será realizada el 31 Ene. 2022 a las 10:00 a.m. ",
                success: "Todos tus movimientos han sido validados exitosamente. Ahora puedes solicitar la firma de la persona designada para autorizar dispersiones para poder realizar las transferencias.",
                completedError: "La dispersión se ha completado exitosamente. Recuerda que los movimientos conflictivos que fueron descartados tendrán que llevarse a cabo en una nueva dispersión.",
                completed: "La dispersión se ha completado exitosamente",
                warning: "Se detectaron <strong>{total} conflictos por ${amount}</strong> en tu plantilla. Te recomendamos solucionarlos antes de solicitar la autorización de la dispersión.",
                danger: "Esta dispersión ha sido rechazada por el siguiente motivo:"
            },
            progressBar: {
                validating: "Validando movimientos",
                finish: "¡Validación completa!",
                conflicts: "{total} conflictos",
                reject: "{total} rechazados",
                scattering: "Realizando dispersión",
                scattered: "¡Dispersión completa!",
            },
            validatingContacts: "La validación de beneficiarios ha comenzado",
            downloadExcel: "Descargar Excel",
            rejectReasonDefault: "Rechazada por el colaborador autorizador",
            table: {
                headers: {
                    validated: "Validados",
                    conflictive: "Conflictivos",
                    pending: "Pendientes",
                    scattered: "Dispersadas",
                    rejected: "Rechazadas",

                    status: "Estatus",
                    beneficiary: "Beneficiario",
                    account: "Cuenta",
                    amount: "Monto",
                },
                rows: {
                    valid: "Validación correcta",
                    conflictiveCLABE: "No se encontró la cuenta CLABE",
                    conflictiveBeneficiary: "Falta autorización del beneficiario",
                    conflictiveNotBeneficiaryClABE: "Beneficiario no coincide con cuenta CLABE",
                    transfers: {
                        PERMIT_ACCOUNT: 'Operación no permitida para el tipo de cuenta',
                        INSUFFICIENT_FOUNDS: 'Fondos Insuficientes',
                        CARD_DES_NOT: 'No se encontró tarjeta en cuenta destino',
                        ACCOUNT_DES_NOT: 'No se encontró cuenta destino',
                        ACCOUNT_DES_CORPORATE: 'Cuenta destino no apta para recibir transferencia',
                        PERMIT_ACCOUNT_DES: 'Operación no permitida para la cuenta de destino',
                        DEPOSIT_ACCOUNT_DES: 'La cuenta destino no puede recibir la transferencia',
                        ERROR_DEPOSIT_PAGANDO: 'No se puedo generar la transferencia entre cuentas pagando',
                        ERROR_DEPOSIT_EXTERNAL: "No se puedo generar el spei",
                        INTERNAL_ERROR_POLIZA: "La poliza destino fallo",
                        PENDING_BALANCE_APPLY: "La cuenta tiene polizas o movimientos pendientes"
                    },
                    pending: "Transferencia pendiente",
                    pendingAuth: "Esperando autorización",
                    correct: "Transferencia realizada",
                    rejected: "Movimiento rechazado",
                    notBank: 'Banco no identificado',
                    transfersPag: 'transferencia | transferencias',
                    emptyState: {
                        valid: {
                            title: "Validando",
                            subtitle: "Estamos validando los los movimientos de tu dispersión",
                        },
                        conflicts: {
                            title: "Sin conflictos",
                            subtitle: "¡Excelente! No se ha presentado ningún conflicto con los movimientos de tu dispersión",
                        },
                        pending: {
                            title: "Sin Pendientes",
                            subtitle: "¡Excelente! No falta ninguno de los movimientos de tu dispersión",
                        },
                        scattered: {
                            title: "Sin Dispersiones",
                            subtitle: "Estamos trabajando para dispersar tus movimientos",
                            titleAuth: "Autorización pendiente",
                            subtitleAuth: "Aquí podrás consultar las transferencias que se hayan realizado exitosamente una vez que se autorice la dispersión.",
                        },
                        rejected: {
                            title: "Sin Rechazados",
                            subtitle: "¡Excelente! No falta ninguno de los movimientos de tu dispersión",
                        },
                    }
                }
            },
            modals: {
                cancel: {
                    title: "¿Estás seguro de que quieres cancelar esta dispersión?",
                    subtitle: "Si cancelas esta dispersión tendrás que volver a cargar tu plantilla si quieres realizar las transferencias.",
                    confirm: "Si",
                    cancel: "No"
                },
                reject: {
                    title: "Rechazo de dispersión",
                    reason: "Motivo de rechazo",
                    reasonPh: "¿Por qué se rechazó?",
                    folio: "Folio:",
                    transfers: "transferencia | transferencias",
                    cancel: "Cancelar",
                    reject: "Rechazar"
                },
                auth: {
                    title: "Autorización de dispersión",
                    now: "Transferir ahora",
                    later: "Transferir después",
                    folio: "Folio:",
                    transfers: "transferencia | transferencias",
                    cancel: "Cancelar",
                    auth: "Autorizar",
                    day: "Día",
                    dayPh: "DD",
                    month: "Mes",
                    monthPh: "MM",
                    year: "Año",
                    yearPh: "AAAA",
                    hour: "Hora",
                    hourPh: "00:00"
                }
            },
            success: {
              cancel: "La dispersión se ha cancelado exitosamente"
            },
            error: {
                loading: "Hubo un problema cargando la dispersion",
                downloadFile: "Hubo un problema tratando de descargar el archivo",
                cancel: "Hubo un problema tratando de cancelar su dispersión",
                update: "Hubo un problema tratando de actualizar la dispersion",
                reject: "Hubo un problema tratando de rechazar la dispersion"
            },
            waitAuth: {
                title: "Dispersión en espera de autorización",
                subtitle: "Hemos notificado a la persona encargada de autorizar esta dispersión. Las transferencias serán realizadas una vez que esa persona introduzca su firma.",
                amountScatter: "Monto a dispersar",
                description: "Descripción",
                commission: "Se cobrará <strong>{commission}% + IVA de comisión</strong> sobre el monto total de la dispersión",
                finish: 'Finalizar'
            }
        },
        conflicts: {
            routerLink: "Regresar a Detalle de dispersión",
            titleStrong: "Solucionar conflictos",
            title: "Solucionar conflictos",
            subtitle: "A continuación te explicamos cómo solucionar los conflictos generados en tu plantilla: ",
            ins1: "Haz clic en el botón <strong class='poppins-font-bold'>“Descargar Excel”</strong>",
            ins2: "Puedes crear una <strong class='poppins-font-bold'>copia del archivo</strong> para tener registro del estatus de todos los movimientos",
            ins3: "En el archivo original <strong class='poppins-font-bold'>elimina</strong> las pestañas:  <strong class='poppins-font-bold'>“Todos” y “Correctos”</strong>",
            ins4: "En la pestaña <strong class='poppins-font-bold'>“Conflictivos” corrige los valores incorrectos</strong> de las líneas marcadas en rojo",
            ins5: "Guarda el <strong class='poppins-font-bold'>archivo corregido</strong>, súbelo aquí abajo y haz clic en <strong class='poppins-font-bold'>“Procesar plantilla”</strong>",
            ins6: "<strong class='poppins-font-bold'>¡Listo!</strong> Se procesarán nuevamente los movimientos corregidos y se agregarán a tu dispersión",
            importan: "IMPORTANTE:",
            ins7: "Agrega los beneficiarios faltantes a tu ",
            ins8: "lista de contactos",
            ins9: "antes de volver a procesar la plantilla ya que se presentaron conflictos con la doble validación de beneficiarios.",
            footer: "En caso de que completes este proceso y sigas teniendo errores puedes <a href='https://www.pagando.mx/contacto-pagando/' class='c-info poppins-font-bold text-underline'>contactar a nuestro equipo de soporte.</a>",
            back: "Regresar",
            later: "Solucionar después",
            processTemplate: "Procesar plantilla",
            modalP: "Si no puedes solucionar los conflictos en este momento pero necesitas <strong>agilizar la dispersión</strong>, tienes la opción de solicitar la autorización solamente de los movimientos validados. Considera que se descargará el Excel generado para identificar los movimientos conflictivos pero ya no podrás solucionar esos conflictos dentro de esta misma dispersión. <strong>Deberás asegurarte de crear una nueva dispersión para validar y dispersar los movimientos corregidos, de lo contrario esas transferencias no se llevarán a cabo.</strong>",
            modalTitle: "¿Deseas solicitar la autorización solo de los movimientos validados?",
            modalYes: "Si",
            modalNo: "No",
            error: {
                template: "hubo un error procesando la plantilla"
            }
        }
    },
    components: {
        dynamicDoc: {
            upload: "Subir",
            modalUploadVideo: {
                title: "¿Cómo tomar mi video?",
                subtitle: "Para realizar la prueba de vida, tienes que grabar un video de tu cara, para realizarlo puedes usar cualquiera de las siguientes dos opciones que te presentamos aquí:"
            },
            modalVideoPermit: {
                title: "Permite el acceso a la cámara",
                subtitle: "En tu navegador verás una ventana emergente que te pedirá que permitas el acceso a tu cámara. Por favor, asegúrate de hacer clic en permitir para acceder a tu cámara y grabar un video corto.",
                acceptPermission: "Permitir el acceso",
                cameraDenied: "El permiso de camara esta bloqueado. Si deseas usar la camara necesitas quitar el bloque de camara!",
                errorPermit: "Se requiere permiso para usar la camara para la opción seleccionada. Por favor de aceptar o seleccionar otra opción",
            },
            modalHowTake: {
                title: "¿Cómo tomar mi video?",
                button: "Tomar mi video",
                vIns1: "<strong>Mira directamente a la cámara</strong> y haz clic en el botón de iniciar video.",
                vIns2: "<strong>Haz despacio 2 círculos con tu cabeza</strong> sin dejar de mirar a la cámara.",
                vIns3: "Termina antes de que pasen 10 segundos y haz clic en el botón de <strong>finalizar video.</strong>",
                cIns1: "<strong>Coloca la identificación frente a la cámara</strong> y haz clic en el botón de Tomar foto.",
                cIns2: "Una vez tomada la foto recorta solamente la identificación y haz click en Procesar.",
                cIns3: "Si la foto de la identificación esta correctamente recortada, haz click en Subir Foto.",
            },
            modalTakeVideo: {
                initRecord: "Iniciar grabación",
                recording: "Grabando video...",
                dummy: "¿Hiciste 2 circulos con tu cabeza?",
                noTryAgain: "No, intentar de nuevo",
                yesConfirm: "Si, hice 2 circulos",
            },
            modalExit: {
                areYouSure: "¿Estás seguro de que quieres salir del proceso?",
                p: "Si decides finalizar ahora, deberás reiniciar el proceso de tomar el video desde el principio.",
                exit: "Salir",
                next: "Continuar",
            },
            error: {
                formatInvalid: "El formato no es valido"
            }
        }
    },
    cardRequest,
    pagandoCheck,
    notifications: {
        title: "Notificaciones",
        all: "Todos",
        unreaded: "Sin leer",
        today: "Hoy",
        yesterday: "Ayer",
        empty: {
            title: "Sin notificaciones recientes",
            text: "No tienes ninguna novedad en tu bandeja de entrada."
        },
        error: {
            seeDetails: "Ocurrió un problema al mostrar los detalles del movimiento",
            getReaded: "Ocurrió un problema al obtener notificaciones leídas",
            getUreaded: "Ocurrió un problema al obtener notificaciones sin leer"
        }
    },
    promotions: {
        title: "Promociones",
        goBack: "Regresar a Inicio",
        back: "Regresar",
        availability: {
            STORE: "Disponible en tienda",
            ONLINE: "Disponible en línea",
            STORE_AND_ONLINE: "Disponible en línea y en tienda",
            CUSTOM: "Disponibilidad personalizada",
        },
        expireIn: "Expira: {date}",
        expireInDays: "Expira en {days} día | Expira en {days} días",
        expireToday: "Expira hoy",
        emptyState: {
            title: "No hay promociones vigentes",
            text: "Próximamente podrás consultar las promociones disponibles para tu cuenta"
        },
        loading: "Cargando promociones...",
        errors: {
            getPromos: "Ocurrió un error al obtener promociones"
        },
        detail: {
            goBack: "Regresar a Promociones",
            title: "Detalle de la promoción",
            appliedPromo: "Aplica promoción en:",
            consultTerms: "Consultar términos",
            termsConditions: "Términos y Condiciones",
            error: "Ocurrió un error al obtener detalle de promoción",
            expireIn: "Expira: {date}",
            expireInDays: "Expira: En {days} día | Expira: En {days} días",
            expireToday: "Expira: Hoy",
        }
    },
    settings: {
        title: "Ajustes",
        goBack: "Regresar a Ajustes",
        back: "Regresar",
        home: {
            goBack: "Regresar al inicio",
            messageWelcome: "Hola, bienvenido",
            lastAccess: "Último acceso:",
            generalConfig: "Configuración general",
            security: "Seguridad",
            help: "Ayuda y soporte",
            frequentQuestions: {
                title: "Preguntas frecuentes",
                text: "Resuelve tus dudas respecto a Pagando "
            }
        },
        personalInfo: {
            title: "Información personal",
            generalInfo: "Información general",
            text2: "Consulta a detalle tu información",
            text: "Consulta la información sobre tus datos personales",
            fullName: "Nombre completo",
            nationality: "Nacionalidad",
            birthDate: "Fecha de nacimiento",
            birthState: "Lugar de nacimiento",
            gender: {
                title: "Género",
                MALE: "Masculino",
                FEMALE: "Femenino"
            },
            ocupation: "Ocupación",
            phone: "Número de teléfono",
            email: "Correo electrónico",
            address: "Domicilio",
            addressText: "Consulta la información sobre los datos de tu domicilio",
            country: "País de residencia",
            state: "Estado",
            city: "Ciudad",
            street: "Calle",
            noExt: "No. Exterior",
            noInt: "No. Interior",
            district: "Colonia",
            zipCode: "Código Postal",
            note: "<strong>IMPORTANTE:</strong> Por cuestiones de seguridad de nuestra plataforma, si deseas cambiar alguno de los datos de tu cuenta deberás contactar a nuestro equipo de soporte por medio del chat o llamada telefónica.",
            back: "Regresar",
            needChange: "¿Necesitas cambiar algo?",
            contactUs: "Contáctanos",
            loading: "Cargando..."
        },
        notifications: {
            title: 'Notificaciones',
            text: "Activa o desactiva las notificaciones",
            typesTitle: 'Tipos de notificaciones',
            typesText: 'Activa o desactiva los distintos tipos de notificaciones',
            type1: 'Emergentes en la pantalla',
            type2: 'Correo electrónico',
            back: "Regresar",
            needChange: "¿Tienes dudas?",
            contactUs: "Contáctanos",
            deactivateModal: {
                title: "¿Seguro que quieres desactivar las notificaciones?",
                text: "Las notificaciones sirven para indicarte cuando un movimiento se efectó en tu cuenta. Al desactivar todas las notificaciones tu cuenta podría correr peligro. A continuación se te pedirá verificar tu identidad.",
                button: "Desactivar",
                cancel: "Cancelar"
            },
            errorActive: "Ocurrió un error al activar notificaciones",
            errorDeactive: "Ocurrió un error al desactivar notificaciones"
        },
        inviteFriends: {
            title: "Invita a tus amigos",
            text: "Invita a tus amigos para ganar premios",
            ppalMessage: "Invita a tus amigos y recibirán $100 cada uno",
            ppalMessageText: "Comparte este código a tus amigos y pídeles que lo ingresen durante la creación de su cuenta nueva. Ganarás tú y tus amigos cuando completen su registro en Pagando.",
            referalCode: "Código de referido",
            copy: "Copiar",
            back: "Regresar",
            needChange: "¿Tienes dudas?",
            contactUs: "Contáctanos",
            errorGetCode: "Ocurrió un error al obtener el código de referido",
            copySuccess: "Código de referido copiado exitosamente"
        },
        security: {
            title: "Centro de seguridad",
            text: "Agrega mas seguridad a tu cuenta",
            password: {
                title: "Contraseña",
                subtitle: "Una contraseña segura protege tu cuenta e impide el acceso indebido, te sugerimos seguir nuestras recomendaciones para crear una contraseña segura.",
                changePass: "Cambiar mi contraseña"
            },
            twoFactorAuth: {
                title: "Factores de doble autenticación",
                subtitle: "Los factores de doble autenticación sirven para dar mayor seguridad a tu cuenta y confirmar que eres tú quien utiliza la aplicación. Estos factores se piden para iniciar sesión y cuando quieras realizar una transacción.",
                fingerPrint: "<i aria-hidden='true' class='iconsax-security-fingerprint-scan m-r-20 f-18'></i><strong class='m-r-5'>Huella digital: </strong> Usa tus huellas digitales para iniciar sesión (Disponible solo en tu app móvil “Pagando”)",
                faceID: "<i aria-hidden='true' class='iconsax-users-user-square m-r-20 f-18'></i><strong class='m-r-5'>Face ID: </strong> Usa tu rostro para iniciar sesión (Disponible solo en tu app móvil “Pagando”)",
                sms: "<i aria-hidden='true' class='iconsax-emails-sms-tracking m-r-20 f-18'></i><strong class='m-r-5'>SMS: </strong> Recibe códigos mediante mensajes de texto para validar tus operaciones",
                errors: {
                    fingerPrint: {
                        active: "Ocurrió un error al activar autentificación con huellas digitales",
                        deactive: "Ocurrió un error al desactivar autentificación con huellas digitales"
                    },
                    facialRecognition: {
                        active: "Ocurrió un error al activar autentificación con reconocimiento facial",
                        deactive: "Ocurrió un error al desactivar autentificación con reconocimiento facial"
                    },
                    smsTwoFactor: {
                        active: "Ocurrió un error al activar SMS de doble factor",
                        deactive: "Ocurrió un error al desactivar SMS de doble factor"
                    }
                }
            },
            back: "Regresar",
            needChange: "¿Tienes dudas?",
            contactUs: "Contáctanos",
            changePass: {
                goBack: "Regresar a Centro de Seguridad",
                title: "Cambiar mi contraseña",
                subtitle: "Por favor introduce y confirma tu nueva contraseña para cambiarla.",
                actualPass: {
                    title: "Contraseña actual",
                    placeholder: "Confirma tu contraseña"
                },
                restartPass: "Restablecer contraseña",
                changePassLoading: "Cambiando contraseña...",
                success: {
                    title: "¡Listo! Contraseña restablecida",
                    text: "Tu contraseña ha sido restablecida correctamente, puedes continuar explorando en Pagando.",
                    ready: "Listo"
                },
                errors: {
                    passNoMatch: "Las contraseñas no coinciden.",
                    changePass: "Ocurrio un error al cambiar contraseña"
                }
            }
        },
        contact: {
            title: "Contacto",
            text2: "Visita nuestras redes sociales",
            title2: "Información de contacto",
            text: "Consulta nuestros números teléfonicos para contactarte desde México y de Estados Unidos, y no olvides visitarnos en nuestras redes sociales.",
            webPage: "Página web",
            phoneFromMexico: "Teléfono desde México",
            phoneFromEUA: "Teléfono desde Estados Unidos",
            email: "Correo electrónico",
            back: "Regresar",
            needChange: "Tienes dudas?",
            contactUs: "Contáctanos",
        }
    },
    multipleAccountPersonal: {
        steps: {
            selectAccount: "Seleccionar cuenta"
        },
        buttonActions: {
            cardReposition: "Ver estatus de reposición",
            physicActiveCard: "Activar tarjeta física",
            physicPendingCard: "Ver estatus de envío",
            virtualContract: "Firmar contrato",
            virtualPendingCard: "Continuar solicitud",
            default: "Intentar de nuevo"
        },
        messagesError: {
            cardReposition: "Actualmente tienes una <strong>solicitud de reposición de tarjeta en curso</strong>. Es necesario que se complete ese proceso para poder realizar una nueva solicitud.",
            physicActiveCard: "Actualmente tienes una <strong>solicitud de activación de tarjeta en curso</strong>. Es necesario que se complete ese proceso para poder realizar una nueva solicitud.",
            physicPendingCard: "Actualmente tienes una <strong>solicitud de envío de tarjeta física en curso</strong>. Es necesario que se complete ese proceso para poder realizar una nueva solicitud.",
            virtualContract: "Actualmente tienes un <strong>contrato pendiente de firma</strong>. Es necesario que se complete ese proceso para poder realizar una nueva solicitud.",
            default: "Actualmente tienes una <strong>solicitud de cuenta</strong> en curso. Es necesario que se complete ese proceso para poder realizar una nueva solicitud.",
            default2: "Ocurrió un error al procesar tu solicitud. Por favor vuelve a intentarlo."
        },
        cancelModal: {
            title: "¿Estás seguro de que quieres cancelar esta solicitud de cuenta?",
            text: "Si la cancelas perderás la información que hayas ingresado y tendrás que iniciar nuevamente el proceso de solicitud.",
            button: "Si"
        },
        success: {
            title: "¡Disfruta tu nueva cuenta Pagando!",
            subtitle: "A partir de ahora cuentas con una nueva tarjeta virtual, podrás enviar y recibir fondos electrónicos, fondear tu cuenta así como solicitar una tarjeta física.",
        },
        back: "Regresar a mi cuenta",
        cancelRequest: "Cancelar solicitud"
    },
    typeCards: {
        VIRTUAL: 'Virtual',
        PHYSIC: 'Física'
    },
    additionalCards: {
        menu: {
            title: "Solicitar nueva tarjeta",
            tooltip1: "Solicitar tarjeta adicional",
            tooltip2: "Añade tarjetas adicionales virtuales a tu cuenta {account}"
        },
        goBack: "Regresar a Inicio",
        back: "Regresar",
        title: "Solicitar tarjeta virtual adicional",
        subtitle: "Solicita una tarjeta virtual adicional para usar con tu cuenta. Compartirá el saldo con tu tarjeta actual, pero podrás segmentar e identificar los movimientos hechos por cada tarjeta.",
        CLABE: "CLABE",
        noAccount: "No. de cuenta",
        numAdditional: "Tarjetas adic.",
        myAccount: "Mi cuenta",
        changeAccount: "Cambiar de cuenta",
        myCards: "Mis tarjetas",
        consultCards: "Consulta las tarjetas virtuales actualmente vinculadas a tu cuenta",
        principal: "Principal",
        editAlias: "Editar alias",
        addNewCard: "Añadir nueva tarjeta virtual",
        aliasDefault: "Tarjeta {num}",
        numCards: "{cards} de {total}",
        alias: {
            title: "Alias de tarjeta",
            placeholder: "P. ej. “Departamento Ventas”",
            warning: "3 caracteres mínimo"
        },
        create: {
           goBack: "Regresar a Solicitar tarjeta adicional" ,
           title: "Nueva solicitud de Tarjeta virtual",
           subtitle: "Completa la información para solicitar una nuevatarjeta virtual para tu cuenta",
           loading: "Generando solicitud...",
           title2: "Nueva tarjeta",
           subtitle2: "Configura tu nueva tarjeta con las opciones presentadas a continuación.",
            qtyCardsMessage: "Puedes añadir hasta {qty} tarjetas adicionales sin costo en tu cuenta {account}. Si requieres más, tendrán un costo extra de {extraCost}",
            button: "Completar solicitud"
        },
        edit: {
            title: "Editar tarjeta",
            subtitle1: "Personaliza la tarjeta virtual adicional de tu cuenta",
            subtitle2: "Una vez terminados tus cambios, haz clic en el botón “Guardar cambios”",
            loading: "Guardando cambios...",
            loadingCancel: "Cancelando tarjeta...",
            subtitle3: "Configura tu tarjeta con las opciones presentadas a continuación.",
            cancelCard: "Cancelar tarjeta",
            modalCancel: {
                title: "¿Estás seguro de que quieres cancelar esta tarjeta virtual?",
                subtitle: "Si cancelas esta tarjeta virtual, los datos asociados a esta se perderán y cualquier pago domiciliado a esta tarjeta ya no podrá cobrarse.",
                button1: "No",
                button2: "Si"
            },
            qtyCardsMessage: "Puedes añadir hasta {qty} tarjetas adicionales sin costo en tu cuenta {account}. Si requieres más, ponte en <a href='https://www.pagando.mx/contacto-pagando/' target='_blank' class='c-info poppins-font-bold hover' tabindex=''><u>contacto con nosotros.</u></a>",
            button: "Guardar cambios"

        },
        errors: {
            incompleteParams: "Necesitas completar correctamente los datos para continuar.",
            doRequest: "Ocurrió un error al realizar la solicitud de tarjeta adicional",
            alias: "Ocurrió un error al modificar alias de tarjeta",
            cancelCard: "Ocurrio un error al cancelar tarjeta",
            getDetail: "Ocurrio un error al obtener detalle de tarjeta"
        },
        success: {
            cancelCard: "Tarjeta cancelada exitosamente"
        }
    },
    changeAccount: {
        balance: "Saldo",
        noAccount: "No. de cuenta",
        myAccount: "Mi cuenta",
        button: "Cambiar cuenta"
    },
    announcements: {
        showMenu: 'Mostrar menú principal',
        hideMenu: 'Esconder menú principal',
        return: 'Regresar a Inicio',
        title: 'Detalle de aviso',
        loading: 'Cargando...',
        imageAlt: 'Aviso',
        returnButton: 'Regresar',
    },
    corporativeAccounts: {
        list: {
            title: 'Cuentas Corporativas',
            subtitle: 'Crea a tus colaboradores cuentas corporativas asociadas a esta cuenta y asígnales un monto mensual para uso en viáticos o compras del negocio.',
            new: 'Nueva cuenta',
            myAccounts: 'Mis cuentas',
            search: 'Buscar cuenta...',
            paginationCount: '1 cuenta corporativa | {total} cuentas corporativas',
            headers: {
                key: 'No. cuenta',
                alias: 'Alias',
                collaborator: 'Colaborador',
                status: 'Estatus',
                monthUsage: 'Uso en el mes',
            },
            loading: 'Buscando...',
            badges: {
                CREATED: 'Creada',
                ERROR: 'Error',
                ACTIVE: 'Activa',
                INACTIVE: 'Bloqueada',
                PROCESSING: 'Procesando',
            },
            actions: {
                header: 'Mas Acciones',
                details: 'Ver Detalles',
                activity: 'Ver actividad',
                edit: 'Editar',
                unblock: 'Desbloquear',
                block: 'Bloquear',
                delete: 'Eliminar',
                resendEmail: 'Reenviar correo',
            },
            empty: {
                title: 'Por el momento no tienes cuentas corporativas',
                text: 'Aquí estará el listado de las cuentas que hayas creado.',
            },
        },
        limits: {
            headers: {
                type: 'Tipo de transacción',
                amount: 'Monto máximo',
                periodicity: 'Periodo',
            },
            errorMin: 'Mínimo $1.00',
            errorMax: 'Máximo {amount} {periodicity}',
            type: {
                CARD_PURCHASES: 'Compras con tarjeta',
                ATM_CASH_WITHDRAWAL: 'Retirar efectivo en cajero',
                CASH_WITHDRAWAL_STORES: 'Retirar efectivo en establecimientos',
                SPEI_IN: 'Recibir transferencias SPEI',
                SPEI_OUT: 'Realizar transferencias SPEI',
                TRANSFER_IN_PAGANDO: 'Recibir transferencias de cuentas Pagando',
                TRANSFER_OUT_PAGANDO: 'Realizar transferencias a cuentas Pagando',
            },
            periodicity: {
                DAILY: "Diario | Diarios",
                MONTHLY: "Mensual | Mensuales",
                QUARTERLY: "Trimestral | Trimestrales",
                BIANNUAL: "Semestral | Semestrales",
                ANNUAL: "Anual | Anuales",
                none: "N/A",
            },
        },
        modalNewAccount: {
            periodicity: {
                UNIQUE: ".",
                BY_EVENT: "por evento.",
                DAILY: "al día.",
                MONTHLY: "al mes.",
                QUARTERLY: "al trimestre.",
                BIANNUAL: "al semestre.",
                ANNUAL: "al año.",
            },
        },
        new: {
            back: 'Regresar a Cuentas corporativas',
            header: 'Nueva cuenta corporativa',
            title: 'Configuración de cuenta',
            subtitle: 'Define la configuración general de la cuenta, los datos del colaborador a quien deseas asignarla y los límites operacionales que tendrá.',
            title2: 'Datos del colaborador',
            subtitle2: 'Ingresa el correo electrónico de tu colaborador que será ligado a esta cuenta corporativa.',
            genericError: 'Error',
            submit: 'Crear cuenta',
            processing: 'Solicitud en proceso...',
            processingAlt: 'Procesando tu solicitud',
            return: 'Regresar',
            finish: 'Finalizar',
            success: {
                title: 'Cuenta creada exitosamente',
                text: 'Tu colaborador podrá acceder a su nueva cuenta siguiendo las instrucciones que hemos enviado a su correo <strong>{email}</strong>'
            },
            error: {
                title: 'Creación de cuenta fallida',
                text: '¡Lo sentimos mucho! Tu cuenta corporativa no pudo crearse exitosamente por el siguiente motivo:',
            },
            contactSupport: 'Contactar a soporte',
            retry: 'Reintentar',
            modal: {
                title: 'Crear esta cuenta corporativa tendrá un costo mensual',
                confirm: 'Confirmar',
                text1: 'La emisión de una nueva cuenta corporativa',
                text2: 'tiene un costo de',
                text3: 'Mientras esta cuenta se encuentre en uso, el monto de la mensualidad será descontado de tu saldo el día de corte de tu tarjeta.',
            },
        },
        edit: {
            header: 'Editar cuenta',
            title: 'Edición de cuenta',
            subtitle: 'Configuración general de la cuenta, los datos del colaborador a quien le fue asignada y los límites operacionales que tiene.',
            title2: 'Datos del colaborador',
            subtitle2: 'Correo electrónico de tu colaborador que está ligado a esta cuenta corporativa.',
            submit: 'Guardar cambios',
        },
        form: {
            limitsTitle: 'Límites operacionales',
            limitsText: 'Establece qué tipo de transacciones podrá realizar tu colaborador con esta cuenta, y los montos que tiene permitidos.',
            back: 'Regresar',
            cancel: 'Cancelar',
            noAlias: 'Asigna un alias',
            noAccountType: 'Tipo de cuenta no seleccionado',
            alias: {
                label: 'Alias de cuenta',
                placeholder: 'P. ej. “Departamento Ventas”',
                warningText: '3 caracteres mínimo',
            },
            monthlyAmount: {
                label: 'Monto disponible por mes',
                placeholder: '$0.00',
            },
            accountType: {
                label: 'Tipo de cuenta',
                placeholder: 'Elige un tipo de cuenta',
            },
            email: {
                label: 'Correo electrónico',
                placeholder: 'Ingresar correo',
                button: 'Buscar',
            },
            user: {
                label: 'Nombre del colaborador',
                labelNoAccount: 'Colaborador sin cuenta en Pagando',
                placeholder: 'Busca un correo',
                textNoAccount: 'Enviaremos una invitación a crear su cuenta',
            },
            limit: {
                placeholder: 'Seleccionar',
            },
        },
        details: {
            return: 'Regresar a Cuentas Corporativas',
            header: 'Detalle de cuenta',
            title: 'Detalle de cuenta corporativa',
            subtitle: 'Configuración general de la cuenta, los datos del colaborador a quien le fue asignada y los límites operacionales que tiene.',
            blockDate: 'Esta cuenta corporativa se bloqueó el día: <strong>{date} a las {time}</strong>',
            alias: 'Alias de cuenta',
            monthlyAmount: 'Monto disponible por mes',
            accountType: 'Tipo de cuenta',
            title2: 'Datos del colaborador',
            subtitle2: 'Nombre y correo electrónico de tu colaborador que está ligado a esta cuenta corporativa.',
            userName: 'Nombre completo',
            userEmail: 'Correo electrónico',
            title3: 'Límites operacionales',
            subtitle3: 'Tipos de transacciones puede realizar tu colaborador con esta cuenta, y los montos que tiene permitidos.',
            back: 'Regresar',
            edit: 'Editar cuenta',
            activity: 'Ver actividad',
        },
        modals: {
            block: {
                title: '¿Estás seguro de que quieres bloquear esta cuenta corporativa?',
                text: 'Tu colaborador no podrá realizar compras ni ninguna acción con su cuenta mientras esta se encuentre bloqueada. Sin embargo, la mensualidad de uso seguirá cobrándose a menos de que la cuenta sea eliminada definitivamente.',
                buttonCancel: 'No',
                buttonText: 'Sí',
                toastSuccess: 'Se ha bloqueado la cuenta corporativa exitosamente.',
            },
            unblock: {
                title: '¿Estás seguro de que quieres desbloquear esta cuenta corporativa?',
                text: 'Una vez desbloqueada la cuenta tu colaborador podrá volver a realizar transacciones de manera regular.',
                buttonCancel: 'No',
                buttonText: 'Sí',
                toastSuccess: 'Se ha desbloqueado la cuenta corporativa exitosamente.',
            },
            delete: {
                title: '¿Estás seguro de que quieres desactivar esta cuenta corporativa?',
                text: 'Recuerda que tu colaborador ya no podrá realizar operaciones con esta cuenta y que si hay pagos domiciliados a ella será necesario redireccionar el pago.',
                buttonCancel: 'No',
                buttonText: 'Sí',
                toastSuccess: 'Se ha eliminado la cuenta corporativa exitosamente.',
            },
        },
        activity: {
            showSidebarMenu: 'Mostrar menú principal',
            showSidebarRight: 'Mostrar menú derecho',
            return: 'Volver a cuenta principal',
            lastLogin: 'Último acceso: {date}',
            monthlyAmount: {
                max: 'Monto máximo mensual',
                available: 'Monto disponible',
                used: 'Monto utilizado',
            },
            blockSuccess: 'Tarjeta bloqueada correctamente',
            unblockSuccess: 'Tarjeta desbloqueada correctamente',
            dashboard: {
                user: 'Titular de la cuenta',
                userText: 'Consulta la información general de tu colaborador.',
                email: 'Correo electrónico',
                phone: 'Teléfono',
                alias: 'Alias de la cuenta',
            },
        },
    },
    dashboard: {
        recentMovements: {
            title: 'Movimientos recientes',
            subtitle: 'Consulta los movimientos que se han realizado en tu cuenta.',
            emptyTitle: 'Sin historial de movimientos',
            emptyText: 'Comienza a utilizar tu cuenta y aquí podrás consultar tu historial de movimientos.',
            seeMovements: 'Ver todos mis movimientos',
        },
        movementsChart: {
            title: 'Movimientos semanales',
            abonos: 'Abonos',
            cargos: 'Cargos',
            abonosWeek: 'Abonos de la semana',
            cargosWeek: 'Cargos de la semana',
        },
        sidebarMenu: {
            hide: 'Esconder menú principal',
            home: 'Inicio',
        },
        sidebarRight: {
            hideShow: 'Ocultar/Mostrar Menu Sidebar',
            accountAlt: 'Cuenta {account}',
            myAccount: 'Mi Cuenta',
            seeBack: 'Ver reverso',
            seeFront: 'Ver vista frontal',
            expDate: 'EXP. DATE',
            nip: 'NIP DE TARJETA',
            seeNip: 'Ver NIP de tarjeta',
            cvc: 'CVC',
            seeCVC: 'Ver CVC',
            available: 'DISPONIBLE',
            block: {
                title: 'Bloquear tarjeta',
                text: 'Al bloquear tu tarjeta no podrás usarla en cajeros, comercios o por internet',
            },
        },
        modalAlias: {
            title: 'Asignar alias de cuenta',
            subtitle: 'Asigna un alias a esta cuenta para que puedas identificarla fácilmente.',
            input: {
                title: 'Alias de tarjeta',
                placeholder: 'P. ej. “Departamento Ventas”',
                warning: '3 caracteres mínimo'
            },
            save: 'Guardar',
            cancel: 'Cancelar',
            successMessage: 'Alias asignado correctamente a la cuenta.',
            warningMessage: 'El alias debe de ser de mínimo 3 caracteres.',
            errorMessage: 'Ocurrió un error al asignar Alias a cuenta.'
        }
    },
    mati: {
        'document-type': {
            'national-id': 'IFE / INE',
            'passport': 'Pasaporte',
            'proof-of-residency': 'Comprobación de Residencia'
        },
        'age-check': {
            'underage': {
                'noDOB': 'No se pudo obtener tu fecha de nacimiento',
                'error': 'Actualmente eres menor de edad, necesitas ser mayor de edad para poder continuar con el proceso',
            },
            'input': {
                'failed': 'No se puede cumplir con la entrada dependiente',
            }
        },
        'alteration-detection': {
            'alterationDetection': {
                'negligence': 'No hemos podido verificar tus documentos.',
                'fraudAttempt': 'Ha ocurrido un error al verificar tus documentos.',
                'tampered': 'Encontramos una alteración en tus documentos.',
            },
            'input': {
                'failed': 'No se puede cumplir con la entrada dependiente',
            },
            'system': {
             'internalError': 'Error interno',
            },
            'reason': {
                'digitalPhotoReplacement': 'La foto del documento ha sido alterada digitalmente.',
                'fake': 'La imagen es sintética. La información que contiene es irreal.',
                'textReplacement': 'El texto del documento ha sido reemplazado con información diferente',
                'manualPhotoReplacement': 'La foto del documento ha sido alterada.',
                'differentFrontAndBack': 'La información de enfrente no coincide con la información del reverso',
                'underage': 'La persona en el documento es menor de edad.',
                'physicalObstruction': 'Hay una obstrucción física que impide ver el documento completo',
                'digitalObstruction': 'Hay una obstrucción digital que impide ver el documento completo',
                'blurred': 'La imagen está borrosa que oculta información sobre el documento.',
                'pixelated': 'La imagen está pixelada que oculta información sobre el documento.',
                'screenPhoto': 'La imagen es una imagen de una pantalla donde se almacena el documento.',
                'grayscale': 'La imagen es una copia en blanco y negro del documento.',
                'cropped': 'El documento está recortado.',
                'distorted': 'La imagen está distorsionada, lo que hace que la calidad de la imagen sea deficiente.',
                'sameImages': 'La misma imagen (anverso o reverso) se subió dos veces',
                'colorCopy': 'La imagen es una copia en color del documento.',
                'screenshot': 'La imagen es una captura de pantalla.',
                'incorrectDocument': 'El documento subido es incorrecto',
                'noDocument': 'No hay ningún documento en la imagen',
            }
        },
        'document-reading': {
            'customDocument': {
                'notProvided': 'No hemos encontrado ningun documento referente a ti',
                'skipped': 'El usuario omitió este paso',
            },
            'eSignature': {
                'notValidParams': 'El documento no es válido o la foto del documento no coincide con la selfie',
                'emailStepFailed': 'No se ha pasado el paso de correo electrónico',
                'ipStepFailed': 'No se ha pasado el paso de IP',
                'phoneStepFailed': 'No se ha pasado el paso del teléfono',
                'fullNameMismatch': 'El nombre en el documento no coincide con el nombre en la firma',
            },
            '503': 'Request is rejected when call `verifications.setDocumentFields` action on `mol-verification-xxxxxxxxxx-xxxxx-xx` node.',
            'system': {
                'internalError': 'Error interno'
            }
        },
        'facematch': {
            'input': {
                'failed': 'No se puede cumplir con la entrada dependiente',
            },
            'selfie_id': 'Error de descarga de selfie_id',
            'doc_photo_id': 'Error de descarga de doc_photo_id',
            'faceDidNotMatch': 'La cara no coincide con el documento.',
            'visionAgent': {
                'facematch': 'Request is rejected when call `visionAgent.facematch` action on `mol-govservices-xxx` node.',
            },
            'personCannotBeConfirmedAsOwner': 'Esta persona no puede ser confirmada como propietaria del documento proporcionado, se solicitará una selfie con el documento',
            'notEnoughData': 'No hay suficientes datos para procesar el paso de coincidencia facial',
            'notEnoughFrames': 'No hay suficientes frames para  procesar el paso de coincidencia facial',
            'requestTimeout': 'Request timeout',
            'system': {
                'internalError': 'Step timeout'
            }
        },
        'liveness': {
            'input': {
                'failed': 'Cannot meet dependent input',
            },
            'node': {
                'failed': 'Cannot meet dependent node',
            },
            'system': {
                'internalError': 'Step timeout',
            },
            'legacy': {
                'error': 'El usuario no pasó liveness'
            }
        },
        'mexican-curp-validation': {
            'curp': {
                'fullNameMismatch': 'Falta de coincidencia de nombre completo',
                'invalid': 'El número CURP no es válido',
                'userNotFound': 'No se encontraron datos de la CURP',

            },
            'system': {
                'internalError': 'Error interno',
            },
            'input': {
                'failed': 'No se puede cumplir con la entrada dependiente',
            },
            'node': {
                'failed': 'Cannot meet dependent node',
            },
            '503': 'Request is rejected when call `documentMetadata.getFieldList` action on `mol-verification-xxx` node.',
            '404': 'Service `documentMetadata.getCountryList` is not available on `mol-verification-xxx` node.',
        },
        'mexican-ine-validation': {
            'ine': {
                'notEnoughParams': 'No hay suficientes parámetros para obtener datos ine',
                'notValidParams': 'Parámetros inválidos',
                'notFound': 'No se encontraron datos de INE',
                'oldDocument': 'Hay un documento más nuevo asociado a este usuario',
            },
            'input': {
                'failed': 'No se puede cumplir con la entrada dependiente',
            },
            'node': {
                'failed': 'Cannot meet dependent node',
            },
            'system': {
                'internalError': 'Error interno',
                'serviceUnavailable': 'Servicio no disponible',
            },
            '503': 'Request is rejected when call `documentMetadata.getFieldList` action on `mol-verification-xxx` node.',
            '404': 'Service `documentMetadata.getFieldList` is not available on `mol-verification-xxx` node.',
        },
        'mexican-pep-validation': {
            'mexicanPep': {
                'matchFound': 'Se encontró que el usuario era un PEP',
                'notEnoughParams': 'El nombre completo no está definido',
                'notValidParams': 'Error de validación de parámetros',
            },
            'input': {
                'failed': 'No se puede cumplir con la entrada dependiente',
            },
            'node': {
                'failed': 'Cannot meet dependent node',
            },
            'system': {
                'internalError': 'Error interno',
                'serviceUnavailable': 'Servicio no disponible',
            }
        },
        'mexican-rfc-validation': {
            'rfc': {
                'invalidParams': 'El número CURP no es válido',
                'notTaxPayer': 'La base de datos del RFC no tiene ningún contribuyente adscrito a esta CURP',
                'invalidCurp': 'La CURP es invalida',
                'notFound': 'No se encontraron datos RFC',
            },
            'input': {
                'failed': 'No se puede cumplir con la entrada dependiente',
            },
            'node': {
                'failed': 'Cannot meet dependent node',
            },
            'system': {
                'internalError': 'Error interno',
                'serviceUnavailable': 'Servicio no disponible',
            }
        },
        'template-matching': {
            'templateMatching': {
                'noMatchFound': 'El documento no coincide con ninguna plantilla válida',
            },
            'node': {
                'failed': 'Cannot meet dependent node',
            },
            'system': {
                'internalError': 'Error interno',
                'serviceUnavailable': 'Servicio no disponible',
            }
        },
        'watchlists': {
            'watchlists': {
                'notEnoughParams': 'No hay suficientes datos para realizar la búsqueda de listas negras',
                'matchFound': 'Encontramos una coincidencia dentro de una lista negra',
                'skipped': 'No se habilitaron listas negras en el flujo',
            },
            'input': {
                'failed': 'No se puede cumplir con la entrada dependiente',
            },
            'node': {
                'failed': 'Cannot meet dependent node',
            },
            'system': {
                'internalError': 'Error interno'
            }
        }
    },
    errorsAccountGenerate: {
        '5000': 'Ocurrió un error al leer tus documentos. Vuelve a subir tus documentos.',
        '100': 'Ocurrió un error al leer tus documentos. Vuelve a subir tus documentos.',
        '101': 'Ocurrió un error con el campo `Nombre`',
        '102': 'Ocurrió un error con el campo `Apellido Paterno`',
        '103': 'Ocurrió un error con el campo `Apellido Materno`',
        '104': 'Ocurrió un error con el campo `RFC`',
        '105': 'Ocurrió un error con el campo `CURP',
        '106': 'Ocurrió un error con el campo `Fecha de nacimiento`',
        '107': 'Ocurrió un error con el campo `País de nacimiento`',
        '108': 'Ocurrió un error con el campo `Estado de nacimiento / Estado de residencia`',
        '109': 'Ocurrió un error con el campo `País donde opera`',
        '110': 'Ocurrió un error con el campo `Fecha de constitución`',
        '111': 'Ocurrió un error con el campo `No. de serie de firma electrónica avanzada`',
        '112': 'Ocurrió un error con el campo `Calle`',
        '113': 'Ocurrió un error con el campo `Número exterior`',
        '114': 'Ocurrió un error con el campo `Número interior`',
        '115': 'Ocurrió un error con el campo `Colonia`',
        '116': 'Ocurrió un error con el campo `Ciudad`',
        '117': 'Ocurrió un error con el campo `Código postal`',
        '118': 'Ocurrió un error con el campo `Correo electrónico`',
        '119': 'Ocurrió un error con el campo `Teléfono celular`',
        '121': 'Ocurrió un error al procesar los documentos',
        '122': 'Ocurrió un error con el campo `Genero`',
        '201': 'La validación de algún documento falló',
        '202': 'La foto de algún documento está demasiado borrosa',
        '203': 'La resolución de las fotos de algún documento es demasiado baja',
        '204': 'Ocurrió un error inesperado en la lectura de algún documento',
        '205': 'Ocurrió un error inesperado en la lectura de algún documento',
        '206': 'Ocurrió un error inesperado en la lectura de algún documento',
        '207': 'Ocurrió un error inesperado en la lectura de algún documento',
        '208': 'Las fotos de algún documento son una captura de pantalla. Debes subir una foto diferente',
        '209': 'Ocurrió un error inesperado en la lectura de algún documento',
        '210': 'Ocurrió un error inesperado en la lectura de algún documento',
        '211': 'Ocurrió un error inesperado en la lectura de algún documento',
        '212': 'Alguno de los documentos no es legible',
        '213': 'Ocurrió un error inesperado en la lectura de algún documento',
        '214': 'Ocurrió un error inesperado en la lectura de algún documento',
        '215': 'Ocurrió un error inesperado en la lectura de algún documento',
        '216': 'Ocurrió un error inesperado en la lectura de algún documento',
        '217': 'Ocurrió un error inesperado en la lectura de algún documento',
        '218': 'Ocurrió un error inesperado en la lectura de algún documento',
        '219': 'Ocurrió un error inesperado en la lectura de algún documento',
        '220': 'Ocurrió un error inesperado en la lectura de algún documento',
        '300': 'Ocurrió un error inesperado al procesar tu solicitud. Vuelve a intentarlo.',
        '301': 'Ocurrió un error inesperado al procesar tu solicitud. Vuelve a intentarlo.',
        '302': 'Ocurrió un error inesperado al procesar tu solicitud. Vuelve a intentarlo.',
        '303': 'Ocurrió un error con el campo `Nombre`',
        '304': 'Ocurrió un error con el campo `Apellido Paterno`',
        '305': 'Ocurrió un error con el campo `Apellido Materno`',
        '306': 'Ocurrió un error con el campo `RFC` o `CURP`',
        '307': 'Ocurrió un error con el campo `Fecha de nacimiento`',
        '308': 'Ocurrió un error con el campo `Genero`',
        '309': 'Ocurrió un error con el campo `Estado`',
        '310': 'Ocurrió un error con el campo `Actividad Económica`',
        '311': 'Ocurrió un error con el campo `Calle`',
        '312': 'Ocurrió un error con el campo `Número exterior`',
        '313': 'Ocurrió un error con el campo `Número interior`',
        '314': 'Ocurrió un error con el campo `Colonia`',
        '315': 'Ocurrió un error con el campo `Ciudad`',
        '316': 'Ocurrió un error con el campo `Código postal`',
        '317': 'Ocurrió un error con el campo `País de nacimiento`',
        '318': 'Ocurrió un error con el campo `Correo electrónico`',
        '320': 'Ocurrió un error con el campo `Teléfono celular`',
        '321': 'Ocurrió un error con el campo `Nombre legal`',
        '322': 'Ocurrió un error con el campo `País`',
        '323': 'Ocurrió un error con el campo `Fecha de constitución`',
        '601': 'INE Vencida',
        '602': 'Comprobante de domicilio mayor a 3 meses',
        '603': 'Comprobante de domicilio no válido',
        '604': 'Comprobante de domicilio no encontrado',
    },
    accOpLimit: {
        title: "Configurar limites operacionales",
        back: "Regresar a Mi Cuenta",
        cardTitle: 'Limites operacionales',
        cardSubTitle: 'Puedes aumentar o disminuir los límites según tus necesidades y preferencias personales, dentro de las restricciones establecidas para tu tipo de cuenta.',
        success: 'Configurar limites operacionales actualizada',
        table: {
            headers: {
                type: 'Tipo de transacción',
                amount: 'Monto máximo',
                periodicity: 'Periodo',
            },
            limit: {
                CARD_PURCHASES: 'Compras con tarjeta',
                ATM_CASH_WITHDRAWAL: 'Retirar efectivo en cajero',
                SPEI_OUT: 'Transferencias SPEI',
                TRANSFER_OUT_PAGANDO: 'Transferencias entre cuentas Pagando',
            },
            limitWarning: 'El limite preestablecido es {amount}',
            limitWarningError: 'El limite máximo preestablecido es {amount}',
            periodicity: {
                BY_EVENT: "Por evento",
                DAILY: "Diario | Diarios",
                MONTHLY: "Mensual | Mensuales",
                QUARTERLY: "Trimestral | Trimestrales",
                BIANNUAL: "Semestral | Semestrales",
                ANNUAL: "Anual | Anuales",
                none: "N/A",
                placeholder: 'Seleccionar',
            },
        },
        footer: {
            back: "Regresar",
            cancel: "Cancelar",
            save: "Guardar configuración"
        }
    }
}
