import {
    GET_ACCOUNT,
    STORE as sessionStore
  } from "@/store/modules/session/session.types";
import store from '@/store/index'

/**
 * Retorna un middleware para usarse en una ruta, que limita el acceso a la ruta dependiendo de los permisos
 * que el usuario tiene en la cuenta activa.
 *
 * @param {String} action La accion del permiso requerido (READ | REQUEST | AUTHORIZE)
 * @param {String} type El tipo de permiso requerido (TRANSFERS | DISPERSIONS | BENEFICIARIES | CARD_REQUESTS | COLLABORATORS)
 * @param {{only: string[]}} options
 * @param {{prevent: string[]}} options
 * @param {Array<String>} options.prevent Los tipos de cuenta que no deben entrar a esta ruta, independientemente
 *      de los permisos. (USERS_ACCOUNT | CLIENTS_ACCOUNT | CORPORATE_ACCOUNT)
 * @param {Array<String>} options.only Se bloquea el acceso a la ruta si el tipo de cuenta actual no se encuentra
 *      en esta lista, independientemente de los permisos. (USERS_ACCOUNT | CLIENTS_ACCOUNT | CORPORATE_ACCOUNT)
 */
export default function hasPermission(action, type, options = {only: [], prevent: []}) {
    return function({ next }) {
        const access = store.getters[`${sessionStore}/${GET_ACCOUNT}`];
        if(!access) {
            return next('/wallet');
        }

        const productType = access.account?.accountType?.productType;


        if(options?.prevent?.length && options.prevent.includes(productType)) {
            return next('/wallet');
        }
        if(options?.only.length && !options.only.includes(productType)) {
            return next('/wallet');
        }
        if(access.isAccountHolder) {
            return next();
        }
        if(access.permissions && access.permissions.some(p => p.action === action && p.type === type)) {
            return next();
        }
        return next('/wallet');
    };
}
