<template>
  <div class="page-container content-centered">
    <div v-if="loadingInfo">
      <LoadingBar clazz="fullcontent-loading" :loadingText="$t('myAccount.beneficiaries.loadingInfo')"></LoadingBar>
    </div>
    <div v-else>
      <div class="row">
        <div class="col-12">
          <router-link
              to="/wallet/my-account"
              class="button xs button-ghost button-text_info p-l-0 p-r-0"
              tabindex="">
            <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
            {{$t('myAccount.beneficiaries.backAccount')}}
          </router-link>
          <FloatingTitleLayout :titleStrong="mode === 'new'  ? $t('myAccount.beneficiaries.titleAdd') : $t('myAccount.beneficiaries.titleEdit')">
          </FloatingTitleLayout>
        </div>

        <div class="col-12">
          <TitleSteps
              :title="$t('myAccount.beneficiaries.titleCard')"
              :subtitle="$t('myAccount.beneficiaries.subtitleCard')">
            <Tachometer clazzContent="leftContent" :percent="currentPercentage.toString()" text="Fondos asignados"/>
          </TitleSteps>
        </div>

        <div class="col-12">
          <div v-for="(beneficiary, index)  in beneficiaries" :key="index">
            <div class="collapse-beneficiary">
              <div class="head">
              <span>
                <BeneficiaryInfo
                    text="Beneficiario #1"
                    :textBold="beneficiary.name"
                    :imgUrl="beneficiary.imageIndex < 0 ? imgBeneficiario : images[beneficiary.imageIndex].src">
                >
                </BeneficiaryInfo>
              </span>
                <span class="percentage">
                <label>{{$t('myAccount.beneficiaries.list.percentage')}}:</label>
                <InputEdit
                    inputType="text"
                    inputPlaceholder="0%"
                    :disabled="!beneficiary.update"
                    v-model="beneficiary.percentage">
                </InputEdit>
              </span>
                <span>
                <button
                    class="button-square xs button-stroke button-accent"
                    type="button"
                    name="Colapsar beneficiario"
                    aria-label="Colapsar beneficiario"
                    v-b-toggle="'collapse-beneficiary' + index"
                >
                  <i aria-hidden="true" class="iconsax-arrow-direction-down"></i>
                  <i aria-hidden="true" class="iconsax-arrow-direction-up"></i>
                </button>
              </span>
              </div>
              <b-collapse :id="'collapse-beneficiary' + index" class="body" :visible="beneficiary.update">
                <div class="row" v-if="beneficiary.update">
                  <div class="col-12 m-b-30">
                    <label class="f-14 c-text_title poppins-font-semibold f-600 d-block m-b-20">
                      {{ $t('myAccount.beneficiaries.list.selectAvatar') }}
                    </label>
                    <div class="security-measures-img">
                      <div class="security-img-card c-pointer"
                           v-for="(image, indexImg) in images"
                           :key="image.id"
                           :class="beneficiary.imageIndex === indexImg ? 'selected': beneficiary.img ? 'gray' : ''"
                           @click="selectImage(image.id, indexImg, beneficiary)"
                      >
                        <i
                            aria-hidden="true"
                            class="iconsax-essential-tick security-icon-selected"
                        ></i>
                        <img
                            class="img-fluid security-img-cover"
                            :src="image.src"
                            alt="Imagen seguridad"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-12">
                    <BasicInput
                        required
                        inputId=""
                        v-model="beneficiary.name"
                        :validations="getErrorV(index, 'name')"
                        :label="$t('myAccount.beneficiaries.list.name')"
                        :inputPlaceholder="$t('myAccount.beneficiaries.list.namePh')">
                    </BasicInput>
                  </div>
                  <div class="col-12 col-md-6">
                    <BasicSelect
                        required
                        :label="$t('myAccount.beneficiaries.list.relationship')">
                      <multiselect
                          v-model="beneficiary.relationship"
                          :options="optionsParentesco"
                          :close-on-select="true"
                          :preserve-search="true"
                          :placeholder="$t('myAccount.beneficiaries.list.relationshipPh')"
                          label="name"
                          track-by="name"
                          :preselect-first="false">
                        <template
                            slot="selection"
                            slot-scope="{ values, search, isOpen }">
                          <span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                          >
                            {{ values.length }} options selected
                          </span>
                        </template>
                      </multiselect>
                    </BasicSelect>
                  </div>
                  <div class="col-12 col-md-6">
                    <BasicInput
                        required
                        inputId=""
                        v-model="beneficiary.phone"
                        :validations="getErrorV(index, 'phone')"
                        :label="$t('myAccount.beneficiaries.list.phone')"
                        :inputPlaceholder="$t('myAccount.beneficiaries.list.phonePh')">
                    </BasicInput>
                  </div>
                  <div class="col-12">
                    <BasicInput
                        required
                        inputId=""
                        v-model="beneficiary.email"
                        :validations="getErrorV(index, 'email')"
                        :label="$t('myAccount.beneficiaries.list.email')"
                        :inputPlaceholder="$t('myAccount.beneficiaries.list.emailPh')">
                    </BasicInput>
                  </div>
                  <div class="col-12">
                    <button id=""
                            type="button"
                            class="button button-filled button-primary w-100"
                            :disabled="getDisabled(index)"
                            @click="saveBen(index)"
                    >
                      {{$t('myAccount.beneficiaries.list.saveBeneficiary')}}
                    </button>
                  </div>
                </div>
                <div class="row" v-if="!beneficiary.update">
                  <div class="col-12 col-md-6">
                    <ReadOnlyInfo
                        :title="$t('myAccount.beneficiaries.list.name')"
                        :text="beneficiary.name"
                    ></ReadOnlyInfo>
                  </div>
                  <div class="col-12 col-md-6">
                    <ReadOnlyInfo
                        :title="$t('myAccount.beneficiaries.list.relationship')"
                        :text="beneficiary.relationship.name"
                    ></ReadOnlyInfo>
                  </div>
                  <div class="col-12 col-md-6">
                    <ReadOnlyInfo
                        :title="$t('myAccount.beneficiaries.list.phone')"
                        :text="beneficiary.phone"
                    ></ReadOnlyInfo>
                  </div>
                  <div class="col-12 col-md-6">
                    <ReadOnlyInfo
                        :title="$t('myAccount.beneficiaries.list.email')"
                        :text="beneficiary.email"
                    ></ReadOnlyInfo>
                  </div>
                  <div class="col-12">
                    <div class="control-buttons buttons-full-w buttons-full-w-xs">
                      <button
                          type="button"
                          class="button button-ghost button-error w-100"
                          @click="openDelete(index)"
                      >
                        {{ $t('myAccount.beneficiaries.list.deleteBeneficiary') }}
                      </button>
                      <button
                          type="button"
                          class="button button-stroke button-primary w-100"
                          @click="editBen(index)"
                      >
                        {{ $t('myAccount.beneficiaries.list.editBeneficiary') }}
                      </button>
                    </div>
                  </div>
                </div>
              </b-collapse>
            </div>
          </div>
          <button
              id="addBeneficiary"
              type="button"
              class="button-square-add"
              @click="addBen"
          >
            <i aria-hidden="true" class="iconsax-essential-add m-r-10"></i>
            {{$t('myAccount.beneficiaries.addBeneficiary')}}
          </button>
        </div>

        <div class="col-12">
          <div class="control-buttons">
            <button
                type="button"
                class="button-icon"
                @click="$bvModal.show('modal-back-beneficiaries')"
            >
              <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
              {{$t('myAccount.beneficiaries.back')}}
            </button>
            <button
                v-if="showSave"
                type="button"
                class="button button-filled button-primary"
                :disabled="disableSave"
                @click="submit"
            >
              {{$t('myAccount.beneficiaries.save')}}
            </button>
            <button
                v-else
                type="button"
                class="button button-filled button-primary">
              <LoadingSimple clazz="small-load no-text" />
            </button>
          </div>
        </div>

      </div>

    </div>


    <ModalAlert
        closeCross
        modalId="modal-back-beneficiaries"
        buttonType="button-primary"
        :imgUrl="imageModalBack"
        :title="$t('myAccount.beneficiaries.modalBack.title')"
        :text="$t('myAccount.beneficiaries.modalBack.subtitle')"
        :buttonText="$t('myAccount.beneficiaries.modalBack.buttonConfirm')"
        v-on:submit="goBack"
    ></ModalAlert>

    <!-- Modal eliminar contacto -->
    <ModalAlert
        closeCross
        modalId="modal-delete-beneficiary"
        buttonType="button-primary"
        :imgUrl="imageModalTrash"
        :title="$t('myAccount.beneficiaries.modalDelete.title')"
        :text="$t('myAccount.beneficiaries.modalDelete.subtitle')"
        :buttonText="$t('myAccount.beneficiaries.modalDelete.buttonConfirm')"
        v-on:submit="deleteBen(indexDelete)"
    ></ModalAlert>

  </div>
</template>
<script>
// @ is an alias to /src
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import TitleSteps from "@/components/TitleSteps.vue";
import Tachometer from "@/components/Tachometer.vue";
import BeneficiaryInfo from "@/components/BeneficiaryInfo.vue";
import InputEdit from "@/components/InputEdit.vue";
import BasicInput from "@/components/BasicInput.vue"
import BasicSelect from "@/components/BasicSelect.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import Multiselect from "vue-multiselect";
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
import imageModalTrash from '@/assets/images/Icons/icon-trash-pink.svg';
import imageModalBack from '@/assets/images/Icons/icon-user-pink.svg';
import imgBeneficiario from '@/assets/images/Backgrounds/Beneficiary/hombre-naranja.svg';
import ModalAlert from "@/components/ModalAlert";
import {mapGetters} from "vuex";
import {GET_ACCOUNT, SET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";
import {beneficiariesImages} from "@/enums/securityBeneficiariesImg";
import {between, email, maxLength, minLength, required} from "vuelidate/lib/validators";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import UsersApi from "@/api/users/users.api";
import {catchError} from "@/utils/utils";
import {EventBus} from "@/main";

const each = "$each";

export default {
  name: "AddBeneficiaries",
  mixins: [ToastSnotify],
  components: {
    FloatingTitleLayout,
    TitleSteps,
    Tachometer,
    BeneficiaryInfo,
    InputEdit,
    BasicInput,
    BasicSelect,
    Multiselect,
    ReadOnlyInfo,
    ModalAlert,
    LoadingSimple,
    LoadingBar,
  },
  computed: {
    ...mapGetters(sessionStore, {
      infoAccount: GET_ACCOUNT,
    }),
    disableSave() {
      if (this.$v.beneficiaries.$invalid || this.beneficiaries.length <= 0 || this.currentPercentage < 100 || this.currentPercentage > 100) {
          return true;
      }
      return this.beneficiaries.some((a) => a.update);
    }
  },
  data() {
    return {
      currentPercentage: 0,
      // Images
      imageModalTrash: imageModalTrash,
      imageModalBack: imageModalBack,
      imgBeneficiario: imgBeneficiario,
      images: beneficiariesImages,
      // SidebarRight / MenuPrincipal
      window: {
        width: 0
      },
      isMenuOpen: false,
      // Multiselect
      valueParentesco: [],
      optionsParentesco: [
        {name: this.$i18n.t('myAccount.beneficiaries.relationship.son'), value: "SON"},
        {name: this.$i18n.t('myAccount.beneficiaries.relationship.daughter'), value: "DAUGHTER"},
        {name: this.$i18n.t('myAccount.beneficiaries.relationship.father'), value: "FATHER"},
        {name: this.$i18n.t('myAccount.beneficiaries.relationship.mother'), value: "MOTHER"},
        {name: this.$i18n.t('myAccount.beneficiaries.relationship.brother'), value: "BROTHER"},
        {name: this.$i18n.t('myAccount.beneficiaries.relationship.sister'), value: "SISTER"},
        {name: this.$i18n.t('myAccount.beneficiaries.relationship.spouse'), value: "SPOUSE"},
        {name: this.$i18n.t('myAccount.beneficiaries.relationship.grandfather'), value: "GRANDFATHER"},
        {name: this.$i18n.t('myAccount.beneficiaries.relationship.grandmother'), value: "GRANDMOTHER"}
      ],
      mode: "new",
      beneficiaries: [],
      showSave: true,
      indexDelete: -1,
      loadingInfo: true,
    }
  },
  methods: {
    goBack() {
      this.$router.push({ name: "MyAccount", query: { url: 'beneficiaries' } })
    },
    addBen() {
      const reminder = 100 - this.currentPercentage;
      if (reminder <=0) {
          this.displayNotificationWarning(this.$i18n.t('myAccount.beneficiaries.errors.noMore'))
      } else {
        this.beneficiaries.push({
          name: "",
          percentage: reminder,
          relationship: "",
          email: "",
          phone: "",
          pagandoAccount: "",
          img: "",
          imageIndex: -1,
          deleted: {
            isDeleted: false
          },
          update: true
        })
      }
    },
    selectImage(id, index,beneficiary){
      if (beneficiary.imageIndex >= 0 && beneficiary.imageIndex === index) {
        beneficiary.imageIndex = -1;
        beneficiary.img = "";
      } else {
        beneficiary.img = id;
        beneficiary.imageIndex = index;
      }
    },
    getErrorV(index, param) {
      return this.$v.beneficiaries[each][index][param];
    },
    getDisabled(index) {
      return this.$v.beneficiaries[each][index].$invalid
    },
    openDelete(index) {
      this.indexDelete = index;
      this.$bvModal.show('modal-delete-beneficiary');
    },
    deleteBen(index) {
      if (index > -1) {
        this.beneficiaries.splice(index, 1);
      }
      this.doPercentage();
      this.$bvModal.hide('modal-delete-beneficiary');
    },
    editBen(index) {
      this.beneficiaries[index].update = true;
    },
    saveBen(index) {
      this.doPercentage();
      if (this.currentPercentage > 100) {
        this.displayNotificationWarning(this.$i18n.t('myAccount.beneficiaries.errors.percentageExceeded'));
      } else {
        this.beneficiaries[index].pagandoAccount = this.infoAccount.account._id;
        this.beneficiaries[index].update = false
      }
    },
    doPercentage() {
      this.currentPercentage = this.beneficiaries.reduce((a, b) => a + (b.deleted.isDeleted ? 0 : parseFloat(b.percentage) ), 0);
    },
    async submit() {
      if(await this.$requestAuth()) {
        this.showSave = false;
        if (this.currentPercentage === 100) {
          const res = await UsersApi.saveBeneficiaryAsList({
            beneficiaries: this.beneficiaries
          }).catch(catchError);
          if (res.data && res.data.error) {
              this.displayNotificationError(this.$i18n.t('myAccount.beneficiaries.errors.saving'))
          } else {
            this.goBack();
          }
        }
        this.showSave = true;
      }
    },
    async loadBeneficiaries() {
      if (this.infoAccount.account._id && this.mode === 'edit') {
        const res = await UsersApi.beneficiaryList({
          pagandoAccount: this.infoAccount.account._id
        })
        if (res.data && !res.data.error && res.data.object.length) {
          const beneficiaries = res.data.object;
          for (const beneficiary of beneficiaries) {
            beneficiary.imageIndex = beneficiariesImages.findIndex((i) => beneficiary.img === i.id);
            beneficiary.relationship = this.optionsParentesco.find((p) => p.value === beneficiary.relationship);
            beneficiary.update = false;
          }
          this.beneficiaries = beneficiaries;
          this.doPercentage();
        }
        this.loadingInfo = false;
      }
    }
  },
  created() {
    this.mode = this.$route.params.mode;
    if (this.mode === "new") {
        this.loadingInfo = false;
    }
  },
  beforeMount() {
    if (this.mode === 'edit') {
      this.loadBeneficiaries();
    }
    EventBus.$on(`${sessionStore}.${SET_ACCOUNT}`, this.loadBeneficiaries);
  },
  beforeDestroy() {
    EventBus.$off(`${sessionStore}.${SET_ACCOUNT}`, this.loadBeneficiaries);
  },
  validations: {
    beneficiaries: {
      $each: {
        email: {
          required,
          email
        },
        name: {
          required,
          minLength: minLength(3),
        },
        phone: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        percentage: {
          required,
          between: between(1, 100)
        },
        relationship: {
          required
        },
        img: {
          required
        }
      }
    },
  }
};
</script>
