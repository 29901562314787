<template>
  <div>
    <div v-if="showLoading">
      <LoadingSimple
          clazz="big-load p-t-50 p-b-50"
          label="Loading..."
      ></LoadingSimple>
    </div>
    <div v-else>
      <TitlePrincipal :title="$t('register.selectDocuments.title')">
        <p class="subtitle" v-html="$t('register.selectDocuments.subtitle')"></p>
      </TitlePrincipal>
      <div v-for="(group, index) in groupFile" v-bind:key="group._id">
        <CardSelection :title="group.name"
                       :subtitle="group.description"
                       :clazz="getColor(index)">
          <template v-slot:icon>
            <i aria-hidden="true" class="banana banana-profile"></i>
          </template>
          <template v-slot:extraMiddleContent v-if="hasFM(group.name, group.description)">
            <a href="http://dof.gob.mx/nota_detalle_popup.php?codigo=2064173"
               class="f-14 c-info body-font-bold text-underline d-block m-t-5 c-pointer"
               tabindex=""
               target="_blank">
              ¿Que es una forma FM2 y FM3?
            </a>
          </template>
          <template v-slot:extraFinalContent>
            <button type="button" class="button-circle button-ghost button-info_text" aria-label="Ir a" name="Ir a"
                    @click="selectType(group._id)">
              <i aria-hidden="true" class="banana banana-chevron-right"></i>
            </button>
          </template>
        </CardSelection>
      </div>
      <div class="footer-buttons right">
        <button type="button" class="button button-ghost button-plain_text" name="Regresar" @click="back()"> {{ $t('register.nationality.back') }} </button>
      </div>
    </div>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import CardSelection from "@/components/CardSelection";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import LoadingSimple from "@/components/LoadingSimple";
import {mapGetters} from 'vuex';
import {
  GET_GROUP_FILES, GET_TYPE_ACCOUNT, LOAD_GROUP_FILES, GET_CARD_REQUEST,
  GET_ACCOUNT_TYPE_BUSINESS,
  GET_LEVEL,
  STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {GET_FULL_DATA_USER, STORE as sessionStore} from "@/store/modules/session/session.types";
import {randomIntFromInterval} from "@/utils/utils";

export default {
  name: "SelectDocument",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    CardSelection,
    LoadingSimple
  },
  computed: {
    ...mapGetters(sessionStore, {
      fullDataUser: GET_FULL_DATA_USER
    }),
    ...mapGetters(onbngStore, {
      groupFile: GET_GROUP_FILES,
      typeAccount: GET_TYPE_ACCOUNT,
      cardRequest: GET_CARD_REQUEST,
      isMoral2: GET_ACCOUNT_TYPE_BUSINESS,
      level: GET_LEVEL,
    })
  },
  methods: {
    back() {
      if(this.level === 0) {
        this.$store.dispatch(`${onbngStore}/backCollaborator`);
      } else if(this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${onbngStore}/backBusiness`);
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`);
      }
    },

    getGotoIfNotAccount: function (goTo) {
      if (!this.typeAccount) {
        if (this.$route.name === 'selectDocumentsMultiple') {
          goTo = "businessUploadDocumentsMultiple";
        } else {
          if (this.$route.name === 'businessSelectDocumentsMultiple') {
            goTo = "businessUploadDocumentsMultiple";
          } else {
            goTo = "businessUploadDocuments";
          }
        }
      }
      return goTo;
    }, async selectType(id) {
      this.showLoading = true;
      const res = await this.$store.dispatch(`${onbngStore}/getFilesDocuments`, {key: id});
      if (res) {
        let goTo = "uploadDocuments"
        if(this.$route.name === 'selectDocumentsMultiple') {
          goTo = "uploadDocumentsMultiple";
        }
        goTo = this.getGotoIfNotAccount(goTo);
        if(this.level === 0) {
          goTo = "uploadDocumentsCollaborator";
        }
        // NO USAR función STORE GO_TO_ROUTE
        this.$router.push({name: goTo, params: {id: id}});
      } else {
        this.displayNotificationError(this.$i18n.t('register.selectDocuments.error'))
      }
      this.showLoading = false;
    },
    getColor(index) {
      if (index < this.clazzColor.length) {
          return this.clazzColor[index];
      }
      return this.clazzColor[randomIntFromInterval(0,this.clazzColor.length - 1)];
    },
    hasFM(name, description) {
      return /fm[2-3]/.test(name.toLowerCase()) || /fm[2-3]/.test(description.toLowerCase());
    },
    async getDataLoad() {
      let nationality;
      let processWhereApply;
      let residence;

      if(this.$route.name === 'selectDocumentsMultiple') {
        nationality = this.fullDataUser.nationalitieOnboarding._id;
        const level = this.cardRequest.information_level === 'LEVEL_THREE' ? 'ONBOARDING_LEVEL_3' : 'ONBOARDING_LEVEL_2';
        processWhereApply = level;
        residence = this.fullDataUser.countryNewOnboarding._id;
      } else if (this.$route.name === 'businessSelectDocumentsMultiple') {
        nationality = this.fullDataUser.nationalitieOnboarding._id;
        processWhereApply = 'ONBOARDING_LEVEL_3';
        residence = this.fullDataUser.countryNewOnboarding._id;
      } else {
        nationality = localStorage.getItem(btoa("nationality")) || this.fullDataUser?.nationalitieOnboarding?._id;
        const level = this.cardRequest.information_level === 'LEVEL_THREE' ? 'ONBOARDING_LEVEL_3' : 'ONBOARDING_LEVEL_2';

        // processWhereApply = localStorage.getItem(btoa("processWhereApply"));
        // if(!processWhereApply) {
          processWhereApply = level;
        // }
        residence = localStorage.getItem(btoa("residence")) || this.fullDataUser?.countryNewOnboarding?._id;
      }

      await this.$store.dispatch(`${onbngStore}/${LOAD_GROUP_FILES}`, {
        nationality: nationality,
        processWhereApply: processWhereApply,
        residence: residence,
        cardRequest: this.cardRequest?._id,
        appliesToClientAccount: this.cardRequest?.accountType === 'BUSINESS'
      });
    }
  },
  data() {
    return {
      showLoading: true,
      clazzColor: [
        "icon-bgm-accent2",
        "icon-bgm-pink",
        "icon-bgm-warning"
      ],
      isMoral: null
    }
  },
  async beforeMount() {

    await this.$store.dispatch(`${sessionStore}/getFullDataByLevel`, {level: this.cardRequest.information_level});

    if(this.isMoral2) {
      this.isMoral = this.isMoral2 === 'MORAL';
    }

    if(this.$route.name === 'selectDocumentsMultiple') {
      this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_PERSONAL');
    } else if(this.$route.name === 'businessSelectDocumentsMultiple') {
      await this.$store.dispatch(`${onbngStore}/putLevel`, this.isMoral ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC');
    }
    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
    await this.getDataLoad();
    this.showLoading = false;
  }
}
</script>

<style scoped>

</style>
