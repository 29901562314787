import { render, staticRenderFns } from "./SuccessRequest.vue?vue&type=template&id=45bec542&scoped=true"
import script from "./SuccessRequest.vue?vue&type=script&lang=js"
export * from "./SuccessRequest.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45bec542",
  null
  
)

export default component.exports