import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'reward-points/api-v1';

export default {
    getDetails: (id, org) => {
        let url = `${base.baseUrlWallet}/${namespace}/get-details/${id}`;

        if(org) {
            url = `${base.baseUrlWallet}/${namespace}/get-details/${id}?moralOrg=${org}`;
        }
        return axios.get(url);
    },
    spendPoints: (id, params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/spend-points/${id}`, params);
    }
}
