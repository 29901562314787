import axios from 'axios';
import base from '@/api/base.api';
const namespaceSignOrg = 'sign-up-organization';
const namespaceOrg = 'organizations';

export default {
    getOccupations: (params) => {
        return axios.get(`${base.baseUrl}/${namespaceSignOrg}/retrieve/occupations`, params);
    },
    dataTransferSend: (params) => {
        return axios.post(`${base.baseUrl}/${namespaceOrg}/data-transfer-send`, params).then(({data: {object}}) => object);
    },
    documentSend: (params) => {
        return axios.post(`${base.baseUrl}/${namespaceOrg}/document-send`, params).then(({data: {object}}) => object);
    },
    hireStatusSend: (params) => {
        return axios.post(`${base.baseUrl}/${namespaceOrg}/hire-status-send`, params).then(({data: {object}}) => object);
    }
}
