export default {
  data() {
    return {
      // SidebarRight / MenuPrincipal
      window: {
        width: 0
      },
      isMenuOpen: false,
    }
  },
  methods: {
    // Resize Sidebar Menu Principal
    handleResizeMenu() {
      this.window.width = window.innerWidth;
      if (window.innerWidth > 767) {
        this.isMenuOpen = true;
      } else {
        this.isMenuOpen = false;
      }
    },
    // Show Sidebar Menu Principal
    showMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    // Hide Sidebar Menu Principal
    hideMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

  },
  mounted() {
    this.handleResizeMenu();
    window.addEventListener('resize', this.handleResizeMenu);
  },
  destroyed() {
    window.addEventListener('resize', this.handleResizeMenu);
  },
}
