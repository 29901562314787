<template>
  <div>
    <div class="register-container">
      <div class="left-side-content">
        <div class="container-left">
          <img
            class="img-fluid logo"
            src="@/assets/images/Logos/logo-color.svg"
            alt="Logo Pagando"
          />
          <div class="progress-percent-title">
            <h2 class="title">Actualización de Contrato</h2>
          </div>

          <div class="vertical-steps-container">
            <ul class="vertical-steps">
              <li
                v-for="document in documentsToSign"
                v-bind:key="document._id"
              >
                <a
                  href="javascript:void(0)"
                  class="step"
                  @click="getContractSelected(document)"
                  :disabled="showLoading"
                  v-bind:class="[
                    document.pagandoAccount === selectedAccountContract?.account
                      ? 'current'
                      : '',
                  ]"
                  tabindex=""
                >
                  <a> Cuenta {{ document?.account }} </a>
                </a>
              </li>
            </ul>
            <button
              v-if="user.contract === 2 || user.contract === 4"
              type="button"
              class="button xs button-stroke button-primary m-t-0 ml-auto mr-auto d-xl-none"
              name="Cerrar sesion"
              @click="logout()"
            >
              Cerrar Sesión
            </button>
          </div>

          <div class="footer-help">
            <a href class="help-chat" tabindex="" @click.prevent="openZendesk">
              <img
                class="img-fluid"
                src="@/assets/images/Illustrations/illustration-chat-help.svg"
                alt="Chat de soporte"
              />
            </a>
            <p class="help-num">
              También puedes contactarnos a los teléfonos:
              <br />
              <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
            </p>
            <button
              v-if="user.contract === 2 || user.contract === 4"
              type="button"
              class="button button-stroke button-primary m-t-30 ml-auto mr-auto"
              name="Cerrar sesion"
              @click="logout()"
            >
              Cerrar Sesión
            </button>
          </div>
        </div>
      </div>
      <div class="right-side-content">
        <div>
          <div v-if="showLoading">
            <LoadingSimple
              clazz="big-load p-t-50 p-b-50"
              label="Loading..."
            ></LoadingSimple>
          </div>
          <div
            v-if="
              !showLoading &&
              !showSignSectionBool &&
              !showSuccessMessage &&
              !showLiveness &&
              !selectedAccountContract.waitingAuth
            "
          >
            <TitlePrincipal
              :title="`Actualización de Contrato ${selectedAccountContract.accountName}`"
            >
              <p
                class="subtitle"
                v-html="
                  ` A continuación puedes <strong>leer el nuevo contrato</strong> para continuar con los beneficios de tu cuenta.`
                "
              ></p>
            </TitlePrincipal>
            <div class="text-contrato" v-html="parsedHtml"></div>
            <b-alert class="alert-note" show variant="accent2">
              <p class="text-container">
                <i aria-hidden="true" class="icon banana banana-comment"></i>
                <span
                  v-html="
                    `Una copia de este contrato será enviada a tu correo electrónico <strong>${user.email}</strong>.`
                  "
                ></span>
              </p>
            </b-alert>
            <div class="footer-buttons right d-flex">
              <button
                v-if="showButton && (user.contract === 1 || user.contract === 3)"
                type="button"
                class="button button-stroke button-primary m-r-30"
                name="Aceptar contrato"
                @click="openModal()"
              >
                Lo hare después
              </button>
              <button
                v-if="showButton"
                type="button"
                class="button button-filled button-primary"
                name="Aceptar contrato"
                @click="showSignSection()"
              >
                {{ $t("register.contract.accept") }}
              </button>
              <button
                v-if="!showButton"
                :id="'Loading button'"
                class="button button-filled button-primary"
              >
                <LoadingSimple clazz="small-load no-text" />
              </button>
            </div>
          </div>
          <div
            v-if="
              !showLoading &&
              showSignSectionBool &&
              !showSuccessMessage &&
              !showLiveness &&
              !selectedAccountContract.waitingAuth
            "
          >
            <TitlePrincipal
              :title="$t('register.signature.title')"
              :subtitle="$t('register.signature.subtitle')"
            >
            </TitlePrincipal>
            <span class="vertical-center m-b-10">
              <p class="f-18 c-primary_1 poppins-font-semibold d-block m-b-5">
                {{ $t("register.signature.instruction") }}
              </p>
              <button
                type="button"
                class="button xs button-stroke button-primary m-0-0-0-auto"
                @click="undoSignature"
              >
                {{ $t("register.signature.deleteSign") }}
              </button>
            </span>
            <div>
              <VueSignaturePad
                id="signaturePad"
                width="100%"
                height="300px"
                ref="signaturePad"
                :options="optionsPad"
              />
            </div>
            <div class="footer-buttons right d-flex m-t-30">
              <button
                @click="back(selectedAccountContract.isMoral ? 3 : 1)"
                type="button"
                class="button button-ghost button-plain_text"
                name="Regresar"
              >
                {{ $t("register.signature.back") }}
              </button>
              <button
                v-if="showButton"
                type="button"
                class="button button-filled button-primary"
                name="Continuar"
                @click="save"
              >
                Firmar
              </button>
              <button
                v-else
                :id="'Loading button'"
                class="button button-filled button-primary"
              >
                <LoadingSimple clazz="small-load no-text" />
              </button>
            </div>
          </div>
          <div
            v-if="
              !showLoading &&
              showLiveness &&
              !showSuccessMessage &&
              !selectedAccountContract.waitingAuth
            "
          >
            <TitlePrincipal
              title="Prueba de vida"
              subtitle="Necesitamos que el representante legal realice una prueba de vida."
            >
            </TitlePrincipal>

            <DynamicDocs
              :docs="livenessDoc"
              @add-file="addFileLiveness"
              :blockLiveness="false"
              @beforeMount="reloadDocsLiveness"
            ></DynamicDocs>

            <div class="footer-buttons right d-flex m-t-30">
              <button
                @click="back(2)"
                type="button"
                class="button button-ghost button-plain_text"
                name="Regresar"
              >
                {{ $t("register.signature.back") }}
              </button>
              <button
                v-if="showNextLiveness"
                type="button"
                class="button button-filled button-primary"
                name="Continuar"
                @click="
                  showSignSectionBool = true;
                  showLiveness = false;
                "
              >
                {{ $t("register.signature.next") }}
              </button>
            </div>
          </div>
          <div
            v-if="
              !showLoading &&
              showSuccessMessage &&
              !selectedAccountContract.waitingAuth
            "
          >
            <TitlePrincipal
              clazz="text-align-c"
              title="Tu contrato ha sido firmado exitosamente!"
              :subtitle="getMessageToShowAfterSign()"
            >
            </TitlePrincipal>
            <img
              class="img-fluid max-h-300px d-flex m-0-auto"
              src="@/assets/images/Illustrations/illustration-bienvenido-pagando.svg"
              alt="Bienvenido a Pagando"
            />
            <div class="footer-buttons center horizontal-center gap-10 m-t-20">
              <button
                v-if="showButton && user.contract === 0"
                type="button"
                class="button button-filled button-primary md"
                name="Ir a mi cuenta"
                @click="goHome(true)"
              >
                {{ $t("register.welcome.next") }}
              </button>
              <button
                v-if="
                  showButton && (user.contract === 1 || user.contract === 3)
                "
                type="button"
                class="button button-stroke button-primary md"
                name="Ir a mi cuenta"
                @click="openModal()"
              >
                Lo hare después
              </button>
              <button
                v-if="
                  showButton &&
                  user.contracto !== 0 && documentsToSign.length > 1
                "
                type="button"
                class="button button-filled button-primary"
                name="Ir a mi cuenta"
                @click="showContract()"
              >
                Firmar nuevo contrato
              </button>
            </div>
          </div>
          <div v-if="!showLoading && selectedAccountContract.waitingAuth">
            <TitlePrincipal
              clazz="text-align-c"
              title="Tu contrato esta en revisión"
              :subtitle="getMessageToShowWaiting()"
            >
            </TitlePrincipal>
            <img
              class="img-fluid max-h-300px d-flex m-0-auto"
              src="@/assets/images/Illustrations/illustration-modal-generic.svg"
            />
            <div class="footer-buttons center horizontal-center gap-10 m-t-50">
              <button
                v-if="
                  showButton && (user.contract === 1 || user.contract === 3)
                "
                type="button"
                class="button button-stroke button-primary md"
                name="Ir a mi cuenta"
                @click="openModal()"
              >
                {{ $t("register.welcome.next") }}
              </button>
              <button
                v-if="
                  showButton &&
                  user.contracto !== 0 && documentsToSign.length > 1
                "
                type="button"
                class="button button-filled button-primary"
                name="Ir a mi cuenta"
                @click="showContract()"
              >
                Firmar nuevo contrato
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ModalAlert
      ref="warning-time"
      modalId="warning-time"
      buttonType="button-primary"
      :imgUrl="imageModalGeneric"
      title="¿Estas seguro?"
      text="Tienes 30 dias a partir de que se notificó el cambio de contrato para firmar."
      buttonText="Firmar Contrato"
      :closeCross="true"
      buttonCancel="Lo hare después"
      :noCancelButton="false"
      :closeEsc="true"
      :closeBackdrop="true"
      @submit="$bvModal.hide('warning-time')"
      @cancel="goHome()"
    ></ModalAlert>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import LoadingSimple from "@/components/LoadingSimple";

import {
  STORE as SESSION_STORE,
  GET_USER,
  SET_USER,
} from "@/store/modules/session/session.types";
import {
  STORE as ShareholderStore,
  LOAD_PHYSICAL_DOCS,
  GET_PHYSICAL_DOCS,
} from "@/store/modules/shareholders/shareholders.types";
import { mapGetters } from "vuex";
import DocumentsApi from "@/api/core/documents.api";
import { catchError } from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import DocumentApi from "@/api/core/documents.api";
import ShareholderApi from "@/api/core/shareholders.api";
import imageModalGeneric from "@/assets/images/Illustrations/illustration-modal-generic.svg";
import ModalAlert from "@/components/ModalAlert.vue";
import VueSignaturePad from "vue-signature-pad";
import UserApi from "@/api/users/users.api";
import DynamicDocs from "@/components/DynamicDocs";
import ShareholdersApi from "@/api/core/shareholders.api";
import {EventBus} from "@/main";

export default {
  name: "UpdateContract",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    LoadingSimple,
    ModalAlert,
    VueSignaturePad,
    DynamicDocs,
    // ProgressCircle,
  },
  computed: {
    ...mapGetters(SESSION_STORE, {
      user: GET_USER,
    }),
    ...mapGetters(ShareholderStore, {
      requiredDocs: GET_PHYSICAL_DOCS,
    }),
    livenessDoc() {
      return this.requiredDocs.filter(
        (d) => d.fileDocumentType === "IDENTIFICATION_LIVENESS"
      );
    },
    parsedHtml() {
      const parser = new DOMParser();
      const elem = parser.parseFromString(
        this.selectedAccountContract.contract,
        "text/html"
      );

      return elem.body.innerText;
    },
    showNextLiveness() {
      return this.showButton && this.livenessVideo !== null;
    },
  },
  data() {
    return {
      showButton: true,
      showLoading: true,
      contract: "",
      documentsToSign: [],
      contractsPreview: [],
      completedPercent: 0,
      diam: 84,
      totalPercent: 100,
      strokeW: 6,
      innerStrokeW: 3,
      selectedAccountContract: null,
      imageModalGeneric,
      showSignSectionBool: false,
      optionsPad: {
        penColor: "#000",
      },
      showSuccessMessage: false,
      showLiveness: false,
      livenessVideo: null,

      // showMessageNeedSign: false
    };
  },
  methods: {
      reloadDocsLiveness() {

        EventBus.$emit("LOAD-DYNAMIC-DOCS-COM", this.livenessVideo !== null ? ['ID-LIVENESS-ACC'] : []);
      },
    getMessageToShowAfterSign() {
      if (this.user.contract === 2 || this.user.contract === 4) {
        return "Aun tienes contratos que se requieren firmar.";
      } else if (this.user.contract === 1 || this.user.contract === 3) {
        return `Te recordamos que tienes contratos pendientes de firma y tienes un limite de 30 dias para firmarlos.`;
      }
      return "Tu contrato ha sido firmado.";
    },
    getMessageToShowWaiting() {
      if (
        (this.user.contract === 2 || this.user.contract === 4) &&
        this.documentsToSign.length > 1
      ) {
        return "Una vez que tu contrato haya sido revisado te notificaremos. Aun tienes contratos que se requieren firmar.";
      } else if (
        this.user.contract === 1 ||
        (this.user.contract === 3 && this.documentsToSign.length > 1)
      ) {
        return `Una vez que tu contrato haya sido revisado te notificaremos. Te recordamos que tienes contratos pendientes de firma y tienes un limite de 30 dias para firmarlos.`;
      }
      return "Una vez que tu contrato haya sido revisado te notificaremos.";
    },
    processImg(img) {
      if (/^data:image\/png;base64,/.test(img)) {
        return img.replace(/^data:image\/png;base64,/, "");
      }
      return img;
    },
    openModal() {
      this.$bvModal.show("warning-time");
    },
    logout() {
      localStorage.removeItem("currentToken");
      this.$store.commit(`${SESSION_STORE}/CLEAR_STORE`);
      this.$router.push({ name: "login" });
    },
    async goHome(removeItem = false) {
      if (removeItem) {
        localStorage.removeItem("s1");
      } else {
        localStorage.setItem("s1", 1);
      }
      this.$bvModal.hide("warning-time");
      await this.$store.dispatch(`${SESSION_STORE}/loadAccount`);
      this.$router.push({ name: "Dashboard" });
    },
    async getDocuments(document) {
      const response = await DocumentsApi.getPreContractFromPagandoAccount({
        appliesTo: "WALLET",
        pagandoAccount: document.pagandoAccount,
        document: document.document,
        documentType: document.documentType,
      }).catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(
            this.$i18n.t("register.contract.error")
          );
        } else {
          this.contractsPreview.push({
            document: document.document,
            account: document.pagandoAccount,
            accountName: document.account,
            contract: response.data.object.preDocumentAccepted.content,
            isMoral: document.isMoral,
            force: document.force,
            waitingAuth: document.waitingAuth,
            documentType: document.documentType,
            preDocument: response.data.object.preDocumentAccepted._id,
            org: document.org
          });
        }
      }
    },
    // reloadDocsLiveness() {
    //     EventBus.$emit("LOAD-DYNAMIC-DOCS-COM", this.livenessDoc());
    // },
    undoSignature() {
      this.$refs.signaturePad.undoSignature();
    },
    checkSignature() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      if (!isEmpty) {
        const img = this.processImg(data);
        return {
          name: `sign-legalRep-${this.selectedAccountContract.org}-${new Date().getTime()}`,
          mimetype: "png",
          size: img.length,
          data: img,
        };
      }
      return null;
    },
    async saveLegalData() {
      const signature = this.checkSignature();
      const params = {
        account: this.selectedAccountContract.account,
        preDocumentAccepted: this.selectedAccountContract.preDocument,
      };
      if (signature) {
        params.digitalSign = signature;
      } else {
        this.displayNotificationError(
          this.$i18n.t("register.signature.notSign")
        );
      }
      params.livenessVideo = this.livenessVideo;

      const response = await ShareholdersApi.saveLegal(params);

      if (response?.data?.error) {
        this.toastErrorDefault("Ocurrió un error al guardar información");
      } else {
        this.selectedAccountContract.waitingAuth = true;
      }
    },
    async save() {
      this.showButton = false;
      if (this.selectedAccountContract.isMoral) {
        await this.saveLegalData();
      } else {
        const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
        if (isEmpty) {
          this.displayNotificationError(
            this.$i18n.t("register.signature.notSign")
          );
        } else {
          const img = this.processImg(data);
          const response = await UserApi.saveDigitalSign({
            digitalSign: {
              name: `sign-${this.selectedAccountContract.account}-${this.user._id}`,
              type: "png",
              size: img.length,
              data: img,
            },
          }).catch(catchError);
          if (response && response.data) {
            if (response.data.error) {
              this.displayNotificationError(
                this.$i18n.t("register.signature.errorSave")
              );
            } else {
              await this.acceptContract();
            }
          }
        }
      }
      this.showButton = true;
    },
    async acceptContract() {
      const response = await DocumentApi.acceptContract({
        documentType: this.selectedAccountContract.documentType,
        appliesTo: "WALLET",
        accountId: this.selectedAccountContract.account,
        document: this.selectedAccountContract.document,
        profileType: "USER",
      }).catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(
            this.$i18n.t("register.signature.errorContract")
          );
        } else {
          await this.getUser();
          // if(this.user.docsContract.length > 1) {
          // await this.selectNewContractReady();
          // }
          this.showSignSectionBool = false;
          this.showSuccessMessage = true;
          // TODO desbloquear usuario
        }
      }
    },
    async showContract() {
      this.showSignSectionBool = false;
      this.showSuccessMessage = false;
      await this.selectNewContractReady();
    },
    async getUser() {
      const response = await UserApi.getCurrentUserSession({
        isForWallet: true,
      }).catch(catchError);

      if (response && response.data && !response.data.error) {
        this.$store.commit(`${SESSION_STORE}/${SET_USER}`, response.data.user);
      }
    },
    back(level) {
      if (level === 2) {
        this.showLiveness = false;
        this.showButton = true;
      } else if (level === 1) {
        this.showSignSectionBool = false;
        this.showButton = true;
      } else if (level === 3) {
        this.showSignSectionBool = false;
        this.showLiveness = true;
      }
    },
    async showSignSection() {
      if (this.selectedAccountContract.isMoral) {
        this.showLoading = true;
        await this.$store.dispatch(
          `${ShareholderStore}/${LOAD_PHYSICAL_DOCS}`,
          { showPassportDocs: false, showExtranjeroDocs: false }
        );
        this.showLoading = false;
        this.showLiveness = true;
      } else {
        this.showSignSectionBool = true;
      }
    },
    async getContractSelected(doc) {
      this.showLoading = true;
      const docPreview = this.contractsPreview.find(
        (c) => c.account === doc.pagandoAccount
      );
      if (!docPreview) {
        await this.getDocuments(doc);
      }
      this.selectedAccountContract = this.contractsPreview.find(
        (c) => c.account === doc.pagandoAccount
      );
      this.showLoading = false;
    },
    async selectNewContractReady() {
      this.showLoading = true;

      this.documentsToSign = this.user.docsContract;
      if (this.documentsToSign && this.documentsToSign.length > 0) {
        const documentToSign = this.documentsToSign.filter(d => !d.waitingAuth)[0];
        const docPreview = this.contractsPreview.find(
          (c) => ((c.account === documentToSign.pagandoAccount) && (c.document === documentToSign.document))
        );
        if (!docPreview) {
          await this.getDocuments(documentToSign);
        }
        this.selectedAccountContract = this.contractsPreview.find(
          (c) => ((c.account === documentToSign.pagandoAccount) && (c.document === documentToSign.document))
        );
      } else {
        this.selectedAccountContract = null;
      }
      this.showLoading = false;
    },
    async selectNewContract() {
      // this.showLoading = true;
      // this.showSuccessMessage = false;
      this.documentsToSign = this.user.docsContract;
      // await this.selectNewContract();
      // for await (const d of this.documentsToSign) {
      await this.getDocuments(this.documentsToSign[0]);
      // }
      this.selectedAccountContract = this.contractsPreview.find(
        (c) => c.account === this.documentsToSign[0].pagandoAccount
      );
      this.showLoading = false;
    },
    addFileLiveness(data) {
      this.livenessVideo = {};
      this.livenessVideo[data.key] = data.file;
    },
  },
  async mounted() {
      this.showLoading = true;

    await this.selectNewContract();
  },
};
</script>

<style scoped>
</style>
