<template>
  <div class="row">
    <!--  HEADERS  -->
    <div class="col-12">
      <router-link :to="{name: 'Contacts'}" class="button xs button-ghost button-text_info p-l-0 p-r-0" tabindex="">
        <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
        Regresar a Contactos
      </router-link>
      <FloatingTitleLayout titleStrong="Importar Contactos"
        subtitle="Importa múltiples contactos, fácil y rápido con solo cargar una plantilla. Ideal para importar una gran cantidad de contactos.">
        <template v-slot:buttons>
          <button type="button" class="button sm button-filled button-primary w-100"
            @click="$router.push({name: 'BulkContactsNew'})">
            <i aria-hidden="true" class="iconsax-content-document-upload m-r-10"></i>
            Subir Archivo
          </button>
        </template>
      </FloatingTitleLayout>
    </div>
    <div class="col-12" v-if="bulks.length === 0">
      <CardSimple clazz="p-0" hideTitle>
        <div class="basic-table">
          <b-table responsive :busy="true">
            <template v-slot:table-busy>
              <EmptyState emptyStateDefault title="Por el momento no has importado contactos con una plantilla"
                text="Aquí estará el historial con los detalles de los archivos que hayas subido." :imgWidth="520"
                :imgHeight="320" imgAlt="Por el momento no has importado contactos con una plantilla">
                <button type="button" class="button sm button-filled button-primary m-0-auto m-t-30"
                  @click="$router.push({name: 'BulkContactsNew'})">
                  <i aria-hidden="true" class="iconsax-content-document-upload m-r-10 f-24"></i>
                  Subir Archivo
                </button>
              </EmptyState>
            </template>
          </b-table>
        </div>
      </CardSimple>
    </div>
    <div class="col-12" v-else>
      <CardSimple clazz="p-0" hideTitle>
        <div id="menuOverflowFix" class="basic-table">
          <b-table-simple responsive selectable selected-variant="active" table-variant="xl">
            <b-thead>
              <b-tr class="">
                <b-th>Folio</b-th>
                <b-th class="text-center"> Fecha </b-th>
                <b-th class="text-center"> No. de Contactos </b-th>
                <b-th class="text-center"> Estatus </b-th>
                <b-th> </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr v-for="bulk in bulks" :key="bulk._id">
                <b-td>
                  <router-link :to="{name: 'BulkContactsDetails', params: {id: bulk._id}}"
                    class="hover poppins-font-bold c-info text-underline" tabindex="">
                    {{bulk.folio}}
                  </router-link>
                </b-td>
                <b-td class="text-center">{{moment(bulk.date).format('LLL')}}</b-td>
                <b-td class="text-center">{{bulk.totalContacts}}</b-td>
                <b-td class="text-center">
                  <Badge type="badge-error" text="Fallido"
                    v-if="['FAILED', 'COMPLETED_WITH_ERRORS'].includes(bulk.status)" />
                  <Badge type="badge-success" text="Terminado"
                    v-else-if="['SUCCESS'].includes(bulk.status)" />
                  <Badge type="badge-text_content" text="Pendiente de Autorizar"
                    v-else-if="['PENDING_AUTHORIZATION'].includes(bulk.status)" />
                  <Badge type="badge-warning" text="En proceso" v-else />
                </b-td>
                <b-td class="text-center">
                  <b-dropdown @show="showDropdown" @hide="hideDropdown" id="dropdown-table" right
                    class="dropdown-table">
                    <template v-slot:button-content>
                      <button type="button" class="button-square button-filled button-primary xs" aria-label="" name="">
                        <i aria-hidden="true" class="iconsax-essential-more-vertical-dots"></i>
                      </button>
                    </template>
                    <b-dropdown-group header="Acciones">
                      <b-dropdown-item @click="$router.push({name: 'BulkContactsDetails', params: {id: bulk._id}})">
                        <i aria-hidden="true" class="iconsax-security-eye"></i>
                        Ver detalles
                      </b-dropdown-item>
                      <b-dropdown-item @click="authorize(bulk)" v-if="canAuthorize">
                        <i aria-hidden="true" class="iconsax-essential-tick"></i>
                        Autorizar
                      </b-dropdown-item>
                      <b-dropdown-item @click="downloadFile(bulk)">
                        <i aria-hidden="true" class="iconsax-content-document-download"></i>
                        {{bulk.status === 'COMPLETED_WITH_ERRORS' ? 'Descargar Resumen' : 'Descargar Archivo'}}
                      </b-dropdown-item>
                    </b-dropdown-group>
                  </b-dropdown>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </CardSimple>

      <div class="pagination-custom">
        <span class="pagination-count">
          {{pagination.totalDocs || 0}} Archivo{{pagination.totalDocs ? 's' : ''}}
        </span>
        <b-pagination v-model="currentPage" :total-rows="pagination.totalDocs" :per-page="perPage"
          aria-controls="my-table" next-text="" prev-text="" hide-goto-end-buttons @change="pageChange"></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import Badge from "@/components/Badge.vue";
import CardSimple from "@/components/CardSimple.vue";
import EmptyState from "@/components/EmptyState.vue";
import FloatingTitleLayout from "@/components/FloatingTitleLayout";
import { mapGetters } from "vuex";

import moment from 'moment';
import ContactsApi from "@/api/wallet/contacts.api";

import {
  STORE as sessionStore,
  CHECK_PERMISSION,
  GET_ACCOUNT,
} from "@/store/modules/session/session.types";

export default {
  name: 'BulkContacts',
  components: {
    Badge,
    CardSimple,
    EmptyState,
    FloatingTitleLayout,
  },
  data() {
    return {
      bulks: [],
      loading: false,
      pagination: {},
      currentPage: 1,
      perPage: 8,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
            access: GET_ACCOUNT
        }),
    canAuthorize() {
      const checkPermission = this.$store.getters[`${sessionStore}/${CHECK_PERMISSION}`];
      const productType = this.access?.account?.accountType?.productType;
      return productType === 'CLIENTS_ACCOUNT' && checkPermission('AUTHORIZE', 'BENEFICIARIES');
    }
  },
  methods: {
    moment,
    showDropdown() {
      document.getElementById("menuOverflowFix").classList.toggle('dropdown-table-overflow');
    },
    hideDropdown() {
      document.getElementById("menuOverflowFix").classList.toggle('dropdown-table-overflow');
    },
    pageChange(page) {
      this.currentPage = page;
      this.loadBulks();
    },
    async loadBulks() {
      this.loading = true;
      try {
        const response = await ContactsApi.listBulks({page: this.currentPage, perPage: this.perPage});
        const { docs, ...pagination } = response.data.object;
        this.bulks = docs;
        this.pagination = pagination;
      } catch (error) {
        this.toastErrorDefault();
      }
      this.loading = false;
    },
    async authorize(bulk) {
      this.loading = true;
      try {
        await ContactsApi.authorizeBulk({id: bulk._id});
        this.$router.push({name: 'BulkContactsDetails', params: {id: bulk._id}});
      } catch (error) {
        this.toastErrorDefault();
      }
      this.loading = false;
    },
    async downloadFile(bulk) {
      const res = await ContactsApi.downloadBulk({layoutId: bulk._id})
      if (res.data && !res.data.error) {
        const temp = `data:application/vnd.ms-excel;base64,${encodeURIComponent(res.data.object)}`;
        const download = document.createElement('a');
        download.href = temp;
        download.download = res.filename || "Contactos";
        document.body.appendChild(download);
        download.click();
        document.body.removeChild(download);
      } else {
        this.displayNotificationError(this.$i18n.t('dispersions.details.error.downloadFile'))
      }
    },
  },
  created() {
    moment.locale(this.$i18n.locale);
    this.loadBulks();
  },
}
</script>
