<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/settings"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('settings.goBack')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('settings.personalInfo.title')"></FloatingTitleLayout>
                        </div>
                        <div class="col-12" v-if="!loading">
                            <CardSimple :title="$t('settings.personalInfo.generalInfo')" :subtitle="$t('settings.personalInfo.text')">
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.fullName')"
                                            :text="fullName"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.nationality')"
                                            :text="user.nationalitieOnboarding ? user.nationalitieOnboarding.nationalitie : '-'"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.birthDate')"
                                            :text="formatDate(user.birthDay)"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.birthState')"
                                            :text="user.birthState ? user.birthState.name : '-'"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.gender.title')"
                                            :text="$t(`settings.personalInfo.gender.${user.gender}`)"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.ocupation')"
                                            :text="user.ocupacion ? `${user.ocupacion.name} - ${user.descriptionOcupation}` : user.descriptionOcupation"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.phone')"
                                            :text="formatPhoneCustom(user.phone)"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.email')"
                                            :text="user.email"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 brd-divider m-b-50">
                                    </div>
                                    <div class="col-12 m-b-30">
                                        <label class="poppins-font-bold f-18 c-text_title"> {{$t('settings.personalInfo.address')}}
                                            <p class="poppins-font-regular f-12 c-text_content m-0 m-t-5">{{$t('settings.personalInfo.addressText')}}</p>
                                        </label>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.country')"
                                            :text="user.countryNewOnboarding ? user.countryNewOnboarding.name : '-'"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.state')"
                                            :text="user.stateNewOnboarding ? user.stateNewOnboarding.name : '-'"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.city')"
                                            :text="user.delegation ? user.delegation.name : '-'"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.street')"
                                            :text="user.street"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.noExt')"
                                            :text="user.noExt"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.noInt')"
                                            :text="user.noInt ? user.noInt : '-'"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.district')"
                                            :text="user.district"
                                        ></ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('settings.personalInfo.zipCode')"
                                            :text="user.zipCode"
                                        ></ReadOnlyInfo>
                                    </div>
                                </div>
                            </CardSimple>
                        </div>
                        <div class="col-12" v-if="!loading">
                            <b-alert class="alert-note" show variant="info">
                                <p class="text-container">
                                    <i
                                        aria-hidden="true"
                                        class="icon iconsax-emails-message-bold"
                                        ></i>
                                    <span v-html="$t('settings.personalInfo.note')"></span>
                                </p>
                            </b-alert>
                        </div>
                        <div class="col-12" v-if="!loading">
                            <div class="control-buttons">
                                <button
                                    @click="goBack()"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('settings.personalInfo.back')}}
                                </button>
                                <div class="f-13 c-text_content poppins-font-medium text-center text-md-right">
                                    {{$t('settings.personalInfo.needChange')}}
                                    <a
                                            href="https://www.pagando.mx/contacto-pagando/" target="_blank"
                                            class="c-info poppins-font-bold text-underline hover"
                                            tabindex="">
                                            {{$t('settings.personalInfo.contactUs')}}
                                    </a>
                                </div>
                            </div>
                        </div>
                        <LoadingBar v-if="loading" :loadingText="$t('settings.personalInfo.loading')"></LoadingBar>

                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>

    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import LoadingBar from "@/components/LoadingBar.vue";
    import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
    import {
        STORE as sessionStore,
        GET_USER_FULL_NAME,
        GET_ACCOUNT,
        GET_FULL_DATA_USER,
        LOAD_FULL_DATA_USER
    } from "@/store/modules/session/session.types";
    import {mapGetters} from "vuex";
    import {EventBus} from "@/main";
    import moment from "moment";
    import { formatPhone } from "@/utils/utils";

    export default {
        name: "PersonalInformation",
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            CardSimple,
            ReadOnlyInfo,
            LoadingBar
        },
        data() {
            return {
                // Images
                window: {
                    width: 0
                },
                isMenuOpen: false,
                loading: false
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                fullName: GET_USER_FULL_NAME,
                account: GET_ACCOUNT,
                user: GET_FULL_DATA_USER
            }),
        },
        methods: {
            moment,
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            formatDate(date) {
                return moment(date).format('DD MMMM, YYYY');
            },
            formatPhoneCustom(phone) {
                return formatPhone(phone);
            },
            goBack() {
                this.$router.push({name: 'Settings'});
            }
        },
        created() {
            EventBus.$on(`${sessionStore}.${LOAD_FULL_DATA_USER}`, () => {
                this.loading = false;
            });
        },
        async mounted () {
            this.loading = true;
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);

            await this.$store.dispatch(`${sessionStore}/getFullDataByLevel`, {level: this.account.account.accountLevel});

        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        },
    };
</script>
