<template>
  <div>
    <div class="register-container">
      <LeftSide :isBusiness="true" :showBack="showBack"></LeftSide>
      <RightSide></RightSide>
      <Footer></Footer>
    </div>
    <FooterPage></FooterPage>
    <vue-snotify></vue-snotify>
  </div>

</template>

<script>

import LeftSide from "@/pages/onboarding/LeftSide";
import RightSide from "@/pages/onboarding/RightSide";
import Footer from "@/pages/onboarding/Footer";
import FooterPage from "@/components/FooterPage.vue";
// import {
//   STORE as OnboardingStore
// } from '@/store/modules/onboardingSteps/onboardingSteps.types';
export default {
  name: "OnboardingMoral",
  components: {
    LeftSide,
    RightSide,
    Footer,
    FooterPage
  },
  data() {
    return {
      showBack: false
    }
  },
  async mounted() {
    this.showBack = this.$route.matched.some(r => r.name === 'selectSchemaBusinessMultiple' || r.name === 'selectAccountBusinessMultiple');

    // await this.$store.dispatch(`${OnboardingStore}/getProvisionalCards`, false);

  }
}
</script>

<style scoped>

</style>
