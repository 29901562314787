<template>
<div>
  <TitleBadge clazzContainer="p-0" :titleText="$t('myAccount.bankStatements.noDocs')" :badgeText="bankSt.totalDocs.toString()">
    <div class="daterange-filter">
      <label class="f-12 c-text_info poppins-font-medium f-500 m-b-0">{{ $t('myAccount.bankStatements.filterBy') }}</label>
      <date-range-picker
          ref="picker"
          opens="right"
          :showDropdowns="false"
          :autoApply="true"
          v-model="dateRange"
          :linked-calendars="false"
          :ranges="false"
          :singleDatePicker="false"
          @start-selection="onClickStartDate"
          @finish-selection="onClickFinishDate"
          :locale-data="timeOptions"
          class="">
        <!-- eslint-disable -->
        <template v-slot:input="picker">
          <span class="daterange-btn">{{ $t('myAccount.bankStatements.date') }} <i aria-hidden="true" class="iconsax-arrow-direction-square-down"></i></span>
        </template>
        <!-- eslint-enable -->
      </date-range-picker>
    </div>
  </TitleBadge>
  <CardSimple
      clazz="p-0"
      hideTitle>
    <div
        id="accStateTable"
        class="basic-table">
      <b-table-simple
          responsive
          table-variant="xl">
        <b-thead>
          <b-tr class="">
            <b-th> {{$t('myAccount.bankStatements.headers.period')}}</b-th>
            <b-th class="text-center"> {{$t('myAccount.bankStatements.headers.dueDate')}}</b-th>
            <b-th class="text-center"> {{$t('myAccount.bankStatements.headers.daysPeriod')}}</b-th>
            <b-th class="text-center"> {{$t('myAccount.bankStatements.headers.year')}}</b-th>
            <b-th class="text-center"> {{$t('myAccount.bankStatements.headers.actions')}}</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr class="noHover" v-if="bankSt.totalDocs <= 0">
            <b-td colspan="5">
              <EmptyState
                  :emptyStateDefault="!loadingBankStats"
                  :title="$t('myAccount.bankStatements.emptyState.title')"
                  :text="$t('myAccount.bankStatements.emptyState.text')"
                  :imgWidth="520"
                  :imgHeight="320"
                  :imgAlt="$t('myAccount.bankStatements.emptyState.imgAlt')"
                  :emptyStateSearching="loadingBankStats"
              >
              </EmptyState>
            </b-td>
          </b-tr>
          <b-tr class="" v-for="doc in bankSt.docs" :key="doc._id">
            <b-td>
              <div class="vertical-center flex-wrap-no">
                <img class="img-fluid" src="@/assets/images/Icons/icon-doc-pdf.svg" width="24" height="26"
                     alt="Archivo PDF"/>
                <strong class="c-text_title poppins-font-bold m-l-15 min-w-100px">{{getFormatDate(doc.from)}} - {{getFormatDate(doc.to)}}</strong>
              </div>
            </b-td>
            <b-td class="text-center">
              <strong class="c-text_title poppins-font-bold">{{getFormatDate(doc.to)}}</strong>
            </b-td>
            <b-td class="text-center">
              <strong class="c-text_title poppins-font-bold">{{ doc.daysOfMonth }}</strong>
            </b-td>
            <b-td class="text-right">
              <strong class="c-text_title poppins-font-bold">{{ doc.year }}</strong>
            </b-td>
            <b-td class="text-center">
              <div class="vertical-center flex-wrap-no column-gap-10px w-fit m-0-auto">
                <button
                    id="seeDoc"
                    type="button"
                    class="button-square xs button-stroke button-primary"
                    name="Ver documento"
                    aria-label="Ver documento"
                    @click="watchDoc(doc)"
                >
                  <i aria-hidden="true" class="iconsax-security-eye"></i>
                </button>
                <button
                    id="downloadDoc"
                    type="button"
                    class="button-square xs button-stroke button-primary"
                    name="Descargar documento"
                    aria-label="Descargar documento"
                    @click="watchDoc(doc, true)"
                >
                  <i aria-hidden="true" class="iconsax-content-document-download"></i>
                </button>
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </CardSimple>

  <div class="pagination-custom" v-if="bankSt.totalDocs">
    <span class="pagination-count">{{ $tc('myAccount.bankStatements.pagination', bankSt.totalDocs, {total: bankSt.totalDocs}) }} </span>
    <b-pagination
        v-model="paginateOpts.currentPage"
        :total-rows="paginateOpts.rows"
        :per-page="paginateOpts.perPage"
        aria-controls="my-table"
        next-text=""
        prev-text=""
        hide-goto-end-buttons
        @change="pageChange"
    ></b-pagination>
  </div>


  <!-- MODAL NIP -->
  <b-modal
      id="modal-nip-bankStatements"
      size="xs"
      hide-header
      hide-footer
      modal-class="modal-default">
    <button class="close-modal"
            @click="$bvModal.hide('modal-nip-bankStatements')"
            aria-label="Boton cerrar ventana"
            name="Boton cerrar ventana">
      <i aria-hidden="true" class="banana banana-cross-normal"></i>
    </button>
    <div class="head">
      <h2 class="modal-title">{{$t('myAccount.bankStatements.modalNip.title')}}</h2>
      <p class="modal-subtitle">
        {{$t('myAccount.bankStatements.modalNip.subtitle')}}
      </p>
    </div>
    <div class="body">
      <CodeInput
          typeInput="password"
          :clazz="errorNip ? 'has-error' : ''"
          :four-codes="true"
          v-on:verificationCode="onChangeCode"
          :deleteName="'delete-code-input'"
          :warningText="$t(warningText)">
      </CodeInput>
    </div>
    <div class="footer">
      <button type="button" class="button button-stroke button-primary" @click="$bvModal.hide('modal-nip-bankStatements')">
        {{$t('myAccount.bankStatements.modalNip.cancel')}}
      </button>
      <button type="button" class="button button-filled button-primary" :disabled="!isValidPin" @click="submitPin">
        {{$t('myAccount.bankStatements.modalNip.next')}}
      </button>
    </div>
    <LoadingBar v-if="loadingNip" clazz="fullcontent-loading" :loadingText="$t('myAccount.bankStatements.modalNip.validating')"></LoadingBar>
  </b-modal>

  <!-- MODAL CODIGO CONFIRMACION -->
  <b-modal
      id="modal-confirm-code-bankStatements"
      size="xs"
      hide-header
      hide-footer
      modal-class="modal-default">
    <button class="close-modal"
            @click="closeModalSms"
            aria-label="Boton cerrar ventana"
            name="Boton cerrar ventana">
      <i aria-hidden="true" class="banana banana-cross-normal"></i>
    </button>
    <div class="head">
      <h2 class="modal-title">{{$t('myAccount.bankStatements.modalCode.title')}}</h2>
      <p class="modal-subtitle">
        {{$t('myAccount.bankStatements.modalCode.subtitle')}}
        <strong class="c-info poppins-font-bold">{{user.phone}}</strong>
      </p>
    </div>
    <div class="body">
      <CodeInput
          typeInput="password"
          :clazz="errorNip ? 'has-error' : ''"
          v-on:verificationCode="onChangeCode"
          :deleteName="'delete-code-input'"
          :warningText="$t(warningText)">
      </CodeInput>

      <span class="f-12 c-text_content poppins-font-regular f-400 text-center d-block" v-if="showTimer">
        {{ $t('myAccount.bankStatements.modalCode.reSendIn') }}
        <p class="f-24 poppins-font-semibold f-600 m-b-0" id="countdownTimer"></p>
      </span>

      <a v-if="!showTimer" href="#" class="f-14 c-info poppins-font-bold f-700 text-center w-100 d-block" tabindex="" @click="reSendCode">
        <u>{{$t('myAccount.bankStatements.modalCode.reSend')}}</u>
      </a>
    </div>
    <div class="footer">
      <button type="button" class="button button-stroke button-primary" @click="closeModalSms">
        {{$t('myAccount.bankStatements.modalCode.cancel')}}
      </button>
      <button type="button" class="button button-filled button-primary" :disabled="!isValidPin" @click="submitPin">
        {{$t('myAccount.bankStatements.modalCode.next')}}
      </button>
    </div>
    <LoadingBar v-if="loadingNip" clazz="fullcontent-loading" :loadingText="$t('myAccount.bankStatements.modalNip.validating')"></LoadingBar>
  </b-modal>

  <!-- MODAL ESTADO DE CUENTA -->
  <b-modal
      id="modal-edo-cuenta"
      size="lg"
      hide-header
      hide-footer>
    <button class="close-modal"
            @click="$bvModal.hide('modal-edo-cuenta')"
            aria-label="Boton cerrar ventana"
            name="Boton cerrar ventana">
      <i aria-hidden="true" class="banana banana-cross-normal"></i>
    </button>
    <div class="modal-terminos-privacidad">
      <h2 class="title">
        {{ $t('myAccount.bankStatements.modalEdoAccount.title') }}
      </h2>
      <span class="date-update">
        <small>{{$t('myAccount.bankStatements.modalEdoAccount.period')}}</small>
        {{getFormatDate(currentDoc.from)}} - {{getFormatDate(currentDoc.to)}}
      </span>
      <div v-if="loadModal" class="text-container">
        <vuePdf :src="pathPdf"></vuePdf>
      </div>
      <div v-else class="text-container">
        <loading-bar :loadingText="$t('myAccount.bankStatements.modalEdoAccount.loadDoc')"></loading-bar>
      </div>
      <button v-if="showDownload" id="" type="button" class="button button-filled button-primary"
              @click="getSignedUrl(currentDoc.file)">
        <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
        {{ $t('myAccount.bankStatements.modalEdoAccount.download') }}
      </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
        <LoadingSimple clazz="small-load no-text"/>
      </button>
    </div>
  </b-modal>
</div>
</template>

<script>
import CardSimple from "@/components/CardSimple";
import TitleBadge from "@/components/TitleBadge";
import EmptyState from "@/components/EmptyState";
import DateRangePicker from "vue2-daterange-picker";
import {mapGetters} from "vuex";
import {
  GET_ACCOUNT,
  GET_SMS_TWO_FACTOR,
  GET_USER,
  SET_ACCOUNT,
  STORE as sessionStore
} from "@/store/modules/session/session.types";
import BankStatementApi from "@/api/wallet/bankStatement.api";
import {catchError, isObjectEmpty, timestampToDate, getMonthByNoAbbr} from "@/utils/utils";
import {EventBus} from "@/main";
import moment from 'moment';
import CodeInput from "@/components/CodeInput";
import LoadingBar from "@/components/LoadingBar";
import UserApi from "@/api/users/users.api";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import LoadingSimple from "@/components/LoadingSimple";
import vuePdf from 'pdfvuer'
import FileApi from "@/api/core/files.api";

let intervalCodeTime;

const modalConfirmCode = "modal-confirm-code-bankStatements";

export default {
  name: "AccountStatements",
  mixins: [ToastSnotify],
  data() {
    return {
      dateRange: {},
      loadingBankStats: true,
      timeOptions: {
        weekLabel: "#",
        daysOfWeek: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'],
      },
      bankSt: {
        docs: [],
        totalDocs: 0,
        totalPages: 0
      },
      search: null,
      paginateOpts: {
        currentPage: 1,
        perPage: 6,
        rows: 1,
      },
      errorNip: false,
      warningText: '',
      loadingNip: false,
      pin: "",
      showTimer: true,
      showDownload: true,
      currentDoc: {},
      // eslint-disable-next-line max-len
      pathPdf: "",
      loadModal: false,
      downloadFileAction: false
    }
  },
  computed: {
    ...mapGetters(sessionStore, {
      infoAccount: GET_ACCOUNT,
      user: GET_USER,
      smsTwoFactor: GET_SMS_TWO_FACTOR
    }),
    isValidPin() {
      return this.pin && (this.smsTwoFactor ? this.pin.length === 5 : this.pin.length === 4);
    }
  },
  components: {
    CardSimple,
    TitleBadge,
    EmptyState,
    DateRangePicker,
    CodeInput,
    LoadingBar,
    LoadingSimple,
    vuePdf
  },
  methods: {
    setTimeFilters() {
      if (this.$i18n.locale === "en") {
          this.timeOptions.daysOfWeek = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
          this.timeOptions.monthNames =['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      }
    },
    async getBankStatements() {
      this.loadingBankStats = true;
      if (!isObjectEmpty(this.infoAccount.account)) {
        const params = {
          user: this.user._id,
          account: this.infoAccount.account._id,
          page: this.paginateOpts.currentPage,
          perPage: this.paginateOpts.perPage
        }
        if (this.search) {
            params.search = this.search;
        }
        const res = await BankStatementApi.findBankStatementByAccount(params).catch(catchError);
        if (res.data && !res.data.error && !isObjectEmpty(res.data.object)) {
          this.bankSt = res.data.object;
          this.paginateOpts.rows = this.bankSt.totalPages;
        }
      }
      this.loadingBankStats = false;
    },
    getFormatDate(timestamp) {
      const date = timestampToDate(timestamp);
      const month = this.$i18n.t(getMonthByNoAbbr(date.getMonth()));
      return `${date.getDate()} ${month}. ${date.getFullYear()}`
    },
    pageChange(page) {
      this.paginateOpts.currentPage = page;
      this.getBankStatements();
    },
    onClickStartDate(date) {
      this.search = {};
      const startDate = moment(date).toDate();
      startDate.setHours(0);
      startDate.setMinutes(0);
      startDate.setSeconds(0);
      startDate.setMilliseconds(0);
      this.search.from = startDate.getTime();
    },
    onClickFinishDate(date) {
      const endDate = moment(date).toDate();
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      this.search.to = endDate.getTime();
      this.getBankStatements();
    },
    watchDoc(doc, isDownload = false) {
      this.downloadFileAction = isDownload;
      this.currentDoc = doc;
      if (this.smsTwoFactor) {
        this.warningText = "myAccount.bankStatements.modalCode.warningText";
        const times = localStorage.getItem('bankStCountDownDate');
        if (times) {
          this.countDown(times);
        } else {
          this.reSendCode();
        }
        this.$bvModal.show(modalConfirmCode);
      } else {
        this.$bvModal.show('modal-nip-bankStatements');
      }
    },
    onChangeCode(value) {
      this.pin = value;
    },
    doAction: function () {
      if (this.downloadFileAction) {
        this.getSignedUrl(this.currentDoc.file);
        this.closeModalPin();
      } else {
        this.openModalBankSta();
        this.getPdfBase64(this.currentDoc.file);
      }
    },
    async submitPin() {
      this.loadingNip = true;
      let res;
      if (this.smsTwoFactor) {
          res = await this.validateSms();
      } else {
          res = await this.validateUserPin();
      }
      if (res && res.data) {
        if (res.data.error) {
          this.warningText = this.smsTwoFactor ? "myAccount.bankStatements.modalCode.error" : "myAccount.bankStatements.modalNip.error";
          this.errorNip = true;
          this.pin = "";
          EventBus.$emit("delete-code-input");
        } else {
          if (this.smsTwoFactor) {
            clearInterval(intervalCodeTime);
            this.showTimer = false;
            localStorage.removeItem('bankStCountDownDate');
          }
          this.errorNip = false;
          this.warningText = ""
          this.doAction();
        }
      }
      this.loadingNip = false;
    },
    closeModalPin() {
      if (this.smsTwoFactor) {
        this.$bvModal.hide(modalConfirmCode);
      } else {
        this.$bvModal.hide('modal-nip-bankStatements');
      }
    },
    openModalBankSta() {
      this.closeModalPin();
      this.$bvModal.show('modal-edo-cuenta');
    },
    async validateUserPin() {
      return UserApi.validatePin({
        pin: this.pin
      }).catch(catchError);
    },
    async validateSms() {
      return UserApi.verifyCode({
        code: this.pin
      }).catch(catchError);
    },
    async reSendCode() {
      this.showTimer = true;
      this.loadingNip = true
      const response = await UserApi.reSendCode().catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          this.showTimer = false;
          this.displayNotificationError(this.$i18n.t('myAccount.bankStatements.modalCode.errorSms'));
        }
        this.countDown();
      }
      this.loadingNip = false;
    },
    countDown(start = 0) {
      this.showTimer = true;
      let countDownDate;
      if (start) {
        countDownDate = start;
      } else {
        countDownDate = new Date();
        countDownDate = countDownDate.setMinutes(countDownDate.getMinutes() + 2, countDownDate.getSeconds() + 30);
        localStorage.setItem('bankStCountDownDate', countDownDate);
      }
      intervalCodeTime = setInterval( () => {
        const now = new Date().getTime();
        const distance = countDownDate - now;
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        const docHtml = document.getElementById("countdownTimer");
        if (docHtml) {
            docHtml.innerHTML = `${minutes} m ${seconds} s`;
        }
        if (distance < 0) {
          clearInterval(intervalCodeTime);
          localStorage.removeItem('bankStCountDownDate');
          this.showTimer = false;
          this.loadingNip = false;
        }
      }, 1000);
    },
    closeModalSms() {
      this.$bvModal.hide(modalConfirmCode);
      clearInterval(intervalCodeTime);
    },
    async getPdfBase64(id) {
      this.loadModal = false;
      const response = await FileApi.downloadFile(id, {
        base64: true
      }).catch(catchError);
      if (response && response.data) {
        this.pathPdf = response.data.object;
      } else {
        this.displayNotificationWarning(this.$i18n.t('myAccount.bankStatements.modalEdoAccount.errorFind'))
      }
      this.loadModal = true;
    },
    async getSignedUrl(id) {
      this.showDownload = false;
      const response = await FileApi.downloadSignedUrl({id: id}).catch(catchError);
      if (response && response.data) {
        this.downloadFile(response.data.object)
      } else {
        this.displayNotificationWarning(this.$i18n.t('myAccount.bankStatements.modalEdoAccount.errorFind'))
      }
      this.showDownload = true;
    },
    downloadFile(url) {
      if (url) {
        const link = document.createElement("a");
        link.setAttribute("href", url);
        link.click();
      }
    },
  },
  created() {
    this.setTimeFilters()
  },
  beforeMount() {
    this.getBankStatements();
    EventBus.$on(`${sessionStore}.${SET_ACCOUNT}`, this.getBankStatements);
  },
  beforeDestroy() {
    clearInterval(intervalCodeTime);
  }
}
</script>

<style scoped>

</style>
