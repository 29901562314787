<template>
  <div>
    <!-- Anuncio -->
    <div v-if="status === 'announcement'">
      <div class="text-center">
        <img
          class="img-fluid m-b-30"
          src="@/assets/images/Illustrations/illustration-code-shield.svg"
          :alt="$t('login.migrationCVV.announcement.imageAlt')"
          width="450"
          height="216"
        />
      </div>
      <TitlePrincipal :title="$t('login.migrationCVV.announcement.title')">
        <div class="subtitle">
          <i18n tag="p" path="login.migrationCVV.announcement.paragraphs[0]">
            {{ username }}<strong class="poppins-font-bold" v-t="'login.migrationCVV.announcement.labels[0]'"/>
          </i18n>
          <i18n tag="p" path="login.migrationCVV.announcement.paragraphs[1]">
            <strong class="poppins-font-bold" v-t="'login.migrationCVV.announcement.labels[1]'"/>
          </i18n>
          <i18n tag="p" path="login.migrationCVV.announcement.paragraphs[2]">
            <strong class="poppins-font-bold" v-t="'login.migrationCVV.announcement.labels[2]'"/>
          </i18n>
          <i18n tag="p" path="login.migrationCVV.announcement.paragraphs[3]">
            <strong class="poppins-font-bold" v-t="'login.migrationCVV.announcement.labels[3]'"/>
          </i18n>
          <i18n tag="p" path="login.migrationCVV.announcement.paragraphs[4]">
            <strong class="poppins-font-bold" v-t="'login.migrationCVV.announcement.labels[4]'"/>
          </i18n>
          <i18n tag="p" path="login.migrationCVV.announcement.paragraphs[5]">
            <a
              href=""
              class="text-underline c-info poppins-font-bold"
              v-t="'login.migrationCVV.announcement.labels[5]'"
              @click.prevent="openZendesk"
            />
          </i18n>
          <div class="control-buttons buttons-full-w-xs justify-content-center">
            <button
              type="button"
              class="button button-stroke button-primary"
              v-t="'login.migrationCVV.announcement.skipBtn'"
              @click="goToDashboard"
            />
            <button
              type="button"
              class="button button-filled button-primary"
              v-t="'login.migrationCVV.announcement.confirmBtn'"
              @click="status = 'input'"
            />
          </div>
        </div>
      </TitlePrincipal>

    </div>

    <!-- Ingresar Pin -->
    <div v-if="status === 'input'">
      <TitlePrincipal
        clazz="text-center"
        :title="$t('login.migrationCVV.input.title')"
        :subtitle="$t('login.migrationCVV.input.subtitle')"
      >
      </TitlePrincipal>
      <CodeInput
        fourCodes
        typeInput="password"
        @verificationCode="pin = $event"
      />
      <div class="control-buttons buttons-full-w-xs justify-content-center" v-if="!loading">
        <button
          type="button"
          class="button button-stroke button-primary"
          v-t="'login.migrationCVV.input.backBtn'"
          @click="status='announcement'"
        />
        <button
          type="button"
          class="button button-filled button-primary"
          :disabled="pin.length !== 4"
          v-t="'login.migrationCVV.input.confirmBtn'"
          @click="migrateCVV"
        />
      </div>
      <div class="control-buttons buttons-full-w-xs justify-content-center" v-else>
        <button
          type="button"
          class="button button-stroke button-primary"
          :width="150"
          v-t="'login.migrationCVV.input.backBtn'"
        />
        <button type="button" class="button button-filled button-primary">
          <LoadingSimple clazz="small-load no-text"/>
        </button>
      </div>
    </div>

    <!-- Éxito -->
    <div v-if="status === 'success'">
      <div class="text-center">
        <img
          class="img-fluid m-b-30"
          src="@/assets/images/Illustrations/illustration-code-clock.svg"
          alt="Aviso"
          width="360"
          height="275"
        />
      </div>
      <TitlePrincipal clazz="text-center" :title="$t('login.migrationCVV.success.title')">
        <div class="subtitle">
          <i18n tag="p" path="login.migrationCVV.success.paragraphs[0]"/>
          <i18n tag="p" path="login.migrationCVV.success.paragraphs[1]"/>
          <i18n tag="p" path="login.migrationCVV.success.paragraphs[2]"/>
          <div class="control-buttons">
            <button
              type="button"
              class="button button-filled button-primary w-100"
              v-t="'login.migrationCVV.success.continueBtn'"
              @click="goToDashboard"
            />
          </div>
        </div>
      </TitlePrincipal>
    </div>

    <!-- Contactar a soporte -->
    <div v-if="status === 'support'">
      <div class="text-center">
        <img
          class="img-fluid m-b-30"
          src="@/assets/images/Illustrations/illustration-success-nip.png"
          alt="Aviso"
          width="360"
          height="275"
        />
      </div>
      <TitlePrincipal clazz="text-center" :title="$t('login.migrationCVV.support.title')">
        <div class="subtitle">
          <i18n tag="p" path="login.migrationCVV.support.paragraphs[0]">
            <strong class="poppins-font-bold" v-t="'login.migrationCVV.support.labels[0]'"/>
            <a
              href=""
              class="text-underline c-info poppins-font-bold"
              v-t="'login.migrationCVV.support.labels[1]'"
              @click.prevent="openZendesk"
            />
            <strong class="poppins-font-bold c-info" v-t="'login.migrationCVV.support.labels[2]'"/>
          </i18n>
          <div class="control-buttons">
            <button
              type="button"
              class="button button-filled button-primary w-100"
              v-t="'login.migrationCVV.support.closeBtn'"
              @click="logOut"
            />
          </div>
        </div>
      </TitlePrincipal>
    </div>

    <!-- Error -->
    <div v-if="status === 'error'">
      <div class="text-center">
        <img
          class="img-fluid m-b-30"
          src="@/assets/images/Illustrations/illustration-error-virtual-card.svg"
          alt="Aviso"
          width="360"
          height="275"
        />
      </div>
      <TitlePrincipal clazz="text-center" :title="$t('login.migrationCVV.error.title')">
        <div class="subtitle">
          <i18n tag="p" path="login.migrationCVV.error.paragraphs[0]">
              <a
                href=""
                class="text-underline c-info poppins-font-bold"
                v-t="'login.migrationCVV.error.labels[0]'"
                @click.prevent="openZendesk"
              />
          </i18n>
          <div class="control-buttons buttons-full-w-xs justify-content-center">
            <button
              type="button"
              class="button button-stroke button-primary"
              v-t="'login.migrationCVV.error.closeBtn'"
              @click="goToDashboard"
            />
            <button
              type="button"
              class="button button-filled button-primary"
              v-t="'login.migrationCVV.error.retryBtn'"
              @click="status = 'input'"
            />
          </div>
        </div>
      </TitlePrincipal>
    </div>
  </div>
</template>

<script>
/* Componentes */
import CodeInput from "@/components/CodeInput.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";
import TitlePrincipal from "@/components/TitlePrincipal.vue";

/* Apis */
import UserApi from "@/api/users/users.api";
import CardApi from "@/api/wallet/card.api";

/* Librerias */
import {mapGetters} from "vuex";

/* Stores */
import {
  GET_USER_NAME,
  SET_USER_CVV_MIGRATION_STATUS,
  GET_USER_CVV_MIGRATION_STATUS,
  GET_LOCATION,
  STORE as sessionStore,
} from "@/store/modules/session/session.types";

export default {
  name: "MigrationCVV",
  components: {
    CodeInput,
    LoadingSimple,
    TitlePrincipal,
  },
  data() {
    return {
      /**
       * Determina cuál paso mostrar en la pantalla
       * @type {'announcement'|'input'|'error'|'support'|'success'}
       */
      status: '',

      /**
       * El pin que ingresa el usuario
       *  @type {string}
       */
      pin: '',

      /**
       * Indica si se está ejecutando la migración
       * @type {boolean}
       */
      loading: false,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
      username: GET_USER_NAME,
      migrationStatus: GET_USER_CVV_MIGRATION_STATUS,
      location: GET_LOCATION,
    }),
  },
  mounted() {
    switch(this.migrationStatus) {
      case 'PENDING':
      default:
        this.status = 'announcement';
        break;
      case 'IN_PROCESS':
        this.status = 'support';
        break;
      case 'FINISHED':
        this.status = 'success';
        break;
    }
  },
  methods: {
    /**
     * Lleva a la pantalla del Dashboard.
     */
    goToDashboard() {
      this.$router.push({name: "Dashboard"});
    },

    /**
     * Realiza la migración de CVV, validando primero que el pin ingresado sea válido.
     */
    async migrateCVV() {
      try {
        this.loading = true;

        const validPin = await this.verifyPin()
          .then(() => true)
          .catch(() => false);
        if (!validPin) {
          this.status = 'error';
          return;
        }

        const response = await CardApi.migrateCVV({ latitud: this.location.lat, longitud: this.location.lon }).catch((err) => err.response);
        if (response.status === 400) {
          /* Request mal formado */
          this.status = 'error';
          return;
        }

        if (response.status === 200) {
          // La migración se hizo exitosamente, se ejecuta `loadAccount` para cargar las nuevas tarjetas.
          await this.$store.dispatch(`${sessionStore}/loadAccount`);
        }

        const migrationStatus = response.data.object;
        this.$store.commit(`${sessionStore}/${SET_USER_CVV_MIGRATION_STATUS}`, migrationStatus);

        const statusOptions = {
          PENDING: 'error',
          IN_PROCESS: 'support',
          FINISHED: 'success',
        };
        this.status = statusOptions[migrationStatus];
      } catch {
        this.status = 'error';
      } finally {
        this.loading = false;
      }
    },

    /**
     * Valida si el pin ingresado es válido. 🤨
     * @throws Tira un error si el pin es inválido
     */
    async verifyPin() {
      const response = await UserApi.validatePin({ pin: this.pin });
      if(response.data.error) {
        throw response;
      }
    },

    /**
     * Abre el chat de zendesk
     */
    openZendesk() {
      if(window.zE) {
        window.zE('messenger', 'open');
      }
    },

    /**
     * Cierra la sesión del usuario
     */
    logOut() {
      localStorage.removeItem('currentToken');
      localStorage.removeItem('lastAccount');
      UserApi.logout();
      this.$store.commit(`${sessionStore}/CLEAR_STORE`);
      this.$router.push({ name: 'login' });
    },
  },
  watch: {
    /**
     * Resetea el pin cada vez que se muestre el input en pantalla
     * @param {string} val El nuevo valor de `status`
     */
    status(val) {
      if (val === 'input') {
        this.pin = '';
      }
    },
  },
};
</script>

<style scoped></style>
