<template>
  <div class="row">
    <div class="col-12">
      <TitleSteps :title="$t('deposits.title')">
      </TitleSteps>
    </div>
    <div class="col-12">
      <b-tabs
          class="simple-tabs"
          content-class="simple-tabs-body p-t-40">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-flex align-items-center p-b-5">
                    <i aria-hidden="true" class="iconsax-money-convert-card m-r-10 f-20 c-primary"></i>
                    {{$t('deposits.transfer')}}
                  </span>
                </template>
                <div class="row">
                  <div class="col-12">
                    <PanelMyAccount
                        :primaryTitle="$t('deposits.CLABE')"
                        :primaryText="account.account.providerCLABE">
                        <AccountType :text="$t('deposits.originAccount')"
                                    :typeAccount="accountDescription"
                                    clazz="p-relative imgLeft">
                                    <img class="img-fluid" :src="accountImage" width="64" height="40" alt="Pagando"/>
                        </AccountType>
                    </PanelMyAccount>
                  </div>
                  <div class="col-12">
                    <CardSimple
                        :title="$t('deposits.byTransfers.title')"
                        :subtitle="$t('deposits.byTransfers.subtitle')">
                        <ul class="checked-list m-b-30">
                            <li class="f-14 w-100 max-w-initial c-text_title" v-html="$t('deposits.byTransfers.step1')">
                            </li>
                            <li class="f-14 w-100 max-w-initial c-text_title" v-html="$t('deposits.byTransfers.step2', {bankName: bank.name})">
                            </li>
                            <li class="f-14 w-100 max-w-initial c-text_title" v-html="$t('deposits.byTransfers.step3', {userFullName: getLegalName})">
                            </li>
                            <li class="f-14 w-100 max-w-initial c-text_title" v-html="$t('deposits.byTransfers.step4', {clabeLength: account.account.providerCLABE.length})">
                            </li>
                            <li class="f-14 w-100 max-w-initial c-text_title">
                              {{$t('deposits.byTransfers.step5')}}
                            </li>
                            <li class="f-14 w-100 max-w-initial c-text_title" v-html="$t('deposits.byTransfers.step6')">
                            </li>
                        </ul>
                        <b-alert class="alert-note m-t-30" show variant="info" v-if="commissionSPEI <= 0">
                            <p class="text-container">
                                <i
                                    aria-hidden="true"
                                    class="icon iconsax-emails-message-bold"
                                    ></i>
                                <span v-html="$t('deposits.byTransfers.noCommission')"></span>
                            </p>
                        </b-alert>
                        <b-alert class="alert-note m-t-30" show variant="info"  v-else>
                            <p class="text-container">
                                <i
                                    aria-hidden="true"
                                    class="icon emails-message-bold"
                                    ></i>
                                <span v-html="$t('deposits.byTransfers.withCommission', {commission: currency(commissionSPEI)})"></span>
                            </p>
                        </b-alert>
                    </CardSimple>
                  </div>
                </div>
              </b-tab>
              <b-tab @click="getEstablishments()">
                <template v-slot:title>
                  <span class="d-flex align-items-center p-b-5">
                    <i aria-hidden="true" class="iconsax-money-wallet-coins m-r-10 f-20 c-primary"></i>
                    {{$t('deposits.cash')}}
                  </span>
                </template>
                  <div class="col-12">
                    <CardSimple
                        :title="$t('deposits.byCash.title')"
                        :subtitle="$t('deposits.byCash.subtitle')">
                        <div class="row" v-if="establishments.length > 0 && !loadingEstablishments">
                          <div class="col-lg-3 col-md-4 col-6 text-center" v-for="establishment in establishments" :key="establishment._id">
                            <router-link :to="getRoute(establishment._id)" class="establisments-selector" tabindex="">
                              <div class="img-container">
                                <img class="img-fluid" :src="establishment.src"
                                width="88" height="38" alt="Imagen establecimiento"/>
                              </div>
                              <div class="name-establisnment">{{establishment.name}}</div>
                            </router-link>
                          </div>
                        </div>
                        <LoadingBar v-if="loadingEstablishments" :loadingText="$t('deposits.byCash.loading')" ></LoadingBar>
                        <EmptyState v-if="establishments.length === 0 && !loadingEstablishments"></EmptyState>
                    </CardSimple>
                  </div>
              </b-tab>
      </b-tabs>
    </div>

  </div>
</template>

<script>
import TitleSteps from "@/components/TitleSteps.vue";
import PanelMyAccount from "@/components/PanelMyAccount.vue";
import AccountType from "@/components/AccountType.vue";
import CardSimple from "@/components/CardSimple.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import EmptyState from "@/components/EmptyState.vue";
import CardApi from "@/api/wallet/card.api";
import {
  STORE as sessionStore,
  GET_USER,
  GET_ACCOUNT,
  GET_USER_FULL_NAME, GET_ORG_INFO
} from "@/store/modules/session/session.types";
import {mapGetters} from "vuex";
import { imageDownloadLink } from '@/utils/utils';
import {CARD_DEFAULT_IMG} from "@/enums/defalutCardImg.enum";
import {catchError} from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import EstablishmentsApi from "@/api/core/establishments.api";
import CardsApi from '@/api/wallet/card.api';

export default {
  name: "Deposit",
  components: {
    TitleSteps,
    PanelMyAccount,
    AccountType,
    CardSimple,
    LoadingBar,
    EmptyState
  },
  mixins: [ToastSnotify],
  data() {
    return {
      commissionSPEI: 0,
      establishments: [],
      loadingEstablishments: false,
      bank: {
        name: 'N/A',
        shortName: 'N/A'
      }
    }
  },
  computed: {
    ...mapGetters(sessionStore, {
        user: GET_USER,
        account: GET_ACCOUNT,
        userFullName: GET_USER_FULL_NAME,
        orgInfo: GET_ORG_INFO
    }),
    currency() {
      return this.$options.filters.currency;
    },
    accountDescription() {
        if(this.account && this.account.account && this.account.account.accountType) {
            return this.account.account.accountType.description;
        }
        return 'Pagando';
    },
    accountImage() {
      const defaultImage = CARD_DEFAULT_IMG.pagandoExploreVirtualFront;
      if(!this.account || !this.account.template) {
          return defaultImage;
      }

      switch (this.account.template.product) {
          case "EXPLORE":
              return defaultImage;
          case "EXPLORE_PLUS":
              return CARD_DEFAULT_IMG.pagandoExplorePlusVirtualFront;
          case "NIGHT":
              return CARD_DEFAULT_IMG.pagandoNightVirtualFront;
          case "BUSINESS":
              return CARD_DEFAULT_IMG.pagandoBusinessVirtualFront;
          case "BUSINESS_PRO":
              return CARD_DEFAULT_IMG.pagandoBusinessProVirtualFront;
          default:
            if(this.account.template.image) {
                return imageDownloadLink(this.account.template.image);
            }
            return defaultImage;
      }
    },
    getLegalName() {
      if (this.account.account.accountType.productType === 'CLIENTS_ACCOUNT') {
          return this.orgInfo.legalName
      }
      return this.userFullName
    },
  },
  methods: {
    getRoute(establishment) {
      return `/deposito-efectivo/${establishment}`;
    },
    async getCommission(commission) {
       const res = await CardApi.getCommissionForEvent({
          pagandoAccount: this.account.account._id,
          commission: commission,
        }).catch(catchError);

        let comission = 0;

        if(res && res.data && !res.data.error) {
          comission = res.data.object;
        } else {
          this.toastErrorDefault(this.$i18n.t('deposits.errors.getCommissions'));
        }

        return comission;
    },
    async getEstablishments() {
      this.loadingEstablishments = true;
      const res = await EstablishmentsApi.getActives({
        action: "DEPOSIT",
        signedUrl: true,
        provider: "PAGANDITO"
      }).catch(catchError);

      if (res && res.data && !res.data.error && res.data.object) {
        this.establishments = res.data.object || [];
      } else {
        this.displayNotificationWarning(this.$i18n.t('deposits.errors.getEstablishments'));
      }

      this.loadingEstablishments = false;
    },
    async getCardData() {
      const response = await CardsApi.getAccountInfo({
          paymentSourceId: this.account.cards[0].card._id,
          provider: this.account.account.externalProvider,
      });

      if(response && response.data && !response.data.error) {
        this.bank = response.data.object.clabeBank;
      }
    },
  },
  async created () {
    this.commissionSPEI = await this.getCommission('SPEI_INCOME');
    await this.getCardData();
  }
};
</script>
