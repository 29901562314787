<template>
  <component
      :is="tag"
      :id="inputId"
      class="edit-input"
      :class="clazz"
      v-model="inputValueForVModel"
      :value="inputValueForVModel"
      :type="inputType"
      :placeholder="inputPlaceholder"
      aria-label="edit"
      :disabled="disabled"
      @input="input($event)"
      :mask="mask"
      :tokens="maskTokens"
      :prefix="prefix"
      :suffix="suffix"
      ref="inputRef"
      v-if="!hide"
  ></component>
</template>

<script>
export default {
  name: 'InputEdit',
  props: {
    inputId: String,
    inputPlaceholder: String,
    inputType: String,
    inputValue: { type: [String, Number], default: null },
    clazz: String,
    disabled: { type: Boolean, default: false },
    // for v-model
    value: {
      type: [String, Number],
    },
    hideInput: {
      type: Boolean,
      default: false
    },
    mask: {
      type: [String, Array],
      default: undefined
    },
    maskTokens: {
      type: Object,
      default: undefined
    },
    money: {
      type: Boolean,
      default: false
    },
    percent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValueForVModel: '',
      hide: false
    }
  },
  computed: {
    tag() {
      if (this.mask) {
        return 'the-mask';
      } else if (this.money || this.percent) {
        return 'money';
      }
      return 'input';
    },
    prefix() {
      return this.money ? '$' : '';
    },
    suffix() {
      return this.percent ? '%' : '';
    }
  },
  methods: {
    input(event) {
      let data;
      if (typeof event === 'object') {
        data = event.target.value;
      } else {
        data = event;
      }
      this.inputValueForVModel = data;
    },
    resetInput() {
      this.$refs.inputRef.value = '';
    },
    toggleHide(){
      this.hide = !this.hide;
    },
  },
  beforeMount() {
    if (this.money || this.percent) {
      this.inputValueForVModel = Number(this.value || this.inputValue || 0);
    } else {
      this.inputValueForVModel = this.value !== null && this.value !== undefined ? this.value : this.inputValue;
    }
  },
  watch: {
    value: {
      handler() {
        this.inputValueForVModel = this.value !== null && this.value !== undefined ? this.value : this.inputValue;
      }
    },
    inputValueForVModel: {
      handler() {
        this.$emit('input', this.inputValueForVModel);
      }
    }
  }
}
</script>
