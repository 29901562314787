<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('services.backToHome')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('services.title2')"></FloatingTitleLayout>
                        </div>
                        <LoadingBar v-if="loading" class="m-t-50" :loadingText="$t('services.loading')"></LoadingBar>

                        <div class="w-100" v-else>
                            <div class="col-12">
                                <TitleBadge clazzContainer="p-0" titleText="Servicios" :badgeText="finalServices.length.toString()">
                                    <div class="btn-filter-container p-0 m-b-0">
                                        <button v-for="(section, index) in sections" :key="index" id="" @click="changeSectionActive(index)"
                                            class="btn-filter" :class="isActive(index)" type="button">{{getSectionTitle(section.title)}}</button>
                                    </div>
                                </TitleBadge>
                            </div>

                            <div class="col-12">
                                <BasicInput
                                    clazz="m-b-15 noLabel panel-search"
                                    inputType="text"
                                    :inputPlaceholder="$t('services.search')"
                                    inputClazz="icon-left"
                                    iconInput="banana-search-normal"
                                    v-model="search"
                                    @input="searchService"
                                ></BasicInput>
                            </div>

                            <div id="sectionScroll" class="w-100">
                                <div class="col-12" v-if="finalServices.length === 0 && !loadingSection">
                                    <CardSimple hideTitle>
                                        <EmptyState
                                            emptyStateDefault
                                            :title="$t('services.empty.title')"
                                            :text="$t('services.empty.text')"
                                            :imgWidth="520"
                                            :imgHeight="320"
                                            imgAlt="Sin resultados"
                                        ></EmptyState>
                                    </CardSimple>
                                </div>

                                <div class="col-12" v-if="finalServices.length > 0 && (!loadingSection || !noScroll)">
                                    <div class="row y-scroll max-h-services-container">
                                        <div class="col-12 col-md-6" v-for="(service, index) in finalServices" :key="index">
                                            <PanelService
                                                @click="selectService(service)"
                                                :imgServiceUrl="getImage(service._source)"
                                                :serviceName="service._source.organization.name"
                                                :serviceDescr="service._source.name">
                                            </PanelService>
                                        </div>
                                    </div>
                                </div>
                                <LoadingBar class="m-t-50" v-if="loadingSection && noScroll" :loadingText="$t('services.loading')"></LoadingBar>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>

        <b-modal
            id="modal-service-type"
            size=""
            hide-header
            hide-footer
            modal-class="modal-default left-oriented">
            <button class="close-modal"
                    @click="$bvModal.hide('modal-service-type')"
                    aria-label="Boton cerrar ventana"
                    name="Boton cerrar ventana">
                <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <div class="head">
                <h2 class="modal-title">{{$t('services.modalParams.title')}}</h2>
                <p class="modal-subtitle" v-if="fieldsToShow.length > 0">
                    {{$t('services.modalParams.text1')}}
                </p>
                <p class="modal-subtitle" v-else>
                    {{$t('services.modalParams.text2')}}
                </p>
            </div>
            <div class="body">
                <div class="row" v-if="!loadingBalance">
                    <div class="col-12" v-for="(field, index) in fieldsToShow" :key="index">
                        <BasicInput
                            v-if="field.type === 'NUMBER'"
                            required
                            :inputId="field.name"
                            :name="field.name"
                            :label="field.description"
                            :placeholder="field.description"
                            @input="changeField($event, field)"
                            type="number">
                        </BasicInput>
                        <BasicInput
                            v-else-if="field.type === 'CURRENCY'"
                            required
                            :inputId="field.name"
                            :name="field.name"
                            :label="field.description"
                            :placeholder="field.description"
                            @input="changeField($event, field)"
                            type="text">
                        </BasicInput>
                        <BasicInput
                            v-else-if="field.type === 'BARCODE'"
                            required
                            :name="field.name"
                            :inputId="field.name"
                            :label="field.description"
                            :placeholder="field.description"
                            @input="changeField($event, field)"
                            type="text">
                        </BasicInput>
                        <TelInput
                            v-else-if="field.type === 'PHONE'"
                            required
                            :name="field.name"
                            :label="field.description"
                            :placeholder="field.description"
                            :idInput="field.name"
                            @input="changeField($event, field)"

                        />
                        <BasicInput
                            v-else
                            required
                            :inputId="field.name"
                            :name="field.name"
                            :label="field.description"
                            :placeholder="field.description"
                            @input="changeField($event, field)"
                            type="text">
                        </BasicInput>
                    </div>
                </div>
                <LoadingBar v-else :loadingText="$t('services.loading')"></LoadingBar>
            </div>
            <div class="footer"  v-if="!loadingBalance">
                <button class="button button-filled button-primary w-100" @click="checkData()" >{{$t('services.modalParams.button')}}</button>
            </div>
        </b-modal>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>
    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import TitleBadge from "@/components/TitleBadge.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import EmptyState from "@/components/EmptyState.vue";
    import LoadingBar from "@/components/LoadingBar.vue";
    import TelInput from "@/components/TelInput.vue";
    import PanelService from "@/components/PanelService.vue";
    import CmsApi from '@/api/core/cms.api';
    import WSApi from '@/api/wallet/ws.api';
    import { catchError } from "@/utils/utils";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";

    import ImgCFE from '@/assets/images/Logos/Services/img-cfe.png';
    import ImgJMAS from '@/assets/images/Logos/Services/img-jmas.png';
    import ImgTELMEX from '@/assets/images/Logos/Services/img-telmex.png';
    import ImgTOTAL from '@/assets/images/Logos/Services/img-totalplay.png';
    import ImgDISH from '@/assets/images/Logos/Services/img-dish.png';
    import ImgIZZI from '@/assets/images/Logos/Services/img-izzi.png';
    import ImgTelcel20 from '@/assets/images/Logos/Services/img-telcel-20.png';
    import ImgTelcel30 from '@/assets/images/Logos/Services/img-telcel-30.png';
    import ImgTelcel50 from '@/assets/images/Logos/Services/img-telcel-50.png';
    import ImgTelcel80 from '@/assets/images/Logos/Services/img-telcel-80.png';
    import ImgTelcel100 from '@/assets/images/Logos/Services/img-telcel-100.png';
    import ImgMovistar30 from '@/assets/images/Logos/Services/img-movistar-30.png';
    import ImgMovistar50 from '@/assets/images/Logos/Services/img-movistar-50.png';
    import ImgMovistar100 from '@/assets/images/Logos/Services/img-movistar-100.png';
    import ImgAtt30 from '@/assets/images/Logos/Services/img-att-30.png';
    import ImgAtt50 from '@/assets/images/Logos/Services/img-att-50.png';
    import ImgAtt100 from '@/assets/images/Logos/Services/img-att-100.png';
    import ImgSpotify1 from '@/assets/images/Logos/Services/img-spotify-1.png';
    import ImgSpotify2 from '@/assets/images/Logos/Services/img-spotify-2.png';
    import ImgSpotify3 from '@/assets/images/Logos/Services/img-spotify-3.png';
    import ImgNetflix from '@/assets/images/Logos/Services/img-netflix.png';
    import ImgHbo from '@/assets/images/Logos/Services/img-hbo.png';
    import ImgCinepolis10 from '@/assets/images/Logos/Services/img-cinepolis-10.png';
    import ImgCinepolis1 from '@/assets/images/Logos/Services/img-cinepolis-1.png';
    import ImgBlim from '@/assets/images/Logos/Services/img-blim.png';
    import ImgPlaystation from '@/assets/images/Logos/Services/img-playstation.png';
    import ImgXbox600 from '@/assets/images/Logos/Services/img-xbox-600.png';
    import ImgXbox1000 from '@/assets/images/Logos/Services/img-xbox-1000.png';
    import ImgSteam200 from '@/assets/images/Logos/Services/img-steam-200.png';
    import ImgSteam350 from '@/assets/images/Logos/Services/img-steam-350.png';
    import {GET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";
    import {mapGetters} from "vuex";

    export default {
        name: "ServicesPayment",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            TitleBadge,
            BasicInput,
            CardSimple,
            EmptyState,
            PanelService,
            LoadingBar,
            TelInput
        },
        data() {
            return {
                // Images
                ImgCFE: ImgCFE,
                ImgJMAS: ImgJMAS,
                ImgTELMEX: ImgTELMEX,
                ImgTOTAL: ImgTOTAL,
                ImgDISH: ImgDISH,
                ImgIZZI: ImgIZZI,
                ImgTelcel20: ImgTelcel20,
                ImgTelcel30: ImgTelcel30,
                ImgTelcel50: ImgTelcel50,
                ImgTelcel80: ImgTelcel80,
                ImgTelcel100: ImgTelcel100,
                ImgMovistar30: ImgMovistar30,
                ImgMovistar50: ImgMovistar50,
                ImgMovistar100: ImgMovistar100,
                ImgAtt30: ImgAtt30,
                ImgAtt50: ImgAtt50,
                ImgAtt100: ImgAtt100,
                ImgSpotify1: ImgSpotify1,
                ImgSpotify2: ImgSpotify2,
                ImgSpotify3: ImgSpotify3,
                ImgNetflix: ImgNetflix,
                ImgHbo: ImgHbo,
                ImgCinepolis10: ImgCinepolis10,
                ImgCinepolis1: ImgCinepolis1,
                ImgBlim: ImgBlim,
                ImgPlaystation: ImgPlaystation,
                ImgXbox600: ImgXbox600,
                ImgXbox1000: ImgXbox1000,
                ImgSteam200: ImgSteam200,
                ImgSteam350: ImgSteam350,
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
                sections: [],
                loading: false,
                language: null,
                finalServices: [],
                perPage: 10,
                page: 0,
                loadingSection: false,
                noScroll: true,
                search: null,
                timeoutSearch: null,
                fieldsToShow: [],
                selectedService: null,
                loadingBalance: false
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            async getSections() {
                this.loading = true;
                const res = await CmsApi.getSections().catch(catchError);

                if(res && res.data && !res.data.error) {
                    const sections = res.data.object.sections;

                    for(let i = 0; i < sections.length; i++) {
                        sections[i].index = i;
                        this.sections.push(sections[i]);

                        if(i === 0) {
                            sections[i].active = true;
                            await this.getSectionServices(i);
                        }
                    }

                } else {
                    this.toastErrorDefault(this.$t('services.errors.getSections'));
                }

                this.loading = false;
            },
            async getSectionServices(section) {
                this.loadingSection = true;
                const res = await CmsApi.getDataBySection(this.language, section,
                    {pageSize: this.perPage, page: this.page, search: this.search}).catch(catchError);
                if(res && res.data && !res.data.error) {
                    const services = res.data.object.elements.elements[0].hits;
                    const section1 = res.data.object.section;
                    section1.index = section;

                    if(this.finalServices.length === 0) {
                        this.finalServices = services;
                    } else {
                        const aux = [...this.finalServices, ...services];
                        this.finalServices = aux;
                    }

                } else {
                    this.toastErrorDefault(this.$t('services.errors.getDetails'));
                }
                this.loadingSection = false;

            },
            getSectionTitle(title) {
                if(this.language === 'es') {
                    return title.es;
                }

                return title.en;
            },
            isActive(index) {
                const active = this.sections.filter(s => s.index === index)[0];
                if(active.active) {
                    return 'active';
                }

                return '';
            },
            async changeSectionActive(section) {
                this.page = 0;
                this.noScroll = true;
                this.finalServices = [];
                for(const element of this.sections) {
                    element.active = element.index === section;
                }
                await this.getSectionServices(section);
            },
            async searchService() {
                clearTimeout(this.timeoutSearch);

                this.timeoutSearch = setTimeout(async () => {
                    this.page = 0;
                    this.noScroll = true;
                    this.finalServices = [];
                    const active = this.sections.filter(s => s.active === true)[0];

                    await this.getSectionServices(active.index);
                }, 1500);
            },
            getImage(service) {
                const images = service.images;

                if (images.length === 0) {
                    return service.organization.image;
                }

                return images[0]
            },
            selectService(service) {
                this.selectedService = service;
                const params = service._source.params;
                this.fieldsToShow = params ? params : [];
                this.$bvModal.show('modal-service-type');
            },
            _switchInputValues(field) {
                let aux = 0;
                switch(field.type) {
                    case 'PHONE':
                        if(field.value.length < 12 || field.value.length > 17) {
                            aux++;
                        }
                        break;
                    case 'BARCODE':
                        if(field.value.length <= 0 ) {
                            aux++;
                        }
                        break;
                    default:
                        if(field.value.length <= 0) {
                            aux++
                        }
                        break;
                }

                return aux;
            },
            checkInputsValidate() {

                const valids = this.fieldsToShow.filter(f => !f.value);

                return valids.length > 0;
            },
            async checkData() {
                if(!this.checkInputsValidate()) {
                    this.loadingBalance = true;
                    const card = this.account.cards.find((c) => c.card.manufacturingType === 'VIRTUAL');
                    const res = await WSApi.balance({paymentId: this.selectedService._id, fields: this.fieldsToShow, cardId: card?.card?._id}).catch(catchError);
                    if(res && res.data && !res.data.error) {
                        const objRes = res.data.object;

                        const service = this.selectedService._source;

                        this.$router.push({name: 'ServicesPaymentSummary', params: {balance: objRes, service: service, serviceId: this.selectedService._id}});
                    } else {
                        if(res.status === 400) {
                            this.$router.push({name: 'ServicesPaymentSummary', params: {showNoDebt: true}});

                        } else {
                            this.toastErrorDefault(this.$t('services.errors.continueWithPay'));
                        }
                        this.$bvModal.hide('modal-service-type');
                    }

                    this.loadingBalance = false;
                } else {
                    this.toastErrorDefault(this.$t('services.errors.fillData'));
                }
            },
            changeField(event, field) {
                switch(field.type) {
                    case 'PHONE':
                        if(event.length > 12 || event.length < 17) {
                            field.value = event;
                        }
                        break;
                    case 'BARCODE':
                        if(event.length > 3) {
                            field.value = event;
                        }
                        break;
                    default:
                        if(event.length > 1) {
                            field.value = event;
                        }
                        break;
                }
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                account: GET_ACCOUNT,
            }),
        },
        async mounted () {
            this.language = this.$i18n.locale;
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);

            await this.getSections();

            const listElm = document.querySelector('#sectionScroll');

            listElm.addEventListener('scroll', () => {
                (async () => {
                    if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
                    this.noScroll = false;
                    const tabActive = this.sections.findIndex(s => s.active);

                    this.page++;
                    await this.getSectionServices(tabActive);


                }
                })();

            });
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
