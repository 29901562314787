<template>
  <div class="row col-12">
    <div class="col-12">
      <TitleSteps :title="$t('myAccount.title')">
      </TitleSteps>
    </div>

    <div class="col-12">
      <PanelMyAccount
        :primaryTitle="$t('general.CLABE')"
        :primaryText="info.general.account.clabe"
        :secondTitle="$t('myAccount.noCuenta')"
        :secondText="info.general.account.fullKey"
      >
        <AccountType clazz="p-relative imgLeft" :text="$t('myAccount.title')" :typeAccount="accountDescription">
          <img class="img-fluid" :src="accountImage" width="64" height="40" :alt="accountDescription"/>
        </AccountType>
      </PanelMyAccount>
    </div>
    <div class="col-12">
      <b-tabs class="simple-tabs" content-class="simple-tabs-body p-t-15">
        <b-tab :title="$t('myAccount.generalInfo.titleTap')">
          <CorporateActivityGeneralInfo :info="info"/>
        </b-tab>
        <b-tab :title="$t('myAccount.cashWithdrawal.titleTap')">
          <CorporateActivityCashWithdrawal :info="info"/>
        </b-tab>
        <b-tab :title="$t('myAccount.bankStatements.tittleTap')" lazy>
          <CorporativeActivityBankStatements :info="info"/>
        </b-tab>
        <b-tab :title="$t('myAccount.limits.titleTap')" lazy>
          <CorporateActivityOperationalLimits :info="info"/>
        </b-tab>
      </b-tabs>
    </div>

  </div>
</template>

<script>
import TitleSteps from "@/components/TitleSteps.vue";
import AccountType from "@/components/AccountType.vue";
import PanelMyAccount from "@/components/PanelMyAccount.vue";
import CorporateActivityGeneralInfo from "@/components/corporative/CorporateActivityGeneralInfo";
import CorporateActivityCashWithdrawal from "@/components/corporative/CorporateActivityCashWithdrawal";
import CorporativeActivityBankStatements from "@/components/corporative/CorporativeActivityBankStatements";
import CorporateActivityOperationalLimits from "@/components/corporative/CorporateActivityOperationalLimits";

import { CARD_DEFAULT_IMG } from "@/enums/defalutCardImg.enum";

import { imageDownloadLink } from "@/utils/utils";

export default {
  name: "CorporateActivityMyAccount",
  components: {
    TitleSteps,
    AccountType,
    PanelMyAccount,
    CorporateActivityGeneralInfo,
    CorporateActivityCashWithdrawal,
    CorporativeActivityBankStatements,
    CorporateActivityOperationalLimits,
  },
  props: {
    info: Object,
  },
  computed: {
    accountDescription() {
      return this.info.general?.accountType?.description;
    },
    accountImage() {
      const defaultImage = CARD_DEFAULT_IMG.pagandoExplore;
      const template = this.info.general?.accountType?.template;
      if(!template) {
        return defaultImage;
      }

      if(template.image) {
        return imageDownloadLink(this.info.general.accountType.template.image);
      }

      switch (template.product) {
        case "EXPLORE":
          return CARD_DEFAULT_IMG.pagandoExplore;
        case "EXPLORE_PLUS":
          return CARD_DEFAULT_IMG.pagandoExplorePlus;
        case "NIGHT":
          return CARD_DEFAULT_IMG.pagandoNight;
        case "BUSINESS":
          return CARD_DEFAULT_IMG.pagandoBusiness;
        case "BUSINESS_PRO":
          return CARD_DEFAULT_IMG.pagandoBusinessPro;
        default:
          return defaultImage;
      }
    },
  },
};
</script>
