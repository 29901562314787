<template>
  <div>
    <CardSimple hideTitle>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="title-card">
            <label>
              {{ $t('myAccount.cashWithdrawal.title') }}
              <p>{{ $t('myAccount.cashWithdrawal.subtitle') }}</p>
            </label>
          </div>
          <b-alert class="alert-note" show variant="info" v-if="limitAtm.hasLimit || limitCash.hasLimit">
            <p class="text-container">
              <i aria-hidden="true" class="icon iconsax-emails-message-bold"></i>
              <span>
                <span v-if="limitAtm.hasLimit">
                  <span v-html="$t('myAccount.cashWithdrawal.label1')"></span>
                  <strong>{{ limitAtm.periodicity }}</strong>
                  {{ $t('myAccount.cashWithdrawal.label2') }}
                  {{limitAtm.cost | currency}}
                  {{ $t('myAccount.cashWithdrawal.label3_1') }}
                </span>
                <br v-if="limitAtm.hasLimit"/>
                <br v-if="limitAtm.hasLimit"/>
                <span v-if="limitCash.hasLimit">
                  <span v-html="$t('myAccount.cashWithdrawal.label1')"></span>
                  <strong>{{ limitCash.periodicity }}</strong>
                  {{ $t('myAccount.cashWithdrawal.label2') }}
                  {{limitCash.cost | currency}}
                  {{ $t('myAccount.cashWithdrawal.label3_2') }}
                </span>

            </span>
            </p>
          </b-alert>
        </div>
        <div class="col-12 col-md-6">
          <SidebarRightLink
            clazz="pink"
            icon="iconsax-money-currency-circle"
            :titleText="$t('myAccount.cashWithdrawal.sideLink.labelAtmRed')"
          >
            <template v-slot:text>
              <p v-html="$t('myAccount.cashWithdrawal.sideLink.atmRed')"></p>
            </template>
            <a
              class=""
              tabindex=""
              aria-label="Consulta de saldo en cajeros"
              @click="openBalanceInquiry"
            >
              <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
            </a>
          </SidebarRightLink>
          <SidebarRightLink
            v-if="canWithdrawFromATM"
            clazz="success"
            icon="iconsax-money-cash"
            :titleText="$t('myAccount.cashWithdrawal.sideLink.labelAtmWithdrawal')"
          >
            <template v-slot:text v-if="limitAtm.cost > 0">
              <p>
                {{$t('myAccount.cashWithdrawal.sideLink.atmWithdrawal')}}
                <strong>{{limitAtm.cost | currency}}</strong>
              </p>
            </template>
            <a
              class=""
              tabindex=""
              aria-label="Retiro en cajeros en RED"
              @click="openModalWithdrawal"
            >
              <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
            </a>
          </SidebarRightLink>
          <SidebarRightLink
            v-if="canWithdrawFromEstablishment"
            clazz="primary"
            icon="iconsax-shop-shop"
            :titleText="$t('myAccount.cashWithdrawal.sideLink.labelAtmStores')"
          >
            <template v-slot:text v-if="limitCash.cost > 0">
              <p>
                {{$t('myAccount.cashWithdrawal.sideLink.atmStores')}}
                <strong>{{ limitCash.cost | currency}}</strong>
              </p>
            </template>
            <a
              class=""
              tabindex=""
              aria-label="Retiro en establecimientos"
              @click="openModalWithdrawalCash"
            >
              <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
            </a>
          </SidebarRightLink>
        </div>
      </div>
    </CardSimple>

    <!-- MODAL CONSULTA SALDO -->
    <b-modal
        id="modal-consulta-saldo"
        size="lg"
        hide-header
        hide-footer
        modal-class="modal-default left-oriented">
      <button class="close-modal"
              @click="$bvModal.hide('modal-consulta-saldo')"
              aria-label="Boton cerrar ventana"
              name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{ $t('myAccount.cashWithdrawal.modals.balance_inquiry.title') }}</h2>
        <p class="modal-subtitle" v-html="$t('myAccount.cashWithdrawal.modals.balance_inquiry.subtitle')"></p>
      </div>
      <div class="body">
        <div class="screen-web">
          <img class="img-fluid m-0-auto" src="@/assets/images/Logos/Consulta-retiro-lg.svg" width="700" height="638"
               alt="Cajeros en RED"/>
        </div>
        <div class="screen-movil">
          <img class="img-fluid m-0-auto" src="@/assets/images/Logos/Consulta-retiro-xs.svg" width="256" height="1718"
               alt="Cajeros en RED"/>
        </div>
        <b-alert v-if="costBalanceInquiry <=0" class="alert-note" show variant="info m-t-30 m-b-0">
          <p class="text-container">
            <i
                aria-hidden="true"
                class="icon iconsax-emails-message-bold"
            ></i>
            <span v-html="$t('myAccount.cashWithdrawal.modals.warning1')"></span>
          </p>
        </b-alert>
        <b-alert v-else class="alert-note" show variant="info m-t-30 m-b-0">
          <p class="text-container">
            <i
                aria-hidden="true"
                class="icon iconsax-emails-message-bold"
            ></i>
            <span v-html="$t('myAccount.cashWithdrawal.modals.warning2', {cost: costBalanceInquiry})"></span>
          </p>
        </b-alert>
      </div>
    </b-modal>

    <!-- MODAL RETIRO EFECTIVO CAJERO -->
    <b-modal
        id="modal-retiro-cajero"
        size="lg"
        hide-header
        hide-footer
        modal-class="modal-default left-oriented">
      <button class="close-modal"
              @click="$bvModal.hide('modal-retiro-cajero')"
              aria-label="Boton cerrar ventana"
              name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{ $t('myAccount.cashWithdrawal.modals.withdrawalModal.title') }}</h2>
        <p class="modal-subtitle">
          {{$t('myAccount.cashWithdrawal.modals.withdrawalModal.subtitle')}}
          <strong class="c-primary poppins-font-bold f-700" v-if="limitAtm.hasLimit">{{limitAtm.cost | currency}} {{limitAtm.periodicity}}.</strong>
          <strong class="c-primary poppins-font-bold f-700" v-else>$8,000.00 al día.</strong>
        </p>
      </div>
      <div class="body">
        <div class="screen-web">
          <img class="img-fluid m-0-auto" src="@/assets/images/Logos/Consulta-retiro-lg.svg" width="700" height="638"
               alt="Cajeros en RED"/>
        </div>
        <div class="screen-movil">
          <img class="img-fluid m-0-auto" src="@/assets/images/Logos/Consulta-retiro-xs.svg" width="256" height="1718"
               alt="Cajeros en RED"/>
        </div>
        <b-alert v-if="costAtmInquiry <=0" class="alert-note" show variant="info m-t-30 m-b-0">
          <p class="text-container">
            <i
                aria-hidden="true"
                class="icon iconsax-emails-message-bold"
            ></i>
            <span v-html="$t('myAccount.cashWithdrawal.modals.warning3')"></span>
          </p>
        </b-alert>
        <b-alert v-else class="alert-note" show variant="info m-t-30 m-b-0">
          <p class="text-container">
            <i
                aria-hidden="true"
                class="icon iconsax-emails-message-bold"
            ></i>
            <span v-html="$t('myAccount.cashWithdrawal.modals.warning4', {cost: costAtmInquiry})"></span>
          </p>
        </b-alert>
      </div>
    </b-modal>

    <!-- MODAL RETIRO EFECTIVO ESTABLECIMIENTO -->
    <b-modal
        id="modal-retiro-establecimiento"
        size="lg"
        hide-header
        hide-footer
        modal-class="modal-default left-oriented">
      <button class="close-modal"
              @click="$bvModal.hide('modal-retiro-establecimiento')"
              aria-label="Boton cerrar ventana"
              name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{$t('myAccount.cashWithdrawal.modals.store.title')}}</h2>
        <p class="modal-subtitle">
          {{$t('myAccount.cashWithdrawal.modals.store.subtitle1')}}
          <strong class="c-primary poppins-font-bold f-700">{{ limitCash.cost | currency }}</strong>
          {{limitCash.periodicity}} {{$t('myAccount.cashWithdrawal.modals.store.subtitle2')}}
        </p>
      </div>
      <div class="body">
        <div v-if="loadStore">
          <loading-bar :loadingText="$t('myAccount.cashWithdrawal.modals.loading')"></loading-bar>
        </div>
        <div class="screen-web max-h-410px y-scroll" v-if="!loadStore" ref="screenWebRefWeb">
          <div class="col-md-3 m-b-30" v-for="store in establishments" :key="store._id">
            <img class="img-fluid m-0-auto" :src="store.src" width="200" height="200" alt="Establecimientos"/>
          </div>
          <loading-bar v-if="busyLoad && !noMore" :loadingText="$t('myAccount.cashWithdrawal.modals.loading')"></loading-bar>
        </div>
        <div class="screen-movil max-h-300px y-scroll" v-if="!loadStore" ref="screenWebRefMobil">
          <div class="col-sm-6 m-b-10 center-item" v-for="store in establishments" :key="store._id">
            <img class="img-fluid m-0-auto" :src="store.src" width="100" height="100" alt="Establecimientos"/>
          </div>
          <loading-bar v-if="busyLoad && !noMore" :loadingText="$t('myAccount.cashWithdrawal.modals.loading')"></loading-bar>
        </div>
        <b-alert v-if="costCash<=0" class="alert-note" show variant="info m-t-30 m-b-0">
          <p class="text-container">
            <i
                aria-hidden="true"
                class="icon iconsax-emails-message-bold"
            ></i>
            <span v-html="$t('myAccount.cashWithdrawal.modals.store.warning1')"></span>
          </p>
        </b-alert>
        <b-alert v-else class="alert-note" show variant="info m-t-30 m-b-0">
          <p class="text-container">
            <i
                aria-hidden="true"
                class="icon iconsax-emails-message-bold"
            ></i>
            <span v-html="$t('myAccount.cashWithdrawal.modals.store.warning2', {commission: costCash})"></span>
          </p>
        </b-alert>
      </div>
    </b-modal>

  </div>
</template>

<script>
import CardSimple from "@/components/CardSimple";
import LoadingBar from "@/components/LoadingBar.vue";
import SidebarRightLink from "@/components/SidebarRightLink";

import {catchError, findRef} from "@/utils/utils";

import CardApi from "@/api/wallet/card.api";
import EstablishmentsApi from "@/api/core/establishments.api";

export default {
  name: "CorporateActivityCashWithdrawal",
  components: {
    CardSimple,
    LoadingBar,
    SidebarRightLink,
  },
  props: {
    info: Object,
  },
  data() {
    return {
      costBalanceInquiry: 0,
      costAtmInquiry: 0,
      costCash: 0,
      establishments: [],
      loadStore: false,
      currentPage: 1,
      busyLoad: false,
      noMore: false
    }
  },
  computed: {
    canWithdrawFromATM() {
      return this.info.corporativeLimits?.some?.(limit => limit.type === 'ATM_CASH_WITHDRAWAL');
    },
    canWithdrawFromEstablishment() {
      return this.info.corporativeLimits?.some?.(limit => limit.type === 'CASH_WITHDRAWAL_STORES');
    },
    limitCash() {
      const limit = this.info.corporativeLimits.find((l) => l.type === "CASH_WITHDRAWAL_STORES");
      return {
        hasLimit: !!limit,
        periodicity: limit ? this.getTime(limit.periodicity) : '',
        cost: limit?.limit || 0,
      };
    },
    limitAtm() {
      const limit = this.info.corporativeLimits.find((l) => l.type === "ATM_CASH_WITHDRAWAL");
      return {
        hasLimit: !!limit,
        periodicity: limit ? this.getTime(limit.periodicity) : '',
        cost: limit?.limit || 0,
      };
    },
  },
  methods: {
    getTime(periodicity) {
      switch (periodicity) {
        case "MONTHLY":
          return this.$i18n.t('myAccount.cashWithdrawal.MONTHLY');
        case "QUARTERLY":
          return this.$i18n.t('myAccount.cashWithdrawal.QUARTERLY');
        case "BIANNUAL":
          return this.$i18n.t('myAccount.cashWithdrawal.BIANNUAL');
        case "ANNUAL":
          return this.$i18n.t('myAccount.cashWithdrawal.ANNUAL');
        case "DAILY":
          return this.$i18n.t('myAccount.cashWithdrawal.DAILY');
        default:
          return this.$i18n.t('myAccount.cashWithdrawal.byEvent');
      }
    },

    async getCommission(commission, current) {
      if (current <= 0) {
        const accountId = this.info.general.accountId;
        const res = await CardApi.getCommissionForEvent({
          pagandoAccount: accountId,
          commission: commission,
        }).catch(catchError);
        if (res.data && !res.data.error && res.data.object){
          if (commission === "BALANCE_INQUIRY") {
            this.costBalanceInquiry = res.data.object;
          } else if (commission === "CASH_ATM") {
            this.costAtmInquiry = res.data.object;
          } else {
            this.costCash = res.data.object;
          }
        }
      }
    },
    async openBalanceInquiry() {
      this.$bvModal.show('modal-consulta-saldo')
      await this.getCommission("BALANCE_INQUIRY", this.costBalanceInquiry);
    },
    async openModalWithdrawal() {
      this.$bvModal.show('modal-retiro-cajero');
      await this.getCommission("CASH_ATM", this.costAtmInquiry);
    },
    initInfinityScroll(doc) {
      doc.addEventListener("scroll", (event) => {
        const element = event.target;
        const current = element.scrollHeight - element.scrollTop;
        if (!this.busyLoad && !this.noMore && (current <= (element.clientHeight + 20))) {
          this.busyLoad = true;
          this.loadMore();
        }
      });
    },
    findRefs(name) {
      findRef(this, name).then((screen) => {
        const display = window.getComputedStyle(screen).display
        if (display !== 'none') {
          this.initInfinityScroll(screen);
        }
      });
    },
    async loadMore() {
      this.busyLoad = true;
      this.currentPage += 1;
      const res = await EstablishmentsApi.getActivesPaginated({
        action: "WITHDRAWAL",
        perPage: 12,
        currentPage: this.currentPage,
        signedUrl: true,
        provider: "PAGANDITO",
        accountId: this.info.general.accountId,
      }).catch(catchError);
      if (res && res.data && !res.data.error && res.data.object) {
        const newElements = res.data.object.docs || [];
        this.noMore = !newElements.length;
        this.establishments.push(...newElements);
      } else {
        this.noMore = true;
      }
      this.busyLoad = false;
    },
    async openModalWithdrawalCash() {
      this.currentPage = 1;
      this.loadStore = true
      this.$bvModal.show('modal-retiro-establecimiento');
      await this.getCommission("CASH_COMMERCE", this.costCash);
      if (!this.establishments.length) {
        const res = await EstablishmentsApi.getActivesPaginated({
          action: "WITHDRAWAL",
          perPage: 12,
          currentPage: this.currentPage,
          signedUrl: true,
          provider: "PAGANDITO",
          accountId: this.info.general.accountId,
        }).catch(catchError);
        if (res && res.data && !res.data.error && res.data.object) {
          this.establishments = res.data.object.docs || []
        } else {
          this.displayNotificationWarning(this.$i18n.t('myAccount.cashWithdrawal.modals.store.error'));
        }
      }
      if (this.establishments.length >= 12 && !this.noMore) {
        this.findRefs("screenWebRefWeb");
        this.findRefs("screenWebRefMobil");
      }
      this.loadStore = false;
    }
  },
}
</script>
