<template>
  <div class="collapse-beneficiary">
    <div class="head">
      <span>
        <BeneficiaryInfo
          :text="collaborator.email"
          :textBold="collaborator.name"
        >
        </BeneficiaryInfo>
      </span>
      <span>
        <span :class="getBadgeClass(value)">
          <i aria-hidden="true" class="badge-icon iconbullet"></i>
          {{ $t(`collaborators.badge.${value.badge || value.status}`) }}
        </span>
      </span>
      <span>
        <button
          id=""
          class="button-square xs button-stroke button-accent"
          type="button"
          :name="$t('collaborators.actions.collapse')"
          :aria-label="$t('collaborators.actions.collapse')"
          v-b-toggle="`collapse-collaborator-${key}`"
        >
          <i aria-hidden="true" class="iconsax-arrow-direction-down"></i>
          <i aria-hidden="true" class="iconsax-arrow-direction-up"></i>
        </button>
      </span>
    </div>

    <!-- Autorizar -->
    <b-collapse :id="`collapse-collaborator-${key}`" class="body" v-if="canAuthorizeCollaborator">
      <div class="row">
        <div class="col-12">
          <div id="" class="basic-table">
            <b-table-simple responsive table-variant="xl">
              <b-thead>
                <b-tr class="">
                  <b-th class="w-60"> {{$t('collaborators.headers.permissions')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.read')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.request')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.authorize')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(type, index) in permissionTypes" :key="index">
                  <b-td class="w-60">
                    {{ $t(`collaborators.permissionTypes.${type}`) }}
                  </b-td>
                  <b-td
                    v-for="(action, index) in permissionActions"
                    :key="index"
                  >
                    <BasicCheckbox
                      clazz="w-fit m-0-auto"
                      inputClass="m-0"
                      :value="checkPermission(collaborator, action, type)"
                      :index="index"
                      disabled
                    ></BasicCheckbox>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
        <div class="control-buttons buttons-full-w buttons-full-w-xs">
          <button
            v-if="!rejecting"
            type="button"
            class="button button-stroke button-primary w-100"
            @click="reject"
          >
            {{rejectButtonText}}
          </button>
          <button
            v-else
            type="button"
            class="button button-filled button-primary w-100"
          >
            <LoadingSimple clazz="small-load no-text" />
          </button>
          <button
            v-if="!authorizing"
            type="button"
            class="button button-filled button-primary w-100"
            @click="authorize"
          >
            {{authorizeButtonText}}
          </button>
          <button
            v-else
            type="button"
            class="button button-filled button-primary w-100"
          >
            <LoadingSimple clazz="small-load no-text" />
          </button>
        </div>
      </div>
    </b-collapse>

    <!-- Mensaje de solicitud pendiente -->
    <b-collapse :id="`collapse-collaborator-${key}`" class="body" v-else-if="!editing" :visible="collaborator.beginVisible">
      <div class="row">
        <div class="col-12">
          <EmptyState
            clazz="p-0"
            emptyStateDefault
            :title="$t('collaborators.pendingAuthorization.invite.title')"
            :text="$t('collaborators.pendingAuthorization.invite.text')"
            :imgUrl="imgEmptystateInvitacion"
            :imgWidth="440"
            :imgHeight="280"
            imgAlt="$t('collaborators.pendingAuthorization.invite.title')"
          >
          </EmptyState>
        </div>
        <div class="control-buttons buttons-full-w buttons-full-w-xs">
          <button
            v-if="!cancelling"
            type="button"
            class="button button-stroke button-primary w-100"
            @click="cancelRequest"
          >
            {{$t('collaborators.actions.cancelInvite')}}
          </button>
          <button
            v-else
            type="button"
            class="button button-filled button-primary w-100"
          >
            <LoadingSimple clazz="small-load no-text" />
          </button>
          <button
            type="button"
            class="button button-filled button-primary w-100"
            @click="editRequest"
          >
            {{$t('collaborators.actions.editRequest')}}
          </button>
        </div>
      </div>
    </b-collapse>

    <!-- Editar Solicitud -->
    <b-collapse :id="`collapse-collaborator-${key}`" class="body" v-else>
      <div class="row">
        <div class="col-12 col-md-6">
          <BasicInput
            required
            inputId=""
            v-model="collaborator.name"
            :label="$t('collaborators.fields.name.label')"
            :inputPlaceholder="$t('collaborators.fields.name.placeholder')"
          >
          </BasicInput>
        </div>
        <div class="col-12 col-md-6">
          <BasicInput
            required
            inputId=""
            v-model="collaborator.email"
            :label="$t('collaborators.fields.email.label')"
            :inputPlaceholder="$t('collaborators.fields.email.placeholder')"
          >
          </BasicInput>
        </div>
        <div class="col-12">
          <div id="" class="basic-table">
            <b-table-simple responsive table-variant="xl">
              <b-thead>
                <b-tr class="">
                  <b-th class="w-60"> {{$t('collaborators.headers.permissions')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.read')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.request')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.authorize')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(type, index) in permissionTypes" :key="index">
                  <b-td class="w-60">
                    {{ $t(`collaborators.permissionTypes.${type}`) }}
                  </b-td>
                  <b-td
                    v-for="(action, index) in permissionActions"
                    :key="index"
                  >
                    <BasicCheckbox
                      clazz="w-fit m-0-auto"
                      inputClass="m-0"
                      :value="checkPermission(collaborator, action, type)"
                      @input="value => onPermissionInput(value, action, type)"
                      :index="index"
                    ></BasicCheckbox>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
        <div class="control-buttons buttons-full-w buttons-full-w-xs">
          <button
            type="button"
            class="button button-stroke button-primary w-100"
            @click="cancelEdit"
          >
            {{$t('collaborators.actions.cancel')}}
          </button>
          <button
            v-if="!saving"
            type="button"
            class="button button-filled button-primary w-100"
            :disabled="$v.$invalid || areEquals(collaborator, value)"
            @click="save"
          >
            {{$t('collaborators.actions.updateRequest')}}
          </button>
          <button
            v-else
            type="button"
            class="button button-filled button-primary w-100"
          >
            <LoadingSimple clazz="small-load no-text" />
          </button>
        </div>
      </div>
    </b-collapse>
    <ConfirmModal ref="confirmModal"/>
  </div>
</template>
<script>
// @ is an alias to /src
import BeneficiaryInfo from "@/components/BeneficiaryInfo.vue";
import BasicCheckbox from "@/components/BasicCheckbox.vue";
import BasicInput from "@/components/BasicInput.vue";
import EmptyState from "@/components/EmptyState.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";
import ConfirmModal from "@/components/collaborators/ConfirmModal.vue";

import ToastSnotify from "@/mixins/toastSnotify.mixin";
import CollaboratorsMixin from "@/mixins/collaboratorPermissions.mixin";

import { required } from "vuelidate/lib/validators";
import { regexEmail } from "@/utils/regexUtils";

import imgEmptystateInvitacion from "@/assets/images/Emptystates/emptystate-invitation.svg";

import CollaboratorsApi from "@/api/wallet/collaborators.api";

const clone = (obj) => JSON.parse(JSON.stringify(obj));

export default {
  name: "CollaboratorPendingAuth",
  mixins: [ToastSnotify, CollaboratorsMixin],
  components: {
    BeneficiaryInfo,
    BasicCheckbox,
    BasicInput,
    EmptyState,
    LoadingSimple,
    ConfirmModal,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgEmptystateInvitacion,
      collaborator: null,
      editing: false,
      cancelling: false,
      saving: false,
      rejecting: false,
      authorizing: false,
    };
  },
  created() {
    this.collaborator = clone(this.value);
  },
  computed: {
    key() {
      return this.$vnode?.key?.toString() || "";
    },
    permissions() {
      return this.collaborator.permissions;
    },
    loading() {
      return this.cancelling || this.saving || this.rejecting || this.authorizing;
    },
    rejectButtonText() {
      switch(this.collaborator.status) {
        case 'PENDING_AUTHORIZATION':
          return this.$t('collaborators.actions.rejectCollaborator');
        case 'PENDING_REMOVAL':
          return this.$t('collaborators.actions.rejectRemoval');
        case 'PENDING_EDIT':
        default:
          return this.$t('collaborators.actions.rejectEdit');
      }
    },
    authorizeButtonText() {
      switch(this.collaborator.status) {
        case 'PENDING_AUTHORIZATION':
          return this.$t('collaborators.actions.authorizeCollaborator');
        case 'PENDING_REMOVAL':
          return this.$t('collaborators.actions.authorizeRemoval');
        case 'PENDING_EDIT':
        default:
          return this.$t('collaborators.actions.authorizeEdit');
      }
    },
  },
  methods: {
    areEquals: (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2),
    onPermissionInput(add, action, type) {
      if (add) {
        this.addPermission(action, type);
      } else {
        this.removePermission(action, type);
      }
    },
    addPermission(action, type) {
      if (
        action !== "READ" &&
        !this.permissions.some((p) => p.action === "READ" && p.type === type)
      ) {
        this.collaborator.permissions.push({ action: "READ", type });
      }
      this.collaborator.permissions.push({ action, type });
    },
    removePermission(action, type) {
      if (action === "READ") {
        this.$set(
          this.collaborator,
          "permissions",
          this.collaborator.permissions.filter((p) => p.type !== type)
        );
      } else {
        this.$set(
          this.collaborator,
          "permissions",
          this.collaborator.permissions.filter(
            (p) => p.action !== action || p.type !== type
          )
        );
      }
    },
    async cancelRequest() {
      if(this.loading) {
        return;
      }
      try {
        const confirmed = await this.$refs.confirmModal.confirm({type: 'cancel'});
        if(confirmed) {
          this.cancelling = true;
          await CollaboratorsApi.cancelRequest({id: this.collaborator._id});
          this.$emit('input', {...this.value, status: 'HIDDEN'});
          this.collaborator.status = 'HIDDEN';
          this.cancelling = false;
          this.toastSuccessDefault();
        }
      } catch(error) {
        this.toastErrorDefault(error);
        this.cancelling = false;
      }
    },
    editRequest() {
      this.editing = true;
    },
    cancelEdit() {
      this.collaborator = clone(this.value);
      this.editing = false;
    },
    async save() {
      if(this.loading) {
        return;
      }
      try {
        this.saving = true;
        const {_id: requestId, ...data} = this.collaborator;
        const accountId = this.accountId;
        const response = await CollaboratorsApi.save({requestId, accountId, ...data});
        this.$emit('input', response.data.object);
        this.editing = false;
        this.saving = false;
        this.toastSuccessDefault();
      } catch (error) {
        this.toastErrorDefault(error);
        this.saving = false;
      }
    },
    async reject() {
      if(this.loading) {
        return;
      }
      try {
        const rejectReason = await this.$refs.confirmModal.confirm({
          type: 'reject',
          collaborator: this.collaborator,
          requireTwoFactorAuth: true,
        });
        if(rejectReason) {
          this.rejecting = true;
          await CollaboratorsApi.reject({id: this.collaborator._id, rejectReason});
          this.$emit('input', {status: 'HIDDEN'});
          this.rejecting = false;
          this.toastSuccessDefault();
        }
      } catch (error) {
        this.toastErrorDefault(error);
        this.rejecting = false;
      }
    },
    async authorize() {
      if(this.loading) {
        return;
      }
      try {
        const confirmed = await this.$refs.confirmModal.confirm({
          type: 'authorize',
          collaborator: this.collaborator,
          requireTwoFactorAuth: true,
        });
        if(confirmed) {
          this.authorizing = true;
          const response = await CollaboratorsApi.authorize({id: this.collaborator._id});
          switch(this.value.status) {
            case 'PENDING_REMOVAL':
              this.$emit('input', {status: 'HIDDEN'});
              break;
            case 'PENDING_EDIT':
              this.$emit('input', {status: 'HIDDEN'});
              this.$emit('reloadCollaborator', response.data.object);
              break;
            case 'PENDING_AUTHORIZATION':
            default:
              this.$emit('input', response.data.object);
              break;
          }
          this.authorizing = false;
          this.toastSuccessDefault();
        }
      } catch (error) {
        this.toastErrorDefault(error);
        this.authorizing = false;
      }
    },
  },
  validations: {
    collaborator: {
      name: { required },
      email: { required, validEmail: (val) => regexEmail.test(val) },
    },
  },
};
</script>
