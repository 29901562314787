<template>
    <div class="row-dots" :class="clazz">
        <label class="row-title" :class="clazzRowTitle">{{textRowTitle}}</label>
        <p class="row-text" :class="clazzRowText">{{textRow}}</p>
    </div>
</template>

<script>
    export default {
        name: 'RowDots',
        props: {
            clazz: String,
            clazzRowTitle: String,
            clazzRowText: String,
            textRowTitle: String,
            textRow: String,
        }
    }
</script>
