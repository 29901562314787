<template>
    <div class="row">
        <div class="col-12">
            <router-link
                    :to="{name: 'Dashboard'}"
                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                    tabindex="">
                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                    {{ $t('cardRequest.reposition.virtual.replacement.back') }}
            </router-link>
            <FloatingTitleLayout :titleStrong="$t('cardRequest.reposition.virtual.replacement.header')"></FloatingTitleLayout>
        </div>
        <!-- <div class="col-12">
            <CardSimple hideTitle>
                <StatusNote
                status="default"
                title="¡Ups! Lo sentimos mucho"
                text="Actualmente <strong class='poppins-font-bold'>tienes una solicitud de cancelación de tarjeta física</strong> en curso.
                Es necesario que se complete ese proceso para poder realizar una nueva solicitud.
                Mientras tanto puedes <strong class='poppins-font-bold'>bloquear tu tarjeta</strong> para evitar que se haga mal uso de ella."
                :imgWidth="630"
                :imgHeight="300"
                imgAlt="¡Ups! Lo sentimos mucho"
                :imgUrl="replaceExploreWarning"
                >
                </StatusNote>
            </CardSimple>
        </div>
        <div class="col-12">
            <CardSimple hideTitle>
                <StatusNote
                status="default"
                title="¿Cómo solicitar el reemplazo de mi tarjeta?"
                text="Si perdiste tu tarjeta física, se ha dañado o sufriste un robo, es necesario que nos llames para reportarla e inmediatamente
                protegeremos tu fondo electrónico y realizaremos el reemplazo de tu tarjeta lo más pronto posible."
                :imgWidth="630"
                :imgHeight="300"
                imgAlt="¿Cómo solicitar el reemplazo de mi tarjeta?"
                :imgUrl="replaceExplore"
                >
                <div class="f-16 c-text_content m-t-30 text-center poppins-font-regular">
                    <strong class='poppins-font-bold'>El reemplazo y envío de tu tarjeta física tiene un costo de $0.00 MXN,</strong>
                    el cual será descontado del saldo disponible de tu cuenta.<br><br>
                    Para continuar con el proceso de cancelación y reemplazo comunicate con nosotros al
                    <strong class='poppins-font-bold'>teléfono: <span class="c-info">800 461 0119</span></strong>
                </div>
                </StatusNote>
            </CardSimple>
        </div>
        <div class="col-12">
            <CardSimple hideTitle>
                <StatusNote
                status="default"
                title="¡Ups! Lo sentimos mucho"
                text="Actualmente <strong class='poppins-font-bold'>tienes una solicitud de reposición de tarjeta física</strong> en curso.
                Es necesario que se complete ese proceso para poder realizar una nueva solicitud.
                Mientras tanto puedes <strong class='poppins-font-bold'>bloquear tu tarjeta</strong> para evitar que se haga mal uso de ella."
                :imgWidth="630"
                :imgHeight="300"
                imgAlt="¡Ups! Lo sentimos mucho"
                :imgUrl="replaceExploreWarning"
                >
                    <div class="m-t-30 text-center">
                        <button
                            type="button"
                            class="button button-filled button-primary m-auto">
                            Ver estatus de reposición
                        </button>
                    </div>
                </StatusNote>
            </CardSimple>
        </div>
        <div class="col-12">
            <CardSimple hideTitle>
                <StatusNote
                status="default"
                title="¡Ups! Lo sentimos mucho"
                text="Actualmente <strong class='poppins-font-bold'>tienes una solicitud de envío de tarjeta física</strong> en curso.
                Es necesario que se complete ese proceso para poder realizar una nueva solicitud.
                Mientras tanto puedes <strong class='poppins-font-bold'>bloquear tu tarjeta</strong> para evitar que se haga mal uso de ella."
                :imgWidth="630"
                :imgHeight="300"
                imgAlt="¡Ups! Lo sentimos mucho"
                :imgUrl="replaceExploreWarning"
                >
                    <div class="m-t-30 text-center">
                        <button
                            type="button"
                            class="button button-filled button-primary m-auto">
                            Ver estatus de envío
                        </button>
                    </div>
                </StatusNote>
            </CardSimple>
        </div>
        <div class="col-12">
            <CardSimple hideTitle>
                <StatusNote
                status="default"
                title="¡Ups! Lo sentimos mucho"
                text="Actualmente <strong class='poppins-font-bold'>tienes una solicitud de cancelación de tarjeta virtual</strong> en curso.
                Es necesario que se complete ese proceso para poder realizar una nueva solicitud.
                Mientras tanto puedes <strong class='poppins-font-bold'>bloquear tu tarjeta</strong> para evitar que se haga mal uso de ella."
                :imgWidth="630"
                :imgHeight="300"
                imgAlt="¡Ups! Lo sentimos mucho"
                :imgUrl="VirtualBusinessProWarning"
                >
                </StatusNote>
            </CardSimple>
        </div>
        <div class="col-12">
            <CardSimple hideTitle>
                <StatusNote
                status="default"
                title="¿Cómo solicitar el reemplazo de mi tarjeta?"
                text="Si sufriste de un robo, o has tenido problemas con tu tarjeta virtual, puedes llamarnos
                para reportarla y realizar el reemplazo de tu tarjeta lo más pronto posible."
                :imgWidth="630"
                :imgHeight="300"
                imgAlt="¿Cómo solicitar el reemplazo de mi tarjeta?"
                :imgUrl="VirtualBusinessPro"
                >
                <div class="f-16 c-text_content m-t-30 text-center poppins-font-regular">
                    <strong class='poppins-font-bold'>El reemplazo de tu tarjeta virtual tiene un costo de $150.00 MXN,</strong>
                    el cual será descontado del saldo disponible de tu cuenta.<br><br>
                    Para continuar con el proceso de cancelación y reemplazo comunicate con nosotros al
                    <strong class='poppins-font-bold'>teléfono: <span class="c-info">800 461 0119</span></strong>
                </div>
                </StatusNote>
            </CardSimple>
        </div>
        <div class="col-12">
            <CardSimple hideTitle>
                <StatusNote
                status="default"
                title="¡Ups! Lo sentimos mucho"
                text="Actualmente <strong class='poppins-font-bold'>tienes una solicitud de reposición de tarjeta virtual</strong> en curso.
                Es necesario que se complete ese proceso para poder realizar una nueva solicitud.
                Mientras tanto puedes <strong class='poppins-font-bold'>bloquear tu tarjeta</strong> para evitar que se haga mal uso de ella."
                :imgWidth="630"
                :imgHeight="300"
                imgAlt="¡Ups! Lo sentimos mucho"
                :imgUrl="VirtualBusinessProWarning"
                >
                    <div class="m-t-30 text-center">
                        <button
                            type="button"
                            class="button button-filled button-primary m-auto">
                            Ver estatus de reposición
                        </button>
                    </div>
                </StatusNote>
            </CardSimple>
        </div> -->
        <div class="col-12">
            <CardSimple hideTitle>
                <StatusNote
                status="default"
                :title="$t('cardRequest.reposition.virtual.replacement.title')"
                :text="$t('cardRequest.reposition.virtual.replacement.text')"
                :imgWidth="292"
                :imgHeight="270"
                :imgAlt="$t('cardRequest.reposition.virtual.replacement.title')"
                :imgUrl="statusIllustration"
                >
                    <div class="m-t-30 text-center poppins-font-bold">
                        {{$t('cardRequest.reposition.virtual.replacement.status')}} <br>
                        <strong class="c-info f-18" v-t="'cardRequest.reposition.virtual.replacement.preparing'"/>
                    </div>
                </StatusNote>
            </CardSimple>
        </div>
        <div class="col-12">
            <div class="control-buttons">
                <button
                    type="button"
                    class="button-icon"
                    @click="$router.push({name: 'Dashboard'})"
                >
                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                    {{$t('cardRequest.reposition.virtual.replacement.backBtn')}}
                </button>
                <div class="f-13 c-text_content poppins-font-medium text-center text-md-right">
                    {{$t('cardRequest.reposition.virtual.replacement.changeAnything')}}
                    <router-link
                        to=""
                        @click.native="openZendesk"
                        class="c-info poppins-font-bold text-underline hover"
                        tabindex="">
                        {{$t('cardRequest.reposition.virtual.replacement.contactUs')}}
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // @ is an alias to /src
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import StatusNote from "@/components/StatusNote.vue";

    import replaceExplore from '@/assets/images/Illustrations/ReplaceCards/replace-explore.svg';
    import replaceExploreWarning from '@/assets/images/Illustrations/ReplaceCards/replace-explore-warning.svg';
    import VirtualBusinessProWarning from '@/assets/images/Illustrations/ReplaceCards/virtual-replace-business-pro-warning.svg';
    import VirtualBusinessPro from '@/assets/images/Illustrations/ReplaceCards/virtual-replace-business-pro.svg';
    import statusIllustration from '@/assets/images/Illustrations/illustration-processing.svg';

    import MenuHandler from "@/mixins/menuHandler.mixin";

    export default {
        name: "ReplaceCard",
        mixins: [MenuHandler],
        components: {
            FloatingTitleLayout,
            CardSimple,
            StatusNote,
        },
        data() {
            return {
                // Images
                replaceExplore,
                replaceExploreWarning,
                VirtualBusinessProWarning,
                VirtualBusinessPro,
                statusIllustration,
            }
        },
        methods: {
            openZendesk() {
                if(window.zE) {
                    window.zE('messenger', 'open');
                }
            },
        },
    };
</script>
