import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'organization-info';

export default {
    saveBasicInfo: (file, params) => {
        const formData = new FormData();
        if(file) {
            formData.append("file", file);
        }
        formData.append("name", params.name);
        formData.append("description", params.description);
        formData.append("isMoral", params.isMoral);
        formData.append("reloadImg", params.reloadImg);
        formData.append("organizationId", params.organizationId);

        return axios
            .post(`${base.baseUrl}/${namespace}/save-basic-info`, formData, {headers: { "Content-Type": "multipart/form-data" }});
    },
    getById: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/${params.id}`);
    },
    getByUserId: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/by-user/${params.id}`);
    },
    saveCountryOperates: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-country-operates`, params);
    },
    saveBusinessInfo: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-business-info`, params);
    },
    saveFiscalData: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-fiscal-data`, params);
    },
    saveFiscalAddress: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-fiscal-address`, params);
    },
    saveCommercialAddress: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-commercial-address`, params);
    },
    saveDocuments: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/save-documents`, params);
    },
    copyInfo: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/copy-invoice-info`, params);
    }
}
