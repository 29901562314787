<template>
    <div class="toggle-switch" :class="clazz">
        <div class="switch-border"></div>
        <input
            ref="input"
            :id="idInput"
            type="checkbox"
            hidden="hidden"
            v-model="switchValueComp"
            :checked="switchValueComp"
            :true-value="true"
            :false-value="false"
            @change="onBasicSwitchChange"
            :disabled="disabled"
        >
        <label
            :for="idInput"
            class="ts-helper"
        ></label>
        <label v-if="label"
                :for="idInput"
                class="textSwitch"
        >{{label}}</label>
    </div>
</template>

<script>
export default {
    name: 'BasicSwitch',
    data () {
        return {
            switchValueComp: false
        }
    },
    props: {
        clazz: String,
        idInput: String,
        label: String,
        value: Boolean,
        useVModel: { type: Boolean, default: false },
        switchValue: { type: Boolean, default: false },
        disabled: { type: Boolean, default: false },
        returnOriginValue: { type: Boolean, default: false }
    },
    watch: {
      value(){
          this.switchValueComp = this.useVModel ? this.value : this.switchValue;
      },
      returnOriginValue() {
          if(this.returnOriginValue === true) {
              this.switchValueComp = this.useVModel ? this.value : this.switchValue;
          }
      }
    },
    methods: {
        onBasicSwitchChange () {
            this.$emit('onBasicSwitchChange', this.switchValueComp);
            this.$emit('input', this.switchValueComp);
        },
        forceFalse () {
            this.switchValueComp = false;
            this.$refs.input.checked = false;
        },
        forceValue (value) {
            this.switchValueComp = value;
            this.$refs.input.checked = !!value;
        }
    },
    beforeMount() {
        this.switchValueComp = this.useVModel ? this.value : this.switchValue;
    },
}
</script>
