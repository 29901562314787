<template>
  <div>
    <CardSimple hideTitle>
      <StatusNote
        status="error"
        :title="$t('transfers.new.errorDetails.title')"
        :text="$t('transfers.new.errorDetails.text')"
        :errorText="transferError"
        :imgWidth="124"
        :imgHeight="124"
        :imgAlt="$t('transfers.new.errorDetails.title')"
        :imgUrl="imgErrorStatus"
      >
      </StatusNote>
    </CardSimple>

    <div class="control-buttons buttons-full-w-xs">
      <button type="button" class="button button-stroke button-primary" @click="openZendesk">
        <i aria-hidden="true" class="iconsax-emails-messages-2 m-r-10"></i>
        {{$t('transfers.new.errorDetails.contactSupport')}}
      </button>
      <button type="button" class="button button-filled button-primary" @click="$emit('retry')">
        {{$t('transfers.new.errorDetails.retry')}}
      </button>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import StatusNote from "@/components/StatusNote.vue";
import CardSimple from "@/components/CardSimple.vue";
import imgErrorStatus from "@/assets/images/Illustrations/illustration-status-error.svg";
import imgUserAccount from "@/assets/images/Default/profile-photo.svg";
import imgStatusFirma from "@/assets/images/Illustrations/illustration-firma.svg";

export default {
  name: "NewTransferErrorDetails",
  components: {
    StatusNote,
    CardSimple,
  },
  props: {
    contact: Object,
    amount: Number,
    concept: String,
    transferError: String,
  },
  data() {
    return {
      // Images
      imgErrorStatus: imgErrorStatus,
      imgUserAccount: imgUserAccount,
      imgStatusFirma: imgStatusFirma,
      // SidebarRight / MenuPrincipal
      window: {
        width: 0,
      },
      isMenuOpen: false,
    };
  },
  methods: {
    // Resize Sidebar Menu Principal
    handleResizeMenu() {
      this.window.width = window.innerWidth;
      if (window.innerWidth > 767) {
        this.isMenuOpen = true;
      } else {
        this.isMenuOpen = false;
      }
    },
    // Show Sidebar Menu Principal
    showMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    // Hide Sidebar Menu Principal
    hideMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    openZendesk() {
      if(window.zE) {
        window.zE('messenger', 'open');
      }
    },
  },
  mounted() {
    this.handleResizeMenu();
    window.addEventListener("resize", this.handleResizeMenu);
  },
  destroyed() {
    window.addEventListener("resize", this.handleResizeMenu);
  },
};
</script>
