<template>
  <div>
    <div class="register-container login">
      <LeftSideLogin></LeftSideLogin>
      <RightSideLogin></RightSideLogin>
    </div>
    <FooterPage></FooterPage>
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import LeftSideLogin from "@/pages/Login/LeftSideLogin";
import RightSideLogin from "@/pages/Login/RightSideLogin";
import FooterPage from "@/components/FooterPage.vue";

export default {
  name: "Login",
  components: {
    LeftSideLogin,
    RightSideLogin,
    FooterPage
  }
}
</script>
