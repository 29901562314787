import store from '@/store/index'
import {
  GET_USER,
  GET_USER_CVV_MIGRATION_STATUS,
  STORE as sessionStore
} from "@/store/modules/session/session.types";

/** Se asegura que el usuario aún no hace la unificación de pin 📌 */
export function needsPinUnification({ next }) {
  const user = store.getters[`${sessionStore}/${GET_USER}`];

  if(!user) {
    return next('/login');
  }
  if(user.hasUnifiedPin) {
    return next('/wallet');
  }
  return next();
}

/** Se asegura que el usuario aún no hace la migración a cvv dinámico 🕑🔑 */
export function needsMigrationCVV({ next }) {
  const user = store.getters[`${sessionStore}/${GET_USER}`];

  if(!user) {
    return next('/login');
  }

  const migrationStatus = store.getters[`${sessionStore}/${GET_USER_CVV_MIGRATION_STATUS}`];
  if(migrationStatus === 'FINISHED') {
    return next('/wallet');
  }

  return next();
}
