import axios from 'axios';
import base from '@/api/base.api';

const namespace = 'establishments';

export default {
    getActivesPaginated: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/actives/paginated`, {params: params});
    },
    getActives: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/actives`, {params: params});
    },
    getById: (id, params) => {
        return axios.get(`${base.baseUrl}/${namespace}/get/${id}`, {params: params});
    }
};
