<template>
  <div class="row">
    <div class="col-12 col-lg-5">
      <CardSimple hideTitle>
        <div class="row">
          <div class="col-12">
            <Tachometer :percent="currentPercentage.toString()" :text="$t('myAccount.beneficiaries.allocatedFunds')"
                        clazzContent="m-b-30"></Tachometer>
          </div>
          <div class="col-12">
            <b-alert class="alert-note" show variant="info m-b-0">
              <p class="text-container">
                <i
                    aria-hidden="true"
                    class="icon iconsax-emails-message-bold"
                ></i>
                {{ $t('myAccount.beneficiaries.infoNote') }}
              </p>
            </b-alert>
          </div>
        </div>
      </CardSimple>
    </div>
    <div class="col-12 col-lg-7">
      <CardSimple
          :title="$t('myAccount.beneficiaries.title')"
          :subtitle="$t('myAccount.beneficiaries.subtitle')">
        <div class="row">
          <div class="col-12 m-b-10" v-if="beneficiaries.length === 0">
            <button
                id="addBeneficiaries"
                type="button"
                class="button sm button-filled button-primary w-100"
                @click="addBeneficiaries"
            >
              <i aria-hidden="true" class="iconsax-essential-add m-r-10"></i>
              {{ $t('myAccount.beneficiaries.addBeneficiaries') }}
            </button>
          </div>
          <div class="col-12">
            <span class="vertical-center flex-wrap-no p-t-10 p-b-10 border-bottom-1px column-gap-10px"
                  v-for="beneficiary in beneficiaries" :key="beneficiary._id"
            >
                <BeneficiaryInfo
                    :text="getRelationship(beneficiary.relationship)"
                    :textBold="beneficiary.name"
                    :imgUrl="getImage(beneficiary.img)"
                    clazzInfo="flex-direction-column-reverse">
                </BeneficiaryInfo>
                <p class="f-18 c-text_title poppins-font-bold f-700 m-0-0-0-auto">{{beneficiary.percentage}}%</p>
            </span>
          </div>
          <div class="col-12" v-if="beneficiaries.length">
            <button
                id="editBeneficiaries"
                type="button"
                class="button sm button-stroke button-primary w-100 m-t-30"
                @click="editBeneficiaries"
            >
              {{ $t('myAccount.beneficiaries.editBeneficiaries') }}
            </button>
          </div>
        </div>
      </CardSimple>
    </div>
  </div>
</template>

<script>
import CardSimple from "@/components/CardSimple";
import Tachometer from "@/components/Tachometer";
import BeneficiaryInfo from "@/components/BeneficiaryInfo";

import imgBeneficiarioMadre from '@/assets/images/Backgrounds/Beneficiary/mujer-guinda.svg';
import imgBeneficiarioPadre from '@/assets/images/Backgrounds/Beneficiary/hombre-amarillo.svg';
import imgBeneficiarioHermana from '@/assets/images/Backgrounds/Beneficiary/mujer-amarillo.svg';
import {GET_ACCOUNT, SET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";
import {mapGetters} from "vuex";
import UsersApi from "@/api/users/users.api";
import {beneficiariesImages} from "@/enums/securityBeneficiariesImg";

export default {
  name: "Beneficiaries",
  components: {
    CardSimple,
    Tachometer,
    BeneficiaryInfo,
  },
  computed: {
    ...mapGetters(sessionStore, {
      infoAccount: GET_ACCOUNT
    }),
  },
  data() {
    return {
      imgBeneficiarioMadre: imgBeneficiarioMadre,
      imgBeneficiarioPadre: imgBeneficiarioPadre,
      imgBeneficiarioHermana: imgBeneficiarioHermana,
      beneficiaries: [],
      currentPercentage: 0,
      unsubscribe: null,
    }
  },
  methods: {
    addBeneficiaries() {
      this.$router.push({name: 'AddBeneficiaries', params: {mode: "new"}});
    },
    editBeneficiaries() {
      this.$router.push({name: 'AddBeneficiaries', params: {mode: "edit"}});
    },
    getRelationship(type) {
      switch (type) {
        case "SON":
          return this.$i18n.t('myAccount.beneficiaries.relationship.son');
        case "DAUGHTER":
          return this.$i18n.t('myAccount.beneficiaries.relationship.daughter');
        case "FATHER":
          return this.$i18n.t('myAccount.beneficiaries.relationship.father');
        case "MOTHER":
          return this.$i18n.t('myAccount.beneficiaries.relationship.mother');
        case "BROTHER":
          return this.$i18n.t('myAccount.beneficiaries.relationship.brother');
        case "SISTER":
          return this.$i18n.t('myAccount.beneficiaries.relationship.sister');
        case "SPOUSE":
          return this.$i18n.t('myAccount.beneficiaries.relationship.spouse');
        case "GRANDFATHER":
          return this.$i18n.t('myAccount.beneficiaries.relationship.grandfather');
        case "GRANDMOTHER":
        default:
          return this.$i18n.t('myAccount.beneficiaries.relationship.grandmother');
      }
    },
    getImage(img) {
      const imgBen = beneficiariesImages.find((i) => i.id === img);
      if (imgBen) {
          return imgBen.src;
      }
      return beneficiariesImages[0].src;
    },
    async loadBeneficiaries() {
      if (this.infoAccount.account._id) {
        const res = await UsersApi.beneficiaryList({
          pagandoAccount: this.infoAccount.account._id
        })
        if (res.data && !res.data.error && res.data.object) {
          this.beneficiaries = res.data.object;
          this.currentPercentage = this.beneficiaries.reduce((a, b) => a + parseFloat(b.percentage), 0);
        }
      }
    }
  },
  beforeMount() {
    this.unsubscribe = this.$watchMutation(sessionStore, SET_ACCOUNT, this.loadBeneficiaries);
  },
  beforeDestroy() {
    if(this.unsubscribe) {
      this.unsubscribe();
    }
  }
}
</script>

<style scoped>

</style>
