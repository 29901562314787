<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/settings"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('settings.goBack')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('settings.inviteFriends.title')"></FloatingTitleLayout>
                        </div>
                        <div class="col-12">
                            <CardSimple hideTitle>
                                <StatusNote
                                    status="default"
                                    :title="$t('settings.inviteFriends.ppalMessage')"
                                    :text="$t('settings.inviteFriends.ppalMessageText')"
                                    :imgWidth="284"
                                    :imgHeight="280"
                                    imgAlt="Comparte y gana"
                                    :imgUrl="imgStatus"
                                    >
                                </StatusNote>
                                <div class="copy-code">
                                    <div>
                                        <span>{{$t('settings.inviteFriends.referalCode')}}</span><br>
                                        <strong>{{code}}</strong>
                                    </div>
                                    <button
                                        @click="copy()"
                                        class="button sm button-filled button-primary"
                                        type="button">
                                        <i aria-hidden="true" class="iconsax-content-document-copy m-r-5"></i>
                                        {{$t('settings.inviteFriends.copy')}}
                                    </button>
                                </div>
                            </CardSimple>
                        </div>
                        <div class="col-12">
                            <div class="control-buttons">
                                <button
                                    @click="goBack()"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('settings.inviteFriends.back')}}
                                </button>
                                <div class="f-13 c-text_content poppins-font-medium text-center text-md-right">
                                    {{$t('settings.inviteFriends.needChange')}}
                                    <a
                                            href="https://www.pagando.mx/contacto-pagando/" target="_blank"
                                            class="c-info poppins-font-bold text-underline hover"
                                            tabindex="">
                                            {{$t('settings.inviteFriends.contactUs')}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>

    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import StatusNote from "@/components/StatusNote.vue";
    import imgStatus from '@/assets/images/Illustrations/illustration-gift.svg';
    import UsersApi from "@/api/users/users.api";
    import { catchError } from "@/utils/utils";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";

    export default {
        name: "InviteFriends",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            CardSimple,
            StatusNote
        },
        data() {
            return {
                // Images
                imgStatus: imgStatus,
                window: {
                    width: 0
                },
                isMenuOpen: false,
                code: null
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            goBack() {
                this.$router.push({name: 'Settings'});
            },
            async getCode() {
                const res = await UsersApi.getReferralCode().catch(catchError);

                if(res && res.data && !res.data.error) {
                    this.code = res.data.object;
                } else {
                    this.toastErrorDefault(this.$t('settings.inviteFriends.errorGetCode'));
                    this.$router.push({name: 'Settings'});
                }
            },
            copy() {
                navigator.clipboard.writeText(this.code);
                this.displayNotificationInfo(this.$t('settings.inviteFriends.copySuccess'));
            }
        },
        async mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);

            await this.getCode();
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        },
    };
</script>
