<template>
  <div>
    <!-- Modal Nuevo contacto otro banco -->
    <b-modal
      :id="modalId"
      size="lg"
      hide-header
      hide-footer
      modal-class="modal-default left-oriented"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-contacto-otro')"
        :aria-label="$t('transfers.beneficiaries.new.close')"
        :name="$t('transfers.beneficiaries.new.close')"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{$t('transfers.beneficiaries.new.titleExternal')}}</h2>
        <p class="modal-subtitle">{{$t('transfers.beneficiaries.new.subtitleExternal')}}</p>
      </div>
      <div class="body">
        <BasicInput
          required
          inputId=""
          v-model="rawClabe"
          :label="$t('transfers.beneficiaries.fields.clabeLabel')"
          :inputPlaceholder="$t('transfers.beneficiaries.fields.clabePlaceholder')"
        >
        </BasicInput>
<!--        <BasicInput v-if="!isNumberCard"-->
<!--          required-->
<!--          disabled-->
<!--          inputId=""-->
<!--          :label="$t('transfers.beneficiaries.fields.bankLabel')"-->
<!--          :value="bank ? bank.shortName : bankPlaceholder"-->
<!--        >-->
<!--        </BasicInput>-->
        <BasicSelect clazz="contact-table" required :label="$t('transfers.beneficiaries.fields.bankLabel')">
            <multiselect
              v-model="bank"
              :options="banks"
              :close-on-select="true"
              :preserve-search="true"
              :disabled="loadingBanks"
              :preselect-first="false"
              label="name"
              track-by="name"
            >
              <template slot="selection" slot-scope="{ values, isOpen }">
                <span
                  class="multiselect__single"
                  v-if="values.length &amp;&amp; !isOpen"
                  >{{ values.length }} options selected</span
                >
              </template>
              <template slot="placeholder">
                <span :class="loadingBanks ? 'f-600 poppins-font-semibold c-text_info' : ''">{{bankPlaceholder}}</span>
              </template>
            </multiselect>
          </BasicSelect>
        <BasicInput
          required
          inputId=""
          v-model="contactName"
          :label="$t('transfers.beneficiaries.fields.nameLabel')"
          :inputPlaceholder="$t('transfers.beneficiaries.fields.namePlaceholderCharacters')"
          :validations="$v.contactName"
          :warningText="$t('transfers.beneficiaries.fields.noSpecialCharacters')"
          warningIcon="banana banana-warning-circle1"
        >
        </BasicInput>
        <BasicInput
          required
          inputId=""
          v-model="contactAlias"
          :label="$t('transfers.beneficiaries.fields.aliasLabel')"
          :inputPlaceholder="$t('transfers.beneficiaries.fields.aliasPlaceholderNew')"
        >
        </BasicInput>
        <div class="row m-b-30">
          <div class="col-12 col-md-6">
            <BasicSwitch
                @onBasicSwitchChange="onSwitchEditChange"
                idInput="switchEditPagando"
                ref="switchEditPagando"
                :label="$t('contacts.modals.editPagando.switchSpeiOut')"
                :switchValue="limitSpeiOut"
            />
          </div>
          <div class="col-12 col-md-6" v-if="limitSpeiOut">
            <BasicInput
                money
                v-model="speiOut"
                :inputValue="speiOut.toString()"
                :label="$t('contacts.modals.editPagando.speiOut')"
            >
            </BasicInput>
          </div>
        </div>
        <BasicCheckbox
          v-if="forTransfer"
          v-model="shouldSave"
          clickableText
          :text="$t('transfers.beneficiaries.new.persist')"
        ></BasicCheckbox>
        <div v-else class="c-text_info f-12 text-center poppins-font-medium f-600">
          {{ $t('contacts.modals.newOther.warning') }}
        </div>
      </div>
      <div class="footer">
        <button
          v-if="!loading"
          type="button"
          class="button button-filled button-primary w-100"
          :disabled="$v.$invalid"
          @click="save"
        >
          {{forTransfer ? $t('transfers.beneficiaries.new.continue') : $t('contacts.modals.newOther.save')}}
        </button>
        <button
          v-else
          type="button"
          class="button button-filled button-primary w-100"
        >
          <LoadingSimple clazz="small-load no-text" />
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BasicCheckbox from "@/components/BasicCheckbox.vue";
import BasicInput from "@/components/BasicInput.vue";
import LoadingSimple from '@/components/LoadingSimple.vue';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import BankApi from '@/api/core/bank.api';
import ContactsApi from "@/api/wallet/contacts.api";
import {regexClabe, cardNumberRegex} from '@/utils/regexUtils';
import {mapGetters} from "vuex";
import { minLength, maxLength, required } from "vuelidate/lib/validators";
import {
  STORE as sessionStore,
  GET_ACCOUNT,
} from "@/store/modules/session/session.types";
import config from "@/config";
import BasicSelect from "@/components/BasicSelect.vue";
import Multiselect from "vue-multiselect";
import BasicSwitch from "@/components/BasicSwitch.vue";

export default {
  name: "ModalNewContactExternal",
  mixins: [ToastSnotify],
  components: {
    BasicSwitch,
    BasicCheckbox,
    BasicInput,
    LoadingSimple,
    BasicSelect,
    Multiselect
  },
  props: {
    forTransfer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalId: 'modal-contacto-otro',
      rawClabe: null,
      banks: [],
      contactName: null,
      contactAlias: null,
      shouldSave: false,
      loading: false,
      loadingBanks: false,
      bank: null,
      onShow: null,
      onHidden: null,
      limitSpeiOut: false,
      speiOut: 0,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
      account: GET_ACCOUNT
    }),
    clabe() {
      return (this.rawClabe || '').replace(/\s/g, '');
    },
    bankPlaceholder() {
      if(this.clabe.length < 3) {
        return this.$t('transfers.beneficiaries.fields.bankPlaceholder');
      }
      if(this.loadingBanks) {
        return this.$t('transfers.beneficiaries.new.loadingBanks');
      }
      if(this.isNumberCard) {
        return this.$t('transfers.beneficiaries.new.selectBank');
      }
      return this.$t('transfers.beneficiaries.new.noBank');
    },
    isNumberCard() {
      return cardNumberRegex.test(this.clabe);
    },
  },
  mounted() {
    this.onShow = (bvEvent, modalId) => {
      if (modalId === this.modalId) {
        this.loadBanks();
      }
    };
    this.onHidden = (bvEvent, modalId) => {
      if (modalId === this.modalId) {
        this.clearModal();
      }
    }
    this.$root.$on("bv::modal::show", this.onShow);
    this.$root.$on("bv::modal::hidden", this.onHidden);
  },
  methods: {
    open() {
      this.$bvModal.show(this.modalId);
    },
    clearModal() {
      this.rawClabe = null;
      this.contactName = null;
      this.contactAlias = null;
      this.shouldSave = false;
      this.loading = false;
      this.bank = null;
    },
    async loadBanks() {
      try {
        this.loadingBanks = true;
        const response = await BankApi.all();
        this.banks = response.data.object;
        this.loadingBanks = false;

        // Se hace este chequeo por si el usuario ingresó la clabe antes de que los bancos terminaran de cargar
        if(this.clabe && regexClabe.test(this.clabe)) {
          if (this.clabe.substring(0, 10) === config.pagando.clabePrefix) {
            this.bank = null;
          }
          const bank = this.banks.find(bank => bank.code === this.clabe.substring(0, 3));
          if(bank) {
            this.bank = bank;
          }
        }
      } catch (error) {
        this.loadingBanks = false;
        this.toastErrorDefault(error);
      }
    },
    onSwitchEditChange(value) {
      this.limitSpeiOut = value;
    },
    async save() {
      this.loading = true;
      try {
        const obj = {
          accountId: this.account.account._id,
          bank: this.bank,
          idCard: this.idCard,
          contactName: this.contactName,
          alias: this.contactAlias,
          isPagandoAccount: false,
          save: this.forTransfer ? this.shouldSave : true,
          speiOutLimit: {
            limitSpeiOut: this.limitSpeiOut,
            speiOut: this.speiOut,
          }
        };

        if(this.isNumberCard) {
          obj.cardNumber = this.clabe;
        } else {
          obj.clabe = this.clabe;
        }

        const response = await ContactsApi.save(obj);
        this.$bvModal.hide(this.modalId);
        this.$emit('continue', response.data.object, this.shouldSave);
      } catch (error) {
        this.toastErrorDefault(error);
        this.loading = false;
      }
    },
  },
  beforeDestroy() {
    this.$root.$off("bv::modal::show", this.onShow);
    this.$root.$off("bv::modal::hidden", this.onHidden);
  },
  validations() {
    return {
      contactName: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(40),
        noSpecialCharacters: (value) => /^[a-zA-Z\s]+$/.test(value),
      },
      contactAlias: {
        required,
      },
      clabe: {
        required,
        validFormat: (value) => regexClabe.test(value) || cardNumberRegex.test(value) ,
      },
      bank: {
        required,
      },
    };
  },
  watch: {
    rawClabe() {
      if(this.clabe && regexClabe.test(this.clabe)) {
        if (this.clabe.substring(0, 10) === config.pagando.clabePrefix) {
          this.displayNotificationInfo(this.$i18n.t('contacts.pagandoCLABE'))
          this.bank = null;
        }
        const bank = this.banks.find(bank => bank.code === this.clabe.substring(0, 3));
        if(bank) {
          this.bank = bank;
        }
      }
    }
  }
};
</script>


