import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'collaborators/api-v1';

export default {
    list: (accountId) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/list/${accountId}`)
    },
    changePermissions: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/change-permissions`, params)
    },
    save: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/save`, params)
    },
    authorize: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/authorize`, params)
    },
    reject: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/reject`, params)
    },
    delete: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/delete`, params)
    },
    cancelRequest: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/cancel-request`, params)
    },
    amendRequest: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/amend-request`, params)
    },
    resendInvitation: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/resend-invitation`, params)
    },
    cancelInvitation: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/cancel-invitation`, params)
    },
}
