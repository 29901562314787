<template>
  <div>
    <div class="registration-accout-type-container">
      <div class="registration-account-content">
        <div class="header-info">
          <router-link to="/login" class="button button-ghost button-info p-l-5 p-r-5 p-b-5 p-t-5" tabindex="">
            <i aria-hidden="true" class="iconsax-arrow-arrow-left m-r-10"></i>
            {{ $t('general.back')}}
          </router-link>
          <img class="img-fluid" src="@/assets/images/Logos/logo-color.svg" alt="Pagando Logo" />
        </div>

        <h1 class="title">{{ $t('register.selectAccount.title') }}</h1>
        <p class="subtitle">{{ $t('register.selectAccount.subtitle') }}</p>

        <div class="body-info">
          <div class="cards-side">
            <div class="row">
              <div class="col-12 col-md-6 col-lg-6 m-b-40" @click="selectCard(true)">
                <CardAccountType :clazz="isPersonal ? 'selected' : ''"
                                :title="$t('register.selectAccount.account')"
                                :titleStrong="$t('register.selectAccount.card_personal.title')">
                  <template v-slot:cardImg>
                    <img class="img-fluid" src="@/assets/images/Default/img-card-cuenta-personal.png" width="90" height="68" alt="Cuenta personal" />
                  </template>
                  <ul class="checked-list">
                    <li>
                      {{$t('register.selectAccount.card_personal.info1')}}
                    </li>
                    <li>
                      {{$t('register.selectAccount.card_personal.info2')}}
                    </li>
                    <li>
                      {{$t('register.selectAccount.card_personal.info3')}}
                    </li>
                    <li>
                      {{$t('register.selectAccount.card_personal.info4')}}
                    </li>
                    <li>
                      {{$t('register.selectAccount.card_personal.info5')}}
                    </li>
                    <li>
                      {{$t('register.selectAccount.card_personal.info6')}}
                    </li>
                  </ul>
                </CardAccountType>
              </div>
              <div class="col-12 col-md-6 col-lg-6 m-b-40" @click="selectCard(false)">
                <CardAccountType :clazz="isPersonal ? '' : 'selected'"
                                :title="$t('register.selectAccount.account')"
                                :titleStrong="$t('register.selectAccount.card_business.title')">
                  <template v-slot:cardImg>
                    <img class="img-fluid" src="@/assets/images/Default/img-card-cuenta-negocios.png" width="90" height="72" alt="Cuenta negocios" />
                  </template>
                  <ul class="checked-list">
                    <li>
                      {{$t('register.selectAccount.card_business.info1')}}
                    </li>
                    <li>
                      {{$t('register.selectAccount.card_business.info2')}}
                    </li>
                    <li>
                      {{$t('register.selectAccount.card_business.info3')}}
                    </li>
                    <li>
                      {{$t('register.selectAccount.card_business.info4')}}
                    </li>
                    <li>
                      {{$t('register.selectAccount.card_business.info5')}}
                    </li>
                    <li>
                      {{$t('register.selectAccount.card_business.info6')}}
                    </li>
                  </ul>
                </CardAccountType>
              </div>
              <div class="col-12 col-lg-6">
                <button type="button" class="button button-filled button-primary w-100" @click="newAccount">
                  <i aria-hidden="true" class="iconsax-arrow-arrow-right m-r-10"></i>
                  {{ $t('register.selectAccount.newAccount') }}
                </button>
              </div>
            </div>
          </div>

          <div class="illustration-side">
            <img class="img-fluid" src="@/assets/images/Illustrations/illustration-crear-cuenta-full.svg" width="540" height="560" alt="Ilustración Tipo de cuenta" />
          </div>
        </div>
      </div>
    </div>
    <FooterPage></FooterPage>
  </div>
</template>


<script>

// TODO AGREGAR BOTON DE REGRESAR PARA CANCELAR TODO EN SU TOTALIDAD

import CardAccountType from "@/components/CardAccountType.vue";
import FooterPage from "@/components/FooterPage.vue";
import {
  STORE as OnboardingStore,
  SET_TYPE_ACCOUNT
} from '@/store/modules/onboardingSteps/onboardingSteps.types';
import usersApi from '@/api/users/users.api';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {
  STORE as sessionStore,
  GET_USER
} from "@/store/modules/session/session.types";
import { mapGetters } from 'vuex';

export default {
  name: "SelectAccount",
  components: {
    CardAccountType,
    FooterPage
  },
  mixins: [ToastSnotify],
  props: {
    isMultiple: {type: Boolean, default: false}
  },
  data() {
    return {
      isPersonal: true
    }
  },
   computed: {
    ...mapGetters(sessionStore, {
      user: GET_USER
    })
  },
  methods: {
    selectCard(val) {
      this.isPersonal = val;
    },
    async newAccount() {
      this.$store.commit(`${OnboardingStore}/${SET_TYPE_ACCOUNT}`, this.isPersonal);
      if (this.isPersonal) {
        if(this.isMultiple || !this.checkRoute()) {
          this.$router.push({name: 'selectAccountMultiple'})
        } else {
          if(this.$route.name === 'selectAccountType') {
            const response = await usersApi.updateOnbTypeUser({user: this.user._id, onbForBusiness: false})

            if(response.data.error) {
              this.displayNotificationError('Ocurrió un error para iniciar proceso.')
            } else {
              this.$router.push({ name: 'selectAccount'});
            }
          } else {
            this.$router.push({ name: 'newAccount'})
          }
        }
      } else {

        if(this.isMultiple || !this.checkRoute()) {
          this.$router.push({name: 'selectSchemaBusinessMultiple'});
        } else {
          this.$store.dispatch(`${OnboardingStore}/changeCurrentStep`);
          if(this.$route.name === 'selectAccountType') {

            const response = await usersApi.updateOnbTypeUser({user: this.user._id, onbForBusiness: true})

            if(response.data.error) {
              this.displayNotificationError('Ocurrió un error para iniciar proceso.')
            } else {
              this.$router.push({ name: 'selectSchemaBusiness'});
            }
          } else {

            this.$router.push({ name: 'newAccountBusiness'});
          }
        }
      }
    },
    checkRoute() {
      const name = this.$route.name;

      if(name === 'createNewAccount') {
        return false;
      }

      return true;
    }
  }
}
</script>

<style scoped>

</style>



