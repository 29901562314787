<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/wallet"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('settings.home.goBack')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('settings.title')"></FloatingTitleLayout>
                        </div>
                        <div class="col-12">
                            <CardSimple hideTitle>
                                <div class="d-md-flex justify-space-between align-items-center">
                                    <div class="d-flex align-items-center">
                                        <UserAccount
                                            :userImg="imageDownloadLink(selfPortrait) || imgUserAccount"
                                            :widthImg="50"
                                            :heightImg="50"
                                            clazz="blue-border"
                                            altImgUser="Usuario de prueba">
                                        </UserAccount>
                                        <div class="m-l-15 p-r-10">
                                            <span class="poppins-font-medium c-text_info f-12">{{$t('settings.home.messageWelcome')}}</span><br>
                                            <strong class="poppins-font-bold c-text_title f-14">{{fullName}}</strong>
                                        </div>
                                    </div>
                                    <span class="badge badge-primary mt-3 mt-md-0">
                                        <i aria-hidden="true" class="badge-icon iconbullet"></i>
                                        {{$t('settings.home.lastAccess')}} <strong>{{moment(user.lastLogin).format('LL h:mm A')}}</strong>
                                    </span>
                                </div>
                            </CardSimple>
                        </div>
                        <div class="col-12">
                            <div class="poppins-font-bold f-16 c-text_title m-b-15">{{$t('settings.home.generalConfig')}}</div>
                        </div>
                        <div class="col-12 col-md-6">
                            <router-link to="/settings/personal-information" class="" tabindex="" aria-label="">
                                <PanelLinkGoTo
                                    clazz="hover"
                                    icon="iconsax-users-user-square"
                                    :titleText="$t('settings.personalInfo.title')"
                                    :text="$t('settings.personalInfo.text2')">
                                    <i aria-hidden="true" class="iconsax-arrow-circle-right c-text_light"></i>
                                </PanelLinkGoTo>
                            </router-link>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- <router-link to="/settings/invite-friends" class="" tabindex="" aria-label="">
                                <PanelLinkGoTo
                                    clazz="hover"
                                    icon="iconsax-school-gift"
                                    :titleText="$t('settings.inviteFriends.title')"
                                    :text="$t('settings.inviteFriends.text')">
                                    <i aria-hidden="true" class="iconsax-arrow-circle-right c-text_light"></i>
                                </PanelLinkGoTo>
                            </router-link> -->
                        </div>
                        <div class="col-12 col-md-6">
                            <router-link to="/settings/notifications" class="" tabindex="" aria-label="">
                                <PanelLinkGoTo
                                    clazz="hover"
                                    icon="iconsax-notifications-bell-2"
                                    :titleText="$t('settings.notifications.title')"
                                    :text="$t('settings.notifications.text')">
                                    <i aria-hidden="true" class="iconsax-arrow-circle-right c-text_light"></i>
                                </PanelLinkGoTo>
                            </router-link>
                        </div>
                        <div class="col-12">
                            <div class="poppins-font-bold f-16 c-text_title m-b-15">{{$t('settings.home.security')}}</div>
                        </div>
                        <div class="col-12 col-md-6">
                            <router-link to="/settings/security" class="" tabindex="" aria-label="">
                                <PanelLinkGoTo
                                    clazz="hover"
                                    icon="iconsax-security-shield-tick"
                                    :titleText="$t('settings.security.title')"
                                    :text="$t('settings.security.text')">
                                    <i aria-hidden="true" class="iconsax-arrow-circle-right-1 c-text_light"></i>
                                </PanelLinkGoTo>
                            </router-link>
                        </div>
                        <div class="col-12">
                            <div class="poppins-font-bold f-16 c-text_title m-b-15">{{$t('settings.home.help')}}</div>
                        </div>
                        <div class="col-12 col-md-6">
                            <a href="https://ayuda.pagando.mx/hc/es-419" target="_blank">
                                <PanelLinkGoTo
                                    clazz="hover"
                                    icon="iconsax-like-question"
                                    :titleText="$t('settings.home.frequentQuestions.title')"
                                    :text="$t('settings.home.frequentQuestions.text')">
                                    <i aria-hidden="true" class="iconsax-arrow-circle-right c-text_light"></i>
                                </PanelLinkGoTo>
                           </a>
                        </div>
                        <div class="col-12 col-md-6">
                            <router-link to="/settings/contact" class="" tabindex="" aria-label="">
                                <PanelLinkGoTo
                                    clazz="hover"
                                    icon="iconsax-call-calling"
                                    :titleText="$t('settings.contact.title')"
                                    :text="$t('settings.contact.text2')">
                                    <i aria-hidden="true" class="iconsax-arrow-circle-right c-text_light"></i>
                                </PanelLinkGoTo>
                            </router-link>
                        </div>
                        <div class="col-12">
                            <div class="control-buttons">
                                <button
                                    @click="goBack()"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('settings.back')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>

    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import PanelLinkGoTo from "@/components/PanelLinkGoTo.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import UserAccount from "@/components/UserAccount.vue";
    import imgUserAccount from '@/assets/images/Default/profile-photo.svg';
    import {
        STORE as sessionStore,
        GET_USER,
        GET_USER_FULL_NAME,
        GET_USER_SELF_PORTRAIT
    } from "@/store/modules/session/session.types";
    import {mapGetters} from "vuex";
    import moment from 'moment';
    import { imageDownloadLink } from '@/utils/utils';

    export default {
        name: "Settings",
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            PanelLinkGoTo,
            CardSimple,
            UserAccount
        },
        computed: {
            ...mapGetters(sessionStore, {
                user: GET_USER,
                fullName: GET_USER_FULL_NAME,
                selfPortrait: GET_USER_SELF_PORTRAIT,

            }),
        },
        data() {
            return {
                // Images
                imgUserAccount: imgUserAccount,
                window: {
                    width: 0
                },
                isMenuOpen: false,
            }
        },
        methods: {
            imageDownloadLink,
            moment,
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            goBack() {
                this.$router.push({name: "Dashboard"});
            }
        },
        mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        },
    };
</script>
