<template>
        <!-- MODAL DETALLE DEVOLUCION -->
        <b-modal
            id="modal-detail-refund"
            size="lg"
            hide-header
            hide-footer
            modal-class="modal-default left-oriented">
            <button class="close-modal"
                    @click="$bvModal.hide('modal-detail-refund')"
                    aria-label="Boton cerrar ventana"
                    name="Boton cerrar ventana">
                <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <div class="head">
                <h2 class="modal-title">{{$t('movements.details.title')}}</h2>
                <div class="w-100 d-none d-md-flex align-items-c justify-space-between">
                    <Badge
                        type="badge-warning"
                        :text="$t('movements.details.refund')"
                    ></Badge>
                    <ReadOnlyInfo
                        clazz="text-center m-b-0 w-fit"
                        :title="$t('movements.details.amount')"
                        titleClazz="f-14"
                        :text="getAmount()"
                        :textClazz="movementToShow.sign === '+' ? 'f-28 c-success poppins-font-bold f-700' : 'f-28 c-primary poppins-font-bold f-700'"
                    ></ReadOnlyInfo>
                </div>
                <div class="w-100 d-flex d-md-none flex-wrap justify-content-center row-gap-10px">
                    <Badge
                        clazz="m-0-auto"
                        type="badge-warning"
                        :text="$t('movements.details.refund')"
                    ></Badge>
                    <ReadOnlyInfo
                        clazz="text-center m-b-0"
                        :title="$t('movements.details.amount')"
                        titleClazz="f-14"
                        :text="getAmount()"
                        :textClazz="movementToShow.sign === '+' ? 'f-28 c-success poppins-font-bold f-700' : 'f-28 c-primary poppins-font-bold f-700'"
                    ></ReadOnlyInfo>
                </div>
            </div>
            <div class="body">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <ReadOnlyInfo
                            :title="$t('movements.details.date')"
                            :text="getDateDetail()"
                        ></ReadOnlyInfo>
                    </div>
                    <div class="col-12 col-md-6">
                        <ReadOnlyInfo
                            :title="$t('movements.details.type')"
                            :text="movementToShow.contaMovementType ? getTypeMovement(movementToShow.contaMovementType) : '-'"
                        ></ReadOnlyInfo>
                    </div>
                    <div class="col-12 col-md-6">
                        <ReadOnlyInfo
                            :title="$t('movements.details.concept')"
                            :text="movementToShow.payConcept ? movementToShow.payConcept : movementToShow.movementTitle"
                        ></ReadOnlyInfo>
                    </div>
                    <div class="col-12 col-md-6">
                        <ReadOnlyInfo
                            :title="$t('movements.details.folio')"
                            :text="movementToShow.fullKey ? movementToShow.fullKey : ''"
                        ></ReadOnlyInfo>
                    </div>
                </div>
            </div>
            <div class="footer">
                <p class="f-13 c-text_content poppins-font-medium f-500 text-center m-b-0">
                    {{$t('movements.details.unrecognizeMovement')}}
                    <a href="" class="f-13 c-info poppins-font-bold f-700 din-block hover" tabindex="">
                        <strong>
                            <u>{{$t('movements.details.contactUs')}}</u>
                        </strong>
                    </a>
                </p>
            </div>
        </b-modal>

</template>

<script>
// import {EventBus} from "@/main";
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
import Badge from "@/components/Badge.vue";
import moment from "moment";

export default {
  name: "ModalRefund",
  components: {
    ReadOnlyInfo,
    Badge,
  },
  props: {
    movementToShow: {},
  },
  watch: {
    loading(newValue) {
      this.localLoading = newValue;
    },
  },
  data() {
    return {};
  },
  methods: {
    hideModal() {
      this.$bvModal.hide("modal-detail-refund");
    },
    openModal() {
      this.$bvModal.show("modal-detail-refund");
    },
    getDateDetail() {
      return moment(this.movementToShow.date).format("DD MMM. YYYY, hh:mm a");
    },
    getTypeMovement(type) {
      return this.$t(`movements.types.${type}`);
    },
    getAmount() {
      return `${this.movementToShow.sign} ${this.$options.filters.currency(
        this.movementToShow.amount
      )}`;
    }
  },
  created() {
    //   EventBus.$on(`CLOSE_MODAL_${this.modalId}`, ()=>{
    //         this.$refs[this.customRef ? this.customRef : 'modalAlert'].hide();
    //     })
  },
};
</script>
