<template>
  <aside
      id="sidebar-right"
      class="sidebar-right"
      :class="[isSidebarRightOpen ? '': 'hide']">
    <button type="button"
            class="button-sidebar-right"
            aria-label="Ocultar/Mostrar Menu Sidebar"
            name="Ocultar/Mostrar Menu Sidebar"
            @click="toggleSidebarRight">
      <i aria-hidden="true" class="icon-arrow"></i>
    </button>
    <button type="button"
            class="button-sidebar-right hide"
            aria-label="Ocultar/Mostrar Menu Sidebar"
            name="Ocultar/Mostrar Menu Sidebar"
            @click="hideSidebarRight">
      <i aria-hidden="true" class="iconsax-arrow-arrow-right"></i>
    </button>
    <div class="sidebar-right-content" :class="clazzContent">
            <span class="content-lg">
                <div class="account-selected">
                    <span>
                        <img class="img-fluid" :src="accountImage" width="124" height="77" :alt="accountDescription"/>
                    </span>
                    <span>
                        <small class="title-account-selected">
                            {{ $t('sideBar.myAccount') }}
                            <br/>
                            <strong v-if="aliasAccount">{{ aliasAccount }}<br/></strong>
                            <span>{{ accountDescription }}</span>
                        </small>
                    </span>
                    <div class="button-account">
                        <button type="button" v-if="account.isAccountHolder"
                                class="button xs button-stroke button-primary"
                                @click="$bvModal.show('modal-alias')">
                                {{ $t('sideBar.asignAlias') }}
                        </button>
                        <button type="button"
                                class="button xs button-filled button-primary"
                                @click="$bvModal.show('modal-cambiar-cuenta')">
                                {{ $t('sideBar.changeAccount') }}
                        </button>
                    </div>
                </div>
              <!-- Botones Asignar alias y cambiar cuenta-->
              <!-- <div class="d-flex w-100 column-gap-10px m-t-20">
                  <button type="button"
                      class="button xs button-stroke button-primary w-100"
                      @click="$bvModal.show('modal-alias-tarjeta')">
                      Asignar alias
                  </button>
                  <button type="button"
                          class="button xs button-filled button-primary w-100"
                          @click="$bvModal.show('modal-cambiar-cuenta')">
                          {{$t('sideBar.changeAccount')}}
                  </button>
              </div> -->
                <div class="divider"></div>
                <div class="account-cards-swiper">
                    <h2>
                        {{ $t('sideBar.myCards') }}
                        <span>{{ swiperIndex + 1 }} {{ $t('general.de') }} {{ cardsLength }}</span>
                    </h2>
                    <button
                        v-if="checkPermissionAdditionalCards"
                        @click="goToAdditionalCards()"
                        id="button-add-new-card"
                        type="button"
                        class="button xs button-filled button-info f-10 p-t-0 p-b-0 p-l-5 p-r-0 m-b-10">
                        {{ $t('additionalCards.menu.title') }}
                        <i aria-hidden="true" class="iconsax-essential-add f-18 m-l-5"></i>
                    </button>
                    <b-tooltip
                        v-if="checkPermissionAdditionalCards"
                        target="button-add-new-card"
                        placement="top">
                        <p class="m-b-0">
                            <strong class="poppins-font-bold">{{ $t('additionalCards.menu.tooltip1') }}</strong>
                            <br/>
                            {{ $t('additionalCards.menu.tooltip2', {account: accountDescription}) }}
                        </p>
                    </b-tooltip>

                  <div class="myCards-swiper pb-0 mb-5">
                    <div class="swiper-container">
                        <carousel
                            ref="cardsSwiper"
                            :perPage="1"
                            :navigationEnabled="true"
                            :paginationEnabled="false"
                            navigationNextLabel="<i aria-hidden='true' class='iconsax-arrow-arrow-right'></i>"
                            navigationPrevLabel="<i aria-hidden='true' class='iconsax-arrow-arrow-left'></i>"
                            v-on:page-change="onChange"
                        >
                            <slide v-for="card in cards" :key="card.card._id">
                              <div class="swiper-slide">
                                <div v-if="!inReposition[card.card._id]" class="inside-card">
                                  <!--       Virtual Card   -->
                                  <div v-if="card.card.manufacturingType === 'VIRTUAL'" class="card-img">
                                    <img
                                        :src="cardImage(card, 'front')"
                                        :alt="$t('sideBar.cardFront', { card: card.cardTemplate.description})">
                                    <div class="virtual-info">
                                      <LoadingBar v-if="loadingPans[card.card._id]" clazz="card-number"/>
                                      <div v-if="!loadingPans[card.card._id]" class="card-number">
                                          {{ pans[card.card._id] }}
                                          <button class="copy-number" @click="copyNoCard(card)">
                                              <i aria-hidden="true" class="banana banana-copy"></i>
                                          </button>
                                      </div>
                                      <div v-if="!loadingPans[card.card._id]" class="bottom-info">
                                          <div class="exp-date">
                                              <div>
                                                  <span>{{ $t('sideBar.expDate') }}</span><br>
                                                  {{ expDates[card.card._id] }}
                                              </div>
                                              <div>
                                                  <span v-if="loadingCvvs[card.card._id]"><LoadingSimple
                                                      clazz="small-load no-text"/></span>
                                                  <div v-else-if="cvvs[card.card._id]">
                                                    <span>{{ $t('sideBar.cvc.label') }}</span><br>
                                                    {{ cvvs[card.card._id] }}
                                                  </div>
                                                  <button
                                                      v-else
                                                      type="button"
                                                      class="button-nip button xs button-filled button-primary"
                                                      @click="checkCVV(card)"
                                                  >
                                                  {{ $t('sideBar.cvc.action') }}
                                                  </button>
                                              </div>
                                              <div
                                                  v-if="!loadingCvvs[card.card._id] && dynamicCvvs[card.card._id] && cvvs[card.card._id]">
                                                <span>{{ $t('sideBar.cvc.available') }}:</span><br>
                                                <div class="d-flex f-14 f-bold">
                                                  <i aria-hidden="true" class="iconsax-time-clock mr-1"></i>
                                                  {{ countdownLabel }}
                                                </div>
                                              </div>
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!--       Virtual Card   -->

                                  <!--       PHYSIC Card   -->
                                  <div v-if="card.card.manufacturingType === 'PHYSIC'" class="flip-card"
                                       :class="{ 'flipped': flippedCards[card.card._id] }">
                                    <div class="flip-card-inner">
                                        <div class="flip-card-front">
                                            <div class="card-img">
                                                <img
                                                    :src="cardImage(card, 'front')"
                                                    :alt="$t('sideBar.cardFront', { card: card.cardTemplate.description})">
                                            </div>
                                        </div>
                                        <div class="flip-card-back">
                                            <div class="back-card">
                                                <div class="card-img">
                                                  <img
                                                      :src="cardImage(card, 'back')"
                                                      :alt="$t('sideBar.cardBack', { card: card.cardTemplate.description})">
                                                  <LoadingBar v-if="loadingPans[card.card._id]" clazz="card-number"/>
                                                  <div v-if="!loadingPans[card.card._id]" class="card-number">
                                                    {{ pans[card.card._id] }}
                                                    <button class="copy-number" @click="copyNoCard(card)">
                                                      <i aria-hidden="true" class="banana banana-copy"></i>
                                                    </button>
                                                  </div>
                                                  <div v-if="!loadingPans[card.card._id]" class="exp-date">
                                                    <div>
                                                      <span>{{$t('sideBar.expDate')}}</span><br>
                                                      {{expDates[card.card._id]}}
                                                    </div>
                                                    <template v-if="!hasUnifiedPin">
                                                      <span v-if="loadingNips[card.card._id]">
                                                        <LoadingSimple clazz="small-load no-text"/>
                                                      </span>
                                                      <div v-else-if="nips[card.card._id]">
                                                        <span>
                                                          {{$t('sideBar.nip.label')}}
                                                        </span><br>
                                                        {{nips[card.card._id]}}
                                                      </div>
                                                      <button
                                                          v-else
                                                          type="button"
                                                          class="button-nip button xs button-filled button-primary"
                                                          @click="checkNip(card)"
                                                      >
                                                        {{$t('sideBar.nip.action')}}
                                                      </button>
                                                    </template>
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                  <!--       PHYSIC Card   -->

                                  <div v-if="card.card.alias" class="alias">{{ card.card.alias }}</div>
                                  <button
                                      v-if="card.card.manufacturingType === 'PHYSIC'"
                                      class="flip-btn"
                                      @click="toggleFlip(card)">
                                    {{
                                      flippedCards[card.card._id] ? $t('sideBar.seeFront') : $t('sideBar.seeReverse')
                                    }}
                                  </button>
                                </div>

                                <!-- Tarjeta física en reposición -->
                                <div class="default-item" v-else-if="card.card.manufacturingType === 'PHYSIC'">
                                  <p>
                                    <strong class="f-14" v-text="$t('cardRequest.reposition.physic.swiper-title')"/><br>
                                    <span v-text="$t('cardRequest.reposition.physic.swiper-subtitle')"/>
                                  </p>
                                  <button
                                      type="button"
                                      class="button xs button-stroke button-white m-b-10"
                                      v-text="$t('cardRequest.reposition.physic.swiper-button-status')"
                                      @click="$router.push({name: 'CardShippingStatus'})"
                                  />
                                  <button
                                      type="button"
                                      class="button xs button-filled button-primary"
                                      v-text="$t('cardRequest.reposition.physic.swiper-button-activate')"
                                      @click="$router.push({name: 'CardActivation'})"
                                  />
                                </div>

                                <!-- Tarjeta virtual en reposición -->
                                <div class="default-item" v-else-if="card.card.manufacturingType === 'VIRTUAL'">
                                  <p>
                                    <strong class="f-14" v-text="$t('cardRequest.reposition.virtual.swiper-title')"/><br>
                                    <span v-text="$t('cardRequest.reposition.virtual.swiper-subtitle')"/>
                                  </p>
                                  <button
                                      id=""
                                      type="button"
                                      class="button xs button-filled button-primary"
                                      v-text="$t('cardRequest.reposition.virtual.swiper-button')"
                                      @click="() => $router.push({name: 'ReplaceCard'})"
                                  />
                                </div>
                              </div>
                            </slide>

                            <slide v-if="showRequestCardSwiper">
                              <div class="swiper-slide">
                                <div class="default-item">
                                  <p>
                                    <strong class="f-14">
                                        {{$t('cardRequest.request')}}
                                    </strong><br>
                                    <span v-html="$t('cardRequest.swiper-html')"></span>
                                  </p>
                                  <button
                                      id=""
                                      type="button"
                                      class="button xs button-filled button-primary"
                                      v-text="$t('cardRequest.request-button')"
                                      @click="() => $router.push({name: 'CardRequest'})"
                                  ></button>
                                </div>
                              </div>
                              </slide>

                            <slide v-if="showActivateCardSwiper">
                              <div class="swiper-slide">
                                <div class="default-item">
                                  <p>
                                    <strong class="f-14" v-text="$t('cardRequest.activate')"></strong><br>
                                    {{$t('cardRequest.activate-now')}}
                                  </p>
                                  <button
                                      type="button"
                                      class="button xs button-stroke button-white m-b-10"
                                      v-text="$t('cardRequest.shipping-status')"
                                      @click="goToShippingStatus"
                                  ></button>
                                  <button
                                      type="button"
                                      class="button xs button-filled button-primary"
                                      v-text="$t('cardRequest.activate-my-card')"
                                      @click="goToActivateCard"
                                  ></button>
                                </div>
                              </div>
                            </slide>
                        </carousel>
                    </div>
                  </div>
                </div>
                <div class="account-options">
                    <SidebarRightLink
                        clazz="warning"
                        icon="iconsax-security-lock"
                        :titleText="$t('cardRequest.block.title')"
                        :text="$t('cardRequest.block.text')">
                        <BasicSwitch
                            clazz="w-fit"
                            idInput="switch-block-card"
                            ref="blockSwitch"
                            :switchValue="isBlocked"
                            :disabled="inReposition[currentCardId]"
                            @onBasicSwitchChange="onBlockCard"
                        ></BasicSwitch>
                    </SidebarRightLink>
                  <!-- <SidebarRightLink
                      clazz="textInfo"
                      icon="iconsax-security-password-check"
                      titleText="Cambiar NIP de seguridad"
                      text="Cambia tu NIP de seguridad a un
                      código de tu preferencia">
                      <router-link to="" tabindex="" aria-label="Cambiar NIP de seguridad">
                          <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                      </router-link>
                  </SidebarRightLink> -->
                  <!-- <SidebarRightLink
                      clazz="accent"
                      icon="iconsax-money-convert-card"
                      titleText="Cancelar y reemplazar tarjeta"
                      text="Si has perdido, dañado o te han
                      robado tu tarjeta, repórtala aquí">
                      <router-link to="" tabindex="" aria-label="Cancelar y reemplazar tarjeta">
                          <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                      </router-link>
                  </SidebarRightLink> -->
                  <!-- <SidebarRightLink
                      clazz="primary"
                      icon="iconsax-like-question"
                      titleText="Ayuda"
                      text="Conoce más acerca de cómo tener
                      el control de tus tarjetas">
                      <router-link to="" tabindex="" aria-label="Ayuda">
                          <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                      </router-link>
                  </SidebarRightLink> -->
                </div>
              <!-- <div
                  class="pagando-check-service"
                  @click="$bvModal.show('modal-pagando-check-service')"
                  v-if="showHirePagandoCheck"
              >
                  <img class="img-fluid" src="@/assets/images/Icons/pagando-check-service.png" width="80" height="80" alt="Pagando Check"/>
                  <span>
                      <label v-html="$t('pagandoCheck.sidebar.label-html')"></label>
                      <p v-text="$t('pagandoCheck.sidebar.p')"></p>
                  </span>
                  <a class=""
                      tabindex=""
                      :aria-label="$t('pagandoCheck.sidebar.p')"
                  >
                      <i aria-hidden="true" class="iconsax-arrow-direction-right"></i>
                  </a>
              </div> -->
            </span>
      <!-- Resnponsive Content -->
      <span class="content-xs">
                <div class="account-selected">
                    <span>
                        <img class="img-fluid" :src="accountImage" width="50" height="30" alt="Cuenta Pagando Explore"/>
                    </span>
                    <span>
                        <small class="title-account-selected">
                            {{ $t('sideBar.myAccount') }}
                            <br/>
                            <strong>{{ accountDescription }}</strong>
                        </small>
                    </span>
                </div>
                <div class="divider"></div>
                <div class="account-options">
                    <SidebarRightLink
                        :onlyIcon="true"
                        :isRouter="false"
                        clazz="warning"
                        icon="iconsax-security-lock"
                        url=""
                        @makeClic="onBlockCardWithNoBlock"
                        ariaLabel="Bloquear tarjeta">
                    </SidebarRightLink>
                  <!-- <SidebarRightLink
                      :onlyIcon="true"
                      clazz="textInfo"
                      icon="iconsax-security-password-check"
                      url=""
                      ariaLabel="Cambiar NIP de seguridad">
                  </SidebarRightLink>
                  <SidebarRightLink
                      :onlyIcon="true"
                      clazz="accent"
                      icon="iconsax-money-convert-card"
                      url=""
                      ariaLabel="Cancelar y reemplazar tarjeta">
                  </SidebarRightLink>
                  <SidebarRightLink
                      :onlyIcon="true"
                      clazz="primary"
                      icon="iconsax-like-message-question"
                      url=""
                      ariaLabel="Ayuda">
                  </SidebarRightLink> -->
                </div>
                <div class="divider"></div>
        <!-- <a
            class=""
            tabindex=""
            :aria-label="$t('pagandoCheck.sidebar.p')"
        >
            <div
                class="pagando-check-service"
                @click="$bvModal.show('modal-pagando-check-service')"
            >
                <img
                    class="img-fluid m-0-auto"
                    src="@/assets/images/Icons/pagando-check-service.png"
                    width="42"
                    height="42"
                    :alt="$t('pagandoCheck.sidebar.p')"
                />
            </div>
        </a> -->
            </span>
    </div>
  </aside>
</template>

<script>
import SidebarRightLink from "@/components/SidebarRightLink.vue";
import BasicSwitch from "@/components/BasicSwitch.vue";
import LoadingSimple from "@/components/LoadingSimple";
import {mapGetters} from "vuex";
import {
  STORE as sessionStore,
  GET_ACCOUNT, SET_ACCOUNT,
  SET_CARD_STATUS,
  GET_USER_HAS_UNIFIED_PIN,
} from "@/store/modules/session/session.types";
import {imageDownloadLink} from '@/utils/utils';
import CardApi from '@/api/wallet/card.api';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {EventBus} from "@/main";
import {CARD_DEFAULT_IMG} from "@/enums/defalutCardImg.enum";
import LoadingBar from "@/components/LoadingBar";
import moment from 'moment';

import {Carousel, Slide} from 'vue-carousel';

export default {
  name: 'SidebarRight',
  mixins: [ToastSnotify],
  components: {
    SidebarRightLink,
    BasicSwitch,
    LoadingSimple,
    LoadingBar,
    Carousel,
    Slide,
  },
  props: {
    clazz: [],
    clazzContent: String,
  },
  data() {
    return {
      flippedCards: {},
      //
      window: {
        width: 0
      },
      isSidebarRightOpen: true,
      swiperIndex: 0,
      pans: {},
      expDates: {},
      nips: {},
      loadingNips: {},
      cvvs: {},
      loadingCvvs: {},
      dynamicCvvs: {},
      loadingPans: {},
      intervalCodeTime: 0,
      countdownLabel: 0
    }
  },
  computed: {
    ...mapGetters(sessionStore, {
      account: GET_ACCOUNT,
      hasUnifiedPin: GET_USER_HAS_UNIFIED_PIN,
    }),
    accountDescription() {
      if (this.account && this.account.account && this.account.account.accountType) {
        return this.account.account.accountType.description;
      }
      return null;
    },
    aliasAccount() {
      if (this.account && this.account.account && this.account.account.aliasName) {
        return this.account.account.aliasName;
      }
      return null;
    },
    accountImage() {
      const defaultImage = CARD_DEFAULT_IMG.pagandoExplore;
      if (!this.account || !this.account.template) {
        return defaultImage;
      }

      if (this.account.template.image) {
        return imageDownloadLink(this.account.template.image);
      }

      switch (this.account.template.product) {
        case "EXPLORE":
          return CARD_DEFAULT_IMG.pagandoExplore;
        case "EXPLORE_PLUS":
          return CARD_DEFAULT_IMG.pagandoExplorePlus;
        case "NIGHT":
          return CARD_DEFAULT_IMG.pagandoNight;
        case "BUSINESS":
          return CARD_DEFAULT_IMG.pagandoBusiness;
        case "BUSINESS_PRO":
          return CARD_DEFAULT_IMG.pagandoBusinessPro;
        default:
          return defaultImage;
      }
    },
    cards() {
      if (this.account && this.account.cards) {
        return this.account.cards;
      }

      return [];
    },
    currentCard() {
      if (this.cards) {
        return this.cards[this.swiperIndex];
      }
      return {};
    },
    currentCardId() {
      return this.currentCard?.card?._id;
    },
    isBlocked() {
      return this.currentCard?.card?.status !== 'ACTIVE' && this.currentCard?.card?.status !== 'VERIFIED';
    },
    showRequestCardSwiper() {
      const hasPermit = !!this.account?.account?.accountType?.permits?.find(({
                                                                               permitKey,
                                                                               status
                                                                             }) => status && permitKey === 'REQUEST_PHYSICAL_CARD');
      const hasPhysicalCard = !!this.account?.cards?.find(({card: {manufacturingType}}) => manufacturingType === 'PHYSIC');
      return hasPermit && !this.showActivateCardSwiper && !hasPhysicalCard;
    },
    showActivateCardSwiper() {
      const status = [
        'ACCEPTED',
        'SENT',
        'DELIVERED',
        'IN_PROCCESS',
      ];
      return !!this.account?.cards?.find(({card: {manufacturingType}, requestCard}) => {
        return status.includes(requestCard?.status) && !requestCard?.isReposition && manufacturingType === 'VIRTUAL';
      });
    },
    cardsLength() {
      return (this.showRequestCardSwiper || this.showActivateCardSwiper) ? this.cards.length + 1 : this.cards.length;
    },
    showHirePagandoCheck() {
      return !this.account?.account?.aggregatorHired && this.account?.account?.accountType?.productType === 'CLIENTS_ACCOUNT';
    },
    checkPermissionAdditionalCards() {
      if (this.account?.account?.accountType?.permits) {
        const permits = this.account.account.accountType.permits;

        const permit = permits.find(p => p.permitKey === 'REQUEST_ADDITIONAL_VIRTUAL_CARD');

        const permission = this.account.permissions.find(p => p.type === 'REQUEST_VIRTUAL_ADDITIONAL_CARD' && p.action === 'REQUEST');

        return permit?.status && (permission || this.account.isAccountHolder);
      }

      return false;
    },
    inReposition() {
      return this.cards.reduce((acc, val) => Object.assign(acc, {[val?.card?._id]: !!val?.requestCard?.isReposition}), {});
    }
  },
  methods: {
    onChange(index) {
      this.swiperIndex = index;
      this.$refs.blockSwitch.forceValue(this.isBlocked);
    },
    async toggleFlip(card) {
      const id = card.card._id;
      this.$set(this.flippedCards, id, !this.flippedCards[id]);
      await this.onCardFlipped(card);
    },
    hideSidebarRight() {
      document.getElementById('sidebar-right').classList.add('hide');
    },
    toggleSidebarRight() {
      document.querySelector('.sidebar-right').classList.toggle('small');
    },
    handleResizeSidebarRight() {
      const sidebar = document.getElementById('sidebar-right');
      this.window.width = window.innerWidth;
      if (!sidebar) {
          return;
      }

      if (window.innerWidth >= 768) {
        sidebar?.classList?.add('small');
        this.isSidebarRightOpen = true;
      } else {
        sidebar?.classList?.remove('small');
        this.isSidebarRightOpen = false;
      }

      if (window.innerWidth >= 1200) {
        sidebar?.classList?.remove('small');
      }
    },
    cardImage({cardTemplate, card}, side) {
      if (cardTemplate.image && side === 'front') {
        return imageDownloadLink(cardTemplate.image);
      }

      let product = 'EXPLORE';
      let type = 'VIRTUAL';

      const validProducts = ['EXPLORE', 'EXPLORE_PLUS', 'NIGHT', 'BUSINESS', 'BUSINESS_PRO'];
      const validTypes = ['VIRTUAL', 'PHYSIC'];
      const images = {
        EXPLORE: {
          VIRTUAL: {
            front: CARD_DEFAULT_IMG.pagandoExploreVirtualFront,
            back: CARD_DEFAULT_IMG.pagandoExploreVirtualBack
          },
          PHYSIC: {front: CARD_DEFAULT_IMG.pagandoExploreFisicaFront, back: CARD_DEFAULT_IMG.pagandoExploreFisicaBack},
        },
        EXPLORE_PLUS: {
          VIRTUAL: {
            front: CARD_DEFAULT_IMG.pagandoExplorePlusVirtualFront,
            back: CARD_DEFAULT_IMG.pagandoExplorePlusVirtualBack
          },
          PHYSIC: {
            front: CARD_DEFAULT_IMG.pagandoExplorePlusFisicaFront,
            back: CARD_DEFAULT_IMG.pagandoExplorePlusFisicaBack
          },
        },
        NIGHT: {
          VIRTUAL: {front: CARD_DEFAULT_IMG.pagandoNightVirtualFront, back: CARD_DEFAULT_IMG.pagandoNightVirtualBack},
          PHYSIC: {front: CARD_DEFAULT_IMG.pagandoNightFisicaFront, back: CARD_DEFAULT_IMG.pagandoNightFisicaBack},
        },
        BUSINESS: {
          VIRTUAL: {
            front: CARD_DEFAULT_IMG.pagandoBusinessVirtualFront,
            back: CARD_DEFAULT_IMG.pagandoBusinessVirtualBack
          },
          PHYSIC: {
            front: CARD_DEFAULT_IMG.pagandoBusinessFisicaFront,
            back: CARD_DEFAULT_IMG.pagandoBusinessFisicaBack
          },
        },
        BUSINESS_PRO: {
          VIRTUAL: {
            front: CARD_DEFAULT_IMG.pagandoBusinessProVirtualFront,
            back: CARD_DEFAULT_IMG.pagandoBusinessProVirtualBack
          },
          PHYSIC: {
            front: CARD_DEFAULT_IMG.pagandoBusinessProVirtualFront,
            back: CARD_DEFAULT_IMG.pagandoBusinessProVirtualBack
          },
        },
      };

      if (cardTemplate && cardTemplate.product && validProducts.includes(cardTemplate.product)) {
        product = cardTemplate.product;
      }

      if (card && card.manufacturingType && validTypes.includes(card.manufacturingType)) {
        type = card.manufacturingType;
      }

      return images[product][type][side];
    },
    async onCardFlipped(card) {
      const id = card.card._id;
      const backside = this.flippedCards[id];
      const panExists = this.pans[card.card._id];
      const expDateExists = this.expDates[card.card._id];
      if (!backside) {
        return;
      }
      if (panExists && expDateExists) {
        return;
      }

      this.$set(this.loadingPans, id, true);
      try {
        const provider = this.account.account.externalProvider;
        const response = await CardApi.getCardPAN({id, provider});
        const pan = response.data.object;
        const formattedPan = pan.replace(/(\d{4})(?=\d)/g, '$1  '); // Dos espacios entre cada 4 digitos
        this.$set(this.pans, id, formattedPan);

        const {exp_month, exp_year} = card.card;
        const twoDigits = (n) => `0${parseInt(n, 10) % 100}`.slice(-2);
        const month = twoDigits(exp_month);
        const year = twoDigits(exp_year);
        this.$set(this.expDates, id, `${month}/${year}`);
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.$set(this.loadingPans, id, false);
    },
    async onBlockCard(block) {
      const card = this.cards[this.swiperIndex];
      const paymentSourceId = card.card._id;
      const params = {paymentSourceId, returnNewStatus: true};

      try {
        let response;
        if (block) {
          response = await CardApi.blockCard(params);
          this.displayNotificationSuccess("Tarjeta bloqueada correctamente");
        } else {
          response = await CardApi.unblockCard(params);
          this.displayNotificationSuccess("Tarjeta desbloqueada correctamente");
        }
        const status = response.data.object.status;
        this.$store.commit(`${sessionStore}/${SET_CARD_STATUS}`, {id: paymentSourceId, status});
      } catch (error) {
        this.toastErrorDefault(error);
      }

    },
    async onBlockCardWithNoBlock() {
      const card = this.cards[this.swiperIndex];
      const statusCard = card.card.status;
      const isBlocked = statusCard !== 'ACTIVE' && statusCard !== 'VERIFIED';
      try {
        if (isBlocked) {
          this.onBlockCard(false);
        } else {
          this.onBlockCard(true);
        }
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    copyNoCard(card) {
      if (card) {
        const currentPan = this.pans[card.card._id];
        if (navigator && navigator.clipboard) {
          navigator.clipboard.writeText(currentPan.replace(/\s/g, ''));
          this.displayNotificationSuccess(this.$i18n.t('sideBar.noCopy'))
        }
      }
    },
    goToShippingStatus() {
      this.$router.push({name: 'CardShippingStatus'});
    },
    goToActivateCard() {
      this.$router.push({name: 'CardActivation'});
    },
    async checkNip(card) {
      const paymentSourceId = card.card._id;
      if (!this.loadingNips[paymentSourceId] && await this.$requestAuth()) {
        this.$set(this.loadingNips, paymentSourceId, true);
        try {
          const nip = await CardApi.checkNip({paymentSourceId});
          this.$set(this.nips, paymentSourceId, nip);
        } catch (error) {
          this.toastErrorDefault(error);
        }
        this.$set(this.loadingNips, paymentSourceId, false);
      }
    },
    /**
     * Obtención del CVC
     *
     * @param {*} card - Objeto Tarjeta
     */
    async checkCVV(card) {
      const paymentSourceId = card.card._id;

      if (!this.loadingCvvs[paymentSourceId] && await this.$requestAuth()) {
        this.$set(this.loadingCvvs, paymentSourceId, true);
        try {
          // Obtención del CVC
          const response = await CardApi.getCardCVV({paymentSourceId, provider: 'PAGANDITO', asObject: true});

          if (response && response.data && response.data.object) {
            const cvvObject = response.data.object;

            // Si tiene fecha de expiración es CVC dinámico
            if (cvvObject.cvvExpirationTime) {
              this.$set(this.dynamicCvvs, paymentSourceId, true);
              this.$set(this.cvvs, paymentSourceId, cvvObject.cvv);

              // Definir tiempo de expiración del CVC
              this.scheduleCvvExpiration(cvvObject.cvvExpirationTime, paymentSourceId);
            } else if (cvvObject.cvv) { // No es CVC Dinámico
              this.$set(this.cvvs, paymentSourceId, cvvObject.cvv);
            } else { // CVV con estructura anterior
              this.$set(this.cvvs, paymentSourceId, cvvObject);
            }
          } else {
            this.$set(this.cvvs, paymentSourceId, null);
          }

        } catch (error) {
          this.toastErrorDefault(error);
        }
        this.$set(this.loadingCvvs, paymentSourceId, false);
      }
    },
    goToAdditionalCards() {
      this.$router.push({name: 'AdditionalCards'});
    },
    async loadVirtualCardPans() {
      const isVirtual = ({card: {manufacturingType}}) => manufacturingType === 'VIRTUAL';
      await Promise.all(this.cards.filter(isVirtual).map(async card => {
        const id = card.card._id;
        this.$set(this.loadingPans, id, true);
        const provider = this.account.account.externalProvider;
        const response = await CardApi.getCardPAN({id, provider});
        const pan = response.data.object;
        const formattedPan = pan.replace(/(\d{4})(?=\d)/g, '$1  '); // Dos espacios entre cada 4 digitos
        this.$set(this.pans, id, formattedPan);

        const {exp_month, exp_year} = card.card;
        const twoDigits = (n) => `0${parseInt(n, 10) % 100}`.slice(-2);
        const month = twoDigits(exp_month);
        const year = twoDigits(exp_year);
        this.$set(this.expDates, id, `${month}/${year}`);
        this.$set(this.loadingPans, id, false);
      })).catch(() => this.toastErrorDefault());
    },
    stopInterval() {
      if (this.intervalCodeTime) {
        clearInterval(this.intervalCodeTime);
        this.intervalCodeTime = null;
        this.countdownLabel = null;
      }
    },
    /**
     * Funcón que permite programar la expiración del cvc dinámico
     * en base a tiempo obtenido
     * @param {*} expirationTime - Tiempo de expiración (timestamp)
     * @param {*} cardId - Identificador de tarjeta que tiene el cvc dinámico
     */
    scheduleCvvExpiration(expirationTime, cardId) {
      const vueInstance = this;
      this.stopInterval();

      // Obtener la fecha de expiración del cvc
      const cvvExpirationTime = moment(expirationTime);

      const updateLabel = () => {
        // Se obtiene la diferencia actual con respecto al tiempo de expiración y se actualiza label
        const diff = cvvExpirationTime.diff(moment());
        vueInstance.countdownLabel = moment.utc(diff).format('mm:ss');

        // Tiempo de expiración llega
        if (diff <= 0) {
          vueInstance.stopInterval();
          vueInstance.$set(vueInstance.cvvs, cardId, null);
          vueInstance.$set(vueInstance.dynamicCvvs, cardId, false);
        }
      };
      updateLabel();
      this.intervalCodeTime = setInterval(updateLabel, 100);
    },
  },
  created() {
    EventBus.$on(`${sessionStore}.${SET_ACCOUNT}`, () => {
      this.swiperIndex = 0;
      if (this.$refs.cardsSwiper) {
        this.$refs.cardsSwiper.goToPage(0);
      }
      this.loadVirtualCardPans();
      if (this.cards[0]) {
        this.$refs.blockSwitch.forceValue(this.isBlocked);
      }
    });
  },
  mounted() {
    this.handleResizeSidebarRight();
    window.addEventListener('resize', this.handleResizeSidebarRight);
    this.loadVirtualCardPans();
  },
  beforeDestroy() {
    EventBus.$off(`${sessionStore}.${SET_ACCOUNT}`);
  },
  destroyed() {
    this.stopInterval();
    window.addEventListener('resize', this.handleResizeSidebarRight);
  },
}
</script>
