<template>
<div>
  <div v-if="showLoading">
    <LoadingSimple
        clazz="big-load p-t-50 p-b-50"
        label="Loading..."
    ></LoadingSimple>
  </div>
  <div v-else>
    <TitlePrincipal :title="$t('register.contract.title')">
      <p class="subtitle" v-html="$t('register.contract.subtitle')"></p>
    </TitlePrincipal>
    <div class="text-contrato" v-html="parsedHtml"></div>
    <b-alert class="alert-note" show variant="accent2">
        <p class="text-container">
            <i
                aria-hidden="true"
                class="icon banana banana-comment"
                ></i>
            <span v-html="$t('register.contract.footer', {email: emailUser})"></span>
        </p>
    </b-alert>
    <div class="footer-buttons right">
      <button v-if="showButton" type="button" class="button button-filled button-primary" name="Aceptar contrato" :disabled="!contract" @click="acceptContract">
        {{$t('register.contract.accept')}}
      </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text"/>
      </button>
    </div>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import LoadingSimple from "@/components/LoadingSimple";
import {
  STORE as SESSION_STORE,
  GET_EMAIL_USER, SET_TEMP_WALLET_USER
} from '@/store/modules/session/session.types';
import { mapGetters } from 'vuex';
import DocumentsApi from "@/api/core/documents.api";
import {catchError} from "@/utils/utils";
import {EventBus} from "@/main";
import {
  GET_ACCOUNT_TYPE, GET_CARD_REQUEST,
  LOAD_CARD_REQUEST,
  GET_ACCOUNT_TYPE_BUSINESS,
  STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import DocumentApi from "@/api/core/documents.api";
import CardsApi from "@/api/wallet/card.api";
import ShareholderApi from "@/api/core/shareholders.api";
import CouponsApi from "@/api/core/coupons.api";

export default {
  name: "Contract",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    LoadingSimple
  },
  computed: {
    ...mapGetters(SESSION_STORE, {
      emailUser: GET_EMAIL_USER
    }),
    ...mapGetters(onbngStore, {
      accountType: GET_ACCOUNT_TYPE,
      cardRequest: GET_CARD_REQUEST,
      isMoral2: GET_ACCOUNT_TYPE_BUSINESS
    }),
    parsedHtml() {
      const parser = new DOMParser();
      const elem = parser.parseFromString(
        this.contract,
        "text/html"
      );

      return elem.body.innerText;
    },
  },
  data() {
    return {
      showButton: true,
      showLoading: true,
      contract: ''
    }
  },
  methods: {
    async getDocuments() {
      if (this.cardRequest) {
        const response = await DocumentsApi.getPreContract({
          appliesTo: "WALLET",
          cardRequestId: this.cardRequest._id,
          pagandoAccount: this.cardRequest.pagandoAccount,
          discriminators: [this.accountType._id || this.accountType],
          documentType: "CONTRACT"
        }).catch(catchError);
        if (response && response.data) {
            if (response.data.error) {
                this.displayNotificationError(this.$i18n.t('register.contract.error'))
            } else {
              this.contract = response.data.object.preDocumentAccepted.content;
            }
        }
        this.showLoading = false;
      }
    },
    async acceptContract() {
      this.showButton = false;
      let goTo = "sign";
      const routeName = this.$route.name;
      if(routeName === 'contractMultiple'){
        goTo = "signMultiple";
      }
      if (this.cardRequest.accountType === 'BUSINESS') {
        if(routeName === 'businessContractMultiple'){
          goTo = "businessSignMultiple";
        } else {
          goTo = "businessSign"
        }
        if (this.cardRequest.isMoral) {
          const signed = await this.updateDigitalSing();
          if (signed) {
            if(routeName === 'businessContractMultiple'){
              this.$router.push({name: 'businessOnbngWelcomeMultiple'});
              return true;
            }
            return this.checkCampaigns();
          }
          this.showButton = true;
          return '';
        }
      }
      this.$router.push({name: goTo});
      this.showButton = true;
      return '';
    },
    async updateDigitalSing() {
      const res = await ShareholderApi.setDigitalSign({
        organizationInfo: this.cardRequest.organizationInfo
      }).catch(catchError);
      if (res.data && !res.data.error) {
        return this.acceptContractMoral();
      }
      return false;
    },
    async checkCampaigns() {
      const responseCampaigns = await CouponsApi.getPublicCampaigns({type: 'REFERRED'});
      let goTo = "businessOnbngWelcome";
      if (responseCampaigns && responseCampaigns.data) {
        if (responseCampaigns.data.error || responseCampaigns.data.object.length === 0) {
          goTo = "businessOnbngWelcome"
        } else {
          goTo = "businessPromotionalCode"
        }
      }
      this.$router.push({name: goTo});
      return true
    },
    async acceptContractMoral() {
      const response = await DocumentApi.acceptContract({
        documentType: "CONTRACT",
        appliesTo: "WALLET",
        cardRequestId: this.cardRequest._id,
        discriminators: [this.accountType._id || this.accountType],
        accountId: this.cardRequest.pagandoAccount,
        profileType: "USER"
      }).catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(this.$i18n.t('register.signature.errorContract'))
        } else {
          return this.updateUserWallet();
        }
      }
      return false;
    },
    async updateUserWallet() {
      const responseUpdate = await CardsApi.updateUserWallet({
        requestCard: this.cardRequest._id
      });
      if (responseUpdate && responseUpdate.data) {
        if (responseUpdate.data.error) {
          this.displayNotificationError(this.$i18n.t('register.signature.errorContract'))
        } else {
          this.$store.commit(`${SESSION_STORE}/${SET_TEMP_WALLET_USER}`, true);
          return true;
        }
      } else {
        this.displayNotificationError('Ocurrió un error al firmar contrato')
      }
      return false;
    },
  },
  async beforeMount() {
    if(this.isMoral2) {
      this.isMoral = this.isMoral2 === 'MORAL';
    }

    const routeName = this.$route.name;
    if(routeName === 'contractMultiple'){
      this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_PERSONAL');
    } else if(routeName === 'businessContractMultiple') {
      await this.$store.dispatch(`${onbngStore}/putLevel`, this.isMoral ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC');
    }
    this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
    await this.getDocuments();
    EventBus.$on(`${onbngStore}.${LOAD_CARD_REQUEST}`, this.getDocuments);
  },
  beforeDestroy() {
    EventBus.$off(`${onbngStore}.${LOAD_CARD_REQUEST}`, this.getDocuments);
  },
}
</script>

<style scoped>

</style>
