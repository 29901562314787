<template>
    <div class="panel-dashboard" :class="[variant, {active}]">
        <a v-if="link" href="#"></a>
        <div v-if="icon" class="icon">
            <i
            aria-hidden="true"
            :class="icon"
            ></i>
        </div>
        <slot name="userLink"></slot>
        <div class="info" :class="extraClazzInfo">
            <span v-if="text" class="normal-text">{{text}}</span>
            <span v-if="responsiveText" class="responsive-text">{{responsiveText}}</span>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'DashboardPanel',
        props: {
            variant: String,
            icon: String,
            text: String,
            responsiveText: String,
            link: {type: Boolean, default: false},
            active: {type: Boolean, default: false},
            extraClazzInfo: String,
        },
    }
</script>
