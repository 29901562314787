<template>
    <div class="tachometer-container" :class="clazzContent">
        <div class="tachometer" :class="clazz">
            <div class="arrow"  :style="`transform: translate(-50%, 0%) rotate(${percent * 1.8}deg);`"></div>
        </div>
        <div class="info">
            <span class="number">{{percent}}%</span>
            <label>{{text}}</label>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Tachometer',
        props: {
            clazzContent: String,
            clazz: String,
            percent: String,
            text: String,
        }
    }
</script>
