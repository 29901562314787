<template>
<div>
  <div v-if="showBigLoading">
    <LoadingSimple
        clazz="big-load p-t-50 p-b-50"
        label="Loading..."
    ></LoadingSimple>
  </div>
  <div v-else>
    <TitlePrincipal :title="$t('register.nationality.title')">
      <p class="subtitle" v-html="$t('register.nationality.subtitle')">
      </p>
    </TitlePrincipal>
    <BasicSelect
        clazz="flag-select "
        required
        :label="$t('register.nationality.nationality')">
      <multiselect
          v-model="nationality"
          :options="optionsFlag"
          :disabled="disabledNationality"
          :placeholder="$t('register.nationality.naPlaceHolder')"
          label="name"
          track-by="_id"
          :close-on-select="true"
          :preserve-search="true"
          :option-height="104"
          :show-labels="false"
          @select="getStates"
      >
        <template
            slot="singleLabel"
            slot-scope="props">
          <div class="d-flex align-items-center">
            <img
                class="flag-img"
                :src="getImgUrl(props.option.flagName)"
                alt="image"
            >
            <div class="text">
              {{ props.option.name }}
            </div>
          </div>
        </template>
        <template
            slot="option"
            slot-scope="props">
          <div class="d-flex align-items-center">
            <img
                class="flag-img"
                :src="getImgUrl(props.option.flagName)"
                alt="image2"
            >
            <div class="text">
              {{ props.option.name }}
            </div>
          </div>
        </template>
      </multiselect>
    </BasicSelect>
    <BasicSelect
        required
        :label="$t('register.nationality.titleState')">
      <multiselect
          v-model="state"
          :options="optionsEstado"
          :disabled="disabledState"
          :close-on-select="true"
          :preserve-search="true"
          :placeholder="statesPlaceholder"
          label="name"
          track-by="name"
          :preselect-first="false"
      >
        <template
            slot="selection"
            slot-scope="{ values, search, isOpen }"
        >
                                <span
                                    class="multiselect__single"
                                    v-if="values.length &amp;&amp; !isOpen"
                                >{{ values.length }} options selected</span>
        </template>
      </multiselect>
    </BasicSelect>
    <BasicSelect
        clazz="flag-select "
        required
        :label="$t('register.nationality.homeTitle')">
      <multiselect
          v-model="currentCountry"
          :options="optionsCurrentCountry"
          :disabled="disabledNationality"
          :placeholder="$t('register.nationality.homePH')"
          label="name"
          track-by="_id"
          :option-height="104"
          :show-labels="false">
        <template
            slot="singleLabel"
            slot-scope="props">
          <div class="d-flex align-items-center">
            <img
                class="flag-img"
                :src="getImgUrl(props.option.flagName)"
                alt="image"
            >
            <div class="text">
              {{ props.option.name }}
            </div>
          </div>
        </template>
        <template
            slot="option"
            slot-scope="props">
          <div class="d-flex align-items-center">
            <img
                class="flag-img"
                :src="getImgUrl(props.option.flagName)"
                alt="image2"
            >
            <div class="text">
              {{ props.option.name }}
            </div>
          </div>
        </template>
      </multiselect>
    </BasicSelect>
    <div class="footer-buttons right">
      <button v-if="showBackButton" type="button" class="button button-ghost button-plain_text" name="Regresar" @click="back()"> {{ $t('register.nationality.back') }} </button>
      <button v-if="!loadingButton" type="button" class="button button-filled button-primary" name="Continuar" :disabled="disableButton" @click="submitNation">
        {{ $t('register.nationality.continue') }} </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text" />
      </button>
    </div>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import BasicSelect from "@/components/BasicSelect";
import Multiselect from "vue-multiselect";
import CountriesApi from '@/api/core/countries.api'
import {catchError} from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {required} from "vuelidate/lib/validators";
import UserApi from '@/api/users/users.api';
import LoadingSimple from "@/components/LoadingSimple";
import {
  GET_LEVEL,
  LOAD_GROUP_FILES,
  GET_CARD_REQUEST,
  GO_TO_ROUTE,
  STORE as onbngStore,
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
  GET_USER,
  GET_FULL_DATA_USER,
  LOAD_FULL_DATA_USER,
  STORE as sessionStore
} from "@/store/modules/session/session.types";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import { mapGetters } from 'vuex';
import {EventBus} from "@/main";

export default {
  name: "Nationality",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicSelect,
    Multiselect,
    LoadingSimple
  },
  data() {
    return {
      state: {},
      optionsEstado: [],
      optionsFlag: [],
      optionsCurrentCountry: [],
      nationality: [],
      currentCountry: [],
      loadingButton: false,
      loadingStates: false,
      disabledNationality: true,
      disabledState: true,
      showBigLoading: true
    }
  },
  methods: {
    back() {
      if(this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${onbngStore}/backBusiness`);
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`);
      }
    },
    getImgUrl(pet) {
      const images = require.context('@/assets/images/Icons/flags', false, /\.png$/)
      try {
        return images(`./${pet}`);
      } catch(e) {
        return images(`./Faroe_Islands.png`);
      }
    },
    async getCountries() {
      const response = await CountriesApi.getCountries().catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(this.$i18n.t('register.nationality.errorCountries'))
        } else {
          this.disabledNationality = false;
          this.optionsFlag = response.data.object || [];
          this.optionsCurrentCountry = (response.data.object || []).filter(country => country.applyWallet);

         await this.assignData();
        }
      } else {
        this.displayNotificationError(this.$i18n.t('register.nationality.errorCountries'));
      }
      this.showBigLoading = false;
    },
    async getStates(country) {
      this.state = null;
      this.optionsEstado = [];
      this.disabledState = true;
      this.loadingStates = true;
      const states = await CountriesApi.getStates(country._id).catch(catchError);
      if (states && states.data) {
          if (states.data.error) {
            this.displayNotificationError(this.$i18n.t('register.nationality.errorStates'));
          } else {
            this.disabledState = false;
            this.optionsEstado = states.data.object || [];
          }
      }
      this.loadingStates = false;
    },
    async submitNation() {
      this.loadingButton = true;
      if (!this.disableButton) {
        const response = await UserApi.saveNationality({
          country: this.nationality._id,
          state: this.state._id,
          countryResidence: this.currentCountry._id
        }).catch(catchError);
        if (response && response.data) {
          if (response.data.error) {
              this.displayNotificationError(this.$i18n.t('register.nationality.saveInfo'));
          } else {
            if (this.level > 1) {
              await this.processMayorLevel();
            } else if (this.level === 0) {
              await this.processCollaboratorLevel();
            } else {
              this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
                stepOnbng: ONBOARDING_STEPS.PERSONAL_INFO,
              });
              this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "personalInfo");
            }
          }
        }
      }
      this.loadingButton = false;
    },
    async processMayorLevel() {
      if (this.cardRequest.isMoral) {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.PERSONAL_INFO,
        });
        this.$router.push({name: "businessPersonalInfo"});
      } else {
        await this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.SELECT_DOCUMENT,
        });
        const levelName = this.level === 2 ? "ONBOARDING_LEVEL_2" : "ONBOARDING_LEVEL_3";
        localStorage.setItem(btoa("nationality"), this.nationality._id);
        localStorage.setItem(btoa("processWhereApply"), levelName);
        localStorage.setItem(btoa("residence"), this.currentCountry._id);
        await this.$store.dispatch(`${onbngStore}/${LOAD_GROUP_FILES}`, {
          nationality: this.nationality._id,
          processWhereApply: levelName,
          residence: this.currentCountry._id,
          cardRequest: this.cardRequest?._id,
          appliesToClientAccount: this.cardRequest.accountType === 'BUSINESS'
        });
        // this.$store.dispatch(`${onbngStore}/checkCurrentStep`, {route: 'selectDocuments', doRedirect: true});
        let goTo = "selectDocuments"
        if(this.cardRequest.accountType === 'BUSINESS') {
          goTo = "businessSelectDocuments";
        }
        this.$router.push({name: goTo});
      }
    },
    async processCollaboratorLevel() {
      const levelName = "ONBOARDING_LEVEL_3";
      localStorage.setItem(btoa("nationality"), this.nationality._id);
      localStorage.setItem(btoa("processWhereApply"), levelName);
      localStorage.setItem(btoa("residence"), this.currentCountry._id);
      await this.$store.dispatch(`${onbngStore}/${LOAD_GROUP_FILES}`, {
        nationality: this.nationality._id,
        processWhereApply: levelName,
        residence: this.currentCountry._id,
        cardRequest: this.cardRequest?._id,
        appliesToClientAccount: this.cardRequest?.accountType === 'BUSINESS'
      });
      this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "selectDocumentsCollaborator");
    },
    async assignData() {
       const mexico = this.optionsFlag.find(country => country.isoCode === 'MX');

      if(this.fullDataUser.nationalitieOnboarding) {
        this.nationality = this.fullDataUser.nationalitieOnboarding;
      } else {
            this.nationality = mexico;

      }

      if(this.fullDataUser.countryNewOnboarding) {
        this.currentCountry = this.fullDataUser.countryNewOnboarding;
      } else {
        this.currentCountry = mexico;

      }
        await this.getStates(this.nationality);
      if (this.fullDataUser.birthState) {
        this.state = this.fullDataUser.birthState;
      }
    }
  },
  computed: {
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      cardRequest: GET_CARD_REQUEST,
    }),
    ...mapGetters(sessionStore, {
      user: GET_USER,
      fullDataUser: GET_FULL_DATA_USER
    }),
    disableButton() {
      return this.$v.$invalid || !this.currentCountry?.applyWallet;
    },
    showBackButton() {
      return !this.user?.stepOnboardingCollaborator;
    },
    statesPlaceholder() {
      return this.loadingStates ? this.$t('register.nationality.loadingStates') : this.$t('register.nationality.statePH');
    },
  },
  async created() {
    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
    await this.getCountries();
  },
  async beforeMount() {
    EventBus.$on(`${sessionStore}.${LOAD_FULL_DATA_USER}`, async () => {
      await this.assignData();
    });
  },
  beforeDestroy() {
    EventBus.$off(`sessionStore.${LOAD_FULL_DATA_USER}`);
  },
  validations: {
    state: {
      required
    },
    nationality: {
      required
    },
    currentCountry: {
      required
    },
  },
}
</script>

<style scoped>

</style>
