<template>
    <div id="" class="floating-title-layout" :class="clazz">
        <slot name="userImg"></slot>
        <span>
            <h2 :id="id" :class="titleClass">
                {{ title }}
                <strong> {{ titleStrong }} </strong>
            </h2>
            <small :class="subtitleClass">
                {{ subtitle }}
                <slot></slot>
            </small>
        </span>

        <div class="buttons">
            <slot name="buttons"></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FloatingTitleLayout',
        props: {
            title: String,
            titleStrong: String,
            subtitle: String,
            clazz: String,
            id: String,
            titleClass: String,
            subtitleClass: String,
        }
    }
</script>
