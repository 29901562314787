var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"register-container"},[_c('div',{staticClass:"left-side-content"},[_c('div',{staticClass:"container-left"},[_c('img',{staticClass:"img-fluid logo",attrs:{"src":require("@/assets/images/Logos/logo-color.svg"),"alt":"Logo Pagando"}}),_vm._m(0),_c('div',{staticClass:"vertical-steps-container"},[_c('ul',{staticClass:"vertical-steps"},_vm._l((_vm.documentsToSign),function(document){return _c('li',{key:document._id},[_c('a',{staticClass:"step",class:[
                  document.pagandoAccount === _vm.selectedAccountContract?.account
                    ? 'current'
                    : '',
                ],attrs:{"href":"javascript:void(0)","disabled":_vm.showLoading,"tabindex":""},on:{"click":function($event){return _vm.getContractSelected(document)}}},[_c('a',[_vm._v(" Cuenta "+_vm._s(document?.account)+" ")])])])}),0),(_vm.user.contract === 2 || _vm.user.contract === 4)?_c('button',{staticClass:"button xs button-stroke button-primary m-t-0 ml-auto mr-auto d-xl-none",attrs:{"type":"button","name":"Cerrar sesion"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Cerrar Sesión ")]):_vm._e()]),_c('div',{staticClass:"footer-help"},[_c('a',{staticClass:"help-chat",attrs:{"href":"","tabindex":""},on:{"click":function($event){$event.preventDefault();return _vm.openZendesk.apply(null, arguments)}}},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/Illustrations/illustration-chat-help.svg"),"alt":"Chat de soporte"}})]),_vm._m(1),(_vm.user.contract === 2 || _vm.user.contract === 4)?_c('button',{staticClass:"button button-stroke button-primary m-t-30 ml-auto mr-auto",attrs:{"type":"button","name":"Cerrar sesion"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Cerrar Sesión ")]):_vm._e()])])]),_c('div',{staticClass:"right-side-content"},[_c('div',[(_vm.showLoading)?_c('div',[_c('LoadingSimple',{attrs:{"clazz":"big-load p-t-50 p-b-50","label":"Loading..."}})],1):_vm._e(),(
            !_vm.showLoading &&
            !_vm.showSignSectionBool &&
            !_vm.showSuccessMessage &&
            !_vm.showLiveness &&
            !_vm.selectedAccountContract.waitingAuth
          )?_c('div',[_c('TitlePrincipal',{attrs:{"title":`Actualización de Contrato ${_vm.selectedAccountContract.accountName}`}},[_c('p',{staticClass:"subtitle",domProps:{"innerHTML":_vm._s(
                ` A continuación puedes <strong>leer el nuevo contrato</strong> para continuar con los beneficios de tu cuenta.`
              )}})]),_c('div',{staticClass:"text-contrato",domProps:{"innerHTML":_vm._s(_vm.parsedHtml)}}),_c('b-alert',{staticClass:"alert-note",attrs:{"show":"","variant":"accent2"}},[_c('p',{staticClass:"text-container"},[_c('i',{staticClass:"icon banana banana-comment",attrs:{"aria-hidden":"true"}}),_c('span',{domProps:{"innerHTML":_vm._s(
                  `Una copia de este contrato será enviada a tu correo electrónico <strong>${_vm.user.email}</strong>.`
                )}})])]),_c('div',{staticClass:"footer-buttons right d-flex"},[(_vm.showButton && (_vm.user.contract === 1 || _vm.user.contract === 3))?_c('button',{staticClass:"button button-stroke button-primary m-r-30",attrs:{"type":"button","name":"Aceptar contrato"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Lo hare después ")]):_vm._e(),(_vm.showButton)?_c('button',{staticClass:"button button-filled button-primary",attrs:{"type":"button","name":"Aceptar contrato"},on:{"click":function($event){return _vm.showSignSection()}}},[_vm._v(" "+_vm._s(_vm.$t("register.contract.accept"))+" ")]):_vm._e(),(!_vm.showButton)?_c('button',{staticClass:"button button-filled button-primary",attrs:{"id":'Loading button'}},[_c('LoadingSimple',{attrs:{"clazz":"small-load no-text"}})],1):_vm._e()])],1):_vm._e(),(
            !_vm.showLoading &&
            _vm.showSignSectionBool &&
            !_vm.showSuccessMessage &&
            !_vm.showLiveness &&
            !_vm.selectedAccountContract.waitingAuth
          )?_c('div',[_c('TitlePrincipal',{attrs:{"title":_vm.$t('register.signature.title'),"subtitle":_vm.$t('register.signature.subtitle')}}),_c('span',{staticClass:"vertical-center m-b-10"},[_c('p',{staticClass:"f-18 c-primary_1 poppins-font-semibold d-block m-b-5"},[_vm._v(" "+_vm._s(_vm.$t("register.signature.instruction"))+" ")]),_c('button',{staticClass:"button xs button-stroke button-primary m-0-0-0-auto",attrs:{"type":"button"},on:{"click":_vm.undoSignature}},[_vm._v(" "+_vm._s(_vm.$t("register.signature.deleteSign"))+" ")])]),_c('div',[_c('VueSignaturePad',{ref:"signaturePad",attrs:{"id":"signaturePad","width":"100%","height":"300px","options":_vm.optionsPad}})],1),_c('div',{staticClass:"footer-buttons right d-flex m-t-30"},[_c('button',{staticClass:"button button-ghost button-plain_text",attrs:{"type":"button","name":"Regresar"},on:{"click":function($event){return _vm.back(_vm.selectedAccountContract.isMoral ? 3 : 1)}}},[_vm._v(" "+_vm._s(_vm.$t("register.signature.back"))+" ")]),(_vm.showButton)?_c('button',{staticClass:"button button-filled button-primary",attrs:{"type":"button","name":"Continuar"},on:{"click":_vm.save}},[_vm._v(" Firmar ")]):_c('button',{staticClass:"button button-filled button-primary",attrs:{"id":'Loading button'}},[_c('LoadingSimple',{attrs:{"clazz":"small-load no-text"}})],1)])],1):_vm._e(),(
            !_vm.showLoading &&
            _vm.showLiveness &&
            !_vm.showSuccessMessage &&
            !_vm.selectedAccountContract.waitingAuth
          )?_c('div',[_c('TitlePrincipal',{attrs:{"title":"Prueba de vida","subtitle":"Necesitamos que el representante legal realice una prueba de vida."}}),_c('DynamicDocs',{attrs:{"docs":_vm.livenessDoc,"blockLiveness":false},on:{"add-file":_vm.addFileLiveness,"beforeMount":_vm.reloadDocsLiveness}}),_c('div',{staticClass:"footer-buttons right d-flex m-t-30"},[_c('button',{staticClass:"button button-ghost button-plain_text",attrs:{"type":"button","name":"Regresar"},on:{"click":function($event){return _vm.back(2)}}},[_vm._v(" "+_vm._s(_vm.$t("register.signature.back"))+" ")]),(_vm.showNextLiveness)?_c('button',{staticClass:"button button-filled button-primary",attrs:{"type":"button","name":"Continuar"},on:{"click":function($event){_vm.showSignSectionBool = true;
                _vm.showLiveness = false;}}},[_vm._v(" "+_vm._s(_vm.$t("register.signature.next"))+" ")]):_vm._e()])],1):_vm._e(),(
            !_vm.showLoading &&
            _vm.showSuccessMessage &&
            !_vm.selectedAccountContract.waitingAuth
          )?_c('div',[_c('TitlePrincipal',{attrs:{"clazz":"text-align-c","title":"Tu contrato ha sido firmado exitosamente!","subtitle":_vm.getMessageToShowAfterSign()}}),_c('img',{staticClass:"img-fluid max-h-300px d-flex m-0-auto",attrs:{"src":require("@/assets/images/Illustrations/illustration-bienvenido-pagando.svg"),"alt":"Bienvenido a Pagando"}}),_c('div',{staticClass:"footer-buttons center horizontal-center gap-10 m-t-20"},[(_vm.showButton && _vm.user.contract === 0)?_c('button',{staticClass:"button button-filled button-primary md",attrs:{"type":"button","name":"Ir a mi cuenta"},on:{"click":function($event){return _vm.goHome(true)}}},[_vm._v(" "+_vm._s(_vm.$t("register.welcome.next"))+" ")]):_vm._e(),(
                _vm.showButton && (_vm.user.contract === 1 || _vm.user.contract === 3)
              )?_c('button',{staticClass:"button button-stroke button-primary md",attrs:{"type":"button","name":"Ir a mi cuenta"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" Lo hare después ")]):_vm._e(),(
                _vm.showButton &&
                _vm.user.contracto !== 0 && _vm.documentsToSign.length > 1
              )?_c('button',{staticClass:"button button-filled button-primary",attrs:{"type":"button","name":"Ir a mi cuenta"},on:{"click":function($event){return _vm.showContract()}}},[_vm._v(" Firmar nuevo contrato ")]):_vm._e()])],1):_vm._e(),(!_vm.showLoading && _vm.selectedAccountContract.waitingAuth)?_c('div',[_c('TitlePrincipal',{attrs:{"clazz":"text-align-c","title":"Tu contrato esta en revisión","subtitle":_vm.getMessageToShowWaiting()}}),_c('img',{staticClass:"img-fluid max-h-300px d-flex m-0-auto",attrs:{"src":require("@/assets/images/Illustrations/illustration-modal-generic.svg")}}),_c('div',{staticClass:"footer-buttons center horizontal-center gap-10 m-t-50"},[(
                _vm.showButton && (_vm.user.contract === 1 || _vm.user.contract === 3)
              )?_c('button',{staticClass:"button button-stroke button-primary md",attrs:{"type":"button","name":"Ir a mi cuenta"},on:{"click":function($event){return _vm.openModal()}}},[_vm._v(" "+_vm._s(_vm.$t("register.welcome.next"))+" ")]):_vm._e(),(
                _vm.showButton &&
                _vm.user.contracto !== 0 && _vm.documentsToSign.length > 1
              )?_c('button',{staticClass:"button button-filled button-primary",attrs:{"type":"button","name":"Ir a mi cuenta"},on:{"click":function($event){return _vm.showContract()}}},[_vm._v(" Firmar nuevo contrato ")]):_vm._e()])],1):_vm._e()])])]),_c('ModalAlert',{ref:"warning-time",attrs:{"modalId":"warning-time","buttonType":"button-primary","imgUrl":_vm.imageModalGeneric,"title":"¿Estas seguro?","text":"Tienes 30 dias a partir de que se notificó el cambio de contrato para firmar.","buttonText":"Firmar Contrato","closeCross":true,"buttonCancel":"Lo hare después","noCancelButton":false,"closeEsc":true,"closeBackdrop":true},on:{"submit":function($event){return _vm.$bvModal.hide('warning-time')},"cancel":function($event){return _vm.goHome()}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-percent-title"},[_c('h2',{staticClass:"title"},[_vm._v("Actualización de Contrato")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"help-num"},[_vm._v(" También puedes contactarnos a los teléfonos: "),_c('br'),_c('strong',[_vm._v("México: 800 461 0119 y EU: 800 392 6948")])])
}]

export { render, staticRenderFns }