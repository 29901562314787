<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/settings"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('settings.goBack')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('settings.security.title')"></FloatingTitleLayout>
                        </div>
                        <div class="col-12">
                            <CardSimple :title="$t('settings.security.password.title')" :subtitle="$t('settings.security.password.subtitle')">
                                <router-link :to="{name: 'SecurityChangePass'}" class="hover">
                                    <div class="d-flex justify-content-between align-items-center border-bottom-1px p-t-10 p-b-10 m-b-50">
                                        <div class="c-text_content f-12 poppins-font-regular d-flex align-items-center">
                                            <i aria-hidden="true" class="iconsax-security-lock-2 m-r-20 f-18"></i>
                                            {{$t('settings.security.password.changePass')}}
                                        </div>
                                        <i aria-hidden="true" class="iconsax-arrow-direction-right f-18"></i>
                                    </div>
                                </router-link>
                                <div class="m-b-20">
                                    <label class="poppins-font-bold f-18 c-text_title"> {{$t('settings.security.twoFactorAuth.title')}}
                                        <p class="poppins-font-regular f-12 c-text_content m-0 m-t-5">
                                            {{$t('settings.security.twoFactorAuth.subtitle')}}
                                        </p>
                                    </label>
                                </div>
                                <div class="d-flex justify-content-between align-items-center border-bottom-1px p-t-10 p-b-10">
                                    <div class="c-text_content f-12 poppins-font-regular d-flex align-items-center" v-html="$t('settings.security.twoFactorAuth.fingerPrint')">
                                    </div>
                                    <BasicSwitch idInput="1" @onBasicSwitchChange="changeUserFingerPrintAuth"
                                        :switchValue="user ? user.userFingerPrintAuth : false" :returnOriginValue="returnOriginValueFingerPrint"></BasicSwitch>
                                </div>
                                <div class="d-flex justify-content-between align-items-center border-bottom-1px p-t-10 p-b-10">
                                    <div class="c-text_content f-12 poppins-font-regular d-flex align-items-center" v-html="$t('settings.security.twoFactorAuth.faceID')">
                                    </div>
                                    <BasicSwitch idInput="2" @onBasicSwitchChange="changeUserFacialRecognitionAuth"
                                        :switchValue="user ? user.userFacialRecognitionAuth : false" :returnOriginValue="returnOriginValueFacialRecognition"></BasicSwitch>
                                </div>
                                <div class="d-flex justify-content-between align-items-center border-bottom-1px p-t-10 p-b-10">
                                    <div class="c-text_content f-12 poppins-font-regular d-flex align-items-center" v-html="$t('settings.security.twoFactorAuth.sms')">
                                    </div>
                                    <BasicSwitch idInput="3" @onBasicSwitchChange="changeUserSmsAsTwoFactor"
                                        :switchValue="user ? user.userSmsAsTwoFactor : false" :returnOriginValue="returnOriginValueSmsAsTwoFactor" ></BasicSwitch>
                                </div>
                            </CardSimple>
                        </div>
                        <div class="col-12">
                            <div class="control-buttons">
                                <button
                                    @click="goBack()"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('settings.security.back')}}
                                </button>
                                <div class="f-13 c-text_content poppins-font-medium text-center text-md-right">
                                    {{$t('settings.security.needChange')}}
                                    <a
                                            href="https://www.pagando.mx/contacto-pagando/" target="_blank"
                                            class="c-info poppins-font-bold text-underline hover"
                                            tabindex="">
                                            {{$t('settings.security.contactUs')}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>

    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import BasicSwitch from "@/components/BasicSwitch.vue";
    import imgStatus from '@/assets/images/Illustrations/illustration-gift.svg';
    import imageModalTrash from '@/assets/images/Icons/icon-alert.svg';
    import UserApi from "@/api/users/users.api";
    import {catchError} from "@/utils/utils";
    import {
        STORE as sessionStore,
        GET_USER,
        GET_ACCOUNT,
        SET_FINGER_PRINT_AUTH,
        SET_FACIAL_RECOGNITION_AUTH,
        SET_SMS_TWO_FACTOR
    } from "@/store/modules/session/session.types";
    import {mapGetters} from "vuex";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";

    export default {
        name: "Security",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            CardSimple,
            BasicSwitch
        },
        data() {
            return {
                // Images
                imgStatus: imgStatus,
                imageModalTrash: imageModalTrash,
                window: {
                    width: 0
                },
                isMenuOpen: false,
                showChangePass: false,
                showForgotPass: false,
                emailSend: false,
                passChangeSuccess: false,
                currentPass: null,
                newPass: null,
                confirmNewPass: null,
                returnOriginValueFingerPrint: false,
                returnOriginValueFacialRecognition: false,
                returnOriginValueSmsAsTwoFactor: false,
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                account: GET_ACCOUNT,
                user: GET_USER
            }),
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            async changeUserFingerPrintAuth(value) {
                this.returnOriginValueFingerPrint = false;
                this.returnOriginValueFacialRecognition = false;

                const res = await UserApi.changeFingerPrintAuth({
                    useFingerPrint: value
                }).catch(catchError);

                if(res && res.data && !res.data.error) {
                    this.$store.commit(`${sessionStore}/${SET_FINGER_PRINT_AUTH}`, value);
                    if(value) {
                        this.returnOriginValueFacialRecognition = true;
                        this.$store.commit(`${sessionStore}/${SET_FACIAL_RECOGNITION_AUTH}`, false);
                    }
                    this.toastSuccessDefault();
                } else {
                    this.returnOriginValueFingerPrint = true;
                    let message = this.$t('settings.security.twoFactorAuth.errors.fingerPrint.deactive');
                    if(value) {
                        message = this.$t('settings.security.twoFactorAuth.errors.fingerPrint.active');
                    }
                    this.toastErrorDefault(message);
                }
            },
            async changeUserFacialRecognitionAuth(value) {
                this.returnOriginValueFacialRecognition = false;
                this.returnOriginValueFingerPrint = false;

                const res = await UserApi.changeFacialRecognitionAuth({
                    useFacialRecognition: value
                }).catch(catchError);

                if(res && res.data && !res.data.error) {
                    this.$store.commit(`${sessionStore}/${SET_FACIAL_RECOGNITION_AUTH}`, value);
                    if(value) {
                        this.returnOriginValueFingerPrint = true;
                        this.$store.commit(`${sessionStore}/${SET_FINGER_PRINT_AUTH}`, false);
                    }
                    this.toastSuccessDefault();
                } else {
                    this.returnOriginValueFacialRecognition = true;
                    let message = this.$t('settings.security.twoFactorAuth.errors.facialRecognition.deactive');
                    if(value) {
                        message = this.$t('settings.security.twoFactorAuth.errors.facialRecognition.active');
                    }
                    this.toastErrorDefault(message);
                }
            },
            async changeUserSmsAsTwoFactor(value) {
                this.returnOriginValueSmsAsTwoFactor = false;
                const res = await UserApi.changeSMSAsTwoFactor({
                    useSms: value
                }).catch(catchError);

                if(res && res.data && !res.data.error) {
                    this.$store.commit(`${sessionStore}/${SET_SMS_TWO_FACTOR}`, value);
                    this.toastSuccessDefault();
                } else {
                    this.returnOriginValueSmsAsTwoFactor = true;
                    let message = this.$t('settings.security.twoFactorAuth.errors.smsTwoFactor.deactive');
                    if(value) {
                        message = this.$t('settings.security.twoFactorAuth.errors.smsTwoFactor.active');
                    }
                    this.toastErrorDefault(message);
                }
            },
            goBack() {
                this.$router.push({name: 'Settings'});
            }
        },
        mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        },
    };
</script>
