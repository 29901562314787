<template>
    <div class="loading-info">
        <div class="loading" v-bind:class="clazz">
            <div class="text-align-c">
                <svg class="svg-circle-load" id="load" x="0px" y="0px" viewBox="0 0 150 150">
                    <circle id="loading-inner" class="no-cpu" cx="75" cy="75" r="60"/>
                </svg>
            </div>
            <p>{{label}}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoadingSimple',
        props: {
            clazz: String,
            label: String
        }
    }
</script>
