<template>
  <div class="row">
    <!--  HEADERS  -->
    <div class="col-12">
      <router-link
          to="/wallet/"
          class="button xs button-ghost button-text_info p-l-0 p-r-0"
          tabindex="">
        <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
        {{ $t('contacts.backDash') }}
      </router-link>
      <FloatingTitleLayout :titleStrong="$t('contacts.title')"></FloatingTitleLayout>
      <FloatingTitleLayout
          :titleStrong="$t('contacts.titleStrong')"
          :subtitle="$t('contacts.subtitle')">
        <template v-slot:buttons>
          <b-dropdown id="" right class="dropdown-contact">
            <template #button-content>
              <button
                  id=""
                  type="button"
                  class="button sm button-filled button-primary"
              >
                <i aria-hidden="true" class="iconsax-users-user-add m-r-10"></i>
                {{ $t('contacts.addContact') }}
              </button>
            </template>
            <b-dropdown-item @click="$bvModal.show('modal-contacto-pagando')">
              <SidebarRightLink
                  clazz="colorInfo"
                  icon="iconsax-users-profile-circle"
                  :titleText="$t('contacts.pagandoUser')"
                  :text="$t('contacts.pagandoUserDes')">
                <router-link to="" tabindex="" :aria-label="$t('contacts.pagandoUser')">
                  <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                </router-link>
              </SidebarRightLink>
            </b-dropdown-item>
            <b-dropdown-item @click="$bvModal.show('modal-contacto-otro')">
              <SidebarRightLink
                  clazz="textInfo"
                  icon="iconsax-building-bank"
                  :titleText="$t('contacts.otherBank')"
                  :text="$t('contacts.otherBankDes')"
              >
                <router-link to="" tabindex="" :aria-label="$t('contacts.otherBank')">
                  <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                </router-link>
              </SidebarRightLink>
            </b-dropdown-item>
            <b-dropdown-item @click="$router.push({name: 'BulkContactsList'})" v-if="infoAccount ? infoAccount.productType === 'CLIENTS_ACCOUNT' : false">
              <SidebarRightLink
                  clazz="accent"
                  icon="iconsax-users-2-users"
                  titleText="Importar Contactos"
                  text="Utiliza una plantilla importar varios contactos a la vez."
              >
                <router-link to="" tabindex="" :aria-label="$t('contacts.otherBank')">
                  <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                </router-link>
              </SidebarRightLink>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </FloatingTitleLayout>
    </div>
    <!--  HEADERS  -->

    <!--  LIST  -->
    <div class="col-12">

      <TitleBadge clazzContainer="p-0" :titleText="$t('contacts.MyContacts.title')"
                  :badgeText="contacts.totalDocs.toString()">
        <div class="btn-filter-container p-0 m-b-0">
          <button class="btn-filter" v-bind:class="{ active: listType === 'BOTH' }" type="button"
                  @click="updateType('BOTH')">{{ $t('contacts.MyContacts.all') }}
          </button>
          <button class="btn-filter" v-bind:class="{ active: listType === 'PAGANDO_ACCOUNT' }" type="button"
                  @click="updateType('PAGANDO_ACCOUNT')">
            {{ $t('contacts.MyContacts.friends') }}
          </button>
          <button class="btn-filter" v-bind:class="{ active: listType === 'OTHERS' }" type="button"
                  @click="updateType('OTHERS')">{{ $t('contacts.MyContacts.banks') }}
          </button>
        </div>
      </TitleBadge>

      <BasicInput
          clazz="m-b-15 noLabel panel-search"
          inputType="text"
          :inputPlaceholder="$t('contacts.search')"
          inputClazz="icon-left"
          iconInput="banana-search-normal"
          @input="onSearchChange"
      ></BasicInput>
      <CardSimple
          clazz="p-0"
          hideTitle>
        <div
            class="basic-table table-contactos">
          <b-table-simple
              responsive
              selectable
              selected-variant="active"
              table-variant="xl">
            <b-thead>
              <b-tr class="">
                <b-th> {{ $t('contacts.table.headers.name') }}</b-th>
                <b-th class="text-center" v-for="(header, index) in tableFormat.headers" :key="index"> {{ header }}
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody v-if="!loadingContacts && !contacts.docs.length">
              <b-tr class="noHover">
                <b-td :colspan="tableFormat.colspan">
                  <EmptyState
                      clazz="p-0"
                      emptyStateDefault
                      :title="$t('contacts.table.emptyState.title')"
                      :text="$t('contacts.table.emptyState.subtitle')"
                      :imgUrl="imgEmptystateTable"
                      :imgWidth="390"
                      :imgHeight="320"
                      imgAlt="Sin contactos">
                    <b-dropdown id="" dropup class="dropdown-contact m-t-30 m-0-auto w-fit d-none d-md-flex">
                      <template #button-content>
                        <button type="button" class="button sm button-filled button-primary">
                          <i aria-hidden="true" class="iconsax-users-user-add m-r-10"></i>
                          {{ $t('contacts.addContact') }}
                        </button>
                      </template>
                      <b-dropdown-item @click="$bvModal.show('modal-contacto-pagando')">
                        <SidebarRightLink
                            clazz="colorInfo"
                            icon="iconsax-users-profile-circle"
                            :titleText="$t('contacts.pagandoUser')"
                            :text="$t('contacts.pagandoUserDes')"
                        >
                          <router-link to="" tabindex="" :aria-label="$t('contacts.pagandoUser')">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                          </router-link>
                        </SidebarRightLink>
                      </b-dropdown-item>
                      <b-dropdown-item @click="$bvModal.show('modal-contacto-otro')">
                        <SidebarRightLink
                            clazz="textInfo"
                            icon="iconsax-building-bank"
                            :titleText="$t('contacts.otherBank')"
                            :text="$t('contacts.otherBankDes')"
                        >
                          <router-link to="" tabindex="" :aria-label="$t('contacts.otherBank')">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                          </router-link>
                        </SidebarRightLink>
                      </b-dropdown-item>
                    </b-dropdown>
                  </EmptyState>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-if="loadingContacts">
              <b-tr class="noHover">
                <b-td :colspan="tableFormat.colspan">
                  <LoadingBar clazz="p-t-50 p-b-50" :loadingText="$t('contacts.table.loading')"></LoadingBar>
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-if="!loadingContacts && contacts.docs.length">
              <b-tr class="" v-for="(contact, index) in contacts.docs" :key="`${contact._id}${index}`">
                <b-td class="d-flex align-items-center">
                  <UserAccount
                      :clazz="`xs ${getContactColorClass(contact)}`"
                      :noAccount="!contact.isPagandoAccount"
                      :userImg="contact.image ? imageDownloadLink(contact.image) : imgUserAccount"
                      :userName="getContactInitials(contact)"
                      :widthImg="42"
                      :heightImg="42"
                      :altImgUser="contact.phone || contact.clabe"
                  />
                  <strong class="m-l-15">{{ contact.alias || contact.concatName }}</strong>
                </b-td>
                <b-td class="text-center">
                  {{
                    contact.isPagandoAccount ? $t('contacts.table.pagando') :
                        contact.bank ? contact.bank.name : $t('contacts.table.externalBank')
                  }}
                </b-td>
                <b-td class="text-center" v-if="contact.isPagandoAccount">
                  <strong>{{contact.numberAccount}}</strong><br/>{{contact.phone}}
                </b-td>
                <b-td class="text-center" v-else>{{contact.clabe}}</b-td>
                <b-td class="text-center" v-if="tableFormat.isClient">
                  <div :id="`tooltip${contact._id}${index}`" class="button-circle button-filled button-warning_light xs m-auto"
                       v-if="contact.authorized ? contact.authorized.status === 'PENDING' : false">
                    <i aria-hidden="true" class="iconsax-essential-trash" v-if="contact.action === 'DELETE'"></i>
                    <i aria-hidden="true" class="iconsax-time-clock" v-else></i>
                  </div>
                  <div :id="`tooltip${contact._id}${index}`" class="button-circle button-filled button-success_light xs m-auto"
                       v-if="contact.authorized ? contact.authorized.status === 'AUTHORIZED' : false">
                    <i aria-hidden="true" class="iconsax-essential-tick"></i>
                  </div>
                  <div :id="`tooltip${contact._id}${index}`" class="button-circle button-filled button-error_light xs m-auto"
                       v-if="contact.authorized ? contact.authorized.status === 'REJECTED' : false">
                    <i aria-hidden="true" class="iconsax-essential-close"></i>
                  </div>
                  <b-tooltip
                      :target="`tooltip${contact._id}${index}`"
                      placement="top"
                      custom-class=""
                  >
                    <p class="m-b-0" v-if="contact.authorized ? contact.authorized.status === 'PENDING' : false">
                      <span v-if="contact.action === 'DELETE'"> {{ $t('contacts.table.waitingSignDelete') }} </span>
                      <span v-else> {{ $t('contacts.table.waitingSign') }} </span>
                    </p>
                    <p class="m-b-0" v-if="contact.authorized ? contact.authorized.status === 'AUTHORIZED' : false">
                      {{ $t('contacts.table.authorized') }}
                    </p>
                    <p class="m-b-0" v-if="contact.authorized ? contact.authorized.status === 'REJECTED' : false">
                      <strong>{{ $t('contacts.table.rejected') }}</strong>
                      <br/>
                      {{contact.authorized.rejectReason}}
                    </p>
                  </b-tooltip>
                </b-td>
                <b-td class="text-center" v-if="tableFormat.isClient">
                  <button
                      v-if="canAuthorized && contact.authorized ? contact.authorized.status !== 'AUTHORIZED' : false"
                      @click="openAuthorize(contact)"
                      type="button"
                      class="button-square button-filled button-primary xs m-auto"
                      aria-label=""
                      name=""
                  >
                    <i aria-hidden="true" class="iconsax-content-clipboard-tick-bold"></i>
                  </button>
                  <button
                      v-else
                      type="button"
                      class="button-square button-filled button-placeholder xs m-auto not-active"
                      disabled
                      aria-label=""
                      name=""
                  >
                    <i aria-hidden="true" class="iconsax-ccontent-clipboard-tick-bold"></i>
                  </button>
                </b-td>
                <b-td class="text-center">
                  <div class="d-flex justify-content-center">
                    <button
                        @click="openDelete(contact)"
                        type="button"
                        class="button-square button-filled button-error xs m-r-10"
                        aria-label=""
                        name=""
                    >
                      <i aria-hidden="true" class="iconsax-essential-trash-bold"></i>
                    </button>
                    <button
                        type="button"
                        class="button-square button-filled button-text_info xs"
                        aria-label=""
                        name=""
                    >
                      <i aria-hidden="true" class="iconsax-content-edit-2-bold" @click="openEdit(contact)"></i>
                    </button>
                  </div>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </CardSimple>

      <div class="pagination-custom">
        <span
            class="pagination-count">{{ contacts.totalDocs }} {{ $tc('contacts.pagination', contacts.totalDocs) }}</span>
        <b-pagination
            v-model="currentPage"
            :total-rows="contacts.totalDocs"
            :per-page="perPage"
            aria-controls="my-table"
            next-text=""
            prev-text=""
            hide-goto-end-buttons
            @change="pageChange"
        ></b-pagination>
      </div>
    </div>
    <!--  LIST  -->

    <!--  MODALS-->
    <ModalNewContactPagando @continue="onContactSaved"/>
    <ModalNewContactExternal @continue="onContactSaved"/>

    <b-modal
        id="modal-editar-contacto-pagando"
        size="lg"
        hide-header
        hide-footer
        modal-class="modal-default left-oriented"
    >
      <button
          class="close-modal"
          @click="$bvModal.hide('modal-editar-contacto-pagando')"
          :aria-label="$t('transfers.beneficiaries.new.close')"
          :name="$t('transfers.beneficiaries.new.close')"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{ $t('contacts.modals.editPagando.title') }}</h2>
        <p class="modal-subtitle">{{ $t('contacts.modals.editPagando.subtitle') }}</p>
      </div>
      <div class="body">
        <div>
          <BasicInput
              required
              disabled
              inputId=""
              :label="$t('contacts.modals.editPagando.phone')"
              :value="userContact.phone"
          >
          </BasicInput>
          <BasicInput
              required
              disabled
              inputId=""
              :label="$t('contacts.modals.editPagando.account')"
              :value="userContact.accountNumber"
          >
          </BasicInput>
          <BasicInput
              required
              disabled
              inputId=""
              :label="$t('contacts.modals.editPagando.name')"
              :value="userContact.name"
          >
          </BasicInput>
          <BasicInput
              required
              v-model="userContact.alias"
              :label="$t('contacts.modals.editPagando.alias')"
          >
          </BasicInput>
          <div class="row">
            <div class="col-12 col-md-6">
              <BasicSwitch
                  @onBasicSwitchChange="onSwitchEditChange"
                  idInput="switchEditPagando"
                  ref="switchEditPagando"
                  :label="$t('contacts.modals.editPagando.switchSpeiOut')"
                  :switchValue="userContact.limitSpeiOut"
              />
            </div>
            <div class="col-12 col-md-6" v-if="userContact.limitSpeiOut">
              <BasicInput
                  money
                  v-model="userContact.speiOut"
                  :inputValue="userContact.speiOut.toString()"
                  :label="$t('contacts.modals.editPagando.speiOut')"
              >
              </BasicInput>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button
            v-if="!loadingSaveContact"
            :disabled="!userContact.alias"
            type="button"
            class="button button-filled button-primary w-100"
            @click="editContact"
        >
          {{ $t('contacts.modals.editPagando.save') }}
        </button>
        <button
            v-if="loadingSaveContact"
            type="button"
            class="button button-filled button-primary w-100"
        >
          <LoadingSimple clazz="small-load no-text"></LoadingSimple>
        </button>
      </div>
    </b-modal>

    <b-modal
        id="modal-editar-contacto-otro"
        size="lg"
        hide-header
        hide-footer
        modal-class="modal-default left-oriented"
    >
      <button
          class="close-modal"
          @click="$bvModal.hide('modal-editar-contacto-otro')"
          :aria-label="$t('transfers.beneficiaries.new.close')"
          :name="$t('transfers.beneficiaries.new.close')"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{ $t('contacts.modals.editOther.title') }}</h2>
        <p class="modal-subtitle">{{ $t('contacts.modals.editOther.subtitle') }}</p>
      </div>
      <div class="body">
        <div>
          <BasicInput
              required
              v-model="userContact.clabe"
              @input="onClabeChange"
              :label="$t('contacts.modals.editOther.success.clabe')"
              disabled
          >
          </BasicInput>
          <BasicInput
              required
              disabled
              inputId=""
              :label="$t('contacts.modals.editOther.success.bank')"
              :value="userContact.bank ? userContact.bank.shortName : $t('contacts.modals.newOther.success.clabePh')"
          >
          </BasicInput>
          <BasicInput
              required
              inputId=""
              :label="$t('contacts.modals.editOther.success.name')"
              :value="userContact.name"
              disabled
          >
          </BasicInput>
          <BasicInput
              required
              inputId=""
              :label="$t('contacts.modals.editOther.success.alias')"
              v-model="userContact.alias"
          >
          </BasicInput>
          <div class="row">
            <div class="col-12 col-md-6">
              <BasicSwitch
                  @onBasicSwitchChange="onSwitchEditChange"
                  idInput="switchEditOtro"
                  ref="switchEditOtro"
                  :label="$t('contacts.modals.editPagando.switchSpeiOut')"
                  :switchValue="userContact.limitSpeiOut"
              />
            </div>
            <div class="col-12 col-md-6" v-if="userContact.limitSpeiOut">
              <BasicInput
                  money
                  v-model="userContact.speiOut"
                  :inputValue="userContact.speiOut.toString()"
                  :label="$t('contacts.modals.editPagando.speiOut')"
              >
              </BasicInput>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button
            v-if="!loadingSaveContact"
            :disabled="!userContact.alias || !userContact.name || !userContact.bank || !userContact.clabe"
            type="button"
            class="button button-filled button-primary w-100"
            @click="editContact"
        >
          {{ $t('contacts.modals.editPagando.save') }}
        </button>
        <button
            v-if="loadingSaveContact"
            type="button"
            class="button button-filled button-primary w-100"
        >
          <LoadingSimple clazz="small-load no-text"></LoadingSimple>
        </button>
      </div>
    </b-modal>

    <ModalAlert
        customRef="modal-delete"
        :loading="loadingDelete"
        closeCross
        modalId="modal-delete"
        buttonType="button-primary"
        :imgUrl="imageModalTrash"
        :title="$t('contacts.modals.delete.title')"
        :text="$t('contacts.modals.delete.description')"
        :buttonText="$t('contacts.modals.delete.delete')"
        withAutomaticLoad
        @submit="deleteContact"
    ></ModalAlert>

    <b-modal
        id="modal-aceptar-contacto"
        size="xs"
        hide-header
        hide-footer
        modal-class="modal-default">
      <button class="close-modal"
              @click="$bvModal.hide('modal-aceptar-contacto')"
              aria-label="Boton cerrar ventana"
              name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{ $t('contacts.modals.acceptContact.title') }}</h2>
      </div>
      <div class="body">
        <div class="vertical-center justify-content-center column-gap-50px m-b-30">
          <RadioInput
              clazz="m-b-0"
              name="rejectAuthorize"
              idInput="accept"
              :text="$t('contacts.modals.acceptContact.acceptIt')"
              @selected="acceptReject = true"
          ></RadioInput>
          <RadioInput
              clazz="m-b-0"
              name="rejectAuthorize"
              idInput="deny"
              :text="$t('contacts.modals.acceptContact.rejectIt')"
              @selected="acceptReject = false"
          ></RadioInput>
        </div>
        {{reasonReject}}
        <BasicInput
            v-if="!acceptReject"
            v-model="reasonReject"
            required
            inputId=""
            :label="$t('contacts.modals.acceptContact.rejectedReason')"
            :inputPlaceholder="$t('contacts.modals.acceptContact.rejectedReasonPh')"
        >
        </BasicInput>
        <div class="transfer-account-info w-100">
          <div class="item max-w-initial">
            <div class="item-container">
              <div class="account">
                {{ $t('contacts.modals.acceptContact.name') }}
              </div>
              <div class="number m-b-10">
                {{ current.contactName || current.alias }}
              </div>
              <div class="account">
                {{ $t('contacts.modals.acceptContact.account') }}
              </div>
              <div class="number m-b-10">
                {{ current.numberAccount || current.clabe }}
              </div>
              <div class="account">
                {{ $t('contacts.modals.acceptContact.bank') }}
              </div>
              <div class="number">
                {{ current.bank || 'Pagando' }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button type="button" class="button button-stroke button-primary" @click="$bvModal.hide('modal-aceptar-contacto')">
          {{ $t('contacts.modals.acceptContact.cancel') }}
        </button>
        <button type="button" class="button button-filled button-primary" @click="authorizeReject">
          {{ $t('contacts.modals.acceptContact.confirm') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FloatingTitleLayout from "@/components/FloatingTitleLayout";
import SidebarRightLink from "@/components/SidebarRightLink";
import TitleBadge from "@/components/TitleBadge";
import BasicInput from "@/components/BasicInput";
import CardSimple from "@/components/CardSimple";
import EmptyState from "@/components/EmptyState";
import LoadingBar from "@/components/LoadingBar";
import LoadingSimple from "@/components/LoadingSimple";
import UserAccount from "@/components/UserAccount";
import BasicSwitch from '@/components/BasicSwitch.vue';
import ModalNewContactPagando from "@/pages/Transfers/ModalNewContactPagando";
import ModalNewContactExternal from "@/pages/Transfers/ModalNewContactExternal";
import imgEmptystateTable from "@/assets/images/Emptystates/emptystate-contactos-recientes.svg";
import imgUserAccount from "@/assets/images/Default/profile-photo.svg";
import imageModalTrash from "@/assets/images/Icons/icon-trash-pink.svg";
import {mapGetters} from "vuex";
import {GET_ACCOUNT, GET_ACCOUNT_ID, SET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";
import {EventBus} from "@/main";
import {ACCOUNT_TYPE_BANK_PRODUCT_TYPE} from "@/enums/accountTypeBankProductType.enum";
import ContactsApi from "@/api/wallet/contacts.api";
import {catchError, debounce, imageDownloadLink} from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import ModalAlert from "@/components/ModalAlert";
import BankApi from "@/api/core/bank.api";
import {regexClabe} from "@/utils/regexUtils";
import {getContactColorClass, getContactInitials} from "@/utils/contactUtils";
import RadioInput from "@/components/RadioInput";
import PermissionsMixin from "@/mixins/PermissionsMixin";
import config from "@/config";

export default {
  name: "ContactsList",
  mixins: [ToastSnotify, PermissionsMixin],
  components: {
    FloatingTitleLayout,
    TitleBadge,
    BasicInput,
    CardSimple,
    EmptyState,
    LoadingBar,
    LoadingSimple,
    SidebarRightLink,
    UserAccount,
    ModalAlert,
    RadioInput, BasicSwitch,
    ModalNewContactPagando,
    ModalNewContactExternal,
  },
  computed: {
    ...mapGetters(sessionStore, {
      infoAccount: GET_ACCOUNT,
      accountId: GET_ACCOUNT_ID
    }),
  },
  data() {
    return {
      banks: [],
      // Images
      imgEmptystateTable: imgEmptystateTable,
      imgUserAccount: imgUserAccount,
      imageModalTrash: imageModalTrash,
      tableFormat: {
        headers: [
          this.$i18n.t('contacts.table.headers.bank'),
          this.$i18n.t('contacts.table.headers.account'),
          this.$i18n.t('contacts.table.headers.actions'),
        ],
        isClient: false,
        colspan: 4
      },
      loadingContacts: false,
      listType: "BOTH",
      perPage: 8,
      currentPage: 1,
      search: "",
      contacts: {
        docs: [],
        totalDocs: 0
      },
      current: {},
      // find pagando user
      userContact: {
        accountNumber: "",
        name: "",
        alias: "",
        clabe: "",
        bank: "",
        limitSpeiOut: false,
        speiOut: 0,
      },
      // save user
      loadingSaveContact: false,
      loadingDelete: false,
      // Authorized
      acceptReject: true,
      reasonReject: ""
    }
  },
  methods: {
    getContactColorClass,
    getContactInitials,
    imageDownloadLink,

    onClabeChange() {
      if (this.userContact.clabe && regexClabe.test(this.userContact.clabe)) {
        if (this.userContact.clabe.substring(0, 10) === config.pagando.clabePrefix) {
          this.displayNotificationInfo(this.$i18n.t('contacts.pagandoCLABE'))
          return false;
        }
        const bank = this.banks.find(bank => bank.code === this.userContact.clabe.substring(0, 3));
        if (bank) {
          this.userContact.bank = bank;
        } else {
          this.userContact.bank = null;
        }
      } else {
        this.userContact.bank = null;
      }
      return true;
    },
    onContactSaved() {
      this.resetFilters();
      this.findContacts();
    },
    resetFilters() {
      this.listType = "BOTH";
      this.search = "";
      this.userContact = {
        accountNumber: "",
        name: "",
        alias: "",
        clabe: "",
        bank: "",
      };
      this.current = {};
      this.reasonReject = null;
      this.acceptReject = true;
    },

    openDelete(contact) {
      this.current = contact;
      this.$bvModal.show('modal-delete')
    },
    async deleteContact() {
      this.loadingDelete = true;
      const res = await ContactsApi.delete({
        accountId: this.accountId,
        contactId: this.current._id,
        idPending: this.current.idPending
      }).catch(catchError);
      if (res.data.error) {
        const message = res.data.message || this.$i18n.t('contacts.error.delete');
        this.displayNotificationWarning(message);
      } else {
        this.displayNotificationSuccess(this.$i18n.t('contacts.deleteSuccess'));
        this.resetFilters();
        this.findContacts();
        this.$bvModal.hide('modal-delete')
      }
      this.loadingDelete = false;
    },

    openEdit(contact) {
      this.userContact = {
        accountNumber: contact.numberAccount,
        name: contact.contactName,
        alias: contact.alias,
        clabe: contact.clabe,
        bank: contact.bank,
        phone: contact.phone,
        contactId: contact._id,
        idPending: contact.idPending,
        limitSpeiOut: contact?.speiOutLimit?.limitSpeiOut || false,
        speiOut: Number(contact?.speiOutLimit?.speiOut)
      };
      if (contact.isPagandoAccount) {
        this.$bvModal.show('modal-editar-contacto-pagando');
      } else {
        this.$bvModal.show('modal-editar-contacto-otro');
      }
    },
    async editContact() {
      this.loadingSaveContact = true;
      const res = await ContactsApi.edit({
        accountId: this.accountId,
        contactId: this.userContact.contactId,
        alias: this.userContact.alias,
        idPending: this.userContact.idPending,
        speiOutLimit: {
          limitSpeiOut: this.userContact.limitSpeiOut,
          speiOut: this.userContact.speiOut,
        }
      }).catch(catchError);
      if (res.data.error) {
        const message = res.data.message || this.$i18n.t('contacts.modals.newPagando.error.message');
        this.displayNotificationWarning(message);
      } else {
        this.resetFilters();
        this.findContacts();
        this.$bvModal.hide('modal-editar-contacto-pagando');
        this.$bvModal.hide('modal-editar-contacto-otro');
      }
      this.loadingSaveContact = false;
    },

    openAuthorize(contact) {
      this.current = contact;
      this.$bvModal.show('modal-aceptar-contacto');
    },
    async authorizeReject() {
      this.$bvModal.hide('modal-aceptar-contacto');
      const auth = await this.$requestAuth();
      if (auth) {
        let res;
        if (this.acceptReject) {
          res = await ContactsApi.authorizeContact({
            accountId: this.accountId,
            id: this.current.idPending
          }).catch(catchError)
        } else {
          res = await ContactsApi.rejectContact({
            accountId: this.accountId,
            id: this.current.idPending,
            rejectReason: this.reasonReject
          }).catch(catchError)
        }
        if (res.data.error) {
          const message = res.data.message || this.$i18n.t('contacts.modals.acceptContact.error');
          this.displayNotificationWarning(message)
        } else {
          this.resetFilters();
          this.findContacts();
        }
      }
    },

    onSearchChange(search) {
      debounce(() => {
        this.currentPage = 1;
        this.search = search;
        this.findContacts();
      }, {time: 500});
    },
    pageChange(page) {
      this.currentPage = page;
      this.findContacts();
    },
    updateType(type) {
      this.currentPage = 1;
      this.listType = type;
      this.findContacts();
    },
    async findContacts() {
      this.loadingContacts = true;
      const res = await ContactsApi.listAll({
        accountId: this.infoAccount.account._id,
        type: this.listType,
        page: this.currentPage,
        limit: this.perPage,
        search: this.search,
        format: true,
        retrievePending: true
      }).catch(catchError);
      if (res && res.data && !res.data.error && res.data.object) {
        if (res.data.object.docs && res.data.object.docs.length) {
          this.contacts = res.data.object;
        } else {
          this.contacts.docs = [];
        }
      } else {
        this.displayNotificationWarning(this.$i18n.t('contacts.error.loading'))
      }
      this.loadingContacts = false;
    },
    async initContacts() {
      if (this.infoAccount.account.accountType.productType === ACCOUNT_TYPE_BANK_PRODUCT_TYPE.clientsAccount) {
        this.tableFormat.headers = [
          this.$i18n.t('contacts.table.headers.bank'),
          this.$i18n.t('contacts.table.headers.account'),
          this.$i18n.t('contacts.table.headers.status'),
          this.$i18n.t('contacts.table.headers.validate'),
          this.$i18n.t('contacts.table.headers.actions'),
        ];
        this.tableFormat.colspan = 7;
        this.tableFormat.isClient = true;
      }
      await this.findContacts();
    },
    async loadBanks() {
      try {
        const response = await BankApi.all();
        this.banks = response.data.object;
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },

    onSwitchEditChange(value) {
      this.userContact.limitSpeiOut = value;
    }
  },
  created() {
    this.setCurrentPermissionTypes(this.permissionTypes.BENEFICIARIES);
  },
  beforeMount() {
    this.loadBanks();
    if (this.infoAccount.account._id) {
      this.initContacts()
    }
    EventBus.$on(`${sessionStore}.${SET_ACCOUNT}`, this.initContacts);
  },
  beforeDestroy() {
    EventBus.$off(`${sessionStore}.${SET_ACCOUNT}`, this.initContacts);
  }
}
</script>

<style scoped>

</style>
