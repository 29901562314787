<template>
  <div>
    <TitlePrincipal
      :title="$t('register.residence.title')"
      :subtitle="$t('register.residence.subtitle')"
    >
    </TitlePrincipal>
    <LoadingBar v-if="!showAddress" :loadingText="$t('register.residence.loading')"/>

    <AddressForm v-if="showAddress" v-model="address" ref="addressForm" forPersonal/>
    <div class="footer-buttons right">
        <button @click="back()" type="button" class="button button-ghost button-plain_text" name="Regresar">
          {{ $t('register.residence.back') }} </button>
        <button v-if="!loadingButton" type="button" class="button button-filled button-primary" name="Continuar" :disabled="disableButton" @click="submit()">
          {{ $t('register.residence.next') }} </button>
        <button v-else :id="'Loading button'" class="button button-filled button-primary">
          <LoadingSimple clazz="small-load no-text" />
        </button>
    </div>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import AddressForm from "@/components/AddressForm.vue";
// import { catchError } from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import { required } from "vuelidate/lib/validators";
import UserApi from '@/api/users/users.api';
import LoadingSimple from "@/components/LoadingSimple";
import LoadingBar from "@/components/LoadingBar.vue";

import {
  GET_LEVEL,
  GET_CARD_REQUEST,
  // LOAD_GROUP_FILES,
  GO_TO_ROUTE,
  STORE as onbngStore,
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
  GET_USER,
  GET_FULL_DATA_USER,
  LOAD_FULL_DATA_USER,
  STORE as sessionStore,
} from "@/store/modules/session/session.types";
import { ONBOARDING_STEPS } from "@/utils/onbngSteps";
import { mapGetters } from "vuex";
import { EventBus } from "@/main";

export default {
  name: "Nationality",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    LoadingSimple,
    AddressForm,
    LoadingBar
  },
  data() {
    return {
      loadingButton: false,
      address: {},
      showAddress: false
    };
  },
  methods: {
     back() {
      if(this.level === 0) {
        this.$store.dispatch(`${onbngStore}/backCollaborator`)
      } else if(this.cardRequest.accountType === 'BUSINESS') {
        this.$store.dispatch(`${onbngStore}/backBusiness`)
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`)
      }
    },
    async submit() {
      this.loadingButton = true;
      const response = await UserApi.saveResidence(this.address);

      if (response && response.data) {
        this.loadingButton = false;
        if (response.data.error) {
            this.displayNotificationError('Error guardando info');
        } else {
          this.nextStep();
        }
      } else {
        this.loadingButton = false;
        this.displayNotificationError('Error guardando info');
      }
    },
    nextStep() {
      if(this.level === 0) {
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, 'occupationCollaborator');
        return;
      }
      this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
        stepOnbng: ONBOARDING_STEPS.JOB,
      });

      let goTo = "occupation";
      if(this.$route.name === 'residenceMultiple') {
        goTo = 'occupationMultiple';
      } else if(this.$route.name === 'businessResidenceMultiple') {
        goTo = 'businessOccupationMultiple';
      } else{
        if (this.cardRequest.accountType === "BUSINESS") {
          goTo = "businessOccupation";
        }
      }
      this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, goTo);
    },
    assignData() {
      if(this.fullDataUser?.zipCode) {
        this.address = {
          zipCode: this.fullDataUser.zipCode,
          district: this.fullDataUser.district,
          street: this.fullDataUser.street,
          noExt: this.fullDataUser.noExt,
          noInt: this.fullDataUser.noInt,
        }

      }
      this.showAddress = true;
    }
  },
  computed: {
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      cardRequest: GET_CARD_REQUEST,
    }),
    ...mapGetters(sessionStore, {
      user: GET_USER,
      fullDataUser: GET_FULL_DATA_USER,
    }),
    disableButton() {
      return this.$v.$invalid;
    },
  },
  async created() {
    await this.$store.dispatch(
      `${onbngStore}/checkCurrentStep`,
      this.$route
    );
    await this.$store.dispatch(`${sessionStore}/getFullDataByLevel`, {level: this.cardRequest.information_level});
  },
  async beforeMount() {
    if(this.$route.name === 'residenceMultiple') {
      this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_PERSONAL');
    } else if(this.$route.name === 'businessResidenceMultiple') {
      await this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_BUSINESS_PHYSIC');
    }
    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);


  },
  mounted() {
     EventBus.$on(`${sessionStore}.${LOAD_FULL_DATA_USER}`, () => {
      this.assignData();
    });
  },
  beforeDestroy() {
    EventBus.$off(`sessionStore.${LOAD_FULL_DATA_USER}`);
  },
  validations: {
    address: {
      zipCode: {
        required
      },
      state: {
        required
      },
      delegation: {
        required
      },
      district: {
        required
      },
      street: {
        required
      },
      noExt: {
        required
      }
    },
  },
};
</script>

<style scoped>
</style>
