<template>
    <button type="button" class="panel-service" :class="clazz" @click="openModal()">
        <span class="image">
            <img class="img-fluid" :src="getImage()" width="150" height="100" alt="Servicio" />
        </span>
        <span class="info">
            <label>{{serviceName}}</label>
            <p>{{serviceDescr}}</p>
        </span>
    </button>
</template>

<script>
    import Imagen from '@/assets/images/Backgrounds/Background-login.png';
    import FileApi from "@/api/core/files.api";

    export default {
        name: 'PanelService',
        props: {
            clazz: String,
            serviceName: String,
            serviceDescr: String,
            imgServiceUrl: {type: String},
        },
        data() {
            return {
                defaultUrl: Imagen,
            }
        },
        methods: {
            getImage() {
                if(this.imgServiceUrl) {
                    return FileApi.downloadImage(this.imgServiceUrl);
                }

                return Imagen;
            },
            openModal() {
                this.$emit('click');
            }
        }
    }
</script>
