<template>
  <div class="right-side-content">

    <AccountType  v-if="selectAccount" text="Cuenta seleccionada"
                 clazzAccount="account-business"
                 business
                 :typeAccount="selectAccount">
    </AccountType>
    <div class="container-right" id="container-right">
      <button v-if="checkRoute()"
          type="button"
          class="button xs button-stroke button-primary btn-cancelar-sol"
          @click="$bvModal.show('modal-cancelar-sol')">
          {{$t('multipleAccountPersonal.cancelRequest')}}
      </button>
      <router-view></router-view>
    </div>

    <ModalAlert
        closeCross
        modalId="modal-cancelar-sol"
        buttonType="button-primary"
        :imgUrl="imageModalCancelar"
        :title="$t('multipleAccountPersonal.cancelModal.title')"
        :text="$t('multipleAccountPersonal.cancelModal.text')"
        buttonCancel="No"
        :buttonText="$t('multipleAccountPersonal.cancelModal.button')"
        @submit="cancelRequest()"
    ></ModalAlert>
  </div>
</template>

<script>

import AccountType from "@/components/AccountType.vue";
import ModalAlert from "@/components/ModalAlert.vue";
import {GET_SELECT_ACCOUNT, GET_CARD_REQUEST, STORE as onbngStore} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {mapGetters} from "vuex";
import imageModalCancelar from "@/assets/images/Icons/icon-card-remove.svg";
import {EventBus} from "@/main";
import CardsApi from "@/api/wallet/card.api";

export default {
  name: "RightSide",
  components: {
    AccountType,
    ModalAlert
  },
  computed: {
    ...mapGetters(onbngStore, {
      selectAccount: GET_SELECT_ACCOUNT,
      cardRequest: GET_CARD_REQUEST
    })
  },
  data() {
    return {
      imageModalCancelar,
      showBack: false
    }
  },
  beforeMount() {
    this.$forceUpdate();

    EventBus.$on(`hide-cancel-request`, (value) => {
        this.showBack = value;
    });

  },
  methods: {
    async cancelRequest() {
      if(this.cardRequest && this.cardRequest.status !== 'CANCELLED') {
        await CardsApi.cancelRequestCard({id: this.cardRequest._id});
      }

      this.$router.push({name: 'Dashboard'});
    },
    checkRoute() {
      return (this.$route.matched.some(r => r.name === 'selectAccountMultiple' || r.name === 'usagePoliciesMultiple' || r.name === 'selectDocumentsMultiple'
      || r.name === 'uploadDocumentsMultiple' || r.name === 'addInfoMultiple' || r.name === 'selectSchemaBusinessMultiple' || r.name === 'selectAccountBusinessMultiple'
      || r.name === 'selectSchemaBusinessMultiple' || r.name === 'selectAccountBusinessMultiple' || r.name === 'selectCreateOrgBusinessMultiple' || r.name === 'residenceMultiple'
      || r.name === 'occupationMultiple' || r.name === 'usagePoliciesBusinessMultiple'))
      || this.showBack;
    }
  }
}
</script>

<style scoped>

</style>
