<template>
<div>
  <TitlePrincipal clazz="text-align-c"
                  :title="routeName === 'onbngWelcomeMultiple' ? $t('multipleAccountPersonal.success.title') : $t('register.welcome.title')"
                  :subtitle="routeName === 'onbngWelcomeMultiple' ? $t('multipleAccountPersonal.success.subtitle') : $t('register.welcome.subtitle', {name: user.name})">
  </TitlePrincipal>
  <img class="img-fluid max-h-300px d-flex m-0-auto"
       src="@/assets/images/Illustrations/illustration-bienvenido-pagando.svg"
       alt="Bienvenido a Pagando"/>
  <div class="footer-buttons center">
    <button v-if="showButton" type="button" class="button button-filled button-primary" name="Ir a mi cuenta" @click="finishLevelOne">
      {{$t('register.welcome.next')}} </button>
    <button v-else :id="'Loading button'" class="button button-filled button-primary">
      <LoadingSimple clazz="small-load no-text"/>
    </button>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import {
  STORE as SESSION_STORE,
  GET_USER
} from "@/store/modules/session/session.types";
import { mapGetters } from 'vuex';
import LoadingSimple from "@/components/LoadingSimple";
import {
  GET_LEVEL,
  GET_CARD_REQUEST,
  GET_ACCOUNT_TYPE_BUSINESS,
  STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import UserApi from "@/api/users/users.api"
import {catchError} from "@/utils/utils";
import {
  STORE as OrganizationInfoStore,
  SET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";

export default {
  name: "OnbngWelcome",
  components: {
    TitlePrincipal,
    LoadingSimple
  },
  computed: {
    ...mapGetters(SESSION_STORE, {
      user: GET_USER
    }),
    ...mapGetters(onbngStore, {
      cardRequest: GET_CARD_REQUEST,
      isMoral2: GET_ACCOUNT_TYPE_BUSINESS,
      level: GET_LEVEL
    }),
  },
  data() {
    return {
      showButton: true,
      routeName: null
    }
  },
  methods: {
    async finishLevelOne() {
        this.showButton = false;
      if(this.level !== 0) {
        await UserApi.finishLevelOne({
          cardRequestInfoId: this.cardRequest._id,
          setSecurity: false
        }).catch(catchError);
      }
      this.$store.commit(`${OrganizationInfoStore}/${SET_ORGANIZATION_INFO}`, null);
      await this.$store.dispatch(`${SESSION_STORE}/loadAccount`);
      this.$router.push({name: "Dashboard"})
      this.showButton = true;
    }
  },
  async beforeMount() {
    if(this.isMoral2) {
      this.isMoral = this.isMoral2 === 'MORAL';
    }

    this.routeName = this.$route.name;
    if(this.routeName === 'onbngWelcomeMultiple'){
      this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_PERSONAL');
    } else if(this.routeName === 'businessContractMultiple') {
      await this.$store.dispatch(`${onbngStore}/putLevel`, this.isMoral ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC');

    }
    this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
  }
}
</script>

<style scoped>

</style>
