<template>
<div>
  <div v-if="showPhone">
    <div class="p-relative d-block w-100 m-b-10">
      <router-link to="/login"
                   class="button xs button-ghost button-info p-l-5 p-r-5 text-capit"
                   tabindex="">
        <i
            aria-hidden="true"
            class="banana banana-arrow-left m-r-10">
        </i>
        {{ $t('login.recoverAccount.back') }}
      </router-link>
    </div>
    <TitlePrincipal
        :title="$t('login.recoverAccount.title')"
        :subtitle="$t('login.recoverAccount.subtitle')">
    </TitlePrincipal>
    <TelInput
        v-model="phone"
        required
        :label="$t('login.recoverAccount.phone')"
        idInput="numCountry"
        :inputPlaceholder="$t('login.recoverAccount.phonePh')"
    />
    <button v-if="showLogin" type="button" class="button button-filled button-primary w-100"
            :disabled="disableButton" @click="recoverEmail">
      {{ $t('login.recoverAccount.recoverAccount') }}
    </button>
    <button v-else type="button" class="button button-filled button-primary w-100">
      <LoadingSimple clazz="small-load no-text" />
    </button>
    <span class="notification-action">
      <label>
        {{ $t('login.recoverAccount.iRemember') }}
        <router-link to="/login" class="hover" tabindex="">{{ $t('login.recoverAccount.login') }}</router-link>
      </label>
    </span>
  </div>
  <div v-else>
    <div class="p-relative d-block w-100 m-b-10">
      <router-link to="/login"
                   class="button xs button-ghost button-info p-l-5 p-r-5 text-capit"
                   tabindex="">
        <i
            aria-hidden="true"
            class="banana banana-arrow-left m-r-10">
        </i>
        {{$t('login.recoverAccount.back')}}
      </router-link>
    </div>
    <TitlePrincipal
        :title="$t('login.recoverAccount.title2')"
        :subtitle="$t('login.recoverAccount.subtitle2')">
    </TitlePrincipal>
    <button v-if="showLogin" type="button" class="button button-stroke button-primary w-100"
            @click="recoverEmail">
      {{$t('login.recoverAccount.resend')}}
    </button>
    <button v-else type="button" class="button button-stroke button-primary w-100">
      <LoadingSimple clazz="small-load no-text" />
    </button>
    <p class="f-12 c-info_text poppins-font-semibold f-600 text-align-c m-t-20 m-b-20" v-html="$t('login.recoverAccount.footer')"></p>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import TelInput from "@/components/TelInput";
import LoadingSimple from "@/components/LoadingSimple";
import {maxLength, minLength, required} from "vuelidate/lib/validators";
import UserApi from "@/api/users/users.api";
import {catchError} from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";

export default {
  name: "RecoveEmail",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    TelInput,
    LoadingSimple
  },
  computed: {
    disableButton() {
      return this.$v.$invalid;
    }
  },
  methods: {
    async recoverEmail() {
      this.showLogin = false;
      const res = await UserApi.recoverPhone({
        phone: this.phone
      }).catch(catchError);
      if (res && res.data) {
        if (res.data.error) {
            const message = res.data.message || this.$i18n.t('login.recoverAccount.error');
            this.displayNotificationError(message);
        } else {
          this.showPhone = false;
        }
      } else {
        this.displayNotificationError(this.$i18n.t('login.recoverAccount.error'))
      }
      this.showLogin = true;
    }
  },
  data() {
    return {
      showPhone: true,
      showLogin: true,
      phone: ""
    }
  },
  validations: {
    phone: {
      required,
      minLength: minLength(13),
      maxLength: maxLength(16),
    }
  }
}
</script>

<style scoped>

</style>
