<template>
  <CardSimple
      :title="$t('myAccount.limits.title')"
      :subtitle="$t('myAccount.limits.subtitle')">
    <div class="row">
      <div class="col-12" v-if="amountTransactions.hasLimit">
        <ReadOnlyInfo
            :title="$t('myAccount.limits.amountTransactions')"
            :text="amountTransactions.limit | currency"
        ></ReadOnlyInfo>
      </div>
      <div class="col-12 col-lg-6" v-if="qtyTransactions.hasLimit">
        <OperationalLimitsBar
            :titleBar="$t('myAccount.limits.qtyTransactions', {time: qtyTransactions.time})"
            :valueProgressBar="qtyTransactions.current"
            :maxProgressBar="qtyTransactions.limit"
            :warningProgressBar="qtyTransactions.warning"
            :dangerProgressBar="qtyTransactions.danger"
            :textBar="$t('myAccount.limits.accumulated')"
            :textBarStrong="qtyTransactions.current.toString()"
            :textBarRight="$t('myAccount.limits.transactions', {count: qtyTransactions.limit})">
        </OperationalLimitsBar>
      </div>
      <div class="col-12 col-lg-6" v-if="qtyCashWithdrawals.hasLimit">
        <OperationalLimitsBar
            :titleBar="$t('myAccount.limits.qtyCashWithdrawals', {time: qtyCashWithdrawals.time})"
            :valueProgressBar="qtyCashWithdrawals.current"
            :maxProgressBar="qtyCashWithdrawals.limit"
            :warningProgressBar="qtyCashWithdrawals.warning"
            :dangerProgressBar="qtyCashWithdrawals.danger"
            :textBar="$t('myAccount.limits.accumulated')"
            :textBarStrong="qtyCashWithdrawals.current.toString()"
            :textBarRight="$t('myAccount.limits.transactions', {count: qtyCashWithdrawals.limit})">
        </OperationalLimitsBar>
      </div>
      <div class="col-12 col-lg-6" v-if="amountDeposits.hasLimit">
        <OperationalLimitsBar
            :titleBar="$t('myAccount.limits.amountDeposits', {time: amountDeposits.time})"
            :valueProgressBar="amountDeposits.current"
            :maxProgressBar="amountDeposits.limit"
            :warningProgressBar="amountDeposits.warning"
            :dangerProgressBar="amountDeposits.danger"
            :textBar="$t('myAccount.limits.accumulated')"
            :textBarStrong="amountDeposits.current.toString() | currency"
            :textBarRight="amountDeposits.limit.toString() | currency">
        </OperationalLimitsBar>
      </div>
      <div class="col-12 col-lg-6" v-if="limitCashWithdrawals.hasLimit">
        <OperationalLimitsBar
            :titleBar="$t('myAccount.limits.limitCashWithdrawals', {time: limitCashWithdrawals.time})"
            :valueProgressBar="limitCashWithdrawals.current"
            :maxProgressBar="limitCashWithdrawals.limit"
            :warningProgressBar="limitCashWithdrawals.warning"
            :dangerProgressBar="limitCashWithdrawals.danger"
            :textBar="$t('myAccount.limits.accumulated')"
            :textBarStrong="limitCashWithdrawals.current.toString() | currency"
            :textBarRight="limitCashWithdrawals.limit.toString() | currency">
        </OperationalLimitsBar>
      </div>
      <div class="col-12 col-lg-6" v-if="accumulatedBalance.hasLimit">
        <OperationalLimitsBar
            :titleBar="$t('myAccount.limits.accumulatedBalance')"
            :valueProgressBar="Number(accumulatedBalance.current)"
            :maxProgressBar="accumulatedBalance.limit"
            :warningProgressBar="accumulatedBalance.warning"
            :dangerProgressBar="accumulatedBalance.danger"
            :textBar="$t('myAccount.limits.accumulated')"
            :textBarStrong="accumulatedBalance.current.toString() | currency"
            :textBarRight="accumulatedBalance.limit.toString() | currency">
        </OperationalLimitsBar>
      </div>
      <div class="col-12 col-lg-6" v-if="amountCashWithdrawals.hasLimit">
        <OperationalLimitsBar
            :titleBar="$t('myAccount.limits.amountCashWithdrawals', {time: amountCashWithdrawals.time})"
            :valueProgressBar="amountCashWithdrawals.current"
            :maxProgressBar="amountCashWithdrawals.limit"
            :warningProgressBar="amountCashWithdrawals.warning"
            :dangerProgressBar="amountCashWithdrawals.danger"
            :textBar="$t('myAccount.limits.accumulated')"
            :textBarStrong="amountCashWithdrawals.current.toString() | currency"
            :textBarRight="amountCashWithdrawals.limit.toString() | currency">
        </OperationalLimitsBar>
      </div>
      <div class="col-12 col-lg-6" v-if="amountDepositsTransfers.hasLimit">
        <OperationalLimitsBar
            :titleBar="$t('myAccount.limits.amountDepositsTransfers', {time: amountDepositsTransfers.time})"
            :valueProgressBar="amountDepositsTransfers.current"
            :maxProgressBar="amountDepositsTransfers.limit"
            :warningProgressBar="amountDepositsTransfers.warning"
            :dangerProgressBar="amountDepositsTransfers.danger"
            :textBar="$t('myAccount.limits.accumulated')"
            :textBarStrong="amountDepositsTransfers.current.toString() | currency"
            :textBarRight="amountDepositsTransfers.limit.toString() | currency">
        </OperationalLimitsBar>
      </div>
      <div class="col-12 col-lg-6" v-if="amountDepositsCash.hasLimit">
        <OperationalLimitsBar
            :titleBar="$t('myAccount.limits.amountDepositsCash', {time: amountDepositsCash.time})"
            :valueProgressBar="amountDepositsCash.current"
            :maxProgressBar="amountDepositsCash.limit"
            :warningProgressBar="amountDepositsCash.warning"
            :dangerProgressBar="amountDepositsCash.danger"
            :textBar="$t('myAccount.limits.accumulated')"
            :textBarStrong="amountDepositsCash.current.toString() | currency"
            :textBarRight="amountDepositsCash.limit.toString() | currency">
        </OperationalLimitsBar>
      </div>
      <div class="col-12 col-lg-6" v-if="limitPin.hasLimit">
        <OperationalLimitsBar
            :titleBar="$t('myAccount.limits.limitPin', {time: limitPin.time})"
            :valueProgressBar="limitPin.current"
            :maxProgressBar="limitPin.limit"
            :warningProgressBar="limitPin.warning"
            :dangerProgressBar="limitPin.danger"
            :textBar="$t('myAccount.limits.accumulated')"
            :textBarStrong="limitPin.current.toString()"
            :textBarRight="$t('myAccount.limits.intents', {count: limitPin.limit})">
        </OperationalLimitsBar>
      </div>


      <div class="col-12">
        <b-alert class="alert-note" show variant="info m-b-0">
          <p class="text-container">
            <i
                aria-hidden="true"
                class="icon iconsax-emails-message-bold"
            ></i>
            {{$t('myAccount.limits.warning')}}
          </p>
        </b-alert>
      </div>
    </div>
  </CardSimple>
</template>

<script>
import CardSimple from "@/components/CardSimple";
import ReadOnlyInfo from "@/components/ReadOnlyInfo";
import OperationalLimitsBar from "@/components/OperationalLimitsBar";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {EventBus} from "@/main";
import {GET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";
import {mapGetters} from "vuex";

export default {
  name: "OperationalLimits",
  mixins: [ToastSnotify],
  components: {
    CardSimple,
    ReadOnlyInfo,
    OperationalLimitsBar,
  },
  data() {
    return {
      amountTransactions: {},
      qtyTransactions: {},

      amountDeposits: {},
      amountDepositsTransfers: {},
      amountDepositsCash: {},
      accumulatedBalance: {},
      amountCashWithdrawals: {},
      qtyCashWithdrawals: {},
      limitCashWithdrawals: {},
      limitPin: {},
    }
  },
  computed: {
    ...mapGetters(sessionStore, {
      infoAccount: GET_ACCOUNT
    }),
  },
  methods: {
    /**
     * los limites tipo Frecuencia (QTY)
     * solo deben tener Periodicidad de tiempo
     * o extrañamente único
     * @param periodicity
     * @return {boolean}
     */
    dropLimitQtyPerEvent(periodicity) {
      if (periodicity) {
        return periodicity !== "BY_EVENT";
      }
      return false;
    },
    getTime(periodicity, gender = "M") {
      switch (periodicity) {
        case "MONTHLY":
          return this.$i18n.t('myAccount.limits.MONTHLY');
        case "QUARTERLY":
          return this.$i18n.t('myAccount.limits.QUARTERLY');
        case "BIANNUAL":
          return this.$i18n.t('myAccount.limits.BIANNUAL');
        case "ANNUAL":
          return this.$i18n.t('myAccount.limits.ANNUAL');
        case "BY_EVENT":
          return this.$i18n.t('myAccount.limits.BY_EVENT');
        case "DAILY":
        default:
          if (gender === "F") {
            return this.$i18n.t('myAccount.limits.DAILY_F');
          }
          return this.$i18n.t('myAccount.limits.DAILY_M');
      }
    },
    /**
     * Establece los warning y dangers para la barra de progreso
     * @param limit
     * @return {{warning: number, danger: number}}
     */
    setBarLimits(limit) {
      const baseLimit = Number(limit) || 10;
      const warning = Number((baseLimit * 0.7).toFixed(0));
      const danger = Number((baseLimit * 0.9).toFixed(0));
      return {warning, danger}
    },
    getOperationLimits(limits) {
      for (const limit of limits) {
        const {warning, danger} = this.setBarLimits(limit.limit);
        switch (limit.id) {
          case "AMOUNT_TRANSACTIONS":
            this.amountTransactions = limit;
            this.amountTransactions.hasLimit = true;
            break;
          case "QTY_TRANSACTIONS":
            this.qtyTransactions = limit;
            this.qtyTransactions.hasLimit = this.dropLimitQtyPerEvent(limit.periodicity);
            if (this.qtyTransactions.hasLimit) {
              this.qtyTransactions.time = this.getTime(limit.periodicity, "F");
              this.qtyTransactions.warning = warning
              this.qtyTransactions.danger = danger
            }
            break;
          case "QTY_CASH_WITHDRAWALS":
            this.qtyCashWithdrawals = limit;
            this.qtyCashWithdrawals.hasLimit = this.dropLimitQtyPerEvent(limit.periodicity);
            if (this.qtyCashWithdrawals.hasLimit) {
              this.qtyCashWithdrawals.time = this.getTime(limit.periodicity, "F");
              this.qtyCashWithdrawals.warning = warning
              this.qtyCashWithdrawals.danger = danger
            }
            break;
          case "AMOUNT_DEPOSITS":
            this.amountDeposits = limit;
            this.amountDeposits.hasLimit = true;
            this.amountDeposits.time = this.getTime(limit.periodicity);
            this.amountDeposits.warning = warning
            this.amountDeposits.danger = danger
            break;
          case "LIMIT_CASH_WITHDRAWALS":
            this.limitCashWithdrawals = limit;
            this.limitCashWithdrawals.hasLimit = true;
            this.limitCashWithdrawals.time = this.getTime(limit.periodicity);
            this.limitCashWithdrawals.warning = warning
            this.limitCashWithdrawals.danger = danger
            break;
          case "ACCUMULATED_BALACE":
            this.accumulatedBalance = limit;
            this.accumulatedBalance.hasLimit = true;
            this.accumulatedBalance.warning = warning
            this.accumulatedBalance.danger = danger
            break;
          case "AMOUNT_CASH_WITHDRAWALS":
            this.amountCashWithdrawals = limit;
            this.amountCashWithdrawals.hasLimit = true;
            this.amountCashWithdrawals.time = this.getTime(limit.periodicity);
            this.amountCashWithdrawals.warning = warning
            this.amountCashWithdrawals.danger = danger
            break;
          case "AMOUNT_DEPOSITS_TRANSFERS":
            this.amountDepositsTransfers = limit;
            this.amountDepositsTransfers.hasLimit = true;
            this.amountDepositsTransfers.time = this.getTime(limit.periodicity);
            this.amountDepositsTransfers.warning = warning
            this.amountDepositsTransfers.danger = danger
            break;
          case "AMOUNT_DEPOSITS_CASH":
            this.amountDepositsCash = limit;
            this.amountDepositsCash.hasLimit = true;
            this.amountDepositsCash.time = this.getTime(limit.periodicity);
            this.amountDepositsCash.warning = warning
            this.amountDepositsCash.danger = danger
            break;
          default:
            this.limitPin = limit;
            this.limitPin.hasLimit = true;
            this.limitPin.time = this.getTime(limit.periodicity);
            this.limitPin.warning = warning
            this.limitPin.danger = danger
            break;
        }
      }
    }
  },
  beforeMount() {
    EventBus.$on(`MyAccountOperationalLimits`, this.getOperationLimits);
  },
  beforeDestroy() {
    EventBus.$off(`MyAccountOperationalLimits`, this.getOperationLimits);
  }
}
</script>

<style scoped>

</style>
