<template>
    <div class="row">
        <div class="col-12">
            <router-link
                to="/wallet/dashboard"
                class="button xs button-ghost button-text_info p-l-0 p-r-0"
                tabindex=""
            >
                <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                {{$t('general.returnHome')}}
            </router-link>
            <FloatingTitleLayout :titleStrong="$t('cardRequest.title')"></FloatingTitleLayout>
        </div>

        <div class="col-12">
            <CardSimple
                clazzTitle="lg m-b-0"
                :title="$t('cardRequest.title-long')"
                :subtitle="$t('cardRequest.subtitle')"
            >
                <template v-slot:titleBefore>
                    <img
                        class="img-fluid"
                        :src="accountImage"
                        width="209"
                        height="126"
                        :alt="accountDescription"
                    />
                </template>
            </CardSimple>
        </div>

        <div
            class="col-12"
            v-if="!showAddressChange"
        >
            <CardSimple
                clazzTitle="m-b-0"
                :title="$t('cardRequest.address')"
                :subtitle="newAddress ? newAddressDescription : userAddressDescription"
                showButtons
            >
                <template v-slot:titleBefore>
                    <div class="icon-bgm icon-warning">
                        <i aria-hidden="true" class="iconsax-location-location"></i>
                    </div>
                </template>

                <template v-slot:titleButtons>
                    <button
                        type="button"
                        class="button sm button-stroke button-primary w-100"
                        v-text="changeAddressButtonText"
                        :disabled="loading || fakeLoading || actuallyLoading"
                        @click="openAddressChange"
                    >
                    </button>
                </template>

                <div
                    class="row"
                    v-if="fakeLoading"
                >
                    <div class="col-12">
                        <img
                            class="img-fluid d-flex m-0-auto m-t-30 m-b-30"
                            :src="envelopeImage"
                            width="378"
                            height="210"
                            :alt="accountDescription" />
                        <b-progress
                            max="100"
                            class="xl"
                            :value="fakeLoadingProgress"
                            :class="{'success' : fakeLoadingProgress === 100 }">
                        </b-progress>
                        <ReadOnlyInfo
                            clazz="m-t-20 text-center"
                            :title="$t('cardRequest.congrats')"
                            :text="$t('cardRequest.congrats-text')"
                            titleClazz="poppins-font-semibold f-600">
                        </ReadOnlyInfo>
                        <button
                            type="button"
                            class="button sm button-filled button-primary w-100"
                            v-text="$t('cardRequest.cancel')"
                            :disabled="!fakeLoading || actuallyLoading"
                            @click="cancel"
                        ></button>
                    </div>
                </div>

            </CardSimple>
        </div>

        <div
            class="col-12"
            v-else
        >
            <CardRequestAddressChange
                @cancel="cancelAddressChange"
                @save="saveNewAddress"
            ></CardRequestAddressChange>
        </div>

        <LoadingBar
            clazz="fullcontent-loading"
            :loadingText="$t('cardRequest.loading')"
            v-if="actuallyLoading"
        />

        <div
            class="col-12"
            v-if="cardRequestCommission && cardRequestCommission.cost > 0"
        >
            <b-alert class="alert-note" show variant="warning">
                <p class="text-container">
                    <i
                        aria-hidden="true"
                        class="icon banana banana-warning-circle2"
                    ></i>
                    <span v-html="$t('cardRequest.cost-warning', {cost: $options.filters.currency(cardRequestCommission.cost)})"></span>
                </p>
            </b-alert>
        </div>

        <div
            class="col-12"
            v-if="pendingCommissionAmount > 0"
        >
            <b-alert class="alert-note" show variant="warning">
                <p class="text-container">
                    <i
                        aria-hidden="true"
                        class="icon banana banana-warning-circle2"
                    ></i>
                    <span v-html="$t('cardRequest.pending-commission-warning', {cost: $options.filters.currency(pendingCommissionAmount)})"></span>
                </p>
            </b-alert>
        </div>

        <div
            class="col-12"
            v-if="location.error"
        >
            <b-alert class="alert-note" show variant="danger">
                <p class="text-container">
                    <i
                        aria-hidden="true"
                        class="icon banana banana-cross-circle1"
                    ></i>
                    <span v-html="$t('cardRequest.location-error')"></span>
                </p>
            </b-alert>
        </div>

        <div
            class="col-12"
            v-if="!!virtualCardWrapper.requestCard"
        >
            <b-alert class="alert-note" show variant="danger">
                <p class="text-container">
                    <i
                        aria-hidden="true"
                        class="icon banana banana-cross-circle1"
                    ></i>
                    <span v-html="$t('cardRequest.already-requested')"></span>
                </p>
            </b-alert>
        </div>

        <div class="col-12">
            <div class="control-buttons">
                <button
                    type="button"
                    class="button-icon"
                    @click="() => $router.push({name: 'Dashboard'})"
                >
                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                    {{$t('general.return')}}
                </button>
                <div class="vertical-center">
                    <button
                        v-if="showDepositButton"
                        type="button"
                        class="button button-stroke button-primary"
                        v-text="$t('cardRequest.deposit')"
                        @click="openDepositTab"
                    ></button>
                    <button
                        type="button"
                        class="button button-filled button-primary"
                        :disabled="!canConfirm"
                        v-text="$t('cardRequest.confirm')"
                        @click="confirm"
                    ></button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import imgEmptyCP from "@/assets/images/Emptystates/emptystate-no-cp.svg";
    import LoadingBar from "@/components/LoadingBar.vue";
    import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
    import {mapGetters} from "vuex";
    import {GET_ACCOUNT_IMG, GET_ACCOUNT_DES, GET_ACCOUNT, GET_USER, STORE as sessionStore} from "@/store/modules/session/session.types";
    import UserApi from "@/api/users/users.api.js";
    import CardsApi from "@/api/wallet/card.api.js";
    import CardRequestAddressChange from "@/pages/CardRequestAddressChange.vue"
    import EnvelopeExplore from '@/assets/images/Cards/img-card-frame-explore.svg';
    import EnvelopeExplorePlus from '@/assets/images/Cards/img-card-frame-explore-plus.svg';
    import EnvelopeNight from '@/assets/images/Cards/img-card-frame-night.svg';
    import EnvelopeBusiness from '@/assets/images/Cards/img-card-frame-business.svg';
    import store from "@/store/index";
    import AccountRequestAPI from '@/api/wallet/accountRequest.api.js';

    function _wait(percentToIncrease, totalMillis) {
        const time = percentToIncrease * totalMillis / 100;
        return new Promise((resolve) => setTimeout(() => resolve(percentToIncrease), time));
    }

    export default {
        name: "CardRequest",
        components: {
            FloatingTitleLayout,
            CardSimple,
            LoadingBar,
            ReadOnlyInfo,
            CardRequestAddressChange,
        },
        data() {
            return {
                // Images
                imgEmptyCP: imgEmptyCP,
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
                // Multiselect
                valuecolonia: [],
                optionsColonia: [],

                userFull: null,
                accountWithBalance: null,
                loading: false, // carga de datos inicial
                fakeLoading: false, // carga de datos que da chance de cancelar
                actuallyLoading: false, // ahora sí se está confirmando
                fakeLoadingProgress: 0,
                showAddressChange: false,
                newAddress: false,
                pendingCommissionAmount: 0
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            getFullDataLevelTwo() {
                return UserApi.getFullDataLevelTwo().then(({data: {object}}) => object);
            },
            getAccountWithBalance() {
                return CardsApi.getAccountsWithBalance()
                    .then(({data: {object}}) => object)
                    .then((accounts) => accounts.find(({_id}) => _id === this.account.account._id));
            },
            openDepositTab() {
                const routeData = this.$router.resolve({name: 'Deposit'});
                window.open(routeData.href, '_blank');
            },
            async fakeLoad() {
                this.fakeLoading = true;
                this.fakeLoadingProgress = 0;
                do {
                    this.fakeLoadingProgress += await _wait(1, 5000);
                } while (this.fakeLoadingProgress < 100 && this.fakeLoading);
            },
            async confirm() {
                await this.fakeLoad();
                if (this.fakeLoading) {
                    try {
                        this.actuallyLoading = true;
                        await CardsApi.requestPhysicalCard(this.confirmPayload());
                        await store.dispatch(`${sessionStore}/loadAccount`)
                        this.$router.push({name: 'CardShippingStatus'});
                    } catch (error) {
                        this.toastErrorDefault(error);
                        this.cancel();
                    } finally {
                        this.actuallyLoading = false;
                    }
                }
            },
            confirmPayload() {
                let shippingAddress;
                if (!this.newAddress) {
                    shippingAddress = {
                        name: `${this.userFull?.name} ${this.userFull?.lastName} ${this.userFull?.secondLastName ? this.userFull?.secondLastName : ''}`,
                        phone: `${this.userFull?.phone}`,
                        country: this.userFull?.countryNewOnboarding?.isoCode,
                        street: this.userFull?.street,
                        number: this.userFull?.noExt,
                        district: this.userFull?.district,
                        postalCode: this.userFull?.zipCode,
                        city: this.userFull?.delegation?.name,
                        state: this.userFull?.stateNewOnboarding,
                        reference: this.userFull?.noInt,
                    };
                } else {
                    shippingAddress = {
                        name: `${this.userFull?.name} ${this.userFull?.lastName} ${this.userFull?.secondLastName ? this.userFull?.secondLastName : ''}`,
                        phone: `${this.userFull?.phone}`,
                        country: this.newAddress?.country?.isoCode,
                        street: this.newAddress?.street,
                        number: this.newAddress?.noExt,
                        district: this.newAddress?.district,
                        postalCode: this.newAddress?.zipCode,
                        city: this.newAddress?.delegation?.name,
                        state: this.newAddress?.state?.isoCode,
                        reference: this.newAddress?.noInt,
                    };
                }

                return {
                    userAddress: !this.newAddress,
                    shippingAddress,
                    paymentSourceId: this.account.cards[0].card._id, // TODO sí es la única tarjeta?
                    latitud: this.location.lat,
                    longitud: this.location.lon,
                };
            },
            cancel() {
                this.fakeLoading = false;
            },
            openAddressChange() {
                this.showAddressChange = true;
            },
            cancelAddressChange() {
                this.showAddressChange = false;
            },
            async saveNewAddress(newAddress) {
                this.showAddressChange = false;
                if (!this.hasDefaultAddress) {
                    await UserApi.saveResidence({
                        state: newAddress.state._id,
                        delegation: newAddress.delegation._id,
                        street: newAddress.street,
                        noExt: newAddress.noExt,
                        noInt: newAddress.noInt,
                        district: newAddress.district,
                        zipCode: newAddress.zipCode
                    });
                }
                this.newAddress = newAddress;
            },
            fetchPendingCommissionAmount() {
                return AccountRequestAPI.hasPendingCommissionAmount(this.account?.cards[0]?.card?._id);
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                accountImage: GET_ACCOUNT_IMG,
                accountDescription: GET_ACCOUNT_DES,
                account: GET_ACCOUNT,
                user: GET_USER,
            }),
            hasDefaultAddress() {
                return !!this.userFull?.stateNewOnboarding;
            },
            userAddressDescription() {
                if (this.hasDefaultAddress) {
                    const noInt = this.userFull?.noInt ? `-${this.userFull?.noInt}` : '';
                    const city = this.userFull?.delegation?.name;
                    const state = this.userFull?.stateNewOnboarding?.name;
                    const country = this.userFull?.countryNewOnboarding?.name;
                    const line1 = `${this.userFull?.street}, ${this.userFull?.noExt}${noInt}, ${this.userFull?.district}`;
                    const line2 = `${this.$t('register.residence.zipCode')} ${this.userFull?.zipCode}, ${city}, ${state}, ${country}`;
                    return `${line1} ${line2}`;
                }
                return this.$t('cardRequest.enter-address');
            },
            newAddressDescription() {
                if (this.newAddress) {
                    const noInt = this.newAddress?.noInt ? `-${this.newAddress?.noInt}` : '';
                    const city = this.newAddress?.delegation?.name;
                    const state = this.newAddress?.state?.name;
                    const country = this.newAddress?.country?.name;
                    const line1 = `${this.newAddress?.street}, ${this.newAddress?.noExt}${noInt}, ${this.newAddress?.district}`;
                    const line2 = `${this.$t('register.residence.zipCode')} ${this.newAddress?.zipCode}, ${city}, ${state}, ${country}`;
                    return `${line1} ${line2}`;
                }
                return this.$t('cardRequest.enter-address');
            },
            cardRequestCommission() {
                return this.account?.account?.accountType?.commissions?.find(({events}) => events === 'CARD_REQUEST');
            },
            virtualCardWrapper() {
                return this.account?.cards?.find(({card: {manufacturingType}}) => manufacturingType === 'VIRTUAL');
            },
            canConfirm() {
                return !this.loading
                    && !this.fakeLoading
                    && !this.actuallyLoading
                    && !this.location.error
                    && !this.showAddressChange
                    && !!this.account
                    && !this.virtualCardWrapper?.requestCard
                    && (this.hasDefaultAddress || !!this.newAddress)
                    && (!this.cardRequestCommission
                    || this.cardRequestCommission.cost === 0
                    || this.cardRequestCommission.cost <= this.accountWithBalance?.balance)
                    && !this.pendingCommissionAmount > 0;
            },
            showDepositButton() {
                return this.cardRequestCommission?.cost < this.accountWithBalance?.balance || this.pendingCommissionAmount > 0;
            },
            envelopeImage() {
                switch (this.account?.template?.product) {
                case 'EXPLORE': return EnvelopeExplore;
                case 'EXPLORE_PLUS': return EnvelopeExplorePlus;
                case 'NIGHT': return EnvelopeNight;
                case 'BUSINESS':
                case 'BUSINESS_PRO': return EnvelopeBusiness;
                default: return EnvelopeExplore;
                }
            },
            changeAddressButtonText() {
                return this.hasDefaultAddress ? this.$t('cardRequest.address-change') : this.$t('cardRequest.enter-address-short');
            }
        },
        async mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
            this.loading = true;
            this.userFull = await this.getFullDataLevelTwo();
            this.accountWithBalance = await this.getAccountWithBalance();
            this.pendingCommissionAmount = (await this.fetchPendingCommissionAmount()).pendingAmount;
            this.loading = false;
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
