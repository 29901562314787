<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/wallet/transfer-list"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    Regresar a Transferencias
                            </router-link>
                            <FloatingTitleLayout titleStrong="Nueva transferencia"></FloatingTitleLayout>
                        </div>
                        <!-- ESTATUS SUCCESS -->
                        <div class="col-12">
                            <CardSimple
                                hideTitle>
                                <StatusNote
                                    status="successs"
                                    title="Transferencia exitosa"
                                    text="17 de noviembre de 2021, 09:24 a.m."
                                    :imgWidth="284"
                                    :imgHeight="280"
                                    imgAlt="Transferencia exitosa">
                                </StatusNote>
                                <TransferInfo
                                    transmitterName="Andrea Mendoza"
                                    transmitterAccount="Pagando Explore"
                                    transmitterNumber="0183645377"
                                    beneficiaryName="Javier Romero Renteria"
                                    beneficiaryAccount="Scotiabank"
                                    beneficiaryNumber="1668 0927 5859 0123">
                                    <template v-slot:cuentaOrigen>
                                        <UserAccount
                                            clazz="xs m-0-auto"
                                            :userImg="imgUserAccount"
                                            :widthImg="42"
                                            :heightImg="42"
                                            altImgUser="Usuario de prueba">
                                        </UserAccount>
                                    </template>
                                    <template v-slot:cuentaBeneficiario>
                                        <UserAccount
                                            clazz="colorError"
                                            :noAccount="true"
                                            userName="JR">
                                        </UserAccount>
                                    </template>
                                </TransferInfo>
                                <div class="row align-items-c m-t-30">
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            title="Monto"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
                                            text="$250.00"
                                            textClazz="f-26 c-text_title poppins-font-bold f-700 m-b-5 text-center">
                                            <p class="f-12 c-text_content poppins-font-medium f-500 m-b-0 text-center">
                                                Esta transferencia genera
                                                <strong class="c-text_title poppins-font-bold f-700">$8.00 de comisión</strong>
                                            </p>
                                        </ReadOnlyInfo>
                                        <ReadOnlyInfo
                                            title="Concepto"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
                                            text="Enviado desde Pagando"
                                            textClazz="f-16 c-text_title poppins-font-bold f-700 m-b-5 text-center">
                                        </ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            title="Folio"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
                                            text="4368051497"
                                            textClazz="f-16 c-text_title poppins-font-bold f-700 m-b-5 text-center">
                                        </ReadOnlyInfo>
                                        <ReadOnlyInfo
                                            title="Clave de rastreo"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
                                            text="MGA0021150163009587764001204"
                                            textClazz="f-16 c-text_title poppins-font-bold f-700 m-b-5 text-center">
                                        </ReadOnlyInfo>
                                        <ReadOnlyInfo
                                            title="Verifica el estatus de tu operación en:"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center">
                                            <a href="https://www.banxico.org.mx/cep/" class="f-15 c-info poppins-font-semibold f-600 text-center" tabindex="">
                                                <u>https://www.banxico.org.mx/cep/</u>
                                            </a>
                                        </ReadOnlyInfo>
                                    </div>
                                </div>
                            </CardSimple>

                            <div class="control-buttons buttons-full-w-xs">
                                <button
                                    type="button"
                                    class="button button-stroke button-primary">
                                    <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
                                    Descargar comprobante
                                </button>
                                <button
                                    type="button"
                                    class="button button-filled button-primary">
                                    Finalizar
                                </button>
                            </div>
                        </div>
                        <!-- ESTATUS ERROR -->
                        <div class="col-12">
                            <CardSimple
                                hideTitle>
                                <StatusNote
                                    status="error"
                                    title="Transferencia fallida"
                                    text="Hubo un problema al momento de realizar tu transferencia.
                                    Verifica que todos los datos sean correctos e intenta de nuevo."
                                    :imgWidth="124"
                                    :imgHeight="124"
                                    imgAlt="Transferencia fallida"
                                    :imgUrl="imgErrorStatus">
                                    </StatusNote>
                            </CardSimple>

                            <div class="control-buttons buttons-full-w-xs">
                                <button
                                    type="button"
                                    class="button button-stroke button-primary">
                                    <i aria-hidden="true" class="iconsax-emails-messages-2 m-r-10"></i>
                                    Contactar a soporte
                                </button>
                                <button
                                    type="button"
                                    class="button button-filled button-primary">
                                    Reintentar
                                </button>
                            </div>
                        </div>
                        <!-- EN ESPERA FIRMA -->
                        <div class="col-12">
                            <CardSimple
                                hideTitle>
                                <StatusNote
                                    status=""
                                    title="Transferencia en espera de firma"
                                    text="Hemos notificado a la persona encargada
                                    de autorizar esta transferencia. La transferencia
                                    será realizada una vez que esa persona introduzca su firma."
                                    :imgWidth="440"
                                    :imgHeight="280"
                                    :imgUrl="imgStatusFirma"
                                    imgAlt="Transferencia en espera de firma">
                                </StatusNote>
                                <TransferInfo
                                    transmitterName="Andrea Mendoza"
                                    transmitterAccount="Pagando Business"
                                    transmitterNumber="0183645377"
                                    beneficiaryName="Valeria Paola Marquez Sandoval"
                                    beneficiaryAccount="Pagando"
                                    beneficiaryNumber="(639) 185 4580">
                                    <template v-slot:cuentaOrigen>
                                        <UserAccount
                                            clazz="xs m-0-auto"
                                            :userImg="imgUserAccount"
                                            :widthImg="42"
                                            :heightImg="42"
                                            altImgUser="Usuario de prueba">
                                        </UserAccount>
                                    </template>
                                    <template v-slot:cuentaBeneficiario>
                                       <UserAccount
                                            clazz="xs m-0-auto"
                                            :userImg="imgUserAccount"
                                            :widthImg="42"
                                            :heightImg="42"
                                            altImgUser="Usuario de prueba">
                                        </UserAccount>
                                    </template>
                                </TransferInfo>
                                <div class="row align-items-c m-t-30">
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            title="Monto"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
                                            text="$250.00"
                                            textClazz="f-26 c-text_title poppins-font-bold f-700 m-b-5 text-center">
                                            <p class="f-12 c-text_content poppins-font-medium f-500 m-b-0 text-center">
                                                Esta transferencia genera
                                                <strong class="c-text_title poppins-font-bold f-700">$8.00 de comisión</strong>
                                            </p>
                                        </ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            title="Concepto"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
                                            text="Enviado desde Pagando"
                                            textClazz="f-16 c-text_title poppins-font-bold f-700 m-b-5 text-center">
                                        </ReadOnlyInfo>
                                    </div>
                                </div>
                            </CardSimple>

                            <div class="control-buttons">
                                <button
                                    type="button"
                                    class="button button-filled button-primary m-0-0-0-auto">
                                    Finalizar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>
    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import TransferInfo from "@/components/TransferInfo.vue";
    import StatusNote from "@/components/StatusNote.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import imgErrorStatus from '@/assets/images/Illustrations/illustration-status-error.svg';
    import UserAccount from "@/components/UserAccount.vue";
    import imgUserAccount from '@/assets/images/Default/profile-photo.svg';
    import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import imgStatusFirma from '@/assets/images/Illustrations/illustration-firma.svg';

    export default {
        name: "NewTransferDetails",
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            TransferInfo,
            StatusNote,
            CardSimple,
            UserAccount,
            ReadOnlyInfo,
            FooterPage
        },
        data() {
            return {
                // Images
                imgErrorStatus: imgErrorStatus,
                imgUserAccount: imgUserAccount,
                imgStatusFirma: imgStatusFirma,
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
        },
        mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        },
    };
</script>
