import ShareholdersApi from "@/api/core/shareholders.api";
import GroupFileDocumentsApi from "@/api/core/groupFileDocument.api";
import FileDocumentsApi from "@/api/core/fileDocument.api";
import {
    SAVE_SHAREHOLDER,
    GET_SHAREHOLDER,
    SET_SHAREHOLDER,
    LOAD_SHAREHOLDER,
    LOAD_PHYSICAL_DOCS, GET_PHYSICAL_DOCS, SET_PHYSICAL_DOCS,
    LOAD_MORAL_DOCS, GET_MORAL_DOCS, SET_MORAL_DOCS
} from "@/store/modules/shareholders/shareholders.types";
import {catchError} from "@/utils/utils";

const state = {
    shareholder: null,
    physicalDocs: [],
    moralDocs: [],
};

const getters = {
    [GET_SHAREHOLDER]: (state) => state.shareholder,
    [GET_PHYSICAL_DOCS]: (state) => state.physicalDocs,
    [GET_MORAL_DOCS]: (state) => state.moralDocs,
};

const actions = {
    [SAVE_SHAREHOLDER]: async ({commit}, params) => {
        const response = await ShareholdersApi.save(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_SHAREHOLDER, {});
            return true;
        }
        return false;
    },
    [LOAD_SHAREHOLDER]: async ({commit}, params) => {
        commit(SET_SHAREHOLDER, null);
        const response = await ShareholdersApi.find(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_SHAREHOLDER, response.data.object);
            return true;
        }
        return false;
    },
    [LOAD_PHYSICAL_DOCS]: async ({commit, getters}, params) => {
        if (getters[GET_PHYSICAL_DOCS].length) {
            return true;
        }
        const groupResponse = await GroupFileDocumentsApi.getGroupForShareholders({
            appliesToMoral: true,
            appliesToPhysicShareholders: true,
            key: params.showPassportDocs === true ? 'ONB-ACCIONISTAS-PASSPORT' :
                params.showExtranjeroDocs === true ? 'OBN-ACCIONISTA-EXTRANJERO' : null
        }).catch(catchError);
        if (!groupResponse.data || groupResponse.data.error) {
            return false;
        }
        const groupId = groupResponse.data.object._id;

        const filesResponse = await FileDocumentsApi.listByGroup(groupId, {
            personType: "FISICA"
        }).catch(catchError);
        if (!filesResponse.data || filesResponse.data.error) {
            return false;
        }
        const documents = filesResponse.data.object;

        const filesResponseBoth = await FileDocumentsApi.listByGroup(groupId, {
            personType: "BOTH"
        }).catch(catchError);
        if (!filesResponseBoth.data || filesResponseBoth.data.error) {
            return false;
        }
        const documentsBoth = filesResponseBoth.data.object;

        commit(SET_PHYSICAL_DOCS, [...documents, ...documentsBoth]);
        return true;
    },

    [LOAD_MORAL_DOCS]: async ({commit, getters}) => {
        if (getters[GET_MORAL_DOCS].length) {
            return true
        }
        const groupResponse = await GroupFileDocumentsApi.getGroupForShareholders({
            appliesToMoral: true,
            appliesToPhysicShareholders: true
        }).catch(catchError);
        if (!groupResponse.data || groupResponse.data.error) {
            return false;
        }
        const groupId = groupResponse.data.object._id;

        const filesResponse = await FileDocumentsApi.listByGroup(groupId, {
            personType: "MORAL"
        }).catch(catchError);
        if (!filesResponse.data || filesResponse.data.error) {
            return false;
        }

        const documents = filesResponse.data.object;

        const filesResponseBoth = await FileDocumentsApi.listByGroup(groupId, {
            personType: "BOTH"
        }).catch(catchError);
        if (!filesResponseBoth.data || filesResponseBoth.data.error) {
            return false;
        }
        const documentsBoth = filesResponseBoth.data.object;

        commit(SET_MORAL_DOCS, [...documents, ...documentsBoth]);
        return true;
    },
};

const mutations = {
    [SET_SHAREHOLDER]: (state, data) => {
        state.shareholder = data
    },
    [SET_PHYSICAL_DOCS]: (state, data) => {
        state.physicalDocs = data
    },
    [SET_MORAL_DOCS]: (state, data) => {
        state.moralDocs = data
    },
};

export default {
    namespaced: true,
    state: {
        ...state
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    }
};
