<template>
  <CardSimple
      :title="$t('myAccount.limits.title')"
      :subtitle="$t('myAccount.limits.subtitle')">
    <div class="row" v-if="loading">
      <LoadingBar clazz="p-t-50 p-b-50" :loadingText="$t('general.searching')"/>
    </div>
    <div class="row" v-else>
      <div class="col-12" v-if="amountTransactions">
        <ReadOnlyInfo
            :title="$t('myAccount.limits.amountTransactions')"
            :text="amountTransactions.limit | currency"
        ></ReadOnlyInfo>
      </div>
      <div class="col-12 col-lg-6" v-for="limit in limits" :key="limit._id">
        <ReadOnlyInfo
            v-if="limit.type === 'AMOUNT_TRANSACTIONS'"
            :title="$t('myAccount.limits.amountTransactions')"
            :text="limit.limit | currency"
        ></ReadOnlyInfo>
        <OperationalLimitsBar
            :titleBar="$t(`myAccount.limits.enum.${limit.type}`, getTime(limit))"
            :valueProgressBar="limit.current"
            :maxProgressBar="limit.limit"
            :warningProgressBar="getBarLimits(limit).warning"
            :dangerProgressBar="getBarLimits(limit).danger"
            :textBar="$t('myAccount.limits.accumulated')"
            :textBarStrong="getCurrentAsString(limit)"
            :textBarRight="getTextBarRightAsString(limit)">
        </OperationalLimitsBar>
      </div>


      <div class="col-12">
        <b-alert class="alert-note" show variant="info m-b-0">
          <p class="text-container">
            <i
                aria-hidden="true"
                class="icon iconsax-emails-message-bold"
            ></i>
            {{$t('myAccount.limits.warning')}}
          </p>
        </b-alert>
      </div>
    </div>
  </CardSimple>
</template>

<script>
import CardSimple from "@/components/CardSimple";
import LoadingBar from "@/components/LoadingBar.vue";
import ReadOnlyInfo from "@/components/ReadOnlyInfo";
import OperationalLimitsBar from "@/components/OperationalLimitsBar";

import CorporativeAccountsApi from "@/api/wallet/corporativeAccounts.api";

export default {
  name: "CorporateActivityOperationalLimits",
  components: {
    CardSimple,
    LoadingBar,
    ReadOnlyInfo,
    OperationalLimitsBar,
  },
  props: {
    info: Object,
  },
  data() {
    return {
      amountTransactions: null,
      loading: false,
      limits: [],
    }
  },
  mounted() {
    this.loadLimits();
  },
  methods: {
    async loadLimits() {
      try {
        this.loading = true;
        const response = await CorporativeAccountsApi.getOperationalLimits(this.info.general.accountId);
        const limits = response.data.object;
        this.limits = limits.filter(({type}) => type !== 'AMOUNT_TRANSACTIONS').filter(limit => {
          if(['QTY_TRANSACTIONS', 'QTY_CASH_WITHDRAWALS'].includes(limit.type)) {
            // Los limites tipo Frecuencia (QTY) solo deben tener Periodicidad de tiempo o extrañamente único
            return limit.periodicity && limit.periodicity !== 'BY_EVENT';
          }
          return true;
        });

        const amountTransactions = limits.find(({type}) => type === 'AMOUNT_TRANSACTIONS');
        if(amountTransactions) {
          this.amountTransactions = amountTransactions;
        }
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.loading = false;
    },
    getTime(limit) {
      let time;
      if(limit.periodicity === 'DAILY') {
        if(['QTY_TRANSACTIONS', 'QTY_CASH_WITHDRAWALS'].includes(limit.type)) {
          time = this.$t('myAccount.limits.DAILY_F');
        } else {
          time = this.$t('myAccount.limits.DAILY_M');
        }
      } else {
        time = this.$t(`myAccount.limits.${limit.periodicity}`);
      }
      return {time};
    },
    getCurrentAsString(limit) {
      if(limit.eventAmount === 'EVENT') {
        return limit.current.toString();
      }
      return this.$options.filters.currency(limit.current);
    },
    getTextBarRightAsString({limit, type}) {
      if(['QTY_TRANSACTIONS', 'QTY_CASH_WITHDRAWALS'].includes(type)) {
        return this.$t('myAccount.limits.transactions', {count: limit});
      }
      if(['LIMIT_PIN'].includes(type)) {
        return this.$t('myAccount.limits.intents', {count: limit});
      }
      return this.$options.filters.currency(limit);
    },
    /**
     * Establece los warning y dangers para la barra de progreso
     * @param limit
     * @return {{warning: number, danger: number}}
     */
    getBarLimits({limit}) {
      const baseLimit = Number(limit) || 10;
      const warning = Number((baseLimit * 0.7).toFixed(0));
      const danger = Number((baseLimit * 0.9).toFixed(0));
      return {warning, danger}
    },
  },
}
</script>

<style scoped>

</style>
