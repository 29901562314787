<template>
  <div v-show="visible">
    <TitleSteps
      :title="$t('transfers.new.beneficiary.title')"
      :subtitle="$t('transfers.new.beneficiary.subtitle')"
    >
      <template v-slot:progress>
        <radial-progress-bar
            class="stepper-circle"
            :diameter="diamStep"
            :totalSteps="3"
            :completedSteps="radialStep"
            startColor="#E40096"
            stopColor="#E40096"
            innerStrokeColor="#D8D8E1"
            :strokeWidth="strokeWStep"
            :innerStrokeWidth="innerStrokeWStep"
            strokeLinecap="square"
            timingFunc="ease-in-out">
            <p class="percentage">1 {{$t('transfers.new.of')}} 3</p>
        </radial-progress-bar>
      </template>
      <b-dropdown id="" right class="dropdown-contact" v-if="canRequestBeneficiaries">
        <template #button-content>
          <button
            type="button"
            class="button sm button-filled button-primary"
          >
            <i aria-hidden="true" class="iconsax-users-user-add m-r-10"></i>
            {{$t('transfers.new.beneficiary.add.button')}}
          </button>
        </template>
        <b-dropdown-item @click="$bvModal.show('modal-contacto-pagando')">
          <SidebarRightLink
            clazz="colorInfo"
            icon="iconsax-users-profile-circle"
            :titleText="$t('transfers.new.beneficiary.add.pagandoTitle')"
            :text="$t('transfers.new.beneficiary.add.pagandoText')"
          >
            <router-link to="" tabindex="" :aria-label="$t('transfers.new.beneficiary.add.pagandoTitle')">
              <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
            </router-link>
          </SidebarRightLink>
        </b-dropdown-item>
        <b-dropdown-item @click="$bvModal.show('modal-contacto-otro')">
          <SidebarRightLink
            clazz="textInfo"
            icon="iconsax-building-bank"
            :titleText="$t('transfers.new.beneficiary.add.externalTitle')"
            :text="$t('transfers.new.beneficiary.add.externalText')"
          >
            <router-link to="" tabindex="" :aria-label="$t('transfers.new.beneficiary.add.externalTitle')">
              <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
            </router-link>
          </SidebarRightLink>
        </b-dropdown-item>
      </b-dropdown>
    </TitleSteps>
    <TitleBadge
      v-if="recentContacts.length > 0"
      clazzContainer="p-0"
      :titleText="$t('transfers.new.beneficiary.recentContacts')"
    ></TitleBadge>
    <div class="recent-contacts-panels" v-if="recentContacts.length > 0">
      <div
        v-for="contact in recentContacts"
        :key="contact._id" @click="onSelection(contact)"
        class="c-pointer"
      >
        <RecentContacts
          :clazz="`vertical-card ${isSelected(contact) ? 'selected' : ''}`"
          :textPrincipal="contact.alias"
          :text="getBankAndNumber(contact, true)"
        >
          <template v-slot:user>
            <UserAccount
              :clazz="`xs ${getContactColorClass(contact)}`"
              :noAccount="!contact.isPagandoAccount"
              :userImg="contact.image ? imageDownloadLink(contact.image) : imgUserAccount"
              :userName="getContactInitials(contact)"
              :widthImg="42"
              :heightImg="42"
              :altImgUser="contact.phone || contact.clabe"
            />
          </template>
          <template v-slot:action>
            <RadioInput
              clazz="w-fit m-0-auto"
              idInput="one"
              name="recents_radio"
              :ref="`radio_recent_${contact._id}`"
              isHiddenText
            />
          </template>
        </RecentContacts>
      </div>
    </div>

    <TitleBadge clazzContainer="p-0" :titleText="$t('transfers.new.beneficiary.contactsTitle')" :badgeText="totalContacts">
      <div class="btn-filter-container p-0 m-b-0">
        <button
          id=""
          class="btn-filter"
          :class="{ active: listType === 'BOTH' }"
          type="button"
          @click="changeList('BOTH')"
        >
          {{$t('transfers.new.beneficiary.filter.both')}}
        </button>
        <button
          id=""
          class="btn-filter"
          :class="{ active: listType === 'PAGANDO_ACCOUNT' }"
          type="button"
          @click="changeList('PAGANDO_ACCOUNT')"
        >
          {{$t('transfers.new.beneficiary.filter.pagando')}}
        </button>
        <button
          id=""
          class="btn-filter"
          :class="{ active: listType === 'OTHERS' }"
          type="button"
          @click="changeList('OTHERS')"
        >
          {{$t('transfers.new.beneficiary.filter.others')}}
        </button>
      </div>
    </TitleBadge>

    <BasicInput
      clazz="m-b-15 noLabel panel-search"
      inputType="text"
      :inputPlaceholder="$t('transfers.new.beneficiary.searchPlaceholder')"
      inputClazz="icon-left"
      iconInput="banana-search-normal"
      @basicInput="onSearch"
    ></BasicInput>

    <CardSimple clazz="p-0" hideTitle>
      <div id="" class="basic-table table-contactos">
        <b-table-simple
          responsive
          selectable
          selected-variant="active"
          table-variant="xl"
          ref="contactsTable"
          @scroll.native="onScroll"
        >
          <b-thead>
            <b-tr class="">
              <b-th> {{$t('transfers.new.beneficiary.alias')}} </b-th>
              <b-th class="text-center"> {{$t('transfers.new.beneficiary.bank')}} </b-th>
              <b-th class="text-center"> {{$t('transfers.new.beneficiary.account')}} </b-th>
              <b-th class="text-center"> {{$t('transfers.new.beneficiary.select')}} </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr class="noHover" v-if="loading">
              <b-td colspan="4">
                <LoadingBar
                  clazz="p-t-50 p-b-50"
                  :loadingText="$t('transfers.new.beneficiary.loading')"
                ></LoadingBar>
              </b-td>
            </b-tr>
            <b-tr
              class="noHover"
              v-else-if="contactList.length === 0 && search.length === 0"
            >
              <b-td colspan="4">
                <EmptyState
                  :clazz="`p-0 ${canRequestBeneficiaries ? '' : 'm-b-30'}`"
                  emptyStateDefault
                  :title="$t('transfers.new.beneficiary.emptyTitle')"
                  :text="$t('transfers.new.beneficiary.emptyText')"
                  :imgUrl="imgEmptystateContacts"
                  :imgWidth="390"
                  :imgHeight="320"
                  :imgAlt="$t('transfers.new.beneficiary.emptyTitle')"
                >
                  <b-dropdown id="" dropup class="dropdown-contact m-t-30 m-0-auto w-fit d-none d-md-flex" v-if="canRequestBeneficiaries">
                    <template #button-content>
                      <button id="" type="button" class="button sm button-filled button-primary">
                        <i aria-hidden="true" class="iconsax-users-user-add m-r-10"></i>
                        {{$t('transfers.new.beneficiary.add.button')}}
                      </button>
                    </template>
                    <b-dropdown-item @click="$bvModal.show('modal-contacto-pagando')">
                      <SidebarRightLink
                        clazz="colorInfo"
                        icon="iconsax-users-profile-circle"
                        :titleText="$t('transfers.new.beneficiary.add.pagandoTitle')"
                        :text="$t('transfers.new.beneficiary.add.pagandoText')"
                      >
                        <router-link to="" tabindex="" :aria-label="$t('transfers.new.beneficiary.add.pagandoTitle')">
                          <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </router-link>
                      </SidebarRightLink>
                    </b-dropdown-item>
                    <b-dropdown-item @click="$bvModal.show('modal-contacto-otro')">
                      <SidebarRightLink
                        clazz="textInfo"
                        icon="iconsax-building-bank"
                        :titleText="$t('transfers.new.beneficiary.add.externalTitle')"
                        :text="$t('transfers.new.beneficiary.add.externalText')"
                      >
                        <router-link to="" tabindex="" :aria-label="$t('transfers.new.beneficiary.add.externalTitle')">
                          <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </router-link>
                      </SidebarRightLink>
                    </b-dropdown-item>
                  </b-dropdown>
                </EmptyState>
              </b-td>
            </b-tr>
            <b-tr
              class="noHover"
              v-else-if="contactList.length === 0 && search.length > 0"
            >
              <b-td colspan="4">
                <EmptyState
                  :clazz="`p-0 ${canRequestBeneficiaries ? '' : 'm-b-30'}`"
                  emptyStateDefault
                  :title="$t('transfers.new.beneficiary.notFoundTitle')"
                  :text="$t('transfers.new.beneficiary.notFoundText')"
                  :imgUrl="imgEmptystateContacts"
                  :imgWidth="390"
                  :imgHeight="320"
                  :imgAlt="$t('transfers.new.beneficiary.notFoundTitle')"
                >
                  <b-dropdown id="" dropup class="dropdown-contact m-0-auto w-fit d-none d-md-flex" v-if="canRequestBeneficiaries">
                    <template #button-content>
                      <button id="" type="button" class="button sm button-filled button-primary">
                        <i aria-hidden="true" class="iconsax-users-user-add m-r-10"></i>
                        {{$t('transfers.new.beneficiary.add.button')}}
                      </button>
                    </template>
                    <b-dropdown-item @click="$bvModal.show('modal-contacto-pagando')">
                      <SidebarRightLink
                        clazz="colorInfo"
                        icon="iconsax-users-profile-circle"
                        :titleText="$t('transfers.new.beneficiary.add.pagandoTitle')"
                        :text="$t('transfers.new.beneficiary.add.pagandoText')"
                      >
                        <router-link to="" tabindex="" :aria-label="$t('transfers.new.beneficiary.add.pagandoTitle')">
                          <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </router-link>
                      </SidebarRightLink>
                    </b-dropdown-item>
                    <b-dropdown-item @click="$bvModal.show('modal-contacto-otro')">
                      <SidebarRightLink
                        clazz="textInfo"
                        icon="iconsax-building-bank"
                        :titleText="$t('transfers.new.beneficiary.add.externalTitle')"
                        :text="$t('transfers.new.beneficiary.add.externalText')"
                      >
                        <router-link to="" tabindex="" :aria-label="$t('transfers.new.beneficiary.add.externalTitle')">
                          <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </router-link>
                      </SidebarRightLink>
                    </b-dropdown-item>
                  </b-dropdown>
                </EmptyState>
              </b-td>
            </b-tr>
            <b-tr
              v-else
              :class="{'b-table-row-selected': isSelected(contact)}"
              v-for="contact in contactList"
              v-bind:key="contact._id"
              @click="onSelection(contact)"
            >
              <b-td>
                <div class="vertical-center flex-wrap-no">
                  <UserAccount
                    :clazz="`xs ${getContactColorClass(contact)}`"
                    :noAccount="!contact.isPagandoAccount"
                    :userImg="contact.image ? imageDownloadLink(contact.image) : imgUserAccount"
                    :userName="getContactInitials(contact)"
                  />
                  <strong class="c-text_title poppins-font-bold m-l-15">{{
                    contact.alias
                  }}</strong>
                </div>
              </b-td>
              <!-- Banco -->
              <b-td class="text-center" v-if="contact.isPagandoAccount">
                {{$t('transfers.new.beneficiary.bankPagando')}}
              </b-td>
              <b-td class="text-center" v-else>
                {{contact.bank.shortName}}
              </b-td>

              <!-- Cuenta -->
              <b-td class="text-center" v-if="contact.isPagandoAccount">
                <strong>{{contact.numberAccount}}</strong><br/>{{contact.phone}}
              </b-td>
              <b-td class="text-center" v-else>
                {{contact.clabe}}
              </b-td>

              <b-td class="text-center">
                <RadioInput
                  clazz="m-0-auto w-fit"
                  idInput="user_EM"
                  name="table_radio"
                  :ref="`radio_${contact._id}`"
                  isHiddenText
                  @selected="onSelection(contact)"
                ></RadioInput>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </CardSimple>

    <button
      v-if="selection"
      type="button"
      class="button button-stroke button-primary w-100 d-flex d-md-none"
    >
      {{$t('transfers.new.beneficiary.editButton')}}
    </button>
    <div class="control-buttons">
      <button type="button" class="button-icon" @click="$emit('stepBack')">
        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
        {{$t('transfers.new.beneficiary.stepBack')}}
      </button>
      <div class="vertical-center">
        <button
          v-if="selection"
          type="button"
          class="button button-stroke button-primary d-none d-md-flex"
          @click="editContact"
        >
          {{$t('transfers.new.beneficiary.editButton')}}
        </button>
        <button
          type="button"
          class="button button-filled button-primary"
          :disabled="!selection"
          @click="onContinue()"
        >
          {{$t('transfers.new.beneficiary.continue')}}
        </button>
      </div>
    </div>

    <ModalNewContactPagando v-if="canRequestBeneficiaries" forTransfer @continue="onContinue" />
    <ModalNewContactExternal v-if="canRequestBeneficiaries" forTransfer @continue="onContinue" />
    <ModalEditContactPagando
      ref="modalEditContactPagando"
      v-if="canRequestBeneficiaries"
      @onSave="reloadList"
      @delete="$bvModal.show('modal-delete-contact')" />
    <ModalEditContactExternal
      ref="modalEditContactExternal"
      v-if="canRequestBeneficiaries"
      @onSave="reloadList"
      @delete="$bvModal.show('modal-delete-contact')" />

    <ModalAlert
      closeCross
      ref="modal-delete-contact"
      modalId="modal-delete-contact"
      v-if="canRequestBeneficiaries"
      buttonType="button-primary"
      :imgUrl="imageModalTrash"
      :title="$t('transfers.new.beneficiary.delete.title')"
      :text="$t('transfers.new.beneficiary.delete.text')"
      :buttonText="$t('transfers.new.beneficiary.delete.action')"
      withAutomaticLoad
      @submit="deleteContact"
    ></ModalAlert>
  </div>
</template>

<script>
import imgEmptystateContacts from "@/assets/images/Emptystates/emptystate-contactos-recientes.svg";
import imgUserAccount from "@/assets/images/Default/profile-photo.svg";
import TitleBadge from "@/components/TitleBadge.vue";
import RecentContacts from "@/components/RecentContacts.vue";
import BasicInput from "@/components/BasicInput.vue";
import CardSimple from "@/components/CardSimple.vue";
import EmptyState from "@/components/EmptyState.vue";
import SidebarRightLink from "@/components/SidebarRightLink.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import UserAccount from "@/components/UserAccount.vue";
import RadioInput from "@/components/RadioInput.vue";
import TitleSteps from "@/components/TitleSteps.vue";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import ModalNewContactPagando from "@/pages/Transfers/ModalNewContactPagando";
import ModalNewContactExternal from "@/pages/Transfers/ModalNewContactExternal";
import ModalEditContactPagando from "@/pages/Transfers/ModalEditContactPagando";
import ModalEditContactExternal from "@/pages/Transfers/ModalEditContactExternal";
import ContactsApi from "@/api/wallet/contacts.api";
import ModalAlert from "@/components/ModalAlert.vue";
import imageModalTrash from '@/assets/images/Icons/icon-trash-pink.svg';
import {imageDownloadLink, debounce} from '@/utils/utils';
import RadialProgressBar from "vue-radial-progress";
import { getContactColorClass, getContactInitials } from "@/utils/contactUtils";
import {EventBus} from "@/main";
import {mapGetters} from "vuex";
import {
  STORE as sessionStore,
  GET_ACCOUNT, SET_ACCOUNT,
  CHECK_PERMISSION,
} from "@/store/modules/session/session.types";
import permissionsMixin from "@/mixins/PermissionsMixin";

export default {
  name: "NewTransferBeneficiary",
  mixins: [ToastSnotify, permissionsMixin],
  components: {
    TitleBadge,
    BasicInput,
    CardSimple,
    EmptyState,
    SidebarRightLink,
    RecentContacts,
    UserAccount,
    RadioInput,
    LoadingBar,
    TitleSteps,
    ModalNewContactPagando,
    ModalNewContactExternal,
    ModalEditContactPagando,
    ModalEditContactExternal,
    ModalAlert,
    RadialProgressBar,
  },
  props: {
    visible: {type: Boolean, default: false},
  },
  data() {
    return {
      // Images
      imgEmptystateContacts: imgEmptystateContacts,
      imgUserAccount,
      imageModalTrash,
      // ProgressCircle
      diamStep: 64,
      currentStep: 0,
      strokeWStep: 5,
      innerStrokeWStep: 5,

      loading: true,
      listType: "BOTH",
      contactList: [],
      recentContacts: [],
      search: "",
      selection: null,
      perPage: 10,
      currentPage: 0,
      noMoreItems: false,
      totalContacts: '0',
      radialStep: 0,
      reloadOnStepback: false,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
      account: GET_ACCOUNT
    }),
    canRequestBeneficiaries() {
      const checkPermission = this.$store.getters[`${sessionStore}/${CHECK_PERMISSION}`];
      return checkPermission('REQUEST', 'BENEFICIARIES') && checkPermission('AUTHORIZE', 'BENEFICIARIES');
    },
    accountId() {
      if(this.account && this.account.account) {
        return this.account.account._id;
      }
      return null;
    }
  },
  mounted() {
    if(this.visible) {
      this.loadContacts();
    } else {
      this.reloadOnStepback = true;
    }
    if (this.account.account._id) {
      this.loadRecentContacts();
    }

    EventBus.$on(`${sessionStore}.${SET_ACCOUNT}`, () => {
      this.reloadList();
    });
  },
  methods: {
    getContactColorClass,
    getContactInitials,
    imageDownloadLink,
    changeList(type) {
      if(this.listType !== type) {
        this.listType = type;
        this.reloadList();
      }
    },
    reloadList() {
      this.contactList = [];
      this.currentPage = 0;
      this.noMoreItems = false;
      this.loading = true;
      this.totalContacts = '0';
      this.onSelection(null);
      this.reloadOnStepback = false;
      this.loadContacts();
      this.loadRecentContacts();
    },
    async loadContacts() {
      try {
        if(this.noMoreItems || !this.accountId) {
          return;
        }
        this.currentPage++;
        const response = await ContactsApi.listAll({
          accountId: this.accountId,
          type: this.listType,
          page: this.currentPage,
          limit: this.perPage,
          search: this.search,
          format: true,
        });
        const data = response.data.object;
        this.loading = false;
        this.contactList = [...this.contactList, ...data.docs];
        this.totalContacts = data.totalDocs.toString();
        if(!data.hasNextPage) {
          this.noMoreItems = true;
        }
        this.$nextTick(this.checkTableHeight);
      } catch (error) {
        this.toastErrorDefault(error);
        this.loading = false;
      }
    },
    checkTableHeight() {
      if(this.$refs.contactsTable) {
        const tableHeight = this.$refs.contactsTable.$el.clientHeight;
        const minHeightForScrollbar = 650;
        if(tableHeight < minHeightForScrollbar && this.noMoreItems === false) {
          this.loading = true;
          this.loadContacts();
        }
      }
    },
    async loadRecentContacts() {
      try {
        const response = await ContactsApi.listRecent({accountId: this.account.account._id});
        this.recentContacts = response.data.object;
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    updateRadioInput(contact, value) {
      if(contact) {
        const tableRef = this.$refs[`radio_${contact._id}`];
        const recentsRef = this.$refs[`radio_recent_${contact._id}`];
        if(tableRef && tableRef[0]) {
          if(value) {
            tableRef[0].reloadInternalValue(true);
          } else {
            tableRef[0].deselect();
          }
        }
        if(recentsRef && recentsRef[0]) {
          if(value) {
            recentsRef[0].reloadInternalValue(true);
          } else {
            recentsRef[0].deselect();
          }
        }
      }
    },
    onSelection(contact) {
      this.updateRadioInput(this.selection, false);
      this.updateRadioInput(contact, true);
      this.selection = contact;
    },
    editContact() {
      if(!this.selection) {
        return;
      }
      if(this.selection.isPagandoAccount) {
        this.$refs.modalEditContactPagando.open(this.selection);
      } else {
        this.$refs.modalEditContactExternal.open(this.selection);
      }
    },
    onScroll({target}) {
      const isBottom = target.scrollTop + target.clientHeight >= target.scrollHeight;
      if(isBottom) {
        this.loadContacts();
      }
    },
    async deleteContact() {
      try {
        await ContactsApi.delete({contactId: this.selection._id});
        this.reloadList();
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.$refs['modal-delete-contact'].hideModal();
    },
    getBankAndNumber(contact, concat) {
      let bank, number;
      if(contact.isPagandoAccount) {
        bank = 'Pagando';
        number = contact.phone;
      } else {
        bank = contact.bank.shortName;
        number = contact.clabe;
      }
      if(concat) {
        return `${bank} -<br/>${number}`;
      }
      return {bank, number};
    },
    isSelected(contact) {
      if(this.selection) {
        return this.selection._id === contact._id;
      }
      return false;
    },
    onContinue(beneficiary, reloadOnStepback) {
      if(reloadOnStepback) {
        this.reloadOnStepback = true;
      }
      if(!beneficiary) {
        const {bank, number} = this.getBankAndNumber(this.selection);
        beneficiary = {
          contactId: this.selection._id,
          alias: this.selection.alias,
          contactName: this.selection.contactName,
          isPagandoAccount: this.selection.isPagandoAccount,
          image: this.selection.image,
          bank,
          number,
        };
      }
      this.$emit('nextStep', beneficiary);
    },
    onSearch(value) {
      this.loading = true;
      this.search = value;
      debounce(() => {
        this.reloadList();
      }, {time: 500});
    },
  },
  watch: {
    visible: {
      immediate: true,
      handler(newValue, oldValue) {
        this.radialStep = oldValue === false ? 2 : 0;
        setTimeout(() => {
          this.radialStep = 1;
        }, 0);

        if(oldValue === false && this.reloadOnStepback) {
          this.reloadList();
        }
      }
    },
  },
  beforeDestroy () {
    EventBus.$off(`${sessionStore}.${SET_ACCOUNT}`);
  },
};
</script>
