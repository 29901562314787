import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'ws/api-v1';

export default {
    balance: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/balance`, params)
    },
    payment: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/ws-payment`, params)
    }
}
