import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'countries';

export default {
    getCountries: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/countries`, params)
    },
    getActiveCountries: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/active-countries`, params)
    },
    getStates: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/country/states/${params}`)
    },
}
