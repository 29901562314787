<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/movements"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('rewardPoints.returnToMovements')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('rewardPoints.title')"></FloatingTitleLayout>
                        </div>

                        <!-- PAGANDO PUNTOS -->
                        <div class="col-12" v-if="!paidSuccess && !loading">
                            <PanelPoints
                                clazzIcon="icon-warning"
                                icon="iconsax-like-medal-star"
                                :textPoints="$t('rewardPoints.availablePoints')"
                                :pointsNumber="$tc('rewardPoints.quantityPoints', availablePoints, {points: availablePoints})"
                                :textEquiv="$t('rewardPoints.equivalence')"
                                :pointsEquiv="$t('rewardPoints.equivalentPoints', {amount: currency(equivalentAmount)})">
                            </PanelPoints>
                        </div>
                        <div class="col-12 col-lg-6 cards-same-height" v-if="!paidSuccess && !loading">
                            <CardSimple
                                :title="$t('rewardPoints.breakdown')"
                                :subtitle="$t('rewardPoints.consultDetails')">
                                <label class="f-14 c-primary poppins-font-semibold f-600 text-upper m-b-15 w-100">
                                    {{$t('rewardPoints.purchaseWithPoints')}}
                                </label>
                                <RowDots
                                    :textRowTitle="$t('rewardPoints.establishment')"
                                    :textRow="establishment"></RowDots>
                                <RowDots
                                    :textRowTitle="$t('rewardPoints.totalPurchase')"
                                    :textRow="totalPurchase | currency"></RowDots>
                                <RowDots
                                    :textRowTitle="$t('rewardPoints.pointsGenerated')"
                                    :textRow="$tc('rewardPoints.generatedPoints', acquiredRewardPoints, {points: acquiredRewardPoints})"></RowDots>
                                <div class="divider m-t-0"></div>
                                <RowDots
                                    clazzRowTitle="c-text_title poppins-font-semibold f-600"
                                    clazzRowText="c-success f-20 poppins-font-bold f-700"
                                    :textRowTitle="$t('rewardPoints.amountToPaidWithPoints')"
                                    :textRow="amountPaidWithPoints | currency"></RowDots>
                                <RowDots
                                    clazzRowTitle="c-text_title poppins-font-semibold f-600"
                                    clazzRowText="c-primary f-15 poppins-font-bold f-700"
                                    :textRowTitle="$t('rewardPoints.amountToPaidWithCard')"
                                    :textRow="amountPaidWithCard | currency"></RowDots>

                                <label class="f-14 c-primary poppins-font-semibold f-600 text-upper m-t-10 m-b-15 w-100">
                                    {{$t('rewardPoints.detailPoints')}}
                                </label>
                                <RowDots
                                    clazzRowText="c-primary"
                                    :textRowTitle="$t('rewardPoints.initialPoints')"
                                    :textRow="$tc('rewardPoints.quantityPoints', availablePoints, {points: availablePoints})"></RowDots>
                                <RowDots
                                    :textRowTitle="$t('rewardPoints.pointsToDiscount')"
                                    :textRow="$tc('rewardPoints.generatedPointsNegative', acquiredRewardPoints, {points: acquiredRewardPoints})"></RowDots>
                                <RowDots
                                    :textRowTitle="$t('rewardPoints.pointsToExchange')"
                                    :textRow="$tc('rewardPoints.generatedPointsNegative', redeemablePoints, {points: redeemablePoints})"></RowDots>
                                <RowDots
                                    clazzRowText="c-success"
                                    :textRowTitle="$t('rewardPoints.finalPoints')"
                                    :textRow="$tc('rewardPoints.generatedPoints', remainingPoints, {points: remainingPoints})"></RowDots>

                                <button
                                    type="button"
                                    @click="submit"
                                    class="button button-filled button-primary w-100 m-t-15">
                                    <i aria-hidden="true" class="iconsax-like-medal-star m-r-10"></i>
                                    {{$t('rewardPoints.exchangePoints')}}
                                </button>
                            </CardSimple>
                        </div>
                        <div class="col-12 col-lg-6 cards-same-height" v-if="!paidSuccess && !loading">
                            <CardSimple
                                :title="$t('rewardPoints.regards.title')"
                                :subtitle="$t('rewardPoints.regards.subtitle')">
                                <ul class="checked-list">
                                    <li>
                                        {{$t('rewardPoints.regards.part1')}}
                                    </li>
                                    <li>
                                        {{$t('rewardPoints.regards.part2')}}
                                    </li>
                                    <li>
                                        {{$t('rewardPoints.regards.part3')}}
                                    </li>
                                    <li>
                                        {{$t('rewardPoints.regards.part4')}}
                                    </li>
                                    <li>
                                        {{$t('rewardPoints.regards.part5')}}
                                    </li>
                                </ul>
                            </CardSimple>
                        </div>

                        <!-- ESTATUS SUCCESS -->
                        <div class="col-12" v-if="paidSuccess">
                            <CardSimple
                                hideTitle>
                                <StatusNote
                                    status="successs"
                                    :title="$t('rewardPoints.success.title')"
                                    :text="date"
                                    :imgWidth="284"
                                    :imgHeight="280"
                                    :imgAlt="$t('rewardPoints.success.title')">
                                </StatusNote>
                                <div class="row m-t-30">
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('rewardPoints.success.amountPaidWithPoints')"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 text-center"
                                            :text="success.redemablePointsValue | currency"
                                            textClazz="f-26 c-text_title poppins-font-bold f-700 text-center">
                                        </ReadOnlyInfo>
                                        <ReadOnlyInfo
                                            :title="$t('rewardPoints.success.amountPaidWithCard')"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 text-center"
                                            :text="success.remaingEPC | currency"
                                            textClazz="f-16 c-text_title poppins-font-bold f-700 text-center">
                                        </ReadOnlyInfo>
                                        <ReadOnlyInfo
                                            :title="$t('rewardPoints.success.pointsRedeemed')"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 text-center"
                                            :text="$tc('rewardPoints.quantityPoints', success.redemablePoints, {points: success.redemablePoints})"
                                            textClazz="f-16 c-text_title poppins-font-bold f-700 text-center">
                                        </ReadOnlyInfo>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <ReadOnlyInfo
                                            :title="$t('rewardPoints.success.account', {account: accountType})"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 text-center"
                                            :text="accountNumber"
                                            textClazz="f-16 c-text_title poppins-font-bold f-700 text-center">
                                        </ReadOnlyInfo>
                                        <ReadOnlyInfo
                                            :title="$t('rewardPoints.success.paidPurchase')"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 text-center"
                                            :text="establishment"
                                            textClazz="f-16 c-text_title poppins-font-bold f-700 m-b-5 text-center">
                                            <p class="f-16 c-title poppins-font-semibold f-600 text-center m-b-0">{{totalPurchase | currency}}</p>
                                        </ReadOnlyInfo>
                                        <ReadOnlyInfo
                                            :title="$t('rewardPoints.success.folio')"
                                            titleClazz="f-14 c-text_info poppins-font-medium f-500 text-center"
                                            :text="folioMovement"
                                            textClazz="f-16 c-text_title poppins-font-bold f-700 text-center">
                                        </ReadOnlyInfo>
                                    </div>
                                </div>
                            </CardSimple>

                            <div class="control-buttons buttons-full-w-xs">
                                <button
                                    type="button"
                                    class="button button-stroke button-primary">
                                    <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
                                    {{$t('rewardPoints.success.downloadTicket')}}
                                </button>
                                <button
                                    @click="returnToMovements()"
                                    type="button"
                                    class="button button-filled button-primary">
                                    {{$t('rewardPoints.success.finalize')}}
                                </button>
                            </div>
                        </div>

                        <!-- ESTATUS ERROR -->
                        <div class="col-12" v-if="paidError">
                            <CardSimple hideTitle>
                                <StatusNote
                                    status="error"
                                    :title="$t('rewardPoints.error.title')"
                                    :text="$t('rewardPoints.error.subtitle')"
                                    :imgWidth="124"
                                    :imgHeight="124"
                                    :imgAlt="$t('rewardPoints.error.title')"
                                    :imgUrl="imgErrorStatus">
                                </StatusNote>
                                <!-- <p class="f-19 c-title poppins=font=semibold f-600 text-center m-b-0 w-100">
                                    Se perdió la conexión con el servidor
                                </p> -->
                            </CardSimple>

                            <div class="control-buttons buttons-full-w-xs">
                                <button
                                    type="button"
                                    class="button button-stroke button-primary">
                                    <i aria-hidden="true" class="iconsax-emails-messages-4 m-r-10"></i>
                                    {{$t('rewardPoints.error.contactToSupport')}}
                                </button>
                                <button
                                    @click="submit"
                                    type="button"
                                    class="button button-filled button-primary">
                                    {{$t('rewardPoints.error.retry')}}
                                </button>
                            </div>
                        </div>
                        <LoadingBar v-if="loading" loadingText="Cargando..."></LoadingBar>
                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>
    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import PanelPoints from "@/components/PanelPoints.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import StatusNote from "@/components/StatusNote.vue";
    import imgErrorStatus from '@/assets/images/Illustrations/illustration-status-error.svg';
    import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
    import RowDots from "@/components/RowDots.vue";
    import RewardPointsApi from '@/api/wallet/rewardPoints.api';
    import { catchError } from "@/utils/utils";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import CardApi from '@/api/wallet/card.api';
    import LoadingBar from '@/components/LoadingBar';
    import moment from 'moment';

    export default {
        name: "PagandoPoints",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            PanelPoints,
            CardSimple,
            StatusNote,
            ReadOnlyInfo,
            RowDots,
            LoadingBar
        },
        data() {
            return {
                // Images
                imgErrorStatus: imgErrorStatus,
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
                availablePoints: 0,
                equivalentAmount: 0,
                acquiredRewardPoints: 0,
                totalPurchase: 0,
                establishment: 'N/A',
                amountPaidWithPoints: 0,
                amountPaidWithCard: 0,
                redeemablePoints: 0,
                remainingPoints: 0,
                folioMovement: 'N/A',
                date: 'N/A',
                accountNumber: 'N/A',
                accountType: 'Pagando',
                paidSuccess: false,
                paidError: false,
                id: null,
                success: {
                    redemablePointsValue: 0,
                    remaingEPC: 0,
                    redemablePoints: 0
                },
                loading: false
            }
        },
        computed: {
            currency() {
                return this.$options.filters.currency;
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            async getDetails() {
                this.id = this.$route.params.id;

                if(this.id) {
                    const res = await RewardPointsApi.getDetails(this.id).catch(catchError);

                    if(res && res.data && !res.data.error) {

                        await this.getMovement(this.id);

                        this.availablePoints = res.data.object.totalPoints.toLocaleString();
                        this.equivalentAmount = res.data.object.valuePerPoint;
                        this.amountPaidWithPoints = res.data.object.redemablePointsValue;
                        this.amountPaidWithCard = res.data.object.remaingEPC;
                        this.redeemablePoints = res.data.object.redeemablePoints.toLocaleString();
                        this.remainingPoints = res.data.object.remainingPoints.toLocaleString();
                    } else {
                        this.toastErrorDefault(this.$t('rewardPoints.error.getDetail'));
                        this.returnToMovements();
                    }
                } else {
                    this.toastErrorDefault(this.$t('rewardPoints.error.getDetail'));
                    this.returnToMovements();
                }
            },
            async getMovement(id) {
                const res = await CardApi.getMovementById(id).catch(catchError);
                if(res && res.data && !res.data.error) {
                    this.acquiredRewardPoints = res.data.object.acquiredRewardPoints.toLocaleString();
                    this.totalPurchase = res.data.object.amount;
                    this.establishment = res.data.object.nameStore;
                    this.folioMovement = res.data.object.fullKey;
                    this.accountNumber = res.data.object.pagandoAccount.fullKey;
                    this.accountType = res.data.object.pagandoAccount.accountType.description;
                    this.date = moment().format('DD [de] MMMM [de] YYYY, hh:mm a');
                } else {
                    this.toastErrorDefault(this.$t('rewardPoints.error.getMovement'));
                    this.returnToMovements();
                }
            },
            async submit() {
                if(await this.$requestAuth()) {
                    const res = await RewardPointsApi.spendPoints(this.id).catch(catchError);

                    if(res && res.data && !res.data.error) {
                        this.paidSuccess = true;
                        this.paidError = false;

                        this.success.redemablePointsValue = res.data.object.redemablePointsValue;
                        this.success.remaingEPC = res.data.object.remaingEPC;
                        this.success.redemablePoints = res.data.object.redeemablePoints.toLocaleString();
                    } else {
                        this.paidSuccess = false;
                        this.paidError = true;
                    }
                } else {
                    this.paidSuccess = false;
                    this.paidError = true;
                }
            },
            returnToMovements() {
                this.$router.push({name: 'Movements'});
            }
        },
        async mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
            this.loading = true;
            await this.getDetails();
            this.loading = false;
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
