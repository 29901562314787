import {
    // GET_CARD_REQUEST,
    STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
    GET_USER,
    STORE as sessionStore
} from "@/store/modules/session/session.types";
import store from '@/store/index'

export default function accountBusiness({ next }) {

    const user = store.getters[`${sessionStore}/${GET_USER}`];

    if(user && !user.onbForBusiness) {
        const cardRequest = store.getters[`${onbngStore}/GET_CARD_REQUEST`];
        if (cardRequest && cardRequest.accountType !== 'BUSINESS') {
            return store.dispatch(`${onbngStore}/getRouteByStep`);
        }
    }
    return next();

    // return router.push({ name: 'onboardingPersonal' });

}
