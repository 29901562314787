<template>
  <div>
    <!-- Anuncio -->
    <div v-if="status === 'announcement'">
      <div class="text-center">
        <img
          class="img-fluid m-b-30"
          src="@/assets/images/Illustrations/illustration-aviso.svg"
          :alt="$t('login.pinUnification.announcement.imageAlt')"
          width="330"
          height="182"
        />
      </div>
      <TitlePrincipal :title="$t('login.pinUnification.announcement.title')">
        <div class="subtitle">
          <i18n tag="p" path="login.pinUnification.announcement.paragraphs[0]">
            <strong class="poppins-font-bold" v-text="`${username}.`"/>
          </i18n>
          <i18n tag="p" path="login.pinUnification.announcement.paragraphs[1]">
            <strong class="poppins-font-bold" v-t="'login.pinUnification.announcement.useOnePin'"/>
          </i18n>
          <i18n tag="p" path="login.pinUnification.announcement.paragraphs[2]">
            <strong class="poppins-font-bold" v-t="'login.pinUnification.announcement.instructionsATM'"/>
          </i18n>
          <i18n tag="p" path="login.pinUnification.announcement.paragraphs[3]"/>
          <i18n tag="p" path="login.pinUnification.announcement.paragraphs[4]">
            <a
              href=""
              class="text-underline c-info poppins-font-bold"
              v-t="'login.pinUnification.announcement.supportTeam'"
              @click.prevent="openZendesk"
            />
          </i18n>
          <div class="control-buttons buttons-full-w-xs justify-content-center">
            <button
              type="button"
              class="button button-stroke button-primary"
              v-t="'login.pinUnification.announcement.skipBtn'"
              @click="goToDashboard"
            />
            <button
              type="button"
              class="button button-filled button-primary"
              v-t="'login.pinUnification.announcement.confirmBtn'"
              @click="status = 'input'"
            />
          </div>
        </div>
      </TitlePrincipal>

    </div>

    <!-- Ingresar Pin -->
    <div v-if="status === 'input'">
      <TitlePrincipal
        clazz="text-center"
        :title="$t('login.pinUnification.input.title')"
        :subtitle="$t('login.pinUnification.input.subtitle')"
      >
      </TitlePrincipal>
      <CodeInput
        fourCodes
        typeInput="password"
        @verificationCode="pin = $event"
      />
      <div class="control-buttons buttons-full-w-xs justify-content-center" v-if="!loading">
        <button
          type="button"
          class="button button-stroke button-primary"
          v-t="'login.pinUnification.input.backBtn'"
          @click="status='announcement'"
        />
        <button
          type="button"
          class="button button-filled button-primary"
          :disabled="pin.length !== 4"
          v-t="'login.pinUnification.input.confirmBtn'"
          @click="unifyPin"
        />
      </div>
      <div class="control-buttons buttons-full-w-xs justify-content-center" v-else>
        <button
          type="button"
          class="button button-stroke button-primary"
          :width="150"
          v-t="'login.pinUnification.input.backBtn'"
        />
        <button type="button" class="button button-filled button-primary">
          <LoadingSimple clazz="small-load no-text"/>
        </button>
      </div>
    </div>

    <!-- Éxito -->
    <div v-else-if="status === 'success'">
      <div class="text-center">
        <img
          class="img-fluid m-b-30"
          src="@/assets/images/Illustrations/illustration-success-nip.png"
          alt="Aviso"
          width="360"
          height="275"
        />
      </div>
      <TitlePrincipal clazz="text-center" :title="$t('login.pinUnification.success.title')">
        <div class="subtitle">
          <i18n tag="p" path="login.pinUnification.success.paragraphs[0]">
            <strong class="poppins-font-bold" v-t="'login.pinUnification.success.goToATM'"/>
          </i18n>
          <i18n tag="p" path="login.pinUnification.success.paragraphs[1]"/>
          <i18n tag="p" path="login.pinUnification.success.paragraphs[2]"/>
          <div class="control-buttons">
            <button
              type="button"
              class="button button-filled button-primary w-100"
              v-t="'login.pinUnification.success.continueBtn'"
              @click="goToDashboard"
            />
          </div>
        </div>
      </TitlePrincipal>
    </div>

    <!-- Error -->
    <div v-else-if="status === 'error'">
      <div class="text-center">
        <img
          class="img-fluid m-b-30"
          src="@/assets/images/Illustrations/illustration-error-nip.png"
          alt="Aviso"
          width="360"
          height="275"
        />
      </div>
      <TitlePrincipal clazz="text-center" :title="$t('login.pinUnification.error.title')">
        <div class="subtitle">
          <i18n tag="p" path="login.pinUnification.error.paragraphs[0]"/>
          <i18n tag="p" path="login.pinUnification.error.paragraphs[1]">
              <a
                href=""
                class="text-underline c-info poppins-font-bold"
                v-t="'login.pinUnification.error.supportTeam'"
                @click.prevent="openZendesk"
              />
          </i18n>
          <div class="control-buttons buttons-full-w-xs justify-content-center">
            <button
              type="button"
              class="button button-stroke button-primary"
              v-t="'login.pinUnification.error.closeBtn'"
              @click="goToDashboard"
            />
            <button
              type="button"
              class="button button-filled button-primary"
              v-t="'login.pinUnification.error.retryBtn'"
              @click="status = 'input'"
            />
          </div>
        </div>
      </TitlePrincipal>
    </div>
  </div>
</template>

<script>
/* Componentes */
import CodeInput from "@/components/CodeInput.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";
import TitlePrincipal from "@/components/TitlePrincipal.vue";

/* Apis */
import UserApi from "@/api/users/users.api";
import CardApi from "@/api/wallet/card.api";

/* Librerias */
import {mapGetters} from "vuex";

/* Stores */
import {
  GET_USER_NAME,
  SET_USER_HAS_UNIFIED_PIN,
  STORE as sessionStore,
} from "@/store/modules/session/session.types";

export default {
  name: "PinUnification",
  components: {
    CodeInput,
    LoadingSimple,
    TitlePrincipal,
  },
  computed: {
    ...mapGetters(sessionStore, {
      username: GET_USER_NAME
    }),
  },
  data() {
    return {
      /**
       * Determina cuál paso mostrar en la pantalla
       * @type {'announcement'|'input'|'error'|'success'}
       */
      status: 'announcement',

      /**
       * El pin que ingresa el usuario
       *  @type {string}
       */
      pin: '',

      /**
       * Indica si se está ejecutando el proceso de unificación
       * @type {boolean}
       */
      loading: false,
    };
  },
  methods: {
    /**
     * Lleva a la pantalla del Dashboard.
     */
    goToDashboard() {
      this.$router.push({name: "Dashboard"});
    },

    /**
     * Unifica el pin del usuario, validando primero que el pin ingresado sea válido.
     */
    async unifyPin() {
      try {
        this.loading = true;
        await new Promise(resolve => setTimeout(resolve, 1000));

        await this.verifyPin();
        await CardApi.unifyPin();
        this.$store.commit(`${sessionStore}/${SET_USER_HAS_UNIFIED_PIN}`, true);

        this.status = 'success';
      } catch (error) {
        this.status = 'error';
      } finally {
        this.loading = false;
      }
    },

    /**
     * Valida si el pin ingresado es válido. 🤨
     * @throws Tira un error si el pin es inválido
     */
    async verifyPin() {
      const response = await UserApi.validatePin({ pin: this.pin });
      if(response.data.error) {
        throw response;
      }
    },

    /**
     * Abre el chat de zendesk
     */
    openZendesk() {
      if(window.zE) {
        window.zE('messenger', 'open');
      }
    },
  },
  watch: {
    /**
     * Resetea el pin cada vez que se muestre el input en pantalla
     * @param {string} val El nuevo valor de `status`
     */
    status(val) {
      if (val === 'input') {
        this.pin = '';
      }
    },
  },
};
</script>

<style scoped></style>
