<template>
    <span class="badge" :class="badgeClassComputed">
        <i v-if="icon" aria-hidden="true" class="badge-icon" :class="icon"></i>
        <slot></slot>
        {{text}}
        <a v-if="closeBtnShow" @click="deleteValue" class="close-badge" tabindex="" aria-label="Boton de borrar">
            <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </a>
    </span>
</template>

<script>
    export default {
        name: 'Badge',
        props: {
            type: String,
            closeBtnShow: {type: Boolean, default: false},
            text: {
                type: [String, Number]
            },
            icon: String,
            badgeClass: {type: String, default: ''}
        },
        methods: {
            deleteValue() {
                this.$emit('deleteValue');
            }
        },
        computed: {
            badgeClassComputed() {
                return {[this.badgeClass]: true, [this.type]: true};
            }
        }
    }
</script>
