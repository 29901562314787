<template>
  <div id="app">
    <ModalTwoFactorAuth ref="modalAuth"/>
    <ModalIdleWarning ref="modalIdle"/>
    <LoadingBar v-if="loading" loadingText="Cargando..."></LoadingBar>
    <router-view v-else />
  </div>
</template>

<script>
import LoadingBar from '@/components/LoadingBar';
import { EventBus } from '@/main';
import {
    mapGetters
} from 'vuex';
import {
    STORE as SESSION_STORE,
    GET_LOADING
    // CLEAR_STORE
} from '@/store/modules/session/session.types';
import ModalTwoFactorAuth from "@/components/ModalTwoFactorAuth.vue";
import ModalIdleWarning from "@/components/ModalIdleWarning.vue";

export default {
  name: "OnboardingPersonal",
  components: {
   LoadingBar,
   ModalTwoFactorAuth,
   ModalIdleWarning,
  },
  computed: {
    ...mapGetters(SESSION_STORE, {
      loading: GET_LOADING
    })
  },
  mounted() {
    EventBus.$on('twoFactorAuth', callback => {
      this.$refs.modalAuth.requestAuth().then(callback);
    });
    EventBus.$on('openIdleWarning', this.$refs.modalIdle.openModal);
    EventBus.$on('closeIdleWarning', this.$refs.modalIdle.closeModal);
    EventBus.$on('checkIfModalIdleIsShown', (callback) => {
      callback(this.$refs.modalIdle.isModalShown);
    });
  },
  beforeDestroy() {
    EventBus.$off('twoFactorAuth');
    EventBus.$off('openIdleWarning');
    EventBus.$off('closeIdleWarning');
    EventBus.$off('checkIfModalIdleIsShown');
  },
}
</script>


<style lang="scss">
    @import "assets/stylesheets/sass/main.scss";
</style>
