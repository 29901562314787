import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'group-file-document';
const namespaceFileDoc = 'file-document';
const namespaceFiles = 'file';

export default {
    getGroupFileOnbng: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/onboarding`, params)
    },
    getFilesFromGroup: (params) => {
        return axios.get(`${base.baseUrl}/${namespaceFileDoc}/group/${params.key}`)
    },
    downloadSignedUrl: (params) => {
        return axios.post(`${base.baseUrl}/${namespaceFiles}/download/signed-url`, params)
    },
    downloadFile: (id, params) => {
        return axios.post(`${base.baseUrl}/${namespaceFiles}/download/${id}`, params)
    },
    downloadImage: (id) => {
        return `${base.baseUrl}/image/download/${id}`;
    },
}
