<template>
    <div>
        <LoadingBar v-if="loading" clazz="fullcontent-loading" :loadingText="loadingMessage"/>

        <div id="divShowLeftMenu" class="responsive-top-bar">
            <span>
                <button type="button"
                        id="showLeftMenu"
                        class="button-square xs button-filled button-primary"
                        aria-label="Mostrar menú principal"
                        name="Mostrar menú principal"
                        @click="showMenu">
                        <i aria-hidden="true" class="iconsax-essential-menu"></i>
                </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span>
                <button type="button"
                        id="showRightMenu"
                        class="button-square xs button-stroke button-primary"
                        aria-label="Mostrar menú derecho"
                        name="Mostrar menú derecho"
                        @click="showSidebarRight">
                        <i aria-hidden="true" class="iconsax-money-cards"></i>
                </button>
            </span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <SidebarRight></SidebarRight>
        <div class="sidebar-menu-backdrop sidebar-right"></div>

        <section id="main-view">
            <div class="page-container">
                <div class="row">
                    <div class="col-12">
                        <FloatingTitleLayout
                                title="Hola"
                                :titleStrong="user.name"
                                :subtitle="`${$t('general.lastAccess')} ${moment(user.lastLogin).format('LL h:mm A')}`">
                                <!-- <div class="vertical-center">
                                    Organización:
                                    <Badge type="badge-org m-l-5" text="Black Labs"></Badge>
                                </div> -->
                                <template v-slot:userImg>
                                    <router-link :to="{name: 'MyAccount'}" class="d-none d-md-block" tabindex="">
                                        <UserAccount
                                            :userImg="imageDownloadLink(selfPortrait) || imgUserAccount"
                                            :widthImg="50"
                                            :heightImg="50"
                                            altImgUser="Usuario de prueba">
                                        </UserAccount>
                                    </router-link>
                                </template>
                                <template v-slot:buttons>
                                    <button type="button"
                                            @click="createNewAccount()"
                                            class="button xs button-stroke button-primary d-none d-md-flex">
                                            {{$t('general.newAccount')}}
                                    </button>
                                    <NotificationsPanel></NotificationsPanel>
                                </template>
                        </FloatingTitleLayout>
                    </div>
                    <div class="col-12">
                        <div class="row no-gutters m-b-15">
                            <div class="col-3 d-md-none pr-2">
                                <DashboardPanel
                                    variant="sm"
                                    responsiveText="Mi perfil"
                                    extraClazzInfo="text-center">
                                    <template v-slot:userLink>
                                        <router-link to="" class="p-relative" tabindex="">
                                            <UserAccount
                                                clazz="xs m-0-auto"
                                                :userImg="imgUserAccount"
                                                :widthImg="42"
                                                :heightImg="42"
                                                altImgUser="Usuario de prueba">
                                            </UserAccount>
                                        </router-link>
                                    </template>
                                </DashboardPanel>
                            </div>
                            <div class="col-9 col-md-4 pr-md-2">
                                <DashboardPanel
                                    variant="lg p-events-initial" extraClazzInfo="max-w-100">
                                        <span>{{$t('walletLayout.balance')}}</span>
                                        <div class="money x-scroll w-100 ">
                                            {{balanceWhole | currency({fractionCount: 0})}}<span>.{{balanceDecimals}}</span>
                                        </div>
                                </DashboardPanel>
                            </div>
                            <div class="col-6 col-md-2 pl-md-2 pr-2 c-pointer" @click="goTo('Deposit')">
                                <DashboardPanel
                                    :active="isRouteActive('Deposit')"
                                    variant="blue-light md"
                                    icon="iconsax-money-receive"
                                    text="Depositar a cuenta"
                                    responsiveText="Depositar">
                                </DashboardPanel>
                            </div>
                            <div class="col-6 col-md-2 pl-2 pr-md-2 c-pointer" @click="goTo('TransfersList')">
                                <DashboardPanel
                                    :active="isRouteActive('TransfersList')"
                                    variant="pink md"
                                    icon="iconsax-money-send"
                                    text="Transferencias"
                                    responsiveText="Transferir">
                                </DashboardPanel>
                            </div>
                            <div class="col-6 col-md-2 pl-md-2 pr-2 c-pointer" @click="goTo('MyAccount')">
                                <DashboardPanel
                                    :active="isRouteActive('MyAccount')"
                                    variant="blue md"
                                    icon="iconsax-money-card"
                                    :text="`${$t('myAccount.title')}`"
                                    :responsiveText="$t('myAccount.title').toString()">
                                </DashboardPanel>
                            </div>
                            <div class="col-6 col-md-2 pl-2 c-pointer" @click="goTo('MyPoints')">
                                <DashboardPanel
                                    :active="isRouteActive('MyPoints')"
                                    variant="yellow md"
                                    icon="iconsax-like-medal-star"
                                    :text="$t('myPoints.title2')"
                                    :responsiveText="$t('myPoints.title2')">
                                </DashboardPanel>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-md-none m-b-20">
                        <!-- <router-link to="" class="button xs button-ghost button-primary m-0-auto" tabindex="">
                            Solicitar nueva cuenta
                        </router-link> -->
                    </div>
                    <div class="col-12">
                        <router-view/>
                    </div>
                </div>
            </div>
        </section>

        <!-- MODAL CAMBIAR DE CUENTA -->
        <b-modal
            id="modal-cambiar-cuenta"
            size="lg"
            hide-header
            hide-footer
            modal-class="modal-default left-oriented">
            <button class="close-modal"
                    @click="$bvModal.hide('modal-cambiar-cuenta')"
                    aria-label="Boton cerrar ventana"
                    name="Boton cerrar ventana">
                <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <div class="head">
                <h2 class="modal-title">Cambiar de cuenta</h2>
                <p class="modal-subtitle">
                    Selecciona una de tus cuentas para
                    ver y administrar tus movimientos.
                </p>
            </div>
            <div class="body">
                <!-- Add clazz "selected" when radioInput selected -->
                <LoadingBar v-if="loadingAccounts" clazz="fullcontent-loading"/>
                <PanelMyAccount
                    pointer
                    v-for="account in accounts"
                    v-bind:key="account._id"
                    primaryTitle="Saldo"
                    :primaryText="account.balance | currency"
                    secondTitle="No. de cuenta"
                    :secondText="account.fullKey"
                    :selectable="true"
                    :hideButtons="true"
                    @click.native="modalSelection = account._id"
                    :clazz="`b-shadow-none centered-xs ${modalSelection === account._id ? 'selected' : ''}`">
                    <AccountType
                        text="Mi cuenta"
                        :typeAccount="account.description"
                        :aliasText="account.aliasName"
                        clazz="p-relative imgLeft">
                        <img class="img-fluid" :src="accountImage(account)" width="64" height="40" :alt="account.description"/>
                        <template v-slot:extraText v-if="account.isMainAccount">
                            <Badge
                                class="c-pointer"
                                type="badge-info brd-4 m-t-5 p-t-0 p-b-0 p-l-5 p-r-5"
                                :text="$t('myAccount.mainAccount.badge.label')"
                            />
                        </template>
                    </AccountType>
                    <template v-slot:selectable>
                        <div class="radio-input m-b-0">
                            <input type="radio" aria-label="Radio button" v-model="modalSelection" :value="account._id">
                        </div>
                    </template>
                </PanelMyAccount>
            </div>
            <div class="footer">
                <button
                    v-if="!accountModalLoading"
                    type="button"
                    class="button button-filled button-primary w-100"
                    :disabled="!modalSelection"
                    @click="changeAccount"
                >
                    Cambiar cuenta
                </button>
                <button
                    v-else
                    type="button"
                    class="button button-filled button-primary w-100"
                >
                    <LoadingSimple clazz="small-load no-text" />
                </button>
            </div>
        </b-modal>

        <b-modal
            id="modal-alias"
            size=""
            hide-header
            hide-footer
            modal-class="modal-default left-oriented">
            <button class="close-modal"
                    @click="$bvModal.hide('modal-alias')"
                    aria-label="Boton cerrar ventana"
                    name="Boton cerrar ventana">
                <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <div class="head">
                <h2 class="modal-title">{{$t('dashboard.modalAlias.title')}}</h2>
                <p class="modal-subtitle">
                    {{$t('dashboard.modalAlias.subtitle')}}
                </p>
            </div>
            <div class="body">
                <BasicInput
                    required
                    inputId=""
                    :label="$t('dashboard.modalAlias.input.title')"
                    :inputPlaceholder="$t('dashboard.modalAlias.input.placeholder')"
                    :warningText="$t('dashboard.modalAlias.input.warning')"
                    v-model="aliasAccount"
                    inputClazz="p-r-50">
                </BasicInput>
            </div>
            <div class="footer">
                <button type="button" class="button button-stroke button-primary"
                    @click="$bvModal.hide('modal-alias');aliasAccount=''">{{$t('dashboard.modalAlias.cancel')}}</button>
                <button type="button" class="button button-filled button-primary"
                    :disabled="aliasAccount.length < 4" @click="saveAliasAccount()">{{$t('dashboard.modalAlias.save')}}</button>
            </div>
        </b-modal>

        <!-- MODAL PAGANDO CHECK INFO -->
        <HireModal></HireModal>
        <!-- MODAL Acuerdo de Traspaso de información -->
        <InfoTransferContract></InfoTransferContract>
        <!-- MODAL Firmar Acuerdo de Traspaso de información -->
        <InfoTransferContractSign></InfoTransferContractSign>

        <ModalAddExtraInfo></ModalAddExtraInfo>

        <ModalAlert
            ref="update-data"
            modalId="update-data"
            buttonType="button-primary"
            :imgUrl="imageModalGeneric"
            title="Necesitamos de tu ayuda"
            text="De acuerdo a disposiciones oficiales necesitamos que nos brindes más información para completar tu perfil."
            buttonText="Confirmar"
            :closeCross="true"
            :noCancelButton="true"
            :closeEsc="true"
            :closeBackdrop="true"
            @submit="goToAddExtraInfo()"
        ></ModalAlert>

        <ModalAlert
            ref="update-contract-multiple"
            modalId="update-contract-multiple"
            buttonType="button-primary"
            :imgUrl="imageModalGeneric"
            title="Tus cuentas tienen nuevo contrato"
            text="Actualizamos los contratos para tus cuentas. Firma tus contratos para seguir disfrutando de los beneficios de tus cuentas."
            buttonText="Firmar Contratos"
            :closeCross="true"
            buttonCancel="Lo hare después"
            :noCancelButton="user.contract !== 1 && user.contract !== 3"
            @cancel="cancelSignContract()"
            :closeEsc="true"
            :closeBackdrop="true"
            @submit="goToUpdateContract(true)"
        ></ModalAlert>

        <ModalAlert
            ref="update-contract"
            modalId="update-contract"
            buttonType="button-primary"
            :imgUrl="imageModalGeneric"
            title="Tu cuenta tiene nuevo contrato"
            text="Actualizamos el contrato para tu cuenta. Firma tu contrato para seguir disfrutando de los beneficios de tu cuenta."
            buttonText="Firmar Contrato"
            :closeCross="true"
            buttonCancel="Lo hare después"
            @cancel="cancelSignContract()"
            :noCancelButton="user.contract !== 1 && user.contract !== 3"
            :closeEsc="true"
            :closeBackdrop="true"
            @submit="goToUpdateContract()"
        ></ModalAlert>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>
    </div>
</template>
<script>
    // @ is an alias to /src
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import {
        STORE as sessionStore,
        GET_USER,
        GET_ACCOUNT,
        SET_ACCOUNT,
        GET_USER_SELF_PORTRAIT,
    } from "@/store/modules/session/session.types";
    import Badge from "@/components/Badge.vue";
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import SidebarRight from "@/components/SidebarRight.vue";
    import DashboardPanel from "@/components/DashboardPanel.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import ModalAlert from "@/components/ModalAlert.vue";
    import UserAccount from "@/components/UserAccount.vue";
    import imgUserAccount from '@/assets/images/Default/profile-photo.svg';
    import PanelMyAccount from "@/components/PanelMyAccount.vue";
    import AccountType from "@/components/AccountType.vue";
    import {mapGetters} from "vuex";
    import CardsApi from '@/api/wallet/card.api';
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import {EventBus} from "@/main";
    import moment from 'moment';
    import { imageDownloadLink } from '@/utils/utils';
    import LoadingSimple from '@/components/LoadingSimple.vue'
    import LoadingBar from "@/components/LoadingBar.vue";
    import NotificationsPanel from "@/pages/NotificationsPanel.vue";

    import pagandoExplore from "@/assets/images/Cards/pagando-explore.svg"
    import pagandoExplorePlus from "@/assets/images/Cards/pagando-explore-plus.svg";
    import pagandoNight from "@/assets/images/Cards/pagando-night.svg";
    import pagandoBusiness from "@/assets/images/Cards/pagando-business.svg";
    import pagandoBusinessPro from "@/assets/images/Cards/Accounts/businesspro-virtual-front.svg";
    import HireModal from "@/pages/Pagandocheck/HireModal.vue";
    import InfoTransferContract from "@/pages/Pagandocheck/InfoTransferContract.vue";
    import InfoTransferContractSign from "@/pages/Pagandocheck/InfoTransferContractSign.vue";
    import ModalAddExtraInfo from "@/pages/ModalAddExtraInfo.vue";

import imageModalGeneric from "@/assets/images/Illustrations/illustration-modal-generic.svg";
    import AccountsApi from '@/api/wallet/accounts.api';


    const modalCambiarCuenta = 'modal-cambiar-cuenta';

    export default {
        name: "WalletLayout",
        mixins: [ToastSnotify],
        components: {
            Badge,
            FloatingTitleLayout,
            SidebarMenuPrincipal,
            SidebarRight,
            DashboardPanel,
            FooterPage,
            UserAccount,
            NotificationsPanel,
            // MovementsList,
            PanelMyAccount,
            AccountType,
            LoadingSimple,
            LoadingBar,
            HireModal,
            InfoTransferContract,
            InfoTransferContractSign,
            ModalAddExtraInfo,
            BasicInput,
            ModalAlert
        },
        props: {},
        data() {
            return {
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                imageModalGeneric,
                isSidebarRightOpen: true,
                isMenuOpen: false,
                imgUserAccount: imgUserAccount,
                balance: 0,
                balanceWhole: 0,
                balanceDecimals: '00',
                accounts: [],
                modalSelection: null,
                accountModalLoading: false,
                loading: false,
                loadingMessage: null,
                loadingAccounts: false,
                unsubscribe: null,
                aliasAccount: ''
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                user: GET_USER,
                selfPortrait: GET_USER_SELF_PORTRAIT,
                account: GET_ACCOUNT,
            }),
            needToUpdateDataVar() {
                return this.user.needToUpdateData;
            },
            needToUpdateContract() {
                const alreadyShow = localStorage.getItem('s1');
                return (this.user.contract === 1 && alreadyShow !== '1') || this.user.contract === 2 || this.user.contract === 3 || this.user.contract === 4;
            }
        },
        methods: {
            moment,
            imageDownloadLink,
            goToUpdateContract(isMultiple = false) {
                if(isMultiple) {
                    this.$bvModal.hide('update-contract-multiple');
                } else {
                    this.$bvModal.hide('update-contract');
                }
                this.$router.push({ name: 'UpdateContract'});

            },
            goToAddExtraInfo() {
                this.$bvModal.hide('update-data')
                this.$bvModal.show('modal-add-extra-info');
            },
            logOut() {
                localStorage.removeItem('currentToken');
                this.$store.commit(`${sessionStore}/CLEAR_STORE`);
                this.$router.push({ name: 'login' });
            },
            // Show Sidebar right
            showSidebarRight () {
                document.getElementById('sidebar-right').classList.remove('hide');
            },
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                this.isMenuOpen = window.innerWidth > 767;
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            goTo(name) {
              if (this.$route.name !== name) {
                this.$router.push({name});
              } else {
                this.$router.push({name: 'Dashboard'})
              }
            },
            goToQuery(name, params) {
              if (this.$route.name === "MyAccount") {
                EventBus.$emit("MyAccount-open-bank-st")
              } else {
                this.$router.push({ name: name, query: params })
              }
            },
            async loadBalance() {
                try {
                    if(this.account && this.account.cards.length) {
                        const response = await CardsApi.getAccountInfo({
                            paymentSourceId: this.account.cards[0].card._id,
                            provider: this.account.account.externalProvider,
                        });
                        this.balance = response.data.object.balance;
                        this.balanceWhole = Math.floor(response.data.object.balance);
                        this.balanceDecimals = Math.round((this.balance*100) % 100).toLocaleString('en', {minimumIntegerDigits: 2});
                    }
                } catch (error) {
                    this.toastErrorDefault(error);
                }
            },
            async loadAccounts() {
                try {
                    this.loadingAccounts = true;
                    const response = await CardsApi.getAccountsWithBalance();
                    this.accounts = response.data.object;
                    const currentAccountId = this.account && this.account.account ? this.account.account._id : null;
                    if(this.accounts.find(acc => acc._id === currentAccountId)) {
                        this.modalSelection = currentAccountId;
                    }
                    this.loadingAccounts = false;
                } catch (error) {
                    this.toastErrorDefault(error);
                    this.loadingAccounts = false;
                }
            },
            changeAccount() {
                this.accountModalLoading = true;
                this.$store.dispatch(`${sessionStore}/loadAccount`, this.modalSelection).then(success => {
                    if(success) {
                        this.$bvModal.hide(modalCambiarCuenta);
                    } else {
                        this.accountModalLoading = false;
                        this.toastErrorDefault();
                    }
                });
            },
            accountImage(account) {
                const defaultImage = pagandoExplore;

                if(account.image) {
                    return imageDownloadLink(account.image);
                }

                switch (account.product) {
                    case "EXPLORE":
                        return pagandoExplore;
                    case "EXPLORE_PLUS":
                        return pagandoExplorePlus;
                    case "NIGHT":
                        return pagandoNight;
                    case "BUSINESS":
                        return pagandoBusiness;
                    case "BUSINESS_PRO":
                        return pagandoBusinessPro;
                    default:
                        return defaultImage;
                }
            },
            createNewAccount() {
                this.$router.push({name: 'createNewAccount', params: { isMultiple: true }})
            },
            isRouteActive(route) {
                const matched = this.$route.matched.filter(r => r.name === route);
                if(matched.length > 0) {
                    return true;
                }

                return false;
            },
            async saveAliasAccount() {

                if(this.aliasAccount.length < 4) {
                        this.toastErrorDefault(this.$t('dashboard.modalAlias.warningMessage'));

                } else {
                    const response = await AccountsApi.setAliasToAccount({alias: this.aliasAccount, accountId: this.account.account._id});

                    if(response.data.error) {
                        this.toastErrorDefault(this.$t('dashboard.modalAlias.errorMessage'));
                    } else {
                        this.$bvModal.hide('modal-alias');
                        this.aliasAccount = '';
                        this.toastSuccessDefault(this.$t('dashboard.modalAlias.successMessage'));
                        this.$store.dispatch(`${sessionStore}/loadAccount`);
                    }
                }

            },
            cancelSignContract() {
                localStorage.setItem('s1', 1);
            }
        },
        created () {
            this.unsubscribe = this.$watchMutation(sessionStore, SET_ACCOUNT, this.loadBalance);
            EventBus.$on('TransfersList.onAuthorize', (event) => {
                this.loading = event.loading;
                this.loadingMessage = event.loadingMessage;
            });
        },
        mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);

            this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
                if(modalId === modalCambiarCuenta) {
                    this.loadAccounts();
                }

                if(modalId === 'modal-alias') {
                    this.aliasAccount = this.account.account.aliasName || '';
                }
            })
            this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
                if(modalId === modalCambiarCuenta) {
                    this.modalSelection = null;
                    this.accountModalLoading = false;
                }
            })

            if(this.needToUpdateDataVar) {
                this.$bvModal.show('update-data');
            }

            if(this.needToUpdateContract) {
                if(this.user.docsContract.length > 1) {
                    this.$bvModal.show('update-contract-multiple');
                } else {
                    this.$bvModal.show('update-contract');
                }
            }

        },
        beforeDestroy () {
            EventBus.$off('TransfersList.onAuthorize');
            if(this.unsubscribe) {
                this.unsubscribe();
            }
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
