<template>
  <div class="row" v-if="hasChildren || showWhenEmpty">
    <div class="col-12">
      <div v-if="showTable" id="tree-table-accionistas" class="">
        <div class="table-responsive">
          <vue-ads-table
            :columns="columnsAccionistas"
            :rows="rowsAccionistas"
            :classes="classesAccionistas"
            :call-children="loadChildren"
            class="table b-table table-xl table-tree-rows"
          >
            <template slot="toggle-children-icon" slot-scope="props">
              <i
                aria-hidden="true"
                class="icon-table-tree banana"
                :class="[
                  props.expanded ? 'banana-symbol-minus' : 'banana-symbol-plus',
                ]"
              >
              </i>
            </template>
            <template slot="nombreRazon" slot-scope="props">
              <p class="m-l-25 m-b-0">{{ props.row.nombreRazon }}</p>
            </template>
            <template slot="legal" slot-scope="props">
              <i
                v-if="props.row.personType === 'PHYSIC' && props.row.legal"
                aria-hidden="true"
                class="banana banana-check-circle2 f-20 c-title m-0-auto"
              ></i>
              <i
                v-if="props.row.personType === 'PHYSIC' && !props.row.legal"
                aria-hidden="true"
                class="banana banana-symbol-minus f-20 c-title m-0-auto"
              ></i>
            </template>
            <template slot="acciones" slot-scope="props" v-if="!readOnly">
              <div class="horizontal-center flex-wrap-no">
                <button
                  v-if="props.row.personType === 'MORAL'"
                  :id="`add-accionista-tooltip-${props.row._id}`"
                  class="button-circle xs button-ghost button-accent2"
                  name="Agregar accionista"
                  aria-label="Agregar accionista"
                  type="button"
                  @click="openNewChildModal(props.row._id)"
                >
                  <i aria-hidden="true" class="banana banana-symbol-plus"></i>
                </button>
                <b-tooltip
                  v-if="props.row.personType === 'MORAL'"
                  :target="`add-accionista-tooltip-${props.row._id}`"
                  placement="left"
                >
                  <p class="m-b-0">Agregar accionista en este nivel</p>
                </b-tooltip>

                <button
                  v-if="!props.row.isRoot"
                  :id="`editar-accionista-tooltip-${props.row._id}`"
                  class="button-circle xs button-ghost button-info"
                  name="Editar accionista"
                  aria-label="Editar accionista"
                  type="button"
                  @click="editShareholder(props.row)"
                >
                  <i aria-hidden="true" class="banana banana-edit"></i>
                </button>
                <b-tooltip
                  v-if="!props.row.isRoot"
                  :target="`editar-accionista-tooltip-${props.row._id}`"
                  placement="left"
                >
                  <p class="m-b-0">Editar accionista</p>
                </b-tooltip>

                <button
                  v-if="!props.row.isRoot"
                  :id="`eliminar-accionista-tooltip-${props.row._id}`"
                  class="button-circle xs button-ghost button-error"
                  name="Eliminar accionista"
                  aria-label="Eliminar accionista"
                  type="button"
                  @click="openDeleteModal(props.row)"
                >
                  <i aria-hidden="true" class="banana banana-delete"></i>
                </button>
                <b-tooltip
                  v-if="!props.row.isRoot"
                  :target="`eliminar-accionista-tooltip-${props.row._id}`"
                  placement="left"
                >
                  <p class="m-b-0">Eliminar accionista</p>
                </b-tooltip>
              </div>
            </template>
          </vue-ads-table>
        </div>
      </div>
      <div v-else>
        <LoadingBar
            :loadingText="$t('register.shareholders.notification.loadingTable')"
        ></LoadingBar>
      </div>
    </div>
    <b-modal
      id="modal-agregar-accionista"
      size=""
      hide-header
      hide-footer
      modal-class="modal-default"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-agregar-accionista')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{$t('register.shareholders.notification.modal.kind')}}</h2>
        <p class="modal-subtitle">
          {{$t('register.shareholders.notification.modal.kindText')}}
        </p>
      </div>
      <div class="body">
        <div class="row">
          <div class="col-12 m-b-20" @click="selectType('PHYSIC')">
            <div
              class="accionista-type single"
              v-bind:class="{ selected: selectedNewType === 'PHYSIC' }"
            >
              <img
                class="img-fluid"
                src="@/assets/images/Illustrations/illustration-accionistas-persona-fisica.svg"
                alt="Persona fisica"
              />
              <span class="accionista-type-info">
                <label>{{$t('register.shareholders.notification.modal.titlePhysic')}}</label>
                <p>
                  {{$t('register.shareholders.notification.modal.textPhysic')}}
                </p>
              </span>
            </div>
          </div>
          <div class="col-12" @click="selectType('MORAL')">
            <div
              class="accionista-type single"
              v-bind:class="{ selected: selectedNewType === 'MORAL' }"
            >
              <img
                class="img-fluid"
                src="@/assets/images/Illustrations/illustration-accionistas-persona-moral.svg"
                alt="Persona moral"
              />
              <span class="accionista-type-info">
                <label>{{$t('register.shareholders.notification.modal.titleMoral')}}</label>
                <p>
                  {{$t('register.shareholders.notification.modal.textMoral')}}
                </p>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button
          type="button"
          class="button button-filled button-primary w-100"
          :disabled="!selectedNewType"
          @click="addChild"
        >
          {{ $t('register.shareholders.notification.modal.next') }}
        </button>
      </div>
    </b-modal>
    <ModalAlert
      ref="modalDeleteShareholder"
      modalId="modal-delete-shareholder"
      buttonType="button-primary"
      :imgUrl="imageModalGeneric"
      :title="deleteModalTitle"
      :text="deleteModalText"
      :buttonText="deleteModalButtonText"
      @submit="deleteShareholder"
      withAutomaticLoad
      :noCancelButton="selectedShareholderHasChildren"
    ></ModalAlert>
  </div>
</template>

<script>
import { VueAdsTable } from "vue-ads-table-tree";
import ShareholderApi from "@/api/core/shareholders.api";
import { catchError } from "@/utils/utils";
import { mapGetters } from "vuex";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import imageModalGeneric from "@/assets/images/Illustrations/illustration-modal-generic.svg";
import ModalAlert from "@/components/ModalAlert.vue";
import LoadingBar from "@/components/LoadingBar";
import {
  STORE as OnboardingStore,
  GET_CARD_REQUEST,
} from '@/store/modules/onboardingSteps/onboardingSteps.types';

export default {
  name: "ShareholdersTree",
  components: {
    VueAdsTable,
    ModalAlert,
    LoadingBar
  },
  props: {
    readOnly: {
      type: Boolean,
      default: false
    },
    showWhenEmpty: {
      type: Boolean,
      default: false
    },
    isMulti: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const columnsAccionistas = [
      {
        property: "nombreRazon",
        title: "Nombre/Razón Social",
      },
      {
        property: "legal",
        title: "R. Legal",
      },
      {
        property: "porcentaje",
        title: "Porcentaje",
      },
    ];
    if(!this.readOnly) {
      columnsAccionistas.push(
        {
          property: "acciones",
          title: "Acciones",
        }
      );
    }
    const classesAccionistas = {
      "0/all": {
        "c-info_text": true,
      },
    };
    return {
      showTable: true,
      // Table tree
      columnsAccionistas,
      rowsAccionistas: [],
      classesAccionistas,
      hasChildren: false,
      imageModalGeneric,
      selectedShareholderId: null,
      selectedShareholderHasChildren: null,
      selectedNewType: null,
    };
  },
  computed: {
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO,
    }),
    ...mapGetters(OnboardingStore, {
      cardRequest: GET_CARD_REQUEST,
    }),
    deleteModalTitle() {
      if(this.selectedShareholderHasChildren) {
        return 'No puedes borrar este accionista';
      }
      return 'Estas a punto de borrar un accionista';
    },
    deleteModalText() {
      if(this.selectedShareholderHasChildren) {
        return 'Otros accionistas dependen de éste. Debes eliminar todos los accionistas dependientes primero.';
      }
      return 'Recuerda que esta operacion no se puede deshacer. ¿Estas seguro?';
    },
    deleteModalButtonText() {
      if(this.selectedShareholderHasChildren) {
        return 'Cerrar';
      }
      return 'Eliminar';
    },
  },
  async mounted() {
    await this.setRoot(this.$route.query.org);
  },
  methods: {
    async loadChildren(father, organizationAux) {
      this.showTable = false;
      let fatherId;
      if (father && !father.isRoot) {
        fatherId = father.id;
      }
      const response = await ShareholderApi.loadTree({
        organization: this.organization._id || organizationAux,
        father: fatherId,
      }).catch(catchError);
      if (response && response.data && !response.data.error) {
        if (response.data.object.length > 0) {
          this.hasChildren = true;
        }
        this.$emit("onLoadChildrenDone", response.data.object.length > 0);
        this.showTable = true;
        return response.data.object.map((shareholder) => {
          return {
            ...shareholder,
            _showChildren: false,
            _classes: [
              shareholder.personType === "MORAL"
                ? { "poppins-font-bold": {} }
                : { "poppins-font-medium f-500": {} },
            ],
          };
        });
      }
      this.$emit("onLoadChildrenError");
      this.showTable = true;
      return [];
    },
    async setRoot(organization=null) {
      const _children = await this.loadChildren(null, organization);
      this.$emit("onLoadRootDone", _children);
      const rootData = {_children};
      if(_children.length > 0) {
        rootData._showChildren = true;
      } else {
        rootData._showChildren = false;
      }

      this.rowsAccionistas = [
        {
          id: this.organization._id,
          nombreRazon: this.organization.legalName,
          personType: "MORAL",
          isRoot: true,
          _classes: [
            {
              "poppins-font-bold": {},
            },
          ],
          ...rootData
        },
      ];
    },
    openNewChildModal(id) {
      this.selectedShareholderId = id;
      this.selectedNewType = null;
      this.$bvModal.show("modal-agregar-accionista");
    },
    addChild() {
      const route = {};
      if(this.isMulti) {
        if (this.selectedNewType === "PHYSIC") {
          route.name = 'newPhysicalShareholderMultiple';
        } else {
          route.name = 'newMoralShareholderMultiple';
        }
      } else {
        if (this.selectedNewType === "PHYSIC") {
        route.name = 'newPhysicalShareholder';
      } else {
        route.name = 'newMoralShareholder';
      }
      }
      if(this.selectedShareholderId) {
        route.query = { parent: this.selectedShareholderId };
      }
      this.$router.push(route);
    },
    editShareholder({id, personType}) {
      const route = { query: { id } };
      if(this.isMulti) {
        if (personType === "PHYSIC") {
          route.name = 'newPhysicalShareholderMultiple';
        } else {
          route.name = 'newMoralShareholderMultiple';
        }
      } else {
        if (personType === "PHYSIC") {
          route.name = 'newPhysicalShareholder';
        } else {
          route.name = 'newMoralShareholder';
        }
      }
      this.$router.push(route);
    },
    openDeleteModal(shareholder) {
      this.selectedShareholderId = shareholder._id;
      this.selectedShareholderHasChildren = shareholder._hasChildren || (shareholder._children || []).length > 0;
      this.$bvModal.show("modal-delete-shareholder");
    },
    async deleteShareholder() {
      if(!this.selectedShareholderHasChildren) {
        const response = await ShareholderApi.delete({
          id: this.selectedShareholderId
        }).catch(catchError);
        if (response && response.data && !response.data.error) {
          await this.setRoot();
        } else {
          this.$emit("onDeleteError");
        }
      }
      this.$bvModal.hide("modal-delete-shareholder");
      this.$refs.modalDeleteShareholder.localLoading = false;
    },
    selectType(value) {
      this.selectedNewType = value;
    },
  },
  watch: {
    organization: {
      immediate: true,
      handler(org) {
        if (org && org._id) {
          this.setRoot();
        }
      },
    },
  },
};
</script>
