<template>
  <div>
    <div class="row" v-if="bulk">
      <div class="col-12">
        <router-link :to="{name: 'BulkContactsList'}" class="button xs button-ghost button-text_info p-l-0 p-r-0"
          tabindex="">
          <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
          Regresar a Importar Contactos
        </router-link>
        <FloatingTitleLayout titleStrong="Importador de Contactos" />
      </div>

      <div class="col-12">
        <CardSimple title="Información del Proceso" showButtons>
          <template v-slot:titleButtons>
            <div>
              <span :class="`badge ${status.badgeClass}`">
                <i aria-hidden="true" class="badge-icon iconbullet"></i>
                {{status.badgeText}}
              </span>
            </div>
          </template>
          <div class="row">
            <div class="col-12 col-lg-3 col-md-4">
              <div class="vertical-center flex-wrap-no column-gap-10px m-b-25">
                <span class="badge-circle md badge-accent">
                  <i aria-hidden="true" class="iconsax-users-user-2-bold"></i>
                </span>
                <ReadOnlyInfo titleClazz="f-12 mb-0" clazz="w-fit m-b-0" title="Total de Contactos" :text="bulk.total">
                </ReadOnlyInfo>
              </div>
            </div>
            <div class="col-12 col-lg-3 col-md-4">
              <div class="vertical-center flex-wrap-no column-gap-10px m-b-25">
                <span class="badge-circle md badge-warning">
                  <i aria-hidden="true" class="iconsax-users-user-2-minus-bold"></i>
                </span>
                <ReadOnlyInfo titleClazz="f-12 mb-0" clazz="w-fit m-b-0" title="Pendientes" :text="bulk.pending">
                </ReadOnlyInfo>
              </div>
            </div>
            <div class="col-12 col-lg-3 col-md-4">
              <div class="vertical-center flex-wrap-no column-gap-10px m-b-25">
                <span class="badge-circle md badge-success">
                  <i aria-hidden="true" class="iconsax-users-user-2-tick-bold"></i>
                </span>
                <ReadOnlyInfo titleClazz="f-12 mb-0" clazz="w-fit m-b-0" title="Importados" :text="bulk.completed">
                </ReadOnlyInfo>
              </div>
            </div>
            <div class="col-12 col-lg-3 col-md-4">
              <div class="vertical-center flex-wrap-no column-gap-10px m-b-25">
                <span class="badge-circle md badge-error">
                  <i aria-hidden="true" class="iconsax-users-user-2-remove-bold"></i>
                </span>
                <ReadOnlyInfo titleClazz="f-12 mb-0" clazz="w-fit m-b-0" title="Fallidos" :text="bulk.failed">
                </ReadOnlyInfo>
              </div>
            </div>
            <div class="col-12">
              <div class="m-b-20">
                <b-progress :max="100" :value="bulk.progress" class="xl" :class="{success: bulk.status === 'SUCCESS'}">
                </b-progress>
              </div>
              <ReadOnlyInfo clazz="text-center mb-0" :title="bulk.status === 'FAILED' ? 'Por favor contacta a soporte' : ''"
                :text="`${bulk.total - bulk.pending} / ${bulk.total}`"
              >
              </ReadOnlyInfo>
            </div>
          </div>
        </CardSimple>
        <div class="control-buttons">
          <button @click="$router.push({name: 'BulkContactsList'})" type="button" class="button-icon">
            <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
            Regresar
          </button>
          <div class="vertical-center" v-if="bulk.status === 'COMPLETED_WITH_ERRORS'">
            <button type="button" class="button button-filled button-primary" @click="downloadError">
              Descargar Conflictos
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import CardSimple from "@/components/CardSimple.vue";
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
import FloatingTitleLayout from "@/components/FloatingTitleLayout";

import ContactsApi from "@/api/wallet/contacts.api";

export default {
  name: "BulkContactsDetails",
  components: {
    CardSimple,
    ReadOnlyInfo,
    FloatingTitleLayout,
  },
  data() {
    return {
      interval: null,
      bulk: null,
    }
  },
  computed: {
    status() {
      let badgeClass, badgeText;
      switch (this.bulk?.status) {
        case "PENDING_AUTHORIZATION":
        case "IDLE":
        case "IN_PROCESS":
          badgeClass = 'badge-warning';
          badgeText = 'Procesando';
          break;
        case "FAILED":
          badgeClass = 'badge-error';
          badgeText = 'Fallido';
          break;
        case "COMPLETED_WITH_ERRORS":
          badgeClass = 'badge-error';
          badgeText = 'Completado con errores';
          break;
        case "SUCCESS":
        default:
          badgeClass = 'badge-info';
          badgeText = 'Terminado';
          break;
      }
      return {
        badgeClass,
        badgeText,
      };
    },
  },
  methods: {
    async checkStatus() {
      try {
        const id = this.$route.params.id;
        const response = await ContactsApi.checkBulkProgress(id);
        this.bulk = response.data.object;
        if (['FAILED', 'COMPLETED_WITH_ERRORS', 'SUCCESS'].includes(this.bulk.status)) {
          clearInterval(this.interval);
        }
      } catch (error) {
        this.toastErrorDefault(error);
        clearInterval(this.interval);
      }
    },
    async downloadError() {
      const res = await ContactsApi.downloadBulk({
        layoutId: this.$route.params.id,
        onlyErrors: true
      })
      if (res.data && !res.data.error) {
        const temp = `data:application/vnd.ms-excel;base64,${
            encodeURIComponent(res.data.object)}`;
        const download = document.createElement('a');
        download.href = temp;
        download.download = res.filename || "Contactos";
        document.body.appendChild(download);
        download.click();
        document.body.removeChild(download);
      } else {
        this.displayNotificationError(this.$i18n.t('dispersions.details.error.downloadFile'))
      }
    },
  },
  created() {
    this.checkStatus();
    this.interval = setInterval(this.checkStatus, 2000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>
