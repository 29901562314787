import {EventBus} from "@/main";
import CardApi from "@/api/wallet/card.api";
import GroupFilesApi from "@/api/core/files.api";
import GiroApi from "@/api/core/giro.api";
import CountryApi from "@/api/core/countries.api";
import DocumentsApi from "@/api/core/documents.api";
import usersApi from '@/api/users/users.api';

// import router from "@/router"
import {
    STORE,
    SET_TYPE_ACCOUNT, SET_TYPE_ACCOUNT_BUSINESS, GET_TYPE_ACCOUNT,
    SET_CURRENT_PCT, GET_CURRENT_PCT,
    SET_CURRENT_STEPS, GET_CURRENT_STEPS,
    GET_SELECT_ACCOUNT, SET_SELECT_ACCOUNT, GET_ACCOUNT_TYPE, GET_ACCOUNT_TYPE_BUSINESS,
    SET_CARD_REQUEST, GET_CARD_REQUEST, LOAD_CARD_REQUEST,
    GET_LEVEL, SET_LEVEL,
    GET_AUX_LEVEL, SET_AUX_LEVEL,
    SET_GROUP_FILES, GET_GROUP_FILES, LOAD_GROUP_FILES,
    GET_DOCUMENTS, SET_DOCUMENTS,
    SET_ERROR_REQUEST_CARD, GET_ERROR_CARD_REQUEST,
    SET_LOCATION, GET_LOCATION,
    SET_GIROS, GET_GIROS, LOAD_GIROS,
    SET_COUNTRIES, GET_COUNTRIES, LOAD_COUNTRIES,
    GO_TO_ROUTE, SET_GO_TO_WAIT, GET_GO_TO_WAIT,
    SET_CONTRACT_INDEX, GET_CONTRACT_INDEX,
    SET_OBJECT_ERROR_REQUEST_CARD, GET_OBJECT_ERROR_REQUEST_CARD,
    GET_CREATE_NEW_ORG, SET_CREATE_NEW_ORG,
    SET_POLICIES_ACCEPTED, GET_POLICIES_ACCEPTED,
    SET_SKIPPED_DYNAMIC, GET_SKIPPED_DYNAMIC,
    SET_ONB_DYNAMIC_COLLAB, GET_ONB_DYNAMIC_COLLAB,
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
    STORE as SessionStore,
    GET_USER
} from "@/store/modules/session/session.types";
// import {
//     STORE as OrgInfoStore,
//     GET_ORGANIZATION_INFO
// } from "@/store/modules/organizationInfo/organizationInfo.types";
import {catchError} from "@/utils/utils";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import _ from 'lodash';
import router from '@/router'
import {
    STORE as organizationInfoStore,
    GET_ORGANIZATIONS_INFO
} from "@/store/modules/organizationInfo/organizationInfo.types";

const stepNewAccount = "register.stepsNames.stepNewAccount";
const stepNewAccountMulti = "multipleAccountPersonal.steps.selectAccount";
const stepInfo = "register.stepsNames.stepInfo";
const stepInfoAdd = "register.stepsNames.stepInfoAdd";
const stepSecurity = "register.stepsNames.stepSecurity";
const stepValAcc = "register.stepsNames.stepValAcc";
const stepContract = "register.stepsNames.stepContract";
const stepBeneficiaries = "register.stepsNames.stepBeneficiaries";
const stepPromotion = "register.stepsNames.stepPromotion";
const stepBusiness = "register.stepsNames.stepBusiness";

// Pasos para Colaboradores
const stepsLevel0 = [
    {
        step: 1,
        visited: false,
        current: true,
        name: stepNewAccount,
        first: "registerCollaborator",
    },
    {
        step: 2,
        visited: false,
        name: stepInfo,
        first: "nationalityCollaborator",
    },
    {
        step: 3,
        visited: false,
        name: stepInfoAdd,
        first: "addInfoCollaborator",
    },
    {
        step: 4,
        visited: false,
        name: stepSecurity,
        first: "phoneVerificationCollaborator",
    },
];

const stepsLevel1and2 = [
    {
        step: 1,
        visited: false,
        current: true,
        name: stepNewAccount,
        first: "selectAccount",
    },
    {
        step: 2,
        visited: false,
        name: stepInfo,
        first: "nationality",
    },
    {
        step: 3,
        visited: false,
        name: stepSecurity,
        first: "phoneVerification",
    },
    {
        step: 4,
        visited: false,
        name: stepValAcc,
        first: "processingRequest",
    },
    {
        step: 5,
        visited: false,
        name: stepBeneficiaries,
        first: "beneficiaries",
    },
    {
        step: 6,
        visited: false,
        name: stepContract,
        first: "contract",
    },
    {
        step: 7,
        visited: false,
        name: stepPromotion,
        first: "promotionalCode",
    },
];
const stepsLevel3 = [
    {
        step: 1,
        visited: false,
        name: stepNewAccount,
        first: "selectAccount",
    },
    {
        step: 2,
        visited: false,
        name: stepInfo,
        first: "nationality",
    },
    {
        step: 3,
        visited: false,
        name: stepInfoAdd,
        first: "addInfo"
    },
    {
        step: 4,
        visited: false,
        name: stepSecurity,
        first: "phoneVerification",
    },
    {
        step: 5,
        visited: false,
        name: stepValAcc,
        first: "processingRequest",
    },
    {
        step: 6,
        visited: false,
        name: stepBeneficiaries,
        first: "beneficiaries",
    },
    {
        step: 7,
        visited: false,
        name: stepContract,
        first: "contract",
    },
    {
        step: 8,
        visited: false,
        name: stepPromotion,
        first: "promotionalCode",
    },
];
const stepsLevelBusinessP = [
    {
        step: 1,
        visited: false,
        name: stepNewAccount,
        first: "selectAccount",
    },
    {
        step: 2,
        visited: false,
        name: stepBusiness,
        first: "businessStart"
    },
    {
        step: 3,
        visited: false,
        name: stepInfo,
        first: "businessNationality",
    },
    {
        step: 4,
        visited: false,
        name: stepInfoAdd,
        first: "businessAddInfo"
    },
    {
        step: 5,
        visited: false,
        name: stepSecurity,
        first: "businessPhoneVerification",
    },
    {
        step: 6,
        visited: false,
        name: stepValAcc,
        first: "businessProcessingRequest",
    },
    {
        step: 7,
        visited: false,
        name: stepBeneficiaries,
        first: "businessBeneficiaries",
    },
    {
        step: 8,
        visited: false,
        name: stepContract,
        first: "businessContract",
    },
    {
        step: 9,
        visited: false,
        name: stepPromotion,
        first: "businessPromotionalCode",
    },
];
const stepsLevelBusinessM = [
    {
        step: 1,
        visited: false,
        name: stepNewAccount,
        first: "selectAccount",
    },
    {
        step: 2,
        visited: false,
        name: stepBusiness,
        first: "businessStart"
    },
    {
        step: 3,
        visited: false,
        name: "register.stepsNames.stepFiscal",
        first: "businessFiscalData"
    },
    {
        step: 4,
        visited: false,
        name: "register.stepsNames.stepShareholders",
        first: "businessShareholders"
    },
    {
        step: 5,
        visited: false,
        name: stepSecurity,
        first: "businessNewPin"
    },
    {
        step: 6,
        visited: false,
        name: stepValAcc,
        first: "businessProcessingRequest",
    },
    {
        step: 7,
        visited: false,
        name: stepContract,
        first: "businessContract",
    },
    {
        step: 8,
        visited: false,
        name: stepPromotion,
        first: "businessPromotionalCode",
    },
];

const state = {
    isPersonalAccount: true,
    businessType: '',
    completedPercent: 10,
    steps: stepsLevel1and2,
    level: 1,
    levelAux: 1,
    accountName: null,
    cardRequest: '',
    groupFiles: [],
    documentsGroupFiles: [],
    errorCardRequest: true,
    objectErrorCardRequest: '',
    location: {},
    giros: [],
    countries: [],
    goToWaitRequest: false,
    contractIndex: 5,
    createNewOrg: false,
    policiesAccepted: false,
    skippedDynamic: false,
    onbDynamicCollab: null
};

const getters = {
    [GET_TYPE_ACCOUNT]: (state) => state.isPersonalAccount,
    [GET_CURRENT_PCT]: (state) => state.completedPercent,
    [GET_CURRENT_STEPS]: (state) => state.steps,
    [GET_SELECT_ACCOUNT]: (state) => state.accountName,
    [GET_CARD_REQUEST]: (state) => state.cardRequest,
    [GET_ERROR_CARD_REQUEST]: (state) => state.errorCardRequest,
    [GET_OBJECT_ERROR_REQUEST_CARD]: (state) => state.objectErrorCardRequest,
    [GET_ACCOUNT_TYPE]: (state) => state.cardRequest.cardTemplate.accountType,
    [GET_ACCOUNT_TYPE_BUSINESS]: (state) => state.businessType,
    [GET_LEVEL]: (state) => state.level,
    [GET_AUX_LEVEL]: (state) => state.levelAux,
    [GET_GROUP_FILES]: (state) => state.groupFiles,
    [GET_DOCUMENTS]: (state) => state.documentsGroupFiles,
    [GET_LOCATION]: (state) => state.location,
    [GET_GIROS]: (state) => state.giros,
    [GET_COUNTRIES]: (state) => state.countries,
    [GET_GO_TO_WAIT]: (state) => state.goToWaitRequest,
    [GET_CONTRACT_INDEX]: (state) => state.contractIndex,
    [GET_CREATE_NEW_ORG]: (state) => state.createNewOrg,
    [GET_POLICIES_ACCEPTED]: (state) => state.policiesAccepted,
    [GET_SKIPPED_DYNAMIC]: (state) => state.skippedDynamic,
    [GET_ONB_DYNAMIC_COLLAB]: (state) => state.onbDynamicCollab,
};

function dataForBusiness(dispatch, commit, object, updateLevel) {
    commit(SET_TYPE_ACCOUNT, false);
    commit(SET_TYPE_ACCOUNT_BUSINESS, object.isMoral ? 'MORAL' : 'PHYSIC');
    const steps = object.isMoral ? 'BUSINESS_MORAL' : 'BUSINESS_PHYSIC';
    if (updateLevel) {
        dispatch(`putLevel`, steps);
    }
    // commit(SET_LEVEL, steps);
}

function getStepsForMultiplePersonal(getters, internalGetters) {
    const user = getters[`${SessionStore}/${GET_USER}`];

    let stepBase = [{
        step: 1,
        visited: false,
        current: true,
        name: stepNewAccountMulti,
        first: "selectAccountMultiple",
    },{
        step: 2,
        visited: false,
        name: stepValAcc,
        first: "processingRequestMultiple",
    },
    {
        step: 3,
        visited: false,
        name: stepContract,
        first: "contractMultiple",
    }];

    const cardRequest = internalGetters[GET_CARD_REQUEST]
    const level = cardRequest ? cardRequest.information_level === 'LEVEL_TWO' ? 2 : cardRequest.information_level === 'LEVEL_THREE' ? 3 : 1 : 1;
    switch (level) {
        case 2:
            if(user && !user.hasFinishLevelTwo) {

                stepBase = [
                {
                    step: 1,
                    visited: false,
                    current: true,
                    name: stepNewAccountMulti,
                    first: "selectAccountMultiple",
                },{
                    step: 2,
                    visited: false,
                    name: stepInfo,
                    first: "residenceMultiple",
                },{
                    step: 3,
                    visited: false,
                    name: stepValAcc,
                    first: "processingRequestMultiple",
                },
                {
                    step: 4,
                    visited: false,
                    name: stepContract,
                    first: "contractMultiple",
                }];
            }

            return stepBase;
        case 3:

            if(user && !user.hasFinishLevelTwo && !user.hasFinishLevelThree) {

                stepBase = [
                    {
                        step: 1,
                        visited: false,
                        current: true,
                        name: stepNewAccountMulti,
                        first: "selectAccountMultiple",
                    },{
                        step: 2,
                        visited: false,
                        name: stepInfo,
                        first: "residenceMultiple",
                    },{
                        step: 3,
                        visited: false,
                        name: stepInfoAdd,
                        first: "addInfoMultiple"
                    },{
                        step: 4,
                        visited: false,
                        name: stepValAcc,
                        first: "processingRequestMultiple",
                    },
                    {
                        step: 5,
                        visited: false,
                        name: stepContract,
                        first: "contractMultiple",
                    }];
            } else if(user && user.hasFinishLevelTwo && !user.hasFinishLevelThree) {

                stepBase = [
                    {
                        step: 1,
                        visited: false,
                        current: true,
                        name: stepNewAccountMulti,
                        first: "selectAccountMultiple",
                    },{
                        step: 2,
                        visited: false,
                        name: stepInfoAdd,
                        first: "addInfoMultiple"
                    },{
                        step: 3,
                        visited: false,
                        name: stepValAcc,
                        first: "processingRequestMultiple",
                    },
                    {
                        step: 4,
                        visited: false,
                        name: stepContract,
                        first: "contractMultiple",
                    }];
            }
            return stepBase;
        default:
            return stepBase;
    }
}

function _getStepForNewBusiness(user) {
    let stepBase =[{
        step: 1,
        visited: false,
        current: true,
        name: stepNewAccountMulti,
        first: "selectAccountBusinessMultiple",
    },
    {
        step: 2,
        visited: false,
        name: stepBusiness,
        first: "businessStartMultiple"
    },
    {
        step: 3,
        visited: false,
        name: stepValAcc,
        first: "businessProcessingRequestMultiple",
    },
    {
        step: 4,
        visited: false,
        name: stepContract,
        first: "businessContractMultiple",
    },
    // {
    //     step: 5,
    //     visited: false,
    //     name: 'Bienvenido',
    //     first: "businessOnbngWelcomeMultiple",
    // }
    ];
    if(user && !user.hasFinishLevelTwo) {
            stepBase = [
            {
                step: 1,
                visited: false,
                current: true,
                name: stepNewAccountMulti,
                first: "selectAccountBusinessMultiple",
            },
            {
                step: 2,
                visited: false,
                name: stepBusiness,
                first: "businessStartMultiple"
            },
            {
                step: 3,
                visited: false,
                name: stepInfo,
                first: "businessResidenceMultiple",
            },
            {
                step: 4,
                visited: false,
                name: stepInfoAdd,
                first: "businessAddInfoMultiple"
            },{
                step: 5,
                visited: false,
                name: stepValAcc,
                first: "businessProcessingRequestMultiple",
            },
            {
                step: 6,
                visited: false,
                name: stepContract,
                first: "businessContractMultiple",
            },
            // {
            //     step: 7,
            //     visited: false,
            //     name: 'Bienvenido',
            //     first: "businessOnbngWelcomeMultiple",
            // }
        ];

} else if(user && user.hasFinishLevelTwo && !user.hasFinishLevelThree) {
        stepBase = [
            {
                step: 1,
                visited: false,
                current: true,
                name: stepNewAccountMulti,
                first: "selectAccountBusinessMultiple",
            },{
                step: 2,
                visited: false,
                name: stepBusiness,
                first: "businessStartMultiple"
            },{
                step: 3,
                visited: false,
                name: stepInfoAdd,
                first: "businessAddInfoMultiple"
            },{
                step: 4,
                visited: false,
                name: stepValAcc,
                first: "businessProcessingRequestMultiple",
            },
            {
                step: 5,
                visited: false,
                name: stepContract,
                first: "businessContractMultiple",
            },
            // {
            //     step: 6,
            //     visited: false,
            //     name: 'Bienvenido',
            //     first: "businessOnbngWelcomeMultiple",
            // }
        ];


    }

    return stepBase;
}

function _getStepForSelectBusiness(user) {
    let stepBase =[{
        step: 1,
        visited: false,
        current: true,
        name: stepNewAccountMulti,
        first: "selectAccountBusinessMultiple",
    },
    {
        step: 2,
        visited: false,
        name: stepBusiness,
        first: "selectCreateOrgBusinessMultiple",
    },
    {
        step: 3,
        visited: false,
        name: stepValAcc,
        first: "businessProcessingRequestMultiple",
    },
    {
        step: 4,
        visited: false,
        name: stepContract,
        first: "businessContractMultiple",
    },
    // {
    //     step: 5,
    //     visited: false,
    //     name: 'Bienvenido',
    //     first: "businessOnbngWelcomeMultiple",
    // }
    ];
    if(user && !user.hasFinishLevelTwo) {
            stepBase = [
                {
                    step: 1,
                    visited: false,
                    current: true,
                    name: stepNewAccountMulti,
                    first: "selectAccountBusinessMultiple",
                },
                {
                    step: 2,
                    visited: false,
                    name: stepBusiness,
                    first: "selectCreateOrgBusinessMultiple",
                },
                // {
                //     step: 3,
                //     visited: false,
                //     name: stepBusiness,
                //     first: "businessStartMultiple"
                // },
                {
                    step: 3,
                    visited: false,
                    name: stepInfo,
                    first: "businessResidenceMultiple",
                },
                {
                    step: 4,
                    visited: false,
                    name: stepInfoAdd,
                    first: "businessAddInfoMultiple"
                },{
                    step: 5,
                    visited: false,
                    name: stepValAcc,
                    first: "businessProcessingRequestMultiple",
                },
                {
                    step: 6,
                    visited: false,
                    name: stepContract,
                    first: "businessContractMultiple",
                },
                // {
                //     step: 7,
                //     visited: false,
                //     name: 'Bienvenido',
                //     first: "businessOnbngWelcomeMultiple",
                // }
            ];
    } else if(user && user.hasFinishLevelTwo && !user.hasFinishLevelThree) {
            stepBase = [
                {
                    step: 1,
                    visited: false,
                    current: true,
                    name: stepNewAccountMulti,
                    first: "selectAccountBusinessMultiple",
                },
                {
                    step: 2,
                    visited: false,
                    name: stepBusiness,
                    first: "selectCreateOrgBusinessMultiple",
                },{
                    step: 3,
                    visited: false,
                    name: stepInfoAdd,
                    first: "businessAddInfoMultiple"
                },{
                    step: 4,
                    visited: false,
                    name: stepValAcc,
                    first: "businessProcessingRequestMultiple",
                },
                {
                    step: 5,
                    visited: false,
                    name: stepContract,
                    first: "businessContractMultiple",
                },
                // {
                //     step: 6,
                //     visited: false,
                //     name: 'Bienvenido',
                //     first: "businessOnbngWelcomeMultiple",
                // }
            ];
        }

        return stepBase;
}

async function getStepsForMultipleBusinessPhysic(getters) {
    const user = getters[`${SessionStore}/${GET_USER}`];
    const orgs = getters[`${organizationInfoStore}/${GET_ORGANIZATIONS_INFO}`];


    // TODO filter orgs
    if(orgs && orgs.length > 0) {
        return _getStepForSelectBusiness(user);
    }
    return _getStepForNewBusiness(user);

}

function getStepsForMultipleBusinessMoral(internalGetters) {

    const isNewOrg = internalGetters.GET_CREATE_NEW_ORG;

    let stepBase = [{
        step: 1,
        visited: false,
        current: true,
        name: stepNewAccountMulti,
        first: "selectAccountBusinessMultiple",
    },
    {
        step: 2,
        visited: false,
        name: stepBusiness,
        first: "businessStartMultiple"
    },
    {
        step: 3,
        visited: false,
        name: stepValAcc,
        first: "businessProcessingRequestMultiple",
    },
    {
        step: 4,
        visited: false,
        name: stepContract,
        first: "businessContractMultiple",
    },
    // {
    //     step: 5,
    //     visited: false,
    //     name: 'Bienvenido',
    //     first: "businessOnbngWelcomeMultiple",
    // }
    ];

    if(isNewOrg) {

        stepBase = [
            {
                step: 1,
                visited: false,
                current: true,
                name: stepNewAccountMulti,
                first: "selectAccountBusinessMultiple",
            },
            {
                step: 2,
                visited: false,
                name: stepBusiness,
                first: "businessStartMultiple"
            },
            {
                step: 3,
                visited: false,
                name: "register.stepsNames.stepFiscal",
                first: "businessFiscalDataMultiple"
            },
            {
                step: 4,
                visited: false,
                name: "register.stepsNames.stepShareholders",
                first: "businessShareholdersMultiple"
            },
            {
                step: 5,
                visited: false,
                name: stepValAcc,
                first: "businessProcessingRequestMultiple",
            },
            {
                step: 6,
                visited: false,
                name: stepContract,
                first: "businessContractMultiple",
            },
            // {
            //     step: 8,
            //     visited: false,
            //     name: 'Bienvenido',
            //     first: "businessOnbngWelcomeMultiple",
            // }
        ];
    }

    return stepBase;
}

async function changStatusStep(commit, getters, step, internalGetters) {
    // const cardRequest = getters[GET_CARD_REQUEST]
    const level = getters[GET_LEVEL];
    // const level = cardRequest ? cardRequest.level : 1;
    switch (level) {
        case 0:
            changeCurrent(commit, stepsLevel0, step);
            break;
        case 1:
        case 2:
            changeCurrent(commit, stepsLevel1and2, step);
            break;
        case 3:
            changeCurrent(commit, stepsLevel3, step);
            break;
        case 4:
            changeCurrent(commit, stepsLevelBusinessP, step);
            break;
        case 5:
            changeCurrent(commit, stepsLevelBusinessM, step);
            break;
        case 6:
            changeCurrent(commit, getStepsForMultiplePersonal(internalGetters, getters), step);
            break;
        case 7:
            changeCurrent(commit, await getStepsForMultipleBusinessPhysic(internalGetters), step);
            break;
        case 8:
            changeCurrent(commit, getStepsForMultipleBusinessMoral(getters), step);
            break;
        default:
            changeCurrent(commit, stepsLevel1and2, step);
            break;
    }
}

function changeCurrent(commit, array, step) {

    let percent;
    if (step === array.length) {
        percent = 100;
    } else {
        percent = (step / array.length) * 100;
    }

    commit(SET_CURRENT_PCT, Math.round(percent));

    const newArray = _.cloneDeep(array);
    let contractIndex;
    newArray.forEach((s, i) => {
        if (s.name === stepContract) {
            contractIndex = i;
        }
        if (i === step) {
            s.current = true;
        } else {
            if (i < step) {
                s.visited = true;
            }
            s.current = false;
        }
    });
    commit(SET_CONTRACT_INDEX, contractIndex);
    commit(SET_CURRENT_STEPS, newArray);
}

function switchRoutesBySteps(step) {
    switch (step) {
        case ONBOARDING_STEPS.SELECT_CARD:
            return router.push({name: "selectAccount"});
        case ONBOARDING_STEPS.POLICIES_OF_USE:
            return router.push({name: "usagePolicies"});
        case ONBOARDING_STEPS.NATIONALITY:
            return router.push({name: "nationality"});
        case ONBOARDING_STEPS.PERSONAL_INFO:
            return router.push({name: "personalInfo"});
        case ONBOARDING_STEPS.PLACE_RESIDENCE:
            return router.push({name: "residence"});
        case ONBOARDING_STEPS.JOB:
            return router.push({name: "occupation"});
        case ONBOARDING_STEPS.PHONE_VERIFICATION:
            return router.push({name: "phoneVerification"});
        case ONBOARDING_STEPS.CODE_VERIFICATION:
            return router.push({name: "codeVerification"});
        case ONBOARDING_STEPS.SECURITY_MEASURES:
            return router.push({name: "securityMeasures"});
        case ONBOARDING_STEPS.CREATE_NIP:
            return router.push({name: "newPin"});
        case ONBOARDING_STEPS.CONFIRM_NIP:
            return router.push({name: "confirmPin"});
        case ONBOARDING_STEPS.BENEFICIARIES:
            return router.push({name: "beneficiariesRegister"});
        case ONBOARDING_STEPS.CONTRACT:
            return router.push({name: "contract"});
        case ONBOARDING_STEPS.SELECT_DOCUMENT:
            return router.push({name: "selectDocuments"});
        case ONBOARDING_STEPS.UPLOAD_DOCUMENTS:
            return router.push({name: "selectDocuments"});
        case ONBOARDING_STEPS.WAIT_REQUEST:
            return router.push({name: "processingRequest"});
        case ONBOARDING_STEPS.ADD_DATA:
            return router.push({name: "addInfo"});
        default:
            return router.push({name: "selectAccount"});
    }
}

function switchRoutesByStepsBusiness(step, cardRequest) {
    switch (step) {
        case ONBOARDING_STEPS.SELECT_CARD:
            return router.push({name: "selectAccountBusiness"});
        case ONBOARDING_STEPS.POLICIES_OF_USE:
            return router.push({name: "usagePoliciesBusiness"});
        case ONBOARDING_STEPS.BUSINESS_START:
            return router.push({name: "businessStart"});
        case ONBOARDING_STEPS.BUSINESS_OPERATES:
            return router.push({name: "businessOperates"});
        case ONBOARDING_STEPS.BUSINESS_INFO:
            return router.push({name: "businessData"});
        case ONBOARDING_STEPS.BUSINESS_FISCAL_DATA:
            return router.push({name: "businessFiscalData"});
        case ONBOARDING_STEPS.BUSINESS_FISCAL_ADDRESS:
            return router.push({name: "businessFiscalAddress"});
        case ONBOARDING_STEPS.BUSINESS_COMERCIAL_ADDRESS:
            return router.push({name: "businessFiscalComAddress"});
        case ONBOARDING_STEPS.NATIONALITY:
            return router.push({name: "businessNationality"});
        case ONBOARDING_STEPS.PERSONAL_INFO:
            return router.push({name: "businessPersonalInfo"});
        case ONBOARDING_STEPS.PLACE_RESIDENCE:
            return router.push({name: "businessResidence"});
        case ONBOARDING_STEPS.BENEFICIARIES:
            if (!cardRequest.isMoral) {
                return router.push({name: "businessBeneficiaries"});
            }
            return router.push({name: "selectSchemaBusiness"});
        case ONBOARDING_STEPS.JOB:
            if(cardRequest.status === 'IN_REVIEW') {
                return router.push({name: "businessProcessingRequest"});
            }
            return router.push({name: "businessOccupation"});
        case ONBOARDING_STEPS.PHONE_VERIFICATION:
            return router.push({name: "businessPhoneVerification"});
        case ONBOARDING_STEPS.CODE_VERIFICATION:
            return router.push({name: "businessCodeVerification"});
        case ONBOARDING_STEPS.SECURITY_MEASURES:
            return router.push({name: "businessSecurityMeasures"});
        case ONBOARDING_STEPS.CREATE_NIP:
            return router.push({name: "businessNewPin"});
        case ONBOARDING_STEPS.CONFIRM_NIP:
            return router.push({name: "businessConfirmPin"});
        case ONBOARDING_STEPS.CONTRACT:
            return router.push({name: "businessContract"});
            case ONBOARDING_STEPS.BUSINESS_SHAREHOLDERS:
                return router.push({name: "businessShareholders"});
        case ONBOARDING_STEPS.SELECT_DOCUMENT:
        case ONBOARDING_STEPS.UPLOAD_DOCUMENTS:
            if (cardRequest.isMoral) {
                return router.push({name: "businessDocuments"});
            }
            return router.push({name: "businessSelectDocuments"});
        case ONBOARDING_STEPS.WAIT_REQUEST:
            return router.push({name: "businessProcessingRequest"});
        case ONBOARDING_STEPS.ADD_DATA:
            return router.push({name: "businessAddInfo"});
        default:
            return router.push({name: "selectSchemaBusiness"});
    }
}

function switchRoutesByStepsCollab(step) {
    switch (step) {
        case ONBOARDING_STEPS.NATIONALITY:
            return router.push({name: "nationalityCollaborator"});
        case ONBOARDING_STEPS.SELECT_DOCUMENT:
            return router.push({name: "selectDocumentsCollaborator"});
        case ONBOARDING_STEPS.UPLOAD_DOCUMENTS:
            return router.push({name: "uploadDocumentsCollaborator"});
        case ONBOARDING_STEPS.PERSONAL_INFO:
            return router.push({name: "personalInfoCollaborator"});
        case ONBOARDING_STEPS.PLACE_RESIDENCE:
            return router.push({name: "residenceCollaborator"});
        case ONBOARDING_STEPS.JOB:
            return router.push({name: "occupationCollaborator"});
        case ONBOARDING_STEPS.ADD_DATA:
            return router.push({name: "addInfoCollaborator"});
        case ONBOARDING_STEPS.PHONE_VERIFICATION:
            return router.push({name: "phoneVerificationCollaborator"});
        case ONBOARDING_STEPS.CODE_VERIFICATION:
            return router.push({name: "codeVerificationCollaborator"});
        case ONBOARDING_STEPS.SECURITY_MEASURES:
            return router.push({name: "securityMeasuresCollaborator"});
        case ONBOARDING_STEPS.CREATE_NIP:
            return router.push({name: "newPinCollaborator"});
        case ONBOARDING_STEPS.CONFIRM_NIP:
            return router.push({name: "confirmPinCollaborator"});
        default:
            return router.push({name: "nationalityCollaborator"});
    }
}

function pushBackBusinessMoral(cardRequest, noMoral, moral) {
    if (cardRequest.isMoral) {
        router.push({name: moral});
    } else {
        router.push({name: noMoral});
    }
}

function _checkBusinessAddInfoMultiple(commit, internalGetters, externalGetters) {
    const user = externalGetters[`${SessionStore}/${GET_USER}`];
    if(user && !user.hasFinishLevelTwo) {
        changStatusStep(commit, internalGetters, 3, externalGetters);
    } else if(user && user.hasFinishLevelTwo && !user.hasFinishLevelThree) {
        changStatusStep(commit, internalGetters, 2, externalGetters);
    }
}

function _checkLevel(commit, internalGetters, externalGetters) {
    const user = externalGetters[`${SessionStore}/${GET_USER}`];
    const cardRequest = internalGetters[GET_CARD_REQUEST]
    const level = cardRequest.information_level === 'LEVEL_TWO' ? 2 : cardRequest.information_level === 'LEVEL_THREE' ? 3 : 1;
    if(level === 2) {
        if(user.hasFinishLevelTwo) {
            changStatusStep(commit, internalGetters, 1, externalGetters);
        } else {
            changStatusStep(commit, internalGetters, 2, externalGetters);
        }
    } else if(level === 3) {

        if(!user.hasFinishLevelTwo && !user.hasFinishLevelThree) {

            changStatusStep(commit, internalGetters, 3, externalGetters);
        } else if(user.hasFinishLevelTwo && !user.hasFinishLevelThree){

            changStatusStep(commit, internalGetters, 2, externalGetters);
        } else {

            changStatusStep(commit, internalGetters, 1, externalGetters);
        }
    } else {

        changStatusStep(commit, internalGetters, 1, externalGetters);
    }
}

function getLevel(getter) {
    const cardRequest = getter[GET_CARD_REQUEST]
    return cardRequest ? cardRequest.information_level === 'LEVEL_TWO' ? 2 : cardRequest.information_level === 'LEVEL_THREE' ? 3 : 1 : 1;

}

function _checkLevelContract(commit, internalGetters, externalGetters) {
    const user = externalGetters[`${SessionStore}/${GET_USER}`];
    const level = getLevel(internalGetters);
    if(level === 2) {
        if(user.hasFinishLevelTwo) {
            changStatusStep(commit, internalGetters, 2, externalGetters);
        } else {
            changStatusStep(commit, internalGetters, 3, externalGetters);
        }
    } else if(level === 3) {

        if(!user.hasFinishLevelTwo && !user.hasFinishLevelThree) {

            changStatusStep(commit, internalGetters, 4, externalGetters);
        } else if(user.hasFinishLevelTwo && !user.hasFinishLevelThree){

            changStatusStep(commit, internalGetters, 3, externalGetters);
        } else {

            changStatusStep(commit, internalGetters, 2, externalGetters);
        }
    } else {

        changStatusStep(commit, internalGetters, 2, externalGetters);
    }
}

function _checkOnbngWelcomeMultiple(commit, internalGetters, externalGetters) {
    const user = externalGetters[`${SessionStore}/${GET_USER}`];
    const level = getLevel(internalGetters);
    if(level === 2) {
        if(user.hasFinishLevelTwo) {
            changStatusStep(commit, internalGetters, 3, externalGetters);
        } else {
            changStatusStep(commit, internalGetters, 4, externalGetters);
        }
    } else if(level === 3) {

        if(!user.hasFinishLevelTwo && !user.hasFinishLevelThree) {

            changStatusStep(commit, internalGetters, 5, externalGetters);
        } else if(user.hasFinishLevelTwo && !user.hasFinishLevelThree){

            changStatusStep(commit, internalGetters, 4, externalGetters);
        } else {

            changStatusStep(commit, internalGetters, 3, externalGetters);
        }
    } else {

        changStatusStep(commit, internalGetters, 3, externalGetters);
    }
}

function _checkLevelAddInfo(commit, internalGetters, externalGetters) {
    const user = externalGetters[`${SessionStore}/${GET_USER}`];

    if(!user.hasFinishLevelTwo && !user.hasFinishLevelThree) {
        changStatusStep(commit, internalGetters, 2, externalGetters);
    } else if(user.hasFinishLevelTwo && !user.hasFinishLevelThree){
        changStatusStep(commit, internalGetters, 1, externalGetters);
    }
}

function _checkBusinessProcessingRequestMultiple(commit, internalGetters, externalGetters) {
    const user = externalGetters[`${SessionStore}/${GET_USER}`];
    if(user && !user.hasFinishLevelTwo) {
        changStatusStep(commit, internalGetters, 4, externalGetters);
    } else if(user && user.hasFinishLevelTwo && !user.hasFinishLevelThree) {
        changStatusStep(commit, internalGetters, 3, externalGetters);
    } else {
        changStatusStep(commit, internalGetters, 2, externalGetters);
    }
}

function _checkBusinessContractMultiple(commit, internalGetters, externalGetters) {
    const user = externalGetters[`${SessionStore}/${GET_USER}`];
    if(user && !user.hasFinishLevelTwo) {
        changStatusStep(commit, internalGetters, 5, externalGetters);
    } else if(user && user.hasFinishLevelTwo && !user.hasFinishLevelThree) {
        changStatusStep(commit, internalGetters, 4, externalGetters);
    } else {
        changStatusStep(commit, internalGetters, 3, externalGetters);
    }
}

function _checkBusinessOnbngWelcomeMultiple(commit, internalGetters, externalGetters) {
    const user = externalGetters[`${SessionStore}/${GET_USER}`];
    if(user && !user.hasFinishLevelTwo) {
        changStatusStep(commit, internalGetters, 6, externalGetters);
    } else if(user && user.hasFinishLevelTwo && !user.hasFinishLevelThree) {
        changStatusStep(commit, internalGetters, 5, externalGetters);
    } else {
        changStatusStep(commit, internalGetters, 4, externalGetters);
    }
}

function _checkBusinessProcessingRequestMultipleMoral(commit, internalGetters, externalGetters) {
    const isNewOrg = internalGetters.GET_CREATE_NEW_ORG;
    if(isNewOrg) {
        changStatusStep(commit, internalGetters, 4, externalGetters);
    } else {
        changStatusStep(commit, internalGetters, 2, externalGetters);
    }
}

function _checkBusinessContractMultipleMoral(commit, internalGetters, externalGetters) {
    const isNewOrg = internalGetters.GET_CREATE_NEW_ORG;
    if(isNewOrg) {
        changStatusStep(commit, internalGetters, 5, externalGetters);
    } else {
        changStatusStep(commit, internalGetters, 3, externalGetters);
    }
}

function _checkBusinessOnbngWelcomeMultipleMoral(commit, internalGetters, externalGetters) {
    const isNewOrg = internalGetters.GET_CREATE_NEW_ORG;
    if(isNewOrg) {
        changStatusStep(commit, internalGetters, 6, externalGetters);
    } else {
        changStatusStep(commit, internalGetters, 4, externalGetters);
    }
}

function _checkBusinessStartMultiple(commit, internalGetters, externalGetters) {
    const user = externalGetters[`${SessionStore}/${GET_USER}`];
    // const orgs = externalGetters[`${organizationInfoStore}/${GET_ORGANIZATIONS_INFO}`];
    const createNewOrg = internalGetters.GET_CREATE_NEW_ORG;
    if(user && !user.hasFinishLevelTwo) {
        if(createNewOrg) {
            // if(orgs.length > 0) {
                changStatusStep(commit, internalGetters, 1, externalGetters);
            // } else {
                changStatusStep(commit, internalGetters, 1, externalGetters);
            // }
        } else {
            changStatusStep(commit, internalGetters, 4, externalGetters);
        }
    } else {

        if(createNewOrg) {
            changStatusStep(commit, internalGetters, 1, externalGetters);
        } else {
            changStatusStep(commit, internalGetters, 4, externalGetters);
        }
        // changStatusStep(commit, internalGetters, 2, externalGetters);
    }
}

/**
 * Checa si el usuario actual del onboarding es un colaborador
 */
function _isCollaborator(user, cardRequest, level) {
    if(cardRequest || !user) {
        return false;
    }
    if(user.stepOnboardingCollaborator) {
        // Esta en proceso de onboarding
        return true;
    }
    // Está en la pantalla de Welcome al final del onboarding.
    return user.hasNewRegisterFinish && level === 0;
}

function _checkRedirectBusinessStart(commit, getters, cardRequest) {
    const orgs = getters[`${organizationInfoStore}/${GET_ORGANIZATIONS_INFO}`];

    const orgsMoral = orgs.filter(o => o.invoiceData.isMoralPerson);
    const orgsPhysic = orgs.filter(o => !o.invoiceData.isMoralPerson);

    if(cardRequest.isMoral) {
        if(orgsMoral.length > 0) {
            router.push({name: "selectCreateOrgBusinessMultiple"})
        } else {
            commit(`SET_CREATE_NEW_ORG`, true);
            router.push({name: "selectAccountBusinessMultiple"})
        }
    } else {
        if(orgsPhysic.length > 0) {
            router.push({name: "selectCreateOrgBusinessMultiple"})
        } else {
            commit(`SET_CREATE_NEW_ORG`, true);
            router.push({name: "selectAccountBusinessMultiple"})
        }
    }
}

const actions = {
    getRouteByStep({getters}) {
        const level = getters[GET_LEVEL];
        const cardRequest = getters[GET_CARD_REQUEST];
        const user = this.getters[`${SessionStore}/${GET_USER}`];
        const isPersonal = getters[GET_TYPE_ACCOUNT];
        if(_isCollaborator(user, cardRequest, level)) {
            return switchRoutesByStepsCollab(user.stepOnboardingCollaborator);
        }
        if(cardRequest) {
            if (cardRequest.accountType === 'BUSINESS') {
                if (cardRequest.status === 'CANCELLED') {
                    return router.push({name: "businessSuccessRequest"});
                }
                return switchRoutesByStepsBusiness(cardRequest.stepOnboarding, cardRequest);
            }
            if (cardRequest.status === 'CANCELLED') {
                return router.push({name: "successRequest"});
            }

            return switchRoutesBySteps(cardRequest.stepOnboarding);
        }

        if(isPersonal) {
            return switchRoutesBySteps();

        }

        return switchRoutesByStepsBusiness();

    },
    // eslint-disable-next-line sonarjs/cognitive-complexity
    backPersonal({dispatch, getters}) {
        const cardRequest = getters[GET_CARD_REQUEST];
        const policiesAccepted = getters[GET_POLICIES_ACCEPTED];
        const user = this.getters[`${SessionStore}/${GET_USER}`];
        const route = router.currentRoute.name;
        switch (route) {
            case 'newAccount':
                router.push({name: "registerAccount"});
                break;
            case 'selectAccount':
                router.push({name: "selectAccountType"});
                break;
            case 'usagePolicies':
                router.push({name: "selectAccount"});
                break;
            case 'nationality':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.POLICIES_OF_USE,
                });
                router.push({name: "usagePolicies"});
                break;
            case 'personalInfo':
                if (cardRequest.information_level === 'LEVEL_ONE') {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.NATIONALITY,
                    });
                    router.push({name: "nationality"});
                } else {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.SELECT_DOCUMENT,
                    });
                    router.push({name: "selectDocuments"});
                }
                break;
            case 'residence':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.PERSONAL_INFO,
                });
                router.push({name: "personalInfo"});
                break;
            case 'occupation':
                if (cardRequest.information_level === 'LEVEL_ONE') {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.PERSONAL_INFO,
                    });
                    router.push({name: "personalInfo"});
                } else {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.PLACE_RESIDENCE,
                    });
                    router.push({name: "residence"});
                }
                break;
            case 'selectDocuments':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.NATIONALITY,
                });
                router.push({name: "nationality"});
                break;
            case 'uploadDocuments':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.SELECT_DOCUMENT,
                });
                router.push({name: "selectDocuments"});
                break;
            case 'addInfo':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.JOB,
                });
                router.push({name: "occupation"});
                break;
            case 'phoneVerification':
                if (cardRequest.information_level === 'LEVEL_THREE') {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.ADD_DATA,
                    });
                    router.push({name: "addInfo"});
                } else {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.JOB,
                    });
                    router.push({name: "occupation"});
                }
                break;
            case 'codeVerification':
            case 'securityMeasures':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.PHONE_VERIFICATION,
                });
                router.push({name: "phoneVerification"});
                break;
            case 'newPin':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.SECURITY_MEASURES,
                });
                router.push({name: "securityMeasures"});
                break;
            case 'confirmPin':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.CREATE_NIP,
                });
                router.push({name: "newPin"});
                break;
            case 'usagePoliciesMultiple':
                router.push({name: "selectAccountMultiple"});
                break;
            case 'selectAccountMultiple':
                router.push({name: "createNewAccount"});
                break;
            case 'residenceMultiple':
                if(policiesAccepted) {
                    // Skip policies page
                    router.push({name: "selectAccountMultiple"});
                } else {
                    router.push({name: "usagePoliciesMultiple"});
                }
                break;
            case 'occupationMultiple':
                router.push({name: "residenceMultiple"});
                break;
            case 'selectDocumentsMultiple':
                router.push({name: "occupationMultiple"});
                break;
            case 'uploadDocumentsMultiple':
                router.push({name: "selectDocumentsMultiple"});
                break;
            case 'addInfoMultiple':
                if(user.hasFinishLevelTwo) {
                    if(policiesAccepted) {
                        // Skip policies page
                        router.push({name: "selectAccountMultiple"});
                    } else {
                        router.push({name: "usagePoliciesMultiple"});
                    }
                } else {
                    router.push({name: "selectDocumentsMultiple"});
                }
                break;
            default:
                router.back();
                break;

        }
    },
    backCollaborator() {
        const route = router.currentRoute.name;
        switch (route) {
            case 'selectDocumentsCollaborator': return router.push({name: "nationalityCollaborator"});
            case 'uploadDocumentsCollaborator': return router.push({name: "selectDocumentsCollaborator"});
            case 'personalInfoCollaborator': return router.push({name: "selectDocumentsCollaborator"});
            case 'residenceCollaborator': return router.push({name: "personalInfoCollaborator"});
            case 'occupationCollaborator': return router.push({name: "residenceCollaborator"});
            case 'addInfoCollaborator': return router.push({name: "occupationCollaborator"});
            case 'phoneVerificationCollaborator': return router.push({name: "addInfoCollaborator"});
            case 'codeVerificationCollaborator': return router.push({name: "phoneVerificationCollaborator"});
            case 'securityMeasuresCollaborator': return router.push({name: "phoneVerificationCollaborator"});
            case 'newPinCollaborator': return router.push({name: "securityMeasuresCollaborator"});
            case 'confirmPinCollaborator': return router.push({name: "securityMeasuresCollaborator"});
            default: return router.back();
        }
    },
    backBusiness({dispatch, getters, commit}) {
        const cardRequest = getters[GET_CARD_REQUEST];
        const policiesAccepted = getters[GET_POLICIES_ACCEPTED];
        const route = router.currentRoute.name;
        // eslint-disable-next-line sonarjs/max-switch-cases
        switch (route) {
            case 'newAccountBusiness':
                router.push({name: "registerAccount"});
                break;
            case 'usagePoliciesBusiness':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.SELECT_CARD,
                });
                router.push({name: "selectAccountBusiness"});
                break;
            case 'selectSchemaBusiness':
                router.push({name: "selectAccountType"});
                break;
            case 'selectAccountBusiness':
                router.push({name: "SelectSchemaBusiness"});
                break;
            case 'businessStart':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.POLICIES_OF_USE,
                });
                router.push({name: "usagePoliciesBusiness"});
                break;
            case 'businessOperates':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.BUSINESS_START,
                });
                router.push({name: "businessStart"});
                break;
            case 'businessNationality':
                if(cardRequest.isMoral) {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.CREATE_NIP,
                    });
                    router.push({name: "businessNewPin"});
                } else {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.BUSINESS_START,
                    });
                    router.push({name: "businessStart"});
                }
                break;
            case 'businessPersonalInfo':
                if(cardRequest.isMoral) {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.NATIONALITY,
                    });
                    router.push({name: "businessNationality"});
                } else {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.SELECT_DOCUMENT,
                    });
                    router.push({name: "businessSelectDocuments"});
                }
                break;
            case 'businessResidence':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.PERSONAL_INFO,
                });
                router.push({name: "businessPersonalInfo"});
                break;
            case 'businessOccupation':
                if (cardRequest.information_level === 'LEVEL_ONE') {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.PERSONAL_INFO,
                    });
                    router.push({name: "businessPersonalInfo"});
                } else {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.PLACE_RESIDENCE,
                    });
                    router.push({name: "businessResidence"});
                }
                break;
            case 'businessDocuments':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.BUSINESS_OPERATES
                });
                router.push({name: "businessOperates"});
                break;
            case 'businessData':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.SELECT_DOCUMENT
                });
                router.push({name: "businessDocuments"});
                break;
            case 'businessFiscalData':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.BUSINESS_INFO
                });
                router.push({name: "businessData"});
                break;
            case 'businessFiscalAddress':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.BUSINESS_FISCAL_DATA
                });
                router.push({name: "businessFiscalData"});
                break;
            case 'businessFiscalComAddress':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.BUSINESS_FISCAL_ADDRESS
                });
                router.push({name: "businessFiscalAddress"});
                break;
            case 'businessShareholders':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.BUSINESS_COMERCIAL_ADDRESS
                });
                router.push({name: "businessFiscalComAddress"});
                break;
            case 'businessUploadDocuments':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.SELECT_DOCUMENT
                });
                router.push({name: "businessSelectDocuments"});
                break;
            case 'businessSelectDocuments':
                if (cardRequest.isMoral) {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.BUSINESS_OPERATES
                    });
                    router.push({name: "businessOperates"});
                } else {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.NATIONALITY
                    });
                    router.push({name: "businessNationality"});
                }
                break;
            case 'businessAddInfo':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.JOB
                });
                router.push({name: "businessOccupation"});
                break;
            case 'businessPhoneVerification':
                if(cardRequest.isMoral) {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.PLACE_RESIDENCE
                    });
                    router.push({name: "businessResidence"});
                } else {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.ADD_DATA
                    });
                    router.push({name: "businessAddInfo"});
                }
                break;
            case 'businessCodeVerification':
            case 'businessSecurityMeasures':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.PHONE_VERIFICATION
                });
                router.push({name: "businessPhoneVerification"});
                break;
            case 'businessNewPin':
                if(cardRequest.isMoral) {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.BUSINESS_SHAREHOLDERS
                    });
                    router.push({name: "businessShareholders"});
                } else {
                    dispatch('updateOnboardingStep', {
                        stepOnbng: ONBOARDING_STEPS.SECURITY_MEASURES
                    });
                    router.push({name: "businessSecurityMeasures"});
                }
                break;
            case 'businessConfirmPin':
                dispatch('updateOnboardingStep', {
                    stepOnbng: ONBOARDING_STEPS.CREATE_NIP
                });
                router.push({name: "businessNewPin"});
                break;
            case 'selectAccountBusinessMultiple':
                router.push({name: "selectSchemaBusinessMultiple"});
                break;
            case 'selectSchemaBusinessMultiple':
                router.push({name: "createNewAccount"});
                break;
            case 'businessOperatesMultiple':
                router.push({name: "businessStartMultiple"});
                break;
            case 'businessDocumentsMultiple':
                router.push({name: "businessOperatesMultiple"});
                break;
            case 'businessDataMultiple':
                router.push({name: "businessDocumentsMultiple"});
                break;
            case 'businessFiscalDataMultiple':
                router.push({name: "businessDataMultiple"});
                break;
            case 'businessFiscalAddressMultiple':
                router.push({name: "businessFiscalDataMultiple"});
                break;
            case 'businessFiscalComAddressMultiple':
                router.push({name: "businessFiscalAddressMultiple"});
                break;
            case 'businessShareholdersMultiple':
                router.push({name: "businessFiscalComAddressMultiple"});
                break;
            case 'selectCreateOrgBusinessMultiple':
                router.push({name: "selectAccountBusinessMultiple"});
                break;
            case 'businessStartMultiple':
                _checkRedirectBusinessStart(commit, this.getters, cardRequest);
                break;
            case 'usagePoliciesBusinessMultiple':
                router.push({name: "selectCreateOrgBusinessMultiple"});
                break;
            case 'businessAddInfoMultiple':
                if(policiesAccepted) {
                    // Skip policies page
                    router.push({name: "selectCreateOrgBusinessMultiple"});
                } else {
                    router.push({name: "usagePoliciesBusinessMultiple"});
                }
                break;
            default:
                router.back();
                break;

        }
    },
    async getRequestCard({commit, dispatch}, id, updateLevel) {
        const response = await CardApi.getCardRequestById(id).catch(catchError);

        if (response.data && !response.data.error && response.data.object) {

            const cardRequest = response.data.object;
                commit(SET_SELECT_ACCOUNT, cardRequest.cardTemplate ? cardRequest.cardTemplate.name : "");
                if (cardRequest.accountType === 'PERSONAL') {
                    commit(SET_TYPE_ACCOUNT, true);
                    if (updateLevel) {
                        dispatch(`putLevel`, cardRequest.information_level)
                    }
                } else {
                    dataForBusiness(dispatch, commit, cardRequest, updateLevel);
                }

                commit(SET_CARD_REQUEST, cardRequest);
                if (response.data.object && cardRequest.status === 'CANCELLED') {
                    commit(SET_ERROR_REQUEST_CARD, true);
                } else {
                    commit(SET_ERROR_REQUEST_CARD, false);
                }
                EventBus.$emit(`${STORE}.${LOAD_CARD_REQUEST}`, cardRequest);
                return true
        }

        return false;
    },
    async getProvisionalCards({commit, dispatch}, updateLevel = true) {
        commit(SET_OBJECT_ERROR_REQUEST_CARD, '');
        const response = await CardApi.findProvisionalCards().catch(catchError);
        if (response.data && !response.data.error && response.data.object) {
            const cardRequest = response.data.object;
            commit(SET_SELECT_ACCOUNT, cardRequest.cardTemplate ? cardRequest.cardTemplate.name : "");
            if (cardRequest.accountType === 'PERSONAL') {
                commit(SET_TYPE_ACCOUNT, true);
                if (updateLevel) {
                    dispatch(`putLevel`, cardRequest.information_level)
                }
            } else {
                dataForBusiness(dispatch, commit, cardRequest, updateLevel);
            }

            commit(SET_CARD_REQUEST, cardRequest);
            if (response.data.object && cardRequest.status === 'CANCELLED') {
                commit(SET_ERROR_REQUEST_CARD, true);
            } else {
                commit(SET_ERROR_REQUEST_CARD, false);
            }
            EventBus.$emit(`${STORE}.${LOAD_CARD_REQUEST}`, cardRequest);
            return true
        }
        return false;
    },
    async saveCardRequest({commit, dispatch}, params) {
        const response = await CardApi.saveCardRequest(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_SELECT_ACCOUNT, params.name);
            commit(SET_CARD_REQUEST, response.data.object);

            if (response.data.object && response.data.object.status === 'CANCELLED') {
                commit(SET_ERROR_REQUEST_CARD, true);
                commit(SET_OBJECT_ERROR_REQUEST_CARD, response.data.object);
            } else {
                commit(SET_ERROR_REQUEST_CARD, false);
            }
            dispatch(`putLevel`, response.data.object.information_level);

            // commit(SET_LEVEL, response.data.object.information_level);
            return true
        }

        commit(SET_ERROR_REQUEST_CARD, true);
        if(response.data) {
            commit(SET_OBJECT_ERROR_REQUEST_CARD, response.data.object);
        }
        return false;
    },
    async saveCardTemplateRequestForBusiness({commit, dispatch}, params) {
        const response = await CardApi.saveCardTemplateRequestForBusiness(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_SELECT_ACCOUNT, params.name);
            commit(SET_CARD_REQUEST, response.data.object);
            if (response.data.object && response.data.object.status === 'CANCELLED') {
                commit(SET_ERROR_REQUEST_CARD, true);
            } else {
                commit(SET_ERROR_REQUEST_CARD, false);
            }
            let steps = [];
            if(params.isMultipleAccount) {
                steps = response.data.object.isMoral ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC';

            } else {
                steps = response.data.object.isMoral ? 'BUSINESS_MORAL' : 'BUSINESS_PHYSIC';
            }
            dispatch(`putLevel`, steps);

            // commit(SET_LEVEL, steps);
            return true
        }
        return false;
    },
    async saveCardRequestForBusiness({commit}, params) {
        const response = await CardApi.saveCardRequestForBusiness(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_SELECT_ACCOUNT, params.name);
            commit(SET_CARD_REQUEST, response.data.object);

            if (response.data.object && response.data.object.status === 'CANCELLED') {
                commit(SET_ERROR_REQUEST_CARD, true);
                commit(SET_OBJECT_ERROR_REQUEST_CARD, response.data.object);
            } else {
                commit(SET_ERROR_REQUEST_CARD, false);
            }
            commit(SET_TYPE_ACCOUNT_BUSINESS, response.data.object.isMoral ? 'MORAL' : 'PHYSIC');
            commit(SET_TYPE_ACCOUNT, false);

            // if(params.isMultipleAccount) {
            //     dispatch(`putLevel`, response.data.object.isMoral ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC');
            // }

            return true
        }

        commit(SET_ERROR_REQUEST_CARD, true);
        if(response.data) {
            commit(SET_OBJECT_ERROR_REQUEST_CARD, response.data.object);
        }
        return false;
    },
    async requestCard({commit, getters}, params) {
        params.cardRequestId = getters[GET_CARD_REQUEST]._id;
        const cardTemplate = getters[GET_CARD_REQUEST].cardTemplate;
        // params.cardTemplateId = getters[GET_CARD_REQUEST].cardTemplate._id;
        params.cardTemplateId = cardTemplate._id;
        const response = await CardApi.requestCard(params).catch(catchError);
        if (response.data && !response.data.error) {
            if (response.data.object.status === 'CANCELLED') {
                commit(SET_ERROR_REQUEST_CARD, true);
            } else {
                commit(SET_ERROR_REQUEST_CARD, false);
            }
            commit(SET_CARD_REQUEST, response.data.object);
            if (response.data.object && response.data.object.status === 'CANCELLED') {
                commit(SET_ERROR_REQUEST_CARD, true);
            } else {
                commit(SET_ERROR_REQUEST_CARD, false);
            }
        } else {
            if(response.data.object) {
                commit(SET_CARD_REQUEST, response.data.object);
            }
            commit(SET_ERROR_REQUEST_CARD, true);
        }
        return false;
    },
    updateOnboardingStep({getters}, params) {
        params.cardRequestId = getters[GET_CARD_REQUEST]._id
        CardApi.updateStepCardRequest(params).then(() => {
            // console.debug('Line 212 - updated');
            // dispatch('changeCurrentStep');
        })
    },
    checkCurrentStep({dispatch, getters}, route) {
        const level = getters[GET_LEVEL];
        const cardRequest = getters[GET_CARD_REQUEST];
        const user = this.getters[`${SessionStore}/${GET_USER}`];
        const matched = route.matched.some(r => r.name === 'multiplePersonal' || r.name === 'multipleBusiness');

        if(matched) {
            if (cardRequest.isMoral) {
                dispatch("checkCurrentStepMoral", route.name)
            } else {
                dispatch("changeCurrentStepPersonal", route.name)
            }
        } else {
            if(cardRequest) {
                if(_isCollaborator(user, cardRequest, level)) {
                    dispatch("checkCurrentStepCollab", route.name)
                } else if (cardRequest.isMoral) {
                    dispatch("checkCurrentStepMoral", route.name)
                } else {
                    dispatch("changeCurrentStepPersonal", route.name)
                }
            } else {
                if(user && user.stepOnboardingCollaborator !== undefined && user.stepOnboardingCollaborator !== null) {
                    dispatch("checkCurrentStepCollab", route.name)
                } else {
                    dispatch("checkCurrentStepMoral", route.name)
                }
            }

        }

    },
    checkCurrentStepCollab({getters, commit}, route) {
        switch (route) {
            default:
            case 'registerCollaborator':
                changStatusStep(commit, getters, 0);
                break;
            case 'nationalityCollaborator':
            case 'selectDocumentsCollaborator':
            case 'uploadDocumentsCollaborator':
            case 'personalInfoCollaborator':
            case 'residenceCollaborator':
            case 'occupationCollaborator':
                changStatusStep(commit, getters, 1);
                break;
            case 'addInfoCollaborator':
                changStatusStep(commit, getters, 2);
                break;
            case 'phoneVerificationCollaborator':
            case 'codeVerificationCollaborator':
            case 'successCodeCollaborator':
            case 'securityMeasuresCollaborator':
            case 'newPinCollaborator':
            case 'confirmPinCollaborator':
                changStatusStep(commit, getters, 3);
                break;
            case 'onbngWelcomeCollaborator':
                changStatusStep(commit, getters, 4);
                break;
        }
    },
    changeCurrentStepPersonal({getters, commit}, route) {
        const level = getters[GET_LEVEL];
        let to;
        switch (route) {
            case 'usagePolicies':
            case 'businessUsagePolicies':
            case 'nationality':
            case 'personalInfo':
            case 'residence':
            case 'occupation':
            case 'selectDocuments':
            case 'uploadDocuments':
            case 'businessStart':
            case 'businessOperates':
                changStatusStep(commit, getters, 1);
                break;
            case 'addInfo':
            case 'businessNationality':
            case 'businessPersonalInfo':
            case 'businessResidence':
            case 'businessOccupation':
            case 'businessSelectDocuments':
            case 'businessUploadDocuments':
            case 'businessDocuments':
            case 'businessFiscalData':
            case 'businessData':
            case 'businessFiscalAddress':
            case 'businessFiscalComAddress':
                changStatusStep(commit, getters, 2);
                break;
            case 'businessAddInfo':
            case 'businessShareholders':
                changStatusStep(commit, getters, 3);
                break;
            case 'phoneVerification':
            case 'codeVerification':
            case 'successCode':
            case 'securityMeasures':
            case 'newPin':
            case 'confirmPin':
                to = level >= 3 ? 3 : 2;
                changStatusStep(commit, getters, to);
                break;
            case 'businessPhoneVerification':
            case 'businessCodeVerification':
            case 'businessSuccessCode':
            case 'businessSecurityMeasures':
            case 'businessNewPin':
            case 'businessConfirmPin':
            case 'processingRequest':
            case 'successRequest':
                to = level >= 3 ? 4 : 3;
                changStatusStep(commit, getters, to);
                break;
            case 'businessProcessingRequest':
            case 'businessSuccessRequest':
            case 'beneficiariesRegister':
                to = level >= 3 ? 5 : 4;
                changStatusStep(commit, getters, to);
                break;
            case 'contract':
            case 'businessBeneficiaries':
            case 'sign':
                to = level >= 3 ? 6 : 5;
                changStatusStep(commit, getters, to);
                break;
            case 'businessContract':
            case 'businessSign':
            case 'onbngWelcome':
                to = 7;
                changStatusStep(commit, getters, to);
                break;
            case 'businessOnbngWelcome':
                changStatusStep(commit, getters, 8);
                break;
            case 'selectAccountMultiple':
                changStatusStep(commit, getters, 0, this.getters);
                break;
            case 'addInfoMultiple':
                _checkLevelAddInfo(commit, getters, this.getters);
                break;
            case 'selectDocumentsMultiple':
            case 'residenceMultiple':
            case 'occupationMultiple':
            case 'uploadDocumentsMultiple':
                changStatusStep(commit, getters, 1, this.getters);
                break;
            case 'processingRequestMultiple':
            case 'successRequestMultiple':
                _checkLevel(commit, getters, this.getters);
                break;
            case 'contractMultiple':
            case 'signMultiple':
                _checkLevelContract(commit, getters, this.getters);
                break;
            case 'onbngWelcomeMultiple':
                _checkOnbngWelcomeMultiple(commit, getters, this.getters);
                break;
            case 'selectSchemaBusinessMultiple':
            case 'selectAccountBusinessMultiple':
                changStatusStep(commit, getters, 0, this.getters);
                break;
            case 'businessStartMultiple':
                _checkBusinessStartMultiple(commit, getters, this.getters);
                // changStatusStep(commit, getters, 1, this.getters);
                break;
            case 'selectCreateOrgBusinessMultiple':
                changStatusStep(commit, getters, 1, this.getters);
                break;
            case 'businessSelectDocumentsMultiple':
            case 'businessUploadDocumentsMultiple':
            case 'businessResidenceMultiple':
            case 'businessOccupationMultiple':
                changStatusStep(commit, getters, 2, this.getters);
                break;
            case 'businessAddInfoMultiple':
                _checkBusinessAddInfoMultiple(commit, getters, this.getters);
                break;
            case 'businessProcessingRequestMultiple':
            case 'businessSuccessRequestMultiple':
                _checkBusinessProcessingRequestMultiple(commit, getters, this.getters);
                break;
            case 'businessContractMultiple':
            case 'businessSignMultiple':
                _checkBusinessContractMultiple(commit, getters, this.getters);
                break;
            case 'businessOnbngWelcomeMultiple':
                _checkBusinessOnbngWelcomeMultiple(commit, getters, this.getters);
                break;
            default:
                changStatusStep(commit, getters, 0, this.getters);
                break;
        }
    },
    checkCurrentStepMoral({dispatch, getters, commit}, route) {
        switch (route) {
            case 'businessUsagePolicies':
            case 'businessStart':
                changStatusStep(commit, getters, 1);
                break;
            case 'selectSchemaBusinessMultiple':
            case 'selectAccountBusinessMultiple':
                changStatusStep(commit, getters, 0, this.getters);
                break;
            case 'businessStartMultiple':
            case 'businessOperatesMultiple':
            case 'businessDocumentsMultiple':
            case 'businessDataMultiple':
            case 'selectCreateOrgBusinessMultiple':
                changStatusStep(commit, getters, 1, this.getters);
                break;
            case 'businessFiscalDataMultiple':
            case 'businessFiscalAddressMultiple':
            case 'businessFiscalComAddressMultiple':
                changStatusStep(commit, getters, 2, this.getters);
                break;
            case 'businessShareholdersMultiple':
                changStatusStep(commit, getters, 3, this.getters);
                break;
            case 'businessProcessingRequestMultiple':
            case 'businessSuccessRequestMultiple':
                _checkBusinessProcessingRequestMultipleMoral(commit, getters, this.getters);
                break;
            case 'businessContractMultiple':
            case 'businessSignMultiple':
                _checkBusinessContractMultipleMoral(commit, getters, this.getters);
                break;
                case 'businessOnbngWelcomeMultiple':
                _checkBusinessOnbngWelcomeMultipleMoral(commit, getters, this.getters);
                break;
            case 'businessOperates':
            case 'businessOccupation':
            case 'businessSelectDocuments':
            case 'businessUploadDocuments':
            case 'businessDocuments':
            case 'businessFiscalData':
            case 'businessData':
            case 'businessFiscalAddress':
            case 'businessFiscalComAddress':
                changStatusStep(commit, getters, 2);
                break;
            case 'businessAddInfo':
            case 'businessShareholders':
                changStatusStep(commit, getters, 3);
                break;
            case 'businessPhoneVerification':
            case 'businessCodeVerification':
            case 'businessSuccessCode':
            case 'businessSecurityMeasures':
            case 'businessNewPin':
            case 'businessConfirmPin':
            case 'businessPersonalInfo':
            case 'businessNationality':
                changStatusStep(commit, getters, 4);
                break;
            case 'businessProcessingRequest':
            case 'businessSuccessRequest':
                changStatusStep(commit, getters, 5);
                break;
            case 'businessContract':
            case 'businessSign':
                changStatusStep(commit, getters, 7);
                break;
            case 'businessOnbngWelcome':
                changStatusStep(commit, getters, 8);
                break;
            default:
                dispatch("changeCurrentStepPersonal", route)
                // changStatusStep(commit, getters, 0);
                break;
        }
    },
    changeCurrentStep({getters, commit}) {
        const cardRequest = getters[GET_CARD_REQUEST];

        if (cardRequest) {
            const accountType = cardRequest.accountType;
            const isMoral = cardRequest.isMoral;

            if (accountType === 'BUSINESS') {
                if (isMoral) {
                    switch (cardRequest.stepOnboarding) {
                        case ONBOARDING_STEPS.BUSINESS_START:
                            changStatusStep(commit, getters, 1);
                            break;
                        case ONBOARDING_STEPS.NATIONALITY:
                        case ONBOARDING_STEPS.UPLOAD_DOCUMENTS:
                        case ONBOARDING_STEPS.BUSINESS_INFO:
                        case ONBOARDING_STEPS.BUSINESS_FISCAL_DATA:
                        case ONBOARDING_STEPS.BUSINESS_FISCAL_ADDRESS:
                        case ONBOARDING_STEPS.BUSINESS_COMERCIAL_ADDRESS:
                            changStatusStep(commit, getters, 2);
                            break;
                        default:
                            changStatusStep(commit, getters, 0);
                            break;
                    }
                }
            } else {
                switch (cardRequest.stepOnboarding) {
                    case ONBOARDING_STEPS.NATIONALITY:
                    case ONBOARDING_STEPS.PERSONAL_INFO:
                    case ONBOARDING_STEPS.JOB:
                        changStatusStep(commit, getters, 1);
                        break;
                    case ONBOARDING_STEPS.PHONE_VERIFICATION:
                    case ONBOARDING_STEPS.CODE_VERIFICATION:
                    case ONBOARDING_STEPS.CREATE_NIP:
                    case ONBOARDING_STEPS.CONFIRM_NIP:
                    case ONBOARDING_STEPS.SECURITY_MEASURES:
                        changStatusStep(commit, getters, 2);
                        break;
                    case ONBOARDING_STEPS.BENEFICIARIES:
                        changStatusStep(commit, getters, 4);
                        break;
                    case ONBOARDING_STEPS.CONTRACT:
                        changStatusStep(commit, getters, 5);
                        break;
                    default:
                        changStatusStep(commit, getters, 0);
                        break;
                }
            }
        }
    },
    async getFilesDocuments({commit}, params) {
        const response = await GroupFilesApi.getFilesFromGroup(params).catch(catchError);
        if (response && response.data && !response.data.error) {
            commit(SET_DOCUMENTS, response.data.object);
            return true;
        }
        return false;
    },
    async putLevel({commit, getters}, step) {
        let numberLevel = 1;
        let steps = [];
        let arrayAux = [];

        switch (step) {
            case "LEVEL_ZERO":
                numberLevel = 0;
                steps = _.cloneDeep(stepsLevel0);
                break;
            case "LEVEL_TWO":
                numberLevel = 2;
                steps = _.cloneDeep(stepsLevel1and2);
                break;
            case "LEVEL_THREE":
                numberLevel = 3;
                steps = _.cloneDeep(stepsLevel3);
                break;
            case "BUSINESS_PHYSIC":
                numberLevel = 4;
                steps = _.cloneDeep(stepsLevelBusinessP);

                break;
            case "BUSINESS_MORAL":
                numberLevel = 5;
                steps = _.cloneDeep(stepsLevelBusinessM);

                break;
            case "MULTIPLE_PERSONAL":
                numberLevel = 6;
                arrayAux = getStepsForMultiplePersonal(this.getters, getters);
                steps = _.cloneDeep(arrayAux);

                break;
            case "MULTIPLE_BUSINESS_PHYSIC":
                numberLevel = 7;
                arrayAux = await getStepsForMultipleBusinessPhysic(this.getters);
                steps = _.cloneDeep(arrayAux);
                break;
            case "MULTIPLE_BUSINESS_MORAL":
                numberLevel = 8;
                arrayAux = getStepsForMultipleBusinessMoral(getters);
                steps = _.cloneDeep(arrayAux);
                break;
            default:
                numberLevel = 1;
                steps = _.cloneDeep(stepsLevel1and2);

                break;
        }

        commit(SET_LEVEL, numberLevel);
        commit(SET_CURRENT_STEPS, steps);
    },
    async checkAcceptedPolicies({getters, commit}) {
        const cardRequest = getters[GET_CARD_REQUEST];
        const policiesAccepted = await DocumentsApi.checkIfPoliciesAlreadyAccepted(cardRequest?._id)
            .then(response => response.data.object)
            .catch(() => false);
        commit(SET_POLICIES_ACCEPTED, policiesAccepted);
        return policiesAccepted;
    },
    async sendRequestCard({dispatch}, params = {}) {
        const cardRequest = getters[GET_CARD_REQUEST];
        const location = getters[GET_LOCATION];

        dispatch('updateOnboardingStep', {
            stepOnbng: ONBOARDING_STEPS.WAIT_REQUEST,
        });

        return dispatch('requestCard', {
            provider: "PAGANDITO",
            longitud: location.longitud,
            latitud: location.latitud,
            isMoral: !!cardRequest.isMoral,
            ...params,
        });
      },
    [LOAD_GROUP_FILES]: async ({commit}, params) => {
        const response = await GroupFilesApi.getGroupFileOnbng(params).catch(catchError);
        if (response && response.data && !response.data.error) {
            commit(SET_GROUP_FILES, response.data.object);
            return response.data.object;
        }
        return [];
    },
    [LOAD_GIROS]: async ({commit}) => {
        const response = await GiroApi.all().catch(catchError);
        if (response && response.data && !response.data.error) {
            commit(SET_GIROS, response.data.object);
            return true;
        }
        return false;
    },
    [LOAD_COUNTRIES]: async ({commit}) => {
        const response = await CountryApi.getActiveCountries().catch(catchError);
        if (response && response.data && !response.data.error) {
            commit(SET_COUNTRIES, response.data.object);
            return true;
        }
        return false;
    },
    /**
     * Función para mandar continuar el onboarding a corregir el request card
     * @param commit
     * @param getters
     * @param params
     * @return {Promise<Route>}
     */
    [GO_TO_ROUTE]: async ({commit, getters, dispatch}, params) => {
        const needToGo = getters[GET_GO_TO_WAIT];
        if (needToGo) {
            const location = getters[GET_LOCATION];
            commit(SET_GO_TO_WAIT, false);
            const cardRequest = getters[GET_CARD_REQUEST]
            const level = getters[GET_LEVEL];
            if(level === 0) {
                await usersApi.updateStepOnbCollab({onbStep: 'WELCOME' });

                return router.push({name: "processingRequestCollaborator"});
            }
            dispatch("requestCard", {
                provider: "PAGANDITO",
                longitud: location.longitud,
                latitud: location.latitud,
                isMoral: cardRequest.isMoral
            })
            if (cardRequest.accountType === 'BUSINESS') {
                return router.push({name: "businessProcessingRequest"});
            }
            return router.push({name: "processingRequest"});
        }
        return router.push({name: params});
    }
};

const mutations = {
    [SET_TYPE_ACCOUNT]: (state, data) => {
        state.isPersonalAccount = data
    },
    [SET_TYPE_ACCOUNT_BUSINESS]: (state, data) => {
        state.businessType = data;
    },
    [SET_CURRENT_PCT]: (state, data) => {
        state.completedPercent = data
    },
    [SET_LEVEL]: (state, data) => {
        state.level = data;
    },
    [SET_AUX_LEVEL]: (state, data) => {
        state.levelAux = data;
    },
    [SET_CURRENT_STEPS]: (state, data) => {
        state.steps = data;
    },
    [SET_SELECT_ACCOUNT]: (state, data) => {
        state.accountName = data;
    },
    [SET_CARD_REQUEST]: (state, data) => {
        state.cardRequest = data;
    },
    [SET_GROUP_FILES]: (state, data) => {
        state.groupFiles = data;
    },
    [SET_DOCUMENTS]: (state, data) => {
        state.documentsGroupFiles = data;
    },
    [SET_ERROR_REQUEST_CARD]: (state, data) => {
        state.errorCardRequest = data;
    },
    [SET_OBJECT_ERROR_REQUEST_CARD]: (state, data) => {
        state.objectErrorCardRequest = data;
    },
    [SET_LOCATION]: (state, data) => {
        state.location = data;
    },
    [SET_GIROS]: (state, data) => {
        state.giros = data;
    },
    [SET_COUNTRIES]: (state, data) => {
        state.countries = data;
    },
    [SET_GO_TO_WAIT]: (state, data) => {
        state.goToWaitRequest = data;
    },
    [SET_CONTRACT_INDEX]: (state, data) => {
        state.contractIndex = data;
    },
    [SET_CREATE_NEW_ORG]: (state, data) => {
        state.createNewOrg = data;
    },
    [SET_POLICIES_ACCEPTED]: (state, data) => {
        state.policiesAccepted = data;
    },
    [SET_SKIPPED_DYNAMIC]: (state, data) => {
        state.skippedDynamic = data;
    },
    [SET_ONB_DYNAMIC_COLLAB]: (state, data) => {
        state.onbDynamicCollab = data;
    },
};

export default {
    namespaced: true,
    state: {
        ...state
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    }
};
