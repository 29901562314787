import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'cards/api-v1';

export default {
    findProvisionalCards: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/find-provisional-cards`, params)
    },
    saveCardRequest: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/save-card-request-info`, params)
    },
    saveCardRequestForBusiness: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/save-card-request-info-for-business`, params)
    },
    saveCardTemplateRequestForBusiness: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/save-card-template-request-info-for-business`, params)
    },
    updateStepCardRequest: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/update-card-request-step`, params)
    },
    requestCard: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/request-card`, params)
    },
    saveOrgToCardRequest: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/add-org-to-cardrequest`, params)
    },
    generateCardRequestFromError: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/generate-new-card-request-info`, params)
    },
    updateUserWallet: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/update-wallet-user`, params)
    },
    getCardPAN: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/get-card/${params.id}/${params.provider}`, params)
    },
    getCardCVV: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/something-to-pay`, params)
    },
    getMovements: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/card-movements-user`, params)
    },
    getMovementsReport: (params) => {
        return axios({
            url: `${base.baseUrlWallet}/${namespace}/card-movements-user-report`,
            method: 'POST',
            headers: {
                'Content-type': "application/json"
            },
            responseType: 'arraybuffer',
            data: params
        });
    },
    getMovementById: (id) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/card-movement/${id}`)
    },
    getMovementsByDateRange: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/card-movements-by-date-range`, params)
    },
    getUserAccounts: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/user-cards`, params)
    },
    getAccountInfo: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/check-card-info`, params)
    },
    getAccountsWithBalance: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/get-accounts-with-balance`, params)
    },
    getAccountsRequestAdditionalWithBalance: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/get-accounts-request-additional-with-balance`, params)
    },
    blockCard: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/block-card`, params)
    },
    unblockCard: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/unblock-card`, params)
    },
    getOperationLimitsByAccount: (id) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/operational-limits-by-pagando-account/${id}`)
    },
    getCommissionForEvent: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/get-commission-for-account-type`, params)
    },
    transferFounds: (params, config) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/transfer-founds`, params, config)
    },
    getTransferList: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/transfers-list`, params)
    },
    downloadTransferReceipt: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/download-transfer-receipt`, params)
    },
    authorizeTransfer: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/authorize-transfer`, params)
    },
    rejectTransfer: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/reject-transfer`, params)
    },
    requestPhysicalCard: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/request-physics`, params).then(({data: {object}}) => object);
    },
    addPhysicalCard: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/add-physics`, params).then(({data: {object}}) => object);
    },
    getCardRequestById: (id) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/get-card-requests-info-by-id/${id}`)
    },
    requestVirtualCard: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/request-virtual`, params);
    },
    assignVirtualCard: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/register-virtual-card`, params);
    },
    changeAliasCard: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/add-alias-to-card`, params);
    },
    cancelVirtualCard: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/request-cancelarion-virtual`, params);
    },
    cancelRequestCard: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/cancel-card-request`, params);
    },
    checkAccountBusiness: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/check-account-business`, params);
    },
    confirmReposition: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/confirm-reposition`, params);
    },
    checkNip: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/check-nip`, params).then(({data: {object}}) => object);
    },
    checkPinUnification: () => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/check-pin-unification`);
    },
    checkMigrationDynamicCVV: () => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/check-migration-dynamic-cvv`);
    },
    unifyPin: () => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/unify-pin`);
    },
    migrateCVV: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/migrate-dynamic-cvv`, params);
    },
}
