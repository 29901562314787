<template>
    <aside
        id="sidebar-right"
        class="sidebar-right"
        :class="[isSidebarRightOpen ? '': 'hide']">
        <button type="button"
                class="button-sidebar-right"
                :aria-label="$t('dashboard.sidebarRight.hideShow')"
                :name="$t('dashboard.sidebarRight.hideShow')"
                @click="toggleSidebarRight">
                <i aria-hidden="true" class="icon-arrow"></i>
        </button>
        <button type="button"
                class="button-sidebar-right hide"
                aria-label="Ocultar/Mostrar Menu Sidebar"
                name="Ocultar/Mostrar Menu Sidebar"
                @click="hideSidebarRight">
                <i aria-hidden="true" class="iconsax-arrow-arrow-right"></i>
        </button>
        <div class="sidebar-right-content">
            <span class="content-lg">
                <div class="account-selected">
                    <span>
                        <img
                            class="img-fluid"
                            :src="accountImage"
                            width="124"
                            height="77"
                            :alt="$t('dashboard.sidebarRight.accountAlt', {account: info.general.accountType.description})"
                        />
                    </span>
                    <span>
                        <small class="title-account-selected">
                             {{$t('dashboard.sidebarRight.myAccount')}}
                            <br/>
                            <strong>{{info.general.accountType.description}}</strong>
                        </small>
                    </span>
                </div>
                <div class="divider"></div>
                <div class="account-cards-swiper">
                    <h2>
                        {{$t('sideBar.myCards')}}
                        <span>{{swiperIndex + 1}} {{$t('general.de')}} {{ info.cards.length }}</span>
                    </h2>
                  <div class="myCards-swiper pb-0 mb-5">
                    <div class="swiper-container">
                        <carousel
                            ref="cardsSwiper"
                            :perPage="1"
                            :navigationEnabled="true"
                            :paginationEnabled="false"
                            navigationNextLabel="<i aria-hidden='true' class='iconsax-arrow-arrow-right'></i>"
                            navigationPrevLabel="<i aria-hidden='true' class='iconsax-arrow-arrow-left'></i>"
                            v-on:page-change="onChange"
                        >
                            <slide v-for="card in info.cards" :key="card._id">
                              <div class="swiper-slide">
                                <div class="inside-card">
                                  <!--       Virtual Card   -->
                                  <div v-if="card.manufacturingType === 'VIRTUAL'" class="card-img">
                                    <img
                                        :src="cardImage(card, 'front')"
                                        :alt="$t('sideBar.cardFront', { card: info.general.accountType.description})">
                                    <div class="virtual-info">
                                      <div class="card-number">
                                          {{formatPan(card)}}
                                          <button class="copy-number" @click="copyNoCard(card)">
                                              <i aria-hidden="true" class="banana banana-copy"></i>
                                          </button>
                                      </div>
                                      <div class="bottom-info">
                                          <div class="exp-date">
                                              <div>
                                                  <span>{{ $t('sideBar.expDate') }}</span><br>
                                                  {{card.exp_month}}/{{card.exp_year}}
                                              </div>
<!--                                              <div>-->
<!--                                                  <span v-if="loadingCvvs[card._id]"><LoadingSimple-->
<!--                                                      clazz="small-load no-text"/></span>-->
<!--                                                  <div v-else-if="cvvs[card._id]">-->
<!--                                                    <span>{{ $t('sideBar.cvc.label') }}</span><br>-->
<!--                                                    {{ cvvs[card._id] }}-->
<!--                                                  </div>-->
<!--                                                  <button-->
<!--                                                      v-else-->
<!--                                                      type="button"-->
<!--                                                      class="button-nip button xs button-filled button-primary"-->
<!--                                                      @click="requestCVV(card._id)"-->
<!--                                                  >-->
<!--                                                  {{ $t('sideBar.cvc.action') }}-->
<!--                                                  </button>-->
<!--                                              </div>-->
<!--                                              <div-->
<!--                                                  v-if="!loadingCvvs[card._id] && dynamicCvvs[card._id] && cvvs[card._id]">-->
<!--                                                <span>{{ $t('sideBar.cvc.available') }}:</span><br>-->
<!--                                                <div class="d-flex f-14 f-bold">-->
<!--                                                  <i aria-hidden="true" class="iconsax-time-clock mr-1"></i>-->
<!--                                                  {{ countdownLabel }}-->
<!--                                                </div>-->
<!--                                              </div>-->
                                          </div>
                                      </div>
                                    </div>
                                  </div>
                                  <!--       Virtual Card   -->

                                  <!--       PHYSIC Card   -->
                                  <div v-if="card.manufacturingType === 'PHYSIC'" class="flip-card"
                                       :class="{ 'flipped': flippedCards[card._id] }">
                                    <div class="flip-card-inner">
                                        <div class="flip-card-front">
                                            <div class="card-img">
                                                <img
                                                    :src="cardImage(card, 'front')"
                                                    :alt="$t('sideBar.cardFront', { card: info.general.accountType.description})">
                                            </div>
                                        </div>
                                        <div class="flip-card-back">
                                            <div class="back-card">
                                                <div class="card-img">
                                                  <img
                                                      :src="cardImage(card, 'back')"
                                                      :alt="$t('sideBar.cardBack', { card: info.general.accountType.description})">
                                                  <div class="card-number">
                                                    {{formatPan(card)}}
                                                    <button class="copy-number" @click="copyNoCard(card)">
                                                      <i aria-hidden="true" class="banana banana-copy"></i>
                                                    </button>
                                                  </div>
                                                  <div class="exp-date">
                                                    <div>
                                                      <span>{{$t('sideBar.expDate')}}</span><br>
                                                      {{card.exp_month}}/{{card.exp_year}}
                                                    </div>
<!--                       Se comenta nip para que el padre de la cuenta no vea el nip del usuario de la tarjeta   -->
<!--                                                    <template>-->
<!--                                                      <span v-if="loadingNips[card._id]">-->
<!--                                                        <LoadingSimple clazz="small-load no-text"/>-->
<!--                                                      </span>-->
<!--                                                      <div v-else-if="nips[card._id]">-->
<!--                                                        <span>-->
<!--                                                          {{$t('sideBar.nip.label')}}-->
<!--                                                        </span><br>-->
<!--                                                        {{nips[card._id]}}-->
<!--                                                      </div>-->
<!--                                                      <button-->
<!--                                                          v-else-->
<!--                                                          type="button"-->
<!--                                                          class="button-nip button xs button-filled button-primary"-->
<!--                                                          @click="requestNIP(card._id)"-->
<!--                                                      >-->
<!--                                                        {{$t('sideBar.nip.action')}}-->
<!--                                                      </button>-->
<!--                                                    </template>-->
                                                  </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                  </div>
                                  <!--       PHYSIC Card   -->

                                  <div class="alias">{{ info.general.alias }}</div>
                                  <button
                                      v-if="card.manufacturingType === 'PHYSIC'"
                                      class="flip-btn"
                                      @click="toggleFlip(card)">
                                    {{
                                      flippedCards[card._id] ? $t('sideBar.seeFront') : $t('sideBar.seeReverse')
                                    }}
                                  </button>
                                </div>
                              </div>
                            </slide>
                        </carousel>
                    </div>
                  </div>
                </div>
                <div class="account-options">
                    <SidebarRightLink
                        clazz="warning"
                        icon="iconsax-security-lock"
                        :titleText="$t('dashboard.sidebarRight.block.title')"
                        :text="$t('dashboard.sidebarRight.block.text')">
                        <BasicSwitch
                            :disabled="!canAuthorizeCorporativeAccounts"
                            clazz="w-fit"
                            idInput="switch-block-card"
                            :switchValue="isBlocked"
                            ref="blockSwitch"
                            @onBasicSwitchChange="onBlockCard"
                        ></BasicSwitch>
                    </SidebarRightLink>
                </div>
            </span>
            <!-- Responsive Content -->
            <span class="content-xs">
                <div class="account-selected">
                    <span>
                        <img
                            class="img-fluid"
                            :src="accountImage"
                            width="50"
                            height="30"
                            :alt="$t('dashboard.sidebarRight.accountAlt', {account: info.general.accountType.description})"
                        />
                    </span>
                    <span>
                        <small class="title-account-selected">
                            {{$t('dashboard.sidebarRight.myAccount')}}
                            <br/>
                            <strong>{{info.general.accountType.description}}</strong>
                        </small>
                    </span>
                </div>
                <div class="divider"></div>
                <div class="account-options">
                    <SidebarRightLink
                        :onlyIcon="true"
                        :isRouter="false"
                        clazz="warning"
                        icon="iconsax-security-lock"
                        url=""
                        @makeClic="onBlockCard"
                        :ariaLabel="$t('dashboard.sidebarRight.block.title')">
                    </SidebarRightLink>
                </div>
            </span>
        </div>
    </aside>
</template>

<script>
import BasicSwitch from "@/components/BasicSwitch.vue";
import SidebarRightLink from "@/components/SidebarRightLink.vue";
import {Carousel, Slide} from 'vue-carousel';

import { mapGetters } from "vuex";
import moment from 'moment';

import { imageDownloadLink } from '@/utils/utils';
import { CARD_DEFAULT_IMG } from "@/enums/defalutCardImg.enum";

import {
  STORE as sessionStore,
  GET_ACCOUNT,
  CHECK_PERMISSION,
} from "@/store/modules/session/session.types";

export default {
    name: 'CorporateSidebarRight',
    components: {
      Carousel,
      Slide,
        BasicSwitch,
        SidebarRightLink,
    },
    props: {
        info: Object,
    },
    data () {
        return {
          flippedCards: {},
            // Swiper
            myCardsSwiperOptions: {
                resizeObserver: true,
                updateOnWindowResize: true,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                slidesPerView: 1,
                centeredSlides: true,
                spaceBetween: -170,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                breakpoints: {
                    768: {
                        allowTouchMove: false,
                        spaceBetween: -230,
                    }
                },
            },
            swiperCard: {
                resizeObserver: true,
                updateOnWindowResize: true,
                observer: true,
                observeParents: true,
                observeSlideChildren: true,
                allowTouchMove: false,
                centeredSlides: true,
                loop: true,
                effect: 'flip',
                grabCursor: false,
                navigation: {
                    nextEl: ".change-view-btn",
                },
            },
            //
            window: {
                width: 0
            },
            isSidebarRightOpen: true,
            swiperIndex: 0,
            nips: {},
            loadingNips: {},
            cvvs: {},
            loadingCvvs: {},
            dynamicCvvs: {},
            intervalCodeTime: 0,
            countdownLabel: 0
        }
    },
    computed: {
        ...mapGetters(sessionStore, {
            access: GET_ACCOUNT
        }),
        canAuthorizeCorporativeAccounts() {
            const checkPermission = this.$store.getters[`${sessionStore}/${CHECK_PERMISSION}`];
            const productType = this.access?.account?.accountType?.productType;
            return productType === 'CLIENTS_ACCOUNT' && checkPermission('AUTHORIZE', 'CORPORATIVE_ACCOUNTS');
        },
        accountImage() {
            const defaultImage = CARD_DEFAULT_IMG.pagandoExplore;
            if(!this.info.general?.accountType?.template) {
                return defaultImage;
            }

            if(this.info.general.accountType.template.image) {
                return imageDownloadLink(this.info.general.accountType.template.image);
            }

            switch (this.info.general.accountType.template.product) {
                case "EXPLORE":
                    return CARD_DEFAULT_IMG.pagandoExplore;
                case "EXPLORE_PLUS":
                    return CARD_DEFAULT_IMG.pagandoExplorePlus;
                case "NIGHT":
                    return CARD_DEFAULT_IMG.pagandoNight;
                case "BUSINESS":
                    return CARD_DEFAULT_IMG.pagandoBusiness;
                case "BUSINESS_PRO":
                    return CARD_DEFAULT_IMG.pagandoBusinessPro;
                default:
                    return defaultImage;
            }
        },
        currentCard() {
            return this.info.cards[this.swiperIndex];
        },
      isBlocked() {
        return this.currentCard?.status !== 'ACTIVE' && this.currentCard?.status !== 'VERIFIED';
      },
    },
    methods: {
      onChange(index) {
        this.swiperIndex = index;
        this.$refs.blockSwitch.forceValue(this.isBlocked);
      },
      async toggleFlip(card) {
        const id = card._id;
        this.$set(this.flippedCards, id, !this.flippedCards[id]);
        // await this.onCardFlipped(card);
      },
        hideSidebarRight () {
            document.getElementById('sidebar-right').classList.add('hide');
        },
        toggleSidebarRight () {
            document.querySelector('.sidebar-right').classList.toggle('small');
        },
        handleResizeSidebarRight () {
            const sidebar = document.getElementById('sidebar-right');
            this.window.width = window.innerWidth;

            if (window.innerWidth >= 768) {
                sidebar.classList.add('small');
                this.isSidebarRightOpen = true;
            } else {
                sidebar.classList.remove('small');
                this.isSidebarRightOpen = false;
            }

            if (window.innerWidth >= 1200) {
                sidebar.classList.remove('small');
            }
        },
        cardImage({template, manufacturingType}, side) {
            if(template?.image && side === 'front') {
                return imageDownloadLink(template.image);
            }

            let product = 'EXPLORE';
            let type;

            const validProducts = ['EXPLORE', 'EXPLORE_PLUS', 'NIGHT', 'BUSINESS', 'BUSINESS_PRO'];
            const validTypes = ['VIRTUAL', 'PHYSIC'];
            const images = {
                EXPLORE: {
                    VIRTUAL: {front: CARD_DEFAULT_IMG.pagandoExploreVirtualFront, back: CARD_DEFAULT_IMG.pagandoExploreVirtualBack},
                    PHYSIC: {front: CARD_DEFAULT_IMG.pagandoExploreFisicaFront, back: CARD_DEFAULT_IMG.pagandoExploreFisicaBack},
                },
                EXPLORE_PLUS: {
                    VIRTUAL: {front: CARD_DEFAULT_IMG.pagandoExplorePlusVirtualFront, back: CARD_DEFAULT_IMG.pagandoExplorePlusVirtualBack},
                    PHYSIC: {front: CARD_DEFAULT_IMG.pagandoExplorePlusFisicaFront, back: CARD_DEFAULT_IMG.pagandoExplorePlusFisicaBack},
                },
                NIGHT: {
                    VIRTUAL: {front: CARD_DEFAULT_IMG.pagandoNightVirtualFront, back: CARD_DEFAULT_IMG.pagandoNightVirtualBack},
                    PHYSIC: {front: CARD_DEFAULT_IMG.pagandoNightFisicaFront, back: CARD_DEFAULT_IMG.pagandoNightFisicaBack},
                },
                BUSINESS: {
                    VIRTUAL: {front: CARD_DEFAULT_IMG.pagandoBusinessVirtualFront, back: CARD_DEFAULT_IMG.pagandoBusinessVirtualBack},
                    PHYSIC: {front: CARD_DEFAULT_IMG.pagandoBusinessFisicaFront, back: CARD_DEFAULT_IMG.pagandoBusinessFisicaBack},
                },
                BUSINESS_PRO: {
                    VIRTUAL: {front: CARD_DEFAULT_IMG.pagandoBusinessProVirtualFront, back: CARD_DEFAULT_IMG.pagandoBusinessProVirtualBack},
                    PHYSIC: {front: CARD_DEFAULT_IMG.pagandoBusinessProVirtualFront, back: CARD_DEFAULT_IMG.pagandoBusinessProVirtualBack},
                },
            };

            if(validProducts.includes(template?.product)) {
                product = template.product;
            }

            if(validTypes.includes(manufacturingType)) {
                type = manufacturingType;
            }
            return images[product][type][side];
        },
        async onBlockCard() {
            if(this.canAuthorizeCorporativeAccounts) {
                this.$emit('blockCard', this.currentCard);
            }
        },
        copyNoCard(card) {
            if (navigator && navigator.clipboard) {
                navigator.clipboard.writeText(card.pan.replace(/\s/g, ''));
                this.displayNotificationSuccess(this.$i18n.t('sideBar.noCopy'));
            }
        },
        formatPan({pan}) {
            return pan.replace(/(\d{4})(?=\d)/g, '$1  ');
        },
        requestNIP(cardId) {
            this.$set(this.loadingNips, cardId, true);
            this.$emit('requestNIP', cardId);
        },
        setCardNIP(cardId, nip) {
            this.$set(this.nips, cardId, nip);
            this.$set(this.loadingNips, cardId, false);
        },
        requestCVV(cardId) {
            this.$set(this.loadingCvvs, cardId, true);
            this.$emit('requestCVV', cardId);
        },
        setCardCVV(cardId, cvv) {
            this.$set(this.cvvs, cardId, cvv);
            this.$set(this.loadingCvvs, cardId, false);
        },
        defineDynamicCVV(cardId, isDynamic) {
            this.$set(this.dynamicCvvs, cardId, isDynamic);
        },
        stopInterval() {
            if(this.intervalCodeTime) {
                clearInterval(this.intervalCodeTime);
                this.intervalCodeTime = null;
                this.countdownLabel = null;
            }
        },
        /**
         * Función que permite programar la expiración del cvc dinámico
         * en base a tiempo obtenido
         * @param {*} expirationTime - Tiempo de expiración (timestamp)
         * @param {*} cardId - Identificador de tarjeta que tiene el cvc dinámico
         */
        scheduleCvvExpiration(expirationTime, cardId) {
            const vueInstance = this;
            this.stopInterval();

            // Obtener la fecha de expiración del cvc
            const cvvExpirationTime = moment(expirationTime);

            const updateLabel = () => {
                // Se obtiene la diferencia actual con respecto al tiempo de expiración y se actualiza label
                const diff = cvvExpirationTime.diff(moment());
                vueInstance.countdownLabel = moment.utc(diff).format('mm:ss');

                // Tiempo de expiración llega
                if(diff <= 0) {
                    vueInstance.stopInterval();
                    vueInstance.$set(vueInstance.cvvs, cardId, null);
                    vueInstance.$set(vueInstance.dynamicCvvs, cardId, false);
                }
            };
            updateLabel();
            this.intervalCodeTime = setInterval(updateLabel, 100);
        },
    },
    mounted () {
        this.handleResizeSidebarRight();
        window.addEventListener('resize', this.handleResizeSidebarRight);
    },
    destroyed () {
        window.addEventListener('resize', this.handleResizeSidebarRight);
    },
}
</script>
