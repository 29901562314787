<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/wallet/depositar-a-cuenta"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('deposits.byCash.returnToDeposit')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('deposits.byCash.title')"></FloatingTitleLayout>
                        </div>
                        <div class="col-12 col-lg-6 cards-same-height" v-if="!loading">
                            <CardSimple
                                :title="$t('deposits.byCash.referenceDeposit')"
                                :subtitle="$t('deposits.byCash.referenceDepositSubtitle')">
                                <div class="establishment-reference">
                                    <img class="img-fluid" :src="establishment.src"
                                    width="305" height="131" alt="Imagen establecimiento"/>
                                    <div class="code">
                                        <div class="text">
                                            {{$t('deposits.byCash.noReferenceDeposit')}}
                                        </div>
                                        <barcode class="img-fluid" :value="account.account.referenceDepositProvider" :display-value="false">
                                            {{$t('deposits.errors.generateBarcode')}}
                                        </barcode>
                                        <div class="number">{{account.account.referenceDepositProvider}}</div>
                                    </div>
                                </div>
                                <button
                                    @click="$bvModal.show('modal-transfer')"
                                    type="button"
                                    class="button button-filled button-primary w-100 m-t-15">
                                    {{$t('deposits.byCash.sendEmail')}}
                                </button>
                                <b-alert class="alert-note m-t-30" show variant="info" v-if="commission > 0">
                                    <p class="text-container">
                                        <i
                                            aria-hidden="true"
                                            class="icon iconsax-emails-message-bold"
                                        ></i>
                                        <span v-html="$t('myAccount.cashWithdrawal.modals.warning2', {cost: commission})"></span>
                                    </p>
                                </b-alert>
                                <b-alert class="alert-note m-t-30" show variant="info" v-else>
                                    <p class="text-container">
                                        <i
                                            aria-hidden="true"
                                            class="icon iconsax-emails-message-bold"
                                        ></i>
                                        <span v-html="$t('deposits.byCash.withCommission')"></span>
                                    </p>
                                </b-alert>
                            </CardSimple>
                        </div>
                        <div class="col-12 col-lg-6 cards-same-height" v-if="!loading">
                            <CardSimple
                                :title="$t('deposits.byCash.howToDo')">
                                <ul class="checked-list m-b-30">
                                    <li class="f-14 w-100 max-w-initial c-text_title m-b-15" v-html="$t('deposits.byCash.step1', {name: establishment.name})"></li>
                                    <li class="f-14 w-100 max-w-initial c-text_title m-b-15" v-html="$t('deposits.byCash.step2')"></li>
                                    <li class="f-14 w-100 max-w-initial c-text_title m-b-15"
                                        v-html="$t('deposits.byCash.step3', {noReferenceLength: account.account.referenceDepositProvider.length})">
                                    </li>
                                    <li class="f-14 w-100 max-w-initial c-text_title m-b-15">
                                        {{$t('deposits.byCash.step4')}}
                                    </li>
                                    <li class="f-14 w-100 max-w-initial c-text_title m-b-15" v-html="$t('deposits.byCash.step5')">
                                    </li>
                                    <li class="f-14 w-100 max-w-initial c-text_title m-b-15" v-html="$t('deposits.byCash.step6')"></li>
                                </ul>
                            </CardSimple>
                        </div>
                        <LoadingBar v-if="loading" :loadingText="$t('deposits.byCash.loading')" ></LoadingBar>

                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>

        <!-- MODAL TRANSFERENCIA -->
        <b-modal
            id="modal-transfer"
            size="xs"
            hide-header
            hide-footer
            modal-class="modal-default">
            <button class="close-modal"
                    @click="closeSend()"
                    aria-label="Boton cerrar ventana"
                    name="Boton cerrar ventana">
                <i aria-hidden="true" class="banana banana-cross-normal"></i>
            </button>
            <div class="head" v-if="!successSendMail">
                <h2 class="modal-title">{{$t('deposits.byCash.modal.title')}}</h2>
            </div>
            <div class="body">
                <div class="vertical-center justify-content-center column-gap-50px m-b-30" v-if="!successSendMail">
                    <RadioInput
                        clazz="m-b-0"
                        idInput="accept"
                        :text="$t('deposits.byCash.modal.toMyMail')"
                        :currentValue="sendToMyEmail"
                        @selected="setSendMyselfEmail()"
                    ></RadioInput>
                    <RadioInput
                        clazz="m-b-0"
                        idInput="deny"
                        :text="$t('deposits.byCash.modal.toOtherMail')"
                        :currentValue="sendToOtherEmail"
                        @selected="setSendOtherEmail()"
                    ></RadioInput>
                </div>
                <div class="transfer-account-info w-100" v-if="sendToMyEmail && !successSendMail">
                    <div class="item max-w-initial">
                        <div class="item-container">
                            <div class="name f-14 m-t-0 m-b-0 poppins-font-semibold">
                                {{$t('deposits.byCash.modal.sendReferenceToMail')}}
                            </div>
                            <div class="name f-14 mt-0 poppins-font-bold c-info">
                                {{userEmail}}
                            </div>
                        </div>
                    </div>
                </div>
                <BasicInput v-if="!sendToMyEmail && !successSendMail"
                    required
                    inputId="input"
                    :label="$t('deposits.byCash.modal.email')"
                    v-model="email"
                    inputPlaceholder="Ingresa un correo">
                </BasicInput>
                <div v-if="successSendMail">
                    <StatusNote
                        :title="$t('deposits.byCash.modal.successSend')"
                        status="successs"
                        :text="$t('deposits.byCash.modal.successMessage', {email: this.emailFinal})"
                        :imgWidth="284"
                        :imgHeight="280"
                        :imgAlt="$t('deposits.byCash.modal.successSend')">
                    </StatusNote>
                    <button type="button" class="button button-filled button-primary w-100" @click="closeSend()">{{$t('deposits.byCash.modal.finalize')}}</button>
                </div>
            </div>
            <div class="footer" v-if="!successSendMail">
                <button type="button" class="button button-stroke button-primary" @click="closeSend()">{{$t('deposits.byCash.modal.cancel')}}</button>
                <button type="button" class="button button-filled button-primary"
                    :disabled="sendToOtherEmail && !validEmail()" @click="sendEmail()">{{$t('deposits.byCash.modal.send')}}</button>
            </div>
        </b-modal>

    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import RadioInput from "@/components/RadioInput.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import StatusNote from "@/components/StatusNote.vue";
    import EstablishmentsApi from "@/api/core/establishments.api";
    import {catchError} from "@/utils/utils";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import LoadingBar from "@/components/LoadingBar.vue";
    import CardApi from "@/api/wallet/card.api";
    import CashDepositApi from "@/api/wallet/cashDeposit.api";
    import {
            STORE as sessionStore,
            GET_ACCOUNT,
            GET_EMAIL_USER,
            GET_USER
        } from "@/store/modules/session/session.types";
    import {mapGetters} from "vuex";
    import {regexEmail} from "@/utils/regexUtils";

    export default {
        name: "CashDeposit",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            CardSimple,
            RadioInput,
            BasicInput,
            StatusNote,
            LoadingBar
        },
        data() {
            return {
                // Images
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
                establishment: {
                    src: ''
                },
                loading: false,
                commission: 0,
                sendToMyEmail: true,
                sendToOtherEmail: false,
                successSendMail: false,
                email: '',
                emailFinal: null
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                account: GET_ACCOUNT,
                userEmail: GET_EMAIL_USER,
                user: GET_USER
            })
        },
        methods: {
            setSendMyselfEmail() {
                this.sendToMyEmail = true;
                this.sendToOtherEmail = false
                this.email = null;
            },
            setSendOtherEmail() {
                this.sendToMyEmail = false;
                this.sendToOtherEmail = true
            },
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            async getEstablishment() {
                const id = this.$route.params.id;

                const res = await EstablishmentsApi.getById(id, {signedUrl: true}).catch(catchError);

                if(res && res.data && !res.data.error) {
                    this.establishment = res.data.object;
                } else {
                    this.toastErrorDefault(this.$i18n.t('deposits.errors.getEstablishment'));
                }

            },
            async getCommission(commission) {
                const res = await CardApi.getCommissionForEvent({
                    pagandoAccount: this.account.account._id,
                    commission: commission,
                    }).catch(catchError);

                let comission = 0;

                if(res && res.data && !res.data.error) {
                    comission = res.data.object;
                } else {
                    this.toastErrorDefault(this.$i18n.t('deposits.errors.getCommissions'));
                }

                return comission;
            },
            validEmail() {
                const regex = new RegExp(regexEmail);
                return regex.test(this.email);
            },
            closeSend() {
                this.email = null;
                this.emailFinal = null;
                this.successSendMail = false;
                this.sendToMyEmail = true;
                this.sendToOtherEmail = false;
                this.$bvModal.hide('modal-transfer');
            },
            async sendEmail() {
                if(this.sendToMyEmail) {
                    this.emailFinal = this.userEmail;
                } else {
                    this.emailFinal = this.email;
                }

                const id = this.$route.params.id;

                const response = await CashDepositApi.sendEmailReference({
                    establishment: id,
                    referenceNumber: this.account.account.referenceDepositProvider,
                    email: this.emailFinal,
                    userId: this.user._id
                }).catch(catchError);

                if(response && response.data && !response.data.error) {
                    this.successSendMail = true;
                } else {
                    this.successSendMail = false;
                    this.toastErrorDefault(this.$i18n.t('deposits.errors.sendMail'));

                }


            }
        },
        async mounted () {
            this.loading = true;

            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);

            this.commission = await this.getCommission('CASH_INFLOW');
            await this.getEstablishment();

            this.loading = false;
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
