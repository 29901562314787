<template>
  <div>
    <div id="" class="responsive-top-bar">
      <span>
        <button
          type="button"
          id="showLeftMenu"
          class="button-square xs button-filled button-primary"
          aria-label="Mostrar menú principal"
          name="Mostrar menú principal"
          @click="showMenu"
        >
          <i aria-hidden="true" class="iconsax-essential-menu"></i>
        </button>
      </span>
      <span>
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          width="114"
          height="25"
          alt="Pagando Logo"
        />
      </span>
      <span></span>
    </div>

    <CorporateSidebarMenu :clazz="[isMenuOpen ? 'show' : '']" @hideMenu="hideMenu" @clickHome="goToActivity"/>
    <div class="sidebar-menu-backdrop menu-principal"></div>

    <section id="main-view" class="p-r-0">
      <div class="page-container content-centered">
        <div>
          <div class="row">
            <div class="col-12">
              <FloatingTitleLayout
                :titleStrong="$t('movements.title')"
                :subtitle="$t('movements.subtitle')"
              >
              </FloatingTitleLayout>
            </div>
            <div class="col-12">
              <TitleBadge
                clazzContainer="p-0"
                :titleText="$t('movements.recentMovements')"
                :badgeText="totalMovements.toString()"
              >
                <div class="daterange-filter">
                  <label
                    class="f-12 c-text_info poppins-font-medium f-500 m-b-0"
                    >{{ $t("movements.filterBy") }}</label
                  >
                  <date-range-picker
                    ref="picker"
                    opens="right"
                    :showDropdowns="false"
                    :autoApply="true"
                    v-model="dateRangeComponentBtn"
                    :linked-calendars="false"
                    :ranges="false"
                    singleDatePicker="range"
                    @update="selectCalendar"
                    :locale-data="{
                      daysOfWeek: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
                      monthNames: [
                        'Enero',
                        'Febrero',
                        'Marzo',
                        'Abril',
                        'Mayo',
                        'Junio',
                        'Julio',
                        'Agosto',
                        'Septiembre',
                        'Octubre',
                        'Noviembre',
                        'Diciembre',
                      ],
                    }"
                    class=""
                  >
                    <!-- eslint-disable -->
                    <template v-slot:input="picker">
                      <span class="daterange-btn"
                        >{{ $t("movements.date") }}
                        <i
                          aria-hidden="true"
                          class="iconsax-arrow-direction-square-down"
                        ></i
                      ></span>
                      <!-- <span v-else>{{moment(dateRangeComponentBtn.startDate).format('ll')}} - {{moment(dateRangeComponentBtn.endDate).format('ll')}}</span> -->
                    </template>
                    <!-- eslint-enable -->
                  </date-range-picker>
                </div>
              </TitleBadge>

              <!-- <BasicInput
                clazz="m-b-15 noLabel panel-search"
                inputType="text"
                :inputPlaceholder="$t('movements.searchMovement')"
                inputClazz="icon-left"
                iconInput="banana-search-normal"
                v-model="search"
                @input="searchChange"
              ></BasicInput> -->

              <SearchBar
                :buttonText="$t('movements.downloadReport')"
                @routerPush="newCancelation()"
                :canCreate="true"
                ref="searchBar"
                @searchTextChange="searchChange"
                @onClickButton="downloadReport"
                iconButton="banana banana-download"
                :placeholder="$t('movements.searchMovement')"
              >
                <template
                    v-slot:extraContent
                    v-if="showDownloadMultiple"
                >
                  <button
                      class="button sm button-filled button-primary m-l-10"
                      @click="downloadMultiple()"
                      >
                      <i aria-hidden="true" class="banana banana-download"></i> Descargar comprobantes
                  </button>
                </template>
              </SearchBar>

              <CardSimple clazz="p-0" hideTitle>
                <div id="" class="basic-table">
                  <b-table-simple responsive table-variant="xl">
                    <b-thead>
                      <b-tr class="">
                        <b-th>
                          <BasicCheckbox
                            clazz="w-fit m-0-auto"
                            inputClass="m-0"
                            v-model="selectAll"
                            @input="checkboxAll"
                          ></BasicCheckbox>
                        </b-th>
                        <b-th> {{ $t("movements.table.description") }} </b-th>
                        <b-th class="text-center">
                          {{ $t("movements.table.folio") }}
                        </b-th>
                        <b-th class="text-center">
                          {{ $t("movements.table.date") }}
                        </b-th>
                        <b-th class="text-center">
                          {{ $t("movements.table.card") }}
                        </b-th>
                        <b-th class="text-right">
                          {{ $t("movements.table.amount") }}
                        </b-th>
                        <b-th></b-th>
                        <b-th></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody v-if="loading">
                      <b-tr class="noHover">
                        <b-td colspan="6">
                          <LoadingBar
                            clazz="p-t-50 p-b-50"
                            :loadingText="$t('movements.searching')"
                          ></LoadingBar>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                    <b-tbody v-if="movements.length === 0 && !loading">
                      <b-tr class="noHover">
                        <b-td colspan="6">
                          <EmptyState
                            clazz="p-0"
                            emptyStateDefault
                            :title="$t('movements.emptyState.title')"
                            :text="$t('movements.emptyState.subtitle')"
                            :imgUrl="imgEmptystateTable"
                            :imgWidth="390"
                            :imgHeight="320"
                            imgAlt="Sin movimientos"
                          >
                          </EmptyState>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                    <b-tbody v-if="movements.length > 0 && !loading">
                      <b-tr
                        class=""
                        v-for="(movement, index) in movements"
                        :key="index"
                      >
                        <b-td>
                          <BasicCheckbox
                            clazz="w-fit m-0-auto"
                            inputClass="m-0"
                            :index="index"
                            :value="movement.selected"
                            @input="checked => selectSpecificMovement(checked, movement)"
                          ></BasicCheckbox>
                        </b-td>
                        <b-td>
                          <MovementsList
                            :clazz="`${movement.color} list-notifications max-w-200px m-b-0 border-none`"
                            :icon="movement.icon"
                            :movConcept="movement.payConcept"
                            :movText="movement.movementDescription"
                          >
                          </MovementsList>
                        </b-td>
                        <b-td class="text-center">
                          <a @click="openModal(movement)" class="c-info hover">
                            <strong class="poppins-font-bold f-700"
                              ><u>{{ movement.fullKey }}</u></strong
                            >
                          </a>
                        </b-td>
                        <b-td class="text-center">
                          <strong class="poppins-font-bold f-700">{{
                            getDate(movement.date)
                          }}</strong>
                          <br />
                          {{ getHour(movement.date) }}
                        </b-td>
                        <b-td
                          v-if="checkTypeToShowCard(movement)"
                          class="text-center"
                        >
                          <strong class="poppins-font-bold f-700"
                            >**** {{ movement.card.last4 }}</strong
                          >
                          <br />
                          {{ movement.card.alias ? movement.card.alias : "-" }}
                        </b-td>
                        <b-td
                          v-if="!checkTypeToShowCard(movement)"
                          class="text-center"
                        >
                          -
                        </b-td>
                        <b-td class="text-right">
                          <strong
                            class="poppins-font-bold f-700"
                            v-if="
                              ((movement.type === 'COMPRA_DIGITAL' ||
                                movement.type === 'Compra' ||
                                movement.type === 'COMPRA_CONTACTLESS') &&
                                movement.contaMovementType !== 'CARGO') ||
                              ((movement.type === 'COMPRA_DIGITAL' ||
                                movement.type === 'Compra' ||
                                movement.type === 'COMPRA_CONTACTLESS') &&
                                movement.contaMovementType !== 'ABONO' &&
                                (movement.spentRewardPoints !== 0 || availablePoints < 100)) ||
                              (movement.type !== 'COMPRA_DIGITAL' &&
                                movement.type !== 'Compra' &&
                                movement.type !== 'COMPRA_CONTACTLESS')
                            "
                            :class="
                              movement.type === 'LOST_CHARGEBACK'
                                ? 'c-error'
                                : movement.contaMovementType === 'ABONO'
                                ? 'c-success'
                                : ''
                            "
                            >{{ movement.sign }}
                            {{ movement.amount | currency }}</strong
                          >
                        </b-td>
                        <b-td class="text-center">
                          <button
                            type="button"
                            class="button-square xs button-filled button-primary"
                            aria-label="Ver movimiento"
                            name="Ver movimiento"
                            @click="openModal(movement)"
                          >
                            <i
                              aria-hidden="true"
                              class="iconsax-security-eye"
                            ></i>
                          </button>
                        </b-td>
                        <b-td class="text-center">
                          <button
                            type="button"
                            class="
                              button-square
                              xs
                              button-stroke button-primary
                            "
                            aria-label="Descargar"
                            name="Descargar"
                            @click="downloadReceipt(movement.fullKey)"
                          >
                            <i
                              aria-hidden="true"
                              class="iconsax-content-document-download"
                            ></i>
                          </button>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </CardSimple>

              <div class="pagination-custom" v-if="totalMovements > 0">
                <span class="pagination-count">{{
                  $tc("movements.pagination", totalMovements, {
                    total: totalMovements,
                  })
                }}</span>
                <b-pagination
                  v-model="page"
                  :total-rows="totalMovements"
                  :per-page="perPage"
                  aria-controls="my-table"
                  next-text=""
                  prev-text=""
                  hide-goto-end-buttons
                  @change="pageChange"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <FooterPage></FooterPage>
    <vue-snotify></vue-snotify>

    <ModalDeposit
      :movementToShow="movementToShow"
      ref="modalDeposit"
    ></ModalDeposit>
    <ModalPurchase
      :movementToShow="movementToShow"
      ref="modalPurchase"
    ></ModalPurchase>
    <ModalCommision
      :movementToShow="movementToShow"
      ref="modalCommision"
    ></ModalCommision>
    <ModalConsultATM
      :movementToShow="movementToShow"
      ref="modalConsultATM"
    ></ModalConsultATM>
    <ModalWithdrawalATM
      :movementToShow="movementToShow"
      ref="modalWithdrawalATM"
    ></ModalWithdrawalATM>
    <ModalSPEI
      :movementToShow="movementToShow"
      ref="modalSPEI"
    ></ModalSPEI>
    <ModalTransfersPagando
      :movementToShow="movementToShow"
      ref="modalTransfersPagando"
    ></ModalTransfersPagando>
    <ModalRefund
      :movementToShow="movementToShow"
      ref="modalRefund"
    ></ModalRefund>
    <ModalClarification
      :movementToShow="movementToShow"
      ref="modalClarification"
    ></ModalClarification>
    <ModalPoints
      :movementToShow="movementToShow"
      ref="modalPoints"
    ></ModalPoints>
  </div>
</template>
<script>
// @ is an alias to /src
import DateRangePicker from "vue2-daterange-picker";
import TitleBadge from "@/components/TitleBadge.vue";
import BasicInput from "@/components/BasicInput.vue";
import CardSimple from "@/components/CardSimple.vue";
import EmptyState from "@/components/EmptyState.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import FooterPage from "@/components/FooterPage.vue";
import MovementsList from "@/components/MovementsList.vue";
import ModalSPEI from "@/components/modalsMovements/ModalSPEI.vue";
import ModalRefund from "@/components/modalsMovements/ModalRefund.vue";
import ModalPoints from "@/components/modalsMovements/ModalPoints.vue";
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import ModalDeposit from "@/components/modalsMovements/ModalDeposit.vue";
import ModalPurchase from "@/components/modalsMovements/ModalPurchase.vue";
import ModalCommision from "@/components/modalsMovements/ModalCommision.vue";
import ModalConsultATM from "@/components/modalsMovements/ModalConsultATM.vue";
import ModalWithdrawalATM from "@/components/modalsMovements/ModalWithdrawalATM.vue";
import ModalClarification from "@/components/modalsMovements/ModalClarification.vue";
import CorporateSidebarMenu from "@/components/corporative/CorporateSidebarMenu.vue";
import ModalTransfersPagando from "@/components/modalsMovements/ModalTransfersPagando.vue";
import CardApi from "@/api/wallet/card.api";
import BasicCheckbox from "@/components/BasicCheckbox.vue";
import SearchBar from "@/components/SearchBar.vue";

import MenuHandler from "@/mixins/menuHandler.mixin";

import CorporativeAccountsApi from "@/api/wallet/corporativeAccounts.api";
import RewardPointsApi from '@/api/wallet/rewardPoints.api';

import imgEmptystateTable from "@/assets/images/Emptystates/emptystate-table.svg";

import moment from "moment";
import { catchError } from "@/utils/utils";

const iconMoneyMoneyRecive = "iconsax-money-receive";
const iconMoneyMoneySend = "iconsax-money-send";
const colorMovReceived = "mov-received";
const modalCompra = "modal-detail-purchase";
const modalCommision = "modal-detail-commision";
const modalConsultSaldo = "modal-detail-inquiry";
const modalRetiroATM = "modal-detail-received";
const modalSPEI = "modal-detail-sent";
const modalDeposito = "modal-detail-received-2";
const modalTransfersPagando = "modal-detail-transfers-pagando";
const modalDevolucion = "modal-detail-refund";
const modalAclaracion = "modal-detail-charge";
const modalPuntos = "modal-detail-puntos";

export default {
  name: "CorporativeActivityMovements",
  mixins: [MenuHandler],
  components: {
    ModalSPEI,
    TitleBadge,
    BasicInput,
    CardSimple,
    EmptyState,
    LoadingBar,
    FooterPage,
    ModalRefund,
    ModalPoints,
    ModalDeposit,
    MovementsList,
    ModalPurchase,
    ModalCommision,
    DateRangePicker,
    ModalConsultATM,
    ModalWithdrawalATM,
    ModalClarification,
    FloatingTitleLayout,
    CorporateSidebarMenu,
    ModalTransfersPagando,
    BasicCheckbox,
    SearchBar,
  },
  data() {
    return {
      availablePoints: 0,
      // Images
      imgEmptystateTable: imgEmptystateTable,
      // Date
      dateRangeComponentBtn: {},
      perPage: 8,
      page: 1,
      totalMovements: 0,
      movements: [],
      loading: false,
      movementToShow: {
        amount: 0,
        sign: null,
        contaMovementType: null,
        fullKey: null,
        date: null,
        movementTitle: null,
        movementDescription: null,
        payConcept: null,
        card: {
          last4: null,
          alias: null,
        },
        contactToTransfer: {
          contactName: null,
          numberAccount: "",
        },
        trackingKey: null,
        numericReference: null,
        recipientAccount: null,
        recipientName: null,
        requestName: null,
        spentRewardPoints: 0,
        spentRewardPointsValue: 0,
        acquiredRewardPoints: 0,
        benefitInstitutionName: null,
      },
      search: null,
      timeoutSearch: null,
      selectAll: false,
    };
  },
  computed: {
    currency() {
      return this.$options.filters.currency;
    },
    showDownloadMultiple() {
      return this.movements.filter(m => m.selected).length > 0;
    }
  },
  methods: {
    moment,
    downloadMultiple() {
      const movements = this.movements.filter(m => m.selected);
      for(const m of movements) {
        this.downloadReceipt(m.fullKey);
      }
    },
    selectSpecificMovement(value, movement) {
      movement.selected = value;
    },
    checkboxAll(value) {
      this.selectAll = value;
      // if(this.selectAll) {
        this.movements.map(m => {
          m.selected = this.selectAll;
          return m;
        });
    },
    downloadReceipt(folio) {
      CardApi.downloadTransferReceipt({folio}).then(async (response) => {
        const a = document.createElement("a");
        a.href = response.data.object;

        // const link = document.createElement("a");
        // link.setAttribute("href", response.data);
        // link.click();
        a.download = this.$t('transfers.new.successDetails.receiptFilename', {folio: this.folio});
        document.body.appendChild(a);
        this.toastSuccessDefault();
        a.click();
      }).catch(error => {
        this.toastErrorDefault(error);
      });
    },
    async downloadReport() {
      CardApi.getMovementsReport({
        accountId: this.$route.params.id,
        dates: this.dateRangeComponentBtn,
        search: this.search
      }).then(response => {
          const blob = new Blob([response.data], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.href = URL.createObjectURL(blob);
          a.download = `pagando-reporte-movimientos.xlsx`;
          document.body.appendChild(a);
          a.click();
          this.toastSuccessDefault();
      }).catch(error => {
          this.toastErrorDefault(error);
      });
    },
    async getPoints(){
      const id = this.$route.params.id;
      const res = await RewardPointsApi.getDetails(id).catch(catchError);
      if(res && res.data && !res.data.error) {
          this.availablePoints = res.data.object.totalPoints.toLocaleString();
      } else {
          this.availablePoints = 0;
      }
    },
    async searchChange(search) {
      this.search = search;
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(async () => {
        await this.getMovements();
      }, 1500);
    },
    async selectCalendar(data) {
      this.dateRangeComponentBtn = data;
      await this.getMovements();
    },
    async getMovements() {
      this.loading = true;
      this.movements = [];
      const id = this.$route.params.id;
      const response = await CorporativeAccountsApi.getAccountMovements({
        id,
        perPage: 8,
        dates: this.dateRangeComponentBtn,
        search: this.search,
        page: this.page,
      }).catch(catchError);

      if (response.data.error) {
        this.loading = false;
        this.toastErrorDefault(this.$t("movements.errorGetMovements"));
      } else {
        const movements = response.data.object.docs;
        movements.map(m => {
          m.selected = false;
          return m;
        });
        this.totalMovements = response.data.object.totalDocs;

        movements.forEach((movement) => {
          this.movements.push(this.parseMovement(movement));
        });
        this.loading = false;
      }
    },
    checkTypeToShowCard(movement) {
      switch (movement.type) {
        case "Compra":
        case "COMPRA_DIGITAL":
        case "COMPRA_CASHBACK":
        case "COMPRA_CONTACTLESS":
        case "Consulta":
        case "Retiro":
        case "RewardsRedemption":
        case "CASHIN":
          return true;
        default:
          return false;
      }
    },
    parseMovement(movement) {
      let sign = "";
      let amountClass = [];
      if (movement.contaMovementType === "ABONO") {
        sign = "+";
        amountClass = ["c-success"];
      } else if (movement.contaMovementType === "CARGO") {
        sign = "-";
      }

      if (movement.type === "LOST_CHARGEBACK") {
        amountClass = ["c-error"];
      }

      // const time = moment(movement.date).format('hh:mm a');
      const movementDescription = `${movement.movementDescription}`;

      let icon = "iconsax-money-card";
      let color = "mov-purchase";
      let modal = modalCompra;
      switch (movement.type) {
        case "Compra":
        case "COMPRA_DIGITAL":
        case "COMPRA_CONTACTLESS":
          modal = modalCompra;
          break;
        case "COMPRA_CASHBACK":
          modal = modalCompra;
          break;
        case "COMMISSION":
          modal = modalCommision;
          break;
        case "Devolución":
          icon = "iconsax-arrow-undo";
          color = "mov-refund";
          modal = modalDevolucion;
          break;
        case "Retiro":
          icon = "iconsax-money-cash";
          color = "mov-received";
          modal = modalRetiroATM;
          break;
        case "SPEIOUT":
          icon = iconMoneyMoneySend;
          color = "mov-sent";
          modal = modalSPEI;
          break;
        case "Deposito":
        case "CASHIN":
          icon = iconMoneyMoneyRecive;
          color = colorMovReceived;
          modal = modalDeposito;
          break;
        case "SPEIIN":
          icon = iconMoneyMoneyRecive;
          color = colorMovReceived;
          modal = modalSPEI;
          break;
        case "Transferencia":
          icon =
            movement.movementKey === "PT"
              ? iconMoneyMoneyRecive
              : iconMoneyMoneySend;
          color = movement.movementKey === "PT" ? colorMovReceived : "mov-sent";
          modal =
            movement.movementKey === "PT"
              ? modalDeposito
              : modalTransfersPagando;
          break;
        case "Consulta":
          icon = "iconsax-money-currency-circle";
          color = "mov-inquiry";
          modal = modalConsultSaldo;
          break;
        case "LOST_CHARGEBACK":
          modal = modalAclaracion;
          icon = "iconsax-essential-warning-2";
          color = "mov-charge";
          break;
        case "RewardsRedemption":
          modal = modalPuntos;
          break;
        case "Transacción":
        default:
          break;
      }

      const timeAgo = moment(movement.date).fromNow();

      return {
        ...movement,
        sign,
        amountClass,
        movementDescription,
        icon,
        color,
        timeAgo,
        modal,
      };
    },
    getDate(date) {
      return moment(date).format("DD MMM, YYYY");
    },
    getHour(date) {
      return moment(date).format("hh:mm a");
    },
    openModal(movement) {
      this.movementToShow = movement;

      switch (movement.modal) {
        case modalCompra:
          this.$refs.modalPurchase.openModal();
          break;
        case modalConsultSaldo:
          this.$refs.modalConsultATM.openModal();
          break;
        case modalCommision:
          this.$refs.modalCommision.openModal();
          break;
        case modalRetiroATM:
          this.$refs.modalWithdrawalATM.openModal();
          break;
        case modalSPEI:
          this.$refs.modalSPEI.openModal();
          break;
        case modalDeposito:
          this.$refs.modalDeposit.openModal();
          break;
        case modalTransfersPagando:
          this.$refs.modalTransfersPagando.openModal();
          break;
        case modalDevolucion:
          this.$refs.modalRefund.openModal();
          break;
        case modalAclaracion:
          this.$refs.modalClarification.openModal();
          break;
        case modalPuntos:
          this.$refs.modalPoints.openModal();
          break;
        default:
          this.$refs.modalPurchase.openModal();
          break;
      }
    },
    async pageChange(page) {
      this.page = page;
      await this.getMovements();
    },
    goToActivity() {
      const id = this.$route.params.id;
      this.$router.push({
        name: 'CorporativeActivity',
        params: {id},
      })
    },
  },
  async mounted() {
    await this.getMovements();
    await this.getPoints();
  },
};
</script>
