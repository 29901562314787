<template>
    <div id="" class="panel-link-go-to" :class="clazz">
        <span class="link-icon">
            <i aria-hidden="true" :class="icon"></i>
        </span>
        <span class="link-info">
            <label>{{titleText}}</label>
            <p>{{text}}</p>
        </span>
        <span class="link-go-to">
            <slot></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'PanelLinkGoTo',
        props: {
            clazz: String,
            icon: String,
            titleText: String,
            text: String,
        },
        methods: {},
    }
</script>
