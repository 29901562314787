<template>
    <div class="panel-puntos" :class="clazz">
        <span class="info">
            <div class="icon-bgm" :class="clazzIcon">
                <i aria-hidden="true" :class="icon"></i>
            </div>
            <p class="text-puntos-disponibles">
                {{textPoints}}
                <br/>
                <strong>{{pointsNumber}}</strong>
            </p>
        </span>
        <span>
            <p class="text-puntos-equiv">
                {{textEquiv}}
                <br/>
                <strong>{{pointsEquiv}}</strong>
            </p>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'PanelPoints',
        props: {
            clazz: String,
            clazzIcon: String,
            icon: String,
            textPoints: String,
            pointsNumber: String,
            textEquiv: String,
            pointsEquiv: String,
        }
    }
</script>
