<template>
  <div>
    <TitlePrincipal
      :title="$t('login.base.title')"
      :subtitle="$t('login.base.subtitle')"
    >
    </TitlePrincipal>
    <BasicInput
      v-model="email"
      clazz="m-b-0"
      required
      inputId="user_email"
      :has-error="email ? $v.email.$invalid : false"
      :label="$t('login.base.emailLabel')"
      :inputPlaceholder="$t('login.base.emailPh')"
      v-on:onKeyUpEnter="getUser"
    >
    </BasicInput>
    <span class="info-forgot">
      <label>{{ $t("login.base.forgotEmail") }}</label>
      <router-link to="/login/recover-email" class="hover" tabindex="">{{
        $t("login.base.recoverHere")
      }}</router-link>
    </span>
    <button
      v-if="showNext"
      type="button"
      class="button button-filled button-primary w-100"
      :disabled="disableButton"
      @click="getUser"
    >
      {{ $t("login.base.next") }}
    </button>
    <button
      v-else
      :id="'Loading button'"
      class="button button-filled button-primary w-100"
    >
      <LoadingSimple clazz="small-load no-text" />
    </button>
    <span class="notification-action">
      <label>
        {{ $t("login.base.newPagando") }}
        <router-link to="/register-account" class="hover" tabindex="">{{
          $t("login.base.registerNow")
        }}</router-link>
      </label>
    </span>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import LoadingSimple from "@/components/LoadingSimple";
import BasicInput from "@/components/BasicInput";
// import CodeInput from "@/components/CodeInput";
import ToastSnotify from "@/mixins/toastSnotify.mixin";

// TODO desccomentar: Se comenta para que los usuarios no puedan registrarse
import UserApi from "@/api/users/users.api";
import { catchError } from "@/utils/utils";
import {
  STORE as sessionStore,
  SET_USER,
  SET_USER_EMAIL,
} from "@/store/modules/session/session.types";

import { required, helpers } from "vuelidate/lib/validators";
import { regexEmail } from "@/utils/regexUtils";
const emailValidator = helpers.regex("rfcValidator", regexEmail);

export default {
  name: "LoginBase",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    LoadingSimple,
    BasicInput,
    // CodeInput,
  },
  data() {
    return {
      email: "",
      showNext: true,
    };
  },
  computed: {
    disableButton() {
      return this.$v.$invalid;
    },
  },
  methods: {
    async getUser() {
      this.showNext = false;
      if (!this.disableButton) {
        const email = this.email.toLowerCase();
        const response = await UserApi.getUserByEmail({
          email: email,
        }).catch(catchError);
        if (response && response.data) {
          if (response.data.error) {
            const message = response.data.message
              ? response.data.message
              : this.$i18n.t("login.base.error");
            this.displayNotificationError(message);
          } else {
            this.$store.commit(
              `${sessionStore}/${SET_USER}`,
              response.data.object
            );
            this.$store.commit(`${sessionStore}/${SET_USER_EMAIL}`, email);
            this.$router.push({ name: "loginSecurity" });
          }
        } else {
          this.displayNotificationError(this.$i18n.t("login.base.error"));
        }
      }
      this.showNext = true;
    },
  },
  validations: {
    email: {
      required,
      emailValidator,
    },
  },
};
</script>

<style scoped></style>
