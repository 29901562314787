<template>
  <CardSelection :title="$t(title)"
                 :subtitle="$t(subtitle)"
                 :clazz="`border-card ${clazzTemplate}`">
    <template v-slot:icon>
      <i aria-hidden="true" class="iconsax-content-document-text"></i>
    </template>
    <template v-slot:extraMiddleContent v-if="!errorUploading">
      <b-progress
          :max="maxProgressBar"
          class="bar-progress percent-color"
          :class="{'success' : valueProgressBar === 100 }"
      >
        <b-progress-bar :value="valueProgressBar">
          <div class="label-progress">
            <span>{{ valueProgressBar }}%</span>
          </div>
        </b-progress-bar>
      </b-progress>
    </template>
    <template v-else v-slot:extraMiddleContent>
      <p class="f-12 c-error poppins-font-semibold d-block m-0">
        {{$t('dispersions.new.error.fileFormat')}}
      </p>
    </template>
    <template v-slot:extraFinalContent>
      <i v-if="valueProgressBar === 100" aria-hidden="true" class="banana banana-check-normal f-30 c-success"></i>
      <button v-else type="button" class="button xs button-filled button-primary p-relative c-pointer">
        {{ $t(buttonCard) }}
        <input type="file" class="input-file c-pointer" value="" name="" @change="uploadDoc"/>
      </button>
    </template>
  </CardSelection>
</template>

<script>
import CardSelection from "@/components/CardSelection.vue";
const clazzCard = {
  info: "icon-bgm-info_text",
  upload: "icon-bgm-accent2",
  success: "icon-bgm-success",
  error: "icon-bgm-error",
}

export default {
  name: "UploadFileCard",
  components: {
    CardSelection,
  },
  props: {
    title: {type: String, default: ''},
    subtitle: {type: String, default: ''},
    buttonUpload: {type: String, default: ''},
    buttonUploading: {type: String, default: ''},
    buttonRetry: {type: String, default: ''},
    errorDisplay: {type: String, default: ''},
  },
  data() {
    return {
      // ProgressBar
      maxProgressBar: 100,
      // view template
      valueProgressBar: 0,
      clazzTemplate: clazzCard.info,
      buttonCard: "",
      fileToSend: {},
      errorUploading: false,
    }
  },
  created() {
    this.buttonCard = this.buttonUpload;
  },
  methods: {
    /**
     * update view cardSimple
     * @param {number} p percentage
     * @param {string} c clazz
     * @param {string} t button text
     */
    updateProgress(p, c, t) {
      this.valueProgressBar = p;
      this.clazzTemplate = c;
      this.buttonCard = t;
    },
    uploadDoc(event) {
      this.errorUploading = false;
      const uploading = this.buttonUploading;
      this.updateProgress(25, clazzCard.upload, uploading);
      const file = event.target.files[0];
      if (file) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.updateProgress(75, clazzCard.upload, uploading);
          this.fileToSend = {
            title: `${file.name}-${Date.now()}`,
            key: file.name || "plantillaKey",
            file: {
              name: file.name,
              type: file.type,
              size: file.size,
              data: fileReader.result.split(',')[1],
            },
          };
          this.updateProgress(100, clazzCard.success, "");
          this.$emit("uploadDoc", this.fileToSend);
          this.$emit("load", file);
        });
        fileReader.addEventListener('error', () => {
          this.fileToSend = {};
          this.displayNotificationWarning(this.$i18n.t(this.errorDisplay));
          this.updateProgress(0, clazzCard.error, this.buttonRetry);
          this.errorUploading = true;
        });
        this.updateProgress(50, clazzCard.upload, uploading);
        fileReader.readAsDataURL(file);
      } else {
        this.fileToSend = {};
        this.errorUploading=true;
        this.displayNotificationWarning(this.$i18n.t(this.errorDisplay));
        this.updateProgress(0, clazzCard.error, this.buttonRetry);
      }
    },
    reset() {
      this.updateProgress(0, clazzCard.info, this.buttonUpload)
    }
  }
}
</script>

<style scoped>

</style>
