import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'v1/corp-acc';

export default {
    listAccounts: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/list`, {params})
    },
    getAccountTypes: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/corporate-accounts`, {params})
    },
    findUserByEmail: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/find-user-by-email`, {params})
    },
    save: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/save`, params)
    },
    checkStatus: (params) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/check-status`, {params})
    },
    getDetails: (id) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/details/${id}`)
    },
    edit: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/edit`, params)
    },
    block: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/block`, params)
    },
    unblock: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/unblock`, params)
    },
    delete: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/delete`, params)
    },
    getActivityInfo: (id) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/activity-info/${id}`)
    },
    getAccountMovements: ({id, ...params}) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/movements/${id}`, params)
    },
    getAccountMovementsByDate: ({id, ...params}) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/movements-by-date/${id}`, {params})
    },
    blockCard: (id) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/block-card/${id}`)
    },
    unblockCard: (id) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/unblock-card/${id}`)
    },
    checkNIP: (id) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/check-nip/${id}`)
    },
    checkCVV: (id) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/check-cvv/${id}`)
    },
    getBankStatements: ({id, ...params}) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/bank-statements/${id}`, {params})
    },
    getOperationalLimits: (id) => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/operational-limits/${id}`)
    },
    resendEmail: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/resend-email`, params)
    },
}
