// PAGES
import SelectAccountCard from "@/pages/onboarding/rightSideSteps/SelectAccountCard";
import UsagePolicies from "@/pages/onboarding/rightSideSteps/UsagePolicies";
import ProcessingRequest from "@/pages/onboarding/rightSideSteps/ProcessingRequest";
import SuccessRequest from "@/pages/onboarding/rightSideSteps/SuccessRequest";
import Contract from "@/pages/onboarding/rightSideSteps/Contract";
import Sign from "@/pages/onboarding/rightSideSteps/Sign";
import SelectDocument from "@/pages/onboarding/rightSideSteps/SelectDocument";
import UploadDocument from "@/pages/onboarding/rightSideSteps/UploadDocument";
const addInfo = () => import('@/pages/onboarding/rightSideSteps/AddInfo');
import OnbngWelcome from "@/pages/onboarding/rightSideSteps/OnbngWelcome";
import Residence from "@/pages/onboarding/rightSideSteps/Residence";
import Occupation from "@/pages/onboarding/rightSideSteps/Occupation";
// MIDDLEWARE
import isWalletUser from "@/middleware/isWalletUser";
import isLevelTwoUser from "@/middleware/isLevelTwoUser";
import hasCardRequest from "@/middleware/hasCardRequest";
import hasUserSession from "@/middleware/hasUserSession";

export default [
    {
        path: 'select-account',
        name: "selectAccountMultiple",
        component: SelectAccountCard,
        meta: {
            middleware: [hasUserSession, isWalletUser]
        },
    },
    {
        path: 'use-policy',
        name: "usagePoliciesMultiple",
        component: UsagePolicies,
        meta: {
            middleware: [hasUserSession, isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'processing-request',
        name: "processingRequestMultiple",
        component: ProcessingRequest,
        meta: {
            middleware: [hasUserSession, isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'response-request',
        name: "successRequestMultiple",
        component: SuccessRequest,
        meta: {
            middleware: [hasUserSession, isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'contract',
        name: "contractMultiple",
        component: Contract,
        meta: {
            middleware: [hasUserSession, isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'sign',
        name: "signMultiple",
        component: Sign,
        meta: {
            middleware: [hasUserSession, isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'residence',
        name: "residenceMultiple",
        component: Residence,
        meta: {
            middleware: [hasUserSession, isWalletUser, isLevelTwoUser, hasCardRequest]
        },
    },
    {
        path: 'occupation',
        name: "occupationMultiple",
        component: Occupation,
        meta: {
            middleware: [hasUserSession, isWalletUser, isLevelTwoUser, hasCardRequest]
        },
    },
    // LEVEL TWO
    {
        path: 'select-documents',
        name: "selectDocumentsMultiple",
        component: SelectDocument,
        meta: {
            middleware: [hasUserSession, isWalletUser, isLevelTwoUser, hasCardRequest]
        },
    },
    {
        path: 'upload-documents/:id',
        name: "uploadDocumentsMultiple",
        component: UploadDocument,
        meta: {
            middleware: [hasUserSession, isWalletUser, isLevelTwoUser, hasCardRequest]
        },
    },
    // LEVEL THREE
    {
        path: 'add-info',
        name: "addInfoMultiple",
        component: addInfo,
        meta: {
            middleware: [hasUserSession, isWalletUser, hasCardRequest]
        },
    },
    {
        path: 'welcome',
        name: "onbngWelcomeMultiple",
        component: OnbngWelcome,
        meta: {
            middleware: [hasUserSession, isWalletUser]
        },
    },
];
