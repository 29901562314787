<template>
  <div>
    <TitlePrincipal
      title="Identificación de accionistas"
      subtitle="Añade la información de los accionistas de la empresa.
                        Si requieres poner organizaciones con subniveles selecciona
                        la opción “Persona Moral” al agregar un accionista."
    >
    </TitlePrincipal>
    <div class="row">
      <div class="col-12" v-if="!childrenExist">
        <label class="f-16 c-title poppins-font-bold d-block m-b-10">{{
          organization.legalName
        }}</label>
      </div>
      <ShareholdersTree
        @onLoadChildrenDone="onTreeChildrenLoaded"
        @onLoadChildrenError="onTreeLoadError"
        @onLoadRootDone="onLoadRootDone"
        readOnly
      />
      <div class="col-12">
        <div class="dropdown-accionistas" v-if="childrenExist">
          <button class="button-square-add" @click="editShareholders">Agregar, ver o editar detalle de accionistas</button>
        </div>
        <b-dropdown id="dropdown-add" class="dropdown-accionistas" v-else>
          <template v-slot:button-content>
            <button class="button-square-add">Agregar accionista</button>
          </template>
          <b-dropdown-group>
            <b-dropdown-item-button @click="addPhysical">
              <div class="accionista-type">
                <img
                  class="img-fluid"
                  src="@/assets/images/Illustrations/illustration-accionistas-persona-fisica.svg"
                  alt="Persona fisica"
                />
                <span class="accionista-type-info">
                  <label> {{$t('register.shareholders.notification.modal.titlePhysic')}}</label>
                  <p>
                    {{$t('register.shareholders.notification.modal.textPhysic')}}
                  </p>
                </span>
              </div>
            </b-dropdown-item-button>
            <b-dropdown-item-button @click="addMoral">
              <div class="accionista-type">
                <img
                  class="img-fluid"
                  src="@/assets/images/Illustrations/illustration-accionistas-persona-moral.svg"
                  alt="Persona moral"
                />
                <span class="accionista-type-info">
                  <label>{{$t('register.shareholders.notification.modal.titleMoral')}}</label>
                  <p>
                    {{$t('register.shareholders.notification.modal.textMoral')}}
                  </p>
                </span>
              </div>
            </b-dropdown-item-button>
          </b-dropdown-group>
        </b-dropdown>
      </div>
    </div>
    <div class="footer-buttons right">
      <button
        type="button"
        class="button button-ghost button-plain_text"
        name="Regresar"
        @click="back"
      >
        Regresar
      </button>
      <button
        v-if="showNext"
        type="button"
        class="button button-filled button-primary"
        name="Continuar"
        :disabled="!childrenExist || !hasLegal"
        @click="nextStep"
      >
        Continuar
      </button>
      <button v-else :id="'Loading button'" class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text" />
      </button>
    </div>
  </div>
</template>
<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import LoadingSimple from "@/components/LoadingSimple";
import ShareholdersTree from "@/components/ShareholdersTree";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import { mapGetters } from "vuex";
import {GO_TO_ROUTE, GET_CARD_REQUEST, GET_LOCATION, STORE as onbngStore} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";

export default {
  name: "ShareholdersIdentification",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    ShareholdersTree,
    LoadingSimple
  },
  data() {
    return {
      loading: true,
      childrenExist: false,
      showNext: true,
      hasLegal: false
    };
  },
  computed: {
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO,
    }),
    ...mapGetters(onbngStore, {
      location: GET_LOCATION,
      cardRequest: GET_CARD_REQUEST
    }),
  },
  methods: {
    back() {
      this.$store.dispatch(`${onbngStore}/backBusiness`);
    },
    sendRequestCard() {
      let isMoral = false;
      if (this.cardRequest.isMoral) {
        isMoral = true;
      }
      this.$store.dispatch(`${onbngStore}/requestCard`, {
        provider: "PAGANDITO",
        longitud: this.location.longitud,
        latitud: this.location.latitud,
        isMoral: isMoral,
        isMultipleAccount: true,
        organizationInfo: this.organization._id,
        isNewOrg: true
      }).then(() => {
      });
    },
    async nextStep() {
      this.showNext = false;
      this.showNext = true;

      if(this.$route.name === 'businessShareholdersMultiple') {
        this.sendRequestCard();
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "businessProcessingRequestMultiple");
      } else {
        await this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.CREATE_NIP,
          });
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "businessNewPin");
      }

    },
    addPhysical() {
      if(this.$route.name === 'businessShareholdersMultiple') {
        this.$router.push({ name: "newPhysicalShareholderMultiple" });
      } else {
        this.$router.push({ name: "newPhysicalShareholder" });
      }
    },
    addMoral() {
      if(this.$route.name === 'businessShareholdersMultiple') {
        this.$router.push({ name: "newMoralShareholderMultiple" });
      } else {
        this.$router.push({ name: "newMoralShareholder" });
      }
    },
    onLoadRootDone(rootData) {
      const legal = rootData.find((d) => d.legal);
      if(legal && legal.hasDigitalSign && legal.hasLiveness) {
        this.hasLegal = true;

      } else {
        this.hasLegal = false;
      }
    },
    onTreeChildrenLoaded(exists) {
      this.childrenExist = exists;
      this.loading = false;
    },
    onTreeLoadError() {
      this.displayNotificationError(
        "No se pudieron cargar los accionistas. Intenta de nuevo."
      );
    },
    editShareholders() {
      if(this.$route.name === 'businessShareholdersMultiple') {
        this.$router.push({ name: "shareholdersModificationMultiple", query: {org: this.organization._id} });
      } else {
        this.$router.push({ name: "shareholdersModification" });
      }
    },
  },
  async beforeMount() {
    if(this.$route.name === 'businessShareholdersMultiple') {
      this.$store.commit(`${onbngStore}/SET_CREATE_NEW_ORG`, true);
      await this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_BUSINESS_MORAL');
    }
    this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
  }
};
</script>
