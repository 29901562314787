function privateRSAKeySTP() {
    return (process.env.VUE_APP_RSA_PRIVATE_STP && process.env.VUE_APP_RSA_PRIVATE_STP.length > 0) ? process.env.VUE_APP_RSA_PRIVATE_STP : _devPrivateRSAKeySTP();
}

async function importPrivateKey() {
    const getKey = getPkcs8Der(privateRSAKeySTP());
    return crypto.subtle.importKey(
        "pkcs8",
        getKey,
        {
            name: "RSASSA-PKCS1-v1_5",
            hash: "SHA-256",
        },
        true,
        ["sign"]
    );
}

function getPkcs8Der(pkcs8Pem) {
    var binaryString = atob(pkcs8Pem).trim();
    const pemHeader = "-----BEGIN PRIVATE KEY-----";
    const pemFooter = "-----END PRIVATE KEY-----";
    const pemContents = binaryString.substring(pemHeader.length, binaryString.length - pemFooter.length);
    const binaryDerString = window.atob(pemContents);
    return str2ab(binaryDerString);
}

function str2ab(str) {
    const buf = new ArrayBuffer(str.length);
    const bufView = new Uint8Array(buf);
    for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
    }
    return buf;
}

function _devPrivateRSAKeySTP() {
    return `LS0tLS1CRUdJTiBQUklWQVRFIEtFWS0tLS0tCk1JSUV2Z0lCQURBTkJ
na3Foa2lHOXcwQkFRRUZBQVNDQktnd2dnU2tBZ0VBQW9JQkFRRE9WNXRTN0tBRT
lzZC8Kdndveit1OHVtR0N5dmxBMnVQc0o2dHhKUkIzZjRZVEVHWXBhYUdaUnlmNU
5jTFFaY0hPdDFNRHl3VWtuRllpegp5N2lvSXFMOUpvcllBc1NNL2hqYlc3NjJjOW
JkR0NnVE82MlExMHdPcjUvb3dkL3hMUXY5QUVybnIwc0g3VXA2CnZ6T0tWWERzQ1h
DYjNsZmZYSERNNHhqbHE4NkFiRlp0cHZNSzFvcEVKckcwenprR3ZncmE1NTArNDVo
ZEFFZ2IKL1NhcFYvWk1NeWZuc2xZMXVYNnYwMy9CT1lpZTNQWlprTXpiWlE2aFBSYn
hRTGloMFB4K2g4Tm4vWUdkbnU5dgpzWFlmMXVWTldmZGEvOFdKMUJIekZ5OWVoZTd
5Y1BER24xcHdTemlKb2RIUmxBc2hXVHkzbE82LzVRU3dLQ1FDCjdWeTdSbWc1QWdN
QkFBRUNnZ0VCQUpVTHpRTmlMdGhIRmFYZDdkWE1QWGJQMUpGb1RQS3FQWk5WVlplS2
4zVzMKSmJxc3BEWlgwUWpsT2s3ZnlRZlRKWXY3OFArb3hGbDZBWXUxN1FWdU40TmFr
dElqZGF4T1l0QVlmaksyakE2YQpRMkJaTEM5Q2hRcmFxMHhxdG9NMFFjT1hzWU9YS0V
CcWsvRUVLWXVUMmdSMWNTVDF2MDExTFhtaDFMc1psZ2xHCmEvOVc3L1FKRkp5ZzNCem
Q5VDN2UjlMNUN3TXZFdUxnWXprQUZKSjVUa2RDSGl6dHRtWnN0aG9jRmg2QUdQSzUKb
FcvQlNrS2JGdWx3MEdHVEp1K2VBRWtTM0FzSXhvZS9GWW4wYVoyUmx6WHdLV1hYSDZt
Vm1PYm5mK2I4djhGVQpBU25iOStpdEl0YUpDWUtSc25ReTZYVWlaNWc0WFhHd0I2N2x
pVmJiMW4wQ2dZRUE3eTZwd0NWK2dET1d5L1BxCjdxbmtqU1VTMnFxcHdiVlIwc1B5V1p
3OEFuU3grTHRNMy9SRFpLS1dGQUs1ek9HVDg5Z3lHSVNsNVZIQlpaSnIKQ1VidWJDMk
tNOUNiTVdCdkdINWp6Si83a2o1R3lUNXUrWVROZkxWNWtrUXdGTmZFMUhnR29sN2JIT
HNNL3JIOApZSmtDLzJBNUExZEJtNnJiTG1qYlpRWWNXa2NDZ1lFQTNOblFsL3FLMU5E
Tk1rSlg1ei9DRjhGdWVobzI4SVZUCkFFWVFwTXJpeWJlbWlKdUFFUW1BTTJDZkwvUEp
TYkg0SFJQVXVXbDBzWEF3Nk1nNEZvZ2doWDYxRHM5OFRrSEcKVjdmcEhmMHhPU0xzeWM
3QlhKbE5CYnNwTzcrOEl2VXJwVUtueVZJb2hIQmNveXRNbWExUVBvVVg5ZzhKWWEvOA
pDN3NnZkgwZDZYOENnWUFZcUJMUHdBMitZWk1XQzFzWXAyTHNPZm9ybUpSUFo4VzdtSC
tQUlUvYVlQVkgwdWNsCjN5UmpaMjlaT0w2MnBEcUFWTFUxV2RkUVFKRlRTempGaUE1a0
VIM0N3YXA5K0piN1MydzJWL2V6SjhCY1ZwZFMKR2FLbFRIL3YyQitzeG1HQUZhOTVaWl
hvN0U5Mm9ocUdTci9JVzc4RTNTWGlTa2EyRGhFdjVPVmZXUUtCZ1FETAp5OE9OT3dsNXN
PUnBpSWNOUWV5ZGQwVzNMeEdiaWF3NkNHMjdZKzA3TDFsaG5memp1OXl0akcvWkZDR2Ur
N2QxClBhT0RKU2tsdzkyZU1ZUndabFY4V3B4QmVuMit5VHQ2SHAvanFJMFBxSWJSQXhXe
m1WTldBb3ZlTXVRdGJnaEUKaStuQkFTdWFhd1ZSSE5lbXlDUFFUWWo2Nm8xQnM5L21BMXZ
MQkxuaGlRS0JnQkpwbVdIeVZiWWpKc1V4KzkzOAp0Y0xKdmZtZUIyNHZyTTFpZWlDMVhOR
DNPWnM2bnVxSmhaeWZ4ZUNIWDBrRitTR3ZVN2NOVlRCREpQSWxMQy9HCmxCUkxmQTBPbWZ
wUWc4REpFcU51UGNwenJNM3p2TzRCU2MzbzZ6NGwyL0ZjeDg1VTF1RXoyL2JSTXRna1JkZ
kkKazBzVWtBblkyYnBjVTQ3dERpaFBJcDh4Ci0tLS0tRU5EIFBSSVZBVEUgS0VZLS0tLS0=`;
}

export default async function ecryptRSAForSTP(data) {
    const privateKey = await importPrivateKey();
    const dataFinal = new TextEncoder().encode(data);
    let encrypted = null;
    try {
        encrypted = await window.crypto.subtle.sign(
            { name: "RSASSA-PKCS1-v1_5" },
            privateKey,
            dataFinal,
        );
        return window.btoa(String.fromCharCode(...new Uint8Array(encrypted)));
    } catch (err) {
        console.log("ERR", err);
    }

    return null;

}

