<template>
    <b-modal
        id="modal-acuerdo-traspaso-info"
        size="lg"
        hide-header
        hide-footer
        @change="change"
    >
        <button class="close-modal" @click="$bvModal.hide('modal-acuerdo-traspaso-info')" aria-label="Boton cerrar ventana" name="Boton cerrar ventana">
            <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </button>
        <div class="modal-terminos-privacidad">
            <h2
                class="title"
                v-text="$t('pagandoCheck.modal-contract.title')"
            ></h2>
            <span class="date-update">
                <small  v-text="$t('pagandoCheck.modal-contract.last-update')"></small>
                {{moment(contract.updatedAt).format('DD MMMM, YYYY')}}
            </span>
            <div
                class="text-container"
                v-html="contract.content"
            ></div>
            <button
                id=""
                type="button"
                class="button button-filled button-primary"
                @click="sign"
            >
                <i aria-hidden="true" class="iconsax-essential-tick-square m-r-10"></i>
                {{$t('pagandoCheck.modal-contract.sign')}}
            </button>
        </div>
        <LoadingBar
            clazz="fullcontent-loading"
            v-if="loading"
        />
    </b-modal>
</template>
<script>
import DocumentsApi from "@/api/core/documents.api.js";
import {mapGetters} from "vuex";
import {
    STORE as sessionStore,
    GET_ACCOUNT,
} from "@/store/modules/session/session.types";
import moment from 'moment';
import LoadingBar from "@/components/LoadingBar.vue";

export default {
    name: "InfoTransferContract",
    components: {
        LoadingBar
    },
    data() {
        return {
            contract: {},
            loading: false,
            moment,
        };
    },
    computed: {
        ...mapGetters(sessionStore, {
            account: GET_ACCOUNT
        }),
    },
    methods: {
        async getContract() {
            this.loading = true;
            const payload = {
                key: 'INFO_TRANSFER',
                discriminators: [this.account?.account?.accountType?._id]
            };
            this.contract = await DocumentsApi.getDocumentWallet(payload).then(({data: {object}}) => object);
            this.loading = false;
        },
        async change(evt) {
            if (evt) {
                await this.getContract();
            }
        },
        sign() {
            this.$bvModal.hide('modal-acuerdo-traspaso-info');
            this.$bvModal.show('modal-pagando-check-sign');
        }
    },
};
</script>
