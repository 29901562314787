/* eslint-disable */
/**
 * @type {Readonly<{speiIn: string, transferOutPagando: string, speiOut: string, transferInPagando: string, atmCashWithdrawal: string, cashWithdrawalStores: string, cardPurchases: string}>}
 */
const operationalLimitsAccountEnum = Object.freeze({
    cardPurchases: "CARD_PURCHASES",
    atmCashWithdrawal: "ATM_CASH_WITHDRAWAL",
    cashWithdrawalStores: "CASH_WITHDRAWAL_STORES",
    speiIn: "SPEI_IN",
    speiOut: "SPEI_OUT",
    transferInPagando: "TRANSFER_IN_PAGANDO",
    transferOutPagando: "TRANSFER_OUT_PAGANDO",
});

module.exports = operationalLimitsAccountEnum;
