<template>
    <footer id="" class="footer-main" :class="clazz">
        <div class="footer-content">
            <span class="footer-top">
                <div class="support-info">
                    <label>{{$t('footerPage.supportInfo.title')}}</label>
                    <p v-html="$t('footerPage.supportInfo.description')"></p>
                </div>
                <img class="img-fluid" src="@/assets/images/Illustrations/illustration-footer-support.png" width="175" height="154" alt="Soporte y ayuda"/>
            </span>
            <span class="footer-bottom">
                <span class="principal-content">
                    <img class="img-fluid logo" src="@/assets/images/Logos/logo-color.svg" width="162" height="36" alt="Logo Remesas" />
                    <label>{{$t('footerPage.principalContent.slogan')}}</label>
                    <p>{{$t('footerPage.principalContent.registeredMark')}}</p>
                    <div class="social-media">
                        <a href="https://www.facebook.com/pagandomx/" class="social-media-link" tabindex="" rel="nofollow" aria-label="Facebook">
                            <img class="img-fluid" src="@/assets/images/Icons/icon-facebook.svg" width="40" height="40" alt="Facebook"/>
                        </a>
                        <a href="https://twitter.com/pagandomx" class="social-media-link" tabindex="" rel="nofollow" aria-label="Twitter">
                            <img class="img-fluid" src="@/assets/images/Icons/icon-twitter.svg" width="40" height="40" alt="Twitter"/>
                        </a>
                        <a href="https://www.instagram.com/pagandomexico/" class="social-media-link" tabindex="" rel="nofollow" aria-label="Instagram">
                            <img class="img-fluid" src="@/assets/images/Icons/icon-instagram.svg" width="40" height="40" alt="Instagram"/>
                        </a>
                        <a href="https://www.linkedin.com/company/pagando-mx/" class="social-media-link" tabindex="" rel="nofollow" aria-label="Linked In">
                            <img class="img-fluid" src="@/assets/images/Icons/icon-linkedin.svg" width="40" height="40" alt="Linked In"/>
                        </a>
                    </div>
                </span>
                <span v-if="isLoggedIn">
                    <label class="title-links">{{$t('footerPage.account.title')}}</label>
                    <a href="" class="link-to" tabindex="">{{$t('footerPage.account.info1')}}</a>
                    <a href="" class="link-to" tabindex="">{{$t('footerPage.account.info2')}}</a>
                    <a href="" class="link-to" tabindex="">{{$t('footerPage.account.info3')}}</a>
                    <a href="" class="link-to" tabindex="">{{$t('footerPage.account.info4')}}</a>
                    <a href="" class="link-to" tabindex="">{{$t('footerPage.account.info5')}}</a>
                    <a href="" class="link-to" tabindex="">{{$t('footerPage.account.info6')}}</a>
                </span>
                <span v-if="isLoggedIn">
                    <label class="title-links">{{ $t('footerPage.profile.title') }}</label>
                    <a href="" class="link-to" tabindex="">{{ $t('footerPage.profile.info1') }}</a>
                    <a href="" class="link-to" tabindex="">{{ $t('footerPage.profile.info2') }}</a>
                    <a href="https://www.pagando.mx/contacto-pagando/" class="link-to" tabindex="">{{ $t('footerPage.profile.info3') }}</a>
                    <a href="" class="link-to" tabindex="">{{ $t('footerPage.profile.info4') }}</a>
                    <a href="https://www.pagando.mx/terminos-y-condiciones-pagando/" class="link-to" tabindex="">{{ $t('footerPage.profile.info5') }}</a>
                    <a href="https://www.pagando.mx/aviso-de-privacidad-pagando/" class="link-to" tabindex="">{{ $t('footerPage.profile.info6') }}</a>
                </span>
                <span>
                    <label class="title-links">{{$t('footerPage.support.title')}}</label>
                    <a href="https://ayuda.pagando.mx/hc/es-419" class="link-to" tabindex="">{{$t('footerPage.support.info1')}}</a>
                    <a href="https://www.pagando.mx/une/" class="link-to" tabindex="">{{$t('footerPage.support.info2')}}</a>
                    <a href="https://www.pagando.mx/costos-y-comisiones-pagando/" class="link-to" tabindex="">{{$t('footerPage.support.info3')}}</a>
                </span>
            </span>
            <span class="extra-info">
                <p v-html="$t('footerPage.extraInfo')"></p>
                <div class="logos">
                    <a href="https://www.buro.gob.mx/"  tabindex="">
                        <img class="img-fluid" src="@/assets/images/Logos/logo-buro.svg" width="162" height="60" alt="Logo Buró"/>
                    </a>
                    <div class="condusef">
                        <img class="img-fluid" src="@/assets/images/Logos/logo-condusef.png" width="116" height="60" alt="Logo CONDUSEF"/>
                        <div>
                            {{$t('footerPage.condusef.phone')}} <br>
                            <a href="www.condusef.gob.mx"  tabindex=""><u>www.condusef.gob.mx</u></a>
                        </div>
                    </div>
                </div>
            </span>
        </div>
    </footer>
</template>

<script>
    import {
    STORE as sessionStore,
    GET_USER
    } from "@/store/modules/session/session.types";
    import {mapGetters} from "vuex";

    const sticky = "sticky-element";
    export default {
        name: 'FooterPage',
        props: {
            clazz: String,
        },
        computed: {
            ...mapGetters(sessionStore, {
                user: GET_USER
            }),
            isLoggedIn() {
                return this.user?.hasNewRegisterFinish;
            },
        },
        methods: {
            stikyMenu() {
                const heightFooter = document.querySelector('.footer-main').clientHeight;
                const isInViewport = function(elem) {
                    const distance = elem.getBoundingClientRect();
                    return (
                        distance.top >= 0 &&
                        distance.left >= 0 &&
                        distance.bottom <= (window.innerHeight + heightFooter || document.documentElement.clientHeight + heightFooter) &&
                        distance.right <= (window.innerWidth || document.documentElement.clientWidth)
                    );
                };
                const footer = document.querySelectorAll('.footer-main');
                const leftMenu = document.getElementById('sidebar-menu');
                const rightMenu = document.getElementById('sidebar-right');

                if (leftMenu != null)
                {
                    window.addEventListener('scroll', function() {
                        footer.forEach(element => {
                        if (isInViewport(element)) {
                            leftMenu.classList.add(sticky);
                            leftMenu.style.bottom = `${heightFooter}px`;

                        } else {
                            leftMenu.classList.remove(sticky);
                            leftMenu.style.bottom = "initial";
                        }
                        });
                    }, false);
                }

                if (rightMenu != null)
                {
                    window.addEventListener('scroll', function() {
                        footer.forEach(element => {
                        if (isInViewport(element)) {
                            rightMenu.classList.add(sticky);
                            rightMenu.style.bottom = `${heightFooter}px`;

                        } else {
                            rightMenu.classList.remove(sticky);
                            rightMenu.style.bottom = "initial";
                        }
                        });
                    }, false);
                }
            }
        },
        mounted () {
            this.stikyMenu();
            window.addEventListener('resize', this.stikyMenu);
        },
    }
</script>
