<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/wallet/dashboard"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('promotions.goBack')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('promotions.title')"></FloatingTitleLayout>
                        </div>

                        <div class="col-12">
                            <CardSimple hideTitle v-if="promos.length === 0 && !loading">
                                <EmptyState
                                    emptyStateDefault
                                    :title="$t('promotions.emptyState.title')"
                                    :text="$t('promotions.emptyState.text')"
                                    :imgUrl="imgEmptyPromo"
                                    :imgWidth="330"
                                    :imgHeight="220"
                                    :imgAlt="$t('promotions.emptyState.title')">
                                </EmptyState>
                            </CardSimple>

                            <div class="row" v-if="promos.length > 0 && !loading">
                                <div class="col-12 col-sm-6 col-lg-4 m-b-40" v-for="promo in promos" :key="promo._id">
                                    <PanelPromo
                                        :promoTitle="promo.sponsor"
                                        :imgPromoUrl="getImage(promo)"
                                        :imgAlt="promo.sponsor"
                                        :promoDescr="promo.alliance.title"
                                        :promoAv="getAvailabilityDescription(promo.alliance.availableIn)"
                                        :promoDate="getDate(promo.endDate)"
                                        :promo="promo"
                                        :dateClazz="getExtraClass(promo)">
                                    </PanelPromo>
                                </div>
                            </div>

                            <LoadingBar v-if="loading" :loadingText="$t('promotions.loading')"></LoadingBar>

                            <div class="control-buttons" v-if="!loading">
                                <button
                                    @click="goBack()"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('promotions.back')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>
    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import LoadingBar from "@/components/LoadingBar.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import EmptyState from "@/components/EmptyState.vue";
    import imgEmptyPromo from "@/assets/images/Emptystates/emptystate-promotions.svg";
    import PanelPromo from "@/components/PanelPromo.vue";
    import CouponsApi from "@/api/core/coupons.api";
    import { catchError, imageDownloadLink } from "@/utils/utils";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import moment from 'moment';
    import {GET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";
    import {mapGetters} from "vuex";
    import Imagen from '@/assets/images/Backgrounds/Background-login.png';

    export default {
        name: "PromotionList",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            CardSimple,
            EmptyState,
            PanelPromo,
            LoadingBar
        },
        data() {
            return {
                // Images
                imgEmptyPromo: imgEmptyPromo,
                window: {
                    width: 0
                },
                isMenuOpen: false,
                // Multiselect
                valuecolonia: [],
                optionsColonia: [],
                promos: [],
                loading: false,
                imagePlaceholder: Imagen
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                account: GET_ACCOUNT,
            }),
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            async getPromos() {
                this.loading = true;
                const res = await CouponsApi.getAllianceCampaigns({accountType: this.account.account.accountType._id}).catch(catchError);

                if(res && res.data && !res.data.error) {
                    this.promos = res.data.object;
                    this.loading = false;
                } else {
                    this.toastErrorDefault(this.$t('promotions.errors.getPromos'));
                }

            },
            getImage(promo) {
                if(promo && promo.alliance && promo.alliance.thirdPartyLogo) {
                    return imageDownloadLink(promo.alliance.thirdPartyLogo);
                }

                return this.imagePlaceholder;
            },
            getExtraClass(promo) {
                if(promo.endDate) {

                    const days = this.getDifferenceDays(promo.endDate);

                    if(days <= 5) {
                        return 'c-warning';
                    }
                }

                return '';
            },
            getDifferenceDays(endDate) {
                const date = moment(endDate);
                const now = moment();

                return date.diff(now, 'days');
            },
            getAvailabilityDescription(value) {
                return this.$t(`promotions.availability.${value}`);
            },
            getDate(value) {
                if(value) {
                    const days = this.getDifferenceDays(value);

                    if(days <= 5 && days > 0) {
                        return this.$tc(`promotions.expireInDays`, days, {days: days});
                    } else if(days === 0) {
                        return this.$t('promotions.expireToday');
                    }
                    return this.$t(`promotions.expireIn`, {date: moment(value).format('DD/MM/YYYY')});
                }

                return null;
            },
            goBack() {
                this.$router.push({name: "Dashboard"});
            }
        },
        async mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
            await this.getPromos();
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
