<template>
    <div class="form-group" :class="clazz">
        <div id="form-inputPhone" v-on:click="activeLabel($event)">
            <vue-tel-input :inputId="idInput" enabledCountryCode defaultCountry="MX" inputClasses="form-control" mode="international" :validCharactersOnly="true"
                :placeholder="inputPlaceholder" :value="value" v-bind="countriesFinal" @input="updateValue($event)" @validate="validate($event)"/>
        </div>

        <div v-if="badge" class="badge" :class="badgeType">{{badgeText}}</div>

        <div class="input-label" :class="{ 'active': labelActive }">
            <label class="" :for="idInput">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i v-if="warningIcon" aria-hidden="true" :class="warningIcon"></i>
            {{warningText}}
        </small>
    </div>
</template>

<script>
    export default {
        name: 'TelInput',
        mixins: [
            // inputValueMixin
        ],
        props: {
            allCountries: {type: Boolean, default: false},
            clazz: String,
            label: String,
            required: {type: Boolean, default: false},
            help: {type: Boolean, default: false},
            helpText: String,
            helpIcon: String,
            idHelpTooltip: String,
            idInput: String,
            inputPlaceholder: String,
            badge: {type: Boolean, default: false},
            badgeType: String,
            badgeText: String,
            warningIcon: String,
            warningText: String,
            value: String
        },
        data () {
            return {
                labelActive: false,
                allowCountries: [[
                    'Mexico (México)',
                    'mx',
                    '52',
                ]]
            }
        },
        computed: {
            countriesFinal() {
                if(this.allCountries) {
                    return {};
                }
                const allCountries = this.allowCountries.map(([name, iso2, dialCode]) => ({
                    name,
                    iso2: iso2.toUpperCase(),
                    dialCode,
                }));

                return {allCountries};
            }
        },
        methods: {
            updateValue(value) {
                this.$emit('input', value);
            },
            validate(value) {
                this.$emit('validate', value);
            },
            activeLabel: function() {
                this.labelActive = !this.labelActive;
            },
            changeActive(event) {
              const header = document.querySelector("#form-inputPhone");
              var isClickInside = header.contains(event.target);

              if (!isClickInside) {
                this.labelActive = false;
              } else {
                this.labelActive = true;
              }
            }
        },
        created () {
          window.addEventListener('click', this.changeActive);
        },
        beforeDestroy() {
          window.removeEventListener('click', this.changeActive);
        }
    }
</script>
