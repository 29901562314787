<template>
  <div>
    <div class="row">
      <div class="col-12">
        <TitleSteps
          :title="$t('myAccount.beneficiaries.titleCard')"
          :subtitle="$t('myAccount.beneficiaries.subtitleCard')"
        >
          <Tachometer
            clazzContent="leftContent"
            :percent="currentPercentage.toString()"
            text="Fondos asignados"
          />
        </TitleSteps>
      </div>

      <div class="col-12">
        <div v-for="(beneficiary, index) in beneficiaries" :key="index">
          <div class="collapse-beneficiary">
            <div class="head">
              <span>
                <BeneficiaryInfo
                  text="Beneficiario #1"
                  :textBold="beneficiary.name"
                  :imgUrl="
                    beneficiary.imageIndex < 0
                      ? imgBeneficiario
                      : images[beneficiary.imageIndex].src
                  "
                >
                  >
                </BeneficiaryInfo>
              </span>
              <span class="percentage">
                <label
                  >{{ $t("myAccount.beneficiaries.list.percentage") }}:</label
                >
                <InputEdit
                  inputType="text"
                  inputPlaceholder="0%"
                  :disabled="!beneficiary.update"
                  v-model="beneficiary.percentage"
                >
                </InputEdit>
              </span>
              <span>
                <button
                  class="button-square xs button-stroke button-accent"
                  type="button"
                  name="Colapsar beneficiario"
                  aria-label="Colapsar beneficiario"
                  v-b-toggle="'collapse-beneficiary' + index"
                >
                  <i
                    aria-hidden="true"
                    class="iconsax-arrow-direction-down"
                  ></i>
                  <i aria-hidden="true" class="iconsax-arrow-direction-up"></i>
                </button>
              </span>
            </div>
            <b-collapse
              :id="'collapse-beneficiary' + index"
              class="body"
              :visible="beneficiary.update"
            >
              <div class="row" v-if="beneficiary.update">
                <div class="col-12 m-b-30">
                  <label
                    class="f-14 c-text_title poppins-font-semibold f-600 d-block m-b-20"
                  >
                    {{ $t("myAccount.beneficiaries.list.selectAvatar") }}
                  </label>
                  <div class="security-measures-img">
                    <div
                      class="security-img-card c-pointer"
                      v-for="(image, indexImg) in images"
                      :key="image.id"
                      :class="
                        beneficiary.imageIndex === indexImg
                          ? 'selected'
                          : beneficiary.img
                          ? 'gray'
                          : ''
                      "
                      @click="selectImage(image.id, indexImg, beneficiary)"
                    >
                      <i
                        aria-hidden="true"
                        class="iconsax-essential-tick security-icon-selected"
                      ></i>
                      <img
                        class="img-fluid security-img-cover"
                        :src="image.src"
                        alt="Imagen seguridad"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <BasicInput
                    required
                    inputId=""
                    v-model="beneficiary.name"
                    :validations="getErrorV(index, 'name')"
                    :label="$t('myAccount.beneficiaries.list.name')"
                    :inputPlaceholder="
                      $t('myAccount.beneficiaries.list.namePh')
                    "
                  >
                  </BasicInput>
                </div>
                <div class="col-12 col-md-6">
                  <BasicSelect
                    required
                    :label="$t('myAccount.beneficiaries.list.relationship')"
                  >
                    <multiselect
                      v-model="beneficiary.relationship"
                      :options="optionsParentesco"
                      :close-on-select="true"
                      :preserve-search="true"
                      :placeholder="
                        $t('myAccount.beneficiaries.list.relationshipPh')
                      "
                      label="name"
                      track-by="name"
                      :preselect-first="false"
                    >
                      <template
                        slot="selection"
                        slot-scope="{ values, search, isOpen }"
                      >
                        <span
                          class="multiselect__single"
                          v-if="values.length &amp;&amp; !isOpen"
                        >
                          {{ values.length }} options selected
                        </span>
                      </template>
                    </multiselect>
                  </BasicSelect>
                </div>
                <div class="col-12 col-md-6">
                  <BasicInput
                    required
                    inputId=""
                    v-model="beneficiary.phone"
                    :validations="getErrorV(index, 'phone')"
                    :label="$t('myAccount.beneficiaries.list.phone')"
                    :inputPlaceholder="
                      $t('myAccount.beneficiaries.list.phonePh')
                    "
                  >
                  </BasicInput>
                </div>
                <div class="col-12">
                  <BasicInput
                    required
                    inputId=""
                    v-model="beneficiary.email"
                    :validations="getErrorV(index, 'email')"
                    :label="$t('myAccount.beneficiaries.list.email')"
                    :inputPlaceholder="
                      $t('myAccount.beneficiaries.list.emailPh')
                    "
                  >
                  </BasicInput>
                </div>
                <div class="col-12">
                  <button
                    id=""
                    type="button"
                    class="button button-filled button-primary w-100"
                    :disabled="getDisabled(index)"
                    @click="saveBen(index)"
                  >
                    {{ $t("myAccount.beneficiaries.list.saveBeneficiary") }}
                  </button>
                  <button
                    type="button"
                    class="button button-ghost button-error w-100 p-0 m-t-10"
                    @click="deleteBen(index)"
                  >
                    {{ $t("myAccount.beneficiaries.list.deleteBeneficiary") }}
                  </button>
                </div>
              </div>
              <div class="row" v-if="!beneficiary.update">
                <div class="col-12 col-md-6">
                  <ReadOnlyInfo
                    :title="$t('myAccount.beneficiaries.list.name')"
                    :text="beneficiary.name"
                  ></ReadOnlyInfo>
                </div>
                <div class="col-12 col-md-6">
                  <ReadOnlyInfo
                    :title="$t('myAccount.beneficiaries.list.relationship')"
                    :text="beneficiary.relationship.name"
                  ></ReadOnlyInfo>
                </div>
                <div class="col-12 col-md-6">
                  <ReadOnlyInfo
                    :title="$t('myAccount.beneficiaries.list.phone')"
                    :text="beneficiary.phone"
                  ></ReadOnlyInfo>
                </div>
                <div class="col-12 col-md-6">
                  <ReadOnlyInfo
                    :title="$t('myAccount.beneficiaries.list.email')"
                    :text="beneficiary.email"
                  ></ReadOnlyInfo>
                </div>
                <div class="col-12">
                  <div class="control-buttons buttons-full-w buttons-full-w-xs">
                    <button
                      type="button"
                      class="button button-ghost button-error w-100 p-0"
                      @click="deleteBen(index)"
                    >
                      {{ $t("myAccount.beneficiaries.list.deleteBeneficiary") }}
                    </button>
                    <button
                      type="button"
                      class="button button-stroke button-primary w-100 p-0"
                      @click="editBen(index)"
                    >
                      {{ $t("myAccount.beneficiaries.list.editBeneficiary") }}
                    </button>
                  </div>
                </div>
              </div>
            </b-collapse>
          </div>
        </div>
        <button
          v-if="100 - currentPercentage > 0"
          id="addBeneficiary"
          type="button"
          class="button-square-add"
          @click="addBen"
        >
          <i aria-hidden="true" class="iconsax-essential-add m-r-10"></i>
          {{ $t("myAccount.beneficiaries.addBeneficiary") }}
        </button>
      </div>
    </div>

    <div class="footer-buttons right">
      <button
        type="button"
        class="button button-filled button-primary"
        name="Continuar"
        :disabled="disableSave"
        @click="submit"
      >
        {{ $t("register.securityMeasure.continue") }}
      </button>
    </div>
  </div>
</template>

<script>
import BasicInput from "@/components/BasicInput";
import TitleSteps from "@/components/TitleSteps.vue";
import InputEdit from "@/components/InputEdit.vue";
import BasicSelect from "@/components/BasicSelect.vue";
import Multiselect from "vue-multiselect";
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";

import UserApi from "@/api/users/users.api";
import { catchError } from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import Tachometer from "@/components/Tachometer";
import BeneficiaryInfo from "@/components/BeneficiaryInfo.vue";
import imgBeneficiario from "@/assets/images/Backgrounds/Beneficiary/hombre-naranja.svg";
import {
  between,
  email,
  maxLength,
  minLength,
  required,
} from "vuelidate/lib/validators";
import UsersApi from "@/api/users/users.api";
import { beneficiariesImages } from "@/enums/securityBeneficiariesImg";
import {
  GET_LEVEL,
  GET_CARD_REQUEST,
  GO_TO_ROUTE,
  STORE as onbngStore,
  GET_LOCATION,
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import { ONBOARDING_STEPS } from "@/utils/onbngSteps";
import {
  GET_USER,
  SET_USER,
  STORE as sessionStore,
} from "@/store/modules/session/session.types";
import { mapGetters } from "vuex";
const each = "$each";

export default {
  name: "BeneficiariesRegister",
  mixins: [ToastSnotify],
  components: {
    BasicInput,
    Tachometer,
    BeneficiaryInfo,
    TitleSteps,
    InputEdit,
    BasicSelect,
    Multiselect,
    ReadOnlyInfo,
  },
  computed: {
    ...mapGetters(sessionStore, {
      user: GET_USER,
    }),
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      location: GET_LOCATION,
      cardRequest: GET_CARD_REQUEST,
    }),
    disableSave() {
      if (
        this.$v.beneficiaries.$invalid ||
        this.beneficiaries.length <= 0 ||
        this.currentPercentage < 100 ||
        this.currentPercentage > 100
      ) {
        return true;
      }
      return this.beneficiaries.some((a) => a.update);
    },
  },
  data() {
    return {
      imgBeneficiario,
      beneficiaries: [],
      optionsParentesco: [
        {
          name: this.$i18n.t("myAccount.beneficiaries.relationship.son"),
          value: "SON",
        },
        {
          name: this.$i18n.t("myAccount.beneficiaries.relationship.daughter"),
          value: "DAUGHTER",
        },
        {
          name: this.$i18n.t("myAccount.beneficiaries.relationship.father"),
          value: "FATHER",
        },
        {
          name: this.$i18n.t("myAccount.beneficiaries.relationship.mother"),
          value: "MOTHER",
        },
        {
          name: this.$i18n.t("myAccount.beneficiaries.relationship.brother"),
          value: "BROTHER",
        },
        {
          name: this.$i18n.t("myAccount.beneficiaries.relationship.sister"),
          value: "SISTER",
        },
        {
          name: this.$i18n.t("myAccount.beneficiaries.relationship.spouse"),
          value: "SPOUSE",
        },
        {
          name: this.$i18n.t(
            "myAccount.beneficiaries.relationship.grandfather"
          ),
          value: "GRANDFATHER",
        },
        {
          name: this.$i18n.t(
            "myAccount.beneficiaries.relationship.grandmother"
          ),
          value: "GRANDMOTHER",
        },
      ],
      currentPercentage: 0,
      showAddBeneficiaries: false,
      showSave: false,
      images: beneficiariesImages,
    };
  },
  methods: {
    async submit() {
      this.showSave = false;
      if (this.currentPercentage === 100) {
        const res = await UsersApi.saveBeneficiaryAsList({
          beneficiaries: this.beneficiaries,
          isForRegister: true
        }).catch(catchError);
        if (res.data && res.data.error) {
          this.displayNotificationError(
            this.$i18n.t("myAccount.beneficiaries.errors.saving")
          );
        } else {
          this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.CONTRACT,
          });

          let goTo = "contract";

          if (this.cardRequest.accountType === "BUSINESS") {
            goTo = "businessContract";
          }
          this.$router.push({ name: goTo });
        }
      }
      this.showSave = true;
    },
    saveBen(index) {
      this.doPercentage();
      if (this.currentPercentage > 100) {
        this.displayNotificationWarning(
          this.$i18n.t("myAccount.beneficiaries.errors.percentageExceeded")
        );
      } else {
        this.beneficiaries[index].pagandoAccount =
          this.cardRequest.pagandoAccount;
        this.beneficiaries[index].update = false;
      }
    },
    doPercentage() {
      this.currentPercentage = this.beneficiaries.reduce(
        (a, b) => a + (b.deleted.isDeleted ? 0 : parseFloat(b.percentage)),
        0
      );
    },
    getDisabled(index) {
      return this.$v.beneficiaries[each][index].$invalid;
    },
    getErrorV(index, param) {
      return this.$v.beneficiaries[each][index][param];
    },
    editBen(index) {
      this.beneficiaries[index].update = true;
    },
    deleteBen(index) {
      if (index > -1) {
        this.beneficiaries.splice(index, 1);
      }
      this.doPercentage();
    },
    addBen() {
      const reminder = 100 - this.currentPercentage;
      if (reminder <= 0) {
        this.displayNotificationWarning(
          this.$i18n.t("myAccount.beneficiaries.errors.noMore")
        );
      } else {
        this.beneficiaries.push({
          name: "",
          percentage: reminder,
          relationship: "",
          email: "",
          phone: "",
          pagandoAccount: "",
          img: "",
          imageIndex: -1,
          deleted: {
            isDeleted: false,
          },
          update: true,
        });
      }
    },
    addBeneficiaries() {
      this.showAddBeneficiaries = true;
    },
    getRelationship(type) {
      switch (type) {
        case "SON":
          return this.$i18n.t("myAccount.beneficiaries.relationship.son");
        case "DAUGHTER":
          return this.$i18n.t("myAccount.beneficiaries.relationship.daughter");
        case "FATHER":
          return this.$i18n.t("myAccount.beneficiaries.relationship.father");
        case "MOTHER":
          return this.$i18n.t("myAccount.beneficiaries.relationship.mother");
        case "BROTHER":
          return this.$i18n.t("myAccount.beneficiaries.relationship.brother");
        case "SISTER":
          return this.$i18n.t("myAccount.beneficiaries.relationship.sister");
        case "SPOUSE":
          return this.$i18n.t("myAccount.beneficiaries.relationship.spouse");
        case "GRANDFATHER":
          return this.$i18n.t(
            "myAccount.beneficiaries.relationship.grandfather"
          );
        case "GRANDMOTHER":
        default:
          return this.$i18n.t(
            "myAccount.beneficiaries.relationship.grandmother"
          );
      }
    },
    getImage(img) {
      const imgBen = beneficiariesImages.find((i) => i.id === img);
      if (imgBen) {
        return imgBen.src;
      }
      return beneficiariesImages[0].src;
    },
    back() {
      if (this.level === 0) {
        this.$store.dispatch(`${onbngStore}/backCollaborator`);
      } else if (this.cardRequest.accountType === "BUSINESS") {
        this.$store.dispatch(`${onbngStore}/backBusiness`);
      } else {
        this.$store.dispatch(`${onbngStore}/backPersonal`);
      }
    },
    selectImage(id, index, beneficiary) {
      if (beneficiary.imageIndex >= 0 && beneficiary.imageIndex === index) {
        beneficiary.imageIndex = -1;
        beneficiary.img = "";
      } else {
        beneficiary.img = id;
        beneficiary.imageIndex = index;
      }
    },
    async submitMeasures() {
      this.showConfirm = false;

      let response;

      if (this.level === 0) {
        response = await UserApi.setCollaboratorSecurity({
          securityPhrase: this.securityPhrase,
          securityImageId: this.currentImage,
        }).catch(catchError);
      } else if (this.level === 1 || this.cardRequest.isMoral) {
        response = await UserApi.finishLevelOne({
          securityPhrase: this.securityPhrase,
          securityImageId: this.currentImage,
          setFinishRegister: false,
          cardRequestInfoId: this.cardRequest._id,
        }).catch(catchError);
      } else if (this.level === 2) {
        response = await UserApi.finishLevelTwo({
          securityPhrase: this.securityPhrase,
          securityImageId: this.currentImage,
          setFinishRegister: false,
        }).catch(catchError);
      } else {
        response = await UserApi.finishLevelThree({
          securityPhrase: this.securityPhrase,
          securityImageId: this.currentImage,
          setFinishRegister: false,
        }).catch(catchError);
      }

      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(
            this.$i18n.t("register.securityMeasure.errorUpdate")
          );
        } else {
          const user = response.data.data.user;
          this.$store.commit(`${sessionStore}/${SET_USER}`, user);
          this.nextStep();
        }
      }
      this.showConfirm = true;
    },
    nextStep() {
      if (this.level === 0) {
        this.$store.dispatch(
          `${onbngStore}/${GO_TO_ROUTE}`,
          "newPinCollaborator"
        );
      } else if (this.cardRequest.isMoral) {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.WAIT_REQUEST,
        });
        this.$store.dispatch(
          `${onbngStore}/${GO_TO_ROUTE}`,
          "businessProcessingRequest"
        );
      } else {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.CREATE_NIP,
        });
        let goTo = "newPin";
        if (this.cardRequest.accountType === "BUSINESS") {
          goTo = "businessNewPin";
        }
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, goTo);
      }
    },
    async loadBeneficiaries() {
      if (this.cardRequest.pagandoAccount) {
        const res = await UsersApi.beneficiaryList({
          pagandoAccount: this.cardRequest.pagandoAccount,
        });
        if (res.data && !res.data.error && res.data.object.length) {
          const beneficiaries = res.data.object;
          for (const beneficiary of beneficiaries) {
            beneficiary.imageIndex = beneficiariesImages.findIndex(
              (i) => beneficiary.img === i.id
            );
            beneficiary.relationship = this.optionsParentesco.find(
              (p) => p.value === beneficiary.relationship
            );
            beneficiary.update = false;
          }
          this.beneficiaries = beneficiaries;
          this.doPercentage();
        }
      }
    },
  },
  async beforeMount() {
    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
    await this.loadBeneficiaries();
  },
  validations: {
    beneficiaries: {
      $each: {
        email: {
          required,
          email,
        },
        name: {
          required,
          minLength: minLength(3),
        },
        phone: {
          required,
          minLength: minLength(10),
          maxLength: maxLength(10),
        },
        percentage: {
          required,
          between: between(1, 100),
        },
        relationship: {
          required,
        },
        img: {
          required,
        },
      },
    },
  },
};
</script>

<style scoped>
</style>
