<template>
<div>
  <div v-if="showLoading">
    <LoadingSimple
        clazz="big-load p-t-50 p-b-50"
        label="Loading..."
    ></LoadingSimple>
  </div>
  <div v-else>
    <img class="img-fluid max-h-300px d-flex m-0-auto"
         src="@/assets/images/Illustrations/Illustration-codigo-promocional.svg"
         alt="Ingresa el código promocional"/>
    <TitlePrincipal clazz="text-align-c m-t-25"
      :title="campaigns.length === 1 ? $tc('register.promoCode.titleWithReward', campaigns[0].reward) : $t('register.promoCode.title')"
      :subtitle="campaigns.length === 1 ? $tc('register.promoCode.subtitleWithReward', campaigns[0].reward) : $t('register.promoCode.subtitle')">
    </TitlePrincipal>
    <div class="row">
      <div class="col-12 col-sm-8">
        <BasicInput
            v-model="code"
            clazz="m-b-0"
            inputType="text"
            inputId="user_promo_code"
            :label="$t('register.promoCode.label')"
            :inputPlaceholder="$t('register.promoCode.labelPH')"/>
      </div>
      <div class="col-12 col-sm-4 p-t-20">
        <button type="button" class="button sm button-filled button-primary m-0-auto" name="Aplicar" @click="applyReferral" :disabled="!code">
          {{ $t('register.promoCode.apply') }} </button>
      </div>
    </div>
    <div class="footer-buttons center">
      <button type="button" class="button button-stroke button-primary" name="Omitir" @click="skipStep">
        {{ $t('register.promoCode.omit') }} </button>
    </div>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import BasicInput from "@/components/BasicInput";
import LoadingSimple from "@/components/LoadingSimple";
import CouponsApi from "@/api/core/coupons.api";
import {catchError} from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {
  GET_CARD_REQUEST,
  STORE as onbngStore,
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {mapGetters} from "vuex";

export default {
  name: "PromotionalCode",
  components: {
    TitlePrincipal,
    BasicInput,
    LoadingSimple
  },
  mixins: [ToastSnotify],
  data() {
    return {
      showLoading: false,
      campaigns: [],
      code: ""
    }
  },
  computed: {
    ...mapGetters(onbngStore, {
      cardRequest: GET_CARD_REQUEST,

    }),
  },
  methods: {
    async applyReferral() {
      this.showLoading = true;
      const response = await CouponsApi.referralCode({code: this.code}).catch(catchError);
      if (response && response.data) {
          if (response.data.error) {
            this.displayNotificationError('No se pudo obtener su recompensa. Por favor contacte a soporte');
          } else {
            let goTo = "onbngWelcome";
            if (this.cardRequest.accountType === 'BUSINESS') {
              goTo = "businessOnbngWelcome"
            }
            this.$router.push({name: goTo});
          }
      } else {
        this.displayNotificationError('No se pudo obtener su recompensa. Por favor contacte a soporte');
      }

      this.showLoading = false;
    },
    async checkCampaigns() {
      const responseCampaigns = await CouponsApi.getPublicCampaigns({type: 'REFERRED'});
      if (responseCampaigns && responseCampaigns.data && !responseCampaigns.data.error) {
        this.campaigns = responseCampaigns.data.object
      }
    },
    skipStep() {
      let goTo = "onbngWelcome";
      if (this.cardRequest.accountType === 'BUSINESS') {
        goTo = "businessOnbngWelcome"
      }
      this.$router.push({name: goTo});
    }
  },
  created() {
    this.checkCampaigns();
  }
}
</script>

<style scoped>

</style>
