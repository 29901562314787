<template>
  <div>
    <CardSimple
      :title="$t('myAccount.generalInfo.title')"
      :subtitle="$t('myAccount.generalInfo.subtitle')"
    >
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <ReadOnlyInfo :title="$t('myAccount.generalInfo.noCuenta')" :text="info.general.account.fullKey"/>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <ReadOnlyInfo :title="$t('general.CLABE')" :text="info.general.account.clabe"/>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <ReadOnlyInfo :title="$t('myAccount.generalInfo.accountType')" :text="$t('myAccount.generalInfo.accountTypeDes')"/>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <ReadOnlyInfo :title="$t('myAccount.generalInfo.cutoffDate')" :text="cutoffDate"/>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-show="virtualCardNumber">
          <ReadOnlyInfo :title="$t('myAccount.generalInfo.virtualCard')" :text="virtualCardNumber"/>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-show="physicCardNumber">
          <ReadOnlyInfo :title="$t('myAccount.generalInfo.physicCard')" :text="physicCardNumber"/>
        </div>
      </div>
    </CardSimple>
    <div class="row">
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
          icon="iconsax-content-archive-book"
          :titleText="$t('myAccount.generalInfo.modals.contrato.title_card')"
          :text="$t('myAccount.generalInfo.modals.contrato.subtitle_card')"
        >
          <a class="c-pointer" tabindex="" aria-label="Contrato" @click="openDocumentModal('CONTRACT')">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
          icon="iconsax-money-currency-square"
          :titleText="$t('myAccount.generalInfo.modals.commissions.title_card')"
          :text="$t('myAccount.generalInfo.modals.commissions.subtitle_card')"
        >
          <a class="c-pointer" tabindex="" aria-label="Comisiones" @click="openModalCommissions">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
          icon="iconsax-content-task-square"
          :titleText="$t('myAccount.generalInfo.modals.termsCond.title_card')"
          :text="$t('myAccount.generalInfo.modals.termsCond.subtitle_card')"
        >
          <a class="c-pointer" tabindex="" aria-label="Términos y condiciones" @click="openDocumentModal('TERMS_CONDITIONS')">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
          icon="iconsax-essential-info-circle"
          :titleText="$t('myAccount.generalInfo.modals.addInfo.title_card')"
          :text="$t('myAccount.generalInfo.modals.addInfo.subtitle_card')"
        >
          <a class="c-pointer" tabindex="" aria-label="Información adicional" @click="openModalAddInfo">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
          icon="iconsax-security-key-square"
          :titleText="$t('myAccount.generalInfo.modals.privacy.title_card')"
          :text="$t('myAccount.generalInfo.modals.privacy.subtitle_card')"
        >
          <a class="c-pointer" tabindex="" aria-label="Aviso de privacidad" @click="openDocumentModal('PRIVACY_AGREEMENT')">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
    </div>

    <!-- MODAL CONTRATO CUENTA -->
    <b-modal id="modal-contrato-cuenta" size="lg" hide-header hide-footer>
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-contrato-cuenta')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="modal-terminos-privacidad">
        <h2 class="title">{{ $t("myAccount.generalInfo.modals.contrato.title") }}</h2>
        <span class="date-update" v-if="contract">
          <small>{{ $t("myAccount.generalInfo.modals.lastUpdate") }}</small>
          {{ getUpdateAt(contract) }}
        </span>
        <div v-if="loadModal" class="text-container">
          <loading-bar :loadingText="$t('myAccount.generalInfo.modals.loadInfo')"/>
        </div>
        <div v-else class="text-container" v-html="contract ? contract.documentAcceptedContent : ''"/>
        <button
          :disabled="contract === null"
          v-if="showDownload"
          id="contractDownload"
          type="button"
          class="button button-filled button-primary"
          @click="downloadFile(contract ? contract.documentPDF : '', 'contract')"
        >
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          {{ $t("myAccount.generalInfo.modals.download") }}
        </button>
        <button v-else :id="'Loading button'" class="button button-filled button-primary">
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          <LoadingSimple clazz="small-load no-text" />
        </button>
      </div>
    </b-modal>

    <!-- MODAL TERMINOS -->
    <b-modal id="modal-terminos" size="lg" hide-header hide-footer>
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-terminos')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="modal-terminos-privacidad">
        <h2 class="title">{{ $t("myAccount.generalInfo.modals.termsCond.title") }}</h2>
        <span class="date-update" v-if="termsCond">
          <small>{{ $t("myAccount.generalInfo.modals.lastUpdate") }}</small>
          {{ getUpdateAt(termsCond) }}
        </span>
        <div v-if="loadModal" class="text-container">
          <loading-bar :loadingText="$t('myAccount.generalInfo.modals.loadInfo')"/>
        </div>
        <div v-else class="text-container" v-html="termsCond ? termsCond.documentAcceptedContent : ''"/>
        <button
          :disabled="termsCond === null"
          v-if="showDownload"
          id="TermsDownload"
          type="button"
          class="button button-filled button-primary"
          @click="downloadFile(termsCond ? termsCond.documentPDF : '', 'términos y condiciones')"
        >
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          {{ $t("myAccount.generalInfo.modals.download") }}
        </button>
        <button v-else :id="'Loading button term'" class="button button-filled button-primary">
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          <LoadingSimple clazz="small-load no-text" />
        </button>
      </div>
    </b-modal>

    <!-- MODAL AVISO -->
    <b-modal id="modal-privacidad" size="lg" hide-header hide-footer>
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-privacidad')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="modal-terminos-privacidad">
        <h2 class="title">{{ $t("myAccount.generalInfo.modals.privacy.title") }}</h2>
        <span class="date-update" v-if="privacy">
          <small>{{ $t("myAccount.generalInfo.modals.lastUpdate") }}</small>
          {{ getUpdateAt(privacy) }}
        </span>
        <div v-if="loadModal" class="text-container">
          <loading-bar :loadingText="$t('myAccount.generalInfo.modals.loadInfo')"/>
        </div>
        <div v-else class="text-container" v-html="privacy ? privacy.documentAcceptedContent : ''"/>
        <button
          :disabled="privacy === null"
          v-if="showDownload"
          id="privacyDownload"
          type="button"
          class="button button-filled button-primary"
          @click="downloadFile(privacy ? privacy.documentPDF : '', 'aviso de privacidad')"
        >
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          {{ $t("myAccount.generalInfo.modals.download") }}
        </button>
        <button v-else :id="'Loading button privacy'" class="button button-filled button-primary">
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          <LoadingSimple clazz="small-load no-text" />
        </button>
      </div>
    </b-modal>

    <!-- MODAL COMISIONES -->
    <b-modal id="modal-comisiones" size="lg" hide-header hide-footer modal-class="modal-default left-oriented">
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-comisiones')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{ $t("myAccount.generalInfo.modals.commissions.title") }}</h2>
        <p class="modal-subtitle">{{ $t("myAccount.generalInfo.modals.commissions.subtitle") }}</p>
      </div>
      <div class="body">
        <div v-if="loadModal" class="text-container">
          <loading-bar :loadingText="$t('myAccount.generalInfo.modals.loadInfo')"/>
        </div>
        <div v-else class="row">
          <div class="col-12 col-md-4" v-for="comObj in commissions" :key="comObj._id">
            <ReadOnlyInfo
              :title="$t(comObj.title)"
              :text="comObj.cost | currency"
              v-if="comObj.events !== 'CLIENT_DISPERSION'"
            >
              <template v-if="comObj.freeEvents">
                <p class="f-10">
                  {{$tc(`myAccount.generalInfo.modals.commissions.freeEvents.${comObj.period}`, comObj.quantity, comObj)}}
                </p>
              </template>
            </ReadOnlyInfo>
            <ReadOnlyInfo
              :title="$t(comObj.title)"
              :text="`${comObj.cost}% + IVA`"
              v-if="comObj.events === 'CLIENT_DISPERSION'"
            >
              <template v-if="comObj.freeEvents">
                <p class="f-10">
                  {{$tc(`myAccount.generalInfo.modals.commissions.freeEvents.${comObj.period}`, comObj.quantity, comObj)}}
                </p>
              </template>
            </ReadOnlyInfo>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- MODAL INFO ADICIONAL -->
    <b-modal id="modal-info-adicional" size="lg" hide-header hide-footer modal-class="modal-default left-oriented">
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-info-adicional')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{ $t("myAccount.generalInfo.modals.addInfo.title") }}</h2>
        <p class="modal-subtitle">{{ $t("myAccount.generalInfo.modals.addInfo.subtitle") }}</p>
      </div>
      <div class="body">
        <div v-if="loadModal">
          <loading-bar :loadingText="$t('myAccount.generalInfo.modals.loadInfo')"/>
        </div>
        <b-tabs v-else class="simple-tabs" content-class="simple-tabs-body p-t-30 p-b-30">
          <b-tab :title="$t('myAccount.generalInfo.modals.addInfo.responsibilities')" active>
            <ul class="checked-list-primary">
              <li v-for="(res, index) in addInfo.responsibilities" :key="index">{{ res }}</li>
            </ul>
          </b-tab>
          <b-tab :title="$t('myAccount.generalInfo.modals.addInfo.risks')">
            <ul class="checked-list-primary">
              <li v-for="(risk, index) in addInfo.risks" :key="index">{{ risk }}</li>
            </ul>
          </b-tab>
        </b-tabs>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CardSimple from "@/components/CardSimple";
import ReadOnlyInfo from "@/components/ReadOnlyInfo";
import PanelLinkGoTo from "@/components/PanelLinkGoTo";
import { catchError, lastDateMonth, getMonthByNo } from "@/utils/utils";
import DocumentsApi from "@/api/core/documents.api";
import AccountTypesApi from "@/api/core/accountTypes.api";
import CardTemplateApi from "@/api/core/cardTemplate.api";
import FileApi from "@/api/core/files.api";
import LoadingBar from "@/components/LoadingBar";
import LoadingSimple from "@/components/LoadingSimple";

const commissionTitles = {
  CARD_REQUEST: "myAccount.generalInfo.modals.commissions.events.card_request",
  BALANCE_INQUIRY: "myAccount.generalInfo.modals.commissions.events.balance_inquiry",
  SPEI_TRANSFER: "myAccount.generalInfo.modals.commissions.events.spei_transfer",
  SPEI_INCOME: "myAccount.generalInfo.modals.commissions.events.spei_income",
  PAGANDO_TRANSFER: "myAccount.generalInfo.modals.commissions.events.pagando_transfer",
  CASH_COMMERCE: "myAccount.generalInfo.modals.commissions.events.cash_commerce",
  CASH_ATM: "myAccount.generalInfo.modals.commissions.events.cash_atm",
  CASH_INFLOW: "myAccount.generalInfo.modals.commissions.events.cash_inflow",
  RESOURCERS_ABROAD: "myAccount.generalInfo.modals.commissions.events.resourcers_abroad",
  CARD_REPLACEMENT: "myAccount.generalInfo.modals.commissions.events.card_replacement",
  CANCEL_ACCOUNT: "myAccount.generalInfo.modals.commissions.events.cancel_account",
  MONTHLY_SUBSCRIPTION: "myAccount.generalInfo.modals.commissions.events.monthly_subscription",
  ACCOUNT_OPENING: "myAccount.generalInfo.modals.commissions.events.account_opening",
  CLIENT_DISPERSION: "myAccount.generalInfo.modals.commissions.events.dispersion_layout",
};

export default {
  name: "CorporateActivityGeneralInfo",
  components: {
    CardSimple,
    ReadOnlyInfo,
    PanelLinkGoTo,
    LoadingBar,
    LoadingSimple,
  },
  props: {
    info: Object,
  },
  data() {
    return {
      loadModal: false,
      showDownload: true,
      contract: null,
      termsCond: null,
      privacy: null,
      commissions: [],
      addInfo: {
        risks: [],
        responsibilities: [],
      },
      commissionCancel: 0,
    };
  },
  computed: {
    virtualCardNumber() {
      const card = this.info.cards.find((c) => c.manufacturingType === "VIRTUAL");
      if (!card) {
        return null;
      }
      // Censura todos los digitos excepto los ultimos 4
      return `**** **** **** ${card.pan.slice(-4)}`;
    },
    physicCardNumber() {
      const card = this.info.cards.find((c) => c.manufacturingType === "PHYSIC");
      if (!card) {
        return null;
      }
      // Censura todos los digitos excepto los ultimos 4
      return `**** **** **** ${card.pan.slice(-4)}`;
    },
    cutoffDate() {
      const lastBill = this.info.general.account.lastBillingDate;
      if (!lastBill) {
        return this.$i18n.t("myAccount.generalInfo.billingDate.entMonth");
      }
      let day = new Date(Number(lastBill)).getDate();
      if (day >= 29) {
        const today = new Date();
        const lastDate = lastDateMonth(today.getMonth(), today.getFullYear());
        day = lastDate.getDate();
      }
      return this.$i18n.t("myAccount.generalInfo.billingDate.everyMonth", {day});
    },
  },
  methods: {
    getUpdateAt(doc) {
      let date;
      if (doc?.updatedAt) {
        try {
          date = new Date(doc.updatedAt);
        } catch (err) {
          date = new Date();
        }
      } else {
        date = new Date();
      }
      return `${date.getDate()} ${this.$i18n.t(
        getMonthByNo(date.getMonth())
      )} ${date.getFullYear()}`;
    },
    async openDocumentModal(type) {
      const types = {
        CONTRACT: { modal: "modal-contrato-cuenta", doc: 'contract' },
        TERMS_CONDITIONS: { modal: "modal-terminos", doc: 'termsCond' },
        PRIVACY_AGREEMENT: { modal: "modal-privacidad", doc: 'privacy' },
      };
      const modalId = types[type].modal;
      const doc = types[type].doc;
      this.$bvModal.show(modalId);
      if (!this[doc]) {
        try {
          this.loadModal = true;
          const id = this.info.general.accountId;
          const response = await DocumentsApi.findDocumentAcceptedCorporative({id, type});
          this[doc] = response.data.object;
        } catch (error) {
          this.displayNotificationWarning(this.$i18n.t("myAccount.generalInfo.modals.errorFind"));
          this.$bvModal.hide(modalId);
        }
        this.loadModal = false;
      }
    },
    async openModalCommissions() {
      this.loadModal = true;
      this.$bvModal.show("modal-comisiones");
      if (!this.commissions.length) {
        const id = this.info.general.accountType._id;
        const res = await AccountTypesApi.getCommissions(id).catch(catchError);
        if (res.data && !res.data.error) {
          this.commissions = res.data.object.map((commission) => ({
            id: commission._id,
            cost: commission.cost,
            freeEvents: commission.freeEvents,
            quantity: commission.quantity,
            period: commission.period,
            events: commission.events,
            title: commissionTitles[commission.events],
          }));
        } else {
          this.displayNotificationWarning(this.$i18n.t("myAccount.generalInfo.modals.commissions.error"));
        }
      }
      this.loadModal = false;
    },
    async openModalAddInfo() {
      this.loadModal = true;
      this.$bvModal.show("modal-info-adicional");
      if (!this.addInfo.risks.length || !this.addInfo.responsibilities.length) {
        const templateId = this.info.general.accountType.template._id;
        const res = await CardTemplateApi.getAddInfo(templateId, {accountType: 'CORPORATE_ACCOUNT'}).catch(catchError);
        if (res.data && !res.data.error) {
          const template = res.data.object;
          if (template) {
            this.addInfo.risks = template.risks[this.$i18n.locale];
            this.addInfo.responsibilities = template.responsabilities[this.$i18n.locale];
          }
        } else {
          this.displayNotificationWarning(this.$i18n.t("myAccount.generalInfo.modals.addInfo.error"));
        }
      }
      this.loadModal = false;
    },
    async downloadFile(id, name) {
      this.showDownload = false;
      const response = await FileApi.downloadSignedUrl({ id }).catch(catchError);
      if (response?.data) {
        const link = document.createElement("a");
        const url = response.data.object;
        link.setAttribute("href", url);
        link.setAttribute("download", name);
        link.click();
      } else {
        this.displayNotificationWarning(this.$i18n.t("myAccount.generalInfo.modals.errorFind"));
      }
      this.showDownload = true;
    },
  },
};
</script>
