import axios from "axios";
import store from "@/store/index";
import {catchError} from "@/utils/utils";
import {
    STORE as SessionStore,
    SET_USER,
    SET_TOKEN,
    SET_LOADING,
    SET_ACCOUNT,
    // SET_TOKEN_EXPIRE_DATE
} from "@/store/modules/session/session.types";
import UserApi from "@/api/users/users.api";
import {
    STORE as onbngStore
  } from "@/store/modules/onboardingSteps/onboardingSteps.types";
  import {
    STORE as organizationInfoStore
  } from "@/store/modules/organizationInfo/organizationInfo.types";

const beforeMount = async function() {
    store.commit(`${SessionStore}/${SET_LOADING}`, true);
    const currentToken = localStorage.getItem('currentToken');
    axios.defaults.headers.common['X-Request-Platform'] = "WEB_CLIENT";
    const currentLocale = localStorage.getItem('currentLocale');
    if (!currentLocale) {
        localStorage.setItem("currentLocale", "es");
    }
    axios.defaults.headers.common['Accept-Language'] = currentLocale || "es";

    // axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
    if (currentToken) {
        axios.defaults.headers.common.Authorization = `Bearer ${currentToken.replace(/"/g, "")}`;
        store.commit(`${SessionStore}/${SET_TOKEN}`, JSON.parse(currentToken));
        const response = await UserApi.getCurrentUserSession({isForWallet: true}).catch(catchError);

        if (response && response.data) {
            if (response.data.error) {
                // this.displayNotificationError('Error obteniendo usuario');
                deleteData();
            } else {
              await processSuccessDataUser(response);
            }
        } else {
            deleteData();
        }

    } else {
        store.commit(`${SessionStore}/${SET_USER}`, null);
        store.commit(`${SessionStore}/${SET_LOADING}`, false);
    }
};

function deleteData() {
    store.commit(`${SessionStore}/${SET_TOKEN}`, "");
    store.commit(`${SessionStore}/${SET_USER}`, null);
    store.commit(`${SessionStore}/${SET_ACCOUNT}`, {
        account: {},
        cards: [],
        template: {}
    });
    localStorage.removeItem('currentToken');
    delete axios.defaults.headers.common.Authorization;
    store.commit(`${SessionStore}/${SET_LOADING}`, false);
}

async function processSuccessDataUser(response) {
    store.commit(`${SessionStore}/${SET_USER}`, response.data.user);
    store.commit(`${onbngStore}/SET_TYPE_ACCOUNT`, !response.data.user.onbForBusiness);
    if (response.data.user) {
        if (!response.data.user.walletUser) {
            const hasError = !!response.data.user.errorOnboardingCollaborator;
            store.commit(`${onbngStore}/SET_GO_TO_WAIT`, hasError);
            await getData(response.data.user);
        } else {
            if(response.data.user.contract !== 2 && response.data.user.contract !== 4) {
                await store.dispatch(`${SessionStore}/loadAccount`)
            }
        }
    }
    store.commit(`${SessionStore}/${SET_LOADING}`, false);
}

async function getData(user) {
    await store.dispatch(`${onbngStore}/getProvisionalCards`);

    const cardRequest = store.getters[`${onbngStore}/GET_CARD_REQUEST`];
    if (cardRequest && cardRequest.status !== 'DELIVERED' && cardRequest.cardType !== 'PHYSIC') {

        if(cardRequest.accountType === 'BUSINESS' && cardRequest.organizationInfo) {
            await store.dispatch(`${organizationInfoStore}/getOrganization`, { id: cardRequest.organizationInfo });
        }
        await store.dispatch(`${SessionStore}/getFullDataByLevel`, {level: cardRequest.information_level});
    } else {
        if(!user.walletUser && user.stepOnboardingCollaborator) {
        await store.dispatch(`${SessionStore}/getFullDataByLevel`, {level: 'LEVEL_TWO'});

            await store.dispatch(`${SessionStore}/loadAccountCollab`, user._id )
        }
    }

    store.commit(`${SessionStore}/${SET_LOADING}`, false);
}

export {
    beforeMount,
    getData
}



