<template>
  <div class="row">
    <div class="col-12">
      <router-link
        :to="listRoute"
        class="button xs button-ghost button-text_info p-l-0 p-r-0"
        tabindex=""
      >
        <i
          aria-hidden="true"
          class="iconsax-arrow-arrow-left f-18 c-info m-r-10"
        ></i>
        {{$t('corporativeAccounts.details.return')}}
      </router-link>
      <FloatingTitleLayout
        :titleStrong="$t('corporativeAccounts.details.header')"
      ></FloatingTitleLayout>
    </div>
    <div class="col-12">
      <CardSimple
        v-if="details"
        :title="$t('corporativeAccounts.details.title')"
        :subtitle="$t('corporativeAccounts.details.subtitle')"
      >
        <div class="row">
          <div class="col-12" v-if="details.blockDate">
            <b-alert class="alert-note" show variant="info">
              <p class="text-container">
                <i aria-hidden="true" class="icon iconsax-emails-message-bold"></i>
                <span v-html="$t('corporativeAccounts.details.blockDate', blockDate)"/>
              </p>
            </b-alert>
          </div>
          <div class="col-12 col-md-6">
            <ReadOnlyInfo
              :title="$t('corporativeAccounts.details.alias')"
              :text="details.alias"
            ></ReadOnlyInfo>
          </div>
          <div class="col-12 col-md-6">
            <ReadOnlyInfo
              :title="$t('corporativeAccounts.details.monthlyAmount')"
              :text="monthlyAmount | currency"
            ></ReadOnlyInfo>
          </div>
          <div class="col-12 col-md-6">
            <ReadOnlyInfo
              :title="$t('corporativeAccounts.details.accountType')"
              :text="details.accountType.description"
            ></ReadOnlyInfo>
          </div>
          <div class="col-12 col-md-6 d-flex align-items-c justify-space-between column-gap-10px m-b-30">
            <AccountType clazz="p-relative imgLeft m-0-auto-0-0">
              <img
                class="img-fluid"
                :src="accountImage"
                width="80"
                height="50"
                :alt="details.accountType.description"
              />
              <template v-slot:extraText>
                <label class="f-12 c-info poppins-font-semibold m-b-0">{{details.alias}}</label>
                <p class="f-12 c-text_title poppins-font-regular m-b-0">{{details.accountType.description}}</p>
              </template>
            </AccountType>
          </div>
          <div class="col-12">
            <div class="title-card m-t-15">
              <label>
                {{$t('corporativeAccounts.details.title2')}}
                <p>{{$t('corporativeAccounts.details.subtitle2')}}</p>
              </label>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <ReadOnlyInfo
              :title="$t('corporativeAccounts.details.userName')"
              :text="details.userName"
            ></ReadOnlyInfo>
          </div>
          <div class="col-12 col-md-6 p-t-10">
            <ReadOnlyInfo
              :title="$t('corporativeAccounts.details.userEmail')"
              :text="details.userEmail"
            ></ReadOnlyInfo>
          </div>
          <div class="col-12">
            <div class="title-card m-t-15">
              <label>
                {{$t('corporativeAccounts.details.title3')}}
                <p>{{$t('corporativeAccounts.details.subtitle3')}}</p>
              </label>
            </div>
          </div>
          <div class="col-12">
            <div id="menuOverflowFix" class="basic-table inside-card">
              <b-table-simple responsive selectable selected-variant="active" table-variant="xl">
                <b-thead>
                  <b-tr class="">
                    <b-th>{{$t('corporativeAccounts.limits.headers.type')}}</b-th>
                    <b-th>{{$t('corporativeAccounts.limits.headers.amount')}}</b-th>
                    <b-th>{{$t('corporativeAccounts.limits.headers.periodicity')}}</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="type in limitTypes" :key="type">
                    <b-td> {{ $t(`corporativeAccounts.limits.type.${type}`) }} </b-td>
                    <b-td> {{ getLimit(type).limit | currency }} </b-td>
                    <b-td> {{ $tc(`corporativeAccounts.limits.periodicity.${getLimit(type).periodicity}`) }} </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </div>
        </div>
      </CardSimple>
      <div class="control-buttons">
        <button type="button" class="button-icon" @click="$router.push(listRoute)">
          <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
          {{$t('corporativeAccounts.details.back')}}
        </button>
        <div class="vertical-center">
          <button type="button" class="button button-stroke button-primary" @click="goToEdit">
            {{$t('corporativeAccounts.details.edit')}}
          </button>
          <button
            type="button"
            class="button button-filled button-primary"
            v-if="details && ['INACTIVE', 'ACTIVE'].includes(details.status)"
            @click="goToActivity"
          >
            {{$t('corporativeAccounts.details.activity')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import CardSimple from "@/components/CardSimple.vue";
import AccountType from "@/components/AccountType.vue";
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";

import {CARD_DEFAULT_IMG} from "@/enums/defalutCardImg.enum";

import { imageDownloadLink } from '@/utils/utils';

import moment from 'moment';

import CorporativeAccountsApi from "@/api/wallet/corporativeAccounts.api";

export default {
  name: "CorporativeAccountDetail",
  components: {
    FloatingTitleLayout,
    CardSimple,
    AccountType,
    ReadOnlyInfo,
  },
  data() {
    return {
      listRoute: {name: 'CorporativeAccountList'},
      details: null,
      limitTypes: [
        'CARD_PURCHASES',
        'SPEI_IN',
        'SPEI_OUT',
        'TRANSFER_IN_PAGANDO',
        'TRANSFER_OUT_PAGANDO',
        'ATM_CASH_WITHDRAWAL',
        'CASH_WITHDRAWAL_STORES',
      ]
    };
  },
  computed: {
    accountImage() {
      if(!this.details?.accountType?.template) {
        return null;
      }

      const {image, product} = this.details.accountType.template;

      if(image) {
        return imageDownloadLink(image);
      }

      const images = {
        EXPLORE: CARD_DEFAULT_IMG.pagandoExplore,
        EXPLORE_PLUS: CARD_DEFAULT_IMG.pagandoExplorePlus,
        NIGHT: CARD_DEFAULT_IMG.pagandoNight,
        BUSINESS: CARD_DEFAULT_IMG.pagandoBusiness,
        BUSINESS_PRO: CARD_DEFAULT_IMG.pagandoBusinessPro,
      };

      return images[product];
    },
    blockDate() {
      if(!this.details) {
        return null;
      }
      return {
        date: moment(this.details.blockDate).format('LL'),
        time: moment(this.details.blockDate).format('hh:mm a'),
      };
    },
    monthlyAmount() {
      if(!this.details?.limits) {
        return null;
      }
      return this.details.limits.find(({type}) => type === 'ACCUMULATED_BALANCE')?.limit;
    },
  },
  created() {
    moment.locale(this.$i18n.locale);
    this.loadDetails();
  },
  methods: {
    moment,
    async loadDetails() {
      try {
        const id = this.$route.params.id;
        const response = await CorporativeAccountsApi.getDetails(id);
        this.details = response.data.object;
        if(['PROCESSING', 'ERROR'].includes(this.details.status)) {
          this.$router.push({
            name: 'NewCorporativeAccount',
            params: {
              requestData: this.details,
            }
          })
        }
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    getLimit(type) {
      if(!this.details?.limits) {
        return false;
      }
      const noLimit = {
       limit: 0,
       periodicity: 'none',
      };
      return this.details.limits.find(limit => limit.type === type) || noLimit;
    },
    goToEdit() {
      const id = this.$route.params.id;
      this.$router.push({
        name: 'EditCorporativeAccount',
        params: {
          id,
          data: this.details,
          comesFromDetails: true,
        }
      })
    },
    goToActivity() {
      const id = this.$route.params.id;
      this.$router.push({
        name: 'CorporativeActivity',
        params: {id, comesFromDetails: true},
      })
    },
  },
};
</script>
