<template>
    <div id="" class="loader-container" :class="clazz">
        <div class="load-content">
            <div class="infinite-load m-0-auto">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
            </div>
            <p v-if="loadingText">{{loadingText}}</p>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "LoadingBar",
    props: {
        clazz: String,
        loadingText: String,
    }
};
</script>
