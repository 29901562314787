<template>
<div>
  <TitlePrincipal
      title="¿Bajo qué esquema opera tu negocio?"
      subtitle="Te pedimos que nos ayudes a definir
      bajo qué esquema operas en tu negocio.">
  </TitlePrincipal>
  <div class="row">
      <div class="col-12 col-md-6" @click="selectSchema(false)">
          <CardAccountType :clazz="isMoral === false ? 'selected' : ''"
                          title="Persona"
                          titleStrong="Fisica"
                          clazzTitle="text-align-c" >
              <template v-slot:cardImg>
                  <img class="img-fluid m-0-auto m-b-20 max-w-100" src="@/assets/images/Default/img-card-cuenta-fisica.svg" alt="Cuenta Persona Fisica" />
              </template>
              <p class="f-12 c-plain_text poppins-font-regular f-400 text-align-c m-b-0">
                  Persona que tiene una actividad profesional o comercial,
                  con la que obtiene una renumeración económica y, por tanto,
                  tendrá que emitir facturas y hacer declaración de impuestos.
              </p>
          </CardAccountType>
      </div>
      <div class="col-12 col-md-6" @click="selectSchema(true)">
          <CardAccountType :clazz="isMoral === true ? 'selected' : ''"
                          title="Persona"
                          titleStrong="Moral"
                          clazzTitle="text-align-c">
              <template v-slot:cardImg>
                  <img class="img-fluid m-0-auto m-b-20 max-w-100" src="@/assets/images/Default/img-card-cuenta-moral.svg" alt="Cuenta Persona Moral" />
              </template>
              <p class="f-12 c-plain_text poppins-font-regular f-400 text-align-c m-b-0">
                  Conjunto de personas físicas que se unen para
                  realizar alguna actividad en comun, comercial,
                  profesional, de servicios o productos, incluso sin fines de lucro.
              </p>
          </CardAccountType>
      </div>
  </div>
  <div class="footer-buttons right">
      <button type="button" class="button button-ghost button-plain_text" name="Regresar" @click="back()"> Regresar </button>
      <button type="button" class="button button-filled button-primary" name="Continuar" :disabled="isMoral === null" @click="nextStep()"> Continuar </button>
  </div>

    <ModalAlert
      closeCross
      modalId="modal-change-account-type"
      buttonType="button-primary"
      :imgUrl="imageModalGeneric"
      title="¿Estás seguro de que quieres regresar a la selección de tipo de cuenta?"
      buttonCancel="No"
      buttonText="Sí"
      @submit="returnToSelectAccountType()"
  ></ModalAlert>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import CardAccountType from "@/components/CardAccountType.vue";
import { mapGetters } from 'vuex';
import {
  STORE as OnboardingStore,
  SET_TYPE_ACCOUNT_BUSINESS,
  GET_CARD_REQUEST,
  GET_ACCOUNT_TYPE_BUSINESS
} from '@/store/modules/onboardingSteps/onboardingSteps.types';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import imageModalGeneric from "@/assets/images/Illustrations/illustration-modal-generic.svg";
import ModalAlert from "@/components/ModalAlert.vue";
import UsersApi from "@/api/users/users.api";
import {
  STORE as sessionStore,
  GET_USER
} from "@/store/modules/session/session.types";

export default {
  name: "SelectSchemaBusiness",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    CardAccountType,
    ModalAlert
  },
  computed: {
    ...mapGetters(OnboardingStore, {
      cardRequest: GET_CARD_REQUEST,
      isMoral2: GET_ACCOUNT_TYPE_BUSINESS
    }),
    ...mapGetters(sessionStore, {
      user: GET_USER
    }),
  },
  data() {
    return {
      isMoral: null,
      imageModalGeneric
    }
  },
  methods: {
    async returnToSelectAccountType() {
      const response = await UsersApi.updateOnbTypeUser({user: this.user._id, onbForBusiness: false, isBack: true});

      if(response.data.error) {
        this.displayNotificationError('Ocurrió un error al regresar.')
      } else {
        this.$store.dispatch(`${OnboardingStore}/backBusiness`)
      }
    },
    back () {
      this.$bvModal.show('modal-change-account-type');
    },
    selectSchema(isMoral) {
      this.isMoral = isMoral;
      this.$store.commit(`${OnboardingStore}/${SET_TYPE_ACCOUNT_BUSINESS}`, this.isMoral ? 'MORAL' : 'PHYSIC');
    },
    async nextStep() {
      if (this.isMoral !== null) {
        this.showLoading = true;
        const created = await this.$store.dispatch(`${OnboardingStore}/saveCardRequestForBusiness`,
            {
              isMoral: this.isMoral,
              cardRequestId: this.cardRequest ? this.cardRequest._id : null,
              isMultipleAccount: this.$route.name === 'selectSchemaBusinessMultiple'
            });
        if (created) {
          // this.$store.dispatch(`${OnboardingStore}/changeCurrentStep`);
          if(this.$route.name === 'selectSchemaBusinessMultiple') {
            this.$router.push({name: "selectAccountBusinessMultiple"})

          } else {
            const response = await UsersApi.updateOnbTypeUser({user: this.user._id, onbForBusiness: true, step: 'SELECT_CARD'})

            if(response.data.error) {
              this.displayNotificationError('Ocurrió un error para continuar proceso.')
            } else {
              this.$router.push({name: "selectAccountBusiness"});
            }
          }
        } else {
          if(this.$route.name === 'selectSchemaBusinessMultiple') {
          // this.showErrorFromMultiple = true;
          // if(this.objectError.hasAlreadyAccount) {
          //   this.errorMessageMultiple = 'Ya tienes una cuenta del tipo seleccionado. Por favor selecciona un tipo de cuenta diferente.'
          // }
          this.$router.push({name: "businessSuccessRequestMultiple"});

        } else {
          this.displayNotificationError(
                this.$i18n.t('register.selectAccountCard.errorSaveRequestCard'),
                this.$i18n.t('register.selectAccountCard.errorRequestCardTitle'),
            )
        }

        }
        this.showLoading=false;
      }
    }
  },
  watch: {
    isMoral2(val) {
      this.isMoral = val === 'MORAL';
    }
  },
  async created(){
    if(this.isMoral2) {
      this.isMoral = this.isMoral2 === 'MORAL';
    }

    const routeName = this.$route.name;
    if(routeName === 'selectSchemaBusinessMultiple'){
      this.$store.commit(
        `${OnboardingStore}/SET_CARD_REQUEST`,
        ''
      );
      await this.$store.dispatch(`${OnboardingStore}/putLevel`, this.isMoral ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC');
    }
    await this.$store.dispatch(`${OnboardingStore}/checkCurrentStep`, this.$route);


  }
}
</script>

<style scoped>

</style>
