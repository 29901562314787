<template>
  <div class="collapse-beneficiary">
    <div class="head">
      <span>
        <BeneficiaryInfo
          :text="value.email"
          :textBold="value.name"
        >
        </BeneficiaryInfo>
      </span>
      <span>
        <span :class="getBadgeClass(value)">
          <i aria-hidden="true" class="badge-icon iconbullet"></i>
          {{ $t(`collaborators.badge.${value.badge || value.status}`) }}
        </span>
      </span>
      <span>
        <button
          id=""
          class="button-square xs button-stroke button-accent"
          type="button"
          :name="$t('collaborators.actions.collapse')"
          :aria-label="$t('collaborators.actions.collapse')"
          v-b-toggle="`collapse-collaborator-${key}`"
        >
          <i aria-hidden="true" class="iconsax-arrow-direction-down"></i>
          <i aria-hidden="true" class="iconsax-arrow-direction-up"></i>
        </button>
      </span>
    </div>
    <b-collapse :id="`collapse-collaborator-${key}`" class="body">
      <div class="row">
        <div class="col-12">
          <div id="" class="basic-table">
            <b-table-simple responsive table-variant="xl">
              <b-thead>
                <b-tr class="">
                  <b-th class="w-60"> {{$t('collaborators.headers.permissions')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.read')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.request')}} </b-th>
                  <b-th class="text-center"> {{$t('collaborators.headers.authorize')}} </b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr v-for="(type, index) in permissionTypes" :key="index">
                  <b-td class="w-60">
                    {{ $t(`collaborators.permissionTypes.${type}`) }}
                  </b-td>
                  <b-td
                    v-for="(action, index) in permissionActions"
                    :key="index"
                  >
                    <BasicCheckbox
                      clazz="w-fit m-0-auto"
                      inputClass="m-0"
                      :value="checkPermission(collaborator, action, type)"
                      @input="checked => onPermissionInput(checked, action, type)"
                      :index="index"
                      :disabled="!editing"
                    ></BasicCheckbox>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </div>
        </div>
        <div class="control-buttons buttons-full-w buttons-full-w-xs"
          v-if="collaborator.status !== 'ACCOUNT_HOLDER' && canRequestCollaborator && !editing"
        >
          <button type="button" class="button button-ghost button-error w-100" @click="deleteCollaborator">
            {{ $t(`collaborators.actions.delete`) }}
          </button>
          <button
            type="button"
            class="button button-filled button-primary w-100"
            @click="editing = true"
          >
            {{ $t(`collaborators.actions.edit`) }}
          </button>
        </div>
        <div class="control-buttons buttons-full-w buttons-full-w-xs"
          v-if="collaborator.status !== 'ACCOUNT_HOLDER' && canRequestCollaborator && editing"
        >
          <button
            type="button"
            class="button button-stroke button-primary w-100"
            @click="editing = false"
          >
            {{ $t(`collaborators.actions.cancel`) }}
          </button>
          <button
            type="button"
            class="button button-filled button-primary w-100"
            :disabled="areEquals(value, collaborator)"
            @click="changePermissions"
            v-if="!loading"
          >
            {{ $t(`collaborators.actions.saveChanges`) }}
          </button>
          <button
            v-else
            type="button"
            class="button button-filled button-primary w-100"
          >
            <LoadingSimple clazz="small-load no-text" />
          </button>
        </div>
        <div class="col-12 m-t-40" v-if="value.rejection">
          <b-alert class="alert-note" show variant="danger">
            <p class="text-container">
              <i
                aria-hidden="true"
                class="icon banana banana-cross-circle2"
              ></i>
              <span>
                <span v-if="value.rejection.type === 'removal'">{{ $t(`collaborators.footers.rejectedRemoval`) }} </span>
                <span v-else>{{ $t(`collaborators.footers.rejectedEdit`) }} </span>
                <strong>{{value.rejection.message}}</strong>
              </span>
            </p>
          </b-alert>
        </div>
      </div>
    </b-collapse>
    <ConfirmModal ref="confirmModal"/>
  </div>
</template>
<script>
// @ is an alias to /src
import BeneficiaryInfo from "@/components/BeneficiaryInfo.vue";
import BasicCheckbox from "@/components/BasicCheckbox.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";
import ConfirmModal from "@/components/collaborators/ConfirmModal.vue";

import ToastSnotify from "@/mixins/toastSnotify.mixin";
import CollaboratorsMixin from "@/mixins/collaboratorPermissions.mixin";

import CollaboratorsApi from "@/api/wallet/collaborators.api";

const clone = (obj) => JSON.parse(JSON.stringify(obj));

export default {
  name: "Collaborator",
  mixins: [ToastSnotify, CollaboratorsMixin],
  components: {
    BeneficiaryInfo,
    BasicCheckbox,
    LoadingSimple,
    ConfirmModal
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      collaborator: null,
      editing: false,
      loading: false,
    };
  },
  computed: {
    key() {
      return this.$vnode?.key?.toString() || "";
    },
  },
  methods: {
    areEquals: (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2),
    onPermissionInput(add, action, type) {
      if(add) {
        this.addPermission(action, type);
      } else {
        this.removePermission(action, type);
      }
    },
    addPermission(action, type) {
      if(action !== 'READ' && !this.collaborator.permissions.some(p => p.action === 'READ' && p.type === type)) {
        this.collaborator.permissions.push({action: 'READ', type});
      }
      this.collaborator.permissions.push({action, type});
    },
    removePermission(action, type) {
      if(action === 'READ') {
        this.$set(this.collaborator, 'permissions', this.collaborator.permissions.filter(p => p.type !== type));
      } else {
        this.$set(this.collaborator, 'permissions', this.collaborator.permissions.filter(p => p.action !== action || p.type !== type));
      }
    },
    async deleteCollaborator() {
      try {
        const confirmed = await this.$refs.confirmModal.confirm({type: 'delete'});
        if(confirmed) {
          this.loading = true;
          const response = await CollaboratorsApi.delete(this.collaborator);
          if(response.status === 202) {
            this.$emit('input', {...this.value, badge: 'PENDING_REMOVAL'});
          } else {
            this.$emit('input', {status: 'HIDDEN'});
          }
          this.loading = false;
          this.toastSuccessDefault();
        }
      } catch (error) {
        this.toastErrorDefault(error);
        this.loading = false;
      }
    },
    async changePermissions() {
      try {
        this.loading = true;
        const response = await CollaboratorsApi.changePermissions(this.collaborator);
        if(response.status === 202) {
          this.$emit('input', {...this.value, badge: 'PENDING_EDIT'});
        } else {
          this.$emit('input', response.data.object);
        }
        this.loading = false;
        this.editing = false;
        this.toastSuccessDefault();
      } catch (error) {
        this.toastErrorDefault(error);
        this.loading = false;
      }
    },
  },
  watch: {
    editing: {
      immediate: true,
      handler: function() {
        this.collaborator = clone(this.value);
      },
    },
    value: {
      handler: function() {
        if(!this.editing) {
          this.collaborator = clone(this.value);
        }
      },
    },
  },
};
</script>
