var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 col-lg-6 cards-same-height"},[_c('CardSimple',{attrs:{"hideTitle":_vm.loading || !_vm.movementGroups.length,"title":_vm.$t('dashboard.recentMovements.title'),"subtitle":_vm.$t('dashboard.recentMovements.subtitle'),"clazzBody":"card-body-no-padding"}},[(_vm.loading)?_c('LoadingBar',{attrs:{"clazz":"fullcontent-loading"}}):_vm._e(),(!_vm.loading && !_vm.movementGroups.length)?_c('EmptyState',{attrs:{"emptyStateDefault":"","title":_vm.$t('dashboard.recentMovements.emptyTitle'),"text":_vm.$t('dashboard.recentMovements.emptyText'),"imgUrl":_vm.imgEmptystateHistorialMov,"imgWidth":310,"imgHeight":300,"imgAlt":_vm.$t('dashboard.recentMovements.emptyTitle')}}):_vm._e(),_vm._l((_vm.movementGroups),function(group){return _c('div',{key:group[0]},[_c('TitleBadge',{attrs:{"clazzContainer":"w-100 m-b-10","clazz":"xs text-upper m-b-0","titleText":_vm.parseMovementGroupTitle(group[0]),"badgeText":`${group[1].length}`}}),_vm._l((group[1]),function(movement,index){return _c('router-link',{key:movement._id,staticClass:"w-100",attrs:{"to":"","tabindex":""}},[_c('MovementsList',{attrs:{"clazz":`${movement.color} ${index < group[1].length - 1 ? 'mb-0' : ''}`,"icon":movement.icon,"movConcept":movement.movementTitle,"movText":movement.movementDescription}},[(((movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
            && movement.contaMovementType !== 'CARGO') ||
            ((movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
            && movement.contaMovementType !== 'ABONO' && (movement.spentRewardPoints !== 0 || _vm.availablePoints < 100))  ||
            (movement.type !== 'COMPRA_DIGITAL' && movement.type !== 'Compra' && movement.type !== 'COMPRA_CONTACTLESS'))?_c('p',{class:movement.amountClass},[_vm._v(_vm._s(movement.sign)+" "+_vm._s(_vm._f("currency")(movement.amount)))]):_vm._e(),(_vm.showPointsButton && (movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
                && movement.contaMovementType === 'CARGO' && movement.spentRewardPoints === 0 && _vm.availablePoints > 100)?_c('router-link',{staticClass:"button xs button-stroke button-primary m-0-0-0-auto p-l-10 p-r-10",attrs:{"to":`/points-pagando/${movement._id}`,"id":`tooltip-pagarPuntos-${movement._id}`,"tabindex":""}},[_c('i',{staticClass:"f-18 iconsax-like-medal-star m-r-5",attrs:{"aria-hidden":"true"}}),_vm._v(" "+_vm._s(movement.sign)+" "+_vm._s(_vm._f("currency")(movement.amount))+" ")]):_vm._e(),(_vm.showPointsButton && (movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
              && movement.contaMovementType === 'CARGO' && movement.spentRewardPoints === 0)?_c('b-tooltip',{attrs:{"target":`tooltip-pagarPuntos-${movement._id}`,"placement":"top"}},[_c('p',{staticClass:"m-b-0"},[_vm._v(" "+_vm._s(_vm.$t('movements.paidWithPoints'))+" ")])]):_vm._e()],1)],1)})],2)}),(_vm.movementGroups.length)?_c('router-link',{staticClass:"button xs button-ghost button-text_title m-auto-auto-0 m-t-30",attrs:{"to":"","tabindex":""},nativeOn:{"click":function($event){return _vm.$emit('goToMovements')}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.recentMovements.seeMovements'))+" "),_c('i',{staticClass:"iconsax-arrow-circle-right f-22 c-info m-l-10",attrs:{"aria-hidden":"true"}})]):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }