import {
    GET_USER,
    STORE as sessionStore
  } from "@/store/modules/session/session.types";
import store from '@/store/index'

export default function needUpdateContract({ next }) {
    const user = store.getters[`${sessionStore}/${GET_USER}`];

    if(user && user.walletUser && (user.contract === 2 || user.contract === 1 || user.contract === 4 || user.contract === 3)) {
           return next();
        // return store.dispatch(`${onbngStore}/getRouteByStep`);
    }
    // TODO algo le duele aquí, no interfiere pere revisar cosas de vue
    return next('/login');
}
