<template>
  <div class="row">
    <!--  HEADERS  -->
    <div class="col-12">
      <router-link
          to="/wallet/my-account"
          class="button xs button-ghost button-text_info p-l-0 p-r-0"
          tabindex="">
        <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
        {{ $t('accOpLimit.back') }}
      </router-link>
      <FloatingTitleLayout :titleStrong="$t('accOpLimit.title')"></FloatingTitleLayout>
    </div>
    <!--  HEADERS  -->

    <!--  CARD  -->
    <CardSimple
        :title="$t('accOpLimit.cardTitle')"
        :subtitle="$t('accOpLimit.cardSubTitle')"
    >
      <div id="menuOverflowFix" v-if="!loading" class="basic-table inside-card">
        <b-table-simple
            responsive
            selectable
            selected-variant="active"
            table-variant="xl"
        >
          <b-thead>
            <b-tr class="">
              <b-th>{{$t('accOpLimit.table.headers.type')}}</b-th>
              <b-th>{{$t('accOpLimit.table.headers.amount')}}</b-th>
              <b-th>{{$t('accOpLimit.table.headers.periodicity')}}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr class="noHover" v-for="(limit, index) in limits" :key="limit.type">
              <b-td class="">{{$t(`accOpLimit.table.limit.${limit.type}`)}}</b-td>
              <b-td class="">
                <BasicInput
                    money
                    clazz="m-0"
                    v-model="limit.limit"
                    :inputValue="limit.limit.toString()"
                    :has-error="hasError(limit)"
                    :validations="getErrorV(index)"
                    :warningText="getWarningText(limit)"
                >
                </BasicInput>
              </b-td>
              <b-td class="">
                <BasicSelect clazz="m-0 w-200px">
                  <multiselect
                      @open="toggleDropdown"
                      @close="toggleDropdown"
                      v-model="limit.periodicity"
                      :options="periodicityOptions"
                      :custom-label="val => $tc(`accOpLimit.table.periodicity.${val}`)"
                      :close-on-select="true"
                      :preserve-search="true"
                      :disabled="!limit.editPeriodicity"
                      :placeholder="$t('accOpLimit.table.periodicity.placeholder')"
                  >
                    <template
                        slot="selection"
                        slot-scope="{ values, isOpen }"
                    >
                          <span
                              class="multiselect__single"
                              v-if="values.length &amp;&amp; !isOpen"
                          >{{ values.length }} options selected</span
                          >
                    </template>
                  </multiselect>
                </BasicSelect>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <LoadingBar v-if="loading"></LoadingBar>
    </CardSimple>
    <!--  CARD  -->

    <!--  BUTTONS  -->
    <div class="control-buttons">
      <button type="button" class="button-icon" @click="cancel">
        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
        {{$t('accOpLimit.footer.back')}}
      </button>
      <div class="vertical-center">
        <button type="button" class="button button-stroke button-primary" @click="cancel">
          {{$t('accOpLimit.footer.cancel')}}
        </button>
        <button
            v-if="showSave"
            type="button"
            class="button button-filled button-primary"
            :disabled="isFormInvalid"
            @click="submit"
        >
          {{$t('accOpLimit.footer.save')}}
        </button>
        <button
            v-else
            class="button button-filled button-primary"
        >
          <LoadingSimple clazz="small-load no-text"/>
        </button>
      </div>
    </div>
    <!--  BUTTONS  -->
  </div>
</template>

<script>
import CardSimple from "@/components/CardSimple";
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import Multiselect from "vue-multiselect";
import BasicSelect from "@/components/BasicSelect.vue";
import BasicInput from "@/components/BasicInput.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";
import AccountApi from '@/api/wallet/accounts.api';
import LoadingBar from "@/components/LoadingBar.vue";
import ToastSnotify from "@/mixins/toastSnotify.mixin";

import operationalLimitsAccount from '@/enums/operationalLimitsAccount.enum';
import {decimal, required, minValue} from "vuelidate/lib/validators";
export default {
  name: "AccOperationalLimits",
  mixins: [ToastSnotify],
  data() {
    return {
      showSave: true,
      loading: true,
      limits: [
        {
          type: operationalLimitsAccount.cardPurchases,
          limit: 1_000,
          periodicity: 'MONTHLY',
          editPeriodicity: false,
          defaultLimit: 0,
        },
        {
          type: operationalLimitsAccount.speiOut,
          limit: 1_000,
          periodicity: 'MONTHLY',
          editPeriodicity: false,
          defaultLimit: 0,
        },
        {
          type: operationalLimitsAccount.transferOutPagando,
          limit: 1_000,
          periodicity: 'MONTHLY',
          editPeriodicity: false,
          defaultLimit: 0,
        },
        {
          type: operationalLimitsAccount.atmCashWithdrawal,
          limit: 1_000,
          periodicity: 'MONTHLY',
          editPeriodicity: false,
          defaultLimit: 0,
        }
      ],
      periodicityOptions: [
        'BY_EVENT',
        'DAILY',
        'MONTHLY',
        'QUARTERLY',
        'BIANNUAL',
        'ANNUAL'
      ],
    }
  },
  components: {
    LoadingBar,
    LoadingSimple,
    BasicInput, BasicSelect, Multiselect,
    CardSimple,
    FloatingTitleLayout
  },
  computed: {
    currency() {
      return this.$options.filters.currency;
    },
    isFormInvalid() {
      return this.$v.$invalid;
    },
  },
  methods: {
    toggleDropdown() {
      document.getElementById("menuOverflowFix").classList.toggle("dropdown-table-overflow");
    },
    cancel() {
      this.$router.push({name: 'MyAccount'})
    },
    hasError(limit) {
      return limit.defaultLimit ? limit.limit > limit.defaultLimit: false;
    },
    getErrorV(index) {
      return this.$v.limits.$each[index].limit;
    },
    getWarningText(limit) {
      if (this.hasError(limit)) {
        return this.$t('accOpLimit.table.limitWarningError', { amount: this.currency(limit.defaultLimit) });
      }
      return limit.defaultLimit ? this.$t('accOpLimit.table.limitWarning', { amount: this.currency(limit.defaultLimit) }) : ''
    },
    parseAccountLimitToLimit(type) {
      switch (type) {
        case operationalLimitsAccount.atmCashWithdrawal:
          return 'LIMIT_CASH_WITHDRAWALS';
        case operationalLimitsAccount.cardPurchases:
          return 'AMOUNT_TRANSACTIONS';
        default:
          return '';
      }
    },
    async getLimits() {
      this.loading = true;
      const response = await AccountApi.getLimits().catch(err => {
        this.toastErrorDefault(err);
      });
      if (!response) {
        this.loading = false;
        return;
      }

      this.limits = response;
      this.loading = false;
    },
    async submit() {
      this.showSave = false;
      const limits = this.limits.map(l => ({
        type: l.type,
        limit: l.limit,
        periodicity: l.periodicity,
      }));
      const response = await AccountApi.setLimits({operationalLimits: limits}).catch(err => {
        this.toastErrorDefault(err);
      });
      if (!response) {
        this.showSave = true;
        return;
      }
      this.displayNotificationSuccess(this.$t('accOpLimit.success'));
      this.$router.push({name: 'MyAccount'})
    }
  },
  beforeMount() {
    this.getLimits();
  },
  mounted() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  },
  validations: {
    limits: {
      $each: {
        limit: {
          required,
          decimal,
          min: minValue(1),
        },
        periodicity: {
          required,
        }
      }
    }
  }
}
</script>
