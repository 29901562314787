<template>
  <div>
    <TitlePrincipal :title="$t('register.newAccountBusiness.title')">
      <p class="subtitle"
         v-html="$t('register.newAccount.subtitle')">
      </p>
    </TitlePrincipal>
    <form>
      <BasicInput
          required
          inputType="email"
          inputId="user_email"
          :label="$t('register.newAccount.emailLabel')"
          :inputPlaceholder="$t('register.newAccount.emailPlace')"
          v-on:basicInput="onBasicInputRegister"
      />

      <PasswordInput
          required
          :inputType="passInputType"
          inputId="user_psw"
          :label="$t('register.newAccount.passLabel')"
          :inputPlaceholder="$t('register.newAccount.passPlace')"
          level
          :clazzPassword="clazzPasswordStatus"
          :status="$t(labelPasswordStatus)"
          v-on:passwordInput="onPasswordChangeRegister"
          v-on:passwordInputType="changeTypePasswordRegister"
          v-on:enterKey="onEnterKey"
      />
      <b-alert class="alert-note" show variant="accent2" v-if="showStatusBar">
        <p class="text-container c-plain_text f-10 d-block">
          {{$t('register.newAccount.passTip')}}
          <ul class="m-t-5 m-0 p-0 list-style-none">
            <li class="f-10 poppins-font-semibold m-b-5 d-flex" :class="[passLength ? 'c-accent_2' : 'c-error']">
              <i aria-hidden="true" class="banana f-12 m-r-10" :class="[passLength ? 'banana-check-normal' : 'banana-cross-normal']"></i>
              {{$t('register.newAccount.passTip6min')}}
            </li>
            <li class="f-10 poppins-font-semibold m-b-5 d-flex" :class="[passNo ? 'c-accent_2' : 'c-error']">
              <i aria-hidden="true" class="banana f-12 m-r-10" :class="[passNo ? 'banana-check-normal' : 'banana-cross-normal']"></i>
              {{$t('register.newAccount.passTipNo')}}
            </li>
            <li class="f-10 poppins-font-semibold m-b-5 d-flex" :class="[passCapLetter ? 'c-accent_2' : 'c-error']">
              <i aria-hidden="true" class="banana f-12 m-r-10" :class="[passCapLetter ? 'banana-check-normal' : 'banana-cross-normal']"></i>
              {{$t('register.newAccount.passTipCap')}}
            </li>
            <li class="f-10 poppins-font-semibold m-b-5 d-flex" :class="[passSymbol ? 'c-accent_2' : 'c-error']">
              <i aria-hidden="true" class="banana f-12 m-r-10" :class="[passSymbol ? 'banana-check-normal' : 'banana-cross-normal']"></i>
              {{$t('register.newAccount.passTipSymbol')}}
            </li>
          </ul></p>
      </b-alert>
      <PasswordInput
          ref="user_confirm_psw"
          required
          :inputType="passInputType"
          inputId="user_confirm_psw"
          :label="$t('register.newAccount.repeatPassword')"
          :inputPlaceholder="$t('register.newAccount.repeatPassword')"
          :hasLevelBar="false"
          v-on:passwordInput="onPasswordRepeat"
          v-on:passwordInputType="changeTypePasswordRegister"
      />
    </form>
    <div class="footer-buttons right">
      <button @click="back()" type="button" class="button button-ghost button-plain_text" name="Regresar"> Regresar </button>
      <button v-if="!loadingRegister" type="button" class="button button-filled button-primary"
              name="Continuar" :disabled="disabledRegister"
              @click.prevent="doRegister"
      > {{ $t('register.newAccount.next') }} </button>
      <button v-else :id="'Loading button'" class="button button-ghost button-plain_text">
        <LoadingSimple clazz="small-load no-text" />
      </button>
    </div>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal.vue";
import BasicInput from "@/components/BasicInput";
import PasswordInput from "@/components/PasswordInput";
import LoadingSimple from "@/components/LoadingSimple";
import {
  STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {sequential, sequentialSymbols, sortByLength} from "@/utils/utils";
import {regexEmail, catchError} from "@/utils/regexUtils";
const { required, minLength, sameAs} = require('vuelidate/lib/validators');
import UsersApi from "@/api/users/users.api";
import ToastSnotify from "@/mixins/toastSnotify.mixin";

export default {
  name: "newAccount",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicInput,
    PasswordInput,
    LoadingSimple
  },
  data() {
    return {
      registerEmail: "",
      registerPassword: "",
      correctEmail: false,
      repeatPassword: "",
      clazzPasswordStatus: "",
      labelPasswordStatus: "",
      showStatusBar: false,
      passInputType: 'password',
      passLength: false,
      passCapLetter: false,
      passSymbol: false,
      passNo: false,
      isValidPass: false,
      loadingRegister: false
    }
  },
  methods: {
    back() {
        this.$store.dispatch(`${onbngStore}/backPersonal`)
    },
    getPositivePoints (password) {
      const numberOfCharacters = password.length;
      const uppercaseLetters = (password.match(/[A-Z]/g) || []).length;
      const lowercaseLetters = (password.match(/[a-z]/g) || []).length;
      const numberOnPassword = (password.match(/[0-9]/g, "") || []).length;
      const symbols =
          numberOfCharacters -
          uppercaseLetters -
          lowercaseLetters -
          numberOnPassword;

      let middleSymbolsNumbers = symbols + numberOnPassword;
      if (numberOfCharacters > 2 && (symbols > 0 || numberOnPassword > 0)) {
        if (password[0].match(/[a-zA-Z]/g) == null) {
          middleSymbolsNumbers -= 1;
        }

        if (password[numberOfCharacters - 1].match(/[a-zA-Z]/g) == null) {
          middleSymbolsNumbers -= 1;
        }
        if (middleSymbolsNumbers < 0) {
          middleSymbolsNumbers = 0;
        }
      }

      let requirements = 0;
      if (
          numberOfCharacters > 6 &&
          uppercaseLetters > 0 &&
          lowercaseLetters > 0 &&
          numberOnPassword > 0 &&
          symbols > 0
      ) {
        requirements = 10;
      }

      this.passLength = numberOfCharacters > 5;
      this.passCapLetter = uppercaseLetters > 0;
      this.passNo = numberOnPassword > 0;
      this.passSymbol = symbols > 0;

      return (
          numberOfCharacters * 4 +
          (numberOfCharacters - uppercaseLetters) * 2 +
          (numberOfCharacters - lowercaseLetters) * 2 +
          numberOnPassword * 4 +
          symbols * 6 +
          middleSymbolsNumbers * 2 +
          requirements
      );
    },
    getRepeatCharacter (password) {
      const pattern = /(.+)(?=.*?\1)/g;
      let score = 0;
      if (pattern.test(password)) {
        const matches = password.match(pattern);
        const maxResultLength = sortByLength(matches)[0].length;
        let ratio = 0;
        if (maxResultLength >= 1 && maxResultLength <= 5) {
          ratio = -8;
        }
        if (maxResultLength >= 6 && maxResultLength <= 10) {
          ratio = -5;
        }
        if (maxResultLength >= 11) {
          ratio = -2;
        }
        score =
            ratio * maxResultLength + (password.length - maxResultLength * 2);
        if (score > 0) {
          score = 0;
        }
      }
      return score;
    },
    onPasswordChangeRegister (password) {
      this.registerPassword = password;
      let positivePoints = this.getPositivePoints(password);
      if (/^[a-zA-Z]+$/.test(password)) {
        positivePoints = positivePoints - password.length;
      }

      if (/^\d+$/.test(password)) {
        positivePoints = positivePoints - password.length;
      }

      positivePoints += this.getRepeatCharacter(password);

      const groups = password.match(/[A-Z]+|[a-z]+|[0-9]+/g);
      if (groups) {
        const groupsFilter = groups.filter(function (element) {
          return element.length >= 2;
        });

        let consecutiveCharacter = 0;
        for (const group of groupsFilter) {
          consecutiveCharacter += (group.length - 1) * 2;
        }

        positivePoints -= consecutiveCharacter;
      }

      positivePoints -= sequential(password);

      positivePoints -= sequentialSymbols(password);

      if (positivePoints <= 0 && password.length > 0) {
        positivePoints = 1;
      }

      if (positivePoints === 0) {
        this.clazzPasswordStatus = "";
        this.labelPasswordStatus = "";
        this.showStatusBar = false;
      } else if (positivePoints < 40) {
        this.clazzPasswordStatus = "weak";
        this.labelPasswordStatus = 'register.newAccount.passStatusWeak';
        this.showStatusBar = true;
      } else if (positivePoints < 60) {
        this.clazzPasswordStatus = "regular";
        this.labelPasswordStatus = 'register.newAccount.passStatusRegular';
        this.showStatusBar = true;
      } else if (positivePoints < 80) {
        this.clazzPasswordStatus = "good";
        this.labelPasswordStatus = 'register.newAccount.passStatusGood';
        this.showStatusBar = true;
      } else {
        this.clazzPasswordStatus = "excellent";
        this.labelPasswordStatus = 'register.newAccount.passStatusExc';
        this.showStatusBar = true;
      }

      this.$v.$touch();
      this.isValidPass = !this.$v.$invalid;
    },
    onPasswordRepeat (password) {
      this.repeatPassword = password;
      this.$v.$touch();
      this.isValidPass = !this.$v.$invalid;
    },
    changeTypePasswordRegister (isVisibleOn) {
      if (isVisibleOn) {
        this.passInputType = 'password';
      } else {
        this.passInputType = 'text';
      }
    },
    onBasicInputRegister (email) {
      const regex = new RegExp(regexEmail);
      this.correctEmail = regex.test(email);
      this.registerEmail = email.toLowerCase();
    },
    onEnterKey() {
      if (this.$refs.user_confirm_psw) {
        this.$refs.user_confirm_psw.focusInput();
      }
    },
    async doRegister() {
      this.loadingRegister = true;
      const response = await UsersApi.save({
        email: this.registerEmail,
        password: this.registerPassword,
        appliesTo: "WALLET",
        onbForBusiness: true,
        fromWeb: true
      }).catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(this.$i18n.t('register.newAccount.errorRegister'), this.$i18n.t('register.newAccount.errorRegisterTitle'));
        } else {
          this.$router.push({name: 'verifyEmailBusiness', params: {email: this.registerEmail}});
        }
      } else {
        this.displayNotificationError(this.$i18n.t('register.newAccount.errorRegister'), this.$i18n.t('register.newAccount.errorRegisterTitle'));
      }
      this.loadingRegister = false;
    }
  },
  computed: {
    disabledRegister () {
      return !(this.isValidPass && this.correctEmail);
    }
  },
  validations: {
    registerPassword: {
      required,
      minLength: minLength(6)
    },
    repeatPassword: {
      sameAsPassword: sameAs('registerPassword')
    }
  },
}
</script>

<style scoped>

</style>
