<template>
    <div>
        <vue-snotify/>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    :aria-label="$t('announcements.showMenu')"
                    :name="$t('announcements.showMenu')"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        :aria-label="$t('announcements.hideMenu')"
                        :name="$t('announcements.hideMenu')"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/wallet/dashboard"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('announcements.return')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('announcements.title')"></FloatingTitleLayout>
                        </div>

                        <div class="col-12">
                            <CardSimple hideTitle>
                                <div v-if="loading" class="row p-30">
                                    <LoadingBar clazz="fullcontent-loading" :loadingText="$t('announcements.loading')"/>
                                </div>
                                <div v-else-if="announcement" class="row">
                                    <div class="col-12">
                                        <img
                                            class="img-fluid d-flex m-0-auto m-b-30"
                                            :src="announcement.image"
                                            width="375"
                                            height="216"
                                            :alt="$t('announcements.imageAlt')" />
                                        <p v-html="announcement.message"></p>
                                    </div>
                                </div>
                            </CardSimple>

                            <div class="control-buttons">
                                <button
                                    @click="returnToDashboard"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('announcements.returnButton')}}
                                </button>
                                <button
                                    v-if="announcement && announcement.actionUrl"
                                    @click="goToUrl"
                                    type="button"
                                    class="button button-filled button-primary">
                                    {{announcement.actionText}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>
    </div>
</template>
<script>
    // @ is an alias to /src this.$route.params.mode;
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import LoadingBar from "@/components/LoadingBar.vue";

    import NoveltiesApi from '@/api/core/novelties.api';

    import MenuHandler from "@/mixins/menuHandler.mixin";

    import { catchError } from "@/utils/utils";

    export default {
        name: "Announcement",
        mixins: [MenuHandler],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            CardSimple,
            LoadingBar,
        },
        data() {
            return {
                loading: true,
                announcement: null,
            };
        },
        created() {
            this.getAnnouncement();
        },
        methods: {
            async getAnnouncement() {
                const id = this.$route.params.id;
                try {
                    const res = await NoveltiesApi.getAnnouncements({params: {id}}).catch(catchError);
                    const announcement = res.data.object;

                    const image = announcement?.image;
                    const message = announcement?.detailedMessage;
                    const actionText = announcement?.callToAction?.text;
                    const actionUrl = announcement?.callToAction?.link;

                    this.announcement = {
                        image: image?.[this.$i18n.locale] || image?.es,
                        message: message?.[this.$i18n.locale] || message?.es,
                        actionText: actionText?.[this.$i18n.locale] || actionText?.es,
                        actionUrl,
                    };
                } catch (error) {
                    this.toastErrorDefault(error);
                }
                this.loading = false;
            },
            goToUrl() {
                window.location = this.announcement.actionUrl;
            },
            returnToDashboard() {
                this.$router.push({name: 'Dashboard'});
            },
        },
    };
</script>
