<template>
    <div class="basic-select" :class="clazz">
        <slot></slot>

        <div class="input-label" v-bind:class="{ 'disabled': disabled }">
            <label class="" :for="labelFor">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info">
            <i v-if="warningIcon" aria-hidden="true" v-bind:class="warningIcon"></i>
            {{warningText}}
        </small>
    </div>
</template>

<script>
    export default {
        name: 'BasicSelect',
        props: {
            clazz: String,
            required: {type: Boolean, default: false},
            label: String,
            labelFor: String,
            help: {type: Boolean, default: false},
            idHelpTooltip: String,
            helpText: String,
            helpIcon: String,
            warningText: String,
            warningIcon: String,
            disabled: {type: Boolean, default: false}
        }
    }
</script>
