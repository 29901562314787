import OrganizationInfoApi from "@/api/core/organizationInfo.api";
import CardApi from "@/api/wallet/card.api";
import {
    SET_ORGANIZATION_INFO, GET_ORGANIZATION_INFO, EMIT_ORGANIZATION_INFO,
    SET_ORGANIZATIONS_INFO, GET_ORGANIZATIONS_INFO
} from "@/store/modules/organizationInfo/organizationInfo.types";
import {
    STORE as onbngStore,
    SET_CARD_REQUEST
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
    STORE as SessionStore,
    GET_USER
} from "@/store/modules/session/session.types";
import {catchError} from "@/utils/utils";
import { EventBus } from "@/main";

const state = {
    organizationInfo: {},
    organizations: []
};

const getters = {
    [GET_ORGANIZATION_INFO]: (state) => state.organizationInfo,
    [GET_ORGANIZATIONS_INFO]: (state) => state.organizations
};

const actions = {
    async getOrganization({commit}, params) {
        const response = await OrganizationInfoApi.getById(params)
            .catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_ORGANIZATION_INFO, response.data.object);
            EventBus.$emit(EMIT_ORGANIZATION_INFO);
            return true
        }
        return false;
    },
    async getOrganizations({commit}) {
        const user = this.getters[`${SessionStore}/${GET_USER}`];
        const response = await OrganizationInfoApi.getByUserId({id: user._id})
            .catch(catchError);

        if (response.data && !response.data.error) {
            commit(SET_ORGANIZATIONS_INFO, response.data.object);
            return true
        }
        return false;
    },
    async saveBasicInfo({commit}, params) {
        const response = await OrganizationInfoApi.saveBasicInfo(params.image,
            {name: params.name,
                description: params.description,
                isMoral: params.isMoral,
                reloadImg: params.reloadImg,
                organizationId: params.organizationId
            })
            .catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_ORGANIZATION_INFO, response.data.object);
            EventBus.$emit(EMIT_ORGANIZATION_INFO);
            return true
        }
        return false;
    },
    async saveCountryOperates({commit}, params) {
        const response = await OrganizationInfoApi.saveCountryOperates(params)
            .catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_ORGANIZATION_INFO, response.data.object);
            return true
        }
        return false;
    },
    async saveOrgToCardRequest({commit}, params) {
        const response = await CardApi.saveOrgToCardRequest(params)
            .catch(catchError);
        if (response.data && !response.data.error) {
            commit(`${onbngStore}/${SET_CARD_REQUEST}`, response.data.object, { root: true })
            return true
        }
        return false;
    },
    async saveBusinessInfo({commit}, params) {
        const response = await OrganizationInfoApi.saveBusinessInfo(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_ORGANIZATION_INFO, response.data.object);
            return true
        }
        return false;
    },
    async saveFiscalData({commit}, params) {
        const response = await OrganizationInfoApi.saveFiscalData(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_ORGANIZATION_INFO, response.data.object);
            return true
        }
        return false;
    },
    async saveFiscalAddress({commit}, params) {
        const response = await OrganizationInfoApi.saveFiscalAddress(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_ORGANIZATION_INFO, response.data.object);
            return true
        }
        return false;
    },
    async saveCommercialAddress({commit}, params) {
        const response = await OrganizationInfoApi.saveCommercialAddress(params).catch(catchError);
        if (response.data && !response.data.error) {
            commit(SET_ORGANIZATION_INFO, response.data.object);
            return true
        }
        return false;
    },
};

const mutations = {
    [SET_ORGANIZATION_INFO]: (state, data) => {
        state.organizationInfo = data
    },
    [SET_ORGANIZATIONS_INFO]: (state, data) => {
        state.organizations = data
    }
};

export default {
    namespaced: true,
    state: {
        ...state
    },
    getters: {
        ...getters
    },
    actions: {
        ...actions
    },
    mutations: {
        ...mutations
    }
};
