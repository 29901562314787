<template>
    <div class="form-group password-input" :class="clazz">
        <input :id="inputId"
                ref="input"
                :type="inputType"
                class="form-control"
                :placeholder="inputPlaceholder"
                @input="onInputPassword"
                @keyup.enter="onEnterKey">

        <button class="show-btn"
                @click="onChangePasswordType"
                type="button"
                aria-label="Mostrar y esconder contraseña"
                name="Mostrar y esconder contraseña">
                <i aria-hidden="true" class="" :class="[isVisibleOn ? 'iconsax-security-eye': 'iconsax-security-eye-slash']"></i>
        </button>

        <div class="input-label">
            <label class="" :for="inputId">{{label}} <span v-if="required">*</span></label>
            <div v-if="help" :id="idHelpTooltip" class="help-info">
                {{helpText}} <i aria-hidden="true" :class="helpIcon"></i>
                <slot name="tooltipHelp"></slot>
            </div>
        </div>

        <small class="form-text-info" v-if="warning">
          <i v-if="warningIcon" aria-hidden="true" :class="warningIcon"></i>
          {{warningText}}
        </small>

        <StrongLevel v-if="hasLevelBar" level :clazz="clazzPassword" :levelText="status"></StrongLevel>
    </div>
</template>

<script>
    import StrongLevel from '@/components/StrongLevel.vue'

    export default {
        name: 'PasswordInput',
        data() {
            return {
            isVisibleOn: true
            }
        },
        components: {
            StrongLevel
        },
        props: {
            help: {type: Boolean, default: false},
            idHelpTooltip: String,
            showlevel: {type: Boolean, default: false},
            clazzPassword: String,
            status: String,
            required: {type: Boolean, default: false},
            label: String,
            helpText: String,
            inputPlaceholder: String,
            helpIcon: String,
            inputId: String,
            clazz: String,
            inputType: String,
            hasLevelBar: {
                type: Boolean,
                default: true
            },
            focusOnLoad: {
                type: Boolean,
                default: false,
            },
            warningIcon: {
              type: String,
              default: "banana banana-warning-circle1"
            },
            warning: {
              type: Boolean,
              default: false
            },
            warningText: String
        },
        mounted (){
            if (this.focusOnLoad) {
                this.focusInput()
            }
        },
        methods: {
            onInputPassword(event) {
                this.$emit('passwordInput', event.target.value)
            },
            onChangePasswordType() {
                this.isVisibleOn = !this.isVisibleOn;
                this.$emit('passwordInputType', this.isVisibleOn)
            },
            onEnterKey(){
              this.$emit('enterKey');
            },
            onTabKey(){
              this.$emit('onTabKey');
            },
            focusInput(){
                try {
                    this.$refs.input.focus()
                } catch {
                    // Attempt to focus input failed, no action taken
                }
            },
        }
    }
</script>
