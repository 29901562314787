<template>
  <div>
    <LoadingBar v-if="!showSave" clazz="fullcontent-loading" loadingText="Cargando..."></LoadingBar>
    <div v-if="showLoading">
      <LoadingSimple
          clazz="big-load p-t-50 p-b-50"
          label="Loading..."
      ></LoadingSimple>
    </div>
    <div v-else>
      <TitlePrincipal
          :title="$t('register.documentsBus.title')"
          :subtitle="$t('register.documentsBus.subtitle')"
      >
      </TitlePrincipal>
      <div v-for="(document, index) in documents" :key="document._id">
        <CardSelection
            :title="document.title"
            :subtitle="`${document.description} / ${document.allowedFormats.join(
            ' ,')}`"
            :clazz="`border-card ${progressBars[index].clazz}`"
        >
          <template v-slot:icon>
            <i aria-hidden="true" :class="`banana ${getIcon(document.maskType)}`"></i>
          </template>
          <template v-slot:extraMiddleContent>
            <b-progress
                :max="maxProgressBar"
                class="bar-progress percent-color"
                :class="{ success: progressBars[index].value === 100 }"
            >
              <b-progress-bar :value="progressBars[index].value">
                <div class="label-progress">
                  <span>{{ progressBars[index].value }}%</span>
                </div>
              </b-progress-bar>
            </b-progress>
          </template>
          <template v-slot:extraFinalContent>
            <button type="button" class="button xs button-filled button-primary p-relative c-pointer">
              {{$t('register.documentsBus.upload')}}
              <input type="file" class="input-file" value="" name="" @change="onFilePicked($event, index ,document)"/>
            </button>
          </template>
        </CardSelection>
      </div>
      <div class="footer-buttons right">
        <button
            @click="back()"
            type="button"
            class="button button-ghost button-plain_text"
            :name="$t('register.documentsBus.back')"
        >
          {{ $t("register.nationality.back") }}
        </button>
        <button
            v-if="showSave"
            type="button"
            class="button button-filled button-primary"
            :name="$t('register.documentsBus.next')"
            :disabled="!disableButton"
            @click="saveDocuments()"
        >
          {{ $t("register.nationality.continue") }}
        </button>
        <button v-else :id="'Loading button'" class="button button-filled button-primary">
          <LoadingSimple clazz="small-load no-text" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_CARD_REQUEST,
  GET_DOCUMENTS,
  GET_GROUP_FILES, GET_TYPE_ACCOUNT,
  GO_TO_ROUTE,
  LOAD_GROUP_FILES,
  STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";

import {
  GET_ORGANIZATION_INFO,
  STORE as orgInfoStore
} from "@/store/modules/organizationInfo/organizationInfo.types";
import OrgInfoApi from "@/api/core/organizationInfo.api";

import TitlePrincipal from "@/components/TitlePrincipal";
import CardSelection from "@/components/CardSelection";
import LoadingBar from '@/components/LoadingBar';
import LoadingSimple from "@/components/LoadingSimple";
import {mapGetters} from "vuex";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {catchError} from "@/utils/utils";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import {GET_FULL_DATA_USER, STORE as sessionStore} from "@/store/modules/session/session.types";

const clazzSuccess = "icon-bgm-success";
const clazzLoad = "icon-bgm-accent2";
const clazzError = "icon-bgm-error";
const clazzNormal = "icon-bgm-info_text";

export default {
  name: "Documents",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    CardSelection,
    LoadingBar,
    LoadingSimple
  },
  data() {
    return {
      showLoading: true,
      maxProgressBar: 100,
      progressBars: {},
      fileDocuments: [],
      groupKey: "",
      showSave: true,
    }
  },
  computed: {
    ...mapGetters(onbngStore, {
      groupFile: GET_GROUP_FILES,
      documents: GET_DOCUMENTS,
      typeAccount: GET_TYPE_ACCOUNT,
      cardRequest: GET_CARD_REQUEST
    }),
    ...mapGetters(orgInfoStore, {
      orgInfo: GET_ORGANIZATION_INFO
    }),
    ...mapGetters(sessionStore, {
      fullDataUser: GET_FULL_DATA_USER
    }),
    disableButton() {
      return (
          // this.documents.length > 0 &&
          this.groupKey &&
          this.documents.length === this.fileDocuments.length &&
          Object.values(this.progressBars).every((e) => e.value === 100)
      );
    },
  },
  methods: {
    back() {
      this.$store.dispatch(`${onbngStore}/backBusiness`);
    },
    getIcon(mask) {
      switch (mask) {
        case "CARDS":
          return "banana-credit-card-generic";
        case "DOCUMENT":
          return "banana-document-normal";
        default:
          return "banana-camera";
      }
    },
    onFilePicked(event, index, document) {
      this._setProgress(index, 0);
      if (event.target.files.length) {
        this._setProgressClazz(index, clazzLoad);
        const file = event.target.files[0];
        if (this._isValidFormat(document, file.type)) {
          this._setProgress(index, 25);
          const fileReader = new FileReader();
          fileReader.addEventListener("load", () => {
            this._setProgress(index, 50);
            this._addFile(file, fileReader.result, document, index);
          });
          fileReader.readAsDataURL(file);
        } else {
          this._setProgress(index, 0);
          this._setProgressClazz(index, clazzError);
          this.displayNotificationError(
              this.$i18n.t("register.uploadDocuments.errorFormat")
          );
        }
      } else {
        this._setProgress(index, 0);
        this._setProgressClazz(index, clazzError);
        this.displayNotificationError(
            this.$i18n.t("register.uploadDocuments.error")
        );
      }
    },
    async _addFile(file, raw, doc, index) {
      this._setProgress(index, 75);
      const newFile = {
        title: `${doc.title}-${this.orgInfo.name}`,
        key: `${doc.title}-${this.orgInfo.name}`,
        fileDocument: doc._id,
        isRequired: doc.isRequired,
        file: {
          name: file.name || doc.title,
          type: file.type,
          size: file.size,
          data: raw.split(',')[1],
        },
      };
      if (this.fileDocuments.length === this.documents.length) {
        const index = this.fileDocuments.findIndex(f => f.fileDocument === doc._id);
        this.fileDocuments.splice(index, 1);
      }
      this.fileDocuments.push(newFile);
      this._setProgress(index, 100);
      this._setProgressClazz(index, clazzSuccess);
    },
    _isValidFormat(doc, type) {
      const formats = [...doc.allowedFormats];
      if (formats.includes("jpg")) {
        formats.push("jpeg");
      }
      for (const format of formats) {
        if (type.match(format)) {
          return true;
        }
      }
      return false;
    },
    _setProgressClazz(i, clazz) {
      this.progressBars[i].clazz = clazz;
      this.$forceUpdate();
    },
    _setProgress(i, level) {
      this.progressBars[i].value = level;
      this.$forceUpdate();
    },
    async getFiles(nationality, processWhereApply, residence) {
      await this.$store.dispatch(`${orgInfoStore}/getOrganization`, { id: this.cardRequest.organizationInfo });
      const groups = await this.$store.dispatch(`${onbngStore}/${LOAD_GROUP_FILES}`, {
        nationality: nationality,
        processWhereApply: processWhereApply,
        residence: residence,
        cardRequest: this.cardRequest?._id,
        appliesToMoral: true
      });
      if (groups.length) {
        this.groupKey = groups[0]._id;
        const res = await this.$store.dispatch(
            `${onbngStore}/getFilesDocuments`,
            {key: this.groupKey}
        );
        if (!res) {
          this.displayNotificationError(this.$i18n.t('default.register.documentsBus.errors.loadDocuments'));
        }
      } else {
        this.displayNotificationError(this.$i18n.t('default.register.documentsBus.errors.loadGroup'))
      }
    },
    fillProgressBars() {
      for (let i = 0; i < this.documents.length; i++) {
        this.progressBars[i] = {
          value: 0,
          clazz: clazzNormal,
          hasError: false,
        };
      }
      this.assignData();
      this.showLoading = false;
    },
    assignData() {
      if (this.orgInfo && this.orgInfo.requestedFiles.length) {
        for(let i = 0; i < this.orgInfo.requestedFiles.length; i++) {
          this.fileDocuments.push(this.orgInfo.requestedFiles[i]);
          this._setProgress(i, 100);
          this._setProgressClazz(i, clazzSuccess);
        }
      }
    },
    async saveDocuments() {
      this.showSave = false;
      const response = await OrgInfoApi.saveDocuments({
        groupId: this.groupKey,
        fileDocuments: this.fileDocuments,
        orgInfo: this.orgInfo._id
      }).catch(catchError)
      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(this.$i18n.t('register.documentsBus.errors.uploadDocuments'))
        } else {
          this.nextStep();
        }
      } else {
        this.displayNotificationError(this.$i18n.t('register.documentsBus.errors.uploadDocuments'))
      }
      this.showSave = true;
    },
    nextStep() {
      this.$store.dispatch(`${orgInfoStore}/getOrganization`, { id: this.cardRequest.organizationInfo });
      this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
        stepOnbng: ONBOARDING_STEPS.BUSINESS_INFO,
      });
      if(this.$route.name === 'businessDocumentsMultiple') {
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "businessDataMultiple");
      } else {
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "businessData");
      }
    }
  },
  async beforeMount() {

    await this.$store.dispatch(`${sessionStore}/getFullDataByLevel`, {level: this.cardRequest.information_level});

    if(this.$route.name === 'businessDocumentsMultiple') {
      this.$store.commit(`${onbngStore}/SET_CREATE_NEW_ORG`, true);
      await this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_BUSINESS_MORAL');
    }

    await this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);

    let nationality;
    let processWhereApply;
    let residence;

    if (this.$route.name === 'businessDocumentsMultiple') {
      nationality = this.fullDataUser.nationalitieOnboarding._id;
      processWhereApply = 'ONBOARDING_LEVEL_3';
      residence = this.fullDataUser.countryNewOnboarding._id;
    } else {
      nationality = localStorage.getItem(btoa("nationality")) || this.fullDataUser.nationalitieOnboarding?._id;
      processWhereApply = localStorage.getItem(btoa("processWhereApply"));
      residence = localStorage.getItem(btoa("residence")) || this.fullDataUser.countryNewOnboarding?._id;
    }

    if (nationality && processWhereApply && residence) {
      await this.getFiles(nationality, processWhereApply, residence);
    } else {
      this.back();
    }

    this.fillProgressBars();
  },
}
</script>

<style scoped>

</style>
