<template>
    <div class="beneficiary-info" :class="clazz">
        <img
            class="img-fluid"
            :src="imgUrl"
            width="42"
            height="42"
            alt="Imagen beneficiario" />
        <span class="info" :class="clazzInfo">
            <label>{{text}}</label>
            <p>{{textBold}}</p>
        </span>
    </div>
</template>

<script>
    import Imagen from '@/assets/images/Default/profile-photo.svg';

    export default {
        name: 'BeneficiaryInfo',
        props: {
            clazz: String,
            clazzInfo: String,
            text: String,
            textBold: String,
            imgUrl: {type: String, default: Imagen},
        },
        data() {
            return {
                defaultUrl: Imagen,
            }
        }
    }
</script>
