<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/settings/security"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('settings.security.changePass.goBack')}}
                            </router-link>
                            <FloatingTitleLayout :titleStrong="$t('settings.security.title')"></FloatingTitleLayout>
                        </div>
                        <div class="col-12" v-if="!passChangeSuccess && !showForgotPass && !emailSend">
                            <CardSimple :title="$t('settings.security.changePass.title')" :subtitle="$t('settings.security.changePass.subtitle')">
                                <div class="row" v-if="!loading">
                                    <div class="col-12">
                                        <PasswordInput
                                            required
                                            :inputType="passInputTypeActual"
                                            inputId="examplep1"
                                            :label="$t('settings.security.changePass.actualPass.title')"
                                            :inputPlaceholder="$t('settings.security.changePass.actualPass.placeholder')"
                                            v-on:passwordInput="onActualPassword"
                                            v-on:passwordInputType="changeTypeActualPassword"
                                            :hasLevelBar="false"
                                        />
                                    </div>
                                    <div class="col-12">
                                        <PasswordInput
                                            required
                                            :inputType="passInputType"
                                            inputId="user_psw"
                                            :label="$t('register.newAccount.passLabel')"
                                            :inputPlaceholder="$t('register.newAccount.passPlace')"
                                            level
                                            :clazzPassword="clazzPasswordStatus"
                                            :status="$t(labelPasswordStatus)"
                                            v-on:passwordInput="onPasswordChangeRegister"
                                            v-on:passwordInputType="changeTypePasswordRegister"
                                            v-on:enterKey="onEnterKey"
                                        />
                                        <b-alert class="alert-note" show variant="accent2" v-if="showStatusBar">
                                            <p class="text-container c-plain_text f-10 d-block">
                                            {{$t('register.newAccount.passTip')}}
                                            <ul class="m-t-5 m-0 p-0 list-style-none">
                                                <li class="f-10 poppins-font-semibold m-b-5 d-flex" :class="[passLength ? 'c-accent_2' : 'c-error']">
                                                <i aria-hidden="true" class="banana f-12 m-r-10" :class="[passLength ? 'banana-check-normal' : 'banana-cross-normal']"></i>
                                                {{$t('register.newAccount.passTip6min')}}
                                                </li>
                                                <li class="f-10 poppins-font-semibold m-b-5 d-flex" :class="[passNo ? 'c-accent_2' : 'c-error']">
                                                <i aria-hidden="true" class="banana f-12 m-r-10" :class="[passNo ? 'banana-check-normal' : 'banana-cross-normal']"></i>
                                                {{$t('register.newAccount.passTipNo')}}
                                                </li>
                                                <li class="f-10 poppins-font-semibold m-b-5 d-flex" :class="[passCapLetter ? 'c-accent_2' : 'c-error']">
                                                <i aria-hidden="true" class="banana f-12 m-r-10" :class="[passCapLetter ? 'banana-check-normal' : 'banana-cross-normal']"></i>
                                                {{$t('register.newAccount.passTipCap')}}
                                                </li>
                                                <li class="f-10 poppins-font-semibold m-b-5 d-flex" :class="[passSymbol ? 'c-accent_2' : 'c-error']">
                                                <i aria-hidden="true" class="banana f-12 m-r-10" :class="[passSymbol ? 'banana-check-normal' : 'banana-cross-normal']"></i>
                                                {{$t('register.newAccount.passTipSymbol')}}
                                                </li>
                                            </ul>
                                            </p>
                                        </b-alert>
                                    </div>
                                    <div class="col-12">
                                        <PasswordInput
                                            ref="user_confirm_psw"
                                            required
                                            :inputType="passInputType"
                                            inputId="user_confirm_psw"
                                            :label="$t('register.newAccount.repeatPassword')"
                                            :inputPlaceholder="$t('register.newAccount.repeatPassword')"
                                            :hasLevelBar="false"
                                            v-on:passwordInput="onPasswordRepeat"
                                            v-on:passwordInputType="changeTypePasswordRegister"
                                        />
                                    </div>
                                    <div class="col-12">
                                        <div class="control-buttons">
                                            <div class="vertical-center">
                                                <!-- <button
                                                    @click="showForgotPassProcess()"
                                                    type="button"
                                                    class="button button-ghost button-primary">
                                                    ¿Olvidaste tu contraseña?
                                                </button> -->
                                                <button
                                                    :disabled="disabledChangePass"
                                                    type="button"
                                                    @click="changePass()"
                                                    class="button button-filled button-primary">
                                                    {{$t('settings.security.changePass.restartPass')}}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <LoadingBar
                                    v-if="loading"
                                    :loadingText="$t('settings.security.changePass.changePassLoading')"
                                ></LoadingBar>
                            </CardSimple>
                        </div>
                        <!-- <div class="col-12" v-if="showForgotPass && !emailSend">
                            <CardSimple title="¿Has olvidado tu contraseña?"
                            subtitle="¡No te preocupes! Restablecer tu contraseña es fácil, solo vamos a enviarte un email con instrucciones para restablecer tu contraseña.">
                                <div class="row">
                                    <div class="col-12">
                                        <BasicInput
                                            required
                                            inputId=""
                                            v-model="email"
                                            :validations="$v.email"
                                            label="Correo electrónico"
                                            inputPlaceholder="Ej: pablo@mail.com">
                                        </BasicInput>
                                    </div>
                                    <div class="col-12">
                                        <div class="control-buttons">
                                            <div class="vertical-center">
                                                <button
                                                    :disabled="disableButton" @click="sendEmail()"
                                                    type="button"
                                                    class="button button-filled button-primary">
                                                    Restablecer
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardSimple>
                        </div> -->
                        <!-- <div class="col-12" v-if="emailSend">
                            <CardSimple hideTitle>
                                <StatusNote
                                status="default"
                                title="Revisa tu correo electrónico"
                                text="Hemos enviado un correo electrónico a <strong class='poppins-font-bold'>cgonzalez@blacklabs.mx</strong> con
                                las instrucciones para que puedas recuperar tu contraseña."
                                :imgNote="false"
                                >
                                <div class="m-t-30 text-cemter">
                                    <button
                                        type="button"
                                        @click="sendEmail()"
                                        class="button button-stroke button-primary m-auto">
                                        Reenviar correo
                                    </button>
                                    <div class="m-t-30 c-text_info f-12 poppins-font-regular">
                                        El correo electrónico puede tardar unos minutos en llegar,
                                        si no lo has recibido puedes presionar el botón <strong>“Reenviar correo”</strong>
                                    </div>
                                </div>
                                </StatusNote>
                            </CardSimple>
                        </div> -->
                        <div class="col-12" v-if="passChangeSuccess && !loading">
                            <CardSimple hideTitle>
                                <StatusNote
                                status="successs"
                                :title="$t('settings.security.changePass.success.title')"
                                :text="$t('settings.security.changePass.success.text')"
                                :imgWidth="284"
                                :imgHeight="280"
                                imgAlt="Contraseña restablecida"
                                >
                                <div class="m-t-30">
                                    <button
                                        @click="goBack()"
                                        type="button"
                                        class="button button-filled button-primary m-auto">
                                        {{$t('settings.security.changePass.success.ready')}}
                                    </button>
                                </div>
                                </StatusNote>
                            </CardSimple>
                        </div>
                        <div class="col-12">
                            <div class="control-buttons">
                                <button
                                    @click="back()"
                                    type="button"
                                    :disabled="passChangeSuccess"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('settings.security.back')}}
                                </button>
                                <div class="f-13 c-text_content poppins-font-medium text-center text-md-right">
                                    {{$t('settings.security.needChange')}}
                                    <a
                                            href="https://www.pagando.mx/contacto-pagando/" target="_blank"
                                            class="c-info poppins-font-bold text-underline hover"
                                            tabindex="">
                                            {{$t('settings.security.contactUs')}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>

    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import PasswordInput from "@/components/PasswordInput.vue";
    import LoadingBar from "@/components/LoadingBar.vue";
    import StatusNote from "@/components/StatusNote.vue";
    // import BasicInput from "@/components/BasicInput.vue";
    import imgStatus from '@/assets/images/Illustrations/illustration-gift.svg';
    import imageModalTrash from '@/assets/images/Icons/icon-alert.svg';
    import {sequential, sequentialSymbols, sortByLength} from "@/utils/utils";
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import UserApi from "@/api/users/users.api";
    import {catchError} from "@/utils/utils";
    import {regexEmail} from "@/utils/regexUtils";
    const {required, minLength, sameAs} = require('vuelidate/lib/validators');
    const errorMessage = 'La nueva contraseña tiene que ser diferente a la actual';

    export default {
        name: "SecurityChangePass",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            CardSimple,
            PasswordInput,
            StatusNote,
            // BasicInput,
            LoadingBar
        },
        data() {
            return {
                // Images
                imgStatus: imgStatus,
                imageModalTrash: imageModalTrash,
                window: {
                    width: 0
                },
                isMenuOpen: false,
                showForgotPass: false,
                emailSend: false,
                passChangeSuccess: false,
                passInputType: 'password',
                passInputTypeActual: 'password',
                showStatusBar: false,
                passLength: false,
                passCapLetter: false,
                passNo: false,
                passSymbol: false,
                isValidPass: false,
                isValidActualPass: false,
                registerPassword: "",
                repeatPassword: "",
                clazzPasswordStatus: "",
                labelPasswordStatus: "",
                actualPassword: "",
                timeoutRepeat: null,
                loading: false,
                email: null,
                emailFinal: null
            }
        },
        computed: {
            disabledChangePass () {
                return !(this.isValidPass);
            },
            disableButton() {
                return !this.email || !regexEmail.test(this.email);
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            onPasswordRepeat (password) {
                this.repeatPassword = password;
                this.$v.$touch();
                const isEquals = (this.actualPassword !== this.registerPassword);
                this.isValidPass = !this.$v.$invalid && isEquals;
                clearTimeout(this.timeoutRepeat);
                this.timeoutRepeat = setTimeout(() => {
                    if(this.repeatPassword !== this.registerPassword) {
                        this.displayNotificationWarning(this.$t('settings.security.changePass.errors.passNoMatch'));
                    } else if(!isEquals && this.actualPassword !== '') {
                        this.displayNotificationWarning(errorMessage);
                    }
                }, 1000);

            },
            onActualPassword (password) {
                this.actualPassword = password;
                this.$v.$touch();
                const isEquals = (this.actualPassword !== this.registerPassword);
                this.isValidPass = !this.$v.$invalid && isEquals;

                clearTimeout(this.timeoutRepeat);
                this.timeoutRepeat = setTimeout(() => {
                    this.showToast(isEquals);
                }, 1000);
            },
            changeTypeActualPassword (isVisibleOn) {
                if (isVisibleOn) {
                    this.passInputTypeActual = 'password';
                } else {
                    this.passInputTypeActual = 'text';
                }
            },
            changeTypePasswordRegister (isVisibleOn) {
                if (isVisibleOn) {
                    this.passInputType = 'password';
                } else {
                    this.passInputType = 'text';
                }
            },
            onEnterKey() {
                if (this.$refs.user_confirm_psw) {
                    this.$refs.user_confirm_psw.focusInput();
                }
            },
            getPositivePoints (password) {
                const numberOfCharacters = password.length;
                const uppercaseLetters = (password.match(/[A-Z]/g) || []).length;
                const lowercaseLetters = (password.match(/[a-z]/g) || []).length;
                const numberOnPassword = (password.match(/[0-9]/g, "") || []).length;
                const symbols =
                    numberOfCharacters -
                    uppercaseLetters -
                    lowercaseLetters -
                    numberOnPassword;

                let middleSymbolsNumbers = symbols + numberOnPassword;
                if (numberOfCharacters > 2 && (symbols > 0 || numberOnPassword > 0)) {
                    if (password[0].match(/[a-zA-Z]/g) == null) {
                    middleSymbolsNumbers -= 1;
                    }

                    if (password[numberOfCharacters - 1].match(/[a-zA-Z]/g) == null) {
                    middleSymbolsNumbers -= 1;
                    }
                    if (middleSymbolsNumbers < 0) {
                    middleSymbolsNumbers = 0;
                    }
                }

                let requirements = 0;
                if (
                    numberOfCharacters > 6 &&
                    uppercaseLetters > 0 &&
                    lowercaseLetters > 0 &&
                    numberOnPassword > 0 &&
                    symbols > 0
                ) {
                    requirements = 10;
                }

                this.passLength = numberOfCharacters > 5;
                this.passCapLetter = uppercaseLetters > 0;
                this.passNo = numberOnPassword > 0;
                this.passSymbol = symbols > 0;

                return (
                    numberOfCharacters * 4 +
                    (numberOfCharacters - uppercaseLetters) * 2 +
                    (numberOfCharacters - lowercaseLetters) * 2 +
                    numberOnPassword * 4 +
                    symbols * 6 +
                    middleSymbolsNumbers * 2 +
                    requirements
                );
            },
            getRepeatCharacter (password) {
                const pattern = /(.+)(?=.*?\1)/g;
                let score = 0;
                if (pattern.test(password)) {
                    const matches = password.match(pattern);
                    const maxResultLength = sortByLength(matches)[0].length;
                    let ratio = 0;
                    if (maxResultLength >= 1 && maxResultLength <= 5) {
                    ratio = -8;
                    }
                    if (maxResultLength >= 6 && maxResultLength <= 10) {
                    ratio = -5;
                    }
                    if (maxResultLength >= 11) {
                    ratio = -2;
                    }
                    score =
                        ratio * maxResultLength + (password.length - maxResultLength * 2);
                    if (score > 0) {
                    score = 0;
                    }
                }
                return score;
            },
            onPasswordChangeRegister (password) {
                this.registerPassword = password;
                let positivePoints = this.getPositivePoints(password);
                if (/^[a-zA-Z]+$/.test(password)) {
                    positivePoints = positivePoints - password.length;
                }

                if (/^\d+$/.test(password)) {
                    positivePoints = positivePoints - password.length;
                }

                positivePoints += this.getRepeatCharacter(password);

                const groups = password.match(/[A-Z]+|[a-z]+|[0-9]+/g);
                if (groups) {
                    const groupsFilter = groups.filter(function (element) {
                    return element.length >= 2;
                    });

                    let consecutiveCharacter = 0;
                    for (const group of groupsFilter) {
                    consecutiveCharacter += (group.length - 1) * 2;
                    }

                    positivePoints -= consecutiveCharacter;
                }

                positivePoints -= sequential(password);

                positivePoints -= sequentialSymbols(password);

                if (positivePoints <= 0 && password.length > 0) {
                    positivePoints = 1;
                }
                // H}J=mT?Av69`kt<L

                if (positivePoints === 0) {
                    this.clazzPasswordStatus = "";
                    this.labelPasswordStatus = "";
                    this.showStatusBar = false;
                } else if (positivePoints < 40) {
                    this.clazzPasswordStatus = "weak";
                    this.labelPasswordStatus = 'register.newAccount.passStatusWeak';
                    this.showStatusBar = true;
                } else if (positivePoints < 60) {
                    this.clazzPasswordStatus = "regular";
                    this.labelPasswordStatus = 'register.newAccount.passStatusRegular';
                    this.showStatusBar = true;
                } else if (positivePoints < 80) {
                    this.clazzPasswordStatus = "good";
                    this.labelPasswordStatus = 'register.newAccount.passStatusGood';
                    this.showStatusBar = true;
                } else {
                    this.clazzPasswordStatus = "excellent";
                    this.labelPasswordStatus = 'register.newAccount.passStatusExc';
                    this.showStatusBar = true;
                }

                this.$v.$touch();
                const isEquals = (this.actualPassword !== this.registerPassword);

                this.isValidPass = !this.$v.$invalid && isEquals;

                clearTimeout(this.timeoutRepeat);
                this.timeoutRepeat = setTimeout(() => {
                    this.showToast(isEquals);
                }, 1000);
            },
            showToast(isEquals) {
                if(!isEquals && this.actualPassword !== '') {
                        this.displayNotificationWarning(errorMessage);
                    }
            },
            async changePass() {
                this.loading = true;
                if(!this.disabledChangePass) {
                    const obj = {
                        actualPassword: this.actualPassword,
                        newPassword: this.registerPassword,
                        newPasswordConfirm: this.repeatPassword
                    };
                    const res = await UserApi.changePass(obj).catch(catchError);

                    if(res && res.data && !res.data.error) {
                        this.passChangeSuccess = true;
                    } else {
                        const message = res.data ? res.data.message : this.$t('settings.security.changePass.errors.changePass');
                        this.toastErrorDefault(message);
                    }
                } else {
                    this.toastErrorDefault(this.$t('settings.security.changePass.errors.changePass'));
                }
                this.loading = false;
            },
            goBack() {
                this.$router.push({name: 'Security'});
            },
            goToContact() {
                this.$router.push({name: 'Contact'});
            },
            showForgotPassProcess() {
                this.showForgotPass = true;
            },
            back() {
                if(this.showForgotPass) {
                    this.showForgotPass = false;
                } else {
                    this.goBack();
                }
            },
            async sendEmail() {
                const obj = {
                    email: this.email,
                    platform: 'WALLET'
                }
                const res = await UserApi.recoverPassOnlyEmail(obj).catch(catchError);
                if(res && res.data && !res.data.error) {
                    this.emailSend = true;
                    this.emailFinal = res.data.email;
                } else {
                    this.toastErrorDefault('Ocurrio un error al enviar correo');
                }
            }
        },
        mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        },
        validations: {
            registerPassword: {
                required,
                minLength: minLength(6)
            },
            actualPassword: {
                required,
                minLength: minLength(6)
            },
            repeatPassword: {
                sameAsPassword: sameAs('registerPassword')
            }
        }
    };
</script>
