<template>
  <div class="video-container" >
    <!-- <div class="webcam-photo-container"> -->
      <!-- <div class="webcam-window"></div> -->
      <video v-if="!isPhotoTaken" class="image-inverted-x" :id="`video-${$props.camId}`" :width="width" :height="height"
             autoplay playsInline></video>
      <!-- <canvas v-show="isProccesed" id="photoTaken" ref="canvas" :width="450" :height="337.5"></canvas> -->
      <img v-if="isProccesed" :src="imageProccesed" alt="" class="cut-image"/>
      <canvas class="d-none" :id="`canvas-${$props.camId}`" :width="canvasWidth" :height="canvasHeight"></canvas>
      <!-- <div class="photo-btn-container">
        <slot name="cameraButtons"></slot>
      </div> -->
    <!-- </div> -->

    <p v-if="startCounting" class="start-counter">{{timeStart}}</p>
    <span v-if="showTimeLeft" class="time-left">{{timeLeft}}</span>
        <slot name="cameraButtons"></slot>
    <slot name="note"></slot>

    <!-- <button @click="takePicture()">Take photo</button> -->

    <vue-cropper v-if="isPhotoTaken && !isProccesed"
      viewMode="1"
      :containerStyle="{'max-height':'100vh', 'max-width':'100vw', 'min-width': 'calc(100vw - 150px) !important'}"
      ref="cropper"
      :aspectRatio="NaN"
      :center="false"
      :guides="false"
      :src="imgSrc"
      :background="false"
      dragMode="move"
      :initialAspectRatio="16/9"
    />

    <!-- <div v-if="imgTaken">
      <div class="img-card">
        <img class="img-fluid" src="https://img1.looper.com/img/uploads/2019/02/harry-potter.jpg"
             alt="picture"/>
      </div>
      <span v-if="verified" class="verified-check"><i aria-hidden="true" class="banana banana-check-circle1"></i> Verificado Correctamente </span>
    </div> -->
  </div>
</template>


<script>

import {EventBus} from "@/main";
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

// Elements for taking the snapshot
let canvas;
let context;
let videoElement;
let mediaRecord;
let callbackonstop;

export default {
  name: "CameraTest",
  data() {
    return {
      videoSelectedIndex: 0,
      videoSources: [],
      videoSourceAvailable: false,
      videoTracks: [],
      parts: [],
      blob: null,
      timeLeft: 10,
      timeStart: 3,
      startCounting: false,
      showTimeLeft: false,
      alreadyStop: false,
      contador: null,
      isPhotoTaken: false,
      isProccesed: false,
      imageProccesed: null,
      cameraSelected: null,
      imgSrc: null,
    }
  },
  components: {
    VueCropper,
  },
  created() {
    EventBus.$on(`global.cameraVue.turn-off`, () => {
      this.alreadyStop = true;
      this.showTimeLeft = false;
      clearInterval(this.contador);
      this.stopVideoCapture();
    });

    EventBus.$on(`global.cameraVue.play-video`, () => {
      videoElement.play();
      videoElement.onended = () => {
        this.$emit('videoEnded', true);
      };
    });

  },
  beforeDestroy() {
    this.stopCamera()
    EventBus.$off(`global.cameraVue.turn-off`);
  },
  methods: {

    // video componente
    initCamera(callback) {
      this.isPhotoTaken = false;
      this.isProccesed = false;
      this.videoSources = [];
      canvas = document.getElementById(`canvas-${this.$props.camId}`);
      context = canvas.getContext('2d');
      const videoElementId = `video-${this.$props.camId}`;
      videoElement = document.getElementById(videoElementId);
      videoElement.autoplay = true;
      window.videoElement = videoElement;
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        // Not adding `{ audio: true }` since we only want video now
        const promise = navigator.mediaDevices.enumerateDevices();
        promise.then(this.gotDevices);
        promise.then(this.getStream);
        if (callback) {
          promise.then(callback)
        }
      }
    },
    stopCamera(callback) {
      if (window.stream) {
        window.stream.getTracks().forEach(function (track) {
          track.stop();
        });
        videoElement.pause();
        videoElement.src = null;
        videoElement.srcObject = null;
        this.$forceUpdate();
        if (callback) {
            callback()
        }
      }
    },
    playCamera(callback) {
      if (this.blob) {
        const url = window.URL.createObjectURL(this.blob);
        videoElement.removeAttribute("controls");
        videoElement.removeAttribute('autoplay');
        videoElement.src = url;
        this.$forceUpdate();
        videoElement.load();
        this.$forceUpdate();
        // videoElement.play();
      }
      // videoElement.play();
      if (callback) {
        callback()
      }
    },
    pauseCamera(callback) {
      videoElement.pause();
      if (callback) {
        callback()
      }
    },
    replay() {
      videoElement.play();
    },

    // Record video
    startVideoCapture(callback) {
			this.$emit('startingRecord', true);

            this.startCounting = true;

      const contador = setInterval(() => {
        if(this.timeStart <= 0){
            this.showTimeLeft = true;
            this.startCounting = false;
			this.$emit('startingRecord', false);

            if (window.stream && MediaRecorder) {
              mediaRecord = new MediaRecorder(window.stream);
              this.parts = [];
              this.blob = null;
              mediaRecord.ondataavailable = this._onDataAvailable;
              mediaRecord.onstop = this._onStopVideo;
              mediaRecord.start(200)
              if (callback) {
                callback();
              }

              this.countDown();
              // setTimeout(this.countDown, 1000);
            }
            clearInterval(contador);
          }
          // else {
            this.timeStart -= 1;

            if(this.timeStart === 0) {
            this.startCounting = false;

            }
          // }
        }, 1000);
    },
    countDown() {
      this.contador = setInterval(() => {
          if(this.timeLeft <= 0){
            this.showTimeLeft = false;
            if(!this.alreadyStop) {
              this.stopVideoCapture();
            }
            clearInterval(this.contador);
          } else {
            this.timeLeft -= 1;
          }
        }, 1000);
    },
    playVideoCapture(callback) {
      mediaRecord.resume();
      if (callback) {
        callback();
      }
    },
    pauseVideoCapture(callback) {
      mediaRecord.pause();
      if (callback) {
        callback();
      }
    },
    stopVideoCapture(callback) {
      if (window.stream) {
        mediaRecord.stop();
        callbackonstop = callback;
      }

      this.timeLeft = 10;
      this.timeStart = 3;
      this.showTimeLeft = false;
      this.startCounting = false;
      this.alreadyStop = false;
    },
    _onStopVideo() {
      this.blob = new Blob(this.parts, {
        type: "video/mp4"
      })
      this.parts = [];
      this.$forceUpdate();

      if (callbackonstop) {
        callbackonstop(this.blob)
      } else {
        this.$emit('stopVideo', this.blob);
      }
    },

    // images
    takePicture(callback) {
      // this.isPhotoTaken = true;

      videoElement.pause();
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      // Trigger photo take
      context.drawImage(videoElement, 0, 0);
      const pngBase64 = canvas.toDataURL();

      this.imgSrc = pngBase64;
      this.isPhotoTaken = true;


      if(callback) {
        callback();
      }
      // TODO emit con foto

    },
    processPicture(callback) {
      const output = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg');
      this.isProccesed = true;
      this.imageProccesed = output;
      callback(output);

    },
    dataURLtoBlob(dataURL) {
      // Decode the dataURL
      const binary = atob(dataURL.split(',')[1]);
      // Create 8-bit unsigned array
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      // Return our Blob object
      return new Blob([new Uint8Array(array)], {type: 'image/png'});
    },
    getVideoSources() {
      return this.videoSources;
    },
    // Set Up
    gotDevices(deviceInfos) {
      const VIDEO_INPUT_TYPE = "videoinput";
      for (let i = 0; i !== deviceInfos.length; ++i) {
        const deviceInfo = deviceInfos[i];
        if (deviceInfo.kind === VIDEO_INPUT_TYPE) {
          this.videoSourceAvailable = true;
          this.videoSources.push({
            index: this.videoSources.length,
            id: deviceInfo.deviceId,
            name: deviceInfo.label || `camera ${this.videoSources.length}`
          })
        }
      }
    },
    getStream() {
      if (!this.videoSources || !this.videoSources.length) {
        // console.log("WARNING: There's no video available");
        this.videoSourceAvailable = false;
        return;
      }
      if (window.stream) {
        window.stream.getTracks().forEach(function (track) {
          track.stop();
        });
      }

      let hdConstraints;
      if (this.cameraSelected) {
        hdConstraints = {
          video: {
            // facingMode: this.cameraSelected
            // ESTE ES EL QUE SIRVE
            deviceId: {exact: this.videoSources[this.videoSelectedIndex].id},
            width: { ideal: 1920 },
            height: { ideal: 1080 },
          }
        };
      } else {
        hdConstraints = {
          // video: true,
          video: {
            width: { ideal: 1920 },
            height: { ideal: 1080 },
        }
        };
      }
      navigator.mediaDevices.getUserMedia(hdConstraints)
          .then(this.gotStream)
    },
    gotStream(stream) {
      this.videoTracks = [];
      window.stream = stream; // make stream available to console
      window.stream.getTracks().forEach((track) => {
        this.videoTracks.push(track)
      });
      videoElement.srcObject = stream;
    },
    nextVideoSource(cameraSelected, indexCameraSelected) {
      this.cameraSelected = cameraSelected;
      // this.getStream()
      // console.log("VIDEO SOURCES", this.videoSources);
      // console.log("videoSelectedIndex", this.videoSelectedIndex);

      // ESTO ES LO QUE SIRVEEE CAMBIAR A QUE EL USUARIO SELECCIONE LA CAMARA TRASERA.
      // if(this.cameraSelected === 'user') {
      //     this.videoSelectedIndex = 0;
      // } else {
      //     this.videoSelectedIndex = this.videoSources.length - 1;
      // }

      this.videoSelectedIndex = indexCameraSelected;

      // if (this.videoSources && this.videoSources.length) {
      //   if (this.videoSelectedIndex + 1 < this.videoSources.length) {
      //     this.videoSelectedIndex++;
      //   } else {
      //     this.videoSelectedIndex = 0;
      //   }
      // }

      //   console.log("VIDEO SELECTED", this.videoSelectedIndex);
        this.getStream()
      // } else {
      //   // console.log("Error trying to get video source");
      // }
    },
    // private
    _onDataAvailable(event) {
      this.parts.push(event.data);
    },
  },
  props: {
    camId: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 1280
    },
    height: {
      type: Number,
      default: 720
    },
    canvasWidth: {
      type: Number,
      default: 1280
    },
    canvasHeight: {
      type: Number,
      default: 720
    },
    imgTaken: {
      type: Boolean,
      default: false
    },
    verified: {
      type: Boolean,
      default: false
    },
    // Where the image must be placed within the Amazon S3
    imageClassification: {
      type: String,
      required: true
    },
    // A unique identifier for the image
    imageNamePrefix: {
      type: String,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    },
  }
}
</script>

<style lang="scss" scoped>

img {
  width: 100%;
}

.image-inverted-x {
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  width: 100%;
  // max-height: 335px;
  height: auto;
}

.webcam-photo-container {
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 20px;
}

.webcam-window {
  width: 91%;
  height: calc(100% - 90px);
  border-radius: 8px;
  opacity: .8;
  position: absolute;
  z-index: 9;
  transform: translate(-50%, 0px);
  left: 50%;
  top: 20px;
}

.photo-btn-container {
  position: absolute;
  bottom: 24px;
  z-index: 9;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px;

  > .button {
    margin: 0 5px 5px;
    padding-left: 10px !important;
    padding-right: 10px !important;

    &:first-of-type i,
    &:last-of-type i {
      margin-right: 0;
    }
  }
}

.button {
  white-space: nowrap;
}

button {
  text-transform: inherit;
}
</style>
