<template>
  <div class="col-12">
    <CardSimple :title="$t('dashboard.movementsChart.title')" showButtons>
      <template v-slot:titleButtons>
        <div class="daterange-table d-none d-md-flex">
          <button @click="subtractWeek">
            <i aria-hidden="true" class="iconsax-arrow-direction-left"></i>
          </button>
          <date-range-picker
            ref="picker"
            opens="right"
            :showDropdowns="false"
            :autoApply="true"
            :showWeekNumbers="true"
            :linked-calendars="false"
            :ranges="false"
            singleDatePicker="range"
            v-model="week"
            @hover-date="onHover"
            @start-selection="onClickDate"
            :locale-data="timeOptions"
          >
            <!-- eslint-disable -->
            <template v-slot:input="picker">
              <span
                >{{ moment(week.startDate).format("ll") }} -
                {{ moment(week.endDate).format("ll") }}</span
              >
            </template>
            <!-- eslint-enable -->
          </date-range-picker>
          <button @click="addWeek">
            <i aria-hidden="true" class="iconsax-arrow-direction-right"></i>
          </button>
        </div>
      </template>
      <div class="graph-container">
        <div class="money-legend">
          <div class="abonos">
            <span>{{$t('dashboard.movementsChart.abonosWeek')}}</span>
            {{ totalAbonos | currency }}
          </div>
          <div class="cargos">
            <span>{{$t('dashboard.movementsChart.cargosWeek')}}</span>
            {{ totalCargos | currency }}
          </div>
        </div>
        <apexchart
          width="100%"
          height="330px"
          type="bar"
          :options="chartMovimientos"
          :series="seriesMovimientos"
          class=""
        ></apexchart>
      </div>
    </CardSimple>
  </div>
</template>
<script>
import CardSimple from "@/components/CardSimple.vue";
import DateRangePicker from "vue2-daterange-picker";

import utils from "@/utils/utils";

import moment from 'moment';

export default {
  name: "MovementsChart",
  components: {
    CardSimple,
    DateRangePicker,
  },
  props: {
    chartData: Object,
  },
  data() {
    return {
      week: {
        startDate: moment().day(0).toDate(),
        endDate: moment().day(6).toDate(),
      },
    };
  },
  computed: {
    timeOptions() {
      return {
        weekLabel: "#",
        daysOfWeek: [...Array(7).keys()].map(n => this.$t(utils.getWeekDayByNoAbbr(n))),
        monthNames: [...Array(12).keys()].map(n => this.$t(utils.getMonthByNo(n))),
      };
    },
    movements() {
      if(this.chartData?.movements) {
        return this.chartData.movements;
      }
      const dates = [...Array(7).keys()].map(n => moment(this.week.startDate).day(n).toISOString());
      return dates.map(date => ({date, abonos: 0, cargos: 0}));
    },
    totalAbonos() {
      return this.chartData?.abonos || 0;
    },
    totalCargos() {
      return this.chartData?.cargos || 0;
    },
    chartMovimientos() {
      return {
        chart: {
          id: "vuechart-movimientos",
          toolbar: {
            show: false,
          },
        },
        legend: {
          position: "top",
          offsetY: 40,
          markers: {
            width: "18px",
            height: "18px",
            strokeWidth: 0,
            fillColors: ["#BFF7FF", "#FF3D72"],
            radius: 3,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            horizontal: false,
            columnWidth: 40,
            endingShape: "rounded",
            labels: {
              show: false,
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 10,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.movements.map(({date}) => [
            moment(date).format('ddd'),
            moment(date).format('DD MMM'),
          ]),
          labels: {
            style: {
              colors: [
                "#5F6185",
                "#5F6185",
                "#5F6185",
                "#5F6185",
                "#5F6185",
                "#5F6185",
                "#5F6185",
              ],
            },
          },
        },
        fill: {
          opacity: 1,
          colors: ["#BFF7FF", "#FF3D72"],
        },
        tooltip: {
          followCursor: false,
          fillSeriesColor: true,
        },
        states: {
          normal: {
            filter: {
              type: "none",
            },
          },
          hover: {
            filter: {
              type: "none",
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "none",
            },
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              xaxis: {
                labels: {
                  rotate: -90,
                },
                categories: this.movements.map(({date}) => moment(date).format('DD MMM')),
              },
              stroke: {
                width: 3,
              },
            },
          },
        ],
      };
    },
    seriesMovimientos() {
      return [
        {
          name: this.$t('dashboard.movementsChart.abonos'),
          data: this.movements.map(move => move.abonos),
        },
        {
          name: this.$t('dashboard.movementsChart.cargos'),
          data: this.movements.map(move => move.cargos),
        },
      ]
    },
  },
  created() {
    moment.locale(this.$i18n.locale);
  },
  methods: {
    moment,
    subtractWeek() {
      this.onClickDate(moment(this.week.startDate).subtract(7, 'days'))
    },
    addWeek() {
      this.onClickDate(moment(this.week.startDate).add(7, 'days'))
    },
    onHover(date) {
      this.$refs.picker.start = moment(date).day(0).toDate();
      this.$refs.picker.end = moment(date).day(6).toDate();
    },
    onClickDate(date) {
      const startDate = moment(date).day(0).toDate();
      const endDate = moment(date).day(6).toDate();
      this.$refs.picker.start = startDate;
      this.$refs.picker.end = endDate;
      this.$refs.picker.in_selection = false;
      this.$refs.picker.togglePicker(false, true);
      this.week = {startDate, endDate};
      this.$emit('dateChange', startDate.toISOString());
    },
  },
};
</script>
