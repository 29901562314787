import {CHECK_PERMISSION, GET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            permissionTypes: [
                "TRANSFERS",
                "BENEFICIARIES",
                "COLLABORATORS",
                "REQUEST_VIRTUAL_ADDITIONAL_CARD",
                "DISPERSIONS",
                "CORPORATIVE_ACCOUNTS"
            ],
            permissionActions: ["READ", "REQUEST", "AUTHORIZE"],
        };
    },
    computed: {
        ...mapGetters(sessionStore, {
            infoAccount: GET_ACCOUNT,
        }),
        accountId() {
            return this.infoAccount?.account?._id;
        },
        canRequestCollaborator() {
            const checkPermission = this.$store.getters[`${sessionStore}/${CHECK_PERMISSION}`];
            return checkPermission('REQUEST', 'COLLABORATORS');
        },
        canAuthorizeCollaborator() {
            const checkPermission = this.$store.getters[`${sessionStore}/${CHECK_PERMISSION}`];
            return checkPermission('AUTHORIZE', 'COLLABORATORS');
        },
    },
    methods: {
        getBadgeClass({status, badge}) {
            const classes = {
                ACCOUNT_HOLDER: 'badge badge-primary',
                COLLABORATOR: 'badge badge-success',
                PENDING_AUTHORIZATION: 'badge badge-warning',
                REJECTED: 'badge badge-error',
                PENDING_INVITE: 'badge badge-info',
                DRAFT: 'badge badge-text_content',
                PENDING_EDIT: 'badge badge-warning',
                PENDING_REMOVAL: 'badge badge-error',
            };
            return classes[badge] || classes[status] || '';
        },
        checkPermission({status, permissions}, action, type) {
            if (status === 'ACCOUNT_HOLDER') {
                return true;
            }
            return permissions.some(p => p.action === action && p.type === type);
        },
    },
}
