import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'coupons';

export default {
    getPublicCampaigns: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/get-public-campaigns`, params)
    },
    referralCode: (params) => {
        return axios.post( `${base.baseUrlWallet}/referral-code-redemption/api-v1/redeem-code/${params.code}`)
    },
    validateCode: (params) => {
        return axios.post( `${base.baseUrl}/payment-process/find-by-code`, params);
    },
    getAllianceCampaigns: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/get-alliance-campaigns`, {params: params});
    }
}
