<template>
    <b-modal
        id="modal-pagando-check-sign"
        size="lg"
        hide-header
        hide-footer
        @change="change"
        modal-class="modal-default left-oriented"
    >
        <button
            class="close-modal"
            @click="$bvModal.hide('modal-acuerdo-traspaso-info')"
            aria-label="Boton cerrar ventana"
            name="Boton cerrar ventana"
        >
            <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </button>
        <div class="head">
            <h2
                class="modal-title"
                v-text="$t('pagandoCheck.modal-sign.title')"
            ></h2>
            <p v-text="$t('pagandoCheck.modal-sign.subtitle')"></p>
        </div>
        <span class="vertical-center m-b-10">
            <p
                class="f-18 c-primary_1 poppins-font-semibold d-block m-b-5"
                v-text="$t('pagandoCheck.modal-sign.sign-instructions')"
            ></p>
            <button
                type="button"
                class="button xs button-stroke button-primary m-0-0-0-auto"
                @click="undoSignature"
                v-text="$t('pagandoCheck.modal-sign.delete')"
            ></button>
        </span>
        <div>
            <VueSignaturePad
                id="signaturePad"
                width="100%"
                height="300px"
                ref="signaturePad"
                :options="optionsPad"
            />
        </div>
        <div class="modal-terminos-privacidad p-r-0 p-t-30 p-b-0">
            <button
                id=""
                type="button"
                class="button button-filled button-primary"
                v-text="$t('pagandoCheck.modal-sign.sign')"
                @click="sign"
                :disabled="!canSend"
            ></button>
        </div>
        <LoadingBar
            clazz="fullcontent-loading"
            v-if="loading"
        />
    </b-modal>
</template>
<script>
import DocumentsApi from "@/api/core/documents.api.js";
import {mapGetters} from "vuex";
import {
    STORE as sessionStore,
    GET_ACCOUNT,
} from "@/store/modules/session/session.types";
import moment from 'moment';
import LoadingBar from "@/components/LoadingBar.vue";
import VueSignaturePad from 'vue-signature-pad';
import OrganizationApi from '@/api/core/organization.api.js';
import config from "@/config";

export default {
    name: "InfoTransferContract",
    components: {
        LoadingBar,
        VueSignaturePad
    },
    data() {
        return {
            loading: false,
            modifiedSignature: false,
            moment,
            optionsPad: {
                onEnd: this.onEnd
            },
        };
    },
    computed: {
        ...mapGetters(sessionStore, {
            account: GET_ACCOUNT
        }),
        canSend() {
            return this.modifiedSignature;
        }
    },
    methods: {
        undoSignature() {
            this.$refs.signaturePad.undoSignature();
        },
        async change() {
            setTimeout(() => this.$refs.signaturePad.resizeCanvas(), 10);
        },
        /**
         * Elimina la parte del base64 que contiene el encoding
         */
        processImg(img) {
            if (/^data:image\/png;base64,/.test(img)) {
                return img.replace(/^data:image\/png;base64,/, "");
            }
            return img;
        },
        /**
         * Obtiene los datos de la firma
         */
        getSignature() {
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if (!isEmpty) {
                const img = this.processImg(data);
                return {
                    name: `firma representante legal`,
                    mimetype: "image/png",
                    size: img.length,
                    data: img,
                };
            }
            return null;
        },
        acceptContract() {
            DocumentsApi.acceptContract({
                documentType: "INFO_TRANSFER",
                appliesTo: "WALLET",
                discriminators: [this.account?.account?.accountType?._id],
                profileType: "USER",
                signature: this.getSignature(),
            }).then(({data}) => data);
        },
        async sign() {
            try {
                this.loading = true;
                const responseAccept = await this.acceptContract();
                if (responseAccept?.error) {
                    return this.toastErrorDefault();
                }

                const response = await OrganizationApi.dataTransferSend({accountId: this.account?.account?._id,});

                // send organizationInfoDocuments one by one (lighter requests)
                for(const requestedFile of response.organizationInfo.requestedFiles) {
                    await OrganizationApi.documentSend({organization: response.organization._id, requestedFile});
                }

                // TODO sacar url de variables de entorno
                window.open(`${config.check.host}:${config.check.port}${config.check.endpoint}/wallet-hire?token=${this.response?.session?.resultData?.token}`, '_blank');

                this.$bvModal.hide('modal-pagando-check-sign');
                await this.$store.dispatch(`${sessionStore}/loadAccount`);
                return response;
            } catch(error) {
                return this.toastErrorDefault(error);
            } finally {
                this.loading = false;
            }
        },
        onEnd() {
            this.modifiedSignature = true;
        }
    },
};
</script>
