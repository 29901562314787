<template>
  <div>
    <div class="row">
      <div class="col-12">
        <router-link :to="{name: 'BulkContactsList'}" class="button xs button-ghost button-text_info p-l-0 p-r-0"
          tabindex="">
          <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
          Regresar a Importar Contactos
        </router-link>
      </div>

      <div class="col-12">
        <CardSimple class="m-t-20" title="Subir Archivo" subtitle="Sube la plantilla de contactos que deseas importar">
          <div class="row">
            <div class="col-12">
              <UploadFileCard ref="uploadFile" title="bulkContacts.new.templateDispersion"
                subtitle="bulkContacts.new.templateDispersionSub" buttonUpload="bulkContacts.new.bCard.upload"
                buttonUploading="bulkContacts.new.bCard.uploading" buttonRetry="bulkContacts.new.bCard.retry"
                errorDisplay="bulkContacts.new.error.uploadFile" @load="(file) => fileToSend = file">
              </UploadFileCard>
            </div>
          </div>
        </CardSimple>
        <div class="control-buttons">
          <button @click="$router.push({name: 'BulkContactsList'})" type="button" class="button-icon">
            <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
            Regresar
          </button>
          <div class="vertical-center">
            <button type="button" class="button button-stroke button-primary" @click="downloadTemplate">
              Descargar plantilla
            </button>
            <button v-if="!uploading" @click="submit" :disabled="!fileToSend" type="button"
              class="button button-filled button-primary">
              Procesar Archivo
            </button>
            <button v-else type="button" class="button button-stroke button-primary">
              <LoadingSimple clazz="small-load no-text" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import CardSimple from "@/components/CardSimple.vue";
import LoadingSimple from "@/components/LoadingSimple";
import UploadFileCard from "@/components/UploadFileCard";

import ContactsApi from "@/api/wallet/contacts.api";

export default {
  name: "BulkContactsNew",
  components: {
    CardSimple,
    LoadingSimple,
    UploadFileCard,
  },
  data() {
    return {
      fileToSend: null,
      uploading: false,
    }
  },
  methods: {
    downloadTemplate() {
      window.open(ContactsApi.downloadTemplate());
    },
    async submit() {
      this.uploading = true;
      try {
        const formData = new FormData();
        formData.append('fileXlsx', this.fileToSend);
        const response = await ContactsApi.uploadBulkFile(formData);
        const { id, status } = response.data.object;
        if(status === 'PENDING_AUTHORIZATION') {
          this.$router.push({name: 'BulkContactsList'});
        } else {
          this.$router.push({
            name: 'BulkContactsDetails',
            params: {id},
          });
        }
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.uploading = false;
    }
  },
};
</script>
