<template>
<div>
  <TitlePrincipal :title="$t('register.nationality.titleBusiness')">
    <p class="subtitle" v-html="$t('register.nationality.subtitleBusiness')">
    </p>
  </TitlePrincipal>
  <BasicSelect
      clazz="flag-select "
      required
      label="País">
    <multiselect
        v-model="currentCountry"
        :options="optionsFlag"
        placeholder="Selecciona el pais donde opera tu negocio"
        label="name"
        track-by="_id"
        :option-height="104"
        :show-labels="false">
      <template
          slot="singleLabel"
          slot-scope="props">
        <div class="d-flex align-items-center">
          <img
              class="flag-img"
              :src="getImgUrl(props.option.flagName)"
              alt="image"
          >
          <div class="text">
            {{ props.option.name }}
          </div>
        </div>
      </template>
      <template
          slot="option"
          slot-scope="props">
        <div class="d-flex align-items-center">
          <img
              class="flag-img"
              :src="getImgUrl(props.option.flagName)"
              alt="image2"
          >
          <div class="text">
            {{ props.option.name }}
          </div>
        </div>
      </template>
    </multiselect>
  </BasicSelect>
  <div class="footer-buttons right">
    <button type="button" class="button button-ghost button-plain_text" name="Regresar" @click="back"> {{ $t('register.nationality.back') }} </button>
    <button v-if="!loadingButton" type="button" class="button button-filled button-primary" name="Continuar" :disabled="disableButton" @click="submitNation">
      {{ $t('register.nationality.continue') }} </button>
    <button v-else :id="'Loading button'" class="button button-filled button-primary">
      <LoadingSimple clazz="small-load no-text" />
    </button>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import BasicSelect from "@/components/BasicSelect";
import Multiselect from "vue-multiselect";
// const SAMPLE_IMAGE = 'https://cdn.zeplin.io/5a34794be8354b6a0e1a7f79/assets/7A399DEF-2E94-4B09-AEC0-CDA1170A8AB4.png';
import CountriesApi from '@/api/core/countries.api'
import {catchError} from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {required} from "vuelidate/lib/validators";
import LoadingSimple from "@/components/LoadingSimple";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import { mapGetters } from 'vuex';
import {
  GET_CARD_REQUEST,
  GO_TO_ROUTE,
  STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";

export default {
  name: "OperateBusiness",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    BasicSelect,
    Multiselect,
    LoadingSimple
  },
  data() {
    return {
      optionsFlag: [],
      currentCountry: {},
      loadingButton: false
    }
  },
  computed: {
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO
    }),
    ...mapGetters(onbngStore, {
      cardRequest: GET_CARD_REQUEST
    }),
    disableButton() {
      return this.$v.$invalid || this.currentCountry?.isoCode !== 'MX';
    }
  },
  methods: {
    back() {
      this.$store.dispatch(`${onbngStore}/backBusiness`);
    },
    getImgUrl(pet) {
      const images = require.context('@/assets/images/Icons/flags', false, /\.png$/)
      try {
        return images(`./${pet}`);
      } catch(e) {
        return images(`./Faroe_Islands.png`);
      }
    },
    async getCountries() {
      const response = await CountriesApi.getCountries().catch(catchError);
      if (response && response.data) {
        if (response.data.error) {
          this.displayNotificationError(this.$i18n.t('register.nationality.errorCountries'))
        } else {
          this.optionsFlag = response.data.object || [];
          const mexico = this.optionsFlag.find(country => country.isoCode === 'MX');
          if(mexico) {
            this.currentCountry = mexico;
          }
        }
      } else {
        this.displayNotificationError(this.$i18n.t('register.nationality.errorCountries'));
      }
      this.assignData();
    },
    assignData() {
      if (this.organization && this.organization.invoiceData) {
          this.currentCountry = this.organization.invoiceData.nationality || this.currentCountry || {};
          if(!this.currentCountry._id) {
            this.currentCountry = this.optionsFlag.find(c => c._id.toString() === this.currentCountry);
          }
      }
    },
    async submitNation() {
      this.loadingButton = true;
      if (!this.disableButton) {
        const created = await this.$store.dispatch(`${OrganizationInfoStore}/saveCountryOperates`,
        {
          id: this.organization._id,
          country: this.currentCountry._id
        });
        if (created) {
          this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.UPLOAD_DOCUMENTS,
          });
          localStorage.setItem(btoa("nationality"), this.currentCountry._id);
          localStorage.setItem(btoa("processWhereApply"), "ONBOARDING_LEVEL_3");
          localStorage.setItem(btoa("residence"), this.currentCountry._id);
          if(this.$route.name === 'businessOperatesMultiple') {
            this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "businessDocumentsMultiple");
          } else {
            this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, "businessDocuments");
          }
        } else {
          this.displayNotificationError(this.$i18n.t('register.nationality.saveInfo'));
        }
      }
      this.loadingButton = false;
    }
  },
  async created() {

    if(this.$route.name === 'businessOperatesMultiple') {
      this.$store.commit(`${onbngStore}/SET_CREATE_NEW_ORG`, true);
      await this.$store.dispatch(`${onbngStore}/putLevel`, 'MULTIPLE_BUSINESS_MORAL');
    }
    this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);


    this.getCountries();
  },
  validations: {
    currentCountry: {
      required
    },
  },
}
</script>

<style scoped>

</style>
