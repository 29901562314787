<template>
  <div>
    <b-dropdown
      id="dropdown-notifications"
      right
      class="dropdown-notifications"
    >
      <template #button-content>
        <button
          type="button"
          class="button-square xs button-ghost button-info p-relative ml-0 ml-md-4"
          aria-label="Notificaciones"
          name="Notificaciones"
        >
          <i
            aria-hidden="true"
            class="iconsax-notifications-bell-bold f-28"
          ></i>
          <span
            class="badge-circle badge-error"
            v-if="totalUnreadedNotifications > 0"
            >{{
              totalUnreadedNotifications > 50
                ? "+50"
                : totalUnreadedNotifications
            }}</span
          >
        </button>
      </template>
      <b-dropdown-header id="dropdown-header-1">{{
        $t("notifications.title")
      }}</b-dropdown-header>
      <div class="btn-filter-container">
        <button
          id=""
          class="btn-filter"
          :class="showReadedNotifications ? 'active' : ''"
          type="button"
          @click="showReadedNotifications = true"
        >
          {{ $t("notifications.all") }}
        </button>
        <button
          id=""
          class="btn-filter"
          :class="!showReadedNotifications ? 'active' : ''"
          type="button"
          @click="showReadedNotifications = false"
        >
          {{ $t("notifications.unreaded") }}
        </button>
      </div>
      <div v-if="showReadedNotifications">
        <EmptyState
          v-if="notificationsReaded.length === 0 && !loadingReadedNotifications"
          clazz="xs p-20"
          emptyStateDefault
          :title="$t('notifications.empty.title')"
          :text="$t('notifications.empty.text')"
          :imgUrl="emptyStateNotifications"
          :imgWidth="248"
          :imgHeight="185"
          imgAlt="Sin notificaciones recientes"
        ></EmptyState>
        <LoadingSimple v-if="loadingReadedNotifications"></LoadingSimple>
        <div
          v-if="!loadingReadedNotifications && notificationsReaded.length > 0"
        >
          <div
            v-for="(day, index) in notificationsReaded"
            :key="index"
            class="m-b-20"
          >
            <TitleBadge
              clazzContainer="m-b-0 p-l-20"
              clazz="xs text-upper m-b-0"
              :titleText="getDate(day.date)"
              :badgeText="day.objects.length.toString()"
            >
            </TitleBadge>
            <b-dropdown-item
              v-for="(notification, indexN) in day.objects"
              :key="indexN"
              @click="handleEventNotification(notification)"
            >
              <MovementsList
                clazz="list-notifications m-b-0"
                :class="notification.color"
                :icon="notification.icon"
                :movConcept="notification.data.notification.title"
                :movText="notification.data.notification.body"
                showTimeNotification
                :movTime="notification.timeAgo"
              >
                <button
                  type="button"
                  class="link-go-to"
                  name="Ver detalle"
                  aria-label="Ver detalle"
                >
                  <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                </button>
              </MovementsList>
            </b-dropdown-item>
          </div>
        </div>
      </div>
      <div v-else>
        <EmptyState
          v-if="
            notificationsNoReaded.length === 0 && !loadingUnreadedNotifications
          "
          clazz="xs p-20"
          emptyStateDefault
          :title="$t('notifications.empty.title')"
          :text="$t('notifications.empty.text')"
          :imgUrl="emptyStateNotifications"
          :imgWidth="248"
          :imgHeight="185"
          imgAlt="Sin notificaciones recientes"
        ></EmptyState>
        <LoadingSimple v-if="loadingUnreadedNotifications"></LoadingSimple>
        <div
          v-if="
            notificationsNoReaded.length > 0 && !loadingUnreadedNotifications
          "
        >
          <div
            v-for="(day, index) in notificationsNoReaded"
            :key="index"
            class="m-b-20"
          >
            <TitleBadge
              clazzContainer="m-b-0 p-l-20"
              clazz="xs text-upper m-b-0"
              :titleText="getDate(day.date)"
              :badgeText="day.objects.length.toString()"
            >
            </TitleBadge>
            <b-dropdown-item
              v-for="(notification, indexN) in day.objects"
              :key="indexN"
              @click="handleEventNotification(notification)"
            >
              <MovementsList
                clazz="list-notifications m-b-0"
                :class="notification.color"
                :icon="notification.icon"
                :movConcept="notification.data.notification.title"
                :movText="notification.data.notification.body"
                showTimeNotification
                :movTime="notification.timeAgo"
              >
                <button
                  type="button"
                  class="link-go-to"
                  name="Ver detalle"
                  aria-label="Ver detalle"
                >
                  <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                </button>
              </MovementsList>
            </b-dropdown-item>
            <!-- <b-dropdown-item>
                    <MovementsList
                        clazz="list-notifications mov-refund m-b-0"
                        icon="iconsax-media-volume-high"
                        movConcept="Aviso importante"
                        movText="Debido a la contingencia sanitaria vivida en la actualidad, Pagando se solidariza y rega..."
                        showTimeNotification
                        movTime="02:10 p.m.">
                        <button
                            type="button"
                            class="link-go-to"
                            name="Aviso importante"
                            aria-label="Aviso importante"
                            @click="$bvModal.show('modal-aviso-importante')">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </button>
                    </MovementsList>
                </b-dropdown-item>
                <b-dropdown-item>
                    <MovementsList
                        clazz="list-notifications mov-sent m-b-0"
                        icon="iconsax-security-security-user"
                        movConcept="Actualización de datos"
                        movText="Se ha realizado exitosamente una actualización de datos en tu cuenta."
                        showTimeNotification
                        movTime="11:34 a.m.">
                        <button
                            type="button"
                            class="link-go-to"
                            name="Actualización de datos"
                            aria-label="Actualización de datos"
                            @click="$bvModal.show('modal-actualizacion-datos-notif')">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </button>
                    </MovementsList>
                </b-dropdown-item>
                <TitleBadge
                    clazzContainer="m-t-10 m-b-0 p-l-20"
                    clazz="xs text-upper m-t-10 m-b-0"
                    titleText="Martes 14 AGO, 2021"
                    badgeText="10">
                </TitleBadge>
                <b-dropdown-item>
                    <MovementsList
                        clazz="list-notifications mov-purchase m-b-0"
                        icon="iconsax-money-card"
                        movConcept="Amazon MX"
                        showTimeNotification
                        movTime="Hace 10 minutos">
                        <template v-slot:strongText>
                            <p>
                                Has realizado una compra con
                                el concepto <strong>“Zapatos Flexi”</strong>
                            </p>
                        </template>
                        <button
                            type="button"
                            class="link-go-to"
                            name="Amazon MX"
                            aria-label="Amazon MX"
                            @click="$bvModal.show('modal-detail-purchase')">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </button>
                    </MovementsList>
                </b-dropdown-item>
                <b-dropdown-item>
                    <MovementsList
                        clazz="list-notifications mov-inquiry m-b-0"
                        icon="iconsax-money-dollar-circle"
                        movConcept="Consulta de saldo"
                        showTimeNotification
                        movTime="Hace 10 minutos">
                        <template v-slot:strongText>
                            <p>
                                Consulta de saldo en ATM
                            </p>
                        </template>
                        <button
                            type="button"
                            class="link-go-to"
                            name="Consulta de saldo"
                            aria-label="Consulta de saldo"
                            @click="$bvModal.show('modal-detail-inquiry')">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </button>
                    </MovementsList>
                </b-dropdown-item>
                <b-dropdown-item>
                    <MovementsList
                        clazz="list-notifications mov-received m-b-0"
                        icon="iconsax-money-moneys"
                        movConcept="Depósito a tu cuenta de $700.00"
                        showTimeNotification
                        movTime="Hace 2 horas">
                        <template v-slot:strongText>
                            <p>
                                Has recibido dinero por el concepto
                                <strong>“Pago de audífonos”</strong>
                            </p>
                        </template>
                        <button
                            type="button"
                            class="link-go-to"
                            name="Depósito a tu cuenta de $700.00"
                            aria-label="Depósito a tu cuenta de $700.00"
                            @click="$bvModal.show('modal-detail-received')">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </button>
                    </MovementsList>
                </b-dropdown-item>
                <b-dropdown-item>
                    <MovementsList
                        clazz="list-notifications mov-sent m-b-0"
                        icon="iconsax-money-money-send"
                        movConcept="Transferencia por $250.00"
                        showTimeNotification
                        movTime="Hace 22 minutos">
                        <template v-slot:strongText>
                            <p>
                                Transferencia exitosa con el concepto
                                <strong>“Pago de comida”</strong>
                            </p>
                        </template>
                        <button
                            type="button"
                            class="link-go-to"
                            name="Transferencia por $250.00"
                            aria-label="Transferencia por $250.00"
                            @click="$bvModal.show('modal-detail-sent')">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </button>
                    </MovementsList>
                </b-dropdown-item>
                <b-dropdown-item>
                    <MovementsList
                        clazz="list-notifications mov-refund m-b-0"
                        icon="iconsax-arrow-undo"
                        movConcept="Devolución por $300.00"
                        showTimeNotification
                        movTime="04:30 p.m.">
                        <template v-slot:strongText>
                            <p>
                                Se ha realizado exitosamente el
                                <strong>reembolso</strong> de tu compra
                            </p>
                        </template>
                        <button
                            type="button"
                            class="link-go-to"
                            name="Devolución por $300.00"
                            aria-label="Devolución por $300.00"
                            @click="$bvModal.show('modal-detail-refund')">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </button>
                    </MovementsList>
                </b-dropdown-item>
                <b-dropdown-item>
                    <MovementsList
                        clazz="list-notifications mov-charge m-b-0"
                        icon="iconsax-essential-warning-2"
                        movConcept="Pérdida de aclaración"
                        showTimeNotification
                        movTime="Hace 10 minutos">
                        <template v-slot:strongText>
                            <p>
                                Cargo a tu cuenta
                            </p>
                        </template>
                        <button
                            type="button"
                            class="link-go-to"
                            name="Pérdida de aclaración"
                            aria-label="Pérdida de aclaración"
                            @click="$bvModal.show('modal-detail-charge')">
                            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
                        </button>
                    </MovementsList>
                </b-dropdown-item> -->
          </div>
        </div>
      </div>
    </b-dropdown>
    <!-- MODAL AVISO IMPORTANTE -->
    <b-modal
      id="modal-aviso-importante"
      size=""
      hide-header
      hide-footer
      modal-class="modal-default left-oriented"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-aviso-importante')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">Aviso importante</h2>
        <p class="modal-subtitle">
          {{ getDateFormat(notificationToShowModal.date) }}
        </p>
      </div>
      <div class="body">
        <p class="f-14 c-text_content poppins-font-regular f-400 m-b-0">
          {{ notificationToShowModal.data.notification.body }}
        </p>
      </div>
      <!-- <div class="footer">
                <button type="button" class="button button-filled button-primary w-100">
                    Call to action si es necesario
                </button>
            </div> -->
    </b-modal>

    <ModalDeposit
      :movementToShow="movementToShowDetail"
      ref="modalDeposit"
    ></ModalDeposit>
    <ModalPurchase
      :movementToShow="movementToShowDetail"
      ref="modalPurchase"
    ></ModalPurchase>
    <ModalConsultATM
      :movementToShow="movementToShowDetail"
      ref="modalConsultATM"
    ></ModalConsultATM>
    <ModalWithdrawalATM
      :movementToShow="movementToShowDetail"
      ref="modalWithdrawalATM"
    ></ModalWithdrawalATM>
    <ModalSPEI
      :movementToShow="movementToShowDetail"
      ref="modalSPEI"
    ></ModalSPEI>
    <ModalTransfersPagando
      :movementToShow="movementToShowDetail"
      ref="modalTransfersPagando"
    ></ModalTransfersPagando>
    <ModalRefund
      :movementToShow="movementToShowDetail"
      ref="modalRefund"
    ></ModalRefund>
    <ModalClarification
      :movementToShow="movementToShowDetail"
      ref="modalClarification"
    ></ModalClarification>
    <ModalPoints
      :movementToShow="movementToShowDetail"
      ref="modalPoints"
    ></ModalPoints>
  </div>
</template>
<script>
import EmptyState from "@/components/EmptyState.vue";
import TitleBadge from "@/components/TitleBadge.vue";
import MovementsList from "@/components/MovementsList.vue";
import { catchError } from "@/utils/utils";
import NotificationsApi from "@/api/core/notifications.api";
import CardsApi from "@/api/wallet/card.api";
import moment from "moment";
import ModalDeposit from "@/components/modalsMovements/ModalDeposit.vue";
import ModalPurchase from "@/components/modalsMovements/ModalPurchase.vue";
import ModalConsultATM from "@/components/modalsMovements/ModalConsultATM.vue";
import ModalWithdrawalATM from "@/components/modalsMovements/ModalWithdrawalATM.vue";
import ModalSPEI from "@/components/modalsMovements/ModalSPEI.vue";
import ModalTransfersPagando from "@/components/modalsMovements/ModalTransfersPagando.vue";
import ModalRefund from "@/components/modalsMovements/ModalRefund.vue";
import ModalClarification from "@/components/modalsMovements/ModalClarification.vue";
import ModalPoints from "@/components/modalsMovements/ModalPoints.vue";
import emptyStateNotifications from "@/assets/images/Illustrations/illustration-notifications.svg";
import LoadingSimple from "@/components/LoadingSimple.vue";
import ToastSnotify from "@/mixins/toastSnotify.mixin";

const iconMoneyMoneyRecive = "iconsax-money-money-recive";
const iconMoneyMoneySend = "iconsax-money-money-send";
const iconMoneyCard = "iconsax-money-card";
const colorMovReceived = "mov-received";
const colorMovPurchase = "mov-purchase";
const modalCompra = "modal-detail-purchase";
const modalConsultSaldo = "modal-detail-inquiry";
const modalRetiroATM = "modal-detail-received";
const modalSPEI = "modal-detail-sent";
const modalDeposito = "modal-detail-received-2";
const modalTransfersPagando = "modal-detail-transfers-pagando";
const modalDevolucion = "modal-detail-refund";
const modalAclaracion = "modal-detail-charge";
const modalPuntos = "modal-detail-puntos";

export default {
  name: "NotificationsPanel",
  mixins: [ToastSnotify],
  components: {
    EmptyState,
    TitleBadge,
    MovementsList,
    ModalDeposit,
    ModalPurchase,
    ModalConsultATM,
    ModalWithdrawalATM,
    ModalSPEI,
    ModalTransfersPagando,
    ModalRefund,
    ModalClarification,
    ModalPoints,
    LoadingSimple,
  },
  data() {
    return {
      emptyStateNotifications: emptyStateNotifications,
      notificationsReaded: [],
      notificationsNoReaded: [],
      pageNotificationReaded: 1,
      pageNotificationNoReaded: 1,
      totalUnreadedNotifications: 0,
      showReadedNotifications: true,
      loadingUnreadedNotifications: true,
      loadingReadedNotifications: false,
      notificationToShowModal: {
        date: null,
        data: {
          notification: {
            body: null,
          },
        },
      },
      movementToShowDetail: {},
      totalPagesReaded: 0,
      totalPagesUnreaded: 0,
    };
  },
  methods: {
    async readNotification(notification) {
      const res = await NotificationsApi.changeStatus(notification).catch(
        catchError
      );

      if (res && res.data && !res.data.error) {
        await this.restart();
      }
    },
    async restart() {
      this.pageNotificationReaded = 1;
      this.pageNotificationNoReaded = 1;
      this.notificationsReaded = [];
      this.notificationsNoReaded = [];
      this.showReadedNotifications = true;

      this.loadingReadedNotifications = true;
      await this.getReadedNotifications();
      this.loadingReadedNotifications = false;
      this.loadingUnreadedNotifications = true;
      await this.getNoReadedNotifications();
      this.loadingUnreadedNotifications = false;
    },
    async handleEventNotification(notification) {
      if (notification.type === "novelty") {
        this.notificationToShowModal = notification;
        this.$bvModal.show(notification.modal);
      } else {
        await this.getMovementDetail(notification);
      }
    },
    parseMovement(movement) {
      let sign = "";
      let amountClass = [];
      if (movement.contaMovementType === "ABONO") {
        sign = "+";
        amountClass = ["c-success"];
      } else if (movement.contaMovementType === "CARGO") {
        sign = "-";
      }

      if (movement.type === "LOST_CHARGEBACK") {
        amountClass = ["c-error"];
      }

      const movementDescription = `${movement.movementDescription}`;

      let icon = iconMoneyCard;
      let color = colorMovPurchase;
      let modal = modalCompra;
      switch (movement.type) {
        case "Compra":
        case "COMPRA_DIGITAL":
        case "COMPRA_CONTACTLESS":
          modal = modalPuntos;
          break;
        case "COMPRA_CASHBACK":
          modal = modalCompra;
          break;
        case "COMMISSION":
          modal = modalCompra;
          break;
        case "Devolución":
          icon = "iconsax-arrow-undo";
          color = "mov-refund";
          modal = modalDevolucion;
          break;
        case "Retiro":
          icon = "iconsax-money-money";
          color = colorMovReceived;
          modal = modalRetiroATM;
          break;
        case "SPEIOUT":
          icon = iconMoneyMoneySend;
          color = "mov-sent";
          modal = modalSPEI;
          break;
        case "Deposito":
        case "CASHIN":
          icon = iconMoneyMoneyRecive;
          color = colorMovReceived;
          modal = modalDeposito;
          break;
        case "SPEIIN":
          icon = iconMoneyMoneyRecive;
          color = colorMovReceived;
          modal = modalSPEI;
          break;
        case "Transferencia":
          icon =
            movement.movementKey === "PT"
              ? iconMoneyMoneyRecive
              : iconMoneyMoneySend;
          color = movement.movementKey === "PT" ? colorMovReceived : "mov-sent";
          modal =
            movement.movementKey === "PT"
              ? modalDeposito
              : modalTransfersPagando;
          break;
        case "Consulta":
          icon = "iconsax-money-currency-circle";
          color = "mov-inquiry";
          modal = modalConsultSaldo;
          break;
        case "LOST_CHARGEBACK":
          modal = modalAclaracion;
          icon = "iconsax-essential-warning-2";
          color = "mov-charge";
          break;
        case "RewardsRedemption":
          modal = modalCompra;
          break;
        case "Transacción":
        default:
          break;
      }

      const timeAgo = moment(movement.date).fromNow();

      return {
        ...movement,
        sign,
        amountClass,
        movementDescription,
        icon,
        color,
        timeAgo,
        modal,
      };
    },
    openModal(movement) {
      switch (movement.modal) {
        case modalCompra:
          this.$refs.modalPurchase.openModal();
          break;
        case modalConsultSaldo:
          this.$refs.modalConsultATM.openModal();
          break;
        case modalRetiroATM:
          this.$refs.modalWithdrawalATM.openModal();
          break;
        case modalSPEI:
          this.$refs.modalSPEI.openModal();
          break;
        case modalDeposito:
          this.$refs.modalDeposit.openModal();
          break;
        case modalTransfersPagando:
          this.$refs.modalTransfersPagando.openModal();
          break;
        case modalDevolucion:
          this.$refs.modalRefund.openModal();
          break;
        case modalAclaracion:
          this.$refs.modalClarification.openModal();
          break;
        case modalPuntos:
          this.$refs.modalPoints.openModal();
          break;
        default:
          this.$refs.modalPurchase.openModal();
          break;
      }
    },
    async getMovementDetail(notification) {
      const res = await CardsApi.getMovementById(notification.cardMovement);

      if (res && res.data && !res.data.error) {
        const movement = res.data.object;

        this.movementToShowDetail = this.parseMovement(movement);

        this.openModal(this.movementToShowDetail);

        await this.readNotification(notification._id);
      } else {
        this.toastErrorDefault(this.$t("notifications.error.seeDetails"));
        this.$bvModal.hide("modal-detail-received-2");
      }
    },
    getDate(date) {
      let newDate;
      try {
        newDate = new Date(date);
      } catch (err) {
        newDate = new Date();
      }
      const today = moment();
      const yesterday = moment().subtract(1, "d").startOf("day");
      if (today.isSame(newDate, "day")) {
        return this.$t("notifications.today");
      } else if (yesterday.isSame(newDate, "day")) {
        return this.$t("notifications.yesterday");
      }
      return moment(date).format("dddd DD MMM YYYY");
    },
    async getReadedNotifications() {
      const res = await NotificationsApi.getAll({
        read: true,
        page: this.pageNotificationReaded,
      }).catch(catchError);

      if (res && res.data && !res.data.error) {
        this.totalPagesReaded = res.data.object.totalPages;
        const objects = this.orderByDate(
          res.data.object.docs,
          this.notificationsReaded
        );
        this.notificationsReaded = objects;
      } else {
        this.toastErrorDefault(this.$t("notifications.error.getReaded"));
      }
    },
    getDateFormat(date) {
      return moment(date).format("DD [de] MMMM YYYY, HH:mm a");
    },
    orderByDate(notifications, data = []) {
      const aux = [...data];
      const group = {};
      notifications.forEach((movement) => {
        const date = moment(movement.date).startOf("day").toString();
        group.date = date;
        const index = aux.findIndex((o) => o.date === date);
        if (index === -1) {
          group.objects = [];
          aux.push({ ...group });
        } else {
          group.objects = aux[index].objects;
        }
        group.objects.push(this.parseNotification(movement));
      });
      return aux;
    },
    parseNotification(notification) {
      let icon = iconMoneyCard;
      let color = colorMovPurchase;
      let modal = "modal-detail-purchase";
      switch (notification.type) {
        case "PB46": // Compra
        case "PB47":
        case "PB48":
        case "PB50":
        case "PB61":
          icon = iconMoneyCard;
          color = colorMovPurchase;
          break;
        case "ABONO": // Deposito SPEI IN
          icon = "iconsax-money-receive";
          color = "mov-received";
          modal = "modal-detail-received";
          break;
        case "CARGO": // Transferencia SPEI OUT
          icon = "iconsax-money-send";
          color = "mov-sent";
          modal = "modal-detail-sent";
          break;
        case "novelty": // Avisos importantes
          icon = "iconsax-media-volume-high";
          color = "mov-refund";
          modal = "modal-aviso-importante";
          break;
        default:
          break;
      }

      const timeAgo = moment(notification.date).fromNow();

      return {
        ...notification,
        icon,
        color,
        timeAgo,
        modal,
      };
    },
    async getNoReadedNotifications() {
      const res = await NotificationsApi.getAll({
        read: false,
        page: this.pageNotificationNoReaded,
      }).catch(catchError);
      if (res && res.data && !res.data.error) {
        this.totalPagesUnreaded = res.data.object.totalPages;
        const objects = this.orderByDate(
          res.data.object.docs,
          this.notificationsNoReaded
        );
        this.notificationsNoReaded = objects;
        this.totalUnreadedNotifications = res.data.object.totalDocs;
      } else {
        this.toastErrorDefault(this.$t("notifications.error.getUreaded"));
      }
    },
  },
  async created() {
    await this.restart();
  },
  async mounted() {
    this.$root.$on("bv::dropdown::show", async () => {
      await this.restart();
    });

    // Detect when scrolled to bottom.
    const listElm = document
      .querySelector("#dropdown-notifications")
      .querySelector("ul");
    listElm.addEventListener("scroll", () => {
      (async () => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          if (this.showReadedNotifications) {
            if (
              this.pageNotificationReaded < 5 &&
              this.pageNotificationReaded < this.totalPagesReaded
            ) {
              this.pageNotificationReaded++;

              await this.getReadedNotifications();
            }
          } else {
            if (
              this.pageNotificationNoReaded < 5 &&
              this.pageNotificationNoReaded < this.totalPagesUnreaded
            ) {
              this.pageNotificationNoReaded++;
              await this.getNoReadedNotifications();
            }
          }
        }
      })();
    });
  },
};
</script>
