<template>
<div>
  <TitlePrincipal
      title="Selecciona o crea un nuevo negocio"
      subtitle="Detectamos que ya cuentas con un negocio registrado, selecciona o crea uno nuevo.">
  </TitlePrincipal>
  <div class="row" v-if="showOrgs">
      <div class="col-12 col-md-6" v-for="(org, index) in orgsFilter" :key="index" @click="selectOrg(org)">
          <CardAccountType :clazz="selectedOrg._id.toString() === org._id.toString() ? 'selected' : ''"
                          :title="org.name"
                          :titleStrong="org.fullKey"
                          clazzTitle="text-align-c" >
              <template v-slot:cardImg>
                  <img class="img-fluid m-0-auto w-100 m-b-20 max-w-initial" :src="getImage(org)" alt="Cuenta Negocios" />
              </template>
              <p class="f-12 c-plain_text poppins-font-regular f-400 text-align-c m-b-0">
                  {{org.description}}
              </p>
          </CardAccountType>
      </div>
  </div>
  <div class="row" v-else>
      <div class="col-12 col-md-6" @click="createNewOrg = true">
          <CardAccountType :clazz="createNewOrg === true ? 'selected' : ''"
                          title="Crear un"
                          titleStrong="nuevo negocio "
                          clazzTitle="text-align-c" >
              <template v-slot:cardImg>
                  <img class="img-fluid m-0-auto m-b-20 max-w-100" :src="getImageDefault()" alt="Cuenta Negocios" />
              </template>
              <p class="f-12 c-plain_text poppins-font-regular f-400 text-align-c m-b-0">
                Selecciona esta opcion si deseas crear un nuevo negocio
              </p>
          </CardAccountType>
      </div>
      <div class="col-12 col-md-6" @click="createNewOrg = false">
          <CardAccountType :clazz="createNewOrg === false ? 'selected' : ''"
                          title="Seleccionar un negocio "
                          titleStrong="ya registrado"
                          clazzTitle="text-align-c" >
              <template v-slot:cardImg>
                  <img class="img-fluid m-0-auto m-b-20 max-w-100" :src="getImageDefault()" alt="Cuenta Negocios" />
              </template>
              <p class="f-12 c-plain_text poppins-font-regular f-400 text-align-c m-b-0">
                Selecciona esta opcion si deseas seleccionar un negocio ya registrado
              </p>
          </CardAccountType>
      </div>
  </div>
  <div class="footer-buttons right">
      <button type="button" class="button button-ghost button-plain_text" name="Regresar" @click="back()"> Regresar </button>
      <button type="button" class="button button-filled button-primary" name="Continuar" v-if="!showOrgs" @click="nextStep()"> Continuar </button>
      <button type="button" class="button button-filled button-primary" name="Continuar" v-if="showOrgs"
        :disabled="showOrgs && selectedOrg._id === ''"  @click="nextStepOrgs()"> Continuar </button>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import CardAccountType from "@/components/CardAccountType.vue";
import { mapGetters } from 'vuex';
import {
  STORE as OnboardingStore,
  GET_CARD_REQUEST,
  GET_ACCOUNT_TYPE_BUSINESS,
  GO_TO_ROUTE,
  GET_LOCATION
} from '@/store/modules/onboardingSteps/onboardingSteps.types';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {
  STORE as sessionStore,
  GET_USER
} from "@/store/modules/session/session.types";
import {
  STORE as organizationInfoStore,
  GET_ORGANIZATIONS_INFO
} from "@/store/modules/organizationInfo/organizationInfo.types";
import CardApi from '@/api/wallet/card.api';
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import { imageDownloadLink } from '@/utils/utils';
import imgPhysic from "@/assets/images/Default/img-card-cuenta-fisica.svg";
import imgMoral from "@/assets/images/Default/img-card-cuenta-moral.svg";

export default {
  name: "SelectCreateOrgBusiness",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    CardAccountType
  },
  computed: {
    ...mapGetters(OnboardingStore, {
      cardRequest: GET_CARD_REQUEST,
      isMoral2: GET_ACCOUNT_TYPE_BUSINESS,
      location: GET_LOCATION

    }),
    ...mapGetters(sessionStore, {
      user: GET_USER
    }),
    ...mapGetters(organizationInfoStore, {
      orgs: GET_ORGANIZATIONS_INFO
    }),
    orgsFilter() {
      if(this.isMoral) {
        return this.orgs.filter(o => o.invoiceData.isMoralPerson)
      }

      return this.orgs.filter(o => !o.invoiceData.isMoralPerson);
    }
  },
  data() {
    return {
      showOrgs: false,
      createNewOrg: false,
      selectedOrg: {
        _id: ''
      },
      isMoral: null,
      imgPhysic,
      imgMoral
      // orgs: []
    }
  },
  methods: {
    back () {
      if(this.showOrgs) {
        this.showOrgs = false;
      } else {
        this.$router.push({name: 'selectAccountBusinessMultiple'});
      }
    },
    // async getOrgs() {
    //     const response = await OrganizationInfoApi.getByUserId({id: this.user._id})
    //         .catch(catchError);
    //     if (response.data && !response.data.error) {
    //         this.orgs = response.data.object;
    //     }
    // },
    selectOrg(org) {
      this.selectedOrg = org;
    },
    async showAndGetOrgs() {
      this.showOrgs = true;
    },
    async nextStep() {
      this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.BUSINESS_START,
        });

      this.$store.commit(`${OnboardingStore}/SET_CREATE_NEW_ORG`, this.createNewOrg);

      if(this.createNewOrg) {
        this.$router.push({name: 'businessStartMultiple'});
      } else {
        await this.showAndGetOrgs();
      }
    },
    async nextStepOrgs() {

      const response = await CardApi.checkAccountBusiness({
        organizationId: this.selectedOrg._id,
        cardTemplate: this.cardRequest.cardTemplate._id
      });

     if(!response.data.object) {
       const editedCard = await this.$store.dispatch(
             `${organizationInfoStore}/saveOrgToCardRequest`,
             {
               id: this.cardRequest._id,
               organization: this.selectedOrg._id,
             }
         );

         if (editedCard) {
           if (this.cardRequest.isMoral) {
            this.sendRequestCard();
           } else {
             this.checkRedirectPhysic();
           }
         } else {
           this.displayNotificationError(
               this.$i18n.t("register.selectAccountCard.errorSaveRequestCard"),
               this.$i18n.t("register.selectAccountCard.errorRequestCardTitle")
           );
         }
     } else {
       this.displayNotificationError('Llegaste al limite de tipos de cuentas que se pueden crear. Intenta con otro tipo de cuenta.');
       this.$router.push({name: 'selectSchemaBusinessMultiple'});
     }

    },
    getImage(org) {
      if(this.isMoral) {
        return org.image ? imageDownloadLink(org.image._id) : imgMoral;

      }

      return org.image ? imageDownloadLink(org.image._id) : imgPhysic;
    },
    getImageDefault() {
      if(this.isMoral) {
        return imgMoral;

      }

      return imgPhysic;
    },
    async checkRedirectPhysic() {
      const policiesAccepted = await this.$store.dispatch(`${OnboardingStore}/checkAcceptedPolicies`);
      if(!policiesAccepted) {
        const query = { org: this.selectedOrg._id };
        this.$router.push({name: 'usagePoliciesBusinessMultiple', query});
        return;
      }
      if(!this.user.hasFinishLevelTwo) {
         this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.PLACE_RESIDENCE,
        });

        this.$store.dispatch(`${OnboardingStore}/${GO_TO_ROUTE}`, "businessResidenceMultiple");
      } else {
        if(!this.user.hasFinishLevelThree) {
          this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.ADD_DATA,
          });
          this.$store.dispatch(`${OnboardingStore}/${GO_TO_ROUTE}`, "businessAddInfoMultiple");
        } else {
          this.sendRequestCard();
        }
      }
    },
    sendRequestCard() {
      let isMoral = false;
      if (this.cardRequest.isMoral) {
        isMoral = true;
      }

      this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
        stepOnbng: ONBOARDING_STEPS.WAIT_REQUEST,
      });

      this.$store.dispatch(`${OnboardingStore}/requestCard`, {
        provider: "PAGANDITO",
        longitud: this.location.longitud,
        latitud: this.location.latitud,
        isMoral: isMoral,
        organizationInfo: this.selectedOrg._id,
        isMultipleAccount: this.$route.name === 'selectCreateOrgBusinessMultiple',
        isNewOrg: false
      }).then(() => {
        this.$router.push({name: "businessProcessingRequestMultiple"});
      });

    },
  },
  watch: {
    isMoral2(val) {
      this.isMoral = val === 'MORAL';
    }
  },
  mounted() {
    if(!this.orgsFilter.length) {
      this.createNewOrg = true;
      this.nextStep();
    }
  },
  async created(){
    if(this.isMoral2) {
      this.isMoral = this.isMoral2 === 'MORAL';
    }

    const routeName = this.$route.name;
    if(routeName === 'selectCreateOrgBusinessMultiple'){
      await this.$store.dispatch(`${OnboardingStore}/putLevel`, this.isMoral ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC');
    }
    await this.$store.dispatch(`${OnboardingStore}/checkCurrentStep`, this.$route);

    // await this.getOrgs();
  }
}
</script>

<style scoped>

</style>
