<template>
  <div class="col-12 col-lg-6 cards-same-height">
    <CardSimple
      :hideTitle="loading || !movementGroups.length"
      :title="$t('dashboard.recentMovements.title')"
      :subtitle="$t('dashboard.recentMovements.subtitle')"
      clazzBody="card-body-no-padding"
    >
      <LoadingBar v-if="loading" clazz="fullcontent-loading"/>
      <EmptyState
        v-if="!loading && !movementGroups.length"
        emptyStateDefault
        :title="$t('dashboard.recentMovements.emptyTitle')"
        :text="$t('dashboard.recentMovements.emptyText')"
        :imgUrl="imgEmptystateHistorialMov"
        :imgWidth="310"
        :imgHeight="300"
        :imgAlt="$t('dashboard.recentMovements.emptyTitle')"
      />
      <div v-for="group in movementGroups" v-bind:key="group[0]">
        <TitleBadge
          clazzContainer="w-100 m-b-10"
          clazz="xs text-upper m-b-0"
          :titleText="parseMovementGroupTitle(group[0])"
          :badgeText="`${group[1].length}`">
        </TitleBadge>
        <router-link to="" class="w-100" tabindex="" v-for="(movement, index) in group[1]" v-bind:key="movement._id">
          <MovementsList
            :clazz="`${movement.color} ${index < group[1].length - 1 ? 'mb-0' : ''}`"
            :icon="movement.icon"
            :movConcept="movement.movementTitle"
            :movText="movement.movementDescription">
            <p v-if="((movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
              && movement.contaMovementType !== 'CARGO') ||
              ((movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
              && movement.contaMovementType !== 'ABONO' && (movement.spentRewardPoints !== 0 || availablePoints < 100))  ||
              (movement.type !== 'COMPRA_DIGITAL' && movement.type !== 'Compra' && movement.type !== 'COMPRA_CONTACTLESS')"
            :class="movement.amountClass">{{movement.sign}} {{movement.amount | currency}}</p>
            <router-link
              v-if="showPointsButton && (movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
                  && movement.contaMovementType === 'CARGO' && movement.spentRewardPoints === 0 && availablePoints > 100"
              :to="`/points-pagando/${movement._id}`"
              :id="`tooltip-pagarPuntos-${movement._id}`"
              tabindex=""
              class="button xs button-stroke button-primary m-0-0-0-auto p-l-10 p-r-10">
              <i aria-hidden="true" class="f-18 iconsax-like-medal-star m-r-5"></i>
              {{movement.sign}} {{movement.amount | currency}}
            </router-link>
            <b-tooltip
              v-if="showPointsButton && (movement.type === 'COMPRA_DIGITAL' || movement.type === 'Compra' || movement.type === 'COMPRA_CONTACTLESS')
                && movement.contaMovementType === 'CARGO' && movement.spentRewardPoints === 0"
              :target="`tooltip-pagarPuntos-${movement._id}`"
              placement="top">
              <p class="m-b-0">
                {{$t('movements.paidWithPoints')}}
              </p>
            </b-tooltip>
          </MovementsList>
        </router-link>
      </div>
      <router-link
        v-if="movementGroups.length"
        to=""
        class="button xs button-ghost button-text_title m-auto-auto-0 m-t-30"
        tabindex=""
        @click.native="$emit('goToMovements')"
      >
        {{$t('dashboard.recentMovements.seeMovements')}}
        <i
          aria-hidden="true"
          class="iconsax-arrow-circle-right f-22 c-info m-l-10"
        ></i>
      </router-link>
    </CardSimple>
  </div>
</template>
<script>
import CardSimple from "@/components/CardSimple.vue";
import TitleBadge from "@/components/TitleBadge.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import EmptyState from "@/components/EmptyState.vue";
import MovementsList from "@/components/MovementsList.vue";

import moment from 'moment';

import imgEmptystateHistorialMov from '@/assets/images/Emptystates/emptystate-movimientos.svg';

export default {
  name: 'RecentMovements',
  components: {
    CardSimple,
    TitleBadge,
    LoadingBar,
    EmptyState,
    MovementsList,
  },
  props: {
    availablePoints: {
      type: Number,
      default: 0,
    },
    movements: Array,
    loading: {
      type: Boolean,
      default: false,
    },
    showPointsButton: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // Images
      imgEmptystateHistorialMov,
    };
  },
  computed: {
    movementGroups() {
      if(!this.movements) {
        return [];
      }
      const movements = this.movements.map(m => Object.assign({}, m));
      movements.forEach((movement) => {
        if (!movement.date) {
          movement.date = movement.createdAt;
        }
      });

      // Agrupa los movimientos en días
      const groups = {};
      movements.forEach((movement) => {
        const date = moment(movement.date).startOf("day").toString();
        if (!Object.prototype.hasOwnProperty.call(groups, date)) {
          groups[date] = [];
        }
        groups[date].push(this.parseMovement(movement));
      });

      // Ordena los grupos de movimientos (más reciente primero)
      const movementGroups = Object.entries(groups).sort((a, b) => {
        const groupA = moment(a[0]);
        const groupB = moment(b[0]);
        return groupB.diff(groupA);
      });

      // Ordena los movimientos de cada grupo (más reciente primero)
      movementGroups.forEach((group) => {
        group[1].sort((a, b) => {
          const movementA = moment(a.date);
          const movementB = moment(b.date);
          return movementB.diff(movementA);
        });
      });

      return movementGroups;
    },
  },
  created() {
    moment.locale(this.$i18n.locale);
  },
  methods: {
    parseMovement(movement) {
      let sign = "";
      let amountClass = [];
      if (movement.contaMovementType === "ABONO") {
        sign = "+";
        amountClass = ["c-success"];
      } else if (movement.contaMovementType === "CARGO") {
        sign = "-";
      }

      if (movement.type === "LOST_CHARGEBACK") {
        amountClass = ["c-error"];
      }

      const time = moment(movement.date).format("hh:mm a");
      const movementDescription = `${time} - ${movement.movementDescription}`;

      let icon = "iconsax-money-card";
      let color = "mov-purchase";
      switch (movement.type) {
        case "Compra":
        case "COMPRA_DIGITAL":
        case "COMPRA_CASHBACK":
        case "COMPRA_CONTACTLESS":
          icon = "iconsax-money-card";
          color = "mov-purchase";
          break;
        case "Devolución":
          icon = "iconsax-arrow-undo";
          color = "mov-refund";
          break;
        case "Retiro":
        case "SPEIOUT":
          icon = "iconsax-money-send";
          color = "mov-sent";
          break;
        case "Deposito":
        case "SPEIIN":
        case "CASHIN":
          icon = "iconsax-money-receive";
          color = "mov-received";
          break;
        case "Transferencia":
          icon =
            movement.movementKey === "PT"
              ? "iconsax-money-receive"
              : "iconsax-money-send";
          color = movement.movementKey === "PT" ? "mov-received" : "mov-sent";
          break;
        case "Transacción":
        case "LOST_CHARGEBACK":
        case "COMMISSION":
        case "Consulta":
        default:
          break;
      }

      const timeAgo = moment(movement.date).fromNow();

      return {
        ...movement,
        sign,
        amountClass,
        movementDescription,
        icon,
        color,
        timeAgo,
      };
    },
    parseMovementGroupTitle(date) {
      let newDate;
      try {
        newDate = new Date(date);
      } catch(err) {
        newDate = new Date();
      }
      const today = moment();
      if(today.isSame(newDate, 'day')) {
        return this.$i18n.t('general.datesCommon.today');
      }
      return moment(newDate).format('dddd ll').toString();
    },
  },
}
</script>
