import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'onboarding-data';

export default {
    getActive: (product, accountType, isForRegister = true) => {
        return axios.get(`${base.baseUrl}/${namespace}/get-active-onboarding/${product}`, {timeout: 10000, params: {accountType, isForRegister}})
    },
    createData: (params) => {
        return axios.post(`${base.baseUrl}/${namespace}/save` , params)
    },
    getData: (user, query) => {
        return axios.get(`${base.baseUrl}/${namespace}/get-last-data-by-user/${user}`, {
            params: query
        })
    }
}
