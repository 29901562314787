<template>
  <div>
    <vue-snotify></vue-snotify>
    <!-- PERMITIR ACCESO CAMARA -->
    <div class="liness-movile-version" v-if="showPermission && !showLogout">
      <div class="movile-navbar">
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          alt="Logo Pagando"
        />
        <router-link
          @click="showLogoutMethod()"
          to=""
          class="link-to-movile m-0-0-0-auto"
          tabindex=""
          aria-label="Cerrar"
        >
          <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </router-link>
      </div>
      <div class="movile-body justify-content-start max-w-420px m-auto">
        <img
          class="img-fluid d-flex m-0-auto m-b-20 m-t-40"
          src="@/assets/images/Illustrations/illustration-acceso-camara.png"
          alt="Permiso de acceso a la cámara"
        />
        <label class="title-text">{{$t('register.livenessMobil.permits.title')}}</label>
        <p class="subtitle-text">
          {{$t('register.livenessMobil.permits.subtitle')}}
        </p>
        <div class="movile-footer m-t-30">
          <button
            type="button"
            class="button button-filled button-primary button-movile-icon"
            @click="getAccessToCamera()"
          >
            {{ $t('register.livenessMobil.next') }}
            <i aria-hidden="true" class="banana banana-arrow-right m-l-10"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="liness-movile-version" v-if="logout">
      <div class="movile-navbar">
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          alt="Logo Pagando"
        />
      </div>
      <div class="movile-body justify-content-start max-w-420px m-auto">
        <!-- <img v-if="isVideo" class="img-fluid d-flex m-0-auto m-b-40"
                     src="@/assets/images/Default/img-ejemplo-como-tomar-video.png"
                     alt="Imágen como tomar video"/> -->
        <h2
          class="f-18 c-title poppins-font-semibold text-align-c w-100 m-b-25"
        >
          {{$t('register.livenessMobil.logout.title')}}
        </h2>
      </div>
    </div>
    <div class="liness-movile-version" v-if="showInstructions && !showLogout">
      <div class="movile-navbar">
        <router-link
          to=""
          class="link-to-movile m-0-auto-0-0"
          tabindex=""
          aria-label="Regresar"
        >
          <i aria-hidden="true" class="banana banana-arrow-left"></i>
        </router-link>
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          alt="Logo Pagando"
        />
        <button
          @click="showLogoutMethod()"
          to=""
          class="link-to-movile m-0-0-0-auto"
          tabindex=""
          aria-label="Cerrar"
        >
          <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </button>
      </div>
      <div class="movile-body justify-content-start max-w-420px m-auto">
<!--        <img-->
<!--          v-if="isVideo"-->
<!--          class="img-fluid d-flex m-0-auto m-b-40"-->
<!--          src="@/assets/images/Default/img-ejemplo-como-tomar-video.png"-->
<!--          alt="Imágen como tomar video"-->
<!--        />-->
<!--        <lottie-animation-->
<!--            v-show="isVideo"-->
<!--            path="lf30_pi6uawro.json"-->
<!--            :width="400"-->
<!--            :height="400"-->
<!--        />-->

        <div class="w-100 max-w-200px  m-0-auto m-b-40">
            <LottieAnimation
            :animationData="require('@/assets/lotties/lf30_pi6uawro.json')"
            :auto-play="true"
            :loop="true"
            :speed="1"/>
        </div>
        <h2
          class="f-18 c-title poppins-font-semibold text-align-c w-100 m-t-20 m-b-25"
        >
          {{ isVideo ? $t('register.livenessMobil.instructions.titleVideo') : $t('register.livenessMobil.instructions.titlePhoto') }}
        </h2>
        <ul class="number-list">
          <li v-if="isVideo">
            <strong>{{$t('register.livenessMobil.instructions.videoFace1')}}</strong>
            {{$t('register.livenessMobil.instructions.videoFace2')}}
          </li>
          <li v-else>
            <strong>{{$t('register.livenessMobil.instructions.photoDoc1')}}</strong>
            {{$t('register.livenessMobil.instructions.photoDoc2')}}
          </li>
          <li v-if="isVideo">
            <strong>{{$t('register.livenessMobil.instructions.videoCircule1')}}</strong>
            {{$t('register.livenessMobil.instructions.videoCircule2')}}
          </li>
          <li v-else>
            <strong>{{$t('register.livenessMobil.instructions.photoDocR1')}}</strong>
            {{$t('register.livenessMobil.instructions.photoDocR2')}}
          </li>
          <li v-if="isVideo">
            {{$t('register.livenessMobil.instructions.videoTime1')}}
            <strong>{{$t('register.livenessMobil.instructions.videoTime2')}}</strong>
          </li>
          <li v-else>
            {{$t('register.livenessMobil.instructions.videoDocF1')}}
            <strong>{{$t('register.livenessMobil.instructions.videoDocF2')}}</strong>
          </li>
        </ul>
        <div class="movile-footer m-t-30">
          <button
            type="button"
            class="button button-filled button-primary button-movile-icon"
            @click="takeVideoMethod()"
          >
            {{ isVideo ? $t('register.livenessMobil.instructions.nextVideo') : $t('register.livenessMobil.instructions.nextPhoto') }}
            <i aria-hidden="true" class="banana banana-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- SALIR DEL PROCESO -->
    <div class="liness-movile-version" v-if="showLogout">
      <div class="movile-navbar">
        <router-link
          to=""
          class="link-to-movile m-0-auto-0-0"
          tabindex=""
          aria-label="Regresar"
        >
          <i aria-hidden="true" class="banana banana-arrow-left"></i>
        </router-link>
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          alt="Logo Pagando"
        />
      </div>
      <div class="movile-body justify-content-start max-w-420px m-auto">
        <img
          class="img-fluid d-flex m-0-auto m-b-20 m-t-40"
          src="@/assets/images/Icons/icono-modal-salir.svg"
          alt="Salir del proceso"
        />
        <label class="title-text">
          {{ $t('register.livenessMobil.showLogout.title') }}
        </label>
        <p class="subtitle-text">
          {{$t('register.livenessMobil.showLogout.subtitle')}}
        </p>
        <div class="movile-footer m-t-30">
          <button
            @click="closeSession()"
            type="button"
            class="button button-ghost button-primary w-100 m-b-5"
          >
            {{$t('register.livenessMobil.exit')}}
          </button>
          <button
            @click="recordAgain()"
            type="button"
            class="button button-filled button-primary button-movile-icon"
          >
            {{ $t('register.livenessMobil.next') }}
            <i aria-hidden="true" class="banana banana-arrow-right"></i>
          </button>
        </div>
      </div>
    </div>
    <!-- GRABAR VIDEO -->
    <div
      class="liness-movile-version"
      v-if="takeVideo && (!confirmVideo || !confirmPhoto) && !showLogout"
    >
      <div class="full-movile-video-preview photo-size-camera">
        <CameraVue
          ref="upload-video"
          verified
          camId="upload-video"
          imageClassification="users/test-delete-in-anyCase"
          imageNamePrefix="junk-"
          @stopVideo="stopRecCamera($event)"
          @startingRecord="showStarting = $event"
          @videoEnded="videoPlaying = false"
        >
        </CameraVue>
        <div id="videoButtons" class="bottom-options photo-menu-fixed-bottom" v-if="isVideo">
          <label class="bottom-title-text">
            {{$t('register.livenessMobil.takeVideo.ins1')}}
          </label>
          <p class="bottom-subtitle-text">
            {{$t('register.livenessMobil.takeVideo.ins2')}}
          </p>
          <span class="buttons-container" v-if="replay && isVideo">
            <span>
              <button
                @click="recordAgain()"
                type="button"
                class="button xs button-ghost button-title f-14 p-l-5 p-r-5"
              >
                {{$t('register.livenessMobil.takeVideo.takeOther')}}
              </button>
            </span>
            <span>
              <button
                id="button-replay-static"
                type="button"
                @click="emitPlay()"
                :class="`btn-recording recording-preview ${
                  videoPlaying ? 'start' : ''
                }`"
                aria-label="Iniciar grabación"
                :name="$t('register.livenessMobil.takeVideo.startVideo')"
              >
                <svg height="60" width="60">
                  <circle
                    cx="30"
                    cy="30"
                    r="30"
                    stroke="#FFFFFF"
                    stroke-width="3"
                    fill-opacity="0"
                  />
                  <circle
                    class="circle"
                    cx="30"
                    cy="30"
                    r="30"
                    stroke="#F77F26"
                    stroke-width="3"
                    fill-opacity="0"
                    stroke-linecap="round"
                  />
                </svg>
                <div class="center-icon">
                  <i aria-hidden="true" class="banana banana-player-play"></i>
                </div>
              </button>
            </span>
            <span>
              <button
                type="button"
                @click="preConfirmVideo()"
                class="button xs button-ghost button-title f-14 p-l-5 p-r-5"
              >
                {{$t('register.livenessMobil.takeVideo.useVideo')}}
              </button>
            </span>
          </span>
          <span class="buttons-container" v-if="!replay && isVideo">
            <span>
              <button
                type="button"
                class="button xs button-ghost button-title f-14 p-l-5 p-r-5"
              >
                {{$t('register.livenessMobil.cancel')}}
              </button>
            </span>
            <span>
              <button
                id="button-record-static"
                v-view="viewHandler"
                type="button"
                v-if="showStart && isVideo"
                @click="startVideoCapture(startRecCamera)"
                :class="`btn-recording ${clazzButtonCamera}`"
                aria-label="Iniciar grabación"
                :name="$t('register.livenessMobil.takeVideo.startVideo')"
              >
                <svg height="60" width="60">
                  <circle
                    cx="30"
                    cy="30"
                    r="30"
                    stroke="#FFFFFF"
                    stroke-width="3"
                    fill-opacity="0"
                  />
                  <circle
                    class="circle"
                    cx="30"
                    cy="30"
                    r="30"
                    stroke="#C23E37"
                    stroke-width="3"
                    fill-opacity="0"
                    stroke-linecap="round"
                  />
                </svg>
                <div class="center-icon">
                  <i
                    aria-hidden="true"
                    class="banana banana-shape-circle bgm-error brd-50prc f-20"
                  ></i>
                </div>
              </button>
              <button
                id="button-record-stop-static"
                type="button"
                v-view="viewHandler"
                v-if="isVideo && !showStart"
                @click="emitStop()"
                :class="`btn-recording ${showStop ? 'start' : ''} ${clazzButtonCamera}`"
                aria-label="Grabando video"
                :name="$t('register.livenessMobil.takeVideo.recordVideo')"
              >
                <!-- Agregar clase "start" 3 seg después de cuando se da click -->
                <svg height="60" width="60">
                  <circle
                    cx="30"
                    cy="30"
                    r="30"
                    stroke="#FFFFFF"
                    stroke-width="3"
                    fill-opacity="0"
                  />
                  <circle
                    class="circle"
                    cx="30"
                    cy="30"
                    r="30"
                    stroke="#C23E37"
                    stroke-width="3"
                    fill-opacity="0"
                    stroke-linecap="round"
                  />
                </svg>
                <div class="center-icon">
                  <i aria-hidden="true" class="banana banana-player-stop"></i>
                </div>
              </button>

              <button
                  id="button-record-fixed"
                  type="button"
                  v-if="showStart && isVideo &&showButtonOver"
                  @click="startVideoCapture(startRecCamera)"
                  class="btn-recording float-button"
                  aria-label="Iniciar grabación"
                  :name="$t('register.livenessMobil.takeVideo.startVideo')"
              >
                <svg height="60" width="60">
                  <circle
                      cx="30"
                      cy="30"
                      r="30"
                      stroke="#FFFFFF"
                      stroke-width="3"
                      fill-opacity="0"
                  />
                  <circle
                      class="circle"
                      cx="30"
                      cy="30"
                      r="30"
                      stroke="#C23E37"
                      stroke-width="3"
                      fill-opacity="0"
                      stroke-linecap="round"
                  />
                </svg>
                <div class="center-icon">
                  <i
                      aria-hidden="true"
                      class="banana banana-shape-circle bgm-error brd-50prc f-20"
                  ></i>
                </div>
              </button>
              <button
                  id="button-record-stop-fixed"
                  type="button"
                  v-if="isVideo && !showStart &&showButtonOver"
                  @click="emitStop()"
                  :class="`btn-recording float-button ${showStop ? 'start' : ''}`"
                  aria-label="Grabando video"
                  :name="$t('register.livenessMobil.takeVideo.recordVideo')"
              >
                <!-- Agregar clase "start" 3 seg después de cuando se da click -->
                <svg height="60" width="60">
                  <circle
                      cx="30"
                      cy="30"
                      r="30"
                      stroke="#FFFFFF"
                      stroke-width="3"
                      fill-opacity="0"
                  />
                  <circle
                      class="circle"
                      cx="30"
                      cy="30"
                      r="30"
                      stroke="#C23E37"
                      stroke-width="3"
                      fill-opacity="0"
                      stroke-linecap="round"
                  />
                </svg>
                <div class="center-icon">
                  <i aria-hidden="true" class="banana banana-player-stop"></i>
                </div>
              </button>
            </span>
            <span>
              <button
                type="button"
                class="button-circle xs button-ghost button-primary1 f-22"
                @click="showModalCameras()"
              >
                <i aria-hidden="true" class="banana banana-updating"></i>
              </button>
            </span>
          </span>
        </div>
        <div id="cameraButtons" class="bottom-options photo-menu-fixed-bottom" v-else>
          <label class="bottom-title-text" v-if="proccessPhoto && !finalizePhoto">
            {{$t('register.livenessMobil.takePhoto.cropTitle')}}
          </label>
          <label class="bottom-title-text" v-else>
            {{$t('register.livenessMobil.takePhoto.title')}}
          </label>
          <div v-if="finalizePhoto" class="bottom-subtitle-text">
            {{$t('register.livenessMobil.takePhoto.subtitle')}}
          </div>
          <div v-else-if="proccessPhoto" class="bottom-subtitle-text">
            {{$t('register.livenessMobil.takePhoto.subtitle')}}
            <p v-html="$t('register.livenessMobil.takePhoto.cropSubtitle')"></p>
          </div>
          <div v-else class="bottom-subtitle-text">
            {{$t('register.livenessMobil.takePhoto.camerasTip')}}
          </div>
          <span class="buttons-container" v-if="showTakePhoto">
            <span>
              <button
                v-if="proccessPhoto || finalizePhoto"
                @click="recordAgain()"
                type="button"
                class="button md button-stroke button-title f-14"
              >
                {{$t('register.livenessMobil.takePhoto.takeOther')}}
              </button>
            </span>
            <span>
              <button
                id="button-static"
                v-view="viewHandler"
                type="button"
                v-if="!proccessPhoto && !finalizePhoto"
                @click="takePicture(_takePicture)"
                :class="`btn-recording recording-preview ${
                  videoPlaying ? 'start' : ''
                } ${clazzButtonCamera}`"
                aria-label="Tomar foto"
                name="Tomar foto"
              >
                <svg height="60" width="60">
                  <circle
                    cx="30"
                    cy="30"
                    r="30"
                    stroke="#FFFFFF"
                    stroke-width="3"
                    fill-opacity="0"
                  />
                  <circle
                    class="circle"
                    cx="30"
                    cy="30"
                    r="30"
                    stroke="#F77F26"
                    stroke-width="3"
                    fill-opacity="0"
                    stroke-linecap="round"
                  />
                </svg>
                <div class="center-icon">
                  <i aria-hidden="true" class="banana banana-camera"></i>
                </div>
              </button>
              <button
                  id="button-fixed"
                  type="button"
                  v-if="!proccessPhoto && !finalizePhoto && showButtonOver"
                  @click="takePicture(_takePicture)"
                  :class="`btn-recording recording-preview float-button ${
                  videoPlaying ? 'start' : ''
                }`"
                  aria-label="Tomar foto"
                  name="Tomar foto"
              >
                <svg height="60" width="60">
                  <circle
                      cx="30"
                      cy="30"
                      r="30"
                      stroke="#FFFFFF"
                      stroke-width="3"
                      fill-opacity="0"
                  />
                  <circle
                      class="circle"
                      cx="30"
                      cy="30"
                      r="30"
                      stroke="#F77F26"
                      stroke-width="3"
                      fill-opacity="0"
                      stroke-linecap="round"
                  />
                </svg>
                <div class="center-icon">
                  <i aria-hidden="true" class="banana banana-player-play"></i>
                </div>
              </button>
            </span>
            <span v-if="!proccessPhoto && !finalizePhoto">
              <button
                type="button"
                class="button-circle xs button-ghost button-primary1 f-22"
                @click="showModalCameras()"
              >
                <i aria-hidden="true" class="banana banana-updating"></i>
              </button>
            </span>
            <span v-else>
              <button
                v-if="proccessPhoto"
                type="button"
                @click="processPicture(_processPicture)"
                class="button md button-filled button-primary f-14"
              >
                {{$t('register.livenessMobil.takePhoto.processPhoto')}}
              </button>
              <button
                v-if="finalizePhoto"
                type="button"
                @click="uploadPhoto()"
                class="button md button-filled button-primary f-14"
              >
                {{$t('register.livenessMobil.takePhoto.uploadPhoto')}}
              </button>
            </span>
          </span>
        </div>
      </div>
    </div>
    <div
      class="liness-movile-version"
      v-if="isVideo && confirmVideo && !finishUpdate && !showLogout"
    >
      <div class="movile-navbar">
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          alt="Logo Pagando"
        />
        <router-link
          @click="showLogoutMethod()"
          to=""
          class="link-to-movile m-0-0-0-auto"
          tabindex=""
          aria-label="Cerrar"
        >
          <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </router-link>
      </div>
      <div class="movile-body justify-content-start max-w-420px m-auto" v-if="!analizeVideo">
        <div class="movile-video-preview">
          <video
            :id="`video-recorded`"
            class="image-inverted-x"
            autoplay
          ></video>
        </div>
        <label class="title-text"> {{$t('register.livenessMobil.analize.title')}} </label>
        <p class="subtitle-text" v-html="$t('register.livenessMobil.analize.subtitle')">
        </p>
        <div class="movile-footer m-t-30">
          <button
            @click="recordAgain()"
            type="button"
            class="button button-ghost button-primary w-100 m-b-5"
          >
            {{$t('register.livenessMobil.analize.tryAgain')}}
          </button>
          <button
            @click="confirmVideoMethod()"
            type="button"
            class="button button-filled button-primary button-movile-icon"
          >
            {{$t('register.livenessMobil.analize.twoCircles')}}
            <i aria-hidden="true" class="banana banana-arrow-right"></i>
          </button>
        </div>
      </div>
      <div class="movile-body justify-content-start max-w-420px m-auto" v-else>
        <div class="p-relative d-block min-h-100px w-100 m-t-100 m-b-100">
          <LoadingBar :loadingText="$t('register.livenessMobil.analize.loading')"></LoadingBar>
        </div>
        <label class="title-text"> {{$t('register.livenessMobil.analize.recordVideo')}} </label>
        <p class="subtitle-text">
          {{$t('register.uploadDocuments.modalVideo.processingFaceVideo')}}
        </p>
        <!-- <div class="movile-footer">
                    <button type="button" class="button button-filled button-primary button-movile-icon">
                        Consultar video
                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                    </button>
                </div> -->
      </div>
    </div>
    <div
      class="liness-movile-version"
      v-if="!isVideo && analizeVideo && !finishUpdate && !showLogout"
    >
      <div class="movile-navbar">
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          alt="Logo Pagando"
        />
        <!-- <router-link to="" class="link-to-movile m-0-0-0-auto" tabindex="" aria-label="Cerrar">
                    <i aria-hidden="true" class="banana banana-cross-normal"></i>
                </router-link> -->
      </div>
      <div class="movile-body justify-content-start max-w-420px m-auto">
        <div class="p-relative d-block min-h-100px w-100 m-t-100 m-b-100">
          <LoadingBar loadingText="Analizando foto..."></LoadingBar>
        </div>
        <label class="title-text"> {{$t('register.livenessMobil.analize.titlePhoto')}} </label>
        <p class="subtitle-text">
          {{$t('register.livenessMobil.analize.subtitlePhoto')}}
        </p>
        <!-- <div class="movile-footer">
                    <button type="button" class="button button-filled button-primary button-movile-icon">
                        Consultar video
                        <i aria-hidden="true" class="banana banana-arrow-right"></i>
                    </button>
                </div> -->
      </div>
    </div>
    <div class="liness-movile-version" v-if="error">
      <div class="movile-navbar">
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          alt="Logo Pagando"
        />
      </div>
      <div class=" justify-content-start max-w-420px m-auto">
        <img
          class="img-fluid d-flex m-0-auto m-b-20"
          src="@/assets/images/Illustrations/Illustration-success-check.svg"
          alt="Verificación realizada"
        />
        <label class="title-text">{{$t('register.livenessMobil.error.title')}}</label>
        <p class="subtitle-text">
          {{$t('register.livenessMobil.error.subtitle')}}
        </p>
      </div>
    </div>
    <div class="liness-movile-version" v-if="finishUpdate">
      <div class="movile-navbar">
        <img
          class="img-fluid"
          src="@/assets/images/Logos/logo-color.svg"
          alt="Logo Pagando"
        />
      </div>
      <div class="movile-body justify-content-start max-w-420px m-auto">
        <img
          class="img-fluid d-flex m-0-auto m-b-20"
          src="@/assets/images/Illustrations/Illustration-success-check.svg"
          alt="Verificación realizada"
        />
        <label class="title-text">{{$t('register.livenessMobil.verify.title')}}</label>
        <p class="subtitle-text">
          {{$t('register.livenessMobil.verify.subtitle')}}
        </p>
      </div>
    </div>

    <b-modal
      id="modal-select-camera"
      size=""
      hide-header
      hide-footer
      modal-class="modal-default"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-select-camera')"
        aria-label="Boton cerrar ventana"
        name="Boton cerrar ventana"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{$t('register.livenessMobil.modal.title')}}</h2>
      </div>
      <div class="body">
        <BasicSelect
          required
          :label="$t('register.livenessMobil.modal.cameras')"
          clazz="d-flex flex-direction-column"
        >
          <multiselect
            v-model="cameraSelected"
            :options="videoSources"
            :close-on-select="true"
            :preserve-search="true"
            placeholder="Selecciona una camara"
            label="name"
            track-by="name"
            :preselect-first="true"
            :searchable="false"
            :allowEmpty="false"
          >
            <template slot="selection" slot-scope="{ values, search, isOpen }">
              <span
                class="multiselect__single"
                v-if="values.length &amp;&amp; !isOpen"
                >{{ values.length }} options selected</span
              >
            </template>
          </multiselect>
        </BasicSelect>
      </div>
      <div class="footer">
        <button
          type="button"
          class="button button-filled button-primary"
          @click="switchCamera()"
          :disabled="!selectedNewCamera"
        >
          {{ $t('register.livenessMobil.next') }}
        </button>
      </div>
    </b-modal>
  </div>
</template>
<script>
// @ is an alias to /src
import LoadingBar from "@/components/LoadingBar.vue";
import BasicSelect from "@/components/BasicSelect.vue";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import CameraMixin from "@/mixins/CameraMixin";
import UserApi from "@/api/users/users.api";
import { catchError } from "@/utils/utils";
import CameraVue from "@/components/CameraVue";
import { EventBus } from "@/main";
import axios from "axios";
import Multiselect from "vue-multiselect";
import LottieAnimation from 'lottie-web-vue'

const uploadVideoText = "upload-video";

export default {
  name: "LivenessMovil",
  mixins: [ToastSnotify, CameraMixin(uploadVideoText)],
  components: {
    CameraVue,
    LoadingBar,
    BasicSelect,
    Multiselect,
    LottieAnimation
  },
  props: {},
  data() {
    return {
      showPermission: false,
      showInstructions: false,
      takeVideo: false,
      cameraShow: "user",
      showStart: false,
      showStop: false,
      showPlay: false,
      showPause: false,
      showStarting: false,
      replay: false,
      isVideo: false,
      showTakePhoto: false,
      fileDocuments: [],
      docModal: {},
      indexModal: {},
      blobVideo: {},
      resultModal: null,
      videoPlaying: false,
      confirmVideo: false,
      analizeVideo: false,
      finishUpdate: false,
      takeOtherPhoto: false,
      proccessPhoto: false,
      finalizePhoto: false,
      imageToUpload: null,
      user: {},
      confirmPhoto: false,
      showLogout: false,
      logout: false,
      error: false,
      cameraSelected: null,
      videoSources: [],
      clazzButtonCamera: "",
      showButtonOver: false,
      lastSelectedCamera: null,
    };
  },
  async mounted() {
    const queryParams = this.$route.query;
    if (queryParams.token && queryParams.fileDocument) {
      const res = await UserApi.getInfoQR({
        fileDocument: queryParams.fileDocument,
        token: queryParams.token,
      }).catch(catchError);
      if (res && res.data) {
        if (res.data.error) {
          this.error = true;
          this.displayNotificationError(
            "Ocurrio un error al leer codigo QR, vuelve a intentarlo de nuevo 1"
          );
        } else {
          this.error = false;
          axios.defaults.headers.common.Authorization = `Bearer ${res.data.data.token.replace(
            /"/g,
            ""
          )}`;
          // localStorage.setItem('currentToken', JSON.stringify(res.data.data.token));
          this.docModal = res.data.data.fileDocument;
          this.user = res.data.data.user;
          this.checkPermission();
        }
      } else {
        this.error = true;

        this.displayNotificationError(
          "Ocurrio un error al leer codigo QR, vuelve a intentarlo de nuevo"
        );
      }
    } else {
      this.error = true;

      this.displayNotificationError(
        "Ocurrio un error al leer codigo QR, vuelve a intentarlo de nuevo"
      );
    }
  },
  destroyed() {},
  computed: {
    selectedNewCamera() {
      return this.lastSelectedCamera?.id !== this.cameraSelected?.id;
    },
  },
  methods: {
    showLogoutMethod() {
      this.showLogout = true;
    },
    closeSession() {
      this.logout = true;
    },
    async uploadPhoto() {
      this.analizeVideo = true;
      this.takeVideo = false;

      await this._addFile(this.imageToUpload, this.resultModal, this.docModal);

      // this.analizeVideo = true;
      // setTimeout(() => {
      //     this.analizeVideo = false;

      // }, 3000);
    },
    async _addFile(file, raw, doc) {
      const newFile = {
        title: `${doc.title}-${this.$route.query.shareholder ? this.$route.query.shareholder : this.user.email}`,
        key: this.docModal.key,
        fileDocument: doc._id,
        isRequired: doc.isRequired,
        file: {
          name: file.name || doc.title,
          type: file.type,
          size: file.size,
          data: raw.split(",")[1],
        },
      };
      this.fileDocuments = [newFile];
      await this.uploadFiles();
    },
    async uploadFiles() {
      const res = await UserApi.saveDocuments({
        groupId: this.docModal.groupFileDocument,
        fileDocuments: this.fileDocuments,
        isIndividual: true,
        shareholder: this.$route.query.shareholder
      }).catch(catchError);
      if (res && res.data) {
        if (res.data.error) {
          this.error = true;
          this.analizeVideo = false;
          const message =
            res.data.message || "register.uploadDocuments.errorUpload";
          this.displayNotificationError(this.$i18n.t(message));
          this.showLogout = false;
          this.recordAgain();
        } else {
          this.error = false;

          this.takeVideo = false;
          this.confirmPhoto = true;
          this.analizeVideo = false;
          this.finishUpdate = true;
        }
      } else {
        this.error = true;

        this.displayNotificationError(
          this.$i18n.t("register.uploadDocuments.errorUpload")
        );
      }
    },
    _processPicture(photo) {
      this.finalizePhoto = true;
      this.proccessPhoto = false;

      fetch(photo)
        .then((res) => res.blob())
        .then((blob) => {
          this.imageToUpload = blob;
          const reader = new FileReader();

          reader.addEventListener("load", () => {
            this.resultModal = reader.result;
            // this._setProgress(index, 50);
            // this._addFile(file, fileReader.result, document, index);
          });
          reader.readAsDataURL(blob);
        });
    },
    recordAgain() {
      this.error = false;
      this.showLogout = false;
      this.logout = false;
      this.confirmVideo = false;
      this.showLoadingCam = true;
      this.replay = false;
      this.showStart = true;
      this.takeVideo = true;
      this.proccessPhoto = false;
      this.finalizePhoto = false;
      this.intervalInitCamera();
    },
    _takePicture() {
      this.takeOtherPhoto = true;
      this.proccessPhoto = true;
      // this.showTakePhoto = false;
      this.stopCamera();
    },
    async confirmVideoMethod() {
      this.analizeVideo = true;
      this.takeVideo = false;

      await this._addFile(
        this.blobVideo,
        this.resultModal,
        this.docModal,
        this.indexModal
      );
    },
    preConfirmVideo() {
      this.confirmVideo = true;
      this.takeVideo = false;
      setTimeout(() => {
        const url = window.URL.createObjectURL(this.blobVideo);
        const videoElement = document.getElementById("video-recorded");

        videoElement.removeAttribute("controls");
        // videoElement.removeAttribute('autoplay');
        videoElement.src = url;

        videoElement.addEventListener(
          "ended",
          function () {
            this.currentTime = 0;
            this.play();
          },
          false
        );
      });
    },
    emitStop() {
      EventBus.$emit("global.cameraVue.turn-off");
    },
    emitPlay() {
      EventBus.$emit("global.cameraVue.play-video");
      this.videoPlaying = true;
    },
    stopRecCamera(data) {
      this.blobVideo = data;
      this.showLoadingCam = true;
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        this.resultModal = reader.result;
        // this._setProgress(index, 50);
        // this._addFile(file, fileReader.result, document, index);
      });
      reader.readAsDataURL(data);
      this.showStop = false;
      this.showStarting = false;
      this.showStart = false;
      this.showPlay = false;
      this.showPause = false;
      this.stopCamera();
      this.playCamera();
      this.replay = true;
      this.showLoadingCam = false;
    },
    _initCamera() {
      if (this.isVideo) {
        this.showStart = true;
      }
      this.videoSources = [...this.getVideoSources()];
    },
    startRecCamera() {
      this.showStop = true;
      this.showStart = false;
      this.showStarting = false;
      this.showPlay = false;
      this.showPause = true;
      this.replay = false;
    },
    checkPermission() {
      if (navigator.permissions) {
        navigator.permissions.query({ name: "camera" }).then((res) => {
          if (res.state === "granted") {
            this.showPermission = false;
            this.showInstructions = true;
            this.isVideo = this.checkIfVideo();
            this.showTakePhoto = !this.isVideo;
          } else {
            this.showPermission = true;
          }
        });
      } else {
        if (navigator.mediaDevices) {
          navigator.mediaDevices.getUserMedia({video: true}).then(() => {
            this.showPermission = false;
            this.showInstructions = true;
            this.isVideo = this.checkIfVideo();
            this.showTakePhoto = !this.isVideo;
          });
        } else {
          this.showPermission = true
          this.displayNotificationError("browser not support")
        }
      }
    },
    checkIfVideo() {
      return this.docModal.allowedFormats.includes("mp4");
    },
    getAccessToCamera() {
      navigator.mediaDevices.getUserMedia({video: true}).then(() => {
        this.error = false;
        this.showPermission = false;
        this.showInstructions = true;
        this.isVideo = this.checkIfVideo();
        this.showTakePhoto = !this.isVideo;
      }).catch(() => {
        this.error = true;
        this.displayNotificationError(
            "Se requiere permiso para usar la camara para la opcion seleccionada. Por favor de aceptar o seleccionar otra opcion"
        );
      })
      //
      // navigator.mediaDevices.getUserMedia(
      //   // constraints
      //   {
      //     video: true,
      //   },
      //
      //   // successCallback
      //   // this.openWebcam(),
      //   () => {
      //     this.error = false;
      //     this.showPermission = false;
      //     this.showInstructions = true;
      //     this.isVideo = this.checkIfVideo();
      //     this.showTakePhoto = !this.isVideo;
      //     // this.openInstructions();
      //     // this.openWebcam();
      //   },
      //
      //   // errorCallback
      //   () => {
      //     this.error = true;
      //
      //     this.displayNotificationError(
      //       "Se requiere permiso para usar la camara para la opcion seleccionada. Por favor de aceptar o seleccionar otra opcion"
      //     );
      //   }
      // );
    },
    takeVideoMethod() {
      this.takeVideo = true;
      this.showInstructions = false;
      this.intervalInitCamera();
    },
    intervalInitCamera() {
      const x = setInterval(() => {
        if (Object.prototype.hasOwnProperty.call(this.$refs, uploadVideoText)) {
          clearInterval(x);
          this.initCamera(this._initCamera);
        }
      }, 1000);
    },
    async showModalCameras() {
      this.$bvModal.show('modal-select-camera');
      await this.$nextTick();
      this.lastSelectedCamera = this.cameraSelected;
    },
    switchCamera() {
      const videoElement = document.getElementById("video-upload-video");
      if (this.cameraShow === "user") {
        this.cameraShow = "environment";

        // setTimeout(() => {
        videoElement.classList.remove("image-inverted-x");
        // }, 3000);
      } else {
        this.cameraShow = "user";
        videoElement.classList.add("image-inverted-x");
      }
      this.nextVideoSource(this.cameraShow, this.cameraSelected.index);
      this.$bvModal.hide('modal-select-camera');
      // this.takeVideoMethod();
    },
    viewHandler (e) {
      if (e.percentInView > 0.80) {
        this.clazzButtonCamera = "";
        this.showButtonOver = false;
      } else {
        this.clazzButtonCamera = "v-hidden";
        this.showButtonOver = true;
      }
    }

  },
};
</script>

<style scoped>
  .v-hidden {
    visibility: hidden !important;
  }
  .float-button {
    position: fixed !important;
    bottom: 8%;
  }
</style>
