import mujerGuinda from "@/assets/images/Backgrounds/Beneficiary/mujer-guinda.svg";
import mujerVerde from "@/assets/images/Backgrounds/Beneficiary/mujer-verde.svg";
import mujerNaranja from "@/assets/images/Backgrounds/Beneficiary/mujer-naranja.svg";
import mujerAmarillo from "@/assets/images/Backgrounds/Beneficiary/mujer-amarillo.svg";
import mujerAzulCielo from "@/assets/images/Backgrounds/Beneficiary/mujer-azul-cielo.svg";
import mujerAzulMarino from "@/assets/images/Backgrounds/Beneficiary/mujer-azul-marino.svg";
import hombreGuinda from "@/assets/images/Backgrounds/Beneficiary/hombre-guinda.svg";
import hombreAzulMarino from "@/assets/images/Backgrounds/Beneficiary/hombre-azul-marino.svg";
import hombreAmarillo from "@/assets/images/Backgrounds/Beneficiary/hombre-amarillo.svg";
import hombreVerde from "@/assets/images/Backgrounds/Beneficiary/hombre-verde.svg";
import hombreNaranja from "@/assets/images/Backgrounds/Beneficiary/hombre-naranja.svg";
import hombreAzulCielo from "@/assets/images/Backgrounds/Beneficiary/hombre-azul-cielo.svg";

const beneficiariesImages = [
    {
        "id": "mujerGuinda",
        "src": mujerGuinda,
        "selected": false
    },
    {
        "id": "mujerVerde",
        "src": mujerVerde,
        "selected": false
    },
    {
        "id": "mujerNaranja",
        "src": mujerNaranja,
        "selected": false
    },
    {
        "id": "mujerAmarillo",
        "src": mujerAmarillo,
        "selected": false
    },
    {
        "id": "mujerAzulCielo",
        "src": mujerAzulCielo,
        "selected": false
    },
    {
        "id": "mujerAzulMarino",
        "src": mujerAzulMarino,
        "selected": false
    },
    {
        "id": "hombreGuinda",
        "src": hombreGuinda,
        "selected": false
    },
    {
        "id": "hombreAzulMarino",
        "src": hombreAzulMarino,
        "selected": false
    },
    {
        "id": "hombreAmarillo",
        "src": hombreAmarillo,
        "selected": false
    },
    {
        "id": "hombreVerde",
        "src": hombreVerde,
        "selected": false
    },
    {
        "id": "hombreNaranja",
        "src": hombreNaranja,
        "selected": false
    },
    {
        "id": "hombreAzulCielo",
        "src": hombreAzulCielo,
        "selected": false
    },
]

export {
    beneficiariesImages
}
