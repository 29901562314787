<template>
    <div id="" class="sidebar-right-link" :class="[onlyIcon ? 'small': '', clazz]">
        <span v-if="!onlyIcon" class="link-icon">
            <i aria-hidden="true" :class="icon"></i>
        </span>
        <span v-if="!onlyIcon" class="link-info">
            <label>{{titleText}}</label>
            <p>{{text}}</p>
            <slot name="text"></slot>
        </span>
        <span v-if="!onlyIcon" class="link-go-to">
            <slot></slot>
        </span>
        <span v-if="onlyIcon && isRouter" class="link-icon">
            <router-link :to="url" tabindex="" :aria-label="ariaLabel" v-if="isRouter">
                <i aria-hidden="true" :class="icon"></i>
            </router-link>
        </span>
        <span v-if="onlyIcon && !isRouter" class="link-icon" @click="onClic">
            <i aria-hidden="true" :class="icon"></i>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'SidebarRightLink',
        props: {
            clazz: String,
            icon: String,
            onlyIcon: {type: Boolean, default: false},
            titleText: String,
            text: String,
            url: String,
            ariaLabel: String,
            isRouter: {type: Boolean, default: true},
        },
        methods: {
            async onClic(){
                this.$emit('makeClic');
            }
        },
    }
</script>
