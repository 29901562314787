<template>
  <div>
    <LoadingBar v-if="loading" loadingText="Cargando..."></LoadingBar>
    <div v-else>
      <TitlePrincipal :title="getTitle()" clazz="text-align-c">
        <p class="subtitle" v-html="getErrorMessage()"></p>
        <!-- <p class="subtitle" v-if="hasError && cardRequest && cardRequest.rejectReason">
          <strong>{{$t('register.processingRequest.error')}}</strong>
        </p> -->
      </TitlePrincipal>
      <img
        class="img-fluid max-h-300px d-flex m-0-auto"
        :src="getImage()"
        alt="Solicitud"
      />
      <div class="footer-buttons center flex-column gap-5">
        <button v-if="loadingButton" class="button button-filled button-primary">
          <LoadingSimple clazz="small-load no-text" />
        </button>
        <button
          v-if="!hasError && !loadingButton"
          type="button"
          class="button button-filled button-filled button-primary"
          name="Continuar"
          @click="nextStep"
        >
          {{ $t("register.processingRequest.continue") }}
        </button>
        <button
          v-if="hasError && !loadingButton"
          type="button"
          class="button button-filled button-filled button-primary"
          name="Corregir"
          @click="corregirErrores()"
        >
          {{ getNameButton() }}
        </button>
        <br>
        <button
          v-if="hasError && !loadingButton"
          type="button"
          class="button button-filled button-filled button-primary "
          name="Contact"
          @click="sendMessageWpp()"
        >
          Contactar a soporte
        </button>

      </div>
    </div>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import LoadingSimple from "@/components/LoadingSimple";
import {
  GET_LEVEL,
  GET_ERROR_CARD_REQUEST,
  GET_CARD_REQUEST,
  GET_LOCATION,
  GET_OBJECT_ERROR_REQUEST_CARD,
  GET_ACCOUNT_TYPE_BUSINESS,
  STORE as onbngStore,
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import { mapGetters } from "vuex";
import { ONBOARDING_STEPS } from "@/utils/onbngSteps";
import CardsApi from "@/api/wallet/card.api";
import successImage from "@/assets/images/Illustrations/illustration-solicitud-aprobada.svg";
import errorImage from "@/assets/images/Illustrations/illustration-status-error.svg";
import solEnvioImage from "@/assets/images/Illustrations/illustration-sol-envio.svg";
import solReposicionImage from "@/assets/images/Illustrations/illustration-sol-reposicion.svg";
import { EventBus } from "@/main";
import {
  GET_USER,
  STORE as SESSION_STORE,
} from "@/store/modules/session/session.types";
import { catchError } from "@/utils/utils";
import {
  STORE as orgInfoStore,
  GET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import LoadingBar from "@/components/LoadingBar";
import usersApi from '@/api/users/users.api';
export default {
  name: "SuccessRequest",
  components: {
    TitlePrincipal,
    LoadingBar,
    LoadingSimple,
  },
  data() {
    return {
      successImage,
      errorImage,
      solEnvioImage,
      solReposicionImage,
      isMoral: null,
      loading: false,
      loadingButton: false,
    };
  },
  computed: {
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      hasCardRequestError: GET_ERROR_CARD_REQUEST,
      cardRequest: GET_CARD_REQUEST,
      location: GET_LOCATION,
      errorObject: GET_OBJECT_ERROR_REQUEST_CARD,
      isMoral2: GET_ACCOUNT_TYPE_BUSINESS,
    }),
    ...mapGetters(SESSION_STORE, {
      user: GET_USER,
    }),
    ...mapGetters(orgInfoStore, {
      organization: GET_ORGANIZATION_INFO,
    }),
    hasError() {
      if (this.level === 0) {
        return !!this.user.errorOnboardingCollaborator;
      }
      return this.hasCardRequestError;
    },
  },
  methods: {
    sendMessageWpp() {
      const message = `¡Hola%20Equipo%20Pagando!%20Recientemente,%20he%20tenido%20dificultades%20para%20completar%20la%20solicitud%20de%20mi%20cuenta%20
      "${this.cardRequest.cardTemplate.name}",%20el%20correo%20electrónico%20que%20vengo%20utilizando%20es%20"${this.user.email}".%20\n\n Me%20gustaría%20solicitar%20su
      %20asistencia%20en%20este%20asunto.%20\nGracias%20de%20antemano.`;

      const url = `https://wa.me/6143605964?text=${message}`;

      window.open(url, '_blank', 'noreferrer');
    },
    nextStep() {
      if (
        this.cardRequest.status === "ACCEPTED" ||
        this.cardRequest.status === "DELIVERED"
      ) {
        // TODO REDIRECCION A BENEFICIARIOS AQUI
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.BENEFICIARIES,
        });
        // this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
        //   stepOnbng: ONBOARDING_STEPS.CONTRACT,
        // });
        let goTo = "beneficiariesRegister";
        if (this.$route.name === "successRequestMultiple") {
          goTo = "contractMultiple";
        }
        if (this.cardRequest.accountType === "BUSINESS") {
          if (this.$route.name === "businessSuccessRequestMultiple") {
            goTo = "businessContractMultiple";
          } else {
            if(this.cardRequest.isMoral) {
              goTo = "businessContract";

            } else {
              goTo = 'businessBeneficiaries';
            }
          }
        }
        this.$router.push({ name: goTo });
      }
    },
    getImage() {
      if (this.hasError) {
        if (
          this.$route.name === "successRequestMultiple" ||
          this.$route.name === "businessSuccessRequestMultiple"
        ) {
          if (this.errorObject.requestCase === "CARD_REPOSITION") {
            return this.solReposicionImage;
          }

          return this.solEnvioImage;
        }

        return this.errorImage;
      }

      return this.successImage;
    },
    getTitle() {
      if (this.hasError) {
        if (
          this.$route.name === "successRequestMultiple" ||
          this.$route.name === "businessSuccessRequestMultiple"
        ) {
          return "¡Ups! Lo sentimos mucho";
        }

        return "Solicitud Cancelada";
      }
      return this.$t("register.processingRequest.successTitle");
    },
    getNameButton() {
      if (
        this.$route.name === "successRequestMultiple" ||
        this.$route.name === "businessSuccessRequestMultiple"
      ) {
        switch (this.errorObject.requestCase) {
          case "CARD_REPOSITION":
            return this.$t(
              "multipleAccountPersonal.buttonActions.cardReposition"
            );
          case "PHYSIC_ACTIVE_CARD":
            return this.$t(
              "multipleAccountPersonal.buttonActions.physicActiveCard"
            );
          case "PHYSIC_PENDING_REQUEST":
            return this.$t(
              "multipleAccountPersonal.buttonActions.physicPendingCard"
            );
          case "VIRTUAL_CONTRACT":
            return this.$t(
              "multipleAccountPersonal.buttonActions.virtualContract"
            );
          case "VIRTUAL_PENDING_REQUEST":
            return this.$t(
              "multipleAccountPersonal.buttonActions.virtualPendingCard"
            );
          default:
            return this.$t("multipleAccountPersonal.buttonActions.default");
        }
      }
      return this.$t("register.processingRequest.errors");
    },
    getErrorMessage() {
      if (this.hasError) {
        if (this.errorObject) {
          if (this.errorObject.hasAlreadyAccount) {
            return "Ya tienes una cuenta del tipo seleccionado. Por favor selecciona un tipo de cuenta diferente.";
          }

          if (this.errorObject.requestCase) {
            switch (this.errorObject.requestCase) {
              case "CARD_REPOSITION":
                return this.$t(
                  "multipleAccountPersonal.messagesError.cardReposition"
                );
              case "PHYSIC_ACTIVE_CARD":
                return this.$t(
                  "multipleAccountPersonal.messagesError.physicActiveCard"
                );
              case "PHYSIC_PENDING_REQUEST":
                return this.$t(
                  "multipleAccountPersonal.messagesError.physicPendingCard"
                );
              case "VIRTUAL_CONTRACT":
                return this.$t(
                  "multipleAccountPersonal.messagesError.virtualContract"
                );
              default:
                return this.$t("multipleAccountPersonal.messagesError.default");
            }
          }
        }

        return this._messageErrorFromCardRequest();
      }

      return this.$t("register.processingRequest.successSubtitle");
    },
    _getErrorsFromProvider() {
      if(this.cardRequest && this.cardRequest.errorsProviders && this.cardRequest.errorsProviders.length > 0) {

        const messages = [];
        for(const error of this.cardRequest.errorsProviders) {
            messages.push(this.$t(error));
        }

        if(messages.length > 0) {
          return messages.join('</br>');
        }
      }

      return this.$t("multipleAccountPersonal.messagesError.default2");
    },
    _messageErrorFromCardRequest() {
      if(this.cardRequest && this.cardRequest.kycResponsesErrors && this.cardRequest.kycResponsesErrors.length > 0) {
          const messages = [];
          for(const error of this.cardRequest.kycResponsesErrors) {
            if(error.isErrorHandled && error.error.value.canShowUser) {
              let messageToPush = `<strong>Documento: ${this.$t(error.documentType)}</strong> </br> • ${this.$t(error.error.name)}`;

              if(error.error.reason && error.error.reason.value.canShowUser) {
                messageToPush = `<strong>Documento: ${this.$t(error.documentType)}</strong> </br> • ${this.$t(error.error.name)} - ${this.$t(error.error.reason.name)}`;
              }
              messages.push(messageToPush);
            }
          }

          if(messages.length > 0) {
            return messages.join('</br>');
          }
        }

        return this._getErrorsFromProvider();
    },
    async _redirectError() {
      switch (this.errorObject.requestCase) {
        case "PHYSIC_ACTIVE_CARD":
          this.$router.push({ name: "CardActivation" });
          break;
        case "CARD_REPOSITION":
        case "PHYSIC_PENDING_REQUEST":
          this.$router.push({ name: "CardShippingStatus" });
          break;
        case "VIRTUAL_CONTRACT":
        case "VIRTUAL_PENDING_REQUEST":
          await this.getRequestCard();
          break;
        default:
          if (this.errorObject.hasAlreadyAccount) {
            if (this.$route.name === "businessSuccessRequestMultiple") {
              this.$router.push({ name: "selectSchemaBusinessMultiple" });
            } else {
              this.$router.push({ name: "selectAccountMultiple" });
            }
          } else {
            await this.generateNewFromErrorMulti();
          }
          break;
      }
    },
    async getOrg() {
      if (
        this.cardRequest.accountType === "BUSINESS" &&
        this.cardRequest.organizationInfo
      ) {
        await this.$store.dispatch(`${orgInfoStore}/getOrganization`, {
          id: this.cardRequest.organizationInfo,
        });
      }
    },
    async getRequestCard() {
      this.loading = true;

      await this.$store.dispatch(`${onbngStore}/getProvisionalCards`, false);

      await this.getOrg();

      if (this.cardRequest.status !== "IN_PROCCESS") {
        if (this.cardRequest.accountType === "BUSINESS") {
          this.$router.push({ name: "businessProcessingRequestMultiple" });
        } else {
          this.$router.push({ name: "processingRequestMultiple" });
        }
      } else {
        if (this.cardRequest.accountType === "BUSINESS") {
          switch (this.cardRequest.stepOnboarding) {
            case "SELECT_DOCUMENT":
            case "UPLOAD_DOCUMENTS":
              this.$router.push({ name: "businessDocumentsMultiple" });
              break;
            case "ADD_DATA":
              this.$router.push({ name: "businessAddInfoMultiple" });
              break;
            case "BUSINESS_START":
              this.$router.push({ name: "selectCreateOrgBusinessMultiple" });
              break;
            case "BUSINESS_INFO":
              this.$router.push({ name: "businessDataMultiple" });
              break;
            case "BUSINESS_FISCAL_DATA":
              this.$router.push({ name: "businessFiscalDataMultiple" });
              break;
            case "BUSINESS_FISCAL_ADDRESS":
              this.$router.push({ name: "businessFiscalAddressMultiple" });
              break;
            case "BUSINESS_COMERCIAL_ADDRESS":
              this.$router.push({ name: "businessFiscalComAddressMultiple" });
              break;
            case "BUSINESS_SHAREHOLDERS":
              this.$router.push({ name: "businessShareholdersMultiple" });
              break;
            case "CONTRACT":
              this.$router.push({ name: "businessContractMultiple" });
              break;
            case "SELECT_CARD":
              this.$router.push({ name: "selectAccountBusinessMultiple" });
              break;
            case "WAIT_REQUEST":
            case "PENDING_WEBHOOK":
              this.$router.push({ name: "businessProcessingRequestMultiple" });
              break;
            default:
              this.$router.push({ name: "selectSchemaBusinessMultiple" });
              break;
          }
        } else {
          switch (this.cardRequest.stepOnboarding) {
            case "PLACE_RESIDENCE":
            case "PERSONAL_INFO":
              this.$router.push({ name: "residenceMultiple" });
              break;
            case "JOB":
              this.$router.push({ name: "occupationMultiple" });
              break;
            case "SELECT_DOCUMENT":
            case "UPLOAD_DOCUMENT":
              this.$router.push({ name: "selectDocumentsMultiple" });
              break;
            case "ADD_DATA":
              this.$router.push({ name: "addInfoMultiple" });
              break;
            case "CONTRACT":
              this.$router.push({ name: "contractMultiple" });
              break;
            case "WAIT_REQUEST":
            case "PENDING_WEBHOOK":
              this.$router.push({ name: "processingRequestMultiple" });
              break;
            default:
              this.$router.push({ name: "selectAccountMultiple" });
              break;
          }
        }
      }

      // }
    },
    _redirectToFirst(newRequest) {
      this.$store.commit(
        `${onbngStore}/SET_CARD_REQUEST`,
        newRequest
      );
      this.$store.commit(`${onbngStore}/SET_ERROR_REQUEST_CARD`, false);
      if (newRequest.accountType === "BUSINESS") {
          this.$router.push({ name: "selectCreateOrgBusinessMultiple" });
      } else {
        if(newRequest.information_level === 'LEVEL_TWO' || newRequest.information_level === 'LEVEL_THREE') {
          this.$router.push({ name: "residenceMultiple" });
        } else {
          this.$router.push({ name: "processingRequestMultiple" });
          this.$store.dispatch(`${onbngStore}/requestCard`, {
            provider: "PAGANDITO",
            longitud: this.location.longitud,
            latitud: this.location.latitud,
            isMultipleAccount: this.$route.name === "successRequestMultiple",
          });
        }
      }
    },
    async generateNewFromErrorMulti() {
      const response = await CardsApi.generateCardRequestFromError({
        cardRequestInfoId: this.cardRequest._id,
      }).catch(catchError);

      if (response && response.data) {
        this.loadingButton = false;
        if (response.data.error) {
          this.displayNotificationError("Error");
        } else {

          const newRequest = response.data.object;

          if(this.cardRequest.rejectReason && this.cardRequest.status !== 'WAIT_REQUEST') {
            this._redirectToFirst(newRequest);
          } else {
            this.$store.commit(`${onbngStore}/SET_GO_TO_WAIT`, true);
            this.$store.commit(
              `${onbngStore}/SET_CARD_REQUEST`,
              response.data.object
            );
            this.$store.commit(`${onbngStore}/SET_ERROR_REQUEST_CARD`, false);
            this._sendErrorRequest(response);
          }

        }
      } else {
        this.loadingButton = false;
        this.displayNotificationError("Error");
      }
    },
    _sendErrorRequest(response) {
      if (response.data.object.accountType === "BUSINESS") {
        this.sendRequestCard();
      } else {
        this.$router.push({ name: "processingRequestMultiple" });
        this.$store.dispatch(`${onbngStore}/requestCard`, {
          provider: "PAGANDITO",
          longitud: this.location.longitud,
          latitud: this.location.latitud,
          isMultipleAccount: this.$route.name === "successRequestMultiple",
        });
      }
    },
    sendRequestCard() {
      let isMoral = false;
      if (this.cardRequest.isMoral) {
        isMoral = true;
      }

      this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
        stepOnbng: ONBOARDING_STEPS.WAIT_REQUEST,
      });

      const isNewOrg = this.$store.getters[`${onbngStore}/GET_CREATE_NEW_ORG`];

      this.$store
        .dispatch(`${onbngStore}/requestCard`, {
          provider: "PAGANDITO",
          longitud: this.location.longitud,
          latitud: this.location.latitud,
          isMoral: isMoral,
          organizationInfo: this.organization._id,
          isMultipleAccount:
            this.$route.name === "businessSuccessRequestMultiple",
          isNewOrg: isNewOrg,
        })
        .then(() => {});

      this.$router.push({ name: "businessProcessingRequestMultiple" });
    },
    async generateFromError() {
      const response = await CardsApi.generateCardRequestFromError({
        cardRequestInfoId: this.cardRequest._id,
      });

      if (response?.data) {
        this.loadingButton = false;
        if (response.data.error) {
          this.displayNotificationError("Error corrigiendo errores");
        } else {
          this.$store.commit(`${onbngStore}/SET_GO_TO_WAIT`, false);
          this.$store.commit(
            `${onbngStore}/SET_CARD_REQUEST`,
            response.data.object
          );
          this.$store.commit(`${onbngStore}/SET_ERROR_REQUEST_CARD`, false);
          if (response.data.object.accountType === "BUSINESS") {
            this.redirectBusiness(response);
          } else {
            this.redirectPersonal(response);
          }
        }
      } else {
        this.loadingButton = false;
        this.displayNotificationError("Error corrigiendo errores");
      }
    },
    async corregirErrores() {
      if(this.loadingButton) {
        return;
      }
      this.loadingButton = true;
      if (
        this.$route.name === "successRequestMultiple" ||
        this.$route.name === "businessSuccessRequestMultiple"
      ) {
        await this._redirectError();
      } else if (this.level === 0) {
        this.$store.commit(`${onbngStore}/SET_GO_TO_WAIT`, true);
        this.redirectCollaborator(this.user.errorOnboardingCollaborator);
      } else {
        await this.generateFromError();
      }
    },
    redirectPersonal(response) {
      switch (response.data.object.stepOnboarding) {
        case ONBOARDING_STEPS.SELECT_CARD:
          this.$router.push({ name: "selectAccount" });
          break;
        case ONBOARDING_STEPS.POLICIES_OF_USE:
          this.$router.push({ name: "usagePolicies" });
          break;
        case ONBOARDING_STEPS.NATIONALITY:
          this.$router.push({ name: "nationality" });
          break;
        case ONBOARDING_STEPS.PERSONAL_INFO:
          this.$router.push({ name: "personalInfo" });
          break;
        case ONBOARDING_STEPS.PLACE_RESIDENCE:
          this.$router.push({ name: "residence" });
          break;
        case ONBOARDING_STEPS.JOB:
          this.$router.push({ name: "occupation" });
          break;
        case ONBOARDING_STEPS.PHONE_VERIFICATION:
          this.$router.push({ name: "phoneVerification" });
          break;
        case ONBOARDING_STEPS.CODE_VERIFICATION:
          this.$router.push({ name: "codeVerification" });
          break;
        case ONBOARDING_STEPS.SECURITY_MEASURES:
          this.$router.push({ name: "securityMeasures" });
          break;
        case ONBOARDING_STEPS.CREATE_NIP:
          this.$router.push({ name: "newPin" });
          break;
        case ONBOARDING_STEPS.CONFIRM_NIP:
          this.$router.push({ name: "confirmPin" });
          break;
        case ONBOARDING_STEPS.SELECT_DOCUMENT:
          this.$router.push({ name: "selectDocuments" });
          break;
        case ONBOARDING_STEPS.UPLOAD_DOCUMENTS:
          this.$router.push({ name: "selectDocuments" });
          break;
        default:
          this.$router.push({ name: "processingRequest" });
          this.$store.dispatch(`${onbngStore}/requestCard`, {
            provider: "PAGANDITO",
            longitud: this.location.longitud,
            latitud: this.location.latitud,
            isMultipleAccount: this.$route.name === "successRequestMultiple",
          });
          break;
      }
    },
    redirectBusiness(response) {
      let isMoral = false;
      if (response.data.object.isMoral) {
        isMoral = true;
      }
      const isNewOrg = this.$store.getters[`${onbngStore}/GET_CREATE_NEW_ORG`];

      switch (response.data.object.stepOnboarding) {
        case ONBOARDING_STEPS.SELECT_CARD:
          this.$router.push({ name: "selectAccountBusiness" });
          break;
        case ONBOARDING_STEPS.POLICIES_OF_USE:
          this.$router.push({ name: "usagePoliciesBusiness" });
          break;
        case ONBOARDING_STEPS.NATIONALITY:
          this.$router.push({ name: "businessNationality" });
          break;
        case ONBOARDING_STEPS.PERSONAL_INFO:
          this.$router.push({ name: "businessPersonalInfo" });
          break;
        case ONBOARDING_STEPS.PLACE_RESIDENCE:
          this.$router.push({ name: "businessResidence" });
          break;
        case ONBOARDING_STEPS.JOB:
          this.$router.push({ name: "businessOccupation" });
          break;
        case ONBOARDING_STEPS.PHONE_VERIFICATION:
          this.$router.push({ name: "businessPhoneVerification" });
          break;
        case ONBOARDING_STEPS.CODE_VERIFICATION:
          this.$router.push({ name: "businessCodeVerification" });
          break;
        case ONBOARDING_STEPS.SECURITY_MEASURES:
          this.$router.push({ name: "businessSecurityMeasures" });
          break;
        case ONBOARDING_STEPS.CREATE_NIP:
          this.$router.push({ name: "businessNewPin" });
          break;
        case ONBOARDING_STEPS.CONFIRM_NIP:
          this.$router.push({ name: "businessConfirmPin" });
          break;
        case ONBOARDING_STEPS.SELECT_DOCUMENT:
        case ONBOARDING_STEPS.UPLOAD_DOCUMENTS:
          if(isMoral) {
            this.$router.push({ name: "businessDocuments" });
          } else {
            this.$router.push({ name: "businessSelectDocuments" });
          }
          break;
        case ONBOARDING_STEPS.BUSINESS_SHAREHOLDERS:
          this.$router.push({ name: "businessShareholders" });
          break;
        case ONBOARDING_STEPS.ADD_DATA:
          this.$router.push({ name: "businessAddInfo" });
          break;
        default:
          this.$router.push({ name: "businessProcessingRequest" });
          this.$store.dispatch(`${onbngStore}/requestCard`, {
            provider: "PAGANDITO",
            longitud: this.location.longitud,
            latitud: this.location.latitud,
            isMoral: isMoral,
            organizationInfo: this.organization._id,
            isMultipleAccount:
              this.$route.name === "businessSuccessRequestMultiple",
            isNewOrg: isNewOrg,
          });
          break;
      }
    },
    async redirectCollaborator(step) {
      switch (step) {
        case ONBOARDING_STEPS.NATIONALITY:
          await usersApi.updateStepOnbCollab({onbStep: ONBOARDING_STEPS.NATIONALITY });
          this.$router.push({ name: "nationalityCollaborator" });
          break;
        case ONBOARDING_STEPS.UPLOAD_DOCUMENTS:
          await usersApi.updateStepOnbCollab({onbStep: ONBOARDING_STEPS.UPLOAD_DOCUMENTS });

          this.$router.push({name: "selectDocumentsCollaborator"});
          break;
        case ONBOARDING_STEPS.PERSONAL_INFO:
          await usersApi.updateStepOnbCollab({onbStep: ONBOARDING_STEPS.PERSONAL_INFO });

          this.$router.push({ name: "personalInfoCollaborator" });
          break;
        case ONBOARDING_STEPS.PLACE_RESIDENCE:
          await usersApi.updateStepOnbCollab({onbStep: ONBOARDING_STEPS.PLACE_RESIDENCE });

          this.$router.push({name: "residenceCollaborator"});
          break;
        case ONBOARDING_STEPS.JOB:
          await usersApi.updateStepOnbCollab({onbStep: ONBOARDING_STEPS.JOB });

          this.$router.push({ name: "occupationCollaborator" });
          break;
        case ONBOARDING_STEPS.ADD_DATA:
          await usersApi.updateStepOnbCollab({onbStep: ONBOARDING_STEPS.ADD_DATA });

          this.$router.push({name: "addInfoCollaborator"});
          break;
        case ONBOARDING_STEPS.PHONE_VERIFICATION:
          await usersApi.updateStepOnbCollab({onbStep: ONBOARDING_STEPS.PHONE_VERIFICATION });

          this.$router.push({ name: "phoneVerificationCollaborator" });
          break;
        case ONBOARDING_STEPS.SECURITY_MEASURES:
          await usersApi.updateStepOnbCollab({onbStep: ONBOARDING_STEPS.SECURITY_MEASURES });

          this.$router.push({ name: "securityMeasuresCollaborator" });
          break;
        case ONBOARDING_STEPS.CREATE_NIP:
                    await usersApi.updateStepOnbCollab({onbStep: ONBOARDING_STEPS.CREATE_NIP });

          this.$router.push({ name: "newPinCollaborator" });
          break;
        default:
          this.$router.push({ name: "processingRequestCollaborator" });
          break;
      }
    },
  },
  async mounted() {
    const message1 = "hide-cancel-request";
    if (this.isMoral2) {
      this.isMoral = this.isMoral2 === "MORAL";
    }

    const routeName = this.$route.name;
    if (routeName === "successRequestMultiple") {
      this.$store.dispatch(`${onbngStore}/putLevel`, "MULTIPLE_PERSONAL");

      if (this.hasError || this.errorObject) {
        EventBus.$emit(message1, true);
      } else {
        EventBus.$emit(message1, false);
      }
    } else if (routeName === "businessSuccessRequestMultiple") {
      await this.$store.dispatch(
        `${onbngStore}/putLevel`,
        this.isMoral ? "MULTIPLE_BUSINESS_MORAL" : "MULTIPLE_BUSINESS_PHYSIC"
      );

      if (this.hasError || this.errorObject) {
        EventBus.$emit(message1, true);
      } else {
        EventBus.$emit(message1, false);
      }
    }
    this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
  },
};
</script>

<style scoped>
</style>
