<template>
  <div>
    <TitlePrincipal
      :title="$t('register.verifyEmail.title')"
      clazz="text-align-c"
    >
      <p
        class="subtitle"
        v-html="getMessageEmail()"
      ></p>
    </TitlePrincipal>
    <img
      class="img-fluid max-h-300px d-flex m-0-auto"
      src="@/assets/images/Illustrations/illustration-verificar-correo.svg"
      alt="Verifica tu correo electrónico"
    />
    <p class="f-14 c-plain_text poppins-font-light text-align-c m-t-30">
      {{ $t("register.verifyEmail.text") }}
      <br />
      <a
        href=""
        class="c-info poppins-font-bold text-underline"
        tabindex=""
        @click.prevent="reSendEmail"
      >
        {{ $t("register.verifyEmail.resendEmail") }}
      </a>
      <button
        type="button"
        class="button md button-stroke button-primary p-relative m-auto m-t-30"
        @click="clearSession()"
      >
        Salir
      </button>
    </p>
  </div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import UserApi from "@/api/users/users.api";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import {
  STORE as sessionStore,
  CLEAR_STORE,
} from "@/store/modules/session/session.types";

export default {
  name: "VerifyEmail",
  mixins: [ToastSnotify],
  data() {
    return {
      registerEmail: "",
    };
  },
  components: {
    TitlePrincipal,
  },
  methods: {
    reSendEmail() {
      UserApi.resendVerificationEmail({
        email: this.registerEmail,
        appliesTo: "WALLET",
        fromWeb: true,
      })
        .then(() => {
          this.displayNotificationSuccess(
            this.$i18n.t("register.verifyEmail.resendEmailSuccess"),
            this.$i18n.t("register.verifyEmail.resendEmailSuccessTitle")
          );
        })
        .catch(() => {
          this.displayNotificationError(
            this.$i18n.t("register.verifyEmail.resendEmailError"),
            this.$i18n.t("register.verifyEmail.resendEmailErrorTitle")
          );
        });
    },
    clearSession() {
      localStorage.removeItem("currentUser");
      localStorage.removeItem("currentToken");
      localStorage.removeItem("currentPermissions");
      localStorage.removeItem("profile");
      localStorage.removeItem("expireDate");
      UserApi.logout();
      this.$store.commit(`${sessionStore}/${CLEAR_STORE}`);
      if (this.$router.currentRoute.name !== "login") {
        this.$router.push({ name: "login" });
      }
    },
    getMessageEmail() {
      return this.$t('register.verifyEmail.subtitle', { email: this.registerEmail })
    }
  },
  created() {
    this.registerEmail = this.$route.params.email;
  },
};
</script>

<style scoped>
</style>
