<template>
  <div>
    <b-modal
      id="modal-details-transfer"
      size="lg"
      hide-header
      hide-footer
      modal-class="modal-default left-oriented"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide('modal-details-transfer')"
        :aria-label="$t('transfers.modalDetails.close')"
        :name="$t('transfers.modalDetails.close')"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head" v-if="!isObjectEmpty(transfer)">
        <h2 class="modal-title">{{$t('transfers.modalDetails.title')}}</h2>
        <div class="status-info">
          <Badge v-if="transfer.status === 'SUCCESS'" type="badge-success" :text="$t('transfers.modalDetails.badge.success')"></Badge>
          <Badge v-if="transfer.status === 'PENDING_AUTH'" type="badge-warning" :text="$t('transfers.modalDetails.badge.pendingAuth')"></Badge>
          <Badge v-if="transfer.status === 'PENDING'" type="badge-warning" :text="$t('transfers.modalDetails.badge.pending')"></Badge>
          <Badge v-if="transfer.status === 'REJECTED'" type="badge-error" :text="$t('transfers.modalDetails.badge.rejected')"></Badge>
          <Badge v-if="transfer.status === 'ERROR'" type="badge-error" :text="$t('transfers.modalDetails.badge.error')"></Badge>
          <ReadOnlyInfo class="text-center m-b-0" :title="$t('transfers.modalDetails.amount')">
            <p class="f-28 c-primary poppins-font-bold f-700">{{transfer.amount | currency}}</p>
          </ReadOnlyInfo>
        </div>
      </div>
      <div class="body row" v-if="!isObjectEmpty(transfer)">
        <div class="col-12 col-md-6">
          <ReadOnlyInfo class="col-12" :title="$t(`transfers.modalDetails.date.${transfer.status}`)" :text="moment(transfer.date).format('DD MMM YYYY HH:mm a')"/>
          <ReadOnlyInfo class="col-12" :title="$t('transfers.modalDetails.concept')" :text="transfer.concept"/>
          <ReadOnlyInfo class="col-12" :title="$t('transfers.modalDetails.beneficiaryName')" :text="contact.contactName"/>
          <ReadOnlyInfo class="col-12" :title="$t('transfers.modalDetails.bank')" :text="contact.bank"/>
          <ReadOnlyInfo class="col-12" :title="$t('transfers.modalDetails.beneficiaryAccoun')" :text="contact.number"/>
        </div>
        <div class="col-12 col-md-6">
          <ReadOnlyInfo class="col-12" :title="$t(`transfers.modalDetails.commission.${transfer.status}`)" :text="transfer.commission | currency"/>
          <ReadOnlyInfo class="col-12" :title="$t('transfers.modalDetails.folio')" :text="transfer.folio"/>
          <ReadOnlyInfo class="col-12" :title="$t('transfers.modalDetails.rejectReason')" :text="transfer.rejectReason" v-if="transfer.status === 'REJECTED'"/>
          <ReadOnlyInfo class="col-12" :title="$t('transfers.modalDetails.trackingKey')" :text="transfer.trackingKey" v-if="!contact.isPagandoAccount"/>
          <ReadOnlyInfo class="col-12" :title="$t('transfers.modalDetails.operationStatus')" v-if="!contact.isPagandoAccount">
            <a href="https://www.banxico.org.mx/cep/" class="f-16 c-info poppins-font-semibold f-600 wb-break-word" tabindex="" rel="nofollow">
              <u>https://www.banxico.org.mx/cep/</u>
            </a>
          </ReadOnlyInfo>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
import Badge from "@/components/Badge.vue";
import moment from 'moment';
import {isObjectEmpty} from "@/utils/utils";

export default {
  name: 'ModalTransferDetails',
  components: {
    ReadOnlyInfo,
    Badge,
  },
  data() {
    return {
      transfer: {},
      contact: {},
      isObjectEmpty: isObjectEmpty
    };
  },
  computed: {
    dateTitle() {
      switch(this.transfer.status) {
        case 'PENDING_AUTH':
          return 'Fecha y hora de solicitud';
        case 'REJECTED':
          return 'Fecha y hora de rechazo';
        case 'PENDING':
        case 'SUCCESS':
        default:
          return 'Fecha y hora de transferencia';
      }
    },
    commissionTitle() {
      switch(this.transfer.status) {
        case 'PENDING_AUTH':
        case 'PENDING':
          return 'Comisión a cobrar';
        case 'REJECTED':
        case 'SUCCESS':
        default:
          return 'Comisión cobrada';
      }
    },
  },
  mounted() {
    moment.locale(this.$i18n.locale);
    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      if (modalId === 'modal-details-transfer') {
        this.transfer = {};
        this.contact = {};
      }
    });
  },
  methods: {
    moment,
    show(transfer) {
      this.transfer = transfer;
      this.contact = transfer.contact;
      this.$bvModal.show('modal-details-transfer');
    },
  },
}
</script>
