
import {
    // GET_CARD_REQUEST,
    STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import store from '@/store/index'


export default function accountPersonal({ next }) {

    const typeAccount = store.getters[`${onbngStore}/GET_TYPE_ACCOUNT`];


    if (typeAccount) {
        return next();
    }

    return store.dispatch(`${onbngStore}/getRouteByStep`);


    // return next('/onboarding-business');
}
