<template>
<div>
  <TitlePrincipal :title="$t('register.phoneVerification.successTitle')" clazz="text-align-c">
    <p class="subtitle" v-html="$t('register.phoneVerification.successSubtitle', {last4: getPhone()})">

    </p>
  </TitlePrincipal>
  <img class="img-fluid max-h-300px d-flex m-0-auto"
       src="@/assets/images/Illustrations/illustration-numero-verificado.svg"
       alt="Celular validado correctamente"/>
  <div class="footer-buttons center">
    <button type="button" class="button button-filled button-primary" name="Continuar" @click="next">
      {{ $t('register.phoneVerification.continue') }} </button>
  </div>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import {GET_USER, STORE as SESSION_STORE} from "@/store/modules/session/session.types";
import { mapGetters } from 'vuex';
import {
  GET_LEVEL,
  GET_CARD_REQUEST,
  GO_TO_ROUTE,
  STORE as onbngStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";

export default {
  name: "SuccessPhone",
  components: {
    TitlePrincipal
  },
  computed: {
    ...mapGetters(SESSION_STORE, {
      user: GET_USER
    }),
    ...mapGetters(onbngStore, {
      level: GET_LEVEL,
      cardRequest: GET_CARD_REQUEST
    }),
  },
  methods: {
    getPhone() {
      const phone = this.user ? this.user.phone : "";
      return phone.substring(phone.length - 4);
    },
    next() {
      if(this.level === 0) {
        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, 'securityMeasuresCollaborator');
      } else {
        this.$store.dispatch(`${onbngStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.SECURITY_MEASURES,
        });
        let goTo = "securityMeasures";
        if (this.cardRequest.accountType === 'BUSINESS') {
          goTo = "businessSecurityMeasures"
        }

        this.$store.dispatch(`${onbngStore}/${GO_TO_ROUTE}`, goTo);
      }
    }
  },
  created() {
    this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);

  }
}
</script>

<style scoped>

</style>
