const ONBOARDING_STEPS = {
    SELECT_CARD: 'SELECT_CARD',
    POLICIES_OF_USE: 'POLICIES_OF_USE',
    NATIONALITY: 'NATIONALITY',
    SELECT_DOCUMENT: 'SELECT_DOCUMENT',
    UPLOAD_DOCUMENTS: 'UPLOAD_DOCUMENTS',
    PERSONAL_INFO: 'PERSONAL_INFO',
    PLACE_RESIDENCE: 'PLACE_RESIDENCE',
    JOB: 'JOB',
    PHONE_VERIFICATION: 'PHONE_VERIFICATION',
    ADD_DATA: 'ADD_DATA',
    CODE_VERIFICATION: 'CODE_VERIFICATION',
    SECURITY_MEASURES: 'SECURITY_MEASURES',
    CREATE_NIP: 'CREATE_NIP',
    CONFIRM_NIP: 'CONFIRM_NIP',
    WAIT_REQUEST: 'WAIT_REQUEST',
    BENEFICIARIES: 'BENEFICIARIES',
    CONTRACT: 'CONTRACT',
    BUSINESS_START: 'BUSINESS_START',
    BUSINESS_OPERATES: 'BUSINESS_OPERATES',
    BUSINESS_INFO: 'BUSINESS_INFO',
    BUSINESS_FISCAL_DATA: 'BUSINESS_FISCAL_DATA',
    BUSINESS_FISCAL_ADDRESS: 'BUSINESS_FISCAL_ADDRESS',
    BUSINESS_COMERCIAL_ADDRESS: 'BUSINESS_COMERCIAL_ADDRESS',
    BUSINESS_SHAREHOLDERS: 'BUSINESS_SHAREHOLDERS'
};

Object.freeze(ONBOARDING_STEPS);

module.exports = {
    ONBOARDING_STEPS
};
