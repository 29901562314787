import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import actions from '@/store/actions';
import state from '@/store/state';
import mutations from '@/store/mutations';
import getters from '@/store/getters';

import {STORE as OnboardingStore} from '@/store/modules/onboardingSteps/onboardingSteps.types';
import {STORE as SessionStore} from "@/store/modules/session/session.types";
import {STORE as OrganizationInfoStore} from "@/store/modules/organizationInfo/organizationInfo.types";
import {STORE as ShareholdersStore} from "@/store/modules/shareholders/shareholders.types";

import onboardingSteps from '@/store/modules/onboardingSteps/onboardingSteps.store';
import session from "@/store/modules/session/session.store";
import organizationInfo from "@/store/modules/organizationInfo/organizationInfo.store";
import shareholders from "@/store/modules/shareholders/shareholders.store";

Vue.use(Vuex)

// Improvement: a better way to obtain current env
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        [OnboardingStore]: onboardingSteps,
        [SessionStore]: session,
        [OrganizationInfoStore]: organizationInfo,
        [ShareholdersStore]: shareholders,
    },
    actions,
    state,
    getters,
    mutations,
    strict: debug,
    plugins: debug ? [createLogger()] : []
})
