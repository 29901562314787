<template>
  <div class="left-side-content">
    <div class="container-left">
      <img class="img-fluid logo" src="@/assets/images/Logos/logo-color.svg" alt="Logo Pagando"/>
      <div class="progress-percent-title">
        <ProgressCircle>
          <radial-progress-bar
              :class="[getCurrentPct() === 100 ? 'success-done' : '']"
              :diameter="diam"
              :totalSteps="totalPercent"
              :completedSteps="getCurrentPct()"
              startColor="#E40096"
              stopColor="#E40096"
              innerStrokeColor="#FFF"
              :strokeWidth="strokeW"
              :innerStrokeWidth="innerStrokeW"
              timingFunc="ease-in-out">
            <p class="percentage"><strong>{{getCurrentPct()}}</strong>%</p>
          </radial-progress-bar>
        </ProgressCircle>

        <h2 class="title">
          {{showBack ? 'Solicitud de nueva' : isBusiness ? 'Cuenta de ' : 'Cuenta'}}
          <br/>
          <strong>{{showBack ? isBusiness ? 'cuenta de negocios' : 'cuenta personal' :isBusiness ? 'Negocios' : 'Personal'}}</strong>
        </h2>
      </div>

      <div class="vertical-steps-container">
        <ul class="vertical-steps">
          <li v-for="step in steps" v-bind:key="step.step">
            <a href="javascript:void(0)" class="step" v-bind:class="[step.visited ? 'visited' : '', step.current ? 'current': '' ]" tabindex="">
              <a v-if="checkRoute()">
                <span class="step-num"> 0{{step.step}} </span>
                {{$t(step.name)}}
              </a>
              <a v-if="!checkRoute()" @click.stop.prevent="goTo(step)">
                <span class="step-num"> 0{{step.step}} </span>
                {{$t(step.name)}}
              </a>
            </a>
          </li>
        </ul>
      </div>

      <!-- <button v-if="showBack" type="button" class="button button-ghost button-plain_text" @click="cancelRequest()" aria-label="Ir a" name="Ir a" >
        <i aria-hidden="true" class="banana banana-chevron-left"></i> {{$t('multipleAccountPersonal.back')}}
      </button> -->

      <div class="footer-help">
        <a href class="help-chat" tabindex="" @click.prevent="openZendesk">
          <img class="img-fluid" src="@/assets/images/Illustrations/illustration-chat-help.svg" alt="Chat de soporte" />
        </a>
        <p class="help-num">
          También puedes contactarnos a los teléfonos:
          <br/>
          <strong>México: 800 461 0119 y EU: 800 392 6948</strong>
        </p>
      </div>
    </div>

    <ModalAlert
      ref="warning-multiple-request"
      modalId="warning-multiple-request"
      buttonType="button-primary"
      :imgUrl="imageModalGeneric"
      :title="$t('multipleAccountPersonal.cancelModal.title')"
      :text="$t('multipleAccountPersonal.cancelModal.text')"
      :buttonText="$t('multipleAccountPersonal.cancelModal.button')"
      @submit="goToDashboard"
    ></ModalAlert>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ProgressCircle from "@/components/ProgressCircle.vue";
import ModalAlert from "@/components/ModalAlert.vue";
import RadialProgressBar from "vue-radial-progress";
import imageModalGeneric from "@/assets/images/Illustrations/illustration-modal-generic.svg";
import {
  STORE as OnboardingStore,
  GET_CURRENT_PCT, GET_CURRENT_STEPS, GET_CONTRACT_INDEX
} from '@/store/modules/onboardingSteps/onboardingSteps.types';

export default {
  name: "LeftSide",
  components: {
    ProgressCircle,
    RadialProgressBar,
    ModalAlert
  },
  computed: {
    ...mapGetters(OnboardingStore, {
      completedPercent: GET_CURRENT_PCT,
      steps: GET_CURRENT_STEPS,
      contractIndex: GET_CONTRACT_INDEX
    })
  },
  props: {
      isBusiness: {type: Boolean, default: false},
      showBack: {type: Boolean, default: false},
  },
  data() {
    return {
      diam: 84,
      totalPercent: 100,
      strokeW: 6,
      innerStrokeW: 3,
      imageModalGeneric
    }
  },
  methods: {
    goTo(step) {
      if (step.step < this.contractIndex && step.visited && !step.current) {
        this.$router.push({name: step.first})
      }
    },
    getCurrentPct() {
      return this.completedPercent || 0;
    },
    openZendesk() {
      if(window.zE) {
        window.zE('messenger', 'open');
      }
    },
    cancelRequest() {
      this.$bvModal.show('warning-multiple-request');
    },
    goToDashboard() {
      this.$router.push({name: 'Dashboard'});
    },
    checkRoute() {
      return (this.$route.matched.some(r => r.name === 'multiplePersonal' || r.name === 'multipleBusiness'));
    }
  }
}
</script>

<style scoped>

</style>
