<template>
  <div v-show="visible">
    <TitleSteps
      :title="$t('transfers.new.summary.title')"
      :subtitle="$t('transfers.new.summary.subtitle')"
    >
      <template v-slot:progress>
        <radial-progress-bar
          class="stepper-circle"
          :diameter="diamStep"
          :totalSteps="3"
          :completedSteps="radialStep"
          startColor="#E40096"
          stopColor="#E40096"
          innerStrokeColor="#D8D8E1"
          :strokeWidth="strokeWStep"
          :innerStrokeWidth="innerStrokeWStep"
          strokeLinecap="square"
          timingFunc="ease-in-out"
        >
          <p class="percentage">3 {{$t('transfers.new.of')}} 3</p>
        </radial-progress-bar>
      </template>
    </TitleSteps>

    <CardSimple hideTitle>
      <LoadingBar
        v-if="loading"
        clazz="fullcontent-loading"
        :loadingText="$t('transfers.new.summary.loading')"
      ></LoadingBar>
      <TransferInfo
        :transmitterName="fullName"
        :transmitterAccount="accountDescription"
        :transmitterNumber="accountNumber"
        :beneficiaryName="contact.contactName"
        :beneficiaryAccount="contact.bank"
        :beneficiaryNumber="contact.number"
      >
        <template v-slot:cuentaOrigen>
          <UserAccount
            clazz="xs m-0-auto"
            :userImg="imageDownloadLink(selfPortrait) || imgUserAccount"
            :widthImg="42"
            :heightImg="42"
            :altImgUser="fullName"
          >
          </UserAccount>
        </template>
        <template v-slot:cuentaBeneficiario>
          <UserAccount
            :clazz="getContactColorClass(contact)"
            :noAccount="!contact.isPagandoAccount"
            :userImg="imageDownloadLink(contact.image) || imgUserAccount"
            :userName="getContactInitials(contact)"
            :altImgUser="contact.contactName"
          />
        </template>
      </TransferInfo>
      <div class="row m-t-20">
        <div class="col-12 col-sm-6">
          <ReadOnlyInfo
            :title="$t('transfers.new.summary.amount')"
            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
            :text="amount | currency"
            textClazz="f-26 c-text_title poppins-font-bold f-700 m-b-5 text-center"
          >
            <p class="f-12 c-text_content poppins-font-medium f-500 m-b-0 text-center" v-if="commission">
              {{$t('transfers.new.summary.commission1')}}
              <strong class="c-text_title poppins-font-bold f-700">
                {{$t('transfers.new.summary.commission2', {amount: currency(commission)})}}
              </strong>
            </p>
            <p class="f-12 c-text_content poppins-font-medium f-500 m-b-0 text-center" v-else>
              {{$t('transfers.new.summary.noCommission')}}
            </p>
          </ReadOnlyInfo>
        </div>
        <div class="col-12 col-sm-6">
          <ReadOnlyInfo
            :title="$t('transfers.new.summary.concept')"
            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
            :text="concept"
            textClazz="f-16 c-text_title poppins-font-bold f-700 m-b-5 text-center"
          >
          </ReadOnlyInfo>
        </div>
      </div>
    </CardSimple>

    <div class="control-buttons">
      <button type="button" class="button-icon" @click="$emit('stepBack')">
        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
        {{$t('transfers.new.summary.stepBack')}}
      </button>
      <button
        type="button"
        class="button button-filled button-primary"
        @click="confirm"
      >
        {{$t('transfers.new.summary.send')}}
      </button>
    </div>
  </div>
</template>
<script>
import TitleSteps from "@/components/TitleSteps.vue";
import CardSimple from "@/components/CardSimple.vue";
import TransferInfo from "@/components/TransferInfo.vue";
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import UserAccount from "@/components/UserAccount.vue";
import imgUserAccount from "@/assets/images/Default/profile-photo.svg";
import RadialProgressBar from "vue-radial-progress";
import { imageDownloadLink } from '@/utils/utils';
import ecryptRSAForSTP from '@/utils/cypher';
import { mapGetters } from "vuex";
import { getContactColorClass, getContactInitials } from "@/utils/contactUtils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import config from '@/config';

import {
  STORE as sessionStore,
  GET_ACCOUNT_DESCRIPTION,
  GET_ACCOUNT,
  GET_ACCOUNT_NUMBER,
  GET_USER_FULL_NAME,
  GET_USER_SELF_PORTRAIT,
} from "@/store/modules/session/session.types";
import CardApi from '@/api/wallet/card.api';

export default {
  name: "NewTransferSummary",
  mixins: [ToastSnotify],
  components: {
    TitleSteps,
    CardSimple,
    TransferInfo,
    ReadOnlyInfo,
    LoadingBar,
    UserAccount,
    RadialProgressBar,
  },
  props: {
    contact: Object,
    amount: Number,
    concept: String,
    visible: {type: Boolean, default: false},
  },
  data() {
    return {
      // ProgressCircle
      diamStep: 64,
      currentStep: 0,
      strokeWStep: 5,
      innerStrokeWStep: 5,
      radialStep: 2,

      imgUserAccount,
      loading: false,
      commission: null,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
      account: GET_ACCOUNT,
      accountDescription: GET_ACCOUNT_DESCRIPTION,
      accountNumber: GET_ACCOUNT_NUMBER,
      fullName: GET_USER_FULL_NAME,
      selfPortrait: GET_USER_SELF_PORTRAIT,
    }),
    currency() {
      return this.$options.filters.currency;
    },
  },
  mounted() {
    this.radialStep = 2
    setTimeout(() => {
      this.radialStep = 3;
    }, 0);

    this.getCommission();
  },
  methods: {
    imageDownloadLink,
    getContactColorClass,
    getContactInitials,
    async getCommission() {
      try {
        const response = await CardApi.getCommissionForEvent({
          pagandoAccount: this.account.account._id,
          commission: this.contact.isPagandoAccount ? 'PAGANDO_TRANSFER' : 'SPEI_TRANSFER'
        });
        if(response.data.error) {
          throw new Error(response.data.message);
        }
        this.commission = response.data.object;
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    async confirm() {
      if(!this.loading && await this.$requestAuth()) {
        this.processTransfer();
      }
    },
    getCurrentPosition() {
      return new Promise((resolve, reject) => {
        if('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(
            ({coords}) => resolve(coords),
            (error) => {
              if(error.code === 1) {
                reject(this.$t('transfers.new.summary.locationUnavailable'))
              } else {
                reject(error.message);
              }
            },
          );
        } else {
          reject(this.$t('transfers.new.summary.locationUnavailable'));
        }
      });
    },
    async processTransfer() {
      try {
        this.loading = true;
        const coords = await this.getCurrentPosition().catch(error => {
          this.toastErrorDefault(error)
        });
        if(coords) {
          const deviceFingerprint = await window.PagandoW.getAFT();
          const objToSend = {
            deviceFingerprint: deviceFingerprint,
            latitud: coords.latitude,
            longitud: coords.longitude,
            paymentSourceId: this.account.cards[0].card._id,
            contactId: this.contact.contactId || this.contact._id,
            amount: this.amount,
            payConcept: this.concept,
            returnForWeb: true,
            platform: 'WEB'
          };

          let sign = null;

          if(config.checkSignSpeiOutActive) {
            const textKey =
            `||${objToSend.latitud}|${objToSend.longitud}|${objToSend.paymentSourceId}|${objToSend.contactId}|${objToSend.amount}|${objToSend.payConcept}||`;
            sign = await ecryptRSAForSTP(textKey);
          }

          const response = await CardApi.transferFounds(
            {
              ...objToSend,
              sign
            }, {timeout: 20000});
          this.$emit('nextStep', {
            transferDetails: {
              commission: this.commission,
              data: response.data.object,
            },
            status: response.status,
          });
        }
        this.loading = false;
      } catch (error) {
        if(error.response && error.response.data && error.response.data.message) {
          const transferError = error.response.data.message;
          this.$emit('nextStep', {transferError});
        } else {
          this.toastErrorDefault(error);
        }
        this.loading = false;
      }
    },
  },
};
</script>
