<template>
  <div class="row">
    <div class="col-12">
      <TitleSteps :title="$t('myAccount.title')">
        <!-- <button
            id="modal-cambiar-cuenta"
            type="button"
            class="button sm button-stroke button-primary"
            @click="$bvModal.show('modal-cambiar-cuenta')">
          <i aria-hidden="true" class="iconsax-arrow-repeat m-r-10"></i>
          Cambiar de cuenta
        </button> -->
        <router-link
            v-if="showCollaboratorsButton"
            to="/collaborators"
            class="button sm button-filled button-primary"
            tabindex="">
            Administrar acceso de colaboradores
        </router-link>
      </TitleSteps>
    </div>

    <div class="col-12">
      <PanelMyAccount
          :primaryTitle="$t('general.CLABE')"
          :primaryText="infoAccount.account.providerCLABE"
          :secondTitle="$t('myAccount.noCuenta')"
          :secondText="infoAccount.account.fullKey">
        <AccountType :text="$t('myAccount.title')" :typeAccount="accountDes" clazz="p-relative imgLeft">
          <img class="img-fluid" :src="accountImage" width="64" height="40" alt="Pagando Explore"/>

          <template v-slot:extraText v-if="productType === 'USERS_ACCOUNT'">
            <Badge
              v-if="isMainAccount"
              type="badge-info brd-4 m-t-5 p-t-0 p-b-0 p-l-5 p-r-5"
              :text="$t('myAccount.mainAccount.badge.label')"
            />
            <button
              v-else
              type="button"
              class="button xs button-filled button-primary whitespace-break-spaces p-t-0 p-b-0 p-l-5 p-r-5 m-t-5"
              @click="openMainAccountModal"
            >
              {{$t('myAccount.mainAccount.badge.action')}}
              <i aria-hidden="true" class="iconsax-arrow-direction-square-right-bold f-14 m-l-5"></i>
            </button>
          </template>
        </AccountType>
      </PanelMyAccount>
    </div>
    <div class="col-12 m-b-40 d-none">
      <button type="" class="btn-link-goto info">
          <p>
              Cuentas Corporativas
              <br/>
              <strong>
                Crea cuentas corporativas a tus colaboradores para viáticos o compras del negocio
              </strong>
          </p>
          <i aria-hidden="true" class="iconsax-arrow-arrow-right"></i>
      </button>
    </div>
    <div class="col-12">
      <b-tabs
          class="simple-tabs"
          content-class="simple-tabs-body p-t-15">

        <b-tab :title="$t('myAccount.generalInfo.titleTap')" active>
          <GeneralInfo></GeneralInfo>
        </b-tab>
        <b-tab :title="$t('myAccount.cashWithdrawal.titleTap')">
          <CashWithdrawal></CashWithdrawal>
        </b-tab>
        <b-tab :title="$t('myAccount.bankStatements.tittleTap')" ref="bankStatements">
          <AccountStatements></AccountStatements>
        </b-tab>
        <b-tab :title="$t('myAccount.limits.titleTap')">
          <OperationalLimits></OperationalLimits>
        </b-tab>
        <b-tab v-if="productType !== 'CORPORATE_ACCOUNT'" :title="$t('myAccount.beneficiaries.titleTap')" ref="beneficiaries">
          <Beneficiaries></Beneficiaries>
        </b-tab>

      </b-tabs>
    </div>
    <ModalAlert
      closeCross
      clazzButtons="flex-direction-column"
      modalId="modal-ppalAccount"
      buttonType="button-primary"
      :imgUrl="imageModalCardCheck"
      :title="$t('myAccount.mainAccount.modal.title')"
      :text="$t('myAccount.mainAccount.modal.text1')"
      :buttonCancel="$t('myAccount.mainAccount.modal.buttonCancel')"
      :buttonText="$t('myAccount.mainAccount.modal.buttonText')"
      @submit="setAsMainAccount"
      :loading="settingMainAccount"
      :noCancelButton="settingMainAccount"
    >
      <template>
        <br/><p>{{$t('myAccount.mainAccount.modal.text2')}}</p>
      </template>
    </ModalAlert>
  </div>
</template>

<script>
import Badge from "@/components/Badge.vue";
import ModalAlert from "@/components/ModalAlert.vue";
import TitleSteps from "@/components/TitleSteps.vue";
import PanelMyAccount from "@/components/PanelMyAccount.vue";
import AccountType from "@/components/AccountType.vue";
import GeneralInfo from "@/pages/MyAccountTabs/GeneralInfo";
import CashWithdrawal from "@/pages/MyAccountTabs/CashWithdrawal";
import AccountStatements from "@/pages/MyAccountTabs/AccountStatements";
import OperationalLimits from "@/pages/MyAccountTabs/OperationalLimits";
import Beneficiaries from "@/pages/MyAccountTabs/Beneficiaries";

import imageModalCardCheck from "@/assets/images/Icons/icon-card-check.svg";

import {mapGetters} from "vuex";
import {
  GET_ACCOUNT,
  SET_ACCOUNT,
  GET_ACCOUNT_DES,
  GET_USER,
  CHECK_PERMISSION,
  GET_MAIN_ACCOUNT,
  SET_MAIN_ACCOUNT,
  GET_PRODUCT_TYPE,
  STORE as sessionStore
} from "@/store/modules/session/session.types";
import {catchError, imageDownloadLink} from "@/utils/utils";
import {CARD_DEFAULT_IMG} from "@/enums/defalutCardImg.enum";
import {EventBus} from "@/main";
import CardsApi from "@/api/wallet/card.api";
import AccountsApi from '@/api/wallet/accounts.api';

export default {
  name: "MyAccount",
  components: {
    Badge,
    ModalAlert,
    TitleSteps,
    PanelMyAccount,
    AccountType,
    GeneralInfo,
    CashWithdrawal,
    AccountStatements,
    OperationalLimits,
    Beneficiaries,
  },
  computed: {
    ...mapGetters(sessionStore, {
      user: GET_USER,
      infoAccount: GET_ACCOUNT,
      isMainAccount: GET_MAIN_ACCOUNT,
      accountDes: GET_ACCOUNT_DES,
      productType: GET_PRODUCT_TYPE,
    }),
    accountImage() {
      const defaultImage = CARD_DEFAULT_IMG.pagandoExplore;
      if (!this.infoAccount || !this.infoAccount.template) {
        return defaultImage;
      }

      if (this.infoAccount.template.image) {
        return imageDownloadLink(this.infoAccount.template.image);
      }

      switch (this.infoAccount.template.product) {
        case "EXPLORE":
          return CARD_DEFAULT_IMG.pagandoExplore;
        case "EXPLORE_PLUS":
          return CARD_DEFAULT_IMG.pagandoExplorePlus;
        case "NIGHT":
          return CARD_DEFAULT_IMG.pagandoNight;
        case "BUSINESS":
          return CARD_DEFAULT_IMG.pagandoBusiness;
        case "BUSINESS_PRO":
          return CARD_DEFAULT_IMG.pagandoBusinessPro;
        default:
          return defaultImage;
      }
    },
    showCollaboratorsButton() {
      if(this.productType !== 'CLIENTS_ACCOUNT') {
        return false;
      }
      const checkPermission = this.$store.getters[`${sessionStore}/${CHECK_PERMISSION}`];
      return checkPermission('READ', 'COLLABORATORS');
    }
  },
  data() {
    return {
      imageModalCardCheck,

      settingMainAccount: false,
    }
  },
  methods: {
    goToUrl() {
      const x = setInterval(() => {
        const url = this.$route.query.url || "bankStatements";
        if (Object.prototype.hasOwnProperty.call(this.$refs, url)) {
          clearInterval(x);
          this.$refs[url].activate();
        }
      }, 1000);
    },
    async getOperationLimits() {
      if (this.infoAccount.account._id) {
        const res = await CardsApi.getOperationLimitsByAccount(this.infoAccount.account._id).catch(catchError);
        if (res.data && !res.data.error && res.data.object) {
          const limits = res.data.object.length ? res.data.object : [];
          EventBus.$emit("MyAccountOperationalLimits", limits);
        }
      }
    },
    async openMainAccountModal() {
      this.settingMainAccount = false;
      this.$bvModal.show('modal-ppalAccount');
    },
    async setAsMainAccount() {
      try {
        this.settingMainAccount = true;
        await AccountsApi.setMainAccount();
        this.$store.commit(`${sessionStore}/${SET_MAIN_ACCOUNT}`, true);
      } catch (error) {
        this.toastErrorDefault(error);
      }
      this.$bvModal.hide('modal-ppalAccount');
      this.settingMainAccount = false;
    },
  },
  beforeMount() {
    if (this.$route.query && this.$route.query.url) {
      this.goToUrl()
    }
    this.getOperationLimits();
    EventBus.$on(`${sessionStore}.${SET_ACCOUNT}`, this.getOperationLimits);
    EventBus.$on("MyAccount-open-bank-st", this.goToUrl);
  },
  beforeDestroy() {
    EventBus.$off("MyAccount-open-bank-st", this.goToUrl);
    EventBus.$off(`${sessionStore}.${SET_ACCOUNT}`, this.getOperationLimits);
  }
};
</script>
