const getContactColorClass = ({alias = '', contactName = ''}) => {
  const colors = [
    "colorError",
    "colorAccentDarker",
    "colorWarningDarker",
    "colorInfoBright",
    "colorPink",
    "colorTextInfo",
    "colorSuccess",
    "colorPrimaryBright",
    "colorInfoDarker",
  ];
  const colorIndex = (alias.length + contactName.length) % colors.length;
  return colors[colorIndex];
};

const getContactInitials = ({alias = ''}) => {
  return alias.match(/\b\w/g).slice(0, 2).join("").toUpperCase();
}

module.exports = {
  getContactColorClass,
  getContactInitials,
};
