import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'cms';

export default {
    getSections: () => {
        return axios.get(`${base.baseUrl}/${namespace}/current/wallet-services`)
    },
    getDataBySection: (language, section, params) => {
        return axios.get(`${base.baseUrl}/${namespace}/mobile/landing/wallet-services/${language}/${section}`, {
            params: params
        });
    }
}
