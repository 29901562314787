function toast(snotify, type, message, title) {
    const content = `
        <span class="icon">
            <i aria-hidden="true" class="banana banana-check-normal"></i>
        </span>
        <span>
            <div class="snotifyToast__title">${title || ''}</div>
            <div class="snotifyToast__body">
            <p>${message || ''}</p>
            </div>
        </span>
        <a class="close">
            <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </a>
    `;

    snotify.html(content, {
        timeout: 5000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        position: 'rightTop',
        type: type
    });
}

const GENERAL_ACTIONS_ERROR_TITLE = 'general.actions.error.title';

export default {
    methods: {
        displayNotificationHTML(message, title) {
            toast(this.$snotify, '', message, title);
        },
        displayNotificationSuccess(message, title) {
            toast(this.$snotify, 'success', message, title);
        },
        displayNotificationError(message, title) {
            toast(this.$snotify, 'error', message, title);
        },
        displayNotificationWarning(message, title) {
            toast(this.$snotify, 'warning', message, title);
        },
        displayNotificationInfo(message, title) {
            toast(this.$snotify, 'info', message, title);
        },
        toastSuccessDefault() {
            this.displayNotificationSuccess(this.$t('general.actions.success.text'), this.$t('general.actions.success.title'));
        },
        toastErrorDefault(error) {
            console.error(error);
            if (error && error.response && error.response.data && error.response.data.message) {
                this.displayNotificationError(error.response.data.message, this.$t(GENERAL_ACTIONS_ERROR_TITLE));
            } else if (typeof error === 'string') {
                this.displayNotificationError(error, this.$t(GENERAL_ACTIONS_ERROR_TITLE));
            } else {
                this.displayNotificationError(this.$t('general.actions.error.text'), this.$t(GENERAL_ACTIONS_ERROR_TITLE));
            }
        }
    }
}
