<template>
  <div class="radio-input" :class="clazz">
    <input ref="input"
           :class="inputClass"
           type="radio"
           :name="name"
           :id="idInput"
           aria-label="Radio button"
           @click="selected"
           v-model="radioInputValue"
           :disabled="disabled">
    <span class="role"
          :class="spanClazz"
          v-if="!isHiddenText"
          :for="idInput">{{ text }}</span>
    <slot></slot>
  </div>
</template>

<script>
import {EventBus} from "@/main";

export default {
  name: 'RadioInput',
  data() {
    return {
      radioInputValue: false
    }
  },
  props: {
    idInput: String,
    name: {type: String, default: 'role'},
    text: String,
    clazz: String,
    inputClass: String,
    isHiddenText: {
      type: Boolean, default: false
    },
    currentValue: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    spanClazz: String,
    findCurrentValue: {type: Boolean, default: false}
  },
  methods: {
    selected(event) {
      this.$emit('selected', event.target.value)
    },
    reloadInternalValue(value) {
      this.radioInputValue = value;
      if (this.radioInputValue) {
        this.$refs.input.checked = true;
      }
      this.$forceUpdate();
    },
    deselect(){
      this.radioInputValue = false;
      this.$refs.input.checked = false;
      this.$forceUpdate();
    },
    updateValue() {
      this.radioInputValue = this.currentValue;
      if (this.radioInputValue) {
        this.$refs.input.checked = true;
      }
    },
    searchValueWhenReady() {
      if (this.findCurrentValue) {
        this.$emit('search-current-value', this.idInput)
      }
    }
  },
  watch: {
    currentValue() {
      this.updateValue();
    }
  },
  beforeMount() {
    EventBus.$on(`radio-${this.idInput}`, this.reloadInternalValue);
    EventBus.$on(`radio-forceSearchValue`, this.searchValueWhenReady);
  },
  beforeDestroy() {
    EventBus.$off(`radio-${this.idInput}`, this.reloadInternalValue);
    EventBus.$off(`radio-forceSearchValue`, this.searchValueWhenReady);
  },
  mounted() {
    this.updateValue();
    this.searchValueWhenReady();
  }
}
</script>
