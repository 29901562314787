// PAGES
import newAccountBusiness from "@/pages/onboarding/businessRightSideSteps/newAccount";
import VerifyEmail from "@/pages/onboarding/rightSideSteps/VerifyEmail";
import VerificationEmailBusiness from "@/pages/onboarding/businessRightSideSteps/VerificationEmail";
import SelectSchemaBusiness from "@/pages/onboarding/businessRightSideSteps/SelectSchemaBusiness";
import SelectAccountCard from "@/pages/onboarding/rightSideSteps/SelectAccountCard";
import UsagePolicies from "@/pages/onboarding/rightSideSteps/UsagePolicies";
import BusinessStart from "@/pages/onboarding/businessRightSideSteps/BusinessStart";
import BusinessOperates from "@/pages/onboarding/businessRightSideSteps/OperateBusiness";
import FiscalData from "@/pages/onboarding/businessRightSideSteps/FiscalData";
import FiscalAddress from "@/pages/onboarding/businessRightSideSteps/FiscalAddress";
import CommercialAddress from "@/pages/onboarding/businessRightSideSteps/CommercialAddress";
import ShareholdersIdentification from "@/pages/onboarding/businessRightSideSteps/ShareholdersIdentification";
import Nationality from "@/pages/onboarding/rightSideSteps/Nationality";
import SelectDocument from "@/pages/onboarding/rightSideSteps/SelectDocument";
import UploadDocument from "@/pages/onboarding/rightSideSteps/UploadDocument";
import PersonalData from "@/pages/onboarding/rightSideSteps/PersonalData";
import Residence from "@/pages/onboarding/rightSideSteps/Residence";
import Occupation from "@/pages/onboarding/rightSideSteps/Occupation";
import AddInfo from "@/pages/onboarding/rightSideSteps/AddInfo";
import PhoneVerification from "@/pages/onboarding/rightSideSteps/PhoneVerification";
import CodeVerification from "@/pages/onboarding/rightSideSteps/CodeVerification";
import SuccessPhone from "@/pages/onboarding/rightSideSteps/SuccessPhone";
import SecurityMeasures from "@/pages/onboarding/rightSideSteps/SecurityMeasures";
import NewNip from "@/pages/onboarding/rightSideSteps/NewNip";
import ConfirmPin from "@/pages/onboarding/rightSideSteps/ConfirmPin";
import ProcessingRequest from "@/pages/onboarding/rightSideSteps/ProcessingRequest";
import SuccessRequest from "@/pages/onboarding/rightSideSteps/SuccessRequest";
import Contract from "@/pages/onboarding/rightSideSteps/Contract";
import Sign from "@/pages/onboarding/rightSideSteps/Sign";
import PromotionalCode from "@/pages/onboarding/rightSideSteps/PromotionalCode";
import OnbngWelcome from "@/pages/onboarding/rightSideSteps/OnbngWelcome";
import BusinessInfo from "@/pages/onboarding/businessRightSideSteps/BusinessInfo";
import Beneficiaries from "@/pages/onboarding/rightSideSteps/Beneficiaries";

// MIDDLEWARE
import hasNotUserSession from "@/middleware/hasNotUserSession";
import hasUserSession from "@/middleware/hasUserSession";
import isNotWalletUser from "@/middleware/isNotWalletUser";
import isWalletUser from "@/middleware/isWalletUser";


import Documents from "@/pages/onboarding/businessRightSideSteps/Documents";

export default [
    {
        path: '',
        name: "newAccountBusiness",
        component: newAccountBusiness,
        meta: {
            middleware: [hasNotUserSession]
        },
    },
    {
        path: 'verify-email/:email',
        name: "verifyEmailBusiness",
        component: VerifyEmail,
        meta: {
            middleware: [hasNotUserSession]
        },
    },
    {
        path: 'verification/:token',
        name: "verificationEmailBusiness",
        component: VerificationEmailBusiness,
        meta: {
            middleware: hasNotUserSession
        },
    },
    {
        path: 'select-schema',
        name: "selectSchemaBusiness",
        component: SelectSchemaBusiness,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'select-account',
        name: "selectAccountBusiness",
        component: SelectAccountCard,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'use-policy',
        name: "usagePoliciesBusiness",
        component: UsagePolicies,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'business-start',
        name: "businessStart",
        component: BusinessStart,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'business-nationality',
        name: "businessNationality",
        component: Nationality,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'select-documents',
        name: "businessSelectDocuments",
        component: SelectDocument,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'upload-documents/:id',
        name: "businessUploadDocuments",
        component: UploadDocument,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'personal-info',
        name: "businessPersonalInfo",
        component: PersonalData,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'residence',
        name: "businessResidence",
        component: Residence,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'occupation',
        name: "businessOccupation",
        component: Occupation,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'add-info',
        name: "businessAddInfo",
        component: AddInfo,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'phone',
        name: "businessPhoneVerification",
        component: PhoneVerification,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'phone-code',
        name: "businessCodeVerification",
        component: CodeVerification,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'success-code',
        name: "businessSuccessCode",
        component: SuccessPhone,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'security-measures',
        name: "businessSecurityMeasures",
        component: SecurityMeasures,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'new-pin',
        name: "businessNewPin",
        component: NewNip,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'confirm-pin',
        name: "businessConfirmPin",
        component: ConfirmPin,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'processing-request',
        name: "businessProcessingRequest",
        component: ProcessingRequest,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'response-request',
        name: "businessSuccessRequest",
        component: SuccessRequest,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'beneficiaries',
        name: "businessBeneficiaries",
        component: Beneficiaries,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'contract',
        name: "businessContract",
        component: Contract,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'sign',
        name: "businessSign",
        component: Sign,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'promotional-code',
        name: "businessPromotionalCode",
        component: PromotionalCode,
        meta: {
            middleware: [hasUserSession, isWalletUser]
        },
    },
    {
        path: 'welcome',
        name: "businessOnbngWelcome",
        component: OnbngWelcome,
        meta: {
            middleware: [hasUserSession, isWalletUser]
        },
    },

    // Moral
    {
        path: 'operates',
        name: "businessOperates",
        component: BusinessOperates,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'documents',
        name: "businessDocuments",
        component: Documents,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'business-info',
        name: "businessData",
        component: BusinessInfo,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'business-fiscal-data',
        name: "businessFiscalData",
        component: FiscalData,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'business-fiscal-address',
        name: "businessFiscalAddress",
        component: FiscalAddress,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'business-fiscal-c-address',
        name: "businessFiscalComAddress",
        component: CommercialAddress,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
    {
        path: 'business-shareholders',
        name: "businessShareholders",
        component: ShareholdersIdentification,
        meta: {
            middleware: [hasUserSession, isNotWalletUser]
        },
    },
];
