<template>
    <div class="type-account-floating" :class="clazz">
        <div>
            <div v-if="aliasText" class="alias-text" @click="$bvModal.show('modal-alias')" :class="clazzText">{{ aliasText }}</div>
            <span class="type-selected" :class="clazzAccount">
                <img v-if="explore" class="img-fluid" src="@/assets/images/Icons/Cuentas/icon-pagando-explore.svg" alt="Cuenta Pagando Explore"/>
                <img v-if="explorePlus" class="img-fluid" src="@/assets/images/Icons/Cuentas/icon-pagando-explore-plus.svg" alt="Cuenta Pagando Explore Plus"/>
                <img v-if="night" class="img-fluid" src="@/assets/images/Icons/Cuentas/icon-pagando-night.svg" alt="Cuenta Pagando Night"/>
                <img v-if="business" class="img-fluid" src="@/assets/images/Icons/Cuentas/icon-pagando-business.svg" alt="Cuenta Pagando Business"/>
                {{typeAccount}}
            </span>
            <slot name="extraText"></slot>
        </div>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'AccountType',
        props: {
            clazz: String,
            clazzText: String,
            aliasText: String,
            text: String,
            clazzAccount: String,
            typeAccount: String,
            explore: {type: Boolean, default: false},
            explorePlus: {type: Boolean, default: false},
            night: {type: Boolean, default: false},
            business: {type: Boolean, default: false},
        },
        methods: {}
    }
</script>
