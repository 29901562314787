<template>
  <div>
    <CardSimple
        :title="$t('myAccount.generalInfo.title')"
        :subtitle="$t('myAccount.generalInfo.subtitle')">
      <div class="row">
        <div class="col-12 col-md-6 col-lg-4">
          <ReadOnlyInfo
              :title="$t('myAccount.generalInfo.noCuenta')"
              :text="infoAccount.account.fullKey"
          ></ReadOnlyInfo>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <ReadOnlyInfo
              :title="$t('general.CLABE')"
              :text="infoAccount.account.providerCLABE"
          ></ReadOnlyInfo>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <ReadOnlyInfo
              :title="$t('myAccount.generalInfo.accountType')"
              :text="$t('myAccount.generalInfo.accountTypeDes')"
          ></ReadOnlyInfo>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <ReadOnlyInfo
              :title="$t('myAccount.generalInfo.cutoffDate')"
              :text="cutoffDate"
          ></ReadOnlyInfo>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-show="hasVirtualCard">
          <ReadOnlyInfo
              :title="$t('myAccount.generalInfo.virtualCard')"
              :text="virtualCard"
          ></ReadOnlyInfo>
        </div>
        <div class="col-12 col-md-6 col-lg-4" v-show="hasPhysicalCard">
          <ReadOnlyInfo
              :title="$t('myAccount.generalInfo.physicCard')"
              :text="physicalCard"
          ></ReadOnlyInfo>
        </div>
      </div>
    </CardSimple>
    <div class="row">
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
            icon="iconsax-content-archive-book"
            :titleText="$t('myAccount.generalInfo.modals.contrato.title_card')"
            :text="$t('myAccount.generalInfo.modals.contrato.subtitle_card')">
          <a
              class="c-pointer"
              tabindex=""
              aria-label="Contrato"
              @click="openModalContract">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
            icon="iconsax-content-task-square"
            :titleText="$t('myAccount.generalInfo.modals.termsCond.title_card')"
            :text="$t('myAccount.generalInfo.modals.termsCond.subtitle_card')">
          <a
              class="c-pointer"
              tabindex=""
              aria-label="Términos y condiciones"
              @click="openModalTermsCond">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
            icon="iconsax-security-lock-circle"
            :titleText="$t('myAccount.generalInfo.modals.privacy.title_card')"
            :text="$t('myAccount.generalInfo.modals.privacy.subtitle_card')">
          <a
              class="c-pointer"
              tabindex=""
              aria-label="Aviso de privacidad"
              @click="openModalPrivacy">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
      <!-- CAMBIAR NIP DE SEGURIDAD -->
      <!-- <div class="col-12 col-lg-6">
        <PanelLinkGoTo
            icon="iconsax-security-key-square"
            titleText="Cambiar NIP de seguridad"
            text="Cambia el NIP de tu cuenta">
          <a  href=""
              class="c-pointer"
              tabindex=""
              aria-label="Aviso de privacidad">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div> -->
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
            icon="iconsax-money-currency-square"
            :titleText="$t('myAccount.generalInfo.modals.commissions.title_card')"
            :text="$t('myAccount.generalInfo.modals.commissions.subtitle_card')">
          <a
              class="c-pointer"
              tabindex=""
              aria-label="Comisiones"
              @click="openModalCommissions">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
            icon="iconsax-essential-info-circle"
            :titleText="$t('myAccount.generalInfo.modals.addInfo.title_card')"
            :text="$t('myAccount.generalInfo.modals.addInfo.subtitle_card')">
          <a
              class=""
              tabindex=""
              aria-label="Información adicional"
              @click="openModalAddInfo">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
            icon="iconsax-money-card-remove"
            titleText="Cancelar cuenta"
            text="Cancelar mi cuenta Pagando">
          <a
              class=""
              tabindex=""
              aria-label="Cancelar cuenta"
              @click="$bvModal.show('modal-delete-account')">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
      <div class="col-12 col-lg-6">
        <PanelLinkGoTo
            icon="iconsax-money-card-remove"
            :titleText="$t('myAccount.generalInfo.cards.limits.title')"
            :text="$t('myAccount.generalInfo.cards.limits.subtitle')">
          <a
              class=""
              tabindex=""
              aria-label="Cancelar cuenta"
              @click="goToLimits">
            <i aria-hidden="true" class="iconsax-arrow-circle-right"></i>
          </a>
        </PanelLinkGoTo>
      </div>
    </div>

    <!-- MODAL CONTRATO CUENTA -->
    <b-modal
        id="modal-contrato-cuenta"
        size="lg"
        hide-header
        hide-footer>
      <button class="close-modal" @click="$bvModal.hide('modal-contrato-cuenta')" aria-label="Boton cerrar ventana"
              name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="modal-terminos-privacidad">
        <h2 class="title">
          {{ $t('myAccount.generalInfo.modals.contrato.title') }}
        </h2>
        <span class="date-update">
                    <small>{{ $t('myAccount.generalInfo.modals.lastUpdate') }}</small>
                    {{ getUpdateAt(contract) }}
                </span>
        <div v-if="loadModal" class="text-container">
          <loading-bar :loadingText="$t('myAccount.generalInfo.modals.loadInfo')"></loading-bar>
        </div>
        <div v-else class="text-container" v-html="contract ? contract.documentAcceptedContent: ''">
        </div>
        <button :disabled="contract === null" v-if="showDownload"
                id="contractDownload" type="button" class="button button-filled button-primary"
                @click="downloadFile(contract ? contract.documentPDF : '', 'contract')">
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          {{ $t('myAccount.generalInfo.modals.download') }}
        </button>
        <button v-else :id="'Loading button'" class="button button-filled button-primary">
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          <LoadingSimple clazz="small-load no-text"/>
        </button>
      </div>
    </b-modal>

    <!-- MODAL TERMINOS -->
    <b-modal
        id="modal-terminos"
        size="lg"
        hide-header
        hide-footer>
      <button class="close-modal" @click="$bvModal.hide('modal-terminos')" aria-label="Boton cerrar ventana"
              name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="modal-terminos-privacidad">
        <h2 class="title">
          {{ $t('myAccount.generalInfo.modals.termsCond.title') }}
        </h2>
        <span class="date-update">
                    <small>{{ $t('myAccount.generalInfo.modals.lastUpdate') }}</small>
                    {{ getUpdateAt(termsCond) }}
                </span>
        <div v-if="loadModal" class="text-container">
          <loading-bar :loadingText="$t('myAccount.generalInfo.modals.loadInfo')"></loading-bar>
        </div>
        <div v-else class="text-container" v-html="termsCond ? termsCond.documentAcceptedContent: ''">
        </div>
        <button :disabled="termsCond === null" v-if="showDownload"
                id="TermsDownload" type="button" class="button button-filled button-primary"
                @click="downloadFile(termsCond ? termsCond.documentPDF : '', 'términos y condiciones')">
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          {{ $t('myAccount.generalInfo.modals.download') }}
        </button>
        <button v-else :id="'Loading button term'" class="button button-filled button-primary">
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          <LoadingSimple clazz="small-load no-text"/>
        </button>
      </div>
    </b-modal>

    <!-- MODAL AVISO -->
    <b-modal
        id="modal-privacidad"
        size="lg"
        hide-header
        hide-footer>
      <button class="close-modal" @click="$bvModal.hide('modal-privacidad')" aria-label="Boton cerrar ventana"
              name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="modal-terminos-privacidad">
        <h2 class="title">
          {{ $t('myAccount.generalInfo.modals.privacy.title') }}
        </h2>
        <span class="date-update">
                    <small>{{ $t('myAccount.generalInfo.modals.lastUpdate') }}</small>
                    {{ getUpdateAt(privacy) }}
                </span>
        <div v-if="loadModal" class="text-container">
          <loading-bar :loadingText="$t('myAccount.generalInfo.modals.loadInfo')"></loading-bar>
        </div>
        <div v-else class="text-container" v-html="privacy ? privacy.documentAcceptedContent: ''">
        </div>
        <button :disabled="privacy === null" v-if="showDownload"
                id="privacyDownload" type="button" class="button button-filled button-primary"
                @click="downloadFile(privacy ? privacy.documentPDF : '', 'aviso de privacidad')">
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          {{ $t('myAccount.generalInfo.modals.download') }}
        </button>
        <button v-else :id="'Loading button privacy'" class="button button-filled button-primary">
          <i aria-hidden="true" class="iconsax-content-document-download m-r-10"></i>
          <LoadingSimple clazz="small-load no-text"/>
        </button>
      </div>
    </b-modal>

    <!-- MODAL COMISIONES -->
    <b-modal
        id="modal-comisiones"
        size="lg"
        hide-header
        hide-footer
        modal-class="modal-default left-oriented">
      <button class="close-modal"
              @click="$bvModal.hide('modal-comisiones')"
              aria-label="Boton cerrar ventana"
              name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{ $t('myAccount.generalInfo.modals.commissions.title') }}</h2>
        <p class="modal-subtitle">
          {{ $t('myAccount.generalInfo.modals.commissions.subtitle') }}
        </p>
      </div>
      <div class="body">
        <div v-if="loadModal" class="text-container">
          <loading-bar :loadingText="$t('myAccount.generalInfo.modals.loadInfo')"></loading-bar>
        </div>
        <div v-else class="row">
          <div class="col-12 col-md-4" v-for="comObj in commissions" v-bind:key="comObj._id">
            <ReadOnlyInfo
                :title="$t(comObj.title)"
                :text="comObj.cost | currency"
                v-if="comObj.events !== 'CLIENT_DISPERSION'"
            >
            <template v-if="comObj.freeEvents">
                <p class="f-10">
                  {{$tc(`myAccount.generalInfo.modals.commissions.freeEvents.${comObj.period}`, comObj.quantity, comObj)}}
                </p>
              </template>
            </ReadOnlyInfo>
            <ReadOnlyInfo
                :title="$t(comObj.title)"
                :text="`${comObj.cost}% + IVA`"
                v-if="comObj.events === 'CLIENT_DISPERSION'"
            >
              <template v-if="comObj.freeEvents">
                <p class="f-10">
                  {{$tc(`myAccount.generalInfo.modals.commissions.freeEvents.${comObj.period}`, comObj.quantity, comObj)}}
                </p>
              </template>
            </ReadOnlyInfo>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- MODAL INFO ADICIONAL -->
    <b-modal
        id="modal-info-adicional"
        size="lg"
        hide-header
        hide-footer
        modal-class="modal-default left-oriented">
      <button class="close-modal"
              @click="$bvModal.hide('modal-info-adicional')"
              aria-label="Boton cerrar ventana"
              name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{ $t('myAccount.generalInfo.modals.addInfo.title') }}</h2>
        <p class="modal-subtitle">
          {{ $t('myAccount.generalInfo.modals.addInfo.subtitle') }}
        </p>
      </div>
      <div class="body">
        <div v-if="loadModal">
          <loading-bar :loadingText="$t('myAccount.generalInfo.modals.loadInfo')"></loading-bar>
        </div>
        <div v-else>
          <b-tabs
              class="simple-tabs"
              content-class="simple-tabs-body p-t-30 p-b-30">
            <b-tab :title="$t('myAccount.generalInfo.modals.addInfo.responsibilities')" active>
              <ul class="checked-list-primary">
                <li v-for="(res, index) in addInfo.responsibilities" v-bind:key="index">
                  {{ res }}
                </li>
              </ul>
            </b-tab>
            <b-tab :title="$t('myAccount.generalInfo.modals.addInfo.risks')">
              <ul class="checked-list-primary">
                <li v-for="(risk, index) in addInfo.risks" v-bind:key="index">
                  {{ risk }}
                </li>
              </ul>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </b-modal>

    <!-- MODAL CANCELAR CUENTA -->
    <b-modal
        id="modal-delete-account"
        size=""
        hide-header
        hide-footer
        modal-class="modal-default left-oriented">
      <button class="close-modal"
              @click="$bvModal.hide('modal-delete-account')"
              aria-label="Boton cerrar ventana"
              name="Boton cerrar ventana">
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{ $t('myAccount.generalInfo.modals.cancelAccount.title', {account: accountDes}) }}</h2>
        <p class="modal-subtitle">
          {{ $t('myAccount.generalInfo.modals.cancelAccount.subtitle') }}
        </p>
      </div>
      <div class="body">
        <ul class="checked-list-primary">
          <li>{{ $t('myAccount.generalInfo.modals.cancelAccount.li1') }}</li>
          <li>{{ $t('myAccount.generalInfo.modals.cancelAccount.li2') }}</li>
          <li>{{ $t('myAccount.generalInfo.modals.cancelAccount.li3') }}</li>
          <li>{{ $t('myAccount.generalInfo.modals.cancelAccount.li4') }}</li>
          <li>{{ $t('myAccount.generalInfo.modals.cancelAccount.li5') }}</li>
        </ul>
        <b-alert class="alert-note" show variant="info m-t-30">
          <p class="text-container">
            <i
                aria-hidden="true"
                class="icon iconsax-emails-message-bold"
            ></i>
            <span>
                      <strong class="text-upper">{{
                          $t('myAccount.generalInfo.modals.cancelAccount.remember')
                        }}</strong>
              {{$t('myAccount.generalInfo.modals.cancelAccount.messageDelete' , {account: accountDes})}}
                        </span>
          </p>
        </b-alert>
        <p class="f-11 c-text_title poppins-font-bold f-700 m-b-0 text-center"
           v-html="$t('myAccount.generalInfo.modals.cancelAccount.pagandoContact', {commission: commissionCancel})">
        </p>
      </div>
      <div class="footer">
        <button type="button" class="button button-filled button-primary w-100" @click="$bvModal.hide('modal-delete-account')">
          {{$t('myAccount.generalInfo.modals.cancelAccount.keepAccount')}}
        </button>
      </div>
    </b-modal>

  </div>
</template>

<script>
import CardSimple from "@/components/CardSimple";
import ReadOnlyInfo from "@/components/ReadOnlyInfo";
import PanelLinkGoTo from "@/components/PanelLinkGoTo";
import {mapGetters} from "vuex";
import {GET_ACCOUNT, GET_ACCOUNT_DES, SET_ACCOUNT, STORE as sessionStore} from "@/store/modules/session/session.types";
import {catchError, lastDateMonth, getMonthByNo} from "@/utils/utils";
import {EventBus} from "@/main";
import DocumentsApi from "@/api/core/documents.api";
import AccountTypesApi from "@/api/core/accountTypes.api";
import CardTemplateApi from "@/api/core/cardTemplate.api";
import FileApi from "@/api/core/files.api";
import LoadingBar from "@/components/LoadingBar";
import LoadingSimple from "@/components/LoadingSimple";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import CardApi from "@/api/wallet/card.api";

const CARD_TYPES = {
  VIRTUAL: 'VIRTUAL',
  PHYSIC: 'PHYSIC'
};

const COMMISSIONS_EVENTS = {
  ACCOUNT_OPENING: "ACCOUNT_OPENING",
  CARD_REQUEST: "CARD_REQUEST",
  BALANCE_INQUIRY: "BALANCE_INQUIRY",
  SPEI_TRANSFER: "SPEI_TRANSFER",
  SPEI_INCOME: "SPEI_INCOME",
  PAGANDO_TRANSFER: "PAGANDO_TRANSFER",
  CASH_COMMERCE: "CASH_COMMERCE",
  CASH_ATM: "CASH_ATM",
  CASH_INFLOW: "CASH_INFLOW",
  RESOURCERS_ABROAD: "RESOURCERS_ABROAD",
  CARD_REPLACEMENT: "CARD_REPLACEMENT",
  CANCEL_ACCOUNT: "CANCEL_ACCOUNT",
  MONTHLY_SUBSCRIPTION: "MONTHLY_SUBSCRIPTION",
  CLIENT_DISPERSION: "CLIENT_DISPERSION"
}

export default {
  name: "GeneralInfo",
  mixins: [ToastSnotify],
  components: {
    CardSimple,
    ReadOnlyInfo,
    PanelLinkGoTo,
    LoadingBar,
    LoadingSimple
  },
  data() {
    return {
      hasVirtualCard: false,
      hasPhysicalCard: false,
      virtualCard: "",
      physicalCard: "",
      cutoffDate: "",
      loadModal: false,
      showDownload: true,
      contract: null,
      termsCond: null,
      privacy: null,
      commissions: [],
      addInfo: {
        risks: [],
        responsibilities: []
      },
      commissionCancel: 0
    }
  },
  computed: {
    ...mapGetters(sessionStore, {
      infoAccount: GET_ACCOUNT,
      accountDes: GET_ACCOUNT_DES
    }),
  },
  methods: {
    getCutoffDate() {
      const lastBill = this.infoAccount.account.lastBillingDate;
      if (lastBill && !isNaN(Number(lastBill))) {
        let day = new Date(Number(lastBill)).getDate();
        if (day >= 29) {
          const today = new Date();
          const lastDate = lastDateMonth(today.getMonth(), today.getFullYear());
          day = lastDate.getDate();
        }
        this.cutoffDate = this.$i18n.t("myAccount.generalInfo.billingDate.everyMonth", {day: day});
      } else {
        this.cutoffDate = this.$i18n.t("myAccount.generalInfo.billingDate.entMonth");
      }
    },
    getVirtualCard() {
      const cardNumber = this.getCardType(CARD_TYPES.VIRTUAL);
      if (cardNumber) {
        this.hasVirtualCard = true;
        this.virtualCard = `**** **** **** ${cardNumber.card.last4}`;
      }
    },
    getPhysicalCard() {
      const cardNumber = this.getCardType(CARD_TYPES.PHYSIC);
      if (cardNumber) {
        this.hasPhysicalCard = true;
        this.physicalCard = `**** **** **** ${cardNumber.card.last4}`;
      }
    },
    /**
     * Busca si existe una tarjeta dependiendo del tipo
     * @param manufacturingType
     */
    getCardType(manufacturingType) {
      if (this.infoAccount.cards.length) {
        const cardNumber = this.infoAccount.cards.find((c) => c?.card?.manufacturingType === manufacturingType);
        if (cardNumber) {
          return cardNumber;
          // return `**** **** **** ${cardNumber.card.last4}`;
        }
      }
      return false;
    },
    getUpdateAt(doc) {
      let date;
      if (doc && doc.updatedAt) {
        try {
          date = new Date(doc.updatedAt);
        } catch (err) {
          date = new Date();
        }
      } else {
        date = new Date();
      }
      return `${date.getDate()} ${this.$i18n.t(getMonthByNo(date.getMonth()))} ${date.getFullYear()}`;
    },
    async openModalContract() {
      this.loadModal = true;
      this.$bvModal.show('modal-contrato-cuenta');
      if (!this.contract) {
        this.contract = await this.getDocument("CONTRACT");
      }
      this.loadModal = false;
    },
    async openModalTermsCond() {
      this.loadModal = true;
      this.$bvModal.show('modal-terminos')
      if (!this.termsCond) {
        this.termsCond = await this.getDocument("TERMS_CONDITIONS");
      }
      this.loadModal = false;
    },
    async openModalPrivacy() {
      this.loadModal = true;
      this.$bvModal.show('modal-privacidad');
      if (!this.privacy) {
        this.privacy = await this.getDocument("PRIVACY_AGREEMENT");
      }
      this.loadModal = false;
    },
    async openModalCommissions() {
      this.loadModal = true;
      this.$bvModal.show('modal-comisiones');
      if (!this.commissions.length) {
        const accountId = this.infoAccount.account.accountType ? this.infoAccount.account.accountType._id : "";
        const res = await AccountTypesApi.getCommissions(accountId).catch(catchError);
        if (res.data && !res.data.error) {
          const commissions = res.data.object
          for (const commission of commissions) {
            const obj = {
              id: commission._id,
              cost: commission.cost,
              freeEvents: commission.freeEvents,
              quantity: commission.quantity,
              period: commission.period,
              events: commission.events
            }
            switch (commission.events) {
              case COMMISSIONS_EVENTS.CARD_REQUEST:
                obj.title = "myAccount.generalInfo.modals.commissions.events.card_request";
                break;
              case COMMISSIONS_EVENTS.BALANCE_INQUIRY:
                obj.title = "myAccount.generalInfo.modals.commissions.events.balance_inquiry";
                break;
              case COMMISSIONS_EVENTS.SPEI_TRANSFER:
                obj.title = "myAccount.generalInfo.modals.commissions.events.spei_transfer";
                break;
              case COMMISSIONS_EVENTS.SPEI_INCOME:
                obj.title = "myAccount.generalInfo.modals.commissions.events.spei_income";
                break;
              case COMMISSIONS_EVENTS.PAGANDO_TRANSFER:
                obj.title = "myAccount.generalInfo.modals.commissions.events.pagando_transfer";
                break;
              case COMMISSIONS_EVENTS.CASH_COMMERCE:
                obj.title = "myAccount.generalInfo.modals.commissions.events.cash_commerce";
                break;
              case COMMISSIONS_EVENTS.CASH_ATM:
                obj.title = "myAccount.generalInfo.modals.commissions.events.cash_atm";
                break;
              case COMMISSIONS_EVENTS.CASH_INFLOW:
                obj.title = "myAccount.generalInfo.modals.commissions.events.cash_inflow";
                break;
              case COMMISSIONS_EVENTS.RESOURCERS_ABROAD:
                obj.title = "myAccount.generalInfo.modals.commissions.events.resourcers_abroad";
                break;
              case COMMISSIONS_EVENTS.CARD_REPLACEMENT:
                obj.title = "myAccount.generalInfo.modals.commissions.events.card_replacement";
                break;
              case COMMISSIONS_EVENTS.CANCEL_ACCOUNT:
                obj.title = "myAccount.generalInfo.modals.commissions.events.cancel_account";
                break;
              case COMMISSIONS_EVENTS.MONTHLY_SUBSCRIPTION:
                obj.title = "myAccount.generalInfo.modals.commissions.events.monthly_subscription";
                break;
              case COMMISSIONS_EVENTS.ACCOUNT_OPENING:
                obj.title = "myAccount.generalInfo.modals.commissions.events.account_opening";
                break;
              case COMMISSIONS_EVENTS.CLIENT_DISPERSION:
                obj.title = "myAccount.generalInfo.modals.commissions.events.dispersion_layout";
                break;
              default:
                obj.title = ""
            }
            this.commissions.push(obj);
          }
        } else {
          this.displayNotificationWarning(this.$i18n.t('myAccount.generalInfo.modals.commissions.error'))
        }
      }
      this.loadModal = false;
    },
    async openModalAddInfo() {
      this.loadModal = true;
      this.$bvModal.show('modal-info-adicional')
      if (!this.addInfo.risks.length || !this.addInfo.responsibilities.length) {
        const templateId = this.infoAccount.template._id;
        const accountType = this.infoAccount.template.accountType.productType;
        const res = await CardTemplateApi.getAddInfo(templateId, {accountType: accountType}).catch(catchError);
        if (res.data && !res.data.error) {
          const template = res.data.object;
          if (template) {
            this.addInfo.risks = template.risks[this.$i18n.locale];
            this.addInfo.responsibilities = template.responsabilities[this.$i18n.locale];
          }
        } else {
          this.displayNotificationWarning(this.$i18n.t('myAccount.generalInfo.modals.addInfo.error'))
        }
      }
      this.loadModal = false;
    },
    async getDocument(type) {
      this.loadModal = true;
      const response = await DocumentsApi.findDocumentAccepted({
        type: type,
        appliesTo: "WALLET",
        discriminators: [this.infoAccount.account.accountType._id]
      }).catch(catchError)
      if (response.data && !response.data.error) {
        return response.data.object;
      }
      this.displayNotificationWarning(this.$i18n.t('myAccount.generalInfo.modals.errorFind'))
      this.loadModal = false;
      return null;
    },
    async downloadFile(id, name) {
      this.showDownload = false;
      const response = await FileApi.downloadSignedUrl({id: id}).catch(catchError);
      if (response && response.data) {
        const link = document.createElement("a");
        const url = response.data.object;
        link.setAttribute("href", url);
        link.setAttribute("download", name);
        link.click();
      } else {
        this.displayNotificationWarning(this.$i18n.t('myAccount.generalInfo.modals.errorFind'))
      }
      this.showDownload = true;
    },
    async getCommission() {
      if (this.infoAccount.account._id) {
        try {
          const response = await CardApi.getCommissionForEvent({
            pagandoAccount: this.infoAccount.account._id,
            commission: "CANCEL_ACCOUNT",
          });
          if (response.data.error) {
            this.commissionCancel = 0;
          } else {
            this.commissionCancel = parseFloat(response.data.object);
          }
        } catch (error) {
          this.toastErrorDefault(error);
        }
      }
    },
    goToLimits() {
      this.$router.push({name: 'MyAccountLimits'});
    },
    initData() {
      this.getCutoffDate();
      this.getVirtualCard();
      this.getPhysicalCard();
      this.getCommission();
    }
  },
  beforeMount() {
    this.initData();
    EventBus.$on(`${sessionStore}.${SET_ACCOUNT}`, this.initData);
  },
  beforeDestroy() {
    EventBus.$off(`${sessionStore}.${SET_ACCOUNT}`, this.initData);
  }
}
</script>

<style scoped>

</style>
