<template>
    <div>
        <div id="" class="responsive-top-bar">
            <span>
                <button type="button"
                    id="showLeftMenu"
                    class="button-square xs button-filled button-primary"
                    aria-label="Mostrar menú principal"
                    name="Mostrar menú principal"
                    @click="showMenu">
                    <i aria-hidden="true" class="iconsax-essential-menu"></i>
            </button>
            </span>
            <span>
                <img
                    class="img-fluid"
                    src="@/assets/images/Logos/logo-color.svg"
                    width="114"
                    height="25"
                    alt="Pagando Logo"
                />
            </span>
            <span></span>
        </div>

        <SidebarMenuPrincipal :clazz="[isMenuOpen ? 'show': '']">
            <template v-slot:buttonClose>
                <button type="button"
                        class="button-close-menu"
                        aria-label="Esconder menú principal"
                        name="Esconder menú principal"
                        @click="hideMenu">
                        <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                </button>
            </template>
        </SidebarMenuPrincipal>
        <div class="sidebar-menu-backdrop menu-principal"></div>

        <section id="main-view" class="p-r-0">
            <div class="page-container content-centered">
                <div>
                    <div class="row">
                        <div class="col-12">
                            <router-link
                                    to="/additional-cards"
                                    class="button xs button-ghost button-text_info p-l-0 p-r-0"
                                    tabindex="">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left f-18 c-info m-r-10"></i>
                                    {{$t('additionalCards.create.goBack')}}
                            </router-link>
                            <FloatingTitleLayout v-if="!isEdit"
                                :titleStrong="$t('additionalCards.create.title')">
                                {{$t('additionalCards.create.subtitle')}}
                                <strong class="c-text_title poppins-font-bold">{{accountDescription}}</strong>
                            </FloatingTitleLayout>
                            <FloatingTitleLayout v-if="isEdit"
                                :titleStrong="$t('additionalCards.edit.title')">
                                {{$t('additionalCards.edit.subtitle1')}}
                                <strong class="c-text_title poppins-font-bold">{{accountDescription}}</strong>.
                                {{$t('additionalCards.edit.subtitle2')}}
                            </FloatingTitleLayout>
                        </div>

                        <LoadingBar v-if="loading"
                            :loadingText="isCancel ? $t('additionalCards.edit.loadingCancel') : isEdit ?
                                $t('additionalCards.edit.loading') : $t('additionalCards.create.loading')"></LoadingBar>

                        <div class="col-12" v-if="!loading">
                            <CardSimple
                                clazz="p-b-0"
                                :title="!isEdit ? $t('additionalCards.create.title2') : $t('additionalCards.edit.title')"
                                :subtitle="!isEdit ? $t('additionalCards.create.subtitle2') :
                                    $t('additionalCards.edit.subtitle3')">
                                <div class="row">
                                    <div class="col-12 col-lg-6">
                                        <BasicInput
                                            inputId=""
                                            :label="$t('additionalCards.alias.title')"
                                            :inputPlaceholder="$t('additionalCards.alias.placeholder')"
                                            :warningText="$t('additionalCards.alias.warning')"
                                            :maxCount="32"
                                            :max-length="32"
                                            v-model="alias"
                                            inputClazz="p-r-50">
                                        </BasicInput>
                                    </div>
                                    <div class="col-12 col-lg-6 d-flex align-items-c justify-space-between column-gap-10px m-b-30">
                                        <AccountType
                                            clazz="p-relative imgLeft m-0-auto-0-0">
                                            <img class="img-fluid" :src="accountImage" width="80" height="50" :alt="accountDescription"/>
                                            <template v-slot:extraText>
                                                <label class="f-12 c-info poppins-font-semibold m-b-0">{{getAlias()}}</label>
                                                <p class="f-12 c-text_title poppins-font-regular m-b-0">{{getProductName()}}</p>
                                                <strong class="f-14 c-text_title poppins-font-bold m-b-0">{{getNumberCard()}}</strong>
                                            </template>
                                        </AccountType>
                                        <button v-if="isEdit && !card.card.default"
                                            @click="$bvModal.show('modal-cancel-card')"
                                            id="tooltip-button-cancelar"
                                            type="button"
                                            class="button-circle button-ghost button-error"
                                            name="Cancelar tarjeta"
                                            aria-label="Cancelar tarjeta">
                                            <i aria-hidden="true" class="iconsax-essential-trash"></i>
                                        </button>
                                        <b-tooltip
                                            target="tooltip-button-cancelar"
                                            placement="top">
                                            <p class="m-b-0">
                                                {{$t('additionalCards.edit.cancelCard')}}
                                            </p>
                                        </b-tooltip>
                                    </div>
                                </div>
                            </CardSimple>
                        </div>

                        <div class="col-12" v-if="!loading">
                            <b-alert class="alert-note" show variant="info" v-if="isEdit && commission">
                                <p class="text-container">
                                    <i
                                        aria-hidden="true"
                                        class="icon iconsax-emails-message"
                                        ></i>
                                    <span v-html="$t('additionalCards.edit.qtyCardsMessage', {qty: commission.quantity, account: accountDescription})">
                                    </span>
                                </p>
                            </b-alert>
                            <b-alert class="alert-note" show variant="info" v-if="!isEdit && commission">
                                <p class="text-container">
                                    <i
                                        aria-hidden="true"
                                        class="icon iconsax-emails-message"
                                        ></i>
                                    <span v-html="$t('additionalCards.create.qtyCardsMessage',
                                        {qty: commission.quantity, account: accountDescription, extraCost: getCommissionCost()})">
                                    </span>
                                </p>
                            </b-alert>
                        </div>

                        <div class="col-12" v-if="!loading">
                            <div class="control-buttons">
                                <button
                                    @click="goBack()"
                                    type="button"
                                    class="button-icon">
                                    <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
                                    {{$t('additionalCards.back')}}
                                </button>
                                <button
                                    @click="save()"
                                    type="button"
                                    class="button button-filled button-primary"
                                    :disabled="!disabledButton">
                                    {{!isEdit ? $t('additionalCards.create.button') : $t('additionalCards.edit.button')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <ModalAlert
            modalId="modal-cancel-card"
            buttonType="button-primary"
            :imgUrl="imageCancel"
            :title="$t('additionalCards.edit.modalCancel.title')"
            :text="$t('additionalCards.edit.modalCancel.subtitle')"
            :buttonCancel="$t('additionalCards.edit.modalCancel.button1')"
            :buttonText="$t('additionalCards.edit.modalCancel.button2')"
            @submit="cancelCard()"
        ></ModalAlert>

        <FooterPage></FooterPage>
        <vue-snotify></vue-snotify>
    </div>
</template>
<script>
    // @ is an alias to /src
    import SidebarMenuPrincipal from "@/components/SidebarMenuPrincipal.vue";
    import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
    import FooterPage from "@/components/FooterPage.vue";
    import AccountType from "@/components/AccountType.vue";
    import CardSimple from "@/components/CardSimple.vue";
    import BasicInput from "@/components/BasicInput.vue";
    import ModalAlert from "@/components/ModalAlert.vue";
    import LoadingBar from "@/components/LoadingBar.vue";
    import {mapGetters} from "vuex";
    import {
        STORE as sessionStore,
        GET_ACCOUNT
    } from "@/store/modules/session/session.types";
    import {CARD_DEFAULT_IMG} from "@/enums/defalutCardImg.enum";
    import { imageDownloadLink, catchError } from '@/utils/utils';
    import ToastSnotify from "@/mixins/toastSnotify.mixin";
    import CardApi from '@/api/wallet/card.api';
    import imageCancel from "@/assets/images/Icons/icon-trash-pink.svg";

    export default {
        name: "AdditionalCardNew",
        mixins: [ToastSnotify],
        components: {
            SidebarMenuPrincipal,
            FloatingTitleLayout,
            FooterPage,
            AccountType,
            CardSimple,
            BasicInput,
            LoadingBar,
            ModalAlert
        },
        props: {
            card: {}
        },
        data() {
            return {
                // SidebarRight / MenuPrincipal
                window: {
                    width: 0
                },
                isMenuOpen: false,
                isEdit: false,
                alias: '',
                loading: false,
                imageCancel,
                isCancel: false
            }
        },
        computed: {
            ...mapGetters(sessionStore, {
                account: GET_ACCOUNT
            }),
            accountDescription() {
                if(this.account && this.account.account && this.account.account.accountType) {
                    return this.account.account.accountType.description;
                }
                return null;
            },
            commission() {
                if(this.account && this.account.account && this.account.account.accountType) {
                    const commissions = this.account.account.accountType.commissions;

                    return commissions.find(c => c.events === "VIRTUAL_ADDITIONAL_COST");
                }

                return null;
            },
            accountImage() {

                if(!this.isEdit) {
                    const originCard = this.account.cards.find(c => c.card.default && c.card.manufacturingType === 'VIRTUAL');
                    return this.getImage(originCard);

                }
                return this.getImage(this.card);
            },
            disabledButton() {
                if(this.isEdit) {
                    return this.card.card.alias !== this.alias && this.alias && this.alias.length >= 3 && this.alias.length <= 32;
                }
                return this.alias && this.alias.length >= 3 && this.alias.length <= 32;
            },
            currency() {
                return this.$options.filters.currency;
            }
        },
        methods: {
            // Resize Sidebar Menu Principal
            handleResizeMenu () {
                this.window.width = window.innerWidth;
                if (window.innerWidth > 767) {
                    this.isMenuOpen = true;
                } else {
                    this.isMenuOpen = false;
                }
            },
            // Show Sidebar Menu Principal
            showMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            // Hide Sidebar Menu Principal
            hideMenu () {
                this.isMenuOpen = !this.isMenuOpen;
            },
            getNumberCard() {
                if(!this.isEdit) {
                    return '•••••••• ••••';
                }

                return `•••••••• ${this.card.card.last4}`;
            },
            getAlias() {
                if(!this.alias || this.alias.length === 0) {
                    const cards = this.account.cards.length;

                    return `Tarjeta ${cards+1}`;
                }

                return this.alias;
            },
            getProductName() {
                const originCard = this.account.cards.find(c => c.card.default && c.card.manufacturingType === 'VIRTUAL');
                const type = this.$t(`typeCards.${originCard.card.manufacturingType}`);
                return `${this.accountDescription} ${type}`;
            },
            getImage(card) {
                const defaultImage = CARD_DEFAULT_IMG.pagandoExplore;

                if(!card || !card.cardTemplate) {
                    return defaultImage;
                }

                switch (card.cardTemplate.product) {
                    case "EXPLORE":
                        return CARD_DEFAULT_IMG.pagandoExplore;
                    case "EXPLORE_PLUS":
                        return CARD_DEFAULT_IMG.pagandoExplorePlus;
                    case "NIGHT":
                        return CARD_DEFAULT_IMG.pagandoNight;
                    case "BUSINESS":
                        return CARD_DEFAULT_IMG.pagandoBusiness;
                    case "BUSINESS_PRO":
                        return CARD_DEFAULT_IMG.pagandoBusinessPro;
                    default:
                        if(card.cardTemplate.image) {
                            return imageDownloadLink(card.cardTemplate.image);
                        }

                        return defaultImage;
                }
            },
            async save() {
                if(this.disabledButton) {
                    if(!this.isEdit) {
                        const auth = await this.$requestAuth();

                        if(auth) {
                            await this.generateRequest();
                        }
                    } else {
                        await this.changeAlias();
                    }
                } else {
                    this.toastErrorDefault(this.$t('additionalCards.errors.incompleteParams'));
                }
            },
            async generateRequest() {
                this.loading = true;
                const res = await CardApi.requestVirtualCard({accountId: this.account.account._id}).catch(catchError);

                if(res && res.data && !res.data.error) {
                    await this.assignCard();
                } else {
                    this.toastErrorDefault(this.$t('additionalCards.errors.doRequest'));
                }
                this.loading = false;
            },
            async assignCard() {
                const res = await CardApi.assignVirtualCard({alias: this.alias, accountId: this.account.account._id}).catch(catchError);

                if(res && res.data && !res.data.error) {
                    this.toastSuccessDefault();
                    await this.$store.dispatch(`${sessionStore}/loadAccount`);
                    this.$router.push({name: 'AdditionalCards'});
                } else {
                    this.toastErrorDefault(this.$t('additionalCards.errors.doRequest'));
                }
            },
            async changeAlias() {
                this.loading = true;
                if(this.card.card.alias !== this.alias) {
                    const res = await CardApi.changeAliasCard({alias: this.alias, cardId: this.card.card._id}).catch(catchError);

                    if(res && res.data && !res.data.error) {
                        this.toastSuccessDefault();
                        await this.$store.dispatch(`${sessionStore}/loadAccount`);
                        this.$router.push({name: 'AdditionalCards'});
                    } else {
                        this.toastErrorDefault(this.$t('additionalCards.errors.alias'));
                    }
                }
                this.loading = false;
            },
            async cancelCard() {
                this.isCancel = true;
                this.$bvModal.hide('modal-cancel-card');
                this.loading = true;
                const res = await CardApi.cancelVirtualCard({accountId: this.account.account._id, cardId: this.card.card._id}).catch(catchError);

                if(res && res.data && !res.data.error) {
                    this.toastSuccessDefault(this.$t('additionalCards.success.cancelCard'));
                    await this.$store.dispatch(`${sessionStore}/loadAccount`);
                    this.$router.push({name: 'AdditionalCards'});
                } else {
                    this.toastErrorDefault(this.$t('additionalCards.errors.cancelCard'));
                    this.loading = false;
                    this.isCancel = false;
                }
            },
            getCommissionCost() {
                return this.currency(this.commission ? this.commission.cost : 0);
            },
            goBack() {
                this.$router.push({name: 'AdditionalCards'});
            }
        },
        mounted () {
            this.handleResizeMenu();
            window.addEventListener('resize', this.handleResizeMenu);

            const routeName = this.$route.name;

            if(routeName === 'AdditionalCardEdit') {
                this.isEdit = true;

                if(!this.card) {
                    this.toastErrorDefault(this.$t('additionalCards.errors.getDetail'));
                    this.$router.push({name: 'AdditionalCards'});
                } else {
                    this.alias = this.card.card.alias;
                }

            }
        },
        destroyed () {
            window.addEventListener('resize', this.handleResizeMenu);
        }
    };
</script>
