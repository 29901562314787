<template>
  <div>
    <div>
      <div id="divShowLeftMenu" class="responsive-top-bar">
        <span>
          <button
            type="button"
            id="showLeftMenu"
            class="button-square xs button-filled button-primary"
            :aria-label="$t('corporativeAccounts.activity.showSidebarMenu')"
            :name="$t('corporativeAccounts.activity.showSidebarMenu')"
            @click="showMenu"
          >
            <i aria-hidden="true" class="iconsax-essential-menu"></i>
          </button>
        </span>
        <span>
          <img
            class="img-fluid"
            src="@/assets/images/Logos/logo-color.svg"
            width="114"
            height="25"
            alt="Pagando Logo"
          />
        </span>
        <span>
          <button
            type="button"
            id="showRightMenu"
            class="button-square xs button-stroke button-primary"
            :aria-label="$t('corporativeAccounts.activity.showSidebarRight')"
            :name="$t('corporativeAccounts.activity.showSidebarRight')"
            @click="showSidebarRight"
          >
            <i aria-hidden="true" class="iconsax-money-cards"></i>
          </button>
        </span>
      </div>

      <CorporateSidebarMenu
        :clazz="[isMenuOpen ? 'show' : '']"
        @hideMenu="hideMenu"
        @clickHome="showDashboard = true"
      />
      <div class="sidebar-menu-backdrop menu-principal"></div>

      <CorporateSidebarRight
        v-if="info"
        ref="sidebarRight"
        :info="info"
        @blockCard="blockCard"
        @requestNIP="getCardNip"
        @requestCVV="getCardCVV"
      />
      <div v-if="info" class="sidebar-menu-backdrop sidebar-right"></div>

      <section id="main-view">
        <LoadingBar v-if="!info" clazz="fullcontent-loading"/>
        <div class="page-container" v-else>
          <div class="row">
            <div class="col-12">
              <FloatingTitleLayout
                :titleStrong="info.general.alias"
                :subtitle="$t('corporativeAccounts.activity.lastLogin', {date: moment(info.lastLogin).format('LL h:mm A')})"
              >
                <template v-slot:userImg>
                  <router-link :to="{ name: 'MyAccount' }" class="d-none d-md-block" tabindex="">
                    <UserAccount
                      :userImg="imageDownloadLink(info.general.image) || imgUserAccount"
                      :widthImg="50"
                      :heightImg="50"
                      :altImgUser="info.general.alias"
                    >
                    </UserAccount>
                  </router-link>
                </template>
                <template v-slot:buttons>
                  <button type="button" class="button xs button-filled button-primary d-md-flex m-r-15" @click="back">
                    {{$t('corporativeAccounts.activity.return')}}
                  </button>
                  <NotificationsPanel></NotificationsPanel>
                </template>
              </FloatingTitleLayout>
            </div>
            <div class="col-12">
              <div class="row no-gutters m-b-15">
                <div class="col-12 col-sm-6 col-lg-4 pr-sm-3">
                  <DashboardPanel variant="lg p-events-initial " extraClazzInfo="max-w-100">
                    <span>{{$t('corporativeAccounts.activity.monthlyAmount.max')}}</span>
                    <div class="money c-info x-scroll w-100">
                      {{Math.floor(info.monthLimit.limit) | currency({fractionCount: 0})}}
                      <span>{{twoDecimals(info.monthLimit.limit)}}</span>
                    </div>
                  </DashboardPanel>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 pr-lg-3">
                  <DashboardPanel variant="lg p-events-initial " extraClazzInfo="max-w-100">
                    <span>{{$t('corporativeAccounts.activity.monthlyAmount.available')}}</span>
                    <div class="money c-success x-scroll w-100">
                      {{Math.floor(info.monthLimit.remaining) | currency({fractionCount: 0})}}
                      <span>{{twoDecimals(info.monthLimit.remaining)}}</span>
                    </div>
                  </DashboardPanel>
                </div>
                <div class="col-12 col-sm-6 col-lg-3 pr-sm-3">
                  <DashboardPanel variant="lg p-events-initial " extraClazzInfo="max-w-100">
                    <span>{{$t('corporativeAccounts.activity.monthlyAmount.used')}}</span>
                    <div class="money x-scroll w-100">
                      {{Math.floor(info.monthLimit.current) | currency({fractionCount: 0})}}
                      <span>{{twoDecimals(info.monthLimit.current)}}</span>
                    </div>
                  </DashboardPanel>
                </div>
                <div class="col-12 col-sm-6 col-lg-2 c-pointer" @click="showDashboard = !showDashboard">
                  <DashboardPanel
                    :variant="`blue md ${showDashboard ? '' : 'active'}`"
                    icon="iconsax-money-card"
                    :text="`${$t('myAccount.title')}`"
                    :responsiveText="$t('myAccount.title').toString()"
                  >
                  </DashboardPanel>
                </div>
              </div>
            </div>
            <CorporateActivityDashboard
              v-show="showDashboard"
              :accountHolder="info.general"
            />
            <CorporateActivityMyAccount
              v-show="!showDashboard"
              :info="info"
            />
          </div>
        </div>
      </section>

      <FooterPage></FooterPage>
      <vue-snotify></vue-snotify>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import FooterPage from "@/components/FooterPage.vue";
import LoadingBar from "@/components/LoadingBar.vue";
import UserAccount from "@/components/UserAccount.vue";
import DashboardPanel from "@/components/DashboardPanel.vue";
import NotificationsPanel from "@/pages/NotificationsPanel.vue";
import FloatingTitleLayout from "@/components/FloatingTitleLayout.vue";
import CorporateSidebarMenu from "@/components/corporative/CorporateSidebarMenu.vue";
import CorporateSidebarRight from "@/components/corporative/CorporateSidebarRight.vue";
import CorporateActivityDashboard from "@/components/corporative/CorporateActivityDashboard";
import CorporateActivityMyAccount from "@/components/corporative/CorporateActivityMyAccount";

import { imageDownloadLink } from "@/utils/utils";
import MenuHandler from "@/mixins/menuHandler.mixin";
import ToastSnotify from "@/mixins/toastSnotify.mixin";

import moment from "moment";

import imgUserAccount from "@/assets/images/Default/profile-photo.svg";

import CorporativeAccountsApi from "@/api/wallet/corporativeAccounts.api";

export default {
  name: "CorporativeActivity",
  mixins: [ToastSnotify, MenuHandler],
  components: {
    FooterPage,
    LoadingBar,
    UserAccount,
    DashboardPanel,
    NotificationsPanel,
    FloatingTitleLayout,
    CorporateSidebarMenu,
    CorporateSidebarRight,
    CorporateActivityDashboard,
    CorporateActivityMyAccount,
  },
  props: {
    comesFromDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgUserAccount,

      info: null,
      showDashboard: true,
    };
  },
  created() {
    moment.locale(this.$i18n.locale);
    this.loadInfo();
  },
  methods: {
    back() {
      if(this.comesFromDetails) {
        const id = this.$route.params.id;
        this.$router.push({name: 'CorporativeAccountDetail', params: {id}});
      } else {
        this.$router.push({name: 'CorporativeAccountList'});
      }
    },
    moment,
    imageDownloadLink,
    // Show Sidebar right
    showSidebarRight() {
      document.getElementById("sidebar-right").classList.remove("hide");
    },
    async loadInfo() {
      try {
        const id = this.$route.params.id;
        const response = await CorporativeAccountsApi.getActivityInfo(id);

        this.info = response.data.object;
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    async blockCard(card) {
        try {
            let response;
            if(card.status !== 'ACTIVE' && card.status !== 'VERIFIED') {
                response = await CorporativeAccountsApi.unblockCard(card._id);
                const message = this.$t('corporativeAccounts.activity.unblockSuccess');
                this.displayNotificationSuccess(message);
            } else {
                response = await CorporativeAccountsApi.blockCard(card._id);
                const message = this.$t('corporativeAccounts.activity.blockSuccess');
                this.displayNotificationSuccess(message);
            }
            card.status = response.data.object;
        } catch (error) {
            this.toastErrorDefault(error);
        }
        this.$refs.sidebarRight.checkCardBlocked();
    },
    twoDecimals(n) {
        return Math.round((n * 100) % 100).toLocaleString("en", { minimumIntegerDigits: 2 });
    },
    async getCardNip(cardId) {
      try {
        if(await this.$requestAuth()) {
          const response = await CorporativeAccountsApi.checkNIP(cardId);
          const nip = response.data.object;
          this.$refs.sidebarRight.setCardNIP(cardId, nip);
        } else {
          this.$refs.sidebarRight.setCardNIP(cardId, null);
        }
      } catch (error) {
        this.toastErrorDefault(error);
        this.$refs.sidebarRight.setCardNIP(cardId, null);
      }
    },
    async getCardCVV(cardId) {
      try {
        if(await this.$requestAuth()) {
          // Obtención del CVC
          const response = await CorporativeAccountsApi.checkCVV(cardId);

          const cvv = response.data.object;

          // Si tiene fecha de expiración es CVC dinámico
          if (cvv.cvvExpirationTime) {
              this.$refs.sidebarRight.defineDynamicCVV(cardId, true);
              this.$refs.sidebarRight.setCardCVV(cardId, cvv.cvv);

              // Definir tiempo de expiración del CVC
              this.$refs.sidebarRight.scheduleCvvExpiration(cvv.cvvExpirationTime, cardId);
          } else if(cvv.cvv){ // No es CVC Dinámico
              this.$refs.sidebarRight.setCardCVV(cardId, cvv.cvv);
          } else { // CVC con estructura anterior
            this.$refs.sidebarRight.setCardCVV(cardId, cvv);
          }

        } else {
          this.$refs.sidebarRight.setCardCVV(cardId, null);
        }
      } catch (error) {
        this.toastErrorDefault(error);
        this.$refs.sidebarRight.setCardCVV(cardId, null);
      }
    },
  },
};
</script>
