const clientHost = process.env.VUE_APP_CLIENT_PROD_HOST || undefined
const clientPort = process.env.VUE_APP_CLIENT_PROD_PORT || undefined
const LOCAL_HOST = 'http://localhost';

export default {
    server: {
        host: process.env.API_HOST || process.env.VUE_APP_API_HOST || LOCAL_HOST,
        port: process.env.API_PORT || process.env.VUE_APP_API_PORT || 3000
    },
    wallet: {
        host: process.env.API_WALLET_HOST || process.env.VUE_APP_API_WALLET_HOST || LOCAL_HOST,
        port: process.env.API_WALLET_PORT || process.env.VUE_APP_API_WALLET_PORT || 3001
    },
    walletWeb: {
        host: clientHost || process.env.HOST_WALLET_WEB|| process.env.VUE_APP_HOST_WALLET_WEB || LOCAL_HOST,
        port: clientPort || process.env.PORT_WALLET_WEB || process.env.VUE_APP_PORT_WALLET_WEB || 8080,
        endpoint: process.env.ENDPOINT_WALLET_WEB || process.env.VUE_APP_ENDPOINT_WALLET_WEB || ''
    },
    check: {
        host: process.env.HOST_CHECK_WEB|| process.env.VUE_APP_HOST_CHECK_WEB || LOCAL_HOST,
        port: process.env.PORT_CHECK_WEB || process.env.VUE_APP_PORT_CHECK_WEB || 8000,
        endpoint: process.env.ENDPOINT_CHECK_WEB || process.env.VUE_APP_ENDPOINT_CHECK_WEB || ''
    },
    sentry: {
        enabled: false,
        dsn: 'https://f1b67dc0efe04213bad62f95521cfc18@sentry.blacklabs.mx/4'
    },
    walletQr: {
        host: "https://826f-201-174-123-50.ngrok.io" // just for test
    },
    googleRecapcha: {
        secretKey: process.env.VUE_APP_RECAPCHA_SECRET,
        publicKey: process.env.VUE_APP_RECAPCHA_PUBLIC
    },
    pagando: {
        clabePrefix: process.env.PAG_CLAVE_PREFIX || '6461802471'
    },
    enableWebcam: process.env.VUE_APP_DISABLE_WEBCAM || false,
    showUx: process.env.VUE_APP_PAG_SHOW_UX || true,
    automaticLogout: process.env.VUE_APP_AUTOMATIC_LOG_OUT || false,
    fingerprintJs: {
        active: process.env.FINGERPRINT_JS_ACTIVE || process.env.VUE_APP_FINGERPRINT_JS_ACTIVE || false,
        key: process.env.FINGERPRINT_JS_KEY || process.env.VUE_APP_FINGERPRINT_JS_KEY || "",
    },
    loginAntiFraud: {
        active: process.env.SEON_ACTIVE || process.env.VUE_APP_SEON_ACTIVE || false,
    },
    checkSignSpeiOutActive: process.env.VUE_APP_CHECK_SIGN_SPEIOUT_ACTIVE || false,

}
