import { render, staticRenderFns } from "./UsagePolicies.vue?vue&type=template&id=040c214c&scoped=true"
import script from "./UsagePolicies.vue?vue&type=script&lang=js"
export * from "./UsagePolicies.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "040c214c",
  null
  
)

export default component.exports