import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'card-template';

export default {
    find: (query, params) => {
        return axios.get(`${base.baseUrl}/${namespace}/find/${query}`, {
            params: params
        })
    },
    findAll: (query, params) => {
        return axios.get(`${base.baseUrl}/${namespace}/find/${query}/ALL`, {
            params: params
        })
    },
    getAddInfo: (id, query) => {
        return axios.get(`${base.baseUrl}/${namespace}/add-info/${id}`, {
            params: query
        })
    }
}
