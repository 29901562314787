<template>
  <aside id="sidebar-menu" class="sidebar-menu" :class="clazz">
    <button
      type="button"
      class="button-close-menu"
      :aria-label="$t('dashboard.sidebarMenu.hide')"
      :name="$t('dashboard.sidebarMenu.hide')"
      @click="$emit('hideMenu')"
    >
      <i aria-hidden="true" class="iconsax-arrow-arrow-left"></i>
    </button>
    <div class="sidebar-menu-content">
      <div id="" class="menu-head">
        <div class="logo">
          <img class="img-fluid" src="@/assets/images/Logos/logo-color-xs.svg" width="40" height="34" alt="Pagando Logo"/>
        </div>
      </div>
      <div id="" class="menu-body">
        <ul class="menu-list">
          <li>
            <router-link to="" class="menu-option" tabindex="" @click.native="$emit('clickHome')">
              <span class="menu-icon">
                <i aria-hidden="true" class="iconsax-building-house-3"></i>
              </span>
              <p class="menu-text">{{$t('dashboard.sidebarMenu.home')}}</p>
            </router-link>
          </li>
        </ul>
        <ul class="menu-list" />
      </div>
    </div>
  </aside>
</template>

<script>
const rootHTML = document.getElementsByTagName("html")[0];
export default {
  name: "CorporateSidebarMenu",
  props: {
    clazz: [],
  },
  methods: {
    blockScroll() {
      // Botones de menu izquierdo
      const leftMenuBtnOpen = document.getElementById("showLeftMenu");
      const leftMenuBtnClose = document.querySelector(".button-close-menu");

      // Botones de menu Derecho
      const rightMenuBtnOpen = document.getElementById("showRightMenu");
      const rightMenuBtnClose = document.querySelector(".button-sidebar-right.hide");
      const SBRight = document.getElementById("sidebar-right");

      leftMenuBtnOpen.addEventListener("click", function () {
        rootHTML.setAttribute("class", "block-scroll");
      });
      leftMenuBtnClose.addEventListener("click", function () {
        rootHTML.removeAttribute("class");
      });

      if (typeof SBRight !== "undefined" && SBRight != null) {
        rightMenuBtnOpen.addEventListener("click", function () {
          rootHTML.setAttribute("class", "block-scroll");
        });
        rightMenuBtnClose.addEventListener("click", function () {
          rootHTML.removeAttribute("class");
        });
      }
    },
    removeScrollClass() {
      rootHTML.removeAttribute("class");
    },
  },
  mounted() {
    this.blockScroll();
    window.addEventListener("resize", this.removeScrollClass);
  },
  // destroyed () {
  //     window.addEventListener('resize', this.removeScrollClass);
  // },
};
</script>
