 <template>
  <div class="col-12">
    <div class="row">
      <!-- Movimientos Recientes -->
      <RecentMovements
        :showPointsButton="false"
        :movements="movements"
        :loading="loadingMovements"
        @goToMovements="goToMovements"
      />

      <!-- Titular de la Cuenta -->
      <div class="col-12 col-lg-6 cards-same-height">
        <CardSimple
          :title="$t('corporativeAccounts.activity.dashboard.user')"
          :subtitle="$t('corporativeAccounts.activity.dashboard.userText')"
          clazzBody="card-body-no-padding"
        >
          <div class="text-center">
            <UserAccount
              clazz="xl m-auto"
              :userImg="imageDownloadLink(accountHolder.image) || imgUserAccount"
              :widthImg="80"
              :heightImg="80"
              :altImgUser="accountHolder.fullName"
            >
            </UserAccount>
            <div class="poppins-font-bold c-text_title f-18 m-b-20 m-t-20">
              {{ accountHolder.fullName }}
            </div>
          </div>
          <ReadOnlyInfo
            clazz="text-center"
            :title="$t('corporativeAccounts.activity.dashboard.email')"
            :text="accountHolder.email"
          ></ReadOnlyInfo>
          <ReadOnlyInfo
            clazz="text-center"
            :title="$t('corporativeAccounts.activity.dashboard.phone')"
            :text="accountHolder.phone"
          ></ReadOnlyInfo>
          <ReadOnlyInfo
            clazz="text-center"
            :title="$t('corporativeAccounts.activity.dashboard.alias')"
            :text="accountHolder.alias"
          ></ReadOnlyInfo>
        </CardSimple>
      </div>

      <!-- Movimientos Semanales -->
      <MovementsChart
        :chartData="chartData"
        @dateChange="getChartData"
      />
    </div>
  </div>
</template>

<script>
import CardSimple from "@/components/CardSimple.vue";
import UserAccount from "@/components/UserAccount.vue";
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
import MovementsChart from "@/components/dashboard/MovementsChart";
import RecentMovements from "@/components/dashboard/RecentMovements";

import { imageDownloadLink } from "@/utils/utils";

import imgUserAccount from "@/assets/images/Default/profile-photo.svg";

import CorporativeAccountsApi from "@/api/wallet/corporativeAccounts.api";

export default {
  name: "CorporateActivityDashboard",
  components: {
    CardSimple,
    UserAccount,
    ReadOnlyInfo,
    MovementsChart,
    RecentMovements,
  },
  props: {
    accountHolder: Object,
  },
  data() {
    return {
      imgUserAccount,
      movements: null,
      loadingMovements: false,
      chartData: null,
    };
  },
  created() {
    this.getCardMovements();
    this.getChartData();
  },
  methods: {
    imageDownloadLink,
    async getCardMovements() {
      try {
        this.loadingMovements = true;
        const response = await CorporativeAccountsApi.getAccountMovements({
          id: this.accountHolder.accountId,
          perPage: 3,
        });
        this.movements = response.data.object.docs;
        this.loadingMovements = false;
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    async getChartData(date) {
      try {
        const response = await CorporativeAccountsApi.getAccountMovementsByDate({
          id: this.accountHolder.accountId,
          date: date || new Date()
        });
        this.chartData = response.data.object;
      } catch (error) {
        this.toastErrorDefault(error);
      }
    },
    goToMovements() {
      const id = this.accountHolder.accountId;
      this.$router.push({
        name: 'CorporativeActivityMovements',
        params: {id},
      })
    },
  },
};
</script>
