<template>
  <div>
    <div v-if="modalId">
      <!-- MODAL CANCELAR INVITACION -->
      <ModalAlert
        closeCross
        modalId="modal-alertCancelInvitation"
        buttonType="button-primary"
        :imgUrl="imageModalInvitationCancel"
        :title="$t('collaborators.confirmModal.cancelInvite.title')"
        :text="$t('collaborators.confirmModal.cancelInvite.text')"
        :buttonCancel="$t('collaborators.confirmModal.actions.no')"
        :buttonText="$t('collaborators.confirmModal.actions.yes')"
        @submit="onClickedConfirm"
      ></ModalAlert>

      <!-- MODAL ELIMINAR COLABORADOR -->
      <ModalAlert
        closeCross
        modalId="modal-alertDeleteCollaborator"
        buttonType="button-primary"
        :imgUrl="imageModalDeleteCollaborator"
        :title="$t('collaborators.confirmModal.removeCollaborator.title')"
        :text="$t('collaborators.confirmModal.removeCollaborator.text')"
        :buttonCancel="$t('collaborators.confirmModal.actions.no')"
        :buttonText="$t('collaborators.confirmModal.actions.yes')"
        @submit="onClickedConfirm"
      ></ModalAlert>

      <!-- MODAL RECHAZAR COLABORADOR -->
      <b-modal
        id="modal-rechazar-colaborador"
        size="xs"
        hide-header
        hide-footer
        modal-class="modal-default"
      >
        <button
          class="close-modal"
          @click="$bvModal.hide('modal-rechazar-colaborador')"
          :aria-label="$t('collaborators.confirmModal.actions.closeWindow')"
          :name="$t('collaborators.confirmModal.actions.closeWindow')"
        >
          <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </button>
        <div class="head">
          <h2 class="modal-title">{{$t('collaborators.confirmModal.rejectCollaborator.title')}}</h2>
        </div>
        <div class="body">
          <div class="row">
            <div class="col-12">
              <BasicInput
                required
                inputId=""
                v-model="rejectReason"
                :label="$t('collaborators.confirmModal.rejectCollaborator.label')"
                :inputPlaceholder="$t('collaborators.confirmModal.rejectCollaborator.placeholder')"
              >
              </BasicInput>
            </div>
            <div class="col-12">
              <div class="transfer-account-info">
                <div class="item max-w-100">
                  <div class="item-container">
                    <div class="name f-24 m-t-0 m-b-10 poppins-font-bold f-700">
                      {{ collaborator.name }}
                    </div>
                    <div class="account">{{ collaborator.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button
            type="button"
            class="button button-stroke button-primary"
            @click="$bvModal.hide(modalId)"
          >
            {{$t('collaborators.confirmModal.actions.cancel')}}
          </button>
          <button
            type="button"
            class="button button-filled button-primary"
            :disabled="!rejectReason"
            @click="onClickedConfirm(rejectReason)"
          >
            {{$t('collaborators.confirmModal.actions.reject')}}
          </button>
        </div>
      </b-modal>

      <!-- MODAL AUTORIZAR COLABORADOR -->
      <b-modal
        id="modal-autorizar-colaborador"
        size="xs"
        hide-header
        hide-footer
        modal-class="modal-default"
      >
        <button
          class="close-modal"
          @click="$bvModal.hide('modal-autorizar-colaborador')"
          :aria-label="$t('collaborators.confirmModal.actions.closeWindow')"
          :name="$t('collaborators.confirmModal.actions.closeWindow')"
        >
          <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </button>
        <div class="head">
          <h2 class="modal-title">{{$t('collaborators.confirmModal.authorizeCollaborator.title')}}</h2>
        </div>
        <div class="body">
          <div class="row">
            <div class="col-12">
              <div class="transfer-account-info">
                <div class="item max-w-100">
                  <div class="item-container">
                    <div class="name f-24 m-t-0 m-b-10 poppins-font-bold f-700">
                      {{ collaborator.name }}
                    </div>
                    <div class="account">{{ collaborator.email }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <button
            type="button"
            class="button button-stroke button-primary"
            @click="$bvModal.hide(modalId)"
          >
            {{$t('collaborators.confirmModal.actions.cancel')}}
          </button>
          <button
            type="button"
            class="button button-filled button-primary"
            @click="onClickedConfirm"
          >
            {{$t('collaborators.confirmModal.actions.authorize')}}
          </button>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import ModalAlert from "@/components/ModalAlert.vue";
import BasicInput from "@/components/BasicInput.vue";

import imageModalInvitationCancel from "@/assets/images/Icons/icon-notification.svg";
import imageModalDeleteCollaborator from "@/assets/images/Icons/icon-close.svg";

const ids = {
  cancel: "modal-alertCancelInvitation",
  delete: "modal-alertDeleteCollaborator",
  reject: "modal-rechazar-colaborador",
  authorize: "modal-autorizar-colaborador",
};

export default {
  name: "ConfirmModal",
  components: {
    ModalAlert,
    BasicInput,
  },
  data() {
    return {
      imageModalInvitationCancel,
      imageModalDeleteCollaborator,

      modalId: null,
      resolve: null,
      rejectReason: null,
      collaborator: {},
      requireTwoFactorAuth: false,
    };
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", async (bvEvent, modalId) => {
      if (modalId === this.modalId && this.resolve) {
        this.resolve(false);
        this.resolve = null;
      }
    });
  },
  methods: {
    async confirm({ type, collaborator = {}, requireTwoFactorAuth = false }) {
      this.modalId = ids[type];
      this.requireTwoFactorAuth = requireTwoFactorAuth;
      this.collaborator = collaborator;
      this.rejectReason = null;
      await this.$nextTick();
      return new Promise((resolve) => {
        this.resolve = resolve;
        this.$bvModal.show(this.modalId);
      });
    },
    async onClickedConfirm(data = true) {
      if (this.requireTwoFactorAuth) {
        this.modalId = null;
        await this.$nextTick();
        const auth = await this.$requestAuth();
        if(!auth) {
          data = false;
        }
      }
      this.resolve(data);
      this.resolve = null;
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>
