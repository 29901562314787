<template>
  <div>
    <CardSimple hideTitle>
      <StatusNote
        status="successs"
        :title="$t('transfers.new.successDetails.title')"
        :text=" moment(transferDetails.data.operationDate).format('LL, hh:mm a')"
        :imgWidth="284"
        :imgHeight="280"
        :imgAlt="$t('transfers.new.successDetails.title')"
      >
      </StatusNote>
      <TransferInfo
        :transmitterName="fullName"
        :transmitterAccount="accountDescription"
        :transmitterNumber="accountNumber"
        :beneficiaryName="contact.contactName"
        :beneficiaryAccount="contact.bank"
        :beneficiaryNumber="contact.number"
      >
        <template v-slot:cuentaOrigen>
          <UserAccount
            clazz="xs m-0-auto"
            :userImg="imageDownloadLink(selfPortrait) || imgUserAccount"
            :widthImg="42"
            :heightImg="42"
            :altImgUser="fullName"
          >
          </UserAccount>
        </template>
        <template v-slot:cuentaBeneficiario>
          <UserAccount
            :clazz="getContactColorClass(contact)"
            :noAccount="!contact.isPagandoAccount"
            :userImg="imageDownloadLink(contact.image) || imgUserAccount"
            :userName="getContactInitials(contact)"
            :altImgUser="contact.contactName"
          />
        </template>
      </TransferInfo>
      <div class="row m-t-30">
        <div class="col-12 col-sm-6">
          <ReadOnlyInfo
            :title="$t('transfers.new.successDetails.amount')"
            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
            :text="amount | currency"
            textClazz="f-26 c-text_title poppins-font-bold f-700 m-b-5 text-center"
          >
            <p class="f-12 c-text_content poppins-font-medium f-500 m-b-0 text-center" v-if="transferDetails.commission">
              {{$t('transfers.new.successDetails.commission1')}}
              <strong class="c-text_title poppins-font-bold f-700">
                {{$t('transfers.new.successDetails.commission2', {amount: currency(transferDetails.commission)})}}
              </strong>
            </p>
            <p class="f-12 c-text_content poppins-font-medium f-500 m-b-0 text-center" v-else>
              {{$t('transfers.new.successDetails.noCommission')}}
            </p>
          </ReadOnlyInfo>
          <ReadOnlyInfo
            :title="$t('transfers.new.successDetails.concept')"
            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
            :text="concept"
            textClazz="f-16 c-text_title poppins-font-bold f-700 m-b-5 text-center"
          >
          </ReadOnlyInfo>
        </div>
        <div class="col-12 col-sm-6">
          <ReadOnlyInfo
            :title="$t('transfers.new.successDetails.folio')"
            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
            :text="folio"
            textClazz="f-16 c-text_title poppins-font-bold f-700 m-b-5 text-center"
          >
          </ReadOnlyInfo>
          <ReadOnlyInfo
            v-if="!contact.isPagandoAccount"
            :title="$t('transfers.new.successDetails.trackingKey')"
            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
            :text="transferDetails.data.trackingKey"
            textClazz="f-16 c-text_title poppins-font-bold f-700 m-b-5 text-center"
          >
          </ReadOnlyInfo>
          <ReadOnlyInfo
            v-if="!contact.isPagandoAccount"
            :title="$t('transfers.new.successDetails.verifyStatusText')"
            titleClazz="f-14 c-text_info poppins-font-medium f-500 m-b-0 text-center"
          >
            <a
              href="https://www.banxico.org.mx/cep/"
              class="f-15 c-info poppins-font-semibold f-600 text-center"
              tabindex=""
            >
              <u>https://www.banxico.org.mx/cep/</u>
            </a>
          </ReadOnlyInfo>
        </div>
      </div>
    </CardSimple>

    <div class="control-buttons buttons-full-w-xs">
      <button
        type="button"
        class="button button-stroke button-primary"
        @click="downloadReceipt"
        v-if="!downloading"
      >
        <i
          aria-hidden="true"
          class="iconsax-content-document-download m-r-10"
        ></i>
        {{$t('transfers.new.successDetails.downloadReceipt')}}
      </button>
      <button v-else class="button button-filled button-primary">
        <LoadingSimple clazz="small-load no-text" />
      </button>
      <button type="button" class="button button-filled button-primary" @click="$emit('finish')">
        {{$t('transfers.new.successDetails.finish')}}
      </button>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import TransferInfo from "@/components/TransferInfo.vue";
import StatusNote from "@/components/StatusNote.vue";
import CardSimple from "@/components/CardSimple.vue";
import imgErrorStatus from "@/assets/images/Illustrations/illustration-status-error.svg";
import UserAccount from "@/components/UserAccount.vue";
import imgUserAccount from "@/assets/images/Default/profile-photo.svg";
import ReadOnlyInfo from "@/components/ReadOnlyInfo.vue";
import imgStatusFirma from "@/assets/images/Illustrations/illustration-firma.svg";
import CardApi from '@/api/wallet/card.api';
import { imageDownloadLink } from '@/utils/utils';
import { getContactColorClass, getContactInitials } from "@/utils/contactUtils";
import { mapGetters } from "vuex";
import moment from 'moment';
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import LoadingSimple from "@/components/LoadingSimple";
import {
  STORE as sessionStore,
  GET_ACCOUNT_DESCRIPTION,
  GET_ACCOUNT,
  GET_ACCOUNT_NUMBER,
  GET_USER_FULL_NAME,
  GET_USER_SELF_PORTRAIT,
} from "@/store/modules/session/session.types";

export default {
  name: "NewTransferSuccessDetails",
  mixins: [ToastSnotify],
  components: {
    TransferInfo,
    StatusNote,
    CardSimple,
    UserAccount,
    ReadOnlyInfo,
    LoadingSimple,
  },
  props: {
    contact: Object,
    amount: Number,
    concept: String,
    transferDetails: Object,
  },
  data() {
    return {
      // Images
      imgErrorStatus: imgErrorStatus,
      imgUserAccount: imgUserAccount,
      imgStatusFirma: imgStatusFirma,

      // SidebarRight / MenuPrincipal
      window: {
        width: 0,
      },
      isMenuOpen: false,
      downloading: false,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
      account: GET_ACCOUNT,
      accountDescription: GET_ACCOUNT_DESCRIPTION,
      accountNumber: GET_ACCOUNT_NUMBER,
      fullName: GET_USER_FULL_NAME,
      selfPortrait: GET_USER_SELF_PORTRAIT,
    }),
    folio() {
      const {folio, reference, id} = this.transferDetails.data;
      return (folio || reference || id || '').toString().padStart(7, '0');
    },
    currency() {
      return this.$options.filters.currency;
    },
  },
  methods: {
    moment,
    imageDownloadLink,
    getContactColorClass,
    getContactInitials,
    // Resize Sidebar Menu Principal
    handleResizeMenu() {
      this.window.width = window.innerWidth;
      if (window.innerWidth > 767) {
        this.isMenuOpen = true;
      } else {
        this.isMenuOpen = false;
      }
    },
    // Show Sidebar Menu Principal
    showMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    // Hide Sidebar Menu Principal
    hideMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    downloadReceipt() {
      this.downloading = true;
      CardApi.downloadTransferReceipt({folio: this.folio}).then(async (response) => {
        const a = document.createElement("a");
        a.href = response.data.object;

        // const link = document.createElement("a");
        // link.setAttribute("href", response.data);
        // link.click();
        a.download = this.$t('transfers.new.successDetails.receiptFilename', {folio: this.folio});
        document.body.appendChild(a);
        this.toastSuccessDefault();
        a.click();
        this.downloading = false;
      }).catch(error => {
        this.toastErrorDefault(error);
        this.downloading = false;
      });
    },
  },
  mounted() {
    this.handleResizeMenu();
    window.addEventListener("resize", this.handleResizeMenu);
  },
  destroyed() {
    window.addEventListener("resize", this.handleResizeMenu);
  },
};
</script>
