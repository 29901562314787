import {
  STORE as onboardingStepsStore,
  GET_CARD_REQUEST,
  GET_OBJECT_ERROR_REQUEST_CARD
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import store from '@/store/index'

export default function hasCardRequest({ next }) {
    const cardRequest = store.getters[`${onboardingStepsStore}/${GET_CARD_REQUEST}`];
    const errorObjectRequest = store.getters[`${onboardingStepsStore}/${GET_OBJECT_ERROR_REQUEST_CARD}`];

    if(cardRequest || (!cardRequest && errorObjectRequest)) {
            return next();
    }

    return next('/wallet');
}
