import axios from 'axios';
import base from '@/api/base.api';
const namespace = 'accounts/api-v1';

export default {
    loadAccount: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/load-account`, params)
    },
    loadAccountCollab: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/load-account-collab`, params)
    },
    setMainAccount: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/set-main-account`, params)
    },
    setAliasToAccount: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/add-alias`, params)
    },

    /**
     * @typedef {object} CustomLimits
     * @property {number} defaultLimit Limite de la cuenta por defecto
     * @property {number} limit limite de la cuenta configurado por el usuario
     * @property {string} periodicity Periodicidad del limite
     * @property {string} type tipo de limite
     * @property {boolean} editPeriodicity si el usuario puede editar la periodicidad
     */

    /**
     * Busca los límites operacionales de una cueta
     * @return {Promise<[CustomLimits]>}
     */
    getLimits: () => {
        return axios.get(`${base.baseUrlWallet}/${namespace}/get-limits`).then(({ data: { object } }) => object);
    },

    setLimits: (params) => {
        return axios.post(`${base.baseUrlWallet}/${namespace}/set-limits`, params)
    },
}
