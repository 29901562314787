<template>
    <aside
        id="sidebar-menu"
        class="sidebar-menu"
        :class="clazz">
        <slot name="buttonClose"></slot>
        <div class="sidebar-menu-content">
            <div id="" class="menu-head">
                <div class="logo">
                    <img
                        class="img-fluid"
                        src="@/assets/images/Logos/logo-color-xs.svg"
                        width="40"
                        height="34"
                        alt="Pagando Logo"
                    />
                </div>
            </div>
            <div id="" class="menu-body">
                <ul class="menu-list">
                    <li>
                        <router-link :to="{name: 'Dashboard'}" class="menu-option" :class="isRouteActive('Wallet')" tabindex="">
                            <span class="menu-icon">
                                <i aria-hidden="true" class="iconsax-building-house-3"></i>
                            </span>
                            <p class="menu-text">Inicio</p>
                        </router-link>
                    </li>
                    <li v-if="canReadDispersions">
                        <router-link :to="{name: 'DispersionsList'}" class="menu-option" :class="{active: isRouteActive('Dispersions')}" tabindex="">
                            <span class="menu-icon">
                                <i aria-hidden="true" class="iconsax-location-arrow"></i>
                            </span>
                            <p class="menu-text">Dispersiones</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'ServicesPayment'}" class="menu-option"
                            :class="isRouteActive('ServicesPayment') || isRouteActive('ServicesPaymentSummary')" tabindex="">
                            <span class="menu-icon">
                                <i aria-hidden="true" class="iconsax-shop-shop"></i>
                            </span>
                            <p class="menu-text">{{$t('services.title')}}</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'PromotionList'}" class="menu-option" tabindex="" :class="isRouteActive('PromotionList') || isRouteActive('PromotionDetails')">
                            <span class="menu-icon">
                                <i aria-hidden="true" class="iconsax-money-discount-shape"></i>
                            </span>
                            <p class="menu-text">{{$t('promotions.title')}}</p>
                        </router-link>
                    </li>
                    <!-- <li>
                        <router-link to="" class="menu-option" tabindex="">
                            <span class="menu-icon">
                                <i aria-hidden="true" class="iconsax-business-chart-2"></i>
                            </span>
                            <p class="menu-text">Mis gastos</p>
                        </router-link>
                    </li> -->
                    <li v-if="canReadCorporativeAccounts">
                        <router-link :to="{name: 'CorporativeAccountList'}" class="menu-option" tabindex="" :class="isRouteActive('CorporativeAccounts')">
                            <span class="menu-icon">
                                <i aria-hidden="true" class="iconsax-money-card-security"></i>
                            </span>
                            <p class="menu-text">Cuentas Corporativas</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name: 'Settings'}" class="menu-option" tabindex=""
                            :class="isRouteActive('Settings') || isRouteActive('PersonalInformation') || isRouteActive('InviteFriends')
                                || isRouteActive('Notifications') || isRouteActive('Security') || isRouteActive('SecurityChangePass') || isRouteActive('Contact')">
                            <span class="menu-icon">
                                <i aria-hidden="true" class="iconsax-settings-gear"></i>
                            </span>
                            <p class="menu-text">{{$t('settings.title')}}</p>
                        </router-link>
                    </li>
                </ul>
                <ul class="menu-list">
                    <li @click="logOut">
                        <router-link to="" class="menu-option logOut" tabindex="" >
                            <span class="menu-icon">
                                <i aria-hidden="true" class="iconsax-arrow-halfsquare-out-left"></i>
                            </span>
                            <p class="menu-text">Cerrar sesión</p>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </aside>
</template>

<script>
        import {
        STORE as sessionStore,
        GET_ACCOUNT,
        CHECK_PERMISSION,
    } from "@/store/modules/session/session.types";
    import {mapGetters} from "vuex";
    import UserApi from "@/api/users/users.api";

    const rootHTML = document.getElementsByTagName( 'html' )[0];
    export default {
        name: 'SidebarMenuPrincipal',
        components: {},
        props: {
            clazz: [],
        },
        data () {
            return {}
        },
        methods: {
            blockScroll() {
                // Botones de menu izquierdo
                const leftMenuBtnOpen = document.getElementById("showLeftMenu");
                const leftMenuBtnClose = document.querySelector(".button-close-menu");

                // Botones de menu Derecho
                const rightMenuBtnOpen = document.getElementById("showRightMenu");
                const rightMenuBtnClose = document.querySelector(".button-sidebar-right.hide");
                const SBRight = document.getElementById('sidebar-right');

                leftMenuBtnOpen.addEventListener("click", function() {
                    rootHTML.setAttribute( 'class', 'block-scroll' );
                });
                leftMenuBtnClose.addEventListener("click", function() {
                    rootHTML.removeAttribute( 'class');
                });

                if (typeof(SBRight) !== 'undefined' && SBRight != null)
                {
                    rightMenuBtnOpen.addEventListener("click", function() {
                        rootHTML.setAttribute( 'class', 'block-scroll' );
                    });
                    rightMenuBtnClose.addEventListener("click", function() {
                        rootHTML.removeAttribute( 'class');
                    });
                }
            },
            removeScrollClass() {
                rootHTML.removeAttribute( 'class');
            },
            logOut() {
                localStorage.removeItem('currentToken');
                localStorage.removeItem('lastAccount');
                UserApi.logout();
                this.$store.commit(`${sessionStore}/CLEAR_STORE`);
                this.$router.push({ name: 'login' });
            },
            isRouteActive(route) {
                const matched = this.$route.matched.filter(r => r.name === route);

                if(matched.length > 0) {
                    return 'active';
                }

                return '';
            }
        },
        mounted () {
            this.blockScroll();
            window.addEventListener('resize', this.removeScrollClass);
        },
        // destroyed () {
        //     window.addEventListener('resize', this.removeScrollClass);
        // },
        computed: {
            ...mapGetters(sessionStore, {
                access: GET_ACCOUNT
            }),
            canReadDispersions() {
                const checkPermission = this.$store.getters[`${sessionStore}/${CHECK_PERMISSION}`];
                const productType = this.access?.account?.accountType?.productType;
                return productType === 'CLIENTS_ACCOUNT' && checkPermission('READ', 'DISPERSIONS');
            },
            canReadCorporativeAccounts() {
                const checkPermission = this.$store.getters[`${sessionStore}/${CHECK_PERMISSION}`];
                const productType = this.access?.account?.accountType?.productType;
              return productType === 'CLIENTS_ACCOUNT' && checkPermission('READ', 'CORPORATIVE_ACCOUNTS');
            },
        },
        watch: {}
    }
</script>
