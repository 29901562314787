<template>
    <div class="card-account-type" :class="clazz">
        <i aria-hidden="true" class="banana banana-check-normal"></i>
        <slot name="cardImg"></slot>
        <h2 class="title" :class="clazzTitle">
            {{title}}
            <strong v-if="titleStrong">{{titleStrong}}</strong>
        </h2>
        <slot></slot>
    </div>
</template>

<script>
    export default {
        name: 'CardAccountType',
        props: {
            clazz: String,
            clazzTitle: String,
            title: String,
            titleStrong: String,
        },
        methods: {}
    }
</script>
