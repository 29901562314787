<template>
    <div class="status-note" :class="status">
        <div class="content-vertical">
            <img v-if="imgNote" class="img-fluid" :class="imgClass" :src="imgUrl" :width="imgWidth" :height="imgHeight" :alt="imgAlt" />
            <span>
                <label> {{title}} </label>
                <p v-html="text"/>
                <p v-if="errorText" class='poppins-font-semibold f-600 f-18'><br>{{errorText}}</p>
            </span>
            <slot></slot>
        </div>
    </div>
</template>

<script>
    import Imagen from '@/assets/images/Illustrations/illustration-status-success.svg';

    export default {
        name: 'StatusNote',
        props: {
            status: String,
            title: String,
            text: String,
            errorText: String,
            imgClass: String,
            imgUrl: {type: String, default: Imagen},
            imgWidth: Number,
            imgHeight: Number,
            imgAlt: {type: String, default: "Estado vacío"},
            imgNote: {type: Boolean, default: true},
        },
        data() {
            return {
                defaultUrl: Imagen,
            }
        }
    }
</script>
