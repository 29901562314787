<template>
  <div class="row">
    <div class="col-12">
      <TitleSteps :title="$t('transfers.list.title')">
        <button
          type="button"
          v-if="canRequestTransfers"
          class="button sm button-filled button-primary"
          @click="$router.push({ name: 'NewTransfer' })"
        >
          <i aria-hidden="true" class="iconsax-money-send m-r-10"></i>
          {{$t('transfers.list.buttonNew')}}
        </button>
      </TitleSteps>
    </div>
    <div class="col-12">
      <TitleBadge
        clazzContainer="p-0"
        :titleText="$t('transfers.list.subtitle')"
        :badgeText="`${transfers.totalDocs || 0}`"
      >
        <div class="daterange-filter">
          <label class="f-12 c-text_info poppins-font-medium f-500 m-b-0">{{$t('transfers.list.filterBy')}}</label>
          <date-range-picker
            ref="picker"
            opens="right"
            :showDropdowns="false"
            :autoApply="true"
            v-model="dateRangeComponentBtn"
            @select="changeDates"
            :linked-calendars="false"
            :ranges="false"
            singleDatePicker="range"
            :locale-data="{
              daysOfWeek: [
                $t('general.weekDaysAbbr.Sunday'),
                $t('general.weekDaysAbbr.Monday'),
                $t('general.weekDaysAbbr.Tuesday'),
                $t('general.weekDaysAbbr.Wednesday'),
                $t('general.weekDaysAbbr.Thursday'),
                $t('general.weekDaysAbbr.Friday'),
                $t('general.weekDaysAbbr.Saturday'),
              ],
              monthNames: [
                $t('general.months.January'),
                $t('general.months.February'),
                $t('general.months.March'),
                $t('general.months.April'),
                $t('general.months.May'),
                $t('general.months.June'),
                $t('general.months.July'),
                $t('general.months.August'),
                $t('general.months.September'),
                $t('general.months.October'),
                $t('general.months.November'),
                $t('general.months.December'),
              ],
            }"
            class=""
          >
            <!-- eslint-disable -->
            <template v-slot:input="picker">
              <span class="daterange-btn"
                >{{$t('transfers.list.date')}}
                <i aria-hidden="true" class="iconsax-arrow-direction-square-down"></i
              ></span>
            </template>
            <!-- eslint-enable -->
          </date-range-picker>
        </div>
      </TitleBadge>

      <CardSimple clazz="p-0" hideTitle>
        <div class="basic-table">
          <b-table-simple
            responsive
            selectable
            selected-variant="active"
            table-variant="xl"
          >
            <!-- Add classes 'b-table-row-selected' to 'b-tbody > b-tr' when a row radiobutton selected. -->
            <b-thead>
              <b-tr class="">
                <b-th> {{$t('transfers.list.fields.beneficiaryName')}} </b-th>
                <b-th class="text-center"> {{$t('transfers.list.fields.beneficiaryAccount')}} </b-th>
                <b-th class="text-center"> {{$t('transfers.list.fields.timestamp')}} </b-th>
                <b-th class="text-center"> {{$t('transfers.list.fields.folio')}} </b-th>
                <b-th class="text-center"> {{$t('transfers.list.fields.status')}} </b-th>
                <b-th class="text-right"> {{$t('transfers.list.fields.amount')}} </b-th>
                <b-th class="text-center"></b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr class="noHover" v-if="loading">
                <b-td colspan="7">
                  <LoadingBar
                    clazz="p-t-50 p-b-50"
                    :loadingText="$t('transfers.list.loading')"
                  ></LoadingBar>
                </b-td>
              </b-tr>
              <b-tr class="noHover" v-else-if="transfers.docs && !transfers.docs.length">
                <b-td colspan="7">
                  <EmptyState
                    emptyStateDefault
                    :title="$t('transfers.list.empty.title')"
                    :text="$t('transfers.list.empty.text')"
                    :imgWidth="520"
                    :imgHeight="320"
                    :imgAlt="$t('transfers.list.empty.title')"
                  >
                    <button
                      id=""
                      type="button"
                      v-if="canRequestTransfers"
                      class="button sm button-filled button-primary m-0-auto m-t-30"
                      @click="$router.push({ name: 'NewTransfer' })"
                    >
                      <i aria-hidden="true" class="iconsax-money-send m-r-10 f-24"></i>
                      {{$t('transfers.list.buttonNew')}}
                    </button>
                  </EmptyState>
                </b-td>
              </b-tr>
              <b-tr v-else v-for="transfer in transfers.docs" :key="transfer._id">
                <b-td>
                  <div class="vertical-center flex-wrap-no">
                    <UserAccount
                      :clazz="`xs ${getContactColorClass(transfer.contact)}`"
                      :noAccount="!transfer.contact.isPagandoAccount"
                      :userName="getContactInitials(transfer.contact)"
                      :userImg="imageDownloadLink(transfer.contact.image) || imgUserAccount"
                      :widthImg="42"
                      :heightImg="42"
                      :altImgUser="transfer.contact.alias"
                    >
                    </UserAccount>
                    <strong class="c-text_title poppins-font-bold m-l-15">{{transfer.contact.alias}}</strong>
                  </div>
                </b-td>
                <b-td class="text-center">
                  <p class="f-14 c-text_content m-b-0">
                    <strong class="poppins-font-bold">{{transfer.contact.bank}}</strong>
                    <br />
                    {{transfer.contact.number}}
                  </p>
                </b-td>
                <b-td class="text-center">
                  <p class="f-14 c-text_content m-b-0">
                    <strong class="poppins-font-bold">{{moment(transfer.date).format('DD MMM YYYY')}}</strong>
                    <br />
                    {{moment(transfer.date).format('HH:mm a')}}
                  </p>
                </b-td>
                <b-td class="text-center">
                  <router-link
                    to=""
                    class="c-info poppins-font-bold f-700"
                    tabindex=""
                  >
                    <u @click="$refs.modalDetails.show(transfer)">{{transfer.folio}}</u>
                  </router-link>
                </b-td>
                <b-td class="text-center">
                  <span
                    :id="`tooltip-${transfer._id}`"
                    class="badge-circle m-0-auto"
                    :class="getStatusInfo(transfer).color"
                  >
                    <i aria-hidden="true" :class="getStatusInfo(transfer).icon"/>
                  </span>
                  <b-tooltip :target="`tooltip-${transfer._id}`" placement="top">
                    <p class="m-b-0">
                      <strong>{{getStatusInfo(transfer).label}}</strong>
                      <span v-if="transfer.status === 'REJECTED'"><br/>{{getStatusInfo(transfer).errorMessage}}</span>
                    </p>
                  </b-tooltip>
                </b-td>
                <b-td class="text-right">
                  <strong class="c-text_title poppins-font-bold"
                    >{{transfer.amount | currency}}</strong
                  >
                </b-td>
                <b-td class="text-center">
                  <router-link
                    v-if="transfer.status === 'PENDING_AUTH'"
                    class="button-square xs button-filled m-0-auto"
                    :class="canAuthorizeTransfers ? 'button-primary' : 'button-text_disabled' "
                    :aria-label="$t('transfers.list.authorize')"
                    to="" tabindex=""
                    @click.native="authorize(transfer)"
                  >
                    <i aria-hidden="true" class="iconsax-content-edit"></i>
                  </router-link>
                  <router-link
                    v-else
                    class="button-square xs button-filled m-0-auto"
                    :class="canRequestTransfers && ['SUCCESS', 'ERROR', 'REJECTED'].includes(transfer.status) ? 'button-primary' : 'button-text_disabled' "
                    :aria-label="$t('transfers.list.resend')"
                    to="" tabindex=""
                    @click.native="resend(transfer)"
                  >
                    <i aria-hidden="true" class="iconsax-arrow-redo"></i>
                  </router-link>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
      </CardSimple>

      <div class="pagination-custom">
        <span class="pagination-count">{{ $tc('transfers.list.pagination', transfers.totalDocs || 0, {total: transfers.totalDocs || 0}) }}</span>
        <b-pagination
          v-model="currentPage"
          :total-rows="transfers.totalDocs"
          :per-page="perPage"
          aria-controls="my-table"
          next-text=""
          prev-text=""
          hide-goto-end-buttons
          @change="pageChange"
        ></b-pagination>
      </div>
    </div>
    <ModalTransferDetails ref="modalDetails"/>
    <ModalAuthorizeTransfer ref="modalAuthorize" @rejected="getTransfers" @error="onAuthorizeError"/>
  </div>
</template>

<script>
import TitleSteps from "@/components/TitleSteps.vue";
import TitleBadge from "@/components/TitleBadge.vue";
import DateRangePicker from "vue2-daterange-picker";
import CardSimple from "@/components/CardSimple.vue";
import EmptyState from "@/components/EmptyState.vue";
import UserAccount from "@/components/UserAccount.vue";
import imgUserAccount from "@/assets/images/Default/profile-photo.svg";
import LoadingBar from "@/components/LoadingBar.vue";
import CardApi from "@/api/wallet/card.api";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import { imageDownloadLink } from '@/utils/utils';
import { getContactColorClass, getContactInitials } from "@/utils/contactUtils";
import moment from 'moment';
import ModalTransferDetails from '@/pages/Transfers/ModalTransferDetails';
import ModalAuthorizeTransfer from '@/pages/Transfers/ModalAuthorizeTransfer';
import {mapGetters} from "vuex";
import {EventBus} from "@/main";
import {
    STORE as sessionStore,
    GET_ACCOUNT,
    SET_ACCOUNT,
} from "@/store/modules/session/session.types";

export default {
  name: "TransfersList",
  mixins: [ToastSnotify],
  components: {
    TitleSteps,
    TitleBadge,
    DateRangePicker,
    CardSimple,
    EmptyState,
    UserAccount,
    LoadingBar,
    ModalTransferDetails,
    ModalAuthorizeTransfer,
  },
  data() {
    return {
      // Images
      imgUserAccount: imgUserAccount,
      // Daterangepicker
      dateRangeComponentBtn: {},
      startDate: null,
      endDate: null,
      // Pagination
      currentPage: 1,
      perPage: 8,

      transfers: {},
      loading: false,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
      account: GET_ACCOUNT
    }),
    canRequestTransfers() {
      if(!this.account) {
        return false;
      }
      if(this.account.isAccountHolder) {
        return true;
      }
      return this.account.permissions.some(p => p.action === 'REQUEST' && p.type === 'TRANSFERS');
    },
    canAuthorizeTransfers() {
      if(!this.account) {
        return false;
      }
      if(this.account.isAccountHolder) {
        return true;
      }
      return this.account.permissions.some(p => p.action === 'AUTHORIZE' && p.type === 'TRANSFERS');
    },
  },
  mounted() {
    moment.locale(this.$i18n.locale);
    this.getTransfers();

    EventBus.$on(`${sessionStore}.${SET_ACCOUNT}`, () => {
      this.resetList();
    });
  },
  methods: {
    moment,
    imageDownloadLink,
    getContactColorClass,
    getContactInitials,
    resetList() {
      this.currentPage = 1;
      this.startDate = null;
      this.endDate = null;
      this.getTransfers();
    },
    async getTransfers() {
      if(this.account) {
        this.loading = true;
        try {
          const response = await CardApi.getTransferList({
            accountId: this.account.account._id,
            page: this.currentPage,
            limit: this.perPage,
            startDate: this.startDate,
            endDate: this.endDate,
          });
          this.transfers = response.data.object;
        } catch (error) {
          this.toastErrorDefault(error);
        }
        this.loading = false;
      }
    },
    getStatusInfo({status, rejectReason}) {
      switch(status) {
        case 'SUCCESS':
          return {
            color: {'badge-success': true},
            icon: 'iconsax-essential-tick',
            label: this.$t('transfers.list.tooltip.success'),
          };
        case 'ERROR':
          return {
            color: {'badge-error': true},
            icon: 'iconsax-essential-close',
            label: this.$t('transfers.list.tooltip.error'),
          };
        case 'REJECTED':
          return {
            color: {'badge-error': true},
            icon: 'iconsax-essential-close',
            label: this.$t('transfers.list.tooltip.rejected'),
            errorMessage: rejectReason,
          };
        case 'PENDING_AUTH':
          return {
            color: {'badge-warning': true},
            icon: 'iconsax-time-clock',
            label: this.$t('transfers.list.tooltip.pendingAuth'),
          };
        case 'PENDING':
          return {
            color: {'badge-warning': true},
            icon: 'iconsax-time-clock',
            label: this.$t('transfers.list.tooltip.pending'),
          };
        default:
          return {};
      }
    },
    resend(transfer) {
      if(this.canRequestTransfers && ['SUCCESS', 'ERROR', 'REJECTED'].includes(transfer.status)) {
        this.$router.push({
          name: 'NewTransfer',
          params: {transfer, resend: true}
        })
      }
    },
    authorize(transfer) {
      if(this.canAuthorizeTransfers) {
        this.$refs.modalAuthorize.open(transfer);
      }
    },
    onAuthorizeError(error) {
      this.toastErrorDefault(error);
    },
    changeDates(dates) {
      const startDate = moment(dates.startDate).startOf('day').toISOString();
      const endDate = moment(dates.endDate).startOf('day').add(1, 'day').toISOString();
      this.startDate = startDate;
      this.endDate = endDate;
      this.currentPage = 1;
      this.getTransfers();
    },
    pageChange(page) {
      this.currentPage = page;
      this.getTransfers();
    }
  },
  beforeDestroy () {
      EventBus.$off(`${sessionStore}.${SET_ACCOUNT}`);
  },
};
</script>
