import {
    GET_USER,
    STORE as sessionStore
  } from "@/store/modules/session/session.types";
import store from '@/store/index'

export default function isLevelTwoUser({ next }) {
    const user = store.getters[`${sessionStore}/${GET_USER}`];

    if(user && (!user.hasFinishLevelTwo || ((user.onbForBusiness && user.hasFinishLevelTwo)))) {
      return next();
    }

    return next('/wallet');
}
