<template>

  <!--                                       ___        _____     ___                                            -->
  <!--                                     .'   `.     |_   _|  .'   `.   _                                      -->
  <!--                                    /  .-.  \      | |   /  .-.  \ (_)                                     -->
  <!--                                    | |   | |  _   | |   | |   | |  _                                      -->
  <!--                                    \  `-'  / | |__' |   \  `-'  / (_)                                     -->
  <!--                                     `.___.'  `.____.'    `.___.'                                          -->
  <!--                                                                                                           -->
  <!--      ____  _____               _                                               _                          -->
  <!--     |_   \|_   _|             (_)                                             / |_                        -->
  <!--       |   \ | |     .--.      __    _ .--..--.    _ .--.     .--.    _ .--.  `| |-'  ,--.    _ .--.       -->
  <!--       | |\ \| |   / .'`\ \   [  |  [ `.-. .-. |  [ '/'`\ \ / /'`\ \ [ `/'`\]  | |   `'_\ :  [ `/'`\]      -->
  <!--      _| |_\   |_  | \__. |    | |   | | | | | |   | \__/ | | \__/ |  | |      | |,  // | |,  | |          -->
  <!--     |_____|\____|  '.__.'    [___] [___||__||__]  | ;.__/   '.__.'  [___]     \__/  \'-;__/ [___]         -->
  <!--                                                  [__|                                                     -->
  <!--                                                                                                           -->
  <!--     No importar nuevamente este componente en el proyecto. Ya esta importado globalmente y se puede       -->
  <!--     usar con el metodo global $requestAuth de la siguiente manera:                                        -->
  <!--                                                                                                           -->
  <!--     if(await this.$requestAuth()) {                                                                       -->
  <!--       ...do something                                                                                     -->
  <!--     }                                                                                                     -->

  <div v-if="user">
    <!-- Modal NIP -->
    <b-modal
      :id="modalNip"
      size="xs"
      hide-header
      hide-footer
      modal-class="modal-default"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide(modalNip)"
        :aria-label="$t('transfers.twoFactor.close')"
        :name="$t('transfers.twoFactor.close')"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{$t(titleNip)}}</h2>
        <p class="modal-subtitle">{{$t(subtitleNip)}}</p>
      </div>
      <div class="body">
        <CodeInput
          fourCodes
          typeInput="password"
          @verificationCode="onNipChange"
          :clazz="hasError ? 'has-error' : ''"
          :warningText="hasError ? $t(warningNip) : ''"/>
      </div>
      <div class="footer">
        <button type="button" class="button button-stroke button-primary" @click="$bvModal.hide(modalNip)">
          {{$t('general.modalTwoFactor.cancel')}}
        </button>
        <button
          type="button"
          class="button button-filled button-primary"
          :disabled="!nip || nip.length !== 4"
          @click="validateNip"
        >
          {{$t(confirm)}}
        </button>
      </div>
      <LoadingBar
        clazz="fullcontent-loading"
        :loadingText="$t(loadingTextNip)"
        v-if="loading"
      ></LoadingBar>
    </b-modal>

    <!-- Modal Código confirmación -->
    <b-modal
      :id="modalSms"
      size="xs"
      hide-header
      hide-footer
      modal-class="modal-default"
    >
      <button
        class="close-modal"
        @click="$bvModal.hide(modalSms)"
        :aria-label="$t('transfers.twoFactor.close')"
        :name="$t('transfers.twoFactor.close')"
      >
        <i aria-hidden="true" class="banana banana-cross-normal"></i>
      </button>
      <div class="head">
        <h2 class="modal-title">{{$t(titleCode)}}</h2>
        <p class="modal-subtitle">
          {{$t(subtitleCode)}}
          <strong class="c-info poppins-font-bold">{{user.phone}}</strong>
        </p>
      </div>
      <div class="body">
        <CodeInput
          :clazz="hasError ? 'has-error' : ''"
          :warningText="hasError ? $t(warningCode) : $t(infoText)"
          @verificationCode="onSmsCodeChange"
        />
        <span v-if="countdownLabel" class="f-12 c-text_content poppins-font-regular f-400 text-center d-block">
          {{$t('general.modalTwoFactor.reSendIn')}}
          <p class="f-24 poppins-font-semibold f-600 m-b-0">{{countdownLabel}} {{$t('transfers.twoFactor.sms.resend.seconds')}}</p>
        </span>
        <a
          v-else
          href="#"
          class="f-14 c-info poppins-font-bold f-700 text-center w-100 d-block"
          tabindex=""
          @click="resendCode"
        >
          <u>{{$t('general.modalTwoFactor.reSend')}}</u>
        </a>
      </div>
      <div class="footer">
        <button type="button" class="button button-stroke button-primary" @click="$bvModal.hide(modalSms)">
          {{$t('general.modalTwoFactor.cancel')}}
        </button>
        <button
          type="button"
          class="button button-filled button-primary"
          :disabled="!smsCode || smsCode.length !== 5"
          @click="verifyCode"
        >
          {{$t(confirm)}}
        </button>
      </div>
      <LoadingBar v-if="loading" clazz="fullcontent-loading" :loadingText="$t(loadingTextCode)"/>
      <LoadingBar v-if="resendingCode" clazz="fullcontent-loading" :loadingText="$t(resendingTextCode)"/>
    </b-modal>
  </div>
</template>
<script>
import LoadingBar from "@/components/LoadingBar.vue";
import CodeInput from "@/components/CodeInput.vue";
import UserApi from "@/api/users/users.api";
import {catchError} from "@/utils/utils";
import moment from 'moment';
import { mapGetters } from "vuex";
import {
  STORE as sessionStore,
  GET_USER,
} from "@/store/modules/session/session.types";

export default {
  name: 'ModalTwoFactorAuth',
  components: {
    LoadingBar,
    CodeInput,
  },
  props: {
    titleNip: {type: String, default: "general.modalTwoFactor.titleNip"},
    subtitleNip: {type: String, default: "general.modalTwoFactor.subtitleNip"},
    titleCode: {type: String, default: "general.modalTwoFactor.titleCode"},
    subtitleCode: {type: String, default: "general.modalTwoFactor.subtitleCode"},
    warningNip: {type: String, default: "general.modalTwoFactor.warningNip"},
    warningCode: {type: String, default: "general.modalTwoFactor.warningCode"},
    infoText: {type: String, default: "general.modalTwoFactor.infoText"},
    loadingTextNip: {type: String, default: "general.modalTwoFactor.loadingNip"},
    loadingTextCode: {type: String, default: "general.modalTwoFactor.loadingCode"},
    resendingTextCode: {type: String, default: "general.modalTwoFactor.resendingCode"},
    confirm: {type: String, default: "general.modalTwoFactor.confirm"},

    // Si el componente viene globalmente (de App.vue) se cambian los ids de los modales para
    // evitar conflictos de id en caso de que se llegue a importar el componente en otro lugar.
    isGlobal: {type: Boolean, default: true},
  },
  data() {
    return {
      loading: false,
      hasError: false,
      promise: null,
      nip: null,
      smsCode: null,
      intervalCodeTime: null,
      countdownLabel: null,
      resendingCode: false,
    };
  },
  computed: {
    ...mapGetters(sessionStore, {
      user: GET_USER,
    }),
    modalNip() {
      return this.isGlobal ? 'app-modal-nip' : 'modal-nip';
    },
    modalSms() {
      return this.isGlobal ? 'app-modal-confirm-code' : 'modal-confirm-code';
    },
  },
  mounted() {
    this.$root.$on("bv::modal::hidden", (bvEvent, modalId) => {
      if ([this.modalNip, this.modalSms].includes(modalId)) {
        this.loading = false;
        this.hasError = false;
        if(this.promise) {
          this.promise.resolve(false);
          this.promise = null;
        }
      }
    });
  },
  methods: {
    requestAuth() {
      if(this.user.userSmsAsTwoFactor) {
        this.sendCode();
        this.countDown();
        this.$bvModal.show(this.modalSms);
      } else {
        this.$bvModal.show(this.modalNip);
      }
      return new Promise((resolve, reject) => {
        this.promise = {resolve, reject};
      });
    },
    closeModals() {
       this.$bvModal.hide(this.modalSms);
      this.$bvModal.hide(this.modalNip);
    },
    // NIP Verification
    onNipChange(nip) {
      this.nip = nip;
    },
    async validateNip() {
      this.loading = true;
      const response = await UserApi.validatePin({
        pin: this.nip
      }).catch(catchError);
      if(response && response.data && !response.data.error) {
        this.promise.resolve(true);
        this.promise = null;
        this.$bvModal.hide(this.modalNip);
      } else {
        this.hasError = true;
        this.nip = null;
      }
      this.loading = false;
    },

    // SMS Verification
    onSmsCodeChange(code) {
      this.smsCode = code;
    },
    async sendCode() {
      const response = await UserApi.sendCode().catch(catchError);
      if (response && response.data && response.data.error) {
        const message = response ? response.data ? response.data.message : null : null;
        this.displayNotificationError(message || 'No se pudo enviar el codigo');
      }
    },
    async resendCode() {
      this.resendingCode = true;
      const response = await UserApi.reSendCode().catch(catchError);
      this.resendingCode = false;
      if (response && response.data && response.data.error) {
        this.displayNotificationError(this.$i18n.t('register.phoneCode.errorCode'));
      } else {
        this.countDown();
      }
    },
    stopInterval() {
      if(this.intervalCodeTime) {
        clearInterval(this.intervalCodeTime);
        this.intervalCodeTime = null;
        this.countdownLabel = null;
      }
    },
    countDown() {
      this.stopInterval();
      const countdownEnd = moment().add(150, 'seconds');
      const updateLabel = () => {
        const diff = countdownEnd.diff(moment());
        this.countdownLabel = moment.utc(diff).format('mm:ss');
        if(diff <= 0) {
          this.stopInterval();
        }
      };
      updateLabel();
      this.intervalCodeTime = setInterval(updateLabel, 100);
    },
    async verifyCode() {
      this.loading = true;
      const response = await UserApi.verifyCode({
        code: this.smsCode
      }).catch(catchError);
      if(response && response.data && !response.data.error) {
        this.promise.resolve(true);
        this.promise = null;
        this.$bvModal.hide(this.modalSms);
      } else {
        this.hasError = true;
        this.smsCode = null;
      }
      this.loading = false;
    },
  },
}
</script>
