<template>
  <div class="collapse-beneficiary">
    <div class="head">
      <span>
        <BeneficiaryInfo
          :text="value.email"
          :textBold="value.name"
        >
        </BeneficiaryInfo>
      </span>
      <span>
        <span class="badge badge-info">
          <i aria-hidden="true" class="badge-icon iconbullet"></i>
          {{$t('collaborators.badge.PENDING_INVITE')}}
        </span>
      </span>
      <span>
        <button
          id=""
          class="button-square xs button-stroke button-accent"
          type="button"
          :name="$t('collaborators.actions.collapse')"
          :aria-label="$t('collaborators.actions.collapse')"
          v-b-toggle="`collapse-collaborator-${key}`"
        >
          <i aria-hidden="true" class="iconsax-arrow-direction-down"></i>
          <i aria-hidden="true" class="iconsax-arrow-direction-up"></i>
        </button>
      </span>
    </div>
    <b-collapse :id="`collapse-collaborator-${key}`" class="body">
      <div class="row">
        <div class="col-12">
          <EmptyState
            clazz="p-0"
            emptyStateDefault
            :title="$t('collaborators.sentInvite.title')"
            :imgUrl="imgEmptystateInvitacionEnviada"
            :imgWidth="427"
            :imgHeight="280"
            :imgAlt="$t('collaborators.sentInvite.title')"
          >
            <p>
              {{$t('collaborators.sentInvite.text')}}
              <strong class="poppins-font-bold f-700">{{value.email}}</strong>
            </p>
          </EmptyState>
        </div>
        <div class="control-buttons buttons-full-w buttons-full-w-xs">
          <button
            v-if="!cancelling"
            type="button"
            class="button button-stroke button-primary w-100"
            @click="cancelInvitation"
          >
            {{$t('collaborators.actions.cancelInvite')}}
          </button>
          <button
            v-else
            type="button"
            class="button button-filled button-primary w-100"
          >
            <LoadingSimple clazz="small-load no-text" />
          </button>
          <button
            v-if="!resending"
            type="button"
            class="button button-filled button-primary w-100"
            @click="resendInvitation"
          >
            {{$t('collaborators.actions.resendInvite')}}
          </button>
          <button
            v-else
            type="button"
            class="button button-filled button-primary w-100"
          >
            <LoadingSimple clazz="small-load no-text" />
          </button>
        </div>
      </div>
    </b-collapse>
    <ConfirmModal ref="confirmModal"/>
  </div>
</template>
<script>
// @ is an alias to /src
import BeneficiaryInfo from "@/components/BeneficiaryInfo.vue";
import EmptyState from "@/components/EmptyState.vue";
import ConfirmModal from "@/components/collaborators/ConfirmModal.vue";
import LoadingSimple from "@/components/LoadingSimple.vue";

import ToastSnotify from "@/mixins/toastSnotify.mixin";
import CollaboratorsMixin from "@/mixins/collaboratorPermissions.mixin";

import imgEmptystateInvitacionEnviada from "@/assets/images/Emptystates/emptystate-invitation-sent.svg";

import CollaboratorsApi from "@/api/wallet/collaborators.api";

export default {
  name: "CollaboratorInvited",
  mixins: [ToastSnotify, CollaboratorsMixin],
  components: {
    BeneficiaryInfo,
    EmptyState,
    ConfirmModal,
    LoadingSimple,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imgEmptystateInvitacionEnviada,
      cancelling: false,
      resending: false,
    };
  },
  computed: {
    key() {
      return this.$vnode?.key?.toString() || "";
    },
  },
  methods: {
    async cancelInvitation() {
      if (this.cancelling) {
        return;
      }
      try {
        const confirmed = await this.$refs.confirmModal.confirm({type: 'cancel'});
        if(confirmed) {
          this.cancelling = true;
          await CollaboratorsApi.cancelInvitation({ id: this.value._id });
          this.$emit("input", { status: "HIDDEN" });
          this.cancelling = false;
          this.toastSuccessDefault();
        }
      } catch (error) {
        this.toastErrorDefault(error);
        this.cancelling = false;
      }
    },
    async resendInvitation() {
      if (this.resending) {
        return;
      }
      try {
        this.resending = true;
        await CollaboratorsApi.resendInvitation({ id: this.value._id });
        this.resending = false;
        this.toastSuccessDefault();
      } catch (error) {
        this.toastErrorDefault(error);
        this.resending = false;
      }
    },
  },
};
</script>
