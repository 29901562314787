import axios from 'axios';
import base from '@/api/base.api';

const namespace = 'banks';

export default {
    all: (params) => {
        return axios.get(`${base.baseUrl}/${namespace}/all`, params);
    },
};
