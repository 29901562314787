<template>
    <b-modal
        id="modal-pagando-check-service"
        size="lg"
        hide-header
        hide-footer
        modal-class="modal-default modal-pagandocheck-info"
        @change="change"
    >
        <button
            class="close-modal"
            @click="$bvModal.hide('modal-pagando-check-service')"
            aria-label="Boton cerrar ventana"
            name="Boton cerrar ventana"
        >
            <i aria-hidden="true" class="banana banana-cross-normal"></i>
        </button>
            <div class="body">
                <div class="row m-b-40">
                    <div class="col-12 col-lg-4 d-flex align-items-c">
                        <img class="img-fluid img-pos" src="@/assets/images/Default/img-POS.png" width="338" height="418" alt="Pagando Check POS" />
                    </div>
                    <div class="col-12 col-lg-8">
                        <div class="pos-info">
                            <small v-text="$t('pagandoCheck.modal.title')"></small>
                            <label v-text="$t('pagandoCheck.modal.label')"></label>
                            <p v-text="$t('pagandoCheck.modal.p')"></p>
                            <ul>
                                <li v-text="$t('pagandoCheck.modal.li1')"></li>
                                <li v-text="$t('pagandoCheck.modal.li2')"></li>
                                <li v-text="$t('pagandoCheck.modal.li3')"></li>
                                <li v-text="$t('pagandoCheck.modal.li4')"></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-4">
                        <div class="features">
                            <img class="img-fluid" src="@/assets/images/Default/img-feature-tarjetas.svg" width="250" height="218" :alt="$t('pagandoCheck.modal.feature1')"/>
                            <label v-text="$t('pagandoCheck.modal.feature1')"></label>
                            <p v-text="$t('pagandoCheck.modal.feature1-p')"></p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="features">
                            <img class="img-fluid" src="@/assets/images/Default/img-feature-contracargos.svg" width="250" height="218" :alt="$t('pagandoCheck.modal.feature2')"/>
                            <label v-text="$t('pagandoCheck.modal.feature2')"></label>
                            <p v-text="$t('pagandoCheck.modal.feature2-p')"></p>
                        </div>
                    </div>
                    <div class="col-12 col-md-4">
                        <div class="features">
                            <img class="img-fluid" src="@/assets/images/Default/img-feature-msi.svg" width="250" height="218" :alt="$t('pagandoCheck.modal.feature1')"/>
                            <label v-text="$t('pagandoCheck.modal.feature3')"></label>
                            <p v-text="$t('pagandoCheck.modal.feature3-p')"></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div class="mas-info-pagandocheck">
                    <i aria-hidden="true" class="iconsax-location-global-search f-24 c-info m-r-10"></i>
                    <span class="">
                        <label class="f-12 c-text_title poppins-font-regular f-400 d-block m-b-0" v-text="$t('pagandoCheck.modal.link')"></label>
                        <a
                            href="https://www.pagandocheck.com"
                            target="_blank"
                            class="f-12 c-info poppins-font-bold f-700 d-block hover"
                            tabindex=""
                            rel="noopener noreferrer"
                        >
                            <u>www.pagandocheck.com</u>
                        </a>
                    </span>
                </div>
                <button
                    id="b-modal-acuerdo-traspaso-info"
                    type="button"
                    class="button sm button-filled button-primary"
                    :disabled="error"
                    @click="next"
                >
                    <i aria-hidden="true" class="iconsax-arrow-circle-right m-r-10 d-none d-sm-flex"></i>
                    {{$t('pagandoCheck.modal.hire')}}
                </button>
            </div>
            <LoadingBar
                clazz="fullcontent-loading"
                v-if="loading && clicked"
            />
        </b-modal>
</template>
<script>
import OrganizationApi from '@/api/core/organization.api.js';
import LoadingBar from "@/components/LoadingBar.vue";
import {mapGetters} from "vuex";
import {
    STORE as sessionStore,
    GET_ACCOUNT,
} from "@/store/modules/session/session.types";
import config from "@/config";

export default {
    name: "HireModal",
    components: {
        LoadingBar,
    },
    data() {
        return {
            loading: false,
            clicked: false,
            response: null,
            error: false,
        };
    },
    computed: {
        ...mapGetters(sessionStore, {
            account: GET_ACCOUNT
        }),
    },
    methods: {
        async getHireStatus() {
            try {
                this.loading = true;
                const payload = {accountId: this.account?.account?._id};
                this.response = await OrganizationApi.hireStatusSend(payload);

                if (this.response.organizationSameName) {
                    this.error = true;
                    this.displayNotificationError(this.$t('pagandoCheck.modal-contract.error-message'), this.$t('pagandoCheck.modal-contract.error-title'));
                } else {
                    this.loading = false;
                    if (this.clicked) {
                        this.next();
                    }
                }
            } catch (error) {
                this.loading = false;
                this.error = true;
                this.toastErrorDefault(error);
            }
        },
        next() {
            if (this.loading) {
                this.clicked = true;
            } else {
                if (this.response?.session?.resultData?.token) {
                    window.open(`${config.check.host}:${config.check.port}${config.check.endpoint}/wallet-hire?token=${this.response?.session?.resultData?.token}`, '_blank');
                } else {
                    this.$bvModal.show('modal-acuerdo-traspaso-info');
                }
                this.$bvModal.hide('modal-pagando-check-service');
            }
        },
        async change(evt) {
            if (evt) {
                await this.getHireStatus();
            }
        },
    },
};
</script>
