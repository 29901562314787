export const STORE = 'SESSION';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER = 'SET_USER';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_NOTIFICATION_EMAIL = 'SET_NOTIFICATION_EMAIL';
export const SET_FINGER_PRINT_AUTH = 'SET_FINGER_PRINT_AUTH';
export const SET_FACIAL_RECOGNITION_AUTH = 'SET_FACIAL_RECOGNITION_AUTH';
export const SET_SMS_TWO_FACTOR = 'SET_SMS_TWO_FACTOR';
export const SET_FULL_DATA_USER = 'SET_FULL_DATA_USER';
export const SET_PERMISSIONS = 'SET_PERMISSIONS';
export const SET_TOKEN_EXPIRE_DATE = 'SET_TOKEN_EXPIRE_DATE';
export const GET_TOKEN = 'GET_TOKEN';
export const GET_USER = 'GET_USER';
export const GET_FULL_DATA_USER = 'GET_FULL_DATA_USER';
export const LOAD_FULL_DATA_USER = 'LOAD_FULL_DATA_USER';
export const GET_EMAIL_USER = 'GET_EMAIL_USER';
export const GET_PERMISSIONS = 'GET_PERMISSIONS';
export const GET_TOKEN_EXPIRE_DATE = 'GET_TOKEN_EXPIRE_DATE';
export const CHANGE_PROFILE_JWT = "CHANGE_PROFILE_JWT";
export const CHANGE_OTHER_PROFILE_JWT = 'CHANGE_OTHER_PROFILE_JWT';
export const CLEAR_STORE = 'CLEAR_STORE';
export const SET_LOADING = 'SET_LOADING';
export const GET_LOADING = 'GET_LOADING';
export const SET_TEMP_WALLET_USER = 'SET_TEMP_WALLET_USER';
export const GET_ACCOUNT = 'GET_ACCOUNT';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const GET_ACCOUNT_DES = 'GET_ACCOUNT_DES';
export const SET_CARD_STATUS = 'SET_CARD_STATUS';
export const GET_ACCOUNT_DESCRIPTION = 'GET_ACCOUNT_DESCRIPTION';
export const GET_ACCOUNT_NUMBER = 'GET_ACCOUNT_NUMBER';
export const GET_USER_NAME = 'GET_USER_NAME';
export const GET_USER_FULL_NAME = 'GET_USER_FULL_NAME';
export const GET_USER_SELF_PORTRAIT = 'GET_USER_SELF_PORTRAIT';
export const GET_USER_HAS_UNIFIED_PIN = 'GET_USER_HAS_UNIFIED_PIN';
export const SET_USER_HAS_UNIFIED_PIN = 'SET_USER_HAS_UNIFIED_PIN';
export const GET_USER_CVV_MIGRATION_STATUS = 'GET_USER_CVV_MIGRATION_STATUS';
export const SET_USER_CVV_MIGRATION_STATUS = 'SET_USER_CVV_MIGRATION_STATUS';
export const GET_SMS_TWO_FACTOR = 'GET_SMS_TWO_FACTOR';
export const CHECK_PERMISSION = 'CHECK_PERMISSION';
export const GET_ACCOUNT_ID = 'GET_ACCOUNT_ID';
export const GET_ACCOUNT_IMG = 'GET_ACCOUNT_IMG';
export const GET_LOCATION = 'GET_LOCATION';
export const SET_LOCATION = 'SET_LOCATION';
export const GET_ORG_INFO = 'GET_ORG_INFO';
export const GET_MAIN_ACCOUNT = 'GET_MAIN_ACCOUNT';
export const SET_MAIN_ACCOUNT = 'SET_MAIN_ACCOUNT';
export const GET_PRODUCT_TYPE = 'GET_PRODUCT_TYPE';
export const SET_COLLAB_ACCOUNT = 'SET_COLLAB_ACCOUNT';
export const GET_COLLAB_ACCOUNT = 'GET_COLLAB_ACCOUNT';
