<template>

<div>
    <h1>404 NOT FOUND</h1>
</div>

</template>
<script>

export default {
  name: "NotFound",
  components: {},
  props: {},
  data() {},
  mounted() {},
  destroyed() {},
  computed: {},
  methods: {},
};
</script>
