<template>
<div>
  <div v-if="showLoading && !showErrorFromMultiple">
    <LoadingSimple
        clazz="big-load p-t-50 p-b-50"
        label="Loading..."
    ></LoadingSimple>
  </div>
  <div v-if="!showLoading && !showErrorFromMultiple">
    <TitlePrincipal
        :title="$route.name === 'selectAccountMultiple' ? 'Solicitar nueva cuenta' : $t('register.selectAccountCard.title')"
        :subtitle="$route.name === 'selectAccountMultiple' ? 'Selecciona la opción de tu preferencia y solicita la apertura de tu nueva cuenta.'
          : $t('register.selectAccountCard.subtitle')">
    </TitlePrincipal>
    <div v-if="cardTemplates.length === 0">
      <EmptyState
          :emptyStateSearching="emptyStateSearching"
          :emptyStateDefault="emptyStateDefault"
          :title="$t(emptyStateTitle)"
          :text="$t(emptyStateText)"
      ></EmptyState>
    </div>

    <div v-else class="account-swiper">
      <div class="swiper-container">
        <carousel
            ref="AccountSwiper"
            :perPage="1"
            :navigationEnabled="true"
            :paginationEnabled="false"
            navigationNextLabel="<i aria-hidden='true' class='iconsax-arrow-arrow-right'></i>"
            navigationPrevLabel="<i aria-hidden='true' class='iconsax-arrow-arrow-left'></i>"
            v-on:page-change="onSlideChange"
        >
          <slide v-for="cardT in cardTemplates" :key="cardT._id">
            <div class="swiper-slide">
              <div class="card-img" @click="selectCard(cardT)">
                <img :src="getImg(cardT)" :alt="cardT.name">
                <div class="account-selected" v-if="selectedCard._id === cardT._id">
                  {{ $t('register.selectAccount.selectedAccount') }}
                </div>
                <div class="account-selected-icon" v-if="selectedCard._id === cardT._id">
                  <i aria-hidden="true" class=" banana banana-check-normal"></i>
                </div>
              </div>
              <div class="info-container">
                <h2 class="f-24 c-primary_1 poppins-font-medium m-b-5">
                  {{ $t('general.account') }} <strong class="c-info poppins-font-bold">{{cardT.name}}</strong>
                </h2>
                <p class="f-12 c-plain_text poppins-font-regular m-b-30">
                  {{ cardT.description}}
                </p>
                <b-tabs class="tabs-pills" content-class="tabs-pills-body p-t-20">
                  <b-tab title="Beneficios" active>
                    <ul class="checked-list">
                      <li v-for="(benefit, index) in cardT.benefits[$i18n.locale]" :key="`b-${index}`">
                        {{benefit}}
                      </li>
                    </ul>
                  </b-tab>
                  <b-tab title="Comisiones">
                    <ul class="checked-list">
                      <li v-for="(commission, index) in cardT.commissions[$i18n.locale]" :key="`c-${index}`">
                        {{commission}}
                      </li>
                    </ul>
                  </b-tab>
                  <b-tab title="Requisitos">
                    <ul class="checked-list">
                      <li v-for="(requirement, index) in cardT.requirements[$i18n.locale]" :key="`r-${index}`">
                        {{requirement}}
                      </li>
                    </ul>
                  </b-tab>
                  <b-tab title="Información adicional">
                    <ul class="checked-list">
                      <li v-for="(responsibility, index) in cardT.responsabilities[$i18n.locale]" :key="`re-${index}`">
                        {{responsibility}}
                      </li>
                    </ul>
                  </b-tab>
                </b-tabs>
              </div>
            </div>
          </slide>
        </carousel>
      </div>
    </div>

    <div class="footer-buttons right">
      <button type="button" class="button button-ghost button-plain_text" name="Regresar" @click="back()"> {{ $t('register.selectAccountCard.back') }} </button>
      <button type="button" class="button button-filled button-primary" name="Continuar" :disabled="!Object.keys(selectedCard).length" @click="submit">
        {{ $t('register.selectAccountCard.continue') }}
      </button>
    </div>
  </div>
  <div v-if="!showLoading && showErrorFromMultiple">
  <TitlePrincipal title="Ocurrio un error la procesar tu solicitud" clazz="text-align-c">
    <p class="subtitle">{{errorMessageMultiple}}</p>
  </TitlePrincipal>
  <img class="img-fluid max-h-300px d-flex m-0-auto"
       src="@/assets/images/Illustrations/illustration-solicitud-aprobada.svg"
       alt="Solicitud aprobada"/>
  <div class="footer-buttons center">
    <button type="button" class="button button-filled button-filled button-primary" name="Corregir" @click="corregirErrores()">
      Intentar de nuevo
    </button>
  </div>
</div>

  <ModalAlert
      closeCross
      modalId="modal-change-account-type"
      buttonType="button-primary"
      :imgUrl="imageModalGeneric"
      title="¿Estás seguro de que quieres regresar a la selección de tipo de cuenta?"
      :text="!isPersonal ? 'Si regresas se borrará toda la información que llevas registrada al momento y se reiniciará todo el proceso de nuevo' : null"
      buttonCancel="No"
      buttonText="Sí"
      @submit="returnToSelectAccountType()"
  ></ModalAlert>
</div>
</template>

<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import EmptyState from "@/components/EmptyState";
import CardTemplateApi from "@/api/core/cardTemplate.api";
import {catchError} from "@/utils/utils";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import UsersApi from "@/api/users/users.api";

import pagandoExplore from "@/assets/images/Cards/pagando-explore.svg"
import pagandoExplorePlus from "@/assets/images/Cards/pagando-explore-plus.svg";
import pagandoNight from "@/assets/images/Cards/pagando-night.svg";
import pagandoBusiness from "@/assets/images/Cards/pagando-business.svg";
import pagandoBusinessPro from "@/assets/images/Cards/Accounts/businesspro-virtual-front.svg";
import imageModalGeneric from "@/assets/images/Illustrations/illustration-modal-generic.svg";
import ModalAlert from "@/components/ModalAlert.vue";

import { mapGetters } from 'vuex';
import {
  STORE as onboardingStepsStore,
  GO_TO_ROUTE,
  GET_ACCOUNT_TYPE_BUSINESS,
  GET_CARD_REQUEST,
  GET_LEVEL,
  GET_OBJECT_ERROR_REQUEST_CARD,
  GET_LOCATION,
  GET_TYPE_ACCOUNT
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
  STORE as sessionStore,
  GET_USER
} from "@/store/modules/session/session.types";
import LoadingSimple from "@/components/LoadingSimple";
import {
  STORE as organizationInfoStore,
  GET_ORGANIZATIONS_INFO
} from "@/store/modules/organizationInfo/organizationInfo.types";
import {ONBOARDING_STEPS} from "@/utils/onbngSteps";
import {Carousel, Slide} from "vue-carousel";

const ACCOUNT_TYPES = {
  CLIENTS_ACCOUNT: "CLIENTS_ACCOUNT",
  USERS_ACCOUNT: "USERS_ACCOUNT"
}

export default {
  name: "SelectAccountCard",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    EmptyState,
    LoadingSimple,
    ModalAlert,
    Carousel,
    Slide,
  },
  computed: {
    ...mapGetters(onboardingStepsStore, {
      businessAccountType: GET_ACCOUNT_TYPE_BUSINESS,
      cardRequest: GET_CARD_REQUEST,
      level: GET_LEVEL,
      objectError: GET_OBJECT_ERROR_REQUEST_CARD,
      location: GET_LOCATION,
      isPersonal: GET_TYPE_ACCOUNT
    }),
    ...mapGetters(sessionStore, {
      user: GET_USER
    }),
    ...mapGetters(organizationInfoStore, {
      orgs: GET_ORGANIZATIONS_INFO
    })
  },
  data() {
    return {
      cardTemplates: [],
      selectedCard: {},
      showLoading: true,
      emptyStateSearching: true,
      emptyStateDefault: false,
      emptyStateTitle: '',
      emptyStateText: "register.selectAccountCard.emptyStateText",
      showErrorFromMultiple: false,
      errorMessageMultiple: '',
      imageModalGeneric
    }
  },
  methods: {
    async returnToSelectAccountType() {
      if(this.isPersonal) {
        if(this.$route.name !== 'selectAccountMultiple') {
          const response = await UsersApi.updateOnbTypeUser({user: this.user._id, onbForBusiness: this.isPersonal ? false : true, isBack: true});

          if(response.data.error) {
            this.displayNotificationError('Ocurrió un error al regresar.')
          } else {
              this.$store.dispatch(`${onboardingStepsStore}/backPersonal`)
          }

          } else {
            this.$store.dispatch(`${onboardingStepsStore}/backPersonal`)

        }
      } else {
        const response = await UsersApi.deleteAllInfoForUser({user: this.user._id,
          isMultiple: this.$route.name === 'selectAccountBusinessMultiple'});

        if(response.data.error) {
          this.displayNotificationError('Ocurrió un error al regresar.')
        } else {
          this.$store.commit(`${onboardingStepsStore}/SET_CARD_REQUEST`, '');
          this.$store.commit(`${organizationInfoStore}/SET_ORGANIZATION_INFO`, {});
          this.$store.commit(`${onboardingStepsStore}/SET_SELECT_ACCOUNT`, null);
          this.$store.commit(`${onboardingStepsStore}/SET_LEVEL`, 1);
          this.$store.commit(`${onboardingStepsStore}/SET_LOCATION`, {});
          this.$store.commit(`${onboardingStepsStore}/SET_AUX_LEVEL`, 1);
              this.$store.dispatch(`${onboardingStepsStore}/backBusiness`)

        }
      }
    },
    back() {
      this.$bvModal.show('modal-change-account-type');
    },
    getImg(cardT) {
      if(cardT.image) {
        return cardT.image;
      }
      return this.getImgByLevel(cardT.product);
    },
    getImgByLevel(level) {
      let img;
      switch (level) {
        case "EXPLORE":
          img = pagandoExplore;
          break;
        case "EXPLORE_PLUS":
          img = pagandoExplorePlus;
          break;
        case "NIGHT":
          img = pagandoNight;
          break;
        case "BUSINESS":
          img = pagandoBusiness;
          break;
        case "BUSINESS_PRO":
          img = pagandoBusinessPro;
          break;
        default:
          img = pagandoExplore;
          break;
      }
      return img;
    },
    selectCard(cardT) {
      this.selectedCard = cardT;
    },
    async submit() {
      if (this.selectedCard) {
        this.showLoading = true;
        if(this.cardRequest && this.cardRequest.accountType === 'BUSINESS') {
          const created = await this.$store.dispatch(`${onboardingStepsStore}/saveCardTemplateRequestForBusiness`,
              {
                cardTemplate: this.selectedCard._id,
                cardRequestId: this.cardRequest._id,
                name: this.selectedCard.name,
                isMultipleAccount: this.$route.name === 'selectAccountBusinessMultiple'
              });
          if (created) {
            // this.$store.dispatch(`${onboardingStepsStore}/checkCurrentStep`, {route: 'usagePoliciesBusiness'});
            await this._handleSuccessBusiness();
          } else {
            this._handleErrorBusiness();
          }
        } else {
          await this._submitPersonal();
        }
          this.showLoading=false;
      }
    },
    async _submitPersonal() {
      const created = await this.$store.dispatch(`${onboardingStepsStore}/saveCardRequest`,
            {
              isOnboarding: this.$route.name === 'selectAccountMultiple' ? false : true,
              cardTemplate: this.selectedCard._id,
              name: this.selectedCard.name,
              cardRequestId: this.cardRequest._id,
              isMultipleAccount: this.$route.name === 'selectAccountMultiple'
            });
        if (created) {
          // this.$store.dispatch(`${onboardingStepsStore}/checkCurrentStep`, {route: 'usagePolicies', doRedirect: true});
          await this._handleSuccess();
        } else {
          this._handleError();
        }
    },
    sendRequestCard() {
      let isMoral = false;
      if (this.cardRequest.isMoral) {
        isMoral = true;
      }

      this.$store.dispatch(`${onboardingStepsStore}/updateOnboardingStep`, {
        stepOnbng: ONBOARDING_STEPS.WAIT_REQUEST,
      });

      this.$store.dispatch(`${onboardingStepsStore}/requestCard`, {
        provider: "PAGANDITO",
        longitud: this.location.longitud,
        latitud: this.location.latitud,
        isMoral: isMoral,
        isMultipleAccount: this.$route.name === 'selectAccountMultiple'
      }).then(() => {});

      this.$router.push({name: "processingRequestMultiple"});
    },
    async _handleSuccessAux() {
      const policiesAccepted = await this.$store.dispatch(`${onboardingStepsStore}/checkAcceptedPolicies`);
      if(!policiesAccepted) {
        this.$store.dispatch(`${onboardingStepsStore}/${GO_TO_ROUTE}`, "usagePoliciesMultiple");
        return;
      }

      if(this.level === 1) {
        this.sendRequestCard();
      } else if(this.level === 2) {
        if(!this.user.hasFinishLevelTwo) {
          this.$store.dispatch(`${onboardingStepsStore}/updateOnboardingStep`, {
            stepOnbng: ONBOARDING_STEPS.PLACE_RESIDENCE,
          });
          this.$router.push({name: "residenceMultiple"});
        } else {
          this.sendRequestCard();
        }
      } else {
        this._checkLevel3();
      }
    },
    async _handleSuccess() {
      if(this.$route.name === 'selectAccountMultiple') {
        this._handleSuccessAux();
      } else {
          const response = await UsersApi.updateOnbTypeUser({user: this.user._id, onbForBusiness: this.isPersonal ? false : true, step: 'ONBOARDING_STEP'})

            if(response.data.error) {
              this.displayNotificationError('Ocurrió un error para continuar proceso.')
            } else {
              this.$router.push({name: "usagePolicies"});
            }
      }
    },
    async _handleSuccessBusiness() {
      if(this.$route.name === 'selectAccountBusinessMultiple') {
        // let orgs = [];
        // const response = await OrganizationInfoApi.getByUserId({id: this.user._id})
        //     .catch(catchError);
        // if (response.data && !response.data.error) {
        //     orgs = response.data.object;
        // }

        this.$store.dispatch(`${onboardingStepsStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.BUSINESS_START,
        });

        const orgsMoral = this.orgs.filter(o => o.invoiceData.isMoralPerson);
        const orgsPhysic = this.orgs.filter(o => !o.invoiceData.isMoralPerson);

        if(this.cardRequest.isMoral) {
          if(orgsMoral.length > 0) {
            this.$router.push({name: "selectCreateOrgBusinessMultiple"})
          } else {
            this.$store.commit(`${onboardingStepsStore}/SET_CREATE_NEW_ORG`, true);
            this.$router.push({name: "businessStartMultiple"})
          }
        } else {
          if(orgsPhysic.length > 0) {
            this.$router.push({name: "selectCreateOrgBusinessMultiple"})
          } else {
            this.$store.commit(`${onboardingStepsStore}/SET_CREATE_NEW_ORG`, true);
            this.$router.push({name: "businessStartMultiple"})
          }
        }
      } else {
        const response = await UsersApi.updateOnbTypeUser({user: this.user._id, onbForBusiness: this.isPersonal ? false : true, step: 'ONBOARDING_STEP'})

            if(response.data.error) {
              this.displayNotificationError('Ocurrió un error para continuar proceso.')
            } else {
              this.$router.push({name: "usagePoliciesBusiness"});
            }
      }
    },
    _checkLevel3() {
      if(!this.user.hasFinishLevelTwo && !this.user.hasFinishLevelThree) {
        this.$router.push({name: "residenceMultiple"});
      } else if(this.user.hasFinishLevelTwo && !this.user.hasFinishLevelThree) {
        this.$router.push({name: "addInfoMultiple"});
      } else {
        this.sendRequestCard();
      }
    },
    _handleErrorBusiness() {
      if(this.$route.name === 'selectAccountBusinessMultiple') {
          // this.showErrorFromMultiple = true;
          // if(this.objectError.hasAlreadyAccount) {
          //   this.errorMessageMultiple = 'Ya tienes una cuenta del tipo seleccionado. Por favor selecciona un tipo de cuenta diferente.'
          // }
        this.$router.push({name: "businessSuccessRequestMultiple"});

      } else {
        this.displayNotificationError(
            this.$i18n.t('register.selectAccountCard.errorSaveRequestCard'),
            'Ocurrió un error al hacer la asignación de tarjeta.',
        )
      }
    },
    _handleError() {
      if(this.$route.name === 'selectAccountMultiple') {
          // this.showErrorFromMultiple = true;
          // if(this.objectError.hasAlreadyAccount) {
          //   this.errorMessageMultiple = 'Ya tienes una cuenta del tipo seleccionado. Por favor selecciona un tipo de cuenta diferente.'
          // }
        this.$router.push({name: "successRequestMultiple"});

      } else {
        this.displayNotificationError(
            this.$i18n.t('register.selectAccountCard.errorSaveRequestCard'),
            this.$i18n.t('register.selectAccountCard.errorRequestCardTitle'),
        )
      }
    },
    getTypeAccount() {
      if (this.cardRequest && this.cardRequest.accountType === 'BUSINESS') {
          return ACCOUNT_TYPES.CLIENTS_ACCOUNT;
      }
      return ACCOUNT_TYPES.USERS_ACCOUNT;
    },
    async getVirtualCards(all) {
      const typeAccount = this.getTypeAccount();
      let response;
      if(all){
        response = await CardTemplateApi.findAll("VIRTUAL", {
          loadedImage: true,
          accountType: typeAccount
        }).catch(catchError);
      } else {
        response = await CardTemplateApi.find("VIRTUAL", {
          loadedImage: true,
          accountType: typeAccount
        }).catch(catchError);
      }
      if (response.data) {
          if (response.data.error) {
              this.displayNotificationError(
                  this.$i18n.t('register.selectAccountCard.errorRequestCard'),
                  this.$i18n.t('register.selectAccountCard.errorRequestCardTitle'),
              )
          } else {
              if (typeAccount === ACCOUNT_TYPES.CLIENTS_ACCOUNT) {
                this.cardTemplates = this.filterTemplates(response.data.object);
              } else {
                this.cardTemplates = response.data.object;
              }
              if(this.cardTemplates?.[0]) {
                this.selectCard(this.cardTemplates[0]);
              }
          }
      }
      this.showLoading = false;
      this.emptyStateSearching = false;
      this.emptyStateDefault = true;
      this.emptyStateTitle = 'register.selectAccountCard.emptyStateTitle';
      this.emptyStateText = ""
    },
    onSlideChange(index) {
      this.selectCard(this.cardTemplates[index]);
    },
    filterTemplates(cardTemplates) {
      let isMoral = false;

      if(this.cardRequest) {
        isMoral = this.cardRequest.isMoral;
      }

      return cardTemplates.filter(e => {
        return (e.informationLevel === 'LEVEL_THREE') && e.isMoral === isMoral;
      });
    }
  },
  async beforeMount() {
    if (this.cardRequest && this.cardRequest.isCorporative) {
      this.$router.push({name: "usagePoliciesBusiness"});
    }
  },
  async created() {
    const routeName = this.$route.name;
    if(routeName === 'selectAccountMultiple'){
      // SI ENTRA EN ESTO DEBE DE SER EL ALL :D
      this.$store.commit(
        `${onboardingStepsStore}/SET_CARD_REQUEST`,
        ''
      );
      await this.$store.dispatch(`${onboardingStepsStore}/putLevel`, 'MULTIPLE_PERSONAL');
      await this.getVirtualCards(true);
    } else {
      if(routeName === 'selectAccountBusinessMultiple') {
        await this.$store.dispatch(`${onboardingStepsStore}/putLevel`, this.cardRequest.isMoral ? 'MULTIPLE_BUSINESS_MORAL' : 'MULTIPLE_BUSINESS_PHYSIC');
      }
      await this.getVirtualCards(false);
    }

    await this.$store.dispatch(`${onboardingStepsStore}/checkCurrentStep`, this.$route);

    if(this.cardRequest && this.cardRequest.cardTemplate) {
      this.selectedCard = this.cardRequest.cardTemplate;
      if(this.cardTemplates?.length) {
        const index = this.cardTemplates.findIndex(t => t._id === this.selectedCard._id);
        if(index >= 0) {
          await this.$nextTick(); // Make sure the swiper is mounted
          this.$refs.AccountSwiper.goToPage(0);
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
