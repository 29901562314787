<template>
  <div>
    <TitlePrincipal
      title="Domicilio fiscal"
      subtitle="Ingresa los siguientes datos del domicilio del negocio."
    >
    </TitlePrincipal>
    <AddressForm v-model="address"/>
    <div class="footer-buttons right">
      <button
        type="button"
        class="button button-ghost button-plain_text"
        name="Regresar"
        @click="back"
      >
        Regresar
      </button>
      <button
        v-if="!processing"
        type="button"
        class="button button-filled button-primary"
        name="Continuar"
        :disabled="$v.$invalid"
        @click="submit"
      >
        Continuar
      </button>
      <button
        v-else
        :id="'Loading button'"
        class="button button-filled button-primary"
      >
        <LoadingSimple clazz="small-load no-text" />
      </button>
    </div>
  </div>
</template>
<script>
import TitlePrincipal from "@/components/TitlePrincipal";
import AddressForm from "@/components/AddressForm.vue";
import ToastSnotify from "@/mixins/toastSnotify.mixin";
import LoadingSimple from "@/components/LoadingSimple";
import { ONBOARDING_STEPS } from "@/utils/onbngSteps";
import { mapGetters } from "vuex";
import {
  GO_TO_ROUTE,
  STORE as onbngStore,
  STORE as OnboardingStore
} from "@/store/modules/onboardingSteps/onboardingSteps.types";
import {
  STORE as OrganizationInfoStore,
  GET_ORGANIZATION_INFO,
} from "@/store/modules/organizationInfo/organizationInfo.types";
import { required, minLength } from "vuelidate/lib/validators";
import {isDefined} from "@/utils/utils";

export default {
  name: "FiscalAddress",
  mixins: [ToastSnotify],
  components: {
    TitlePrincipal,
    LoadingSimple,
    AddressForm,
  },
  data() {
    return {
      processing: false,
      address: {},
    };
  },
  computed: {
    ...mapGetters(OrganizationInfoStore, {
      organization: GET_ORGANIZATION_INFO,
    }),
  },
  validations: {
    address: {
      zipCode: { required },
      city: { required },
      state: { required },
      district: { required, minLength: minLength(3) },
      streetAddress: {
        required,
        minLength: minLength(3),
      },
      exteriorNumber: { required },
    },
  },
  methods: {
    back() {
      this.$store.dispatch(`${OnboardingStore}/backBusiness`);
    },
    async submit() {
      this.processing = true;
      const params = {
        ...this.address,
        id: this.organization.id,
      };

      const success = await this.$store.dispatch(
        `${OrganizationInfoStore}/saveFiscalAddress`,
        params
      );
      if (success) {
        this.$store.dispatch(`${OnboardingStore}/updateOnboardingStep`, {
          stepOnbng: ONBOARDING_STEPS.BUSINESS_COMERCIAL_ADDRESS,
        });

        if(this.$route.name === 'businessFiscalAddressMultiple') {
          this.$store.dispatch(`${OnboardingStore}/${GO_TO_ROUTE}`, "businessFiscalComAddressMultiple");
        } else {
          this.$store.dispatch(`${OnboardingStore}/${GO_TO_ROUTE}`, "businessFiscalComAddress");
        }

      } else {
        this.displayNotificationError(
          "No se pudo guardar la informacion del negocio"
        );
      }
      this.processing = false;
    },
    assignData() {
      if (isDefined(this.organization?.invoiceData)) {
        this.address = this.organization.invoiceData;
      }
    }
  },
  async beforeMount() {
    if(this.$route.name === 'businessFiscalAddressMultiple') {
      this.$store.commit(`${OnboardingStore}/SET_CREATE_NEW_ORG`, true);
      await this.$store.dispatch(`${OnboardingStore}/putLevel`, 'MULTIPLE_BUSINESS_MORAL');
    }

    this.$store.dispatch(`${onbngStore}/checkCurrentStep`, this.$route);
    this.assignData()

  }
};
</script>
