<template>
    <div id="dct-UploadProfileImage" class="upload-profile-image" :class="clazz">
        <span class="profile-img">
            <img v-if="!imgUser" class="img-fluid" src="@/assets/images/Default/profile-photo.svg" alt="default" />
            <img v-if="imgUser" class="img-fluid" :src="imgUser" alt="user" />
        </span>
        <button v-if="!imgUser && !showInfoUser" type="submit" class="button xs button-stroke button-accent2">
            Agregar imagen
            <input :id="id" :ref="id" type="file" class="input-file" />
        </button>
        <button v-if="imgUser && imgUploaded && !showInfoUser" type="submit" class="button xs button-stroke button-accent2">
            Cambiar imagen
            <input :id="id" :ref="id" type="file" class="input-file" />
        </button>
        <span v-if="showInfoUser" class="info-user">
            <label>{{userName}}</label>
            <slot></slot>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'UploadProfileImage',
        props: {
            clazz: String,
            imgUser: {type: String},
            showInfoUser: {type: Boolean, default: false},
            imgUploaded: {type: Boolean, default: false},
            userName: String,
            id: {type: String, default: 'file-imageUpload'}
        },
        methods: {}
    }
</script>
